import { useEffect, useState, useContext } from "react";
import Layout from "../../../Hoc/Layout";
import Card from "../../../components/UI/Card";
import Footer from "../../../organisms/Footer";
import Main from "../../../components/UI/Main";
import Input from "../../../components/Input";
import { Radio } from "@mui/material";
import Button from "../../../components/Button";
import { useDispatch, useSelector } from "react-redux";
import { getPriorityConfigList, postPriorityConfig } from "../../../redux/actions/my-task";
import Loader from "../../../components/Loader";
import SuccessMessageBox from "../../../components/UI/Alert/sucess";
import MessageBox from "../../../components/UI/Alert";
import { POST_PRIORITY_CONFIG_RESET } from "../../../redux/types/types";
import { values } from "lodash";
import { Link } from "react-router-dom";
import { ThemeContext } from "../../../Hooks/Themes/Themeprovider";

const PriorityConfiguration = () => {
    const { currentMode, toggleMode } = useContext(ThemeContext);
    const [selectedValue, setSelectedValue] = useState(null);
    const dispatch = useDispatch();
    const getConfig = useSelector((state) => state?.getPriorityConfigState);
    const { dataList: dataList } = getConfig;
    const [radioDisabled, setRadioDisabled] = useState(true);
    const { fetching, requestComplete, error, data } = useSelector((state) => state?.postPriorityConfigState);
    const succesMsg = "Updated successfully";
    const errormsg = "Something went wrong please contact admin";
    const [errormsgs, seterrormsg] = useState({
        message: "",
    });
    const value = [
        {
            id: 0,
            task_priority: 4,
            name: "Urgent",
            due_days: "",
            isdefault: false,
        },
        {
            id: 1,
            task_priority: 3,
            name: "High",
            due_days: "",
            isdefault: false,
        },
        {
            id: 2,
            task_priority: 2,
            name: "Medium",
            due_days: "",
            isdefault: false,
        },
        {
            id: 3,
            task_priority: 1,
            name: "Low",
            due_days: "",
            isdefault: false,
        },
    ];

    useEffect(() => {
        if (dataList?.length > 0) {
            let configData = [];
            dataList.map((item) => {
                let parseData = {
                    id: item?.id,
                    task_priority: item?.task_priority,
                    name: item?.task_priority == 4 ? "Urgent" : item?.task_priority == 3 ? "High" : item?.task_priority == 2 ? "Medium" : "Low",
                    due_days: item?.due_days.toString(),
                    isdefault: item?.isdefault,
                };
                configData.push(parseData);
                if (item?.isdefault) {
                    setRadioDisabled(false);
                }
            });
            configData.sort((a, b) => {
                return b.task_priority - a.task_priority;
            });
            setLayoutItems(configData);
        } else {
            setLayoutItems(value);
        }
    }, [getConfig]);

    useEffect(() => {
        dispatch(getPriorityConfigList());
    }, [dispatch]);
    const [layoutItems, setLayoutItems] = useState(value);

    const MandatoryCheckboxChange = (event, id) => {
        setRadioDisabled(false);
        const temp = layoutItems.map((element) => {
            if (id == element?.id) {
                element.isdefault = true;
            } else {
                element.isdefault = false;
            }
            return element;
        });

        setLayoutItems(temp);
    };

    const inputChange = (event, id) => {
        var inputCheck = false;
        const temp = layoutItems.map((element) => {
            if (id == element?.id) {
                if (event.target.value == "Na" || event.target.value == "0") {
                    element.due_days = "";
                } else {
                    element.due_days = event.target.value;
                }
            }
            return element;
        });

        setLayoutItems(temp);
    };
    const submit = () => {
        seterrormsg(false);
        const requestObj = {
            json: [],
        };
        layoutItems.map((item) => {
            if (item?.due_days == "" || item?.due_days == "0") {
                seterrormsg({
                    message: "Due Days can't be  empty",
                });
                return false;
            }
            const passItem = {
                id: item?.id,
                task_priority: item?.task_priority,
                due_days: parseInt(item?.due_days),
                isdefault: item?.isdefault,
            };
            requestObj.json.push(passItem);
        });
        if (requestObj?.json.length == 4) {
            dispatch(postPriorityConfig(requestObj));
        }
    };
    if (requestComplete || (requestComplete && !data.status) || (error && !data?.status)) {
        setTimeout(function () {
            dispatch({ type: POST_PRIORITY_CONFIG_RESET });
        }, 2000);
    }
    return (
        <>
            {requestComplete && data?.status && <SuccessMessageBox success={succesMsg} />}
            {requestComplete && !data?.status && <MessageBox error={errormsg} />}
            {error && !data?.status && <MessageBox error={errormsg} />}
            <Layout>
                <Main>
                    <div>
                        <h4 className={`text-2xl font-interSb text-${currentMode}-copy`}>Priority Configuration</h4>
                    </div>

                    <Card className="p-[30px] rounded-t-[10px] rounded-b-[0] border-b-0 xl:h-[calc(100vh_-_18rem)] h-[calc(100vh_-_23rem)] overflow-y-auto mt-5">
                        <h5 className="font-interM text-lg mb-1">Set priority due days</h5>
                        <p className="text-xs mb-[30px]">Set preferences for task creation.</p>
                        {getConfig?.fetching || requestComplete ? (
                            <Loader className="h-[calc(100vh_-_19rem)] " />
                        ) : (
                            <table
                                className={`xl:w-[50%] w-full  border-${currentMode}-700 !border-[1px] rounded-[8px] rolemasterTable priority text-center`}
                            >
                                <thead className={`bg-${currentMode}-450`}>
                                    <tr>
                                        <th>Priority</th>
                                        <th>Due Days (in days)</th>
                                        <th>Set Default</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {layoutItems &&
                                        layoutItems?.map((item) => {
                                            return (
                                                <tr className={`border-b border-${currentMode}-700`}>
                                                    <td>
                                                        <Input
                                                            className={`border-[rgba(85,85,85,0.4)]  border-[1px] rounded-md w-[120px] h-[40px] text-[14px] text-normal text-left pl-4 color-[rgba(85,85,85,1)]  bg-${currentMode}-810 text-${currentMode}-copy`}
                                                            value={item?.name}
                                                            disabled={false}
                                                            maxLength={9}
                                                            tabIndex="1"
                                                            type="text"
                                                            readOnly={true}
                                                        />
                                                    </td>
                                                    <td>
                                                        <Input
                                                            className={`border-[rgba(85,85,85,0.4)]  border-[1px] rounded-md w-[50px] h-[40px] text-[14px] text-center color-[rgba(85,85,85,1)]  bg-${currentMode}-810 text-${currentMode}-copy`}
                                                            value={item?.due_days != null ? item?.due_days : " "}
                                                            tabIndex="2"
                                                            onChange={(event) => {
                                                                event.target.value = Math.max(0, parseInt(event.target.value)).toString().slice(0, 2);
                                                                inputChange(
                                                                    {
                                                                        target: {
                                                                            name: event.target.name,
                                                                            value: event.target.value,
                                                                        },
                                                                    },
                                                                    item?.id
                                                                );
                                                            }}
                                                            disabled={false}
                                                            type="text"
                                                        />
                                                    </td>
                                                    <td>
                                                        <Radio
                                                            tabIndex="3"
                                                            id={item?.id}
                                                            checked={item?.isdefault}
                                                            sx={{
                                                                color: "rgba(85,85,85,0.7)",

                                                                "&.Mui-checked": {
                                                                    color: "#00B399",
                                                                },
                                                            }}
                                                            onClick={(event) => {
                                                                MandatoryCheckboxChange(
                                                                    {
                                                                        target: {
                                                                            name: event.target.name,
                                                                            value: event.target.checked,
                                                                        },
                                                                    },
                                                                    item?.id
                                                                );
                                                            }}
                                                            name="radio-buttons"
                                                            inputProps={{
                                                                "aria-label": "A",
                                                            }}
                                                        />
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                </tbody>
                            </table>
                        )}
                        {errormsgs?.message && <p className="text-light-50 block text-l mt-8">{errormsgs?.message}</p>}
                    </Card>
                    <div
                        className={`bg-${currentMode}-card text-${currentMode}-copy border-${currentMode}-700 py-[20px] space-x-2 px-[30px] sticky left-0 right-0 shadow-[0px_4px_20px_rgba(85,85,85,0.2)] border-[1px] border-[#dfe1e28f] rounded-b-[10px]`}
                    >
                        <Button
                            title="Save"
                            className="bg-light-20 text-white text-base rounded-[8px] py-2 px-7"
                            onClick={submit}
                            tabIndex="4"
                            disabled={radioDisabled}
                        >
                            Save
                        </Button>
                        <Link
                            title="Cancel"
                            to="/admin-setting"
                            tabIndex="5"
                        >
                            <Button className="bg-light-240 text-light-40 text-base rounded-[8px] py-2 px-7">
                            Cancel
                            </Button>
                        </Link>
                    </div>
                </Main>
                <Footer />
            </Layout>
        </>
    );
};
export default PriorityConfiguration;
