import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { loginRequest } from "../../redux/actions/auth";
import Container from "../../components/UI/Container";
import { decryptId, encryptId, getOS, getSystemInfo } from "../../utils/helpers";
import Loader from "../../components/Loader";
import { fnBrowserDetect } from "../../utils/helpers";
import { fetchToken } from "../../components/Firebase";
import { v4 as uuidv4 } from "uuid";

const OAuthLogin = () => {
    const navigate = useNavigate();
    let [searchParams, setSearchParams] = useSearchParams();
    const dispatch = useDispatch();
    const { data, fetching, error, requestComplete } = useSelector((state) => state?.loginState);
    const ifOuth = searchParams.get("user");
    if (error !== "" && ifOuth) {
        navigate("/Oauth-error");
    }
    useEffect(() => {
        const Result = async () => {
            let secret = localStorage.getItem("secretId");
            let uniqueId = uuidv4();
            if (secret === null) {
                localStorage.setItem("secretId", encryptId(uniqueId));
            }
            const fcmToken = await fetchToken();
            var date = new Date();
            const offset = date.getTimezoneOffset();
            const offsets = String(offset);
            const result = offsets?.replace("-", "+");
            let systemInfo = new Object();
            systemInfo.info = null; //await getSystemInfo()
            systemInfo.browser = await fnBrowserDetect();
            systemInfo.os = await getOS();
            systemInfo.secretid = secret === null ? uniqueId : decryptId(secret);
            let oauthUser = searchParams.get("user");
            const logType = searchParams.get("type");
            if (oauthUser) {
                oauthUser = oauthUser.replaceAll(" ", "+");
                dispatch(
                    loginRequest({
                        emailaddress: oauthUser,
                        password: "",
                        logintype: +logType,
                        fcm_token: fcmToken,
                        logininfo: JSON.stringify(systemInfo),
                        timezone: result,
                    })
                );
            }
        };
        Result();
    }, [dispatch]);
    const menuaItems = process.env?.REACT_APP_MENU_LIST;
    const menuList = JSON.parse(menuaItems);
    const menuName = menuList.find((item) => item.id == data?.allowed_menu);
    if (requestComplete) {
        if (menuName) {
            localStorage.setItem("defaultLogin", menuName.name);
            navigate(menuName.name);
        } else {
            navigate("/dashboard");
        }
    }

    return (
        <div className="WrapCenter">
            <Container className="mt-10">
                <Link to={"//www.myiq.ai/"} target="_blank">
                    <img className="w-[121px] h-[75px]" src="/images/logo/MyiqFinal.svg" alt="logoiq" />
                </Link>
                <div className="flex justify-center items-center h-[calc(80vh_-_120px)]">
                    <div className="basis-1/2 2xl:basis-full text-center">
                        <Loader className="h-[calc(100vh_-_15rem)]" />
                    </div>
                </div>
            </Container>
        </div>
    );
};

export default OAuthLogin;