import {
    ADD_REPORTS_FAV_FAILURE,
    ADD_REPORTS_FAV_REQUEST,
    ADD_REPORTS_FAV_RESET,
    ADD_REPORTS_FAV_SUCCESS,
    GET_REPORTS_ACCRUAL_FAILURE,
    GET_REPORTS_ACCRUAL_REQUEST,
    GET_REPORTS_ACCRUAL_RESET,
    GET_REPORTS_ACCRUAL_SUCCESS,
    GET_REPORTS_AGEING_FAILURE,
    GET_REPORTS_AGEING_REQUEST,
    GET_REPORTS_AGEING_RESET,
    GET_REPORTS_AGEING_SUCCESS,
    GET_REPORTS_AGEING_SUMMARY_FAILURE,
    GET_REPORTS_AGEING_SUMMARY_REQUEST,
    GET_REPORTS_AGEING_SUMMARY_RESET,
    GET_REPORTS_AGEING_SUMMARY_SUCCESS,
    GET_REPORTS_COUNT_FAILURE,
    GET_REPORTS_COUNT_REQUEST,
    GET_REPORTS_COUNT_RESET,
    GET_REPORTS_COUNT_SUCCESS,
    GET_REPORTS_FAV_FAILURE,
    GET_REPORTS_FAV_REQUEST,
    GET_REPORTS_FAV_RESET,
    GET_REPORTS_FAV_SUCCESS,
    GET_REPORTS_LIST_FAILURE,
    GET_REPORTS_LIST_REQUEST,
    GET_REPORTS_LIST_RESET,
    GET_REPORTS_LIST_SUCCESS,
    REPORTS_AGEING_COUNT_FAILURE,
    REPORTS_AGEING_COUNT_REQUEST,
    REPORTS_AGEING_COUNT_RESET,
    REPORTS_AGEING_COUNT_SUCCESS,
    GET_SUMMARY_AGING_SUCCESS,
    GET_SUMMARY_AGING_FAILURE,
    GET_SUMMARY_AGING_REQUEST,
    GET_SUMMARY_AGING_RESET,
    GET_TASK_REPORT_COUNT_REQUEST,
    GET_TASK_REPORT_COUNT_SUCCESS,
    GET_TASK_REPORT_COUNT_RESET,
    GET_TASK_REPORT_COUNT_FAILURE,
    GET_TASK_REPORT_LIST_REQUEST,
    GET_TASK_REPORT_LIST_SUCCESS,
    GET_TASK_REPORT_LIST_RESET,
    GET_TASK_REPORT_LIST_FAILURE,
    GET_TASK_REPORTS_CREATEDBYUSER_REQUEST,
    GET_TASK_REPORTS_CREATEDBYUSER_FAILURE,
    GET_TASK_REPORTS_CREATEDBYUSER_SUCCESS,
    GET_TASK_REPORTS_CREATEDBYUSER_RESET,
    GET_LICENSE_SUMMARY_FAILURE,
    GET_LICENSE_SUMMARY_REQUEST,
    GET_LICENSE_SUMMARY_SUCCESS,
    GET_LICENSE_SUMMARY_RESET,
    GET_LICENSE_USAGE_FAILURE,
    GET_LICENSE_USAGE_REQUEST,
    GET_LICENSE_USAGE_RESET,
    GET_LICENSE_USAGE_SUCCESS,
    GET_LICENSE_REPORT_LIST_REQUEST,
    GET_LICENSE_REPORT_LIST_FAILURE,
    GET_LICENSE_REPORT_LIST_RESET,
    GET_LICENSE_REPORT_LIST_SUCCESS,
    GET_LICENSE_REPORT_COUNT_SUCCESS,
    GET_LICENSE_REPORT_COUNT_REQUEST,
    GET_LICENSE_REPORT_COUNT_RESET,
    GET_LICENSE_REPORT_COUNT_FAILURE,
    EXPORT_INVOICE_REPORTS_SUCCESS,
    EXPORT_AGEING_REPORTS_SUCCESS,
    EXPORT_TASK_REPORTS_SUCCESS,
    EXPORT_BILLING_REPORTS_SUCCESS,
    SEND_BILLING_MAIL_REQUEST,
    SEND_BILLING_MAIL_SUCCESS,
    SEND_BILLING_MAIL_FAILURE,
    SERVER_TARGET_TYPE_RESET,
    SEND_BILLING_MAIL_RESET,
    GET_ADVANCE_REPORTS_REQUEST,
    GET_ADVANCE_REPORTS_SUCCESS,
    GET_ADVANCE_REPORTS_RESET,
    GET_ADVANCE_REPORTS_FAILURE,
    EXPORT_ADVANCE_REPORT_LIST_SUCCESS,
    EXPORT_ADVANCE_REPORT_LIST_RESET
} from "../types/types";

const getReportsListState = {
    dataList: [],
    exportData: [],
    fetching: false,
    requestComplete: false,
    error: "",
};

export function ReportsListItem(state = getReportsListState, action) {
    switch (action.type) {
        case GET_REPORTS_LIST_REQUEST:
            return {
                ...state,
                fetching: true,
                requestComplete: false,
                error: "",
            };
        case GET_REPORTS_LIST_SUCCESS:
            return {
                dataList: action.payload,
                fetching: false,
                requestComplete: true,
                error: "",
            };
        case EXPORT_INVOICE_REPORTS_SUCCESS:
            return {
                ...state,
                exportData: action.payload,
                fetching: false,
                requestComplete: true,
                error: "",
            };
        case GET_REPORTS_LIST_RESET:
            return {
                dataList: [],
                fetching: false,
                requestComplete: false,
                error: "",
            };
        case GET_REPORTS_LIST_FAILURE:
            return {
                fetching: false,
                requestComplete: false,
                error: action.payload,
            };
        default:
            return state;
    }
}

const getReportCountState = {
    data: [],
    fetching: false,
    requestComplete: false,
    error: "",
};
export function reportsCount(state = getReportCountState, action) {
    switch (action.type) {
        case GET_REPORTS_COUNT_REQUEST:
            return {
                ...state,
                fetching: true,
                requestComplete: false,
                error: "",
            };
        case GET_REPORTS_COUNT_SUCCESS:
            return {
                data: action.payload,
                fetching: false,
                requestComplete: true,
                error: "",
            };
        case GET_REPORTS_COUNT_RESET:
            return {
                data: [],
                fetching: false,
                requestComplete: false,
                error: "",
            };
        case GET_REPORTS_COUNT_FAILURE:
            return {
                fetching: false,
                requestComplete: false,
                error: action.payload,
            };
        default:
            return state;
    }
}

const getReportAgeingCountState = {
    data: [],
    fetching: false,
    requestComplete: false,
    error: "",
};
export function reportsAgeingCount(state = getReportAgeingCountState, action) {
    switch (action.type) {
        case REPORTS_AGEING_COUNT_REQUEST:
            return {
                ...state,
                fetching: true,
                requestComplete: false,
                error: "",
            };
        case REPORTS_AGEING_COUNT_SUCCESS:
            return {
                data: action.payload,
                fetching: false,
                requestComplete: true,
                error: "",
            };
        case REPORTS_AGEING_COUNT_RESET:
            return {
                data: [],
                fetching: false,
                requestComplete: false,
                error: "",
            };
        case REPORTS_AGEING_COUNT_FAILURE:
            return {
                fetching: false,
                requestComplete: false,
                error: action.payload,
            };
        default:
            return state;
    }
}

const getReportsAgeingState = {
    dataList: [],
    exportData: [],
    fetching: false,
    requestComplete: false,
    error: "",
};

export function ReportsListAgeingItem(state = getReportsAgeingState, action) {
    switch (action.type) {
        case GET_REPORTS_AGEING_REQUEST:
            return {
                ...state,
                fetching: true,
                requestComplete: false,
                error: "",
            };
        case GET_REPORTS_AGEING_SUCCESS:
            return {
                dataList: action.payload,
                fetching: false,
                requestComplete: true,
                error: "",
            };
        case EXPORT_AGEING_REPORTS_SUCCESS:
            return {
                ...state,
                exportData: action.payload,
                fetching: false,
                requestComplete: true,
                error: "",
            };
        case GET_REPORTS_AGEING_RESET:
            return {
                dataList: [],
                fetching: false,
                requestComplete: false,
                error: "",
            };
        case GET_REPORTS_AGEING_FAILURE:
            return {
                fetching: false,
                requestComplete: false,
                error: action.payload,
            };
        default:
            return state;
    }
}

const getReportsAgeingSummaryState = {
    dataList: [],
    fetching: false,
    requestComplete: false,
    error: "",
};

export function ReportsListAgeingSummaryItem(
    state = getReportsAgeingSummaryState,
    action
) {
    switch (action.type) {
        case GET_REPORTS_AGEING_SUMMARY_REQUEST:
            return {
                ...state,
                fetching: true,
                requestComplete: false,
                error: "",
            };
        case GET_REPORTS_AGEING_SUMMARY_SUCCESS:
            return {
                dataList: action.payload,
                fetching: false,
                requestComplete: true,
                error: "",
            };
        case GET_REPORTS_AGEING_SUMMARY_RESET:
            return {
                dataList: [],
                fetching: false,
                requestComplete: false,
                error: "",
            };
        case GET_REPORTS_AGEING_SUMMARY_FAILURE:
            return {
                fetching: false,
                requestComplete: false,
                error: action.payload,
            };
        default:
            return state;
    }
}

const getReportsAccrualState = {
    dataList: [],
    exportData: [],
    fetching: false,
    requestComplete: false,
    error: "",
};

export function ReportsListAccrualItem(state = getReportsAccrualState, action) {
    switch (action.type) {
        case GET_REPORTS_ACCRUAL_REQUEST:
            return {
                ...state,
                fetching: true,
                requestComplete: false,
                error: "",
            };
        case GET_REPORTS_ACCRUAL_SUCCESS:
            return {
                dataList: action.payload,
                fetching: false,
                requestComplete: true,
                error: "",
            };
        case GET_REPORTS_ACCRUAL_RESET:
            return {
                dataList: [],
                fetching: false,
                requestComplete: false,
                error: "",
            };
        case GET_REPORTS_ACCRUAL_FAILURE:
            return {
                fetching: false,
                requestComplete: false,
                error: action.payload,
            };
        default:
            return state;
    }
}

const getReportsFavState = {
    dataList: [],
    fetching: false,
    requestComplete: false,
    error: "",
};

export function ReportsFavGet(state = getReportsFavState, action) {
    switch (action.type) {
        case GET_REPORTS_FAV_REQUEST:
            return {
                ...state,
                fetching: true,
                requestComplete: false,
                error: "",
            };
        case GET_REPORTS_FAV_SUCCESS:
            return {
                dataList: action.payload,
                fetching: false,
                requestComplete: true,
                error: "",
            };
        case GET_REPORTS_FAV_RESET:
            return {
                dataList: [],
                fetching: false,
                requestComplete: false,
                error: "",
            };
        case GET_REPORTS_FAV_FAILURE:
            return {
                fetching: false,
                requestComplete: false,
                error: action.payload,
            };
        default:
            return state;
    }
}

const addReportsFavState = {
    dataList: [],
    fetching: false,
    requestComplete: false,
    error: "",
};

export function ReportsFavAdd(state = addReportsFavState, action) {
    switch (action.type) {
        case ADD_REPORTS_FAV_REQUEST:
            return {
                ...state,
                fetching: true,
                requestComplete: false,
                error: "",
            };
        case ADD_REPORTS_FAV_SUCCESS:
            return {
                dataList: action.payload,
                fetching: false,
                requestComplete: true,
                error: "",
            };
        case ADD_REPORTS_FAV_RESET:
            return {
                dataList: [],
                fetching: false,
                requestComplete: false,
                error: "",
            };
        case ADD_REPORTS_FAV_FAILURE:
            return {
                fetching: false,
                requestComplete: false,
                error: action.payload,
            };
        default:
            return state;
    }
}

const getSummaryAgingListState = {
    dataList: [],
    fetching: false,
    requestComplete: false,
    error: "",
};

export function GetSummaryAgingItem(state = getSummaryAgingListState, action) {
    switch (action.type) {
        case GET_SUMMARY_AGING_REQUEST:
            return {
                ...state,
                fetching: true,
                requestComplete: false,
                error: "",
            };
        case GET_SUMMARY_AGING_SUCCESS:
            return {
                dataList: action.payload,
                fetching: false,
                requestComplete: true,
                error: "",
            };
        case GET_SUMMARY_AGING_RESET:
            return {
                dataList: [],
                fetching: false,
                requestComplete: false,
                error: "",
            };
        case GET_SUMMARY_AGING_FAILURE:
            return {
                fetching: false,
                requestComplete: false,
                error: action.payload,
            };
        default:
            return state;
    }
}

//tsak report count
const getTaskReportCountState = {
    data: [],
    fetching: false,
    requestComplete: false,
    error: "",
};
export function reportsTaskCount(state = getTaskReportCountState, action) {
    switch (action.type) {
        case GET_TASK_REPORT_COUNT_REQUEST:
            return {
                ...state,
                fetching: true,
                requestComplete: false,
                error: "",
            };
        case GET_TASK_REPORT_COUNT_SUCCESS:
            return {
                data: action.payload,
                fetching: false,
                requestComplete: true,
                error: "",
            };
        case GET_TASK_REPORT_COUNT_RESET:
            return {
                data: [],
                fetching: false,
                requestComplete: false,
                error: "",
            };
        case GET_TASK_REPORT_COUNT_FAILURE:
            return {
                fetching: false,
                requestComplete: false,
                error: action.payload,
            };
        default:
            return state;
    }
}

const getReportsTaskState = {
    dataList: [],
    exportData: [],
    fetching: false,
    requestComplete: false,
    error: "",
};

export function ReportsTaskList(state = getReportsTaskState, action) {
    switch (action.type) {
        case GET_TASK_REPORT_LIST_REQUEST:
            return {
                ...state,
                fetching: true,
                requestComplete: false,
                error: "",
            };
        case GET_TASK_REPORT_LIST_SUCCESS:
            return {
                dataList: action.payload,
                fetching: false,
                requestComplete: true,
                error: "",
            };
        case EXPORT_TASK_REPORTS_SUCCESS:
            return {
                ...state,
                exportData: action.payload,
                fetching: false,
                requestComplete: true,
                error: "",
            };
        case GET_TASK_REPORT_LIST_RESET:
            return {
                dataList: [],
                fetching: false,
                requestComplete: false,
                error: "",
            };
        case GET_TASK_REPORT_LIST_FAILURE:
            return {
                fetching: false,
                requestComplete: false,
                error: action.payload,
            };
        default:
            return state;
    }
}
//GET_TASK REPORT CREATED BY USER
const getReportsTaskUserState = {
    dataList: [],
    fetching: false,
    requestComplete: false,
    error: "",
};

export function ReportsTaskUserList(state = getReportsTaskUserState, action) {
    switch (action.type) {
        case GET_TASK_REPORTS_CREATEDBYUSER_REQUEST:
            return {
                ...state,
                fetching: true,
                requestComplete: false,
                error: "",
            };
        case GET_TASK_REPORTS_CREATEDBYUSER_SUCCESS:
            return {
                dataList: action.payload,
                fetching: false,
                requestComplete: true,
                error: "",
            };
        case GET_TASK_REPORTS_CREATEDBYUSER_RESET:
            return {
                dataList: [],
                fetching: false,
                requestComplete: false,
                error: "",
            };
        case GET_TASK_REPORTS_CREATEDBYUSER_FAILURE:
            return {
                fetching: false,
                requestComplete: false,
                error: action.payload,
            };
        default:
            return state;
    }
}
//GET License Summary

const getLicenseSummaryState = {
    dataList: [],

    fetching: false,

    requestComplete: false,

    error: "",
};

export function getLicenseSummary(state = getLicenseSummaryState, action) {
    switch (action.type) {
        case GET_LICENSE_SUMMARY_REQUEST:
            return {
                ...state,

                fetching: true,

                requestComplete: false,

                error: "",
            };

        case GET_LICENSE_SUMMARY_SUCCESS:
            return {
                dataList: action.payload,

                fetching: false,

                requestComplete: true,

                error: "",
            };

        case GET_LICENSE_SUMMARY_RESET:
            return {
                dataList: [],

                fetching: false,

                requestComplete: false,

                error: "",
            };

        case GET_LICENSE_SUMMARY_FAILURE:
            return {
                fetching: false,

                requestComplete: false,

                error: action.payload,
            };

        default:
            return state;
    }
}

//GET License Summary

const getLicenseUsageState = {
    dataList: [],

    fetching: false,

    requestComplete: false,

    error: "",
};

export function getLicenseUsage(state = getLicenseUsageState, action) {
    switch (action.type) {
        case GET_LICENSE_USAGE_REQUEST:
            return {
                ...state,

                fetching: true,

                requestComplete: false,

                error: "",
            };

        case GET_LICENSE_USAGE_SUCCESS:
            return {
                dataList: action.payload,

                fetching: false,

                requestComplete: true,

                error: "",
            };

        case GET_LICENSE_USAGE_RESET:
            return {
                dataList: [],

                fetching: false,

                requestComplete: false,

                error: "",
            };

        case GET_LICENSE_USAGE_FAILURE:
            return {
                fetching: false,

                requestComplete: false,

                error: action.payload,
            };

        default:
            return state;
    }
}

const getLicenseReportCountState = {
    data: [],
    fetching: false,
    requestComplete: false,
    error: "",
};
export function reportsLicenseCount(
    state = getLicenseReportCountState,
    action
) {
    switch (action.type) {
        case GET_LICENSE_REPORT_COUNT_REQUEST:
            return {
                ...state,
                fetching: true,
                requestComplete: false,
                error: "",
            };
        case GET_LICENSE_REPORT_COUNT_SUCCESS:
            return {
                data: action.payload,
                fetching: false,
                requestComplete: true,
                error: "",
            };
        case GET_LICENSE_REPORT_COUNT_RESET:
            return {
                data: [],
                fetching: false,
                requestComplete: false,
                error: "",
            };
        case GET_LICENSE_REPORT_COUNT_FAILURE:
            return {
                fetching: false,
                requestComplete: false,
                error: action.payload,
            };
        default:
            return state;
    }
}

const getReportsLicenseState = {
    dataList: [],
    ExportData: [],
    fetching: false,
    requestComplete: false,
    error: "",
};

export function ReportsLicenseList(state = getReportsLicenseState, action) {
    switch (action.type) {
        case GET_LICENSE_REPORT_LIST_REQUEST:
            return {
                ...state,
                fetching: true,
                requestComplete: false,
                error: "",
            };
        case GET_LICENSE_REPORT_LIST_SUCCESS:
            return {
                dataList: action.payload,
                fetching: false,
                requestComplete: true,
                error: "",
            };
        case EXPORT_BILLING_REPORTS_SUCCESS:
            return {
                ...state,
                exportData: action.payload,
                fetching: false,
                requestComplete: true,
                error: "",
            };
        case GET_LICENSE_REPORT_LIST_RESET:
            return {
                dataList: [],
                fetching: false,
                requestComplete: false,
                error: "",
            };
        case GET_LICENSE_REPORT_LIST_FAILURE:
            return {
                fetching: false,
                requestComplete: false,
                error: action.payload,
            };
        default:
            return state;
    }
}

const getEmailSentState = {
    dataList: [],
    fetching: false,
    requestComplete: false,
    error: "",
};

export function sendBillingEmail(state = getEmailSentState, action) {
    switch (action.type) {
        case SEND_BILLING_MAIL_REQUEST:
            return {
                ...state,
                fetching: true,
                requestComplete: false,
                error: "",
            };
        case SEND_BILLING_MAIL_SUCCESS:
            return {
                dataList: action.payload,
                fetching: false,
                requestComplete: true,
                error: "",
            };

        case SEND_BILLING_MAIL_RESET:
            return {
                dataList: [],
                fetching: false,
                requestComplete: false,
                error: "",
            };
        case SEND_BILLING_MAIL_FAILURE:
            return {
                fetching: false,
                requestComplete: false,
                error: action.payload,
            };
        default:
            return state;
    }
}


const getAdvanceRequestReport = {
    data: [],
    fetching: false,
    requestComplete: false,
    error: "",
    exportData: []
};
export function getAdvanceReport(state = getAdvanceRequestReport, action) {

    switch (action.type) {
        case GET_ADVANCE_REPORTS_REQUEST:
            return {
                ...state,
                fetching: true,
                requestComplete: false,
                error: "",
            };
        case GET_ADVANCE_REPORTS_SUCCESS:
            return {
                data: action?.payload,
                fetching: false,
                requestComplete: true,
                error: "",
            };
        case EXPORT_ADVANCE_REPORT_LIST_SUCCESS:
            return {
                ...state,
                exportData: action.payload,
                fetching: false,
                requestComplete: true,
                error: "",
            };
        case EXPORT_ADVANCE_REPORT_LIST_RESET:
            return {
                ...state,
                exportData: [],
                fetching: false,
                requestComplete: false,
                error: "",
            };

        case GET_ADVANCE_REPORTS_RESET:
            return {
                data: [],
                fetching: false,
                requestComplete: false,
                error: "",
            };
        case GET_ADVANCE_REPORTS_FAILURE:
            return {
                fetching: false,
                requestComplete: false,
                error: action.payload,
            };
        default:
            return state;
    }
};