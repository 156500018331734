import { FormControl, MenuItem, Select } from "@mui/material";
import { useFormik } from "formik";
import { useContext, useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Button from "../../../components/Button";
import Card from "../../../components/UI/Card";
import Main from "../../../components/UI/Main";
import Layout from "../../../Hoc/Layout";
import { ThemeContext } from "../../../Hooks/Themes/Themeprovider";
import Footer from "../../../organisms/Footer";
import { decryptId, encryptId, isEmailregex, SelectDropdwnImg, showSwal, showValidationError } from "../../../utils/helpers";
import { ColumnTble } from "./ColumnTble";
import moment from "moment";
import { useDispatch } from "react-redux";
import { GetColumnsList, GetDropdownList, GetViewTablerList, postTablerdata } from "../../../redux/actions/output-management";
import { useSelector } from "react-redux";
import { GET_PREVIEW_DATA_RESET, POST_TABLER_DATA_RESET } from "../../../redux/types/types";
import { storeReportDetail } from "../../../redux/actions/filter";
import Loader from "../../../components/Loader";
import { SchedulerType } from "./ScheduledType";
import { JsonTble } from "./JsonTable";
import SuccessMessageBox from "../../../components/UI/Alert/sucess";
import MessageBox from "../../../components/UI/Alert";
import _ from "lodash";
import { XmlTable } from "./XmlTable";
import { validateApiForm, validateEmailForm, validateFtpForm } from "../../../Hooks/validateschema";
import Swal from "sweetalert2";

let dropp = [];
export const ScheduledOutput = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const params = new URLSearchParams(window.location.search);
    let ID = params.get("id"); // If View Page this get actual Id new add page id is "0"
    let TypeFrom = params.get("dataset"); //This is if 1:Invoice Reports 2:Task Reports 3:Accrual Reports
    let ReportType = params.get("type"); //This is the if "1" Custom Output or "2" Scheduled Output...
    let previewType = params.get("prev"); //This is if 1:Tabuler 2:Matrix 3:Charts 4:Json 5:Xml
    let ReportName = params.get("name");

    const OrgId = localStorage.getItem("orgId");
    useEffect(() => {
        if (ID) {
            dispatch(GetViewTablerList(ID));
        }
        dispatch(GetDropdownList(OrgId));
        if (ID == null) {
            dispatch({ type: "TOGGLE_CHECKBOX_RESET" });
        } //if user in the edit page directly click the sidebar report link  to reset the that!
    }, [dispatch]);
    const { currentMode } = useContext(ThemeContext);
    const { fetching: fetch, datas } = useSelector((state) => state?.getTablerViewState);
    const { fetching, dropdown } = useSelector((state) => state?.getDropdownReportState);
    const {
        droppedValues,
        subTtalValues,
        sortingValues,
        morefilterValues,
        viewData,
        fieldValues,
        labelValues,
        matrixColValues,
        rowsValues,
        jsonData,
        xmlData,
    } = useSelector((state) => state?.droppedState);

    const currentMonth = moment().format("M");
    const currentDay = moment().format("d");
    const currentdate = moment().format("DD");
    const dayWithoutLeadingZero = parseInt(currentdate, 10);
    let DeliveryOptObj;

    const InitialObj = {
        report_from: "",
        delivery_format: 0, //""
        frequency: previewType == 4 || previewType == 5 ? 1 : 0, //""
        delivery_opt: 0, //""
        events_types: 0,
        message: "",
        type: viewData?.time_based?.[0]?.type || 0,
        day: dayWithoutLeadingZero,
        week: [+currentDay + 1],
        on: 1,
        startdate: new Date(),
        enddate: null,
        scheduled_time: new Date(),
        on_starting: 1,
        day_select: +currentDay + 1,
        on_month: currentMonth,
        selectedOption: "option1",
        chart_type: 0,
    };

    const { data, fetching: AddFetch, requestComplete: Addrequest, error } = useSelector((state) => state?.getRespondAddTableState);
    const { Reprt } = useSelector((state) => state?.storeReportState);
    const { storeFilter } = useSelector((state) => state?.checkFilterState);
    const [toInput, setToInput] = useState("");
    const [ccInput, setCcInput] = useState("");
    const [bccInput, setBccInput] = useState("");
    const formik = useFormik({
        initialValues: InitialObj,
        onSubmit: (values) => {
            // Handle form submission here
            const hasNumericType = rowsValues?.some((item) => item?.data_type === "n" || item?.data_type === "m" || item?.data_type === "p");
            const numericTypes = rowsValues?.filter((item) => item.data_type === "n" || item.data_type === "m" || item?.data_type === "p");
            const filteredData = numericTypes?.filter((item) => item?.Agg !== null);
            const firstColumnNames = subTtalValues?.map((obj) => obj?.column_name);
            const filteredSecondArray = _.filter(droppedValues, (obj) => firstColumnNames?.includes(obj?.column_name));
            const checkSubtlAgg = filteredSecondArray?.filter((item) => item?.Agg == null);
            const validationErrors = validateApiForm(values);
            const validateEMmail = validateEmailForm(values, toInput);
            const IsDragged = jsonData?.some((item) => typeof item?.content === "object");
            const IsEmptyKey = jsonData?.some((item) => item?.label === "");
            if (previewType != 3 && previewType != 2 && previewType != 4 && previewType != 5 && droppedValues?.length < 1) {
                showSwal(`Select at least one column to proceed!`);
            } else if ((previewType == 1 || previewType == 2) && subTtalValues?.length > 0 && checkSubtlAgg?.length !== 0) {
                showSwal(`Add the aggregate function applicable to the ${checkSubtlAgg?.map((el) => ` ${el?.field_display_name}`)} columns!`);
                return false;
            } else if (previewType == 2 && (matrixColValues?.length < 1 || rowsValues?.length < 1)) {
                showSwal(`Select at least one Columns/Rows to proceed!`);
            } else if (previewType == 2 && rowsValues?.length < 2) {
                showSwal(`Select at least two rows to proceed!`);
            } else if (previewType == 2 && !hasNumericType) {
                showSwal(`Select at least one number type rows to proceed!`);
            } else if (previewType == 2 && filteredData?.length == 0) {
                showSwal(`Select the aggregate function for the ${numericTypes?.[0]?.field_display_name}!`);
                return false;
            } else if (previewType == 2 && numericTypes?.length > 1) {
                showSwal(`Only one number type rows has to be selected!`);
                return false;
            } else if (previewType == 3 && (fieldValues?.length < 1 || labelValues?.length < 1)) {
                showSwal(`Select at least one Field/Label to proceed!`);
            } else if (previewType != 4 && previewType != 5 && storeFilter?.length == 0) {
                showSwal(`Select at least one filter to proceed!`);
            } else if (previewType != 4 && previewType != 5 && ReportType == 2 && values?.delivery_format < 1 && values?.events_types < 1) {
                showSwal("Delivery format is mandatory!");
            } else if (previewType == 4 && IsEmptyKey) {
                showSwal("Key values should not be empty!");
            } else if (previewType == 4 && !IsDragged) {
                showSwal("Drag and drop at least one field in the content label!");
            } else if (ReportType == 2 && values?.delivery_opt < 1) {
                showSwal("Delivery option is mandatory!");
            } else if (
                ReportType == 2 &&
                (values?.delivery_opt == 3 || values?.delivery_opt == 4) &&
                Object.keys(validateFtpForm(values))?.length != 0
            ) {
                showValidationError(Object?.values(validateFtpForm(values)), "Delivery Option");
            } else if (ReportType == 2 && values?.delivery_opt == 2 && (!email || !folderName)) {
                showSwal(`Drive details is mandatory`);
            } else if (ReportType == 2 && values?.delivery_opt == 1 && Object.keys(validateEMmail)?.length != 0) {
                showValidationError(Object?.values(validateEMmail), "Delivery Option");
            } else if (ReportType == 2 && values?.frequency < 1) {
                showSwal("Frequency is mandatory!");
            } else if (ReportType == 2 && values?.frequency == 1 && values?.events_types == 0) {
                showSwal("Select at least one event type to proceed!");
            } else if (
                ReportType == 2 &&
                values?.delivery_opt == 5 &&
                Object.keys(validationErrors)?.length != 0 &&
                (previewType == 4 || previewType == 5)
            ) {
                showValidationError(Object?.values(validationErrors), "Delivery Option");
                return false;
            } else {
                if (values?.delivery_opt == 1) {
                    const invalidEmail = !isEmailregex.test(toInput);
                    const invalidccEmail = !isEmailregex.test(ccInput);
                    const invalidBccEmail = !isEmailregex.test(bccInput);
                    if ((toInput !== "" && invalidEmail) || (ccInput !== "" && invalidccEmail) || (bccInput !== "" && invalidBccEmail)) {
                        showSwal("Invalid email address!");
                        return false;
                    } // check To,Cc,Bcc emails are valid email!...
                    DeliveryOptObj = {
                        to: [...(values?.delivery?.to || []), !invalidEmail && toInput !== "" ? toInput : ``], //This one is if user not hit enter on typed the correct email that email is valid insert to the array funct same as Cc abd Bcc
                        cc: [...(values?.delivery?.cc || []), !invalidccEmail && ccInput !== "" ? ccInput : ``],
                        bcc: [...(values?.delivery?.bcc || []), !invalidBccEmail && bccInput !== "" ? bccInput : ``],
                        message: values?.delivery?.message,
                        subject: values?.delivery?.subject,
                    };
                    // DeliveryOptObj = values?.delivery;
                } else if (values?.delivery_opt == 3 || values?.delivery_opt == 4) {
                    DeliveryOptObj = {
                        host: values?.delivery?.host,
                        port: values?.delivery?.port,
                        user: encryptId(values?.delivery?.user),
                        password: encryptId(values?.delivery?.password),
                    };
                } else if (values?.delivery_opt == 2) {
                    DeliveryOptObj = {
                        accessToken: profile?.access_token,
                        refreshToken: profile?.refresh_token,
                        expiry: profile?.exp,
                        email: email,
                        foldername: folderName,
                        oauth: oAuth,
                    };
                } else if (values?.delivery_opt == 5) {
                    if (values?.delivery?.authentic == 2) {
                        DeliveryOptObj = {
                            username: encryptId(values?.delivery?.username),
                            apipasword: encryptId(values?.delivery?.apipasword),
                            url: values?.delivery?.url,
                            authentic: values?.delivery?.authentic,
                        };
                    } else {
                        let AddAuth = values?.delivery?.authentic == undefined && { authentic: "1" };
                        DeliveryOptObj = { ...values?.delivery, ...AddAuth };
                    }
                }

                const ReqObj = {
                    id: ID ? +ID : 0,
                    report_name: Reprt?.report_name,
                    report_description: Reprt?.report_description,
                    report_type: Reprt?.report_type,
                    output_type: Reprt?.output_type,
                    template_refid: null,
                    dataset_id: values?.report_from,
                    download_format: values?.delivery_format,
                    frequency_type: values?.frequency || null,
                    frequency_value: values?.events_types,
                    eventbased: values?.events_types,
                    timebased: [
                        {
                            type: values?.type,
                            day: !values?.day ? dayWithoutLeadingZero : values?.day,
                            day_select: values?.day_select,
                            week: values?.week,
                            on: !values?.on ? "1" : values?.on,
                            startdate: values?.startdate,
                            enddate: values?.enddate,
                            scheduled_time: values?.scheduled_time,
                            on_starting: values?.on_starting,
                            on_month: values?.on_month,
                            selectedOption: values?.selectedOption,
                        },
                    ],

                    delivery_type: values?.delivery_opt,
                    delivery_credentials: DeliveryOptObj,
                    fieldinfo: [
                        ...droppedValues,
                        ...subTtalValues,
                        ...sortingValues,
                        ...morefilterValues,
                        ...fieldValues,
                        ...labelValues,
                        ...matrixColValues,
                        ...rowsValues,
                        ...(previewType == 4 ? jsonData : []), //only sent the jsonData for json reports..!
                        ...(previewType == 5 ? xmlData : []), //only sent the xmlData for xml reports..!
                    ],
                    orgid: +OrgId,
                    chart_type: values?.chart_type,
                };
                if ((previewType == 1 || previewType == 2) && subTtalValues?.length > 0 && sortingValues?.length == 0) {
                    Swal.fire({
                        text: `Sorting option is not selected!
                        Are you sure you want  to view the Subtotal without any order?
                        Click "OK" to save the report. `,
                        icon: "warning",
                        showCloseButton: true,
                        showCancelButton: false,
                        buttons: {
                            confirm: "Okay",
                        },
                    }).then((confirm) => {
                        if (confirm?.isConfirmed) {
                            dispatch(postTablerdata(ReqObj));
                        }
                    });
                } else {
                    dispatch(postTablerdata(ReqObj));
                }
            }
        },
    });
    const { report_from, delivery_opt, type, chart_type } = formik?.values; //value destructure for easy to read and access...
    // useMemo(() => {
    // This Switch statement delivery object is based on user delivery option...
    switch (delivery_opt) {
        case 1: // EMAIl
            InitialObj.delivery = {
                to: [],
                cc: [],
                bcc: [],
                message: "",
                subject: "",
                authentic: "1",
            };
            break;
        case 2: // DRIVE
            InitialObj.delivery = {
                email: "",
                folder: "",
            };
            break;
        case 3: // FTP
        case 4: // SFTP
            InitialObj.delivery = {
                host: "",
                port: InitialObj.delivery_opt === 3 ? "21" : "22",
                user: "",
                password: "",
            };
            break;
        case 5: // API
            InitialObj.delivery = {
                url: "",
                key: "",
                value: "",
                add_to: "",
                username: "",
                apipasword: "",
                authentic: "1",
            };
            break;
        default:
            break;
    }
    // }, [delivery_opt]);
    useEffect(() => {
        if (viewData?.dataset_id) {
            if (type == 0) {
                formik?.setFieldValue("type", viewData?.time_based?.[0]?.type || 0);
            }
            if (delivery_opt == 0) {
                formik?.setFieldValue("delivery_opt", viewData?.delivery_type || 0);
            }
            let TypeId = viewData?.time_based?.[0]?.type;
            let deliveryType = viewData?.delivery_type;
            formik?.setFieldValue("frequency", viewData?.frequency_type || 0);
            formik?.setFieldValue("report_from", viewData?.dataset_id || "");
            formik?.setFieldValue("delivery_format", viewData?.format_id || 0);
            // formik?.setFieldValue("delivery_opt", viewData?.delivery_type || 0);
            formik?.setFieldValue("events_types", viewData?.frequency_value || 0);
            formik?.setFieldValue("week", TypeId == type ? viewData?.time_based?.[0]?.week : [+currentDay + 1]);
            formik?.setFieldValue("day", TypeId == type ? viewData?.time_based?.[0]?.day : dayWithoutLeadingZero);
            formik?.setFieldValue("day_select", TypeId == type ? viewData?.time_based?.[0]?.day_select : +currentDay + 1);
            formik?.setFieldValue("on", TypeId == type ? viewData?.time_based?.[0]?.on : 1);
            formik?.setFieldValue("on_starting", TypeId == type ? viewData?.time_based?.[0]?.on_starting : 1);
            formik?.setFieldValue("selectedOption", viewData?.time_based?.[0]?.selectedOption || "option1");
            formik?.setFieldValue("startdate", TypeId == type ? viewData?.time_based?.[0]?.startdate : new Date());
            formik?.setFieldValue("enddate", TypeId == type ? viewData?.time_based?.[0]?.enddate : null);
            formik?.setFieldValue("scheduled_time", TypeId == type ? viewData?.time_based?.[0]?.scheduled_time : new Date());
            formik?.setFieldValue("delivery.subject", viewData?.delivery_credentials?.subject || "");
            formik?.setFieldValue("delivery.to", _.compact(viewData?.delivery_credentials?.to) || []); //_.compact lodash func is remove the empty string in the array... same as Cc and Bcc
            formik?.setFieldValue("delivery.cc", _.compact(viewData?.delivery_credentials?.cc) || []);
            formik?.setFieldValue("delivery.bcc", _.compact(viewData?.delivery_credentials?.bcc) || []);
            formik?.setFieldValue("delivery.message", viewData?.delivery_credentials?.message || "");
            formik?.setFieldValue("delivery.host", deliveryType == delivery_opt ? viewData?.delivery_credentials?.host : "");
            formik?.setFieldValue("delivery.user", deliveryType == delivery_opt ? decryptId(viewData?.delivery_credentials?.user) : "");
            formik?.setFieldValue("delivery.password", deliveryType == delivery_opt ? decryptId(viewData?.delivery_credentials?.password) : "");
            formik?.setFieldValue("delivery.url", deliveryType == delivery_opt ? viewData?.delivery_credentials?.url : "");
            formik?.setFieldValue("delivery.authentic", deliveryType == delivery_opt ? viewData?.delivery_credentials?.authentic : "1");
            formik?.setFieldValue("delivery.username", deliveryType == delivery_opt ? decryptId(viewData?.delivery_credentials?.username) : "");
            formik?.setFieldValue("delivery.apipasword", deliveryType == delivery_opt ? decryptId(viewData?.delivery_credentials?.apipasword) : "");
            formik?.setFieldValue("delivery.key", deliveryType == delivery_opt ? viewData?.delivery_credentials?.key : "");
            formik?.setFieldValue("delivery.value", deliveryType == delivery_opt ? viewData?.delivery_credentials?.value : "");
            formik?.setFieldValue("delivery.add_to", deliveryType == delivery_opt ? viewData?.delivery_credentials?.add_to : "0");
            formik?.setFieldValue("chart_type", viewData?.chart_type || 0);

            formik?.setFieldValue(
                "delivery.port",
                deliveryType == delivery_opt ? viewData?.delivery_credentials?.port : delivery_opt == 3 ? `21` : `22`
            );
            setEmail(viewData?.delivery_credentials?.email || "");
            setProfile(
                {
                    access_token: viewData?.delivery_credentials?.accessToken,
                    refresh_token: viewData?.delivery_credentials?.refreshToken,
                    expiry: viewData?.delivery_credentials?.expiry,
                } || {}
            );
            setFolderName(viewData?.delivery_credentials?.foldername || "");
            checkValidEmail(viewData?.delivery_credentials?.email ? true : false);
            setOauth(viewData?.delivery_credentials?.oauth || "");
            const ReqObj = {
                report_name: viewData?.report_name,
                report_description: viewData?.report_description,
                report_type: viewData?.report_type,
                output_type: viewData?.output_type,
            };
            dispatch(storeReportDetail(ReqObj)); //View Reports name and description types ....!
        }
    }, [viewData, delivery_opt]); //This Side effect is used for if view api data is there update that on first render.... as well as dependency..
    if (Addrequest && data?.status) {
        setTimeout(() => {
            dispatch({ type: "STORE_REPORT_DATA_RESET" });
            dispatch({ type: GET_PREVIEW_DATA_RESET });
            dispatch({ type: POST_TABLER_DATA_RESET });
            dispatch({ type: "VIEW_DATA_RESET" });
            dispatch({ type: "CHECK_FILTER_ADDED_RESET" });

            navigate("/reports/reports-summary-list");
        }, 2000);
    } else if (error) {
        setTimeout(() => {
            dispatch({ type: POST_TABLER_DATA_RESET });
        }, 2000);
    }

    // After Add and Update APi call reset the redux reducer datas....

    const [profile, setProfile] = useState({}); //store the response after success api call (outlook and google)!!
    const [email, setEmail] = useState(); //emailState store the email (outlook and google)!!
    const [folderName, setFolderName] = useState(); //foldernamestate store the foldername (outlook and google)!!
    const [validEmail, checkValidEmail] = useState(false); //check fetched email is valid whether not valid (outlook and google)!!
    const [oAuth, setOauth] = useState();

    const resetData = () => {
        dispatch({ type: "TOGGLE_CHECKBOX_RESET" });
        dispatch({ type: "STORE_REPORT_DATA_RESET" });
        dispatch({ type: GET_PREVIEW_DATA_RESET });
        dispatch({ type: POST_TABLER_DATA_RESET });
        dispatch({ type: "VIEW_DATA_RESET" });
        dispatch({ type: "CHECK_FILTER_ADDED_RESET" });
    }; // on cancel button click in ui top right end reset the previous data from redux reducer..!

    const CustomOutput = Reprt?.report_type == 1 || viewData?.report_type == 1 || ReportType == 1; //check it's cutom or scheduled..!
    const [dropdownFilter, setDropdownFilter] = useState([]);
    const EventFilter = (id) => {
        let eventDropdown = dropdown?.event_based?.filter((el) => el?.dataset_id == id);
        setDropdownFilter(eventDropdown);
    }; //filter the data based on the report
    useEffect(() => {
        EventFilter(report_from);
    }, [report_from]); //based on the report from slected filter the data
    const DynamicText = () => {
        switch (previewType) {
            case "1":
                return "Tabular";
            case "2":
                return "Matrix";
            case "3":
                return "Chart";
            case "4":
                return "JSON";
            case "5":
                return "XML";
            default:
                return "Tabular";
        }
    };
    const WholeData = [
        ...droppedValues,
        ...subTtalValues,
        ...sortingValues,
        ...morefilterValues,
        ...fieldValues,
        ...labelValues,
        ...matrixColValues,
        ...rowsValues,
    ];

    const _reportfromChange = async (e) => {
        const checkJson = jsonData?.some((item) => item?.label !== "");
        const checkxml = xmlData?.some((item) => item?.label !== "" && item?.content !== "");
        const resetDispatches = async () => {
            await dispatch({ type: GET_PREVIEW_DATA_RESET });
            await dispatch({ type: POST_TABLER_DATA_RESET });
            await dispatch({ type: "VIEW_DATA_RESET" });
            await dispatch({ type: "CHECK_FILTER_ADDED_RESET" });
            await formik?.resetForm();
        };
        if (!ID && (WholeData?.length != 0 || checkJson || checkxml)) {
            Swal.fire({
                text: `Are you sure you want to switch the "Reports From" option? All ${DynamicText()} report configurations will be lost. Please save your settings before modifying the "Reports From" option.`,
                icon: "warning",
                showCloseButton: true,
                showCancelButton: true,
                buttons: {
                    cancel: "Cancel",
                    confirm: "Okay",
                },
            }).then((confirm) => {
                if (confirm?.isConfirmed) {
                    resetDispatches();
                    formik.handleChange(e);
                    if (e?.target?.value == TypeFrom) {
                        dispatch(GetViewTablerList(ID));
                        dispatch(GetColumnsList({ type: +e?.target?.value, orgid: +OrgId, droppedValues: datas, outputType: previewType }));
                    } else {
                        if (e?.target?.value > 0) {
                            dispatch(GetColumnsList({ type: +e?.target?.value, orgid: +OrgId, outputType: previewType }));
                        }
                        dispatch({ type: "TOGGLE_CHECKBOX_RESET" });
                    }
                }
            });
        } else {
            if (e?.target?.value > 0) {
                await dispatch(GetColumnsList({ type: +e?.target?.value, orgid: +OrgId, outputType: previewType }));
            }
            await resetDispatches();
            await formik.handleChange(e);
            if (e?.target?.value == TypeFrom) {
                await dispatch(GetViewTablerList(ID));
                await dispatch(GetColumnsList({ type: +e?.target?.value, orgid: +OrgId, droppedValues: datas, outputType: previewType }));
            } else {
                if (e?.target?.value > 0) {
                    await dispatch(GetColumnsList({ type: +e?.target?.value, orgid: +OrgId, outputType: previewType }));
                }
                await dispatch({ type: "TOGGLE_CHECKBOX_RESET" });
            }
        }
    }; //change and api call on report from dropdown change reset value based on the value..@
    if (!ID && ReportType && Object.keys(Reprt)?.length == 0) {
        navigate("/reports/reports-summary-list");
    } // if user enter the manually reports-sceduled page redirect to list page...!!
    const [localStorageValue, setLocalStorageValue] = useState(localStorage.getItem("orgId"));

    useEffect(() => {
        const handleStorageChange = () => {
            // Update the state when the localStorage value changes
            setLocalStorageValue(localStorage.getItem("orgId"));
        };
        // Listen for changes to the localStorage key
        window.addEventListener("storage", handleStorageChange);
        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener("storage", handleStorageChange);
        };
    }, []); // Empty dependency array ensures the effect runs only once (get orgid value in first render and store it to state...)
    useEffect(() => {
        // Redirect when localStorage value changes
        if (localStorageValue != localStorage.getItem("orgId")) {
            // You can replace '/reports/reports-summary-list' with the path you want to redirect to
            navigate("/reports/reports-summary-list");
        }
    }, [localStorage.getItem("orgId")]); //This side effect when user change the organisation in header it's redirect the reportsummary list page...

    const ReportVal = CustomOutput ? "Custom" : "Scheduled";
    const successMsg = `${ID ? `${ReportVal} report is updated successfully` : `${ReportVal} report is created successfully`}`;
    const decodedReportname = decodeURIComponent(ReportName);
    const reportType = CustomOutput ? "Custom Report" : "Scheduled Output";
    const DisplayName = ID ? decodedReportname : reportType;
    const errorMsg = "Something went wrong please contact admin";
    const TableRender = () => {
        if (previewType == 4) {
            return <JsonTble currentMode={currentMode} type={report_from} isView={TypeFrom} prevType={previewType} />;
        } else if (previewType == 5) {
            return <XmlTable currentMode={currentMode} prevType={previewType} />;
        } else {
            return <ColumnTble formik={formik} type={report_from} />;
        }
    };
    return (
        <Layout>
            <Main>
                {Addrequest && data?.status && <SuccessMessageBox success={successMsg} />}
                {error && <MessageBox error={error?.error?.message || errorMsg} />}
                <div className={`flex justify-between items-center mb-6 text-${currentMode}-copy`}>
                    <div>
                        <h4 title={DisplayName} className={`text-2xl font-interSb truncate max-w-[700px]`}>
                            {DisplayName}
                        </h4>
                    </div>
                    <div className="space-x-2">
                        <Link onClick={resetData} to="/reports/reports-summary-list">
                            <Button title="Cancel" className={`bg-light-240 text-light-40 text-base rounded-[8px] py-2 px-7`}>
                                Cancel
                            </Button>
                        </Link>
                        <Button
                            disabled={report_from == "" || fetch || AddFetch || (previewType == 3 && chart_type == 0)}
                            onClick={formik?.handleSubmit}
                            title={ID ? "Update" : "Save"}
                            className="bg-light-20 text-base text-white rounded-[8px] py-2 px-7">
                            {ID ? "Update" : "Save"}
                        </Button>
                    </div>
                </div>
                <Card
                    className={`h-[calc(100vh_-_18rem)] py-[30px] xl:h-[calc(100vh_-_13rem)] card rounded-t-[10px] w-full overflow-y-auto ${
                        currentMode === "dark" ? "" : "shadow-[0px_4px_20px_rgba(238,238,238,0.5)]"
                    }  pb-0 relative`}>
                    {fetching || fetch ? (
                        <Loader className="flex justify-center items-ecnter" />
                    ) : (
                        <>
                            {" "}
                            <div className="px-[30px]">
                                <h5 className="font-interM text-lg mb-1">
                                    {" "}
                                    {ID ? "Update" : "Create"} {DynamicText()} Reports
                                </h5>
                                <p className="text-xs mb-[30px]">
                                    You can {ID ? "update" : "create"} and customize your {DynamicText()} reports as per your organization
                                    requirements.
                                </p>
                            </div>
                            <div className="space-y-[20px] mb-[30px]">
                                <div className="flex items-center px-[30px]">
                                    <label className="xl:basis-[10%] basis-[30%]" htmlFor="">
                                        Report From:
                                    </label>
                                    <div className="basis-[90%]">
                                        <FormControl sx={{ minWidth: 200 }}>
                                            <Select
                                                name="report_from"
                                                value={report_from || "0"}
                                                fullWidth
                                                onChange={_reportfromChange}
                                                inputProps={{
                                                    "aria-label": "Without label",
                                                    tabIndex: "1",
                                                }}
                                                IconComponent={() => SelectDropdwnImg(currentMode)}>
                                                <MenuItem key={0} name="OrganisationList" value={0}>
                                                    {"Select "}
                                                </MenuItem>
                                                {dropdown?.report_from?.map((el, i) => (
                                                    <MenuItem key={el?.id} tabIndex={el?.id} value={el?.id} className="truncate trunc-1 block">
                                                        {el?.name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </div>
                                </div>
                                {report_from > 0 && TableRender()}
                                {!CustomOutput && (
                                    <SchedulerType
                                        formik={formik}
                                        prevtype={previewType}
                                        email={email}
                                        setEmail={setEmail}
                                        setProfile={setProfile}
                                        validEmail={validEmail}
                                        toInput={toInput}
                                        setToInput={setToInput}
                                        ccInput={ccInput}
                                        setCcInput={setCcInput}
                                        bccInput={bccInput}
                                        setBccInput={setBccInput}
                                        checkValidEmail={checkValidEmail}
                                        currentMode={currentMode}
                                        setFolderName={setFolderName}
                                        folderName={folderName}
                                        setOauth={setOauth}
                                        oAuth={oAuth}
                                        viewData={viewData}
                                    />
                                )}
                            </div>
                        </>
                    )}
                </Card>
            </Main>
            <Footer />
        </Layout>
    );
};
