import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import Input from "../../components/Input";
import Modal from "../../components/UI/Modal/Modal";
import ActivityModal from "./ActivityModal";
import { useState, useEffect, useContext } from "react";
import { ThemeContext } from "../../Hooks/Themes/Themeprovider";

const Activity = (props) => {
    const [click, setClick] = useState(false);
    const closeChange = () => {
        props?.handleClose();
        setClick(true);
    };
    const { currentMode, toggleMode } = useContext(ThemeContext);

    return (
        <>
            <Modal cartClose={props?.handleClose} className={`${props?.className}`}>
                <span onClick={closeChange} className="cursor-pointer p-[6.5px] absolute right-[490px] bg-light-10">
                    <img src="/images/common/whiteClose.svg" alt="whiteClose" />
                </span>
                <div
                    className={`xl:max-w-[490px] ml-auto bg-${currentMode}-card border-${currentMode}-700 transition duration-150 ease-out flex-col flex h-screen max-h-[-webkit-fill-available] overflow-y-auto`}
                >
                    <ActivityModal cartClose={click} />
                </div>
            </Modal>
        </>
    );
};

export default Activity;
