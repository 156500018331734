import { FormControl, MenuItem, Select } from "@mui/material";
import React, { useEffect, useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { ThemeContext } from "../../Hooks/Themes/Themeprovider";
import Button from "../../components/Button";
import Input from "../../components/Input";
import Card from "../../components/UI/Card";
import Main from "../../components/UI/Main";
import Layout from "../../Hoc/Layout";
import { invoiceVendorList } from "../../redux/actions/invoice";
import { decryptId } from "../../utils/helpers";
import ReportTaskTable from "../../pages/ReportsTask/Table";
import {
    getReportsTaskList,
    getReportTaskCount,
    getReportsTaskUserList,
} from "../../redux/actions/report.js";
import { UserDropDown } from "../../redux/actions/user";
import { getCurrentUser } from "../../redux/actions/auth";
import swal from "sweetalert";

const ReportsTask = () => {
    const [invNumber, setInvNumber] = useState("");
    const dispatch = useDispatch();
    const [currentPageNo, changePageNo] = useState(1);
    const [taskType, setTaskType] = useState(0);
    const [taskStatus, setTaskStatus] = useState(0);
    const [user, setUser] = useState(0);
    const handleUserChange = (value) => {
        setUser(value);
    };

    const handleTaskStatus = (e) => {
        setTaskStatus(e.target.value);
    };
    const CurrentUser = getCurrentUser();
    const letuserId = CurrentUser?.role_id == 1 ? CurrentUser?.id : user;
    const handleTaskType = (e) => {
        setTaskType(e.target.value);
    };
    const [currentPageItem, changePageItem] = useState(10);
    const { currentMode } = useContext(ThemeContext);
    const dropdownColor = currentMode === "dark" ? "#333333" : `white`;
    const dropdownText = currentMode === "dark" ? "#ECECEC" : `#555555`;
    const {
        dataList: taskListData,
        fetching,
        requestComplete,
    } = useSelector((state) => state?.ReportsTaskUserListState);
    const {
        dataList: listData,
        fetching: fetch,
        requestComplete: reqComp,
    } = useSelector((state) => state?.ReportsTaskListState);
    const { data } = useSelector((state) => state?.reportsTaskCountState);
    const listCount = data?.data?.datalist?.[0];
    const orgId = localStorage.getItem("orgId");
    const handleSearchFilter = () => {
        changePageNo(1);
        dispatch(
            getReportsTaskList(
                user,
                taskType,
                taskStatus,
                currentPageNo,
                currentPageItem,
                invNumber
            )
        );
        dispatch(getReportTaskCount(user, taskType, taskStatus, invNumber));
    };
    useEffect(() => {
        dispatch(getReportsTaskUserList(orgId));
    }, []);
    // useEffect(() => {
    //     if (taskStatus == 2) {
    //         setTaskType(0);
    //     }
    // }, [taskStatus]);
    return (
        <>
            <Layout>
                <Main>
                    <div
                        className={`flex justify-between items-center mb-6 text-${currentMode}-copy`}
                    >
                        <div>
                            <h4 className="text-2xl font-interSb">
                                Task Reports
                            </h4>
                        </div>
                        <Link className="flex" to="/reports">
                            <img
                                className="mr-2 convertWhite"
                                src="/images/main/noun-back.svg"
                                alt="noun-back"
                            />{" "}
                            Back
                        </Link>
                    </div>
                    <Card className="p-[20px] mb-6">
                        <div className="grid grid-cols-1 xl:grid-cols-5 gap-2">
                            <div className="">
                                <span className="block mb-[10px]">
                                    Created By
                                </span>
                                <FormControl fullWidth>
                                    <Select
                                        value={user || "0"}
                                        name="UserList"
                                        onChange={(e) =>
                                            handleUserChange(e.target.value)
                                        }
                                        inputProps={{
                                            "aria-label": "Without label",
                                        }}
                                        fullWidth
                                        IconComponent={() => (
                                            <img
                                                className="absolute opacity-50 right-[15px] pointer-events-none"
                                                src={
                                                    currentMode !== "dark"
                                                        ? `/images/header/chevronDown.svg`
                                                        : `/images/header/chevronDown-white.svg`
                                                }
                                            />
                                        )}
                                        MenuProps={{
                                            sx: {
                                                "& .MuiMenu-paper": {
                                                    backgroundColor:
                                                        dropdownColor,
                                                    color: dropdownText,
                                                    maxWidth: "245px",
                                                },
                                                "& .MuiMenuItem-root:hover": {
                                                    backgroundColor:
                                                        "rgba(218, 249, 244, 0.3)",
                                                },
                                                "& .Mui-selected": {
                                                    backgroundColor:
                                                        "rgba(218, 249, 244, 0.3) !important",
                                                },
                                            },
                                        }}
                                        SelectDisplayProps={{
                                            style: {
                                                paddingTop: "9px",
                                                paddingBottom: "9px",
                                                paddingLeft: "15px",
                                                color: `${
                                                    currentMode !== "dark"
                                                        ? `#555555`
                                                        : `#ECECEC`
                                                }`,
                                                paddingRight: "0px",
                                                border: "1px solid rgba(85, 85, 85, 0.4)",
                                                borderRadius: "8px",
                                            },
                                        }}
                                    >
                                        <MenuItem
                                            key={0}
                                            name="UserList"
                                            value={0}
                                        >
                                            {"All Users"}
                                        </MenuItem>
                                        {taskListData &&
                                            taskListData?.map((listelem, i) => {
                                                return (
                                                    <MenuItem
                                                        key={i + 1}
                                                        name="UserList"
                                                        value={
                                                            listelem?.user_id
                                                        }
                                                    >
                                                        {decryptId(
                                                            listelem?.user_emailaddress
                                                        )}
                                                    </MenuItem>
                                                );
                                            })}
                                    </Select>
                                </FormControl>
                            </div>
                            <div className="">
                                <span className="block mb-[10px]">
                                    Task Type
                                </span>
                                <FormControl fullWidth>
                                    <Select
                                        inputProps={{
                                            "aria-label": "Without label",
                                        }}
                                        onChange={(e) => handleTaskType(e)}
                                        value={taskType}
                                        IconComponent={() => (
                                            <img
                                                className="absolute opacity-50 right-[15px] pointer-events-none"
                                                src={
                                                    currentMode !== "dark"
                                                        ? `/images/header/chevronDown.svg`
                                                        : `/images/header/chevronDown-white.svg`
                                                }
                                            />
                                        )}
                                        MenuProps={{
                                            sx: {
                                                "& .MuiMenu-paper": {
                                                    backgroundColor:
                                                        dropdownColor,
                                                    color: dropdownText,
                                                    maxWidth: "245px",
                                                },
                                                "& .MuiMenuItem-root:hover": {
                                                    backgroundColor:
                                                        "rgba(218, 249, 244, 0.3)",
                                                },
                                                "& .Mui-selected": {
                                                    backgroundColor:
                                                        "rgba(218, 249, 244, 0.3) !important",
                                                },
                                            },
                                        }}
                                        displayEmpty
                                        SelectDisplayProps={{
                                            style: {
                                                paddingTop: "9px",
                                                paddingBottom: "9px",
                                                paddingLeft: "15px",
                                                color: `${
                                                    currentMode !== "dark"
                                                        ? `#555555`
                                                        : `#ECECEC`
                                                }`,
                                                paddingRight: "0px",
                                                border: "1px solid rgba(85, 85, 85, 0.4)",
                                                borderRadius: "8px",
                                            },
                                        }}
                                    >
                                        <MenuItem value={0}>All</MenuItem>
                                        {taskStatus !== 2 ? (
                                            <MenuItem value={1}>
                                                Today's Task
                                            </MenuItem>
                                        ) : (
                                            ""
                                        )}
                                        {taskStatus !== 2 ? (
                                            <MenuItem value={2}>
                                                Due this week
                                            </MenuItem>
                                        ) : (
                                            ""
                                        )}
                                        {taskStatus !== 2 ? (
                                            <MenuItem value={3}>
                                                Overdue
                                            </MenuItem>
                                        ) : (
                                            ""
                                        )}
                                    </Select>
                                </FormControl>
                            </div>
                            <div className="">
                                <span className="block mb-[10px]">
                                    Task Status
                                </span>
                                <FormControl fullWidth>
                                    <Select
                                        inputProps={{
                                            "aria-label": "Without label",
                                        }}
                                        onChange={(e) => handleTaskStatus(e)}
                                        value={taskStatus}
                                        IconComponent={() => (
                                            <img
                                                className="absolute opacity-50 right-[15px] pointer-events-none"
                                                src={
                                                    currentMode !== "dark"
                                                        ? `/images/header/chevronDown.svg`
                                                        : `/images/header/chevronDown-white.svg`
                                                }
                                            />
                                        )}
                                        MenuProps={{
                                            sx: {
                                                "& .MuiMenu-paper": {
                                                    backgroundColor:
                                                        dropdownColor,
                                                    color: dropdownText,
                                                    maxWidth: "245px",
                                                },
                                                "& .MuiMenuItem-root:hover": {
                                                    backgroundColor:
                                                        "rgba(218, 249, 244, 0.3)",
                                                },
                                                "& .Mui-selected": {
                                                    backgroundColor:
                                                        "rgba(218, 249, 244, 0.3) !important",
                                                },
                                            },
                                        }}
                                        displayEmpty
                                        SelectDisplayProps={{
                                            style: {
                                                paddingTop: "10px",
                                                paddingBottom: "10px",
                                                paddingRight: "30px",
                                                paddingLeft: "15px",
                                                border: "1px solid rgba(85, 85, 85, 0.4)",
                                                borderRadius: "8px",
                                                color: `${
                                                    currentMode !== "dark"
                                                        ? `#555555`
                                                        : `#ECECEC`
                                                }`,
                                            },
                                        }}
                                    >
                                        <MenuItem value={0}>All</MenuItem>
                                        <MenuItem value={1}>
                                            Inprogress
                                        </MenuItem>
                                        {taskType == 0 ? (
                                            <MenuItem value={2}>
                                                Completed
                                            </MenuItem>
                                        ) : (
                                            ""
                                        )}
                                        <MenuItem value={3}>Done</MenuItem>
                                        <MenuItem value={4}>Assign</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                            <div className="">
                                <span className="block mb-[10px]">
                                    Invoice Number
                                </span>
                                <Input
                                    type="text"
                                    onChange={(e) =>
                                        setInvNumber(e.target.value)
                                    }
                                    className={`border-[1px] w-full  py-2 px-4  rounded-[8px] border-${currentMode}-840 bg-transparent`}
                                    placeholder="Enter Invoice Number"
                                />
                            </div>
                            <div className="flex items-end">
                                <Button
                                    disabled={fetch && !reqComp}
                                    onClick={handleSearchFilter}
                                    className={`bg-light-20 mb-0 border-[1px] py-[7px] w-full text-base rounded-[8px] text-white  border-transparent`}
                                >
                                    Search
                                </Button>
                            </div>
                        </div>
                    </Card>
                    {/* <DashboardTile report={true} /> */}
                    {/* <ReportChart /> */}
                    <ReportTaskTable
                        user={user}
                        taskStatus={taskStatus}
                        taskType={taskType}
                        currentPageNo={currentPageNo}
                        changePageNo={changePageNo}
                        currentPageItem={currentPageItem}
                        changePageItem={changePageItem}
                        listCount={listCount}
                        invNumber={invNumber}

                        // isSupplier={isSupplier}
                        // selAging={selAging}
                        // invNumber={invNumber}
                        // isStatus={isStatus}
                        // fromDate={fromDate}
                        // toDate={toDate}
                        // ageing={ageing}
                        // page={page}
                        // clickTrack={searchClick}
                    />
                </Main>
            </Layout>
        </>
    );
};

export default ReportsTask;
