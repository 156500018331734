import Modal from "../../../components/UI/Modal/Modal";
import { CreateTicketModal } from "./Modal";

export const CreateTicket = (props) => {
    return (
        <>
            <Modal cartClose={props?.handleClose} className={`${props?.className}`}>
                <span onClick={props?.handleClose} className="p-[6.5px] absolute cursor-pointer right-[712px] bg-light-10">
                    <img src="/images/common/whiteClose.svg" alt="whiteClose" />
                </span>

                <div className="max-w-[712px] mx-auto overflow-y-auto flex flex-col transition duration-150 ease-out flex-col flex h-screen max-h-[-webkit-fill-available]">
                    <div className="bg-white px-[30px] py-[24px] border-b-[1px] border-[rgba(236,236,236,0.88)] mb-[1.8rem] relative">
                        <h4 className="font-interSb text-lg">Create Ticket</h4>
                    </div>
                    <CreateTicketModal cartClose={props?.handleClose} />
                </div>
            </Modal>
        </>
    );
};
