import { useContext, useEffect } from "react";
import Card from "../../../components/UI/Card";
import CommentCard from "../../../organisms/CommentsCard";
import { useSelector } from "react-redux";
import Modal from "../../../components/UI/Modal/Modal";
import { ADD_UPDATE_COMMENTS_RESET } from "../../../redux/types/types";
import { useDispatch } from "react-redux";

export const CommentModal = (props) => {
    const { className, handleClose, currentMode } = props;
    const { data } = useSelector((state) => state?.getInvoiceItemsState);
    const dispatch = useDispatch();
    const {
        data: commentData,
        fetching: addCmtFetch,
        requestComplete: addCmtReqComp,
        error: addCmtErr,
    } = useSelector((state) => state?.addUpdateCommentsState);
    useEffect(() => {
        if (commentData && addCmtReqComp) {
            setTimeout(() => {
                dispatch({ type: ADD_UPDATE_COMMENTS_RESET });
            }, 2000);
        }
    }, [addCmtReqComp]);
    return (
        <Modal cartClose={handleClose} center="center" className={`${className}`}>
            <Card
                className={` border-[1px] relative  flex flex-col mt-[20px] justify-between h-[345px] border-[rgba(223,225,226,0.56)] w-full max-w-[346px] ${
                    currentMode === "dark" ? "" : "shadow-[0px_4px_20px_rgba(238,238,238,0.5)]"
                }`}>
                <span onClick={handleClose} className="cursor-pointer block absolute top-[16px] right-[16px]">
                    <img src="/images/common/close-one.svg" alt="cls-blk" />
                </span>
                <CommentCard isEdit={props?.isEdit} createDataObject={data?.datalist?.[0]} type={1} reqId={props?.invID} />
            </Card>
        </Modal>
    );
};
