import {
	ADD_UPDATE_MISC_FAILURE,
	ADD_UPDATE_MISC_REQUEST,
	ADD_UPDATE_MISC_RESET,
	ADD_UPDATE_MISC_SUCCESS,
	GET_MISC_LIST_FAILURE,
	GET_MISC_LIST_REQUEST,
	GET_MISC_LIST_RESET,
	GET_MISC_LIST_SUCCESS,
	GET_MISC_PO_LIST_FAILURE,
	GET_MISC_PO_LIST_REQUEST,
	GET_MISC_PO_LIST_RESET,
	GET_MISC_PO_LIST_SUCCESS,
	GET_MISC_VENDOR_LIST_FAILURE,
	GET_MISC_VENDOR_LIST_REQUEST,
	GET_MISC_VENDOR_LIST_RESET,
	GET_MISC_VENDOR_LIST_SUCCESS,
	GET_MISC_WAV_SYNC_FAILURE,
	GET_MISC_WAV_SYNC_REQUEST,
	GET_MISC_WAV_SYNC_RESET,
	GET_MISC_WAV_SYNC_SUCCESS,
	GET_MISC_WBS_LIST_FAILURE,
	GET_MISC_WBS_LIST_REQUEST,
	GET_MISC_WBS_LIST_RESET,
	GET_MISC_WBS_LIST_SUCCESS,
	TDS_LIST_FAILURE,
	TDS_LIST_REQUEST,
	TDS_LIST_RESET,
	TDS_LIST_SUCCESS,
	VIEW_MISC_FAILURE,
	VIEW_MISC_REQUEST,
	VIEW_MISC_RESET,
	VIEW_MISC_SUCCESS,
} from "../types/types";

const addUpdateState = {
	dataList: [],
	fetching: false,
	requestComplete: false,
	error: "",
};

export function AddUpdateMiscReducer(state = addUpdateState, action) {
	switch (action.type) {
		case ADD_UPDATE_MISC_REQUEST:
			return {
				...state,
				fetching: true,
				requestComplete: false,
				error: "",
			};
		case ADD_UPDATE_MISC_SUCCESS:
			return {
				dataList: action.payload,
				fetching: false,
				requestComplete: true,
				error: "",
			};

		case ADD_UPDATE_MISC_RESET:
			return {
				dataList: [],
				fetching: false,
				requestComplete: false,
				error: "",
			};
		case ADD_UPDATE_MISC_FAILURE:
			return {
				fetching: false,
				requestComplete: true,
				error: action.payload,
			};
		default:
			return state;
	}
}

const ViewState = {
	data: [],
	fetching: false,
	requestComplete: false,
	error: "",
};
export function ViewMiscReducer(state = ViewState, action) {
	switch (action.type) {
		case VIEW_MISC_REQUEST:
			return {
				...state,
				fetching: true,
				requestComplete: false,
				error: "",
			};
		case VIEW_MISC_SUCCESS:
			return {
				data: action.payload,
				fetching: false,
				requestComplete: true,
				error: "",
			};
		case VIEW_MISC_RESET:
			return {
				data: [],
				fetching: false,
				requestComplete: false,
				error: "",
			};
		case VIEW_MISC_FAILURE:
			return {
				fetching: false,
				requestComplete: true,
				error: action.payload,
			};
		default:
			return state;
	}
}
const ListState = {
	data: [],
	fetching: false,
	requestComplete: false,
	error: "",
};
export function MiscListReducer(state = ListState, action) {
	switch (action.type) {
		case GET_MISC_LIST_REQUEST:
			return {
				...state,
				fetching: true,
				requestComplete: false,
				error: "",
			};
		case GET_MISC_LIST_SUCCESS:
			return {
				data: action.payload,
				fetching: false,
				requestComplete: true,
				error: "",
			};
		case GET_MISC_LIST_RESET:
			return {
				data: [],
				fetching: false,
				requestComplete: false,
				error: "",
			};
		case GET_MISC_LIST_FAILURE:
			return {
				fetching: false,
				requestComplete: false,
				error: action.payload,
			};
		default:
			return state;
	}
}
const PoListState = {
	data: [],
	fetching: false,
	requestComplete: false,
	error: "",
};
export function MiscPoListReducer(state = PoListState, action) {
	switch (action.type) {
		case GET_MISC_PO_LIST_REQUEST:
			return {
				...state,
				fetching: true,
				requestComplete: false,
				error: "",
			};
		case GET_MISC_PO_LIST_SUCCESS:
			return {
				data: action.payload,
				fetching: false,
				requestComplete: true,
				error: "",
			};
		case GET_MISC_PO_LIST_RESET:
			return {
				data: [],
				fetching: false,
				requestComplete: false,
				error: "",
			};
		case GET_MISC_PO_LIST_FAILURE:
			return {
				fetching: false,
				requestComplete: false,
				error: action.payload,
			};
		default:
			return state;
	}
}
const VendorListState = {
	data: [],
	fetching: false,
	requestComplete: false,
	error: "",
};
export function MiscVendorListReducer(state = VendorListState, action) {
	switch (action.type) {
		case GET_MISC_VENDOR_LIST_REQUEST:
			return {
				...state,
				fetching: true,
				requestComplete: false,
				error: "",
			};
		case GET_MISC_VENDOR_LIST_SUCCESS:
			return {
				data: action.payload,
				fetching: false,
				requestComplete: true,
				error: "",
			};
		case GET_MISC_VENDOR_LIST_RESET:
			return {
				data: [],
				fetching: false,
				requestComplete: false,
				error: "",
			};
		case GET_MISC_VENDOR_LIST_FAILURE:
			return {
				fetching: false,
				requestComplete: false,
				error: action.payload,
			};
		default:
			return state;
	}
}

const WBSListState = {
	data: [],
	fetching: false,
	requestComplete: false,
	error: "",
};
export function MiscWBSListReducer(state = WBSListState, action) {
	switch (action.type) {
		case GET_MISC_WBS_LIST_REQUEST:
			return {
				...state,
				fetching: true,
				requestComplete: false,
				error: "",
			};
		case GET_MISC_WBS_LIST_SUCCESS:
			return {
				data: action.payload,
				fetching: false,
				requestComplete: true,
				error: "",
			};
		case GET_MISC_WBS_LIST_RESET:
			return {
				data: [],
				fetching: false,
				requestComplete: false,
				error: "",
			};
		case GET_MISC_WBS_LIST_FAILURE:
			return {
				fetching: false,
				requestComplete: false,
				error: action.payload,
			};
		default:
			return state;
	}
}
const WBSyncState = {
	data: [],
	fetching: false,
	requestComplete: false,
	error: "",
};
export function WbsSyncReducer(state = WBSyncState, action) {
	switch (action.type) {
		case GET_MISC_WAV_SYNC_REQUEST:
			return {
				...state,
				fetching: true,
				requestComplete: false,
				error: "",
			};
		case GET_MISC_WAV_SYNC_SUCCESS:
			return {
				data: action.payload,
				fetching: false,
				requestComplete: true,
				error: "",
			};
		case GET_MISC_WAV_SYNC_RESET:
			return {
				data: [],
				fetching: false,
				requestComplete: false,
				error: "",
			};
		case GET_MISC_WAV_SYNC_FAILURE:
			return {
				fetching: false,
				requestComplete: true,
				error: action.payload,
			};
		default:
			return state;
	}
}
const TDSListState = {
	data: [],
	fetching: false,
	requestComplete: false,
	error: "",
};
export function TdsReducer(state = TDSListState, action) {
	switch (action.type) {
		case TDS_LIST_REQUEST:
			return {
				...state,
				fetching: true,
				requestComplete: false,
				error: "",
			};
		case TDS_LIST_SUCCESS:
			return {
				data: action.payload,
				fetching: false,
				requestComplete: true,
				error: "",
			};
		case TDS_LIST_RESET:
			return {
				data: [],
				fetching: false,
				requestComplete: false,
				error: "",
			};
		case TDS_LIST_FAILURE:
			return {
				fetching: false,
				requestComplete: false,
				error: action.payload,
			};
		default:
			return state;
	}
}
