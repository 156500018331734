import {
    ADD_REPORTS_FAV_FAILURE,
    ADD_REPORTS_FAV_REQUEST,
    ADD_REPORTS_FAV_SUCCESS,
    GET_REPORTS_ACCRUAL_FAILURE,
    GET_REPORTS_ACCRUAL_REQUEST,
    GET_REPORTS_ACCRUAL_SUCCESS,
    GET_REPORTS_AGEING_FAILURE,
    GET_REPORTS_AGEING_REQUEST,
    GET_REPORTS_AGEING_SUCCESS,
    GET_REPORTS_AGEING_SUMMARY_FAILURE,
    GET_REPORTS_AGEING_SUMMARY_REQUEST,
    GET_REPORTS_AGEING_SUMMARY_SUCCESS,
    GET_REPORTS_COUNT_FAILURE,
    GET_REPORTS_COUNT_REQUEST,
    GET_REPORTS_COUNT_SUCCESS,
    GET_REPORTS_FAV_FAILURE,
    GET_REPORTS_FAV_REQUEST,
    GET_REPORTS_FAV_SUCCESS,
    GET_REPORTS_LIST_FAILURE,
    GET_REPORTS_LIST_REQUEST,
    GET_REPORTS_LIST_SUCCESS,
    REPORTS_AGEING_COUNT_FAILURE,
    REPORTS_AGEING_COUNT_REQUEST,
    REPORTS_AGEING_COUNT_SUCCESS,
    GET_SUMMARY_AGING_SUCCESS,
    GET_SUMMARY_AGING_FAILURE,
    GET_SUMMARY_AGING_REQUEST,
    GET_SUMMARY_AGING_RESET,
    GET_TASK_REPORT_LIST_REQUEST,
    GET_TASK_REPORT_LIST_FAILURE,
    GET_TASK_REPORT_LIST_SUCCESS,
    GET_TASK_REPORT_COUNT_REQUEST,
    GET_TASK_REPORT_COUNT_FAILURE,
    GET_TASK_REPORT_COUNT_SUCCESS,
    GET_TASK_REPORTS_CREATEDBYUSER_REQUEST,
    GET_TASK_REPORTS_CREATEDBYUSER_SUCCESS,
    GET_TASK_REPORTS_CREATEDBYUSER_FAILURE,
    GET_LICENSE_SUMMARY_FAILURE,
    GET_LICENSE_SUMMARY_REQUEST,
    GET_LICENSE_SUMMARY_SUCCESS,
    GET_LICENSE_SUMMARY_RESET,
    GET_LICENSE_USAGE_FAILURE,
    GET_LICENSE_USAGE_REQUEST,
    GET_LICENSE_USAGE_RESET,
    GET_LICENSE_USAGE_SUCCESS,
    GET_LICENSE_REPORT_LIST_REQUEST,
    GET_LICENSE_REPORT_LIST_SUCCESS,
    GET_LICENSE_REPORT_LIST_FAILURE,
    GET_LICENSE_REPORT_COUNT_FAILURE,
    GET_LICENSE_REPORT_COUNT_SUCCESS,
    GET_LICENSE_REPORT_COUNT_REQUEST,
    EXPORT_INVOICE_REPORTS_SUCCESS,
    EXPORT_AGEING_REPORTS_SUCCESS,
    EXPORT_TASK_REPORTS_SUCCESS,
    EXPORT_BILLING_REPORTS_SUCCESS,
    SEND_BILLING_MAIL_REQUEST,
    SEND_BILLING_MAIL_SUCCESS,
    SEND_BILLING_MAIL_FAILURE,
    GET_ADVANCE_REPORTS_REQUEST,
    GET_ADVANCE_REPORTS_SUCCESS,
    GET_ADVANCE_REPORTS_FAILURE,
    EXPORT_ADVANCE_REPORT_LIST_SUCCESS
    // GET_ADVANCE_REPORTS_RESET
} from "../types/types";
import axios from "axios";
import authHeader from "./auth-header";
import { checkApiStatus } from "../../utils/helpers";
const API_URL = process.env?.REACT_APP_API_URL;
const NODE_API_URL = process.env?.REACT_APP_NODE_API_URL;

export const getReportsList = (data, download) => async (dispatch) => {
    try {
        if (download !== "download") {
            dispatch({ type: GET_REPORTS_LIST_REQUEST });
        }

        axios
            .post(`${API_URL}Reports/GetReports_List`, data, {
                headers: authHeader(),
            })
            .then((response) => {
                if (download === "download") {
                    dispatch({
                        type: EXPORT_INVOICE_REPORTS_SUCCESS,
                        payload: response?.data?.datalist,
                    });
                } else {
                    dispatch({
                        type: GET_REPORTS_LIST_SUCCESS,
                        payload: response?.data?.datalist,
                    });
                }
            })
            .catch((ex) => {
                if (ex?.response?.status == 401) {
                    checkApiStatus();
                }
                dispatch({
                    type: GET_REPORTS_LIST_FAILURE,
                    payload: ex,
                });
            });
    } catch (error) {
        dispatch({
            type: GET_REPORTS_LIST_FAILURE,
        });
    }
};
export const reportsCountTotal = (data) => async (dispatch) => {
    try {
        dispatch({ type: GET_REPORTS_COUNT_REQUEST });

        axios
            .post(`${API_URL}Reports/GetInvoiceCount`, data, {
                headers: authHeader(),
            })
            .then((response) => {
                dispatch({
                    type: GET_REPORTS_COUNT_SUCCESS,
                    payload: response,
                });
            });
    } catch (error) {
        if (error?.response?.status == 401) {
            checkApiStatus();
        }
        dispatch({
            type: GET_REPORTS_COUNT_FAILURE,
        });
    }
};

export const getReportsAgeingList = (data, download) => async (dispatch) => {
    try {
        if (download !== "download") {
            dispatch({ type: GET_REPORTS_AGEING_REQUEST });
        }

        axios
            .post(`${API_URL}Reports/GetAgeingList`, data, {
                headers: authHeader(),
            })
            .then((response) => {
                if (download === "download") {
                    dispatch({
                        type: EXPORT_AGEING_REPORTS_SUCCESS,
                        payload: response?.data?.datalist,
                    });
                } else {
                    dispatch({
                        type: GET_REPORTS_AGEING_SUCCESS,
                        payload: response?.data?.datalist,
                    });
                }
            })

            .catch((ex) => {
                if (ex?.response?.status == 401) {
                    checkApiStatus();
                }
                dispatch({
                    type: GET_REPORTS_AGEING_FAILURE,
                    payload: ex,
                });
            });
    } catch (error) {
        dispatch({
            type: GET_REPORTS_AGEING_FAILURE,
        });
    }
};

export const reportsAgeingCountTotal = (data) => async (dispatch) => {
    try {
        dispatch({ type: REPORTS_AGEING_COUNT_REQUEST });

        axios
            .post(`${API_URL}Reports/GetAgeing_ReportCount`, data, {
                headers: authHeader(),
            })
            .then((response) => {
                dispatch({
                    type: REPORTS_AGEING_COUNT_SUCCESS,
                    payload: response,
                });
            });
    } catch (error) {
        if (error?.response?.status == 401) {
            checkApiStatus();
        }
        dispatch({
            type: REPORTS_AGEING_COUNT_FAILURE,
        });
    }
};

export const getReportsAgeingSummaryList = (OrgId) => async (dispatch) => {
    try {
        dispatch({ type: GET_REPORTS_AGEING_SUMMARY_REQUEST });

        axios
            .get(`${API_URL}Reports/ReportsAgeingSummary?organisation=${OrgId}`, {
                headers: authHeader(),
            })
            .then((response) => {
                dispatch({
                    type: GET_REPORTS_AGEING_SUMMARY_SUCCESS,
                    payload: response?.data?.datalist,
                });
            })
            .catch((ex) => {
                if (ex?.response?.status == 401) {
                    checkApiStatus();
                }
                dispatch({
                    type: GET_REPORTS_AGEING_SUMMARY_FAILURE,
                    payload: ex,
                });
            });
    } catch (error) {
        dispatch({
            type: GET_REPORTS_AGEING_SUMMARY_FAILURE,
        });
    }
};

export const getReportsAccrualList = (data, download) => async (dispatch) => {
    try {
        dispatch({ type: GET_REPORTS_ACCRUAL_REQUEST });

        axios
            .post(`${API_URL}Reports/ReportsAccrual`, data, {
                headers: authHeader(),
            })
            .then((response) => {
                dispatch({
                    type: GET_REPORTS_ACCRUAL_SUCCESS,
                    payload: response?.data?.datalist,
                });
            })
            .catch((ex) => {
                if (ex?.response?.status == 401) {
                    checkApiStatus();
                }
                dispatch({
                    type: GET_REPORTS_ACCRUAL_FAILURE,
                    payload: ex,
                });
            });
    } catch (error) {
        dispatch({
            type: GET_REPORTS_ACCRUAL_FAILURE,
        });
    }
};

export const getReportsFavList = (OrgId) => async (dispatch) => {
    try {
        dispatch({ type: GET_REPORTS_FAV_REQUEST });
        axios
            .get(`${NODE_API_URL}api/reportsFav/getReportsFavourite?orgid=${OrgId}`, {
                headers: authHeader(),
            })
            .then((response) => {
                dispatch({
                    type: GET_REPORTS_FAV_SUCCESS,
                    payload: response?.data?.data,
                });
            })
            .catch((ex) => {
                if (ex?.response?.status == 401) {
                    checkApiStatus();
                }
                dispatch({
                    type: GET_REPORTS_FAV_FAILURE,
                    payload: ex,
                });
            });
    } catch (error) {
        dispatch({
            type: GET_REPORTS_FAV_FAILURE,
        });
    }
};

export const addReportsFavList = (data) => async (dispatch) => {
    try {
        dispatch({ type: ADD_REPORTS_FAV_REQUEST });
        axios
            .post(`${API_URL}Reports/AddReports_Favourites`, data, {
                headers: authHeader(),
            })
            .then((response) => {
                dispatch({
                    type: ADD_REPORTS_FAV_SUCCESS,
                    payload: response?.data?.datalist,
                });
            })
            .catch((ex) => {
                if (ex?.response?.status == 401) {
                    checkApiStatus();
                }
                dispatch({
                    type: ADD_REPORTS_FAV_FAILURE,
                    payload: ex,
                });
            });
    } catch (error) {
        dispatch({
            type: ADD_REPORTS_FAV_FAILURE,
        });
    }
};

export const getSummaryAgingList = (data) => async (dispatch) => {
    try {
        dispatch({ type: GET_SUMMARY_AGING_REQUEST });

        axios
            .post(`${API_URL}Reports/GetAgeingSummary_List`, data, {
                headers: authHeader(),
            })
            .then((response) => {
                dispatch({
                    type: GET_SUMMARY_AGING_SUCCESS,
                    payload: response?.data?.datalist,
                });
            })
            .catch((ex) => {
                if (ex?.response?.status == 401) {
                    checkApiStatus();
                }
                dispatch({
                    type: GET_SUMMARY_AGING_FAILURE,
                    payload: ex,
                });
            });
    } catch (error) {
        dispatch({
            type: GET_SUMMARY_AGING_FAILURE,
        });
    }
};
//task report list

export const getReportsTaskList = (created_by, task_due, task_status, pgno, reccnt, invoice_no, download) => async (dispatch) => {
    try {
        if (download !== "download") {
            dispatch({ type: GET_TASK_REPORT_LIST_REQUEST });
        }
        axios
            .get(
                `${NODE_API_URL}api/taskReports/getReportsTaskList?created_by=${created_by}&task_due=${task_due}&task_status=${task_status}&pgno=${pgno}&reccnt=${reccnt}&invoice_no=${invoice_no}`,
                {
                    headers: authHeader(),
                }
            )
            .then((response) => {
                if (download == "download") {
                    dispatch({
                        type: EXPORT_TASK_REPORTS_SUCCESS,
                        payload: response?.data?.datalist,
                    });
                } else {
                    dispatch({
                        type: GET_TASK_REPORT_LIST_SUCCESS,
                        payload: response?.data?.datalist,
                    });
                }
            })
            .catch((ex) => {
                if (ex?.response?.status == 401) {
                    checkApiStatus();
                }
                dispatch({
                    type: GET_TASK_REPORT_LIST_FAILURE,
                    payload: ex,
                });
            });
    } catch (error) {
        dispatch({
            type: GET_TASK_REPORT_LIST_FAILURE,
        });
    }
};
//report task count
export const getReportTaskCount = (created_by, task_due, task_status, invoice_no) => async (dispatch) => {
    try {
        dispatch({ type: GET_TASK_REPORT_COUNT_REQUEST });

        axios
            .get(
                `${NODE_API_URL}api/taskReports/getReportsTaskCount?created_by=${created_by}&task_due=${task_due}&task_status=${task_status}&invoice_no=${invoice_no}`,
                {
                    headers: authHeader(),
                }
            )
            .then((response) => {
                dispatch({
                    type: GET_TASK_REPORT_COUNT_SUCCESS,
                    payload: response,
                });
            });
    } catch (error) {
        if (error?.response?.status == 401) {
            checkApiStatus();
        }
        dispatch({
            type: GET_TASK_REPORT_COUNT_FAILURE,
        });
    }
};

//GET TASK REPORT USER LIST

export const getReportsTaskUserList = (orgId) => async (dispatch) => {
    try {
        dispatch({ type: GET_TASK_REPORTS_CREATEDBYUSER_REQUEST });

        axios
            .get(`${NODE_API_URL}api/taskReports/userDropdown?orgid=${orgId}`, {
                headers: authHeader(),
            })
            .then((response) => {
                dispatch({
                    type: GET_TASK_REPORTS_CREATEDBYUSER_SUCCESS,
                    payload: response?.data?.datalist,
                });
            })
            .catch((ex) => {
                if (ex?.response?.status == 401) {
                    checkApiStatus();
                }
                dispatch({
                    type: GET_TASK_REPORTS_CREATEDBYUSER_FAILURE,
                    payload: ex,
                });
            });
    } catch (error) {
        dispatch({
            type: GET_TASK_REPORTS_CREATEDBYUSER_FAILURE,
        });
    }
};

//GET License Summary

export const getLicenseSummaryList = () => async (dispatch) => {
    try {
        dispatch({ type: GET_LICENSE_SUMMARY_REQUEST });
        axios
            .get(`${NODE_API_URL}api/licenseReports/getReportsLicenseSummary`, {
                headers: authHeader(),
            })
            .then((response) => {
                dispatch({
                    type: GET_LICENSE_SUMMARY_SUCCESS,
                    payload: response?.data?.datalist[0],
                });
            })
            .catch((ex) => {
                if (ex?.response?.status == 401) {
                    checkApiStatus();
                }
                dispatch({
                    type: GET_LICENSE_SUMMARY_FAILURE,
                    payload: ex,
                });
            });
    } catch (error) {
        dispatch({
            type: GET_LICENSE_SUMMARY_FAILURE,
        });
    }
};

//GET License Summary

export const getLicenseUsageData = (fromDate, toDate) => async (dispatch) => {
    try {
        dispatch({ type: GET_LICENSE_USAGE_REQUEST });
        axios
            .get(`${NODE_API_URL}api/licenseReports/getReportsLicenseUseage?from_date=${fromDate}&to_date=${toDate}`, {
                headers: authHeader(),
            })
            .then((response) => {
                dispatch({
                    type: GET_LICENSE_USAGE_SUCCESS,
                    payload: response?.data?.datalist,
                });
            })
            .catch((ex) => {
                if (ex?.response?.status == 401) {
                    checkApiStatus();
                }
                dispatch({
                    type: GET_LICENSE_USAGE_FAILURE,
                    payload: ex,
                });
            });
    } catch (error) {
        dispatch({
            type: GET_LICENSE_USAGE_FAILURE,
        });
    }
};
//license report list

export const getReportsLicenseList = (fromDate, toDate, pageNo, recCount, download) => async (dispatch) => {
    try {
        if (download !== "download") {
            dispatch({ type: GET_LICENSE_REPORT_LIST_REQUEST });
        }

        axios
            .get(
                `${NODE_API_URL}api/licenseReports/getReportsLicenseList?from_date=${fromDate}&to_date=${toDate}&pgno=${pageNo}&reccnt=${recCount}`,
                {
                    headers: authHeader(),
                }
            )
            .then((response) => {
                if (download == "download") {
                    dispatch({
                        type: EXPORT_BILLING_REPORTS_SUCCESS,
                        payload: response?.data?.datalist,
                    });
                } else {
                    dispatch({
                        type: GET_LICENSE_REPORT_LIST_SUCCESS,
                        payload: response?.data?.datalist,
                    });
                }
            })
            .catch((ex) => {
                if (ex?.response?.status == 401) {
                    checkApiStatus();
                }
                dispatch({
                    type: GET_LICENSE_REPORT_LIST_FAILURE,
                    payload: ex,
                });
            });
    } catch (error) {
        dispatch({
            type: GET_LICENSE_REPORT_LIST_FAILURE,
        });
    }
};
//report License count
export const getReportLicenseCount = (fromDate, toDate) => async (dispatch) => {
    try {
        dispatch({ type: GET_LICENSE_REPORT_COUNT_REQUEST });

        axios
            .get(`${NODE_API_URL}api/licenseReports/getReportsLicenseCount?from_date=${fromDate}&to_date=${toDate}`, {
                headers: authHeader(),
            })
            .then((response) => {
                dispatch({
                    type: GET_LICENSE_REPORT_COUNT_SUCCESS,
                    payload: response,
                });
            });
    } catch (error) {
        if (error?.response?.status == 401) {
            checkApiStatus();
        }
        dispatch({
            type: GET_LICENSE_REPORT_COUNT_FAILURE,
        });
    }
};

// billing email send
export const sendBillingEmail = (data) => async (dispatch) => {
    try {
        dispatch({ type: SEND_BILLING_MAIL_REQUEST });
        axios
            .post(`${NODE_API_URL}api/billingEmail`, data, {
                headers: authHeader(),
            })
            .then((response) => {
                dispatch({
                    type: SEND_BILLING_MAIL_SUCCESS,
                    payload: response,
                });
            })
            .catch((error) => {
                dispatch({
                    type: SEND_BILLING_MAIL_FAILURE,
                    payload: error,
                });
            });
    } catch (error) {
        if (error?.response?.status == 401) {
            checkApiStatus();
        }
        dispatch({
            type: SEND_BILLING_MAIL_FAILURE,
            payload: error,
        });
    }
};


// Advance reports 

export const getAdvanceReport = (data, download) => async (dispatch) => {
    try {

        if (download !== "download") {
            dispatch({ type: GET_ADVANCE_REPORTS_REQUEST });
        }
        axios
            .post(`${NODE_API_URL}api/taskReports/getReportsAdvanceList`, data, {
                headers: authHeader(),
            })
            .then((response) => {
                if (download === "download") {
                    dispatch({
                        type: EXPORT_ADVANCE_REPORT_LIST_SUCCESS,
                        payload: response?.data?.data,
                    })
                }
                else {
                    dispatch({
                        type: GET_ADVANCE_REPORTS_SUCCESS,
                        payload: response?.data?.data,
                    });
                }
            })
            .catch((error) => {
                if (error?.response?.status == 401) {
                    checkApiStatus();
                }
                dispatch({
                    type: GET_ADVANCE_REPORTS_FAILURE,
                    payload: error,
                });
            });
    } catch (error) {
        if (error?.response?.status == 401) {
            checkApiStatus();
        }
        dispatch({
            type: GET_ADVANCE_REPORTS_FAILURE,
            payload: error,
        });
    }
};