const Input = (props) => {
    return (
        <input
            type={props?.type}
            placeholder={props?.placeholder}
            className={`${props?.className} `}
            autoComplete={props?.autoComplete}
            onKeyPress={props?.onKeyPress}
            name={props?.name}
            onChange={props?.onChange}
            onClick={props?.onClick}
            onBlur={props?.onBlur}
            value={props?.value}
            disabled={props?.disabled}
            required={props?.required}
            maxLength={props?.maxLength}
            multiple={props?.multiple}
            accept={props?.accept}
            ref={props?.ref}
            pattern={props?.pattern}
            autoFocus={props?.autoFocus}
            onFocus={props?.onFocus}
            readOnly={props?.readOnly}
            tabIndex={props?.tabIndex}
            defaultValue={props?.defaultvalue}
            max={props?.max}
            min={props?.min}
            key={props?.key}
            id={props?.id}
            onDragOver={props?.onDragOver}
            onDrop={props?.onDrop}
        />
    );
};

export default Input;
