import {
    SEND_LAYOUT_FAILURE,
    SEND_LAYOUT_REQUEST,
    SEND_LAYOUT_RESET,
    SEND_LAYOUT_SUCCESS,
    GET_LAYOUT_COUNTRYBYORG_REQUEST,
    GET_LAYOUT_COUNTRYBYORG_RESET,
    GET_LAYOUT_COUNTRYBYORG_SUCCESS,
    GET_LAYOUT_COUNTRYBYORG_FAILURE,
} from "../types/types";

const intAuthState = {
    data: {},
    fetching: false,
    requestComplete: false,
    error: "",
};
export function layoutReducer(state = intAuthState, action) {
    switch (action.type) {
        case SEND_LAYOUT_REQUEST:
            return {
                ...state,
                fetching: true,
                requestComplete: false,
                error: "",
            };
        case SEND_LAYOUT_SUCCESS:
            return {
                data: action.payload,
                fetching: false,
                requestComplete: true,
                error: "",
            };
        case SEND_LAYOUT_RESET:
            return {
                data: {},
                fetching: false,
                requestComplete: false,
                error: "",
            };
        case SEND_LAYOUT_FAILURE:
            return {
                fetching: false,
                requestComplete: false,
                error: action.payload,
            };
        default:
            return state;
    }
}

const layoutCountryListState = {
    datalist: [],
    fetching: false,
    requestComplete: false,
    error: "",
};
export function layoutCountryList(state = layoutCountryListState, action) {
    switch (action.type) {
        case GET_LAYOUT_COUNTRYBYORG_REQUEST:
            return {
                ...state,
                fetching: true,
                requestComplete: false,
                error: "",
            };
        case GET_LAYOUT_COUNTRYBYORG_SUCCESS:
            return {
                datalist: action.payload,
                fetching: false,
                requestComplete: true,
                error: "",
            };
        case GET_LAYOUT_COUNTRYBYORG_RESET:
            return {
                datalist: [],
                fetching: false,
                requestComplete: false,
                error: "",
            };
        case GET_LAYOUT_COUNTRYBYORG_FAILURE:
            return {
                fetching: false,
                requestComplete: false,
                error: action.payload,
            };
        default:
            return state;
    }
}
