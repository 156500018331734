import {
	SETTING_FAILURE,
	SETTING_REQUEST,
	SETTING_RESET,
	SETTING_SUCCESS,
	SETTING_UPDATE_FAILURE,
	SETTING_UPDATE_REQUEST,
	SETTING_UPDATE_RESET,
	SETTING_UPDATE_SUCCESS,
	SETTING_VALUE_REQUEST,
	SETTING_VALUE_SUCCESS,
	SETTING_VALUE_FAILURE,
	SETTING_VALUE_RESET,
	REMOVE_DEMO_DATA_FAILURE,
	REMOVE_DEMO_DATA_SUCCESS,
	REMOVE_DEMO_DATA_REQUEST,
	REMOVE_DEMO_DATA_RESET,
	BACKUP_DATA_REQUEST,
	BACKUP_DATA_FAILURE,
	BACKUP_DATA_SUCCESS,
	BACKUP_DATA_RESET,
} from "../types/types";

const intAuthsettingState = {
	data: [],
	fetching: false,
	requestComplete: false,
	error: "",
};

const intSettingUpdateState = {
	data: [],
	fetching: false,
	requestComplete: false,
	error: "",
};
const intsettingValueState = {
	data: [],
	fetching: false,
	requestComplete: false,
	error: "",
};

export function settingReducer(state = intAuthsettingState, action) {
	switch (action.type) {
		case SETTING_REQUEST:
			return {
				...state,
				fetching: true,
				requestComplete: false,
				error: "",
			};
		case SETTING_SUCCESS:
			return {
				data: action.payload,
				fetching: false,
				requestComplete: true,
				error: "",
			};
		case SETTING_RESET:
			return {
				data: [],
				fetching: false,
				requestComplete: false,
				error: "",
			};
		case SETTING_FAILURE:
			return {
				fetching: false,
				requestComplete: false,
				error: action.payload,
			};
		default:
			return state;
	}
}

export function settingUpdateReducer(state = intSettingUpdateState, action) {
	switch (action.type) {
		case SETTING_UPDATE_REQUEST:
			return {
				...state,
				fetching: true,
				requestComplete: false,
				error: "",
			};
		case SETTING_UPDATE_SUCCESS:
			return {
				data: action.payload,
				fetching: false,
				requestComplete: true,
				error: "",
			};
		case SETTING_UPDATE_RESET:
			return {
				data: [],
				fetching: false,
				requestComplete: false,
				error: "",
			};
		case SETTING_UPDATE_FAILURE:
			return {
				fetching: false,
				requestComplete: false,
				error: action.payload,
			};
		default:
			return state;
	}
}

export function settingValueReducer(state = intsettingValueState, action) {
	switch (action.type) {
		case SETTING_VALUE_REQUEST:
			return {
				...state,
				fetching: true,
				requestComplete: false,
				error: "",
			};
		case SETTING_VALUE_SUCCESS:
			return {
				data: action.payload,
				fetching: false,
				requestComplete: true,
				error: "",
			};
		case SETTING_VALUE_RESET:
			return {
				data: [],
				fetching: false,
				requestComplete: false,
				error: "",
			};
		case SETTING_VALUE_FAILURE:
			return {
				fetching: false,
				requestComplete: false,
				error: action.payload,
			};
		default:
			return state;
	}
}

const RemoveData = {
	data: {},
	fetching: false,
	requestComplete: false,
	error: "",
};

export function RemoveDemoDataState(state = RemoveData, action) {
	switch (action.type) {
		case REMOVE_DEMO_DATA_REQUEST:
			return {
				...state,
				fetching: true,
				requestComplete: false,
				error: "",
			};
		case REMOVE_DEMO_DATA_SUCCESS:
			return {
				data: action.payload,
				fetching: false,
				requestComplete: true,
				error: "",
			};
		case REMOVE_DEMO_DATA_RESET:
			return {
				data: [],
				fetching: false,
				requestComplete: false,
				error: "",
			};
		case REMOVE_DEMO_DATA_FAILURE:
			return {
				fetching: false,
				requestComplete: false,
				error: action.payload,
			};
		default:
			return state;
	}
}

const BackupData = {
	data: {},
	fetching: false,
	requestComplete: false,
	error: "",
};

export function BackupDataRedux(state = BackupData, action) {
	switch (action.type) {
		case BACKUP_DATA_REQUEST:
			return {
				...state,
				fetching: true,
				requestComplete: false,
				error: "",
			};
		case BACKUP_DATA_SUCCESS:
			return {
				data: action.payload,
				fetching: false,
				requestComplete: true,
				error: "",
			};
		case BACKUP_DATA_RESET:
			return {
				data: [],
				fetching: false,
				requestComplete: false,
				error: "",
			};
		case BACKUP_DATA_FAILURE:
			return {
				fetching: false,
				requestComplete: false,
				error: action.payload,
			};
		default:
			return state;
	}
}
