import { FormControl, Input, MenuItem, Select, TextField } from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Button from "../../components/Button";
import Card from "../../components/UI/Card";
import Main from "../../components/UI/Main";
import Layout from "../../Hoc/Layout";
import Footer from "../../organisms/Footer";
import { useDispatch, useSelector } from "react-redux";
import { GetAccrualValidatePostAct, GetMonthClosingAction, MonthClosingPostAction } from "../../redux/actions/accrual-accounting";
import MessageBox from "../../components/UI/Alert";
import SuccessMessageBox from "../../components/UI/Alert/sucess";
import Swal from "sweetalert2";
import { GET_ACCRUAL_VALIDATE_RESET, POST_MONTH_CLOSING_RESET } from "../../redux/types/types";
import Loader from "../../components/Loader";
import { getCurrentUser } from "../../redux/actions/auth";
import { ThemeContext } from "../../Hooks/Themes/Themeprovider";
import moment from "moment";
import { async } from "@firebase/util";
let LastDate = "";
let FirstDate = "";
const MonthEndClosing = (props) => {
    const { currentMode } = useContext(ThemeContext);
    const successMsg = "Updated Successfully";
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const CurrentUser = getCurrentUser();
    const { data, requestComplete, fetching } = useSelector((state) => state?.postMonthEndClosingState);
    const { data: dataList, requestComplete: reqComplete, fetching: fetchingGet } = useSelector((state) => state?.getMonthEndClosingState);
    const { data: GetdataList, requestComplete: GetreqComplete, fetching: fetchingGetValue } = useSelector((state) => state?.GetAccrualValidateState);
    const ValidateDate = GetdataList?.[0];
    const IsaccrualPost = ValidateDate?.is_accrual_post;
    const orgData = useSelector((state) => state?.ListorganisationDropDownState);
    const orgId = localStorage.getItem("orgId");
    const OrgStorageName = localStorage.getItem("OrganisationName");
    const OrgdateFormat = localStorage.getItem("OrgDateFormat");
    const [orgNamedrop, setOrgNamedrop] = useState(orgId);
    const [OrgName, setOrgname] = useState(OrgStorageName);
    const [Edit, setEdit] = useState(false);
    const dateStringOpen = dataList?.[0]?.month_opened;
    const dateStringClose = dataList?.[0]?.month_closed;
    const dateStringEndsOn = dataList?.[0]?.ends_on;
    // const IsAccrualPost = dataList?.[0]?.is_accrualpost
    const [MonthOpened, setMonthOpened] = useState(dateStringOpen);
    const [MonthClosed, setMonthClose] = useState(dateStringClose);
    const [EndsOn, setEndsOn] = useState(dateStringEndsOn);
    const orgList = orgData?.viewData;
    useEffect(() => {
        setMonthClose(dateStringClose == null ? "" : dateStringClose);
        setMonthOpened(dateStringOpen == null ? "" : dateStringOpen);
        setEndsOn(dateStringEndsOn == null ? "" : dateStringEndsOn);
    }, [dateStringOpen, dateStringClose, dateStringEndsOn]);
    const reqObj = {
        org_id: +orgNamedrop,
        month_opened: moment(new Date(MonthOpened))?.format("YYYY-MM-DD"),
        ends_on: moment(new Date(EndsOn))?.format("YYYY-MM-DD"),
        month_closed: moment(new Date(MonthClosed))?.format("YYYY-MM-DD"),
        createdby: CurrentUser?.id,
        modifiedby: 0,
    };
    // Parse the date string into a Date object
    const MonthClosedDate = moment(new Date(MonthClosed))?.format("YYYY-MM-DD");
    const MonthCloseObject = new Date(MonthClosedDate);

    // Get the month name
    const monthName = MonthCloseObject?.toLocaleString("en-US", {
        month: "long",
    });

    const [submit, setSubmit] = useState(false);

    const handlePosting = async () => {
        setSubmit(true);
        if (!Edit && MonthClosed !== "" && MonthOpened !== "" && EndsOn !== "") {
            Swal.fire({
                text: "Make any necessary changes to save!",
                icon: "warning",
                closeOnClickOutside: false,
                showCloseButton: true,
                buttons: {
                    confirm: {
                        text: "Ok",
                        value: true,
                        visible: true,
                    },
                },
            });
        } else if (!Edit && MonthClosed == "" && MonthOpened == "" && EndsOn == "") {
            Swal.fire({
                text: "Fill out the required data!",
                icon: "warning",
                closeOnClickOutside: false,
                showCloseButton: true,
                buttons: {
                    confirm: {
                        text: "Ok",
                        value: true,
                        visible: true,
                    },
                },
            });
        } else {
            dispatch(GetAccrualValidatePostAct(orgNamedrop, MonthClosedDate));
        }
    };

    if (requestComplete) {
        setTimeout(() => {
            dispatch({ type: POST_MONTH_CLOSING_RESET });
            dispatch({ type: GET_ACCRUAL_VALIDATE_RESET });
        }, 2000);
    }
    useEffect(() => {
        if (orgNamedrop !== 0) {
            dispatch(GetMonthClosingAction(orgNamedrop));
        }
    }, []);

    useEffect(() => {
        if (IsaccrualPost == false && GetreqComplete && submit) {
            Swal.fire({
                text: `You haven't posted accrual for ${monthName} month . Do you wish to post accrual or continue closing the month?`,
                icon: "warning",
                closeOnClickOutside: false,
                showCloseButton: true,
                showCancelButton: true,
                confirmButtonText: "Continue", // Rename the confirm button
                cancelButtonText: "Accrual",
                buttons: {
                    cancel: "Cancel",
                    confirm: "Confirm",
                },
            }).then((confirm) => {
                if (confirm?.isConfirmed) {
                    dispatch(MonthClosingPostAction(reqObj));
                    setSubmit(false);
                } else {
                    navigate(`/accrual-secondary`);
                    setSubmit(false);
                }
            });
        } else if (submit && GetreqComplete) {
            setSubmit(false);
            dispatch(MonthClosingPostAction(reqObj));
        }
    }, [IsaccrualPost]);

    const AccessIcon = () => <img src="/images/common/date-ico.svg" alt="date-ico" />;
    const onChangeOrganisation = (e) => {
        if (+e.target.value !== 0) {
            setMonthClose("");
            setMonthOpened("");
            setEndsOn("");
            setSubmit(false);
            setEdit(false);
            const setName = orgList?.find((el) => el?.id == +e.target.value)?.organisation_name;
            setOrgNamedrop(+e.target.value);
            setOrgname(setName);
            dispatch(GetMonthClosingAction(+e.target.value));
        }
    };
    function getLastDayOfMonth(year, month) {
        return new Date(year, month + 1, 0);
    }
    function getFirstDayOfMonth(year, month) {
        return new Date(year, month, 1);
    }

    // Given date string
    const dateString = MonthClosed;

    // Parse the date string into a Date object
    const dateObject = new Date(dateString);

    // Get the month and year
    const month = dateObject.getMonth() + 1; // Months are zero-based, so add 1
    const year = dateObject.getFullYear();
    if (Edit == false) {
        LastDate = getLastDayOfMonth(year, month);
        FirstDate = getFirstDayOfMonth(year, month);
    }

    const onChangeMonthOpen = (date) => {
        setEdit(true);
        setMonthOpened(date);
        var makeDate = new Date(date);
        makeDate = new Date(makeDate?.setMonth(makeDate?.getMonth() - 1));
        makeDate = new Date(makeDate?.setDate(1));
        setMonthClose(makeDate);
        const year = date?.$y;
        const month = date?.$M;
        LastDate = getLastDayOfMonth(year, month);
        FirstDate = getFirstDayOfMonth(year, month);
        const otherDate = LastDate;
        const currentDate = new Date();
        if (otherDate > currentDate) {
            setEndsOn(new Date());
        } else {
            setEndsOn(LastDate);
        }
    };

    const onChangeMonthClose = (date) => {
        setEdit(true);
        setMonthClose(date);
        var makeNextDate = new Date(date);
        makeNextDate = new Date(makeNextDate?.setMonth(makeNextDate?.getMonth() + 1));
        makeNextDate = new Date(makeNextDate?.setDate(1));
        setMonthOpened(makeNextDate);
        const month = makeNextDate?.toLocaleString("default", {
            month: "short",
        });
        const year = makeNextDate?.getFullYear();
        const monthNumber = {
            Jan: 0,
            Feb: 1,
            Mar: 2,
            Apr: 3,
            May: 4,
            Jun: 5,
            Jul: 6,
            Aug: 7,
            Sept: 8,
            Oct: 9,
            Nov: 10,
            Dec: 11,
        }[month];
        const LastDates = getLastDayOfMonth(year, monthNumber);
        LastDate = getLastDayOfMonth(year, monthNumber);
        FirstDate = getFirstDayOfMonth(year, monthNumber);
        setEndsOn(LastDates);
    };
    const setTransactionChange = (date) => {
        setEdit(true);
        setEndsOn(date);
        var makeDate = new Date(date);
        makeDate = new Date(makeDate?.setDate(1));
        setMonthOpened(makeDate);
        var makeNextDate = new Date(date);
        makeNextDate = new Date(makeNextDate?.setMonth(makeNextDate?.getMonth() - 1));
        makeNextDate = new Date(makeNextDate?.setDate(1));
        setMonthClose(makeNextDate);
    };
    const dropdownColor = currentMode === "dark" ? "#333333" : `white`;
    const dropdownText = currentMode === "dark" ? "#ECECEC" : `#555555`;
    const Reset = () => {
        dispatch(GetMonthClosingAction(orgNamedrop));
        setMonthClose(dateStringClose == undefined ? "" : dateStringClose);
        setMonthOpened(dateStringOpen == undefined ? "" : dateStringOpen);
        setEndsOn(dateStringEndsOn == undefined ? "" : dateStringEndsOn);
        setEdit(false);
    };
    const disableButton = fetching || orgNamedrop == 0 || fetchingGetValue;
    return (
        <>
            <Layout>
                {requestComplete && data?.status && <SuccessMessageBox success={successMsg} />}
                {requestComplete && !data?.status && <MessageBox error={data?.message} />}
                <Main>
                    <div className={`flex justify-between items-center mb-4 `}>
                        <h4 className={`text-2xl font-interSb text-${currentMode}-copy`}>Month Closing</h4>
                    </div>
                    <Card
                        className={`h-[calc(100vh_-_22rem)] xl:h-[calc(100vh_-_18rem)] p-[30px] relative border-b-0 border-${currentMode}-700 rounded-t-[10px] rounded-b-[0] overflow-y-auto ${
                            currentMode === "dark" ? "" : "shadow-[0px_4px_20px_rgba(238,238,238,0.5)]"
                        } max-h-[-webkit-fill-available] relative`}
                    >
                        {fetchingGet || orgData?.fetching ? (
                            <div className="h-[300px] absolute left-[35%] translate-x[-35%] flex items-center justify-center">
                                <Loader className="flex items-center justify-center" />
                            </div>
                        ) : (
                            <>
                                <div
                                    class={`bg-${currentMode}card border py-[12px] px-[19px] rounded-[8px] border-l-[7px] border-l-${currentMode}-20 border-${currentMode}-1140 ${
                                        currentMode === "dark" ? "" : ` shadow-[0px_4px_20px] shadow-${currentMode}-1130`
                                    } `}
                                >
                                    <p className="font-interM mr-5">
                                        {" "}
                                        In accounting, a monthly close is a series of steps a business follows to review, record, and reconcile
                                        account information.
                                    </p>
                                </div>
                                <div className="mt-[22px]">
                                    <span className="font-interM mr-5">Organization</span>
                                    <div className="flex items-center mb-5 mt-3">
                                        <FormControl sx={{ minWidth: 300 }}>
                                            <Select
                                                name="orgName"
                                                value={orgNamedrop || "0"}
                                                fullWidth
                                                title={orgList?.find((el) => el?.id == orgNamedrop)?.organisation_name}
                                                onChange={(e) => onChangeOrganisation(e)}
                                                inputProps={{
                                                    "aria-label": "Without label",
                                                    tabIndex: "1",
                                                }}
                                                IconComponent={() => (
                                                    <img
                                                        className="absolute right-[15px] opacity-50 pointer-events-none"
                                                        src={
                                                            currentMode !== "dark"
                                                                ? `/images/header/chevronDown.svg`
                                                                : `/images/header/chevronDown-white.svg`
                                                        }
                                                        alt="chevronDown"
                                                    />
                                                )}
                                                MenuProps={{
                                                    sx: {
                                                        "& .MuiMenu-paper": {
                                                            backgroundColor: dropdownColor,
                                                            color: dropdownText,
                                                            maxWidth: "24rem !important",
                                                            top: "170px !important",
                                                            maxHeight: "30rem",
                                                        },
                                                        "& .MuiMenuItem-root:hover": {
                                                            backgroundColor: "rgba(218, 249, 244, 0.3)",
                                                        },
                                                        "& .Mui-selected": {
                                                            backgroundColor: "rgba(218, 249, 244, 0.3) !important",
                                                        },
                                                    },
                                                }}
                                                SelectDisplayProps={{
                                                    style: {
                                                        paddingTop: "9px",
                                                        paddingBottom: "9px",
                                                        color: `${currentMode !== "dark" ? `#555555` : `#ECECEC`}`,
                                                    },
                                                }}
                                            >
                                                <MenuItem key={0} name="OrganisationList" value={0}>
                                                    {"Select Organization"}
                                                </MenuItem>
                                                {orgList &&
                                                    orgList?.map((elem, i) => {
                                                        return (
                                                            <MenuItem
                                                                name="organisation_name"
                                                                key={i + 1}
                                                                tabIndex={0}
                                                                value={elem?.id}
                                                                title={elem?.organisation_name}
                                                                className="truncate trunc-1 block"
                                                            >
                                                                {elem?.organisation_name}
                                                            </MenuItem>
                                                        );
                                                    })}
                                            </Select>
                                        </FormControl>
                                    </div>
                                </div>

                                <span className="font-interM mr-5">Month Opened:</span>
                                <div className="ml-[33px]">
                                    <div className="flex items-center mb-5 mt-5">
                                        <span className="font-inter mr-5">Month opened</span>
                                        <div className={` datePic `} title="Calendar">
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DesktopDatePicker
                                                    className={`border-0`}
                                                    views={["year", "month"]}
                                                    placeholder="Month"
                                                    value={MonthOpened}
                                                    maxDate={new Date()}
                                                    onChange={(e) => onChangeMonthOpen(e)}
                                                    components={{
                                                        OpenPickerIcon: AccessIcon,
                                                    }}
                                                    sx={{
                                                        ".MuiOutlinedInput-input": {
                                                            padding: "10px",
                                                        },
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            sx={{
                                                                "& .MuiInputBase-input": {
                                                                    color: `${currentMode !== "dark" ? `#555555` : `#ECECEC`}`,
                                                                },
                                                            }}
                                                            {...params}
                                                            inputProps={{
                                                                ...params.inputProps,
                                                                placeholder: "mm/yyyy",
                                                                readOnly: true,
                                                            }}
                                                        />
                                                    )}
                                                />
                                            </LocalizationProvider>
                                        </div>
                                    </div>

                                    <div className="flex items-center mb-5">
                                        <span className="font-inter mr-5 text-left">
                                            Record the
                                            <br />
                                            transaction on
                                        </span>
                                        <div className={`datePic`} title="Calendar">
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DesktopDatePicker
                                                    className={`border-0`}
                                                    components={{
                                                        OpenPickerIcon: AccessIcon,
                                                    }}
                                                    inputFormat={CurrentUser?.user_date?.toUpperCase()}
                                                    value={EndsOn}
                                                    minDate={FirstDate}
                                                    // maxDate={LastDate == "Invalid Date" ? new Date() : LastDate}
                                                    maxDate={new Date()}
                                                    onChange={(e) => setTransactionChange(e)}
                                                    sx={{
                                                        ".MuiOutlinedInput-input": {
                                                            padding: "10px",
                                                        },
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            sx={{
                                                                "& .MuiInputBase-input": {
                                                                    color: `${currentMode !== "dark" ? `#555555` : `#ECECEC`}`,
                                                                },
                                                            }}
                                                            placeholder={params.inputProps?.placeholder?.replace("To")}
                                                            {...params}
                                                            inputProps={{
                                                                ...params.inputProps,
                                                                readOnly: true,
                                                            }}
                                                        />
                                                    )}
                                                />
                                            </LocalizationProvider>
                                        </div>
                                    </div>
                                </div>
                                <span className="font-interM mr-5">Month Closed:</span>
                                <div className="flex items-center mb-5 mt-3">
                                    <div className={`datePic`} title="Calendar">
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DesktopDatePicker
                                                className={`border-0`}
                                                views={["year", "month"]}
                                                value={MonthClosed}
                                                maxDate={new Date()}
                                                components={{
                                                    OpenPickerIcon: AccessIcon,
                                                }}
                                                sx={{
                                                    ".MuiOutlinedInput-input": {
                                                        padding: "10px",
                                                    },
                                                }}
                                                onChange={(e) => onChangeMonthClose(e)}
                                                renderInput={(params) => (
                                                    <TextField
                                                        sx={{
                                                            "& .MuiInputBase-input": {
                                                                color: `${currentMode !== "dark" ? `#555555` : `#ECECEC`}`,
                                                            },
                                                        }}
                                                        // placeholder="Select a date"
                                                        {...params}
                                                        inputProps={{
                                                            ...params.inputProps,
                                                            placeholder: "mm/yyyy",
                                                            readOnly: true,
                                                        }}
                                                    />
                                                )}
                                            />
                                        </LocalizationProvider>
                                    </div>
                                </div>
                            </>
                        )}
                    </Card>

                    <div
                        className={`bg-${currentMode}-card text-${currentMode}-copy border-${currentMode}-700 py-[20px] space-x-2 px-[30px] sticky left-0 right-0 shadow-[0px_4px_20px_rgba(85,85,85,0.2)] border-[1px] border-[#dfe1e28f] rounded-b-[10px]`}
                    >
                        <Button
                            onClick={handlePosting}
                            disabled={disableButton}
                            title="Update"
                            className="bg-light-20 font-interSb text-base rounded-[8px] px-[35px] py-[8px] text-white"
                        >
                            Update
                        </Button>
                        <Button
                            onClick={Reset}
                            disabled={orgNamedrop == 0}
                            title="Reset"
                            className="bg-light-240 ml-[10px] text-base font-interR px-[30px] py-[8px] rounded-[8px] text-light-40"
                        >
                            Reset
                        </Button>
                    </div>
                </Main>
                <Footer />
            </Layout>
        </>
    );
};

export default MonthEndClosing;
