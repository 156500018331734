import React from "react";
import axios from "axios";

const Token = localStorage.getItem("token");

class Uploadphoto extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Id: "86",
      Userid: "41",
      Image: [],
    };
  }

  onChange = (e) => {
    debugger;
    let file = e.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onload = this._handleReaderLoaded.bind(this);
      reader.readAsBinaryString(file);
    }
  };

  _handleReaderLoaded = (e) => {
    let binaryString = e.target.result;
    this.setState({
      Image: btoa(binaryString),
    });
  };
  handleFile(e) {
    let Image = e.target.files[0];

    this.setState({ Image: [Image] });
  }
  handleUpload() {
    let Id = this.state.Id;
    let Userid = this.state.Userid;
    let Image = this.state.Image;

    let formdata = new FormData();

    formdata.append("Id", Id);
    formdata.append("Userid", Userid);
    formdata.append("Image", Image);

    axios({
      url: "http://18.133.78.222/User/UploadPhoto",
      method: "POST",
      headers: {
        authorization: `Bearer ${Token}`,
      },
      data: formdata,
    }).then(
      (res) => {},
      (err) => {}
    );
  }

  render() {
    const { Image } = this.state;
    return <>
        <input
          type="file"
          name="image"
          className="absolute opacity-0 z-10"
          id="file"
          accept=".jpg, .jpeg, .png"
          onChange={(e) => this.onChange(e) - this.handleFile(e)}
        />
            {/* <img
              className="w-100"
              src={`/images/common/profileImg.png`}
            /> */}
          {(
            <img
              className="w-100"
              src={
                Image?.length !== 0
                  ? `data:image;base64,${Image}`
                  : `/images/common/profileImg.png`
              }
            />
          )}
    </>
  }
}

export default Uploadphoto;
