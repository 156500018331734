import _ from "lodash";
import { memo, useCallback, useMemo, useState } from "react";
import { useDrop } from "react-dnd";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import Input from "../../components/Input";
import Swal from "sweetalert2";

export const XmlTarget = memo(function Container({ data, currentMode, onDrop, areaName, index, handleChange, name, isDisable, mainData }) {
    const { xmlValues, xmlData } = useSelector((state) => state?.droppedState);
    const [dropped, setDropped] = useState([]);
    const resultArray = _.uniqBy(xmlValues, "column_name");
    useMemo(() => {
        setDropped(resultArray);
    }, [xmlValues]);
    const findCard = useCallback(
        (id) => {
            const card = dropped.filter((c) => `${c.id}` === id)[0];
            return {
                card,
                index: dropped.indexOf(card),
            };
        },
        [dropped]
    );
    const moveCard = useCallback(
        (id, atIndex) => {
            const { card, index } = findCard(id);
            dispatch({ type: "MOVE_THE_CARD", payload: { card, index, atIndex } }); //This action for after card swapped original array should be modified!!
        },
        [findCard, dropped, setDropped]
    );
    const [, drop] = useDrop(() => ({ accept: "card" }));
    const dispatch = useDispatch();
    const IsNormal = mainData?.parent_id == "" && mainData?.isChild == false;
    const CheckTracked = typeof mainData?.content == "object" ? true : false;
    const [{ isOver }, drag] = useDrop({
        accept: "DRAGGABLE_ITEM",
        drop: (item, i) => {
            onDrop(item?.value, areaName, index, mainData?.parent_id, mainData?.isChild, CheckTracked);
        },
        collect: (monitor) => ({
            isOver: !!monitor.isOver(),
        }),
    });

    const _eRase = () => {
        Swal.fire({
            text: `Are you sure you want  to erase the field value?`,
            icon: "warning",
            showCloseButton: true,
            showCancelButton: true,
            buttons: {
                cancel: "Cancel",
                confirm: "Confirm",
            },
        }).then((confirm) => {
            if (confirm?.isConfirmed) {
                // if()
                const getData = xmlData?.filter((el) => el?.id != mainData?.id && el?.parent_id == mainData?.parent_id);
                const allHaveMultipleObjects = getData?.some((item) => typeof item?.content == "object" && item?.content?.multiple_objects);
                handleChange(index, "content", "", mainData?.parent_id, allHaveMultipleObjects);
            }
        });
    };
    return (
        <div ref={drag}>
            <div ref={drop} className={`flex relative flex-wrap items-center w-full`}>
                <Input
                    value={data}
                    disabled={mainData?.isChild}
                    className={`px-[20px]  ${isOver ? "bg-[#f0f0f0]" : `bg-${currentMode}-card`} ${CheckTracked ? `pointer-events-none` : ``
                        }  py-[9px]  w-full`}
                    name={name}
                    onChange={(e) => handleChange(index, "content", e?.target?.value, mainData?.parent_id)}
                    placeholder={!mainData?.isChild ? `Type here / Drag and Drop` : ``}
                />
                {CheckTracked && (
                    <img
                        title="Click the clear formatting to remove the text."
                        className="absolute cursor-pointer right-[20px]"
                        onClick={_eRase}
                        src="/images/Output-manage/clear-format.svg"
                        alt="clear-format"
                    />
                )}
            </div>
        </div>
    );
});
