// import { Tooltip } from "@mui/material";
import { ClickAwayListener } from "@mui/material";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import moment from "moment";
import { useState, useEffect, useContext } from "react";
import { Link, useSearchParams } from "react-router-dom";
import Button from "../../components/Button";
import Card from "../../components/UI/Card";
import VerticalStepper from "../../components/VerticalStepper";
import { useDispatch, useSelector } from "react-redux";
import { ActivityHistory } from "../../redux/actions/auth";
import Input from "../../components/Input";
import { InvoiceCompare } from "../../redux/actions/invoice";
import { includes } from "lodash";
import { decryptId } from "../../utils/helpers";
import Loader from "../../components/Loader";
import { ThemeContext } from "../../Hooks/Themes/Themeprovider";
import { useLocation } from "react-router-dom";

const ActivityModal = (props) => {
    const { currentMode, toggleMode } = useContext(ThemeContext);
    const dispatch = useDispatch();
    const [filteredResults, setFilteredResults] = useState([]);
    const SearchLength = filteredResults?.length;
    const [searchInput, setSearchInput] = useState("");
    const [popstate, setPopState] = useState({ id: null, anchorEl: null });
    const [placement, setPlacement] = useState();
    const [invNo, getQueryStringVal] = useState("");
    const Activitydata = useSelector((state) => state?.ActivityHistoryState);
    const { data: actData, fetching } = Activitydata;
    const CompareData = useSelector((state) => state?.InvoiceCompareState);
    const { data: compdata } = CompareData;
    const modifylogo = actData?.map((elem) => elem?.action);
    const searchItems = (searchValue) => {
        setSearchInput(searchValue);
        if (searchValue !== "") {
            const filteredData = actData.filter((item) => {
                // return Object.values(item)
                // .join("")
                //.toLowerCase()
                //.includes(searchInput.toLowerCase());
                const getEmail = decryptId(item?.useremail);
                if (item?.username?.toLowerCase().includes(searchValue.toLowerCase())) {
                    return item;
                } else if (getEmail?.toLowerCase().includes(searchValue.toLowerCase())) {
                    return item;
                } else if (item?.action.toLowerCase().includes(searchValue.toLowerCase())) {
                    return item;
                } else if (item?.remarks.toLowerCase().includes(searchValue.toLowerCase())) {
                    return item;
                } else if (item?.modified_time.toLowerCase().includes(searchValue.toLowerCase())) {
                    return item;
                }
            });
            setFilteredResults(filteredData);
        } else {
            setFilteredResults(actData);
        }
    };

    const resetInputField = () => {
        searchItems("");
    };

    const [open, setOpen] = useState(false);

    const handleClick = (id, newPlacement, event) => {
        setPopState({ id, anchorEl: event.currentTarget }, (prev) => placement !== newPlacement || !prev);

        setPlacement(newPlacement);
        setOpen(true);
    };

    const handleTooltipClose = () => {
        // setSearchInput("");
        setOpen(false);
    };
    const userDateFormat = localStorage.getItem("userDateFormat");
    // const handleTooltipOpen = (id) => {
    //   setOpen(id);
    // };
    // const handleClickAway = () => {
    //   setPopState({ id: false, anchorEl: false });
    // };
    const LightTooltip = styled(({ className, ...props }) => (
        <Tooltip
            {...props}
            placement="top-start"
            PopperProps={{
                sx: {
                    "& .MuiTooltip-tooltip": {
                        padding: "0 5px",
                    },
                },
                modifiers: [
                    {
                        name: "offset",
                        options: {
                            offset: [-14, -80],
                        },
                    },
                ],
            }}
            classes={{ popper: className }}
        />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: `${currentMode === "dark" ? `#1F1F1F` : theme.palette.common.white}`,
            color: "rgba(0, 0, 0, 0.87)",
            borderRadius: "10px",
            minWidth: "250px",
        },
    }));
    useEffect(() => {
        if (props?.cartClose) {
            setSearchInput("");
            setOpen(false);
        }
    }, [props]);
    const location = useLocation();
    const Details = (props) => (
        <>
            <Card
                className={`flex w-full mb-3 items-center justify-between ${
                    currentMode === "dark" ? "" : "shadow-[0px_4px_20px_rgba(238,238,238,0.5)]"
                } rounded-[10px] px-2 pt-3 pb-4`}
            >
                {props?.children}
                {props?.action === "update" &&
                    location.pathname !== "/miscellaneous-request" &&
                    location.pathname !== "/adhoc-advance-request" &&
                    location.pathname !== "/retention-release" &&
                    location.pathname !== "/mobilization-request" &&
                    location.pathname !== "/proforma-invoice-view" && (
                        <>
                            {/* <ClickAwayListener
              mouseEvent="onMouseDown"
              touchEvent="onTouchStart"
              onClickAway={handleTooltipClose}
            > */}
                            <Tooltip
                                arrow
                                TransitionProps={{ timeout: 0 }}
                                open={props?.index === popstate.id && open}
                                anchorEl={popstate.anchorEl}
                                id={props?.index}
                                placement={placement}
                                PopperProps={{
                                    sx: {
                                        "& .MuiTooltip-tooltip": {
                                            padding: "0",
                                            margin: 0,
                                            backgroundColor: "transparent",
                                            color: "#555555",
                                            maxWidth: "550px",
                                            maxHeight: "500px",
                                            overflowY: "auto",
                                            borderRadius: "8px",
                                            // border: ".5px solid rgba(223, 225, 226, 0.56)",
                                            // boxShadow: "0px 4px 20px rgba(178, 178, 178, 0.5)",
                                        },
                                        "& .MuiTooltip-arrow": {
                                            width: "30px",
                                            height: "17px",
                                            marginTop: "-18px !important",
                                            "&::before": {
                                                backgroundColor: "#229988",
                                            },
                                        },
                                    },
                                    modifiers: [
                                        {
                                            name: "offset",
                                            options: {
                                                offset: [0, 0],
                                            },
                                        },
                                    ],
                                }}
                                //onClose={handleTooltipClose}
                                className={`${
                                    currentMode === "dark" ? "" : "shadow-[0px_4px_20px_rgba(178,178,178,0.5)]"
                                } z-[111] rounded-[8px] border-[1px] bg-${currentMode}-card text-${currentMode}-copy border-${currentMode}-700`}
                                disableFocusListener
                                disableHoverListener
                                disableTouchListener
                                title={
                                    <div className="relative">
                                        <span className="cursor-pointer p-[6.5px] rounded-[0] fixed top-[4px] left-[-25px] z-[999] bg-light-10">
                                            <img src="/images/common/whiteClose.svg" onClick={handleTooltipClose} alt="whiteClose" />
                                        </span>
                                        <table className="compareTbl">
                                            <thead className="sticky top-0 z-[999]">
                                                <tr>
                                                    <th>Field name</th>
                                                    <th>Before modification</th>
                                                    <th>After modification</th>
                                                </tr>
                                            </thead>

                                            <tbody className="whitespace-nowrap">
                                                {compdata &&
                                                    compdata?.map((elem) => {
                                                        return (
                                                            <>
                                                                <tr>
                                                                    <td className="bg-light-200">{elem?.label_name}</td>
                                                                    <td>{elem?.previous_value}</td>
                                                                    <td>
                                                                        <span className="bg-light-210">{elem?.current_value}</span>
                                                                    </td>
                                                                </tr>
                                                            </>
                                                        );
                                                    })}
                                                {compdata.length == 0 && (
                                                    <tr>
                                                        <td
                                                            colSpan={12}
                                                            style={{
                                                                textAlign: "center",
                                                            }}
                                                        >
                                                            No Datas Found
                                                        </td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                        {CompareData?.fetching && <Loader className="h-full bg-white absolute top-[63px]" />}
                                    </div>
                                }
                            >
                                <div
                                    id={props?.index}
                                    onClick={() =>
                                        handleClick(
                                            props?.index,
                                            "bottom-end",
                                            dispatch(
                                                InvoiceCompare({
                                                    history_id: props?.transaction_key,
                                                })
                                            )
                                        )
                                    }
                                    className="bg-light-220 cursor-pointer grid place-items-center w-[38px] h-[38px] rounded-full  "
                                >
                                    <img src="/images/common/compare.svg" alt="libra" />
                                </div>
                            </Tooltip>
                            {/* </ClickAwayListener> */}
                        </>
                    )}
            </Card>
        </>
    );
    return (
        <>
            <div
                className={`bg-${currentMode}-card text-${currentMode}-copy   px-[30px] py-[24px] border-b-[1px] flex justify-between items-center  mb-[1.8rem] relative`}
            >
                <h4 className="font-interSb text-lg">Recent Activity</h4>
                <div
                    className={`bg-light-230 relative flex justify-between py-2 px-[16px] xl:max-w-[250px] rounded-[8px] border border-${currentMode}-840`}
                >
                    <Input
                        placeholder="Search..."
                        className="border-0 bg-transparent"
                        disableUnderline={true}
                        autoComplete="off"
                        value={searchInput}
                        onChange={(e) => searchItems(e.target.value)}
                    />
                    {searchInput && searchInput.length !== 0 ? (
                        <img onClick={resetInputField} src="/images/common/closeBlk.svg" alt="close" className="convertWhite" />
                    ) : (
                        <img src="/images/header/search.svg" />
                    )}
                    {/* {(
            <img
              src="/images/header/search.svg"
              alt="close"
            />
          )} */}
                </div>
            </div>
            {fetching ? (
                <Loader className="h-[calc(100vh_-_15rem)]" />
            ) : (
                <div>
                    {searchInput?.length > 1
                        ? filteredResults?.map((elem, index) => {
                              const actvDate = moment(elem?.modified_date, userDateFormat?.toUpperCase())?._d;
                              const today = moment(new Date(), userDateFormat?.toUpperCase());
                              const checkDate = actvDate.toString().slice(0, -40) === today.toString().slice(0, -18);
                              return (
                                  <>
                                      <div className="pl-[25px] pr-[30px]">
                                          <VerticalStepper
                                              img={`${
                                                  elem?.action === "insert"
                                                      ? `/images/common/inserted.svg`
                                                      : elem?.action === "rejected"
                                                      ? "/images/common/rejected.svg"
                                                      : elem?.action === "update"
                                                      ? "/images/common/updated.svg"
                                                      : elem?.action === "approved"
                                                      ? "/images/common/approved.svg"
                                                      : elem?.action === "validate"
                                                      ? "/images/common/approved.svg"
                                                      : elem?.action === "flag"
                                                      ? "/images/common/flag.svg"
                                                      : elem?.action === "unflag"
                                                      ? "/images/common/unflag.svg"
                                                      : elem?.action === "view"
                                                      ? "/images/main/view-ico.svg"
                                                      : elem?.action === "compared"
                                                      ? "/images/common/compare.svg"
                                                      : elem?.action === "invoice transfer"
                                                      ? "/images/common/change-ownership.svg"
                                                      : ""
                                              } `}
                                              card={
                                                  <>
                                                      <Details action={elem?.action} index={index} transaction_key={elem?.transaction_key}>
                                                          <div className="flex">
                                                              <LightTooltip
                                                                  title={
                                                                      <div
                                                                          className={`flex items-start p-3 rounded-[10px] shadow-[0px_4px_20px_rgba(85,85,85,0.2)] text-${currentMode}-copy`}
                                                                      >
                                                                          <img
                                                                              className="w-[32px] h-[32px] object-contain"
                                                                              src={
                                                                                  elem?.userimage
                                                                                      ? `data:image;base64,${elem?.userimage}`
                                                                                      : "/images/common/profileImg.jpg"
                                                                              }
                                                                              alt="Profile"
                                                                          />
                                                                          <div className={`ml-3 `}>
                                                                              <span
                                                                                  title={elem?.username}
                                                                                  className={`text-light-10 block max-w-[240px] truncate text-sm`}
                                                                              >
                                                                                  {elem?.username}
                                                                              </span>
                                                                              <p className={`text-xs`}>{decryptId(elem?.useremail)}</p>
                                                                              <p className="text-xs">Role: {elem?.userrole}</p>{" "}
                                                                          </div>
                                                                      </div>
                                                                  }
                                                              >
                                                                  <img
                                                                      className="cursor-pointer rounded-full w-[28px] h-[28px] object-cover"
                                                                      src={
                                                                          elem?.userimage
                                                                              ? `data:image;base64,${elem?.userimage}`
                                                                              : `/images/common/profileImg.jpg`
                                                                      }
                                                                      alt="user-1"
                                                                  />
                                                              </LightTooltip>
                                                              <div className="ml-3">
                                                                  <p>
                                                                      <span
                                                                          title={elem?.username}
                                                                          className="font-interSb block max-w-[240px] truncate"
                                                                      >
                                                                          {elem?.username}
                                                                      </span>{" "}
                                                                      <em className="text-light-20 not-italic"> {elem?.action}</em>{" "}
                                                                      {`${
                                                                          location.pathname === "/adhoc-advance-request" ||
                                                                          location.pathname === "/mobilization-request" ||
                                                                          location.pathname === "/retention-release" ||
                                                                          location.pathname === "/miscellaneous-request"
                                                                              ? "the request"
                                                                              : "the invoice"
                                                                      }`}
                                                                  </p>
                                                                  <p className="text-light-20 not-italic truncate w-[250px]"> {elem?.remarks}</p>
                                                                  <span className="text-light-30 text-xs">
                                                                      {checkDate == false ? elem?.modified_date : ""} at {elem?.modified_time}
                                                                  </span>
                                                              </div>
                                                          </div>
                                                      </Details>
                                                  </>
                                              }
                                          />
                                      </div>
                                  </>
                              );
                          })
                        : actData?.length !== 0
                        ? actData?.map((elem, index) => {
                              const actvDate = moment(elem?.modified_date, userDateFormat?.toUpperCase())?._d;
                              const today = moment(new Date(), userDateFormat?.toUpperCase());
                              const checkDate = actvDate.toString().slice(0, -40) === today.toString().slice(0, -18);
                              return (
                                  <div className="px-[30px]">
                                      <VerticalStepper
                                          img={`${
                                              elem?.action === "insert"
                                                  ? `/images/common/inserted.svg`
                                                  : elem?.action === "rejected"
                                                  ? "/images/common/rejected.svg"
                                                  : elem?.action === "update"
                                                  ? "/images/common/updated.svg"
                                                  : elem?.action === "approved"
                                                  ? "/images/common/approved.svg"
                                                  : elem?.action === "validate"
                                                  ? "/images/common/updated.svg"
                                                  : elem?.action === "flag"
                                                  ? "/images/common/flag.svg"
                                                  : elem?.action === "unflag"
                                                  ? "/images/common/unflag.svg"
                                                  : elem?.action === "view"
                                                  ? "/images/main/view-ico.svg"
                                                  : elem?.action === "compared"
                                                  ? "/images/common/compare.svg"
                                                  : elem?.action === "invoice transfer"
                                                  ? "/images/common/change-ownership.svg"
                                                  : ""
                                          } `}
                                          card={
                                              <Details action={elem?.action} index={index} transaction_key={elem?.transaction_key}>
                                                  <div className="flex">
                                                      <LightTooltip
                                                          title={
                                                              <div
                                                                  className={`flex items-start p-3 rounded-[10px] shadow-[0px_4px_20px_rgba(85,85,85,0.2)] text-${currentMode}-copy`}
                                                              >
                                                                  <img
                                                                      className="w-[32px] rounded-full h-[32px] object-cover"
                                                                      src={
                                                                          elem?.userimage
                                                                              ? `data:image;base64,${elem?.userimage}`
                                                                              : "/images/common/profileImg.jpg"
                                                                      }
                                                                      alt="Profile"
                                                                  />
                                                                  <div className="ml-3">
                                                                      <span
                                                                          title={elem?.username}
                                                                          className="text-light-10 block max-w-[240px] truncate text-sm"
                                                                      >
                                                                          {elem?.username}
                                                                      </span>
                                                                      <p className="text-xs">{decryptId(elem?.useremail)}</p>
                                                                      <p className="text-xs">Role: {elem?.userrole}</p>
                                                                  </div>
                                                              </div>
                                                          }
                                                      >
                                                          <img
                                                              className="cursor-pointer rounded-full w-[28px] h-[28px] object-cover"
                                                              src={
                                                                  elem?.userimage
                                                                      ? `data:image;base64,${elem?.userimage}`
                                                                      : `/images/common/profileImg.jpg`
                                                              }
                                                              alt="user-1"
                                                          />
                                                      </LightTooltip>
                                                      <div className="ml-3">
                                                          <p>
                                                              <span title={elem?.username} className="font-interSb block max-w-[240px] truncate">
                                                                  {elem?.username}
                                                              </span>{" "}
                                                              {elem?.action !== "invoice transfer" && (
                                                                  <span>
                                                                      <em className="text-light-20 not-italic"> {elem?.action}</em>{" "}
                                                                      {`${
                                                                          location.pathname === "/adhoc-advance-request" ||
                                                                          location.pathname === "/mobilization-request" ||
                                                                          location.pathname === "/retention-release" ||
                                                                          location.pathname === "/miscellaneous-request"
                                                                              ? "the request"
                                                                              : "the invoice"
                                                                      }`}
                                                                  </span>
                                                              )}
                                                          </p>

                                                          <p title={elem?.remarks} className="text-light-20 not-italic truncate w-[250px]">
                                                              {" "}
                                                              {elem?.remarks}
                                                          </p>

                                                          <span className="text-light-30 text-xs">
                                                              {checkDate == false ? elem?.modified_date : ""} at {elem?.modified_time}
                                                          </span>
                                                      </div>
                                                  </div>
                                              </Details>
                                          }
                                      />
                                  </div>
                              );
                          })
                        : fetching && <Loader />}
                </div>
            )}
            {searchInput?.length > 1 && SearchLength === 0 ? (
                <div className="h-full grid place-items-center place-content-center">
                    <img className="mb-3" src="/images/common/delivery.png" alt="searching" />
                    <h4 className="font-interSb">No Result found</h4>
                </div>
            ) : null}
        </>
    );
};

export default ActivityModal;
