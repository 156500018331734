import axios from "axios";
import configMyiQ from "../../configuration";
import {
    GET_NOTIFICATION_FAILURE,
    GET_NOTIFICATION_REQUEST,
    GET_NOTIFICATION_SUCCESS,
    GET_NOTIFICATION_LIST_FAILURE,
    GET_NOTIFICATION_LIST_REQUEST,
    GET_NOTIFICATION_LIST_SUCCESS,
    UPDATE_NOTIFICATION_FAILURE,
    UPDATE_NOTIFICATION_REQUEST,
    UPDATE_NOTIFICATION_SUCCESS,
    GET_NOTIFICATION_SCROLL_FAILURE,
    GET_NOTIFICATION_SCROLL_REQUEST,
    GET_NOTIFICATION_SCROLL_SUCCESS,
} from "../types/types";
import authHeader from "./auth-header";
import { checkApiStatus } from "../../utils/helpers";

const API_URL = process.env?.REACT_APP_NODE_API_URL;

export const GetPushNotification =
    (isread, pgno, reccnt, scroll) => async (dispatch) => {
        try {
            if (scroll === "normal") {
                dispatch({ type: GET_NOTIFICATION_REQUEST });
            } else {
                dispatch({ type: GET_NOTIFICATION_SCROLL_REQUEST });
            }

            axios
                .get(
                    `${API_URL}api/getpushnotification?isread=${isread}&pgno=${pgno}&reccnt=${reccnt}`,
                    {
                        headers: authHeader(),
                    }
                )
                .then((response) => {
                    if (scroll === "normal") {
                        dispatch({
                            type: GET_NOTIFICATION_SUCCESS,
                            payload: response?.data?.datalist,
                        });
                    } else {
                        if (response?.data?.datalist) {
                            dispatch({
                                type: GET_NOTIFICATION_SCROLL_SUCCESS,
                                payload: response?.data?.datalist,
                            });
                        }
                    }
                })
                .catch((ex) => {
                    if (ex?.response?.status == 401) {
                        checkApiStatus();
                    }
                    dispatch({
                        type: GET_NOTIFICATION_FAILURE,
                        payload: ex,
                    });
                });
        } catch (error) {
            dispatch({
                type: GET_NOTIFICATION_FAILURE,
            });
        }
    };

export const GetNotificationList = (pgno, reccnt) => async (dispatch) => {
    try {
        dispatch({ type: GET_NOTIFICATION_LIST_REQUEST });

        axios
            .get(
                `${API_URL}api/getpushnotificationall?&pgno=${pgno}&reccnt=${reccnt}`,
                {
                    headers: authHeader(),
                }
            )
            .then((response) => {
                dispatch({
                    type: GET_NOTIFICATION_LIST_SUCCESS,
                    payload: response?.data?.datalist,
                });
            })
            .catch((ex) => {
                if (ex?.response?.status == 401) {
                    checkApiStatus();
                }
                dispatch({
                    type: GET_NOTIFICATION_LIST_FAILURE,
                    payload: ex,
                });
            });
    } catch (error) {
        dispatch({
            type: GET_NOTIFICATION_LIST_FAILURE,
        });
    }
};

export const UpdateNotification = (data) => async (dispatch) => {
    try {
        dispatch({ type: UPDATE_NOTIFICATION_REQUEST });

        axios
            .post(`${API_URL}api/updatenotificationstatus`, data, {
                headers: authHeader(),
            })
            .then((response) => {
                dispatch({
                    type: UPDATE_NOTIFICATION_SUCCESS,
                    payload: response?.data?.datalist,
                });
            })
            .catch((ex) => {
                if (ex?.response?.status == 401) {
                    checkApiStatus();
                }
                dispatch({
                    type: UPDATE_NOTIFICATION_FAILURE,
                    payload: ex,
                });
            });
    } catch (error) {
        dispatch({
            type: UPDATE_NOTIFICATION_FAILURE,
        });
    }
};
