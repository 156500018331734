import {
    GETALLUSER_BYORG_FAILURE,
    GETALLUSER_BYORG__REQUEST,
    GETALLUSER_BYORG__RESET,
    GETALLUSER_BYORG__SUCCESS,
    GET_USERCOUNT_FAILURE,
    GET_USERCOUNT_REQUEST,
    GET_USERCOUNT_RESET,
    GET_USERCOUNT_SUCCESS,
    USER_ADD_FAILURE,
    USER_ADD_REQUEST,
    USER_ADD_RESET,
    USER_ADD_SUCCESS,
    USER_EDIT_FAILURE,
    USER_EDIT_REQUEST,
    USER_EDIT_RESET,
    USER_EDIT_SUCCESS,
    USER_STATUS_UPDATE_FAILURE,
    USER_STATUS_UPDATE_REQUEST,
    USER_STATUS_UPDATE_RESET,
    USER_STATUS_UPDATE_SUCCESS,
    USER_VIEW_FAILURE,
    USER_VIEW_REQUEST,
    USER_VIEW_RESET,
    USER_VIEW_SUCCESS,
    USER_ROLE_DELETE_REQUEST,
    USER_ROLE_DELETE_SUCCESS,
    USER_ROLE_DELETE_FAILURE,
    USER_ROLE_DELETE_RESET,
    USER_ACTIVITY_FAILURE,
    USER_ACTIVITY_REQUEST,
    USER_ACTIVITY_SUCCESS,
    USER_ACTIVITY_RESET,
    USER_LIST_DROP_REQUEST,
    USER_LIST_DROP_SUCCESS,
    USER_LIST_DROP_FAILURE,
    USER_LIST_DROP_RESET,
    GET_USER_DROP_DOWN_ALLOCATION_REQUEST,
    GET_USER_DROP_DOWN_ALLOCATION_SUCCESS,
    GET_USER_DROP_DOWN_ALLOCATION_FAILURE,
    GET_USER_DROP_DOWN_ALLOCATION_RESET,
} from "../types/types";

//userList
const userAuthListState = {
    data: [],
    fetching: false,
    requestComplete: false,
    error: "",
};
export function userList (state = userAuthListState, action) {
    switch (action.type) {
        case GETALLUSER_BYORG__REQUEST:
            return {
                ...state,
                fetching: true,
                requestComplete: false,
                error: "",
            };
        case GETALLUSER_BYORG__SUCCESS:
            return {
                data: action.payload,
                fetching: false,
                requestComplete: true,
                error: "",
            };
        case GETALLUSER_BYORG__RESET:
            return {
                data: [],
                fetching: false,
                requestComplete: false,
                error: "",
            };
        case GETALLUSER_BYORG_FAILURE:
            return {
                fetching: false,
                requestComplete: false,
                error: action.payload,
            };
        default:
            return state;
    }
}
//usercount
const userAuthCountState = {
    data: [],
    fetching: false,
    requestComplete: false,
    error: "",
};
export function userCount (state = userAuthCountState, action) {
    switch (action.type) {
        case GET_USERCOUNT_REQUEST:
            return {
                ...state,
                fetching: true,
                requestComplete: false,
                error: "",
            };
        case GET_USERCOUNT_SUCCESS:
            return {
                data: action.payload,
                fetching: false,
                requestComplete: true,
                error: "",
            };
        case GET_USERCOUNT_RESET:
            return {
                data: [],
                fetching: false,
                requestComplete: false,
                error: "",
            };
        case GET_USERCOUNT_FAILURE:
            return {
                fetching: false,
                requestComplete: false,
                error: action.payload,
            };
        default:
            return state;
    }
}
//userAdd
const userAuthAddState = {
    profile: [],
    fetching: false,
    requestComplete: false,
    error: "",
};
export function userAdd (state = userAuthAddState, action) {
    switch (action.type) {
        case USER_ADD_REQUEST:
            return {
                ...state,
                fetching: true,
                requestComplete: false,
                error: "",
            };
        case USER_ADD_SUCCESS:
            return {
                profile: action.payload,
                fetching: false,
                requestComplete: true,
                error: "",
            };
        case USER_ADD_RESET:
            return {
                profile: {},
                fetching: false,
                requestComplete: false,
                error: "",
            };
        case USER_ADD_FAILURE:
            return {
                fetching: false,
                requestComplete: false,
                error: action.payload,
            };
        default:
            return state;
    }
}
//userView
const userAuthViewState = {
    viewData: {},
    fetching: false,
    requestComplete: false,
    error: "",
};
export function userView (state = userAuthViewState, action) {
    switch (action.type) {
        case USER_VIEW_REQUEST:
            return {
                ...state,
                fetching: true,
                requestComplete: false,
                error: "",
            };
        case USER_VIEW_SUCCESS:
            return {
                viewData: action.payload,
                fetching: false,
                requestComplete: true,
                error: "",
            };
        case USER_VIEW_RESET:
            return {
                viewData: {},
                fetching: false,
                requestComplete: false,
                error: "",
            };
        case USER_VIEW_FAILURE:
            return {
                fetching: false,
                requestComplete: false,
                error: action.payload,
            };
        default:
            return state;
    }
}
//userEdit
const userAuthEditState = {
    data: [],
    fetching: false,
    requestComplete: false,
    error: "",
};
export function userEdit (state = userAuthEditState, action) {
    switch (action.type) {
        case USER_EDIT_REQUEST:
            return {
                ...state,
                fetching: true,
                requestComplete: false,
                error: "",
            };
        case USER_EDIT_SUCCESS:
            return {
                data: action.payload,
                fetching: false,
                requestComplete: true,
                error: "",
            };
        case USER_EDIT_RESET:
            return {
                data: {},
                fetching: false,
                requestComplete: false,
                error: "",
            };
        case USER_EDIT_FAILURE:
            return {
                fetching: false,
                requestComplete: false,
                error: action.payload,
            };
        default:
            return state;
    }
}

const userStatusUpdateState = {
    data: [],
    fetching: false,
    requestComplete: false,
    error: "",
};
export function userStatusUpdate (state = userStatusUpdateState, action) {
    switch (action.type) {
        case USER_STATUS_UPDATE_REQUEST:
            return {
                ...state,
                fetching: true,
                requestComplete: false,
                error: "",
            };
        case USER_STATUS_UPDATE_SUCCESS:
            return {
                data: action.payload,
                fetching: false,
                requestComplete: true,
                error: "",
            };
        case USER_STATUS_UPDATE_RESET:
            return {
                data: {},
                fetching: false,
                requestComplete: false,
                error: "",
            };
        case USER_STATUS_UPDATE_FAILURE:
            return {
                fetching: false,
                requestComplete: false,
                error: action.payload,
            };
        default:
            return state;
    }
}

//userEdit
const userRoleDeleteState = {
    data: [],
    fetching: false,
    requestComplete: false,
    error: "",
};

export function userRoleDelete (state = userRoleDeleteState, action) {
    switch (action.type) {
        case USER_ROLE_DELETE_REQUEST:
            return {
                ...state,
                fetching: true,
                requestComplete: false,
                error: "",
            };
        case USER_ROLE_DELETE_SUCCESS:
            return {
                data: action.payload,
                fetching: false,
                requestComplete: true,
                error: "",
            };
        case USER_ROLE_DELETE_RESET:
            return {
                data: {},
                fetching: false,
                requestComplete: false,
                error: "",
            };
        case USER_ROLE_DELETE_FAILURE:
            return {
                fetching: false,
                requestComplete: false,
                error: action.payload,
            };
        default:
            return state;
    }
}

const userActState = {
    data: [],
    fetching: false,
    requestComplete: false,
    error: "",
};

export function userActivityState (state = userActState, action) {
    switch (action.type) {
        case USER_ACTIVITY_REQUEST:
            return {
                ...state,
                fetching: true,
                requestComplete: false,
                error: "",
            };

        case USER_ACTIVITY_SUCCESS:
            return {
                data: action.payload,
                fetching: false,
                requestComplete: true,
                error: "",
            };

        case USER_ACTIVITY_RESET:
            return {
                data: {},
                fetching: false,
                requestComplete: false,
                error: "",
            };

        case USER_ACTIVITY_FAILURE:
            return {
                fetching: false,
                requestComplete: false,
                error: action.payload,
            };
        default:
            return state;
    }
}

const userDropDownState = {
    data: [],
    fetching: false,
    requestComplete: false,
    error: "",
};

export function userListDropDown (state = userDropDownState, action) {
    switch (action.type) {
        case USER_LIST_DROP_REQUEST:
            return {
                ...state,
                fetching: true,
                requestComplete: false,
                error: "",
            };

        case USER_LIST_DROP_SUCCESS:
            return {
                data: action.payload,
                fetching: false,
                requestComplete: true,
                error: "",
            };

        case USER_LIST_DROP_RESET:
            return {
                data: [],
                fetching: false,
                requestComplete: false,
                error: "",
            };

        case USER_LIST_DROP_FAILURE:
            return {
                fetching: false,
                requestComplete: false,
                error: action.payload,
            };
        default:
            return state;
    }
}
const userDropState = {
    data: [],
    fetching: false,
    requestComplete: false,
    error: "",
};
export function userListDropDownAllocation (state = userDropState, action) {
    switch (action.type) {
        case GET_USER_DROP_DOWN_ALLOCATION_REQUEST:
            return {
                ...state,
                fetching: true,
                requestComplete: false,
                error: "",
            };

        case GET_USER_DROP_DOWN_ALLOCATION_SUCCESS:
            return {
                data: action.payload,
                fetching: false,
                requestComplete: true,
                error: "",
            };

        case GET_USER_DROP_DOWN_ALLOCATION_RESET:
            return {
                data: [],
                fetching: false,
                requestComplete: false,
                error: "",
            };

        case GET_USER_DROP_DOWN_ALLOCATION_FAILURE:
            return {
                fetching: false,
                requestComplete: false,
                error: action.payload,
            };
        default:
            return state;
    }
}
