import {
    SEND_CHATBOT_FAILURE,
    SEND_CHATBOT_REQUEST,
    SEND_CHATBOT_RESET,
    SEND_CHATBOT_SUCCESS,
    SEND_CHATBOT_TEXT_SUCCESS,
} from "../types/types";

const intAuthState = {
    chatMessages: [],
    fetching: false,
    requestComplete: false,
    error: "",
};

export function reducerChatbot(state = intAuthState, action) {
    switch (action.type) {
        case SEND_CHATBOT_REQUEST:
            return {
                ...state,
                fetching: true,
                requestComplete: false,
                error: "",
            };
        case SEND_CHATBOT_TEXT_SUCCESS:
            return {
                ...state,
                chatMessages: [...state.chatMessages, { text: action.payload, isUser: true }],
            };
        case SEND_CHATBOT_SUCCESS:
            return {
                chatMessages: [...state.chatMessages, { text: action.payload, isUser: false }],
                fetching: false,
                requestComplete: true,
                error: "",
            };
        case SEND_CHATBOT_RESET:
            return {
                chatMessages: [],
                fetching: false,
                requestComplete: false,
                error: "",
            };
        case SEND_CHATBOT_FAILURE:
            return {
                fetching: false,
                chatMessages: [],
                requestComplete: false,
                error: action.payload,
            };
        default:
            return state;
    }
}
