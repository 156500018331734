import Input from "../../components/Input";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import UseForm from "../../Hooks/useForm";
import { forgotpassword } from "../../redux/actions/auth";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { LoginValidateSchema } from "../../Hooks/validateschema";
import MessageBox from "../../components/UI/Alert";
import Button from "../../components/Button";
import SuccessMessageBox from "../../components/UI/Alert/sucess";
import Container from "../../components/UI/Container";
import { useEffect, useState } from "react";
import { FORGOT_PASS_RESET } from "../../redux/types/types";
import { validateLogin } from "../../Hooks/validate";
import { encryptId } from "../../utils/helpers";
const successMsg = "Password Reset link is  successfully sent to your Business E-mail";
const errormsg = "Something went wrong please contact admin";
const errorEmail = "No user found with this E-mail";
const Card = (props) => (
    <div
        className={`${props?.className} bg-white px-[6.25rem] py-[5.125rem] shadow-[0px_4px_20px_rgba(238,238,238,0.501967)] rounded-[10px] border-[1px] border-[#dfe1e28f]`}
    >
        {props?.children}
    </div>
);

const ForgotPassUI = () => {
    const dispatch = useDispatch();

    const [isLoading, setIsLoading] = useState(false);
    const [resendLink, setResendLink] = useState(true);

    const { handleChange, handleSubmit, values, errors } = UseForm(submit, validateLogin);
    const navigate = useNavigate();
    const { fetching, requestComplete, error, data } = useSelector((state) => state?.forgoutpasstate);

    const response = useSelector((state) => state?.forgoutpasstate);

    function submit() {
        dispatch(
            forgotpassword({
                emailaddress: encryptId(values?.emailaddress.toLowerCase()),
            })
        );
        setIsLoading(true);
    }
    // if (requestComplete && data?.status) {
    //   setTimeout(function () {
    //     navigate("/")
    //   }, 2000)
    //
    if (requestComplete && !data?.status) {
        setTimeout(() => {
            dispatch({ type: FORGOT_PASS_RESET });
        }, 4000);
    }
    useEffect(() => {
        return () => {
            dispatch({ type: FORGOT_PASS_RESET });
        };
    }, [dispatch]);

    return (
        <>
            {requestComplete && data?.status && <SuccessMessageBox success={successMsg} />}
            {requestComplete && !data?.status && <MessageBox error={data?.message} />}
            {error && !data?.status && <MessageBox error={errormsg} />}
            <Container className="mt-10">
                <Link to={"//www.myiq.ai/"} target="_blank" className="mb-10 inline-block">
                    <img className="w-[121px] h-[95px]" src="/images/logo/MyiqFinal.svg" alt="logoiq" />
                </Link>
                <div className="flex justify-center ">
                    <div className="basis-[44%]">
                        <Card className="w-[500px] h-[364px] py-[47px] px-[75px]">
                            <h4 className="font-interSb text-center text-2xl mb-[2.813rem]">To reset your password</h4>
                            <form action="" onSubmit={handleSubmit} autoComplete="off">
                                <div className="form-control">
                                    <label htmlFor="" className="text-light-40 font-interR text-sm mb-2">
                                        <span className="text-[red] mr-1">*</span>Business Email
                                    </label>
                                    <div className="relative">
                                        <Input
                                            className="border-[1px] border-light-840 rounded-[8px] px-[20px] py-2 w-full"
                                            onChange={handleChange}
                                            name="emailaddress"
                                            maxLength={250}
                                            placeholder="jacob@abc.com"
                                            autoFocus
                                        />
                                        {errors && <p className="text-light-50 text-xs">{errors?.emailaddress}</p>}
                                    </div>
                                </div>
                                <Button
                                    title="Submit"
                                    disabled={(requestComplete && data?.status) || fetching}
                                    type="submit"
                                    className="w-full rounded-[8px] text-white font-interSb text-lg bg-light-20 py-[6px]"
                                >
                                    {fetching ? `Loading...` : `Submit`}
                                </Button>
                                {resendLink !== fetching && requestComplete && data?.status && (
                                    <p className="mt-4  text-center text-xs">
                                        <span className="text-[red]">*</span> If you {`haven’t`} received any links to reset your password, click here
                                        to{" "}
                                        <Button type="submit" className="text-light-10 inline-block">
                                            {fetching ? "Resend confirmation mail" : "Resend confirmation mail"}
                                        </Button>
                                    </p>
                                )}
                            </form>
                        </Card>
                    </div>
                </div>
            </Container>
        </>
    );
};

export default ForgotPassUI;
