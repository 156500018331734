import { useEffect, useState, useContext } from "react";
import Layout from "../../../Hoc/Layout";
import Card from "../../../components/UI/Card";
import Footer from "../../../organisms/Footer";
import Main from "../../../components/UI/Main";
import Input from "../../../components/Input";
import Button from "../../../components/Button";
import { FormControl, FormControlLabel, MenuItem, Select } from "@mui/material";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { stringify } from "@firebase/util";
import { GET_TASK_ID_RESET, POST_TASK_ID_RESET } from "../../../redux/types/types";
import { getTaskNumberConfigList, postTaskIdConfig } from "../../../redux/actions/my-task";
import Loader from "../../../components/Loader";
import SuccessMessageBox from "../../../components/UI/Alert/sucess";
import MessageBox from "../../../components/UI/Alert";
import { ThemeContext } from "../../../Hooks/Themes/Themeprovider";

const TaskIdConfiguration = () => {
    const { currentMode, toggleMode } = useContext(ThemeContext);
    const orgId = localStorage.getItem("orgId");
    const [orgList, setorgList] = useState(orgId);
    const { fetching, requestComplete, error, data } = useSelector((state) => state?.postTaskIdConfigState);
    const succesMsg = "Updated successfully";
    const errormsg = "Something went wrong please contact admin";
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getTaskNumberConfigList());
    }, [dispatch]);

    const taskNumberConfig = useSelector((state) => state?.getTaskIdState);
    const { dataList: dataList } = taskNumberConfig;

    useEffect(() => {
        setNumberStart(dataList[0]?.next_no);
        setNumberSize(dataList[0]?.padding);
        setSuffix(dataList[0]?.suffix);
        setPrefix(dataList[0]?.prefix);
    }, [taskNumberConfig]);

    const [prefix, setPrefix] = useState("");
    const [Suffix, setSuffix] = useState("");
    const [NumberStart, setNumberStart] = useState("");
    const [NumberSize, setNumberSize] = useState("");

    const numberstartDisplay = NumberStart == 0 ? "" : NumberStart;
    const Padding = String(numberstartDisplay)?.padStart(NumberSize, "0");

    const preview = prefix + Padding + Suffix;
    const previewString = stringify(preview);
    const pre = previewString.replace(/,/g, "");
    const [errormsgs, seterrormsg] = useState({
        message: "",
    });
    const [previewError, setPreviewError] = useState({
        message: "",
    });
    const [NumberError, setNumberError] = useState({
        message: "",
    });
    const handleprefixChange = (value) => {
        setPrefix(value);
    };
    const handleSuffixChange = (value) => {
        setSuffix(value);
    };
    const handleNumberStartChange = (value) => {
        setNumberStart(value);
    };
    const handlepaddingChange = (value) => {
        setNumberSize(value);
    };

    function submit() {
        seterrormsg(false);
        setNumberError(false);
        setPreviewError(false);
        if (!/^(?:[1-9]|0[1-9]|10)$/.test(NumberSize) && (NumberSize?.length == 0 || parseInt(NumberSize) > 10)) {
            seterrormsg({
                message: " The numeric value should range from 1 to 10 ",
            });
            return false;
        } else {
            seterrormsg({
                message: "",
            });
        }

        if (!/^[0-9]*$/.test(NumberStart)) {
            setNumberError({
                message: "Value should be numeric",
            });
            return false;
        } else if (NumberStart == "") {
            setNumberError({
                message: "Number Starts with is required",
            });
            return false;
        } else {
            setNumberError({
                message: "",
            });
        }
        if (pre?.length > 27) {
            setPreviewError({
                message: "Preview should not exceed 25 characters ",
            });
            return false;
        } else {
            setPreviewError({
                message: "",
            });
        }

        dispatch(
            postTaskIdConfig({
                id: dataList[0]?.id,
                preffix: prefix,
                suffix: Suffix,
                padding: +NumberSize,
                nextno: +NumberStart,
            })
        );
    }
    const timer = (requestComplete && !data.status) || (error && !data?.status) ? 5000 : 2000;
    if (requestComplete || (requestComplete && !data.status) || (error && !data?.status)) {
        setTimeout(function () {
            dispatch({ type: POST_TASK_ID_RESET });
        }, timer);
    }
    return (
        <>
            {requestComplete && data?.status && <SuccessMessageBox success={succesMsg} />}
            {requestComplete && !data?.status && (
                <MessageBox
                    error={
                        data?.message == "Duplication of sequence number"
                            ? `Task number ${NumberStart} is already allocated, configure with a new number series!`
                            : errormsg
                    }
                />
            )}
            {error && !data?.status && <MessageBox error={errormsg} />}
            <Layout>
                <Main>
                    <div className={`flex justify-between items-center mb-6 text-${currentMode}-copy `}>
                        <div>
                            <h4 className="text-2xl font-interSb">Task ID Configuration</h4>
                        </div>
                    </div>
                    <Card
                        className={`h-[calc(100vh_-_22rem)] xl:h-[calc(100vh_-_17.5rem)] p-[30px] border-b-0 rounded-t-[10px] rounded-b-[0] w-full overflow-y-auto ${currentMode === "dark" ? "" : "shadow-[0px_4px_20px_rgba(238,238,238,0.5)]"
                            }  pb-0 relative bg-${currentMode}-card text-${currentMode}-copy border-${currentMode}-700`}
                    >
                        {taskNumberConfig?.fetching || requestComplete ? (
                            <Loader className="h-[calc(100vh_-_17rem)] " />
                        ) : (
                            <form action="">
                                <div className="max-w-[483px] invoiceConfig">
                                    {/* <div className="flex items-center justify-between mb-5 ">
                    <span>Organization</span>
                    <div>
                      <FormControl
                        sx={{
                          minWidth: "300px",
                        }}
                      >
                        <Select
                          value={orgList || "0"}
                          name="OrganisationList"
                          onChange={(e) => handleOrgValueChange(e.target.value)}
                          inputProps={{ "aria-label": "Without label" }}
                          IconComponent={() => (
                            <img
                              className="absolute right-[10px] pointer-events-none"
                              src="/images/common/chevron-grey.svg"
                            />
                          )}
                          MenuProps={{
                            sx: {
                              "& .MuiMenu-paper": {
                                backgroundColor: "white",
                              },
                              "& .MuiMenuItem-root:hover": {
                                backgroundColor: "rgba(218, 249, 244, 0.3)",
                              },
                              "& .Mui-selected": {
                                backgroundColor:
                                  "rgba(218, 249, 244, 0.3) !important",
                              },
                            },
                          }}
                          SelectDisplayProps={{
                            style: {
                              paddingTop: "9px",
                              paddingBottom: "9px",
                              color: "#555555",
                              paddingRight: "30px",
                              border: "1px solid rgba(85, 85, 85, 0.3)",
                              borderRadius: "8px",
                            },
                          }}
                        >
                          <MenuItem key={0} name="OrganisationList" value={0}>
                            {"Select Organization"}
                          </MenuItem>
                          {dataList &&
                            dataList?.map((listelem, i) => {
                              return (
                                <MenuItem
                                  key={i + 1}
                                  name="OrganisationList"
                                  value={listelem?.id}
                                >
                                  {listelem?.organisation_name}(
                                  {listelem?.preview})
                                </MenuItem>
                              );
                            })}
                        </Select>
                      </FormControl>
                    </div>
                  </div> */}

                                    <div>
                                        <div className="flex items-center justify-between mb-5">
                                            <span>Prefix</span>
                                            <Input
                                                className={`min-w-[300px] border-${currentMode}-840 border-[1px] px-[10px] py-[8px] rounded-[8px] bg-${currentMode}-810 border-${currentMode}-830`}
                                                placeholder="eg: TSK-"
                                                maxLength={10}
                                                name="prefix"
                                                disabled={false}
                                                value={prefix == undefined ? "" : prefix}
                                                onChange={(e) => handleprefixChange(e.target.value)}
                                                autoComplete="off"
                                            />
                                        </div>
                                        <div className="flex items-center justify-between mb-5">
                                            <span>Running Number Size</span>
                                            <div>
                                                <Input
                                                    placeholder="eg: 5"
                                                    className={`min-w-[300px] border-${currentMode}-840 border-[1px] px-[10px] py-[8px] rounded-[8px] bg-${currentMode}-810 border-${currentMode}-830`}
                                                    value={NumberSize == 0 ? "" : NumberSize}
                                                    name="NumberSize"
                                                    // type="number"
                                                    maxLength={2}
                                                    disabled={false}
                                                    onChange={(e) => {
                                                        e.target.value =
                                                            e.target.value == "" ? "" : Math.max(parseInt(e.target.value)).toString().slice(0, 2);
                                                        if (e.target.value == "Na" || e.target.value == "0") {
                                                            e.target.value = "";
                                                        }
                                                        handlepaddingChange(e.target.value);
                                                    }}
                                                    autoComplete="off"
                                                />
                                                {errormsgs?.message && <p className="text-light-50 block text-xs">{errormsgs?.message}</p>}
                                            </div>
                                        </div>
                                        <div className="flex items-center justify-between mb-5">
                                            <span>Suffix</span>
                                            <Input
                                                placeholder="eg: -123"
                                                className={`min-w-[300px] border-${currentMode}-840 border-[1px] px-[10px] py-[8px] rounded-[8px] bg-${currentMode}-810 border-${currentMode}-830`}
                                                value={Suffix == undefined ? "" : Suffix}
                                                disabled={false}
                                                maxLength={10}
                                                onChange={(e) => handleSuffixChange(e.target.value)}
                                            />
                                        </div>
                                        <div className="flex items-center justify-between mb-5">
                                            <span>Numbers Starts with</span>
                                            <div>
                                                <Input
                                                    placeholder="eg: 1"
                                                    className={`min-w-[300px] border-${currentMode}-840 border-[1px] px-[10px] py-[8px] rounded-[8px] bg-${currentMode}-810 border-${currentMode}-830`}
                                                    value={NumberStart}
                                                    // disabled={orgList == 0 ? true : false}
                                                    disabled={false}
                                                    // maxLength={5}
                                                    onChange={(e) => {
                                                        e.target.value =
                                                            e.target.value == "" ? "" : Math.max(parseInt(e.target.value)).toString().slice(0, 5);
                                                        if (e.target.value == "NaN" || e.target.value == "0") {
                                                            e.target.value = "";
                                                        }

                                                        handleNumberStartChange(e.target.value);
                                                    }}
                                                // type="number"
                                                />
                                                {NumberError?.message && <p className="text-light-50 block text-xs">{NumberError?.message}</p>}
                                            </div>
                                        </div>

                                        <div className="flex items-center justify-between">
                                            <div className="w-[38%]">
                                                <span>Preview</span>
                                            </div>
                                            <div className="w-[62%]">
                                                <div
                                                    className={`relative font-interM border-b-[1px] border-${currentMode}-590 w-full bg-${currentMode}-1330 ${currentMode !== "dark" ? "shadow-[0px_4px_20px_rgba(238,238,238,0.5)]" : ""
                                                        } text-[#555555)] flex justify-center items-center h-[80px]  min-w-[300px] border-[1px] px-[10px] py-[8px] rounded-[8px] border-${currentMode}-830`}
                                                >

                                                    <span title={`${prefix}${Padding}${Suffix}`} className="trunc-1 cursor-default">
                                                        {prefix}
                                                        {Padding}
                                                        {Suffix}
                                                    </span>
                                                    {NumberStart == "" &&
                                                        (prefix == "" || prefix == undefined) &&
                                                        (Suffix == "" || Suffix == undefined) &&
                                                        NumberSize == "" && <span className="text-light-120">eg: TSK-00001-123</span>}
                                                </div>

                                                {previewError?.message && <p className="text-light-50 text-xs">{previewError?.message}</p>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        )}
                    </Card>
                    <div
                        className={`bg-${currentMode}-card text-${currentMode}-copy border-${currentMode}-700 py-[20px] space-x-2 px-[30px] sticky left-0 right-0 shadow-[0px_4px_20px_rgba(85,85,85,0.2)] border-[1px] border-[#dfe1e28f] rounded-b-[10px]`}
                    >
                        <Button
                            className="bg-light-20 text-white text-base rounded-[8px] py-2 px-7"
                            onClick={submit}
                            title={"Save"}
                            disabled={NumberStart == ""}
                        >
                            {"Save"}
                        </Button>
                        <Link to="/admin-setting" title="Cancel">
                            <Button className="bg-light-240 text-base text-light-40 rounded-[8px] py-2 px-7">
                                Cancel
                            </Button>
                        </Link>
                    </div>
                </Main>
                <Footer />
            </Layout>
        </>
    );
};
export default TaskIdConfiguration;
