import {
  GET_MS_CONTACT_LIST_REQUEST,
  GET_MS_CONTACT_LIST_SUCCESS,
  GET_MS_CONTACT_LIST_RESET,
  GET_MS_CONTACT_LIST_FAILURE,
} from "../types/types.js";

//GetCostCenterSyncList
const GetMSContactListState = {
  Listdata: {},
  fetching: false,
  requestComplete: false,
  error: "",
};
export function GetMicrosoftContactListRedu(
  state = GetMSContactListState,
  action
) {
  switch (action.type) {
    case GET_MS_CONTACT_LIST_REQUEST:
      return {
        ...state,
        fetching: true,
        requestComplete: false,
        error: "",
      };
    case GET_MS_CONTACT_LIST_SUCCESS:
      return {
        Listdata: action.payload,
        fetching: false,
        requestComplete: true,
        error: "",
      };
    case GET_MS_CONTACT_LIST_RESET:
      return {
        Listdata: {},
        fetching: false,
        requestComplete: false,
        error: "",
      };
    case GET_MS_CONTACT_LIST_FAILURE:
      return {
        fetching: false,
        requestComplete: false,
        error: action.payload,
      };
    default:
      return state;
  }
}
