import axios from "axios";
import configMyiQ from "../../configuration";
import { useNavigate } from "react-router-dom";
import authHeader from "./auth-header";
import { checkApiStatus } from "../../utils/helpers";
import {
    GET_INBOX_SETTINGS_REQUEST,
    GET_INBOX_SETTINGS_SUCCESS,
    GET_INBOX_SETTINGS_RESET,
    GET_INBOX_SETTINGS_FAILURE,
    ACTIVATE_DEACTIVATE_INBOX_SETTINGS_REQUEST,
    ACTIVATE_DEACTIVATE_INBOX_SETTINGS_SUCCESS,
    ACTIVATE_DEACTIVATE_INBOX_SETTINGS_FAILURE,
    ACTIVATE_DEACTIVATE_INBOX_SETTINGS_RESET,
    INBOX_SETTINGS_DELETE_REQUEST,
    INBOX_SETTINGS_DELETE_SUCCESS,
    INBOX_SETTINGS_DELETE_FAILURE,
    INBOX_SETTINGS_DELETE_RESET,
} from "../types/types";

const API_URL = process.env?.REACT_APP_NODE_API_URL;

//inbox settings list
export const getInboxSettingList = (navigate) => async (dispatch) => {
    try {
        dispatch({ type: GET_INBOX_SETTINGS_REQUEST });
        axios
            .get(
                `${API_URL}api/caseMangement/inboxSettings/viewInboxSettings`,
                {
                    headers: authHeader(),
                }
            )
            .then((response) => {
                //   rediredirect url condition based in header mail icon click
                dispatch({
                    type: GET_INBOX_SETTINGS_SUCCESS,
                    payload: response?.data?.datalist,
                });
                if (response?.data?.datalist?.length !== 0) {
                    localStorage.setItem(
                        "SyncMail",
                        response?.data?.datalist?.[0]?.emailaddress
                    );
                    localStorage.setItem(
                        "mailSyncEmail",
                        JSON.stringify(response?.data?.datalist?.[0]?.isactive)
                    );
                    if (response?.data?.datalist?.[0]?.isactive) {
                        navigate("/inbox-compose");
                    } else {
                        navigate("/inbox-settings");
                    }
                } else {
                    navigate("/inbox-config");
                }
            });
    } catch (error) {
        if (error?.response?.status == 401) {
            checkApiStatus();
        }
        dispatch({
            type: GET_INBOX_SETTINGS_FAILURE,
        });
    }
};

//active-inactive inbox settings
export const inboxSettingsStatusMode = (data) => async (dispatch) => {
    try {
        dispatch({ type: ACTIVATE_DEACTIVATE_INBOX_SETTINGS_REQUEST });

        axios
            .post(
                `${API_URL}api/caseMangement/inboxSettings/activeInactiveInboxSettings`,
                data,
                {
                    headers: authHeader(),
                }
            )
            .then((response) => {
                dispatch({
                    type: ACTIVATE_DEACTIVATE_INBOX_SETTINGS_SUCCESS,
                    payload: response.data,
                });
            });
    } catch (error) {
        if (error?.response?.status == 401) {
            checkApiStatus();
        }
        dispatch({
            type: ACTIVATE_DEACTIVATE_INBOX_SETTINGS_FAILURE,
        });
    }
};

export const inboxSettingsDelete = (data) => async (dispatch) => {
    try {
        dispatch({ type: INBOX_SETTINGS_DELETE_REQUEST });
        axios
            .post(
                `${API_URL}api/caseMangement/inboxSettings/deleteInboxSettings`,
                data,
                {
                    headers: authHeader(),
                }
            )
            .then((response) => {
                dispatch({
                    type: INBOX_SETTINGS_DELETE_SUCCESS,
                    payload: response.data,
                });
            });
    } catch (error) {
        if (error?.response?.status == 401) {
            checkApiStatus();
        }
        dispatch({
            type: INBOX_SETTINGS_DELETE_FAILURE,
        });
    }
};
