import axios from "axios";
import {
	WORKFLOW_STATUS_DELETE_REQUEST,
	WORKFLOW_STATUS_DELETE_SUCCESS,
	WORKFLOW_STATUS_DELETE_FAILURE,
	GET_WORKFLOW_DATA_REQUEST,
	GET_WORKFLOW_DATA_SUCCESS,
	GET_WORKFLOW_DATA_FAILURE,
	GET_WORKFLOW_PERMISSIONLAYOUT_REQUEST,
	GET_WORKFLOW_PERMISSIONLAYOUT_SUCCESS,
	GET_WORKFLOW_PERMISSIONLAYOUT_FAILURE,
	POST_WORKFLOW_AUTOMATION_BYORG_REQUEST,
	POST_WORKFLOW_AUTOMATION_BYORG_SUCCESS,
	POST_WORKFLOW_AUTOMATION_BYORG_FAILURE,
	GET_WORKFLOW_DATA_BY_INVOICE_ID_REQUEST,
	GET_WORKFLOW_DATA_BY_INVOICE_ID_SUCCESS,
	GET_WORKFLOW_DATA_BY_INVOICE_ID_FAILURE,
	GET_WORKFLOW_FILTER_REQUEST,
	GET_WORKFLOW_FILTER_SUCCESS,
	GET_WORKFLOW_FILTER_FAILURE,
	POST_CHANGE_WORKFLOW_STATUS_REQUEST,
	POST_CHANGE_WORKFLOW_STATUS_RESET,
	POST_CHANGE_WORKFLOW_STATUS_SUCCESS,
	POST_CHANGE_WORKFLOW_STATUS_FAILURE,
} from "../types/types";
import authHeader from "./auth-header";
import { checkApiStatus } from "../../utils/helpers";

const API = process.env?.REACT_APP_NODE_API_URL;

export const DeleteWorkFlowStatus = (workflowId, orgId) => async (dispatch) => {
	try {
		dispatch({ type: WORKFLOW_STATUS_DELETE_REQUEST });
		axios
			.get(`${API}api/workflow/DeleteWorkFLowStatus?orgid=${orgId}&worflowId=${workflowId}`, {
				headers: authHeader(),
			})
			.then((response) => {
				dispatch({
					type: WORKFLOW_STATUS_DELETE_SUCCESS,
					payload: response?.data,
				});
			})
			.catch((error) => {
				if (error?.response?.status == 401) {
					checkApiStatus();
				}
				dispatch({
					type: WORKFLOW_STATUS_DELETE_FAILURE,
					payload: error?.response?.data,
				});
			});
	} catch (error) {
		if (error?.response?.status == 401) {
			checkApiStatus();
		}
		dispatch({
			type: WORKFLOW_STATUS_DELETE_FAILURE,
			payload: error?.response?.data,
		});
	}
};

//get workflow Data list by organisation

export const getWorkFlowDataByOrg = (orgId, doc) => async (dispatch) => {
	dispatch({ type: GET_WORKFLOW_DATA_REQUEST });

	axios
		.get(`${API}api/workflow/getWorkFlowAutomationByOrg?orgid=${orgId}&docgroup=${doc}`, {
			headers: authHeader(),
		})
		.then((response) => {
			dispatch({
				type: GET_WORKFLOW_DATA_SUCCESS,
				payload: response.data?.datalist?.[0]?.invdata,
			});
		})
		.catch((error) => {
			if (error?.response?.status == 401) {
				checkApiStatus();
			}
			dispatch({
				type: GET_WORKFLOW_DATA_FAILURE,
			});
		});
};

//GET PERMISSION LAYOUT BY ORG

export const getWorkFlowPermissionLayoutByOrg = (orgId) => async (dispatch) => {
	dispatch({ type: GET_WORKFLOW_PERMISSIONLAYOUT_REQUEST });

	axios
		.get(`${API}api/workflow/getWorkFlowPermissionsByOrg?orgid=${orgId}`, {
			headers: authHeader(),
		})
		.then((response) => {
			dispatch({
				type: GET_WORKFLOW_PERMISSIONLAYOUT_SUCCESS,
				payload: response.data?.datalist?.[0]?.invdata,
			});
		})
		.catch((error) => {
			if (error?.response?.status == 401) {
				checkApiStatus();
			}
			dispatch({
				type: GET_WORKFLOW_PERMISSIONLAYOUT_FAILURE,
			});
		});
};

//addUpdate workflow config by organsation

export const postWorkFlowByOrg = (data) => async (dispatch) => {
	try {
		dispatch({ type: POST_WORKFLOW_AUTOMATION_BYORG_REQUEST });
		axios
			.post(`${API}api/workFlow/AddUpdateWorkFlowByOrg`, data, {
				headers: authHeader(),
			})
			.then((response) => {
				dispatch({
					type: POST_WORKFLOW_AUTOMATION_BYORG_SUCCESS,
					payload: response.data,
				});
			})
			.catch((error) => {
				if (error?.response?.status == 401) {
					checkApiStatus();
				}
				dispatch({
					type: POST_WORKFLOW_AUTOMATION_BYORG_FAILURE,
					payload: error.response.data,
				});
			});
	} catch (error) {
		if (error?.response?.status == 401) {
			checkApiStatus();
		}
		dispatch({
			type: POST_WORKFLOW_AUTOMATION_BYORG_FAILURE,
			payload: error.response.data,
		});
	}
};

//* Get Workflow statatus of an invoice id
export const getWorkFlowByInvoiceId =
	({ orgId, invId, type }) =>
	async (dispatch) => {
		dispatch({ type: GET_WORKFLOW_DATA_BY_INVOICE_ID_REQUEST });

		axios
			.get(`${API}api/workflow/getWorkflowConfigStatus?orgid=${orgId}&inv_id=${invId}&docgroup=${type}`, {
				headers: authHeader(),
			})
			.then((response) => {
				dispatch({
					type: GET_WORKFLOW_DATA_BY_INVOICE_ID_SUCCESS,
					payload: response?.data,
				});
			})
			.catch((error) => {
				if (error?.response?.status == 401) {
					checkApiStatus();
				}
				dispatch({
					type: GET_WORKFLOW_DATA_BY_INVOICE_ID_FAILURE,
				});
			});
	};

//* Get Workflow status filter
export const getWorkFlowFilter = (orgId, grp) => async (dispatch) => {
	dispatch({ type: GET_WORKFLOW_FILTER_REQUEST });

	axios
		.get(`${API}api/workflow/getWfStatusList?orgid=${orgId}&docgroup=${grp}`, {
			headers: authHeader(),
		})
		.then((response) => {
			dispatch({
				type: GET_WORKFLOW_FILTER_SUCCESS,
				payload: response?.data,
				orgId,
			});
		})
		.catch((error) => {
			if (error?.response?.status == 401) {
				checkApiStatus();
			}
			dispatch({
				type: GET_WORKFLOW_FILTER_FAILURE,
			});
		});
};

//* Action module to update the workflow status for an invoice

export const postChangeWorkflowStatus = (data) => async (dispatch) => {
	try {
		dispatch({ type: POST_CHANGE_WORKFLOW_STATUS_REQUEST });
		axios
			.post(`${API}api/workflow/WfStatusUpdate`, data, {
				headers: authHeader(),
			})
			.then((response) => {
				dispatch({
					type: POST_CHANGE_WORKFLOW_STATUS_SUCCESS,
					payload: response?.data,
				});
			})
			.catch((error) => {
				if (error?.response?.status == 401) {
					checkApiStatus();
				}
				dispatch({
					type: POST_CHANGE_WORKFLOW_STATUS_FAILURE,
					payload: error?.response?.data || "Some Error Occurs",
				});
			});
	} catch (error) {
		if (error?.response?.status == 401) {
			checkApiStatus();
		}
		dispatch({
			type: POST_CHANGE_WORKFLOW_STATUS_FAILURE,
			payload: error?.response?.data || "Some Error Occurs",
		});
	}
};
