const Button = (props) => {
  return (
    <>
      <button
        ref={props?.ref}
        id={props?.id}
        className={props?.className}
        type={props?.type}
        disabled={props?.disabled}
        onClick={props?.onClick}
        tabIndex={props?.tabIndex}
        title={props?.title}
      >
        {props?.children}
      </button>
    </>
  );
};

export default Button;
