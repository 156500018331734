import { FormControlLabel, Checkbox } from "@mui/material";
import { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import Button from "../../../../components/Button";
import Input from "../../../../components/Input";
import Card from "../../../../components/UI/Card";
import Main from "../../../../components/UI/Main";
import Layout from "../../../../Hoc/Layout";
import Footer from "../../../../organisms/Footer";
import { useDispatch, useSelector } from "react-redux";
import { AddRolePermission, ViewRolePermission } from "../../../../redux/actions/role";
import { Link } from "react-router-dom";
import SuccessMessageBox from "../../../../components/UI/Alert/sucess";
import MessageBox from "../../../../components/UI/Alert";
const EditRole = () => {
    const dispatch = useDispatch();
    const [tooltipOpen, setTooltipOpen] = useState(false);

    const handleSpanClick = () => {
        setTooltipOpen(!tooltipOpen);
    };
    const Role = useSelector((state) => state?.ViewRolePermissionStateAction);
    const { data: RoleData } = Role;
    const { fetching, requestComplete, error, data } = useSelector((state) => state?.AddRolePermissionStateAction);
    const [values, setValues] = useState(RoleData);
    const succesMsg = "Role is added successfully";
    const errormsg = "Something went wrong please contact admin";
    const [errormsgs, seterrormsg] = useState(false);
    const [roleid, getQueryStringVal] = useState("");
    let [Name, setname] = useState("");
    const [roleName, setroleName] = useState("");
    const [description, setDescription] = useState("");
    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        let RoleID = params.get("roleid");
        getQueryStringVal(RoleID);
        dispatch(ViewRolePermission({ roleid: RoleID }));
    }, [dispatch]);

    const RoleList = RoleData?.role_info;
    const RoleMenu = RoleList?.menu;
    const Roleorg = RoleMenu?.find((elem) => elem?.menu_id === 1);
    const RoleActOrg = Roleorg?.actions;
    const RoleActOrgId1 = RoleActOrg?.find((elem) => elem?.id === 1);
    const RoleActOrgId2 = RoleActOrg?.find((elem) => elem?.id === 2);
    const RoleActOrgId3 = RoleActOrg?.find((elem) => elem?.id === 3);
    const RoleActOrgId4 = RoleActOrg?.find((elem) => elem?.id === 4);
    const RoleActOrgId5 = RoleActOrg?.find((elem) => elem?.id === 5);
    const RoleActOrgId12 = RoleActOrg?.find((elem) => elem?.id === 12);
    const isallowedOrg = RoleActOrg?.map((elem) => elem?.isallowed);
    const ActFAOrg = isallowedOrg;
    const RoleEmail = RoleMenu?.find((elem) => elem?.menu_id === 2);
    const RoleActEmail = RoleEmail?.actions;
    const RoleActEmailId1 = RoleActEmail?.find((elem) => elem?.id === 1);
    const RoleActEmailId2 = RoleActEmail?.find((elem) => elem?.id === 2);
    const RoleActEmailId3 = RoleActEmail?.find((elem) => elem?.id === 3);
    const RoleActEmailId4 = RoleActEmail?.find((elem) => elem?.id === 4);
    const RoleActEmailId5 = RoleActEmail?.find((elem) => elem?.id === 5);
    const RoleActEmailId12 = RoleActEmail?.find((elem) => elem?.id === 12);
    const isallowedEmail = RoleActEmail?.map((elem) => elem?.isallowed);
    const ActFAEmail = isallowedEmail;

    const RoleFolder = RoleMenu?.find((elem) => elem?.menu_id === 3);
    const RoleActFolder = RoleFolder?.actions;
    const RoleActFolderId1 = RoleActFolder?.find((elem) => elem?.id === 1);
    const RoleActFolderId2 = RoleActFolder?.find((elem) => elem?.id === 2);
    const RoleActFolderId3 = RoleActFolder?.find((elem) => elem?.id === 3);
    const RoleActFolderId4 = RoleActFolder?.find((elem) => elem?.id === 4);
    const RoleActFolderId5 = RoleActFolder?.find((elem) => elem?.id === 5);
    const RoleActFolderId12 = RoleActFolder?.find((elem) => elem?.id === 12);
    const isallowedFolder = RoleActFolder?.map((elem) => elem?.isallowed);
    const ActFAFolder = isallowedFolder;

    const RoleTarget = RoleMenu?.find((elem) => elem?.menu_id === 4);
    const RoleActTarget = RoleTarget?.actions;
    const RoleActTargetId1 = RoleActTarget?.find((elem) => elem?.id === 1);
    const RoleActTargetId2 = RoleActTarget?.find((elem) => elem?.id === 2);
    const RoleActTargetId3 = RoleActTarget?.find((elem) => elem?.id === 3);
    const RoleActTargetId4 = RoleActTarget?.find((elem) => elem?.id === 4);
    const RoleActTargetId5 = RoleActTarget?.find((elem) => elem?.id === 5);
    const RoleActTargetId12 = RoleActTarget?.find((elem) => elem?.id === 12);
    const isallowedTarget = RoleActTarget?.map((elem) => elem?.isallowed);
    const ActFATarget = isallowedTarget;

    const RoleUserRole = RoleMenu?.find((elem) => elem?.menu_id === 5);
    const RoleActUserRole = RoleUserRole?.actions;
    const RoleActURId1 = RoleActUserRole?.find((elem) => elem?.id === 1);
    const RoleActURId2 = RoleActUserRole?.find((elem) => elem?.id === 2);
    const RoleActURId3 = RoleActUserRole?.find((elem) => elem?.id === 3);
    const RoleActURId4 = RoleActUserRole?.find((elem) => elem?.id === 4);
    const RoleActURId5 = RoleActUserRole?.find((elem) => elem?.id === 5);
    const RoleActURId12 = RoleActUserRole?.find((elem) => elem?.id === 12);
    const isallowedUserRole = RoleActUserRole?.map((elem) => elem?.isallowed);
    const ActFAUserRole = isallowedTarget;

    const RoleUser = RoleMenu?.find((elem) => elem?.menu_id === 6);
    const RoleActUser = RoleUser?.actions;
    const RoleActUserId1 = RoleActUser?.find((elem) => elem?.id === 1);
    const RoleActUserId2 = RoleActUser?.find((elem) => elem?.id === 2);
    const RoleActUserId3 = RoleActUser?.find((elem) => elem?.id === 3);
    const RoleActUserId4 = RoleActUser?.find((elem) => elem?.id === 4);
    const RoleActUserId5 = RoleActUser?.find((elem) => elem?.id === 5);
    const RoleActUserId12 = RoleActUser?.find((elem) => elem?.id === 12);
    const RoleActUserId13 = RoleActUser?.find((elem) => elem?.id === 13);
    const isallowedUser = RoleActUser?.map((elem) => elem?.isallowed);
    const ActFAUser = isallowedUser;

    const RoleOrgRole = RoleMenu?.find((elem) => elem?.menu_id === 7);
    const RoleActOrgRole = RoleOrgRole?.actions;
    const RoleActOrgRoleId1 = RoleActOrgRole?.find((elem) => elem?.id === 1);
    const RoleActOrgRoleId2 = RoleActOrgRole?.find((elem) => elem?.id === 2);
    const RoleActOrgRoleId3 = RoleActOrgRole?.find((elem) => elem?.id === 3);
    const RoleActOrgRoleId4 = RoleActOrgRole?.find((elem) => elem?.id === 4);
    const RoleActOrgRoleId5 = RoleActOrgRole?.find((elem) => elem?.id === 5);
    const RoleActOrgRoleId12 = RoleActOrgRole?.find((elem) => elem?.id === 12);
    const isallowedOrgRole = RoleActOrgRole?.map((elem) => elem?.isallowed);
    const ActFAOrgRole = isallowedOrgRole;

    const RoleProcess = RoleMenu?.find((elem) => elem?.menu_id === 8);
    const RoleActProcess = RoleProcess?.actions;
    const RoleActProcessId3 = RoleActProcess?.find((elem) => elem?.id === 3);
    const RoleActProcessId2 = RoleActProcess?.find((elem) => elem?.id === 2);
    const RoleActProcessId6 = RoleActProcess?.find((elem) => elem?.id === 6);
    const RoleActProcessId9 = RoleActProcess?.find((elem) => elem?.id === 9);
    const RoleActProcessId13 = RoleActProcess?.find((elem) => elem?.id === 13);
    const RoleActProcessId15 = RoleActProcess?.find((elem) => elem?.id === 15);
    const RoleActProcessId16 = RoleActProcess?.find((elem) => elem?.id === 16);
    const RoleActProcessId17 = RoleActProcess?.find((elem) => elem?.id === 17);
    const RoleActProcessId18 = RoleActProcess?.find((elem) => elem?.id === 18);
    const isallowedProcess = RoleActProcess?.map((elem) => elem?.isallowed);
    const ActFAProcess = isallowedProcess;

    const RoleException = RoleMenu?.find((elem) => elem?.menu_id === 9);
    const RoleActException = RoleException?.actions;
    const RoleActExceptionId3 = RoleActException?.find((elem) => elem?.id === 3);
    const RoleActExceptionId2 = RoleActException?.find((elem) => elem?.id === 2);
    const RoleActExceptionId6 = RoleActException?.find((elem) => elem?.id === 6);
    const RoleActExceptionId9 = RoleActException?.find((elem) => elem?.id === 9);
    const RoleActExceptionId15 = RoleActException?.find((elem) => elem?.id === 15);
    const RoleActExceptionId16 = RoleActException?.find((elem) => elem?.id === 16);
    const RoleActExceptionId17 = RoleActException?.find((elem) => elem?.id === 17);
    const RoleActExceptionId18 = RoleActException?.find((elem) => elem?.id === 18);
    const isallowedException = RoleActException?.map((elem) => elem?.isallowed);
    const ActFAException = isallowedException;

    const RoleReports = RoleMenu?.find((elem) => elem?.menu_id === 10);
    const RoleActReports = RoleReports?.actions;
    const RoleActReportId20 = RoleActReports?.find((elem) => elem?.id === 20);

    const RoleDashboard = RoleMenu?.find((elem) => elem?.menu_id === 11);
    const RoleActDashboard = RoleDashboard?.actions;
    const RoleActDashboardId19 = RoleActDashboard?.find((elem) => elem?.id === 19);

    const RoleManualUpload = RoleMenu?.find((elem) => elem?.menu_id === 12);
    const RoleActManualUpload = RoleManualUpload?.actions;
    const RoleActManualUploadId21 = RoleActManualUpload?.find((elem) => elem?.id === 21);
    const [orgAct, setorgACt] = useState(RoleActOrg);

    const handleChange = (event, id) => {
        orgAct?.forEach((element) => {
            if (id == element?.id) {
                element.isallowed = event.target.value;
            }
        });

        setorgACt([...orgAct]);
    };
    function submit() {
        const reqobj = {
            headers: [
                {
                    role_id: RoleData?.role_id,
                    description: values?.role_description,
                    role_name: values?.role_name,
                },
            ],
            items: [
                {
                    role_id: RoleData?.role_id,
                    menu: [{ menu_id: 1, actions: [RoleActOrg] }],
                },
            ],
        };

        const reqStr = {
            json: JSON.stringify(reqobj),
        };
        dispatch(AddRolePermission(reqStr));
    }

    const LightTooltip = styled(({ className, ...props }) => (
        <Tooltip
            {...props}
            placement="top-start"
            PopperProps={{
                sx: {
                    "& .MuiTooltip-tooltip": {
                        padding: "0 5px",
                    },
                },
                modifiers: [
                    {
                        name: "offset",
                        options: {
                            offset: [0, -10],
                        },
                    },
                ],
            }}
            classes={{ popper: className }}
        />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: theme.palette.common.white,
            color: "rgba(0, 0, 0, 0.87)",
            boxShadow: "0px 4px 20px rgba(85,85,85,0.2)",
            borderRadius: "10px",
            minWidth: "250px",
        },
    }));
    return (
        <>
            {requestComplete && data !== undefined && data.status !== undefined && data.status && <SuccessMessageBox success={succesMsg} />}
            {requestComplete && data !== undefined && data.status !== undefined && !data.status && <MessageBox error={data.message} />}
            {error && !data?.status && <MessageBox error={errormsg} />}
            <Layout>
                <Main>
                    <div className="flex justify-between items-center mb-6">
                        <div>
                            <h4 className="text-2xl font-interSb">User Roles</h4>
                        </div>
                        <div className="flex space-x-2">
                            <Link to="/new-role">
                                <Button
                                    title="New Role"
                                    className="border-20 bg-light-20 px-5 py-2 rounded-[8px] font-interM text-sm flex items-center border-[1px] text-white"
                                >
                                    <img className="mr-1" src="/images/common/plus.svg" alt="plus" /> New Role
                                </Button>
                            </Link>
                        </div>
                    </div>

                    <Card className="h-[calc(100vh_-_18.5rem)] xl:h-[calc(100vh_-_14.5rem)] max-h-[-webkit-fill-available] p-[30px] pb-0 overflow-y-auto relative">
                        <div className="mb-[3.7rem]">
                            <div className="form-group flex mb-4">
                                <label className="mt-3">
                                    Role Name <span className="text-light-150">*</span>
                                </label>
                                <Input
                                    className="border-[rgba(85,85,85,0.4)] min-w-[300px] border-[1px] rounded-[8px] py-2 ml-[5rem] px-3"
                                    placeholder="Manager"
                                    value={values?.role_name || RoleData?.role_name}
                                    onChange={handleChange}
                                    name="role_name"
                                    type="text"
                                />
                            </div>
                            <div className="form-group flex">
                                <label className="mt-3">Description</label>
                                <Input
                                    placeholder="Max. 500 characters"
                                    name="role_description"
                                    rows="5"
                                    cols=""
                                    value={values?.role_description || RoleData?.role_description}
                                    onChange={handleChange}
                                    className="ml-[5.5rem] border-[1px] min-w-[300px] rounded-[8px] border-[rgba(85,85,85,0.4)] px-3 py-2"
                                />
                            </div>
                        </div>
                        <div className="mb-10">
                            <table className="w-full mb-7 rolemasterTable dual text-left shadow-[0px_4px_20px_rgba(85,85,85,0.2)]">
                                <thead>
                                    <tr>
                                        <th className="font-interSb">Organization Management</th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th>
                                            <FormControlLabel
                                                sx={{
                                                    width: "150px",
                                                }}
                                                control={
                                                    <Checkbox
                                                        sx={{
                                                            color: "rgba(85,85,85,0.7)",
                                                            "&.Mui-checked": {
                                                                color: "#028C78",
                                                            },
                                                        }}
                                                    />
                                                }
                                                label="Select All"
                                            />
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <>
                                        <tr>
                                            <td></td>
                                            <td>
                                                Full <br /> Access
                                            </td>
                                            <td id={3}>View</td>
                                            <td id={1}>Add</td>
                                            <td id={2}>Edit</td>
                                            <td id={4}>Active</td>
                                            <td id={5}>Inactive</td>
                                            <td id={12}>Delete</td>
                                            <td></td>
                                        </tr>

                                        <tr>
                                            <>
                                                {/* <Contents elems={elem} /> */}

                                                <>
                                                    <td>{Roleorg?.menu_name}</td>

                                                    <td>
                                                        <Checkbox
                                                            //   checked={ActFAOrg?true:true}
                                                            sx={{
                                                                color: "rgba(85,85,85,0.7)",
                                                                "&.Mui-checked": {
                                                                    color: "#028C78",
                                                                },
                                                            }}
                                                        />
                                                    </td>

                                                    <>
                                                        <td>
                                                            <Checkbox
                                                                checked={RoleActOrgId3?.isallowed ? true : false}
                                                                id={RoleActOrgId3?.id}
                                                                disabled={RoleActOrgId3?.isactive}
                                                                onChange={(event) => {
                                                                    handleChange(
                                                                        {
                                                                            target: {
                                                                                name: event.target.name,
                                                                                value: event.target.checked,
                                                                            },
                                                                        },
                                                                        RoleActOrgId3
                                                                    );
                                                                }}
                                                                sx={{
                                                                    color: "rgba(85,85,85,0.7)",
                                                                    "&.Mui-checked": {
                                                                        color: "#028C78",
                                                                    },
                                                                }}
                                                            />
                                                        </td>
                                                        <td>
                                                            <Checkbox
                                                                id={RoleActOrgId1?.id}
                                                                onChange={(event) => {
                                                                    handleChange(
                                                                        {
                                                                            target: {
                                                                                name: event.target.name,
                                                                                value: event.target.checked,
                                                                            },
                                                                        },
                                                                        RoleActOrgId1?.id
                                                                    );
                                                                }}
                                                                checked={RoleActOrgId1?.isallowed ? true : false}
                                                                sx={{
                                                                    color: "rgba(85,85,85,0.7)",
                                                                    "&.Mui-checked": {
                                                                        color: "#028C78",
                                                                    },
                                                                }}
                                                            />
                                                        </td>
                                                        <td>
                                                            <Checkbox
                                                                id={RoleActOrgId2?.id}
                                                                onChange={(event) => {
                                                                    handleChange(
                                                                        {
                                                                            target: {
                                                                                name: event.target.name,
                                                                                value: event.target.checked,
                                                                            },
                                                                        },
                                                                        RoleActOrgId2?.id
                                                                    );
                                                                }}
                                                                checked={RoleActOrgId2?.isallowed !== undefined && RoleActOrgId2?.isallowed}
                                                                sx={{
                                                                    color: "rgba(85,85,85,0.7)",
                                                                    "&.Mui-checked": {
                                                                        color: "#028C78",
                                                                    },
                                                                }}
                                                            />
                                                        </td>
                                                        <td>
                                                            <Checkbox
                                                                id={RoleActOrgId4?.id}
                                                                onChange={handleChange}
                                                                checked={RoleActOrgId4?.isallowed ? true : false}
                                                                sx={{
                                                                    color: "rgba(85,85,85,0.7)",
                                                                    "&.Mui-checked": {
                                                                        color: "#028C78",
                                                                    },
                                                                }}
                                                            />
                                                        </td>
                                                        <td>
                                                            <Checkbox
                                                                id={RoleActOrgId5?.id}
                                                                onChange={handleChange}
                                                                checked={RoleActOrgId5?.isallowed ? true : false}
                                                                sx={{
                                                                    color: "rgba(85,85,85,0.7)",
                                                                    "&.Mui-checked": {
                                                                        color: "#028C78",
                                                                    },
                                                                }}
                                                            />
                                                        </td>
                                                        <td>
                                                            <Checkbox
                                                                disabled
                                                                id={RoleActOrgId12?.id}
                                                                onChange={handleChange}
                                                                checked={RoleActOrgId12?.isallowed ? true : false}
                                                                sx={{
                                                                    color: "rgba(85,85,85,0.7)",
                                                                    "&.Mui-checked": {
                                                                        color: "#028C78",
                                                                    },
                                                                }}
                                                            />
                                                        </td>
                                                    </>
                                                </>
                                            </>
                                        </tr>

                                        <tr>
                                            <td>{RoleEmail?.menu_name}</td>

                                            <>
                                                <td>
                                                    {" "}
                                                    <Checkbox
                                                        // key={elem?.id}
                                                        // id={elem?.id}
                                                        // checked={elem?.isallowed ? true : false}
                                                        sx={{
                                                            color: "rgba(85,85,85,0.7)",
                                                            "&.Mui-checked": {
                                                                color: "#028C78",
                                                            },
                                                        }}
                                                    />
                                                </td>

                                                <td>
                                                    {" "}
                                                    <Checkbox
                                                        id={RoleActEmailId3?.id}
                                                        checked={RoleActEmailId3?.isallowed ? true : false}
                                                        onChange={handleChange}
                                                        sx={{
                                                            color: "rgba(85,85,85,0.7)",
                                                            "&.Mui-checked": {
                                                                color: "#028C78",
                                                            },
                                                        }}
                                                    />
                                                </td>
                                                <td>
                                                    {" "}
                                                    <Checkbox
                                                        id={RoleActEmailId1?.id}
                                                        checked={RoleActEmailId1?.isallowed ? true : false}
                                                        onChange={handleChange}
                                                        sx={{
                                                            color: "rgba(85,85,85,0.7)",
                                                            "&.Mui-checked": {
                                                                color: "#028C78",
                                                            },
                                                        }}
                                                    />
                                                </td>
                                                <td>
                                                    {" "}
                                                    <Checkbox
                                                        id={RoleActEmailId2?.id}
                                                        checked={RoleActEmailId2?.isallowed ? true : false}
                                                        onChange={handleChange}
                                                        sx={{
                                                            color: "rgba(85,85,85,0.7)",
                                                            "&.Mui-checked": {
                                                                color: "#028C78",
                                                            },
                                                        }}
                                                    />
                                                </td>
                                                <td>
                                                    {" "}
                                                    <Checkbox
                                                        id={RoleActEmailId4?.id}
                                                        checked={RoleActEmailId4?.isallowed ? true : false}
                                                        onChange={handleChange}
                                                        sx={{
                                                            color: "rgba(85,85,85,0.7)",
                                                            "&.Mui-checked": {
                                                                color: "#028C78",
                                                            },
                                                        }}
                                                    />
                                                </td>
                                                <td>
                                                    {" "}
                                                    <Checkbox
                                                        id={RoleActEmailId5?.id}
                                                        checked={RoleActEmailId5?.isallowed ? true : false}
                                                        onChange={handleChange}
                                                        sx={{
                                                            color: "rgba(85,85,85,0.7)",
                                                            "&.Mui-checked": {
                                                                color: "#028C78",
                                                            },
                                                        }}
                                                    />
                                                </td>
                                                <td>
                                                    {" "}
                                                    <Checkbox
                                                        disabled
                                                        id={RoleActEmailId12?.id}
                                                        checked={RoleActEmailId12?.isallowed ? true : false}
                                                        onChange={handleChange}
                                                        sx={{
                                                            color: "rgba(85,85,85,0.7)",
                                                            "&.Mui-checked": {
                                                                color: "#028C78",
                                                            },
                                                        }}
                                                    />
                                                </td>
                                            </>
                                        </tr>
                                        <tr>
                                            <td>{RoleFolder?.menu_name}</td>
                                            <>
                                                <td>
                                                    {" "}
                                                    <Checkbox
                                                        sx={{
                                                            color: "rgba(85,85,85,0.7)",
                                                            "&.Mui-checked": {
                                                                color: "#028C78",
                                                            },
                                                        }}
                                                    />
                                                </td>
                                                <td>
                                                    {" "}
                                                    <Checkbox
                                                        id={RoleActFolderId3?.id}
                                                        checked={RoleActFolderId3?.isallowed ? true : false}
                                                        onChange={handleChange}
                                                        sx={{
                                                            color: "rgba(85,85,85,0.7)",
                                                            "&.Mui-checked": {
                                                                color: "#028C78",
                                                            },
                                                        }}
                                                    />
                                                </td>
                                                <td>
                                                    {" "}
                                                    <Checkbox
                                                        id={RoleActFolderId1?.id}
                                                        checked={RoleActFolderId1?.isallowed ? true : false}
                                                        onChange={handleChange}
                                                        sx={{
                                                            color: "rgba(85,85,85,0.7)",
                                                            "&.Mui-checked": {
                                                                color: "#028C78",
                                                            },
                                                        }}
                                                    />
                                                </td>
                                                <td>
                                                    {" "}
                                                    <Checkbox
                                                        id={RoleActFolderId2?.id}
                                                        checked={RoleActFolderId2?.isallowed ? true : false}
                                                        onChange={handleChange}
                                                        sx={{
                                                            color: "rgba(85,85,85,0.7)",
                                                            "&.Mui-checked": {
                                                                color: "#028C78",
                                                            },
                                                        }}
                                                    />
                                                </td>
                                                <td>
                                                    {" "}
                                                    <Checkbox
                                                        id={RoleActFolderId4?.id}
                                                        checked={RoleActFolderId4?.isallowed ? true : false}
                                                        onChange={handleChange}
                                                        sx={{
                                                            color: "rgba(85,85,85,0.7)",
                                                            "&.Mui-checked": {
                                                                color: "#028C78",
                                                            },
                                                        }}
                                                    />
                                                </td>
                                                <td>
                                                    {" "}
                                                    <Checkbox
                                                        id={RoleActFolderId5?.id}
                                                        checked={RoleActFolderId5?.isallowed ? true : false}
                                                        onChange={handleChange}
                                                        sx={{
                                                            color: "rgba(85,85,85,0.7)",
                                                            "&.Mui-checked": {
                                                                color: "#028C78",
                                                            },
                                                        }}
                                                    />
                                                </td>
                                                <td>
                                                    {" "}
                                                    <Checkbox
                                                        id={RoleActFolderId12?.id}
                                                        checked={RoleActFolderId12?.isallowed ? true : false}
                                                        onChange={handleChange}
                                                        disabled
                                                        sx={{
                                                            color: "rgba(85,85,85,0.7)",
                                                            "&.Mui-checked": {
                                                                color: "#028C78",
                                                            },
                                                        }}
                                                    />
                                                </td>
                                            </>

                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td>{RoleTarget?.menu_name}</td>

                                            <>
                                                <td>
                                                    {" "}
                                                    <Checkbox
                                                        sx={{
                                                            color: "rgba(85,85,85,0.7)",
                                                            "&.Mui-checked": {
                                                                color: "#028C78",
                                                            },
                                                        }}
                                                    />
                                                </td>
                                                <td>
                                                    {" "}
                                                    <Checkbox
                                                        id={RoleActTargetId3?.id}
                                                        checked={RoleActTargetId3?.isallowed ? true : false}
                                                        onChange={handleChange}
                                                        sx={{
                                                            color: "rgba(85,85,85,0.7)",
                                                            "&.Mui-checked": {
                                                                color: "#028C78",
                                                            },
                                                        }}
                                                    />
                                                </td>
                                                <td>
                                                    {" "}
                                                    <Checkbox
                                                        id={RoleActTargetId1?.id}
                                                        checked={RoleActTargetId1?.isallowed ? true : false}
                                                        onChange={handleChange}
                                                        sx={{
                                                            color: "rgba(85,85,85,0.7)",
                                                            "&.Mui-checked": {
                                                                color: "#028C78",
                                                            },
                                                        }}
                                                    />
                                                </td>
                                                <td>
                                                    {" "}
                                                    <Checkbox
                                                        id={RoleActTargetId2?.id}
                                                        checked={RoleActTargetId2?.isallowed ? true : false}
                                                        onChange={handleChange}
                                                        sx={{
                                                            color: "rgba(85,85,85,0.7)",
                                                            "&.Mui-checked": {
                                                                color: "#028C78",
                                                            },
                                                        }}
                                                    />
                                                </td>
                                                <td>
                                                    {" "}
                                                    <Checkbox
                                                        id={RoleActTargetId4?.id}
                                                        checked={RoleActTargetId4?.isallowed ? true : false}
                                                        onChange={handleChange}
                                                        sx={{
                                                            color: "rgba(85,85,85,0.7)",
                                                            "&.Mui-checked": {
                                                                color: "#028C78",
                                                            },
                                                        }}
                                                    />
                                                </td>
                                                <td>
                                                    {" "}
                                                    <Checkbox
                                                        id={RoleActTargetId5?.id}
                                                        checked={RoleActTargetId5?.isallowed ? true : false}
                                                        onChange={handleChange}
                                                        sx={{
                                                            color: "rgba(85,85,85,0.7)",
                                                            "&.Mui-checked": {
                                                                color: "#028C78",
                                                            },
                                                        }}
                                                    />
                                                </td>
                                                <td>
                                                    {" "}
                                                    <Checkbox
                                                        id={RoleActTargetId12?.id}
                                                        checked={RoleActTargetId12?.isallowed ? true : false}
                                                        onChange={handleChange}
                                                        disabled
                                                        sx={{
                                                            color: "rgba(85,85,85,0.7)",
                                                            "&.Mui-checked": {
                                                                color: "#028C78",
                                                            },
                                                        }}
                                                    />
                                                </td>
                                            </>

                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td>{RoleUserRole?.menu_name}</td>
                                            <>
                                                <td>
                                                    {" "}
                                                    <Checkbox
                                                        sx={{
                                                            color: "rgba(85,85,85,0.7)",
                                                            "&.Mui-checked": {
                                                                color: "#028C78",
                                                            },
                                                        }}
                                                    />
                                                </td>
                                                <td>
                                                    {" "}
                                                    <Checkbox
                                                        id={RoleActURId3?.id}
                                                        checked={RoleActURId3?.isallowed ? true : false}
                                                        onChange={handleChange}
                                                        sx={{
                                                            color: "rgba(85,85,85,0.7)",
                                                            "&.Mui-checked": {
                                                                color: "#028C78",
                                                            },
                                                        }}
                                                    />
                                                </td>
                                                <td>
                                                    {" "}
                                                    <Checkbox
                                                        id={RoleActURId1?.id}
                                                        checked={RoleActURId1?.isallowed ? true : false}
                                                        onChange={handleChange}
                                                        sx={{
                                                            color: "rgba(85,85,85,0.7)",
                                                            "&.Mui-checked": {
                                                                color: "#028C78",
                                                            },
                                                        }}
                                                    />
                                                </td>
                                                <td>
                                                    {" "}
                                                    <Checkbox
                                                        id={RoleActURId2?.id}
                                                        checked={RoleActURId2?.isallowed ? true : false}
                                                        onChange={handleChange}
                                                        sx={{
                                                            color: "rgba(85,85,85,0.7)",
                                                            "&.Mui-checked": {
                                                                color: "#028C78",
                                                            },
                                                        }}
                                                    />
                                                </td>
                                                <td>
                                                    {" "}
                                                    <Checkbox
                                                        id={RoleActURId4?.id}
                                                        checked={RoleActURId4?.isallowed ? true : false}
                                                        onChange={handleChange}
                                                        disabled
                                                        sx={{
                                                            color: "rgba(85,85,85,0.7)",
                                                            "&.Mui-checked": {
                                                                color: "#028C78",
                                                            },
                                                        }}
                                                    />
                                                </td>
                                                <td>
                                                    {" "}
                                                    <Checkbox
                                                        id={RoleActURId5?.id}
                                                        checked={RoleActURId5?.isallowed ? true : false}
                                                        onChange={handleChange}
                                                        disabled
                                                        sx={{
                                                            color: "rgba(85,85,85,0.7)",
                                                            "&.Mui-checked": {
                                                                color: "#028C78",
                                                            },
                                                        }}
                                                    />
                                                </td>
                                                <td>
                                                    {" "}
                                                    <Checkbox
                                                        id={RoleActURId12?.id}
                                                        checked={RoleActURId12?.isallowed ? true : false}
                                                        onChange={handleChange}
                                                        sx={{
                                                            color: "rgba(85,85,85,0.7)",
                                                            "&.Mui-checked": {
                                                                color: "#028C78",
                                                            },
                                                        }}
                                                    />
                                                </td>
                                            </>
                                            <td></td>
                                        </tr>
                                    </>
                                </tbody>
                            </table>
                            <table className="w-full mb-7 rolemasterTable dual text-left shadow-[0px_4px_20px_rgba(85,85,85,0.2)]">
                                <thead>
                                    <tr>
                                        <th className="font-interSb">User Management</th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        sx={{
                                                            color: "rgba(85,85,85,0.7)",
                                                            "&.Mui-checked": {
                                                                color: "#028C78",
                                                            },
                                                        }}
                                                    />
                                                }
                                                label="Select All"
                                            />
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td></td>
                                        <td>
                                            Full <br /> Access
                                        </td>
                                        <td>View</td>
                                        <td>Add</td>
                                        <td>Edit</td>
                                        <td>Active</td>
                                        <td>Inactive</td>
                                        <td>Delete</td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td>{RoleUser?.menu_name}</td>
                                        <>
                                            <td>
                                                {" "}
                                                <Checkbox
                                                    sx={{
                                                        color: "rgba(85,85,85,0.7)",
                                                        "&.Mui-checked": {
                                                            color: "#028C78",
                                                        },
                                                    }}
                                                />
                                            </td>
                                            <td>
                                                {" "}
                                                <Checkbox
                                                    id={RoleActUserId3?.id}
                                                    checked={RoleActUserId3?.isallowed ? true : false}
                                                    onChange={handleChange}
                                                    sx={{
                                                        color: "rgba(85,85,85,0.7)",
                                                        "&.Mui-checked": {
                                                            color: "#028C78",
                                                        },
                                                    }}
                                                />
                                            </td>
                                            <td>
                                                {" "}
                                                <Checkbox
                                                    id={RoleActUserId1?.id}
                                                    checked={RoleActUserId1?.isallowed ? true : false}
                                                    onChange={handleChange}
                                                    sx={{
                                                        color: "rgba(85,85,85,0.7)",
                                                        "&.Mui-checked": {
                                                            color: "#028C78",
                                                        },
                                                    }}
                                                />
                                            </td>
                                            <td>
                                                {" "}
                                                <Checkbox
                                                    id={RoleActUserId2?.id}
                                                    checked={RoleActUserId2?.isallowed ? true : false}
                                                    onChange={handleChange}
                                                    sx={{
                                                        color: "rgba(85,85,85,0.7)",
                                                        "&.Mui-checked": {
                                                            color: "#028C78",
                                                        },
                                                    }}
                                                />
                                            </td>
                                            <td>
                                                {" "}
                                                <Checkbox
                                                    id={RoleActUserId4?.id}
                                                    checked={RoleActUserId4?.isallowed ? true : false}
                                                    onChange={handleChange}
                                                    sx={{
                                                        color: "rgba(85,85,85,0.7)",
                                                        "&.Mui-checked": {
                                                            color: "#028C78",
                                                        },
                                                    }}
                                                />
                                            </td>
                                            <td>
                                                {" "}
                                                <Checkbox
                                                    id={RoleActUserId5?.id}
                                                    checked={RoleActUserId5?.isallowed ? true : false}
                                                    onChange={handleChange}
                                                    sx={{
                                                        color: "rgba(85,85,85,0.7)",
                                                        "&.Mui-checked": {
                                                            color: "#028C78",
                                                        },
                                                    }}
                                                />
                                            </td>
                                            <td>
                                                {" "}
                                                <Checkbox
                                                    id={RoleActUserId12?.id}
                                                    checked={RoleActUserId12?.isallowed ? true : false}
                                                    onChange={handleChange}
                                                    disabled
                                                    sx={{
                                                        color: "rgba(85,85,85,0.7)",
                                                        "&.Mui-checked": {
                                                            color: "#028C78",
                                                        },
                                                    }}
                                                />
                                            </td>
                                        </>
                                        <td className="text-left">
                                            <LightTooltip
                                                title={
                                                    <div className="flex items-start px-4 py-3 rounded-[10px]">
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    id={RoleActUserId13?.id}
                                                                    checked={RoleActUserId13?.isallowed ? true : false}
                                                                    onChange={handleChange}
                                                                    sx={{
                                                                        color: "rgba(85,85,85,0.7)",
                                                                        "&.Mui-checked": {
                                                                            color: "#028C78",
                                                                        },
                                                                    }}
                                                                />
                                                            }
                                                            label="Task Activity"
                                                        />
                                                    </div>
                                                }
                                                open={tooltipOpen}
                                                onClose={() => setTooltipOpen(false)}
                                            >
                                                <span className="cursor-pointer" onClick={handleSpanClick}>
                                                    Kavi
                                                </span>
                                            </LightTooltip>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>{RoleOrgRole?.menu_name}</td>
                                        <>
                                            <td>
                                                {" "}
                                                <Checkbox
                                                    sx={{
                                                        color: "rgba(85,85,85,0.7)",
                                                        "&.Mui-checked": {
                                                            color: "#028C78",
                                                        },
                                                    }}
                                                />
                                            </td>
                                            <td>
                                                {" "}
                                                <Checkbox
                                                    id={RoleActOrgRoleId3?.id}
                                                    checked={RoleActOrgRoleId3?.isallowed ? true : false}
                                                    onChange={handleChange}
                                                    sx={{
                                                        color: "rgba(85,85,85,0.7)",
                                                        "&.Mui-checked": {
                                                            color: "#028C78",
                                                        },
                                                    }}
                                                />
                                            </td>
                                            <td>
                                                {" "}
                                                <Checkbox
                                                    id={RoleActOrgRoleId1?.id}
                                                    checked={RoleActOrgRoleId1?.isallowed ? true : false}
                                                    onChange={handleChange}
                                                    sx={{
                                                        color: "rgba(85,85,85,0.7)",
                                                        "&.Mui-checked": {
                                                            color: "#028C78",
                                                        },
                                                    }}
                                                />
                                            </td>
                                            <td>
                                                {" "}
                                                <Checkbox
                                                    id={RoleActOrgRoleId2?.id}
                                                    checked={RoleActOrgRoleId2?.isallowed ? true : false}
                                                    onChange={handleChange}
                                                    sx={{
                                                        color: "rgba(85,85,85,0.7)",
                                                        "&.Mui-checked": {
                                                            color: "#028C78",
                                                        },
                                                    }}
                                                />
                                            </td>
                                            <td>
                                                {" "}
                                                <Checkbox
                                                    id={RoleActOrgRoleId4?.id}
                                                    checked={RoleActOrgRoleId4?.isallowed ? true : false}
                                                    onChange={handleChange}
                                                    sx={{
                                                        color: "rgba(85,85,85,0.7)",
                                                        "&.Mui-checked": {
                                                            color: "#028C78",
                                                        },
                                                    }}
                                                />
                                            </td>
                                            <td>
                                                {" "}
                                                <Checkbox
                                                    id={RoleActOrgRoleId5?.id}
                                                    checked={RoleActOrgRoleId5?.isallowed ? true : false}
                                                    onChange={handleChange}
                                                    sx={{
                                                        color: "rgba(85,85,85,0.7)",
                                                        "&.Mui-checked": {
                                                            color: "#028C78",
                                                        },
                                                    }}
                                                />
                                            </td>
                                            <td>
                                                {" "}
                                                <Checkbox
                                                    id={RoleActOrgRoleId12?.id}
                                                    checked={RoleActOrgRoleId12?.isallowed ? true : false}
                                                    onChange={handleChange}
                                                    sx={{
                                                        color: "rgba(85,85,85,0.7)",
                                                        "&.Mui-checked": {
                                                            color: "#028C78",
                                                        },
                                                    }}
                                                />
                                            </td>
                                        </>
                                        <td></td>
                                    </tr>
                                </tbody>
                            </table>
                            <table className="w-full mb-7 rolemasterTable dual text-left shadow-[0px_4px_20px_rgba(85,85,85,0.2)]">
                                <thead>
                                    <tr>
                                        <th className="font-interSb">Invoice Automation</th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        sx={{
                                                            color: "rgba(85,85,85,0.7)",
                                                            "&.Mui-checked": {
                                                                color: "#028C78",
                                                            },
                                                        }}
                                                    />
                                                }
                                                label="Select All"
                                            />
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td></td>
                                        <td>
                                            Full <br /> Access
                                        </td>
                                        <td>View</td>
                                        <td>Edit</td>
                                        <td>Approve</td>
                                        <td>Reject</td>
                                        <td>Flag</td>
                                        <td>Task View</td>
                                        <td>Task Add</td>
                                        <td>Task Edit</td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td>{RoleProcess?.menu_name}</td>
                                        <>
                                            <td>
                                                {" "}
                                                <Checkbox
                                                    sx={{
                                                        color: "rgba(85,85,85,0.7)",
                                                        "&.Mui-checked": {
                                                            color: "#028C78",
                                                        },
                                                    }}
                                                />
                                            </td>
                                            <td>
                                                {" "}
                                                <Checkbox
                                                    id={RoleActProcessId3?.id}
                                                    checked={RoleActProcessId3?.isallowed ? true : false}
                                                    onChange={handleChange}
                                                    sx={{
                                                        color: "rgba(85,85,85,0.7)",
                                                        "&.Mui-checked": {
                                                            color: "#028C78",
                                                        },
                                                    }}
                                                />
                                            </td>
                                            <td>
                                                {" "}
                                                <Checkbox
                                                    id={RoleActProcessId2?.id}
                                                    checked={RoleActProcessId2?.isallowed ? true : false}
                                                    onChange={handleChange}
                                                    sx={{
                                                        color: "rgba(85,85,85,0.7)",
                                                        "&.Mui-checked": {
                                                            color: "#028C78",
                                                        },
                                                    }}
                                                />
                                            </td>
                                            <td>
                                                {" "}
                                                <Checkbox
                                                    id={RoleActProcessId6?.id}
                                                    checked={RoleActProcessId6?.isallowed ? true : false}
                                                    onChange={handleChange}
                                                    sx={{
                                                        color: "rgba(85,85,85,0.7)",
                                                        "&.Mui-checked": {
                                                            color: "#028C78",
                                                        },
                                                    }}
                                                />
                                            </td>
                                            <td>
                                                {" "}
                                                <Checkbox
                                                    id={RoleActProcessId9?.id}
                                                    checked={RoleActProcessId9?.isallowed ? true : false}
                                                    onChange={handleChange}
                                                    sx={{
                                                        color: "rgba(85,85,85,0.7)",
                                                        "&.Mui-checked": {
                                                            color: "#028C78",
                                                        },
                                                    }}
                                                />
                                            </td>
                                            <td>
                                                {" "}
                                                <Checkbox
                                                    id={RoleActProcessId18?.id}
                                                    checked={RoleActProcessId18?.isallowed ? true : false}
                                                    onChange={handleChange}
                                                    sx={{
                                                        color: "rgba(85,85,85,0.7)",
                                                        "&.Mui-checked": {
                                                            color: "#028C78",
                                                        },
                                                    }}
                                                />
                                            </td>
                                            <td>
                                                {" "}
                                                <Checkbox
                                                    id={RoleActProcessId15?.id}
                                                    checked={RoleActProcessId15?.isallowed ? true : false}
                                                    onChange={handleChange}
                                                    disabled
                                                    sx={{
                                                        color: "rgba(85,85,85,0.7)",
                                                        "&.Mui-checked": {
                                                            color: "#028C78",
                                                        },
                                                    }}
                                                />
                                            </td>
                                            <td>
                                                {" "}
                                                <Checkbox
                                                    id={RoleActProcessId16?.id}
                                                    checked={RoleActProcessId16?.isallowed ? true : false}
                                                    onChange={handleChange}
                                                    disabled
                                                    sx={{
                                                        color: "rgba(85,85,85,0.7)",
                                                        "&.Mui-checked": {
                                                            color: "#028C78",
                                                        },
                                                    }}
                                                />
                                            </td>
                                            <td>
                                                {" "}
                                                <Checkbox
                                                    id={RoleActProcessId17?.id}
                                                    checked={RoleActProcessId17?.isallowed ? true : false}
                                                    onChange={handleChange}
                                                    disabled
                                                    sx={{
                                                        color: "rgba(85,85,85,0.7)",
                                                        "&.Mui-checked": {
                                                            color: "#028C78",
                                                        },
                                                    }}
                                                />
                                            </td>
                                        </>
                                        <td className="text-left">
                                            <LightTooltip
                                                title={
                                                    <div className="flex items-start px-4 py-3 rounded-[10px]">
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    id={RoleActProcessId13?.id}
                                                                    checked={RoleActProcessId13?.isallowed ? true : false}
                                                                    onChange={handleChange}
                                                                    sx={{
                                                                        color: "rgba(85,85,85,0.7)",
                                                                        "&.Mui-checked": {
                                                                            color: "#028C78",
                                                                        },
                                                                    }}
                                                                />
                                                            }
                                                            label="Task Activity"
                                                        />
                                                    </div>
                                                }
                                            >
                                                <span className="cursor-pointer">More Permissions</span>
                                            </LightTooltip>
                                        </td>

                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td>{RoleException?.menu_name}</td>

                                        <td>
                                            {" "}
                                            <Checkbox
                                                sx={{
                                                    color: "rgba(85,85,85,0.7)",
                                                    "&.Mui-checked": {
                                                        color: "#028C78",
                                                    },
                                                }}
                                            />
                                        </td>
                                        <td>
                                            {" "}
                                            <Checkbox
                                                id={RoleActExceptionId3?.id}
                                                checked={RoleActExceptionId3?.isallowed ? true : false}
                                                onChange={handleChange}
                                                sx={{
                                                    color: "rgba(85,85,85,0.7)",
                                                    "&.Mui-checked": {
                                                        color: "#028C78",
                                                    },
                                                }}
                                            />
                                        </td>
                                        <td>
                                            {" "}
                                            <Checkbox
                                                id={RoleActExceptionId2?.id}
                                                checked={RoleActExceptionId2?.isallowed ? true : false}
                                                onChange={handleChange}
                                                sx={{
                                                    color: "rgba(85,85,85,0.7)",
                                                    "&.Mui-checked": {
                                                        color: "#028C78",
                                                    },
                                                }}
                                            />
                                        </td>
                                        <td>
                                            {" "}
                                            <Checkbox
                                                id={RoleActExceptionId6?.id}
                                                checked={RoleActExceptionId6?.isallowed ? true : false}
                                                onChange={handleChange}
                                                sx={{
                                                    color: "rgba(85,85,85,0.7)",
                                                    "&.Mui-checked": {
                                                        color: "#028C78",
                                                    },
                                                }}
                                            />
                                        </td>
                                        <td>
                                            {" "}
                                            <Checkbox
                                                id={RoleActExceptionId9?.id}
                                                checked={RoleActExceptionId9?.isallowed ? true : false}
                                                onChange={handleChange}
                                                sx={{
                                                    color: "rgba(85,85,85,0.7)",
                                                    "&.Mui-checked": {
                                                        color: "#028C78",
                                                    },
                                                }}
                                            />
                                        </td>
                                        <td>
                                            {" "}
                                            <Checkbox
                                                id={RoleActExceptionId18?.id}
                                                checked={RoleActExceptionId18?.isallowed ? true : false}
                                                onChange={handleChange}
                                                sx={{
                                                    color: "rgba(85,85,85,0.7)",
                                                    "&.Mui-checked": {
                                                        color: "#028C78",
                                                    },
                                                }}
                                            />
                                        </td>
                                        <td>
                                            {" "}
                                            <Checkbox
                                                id={RoleActExceptionId15?.id}
                                                checked={RoleActExceptionId15?.isallowed ? true : false}
                                                onChange={handleChange}
                                                disabled
                                                sx={{
                                                    color: "rgba(85,85,85,0.7)",
                                                    "&.Mui-checked": {
                                                        color: "#028C78",
                                                    },
                                                }}
                                            />
                                        </td>
                                        <td>
                                            {" "}
                                            <Checkbox
                                                id={RoleActExceptionId16?.id}
                                                checked={RoleActExceptionId16?.isallowed ? true : false}
                                                onChange={handleChange}
                                                disabled
                                                sx={{
                                                    color: "rgba(85,85,85,0.7)",
                                                    "&.Mui-checked": {
                                                        color: "#028C78",
                                                    },
                                                }}
                                            />
                                        </td>
                                        <td>
                                            {" "}
                                            <Checkbox
                                                id={RoleActExceptionId17?.id}
                                                checked={RoleActExceptionId17?.isallowed ? true : false}
                                                onChange={handleChange}
                                                disabled
                                                sx={{
                                                    color: "rgba(85,85,85,0.7)",
                                                    "&.Mui-checked": {
                                                        color: "#028C78",
                                                    },
                                                }}
                                            />
                                        </td>
                                        <td></td>
                                    </tr>
                                </tbody>
                            </table>
                            <div className="bg-[rgba(237,252,250,0.5)] agreed rounded-[8px] shadow-[0px_4px_20px_rgba(85,85,85,0.2)] px-[20px] py-2">
                                <FormControlLabel
                                    className="block"
                                    control={
                                        <Checkbox
                                            id={RoleActManualUploadId21?.id}
                                            checked={RoleActManualUploadId21?.isallowed ? true : false}
                                            onChange={handleChange}
                                            sx={{
                                                color: "rgba(85,85,85,0.7)",
                                                "&.Mui-checked": {
                                                    color: "#028C78",
                                                },
                                            }}
                                        />
                                    }
                                    label={RoleManualUpload?.menu_name}
                                />
                                <FormControlLabel
                                    className="block"
                                    control={
                                        <Checkbox
                                            id={RoleActReportId20?.id}
                                            checked={RoleActReportId20?.isallowed ? true : false}
                                            onChange={handleChange}
                                            sx={{
                                                color: "rgba(85,85,85,0.7)",
                                                "&.Mui-checked": {
                                                    color: "#028C78",
                                                },
                                            }}
                                        />
                                    }
                                    label={RoleReports?.menu_name}
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            id={RoleActDashboardId19?.id}
                                            checked={RoleActDashboardId19?.isallowed ? true : false}
                                            onChange={handleChange}
                                            sx={{
                                                color: "rgba(85,85,85,0.7)",
                                                "&.Mui-checked": {
                                                    color: "#028C78",
                                                },
                                            }}
                                        />
                                    }
                                    label={RoleDashboard?.menu_name}
                                />
                            </div>
                        </div>
                        <div className="bg-white py-[20px] space-x-2 mx-[-30px] px-[30px] sticky bottom-0 shadow-[0px_4px_20px_rgba(85,85,85,0.2)] border-[rgba(223,225,226,0.56)] border-t-[1px] rounded-b-[8px]">
                            <Button className="bg-light-20 text-base text-white rounded-[8px] py-2 px-7" onClick={submit}>
                                Save
                            </Button>
                            <a href="role-master">
                                <Button className="bg-light-240 text-base rounded-[8px] py-2 px-7">Cancel</Button>
                            </a>
                        </div>
                    </Card>
                </Main>
                <Footer />
            </Layout>
        </>
    );
};

export default EditRole;
