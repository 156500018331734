import {
	ADD_UPDATE_EXCHANGE_RATE_FAILURE,
	ADD_UPDATE_EXCHANGE_RATE_REQUEST,
	ADD_UPDATE_EXCHANGE_RATE_RESET,
	ADD_UPDATE_EXCHANGE_RATE_SUCCESS,
	CURRENCY_DROPDOWN_BYORG_EXCHANGE_RATE_FAILURE,
	CURRENCY_DROPDOWN_BYORG_EXCHANGE_RATE_REQUEST,
	CURRENCY_DROPDOWN_BYORG_EXCHANGE_RATE_RESET,
	CURRENCY_DROPDOWN_BYORG_EXCHANGE_RATE_SUCCESS,
	CURRENCY_DROPDOWN_EXCHANGE_RATE_FAILURE,
	CURRENCY_DROPDOWN_EXCHANGE_RATE_REQUEST,
	CURRENCY_DROPDOWN_EXCHANGE_RATE_RESET,
	CURRENCY_DROPDOWN_EXCHANGE_RATE_SUCCESS,
	DELETE_EXCHANGE_RATE_FAILURE,
	DELETE_EXCHANGE_RATE_REQUEST,
	DELETE_EXCHANGE_RATE_RESET,
	DELETE_EXCHANGE_RATE_SUCCESS,
	HISTORY_EXCHANGE_RATE_FAILURE,
	HISTORY_EXCHANGE_RATE_REQUEST,
	HISTORY_EXCHANGE_RATE_RESET,
	HISTORY_EXCHANGE_RATE_SUCCESS,
	LIST_EXCHANGE_RATE_FAILURE,
	LIST_EXCHANGE_RATE_REQUEST,
	LIST_EXCHANGE_RATE_RESET,
	LIST_EXCHANGE_RATE_SUCCESS,
	UPLOAD_EXCHANGE_RATE_FAILURE,
	UPLOAD_EXCHANGE_RATE_REQUEST,
	UPLOAD_EXCHANGE_RATE_RESET,
	UPLOAD_EXCHANGE_RATE_SUCCESS,
} from "../types/types";

const addUpdateState = {
	data: {},
	fetching: false,
	requestComplete: false,
	error: "",
};

export function AddUpdateExchangeRateReducer(state = addUpdateState, action) {
	switch (action.type) {
		case ADD_UPDATE_EXCHANGE_RATE_REQUEST:
			return {
				...state,
				fetching: true,
				requestComplete: false,
				error: "",
			};
		case ADD_UPDATE_EXCHANGE_RATE_SUCCESS:
			return {
				data: action.payload,
				fetching: false,
				requestComplete: true,
				error: "",
			};

		case ADD_UPDATE_EXCHANGE_RATE_RESET:
			return {
				data: {},
				fetching: false,
				requestComplete: false,
				error: "",
			};
		case ADD_UPDATE_EXCHANGE_RATE_FAILURE:
			return {
				fetching: false,
				requestComplete: false,
				error: action?.payload,
			};
		default:
			return state;
	}
}

const UploadState = {
	data: {},
	fetching: false,
	requestComplete: false,
	error: "",
};

export function UploadExchangeRateReducer(state = UploadState, action) {
	switch (action.type) {
		case UPLOAD_EXCHANGE_RATE_REQUEST:
			return {
				...state,
				fetching: true,
				requestComplete: false,
				error: "",
			};
		case UPLOAD_EXCHANGE_RATE_SUCCESS:
			return {
				data: action.payload,
				fetching: false,
				requestComplete: true,
				error: "",
			};

		case UPLOAD_EXCHANGE_RATE_RESET:
			return {
				data: {},
				fetching: false,
				requestComplete: false,
				error: "",
			};
		case UPLOAD_EXCHANGE_RATE_FAILURE:
			return {
				fetching: false,
				requestComplete: false,
				error: action.payload,
			};
		default:
			return state;
	}
}

const currencyState = {
	data: [],
	fetching: false,
	requestComplete: false,
	error: "",
};
export function CurrencyExchangeRateReducer(state = currencyState, action) {
	switch (action.type) {
		case CURRENCY_DROPDOWN_EXCHANGE_RATE_REQUEST:
			return {
				...state,
				fetching: true,
				requestComplete: false,
				error: "",
			};
		case CURRENCY_DROPDOWN_EXCHANGE_RATE_SUCCESS:
			return {
				data: action.payload,
				fetching: false,
				requestComplete: true,
				error: "",
			};
		case CURRENCY_DROPDOWN_EXCHANGE_RATE_RESET:
			return {
				data: [],
				fetching: false,
				requestComplete: false,
				error: "",
			};
		case CURRENCY_DROPDOWN_EXCHANGE_RATE_FAILURE:
			return {
				fetching: false,
				requestComplete: false,
				error: action.payload,
			};
		default:
			return state;
	}
}
const currencyStateByOrg = {
	data: [],
	fetching: false,
	requestComplete: false,
	error: "",
};
export function CurrencyExchangeRateByOrgReducer(state = currencyStateByOrg, action) {
	switch (action.type) {
		case CURRENCY_DROPDOWN_BYORG_EXCHANGE_RATE_REQUEST:
			return {
				...state,
				fetching: true,
				requestComplete: false,
				error: "",
			};
		case CURRENCY_DROPDOWN_BYORG_EXCHANGE_RATE_SUCCESS:
			return {
				data: action.payload,
				fetching: false,
				requestComplete: true,
				error: "",
			};
		case CURRENCY_DROPDOWN_BYORG_EXCHANGE_RATE_RESET:
			return {
				data: [],
				fetching: false,
				requestComplete: false,
				error: "",
			};
		case CURRENCY_DROPDOWN_BYORG_EXCHANGE_RATE_FAILURE:
			return {
				fetching: false,
				requestComplete: false,
				error: action.payload,
			};
		default:
			return state;
	}
}
const deleteState = {
	data: [],
	fetching: false,
	requestComplete: false,
	error: "",
};
export function DeleteExchangeRateReducer(state = deleteState, action) {
	switch (action.type) {
		case DELETE_EXCHANGE_RATE_REQUEST:
			return {
				...state,
				fetching: true,
				requestComplete: false,
				error: "",
			};
		case DELETE_EXCHANGE_RATE_SUCCESS:
			return {
				data: action.payload,
				fetching: false,
				requestComplete: true,
				error: "",
			};
		case DELETE_EXCHANGE_RATE_RESET:
			return {
				data: [],
				fetching: false,
				requestComplete: false,
				error: "",
			};
		case DELETE_EXCHANGE_RATE_FAILURE:
			return {
				fetching: false,
				requestComplete: false,
				error: action.payload,
			};
		default:
			return state;
	}
}

const HistoryState = {
	dataList: [],
	fetching: false,
	requestComplete: false,
	error: "",
};

export function HistoryExchangeRateReducer(state = HistoryState, action) {
	switch (action.type) {
		case HISTORY_EXCHANGE_RATE_REQUEST:
			return {
				...state,
				fetching: true,
				requestComplete: false,
				error: "",
			};
		case HISTORY_EXCHANGE_RATE_SUCCESS:
			return {
				dataList: action.payload,
				fetching: false,
				requestComplete: true,
				error: "",
			};

		case HISTORY_EXCHANGE_RATE_RESET:
			return {
				dataList: [],
				fetching: false,
				requestComplete: false,
				error: "",
			};
		case HISTORY_EXCHANGE_RATE_FAILURE:
			return {
				fetching: false,
				requestComplete: false,
				error: action.payload,
			};
		default:
			return state;
	}
}

const ListState = {
	dataList: [],
	fetching: false,
	requestComplete: false,
	error: "",
};

export function ExchangeRateListReducer(state = ListState, action) {
	switch (action.type) {
		case LIST_EXCHANGE_RATE_REQUEST:
			return {
				...state,
				fetching: true,
				requestComplete: false,
				error: "",
			};
		case LIST_EXCHANGE_RATE_SUCCESS:
			return {
				dataList: action.payload,
				fetching: false,
				requestComplete: true,
				error: "",
			};
		case LIST_EXCHANGE_RATE_RESET:
			return {
				dataList: [],
				fetching: false,
				requestComplete: false,
				error: "",
			};
		case LIST_EXCHANGE_RATE_FAILURE:
			return {
				fetching: false,
				requestComplete: false,
				error: action.payload,
			};
		default:
			return state;
	}
}
