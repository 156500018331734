import Footer from "../organisms/Footer";
import Header from "../organisms/Header";
import Sidebar from "../organisms/Sidebar";

const Layout = (props) => {
    return (
        <>
            <Sidebar />
            <Header />
            <div className="bg-190">{props?.children}</div>
        </>
    );
};

export default Layout;
