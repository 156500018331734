import {
    GET_ORGANISATION_FAILURE,
    GET_ORGANISATION_REQUEST,
    GET_ORGANISATION_RESET,
    GET_ORGANISATION_SUCCESS,
    GET_ORGCOUNT_FAILURE,
    GET_ORGCOUNT_REQUEST,
    GET_ORGCOUNT_RESET,
    GET_ORGCOUNT_SUCCESS,
    ORG_ADD_FAILURE,
    ORG_ADD_REQUEST,
    ORG_ADD_RESET,
    ORG_ADD_SUCCESS,
    ORG_EDIT_FAILURE,
    ORG_EDIT_REQUEST,
    ORG_EDIT_RESET,
    ORG_EDIT_SUCCESS,
    ORG_VIEW_FAILURE,
    ORG_VIEW_REQUEST,
    ORG_VIEW_RESET,
    ORG_VIEW_SUCCESS,
    ORG_STATUS_UPDATE_REQUEST,
    ORG_STATUS_UPDATE_SUCCESS,
    ORG_STATUS_UPDATE_FAILURE,
    ORG_STATUS_UPDATE_RESET,
    ORG_DOMAIN_REQUEST,
    ORG_DOMAIN_SUCCESS,
    ORG_DOMAIN_FAILURE,
    ORG_DOMAIN_RESET,
    GET_ORGLIST_DROP_DOWN_REQUEST,
    GET_ORGLIST_DROP_DOWN_SUCCESS,
    GET_ORGLIST_DROP_DOWN_RESET,
    GET_ORGLIST_DROP_DOWN_FAILURE,
    GET_SAP_FINANCIAL_YEAR_REQUEST,
    GET_SAP_FINANCIAL_YEAR_SUCCESS,
    GET_SAP_FINANCIAL_YEAR_RESET,
    GET_SAP_FINANCIAL_YEAR_FAILURE,
} from "../types/types";

const ListOrgState = {
    OrgList: [],
    fetching: false,
    requestComplete: false,
    error: "",
};

export function organisationList(state = ListOrgState, action) {
    switch (action.type) {
        case GET_ORGANISATION_REQUEST:
            return {
                ...state,
                fetching: true,
                requestComplete: false,
                error: "",
            };
        case GET_ORGANISATION_SUCCESS:
            return {
                OrgList: action.payload,
                fetching: false,
                requestComplete: true,
                error: "",
            };
        case GET_ORGANISATION_RESET:
            return {
                OrgList: [],
                fetching: false,
                requestComplete: false,
                error: "",
            };
        case GET_ORGANISATION_FAILURE:
            return {
                fetching: false,
                requestComplete: false,
                error: action.payload,
            };
        default:
            return state;
    }
}
const countOrgState = {
    data: [],
    fetching: false,
    requestComplete: false,
    error: "",
};
export function organisationCount(state = countOrgState, action) {
    switch (action.type) {
        case GET_ORGCOUNT_REQUEST:
            return {
                ...state,
                fetching: true,
                requestComplete: false,
                error: "",
            };
        case GET_ORGCOUNT_SUCCESS:
            return {
                data: action.payload,
                fetching: false,
                requestComplete: true,
                error: "",
            };
        case GET_ORGCOUNT_RESET:
            return {
                data: [],
                fetching: false,
                requestComplete: false,
                error: "",
            };
        case GET_ORGCOUNT_FAILURE:
            return {
                fetching: false,
                requestComplete: false,
                error: action.payload,
            };
        default:
            return state;
    }
}
const viewOrgState = {
    viewData: [],
    fetching: false,
    requestComplete: false,
    error: "",
};
export function organisationView(state = viewOrgState, action) {
    switch (action.type) {
        case ORG_VIEW_REQUEST:
            return {
                ...state,
                fetching: true,
                requestComplete: false,
                error: "",
            };
        case ORG_VIEW_SUCCESS:
            return {
                viewData: action.payload,
                fetching: false,
                requestComplete: true,
                error: "",
            };
        case ORG_VIEW_RESET:
            return {
                viewData: [],
                fetching: false,
                requestComplete: false,
                error: "",
            };
        case ORG_VIEW_FAILURE:
            return {
                fetching: false,
                requestComplete: false,
                error: action.payload,
            };
        default:
            return state;
    }
}
const editOrgState = {
    data: {},
    fetching: false,
    requestComplete: false,
    error: "",
};
export function organisationEdit(state = editOrgState, action) {
    switch (action.type) {
        case ORG_EDIT_REQUEST:
            return {
                ...state,
                fetching: true,
                requestComplete: false,
                error: "",
            };
        case ORG_EDIT_SUCCESS:
            return {
                data: action.payload,
                fetching: false,
                requestComplete: true,
                error: "",
            };
        case ORG_EDIT_RESET:
            return {
                data: {},
                fetching: false,
                requestComplete: false,
                error: "",
            };
        case ORG_EDIT_FAILURE:
            return {
                fetching: false,
                requestComplete: false,
                error: action.payload,
            };
        default:
            return state;
    }
}
const addOrgState = {
    profile: [],
    fetching: false,
    requestComplete: false,
    error: "",
};
export function organisationAdd(state = addOrgState, action) {
    switch (action.type) {
        case ORG_ADD_REQUEST:
            return {
                ...state,
                fetching: true,
                requestComplete: false,
                error: "",
            };
        case ORG_ADD_SUCCESS:
            return {
                profile: action.payload,
                fetching: false,
                requestComplete: true,
                error: "",
            };
        case ORG_ADD_RESET:
            return {
                profile: {},
                fetching: false,
                requestComplete: false,
                error: "",
            };
        case ORG_ADD_FAILURE:
            return {
                profile: action.payload,
                fetching: false,
                requestComplete: true,
                error: action.payload,
            };
        default:
            return state;
    }
}

const orgStatusUpdate = {
    data: [],
    fetching: false,
    requestComplete: false,
    error: "",
};
export function organisationStatusUpdate(state = orgStatusUpdate, action) {
    switch (action.type) {
        case ORG_STATUS_UPDATE_REQUEST:
            return {
                ...state,
                fetching: true,
                requestComplete: false,
                error: "",
            };
        case ORG_STATUS_UPDATE_SUCCESS:
            return {
                result: action.payload,
                fetching: false,
                requestComplete: true,
                error: "",
            };
        case ORG_STATUS_UPDATE_RESET:
            return {
                result: {},
                fetching: false,
                requestComplete: false,
                error: "",
            };
        case ORG_STATUS_UPDATE_FAILURE:
            return {
                fetching: false,
                requestComplete: false,
                error: action.payload,
            };
        default:
            return state;
    }
}

const orgGetDomain = {
    data: [],
    fetching: false,
    requestComplete: false,
    error: "",
};

export function organisationDomainDetails(state = orgGetDomain, action) {
    switch (action.type) {
        case ORG_DOMAIN_REQUEST:
            return {
                ...state,
                fetching: true,
                requestComplete: false,
                error: "",
            };
        case ORG_DOMAIN_SUCCESS:
            return {
                result: action.payload,
                fetching: false,
                requestComplete: true,
                error: "",
            };
        case ORG_DOMAIN_FAILURE:
            return {
                result: {},
                fetching: false,
                requestComplete: false,
                error: "",
            };
        case ORG_DOMAIN_RESET:
            return {
                fetching: false,
                requestComplete: false,
                error: action.payload,
            };
        default:
            return state;
    }
}

const listOrgDropDrownState = {
    viewData: [],
    fetching: false,
    requestComplete: false,
    error: "",
};
export function organisationListDropDown(
    state = listOrgDropDrownState,
    action
) {
    switch (action.type) {
        case GET_ORGLIST_DROP_DOWN_REQUEST:
            return {
                ...state,
                fetching: true,
                requestComplete: false,
                error: "",
            };
        case GET_ORGLIST_DROP_DOWN_SUCCESS:
            return {
                viewData: action.payload,
                fetching: false,
                requestComplete: true,
                error: "",
            };
        case GET_ORGLIST_DROP_DOWN_RESET:
            return {
                viewData: [],
                fetching: false,
                requestComplete: false,
                error: "",
            };
        case GET_ORGLIST_DROP_DOWN_FAILURE:
            return {
                fetching: false,
                requestComplete: false,
                error: action.payload,
            };
        default:
            return state;
    }
}

//GET SAP FINANCIAL YEAR
const ListSapFinancialState = {
    sapList: [],
    fetching: false,
    requestComplete: false,
    error: "",
};

export function sapFinancialList(state = ListSapFinancialState, action) {
    switch (action.type) {
        case GET_SAP_FINANCIAL_YEAR_REQUEST:
            return {
                ...state,
                fetching: true,
                requestComplete: false,
                error: "",
            };
        case GET_SAP_FINANCIAL_YEAR_SUCCESS:
            return {
                sapList: action.payload,
                fetching: false,
                requestComplete: true,
                error: "",
            };
        case GET_SAP_FINANCIAL_YEAR_RESET:
            return {
                sapList: [],
                fetching: false,
                requestComplete: false,
                error: "",
            };
        case GET_SAP_FINANCIAL_YEAR_FAILURE:
            return {
                fetching: false,
                requestComplete: false,
                error: action.payload,
            };
        default:
            return state;
    }
}
