import { useContext, useState } from "react";
import { ThemeContext } from "../../Hooks/Themes/Themeprovider";
import styles from "./index.module.scss";

const Footer = () => {
    const { currentMode } = useContext(ThemeContext);
    // const [privacy,setPrivacy] = useState();
    // const [terms,setTerms] = useState();
    return (
        <footer
            className={`flex justify-between items-center z-[2] ml-[6rem] lg:ml-[7.188rem] bg-${currentMode}-card mr-[35px] py-[10px] text-${currentMode}-copy ${styles?.footer}`}
        >
            <p className="text-xs">2024 © MyiQ.Ai</p>
            <p className="text-xs">
                <a href={"https://www.myiq.ai/privacy-policy"} className={`text-${currentMode}-copy`} target="_blank">
                    Privacy Policy
                </a>{" "}
                |{" "}
                <a href={"https://www.myiq.ai/terms-condition"} className={`text-${currentMode}-copy`} target="_blank">
                    Terms and Conditions
                </a>
            </p>
        </footer>
    );
};

export default Footer;
