import {
    Checkbox,
    ClickAwayListener,
    Fade,
    FormControl,
    FormControlLabel,
    Input,
    MenuItem,
    Paper,
    Popper,
    Select,
    TextField,
    Tooltip,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../../components/Button";
import Card from "../../../components/UI/Card";
import { useState, useEffect, useContext, useMemo } from "react";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import { cartToggleAction } from "../../../redux/actions/filter";
import UseForm from "../../../Hooks/useForm";
import { getCurrentUser } from "../../../redux/actions/auth";
import React from "react";
import Loader from "../../../components/Loader";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import moment from "moment";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { ThemeContext } from "../../../Hooks/Themes/Themeprovider";
import {
    GetAdvanceStatusList,
    getAdvanceList,
    GetListAdvanceReqForPoAndVendor,
    GetListUserForComments,
} from "../../../redux/actions/advance-request.js";
import { decryptId, encryptId } from "../../../utils/helpers.js";
import { Trans, useTranslation } from "react-i18next";
import StatusInfoComponent from "../../Invoice/Processed/StatusInfoComponent";
import ReactDOM from "react-dom";

let options = null;
let Tags;
const Table = (props) => {
    let location = useLocation();
    const { t } = useTranslation(["invoiceList"]);
    const orgData = useSelector((state) => state?.ListorganisationDropDownState);
    const { data: vendorPoData } = useSelector((state) => state?.getAdvanceRequestPoAndVendorListState);
    const [selected, setSelected] = useState("default");
    const [SatusValue, setStatusValue] = useState([]);
    const StatusCount = SatusValue?.filter((el) => el?.isCheck == true);
    const popstate = props?.popstate;
    const setPopState = props?.setPopState;
    const { handleSubmit } = UseForm(submit);
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const [placement, setPlacement] = useState();
    const [currentPage, setCurrentPage] = useState();
    const [openTransfer, setOpenTransfer] = useState(false);
    const [currentPageItem, changePageItem] = useState(10);
    const [approvedList, approvedListItem] = useState();
    const [rejectList, rejectListItem] = useState();
    const [selectedValue, setSelectedValue] = useState("");
    const [isIndeterminate, setIndeterminate] = useState(false);
    const isSorting = props?.isSorting;
    const setSorting = props?.setSorting;
    const sortColumn = props?.sortColumn;
    const columnSorting = props?.columnSorting;
    const [valuecheck, handleValues] = useState([]);
    const [anchorElCalendar, setAnchorElCalendar] = useState(null);
    const [placementCalendar, setPlacementCalendar] = useState();
    const [statusInfoAnchor, setStatusInfoAnchor] = useState(false);
    const filterObj = props?.filterObj;
    const currentUser = getCurrentUser();
    // let bodys = document.querySelector("body").classList;
    const orgId = localStorage.getItem("orgId") || 0;
    const { currentMode } = useContext(ThemeContext);
    const currentPageNo = props?.currentPageNo;
    const changePageNo = props?.changePageNo;
    // location.pathname === "/profile" && bodys.remove(`bg-${currentMode}-base`);
    const ProcessInv = useSelector((state) => state?.InvoiceListState);
    //* For workflow filter
    const statusReset = (filterList) => {
        return filterList?.map((item) => {
            return {
                ...item,
                isActive: false,
            };
        });
    };

    const [isFlag, setisFlag] = useState(false);
    const [CheckFilterValue, setCheckFilterValue] = useState([]);
    const [FilterValue, setFilterValue] = useState([]);
    const Unique = [...new Set(FilterValue)];
    const UniqueValue = [...new Set(CheckFilterValue)];
    const TransferValue = UniqueValue?.filter((el) => el !== "Transferred");
    const TransferValueAlone = UniqueValue?.filter((el) => el == "Transferred");
    const RemoveTransfer = UniqueValue?.length > 1 && UniqueValue?.includes("Transferred") ? TransferValue : UniqueValue;
    const uniqLength = RemoveTransfer?.length - 1;
    const uniqLength2 = uniqLength == "0" ? "" : uniqLength;
    const plusShow = RemoveTransfer?.length > 1 ? "+" : "";
    const StatusValue = RemoveTransfer?.[0] + plusShow + uniqLength2;
    const { dataList } = ProcessInv;
    const [userId, setUserId] = useState(0);
    const letuserId = currentUser?.role_id == 1 ? currentUser?.id : props?.userId;
    const Fromdate =
        moment(new Date(props?.fromDate)).format("YYYY-MM-DD") == "Invalid date" ? "" : moment(new Date(props?.fromDate)).format("YYYY-MM-DD");
    const Todate = moment(new Date(props?.toDate)).format("YYYY-MM-DD") == "Invalid date" ? "" : moment(new Date(props?.toDate)).format("YYYY-MM-DD");
    let reqObject = {
        status_type:
            location.pathname === "/invoice"
                ? 4
                : location.pathname === "/exception"
                ? 1
                : location.pathname === "/processed"
                ? 2
                : location.pathname === "/approve"
                ? 3
                : "",
        status_id: filterObj?.status_id ? filterObj?.status_id : props?.statusOptionArray,
        org_group: currentUser?.organisation,
        isflagged: isFlag,
        vendor_id: filterObj?.vendor_id ? filterObj?.vendor_id : props?.filterSupplierValue,
        invoice_date_from: Fromdate,
        invoice_date_to: Todate,
        search_text: props?.searchValue || "",
        order_column: sortColumn,
        order_by: isSorting,
        // invoice_type: invoiceType,
        pg_no: currentPageNo,
        rec_cnt: currentPageItem,
        organisation: +orgId,
        invoice_no: "",
        document_type: props?.invoiceType?.value,
        alloc_user: letuserId,
    };
    if (props?.searchValue) {
        localStorage.setItem("advanceListSearch", props?.searchValue);
    }

    const handleSelectedValue = (event) => {
        setCheckSelected(false);
        setSelectedValue(event.target.value);
        changePageNo(1);
    };

    const handleValue = (newValue) => {
        if (newValue) {
            props?.setFromDate(newValue);
        }
    };
    const handleToDate = (toDate) => {
        if (toDate) {
            props?.setToDate(toDate);
        }
    };

    let reqObjectCount = {
        status_type:
            location.pathname === "/invoice"
                ? 4
                : location.pathname === "/exception"
                ? 1
                : location.pathname === "/processed"
                ? 2
                : location.pathname === "/approve"
                ? 3
                : "",
        status_id: filterObj?.status_id ? filterObj?.status_id : props?.statusOptionArray,

        isflagged: isFlag,
        invoice_date_from: Fromdate,
        invoice_date_to: Todate,
        search_text: props?.searchValue || "",
        organisation: +orgId,
        // invoice_type: invoiceType,
        vendor_id: filterObj?.vendor_id ? filterObj?.vendor_id : props?.filterSupplierValue,
        invoice_no: "",
        document_type: props?.invoiceType?.value,
        alloc_user: letuserId,
    };
    let reqObj = reqObject;
    // if(props?.filterObj){
    //   reqObj = props.filterObj;
    //   reqObjectCount = props.filterObj;
    // }
    const reqObjCount = reqObjectCount;

    const PeriodTitle =
        (props?.fromDate == "" ? "" : props?.fromDate?.$d?.toLocaleDateString()) +
        (props?.fromDate == "" || props?.toDate == "" ? " " : " to ") +
        (props?.toDate == "" ? "" : props?.toDate?.$d?.toLocaleDateString());
    const [checkSelected, setCheckSelected] = useState(false);

    function submit(reqPara) {
        props?.setStatus(true);
        dispatch(cartToggleAction(true));
        props?.OrderBy(reqObject);
    }

    const onPageNumberSelect = (event) => {
        let pageNumber = event.target.value;
        changePageNo(parseInt(pageNumber));
    };
    const onPageItemSelect = (event) => {
        props?.setAllSelected(false);
        let pageItemVal = event.target.value;
        changePageItem(parseInt(pageItemVal));
        changePageNo(1);
    };
    const previousBtnClick = () => {
        if (props?.isAllSelected === true) {
            props?.setAllSelected(false);
        }
        if (isIndeterminate == true) {
            setIndeterminate(false);
        }
        changePageNo(currentPageNo - 1);
    };
    const nextBtnClick = () => {
        if (props?.isAllSelected === true) {
            props?.setAllSelected(false);
        }
        if (isIndeterminate == true) {
            setIndeterminate(false);
        }
        changePageNo(currentPageNo + 1);
    };
    const resetInputField = () => {
        props?.handleSearchChange("");
        localStorage.removeItem("advanceListSearch", "");
    };
    const handleClickAwayCalendar = () => {
        setOpen(false);
        setAnchorElCalendar(null);
        setPlacementCalendar();
    };
    useEffect(() => {
        if (type == supplier) {
            props?.setFilterSupplierValue(selectedValue?.id);
        }
        if (type == poNumberList) {
            props?.setPoNumber(selectedValue?.po_id);
        }
        if (type == advanceType) {
            props?.setInvoiceType(selectedValue);
        }
    }, [selectedValue]);
    const pageNo = currentPageNo;
    const entries = currentPageItem;
    const handleClickCalendar = (newPlacement) => (event) => {
        setAnchorElCalendar(event.currentTarget);
        setOpen((prev) => placementCalendar !== newPlacement || !prev);
        setPlacementCalendar(newPlacement);
    };
    const AccessIcon = () => <img src="/images/common/date-ico.svg" alt="date-ico" />;

    const dropdownColor = currentMode === "dark" ? "#333333" : `white`;
    const dropdownText = currentMode === "dark" ? "#ECECEC" : `#555555`;
    const dropdownShadow = currentMode !== "dark" ? "0px 4px 20px rgba(238, 238, 238, 0.5)" : "";
    const dropdownBorder = currentMode !== "dark" ? "223, 225, 226, 0.56" : "";
    const { data: dataObj, fetching, requestComplete, error, exportData } = useSelector((state) => state?.advanceRequestListState);

    // MODIFICATION START
    const filterMenuList = [
        "Advance Id.",
        "PO. No.",
        "Advance Type",
        "Vendor Name",
        "Document No.",
        "Advance Date",
        "Payable Amount",
        "Advance Amount",
        "Status",
    ];
    let optionval = ["Advance Id.", "Advance Type", "Vendor Name", "Document No.", "Advance Date", "Payable Amount", "Advance Amount", "Status"];
    const [values, setValues] = useState(optionval);
    const checkChangeOptions = (e) => {
        const elem = e.target.value;
        if (!e.target.checked) {
            const Remove = values?.filter((el) => el !== elem);
            setValues(Remove);
            localStorage.setItem("advanceListOption", JSON.stringify(values));
        } else {
            setValues([...values, elem]);
            localStorage.setItem("advanceListOption", JSON.stringify(values));
        }
    };
    const handleClick = (newPlacement, value, event, id = null) => {
        setPopState({ value, anchorEl: event.currentTarget, id }, (prev) => placement !== newPlacement || !prev);
        setPlacement(newPlacement);
    };
    const handleClickMore = (newPlacement, id) => (event) => {
        setPopState({ id, anchorEl: event.currentTarget }, (prev) => placement !== newPlacement || !prev);
        setPlacement(newPlacement);
    };

    const listData = props?.listData;
    const countData = props?.countData;
    useEffect(() => {
        let Check = JSON.parse(localStorage.getItem("advanceListOption"));
        if (Check !== null && Check !== undefined && Check !== "") {
            setValues(JSON.parse(localStorage.getItem("advanceListOption")));
        } else {
            localStorage.setItem("advanceListOption", JSON.stringify(optionval));
            setValues(optionval);
        }
    }, []);
    useEffect(() => {
        const reqObj = {
            status_id: props?.statusOptionArray,
            po_id: props?.poNumber,
            vendor_id: props?.filterSupplierValue,
            search_text: props?.searchValue,
            order_column: sortColumn,
            order_by: isSorting,
            pg_no: currentPageNo,
            rec_cnt: currentPageItem,
            org_id: +orgId,
            adv_type: props?.invoiceType?.value || 0,
            from_date: props?.fromDate ? moment(new Date(props?.fromDate))?.format("YYYY-MM-DD") : null,
            to_date: props?.toDate ? moment(new Date(props?.toDate))?.format("YYYY-MM-DD") : null,
            alloc_user: props?.user,
        };

        if (!props?.exportFile && !filterObj) {
            dispatch(getAdvanceList(reqObj));
        }
    }, [
        location.pathname,
        dispatch,
        currentPageNo,
        currentPageItem,
        props?.searchValue,
        props?.filterSupplierValue,
        isSorting,
        props?.poNumber,
        props?.statusid,
        props?.invoiceType,
        props?.fromDate,
        props?.toDate,
        props?.filterStatusValue,
        props?.user,
    ]);
    useEffect(() => {
        if (!localStorage.getItem("advanceListOption")) {
            localStorage.setItem("advanceListOption", JSON.stringify(values));
        }
        localStorage.setItem("advanceListOption", JSON.stringify(values));
    }, [values]);
    const parseId = props?.parseId;
    const advanceStatusFilter = props?.advanceStatusFilter;
    // useEffect(() => {}, [parseId]);
    const filterValues = advanceStatusFilter;
    const [status, setStatusCheck] = useState(advanceStatusFilter ? statusReset(filterValues) : []);
    const changeSelectOptionHandler = (event) => {
        // changePageNo(1);
        setCheckSelected(true);
        if (selected == "ponumber" || selected == "vendor" || selected == "status" || selected == "advanceType") {
            props?.setFilterSupplierValue("0");
            props?.setPoNumber("0");
            props?.setInvoiceType({ name: "", value: 0 });
            props?.setFromDate(null);
            props?.setToDate(null);
            setSelected("default");
            setisFlag(false);
            setSelectedValue("");
            setCheckFilterValue([]);
            setStatusCheck(statusReset(filterValues));
        }
        if (location.pathname == "/advance-list") {
            props?.setstatusOptionArray(parseId || []);
        }
        setSelected(event.target.value);
        if (event.target.value == "status") {
            props?.setstatusOptionArray([]);
        } else if (event.target.value !== "status") {
            props.setstatusOptionArray(parseId || []);
        }
        setStatusValue([]);
    };
    const advanceType = [
        { name: "Mobilization Request", value: 1 },
        { name: "Adhoc Request", value: 2 },
    ];
    const supplier = vendorPoData?.vendorListData;
    const poNumberList = vendorPoData?.poListData;
    let type = null;
    if (selected === "ponumber") {
        type = poNumberList;
    } else if (selected === "status") {
        type = status;
    } else if (selected === "vendor") {
        type = supplier;
    } else if (selected === "advanceType") {
        type = advanceType;
    }
    useEffect(() => {
        if (selected === "status") {
            setStatusCheck(statusReset(filterValues));
        }

        dispatch(GetListAdvanceReqForPoAndVendor(0, orgId));
        if (selected != "default" && selected) {
            props?.setOnrefresh(true);
        }
    }, [dispatch, selected, orgId]);
    useEffect(() => {
        if (orgId) {
            onRefreshPage();
            dispatch(GetListUserForComments(2, orgId));
            dispatch(GetAdvanceStatusList(+orgId, 2));
        }
    }, [orgId]);
    const checkChange = (e) => {
        const { checked } = e.target;
        const { value, id } = e.target;

        handleValues({
            // ...valuecheck,
            name: e.target.value,
            isCheck: checked,
        });
        const parseValue = {
            name: e.target.value,
            isCheck: checked,
            id,
        };
        checkStatus(parseValue);
        status.map((item) => {
            if (item.name == value) {
                item.isCheck = checked;
                return item;
            } else {
                return item;
            }
        });
    };
    const checkStatus = (parseValue) => {
        SatusValue.push(parseValue);
        const { name, isCheck, id } = parseValue;
        const parse = props?.statusOptionArray ? props?.statusOptionArray : [];
        if (isCheck) {
            CheckFilterValue.push(name);
            parse?.push(+id);
            props?.setstatusOptionArray(parse);
            FilterValue.push(`${name}+ `);
        } else {
            props?.setstatusOptionArray(props?.statusOptionArray?.filter((el) => el != +id));
            setFilterValue(FilterValue?.filter((el) => el != `${name}+ `));
            setCheckFilterValue(CheckFilterValue?.filter((el) => el != name));
        }
        props?.setFilterStatusValue(ReAssign);
    };
    const ReAssign = [...new Set(props?.statusOptionArray)];

    if (type == status) {
        options = status?.map((el, i) => (
            <MenuItem key={i}>
                <FormControlLabel
                    control={
                        <Checkbox
                            id={el?.id}
                            size="small"
                            onChange={checkChange}
                            value={el?.status_name}
                            checked={UniqueValue?.includes(el?.status_name)}
                            // checked={el?.isCheck}
                            sx={{
                                padding: 0,
                                color: "#008785",
                                margin: "0 12px",
                                "&.Mui-checked": {
                                    color: "#008785",
                                },
                            }}
                        />
                    }
                    label={el?.status_name}
                />
            </MenuItem>
        ));
    }
    if (type == poNumberList) {
        options = type?.map((el, i) => (
            <MenuItem key={i} value={el} className="truncate trunc-1 block">
                {el?.po_number}
            </MenuItem>
        ));
    }
    if (type == advanceType) {
        options = type?.map((el, i) => (
            <MenuItem key={i} value={el} className="truncate trunc-1 block">
                {el?.name}
            </MenuItem>
        ));
    }
    if (type == supplier) {
        options = type?.map((el, i) => (
            <MenuItem key={i} value={el} className="truncate trunc-1 block">
                {el?.vendor_name}
            </MenuItem>
        ));
    }
    const onRefreshPage = () => {
        changePageItem(10);
        props?.setAllSelected(false);
        props?.handleSearchChange("");
        setCheckFilterValue([]);
        setFilterValue([]);
        setIndeterminate(false);
        setSelected("default");
        setSelectedValue("");
        props?.setUser(0);
        localStorage.removeItem("advanceCurrentPageNo");
        changePageNo(1);
        localStorage.removeItem("advanceListSearch", "");
        if (advanceStatusFilter && parseId) {
            const reqObj = {
                status_id: parseId,
                po_id: "0",
                vendor_id: "0",
                search_text: "",
                order_column: sortColumn,
                order_by: isSorting,
                pg_no: 1,
                rec_cnt: 10,
                org_id: +orgId,
                adv_type: 0,
                from_date: null,
                to_date: null,
                alloc_user: 0,
            };
            dispatch(getAdvanceList(reqObj));
            props?.setstatusOptionArray(parseId || []);
        }
        props?.setFromDate(null);
        props?.setToDate(null);
        props?.setFilterSupplierValue("0");
        props?.setPoNumber("0");
        props?.setOnrefresh(true);
        setisFlag(false);
        setStatusCheck(statusReset(filterValues));
    };
    useEffect(() => {
        if (!fetching && requestComplete) {
            listData?.forEach((item) => {
                return (item.checked = false);
            });
            setAddedInvoice(listData);
        }
    }, [listData]);
    useMemo(() => {
        if (userId != letuserId) {
            setUserId(letuserId);
            changePageNo(1);
        } else if (advanceStatusFilter?.length !== 0) {
            onRefreshPage();
        }
    }, [advanceStatusFilter, location.pathname, letuserId]);
    const [addedInvoice, setAddedInvoice] = useState([]);
    const onSelectAll = (event) => {
        props?.setAllSelected(event.target.checked);
        setIndeterminate(false);
        listData?.forEach((invoice) => {
            if (
                invoice?.status_info?.[0]?.master_refid === 3 ||
                invoice?.status_info?.[0]?.master_refid === 4 ||
                invoice?.status_info?.[0]?.master_refid === 5
            ) {
                invoice.checked = false;
            } else {
                invoice.checked = event.target.checked;
            }
        });
        setAddedInvoice(listData);
    };
    const onSelect = (event) => {
        const index = listData.findIndex((item) => item.req_id === event.target.name);
        // shallow clone
        const updatedArray = addedInvoice;
        let getListObjIndex = updatedArray[index];
        getListObjIndex.checked = event.target.checked;
        let newArr = listData.map(function (a, _index) {
            return _index === index ? getListObjIndex : a;
        });
        setAddedInvoice(newArr);
        // change all select checkbox
        if (listData.every((invoice) => invoice.checked)) {
            props?.setAllSelected(true);
            setIndeterminate(false);
        } else if (listData.every((invoice) => !invoice.checked)) {
            props?.setAllSelected(false);
            setIndeterminate(false);
        } else {
            setIndeterminate(true);
        }
    };
    const pageCount = countData ? countData : 0;
    const CountCalc = pageCount / currentPageItem;
    const Rounded = Math.ceil(CountCalc);
    let EmptyArr = [];
    for (let i = 0; i < Rounded; i++) {
        EmptyArr.push(i);
    }
    let totalPageCount = listData ? listData.length * (currentPageNo ? currentPageNo : 1) : 0;
    const totalPageExceed = currentPageNo * currentPageItem;
    if (totalPageExceed >= pageCount) {
        totalPageCount = pageCount;
    }
    let pagesCount = (entries - 1) / entries + 1;
    let start = (pageNo - 1) * entries + 1;
    let end = Math.min(start + entries - 1, pageCount);

    const nextPageCount = Math.ceil(pageCount / currentPageItem);
    const onSorting = (columnName) => {
        const sortBy = isSorting === 1 ? 2 : 1;
        // if (sortBy === 1) {
        //   document.getElementById(columnName).classList.add("Up");
        // } else {
        //   document.getElementById(columnName).classList.remove("Up");
        // }

        setSorting(sortBy);
        columnSorting(columnName);
    };
    return (
        //    {error && !data?.status && <MessageBox error={errorMsg} />}
        // {requestComplete && data?.status && <SuccessMessageBox success={successMsg} />}
        <>
            <Card className="mb-6 xl:h-[calc(100vh_-_13rem)] h-[calc(100vh_-_18rem)]">
                <form onSubmit={handleSubmit}>
                    <div className="xl:flex justify-between px-[15px] py-[25px] space-y-4 xl:space-y-0">
                        <div className="flex justify-between xl:justify-start">
                            <div className="flex items-center">
                                <span>Show</span>
                                <Select
                                    name="entrycount"
                                    className={` insideTble bg-${currentMode}-card mx-3 text-xs rounded-[5px] entries`}
                                    value={currentPageItem}
                                    onChange={(e) => onPageItemSelect(e)}
                                    IconComponent={() => (
                                        <img
                                            className="absolute opacity-50 right-[8px] pointer-events-none"
                                            src={currentMode !== "dark" ? `/images/header/chevronDown.svg` : `/images/header/chevronDown-white.svg`}
                                        />
                                    )}
                                    MenuProps={{
                                        sx: {
                                            "& .MuiMenu-paper": {
                                                backgroundColor: dropdownColor,
                                                color: dropdownText,
                                                boxShadow: dropdownShadow,
                                                border: dropdownBorder,
                                                borderRadius: "8px",
                                            },
                                        },
                                    }}
                                    SelectDisplayProps={{
                                        style: {
                                            display: "flex",
                                            border: 0,
                                            color: currentMode === "dark" ? `#ECECEC` : `#555555`,
                                        },
                                    }}
                                >
                                    {/* {EntryArr?.map(ent => <option value={ent}>{ent}</option>)} */}
                                    <MenuItem value={10}>{10}</MenuItem>
                                    <MenuItem value={15}>{15}</MenuItem>
                                    <MenuItem value={20}>{20}</MenuItem>
                                    <MenuItem value={25}>{25}</MenuItem>
                                    <MenuItem value={50}>{50}</MenuItem>
                                    <MenuItem value={75}>{75}</MenuItem>
                                    <MenuItem value={100}>{100}</MenuItem>
                                </Select>
                                <span>entries</span>
                                <div className="flex items-center">
                                    <div>
                                        <Button title="To Refresh the Advance Requests List" onClick={() => onRefreshPage()}>
                                            <img className="mx-3 w-[18]" src="/images/common/refresh.svg" alt="upload" />{" "}
                                        </Button>
                                    </div>
                                </div>
                            </div>

                            <div
                                className={`bg-${currentMode}-230 xl:hidden border-${currentMode}-840 border search-step flex items-center justify-between py-[5.5px] px-[16px] min-w-[250px] max-w-[250px] xl:max-w-[auto] rounded-[8px]`}
                            >
                                <Input
                                    placeholder="Search..."
                                    name="search"
                                    className={`border-0 text-${currentMode}-copy`}
                                    disableUnderline={true}
                                    onChange={(e) => props?.handleSearchChange(e.target.value)}
                                    value={props?.searchValue}
                                />
                                <div>
                                    {props?.searchValue && props?.searchValue.length !== 0 ? (
                                        <img onClick={resetInputField} src="/images/common/closeBlk.svg" alt="close" className="convertWhite" />
                                    ) : (
                                        <img src="/images/header/search.svg" alt="search" />
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="xl:flex space-y-5 xl:space-y-0 items-center xl:space-x-2">
                            <div
                                className={`bg-${currentMode}-230 hidetab border-${currentMode}-840 border search-step flex items-center justify-between py-[4.5px] px-[16px] min-w-[250px] max-w-[250px] xl:max-w-[auto] rounded-[8px]`}
                            >
                                <Input
                                    placeholder="Search..."
                                    name="search"
                                    className={`border-0 text-${currentMode}-copy`}
                                    disableUnderline={true}
                                    onChange={(e) => props?.handleSearchChange(e.target.value)}
                                    value={props?.searchValue}
                                />
                                <div>
                                    {props?.searchValue && props?.searchValue.length !== 0 ? (
                                        <img onClick={resetInputField} src="/images/common/closeBlk.svg" alt="close" className="convertWhite" />
                                    ) : (
                                        <img src="/images/header/search.svg" alt="search" />
                                    )}
                                </div>
                            </div>
                            <div className=" flex space-x-2">
                                <div
                                    className={`flex invoice_filter_step items-center border-[1px] border-${currentMode}-840 pl-[15px] pr-[10px] py-[8.5px] rounded-[8px] bg-${currentMode}-card `}
                                >
                                    <img className="mr-2 convertWhite" src="/images/invoice/filter-grp.svg" alt="filter-grp" />
                                    <span>Filter by:</span>
                                    <FormControl
                                        sx={{
                                            minWidth: 138,
                                        }}
                                    >
                                        <Select
                                            onChange={changeSelectOptionHandler}
                                            name="filter"
                                            defaultValue="default"
                                            className={"insideFilter Org"}
                                            value={selected == undefined ? "default" : selected}
                                            MenuProps={{
                                                sx: {
                                                    "& .MuiMenu-paper": {
                                                        marginTop: "10px",
                                                    },
                                                    "& ..MuiSelect-select": {
                                                        color: "#B3F7D6",
                                                    },
                                                    "& .MuiMenuItem-root:hover": {
                                                        backgroundColor: "rgba(218, 249, 244, 0.3)",
                                                    },
                                                    "& .Mui-selected": {
                                                        backgroundColor: "rgba(218, 249, 244, 0.3) !important",
                                                    },
                                                },
                                            }}
                                            IconComponent={() => (
                                                <img
                                                    className="absolute right-[5px] opacity-[.5] pointer-events-none"
                                                    src={
                                                        currentMode !== "dark"
                                                            ? `/images/header/chevronDown.svg`
                                                            : `/images/header/chevronDown-white.svg`
                                                    }
                                                    alt="chevronDown"
                                                />
                                            )}
                                            SelectDisplayProps={{
                                                style: {
                                                    display: "flex",
                                                    border: 0,
                                                    color: currentMode === "dark" ? `#ECECEC` : `#555555`,
                                                    paddingRight: "30px",
                                                    maxWidth: "100px",
                                                },
                                            }}
                                            displayEmpty
                                        >
                                            <MenuItem value="default" sx={{ display: "none" }}>
                                                Select
                                            </MenuItem>
                                            <MenuItem value="ponumber">
                                                <img className="mr-2 convertWhite" src="/images/mobilization/Po-Number-filter.svg" alt="ponumber" />{" "}
                                                PO.Number
                                            </MenuItem>
                                            <MenuItem value="status">
                                                <img className="mr-2 convertWhite" src="/images/invoice/status.svg" alt="status" /> Status
                                            </MenuItem>
                                            <MenuItem value="vendor">
                                                <img className="mr-2 convertWhite" src="/images/invoice/user-plusblk.svg" alt="vendor" /> Vendor
                                            </MenuItem>
                                            <MenuItem value="advanceType">
                                                <img
                                                    className="mr-2 convertWhite"
                                                    src="/images/mobilization/money-bag-filtersvg.svg"
                                                    alt="advanceType"
                                                />{" "}
                                                Advance Type
                                            </MenuItem>
                                            <MenuItem value="advancePeriod">
                                                <img className="mr-2 convertWhite" src="/images/invoice/calendar.svg" alt="advancePeriod" /> Advance
                                                Period
                                            </MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                                <div
                                    className={`flex items-center  border-[1px] border-${currentMode}-840 pl-[15px] pr-[10px] py-[8px] rounded-[8px] bg-${currentMode}-card`}
                                >
                                    {/* <img
                    className="mr-2"
                    src="/images/invoice/3-layers.svg"
                    alt="filter-grp"
                  /> */}

                                    <FormControl
                                        fullWidth
                                        sx={
                                            {
                                                // minWidth: 170,
                                                // minHeight: 80
                                            }
                                        }
                                    >
                                        <Select
                                            name="filter"
                                            title={
                                                selected == "vendor"
                                                    ? selectedValue?.vendor_name
                                                    : selected == "ponumber"
                                                    ? selectedValue?.po_number
                                                    : selected == "advancePeriod"
                                                    ? PeriodTitle
                                                    : selected == "status"
                                                    ? UniqueValue
                                                    : ""
                                            }
                                            onChange={handleSelectedValue}
                                            className="insideFilter Org"
                                            defaultValue="default"
                                            disabled={!selected || selected == "default"}
                                            fullWidth
                                            multiple={selected == "status" ? true : false}
                                            value={
                                                props?.fromDate || props?.toDate
                                                    ? PeriodTitle
                                                    : selected == "advanceType"
                                                    ? selected == "advanceType"
                                                        ? selectedValue?.name
                                                        : selectedValue
                                                    : selected == "vendor"
                                                    ? selectedValue?.vendor_name
                                                    : selected == "ponumber"
                                                    ? selectedValue?.po_number
                                                    : []
                                            }
                                            displayEmpty
                                            renderValue={(value) =>
                                                value?.length
                                                    ? Array.isArray(value)
                                                        ? value.join(", ")
                                                        : value
                                                    : StatusCount?.length == 0
                                                    ? "Select"
                                                    : valuecheck
                                                    ? CheckFilterValue?.length == 0
                                                        ? "Select"
                                                        : StatusValue
                                                    : selected
                                            }
                                            MenuProps={{
                                                sx: {
                                                    "& .MuiMenu-paper": {
                                                        backgroundColor: dropdownColor,
                                                        color: dropdownText,
                                                        boxShadow: dropdownShadow,
                                                        border: dropdownBorder,
                                                        borderRadius: "8px",
                                                        maxWidth: "30rem",
                                                        // height: selected == "invType" ? "5rem" : selected == "status" ? "11rem" : selected == "advancePeriod" ? "3rem" : "",
                                                        // minHeight: selected == "supplier" && "10rem !important",
                                                        top: "304rem",
                                                        marginTop: "10px",
                                                    },
                                                    "& .MuiMenuItem-root:hover": {
                                                        backgroundColor: "rgba(218, 249, 244, 0.3)",
                                                    },
                                                    "& .Mui-selected": {
                                                        backgroundColor: "rgba(218, 249, 244, 0.3)",
                                                    },
                                                },
                                            }}
                                            IconComponent={() => (
                                                <img
                                                    className="absolute right-[5px] opacity-[.5] pointer-events-none"
                                                    src={
                                                        currentMode !== "dark"
                                                            ? `/images/header/chevronDown.svg`
                                                            : `/images/header/chevronDown-white.svg`
                                                    }
                                                    alt="chevronDown"
                                                />
                                            )}
                                            SelectDisplayProps={{
                                                style: {
                                                    paddingTop: 1,
                                                    paddingBottom: 1,
                                                    width: "11rem",
                                                    color: currentMode === "dark" ? `#ECECEC` : `#555555`,
                                                    paddingLeft: "0",
                                                    border: 0,

                                                    paddingRight: "10px",
                                                },
                                            }}
                                        >
                                            <MenuItem
                                                value={
                                                    props?.fromDate && props?.toDate
                                                        ? moment(new Date(props?.fromDate)).format("DD-MM-YYYY") +
                                                          " to " +
                                                          moment(new Date(props?.toDate)).format("DD-MM-YYYY")
                                                        : "default"
                                                }
                                                sx={{ display: "none" }}
                                            >
                                                Select
                                            </MenuItem>
                                            {selected == "advancePeriod" ? (
                                                <div className="basis-[32%] xl:basis-[20%]">
                                                    <Button
                                                        className={`font-interR px-[15px] truncate  text-${currentMode}-copy text-left py-[8px] w-full`}
                                                        onClick={handleClickCalendar("bottom-start")}
                                                    >
                                                        {props?.fromDate && props?.toDate
                                                            ? moment(new Date(props?.fromDate)).format("DD-MM-YYYY") +
                                                              " to " +
                                                              moment(new Date(props?.toDate)).format("DD-MM-YYYY")
                                                            : "Select data range"}
                                                    </Button>
                                                    <ClickAwayListener
                                                        mouseEvent="onMouseDown"
                                                        touchEvent="onTouchStart"
                                                        onClickAway={handleClickAwayCalendar}
                                                    >
                                                        <Popper
                                                            open={open}
                                                            anchorEl={anchorElCalendar || ""}
                                                            placement={placementCalendar || ""}
                                                            className="z-[1300] filterPop"
                                                        >
                                                            <Paper>
                                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                    <ul className={`space-y-4 px-[15px] py-4 bg-${currentMode}-card`}>
                                                                        <li className="flex justify-between">
                                                                            <DesktopDatePicker
                                                                                selectsStart
                                                                                inputFormat="DD/MM/YYYY"
                                                                                className={`border-0 text-${currentMode}-copy`}
                                                                                components={{
                                                                                    OpenPickerIcon: AccessIcon,
                                                                                }}
                                                                                value={props?.fromDate}
                                                                                label="From"
                                                                                minDate={new Date(1999, 12)}
                                                                                maxDate={new Date()}
                                                                                name="invoice_date_from"
                                                                                onChange={handleValue}
                                                                                renderInput={(params) => (
                                                                                    <TextField
                                                                                        sx={{
                                                                                            "& .MuiInputBase-input": {
                                                                                                color: `${
                                                                                                    currentMode !== "dark" ? `#555555` : `#ECECEC`
                                                                                                }`,
                                                                                            },
                                                                                        }}
                                                                                        placeholder={params.inputProps?.placeholder?.replace("From")}
                                                                                        {...params}
                                                                                        inputProps={{
                                                                                            ...params.inputProps,
                                                                                            readOnly: true,
                                                                                        }}
                                                                                    />
                                                                                )}
                                                                            />
                                                                        </li>
                                                                        <li className="flex justify-between">
                                                                            <DesktopDatePicker
                                                                                selectsEnd
                                                                                className="border-0"
                                                                                inputFormat="DD/MM/YYYY"
                                                                                name="invoice_date_to"
                                                                                label="To"
                                                                                components={{
                                                                                    OpenPickerIcon: AccessIcon,
                                                                                }}
                                                                                value={props?.toDate}
                                                                                onChange={handleToDate}
                                                                                minDate={props?.fromDate}
                                                                                maxDate={new Date()}
                                                                                renderInput={(params) => (
                                                                                    <TextField
                                                                                        sx={{
                                                                                            "& .MuiInputBase-input": {
                                                                                                color: `${
                                                                                                    currentMode !== "dark" ? `#555555` : `#ECECEC`
                                                                                                }`,
                                                                                            },
                                                                                        }}
                                                                                        placeholder={params.inputProps?.placeholder?.replace("To")}
                                                                                        {...params}
                                                                                        inputProps={{
                                                                                            ...params.inputProps,
                                                                                            readOnly: true,
                                                                                        }}
                                                                                    />
                                                                                )}
                                                                            />
                                                                        </li>
                                                                    </ul>
                                                                </LocalizationProvider>
                                                            </Paper>
                                                        </Popper>
                                                    </ClickAwayListener>
                                                </div>
                                            ) : (
                                                options
                                            )}
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="heightFix invoices">
                        {fetching || (props?.viewFetch && !props?.viewReq) ? (
                            <Loader className="h-[calc(100vh_-_26rem)]" />
                        ) : (
                            <table className="TblNormal invoice w-max xl:w-full">
                                <thead className="sticky top-0 z-[1] w-full">
                                    <tr className={`bg-${currentMode}-450 w-[1294px]`}>
                                        <th className={``}>
                                            {countData && +countData > 0 && (
                                                <Checkbox
                                                    title="Checkbox"
                                                    checked={props?.isAllSelected}
                                                    inputProps={{
                                                        "aria-label": "select all desserts",
                                                    }}
                                                    onChange={onSelectAll}
                                                    indeterminate={isIndeterminate}
                                                    sx={{
                                                        padding: 0,
                                                        color: "#008785",
                                                        "&.Mui-checked": {
                                                            color: "#008785",
                                                        },
                                                        "&.MuiCheckbox-indeterminate": {
                                                            color: "#008785",
                                                        },
                                                    }}
                                                />
                                            )}
                                        </th>
                                        {values?.includes("Advance Id.") ? (
                                            <th className="w-[9%]">
                                                <Link className="flex tableHead" onClick={() => onSorting("sequence_no")}>
                                                    Advance Id.
                                                    <img
                                                        id="id"
                                                        src="/images/common/chevronDown.svg"
                                                        alt="chevronDown"
                                                        className={`${isSorting === 1 && sortColumn === "sequence_no" ? "rotate-180" : ""}`}
                                                    />
                                                </Link>
                                            </th>
                                        ) : (
                                            ""
                                        )}
                                        {values?.includes("PO. No.") ? (
                                            <th>
                                                <Link className="flex tableHead" onClick={() => onSorting("po_no")}>
                                                    PO. No.
                                                    <img
                                                        id="id"
                                                        src="/images/common/chevronDown.svg"
                                                        alt="chevronDown"
                                                        className={`${isSorting === 1 && sortColumn === "po_no" ? "rotate-180" : ""}`}
                                                    />
                                                </Link>
                                            </th>
                                        ) : (
                                            ""
                                        )}

                                        {values?.includes("Advance Type") ? <th>Advance Type</th> : ""}
                                        {values?.includes("Vendor Name") ? (
                                            <th>
                                                <Link className="flex tableHead" onClick={() => onSorting("vendor_name")}>
                                                    Vendor Name
                                                    <img
                                                        id="id"
                                                        src="/images/common/chevronDown.svg"
                                                        alt="chevronDown"
                                                        className={`${isSorting === 1 && sortColumn === "vendor_name" ? "rotate-180" : ""}`}
                                                    />
                                                </Link>
                                            </th>
                                        ) : (
                                            ""
                                        )}
                                        {values?.includes("Document No.") ? (
                                            <th>
                                                <Link className="flex tableHead" onClick={() => onSorting("document_no")}>
                                                    Document No.
                                                    <img
                                                        id="id"
                                                        src="/images/common/chevronDown.svg"
                                                        alt="chevronDown"
                                                        className={`${isSorting === 1 && sortColumn === "document_no" ? "rotate-180" : ""}`}
                                                    />
                                                </Link>
                                            </th>
                                        ) : (
                                            ""
                                        )}
                                        {values?.includes("Advance Date") ? (
                                            <th>
                                                <Link className="flex tableHead" onClick={() => onSorting("request_date")}>
                                                    Advance Date{" "}
                                                    <img
                                                        id="id"
                                                        src="/images/common/chevronDown.svg"
                                                        alt="chevronDown"
                                                        className={`${isSorting === 1 && sortColumn === "request_date" ? "rotate-180" : ""}`}
                                                    />
                                                </Link>
                                            </th>
                                        ) : (
                                            ""
                                        )}
                                        {values?.includes("Advance Amount") ? (
                                            <th>
                                                {/* <Link className="flex tableHead" onClick={() => onSorting("advance_amount")}> */}
                                                Advance Amount{" "}
                                                {/* <img
                                                        id="id"
                                                        src="/images/common/chevronDown.svg"
                                                        alt="chevronDown"
                                                        className={`${isSorting === 1 && sortColumn === "advance_amount" ? "rotate-180" : ""}`}
                                                    /> */}
                                                {/* </Link> */}
                                            </th>
                                        ) : (
                                            ""
                                        )}
                                        {values?.includes("Payable Amount") ? (
                                            <th>
                                                {/* <Link className="flex tableHead" onClick={() => onSorting("payable_amount")}> */}
                                                Payable Amount{" "}
                                                {/* <img
                                                        id="id"
                                                        src="/images/common/chevronDown.svg"
                                                        alt="chevronDown"
                                                        className={`${isSorting === 1 && sortColumn === "payable_amount" ? "rotate-180" : ""}`}
                                                    />
                                                </Link> */}
                                            </th>
                                        ) : (
                                            ""
                                        )}
                                        {values?.includes("Status") ? (
                                            <th>
                                                <Link className="flex tableHead">Status </Link>
                                            </th>
                                        ) : (
                                            ""
                                        )}
                                        <th>
                                            <img
                                                className="cursor-pointer max-w-[inherit] convertWhite"
                                                onClick={(e) => handleClick("bottom-end", 1, e)}
                                                src="/images/main/columns.svg"
                                                alt="columns"
                                            />
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {listData && listData?.length !== 0 ? (
                                        addedInvoice?.map((ele) => {
                                            return (
                                                <tr className={`border-b border-${currentMode}-700 last:border-b-0 `}>
                                                    <td className="">
                                                        <Checkbox
                                                            disabled={
                                                                ele?.status_info?.[0]?.master_refid == 5 || ele?.status_info?.[0]?.master_refid == 3
                                                            }
                                                            title="Checkbox"
                                                            checked={ele?.checked}
                                                            onChange={(event) => onSelect(event)}
                                                            name={ele.req_id}
                                                            sx={{
                                                                padding: 0,
                                                                color: "#008785",
                                                                "&.Mui-checked": {
                                                                    color: "#008785",
                                                                },
                                                            }}
                                                        />{" "}
                                                    </td>
                                                    {values?.includes("Advance Id.") ? (
                                                        <td className={`${"pl-[15px]"}`}>
                                                            <Link
                                                                title={ele?.sequence_no}
                                                                className={"cursor-pointer tableHead truncate w-[110px]"}
                                                                state={{ from: location.pathname }}
                                                                to={`${
                                                                    ele?.adv_type == 1
                                                                        ? `/mobilization-request?reqId=${ele?.req_id}`
                                                                        : ele?.adv_type == 2 && ele?.payable_amount == null
                                                                        ? `${"/proforma-invoice-view?"}${encryptId(
                                                                              `{"inv":${+ele?.invoice_id},"type":${8},"seq":"${+ele?.sequence_no}","status":${+ele
                                                                                  ?.status_info?.[0]?.master_refid}}`
                                                                          )}`
                                                                        : ele?.adv_type == 2 &&
                                                                          ele?.payable_amount !== null &&
                                                                          `/adhoc-advance-request?reqId=${ele?.req_id}`
                                                                }`}
                                                                style={{
                                                                    textDecoration: "underline",
                                                                }}
                                                                onClick={() => {
                                                                    localStorage.setItem("advanceCurrentPageNo", currentPageNo);
                                                                }}
                                                            >
                                                                {" "}
                                                                {ele?.sequence_no}
                                                            </Link>
                                                        </td>
                                                    ) : (
                                                        ""
                                                    )}
                                                    {values?.includes("PO. No.") ? <td>{ele?.po_no}</td> : ""}
                                                    {values?.includes("Advance Type") ? <td>{ele?.adv_type == 1 ? "Mobilization" : "Adhoc"}</td> : ""}
                                                    {values?.includes("Vendor Name") ? (
                                                        <td>
                                                            <p title={ele?.vendor_name} className="truncate w-[100px]">
                                                                {ele?.vendor_name}
                                                            </p>
                                                        </td>
                                                    ) : (
                                                        ""
                                                    )}
                                                    {values?.includes("Document No.") ? (
                                                        <td>
                                                            <p title="asdadasdas" className="truncate w-[100px]">
                                                                {ele?.document_no}
                                                            </p>
                                                        </td>
                                                    ) : (
                                                        ""
                                                    )}
                                                    {values?.includes("Advance Date") ? <td>{ele?.request_date}</td> : ""}
                                                    {values?.includes("Advance Amount") ? <td className="">{ele?.advance_amount}</td> : ""}
                                                    {values?.includes("Payable Amount") ? <td>{ele?.payable_amount}</td> : ""}
                                                    {values?.includes("Status") ? (
                                                        <td>
                                                            {ele?.status_info?.length > 0 && (
                                                                <div className="flex items-center">
                                                                    <Tooltip
                                                                        title={ele?.status_info[0]?.status_name}
                                                                        disablePortal
                                                                        placement="bottom"
                                                                        disableInteractive
                                                                        PopperProps={{
                                                                            sx: {
                                                                                "& .MuiTooltip-tooltip": {
                                                                                    fontSize: "0.9em",
                                                                                    maxHeight: "120px",
                                                                                    maxWidth: "250px",
                                                                                    background: "#DAF9F4",
                                                                                    color: "#555555",
                                                                                    borderRadius: "5px",
                                                                                    marginTop: "10px !important",
                                                                                },

                                                                                "& .MuiTooltip-arrow": {
                                                                                    color: "#DAF9F4",
                                                                                },
                                                                            },
                                                                        }}
                                                                        arrow
                                                                    >
                                                                        <div
                                                                            className={`flex items-center justify-center px-2 py-3 min-w-[90px] min-h-[17px] max-w-[90px] max-h-[17px] rounded-[5px] cursor-pointer text-white`}
                                                                            style={{
                                                                                background: `${ele?.status_info[0]?.color_code}`,
                                                                            }}
                                                                        >
                                                                            <span className="block truncate  ">
                                                                                {" "}
                                                                                {ele?.status_info[0]?.status_name}
                                                                            </span>
                                                                        </div>
                                                                    </Tooltip>
                                                                    <img
                                                                        src={
                                                                            currentMode == "dark" && !ele?.message_category
                                                                                ? "/images/workflow-automation/api_call_dark.svg"
                                                                                : !ele?.message_category
                                                                                ? `/images/workflow-automation/statusApi.svg`
                                                                                : `/images/workflow-automation/StatusRed.svg`
                                                                        }
                                                                        alt="get-status"
                                                                        onClick={() => {
                                                                            setStatusInfoAnchor({
                                                                                anchor: true,
                                                                                invoiceId: ele?.req_id,
                                                                                seqNo: ele?.sequence_no,
                                                                            });
                                                                        }}
                                                                        className="ml-2 max-h-[18px] cursor-pointer"
                                                                    />
                                                                </div>
                                                            )}
                                                        </td>
                                                    ) : (
                                                        ""
                                                    )}

                                                    <td>
                                                        <Button
                                                            title="Actions"
                                                            className="px-[10px] w-max"
                                                            onClick={handleClickMore("bottom-end", ele?.req_id)}
                                                        >
                                                            <img src="/images/common/threeDots.svg" alt="threeDots" />
                                                        </Button>

                                                        <ClickAwayListener
                                                            mouseEvent="onMouseDown"
                                                            touchEvent="onTouchStart"
                                                            onClickAway={props?.handleClickAwayMore}
                                                        >
                                                            <Popper
                                                                className={`bg-${currentMode}-card text-${currentMode}-copy px-3 py-3 border-[1px] rounded-[10px] border-${currentMode}-600`}
                                                                open={ele?.req_id === popstate?.id}
                                                                anchorEl={popstate?.anchorEl}
                                                                id={ele?.req_id}
                                                                placement={placement}
                                                                transition
                                                            >
                                                                {({ TransitionProps }) => (
                                                                    <Fade {...TransitionProps} timeout={350}>
                                                                        <div>
                                                                            <ul className="">
                                                                                <li>
                                                                                    <Link
                                                                                        state={{ from: location.pathname }}
                                                                                        className={`flex mx-[-12px] px-[11px] py-[6px] transition duration-300 ease-in-out hover:bg-${currentMode}-960`}
                                                                                        to={
                                                                                            ele?.adv_type == 1
                                                                                                ? `/mobilization-request?reqId=${ele?.req_id}`
                                                                                                : `${"/proforma-invoice-view?"}${encryptId(
                                                                                                      `{"inv":${+ele?.invoice_id},"type":${8},"seq":"${+ele?.sequence_no}","status":${+ele
                                                                                                          ?.status_info?.[0]?.master_refid}}`
                                                                                                  )}`
                                                                                        }
                                                                                        onClick={() => {
                                                                                            localStorage.setItem(
                                                                                                "advanceCurrentPageNo",
                                                                                                currentPageNo
                                                                                            );
                                                                                        }}
                                                                                    >
                                                                                        <img
                                                                                            className="flex-none pr-2"
                                                                                            src="/images/common/eye.svg"
                                                                                            alt="user"
                                                                                        />
                                                                                        View
                                                                                    </Link>
                                                                                </li>
                                                                                <li>
                                                                                    <Link
                                                                                        className={`flex mx-[-12px] px-[11px] py-[6px] transition duration-300 ease-in-out hover:bg-${currentMode}-960`}
                                                                                        onClick={() => props.downloadInvoice(ele?.req_id)}
                                                                                    >
                                                                                        <img
                                                                                            className="flex-none pr-2"
                                                                                            src="/images/common/log-in.svg"
                                                                                            alt="log-in"
                                                                                        />
                                                                                        Download
                                                                                    </Link>
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                    </Fade>
                                                                )}
                                                            </Popper>
                                                        </ClickAwayListener>
                                                    </td>
                                                </tr>
                                            );
                                        })
                                    ) : (
                                        <div className="bg-white">
                                            <div className="text-center absolute left-[50%] translate-x-[-50%]">
                                                <div className="mx-auto my-5">
                                                    <img className="mx-auto" src="/images/common/delivery.png" alt="delivery" />
                                                </div>
                                                <h4 className="font-interSb">No Data found</h4>
                                            </div>
                                        </div>
                                    )}
                                </tbody>
                            </table>
                        )}
                    </div>
                    <ClickAwayListener mouseEvent="onMouseDown" touchEvent="onTouchStart" onClickAway={props?.handleClickAway}>
                        <Popper
                            className={`bg-${currentMode}-card text-${currentMode}-copy  p-4 border-[1px] z-[12] border-${currentMode}-700  shadow-[0px_4px_20px_rgba(238,238,238,0.5)] rounded-[10px] reportsPopper `}
                            open={1 === popstate.value}
                            anchorEl={popstate.anchorEl}
                            id={1}
                            placement={placement}
                        >
                            <ul>
                                {filterMenuList?.map((el, i) => {
                                    return (
                                        <li
                                            key={i}
                                            className={`transition duration-300 ease-in-out mx-[-17px]  py-1 px-[20px] hover:bg-${currentMode}-960`}
                                        >
                                            <Button className={`flex ${1} items-center justify-between font-interR`}>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            size="small"
                                                            value={el}
                                                            onChange={checkChangeOptions}
                                                            disabled={!Boolean(optionval?.indexOf(el) === -1)}
                                                            defaultChecked={!Boolean(values?.indexOf(el) === -1)}
                                                            sx={{
                                                                padding: 0,
                                                                color: "#008785",
                                                                margin: "0 12px",
                                                                "&.Mui-checked": {
                                                                    color: "#008785",
                                                                },
                                                            }}
                                                        />
                                                    }
                                                    label={el}
                                                />
                                            </Button>
                                        </li>
                                    );
                                })}
                            </ul>
                        </Popper>
                    </ClickAwayListener>
                    <div className={`bg-${currentMode}-460 flex justify-between rounded-b-[8px] items-center py-[2.5px] xl:py-[10px] px-[15px]`}>
                        {countData && +countData > 0 && (
                            <div>
                                <div>
                                    Showing <span>{`${start} to ${totalPageCount}`}</span> entries of <span>{countData ? countData : 0}</span>
                                </div>
                                {/* <Trans
                                    // i18nKey={"description.line1"}
                                    i18nKey={t("Showing")}
                                    values={{
                                        start: start,
                                        pageTotal: totalPageCount,
                                        total: pageCount,
                                    }}
                                    components={{ 1: <b /> }}
                                ></Trans> */}
                            </div>
                        )}
                        {countData && +countData > 0 && (
                            <div className="flex items-center">
                                <span>The page you’re on</span>
                                <Select
                                    name=""
                                    className={`bg-${currentMode}-card insideTble mx-3 text-xs rounded-[5px] entries`}
                                    id=""
                                    value={currentPageNo}
                                    onChange={(e) => onPageNumberSelect(e)}
                                    IconComponent={() => (
                                        <img
                                            className="absolute opacity-50 right-[8px] pointer-events-none"
                                            src={currentMode !== "dark" ? `/images/header/chevronDown.svg` : `/images/header/chevronDown-white.svg`}
                                        />
                                    )}
                                    SelectDisplayProps={{
                                        style: {
                                            paddingTop: "5px !important",
                                            display: "flex",
                                            paddingBottom: "5px !important",
                                            paddingLeft: "10px",
                                            border: 0,
                                            color: currentMode === "dark" ? `#ECECEC` : `#555555`,
                                            paddingRight: "25px",
                                        },
                                    }}
                                    MenuProps={{
                                        sx: {
                                            "& .MuiMenu-paper": {
                                                backgroundColor: dropdownColor,
                                                color: dropdownText,
                                                boxShadow: dropdownShadow,
                                                border: dropdownBorder,
                                                borderRadius: "8px",
                                            },
                                        },
                                    }}
                                >
                                    {EmptyArr?.map((elem, i) => (
                                        <MenuItem key={i} value={elem + 1}>
                                            {elem + 1}
                                        </MenuItem>
                                    ))}
                                </Select>
                                <div className={`flex space-x-2 border-l-[1px] border-${currentMode}-830  pl-3`}>
                                    <Button
                                        title="Previous page"
                                        disabled={currentPageNo > 1 ? false : true}
                                        onClick={() => previousBtnClick()}
                                        className={`border-${currentMode}-840 border-[1px] rounded-[5px] bg-${currentMode}-card px-2 py-3`}
                                    >
                                        <img className="rotate-180 convertWhite" src="/images/common/arrowRight.svg" alt="arrowRight" />
                                    </Button>

                                    <Button
                                        title="Next page"
                                        className={`border-${currentMode}-840 border-[1px] rounded-[5px] bg-${currentMode}-card px-2 py-3`}
                                        disabled={currentPageNo >= nextPageCount ? true : false}
                                        onClick={() => nextBtnClick()}
                                    >
                                        <img className="convertWhite" src="/images/common/arrowRight.svg" alt="arrowRight" />
                                    </Button>
                                </div>
                            </div>
                        )}
                    </div>
                </form>
            </Card>
            {statusInfoAnchor?.anchor && (
                <>
                    {ReactDOM.createPortal(
                        <StatusInfoComponent
                            isOpened={statusInfoAnchor?.anchor}
                            setStatusInfoAnchor={setStatusInfoAnchor}
                            currentMode={currentMode}
                            className={statusInfoAnchor?.anchor && "Show"}
                            orgId={orgId}
                            seqNo={statusInfoAnchor?.seqNo}
                            invoiceId={statusInfoAnchor?.invoiceId}
                        />,
                        document.getElementById("Modal-root")
                    )}
                </>
            )}
        </>
    );
};

export default Table;
