import styles from "../Sidebar/index.module.scss";
import { HeadeLinks } from "../../utils/constants";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ReactDOM from "react-dom";
import { CreateTicket } from "../Tickets/CreateTicket";
import { useContext, useEffect, useState } from "react";
import { ThemeContext } from "../../Hooks/Themes/Themeprovider";
import { getCurrentUser } from "../../redux/actions/auth";
import { getInboxSettingList } from "../../redux/actions/inbox-setting";
import UseTour from "../../Hooks/ProductTour";
// import { tourConfig } from "../../utils/helpers.js";
let getOrgLogo;
let isDefaultLogo;
const Sidebar = () => {
    const [openTicket, setOpenTicket] = useState(false);
    const location = useLocation();
    const dispatch = useDispatch();
    const data = useSelector((state) => state?.inboxSettingListState);
    const checkMailConfig = data?.configData?.[0]?.isactive;
    const [stepId, getStepId] = useState(0);
    const navigate = useNavigate();
    const inboxSetting = async () => {
        dispatch(getInboxSettingList(navigate));
    };
    const DisplayRole = useSelector((state) => state?.DisplayRolePermissionStateAction);
    const orgData = useSelector((state) => state?.ListorganisationDropDownState);
    const orgId = localStorage.getItem("orgId");
    useEffect(() => {
        if (orgId) {
            getOrgLogo = orgData?.viewData?.find((item) => item?.id == orgId);
            isDefaultLogo = orgData?.viewData?.find((item) => item?.id == orgId);
        }
    }, [orgId]);
    if (getOrgLogo !== undefined) {
        localStorage.setItem("orgLogo", getOrgLogo?.logo);
    }
    const orgLogo = localStorage.getItem("orgLogo");
    const { data: DataList } = DisplayRole;
    const RoleList = DataList?.role_info;
    const RoleMenu = RoleList?.menu;
    const Roleorg = RoleMenu?.find((elem) => elem?.menu_id === 1);
    const RoleActOrg = Roleorg?.actions;
    const checkorg = RoleActOrg?.some((element) => element.isallowed === true);
    const RoleUser = RoleMenu?.find((elem) => elem?.menu_id === 6);
    const RoleActUser = RoleUser?.actions;
    const checkUser = RoleActUser?.some((element) => element.isallowed === true);
    const RoleProcess = RoleMenu?.find((elem) => elem?.menu_id === 8);
    const RoleActProcess = RoleProcess?.actions;
    const checkProcess = RoleActProcess?.some((element) => element.isallowed === true);
    const RoleException = RoleMenu?.find((elem) => elem?.menu_id === 9);
    const RoleActException = RoleException?.actions;
    const checkException = RoleActException?.some((element) => element.isallowed === true);
    const RoleApproval = RoleMenu?.find((elem) => elem?.menu_id === 16);
    const RoleActApproval = RoleApproval?.actions;
    const checkApproval = RoleActApproval?.some((element) => element.isallowed === true);
    const RoleDashboard = RoleMenu?.find((elem) => elem?.menu_id === 11);
    const RoleActdashboard = RoleDashboard?.actions;
    const checkdashboard = RoleActdashboard?.find((element) => element.id === 19);
    const RoleUpload = RoleMenu?.find((elem) => elem?.menu_id === 12);
    const RoleActUpload = RoleUpload?.actions;
    const checkUpload = RoleActUpload?.find((element) => element.id === 21);
    const RoleAccrual = RoleMenu?.find((elem) => elem?.menu_id === 17);
    const RoleActAccrual = RoleAccrual?.actions;
    const checkAccrual = RoleActAccrual?.find((element) => element.id === 3);
    const AccrualReport = RoleMenu?.find((elem) => elem?.menu_id === 13);
    const RoleAccrualReport = AccrualReport?.actions;
    const checkAccrualReport = RoleAccrualReport?.find((element) => element.id === 20);
    const ReportsAgeing = RoleMenu?.find((elem) => elem?.menu_id === 15);
    const RoleReportsAgeing = ReportsAgeing?.actions;
    const checkReportsAgeing = RoleReportsAgeing?.find((element) => element.id === 20);
    const InvoiceReport = RoleMenu?.find((elem) => elem?.menu_id === 14);
    const RoleInvoiceReport = InvoiceReport?.actions;
    const checkInvoiceReport = RoleInvoiceReport?.find((element) => element.id === 20);
    const TaskReport = RoleMenu?.find((elem) => elem?.menu_id === 18);
    const RoletaskReport = TaskReport?.actions;
    const checkTaskReport = RoletaskReport?.find((element) => element.id === 20);
    const RoleMonthEnd = RoleMenu?.find((elem) => elem?.menu_id === 19);
    const RoleActMonthEnd = RoleMonthEnd?.actions;
    const checkMonthEnd = RoleActMonthEnd?.find((element) => element.id === 3);
    const RoleExchangerate = RoleMenu?.find((elem) => elem?.menu_id === 27);
    const RoleActExchangeRate = RoleExchangerate?.actions;
    const RoleAdvance = RoleMenu?.find((elem) => elem?.menu_id === 21);
    const RoleActAdvance = RoleAdvance?.actions;
    const checkAdvance = RoleActAdvance?.some((element) => element.isallowed === true);
    const RoleMisc = RoleMenu?.find((elem) => elem?.menu_id === 22);
    const RoleActMisc = RoleMisc?.actions;
    const checkMisc = RoleActMisc?.some((element) => element.isallowed === true);
    const checkExchangeRate = RoleActExchangeRate?.find((element) => element.id === 22);
    const retentionRolePermission = RoleMenu?.find((elem) => elem?.menu_id === 23);
    const CheckRetention = retentionRolePermission?.actions?.find((ele) => ele?.id == 3)?.isallowed;
    const checkReports =
        checkAccrualReport?.isallowed == true ||
        checkReportsAgeing?.isallowed == true ||
        checkInvoiceReport?.isallowed == true ||
        checkTaskReport?.isallowed == true;
    const date = new Date();
    const d = date.toLocaleDateString("en-GB");
    const currentUser = getCurrentUser();
    const params = new URLSearchParams(window.location.search);
    let type = params.get("type");
    let tourId = params.get("tourId");
    const { currentMode } = useContext(ThemeContext);
    const tourConfigArr = [
        checkorg
            ? {
                  target: ".Organisation_step",
                  to: "/admin-setting",
                  content: (
                      <div className="text-left mt-[-10px]">
                          {/* <div className="custom-beacon">
                              <span className="inner-circle"></span>
                              <span className="outer-border"></span>
                          </div> */}
                          <span className={`text-[12px] ${currentMode == "dark" ? "text-white" : "text-[#555555]"} `}>1 of 10</span>
                          <h2
                              className={`font-interB text-base ${
                                  currentMode == "dark" ? "text-[#00b399]" : ""
                              } mt-1 mb-2 block font-semibold leading-5 tracking-tight text-left`}
                          >
                              Organization Management
                          </h2>
                          <p className="font-interR text-[14px] mb-[30px]">
                              Manage your organization details, define your invoice sources (Email & Drive), and integrate with the industry leading
                              ERP of your choice effortlessly.
                          </p>
                      </div>
                  ),
                  styles: {
                      buttonNext: {
                          backgroundColor: "rgba(0, 179, 153, 1)", // Assuming 'bg-light-20' is a light background color with 20% opacity
                          padding: "3px 15px",
                          color: "white",
                          borderRadius: "4px",
                          border: "1px solid transparent",
                          width: "70px",
                          height: "30px",
                          position: "absolute",
                          left: "25px",
                          bottom: "30px",
                      },
                  },
                  placement: "auto",
                  placementBeacon: "auto",
                  disableBeacon: true,
              }
            : null,
        checkUser
            ? {
                  target: ".user_management_step",
                  to: "/admin-setting",
                  filter: true,
                  content: (
                      <div className="text-left mt-[-10px]">
                          <span className={`text-[12px] ${currentMode == "dark" ? "text-white" : "text-[#555555]"} `}>{stepId + 1} of 10</span>
                          <h2
                              className={`font-interB text-base ${
                                  currentMode == "dark" ? "text-[#00b399]" : ""
                              } mt-1 mb-2 block font-semibold leading-5 tracking-tight text-left`}
                          >
                              Users Management
                          </h2>
                          <p className="font-interR text-[14px] mb-[30px]">
                              In just a few clicks, you can take charge of user access, permissions, and collaboration. No more complex processes -
                              it”s easy as a walk in the park.
                          </p>
                      </div>
                  ),
                  placement: "bottom",
                  placementBeacon: "bottom",
              }
            : null,
        checkUpload
            ? {
                  target: "inv_menu_step",
                  target: ".manual_upload_step",
                  to: "/file-upload",
                  content: (
                      <div className="text-left mt-[-10px]">
                          <span className={`text-[12px] ${currentMode == "dark" ? "text-white" : "text-[#555555]"} `}>{stepId + 1} of 10</span>
                          <h2
                              className={`font-interB text-base ${
                                  currentMode == "dark" ? "text-[#00b399]" : ""
                              } mt-1 mb-2 block font-semibold leading-5 tracking-tight text-left`}
                          >
                              Upload
                          </h2>
                          <p className="font-interR text-[14px] mb-[30px]">Upload upto 10 invoices manually from your computer at once.</p>
                      </div>
                  ),
                  placement: "right",
                  placementBeacon: "right",
                  //   disableBeacon: true,
              }
            : null,
        {
            target: ".invoice_step",
            to: "/invoice",
            content: (
                <div className="text-left mt-[-10px]">
                    <span className={`text-[12px] ${currentMode == "dark" ? "text-white" : "text-[#555555]"} `}>{stepId + 1} of 10</span>
                    <h2
                        className={`font-interB text-base ${
                            currentMode == "dark" ? "text-[#00b399]" : ""
                        } mt-1 mb-2 block font-semibold leading-5 tracking-tight text-left`}
                    >
                        Invoice
                    </h2>
                    <p className="font-interR text-[14px] mb-[30px]">
                        Once your invoice is uploaded, head over to the invoice list queue for a quick and simple overview of all your invoices
                        status.
                    </p>
                </div>
            ),
            placement: "right",
            placementBeacon: "right",
            // disableBeacon: true,
        },
        {
            target: ".invoice_filter_step",
            to: "/invoice",
            filter: true,
            content: (
                <div className="text-left mt-[-10px]">
                    <span className={`text-[12px] ${currentMode == "dark" ? "text-white" : "text-[#555555]"} `}>{stepId + 1} of 10</span>
                    <h2
                        className={`font-interB text-base ${
                            currentMode == "dark" ? "text-[#00b399]" : ""
                        } mt-1 mb-2 block font-semibold leading-5 tracking-tight text-left`}
                    >
                        Invoice Filter
                    </h2>
                    <p className="font-interR text-[14px] mb-[30px]">
                        Filter invoices by Status, Vendor, Data Range, and Invoice type for a customized and efficient experience.
                    </p>
                </div>
            ),
            placement: "bottom",
            placementBeacon: "right",
            // disableBeacon: true,
        },
        checkApproval
            ? {
                  target: ".approval_step",
                  to: "/approve",
                  content: (
                      <div className="text-left mt-[-10px]">
                          <span className={`text-[12px] ${currentMode == "dark" ? "text-white" : "text-[#555555]"} `}>{stepId + 1} of 10</span>
                          <h2
                              className={`font-interB text-base ${
                                  currentMode == "dark" ? "text-[#00b399]" : ""
                              } mt-1 mb-2 block font-semibold leading-5 tracking-tight text-left`}
                          >
                              Approval
                          </h2>
                          <p className="font-interR text-[14px] mb-[30px]">
                              Get a quick overview of all approved invoices with a simple glance from here.
                          </p>
                      </div>
                  ),
                  placement: "right",
                  placementBeacon: "right",
                  //   disableBeacon: true,
              }
            : null,
        checkdashboard
            ? {
                  target: ".Dashboard_step",
                  to: "/dashboard",
                  content: (
                      <div className="text-left mt-[-10px]">
                          <span className={`text-[12px] ${currentMode == "dark" ? "text-white" : "text-[#555555]"} `}>{stepId + 1} of 10</span>
                          <h2
                              className={`font-interB text-base ${
                                  currentMode == "dark" ? "text-[#00b399]" : ""
                              } mt-1 mb-2 block font-semibold leading-5 tracking-tight text-left`}
                          >
                              Dashboard
                          </h2>
                          <p className="font-interR text-[14px] mb-[30px]">
                              Get insights, monitor your AP performance, and track cash flow effortlessly with our pre-built dashboards and KPI`s for
                              streamlined invoice processing.
                          </p>
                      </div>
                  ),
                  placement: "right",
                  placementBeacon: "right",
                  //   disableBeacon: true,
              }
            : null,
        {
            target: ".task_management_step",
            to: "/my-task/list",
            content: (
                <div className="text-left mt-[-10px]">
                    <span className={`text-[12px] ${currentMode == "dark" ? "text-white" : "text-[#555555]"} `}>{stepId + 1} of 10</span>
                    <h2
                        className={`font-interB text-base ${
                            currentMode == "dark" ? "text-[#00b399]" : ""
                        } mt-1 mb-2 block font-semibold leading-5 tracking-tight text-left`}
                    >
                        My Task
                    </h2>
                    <p className="font-interR text-[14px] mb-[30px]">
                        Assign tasks to your internal team and external vendor for any invoice exceptions, and stay on top of pending tasks for both
                        you and your team right here.
                    </p>
                </div>
            ),
            placement: "right",
            placementBeacon: "right",
            // disableBeacon: true,
        },
        {
            target: ".inbox_step",
            to: "/inbox-compose",
            content: (
                <div className="text-left mt-[-10px]">
                    <span className={`text-[12px] ${currentMode == "dark" ? "text-white" : "text-[#555555]"} `}>{stepId + 1} of 10</span>
                    <h2
                        className={`font-interB text-base ${
                            currentMode == "dark" ? "text-[#00b399]" : ""
                        } mt-1 mb-2 block font-semibold leading-5 tracking-tight text-left`}
                    >
                        Inbox
                    </h2>
                    <p className="font-interR text-[14px] mb-[30px]">
                        Address invoice exceptions with ease by using our integrated email feature for direct communication with vendors. Keep
                        everything in one place for a hassle-free experience.
                    </p>
                </div>
            ),
            placement: "right",
            placementBeacon: "right",
            // disableBeacon: true,
        },
        checkReports
            ? {
                  target: ".reports_step",
                  to: "/reports",
                  content: (
                      <div className="text-left mt-[-10px]">
                          <span className={`text-[12px] ${currentMode == "dark" ? "text-white" : "text-[#555555]"} `}>{stepId + 1} of 10</span>
                          <h2
                              className={`font-interB text-base ${
                                  currentMode == "dark" ? "text-[#00b399]" : ""
                              } mt-1 mb-2 block font-semibold leading-5 tracking-tight text-left`}
                          >
                              Reports
                          </h2>
                          <p className="font-interR text-[14px] mb-[30px]">
                              Access detailed and accurate reporting to stay informed about key KPI`s and other metrics, allowing you to identify and
                              eliminate bottlenecks for a streamlined invoicing process.
                          </p>
                      </div>
                  ),
                  placement: "right",
                  placementBeacon: "right",
                  //   disableBeacon: true,
              }
            : "",
    ];
    const tourConfig = tourConfigArr?.filter((el) => el !== null);
    const { openTour, ProdTour, currentId } = UseTour(tourConfig);
    const targetDateStr = currentUser?.expiring_date;
    const targetDate = new Date(targetDateStr);
    const currentDate = new Date();
    const matchdate = targetDate < currentDate;
    const ShowProffesionIcon = (!matchdate && currentUser?.license_type == 2) || currentUser?.license_type == 1 || currentUser?.license_type == 3;
    HeadeLinks.forEach((item) => {
        if (item.name == "Dashboard") {
            item.isShow = checkdashboard?.isallowed;
        }
        if (item.name == "Inbox" || item.name == "Task") {
            item.isShow = ShowProffesionIcon;
        }

        if (item.name == "Users") {
            item.isShow = checkUser;
        }
        if (
            item.name == "Invoice" ||
            item.name == "Exception" ||
            item.name == "Approval" ||
            item.name == "Advance" ||
            item.name == "Miscellaneous" ||
            item.name == "Retention"
        ) {
            item.isShow = checkProcess || checkException || checkApproval || checkUpload?.isallowed || checkAdvance || checkMisc || CheckRetention;
        }
        // if(item.name == "Exception"){
        //   item.isShow = checkException;
        // }
        if (item.name == "Upload") {
            item.isShow = checkUpload?.isallowed;
        }
        if (item.name == "Month End") {
            item.isShow = (checkAccrual?.isallowed || checkMonthEnd?.isallowed || checkExchangeRate?.isallowed) && ShowProffesionIcon;
        }
        if (item.name == "Organisation") {
            item.isShow = checkorg;
        }
        if (item.name == "Reports") {
            item.isShow = checkReports || currentUser?.license_type !== 2;
        }
    });
    const defaultLogin = localStorage.getItem("defaultLogin") || "/user-page";
    const [openInv, setopenInv] = useState("");
    const InvOpen = (name) => {
        setopenInv(name);
    };
    const handleKeyDown = (event, name) => {
        if (event.key === "Tab") {
            setopenInv(openInv);
        }
    };
    const [checked, setChecked] = useState();
    useEffect(() => {
        const handleLocalStorageChange = () => {
            // Code to be executed when localStorage changes
            let checkInboxClick = localStorage.getItem("inboxClick");
            setChecked(checkInboxClick);
        };

        // Listen to the custom event for localStorage changes
        window.addEventListener("storageChange", handleLocalStorageChange);

        // Clean up the event listener on unmount
        return () => {
            window.removeEventListener("storageChange", handleLocalStorageChange);
        };
    }, []); // Empty dependency array to ensure the effect runs only once

    const LinkOpen = () => {
        setopenInv("");
    };
    useEffect(() => {
        if (type == "tour") {
            openTour();
        }
        if (currentId) {
            getStepId(currentId);
        }
    }, [location.pathname, type, currentId, tourId]);

    return (
        <>
            <ProdTour />
            <div className={`${styles?.sidebar} bg-${currentMode}-base sidebar`}>
                <a
                    href={defaultLogin}
                    tabIndex={1}
                    className={`${styles?.logo} border-${currentMode}-690 xl:h-[78px] h-[81px] w-[90px] bg-${currentMode}-base`}
                >
                    <img
                        // rounded-full
                        className={`${!isDefaultLogo?.is_default ? "object-cover  mt-1 w-[60px] h-[60px]" : "object-cover  mt-1"}`}
                        title=""
                        src={
                            !isDefaultLogo?.is_default && orgLogo
                                ? `data:image;base64,${orgLogo}`
                                : currentMode === "dark" || currentMode == "classic"
                                ? "/images/logo/logo-white.svg"
                                : `/images/logo/MyiqFinal.svg`
                        }
                        alt="myiq-logo"
                    />
                </a>
                <ul className={`text-xs border-${currentMode}-690 border-r-[1px] w-[90px]`}>
                    {HeadeLinks?.map((el) => {
                        if (el?.isShow) {
                            return (
                                <li key={el?.id}>
                                    {el?.name == "Organisation" && checked == "true" ? (
                                        <a href={el?.href} tabIndex={1}>
                                            <img src={el?.icon} alt="dashboard" />
                                            {el?.name}
                                        </a>
                                    ) : (
                                        <div style={{ zIndex: 0 }} className={`${el?.tour}`}>
                                            <Link
                                                tabIndex={1}
                                                // onClick={(e) => InvOpen(el?.name)}
                                                // onKeyDown={handleKeyDown}
                                                // onClick={
                                                //     el?.id == 4 ? inboxSetting : ""
                                                // }
                                                to={el?.href}
                                                className={` text-${currentMode}-40 hover:text-light-680 ${
                                                    (el?.name === "Invoice" || el?.name === "Month End") && styles?.handlemenu
                                                } ${
                                                    (location?.pathname === el?.href ||
                                                        location?.pathname === el?.exep ||
                                                        location?.pathname === el?.approve ||
                                                        location?.pathname === el?.ihref ||
                                                        location?.pathname === el?.upload ||
                                                        location?.pathname === el?.aref ||
                                                        location?.pathname.includes(el?.href) ||
                                                        location?.pathname.includes(el?.name?.toLocaleLowerCase())) &&
                                                    !type
                                                        ? `${styles?.active} text-light-680`
                                                        : ``
                                                } font-interM`}
                                            >
                                                <img src={el?.icon} alt="dashboard" />

                                                {el?.name}
                                                {el?.name === "Invoice" ? (
                                                    <div
                                                        className={`${styles?.menuHover} ${
                                                            openInv === "Invoice" ? styles?.tabed : ``
                                                        } bg-${currentMode}-card rounded-r-[8px] text-${currentMode}-40 InvSidebarPopup ${
                                                            (location.pathname == "/invoice" ||
                                                                location.pathname == "/file-upload" ||
                                                                location.pathname == "/approve") &&
                                                            type == "tour"
                                                                ? styles?.tabed
                                                                : ""
                                                        } `}
                                                    >
                                                        {checkUpload?.isallowed == true ? (
                                                            <Link
                                                                tabIndex={1}
                                                                className={`manual_upload_step ${
                                                                    currentId == 2 ? "bg-light-960" : ""
                                                                } flex text-${currentMode}-copy  ${
                                                                    currentMode == "dark" ? `hover:text-white hover:bg-${currentMode}-960` : ""
                                                                }`}
                                                                to="/file-upload"
                                                            >
                                                                Upload
                                                            </Link>
                                                        ) : (
                                                            ""
                                                        )}
                                                        {/* {checkException == true ? (
                                                        <Link
                                                            tabIndex={1}
                                                            to="/exception"
                                                            className={`flex text-${currentMode}-copy ${currentMode ==
                                                                "dark"
                                                                ? `hover:text-white hover:bg-${currentMode}-960`
                                                                : ""
                                                                }`}
                                                        >
                                                            Exception
                                                        </Link>
                                                    ) : (
                                                        ``
                                                    )} */}
                                                        {checkProcess == true ? (
                                                            <Link
                                                                tabIndex={1}
                                                                className={`invoice_step   ${
                                                                    currentId == 3 ? "bg-light-960" : ""
                                                                } flex text-${currentMode}-copy  ${
                                                                    currentMode == "dark" ? `hover:text-white hover:bg-${currentMode}-960` : ""
                                                                }`}
                                                                to="/invoice"
                                                            >
                                                                Invoice
                                                            </Link>
                                                        ) : (
                                                            ""
                                                        )}

                                                        {checkApproval == true ? (
                                                            <Link
                                                                tabIndex={1}
                                                                to="/approve"
                                                                className={`approval_step  ${
                                                                    currentId == 5 ? "bg-light-960" : ""
                                                                } flex text-${currentMode}-copy ${
                                                                    currentMode == "dark" ? `hover:text-white hover:bg-${currentMode}-960` : ""
                                                                }`}
                                                            >
                                                                Approval
                                                            </Link>
                                                        ) : (
                                                            ``
                                                        )}
                                                        {checkAdvance == true ? (
                                                            <Link
                                                                tabIndex={1}
                                                                to="/advance-list"
                                                                className={`flex text-${currentMode}-copy ${
                                                                    currentMode == "dark" ? `hover:text-white hover:bg-${currentMode}-960` : ""
                                                                }`}
                                                            >
                                                                Advance
                                                            </Link>
                                                        ) : (
                                                            ``
                                                        )}
                                                        {checkMisc && (
                                                            <Link
                                                                tabIndex={1}
                                                                to="/miscellaneous-table"
                                                                className={`flex text-${currentMode}-copy ${
                                                                    currentMode == "dark" ? `hover:text-white hover:bg-${currentMode}-960` : ""
                                                                }`}
                                                            >
                                                                Miscellaneous
                                                            </Link>
                                                        )}

                                                        {/* <Link
                                                            tabIndex={1}
                                                            to="/advance-list"
                                                            className={`flex text-${currentMode}-copy ${
                                                                currentMode == "dark" ? `hover:text-white hover:bg-${currentMode}-960` : ""
                                                            }`}
                                                        >
                                                            miscellaneous
                                                        </Link>*/}
                                                        {retentionRolePermission?.actions?.find((ele) => ele?.id == 3)?.isallowed && (
                                                            <Link
                                                                tabIndex={1}
                                                                to="/retention-release-list"
                                                                className={`flex text-${currentMode}-copy ${
                                                                    currentMode == "dark" ? `hover:text-white hover:bg-${currentMode}-960` : ""
                                                                }`}
                                                            >
                                                                Retention
                                                            </Link>
                                                        )}
                                                    </div>
                                                ) : (
                                                    ""
                                                )}
                                                {el?.name === "Month End" ? (
                                                    <div
                                                        className={`${styles?.menuHover} ${
                                                            openInv === "Month End" ? styles?.tabed : ``
                                                        } bg-${currentMode}-card text-${currentMode}-40 InvSidebarPopup `}
                                                    >
                                                        {checkAccrual?.isallowed && (
                                                            <Link
                                                                tabIndex={1}
                                                                className={`flex text-${currentMode}-copy  ${
                                                                    currentMode == "dark" ? `hover:text-white hover:bg-${currentMode}-960` : ""
                                                                }`}
                                                                to="/accrual-secondary"
                                                            >
                                                                Accrual
                                                            </Link>
                                                        )}
                                                        {checkMonthEnd?.isallowed && (
                                                            <Link
                                                                tabIndex={1}
                                                                className={`flex text-${currentMode}-copy  ${
                                                                    currentMode == "dark" ? `hover:text-white hover:bg-${currentMode}-960` : ""
                                                                }`}
                                                                to="/month-closing"
                                                            >
                                                                Month Closing
                                                            </Link>
                                                        )}
                                                        {checkExchangeRate?.isallowed && (
                                                            <Link
                                                                tabIndex={1}
                                                                className={`flex text-${currentMode}-copy  ${
                                                                    currentMode == "dark" ? `hover:text-white hover:bg-${currentMode}-960` : ""
                                                                }`}
                                                                to="/exchange-rate"
                                                            >
                                                                Exchange Rate
                                                            </Link>
                                                        )}
                                                    </div>
                                                ) : (
                                                    ""
                                                )}
                                            </Link>
                                        </div>
                                    )}
                                </li>
                            );
                        }
                    })}
                </ul>
            </div>
            {ReactDOM.createPortal(
                <CreateTicket isOpened={openTicket} handleClose={() => setOpenTicket(false)} className={openTicket && "Show"} />,
                document.getElementById("user-modal-root")
            )}
        </>
    );
};

export default Sidebar;
