import Slider from "react-slick";

const Content = () => {
    const settings = {
        dots: true,
        infinite: true,
        arrows: false,
        autoplay: true,
        speed: 2500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };
    return (
        <Slider {...settings}>
            <div>
                <div className="mx-auto mb-2">
                    <img
                        className="mx-auto"
                        src="/images/signin/signin-inv.png"
                        alt="automated-invoice-processing-system"
                    />
                </div>
                {/* <span className="font-interSb block mb-2">
                    Achieve a remarkable 60% cost reduction on your Financial
                    efficiency with 1000+ invoices per min
                </span> */}
                <div className="flex space-x-5 mt-8">
                    <ul className="list-disc pl-4 space-y-2">
                        <li className="marker:text-light-20">99% up time</li>
                        <li className="marker:text-light-20"> 95% accuracy</li>
                        <li className="marker:text-light-20">
                            Data end-to-end encrypted
                        </li>
                    </ul>
                    <ul className="list-disc pl-3 space-y-2">
                        <li className="marker:text-light-20">
                            {" "}
                            8X increase in processing time
                        </li>
                        <li className="marker:text-light-20">
                            {" "}
                            1000+ invoices per minute
                        </li>
                    </ul>
                    <ul className="list-disc pl-3 space-y-2">
                        <li className="marker:text-light-20">
                            {" "}
                            90% error elimination
                        </li>
                        <li className="marker:text-light-20"> 60% cost saving</li>

                    </ul>
                </div>

                {/* <p className="font-interR">
                    The responsibility for invoice processing typically falls to the
                    accounts payable department or a designated individual responsible for
                    accounts payable. This individual or team is responsible for verifying
                    that all invoices are accurate and complete, coding them for expenses,
                    entering them into the accounting system, and submitting them for
                    payment. The accounts payable team may also be responsible for
                    researching and resolving any discrepancies or errors that arise when
                    processing invoices.
                </p> */}
            </div>
            {/* <div>
        <div className="mx-auto mb-7">
          <img
            className="mx-auto"
            src="/images/gif/invoice.gif"
            alt="automated-invoice-processing-system"
          />
        </div>
        <p className="font-interR">
          A digital invoice is a component of workflow automation. Technology is
          redefining business processes and this includes invoice management,
          from arrival to post. A company can accelerate the payable process by
          taking advantage of automation
        </p>
      </div> */}
        </Slider>
    );
};

export default Content;
