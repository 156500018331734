import Button from "../../components/Button";
import Modal from "../../components/UI/Modal/Modal";
import { RemoveData } from "../../redux/actions/setting";
import { useSelector, useDispatch } from "react-redux";
import Swal from "sweetalert2";
import { Link, useNavigate } from "react-router-dom";
import MessageBox from "../../components/UI/Alert";
import { GET_FREE_TRAIL_RESET, REMOVE_DEMO_DATA_RESET } from "../../redux/types/types";
import { getFreeTrialDispatch } from "../../redux/actions/free-trail";
import { getCurrentUser, LogoutRequest } from "../../redux/actions/auth";
import { ThemeContext } from "../../Hooks/Themes/Themeprovider";
import { useContext, useEffect } from "react";
const FreeTrail = (props) => {
    // const { requestComplete, fetching, error, data } = useSelector(
    //     (state) => state?.RemoveDemoDatasState
    // );
    const { currentMode } = useContext(ThemeContext);
    const errormsg = "Something went wrong please contact admin";
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const currentUser = getCurrentUser();
    const OrgGroup = localStorage.getItem("orgGroup");
    const Data = useSelector((state) => state?.getFreeTrialReducerState);
    const { status, requestComplete } = useSelector((state) => state?.getFreeTrialReducerState);
    const reqObject = {
        expiration_date: currentUser?.expiring_date,
        org_grp: +OrgGroup,
    };
    function submit() {
        dispatch(getFreeTrialDispatch(reqObject));
    }
    useEffect(() => {
        if (Data?.requestComplete) {
            if (Data?.data?.status) {
                Swal.fire({
                    text: "The trial period has been successfully extended for an additional 15 days!",
                    icon: "success",
                    closeOnClickOutside: false,
                    showCloseButton: true,
                    button: "Ok",
                }).then((confirm) => {
                    if (confirm?.isConfirmed || confirm?.isDismissed) {
                        window.localStorage.clear();
                        window.location.reload();
                        dispatch({ type: GET_FREE_TRAIL_RESET });
                    }
                });
            } else if (!Data?.data?.status) {
                Swal.fire({
                    text: `${Data?.data?.message}`,
                    icon: "warning",
                    closeOnClickOutside: false,
                    showCloseButton: true,
                    button: "Ok",
                }).then((confirm) => {
                    if (confirm?.isConfirmed || confirm?.isDismissed) {
                        dispatch({ type: GET_FREE_TRAIL_RESET });
                    }
                });
                dispatch({ type: GET_FREE_TRAIL_RESET });
            }
        }
    }, [Data?.requestComplete]);

    const navigateButton = () => {
        navigate("/reports/reports-billing");
        props?.handleClose();
    };
    return (
        <>
            {/* {requestComplete && !data?.status && <MessageBox error={errormsg} />}
            {error && !data?.status && <MessageBox error={errormsg} />} */}
            <Modal cartClose={props?.handleClose} center="center" className={`${props?.className}`}>
                <div
                    className={`w-[500px] h-[302px] mx-auto relative bg-${currentMode}-card border-[rgba(223,225,226,0.56)] px-[50px]  py-[31px] rounded-[10px] ${
                        currentMode === "dark" ? "" : "shadow-[0px_4px_20px_rgba(238,238,238,0.5)]"
                    }]`}
                >
                    <span onClick={props?.handleClose} className="cursor-pointer absolute right-[20px] top-[20px] convertWhite">
                        <img src="/images/common/closeBlk.svg" alt="closeBlk" />
                    </span>
                    <div className="flex space-x-3 mt-4">
                        <img className="bg-message rounded-[50%] w-[17px] h-[24px]" src="/images/common/sand-clock.svg" alt="sand-clock" />
                        <span className="font-interSb block">Your 15-day free trial is over</span>
                    </div>
                    <p className={`mt-6 text-${currentMode}-40`}>
                        Click the Extend Trial button for extending the free trial and to use the application for few more days.
                    </p>
                    <div className="flex mt-[90px] space-x-3">
                        <Button
                            className={`border bg-[#F0F0F0] duration-500 px-[30px] py-[9px] rounded-[8px] text-black`}
                            type="submit"
                            onClick={submit}
                            disabled={Data?.fetching}
                        >
                            Extend Trial
                        </Button>
                        <Button onClick={navigateButton} className="bg-light-20 px-[30px] py-[9px] text-white rounded-[8px]" type="submit">
                            Upgrade Now
                        </Button>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default FreeTrail;
