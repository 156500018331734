import { useEffect } from "react";
import { getInvoiceCalcDetail } from "../../../../redux/actions/invoice";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import Loader from "../../../../components/Loader";

export const PoHeaderView = ({ currentMode, handleClose, invid, type, listItems }) => {
    const dispatch = useDispatch();
    const { invCalcview, poData, fetching, error } = useSelector((state) => state?.getInvCalcDetailState);
    const DummyData = {
        header: ["Sl. No", "Description of Goods", "Rate", "Tax %", "Qty", "Amount"],
        taxHeader: ["Tax Code", "Condition Type", "Percentage (%)", "LTDC Percent (%)", "TDS Amount", "TDS Base Amount", "Threshold Limit"],
        taxData: [["TC", "CT", "10.00", "4.00", "26,400.00", "660,000.00", "2,970,000.00"]],
    };
    const Itemss = listItems?.invoice_items;
    const PoNumber = Itemss?.find((el) => el?.label_name == "purchase_order_no")?.label_value;
    const orgId = localStorage?.getItem("orgId");
    useEffect(() => {
        if (type) {
            dispatch(getInvoiceCalcDetail(invid, type, orgId, PoNumber));
        }
    }, [type]);
    return (
        <>
            <span onClick={handleClose} className="cursor-pointer right-[20px] top-[17px] absolute convertWhite">
                <img src="/images/common/closeBlk.svg" alt="closeBlk" />
            </span>
            {fetching ? (
                <Loader />
            ) : poData && Object.keys(poData || {})?.length != 0 ? (
                <>
                    <div className={`${currentMode == "dark" ? "" : "shadow-[0px_4px_20px_0px_rgba(238,238,238,0.50)]"}`}>
                        <div className={`flex justify-between items-center px-[30px] border-b border-${currentMode}-1380 py-[14px]`}>
                            <span className="text-[18px] font-interM">PO Header</span>
                        </div>
                        <div className="">
                            <div className={`flex border-b px-[30px] py-[15px] border-${currentMode}-1370 text-left grid grid-cols-5`}>
                                <div className="">
                                    <p>Project:</p>
                                    <span className="font-interM truncate block max-w-[150px]">{poData?.poHeaders?.project || "-"}</span>
                                </div>
                                <div className="">
                                    <p>WBS Element:</p>
                                    <span className="font-interM truncate block max-w-[150px]">{poData?.poHeaders?.wbs_element || "-"}</span>
                                </div>
                                <div className="">
                                    <p>Plant:</p>
                                    <span className="font-interM truncate block max-w-[150px]">{poData?.poHeaders?.plant || "-"}</span>
                                </div>
                                <div className="">
                                    <p>PO Basic Value:</p>
                                    <span className="font-interM truncate block max-w-[150px]">{poData?.poHeaders?.basic_value || "-"}</span>
                                </div>
                                <div className="">
                                    <p>PO Gross:</p>
                                    <span className="font-interM truncate block max-w-[150px]">{poData?.poHeaders?.gross || "-"}</span>
                                </div>
                            </div>
                            <>
                                <div className={`flex border-b px-[30px] py-[15px] border-${currentMode}-1370 text-left grid grid-cols-5`}>
                                    <div className="">
                                        <p>PO Tax:</p>
                                        <span className="font-interM truncate block max-w-[150px]">{poData?.poHeaders?.tax || "-"}</span>
                                    </div>
                                    <div className="">
                                        <p>Vendor:</p>
                                        <span className="font-interM truncate block max-w-[150px]">{poData?.vendorMaster?.VendorName || "-"}</span>
                                    </div>
                                    <div className="">
                                        <p>GST No.:</p>
                                        <span className="font-interM truncate block max-w-[150px]">{"-"}</span>
                                    </div>
                                    <div className="">
                                        <p>Paying Company:</p>
                                        <span className="font-interM truncate block max-w-[150px]">{"-"}</span>
                                    </div>
                                    <div className="">
                                        <p>Payment Due Date:</p>
                                        <span className="font-interM truncate block max-w-[150px]">{"-"}</span>
                                    </div>
                                </div>
                                <div className={`flex border-b px-[30px] py-[15px] border-${currentMode}-1370 text-left grid grid-cols-5`}>
                                    <div className="">
                                        <p>Special G/L Ind:</p>
                                        <span className="font-interM truncate block max-w-[150px]">{poData?.poHeaders?.gl_indicator || "-"}</span>
                                    </div>
                                    <div className="">
                                        <p>Adv. Against RA Bill No.:</p>
                                        <span className="font-interM truncate block max-w-[150px]">{poData?.poHeaders?.advance_abn || "-"}</span>
                                    </div>
                                    <div className="">
                                        <p>Section Code:</p>
                                        <span className="font-interM truncate block max-w-[150px]">{poData?.poHeaders?.section_code || "-"}</span>
                                    </div>
                                    <div className="">
                                        <p>Currency:</p>
                                        <span className="font-interM truncate block max-w-[150px]">{poData?.poHeaders?.currency || "-"}</span>
                                    </div>
                                    <div className="">
                                        <p>Exchange Rate:</p>
                                        <span className="font-interM truncate block max-w-[150px]">{"-"}</span>
                                    </div>
                                </div>
                                <div className={`flex border-b px-[30px] py-[15px] border-${currentMode}-1370 text-left grid grid-cols-5`}>
                                    <div className="">
                                        <p>Business Place:</p>
                                        <span className="font-interM truncate block max-w-[150px]">{poData?.poHeaders?.bussiness_place || "-"}</span>
                                    </div>
                                    <div className="">
                                        <p>Submission Date</p>
                                        <span className="font-interM truncate block max-w-[150px]">{poData?.poHeaders?.submission_date || "-"}</span>
                                    </div>
                                    <div className="">
                                        <p>Adv Amt in INR:</p>
                                        <span className="font-interM truncate block max-w-[150px]">{poData?.poHeaders?.advance_amount_inr}</span>
                                    </div>
                                </div>
                            </>
                        </div>
                    </div>
                    <div className={`${currentMode == "dark" ? "" : "shadow-[0px_4px_20px_0px_rgba(238,238,238,0.50)]"}`}>
                        <div className={`flex justify-between items-center px-[30px] border-t border-${currentMode}-1380 py-[14px]`}>
                            <span className="text-[18px] font-interM">Line Item</span>
                        </div>
                        <div className="max-h-[350px] overflow-y-auto">
                            <table className="w-full border-0  rolemasterTable !rounded-[0] text-left">
                                <thead className="sticky top-0 z-[7]">
                                    <tr>
                                        {DummyData?.header?.map((el) => (
                                            <th className="rounded-[0]">{el}</th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody className={`relative`}>
                                    {poData?.poLineItems?.map((el, i) => (
                                        <tr>
                                            <td>{i + 1}</td>
                                            <td>{el?.description}</td>
                                            <td>{el?.rate}</td>
                                            <td>{el?.tax_percentage}</td>
                                            <td>{el?.quantity}</td>
                                            <td>{el?.amount}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </>
            ) : (
                <div className=" text-center py-5">
                    <div className="mx-auto my-5">
                        <img className="mx-auto" src="/images/common/delivery.png" alt="delivery" />
                    </div>
                    <h4 className="font-interSb">No Data found</h4>
                </div>
            )}
        </>
    );
};
