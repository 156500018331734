import {
    INBOX_BODY_VIEW_FAILURE,
    INBOX_BODY_VIEW_REQUEST,
    INBOX_BODY_VIEW_RESET,
    INBOX_BODY_VIEW_SUCCESS,
    INBOX_CHILD_PREVIEW_GET_FAILURE,
    INBOX_CHILD_PREVIEW_GET_REQUEST,
    INBOX_CHILD_PREVIEW_GET_RESET,
    INBOX_CHILD_PREVIEW_GET_SUCCESS,
    INBOX_FOLDER_GET_FAILURE,
    INBOX_FOLDER_GET_REQUEST,
    INBOX_FOLDER_GET_RESET,
    INBOX_FOLDER_GET_SUCCESS,
    INBOX_PREVIEW_GET_FAILURE,
    INBOX_PREVIEW_GET_NORMAL_FAILURE,
    INBOX_PREVIEW_GET_REQUEST,
    INBOX_PREVIEW_GET_RESET,
    INBOX_PREVIEW_GET_SCROLL_REQUEST,
    INBOX_PREVIEW_GET_SCROLL_SUCCESS,
    INBOX_PREVIEW_GET_SUCCESS,
    POST_SEND_MS_MAIL_REQUEST,
    POST_SEND_MS_MAIL_SUCCESS,
    POST_SEND_MS_MAIL_RESET,
    POST_SEND_MS_MAIL_FAILURE,
    DELETE_MICROSOFT_MAIL_REQUEST,
    DELETE_MICROSOFT_MAIL_SUCCESS,
    DELETE_MICROSOFT_MAIL_RESET,
    DELETE_MICROSOFT_MAIL_FAILURE,
    GET_ATTACHMENT_PREVIEW_SUCCESS,
    GET_ATTACHMENT_PREVIEW_FAILURE,
    GET_ATTACHMENT_PREVIEW_REQUEST,
    GET_ATTACHMENT_PREVIEW_RESET,
    DOWNLOAD_ATTACHMENT_FAILURE,
    DOWNLOAD_ATTACHMENT_REQUEST,
    DOWNLOAD_ATTACHMENT_RESET,
    DOWNLOAD_ATTACHMENT_SUCCESS,
    TOGGLE_MARK_AS_READ,
    DELETE_MICROSOFT_EMAIL_ACTION,
    INBOX_EMAIL_MARK_AS_REQUEST,
    INBOX_EMAIL_MARK_AS_SUCCESS,
    INBOX_EMAIL_MARK_AS_RESET,
    INBOX_EMAIL_MARK_AS_FAILURE,
    DELETE_MICROSOFT_CHILD_EMAIL_ACTION,
    COUNT_UPDATE_EMAIL_ACTION,
    TOGGLE_CHILD_MARK_AS_READ,
    INBOX_ONCLICK_READ_ACTION,
    INBOX_ONCLICK_READ_CHILD_ACTION,
    WS_CONNECT,
    WS_DISCONNECTED,
    WS_MESSAGE_RECEIVED,
    WS_SEND_MESSAGE,
} from "../types/types";
import update from "immutability-helper";
import { storeDataInLocalForage } from "../../utils/helpers";

const FolderListState = {
    folderData: [],
    fetching: false,
    requestComplete: false,
    error: "",
};

export function getInboxFolderReducer(state = FolderListState, action) {
    switch (action.type) {
        case INBOX_FOLDER_GET_REQUEST:
            return {
                folderData: action?.payload,
                fetching: true,
                requestComplete: false,
                error: "",
            };
        case INBOX_FOLDER_GET_SUCCESS:
            return {
                folderData: action.payload,
                fetching: false,
                requestComplete: true,
                error: "",
            };
        case INBOX_FOLDER_GET_RESET:
            return {
                folderData: [],
                fetching: false,
                requestComplete: false,
                error: "",
            };
        case INBOX_FOLDER_GET_FAILURE:
            return {
                fetching: false,
                requestComplete: false,
                error: action.payload,
            };
        default:
            return state;
    }
}

const EmailPrevListState = {
    data: [],
    isLoading: false,
    isLoadingScroll: false,
    hasMore: true,
    //   page: 1,
    pageSize: 20,
};

export function getInboxEmailPrevReducer(state = EmailPrevListState, action) {
    const folderId = localStorage.getItem("folderId");

    switch (action.type) {
        case INBOX_PREVIEW_GET_REQUEST:
            return {
                ...state,
                data:
                    action?.payload?.isId === folderId
                        ? action?.payload?.data
                        : state?.data,
                isLoading: true,
            };

        case INBOX_PREVIEW_GET_SCROLL_REQUEST:
            //   let inso = [];
            //   fetchEmails(localStorage.getItem("folderId"))
            //     .then((response) => {
            //       if (response?.length !== 0) {
            //         inso = response;
            //       } else {
            //         inso = [];
            //       }
            //     })
            //     .catch(function (error) {
            //       console.log("Error:", error);
            //       return {
            //         ...state,
            //         isLoadingScroll: true,
            //       };
            //     });
            return {
                ...state,
                data:
                    action?.payload?.isId === folderId
                        ? action?.payload?.data
                        : state?.data,
                isLoadingScroll: true,
            };

        case INBOX_PREVIEW_GET_SCROLL_SUCCESS:
            return {
                ...state,
                data:
                    action?.payload?.isId === folderId
                        ? action?.payload?.data
                        : state?.data,
                // page: state.page + 1,
                pageSize: state.data.length + 20,
                isLoading: false,
                isLoadingScroll: false,
                hasMore: action?.payload?.hasMore,
            };
        case INBOX_PREVIEW_GET_SUCCESS:
            return {
                ...state,
                data:
                    action?.payload?.isId === folderId
                        ? action?.payload?.data
                        : state?.data,

                // page: state.page + 1,
                pageSize: state.data.length + 20,
                isLoading: false,
                isLoadingScroll: false,
                hasMore: action?.payload?.hasMore,
            };
        case INBOX_ONCLICK_READ_ACTION:
            const { index: idindex, isRead: isReads } = action.payload;
            const itemIndexs = state?.data?.findIndex(
                (item, index) => index === idindex
            );
            // if (itemIndexs === -1) {
            //     // Item not found, return the current state
            //     return state;
            // }

            const readedData = update(state, {
                data: {
                    [itemIndexs]: {
                        bodypreview: { isRead: { $set: isReads } },
                    },
                },
            });
            // storeDataInLocalForage(
            //     localStorage.getItem("folderId"),
            //     readedData?.data
            // );
            return {
                ...state,
                data: readedData?.data,
            };
        case TOGGLE_MARK_AS_READ:
            const { index: id, isRead, type } = action.payload;
            const itemIndex = state?.data?.findIndex(
                (item, index) => index === id
            );
            if (itemIndex === -1) {
                // Item not found, return the current state
                return state;
            }

            const NewData = update(state, {
                data: {
                    [itemIndex]: {
                        bodypreview: { isRead: { $set: isRead } },
                    },
                },
            });
            // storeDataInLocalForage(
            //     localStorage.getItem("folderId"),
            //     NewData?.data
            // );
            return {
                ...state,
                data: NewData?.data,
            };
        case COUNT_UPDATE_EMAIL_ACTION:
            const ids = action.payload;
            const itemInd = state?.data?.findIndex(
                (item, index) => item?.id === ids
            );
            state.data[itemInd].messagescount =
                state?.data?.[itemInd]?.messagescount - 1;
            // storeDataInLocalForage(
            //     localStorage.getItem("folderId"),
            //     state?.data
            // );
            return {
                ...state,
                data: state?.data,
            };
        case DELETE_MICROSOFT_EMAIL_ACTION:
            const objectsToRemove = action.payload;
            const updatedData = update(state?.data, {
                $apply: (data) =>
                    data?.filter((obj, i) => i !== objectsToRemove),
            });
            // storeDataInLocalForage(
            //     localStorage.getItem("folderId"),
            //     updatedData
            // );
            return {
                ...state,
                data: updatedData,
            };

        case INBOX_PREVIEW_GET_RESET:
            return {
                data: [],
                isLoading: false,
                isLoadingScroll: false,
                hasMore: true,
                // page: 1,
                pageSize: 20,
            };
        case INBOX_PREVIEW_GET_NORMAL_FAILURE:
            return {
                data: [],
                isLoading: false,
                isLoadingScroll: false,
                hasMore: false,
                // page: 0,
                pageSize: 0,
            };
        case INBOX_PREVIEW_GET_FAILURE:
            return {
                ...state,
                hasMore: false,
                isLoading: false,
                isLoadingScroll: false,
            };
        default:
            return state;
    }
}

const EmailChildPrevListState = {
    emailPrevChildData: [],
    fetching: false,
    requestComplete: false,
    error: "",
};
export function getInboxEmailChildPrevReducer(
    state = EmailChildPrevListState,
    action
) {
    switch (action.type) {
        case INBOX_CHILD_PREVIEW_GET_REQUEST:
            return {
                ...state,
                fetching: true,
                requestComplete: false,
                error: "",
            };
        case INBOX_CHILD_PREVIEW_GET_SUCCESS:
            return {
                emailPrevChildData: action.payload?.value?.reverse(),
                fetching: false,
                requestComplete: true,
                error: "",
            };
        case TOGGLE_CHILD_MARK_AS_READ:
            const { index: id, isRead, type } = action.payload;
            const itemIndex = state?.emailPrevChildData?.findIndex(
                (item, index) => index === id
            );
            const Update = update(state, {
                emailPrevChildData: {
                    [itemIndex]: { isRead: { $set: isRead } },
                },
            });
            return {
                ...state,
                emailPrevChildData: Update?.emailPrevChildData,
            };
        case INBOX_ONCLICK_READ_CHILD_ACTION:
            const { index: idindex, isRead: isReads } = action.payload;
            const itemIndexes = state?.emailPrevChildData?.findIndex(
                (item, index) => index === idindex
            );
            const readedchildData = update(state, {
                emailPrevChildData: {
                    [itemIndexes]: { isRead: { $set: isReads } },
                },
            });
            return {
                ...state,
                emailPrevChildData: readedchildData?.emailPrevChildData,
            };
        case DELETE_MICROSOFT_CHILD_EMAIL_ACTION:
            const objectsToRemove = action.payload;
            const updatedData = update(state?.emailPrevChildData, {
                $apply: (value) =>
                    value?.filter((obj, i) => i !== objectsToRemove),
            });
            return {
                ...state,
                emailPrevChildData: updatedData,
            };
        case INBOX_CHILD_PREVIEW_GET_RESET:
            return {
                emailPrevChildData: [],
                fetching: false,
                requestComplete: false,
                error: "",
            };
        case INBOX_CHILD_PREVIEW_GET_FAILURE:
            return {
                fetching: false,
                requestComplete: false,
                error: action.payload,
            };
        default:
            return state;
    }
}
// email body view
const EmailbodyViewState = {
    emailbody: [],
    taskBody: [],
    fetching: false,
    requestComplete: false,
    error: "",
};
export function getInboxEmailBodyViewReducer(
    state = EmailbodyViewState,
    action
) {
    switch (action.type) {
        case INBOX_BODY_VIEW_REQUEST:
            return {
                ...state,
                fetching: true,
                requestComplete: false,
                error: "",
            };
        case INBOX_BODY_VIEW_SUCCESS:
            return {
                emailbody: action.payload?.data,
                taskBody: action.payload?.task,
                fetching: false,
                requestComplete: true,
                error: "",
            };
        case INBOX_BODY_VIEW_RESET:
            return {
                emailbody: [],
                fetching: false,
                requestComplete: false,
                error: "",
            };
        case INBOX_BODY_VIEW_FAILURE:
            return {
                fetching: false,
                requestComplete: false,
                error: action.payload,
            };
        default:
            return state;
    }
}
//SEND MS MAIL
const sendMicroSoftMailState = {
    data: {},
    fetching: false,
    requestComplete: false,
    error: "",
};

export function sendMsMail(state = sendMicroSoftMailState, action) {
    switch (action.type) {
        case POST_SEND_MS_MAIL_REQUEST:
            return {
                ...state,
                fetching: true,
                requestComplete: false,
                error: "",
            };
        case POST_SEND_MS_MAIL_SUCCESS:
            return {
                dataList: action.payload,
                fetching: false,
                requestComplete: true,
                error: "",
            };
        case POST_SEND_MS_MAIL_RESET:
            return {
                data: {},
                fetching: false,
                requestComplete: false,
                error: "",
            };
        case POST_SEND_MS_MAIL_FAILURE:
            return {
                fetching: false,
                requestComplete: false,
                error: action.payload,
            };
        default:
            return state;
    }
}
//DELETE MS MAIL
const deleteMicroSoftMailState = {
    delData: {},
    fetching: false,
    requestComplete: false,
    error: "",
};

export function deleteMsMail(state = deleteMicroSoftMailState, action) {
    switch (action.type) {
        case DELETE_MICROSOFT_MAIL_REQUEST:
            return {
                ...state,
                fetching: true,
                requestComplete: false,
                error: "",
            };
        case DELETE_MICROSOFT_MAIL_SUCCESS:
            return {
                delData: action.payload,
                fetching: false,
                requestComplete: true,
                error: "",
            };
        case DELETE_MICROSOFT_MAIL_RESET:
            return {
                delData: {},
                fetching: false,
                requestComplete: false,
                error: "",
            };
        case DELETE_MICROSOFT_MAIL_FAILURE:
            return {
                fetching: false,
                requestComplete: false,
                error: action.payload,
            };
        default:
            return state;
    }
}

// GET Attachment Preview

const getAttachmentPreviewState = {
    data: {},
    fetching: false,
    requestComplete: false,
    error: "",
};

export function getAttachmentPreview(
    state = getAttachmentPreviewState,
    action
) {
    switch (action.type) {
        case GET_ATTACHMENT_PREVIEW_REQUEST:
            return {
                ...state,
                fetching: true,
                requestComplete: false,
                error: "",
            };
        case GET_ATTACHMENT_PREVIEW_SUCCESS:
            return {
                dataList: action.payload,
                fetching: false,
                requestComplete: true,
                error: "",
            };
        case GET_ATTACHMENT_PREVIEW_RESET:
            return {
                data: {},
                fetching: false,
                requestComplete: false,
                error: "",
            };
        case GET_ATTACHMENT_PREVIEW_FAILURE:
            return {
                fetching: false,
                requestComplete: false,
                error: action.payload,
            };
        default:
            return state;
    }
}
// email mark as read and unread

const EmailMarkasState = {
    emailRead: [],
    fetching: false,
    requestComplete: false,
    error: "",
};
export function getInboxEmailMarkasReducer(state = EmailMarkasState, action) {
    switch (action.type) {
        case INBOX_EMAIL_MARK_AS_REQUEST:
            return {
                ...state,
                fetching: true,
                requestComplete: false,
                error: "",
            };
        case INBOX_EMAIL_MARK_AS_SUCCESS:
            return {
                emailbody: action.payload,
                fetching: false,
                requestComplete: true,
                error: "",
            };
        case INBOX_EMAIL_MARK_AS_RESET:
            return {
                emailbody: [],
                fetching: false,
                requestComplete: false,
                error: "",
            };
        case INBOX_EMAIL_MARK_AS_FAILURE:
            return {
                fetching: false,
                requestComplete: false,
                error: action.payload,
            };
        default:
            return state;
    }
}

// GET Attachment Preview

const downloadAttachmentState = {
    data: {},
    fetching: false,
    requestComplete: false,
    error: "",
};

export function downloadAttachment(state = downloadAttachmentState, action) {
    switch (action.type) {
        case DOWNLOAD_ATTACHMENT_REQUEST:
            return {
                ...state,
                fetching: true,
                requestComplete: false,
                error: "",
            };
        case DOWNLOAD_ATTACHMENT_SUCCESS:
            return {
                dataList: action.payload,
                fetching: false,
                requestComplete: true,
                error: "",
            };
        case DOWNLOAD_ATTACHMENT_RESET:
            return {
                data: {},
                fetching: false,
                requestComplete: false,
                error: "",
            };
        case DOWNLOAD_ATTACHMENT_FAILURE:
            return {
                fetching: false,
                requestComplete: false,
                error: action.payload,
            };
        default:
            return state;
    }
}

//Websocket Connection

let socket = null;
const socketState = {
    data: null,
    message: {},
    isConnected: false,
    error: "",
};
export function SocketConnection(state = socketState, action) {
    switch (action.type) {
        case WS_CONNECT:
            socket = new WebSocket(action?.payload?.url);
            socket.onopen = () => {
                socket.send("connect");
            };

            socket.onmessage = (event) => {
                //  const dataList = JSON.parse(event.data);
                const dataList = event?.data;
                action?.dispatch({
                    type: WS_MESSAGE_RECEIVED,
                    payload: dataList,
                });
            };

            socket.onclose = () => {};
            return {
                ...state,
                data: socket,
                isConnected: true,
            };

        case WS_SEND_MESSAGE:
            if (socket && socket.readyState === WebSocket.OPEN) {
                socket.send(JSON.stringify(action.payload));
            }
            return {
                ...state,
            };

        case WS_DISCONNECTED:
            if (socket) {
                socket.close();
                socket = null;
            }
            return {
                ...state,
                isConnected: false,
                data: null,
            };

        case WS_MESSAGE_RECEIVED:
            if (socket) {
                return {
                    ...state,
                    message: action?.payload,
                };
            }
        default:
            return state;
    }
}
