export const CART_ACTION = "CART_ACTION";
export const USER_TOGGLE_ACTION = "USER_TOGGLE_ACTION";
export const ORG_TOGGLE_ACTION = "USER_TOGGLE_ACTION";

export const SIGNUP_REQUEST = "SIGNUP_REQUEST";
export const SIGNUP_FAILURE = "SIGNUP_FAILURE";
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
export const SIGNUP_RESET = "SIGNUP_RESET";

export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_RESET = "LOGIN_RESET";

export const PROFILE_VIEW_REQUEST = "PROFILE_VIEW_REQUEST";
export const PROFILE_VIEW_FAILURE = "PROFILE_VIEW_FAILURE";
export const PROFILE_VIEW_SUCCESS = "PROFILE_VIEW_SUCCESS";
export const PROFILE_VIEW_RESET = "PROFILE_VIEW_RESET";

export const PROFILE_EDIT_REQUEST = "PROFILE_EDIT_REQUEST";
export const PROFILE_EDIT_FAILURE = "PROFILE_EDIT_FAILURE";
export const PROFILE_EDIT_SUCCESS = "PROFILE_EDIT_SUCCESS";
export const PROFILE_EDIT_RESET = "PROFILE_EDIT_RESET";

export const COUNTRY_REQUEST = "COUNTRY_REQUEST";
export const COUNTRY_FAILURE = "COUNTRY_FAILURE";
export const COUNTRY_SUCCESS = "COUNTRY_SUCCESS";
export const COUNTRY_RESET = "COUNTRY_RESET";

export const CHANGE_FAILURE = "CHANGE_FAILURE";
export const CHANGE_REQUEST = "CHANGE_REQUEST";
export const CHANGE_RESET = "CHANGE_RESET";
export const CHANGE_SUCCESS = "CHANGE_SUCCESS";

export const CHANGE_PASSWORD_REQUEST = "CHANGE_PASSWORD_REQUEST";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_FAILURE = "CHANGE_PASSWORD_FAILURE";
export const CHANGE_PASSWORD_RESET = "CHANGE_PASSWORD_RESET";

export const SET_PASSWORD_FAILURE = "SET_PASSWORD_FAILURE";
export const SET_PASSWORD_RESET = "SET_PASSWORD_RESET";
export const SET_PASSWORD_REQUEST = "SET_PASSWORD_REQUEST";
export const SET_PASSWORD_SUCCESS = "SET_PASSWORD_SUCCESS";

export const RESET_PASSWORD_FAILURE = "RESET_PASSWORD_FAILURE";
export const RESET_PASSWORD_RESET = "RESET_PASSWORD_RESET";
export const RESET_PASSWORD_REQUEST = "RESET_PASSWORD_REQUEST";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";

export const GETALLUSER_BYORG_FAILURE = "GETALLUSER_BYORG_FAILURE";
export const GETALLUSER_BYORG__RESET = "GETALLUSER_BYORG__RESET";
export const GETALLUSER_BYORG__SUCCESS = "GETALLUSER_BYORG__SUCCESS";
export const GETALLUSER_BYORG__REQUEST = "GETALLUSER_BYORG__REQUEST";

export const GET_USERCOUNT_REQUEST = "GET_USERCOUNT_REQUEST";
export const GET_USERCOUNT_SUCCESS = "GET_USERCOUNT_SUCCESS";
export const GET_USERCOUNT_FAILURE = "GET_USERCOUNT_FAILURE";
export const GET_USERCOUNT_RESET = "GET_USERCOUNT_RESET";

export const USER_FILTER_REQUEST = "USER_FILTER_REQUEST";
export const USER_FILTER_SUCCESS = "USER_FILTER_SUCCESS";
export const USER_FILTER_RESET = "USER_FILTER_RESET";
export const USER_FILTER_FAILURE = "USER_FILTER_FAILURE";

export const USER_ADD_REQUEST = "USER_ADD_REQUEST";
export const USER_ADD_FAILURE = "USER_ADD_FAILURE";
export const USER_ADD_SUCCESS = "USER_ADD_SUCCESS";
export const USER_ADD_RESET = "USER_ADD_RESET";

export const USER_VIEW_REQUEST = "USER_VIEW_REQUEST";
export const USER_VIEW_FAILURE = "USER_VIEW_FAILURE";
export const USER_VIEW_SUCCESS = "USER_VIEW_SUCCESS";
export const USER_VIEW_RESET = "USER_VIEW_RESET";

export const USER_EDIT_REQUEST = "USER_EDIT_REQUEST";
export const USER_EDIT_FAILURE = "USER_EDIT_FAILURE";
export const USER_EDIT_SUCCESS = "USER_EDIT_SUCCESS";
export const USER_EDIT_RESET = "USER_EDIT_RESET";

//list org
export const GET_ORGANISATION_FAILURE = "GET_ORGANISATION_FAILURE";
export const GET_ORGANISATION_RESET = "GET_ORGANISATION_RESET";
export const GET_ORGANISATION_SUCCESS = "GET_ORGANISATION_SUCCESS";
export const GET_ORGANISATION_REQUEST = "GET_ORGANISATION_REQUEST";

//pagination
export const GET_ORGCOUNT_REQUEST = "GET_ORGCOUNT_REQUEST";
export const GET_ORGCOUNT_SUCCESS = "GET_ORGCOUNT_SUCCESS";
export const GET_ORGCOUNT_FAILURE = "GET_ORGCOUNT_FAILURE";
export const GET_ORGCOUNT_RESET = "GET_ORGCOUNT_RESET";

export const ORG_FILTER_REQUEST = "ORG_FILTER_REQUEST";
export const ORG_FILTER_SUCCESS = "ORG_FILTER_SUCCESS";
export const ORG_FILTER_RESET = "ORG_FILTER_RESET";
export const ORG_FILTER_FAILURE = "ORG_FILTER_FAILURE";

//add org
export const ORG_ADD_REQUEST = "ORG_ADD_REQUEST";
export const ORG_ADD_FAILURE = "ORG_ADD_FAILURE";
export const ORG_ADD_SUCCESS = "ORG_ADD_SUCCESS";
export const ORG_ADD_RESET = "ORG_ADD_RESET";

//view org
export const ORG_VIEW_REQUEST = "ORG_VIEW_REQUEST";
export const ORG_VIEW_FAILURE = "ORG_VIEW_FAILURE";
export const ORG_VIEW_SUCCESS = "ORG_VIEW_SUCCESS";
export const ORG_VIEW_RESET = "ORG_VIEW_RESET";

//edit org
export const ORG_EDIT_REQUEST = "ORG_EDIT_REQUEST";
export const ORG_EDIT_FAILURE = "ORG_EDIT_FAILURE";
export const ORG_EDIT_SUCCESS = "ORG_EDIT_SUCCESS";
export const ORG_EDIT_RESET = "ORG_EDIT_RESET";

export const SETTING_REQUEST = "SETTING_REQUEST";
export const SETTING_FAILURE = "SETTING_FAILURE";
export const SETTING_SUCCESS = "SETTING_SUCCESS";
export const SETTING_RESET = "SETTING_RESET";
//invoicelist
export const INVOICE_LIST_REQUEST = "  INVOICE_LIST_REQUEST";
export const INVOICE_LIST_SUCCESS = " INVOICE_LIST_SUCCESS";
export const INVOICE_LIST_RESET = " INVOICE_LIST_RESET";
export const INVOICE_LIST_FAILURE = "INVOICE_LIST_FAILURE";

export const INVOICE_PROCESS_FILTER_REQUEST = " INVOICE_PROCESS_FILTER_REQUEST";
export const INVOICE_PROCESS_FILTER_SUCCESS = "INVOICE_PROCESS_FILTER_SUCCESS";
export const INVOICE_PROCESS_FILTER_FAILURE = " INVOICE_PROCESS_FILTER_FAILURE";
export const INVOICE_PROCESS_FILTER_RESET = "INVOICE_PROCESS_FILTER_RESET";

export const INVOICE_UPDATE_REQUEST = "INVOICE_UPDATE_REQUEST";
export const INVOICE_UPDATE_FAILURE = "INVOICE_UPDATE_FAILURE";
export const INVOICE_UPDATE_SUCCESS = "INVOICE_UPDATE_SUCCESS";
export const INVOICE_UPDATE_RESET = "INVOICE_UPDATE_RESET";

export const SETTING_UPDATE_REQUEST = "SETTING_UPDATE_REQUEST";
export const SETTING_UPDATE_FAILURE = "SETTING_UPDATE_FAILURE";
export const SETTING_UPDATE_SUCCESS = "SETTING_UPDATE_SUCCESS";
export const SETTING_UPDATE_RESET = "SETTING_UPDATE_RESET";

export const ROLE_REQUEST = "ROLE_REQUEST";
export const ROLE_FAILURE = "ROLE_FAILURE";
export const ROLE_SUCCESS = "ROLE_SUCCESS";
export const ROLE_RESET = "ROLE_RESET";

//email config
export const GET_EMAIL_CONFIG_REQUEST = "GET_EMAIL_CONFIG_REQUEST ";
export const GET_EMAIL_CONFIG_FAILURE = "GET_EMAIL_CONFIG_FAILURE ";
export const GET_EMAIL_CONFIG_SUCCESS = "GET_EMAIL_CONFIG_SUCCESS";
export const GET_EMAIL_CONFIG_RESET = " GET_EMAIL_CONFIG_RESET ";

//Add email config

export const ADD_EMAIL_CONFIG_REQUEST = "ADD_EMAIL_CONFIG_REQUEST ";
export const ADD_EMAIL_CONFIG_FAILURE = "ADD_EMAIL_CONFIG_FAILURE ";
export const ADD_EMAIL_CONFIG_SUCCESS = "ADD_EMAIL_CONFIG_SUCCESS";
export const ADD_EMAIL_CONFIG_RESET = " ADD_EMAIL_CONFIG_RESET ";

//Activate-deactivate email config

export const ACTIVATE_DEACTIVATE_EMAIL_CONFIG_REQUEST = "ACTIVATE_DEACTIVATE_EMAIL_CONFIG_REQUEST";
export const ACTIVATE_DEACTIVATE_EMAIL_CONFIG_FAILURE = "ACTIVATE_DEACTIVATE_EMAIL_CONFIG_FAILURE";
export const ACTIVATE_DEACTIVATE_EMAIL_CONFIG_SUCCESS = "ACTIVATE_DEACTIVATE_EMAIL_CONFIG_SUCCESS";
export const ACTIVATE_DEACTIVATE_EMAIL_CONFIG_RESET = "ACTIVATE_DEACTIVATE_EMAIL_CONFIG_RESET";

//folder config
export const GET_FOLDER_CONFIG_REQUEST = "GET_FOLDER_CONFIG_REQUEST ";
export const GET_FOLDER_CONFIG_FAILURE = "GET_FOLDER_CONFIG_FAILURE ";
export const GET_FOLDER_CONFIG_SUCCESS = "GET_FOLDER_CONFIG_SUCCESS";
export const GET_FOLDER_CONFIG_RESET = " GET_FOLDER_CONFIG_RESET ";

//Add folder config

export const ADD_FOLDER_CONFIG_REQUEST = "ADD_FOLDER_CONFIG_REQUEST ";
export const ADD_FOLDER_CONFIG_FAILURE = "ADD_FOLDER_CONFIG_FAILURE ";
export const ADD_FOLDER_CONFIG_SUCCESS = "ADD_FOLDER_CONFIG_SUCCESS";
export const ADD_FOLDER_CONFIG_RESET = " ADD_FOLDER_CONFIG_RESET ";

//Activate-deactivate email config

export const ACTIVATE_DEACTIVATE_FOLDER_CONFIG_REQUEST = "ACTIVATE_DEACTIVATE_FOLDER_CONFIG_REQUEST";
export const ACTIVATE_DEACTIVATE_FOLDER_CONFIG_FAILURE = "ACTIVATE_DEACTIVATE_FOLDER_CONFIG_FAILURE";
export const ACTIVATE_DEACTIVATE_FOLDER_CONFIG_SUCCESS = "ACTIVATE_DEACTIVATE_FOLDER_CONFIG_SUCCESS";
export const ACTIVATE_DEACTIVATE_FOLDER_CONFIG_RESET = "ACTIVATE_DEACTIVATE_FOLDER_CONFIG_RESET";

export const GET_INVCOUNT_FAILURE = "GET_INVCOUNT_FAILURE";
export const GET_INVCOUNT_REQUEST = "GET_INVCOUNT_REQUEST";
export const GET_INVCOUNT_RESET = "GET_INVCOUNT_RESET";
export const GET_INVCOUNT_SUCCESS = "GET_INVCOUNT_SUCCESS";

export const INVOICE_GET_REQUEST = "INVOICE_GET__REQUEST";
export const INVOICE_GET_SUCCESS = "INVOICE_GET__SUCCESS";
export const INVOICE_GET_FAILURE = "INVOICE_GET_FAILURE";
export const INVOICE_GET_RESET = "INVOICE_GET_RESET";

export const INVOICE_GET_DOCUMENT_REQUEST = "INVOICE_GET_DOCUMENT_REQUEST";
export const INVOICE_GET_DOCUMENT_SUCCESS = "INVOICE_GET_DOCUMENT_SUCCESS";
export const INVOICE_GET_DOCUMENT_FAILURE = "INVOICE_GET_DOCUMENT_FAILURE";
export const INVOICE_GET_DOCUMENT_RESET = "INVOICE_GET_DOCUMENT_RESET";

export const INVOICE_APPROVED_LIST_FAILURE = "INVOICE_APPROVED_LIST_FAILURE";
export const INVOICE_APPROVED_LIST_REQUEST = "INVOICE_APPROVED_LIST_REQUEST";
export const INVOICE_APPROVED_LIST_RESET = "INVOICE_APPROVED_LIST_RESET";
export const INVOICE_APPROVED_LIST_SUCCESS = "INVOICE_APPROVED_LIST_SUCCESS";

export const INVOICE_REJECTED_LIST_FAILURE = "INVOICE_REJECTED_LIST_FAILURE";
export const INVOICE_REJECTED_LIST_REQUEST = "INVOICE_REJECTED_LIST_REQUEST";
export const INVOICE_REJECTED_LIST_RESET = "INVOICE_REJECTEDLIST_RESET";
export const INVOICE_REJECTED_LIST_SUCCESS = "INVOICE_REJECTED_LIST_SUCCESS";

export const LOGOUT_REQUEST = "LOGOUT_REQUEST";
export const LOGOUT_FAILURE = "LOGOUT_FAILURE";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_RESET = "LOGOUT_RESET";
//Add-update integrated server config

export const ADD_SERVER_CONFIG_REQUEST = "ADD_SERVER_CONFIG_REQUEST";
export const ADD_SERVER_CONFIG_SUCCESS = "ADD_SERVER_CONFIG_SUCCESS";
export const ADD_SERVER_CONFIG_RESET = "ADD_SERVER_CONFIG_RESET";
export const ADD_SERVER_CONFIG_FAILURE = " ADD_SERVER_CONFIG_FAILURE";

// integrated server by id

export const VIEW_SERVER_CONFIG_REQUEST = "VIEW_SERVER_CONFIG_REQUEST ";
export const VIEW_SERVER_CONFIG_SUCCESS = "VIEW_SERVER_CONFIG_SUCCESS ";
export const VIEW_SERVER_CONFIG_RESET = "VIEW_SERVER_CONFIG_RESET";
export const VIEW_SERVER_CONFIG_FAILURE = "VIEW_SERVER_CONFIG_FAILURE";

export const FORGOT_PASS_REQUEST = "FORGOT_PASS_REQUEST";
export const FORGOT_PASS_FAILURE = "FORGOT_PASS_FAILURE";
export const FORGOT_PASS_SUCCESS = "FORGOT_PASS_SUCCESS";
export const FORGOT_PASS_RESET = "FORGOT_PASS_RESET";

export const SETTING_VALUE_REQUEST = "SETTING_VALUE_REQUEST";
export const SETTING_VALUE_FAILURE = "SETTING_VALUE_FAILURE";
export const SETTING_VALUE_SUCCESS = "SETTING_VALUE_SUCCESS";
export const SETTING_VALUE_RESET = "SETTING_VALUE_RESET";

export const CATEGORY_REQUEST = "CATEGORY_REQUEST";
export const CATEGORY_FAILURE = "CATEGORY_FAILURE";
export const CATEGORY_SUCCESS = "CATEGORY_SUCCESS";
export const CATEGORY_RESET = "CATEGORY_RESET";

export const DASHBORAD_VENDOR_REQUEST = "DASHBORAD_VENDOR_REQUEST";
export const DASHBORAD_VENDOR_SUCCESS = "DASHBORAD_VENDOR_SUCCESS";
export const DASHBORAD_VENDOR_FAILURE = "DASHBORAD_VENDOR_FAILURE";
export const DASHBORAD_VENDOR_RESET = "DASHBORAD_VENDOR_RESET";

export const DASHBORAD_DATE_REQUEST = "DASHBORAD_DATE_REQUEST";
export const DASHBORAD_DATE_SUCCESS = "DASHBORAD_DATE_SUCCESS";
export const DASHBORAD_DATE_FAILURE = "DASHBORAD_DATE_FAILURE";
export const DASHBORAD_DATE_RESET = "DASHBORAD_DATE_RESET";

export const DASHBORAD_VENDOR_LIST_REQUEST = "DASHBORAD_VENDOR_LIST_REQUEST";
export const DASHBORAD_VENDOR_LIST_SUCCESS = "DASHBORAD_VENDOR_LIST_SUCCESS";
export const DASHBORAD_VENDOR_LIST_FAILURE = "DASHBORAD_VENDOR_LIST_FAILURE";

export const INVOICE_FLAG_REQUEST = "INVOICE_FLAG_REQUEST";
export const INVOICE_FLAG_SUCCESS = "INVOICE_FLAG_SUCCESS";
export const INVOICE_FLAG_FAILURE = "INVOICE_FLAG_FAILURE";
export const INVOICE_FLAG_RESET = "INVOICE_FLAG_RESET";

//MANUAL UPLOAD
export const UPLOAD_INVOICE_REQUEST = "UPLOAD_INVOICE_REQUEST";
export const UPLOAD_INVOICE_SUCCESS = "UPLOAD_INVOICE_SUCCESS ";
export const UPLOAD_INVOICE_FAILURE = "UPLOAD_INVOICE_FAILURE ";
export const UPLOAD_INVOICE_RESET = "UPLOAD_INVOICE_RESET ";

//search
export const GLOBAL_SEARCH_REQUEST = "GLOBAL_SEARCH_REQUEST";
export const GLOBAL_SEARCH_SUCCESS = "GLOBAL_SEARCH_SUCCESS";
export const GLOBAL_SEARCH_FAILURE = "GLOBAL_SEARCH_FAILURE";
export const GLOBAL_SEARCH_RESET = "GLOBAL_SEARCH_RESET";

export const EXPIRY_LINK_REQUEST = "EXPIRY_LINK_REQUEST";
export const EXPIRY_LINK_SUCCESS = "EXPIRY_LINK_SUCCESS ";
export const EXPIRY_LINK_FAILURE = "EXPIRY_LINK_FAILURE ";
export const EXPIRY_LINK_RESET = "EXPIRY_LINK_RESET ";

//Upload croped image
export const INVOICE_CROPED_IMG_REQUEST = "INVOICE_CROPED_IMG_REQUEST";
export const INVOICE_CROPED_IMG_SUCCESS = "INVOICE_CROPED_IMG_SUCCESS";
export const INVOICE_CROPED_IMG_FAILURE = "INVOICE_CROPED_IMG_FAILURE";
export const INVOICE_CROPED_IMG_RESET = "INVOICE_CROPED_IMG_RESET";

//Org status update
export const ORG_STATUS_UPDATE_REQUEST = "ORG_STATUS_UPDATE_REQUEST";
export const ORG_STATUS_UPDATE_SUCCESS = "ORG_STATUS_UPDATE_SUCCESS";
export const ORG_STATUS_UPDATE_FAILURE = "ORG_STATUS_UPDATE_FAILURE";
export const ORG_STATUS_UPDATE_RESET = "ORG_STATUS_UPDATE_RESET";

export const ORG_DOMAIN_REQUEST = "ORG_DOMIN_REQUEST";
export const ORG_DOMAIN_SUCCESS = "ORG_DOMIN_SUCCESS";
export const ORG_DOMAIN_FAILURE = "ORG_DOMIN_FAILURE";
export const ORG_DOMAIN_RESET = "ORG_DOMIN_RESET";

export const RESEND_EMAIL_LINK_REQUEST = "RESEND_EMAIL_LINK_REQUEST";
export const RESEND_EMAIL_LINK_FAILURE = "RESEND_EMAIL_LINK_FAILURE";
export const RESEND_EMAIL_LINK_SUCCESS = "RESEND_EMAIL_LINK_SUCCESS";
export const RESEND_EMAIL_LINK_RESET = "RESEND_EMAIL_LINK_RESET";

export const ACTIVITY_HISTORY_REQUEST = "ACTIVITY_HISTORY_REQUEST";
export const ACTIVITY_HISTORY_FAILURE = "ACTIVITY_HISTORY_FAILURE";
export const ACTIVITY_HISTORY_SUCCESS = "ACTIVITY_HISTORY_SUCCESS ";
export const ACTIVITY_HISTORY_RESET = "ACTIVITY_HISTORY_RESET";

export const INVOICE_COMPARE_REQUEST = "INVOICE_COMPARE_REQUEST";
export const INVOICE_COMPARE_FAILURE = "INVOICE_COMPARE_FAILURE";
export const INVOICE_COMPARE_SUCCESS = "INVOICE_COMPARE_SUCCESS";
export const INVOICE_COMPARE_RESET = "INVOICE_COMPARE_RESET";

export const USER_ROLE_BYORG_REQUEST = "USER_ROLE_BYORG_REQUEST";
export const USER_ROLE_BYORG_FAILURE = "USER_ROLE_BYORG_FAILURE";
export const USER_ROLE_BYORG_SUCCESS = "USER_ROLE_BYORG_SUCCESS";
export const USER_ROLE_BYORG_RESET = "USER_ROLE_BYORG_RESET";

export const USER_ROLEBYORG_VIEW_REQUEST = "USER_ROLEBYORG_VIEW_REQUEST";
export const USER_ROLEBYORG_VIEW_FAILURE = "USER_ROLEBYORG_VIEW_FAILURE";
export const USER_ROLEBYORG_VIEW_SUCCESS = "USER_ROLEBYORG_VIEW_SUCCESS";
export const USER_ROLEBYORG_VIEW_RESET = "USER_ROLEBYORG_VIEW_RESET";

export const LIST_ORG_ROLEBYUSER_REQUEST = "LIST_ORG_ROLEBYUSER_REQUEST";
export const LIST_ORG_ROLEBYUSER_FAILURE = "LIST_ORG_ROLEBYUSER_FAILURE";
export const LIST_ORG_ROLEBYUSER_SUCCESS = "LIST_ORG_ROLEBYUSER_SUCCESS";
export const LIST_ORG_ROLEBYUSER_RESET = "LIST_ORG_ROLEBYUSER_RESET";

export const USER_STATUS_UPDATE_REQUEST = "USER_STATUS_UPDATE_REQUEST";
export const USER_STATUS_UPDATE_SUCCESS = "USER_STATUS_UPDATE_SUCCESS";
export const USER_STATUS_UPDATE_FAILURE = "USER_STATUS_UPDATE_FAILURE";
export const USER_STATUS_UPDATE_RESET = "USER_STATUS_UPDATE_RESET";

export const USER_ROLE_DELETE_REQUEST = "USER_ROLE_DELETE_REQUEST";
export const USER_ROLE_DELETE_SUCCESS = "USER_ROLE_DELETE_SUCCESS";
export const USER_ROLE_DELETE_FAILURE = "USER_ROLE_DELETE_FAILURE";
export const USER_ROLE_DELETE_RESET = "USER_ROLE_DELETE_RESET";

export const ADD_UPDATE_USER_ROLEBYUSER_REQUEST = "ADD_UPDATE_USER_ROLEBYUSER_REQUEST";
export const ADD_UPDATE_USER_ROLEBYUSER_SUCCESS = "ADD_UPDATE_USER_ROLEBYUSER_SUCCESS";
export const ADD_UPDATE_USER_ROLEBYUSER_FAILURE = "ADD_UPDATE_USER_ROLEBYUSER_FAILURE";
export const ADD_UPDATE_USER_ROLEBYUSER_RESET = "ADD_UPDATE_USER_ROLEBYUSER_RESET";

export const GET_ORGLIST_DROP_DOWN_REQUEST = "GET_ORGLIST_DROP_DOWN_REQUEST";
export const GET_ORGLIST_DROP_DOWN_SUCCESS = "GET_ORGLIST_DROP_DOWN_SUCCESS";
export const GET_ORGLIST_DROP_DOWN_FAILURE = "GET_ORGLIST_DROP_DOWN_FAILURE";
export const GET_ORGLIST_DROP_DOWN_RESET = "GET_ORGLIST_DROP_DOWN_RESET";

export const USER_ACTIVITY_REQUEST = "USER_ACTIVITY_REQUEST";
export const USER_ACTIVITY_FAILURE = "USER_ACTIVITY_FAILURE";
export const USER_ACTIVITY_SUCCESS = "USER_ACTIVITY_SUCCESS";
export const USER_ACTIVITY_RESET = "USER_ACTIVITY_RESET";

export const INVOICE_VENDOR_LIST_REQUEST = "INVOICE_VENDOR_LIST_REQUEST";
export const INVOICE_VENDOR_LIST_SUCCESS = "INVOICE_VENDOR_LIST_SUCCESS";
export const INVOICE_VENDOR_LIST_FAILURE = "INVOICE_VENDOR_LIST_FAILURE";
export const INVOICE_VENDOR_LIST_RESET = "INVOICE_VENDOR_LIST_RESET";

export const ROLE_PERMISSION_LIST_REQUEST = "ROLE_PERMISSION_LIST_REQUEST";
export const ROLE_PERMISSION_LIST_SUCCESS = "ROLE_PERMISSION_LIST_SUCCESS";
export const ROLE_PERMISSION_LIST_FAILURE = "ROLE_PERMISSION_LIST_FAILURE";
export const ROLE_PERMISSION_LIST_RESET = "ROLE_PERMISSION_LIST_RESET";

export const ROLE_PERMISSION_ADD_REQUEST = "ROLE_PERMISSION_ADD_REQUEST";
export const ROLE_PERMISSION_ADD_SUCCESS = "ROLE_PERMISSION_ADD_SUCCESS";
export const ROLE_PERMISSION_ADD_FAILURE = "ROLE_PERMISSION_ADD_FAILURE";
export const ROLE_PERMISSION_ADD_RESET = "ROLE_PERMISSION_ADD_RESET";

export const ROLE_PERMISSION_VIEW_REQUEST = "ROLE_PERMISSION_VIEW_REQUEST";
export const ROLE_PERMISSION_VIEW_SUCCESS = "ROLE_PERMISSION_VIEW_SUCCESS";
export const ROLE_PERMISSION_VIEW_FAILURE = "ROLE_PERMISSION_VIEW_FAILURE";
export const ROLE_PERMISSION_VIEW_RESET = "ROLE_PERMISSION_VIEW_RESET";

export const INVOICE_LABEL_LAYOUT_LIST_REQUEST = "INVOICE_LABEL_LAYOUT_LIST_REQUEST";
export const INVOICE_LABEL_LAYOUT_LIST_SUCCESS = "INVOICE_LABEL_LAYOUT_LIST_SUCCESS";
export const INVOICE_LABEL_LAYOUT_LIST_FAILURE = "INVOICE_LABEL_LAYOUT_LIST_FAILURE";
export const INVOICE_LABEL_LAYOUT_LIST_RESET = "INVOICE_LABEL_LAYOUT_LIST_RESET";

export const INVOICE_LABEL_LAYOUT_ADD_REQUEST = "INVOICE_LABEL_LAYOUT_ADD_REQUEST";
export const INVOICE_LABEL_LAYOUT_ADD_SUCCESS = "INVOICE_LABEL_LAYOUT_ADD_SUCCESS";
export const INVOICE_LABEL_LAYOUT_ADD_FAILURE = "INVOICE_LABEL_LAYOUT_ADD_FAILURE";
export const INVOICE_LABEL_LAYOUT_ADD_RESET = "INVOICE_LABEL_LAYOUT_ADD_RESET";

export const ROLE_PERMISSION_DISPLAY_REQUEST = "ROLE_PERMISSION_DISPLAY_REQUEST";
export const ROLE_PERMISSION_DISPLAY_SUCCESS = "ROLE_PERMISSION_DISPLAY_SUCCESS";
export const ROLE_PERMISSION_DISPLAY_FAILURE = "ROLE_PERMISSION_DISPLAY_FAILURE";
export const ROLE_PERMISSION_DISPLAY_RESET = "ROLE_PERMISSION_DISPLAY_RESET";

export const USER_LIST_DROP_REQUEST = "USER_LIST_DROP_REQUEST";
export const USER_LIST_DROP_SUCCESS = "USER_LIST_DROP_SUCCESS";
export const USER_LIST_DROP_FAILURE = "USER_LIST_DROP_FAILURE";
export const USER_LIST_DROP_RESET = "USER_LIST_DROP_RESET";

export const TRANSFER_OWNER_FAILURE = "TRANSFER_OWNER_FAILURE";
export const TRANSFER_OWNER_REQUEST = "TRANSFER_OWNER_REQUEST";
export const TRANSFER_OWNER_SUCCESS = "TRANSFER_OWNER_SUCCESS";
export const TRANSFER_OWNER_RESET = "TRANSFER_OWNER_RESET";

export const SERVER_TARGET_TYPE_REQUEST = "SERVER_TARGET_TYPE_REQUEST";
export const SERVER_TARGET_TYPE_SUCCESS = "SERVER_TARGET_TYPE_SUCCESS";
export const SERVER_TARGET_TYPE_FAILURE = "SERVER_TARGET_TYPE_FAILURE";
export const SERVER_TARGET_TYPE_RESET = "SERVER_TARGET_TYPE_RESET";

export const REMOVE_DEMO_DATA_FAILURE = "REMOVE_DEMO_DATA_FAILURE";
export const REMOVE_DEMO_DATA_SUCCESS = "REMOVE_DEMO_DATA_SUCCESS";
export const REMOVE_DEMO_DATA_REQUEST = "REMOVE_DEMO_DATA_REQUEST";
export const REMOVE_DEMO_DATA_RESET = "REMOVE_DEMO_DATA_RESET";

export const INVOICE_ID_CONFIG_REQUEST = "INVOICE_ID_CONFIG_REQUEST";
export const INVOICE_ID_CONFIG_SUCCESS = "INVOICE_ID_CONFIG_SUCCESS";
export const INVOICE_ID_CONFIG_FAILURE = "INVOICE_ID_CONFIG_FAILURE";
export const INVOICE_ID_CONFIG_RESET = "INVOICE_ID_CONFIG_RESET";

export const GET_INVOICE_ID_CONFIG_REQUEST = "GET_INVOICE_ID_CONFIG_REQUEST";
export const GET_INVOICE_ID_CONFIG_SUCCESS = "GET_INVOICE_ID_CONFIG_SUCCESS";
export const GET_INVOICE_ID_CONFIG_FAILURE = "GET_INVOICE_ID_CONFIG_FAILURE";
export const GET_INVOICE_ID_CONFIG_RESET = "GET_INVOICE_ID_CONFIG_RESET";

export const GET_ACCRUAL_ACCOUNTING_REQUEST = "GET_ACCRUAL_ACCOUNTING_REQUEST";
export const GET_ACCRUAL_ACCOUNTING_SUCCESS = "GET_ACCRUAL_ACCOUNTING_SUCCESS";
export const GET_ACCRUAL_ACCOUNTING_FAILURE = "GET_ACCRUAL_ACCOUNTING_FAILURE";
export const GET_ACCRUAL_ACCOUNTING_RESET = "GET_ACCRUAL_ACCOUNTING_RESET";

export const ADD_ACCRUAL_ACCOUNTING_REQUEST = "ADD_ACCRUAL_ACCOUNTING_REQUEST";
export const ADD_ACCRUAL_ACCOUNTING_SUCCESS = "ADD_ACCRUAL_ACCOUNTING_SUCCESS";
export const ADD_ACCRUAL_ACCOUNTING_FAILURE = "ADD_ACCRUAL_ACCOUNTING_FAILURE";
export const ADD_ACCRUAL_ACCOUNTING_RESET = "ADD_ACCRUAL_ACCOUNTING_RESET";

export const TOKEN_AUTHORIZATION_REQUEST = "TOKEN_AUTHORIZATION_REQUEST";
export const TOKEN_AUTHORIZATION_SUCCESS = "TOKEN_AUTHORIZATION_SUCCESS";
export const TOKEN_AUTHORIZATION_FAILURE = "TOKEN_AUTHORIZATION_FAILURE";
export const TOKEN_AUTHORIZATION_RESET = "TOKEN_AUTHORIZATION_RESET";

export const GET_REPORTS_LIST_REQUEST = "GET_REPORTS_LIST_REQUEST";
export const GET_REPORTS_LIST_SUCCESS = "GET_REPORTS_LIST_SUCCESS";
export const GET_REPORTS_LIST_FAILURE = "GET_REPORTS_LIST_FAILURE";
export const GET_REPORTS_LIST_RESET = "GET_REPORTS_LIST_RESET";

export const GET_REPORTS_COUNT_REQUEST = "GET_REPORTS_COUNT_REQUEST";
export const GET_REPORTS_COUNT_SUCCESS = "GET_REPORTS_COUNT_SUCCESS";
export const GET_REPORTS_COUNT_FAILURE = "GET_REPORTS_COUNT_FAILURE";
export const GET_REPORTS_COUNT_RESET = "GET_REPORTS_COUNT_RESET";

export const GET_REPORTS_AGEING_REQUEST = "GET_REPORTS_AGEING_REQUEST";
export const GET_REPORTS_AGEING_SUCCESS = "GET_REPORTS_AGEING_SUCCESS";
export const GET_REPORTS_AGEING_FAILURE = "GET_REPORTS_AGEING_FAILURE";
export const GET_REPORTS_AGEING_RESET = "GET_REPORTS_AGEING_RESET";

export const GET_REPORTS_AGEING_SUMMARY_REQUEST = "GET_REPORTS_AGEING_SUMMARY_REQUEST";
export const GET_REPORTS_AGEING_SUMMARY_SUCCESS = "GET_REPORTS_AGEING_SUMMARY_SUCCESS";
export const GET_REPORTS_AGEING_SUMMARY_FAILURE = "GET_REPORTS_AGEING_SUMMARY_FAILURE";
export const GET_REPORTS_AGEING_SUMMARY_RESET = "GET_REPORTS_AGEING_SUMMARY_RESET";

export const GET_REPORTS_ACCRUAL_REQUEST = "GET_REPORTS_ACCRUAL_REQUEST";
export const GET_REPORTS_ACCRUAL_SUCCESS = "GET_REPORTS_ACCRUAL_SUCCESS";
export const GET_REPORTS_ACCRUAL_FAILURE = "GET_REPORTS_ACCRUAL_FAILURE";
export const GET_REPORTS_ACCRUAL_RESET = "GET_REPORTS_ACCRUAL_RESET";

export const GET_REPORTS_FAV_REQUEST = "GET_REPORTS_FAV_REQUEST";
export const GET_REPORTS_FAV_SUCCESS = "GET_REPORTS_FAV_SUCCESS";
export const GET_REPORTS_FAV_FAILURE = "GET_REPORTS_FAV_FAILURE";
export const GET_REPORTS_FAV_RESET = "GET_REPORTS_FAV_RESET";

export const ADD_REPORTS_FAV_REQUEST = "ADD_REPORTS_FAV_REQUEST";
export const ADD_REPORTS_FAV_SUCCESS = "ADD_REPORTS_FAV_SUCCESS";
export const ADD_REPORTS_FAV_FAILURE = "ADD_REPORTS_FAV_FAILURE";
export const ADD_REPORTS_FAV_RESET = "ADD_REPORTS_FAV_RESET";

export const GET_RULE_LIST_REQUEST = "GET_RULE_LIST_REQUEST";
export const GET_RULE_LIST_SUCCESS = "GET_RULE_LIST_SUCCESS";
export const GET_RULE_LIST_FAILURE = "GET_RULE_LIST_FAILURE";
export const GET_RULE_LIST_RESET = "GET_RULE_LIST_RESET";

export const ADD_RULE_LIST_REQUEST = "ADD_RULE_LIST_REQUEST";
export const ADD_RULE_LIST_SUCCESS = "ADD_RULE_LIST_SUCCESS";
export const ADD_RULE_LIST_FAILURE = "ADD_RULE_LIST_FAILURE";
export const ADD_RULE_LIST_RESET = "ADD_RULE_LIST_RESET";

export const FIELDS_RULE_LIST_REQUEST = "FIELDS_RULE_LIST_REQUEST";
export const FIELDS_RULE_LIST_SUCCESS = "FIELDS_RULE_LIST_SUCCESS";
export const FIELDS_RULE_LIST_FAILURE = "FIELDS_RULE_LIST_FAILURE";
export const FIELDS_RULE_LIST_RESET = "FIELDS_RULE_LIST_RESET";

export const ADD_INVOICE_ALLOCATION_REQUEST = "ADD_INVOICE_ALLOCATION_REQUEST";
export const ADD_INVOICE_ALLOCATION_SUCCESS = "ADD_INVOICE_ALLOCATION_SUCCESS";
export const ADD_INVOICE_ALLOCATION_FAILURE = "ADD_INVOICE_ALLOCATION_FAILURE";
export const ADD_INVOICE_ALLOCATION_RESET = "ADD_INVOICE_ALLOCATION_RESET";

export const GET_VIEW_RULE_REQUEST = "GET_VIEW_RULE_REQUEST";
export const GET_VIEW_RULE_SUCCESS = "GET_VIEW_RULE_SUCCESS";
export const GET_VIEW_RULE_FAILURE = "GET_VIEW_RULE_FAILURE";
export const GET_VIEW_RULE_RESET = "GET_VIEW_RULE_RESET";

export const GET_ACCRUAL_POSTING_REQUEST = "GET_ACCRUAL_POSTING_REQUEST";
export const GET_ACCRUAL_POSTING_SUCCESS = "GET_ACCRUAL_POSTING_SUCCESS";
export const GET_ACCRUAL_POSTING_FAILURE = "GET_ACCRUAL_POSTING_FAILURE";
export const GET_ACCRUAL_POSTING_RESET = "GET_ACCRUAL_POSTING_RESET";

export const ADD_ACCRUAL_POSTING_REQUEST = "ADD_ACCRUAL_POSTING_REQUEST";
export const ADD_ACCRUAL_POSTING_SUCCESS = "ADD_ACCRUAL_POSTING_SUCCESS";
export const ADD_ACCRUAL_POSTING_FAILURE = "ADD_ACCRUAL_POSTING_FAILURE";
export const ADD_ACCRUAL_POSTING_RESET = "ADD_ACCRUAL_POSTING_RESET";
export const OPERATORS_RULE_LIST_REQUEST = "OPERATORS_RULE_LIST_REQUEST";
export const OPERATORS_RULE_LIST_SUCCESS = "OPERATORS_RULE_LIST_SUCCESS";
export const OPERATORS_RULE_LIST_FAILURE = "OPERATORS_RULE_LIST_FAILURE";
export const OPERATORS_RULE_LIST_RESET = "OPERATORS_RULE_LIST_RESET";

export const GET_RULE_VIEW_REQUEST = "GET_RULE_VIEW_REQUEST";
export const GET_RULE_VIEW_SUCCESS = "GET_RULE_VIEW_SUCCESS";
export const GET_RULE_VIEW_FAILURE = "GET_RULE_VIEW_FAILURE";
export const GET_RULE_VIEW_RESET = "GET_RULE_VIEW_RESET";

export const ACTIVATE_DEACTIVATE_RULE_REQUEST = "ACTIVATE_DEACTIVATE_RULE_REQUEST";
export const ACTIVATE_DEACTIVATE_RULE_SUCCESS = "ACTIVATE_DEACTIVATE_RULE_SUCCESS";
export const ACTIVATE_DEACTIVATE_RULE_FAILURE = "ACTIVATE_DEACTIVATE_RULE_FAILURE";
export const ACTIVATE_DEACTIVATE_RULE_RESET = "ACTIVATE_DEACTIVATE_RULE_RESET";

export const GET_INVOICE_ALLOCATION_REQUEST = "GET_INVOICE_ALLOCATION_REQUEST";
export const GET_INVOICE_ALLOCATION_SUCCESS = "GET_INVOICE_ALLOCATION_SUCCESS";
export const GET_INVOICE_ALLOCATION_RESET = "GET_INVOICE_ALLOCATION_RESET";
export const GET_INVOICE_ALLOCATION_FAILURE = "GET_INVOICE_ALLOCATION_FAILURE";

export const GET_ACCRUAL_POSTING_DATES_REQUEST = "GET_ACCRUAL_POSTING_DATES_REQUEST";
export const GET_ACCRUAL_POSTING_DATES_SUCCESS = "GET_ACCRUAL_POSTING_DATES_SUCCESS";
export const GET_ACCRUAL_POSTING_DATES_RESET = "GET_ACCRUAL_POSTING_DATES_RESET";
export const GET_ACCRUAL_POSTING_DATES_FAILURE = "GET_ACCRUAL_POSTING_DATES_FAILURE";

export const REPORTS_AGEING_COUNT_REQUEST = "REPORTS_AGEING_COUNT_REQUEST";
export const REPORTS_AGEING_COUNT_SUCCESS = "REPORTS_AGEING_COUNT_SUCCESS";
export const REPORTS_AGEING_COUNT_RESET = "REPORTS_AGEING_COUNT_RESET";
export const REPORTS_AGEING_COUNT_FAILURE = "REPORTS_AGEING_COUNT_FAILURE";

export const GET_SUMMARY_AGING_REQUEST = "GET_SUMMARY_AGING_REQUEST";
export const GET_SUMMARY_AGING_SUCCESS = "GET_SUMMARY_AGING_SUCCESS";
export const GET_SUMMARY_AGING_FAILURE = "GET_SUMMARY_AGING_FAILURE";
export const GET_SUMMARY_AGING_RESET = "GET_SUMMARY_AGING_RESET";

export const TEST_SERVER_CONFIG_REQUEST = "TEST_SERVER_CONFIG_REQUEST";
export const TEST_SERVER_CONFIG_SUCCESS = "TEST_SERVER_CONFIG_SUCCESS";
export const TEST_SERVER_CONFIG_FAILURE = "TEST_SERVER_CONFIG_FAILURE";
export const TEST_SERVER_CONFIG_RESET = "TEST_SERVER_CONFIG_RESET";

//update email config

export const UPDATE_EMAIL_CONFIG_REQUEST = "UPDATE_EMAIL_CONFIG_REQUEST";
export const UPDATE_EMAIL_CONFIG_SUCCESS = "UPDATE_EMAIL_CONFIG_SUCCESS";
export const UPDATE_EMAIL_CONFIG_FAILURE = "UPDATE_EMAIL_CONFIG_FAILURE";
export const UPDATE_EMAIL_CONFIG_RESET = "UPDATE_EMAIL_CONFIG_RESET";

//update folder config

export const UPDATE_FOLDER_CONFIG_REQUEST = "UPDATE_FOLDER_CONFIG_REQUEST";
export const UPDATE_FOLDER_CONFIG_SUCCESS = "UPDATE_FOLDER_CONFIG_SUCCESS";
export const UPDATE_FOLDER_CONFIG_FAILURE = "UPDATE_FOLDER_CONFIG_FAILURE";
export const UPDATE_FOLDER_CONFIG_RESET = "UPDATE_FOLDER_CONFIG_RESET";
export const GET_USER_DROP_DOWN_ALLOCATION_REQUEST = "GET_USER_DROP_DOWN_ALLOCATION_REQUEST";
export const GET_USER_DROP_DOWN_ALLOCATION_SUCCESS = "GET_USER_DROP_DOWN_ALLOCATION__SUCCESS";
export const GET_USER_DROP_DOWN_ALLOCATION_FAILURE = "GET_USER_DROP_DOWN_ALLOCATION__FAILURE";
export const GET_USER_DROP_DOWN_ALLOCATION_RESET = "GET_USER_DROP_DOWN_ALLOCATION__RESET";

export const GET_ROLE_LIST_ALLOCATION_REQUEST = "GET_ROLE_LIST_ALLOCATION_REQUEST";
export const GET_ROLE_LIST_ALLOCATION_SUCCESS = "GET_ROLE_LIST_ALLOCATION_SUCCESS";
export const GET_ROLE_LIST_ALLOCATION_FAILURE = "GET_ROLE_LIST_ALLOCATION_FAILURE";
export const GET_ROLE_LIST_ALLOCATION_RESET = "GET_ROLE_LIST_ALLOCATION_RESET";

export const POST_INVOICE_APPROVER_REQUEST = "POST_INVOICE_APPROVER_REQUEST";
export const POST_INVOICE_APPROVER_SUCCESS = "POST_INVOICE_APPROVER_SUCCESS";
export const POST_INVOICE_APPROVER_RESET = "POST_INVOICE_APPROVER_RESET";
export const POST_INVOICE_APPROVER_FAILURE = "POST_INVOICE_APPROVER_FAILURE";

export const POST_INVOICE_REASSIGN_REQUEST = "POST_INVOICE_REASSIGN_REQUEST";
export const POST_INVOICE_REASSIGN_SUCCESS = "POST_INVOICE_REASSIGN_SUCCESS";
export const POST_INVOICE_REASSIGN_RESET = "POST_INVOICE_REASSIGN_RESET";
export const POST_INVOICE_REASSGIN_FAILURE = "POST_INVOICE_REASSGIN_FAILURE";

//getlistGeneralLedger

export const GET_GENERAL_LEDGER_LIST_REQUEST = "GET_GENERAL_LEDGER_LIST_REQUEST";
export const GET_GENERAL_LEDGER_LIST_SUCCESS = "GET_GENERAL_LEDGER_LIST_SUCCESS";
export const GET_GENERAL_LEDGER_LIST_RESET = "GET_GENERAL_LEDGER_LIST_RESET";
export const GET_GENERAL_LEDGER_LIST_FAILURE = "GET_GENERAL_LEDGER_LIST_FAILURE";

//get document type list

export const GET_DOCUMENT_TYPE_LIST_REQUEST = "GET_DOCUMENT_TYPE_LIST_REQUEST";
export const GET_DOCUMENT_TYPE_LIST_SUCCESS = "GET_DOCUMENT_TYPE_LIST_SUCCESS";
export const GET_DOCUMENT_TYPE_LIST_RESET = "GET_DOCUMENT_TYPE_LIST_RESET";
export const GET_DOCUMENT_TYPE_LIST_FAILURE = "GET_DOCUMENT_TYPE_LIST_FAILURE";

//get list cost Centre

export const GET_LIST_COST_CENTRE_REQUEST = "GET_LIST_COST_CENTRE_REQUEST";
export const GET_LIST_COST_CENTRE_SUCCESS = "GET_LIST_COST_CENTRE_SUCCESS";
export const GET_LIST_COST_CENTRE_RESET = "GET_LIST_COST_CENTRE_RESET";
export const GET_LIST_COST_CENTRE_FAILURE = "GET_LIST_COST_CENTRE_FAILURE";

//Task Id Configuration

export const GET_TASK_ID_REQUEST = "GET_TASK_ID_REQUEST";
export const GET_TASK_ID_SUCCESS = "GET_TASK_ID_SUCCESS";
export const GET_TASK_ID_RESET = "GET_TASK_ID_RESET";
export const GET_TASK_ID_FAILURE = "GET_TASK_ID_FAILURE";

// POST Task Id Configuration

export const POST_TASK_ID_REQUEST = "POST_TASK_ID_REQUEST";
export const POST_TASK_ID_SUCCESS = "POST_TASK_ID_SUCCESS";
export const POST_TASK_ID_RESET = "POST_TASK_ID_RESET";
export const POST_TASK_ID_FAILURE = "POST_TASK_ID_FAILURE";

//Get priority Configuration

export const GET_PRIORITY_CONFIG_REQUEST = "GET_PRIORITY_CONFIG_REQUEST";
export const GET_PRIORITY_CONFIG_SUCCESS = "GET_PRIORITY_CONFIG_SUCCESS";
export const GET_PRIORITY_CONFIG_RESET = "GET_PRIORITY_CONFIG_RESET";
export const GET_PRIORITY_CONFIG_FAILURE = "GET_PRIORITY_CONFIG_FAILURE";

//Get priority Configuration

export const POST_PRIORITY_CONFIG_REQUEST = "POST_PRIORITY_CONFIG_REQUEST";
export const POST_PRIORITY_CONFIG_SUCCESS = "POST_PRIORITY_CONFIG_SUCCESS";
export const POST_PRIORITY_CONFIG_RESET = "POST_PRIORITY_CONFIG_RESET";
export const POST_PRIORITY_CONFIG_FAILURE = "POST_PRIORITY_CONFIG_FAILURE";

export const GET_NOTIFICATION_REQUEST = "GET_NOTIFICATION_REQUEST";
export const GET_NOTIFICATION_SUCCESS = "GET_NOTIFICATION_SUCCESS";
export const GET_NOTIFICATION_RESET = "GET_NOTIFICATION_RESET";
export const GET_NOTIFICATION_FAILURE = "GET_NOTIFICATION_FAILURE";

export const GET_NOTIFICATION_SCROLL_REQUEST = "GET_NOTIFICATION_SCROLL_REQUEST";
export const GET_NOTIFICATION_SCROLL_SUCCESS = "GET_NOTIFICATION_SCROLL_SUCCESS";
export const GET_NOTIFICATION_SCROLL_RESET = "GET_NOTIFICATION_SCROLL_RESET";
export const GET_NOTIFICATION_SCROLL_FAILURE = "GET_NOTIFICATION_SCROLL_FAILURE";

export const GET_NOTIFICATION_LIST_REQUEST = "GET_NOTIFICATION_REQUEST";
export const GET_NOTIFICATION_LIST_SUCCESS = "GET_NOTIFICATION_SUCCESS";
export const GET_NOTIFICATION_LIST_RESET = "GET_NOTIFICATION_RESET";
export const GET_NOTIFICATION_LIST_FAILURE = "GET_NOTIFICATION_FAILURE";

export const UPDATE_NOTIFICATION_REQUEST = "UPDATE_NOTIFICATION_REQUEST";
export const UPDATE_NOTIFICATION_SUCCESS = "UPDATE_NOTIFICATION_SUCCESS";
export const UPDATE_NOTIFICATION_RESET = "UPDATE_NOTIFICATION_RESET";
export const UPDATE_NOTIFICATION_FAILURE = "UPDATE_NOTIFICATION_FAILURE";

//Get Email Template List

export const GET_EMAIL_TEMPLATE_LIST_REQUEST = "GET_EMAIL_TEMPLATE_LIST_REQUEST";
export const GET_EMAIL_TEMPLATE_LIST_SUCCESS = "GET_EMAIL_TEMPLATE_LIST_SUCCESS";
export const GET_EMAIL_TEMPLATE_LIST_RESET = "GET_EMAIL_TEMPLATE_LIST_RESET";
export const GET_EMAIL_TEMPLATE_LIST_FAILURE = "GET_EMAIL_TEMPLATE_LIST_FAILURE";

//view email template

export const VIEW_EMAIL_TEMPLATE_REQUEST = "VIEW_EMAIL_TEMPLATE_REQUEST";
export const VIEW_EMAIL_TEMPLATE_SUCCESS = "VIEW_EMAIL_TEMPLATE_SUCCESS";
export const VIEW_EMAIL_TEMPLATE_RESET = "VIEW_EMAIL_TEMPLATE_RESET";
export const VIEW_EMAIL_TEMPLATE_FAILURE = "VIEW_EMAIL_TEMPLATE_FAILURE";

//emailTemplatePlaceholderList

export const LIST_EMAIL_TEMPLATE_PLACEHOLDER_REQUEST = "LIST_EMAIL_TEMPLATE_PLACEHOLDER_REQUEST";
export const LIST_EMAIL_TEMPLATE_PLACEHOLDER_SUCCESS = "LIST_EMAIL_TEMPLATE_PLACEHOLDER_SUCCESS";
export const LIST_EMAIL_TEMPLATE_PLACEHOLDER_RESET = "LIST_EMAIL_TEMPLATE_PLACEHOLDER_RESET";
export const LIST_EMAIL_TEMPLATE_PLACEHOLDER_FAILURE = "LIST_EMAIL_TEMPLATE_PLACEHOLDER_FAILURE";

//addEmailTemplate

export const ADD_UPDATE_EMAIL_TEMPLATE_REQUEST = "ADD_UPDATE_EMAIL_TEMPLATE_REQUEST";
export const ADD_UPDATE_EMAIL_TEMPLATE_SUCCESS = "ADD_UPDATE_EMAIL_TEMPLATE_SUCCESS";
export const ADD_UPDATE_EMAIL_TEMPLATE_RESET = "ADD_UPDATE_EMAIL_TEMPLATE_RESET";
export const ADD_UPDATE_EMAIL_TEMPLATE_FAILURE = "ADD_UPDATE_EMAIL_TEMPLATE_FAILURE";

//EmailTemplateStatus

export const ACTIVATE_DEACTIVATE_EMAIL_TEMPLATE_REQUEST = "ACTIVATE_DEACTIVATE_EMAIL_TEMPLATE_REQUEST";
export const ACTIVATE_DEACTIVATE_EMAIL_TEMPLATE_SUCCESS = "ACTIVATE_DEACTIVATE_EMAIL_TEMPLATE_SUCCESS";
export const ACTIVATE_DEACTIVATE_EMAIL_TEMPLATE_RESET = "ACTIVATE_DEACTIVATE_EMAIL_TEMPLATE_RESET";
export const ACTIVATE_DEACTIVATE_EMAIL_TEMPLATE_FAILURE = "ACTIVATE_DEACTIVATE_EMAIL_TEMPLATE_FAILURE";

//my task list

export const GET_MY_TASK_LIST_REQUEST = "GET_MY_TASK_LIST_REQUEST";
export const GET_MY_TASK_LIST_SUCCESS = "GET_MY_TASK_LIST_SUCCESS";
export const GET_MY_TASK_LIST_RESET = "GET_MY_TASK_LIST_RESET";
export const GET_MY_TASK_LIST_FAILURE = "GET_MY_TASK_LIST_FAILURE";

// INVOICE TASK- getUserToDoTask

export const GET_INVOICE_TASK_USERDROPDOWN_REQUEST = "GET_INVOICE_TASK_USERDROPDOWN_REQUEST";
export const GET_INVOICE_TASK_USERDROPDOWN_SUCCESS = "GET_INVOICE_TASK_USERDROPDOWN_SUCCESS";
export const GET_INVOICE_TASK_USERDROPDOWN_RESET = "GET_INVOICE_TASK_USERDROPDOWN_RESET";
export const GET_INVOICE_TASK_USERDROPDOWN_FAILURE = "GET_INVOICE_TASK_USERDROPDOWN_FAILURE";

//InvoiceTask-createTask

export const Add_INVOICE_TODO_CREATE_TASK_REQUEST = "Add_INVOICE_TODO_CREATE_TASK_REQUEST";
export const Add_INVOICE_TODO_CREATE_TASK_SUCCESS = "Add_INVOICE_TODO_CREATE_TASK_SUCCESS";
export const Add_INVOICE_TODO_CREATE_TASK_RESET = "Add_INVOICE_TODO_CREATE_TASK_RESET";
export const Add_INVOICE_TODO_CREATE_TASK_FAILURE = "Add_INVOICE_TODO_CREATE_TASK_FAILURE";
//my task list

export const GET_TASK_REQUEST = "GET_TASK_REQUEST";
export const GET_TASK_SUCCESS = "GET_TASK_SUCCESS";
export const GET_TASK_RESET = "GET_TASK_LIST_RESET";
export const GET_TASK_FAILURE = "GET_TASK_FAILURE";

//post comments

export const POST_COMMENT_REQUEST = "POST_COMMENT_REQUEST";
export const POST_COMMENT_SUCCESS = "POST_COMMENT_SUCCESS";
export const POST_COMMENT_RESET = "POST_COMMENT_RESET";
export const POST_COMMENT_FAILURE = "POST_COMMENT_FAILURE";

//complete task

export const POST_COMPLETE_TASK_REQUEST = "POST_COMPLETE_TASK_REQUEST";
export const POST_COMPLETE_TASK_SUCCESS = "POST_COMPLETE_TASK_SUCCESS";
export const POST_COMPLETE_TASK_RESET = "POST_COMPLETE_TASK_RESET";
export const POST_COMPLETE_TASK_FAILURE = "POST_COMPLETE_TASK_FAILURE";

//getGeneralLedgerSync

export const GET_GENERAL_LEDGER_SYNC_LIST_REQUEST = "GET_GENERAL_LEDGER_SYNC_LIST_REQUEST";
export const GET_GENERAL_LEDGER_SYNC_LIST_SUCCESS = "GET_GENERAL_LEDGER_SYNC_LIST_SUCCESS";
export const GET_GENERAL_LEDGER_SYNC_LIST_RESET = "GET_GENERAL_LEDGER_SYNC_LIST_RESET";
export const GET_GENERAL_LEDGER_SYNC_LIST_FAILURE = "GET_GENERAL_LEDGER_SYNC_LIST_FAILURE";

//get general ledger account sync

export const GET_GL_ACCOUNT_SYNC_REQUEST = "GET_GL_ACCOUNT_SYNC_REQUEST";
export const GET_GL_ACCOUNT_SYNC_SUCCESS = "GET_GL_ACCOUNT_SYNC_SUCCESS";
export const GET_GL_ACCOUNT_SYNC_RESET = "GET_GL_ACCOUNT_SYNC_RESET";
export const GET_GL_ACCOUNT_SYNC_FAILURE = "GET_GL_ACCOUNT_SYNC_FAILURE";

//GET COST CENTER ACCOUNT SYNC

export const GET_COST_CENTER_ACCOUNT_SYNC_REQUEST = "GET_COST_CENTER_ACCOUNT_SYNC_REQUEST";
export const GET_COST_CENTER_ACCOUNT_SYNC_SUCCESS = "GET_COST_CENTER_ACCOUNT_SYNC_SUCCESS";
export const GET_COST_CENTER_ACCOUNT_SYNC_RESET = "GET_COST_CENTER_ACCOUNT_SYNC_RESET";
export const GET_COST_CENTER_ACCOUNT_SYNC_FAILURE = "GET_COST_CENTER_ACCOUNT_SYNC_FAILURE";

//GET INVOICE TASK LIST BY INVID

export const GET_INVOICE_TASK_LIST_REQUEST = "GET_INVOICE_TASK_LIST_REQUEST";
export const GET_INVOICE_TASK_LIST_SUCCESS = "GET_INVOICE_TASK_LIST_SUCCESS";
export const GET_INVOICE_TASK_LIST_RESET = "GET_INVOICE_TASK_LIST_RESET";
export const GET_INVOICE_TASK_LIST_FAILURE = "GET_INVOICE_TASK_LIST_FAILURE";

//GET Template List

export const GET_TEMPLATE_LIST_REQUEST = "GET_TEMPLATE_LIST_REQUEST";
export const GET_TEMPLATE_LIST_SUCCESS = "GET_TEMPLATE_LIST_SUCCESS";
export const GET_TEMPLATE_LIST_RESET = "GET_TEMPLATE_LIST_RESET";
export const GET_TEMPLATE_LIST_FAILURE = "GET_TEMPLATE_LIST_FAILURE";

//get inbox settings list

export const GET_INBOX_SETTINGS_REQUEST = "GET_INBOX_SETTINGS_REQUEST";

export const GET_INBOX_SETTINGS_SUCCESS = "GET_INBOX_SETTINGS_SUCCESS";

export const GET_INBOX_SETTINGS_RESET = "GET_INBOX_SETTINGS_RESET";

export const GET_INBOX_SETTINGS_FAILURE = "GET_INBOX_SETTINGS_FAILURE";

//activate deactivate inbox settings

export const ACTIVATE_DEACTIVATE_INBOX_SETTINGS_REQUEST = "ACTIVATE_DEACTIVATE_INBOX_SETTINGS_REQUEST";

export const ACTIVATE_DEACTIVATE_INBOX_SETTINGS_SUCCESS = "ACTIVATE_DEACTIVATE_INBOX_SETTINGS_SUCCESS";

export const ACTIVATE_DEACTIVATE_INBOX_SETTINGS_FAILURE = "ACTIVATE_DEACTIVATE_INBOX_SETTINGS_FAILURE";

export const ACTIVATE_DEACTIVATE_INBOX_SETTINGS_RESET = "ACTIVATE_DEACTIVATE_INBOX_SETTINGS_RESET";

//delete inbox settings

export const INBOX_SETTINGS_DELETE_REQUEST = "INBOX_SETTINGS_DELETE_REQUEST";

export const INBOX_SETTINGS_DELETE_SUCCESS = "INBOX_SETTINGS_DELETE_SUCCESS";

export const INBOX_SETTINGS_DELETE_FAILURE = "INBOX_SETTINGS_DELETE_FAILURE";

export const INBOX_SETTINGS_DELETE_RESET = "INBOX_SETTINGS_DELETE_RESET";

// get inbox folder
export const INBOX_FOLDER_GET_REQUEST = "INBOX_FOLDER_GET_REQUEST";
export const INBOX_FOLDER_GET_SUCCESS = "INBOX_FOLDER_GET_SUCCESS";
export const INBOX_FOLDER_GET_FAILURE = "INBOX_FOLDER_GET_FAILURE";
export const INBOX_FOLDER_GET_RESET = "INBOX_FOLDER_GET_RESET";

// get inbox email by folder email
export const INBOX_PREVIEW_GET_REQUEST = "INBOX_PREVIEW_GET_REQUEST";
export const INBOX_PREVIEW_GET_SCROLL_REQUEST = "INBOX_PREVIEW_GET_SCROLL_REQUEST";
export const INBOX_PREVIEW_GET_NORMAL_FAILURE = "INBOX_PREVIEW_GET_NORMAL_FAILURE";
export const INBOX_PREVIEW_GET_SCROLL_SUCCESS = "INBOX_PREVIEW_GET_SCROLL_SUCCESS";
export const INBOX_PREVIEW_GET_SUCCESS = "INBOX_PREVIEW_GET_SUCCESS";
export const INBOX_PREVIEW_GET_FAILURE = "INBOX_PREVIEW_GET_FAILURE";
export const INBOX_PREVIEW_GET_RESET = "INBOX_PREVIEW_GET_RESET";
export const INBOX_ONCLICK_READ_ACTION = "INBOX_ONCLICK_READ_ACTION";
export const INBOX_ONCLICK_READ_CHILD_ACTION = "INBOX_ONCLICK_READ_CHILD_ACTION";

// get inbox child prev email
export const INBOX_CHILD_PREVIEW_GET_REQUEST = "INBOX_CHILD_PREVIEW_GET_REQUEST";
export const INBOX_CHILD_PREVIEW_GET_SUCCESS = "INBOX_CHILD_PREVIEW_GET_SUCCESS";
export const INBOX_CHILD_PREVIEW_GET_FAILURE = "INBOX_CHILD_PREVIEW_GET_FAILURE";
export const INBOX_CHILD_PREVIEW_GET_RESET = "INBOX_CHILD_PREVIEW_GET_RESET";
export const TOGGLE_CHILD_MARK_AS_READ = "TOGGLE_CHILD_MARK_AS_READ";

// get inbox body view email
export const INBOX_BODY_VIEW_REQUEST = "INBOX_BODY_VIEW_REQUEST";
export const INBOX_BODY_VIEW_SUCCESS = "INBOX_BODY_VIEW_SUCCESS";
export const INBOX_BODY_VIEW_FAILURE = "INBOX_BODY_VIEW_FAILURE";
export const INBOX_BODY_VIEW_RESET = "INBOX_BODY_VIEW_RESET";
//get MS contact
export const GET_MS_CONTACT_LIST_REQUEST = "GET_MS_CONTACT_LIST_REQUEST";
export const GET_MS_CONTACT_LIST_SUCCESS = "GET_MS_CONTACT_LIST_SUCCESS";
export const GET_MS_CONTACT_LIST_FAILURE = "GET_MS_CONTACT_LIST_FAILURE";
export const GET_MS_CONTACT_LIST_RESET = "GET_MS_CONTACT_LIST_RESET";

//post inbox email read and unread
export const INBOX_EMAIL_MARK_AS_REQUEST = "INBOX_EMAIL_MARK_AS_REQUEST";
export const INBOX_EMAIL_MARK_AS_SUCCESS = "INBOX_EMAIL_MARK_AS_SUCCESS";
export const INBOX_EMAIL_MARK_AS_FAILURE = "INBOX_EMAIL_MARK_AS_FAILURE";
export const INBOX_EMAIL_MARK_AS_RESET = "INBOX_EMAIL_MARK_AS_RESET";
export const TOGGLE_MARK_AS_READ = "TOGGLE_MARK_AS_READ";

//Send Mail MS
export const POST_SEND_MS_MAIL_REQUEST = "POST_SEND_MS_MAIL_REQUEST";
export const POST_SEND_MS_MAIL_SUCCESS = "POST_SEND_MS_MAIL_SUCCESS";
export const POST_SEND_MS_MAIL_FAILURE = "POST_SEND_MS_MAIL_FAILURE";
export const POST_SEND_MS_MAIL_RESET = "POST_SEND_MS_MAIL_RESET";

//Attachment preview Mail MS
export const GET_ATTACHMENT_PREVIEW_REQUEST = "GET_ATTACHMENT_PREVIEW_REQUEST";
export const GET_ATTACHMENT_PREVIEW_SUCCESS = "GET_ATTACHMENT_PREVIEW_SUCCESS";
export const GET_ATTACHMENT_PREVIEW_FAILURE = "GET_ATTACHMENT_PREVIEW_FAILURE";
export const GET_ATTACHMENT_PREVIEW_RESET = "GET_ATTACHMENT_PREVIEW_RESET";

//delete MS mail

export const DELETE_MICROSOFT_MAIL_REQUEST = "DELETE_MICROSOFT_MAIL_REQUEST";
export const DELETE_MICROSOFT_MAIL_SUCCESS = "DELETE_MICROSOFT_MAIL_SUCCESS";
export const DELETE_MICROSOFT_MAIL_FAILURE = "DELETE_MICROSOFT_MAIL_FAILURE";
export const DELETE_MICROSOFT_MAIL_RESET = "DELETE_MICROSOFT_MAIL_RESET";

export const DELETE_MICROSOFT_EMAIL_ACTION = "DELETE_MICROSOFT_EMAIL_ACTION";
export const DELETE_MICROSOFT_CHILD_EMAIL_ACTION = "DELETE_MICROSOFT_CHILD_EMAIL_ACTION";
export const COUNT_UPDATE_EMAIL_ACTION = "COUNT_UPDATE_EMAIL_ACTION";

//Attachment preview Mail MS
export const DOWNLOAD_ATTACHMENT_REQUEST = "DOWNLOAD_ATTACHMENT_REQUEST";
export const DOWNLOAD_ATTACHMENT_SUCCESS = "DOWNLOAD_ATTACHMENT_SUCCESS";
export const DOWNLOAD_ATTACHMENT_FAILURE = "DOWNLOAD_ATTACHMENT_FAILURE";
export const DOWNLOAD_ATTACHMENT_RESET = "DOWNLOAD_ATTACHMENT_RESET";

export const GET_FREE_TRAIL_REQUEST = "GET_FREE_TRAIL_REQUEST";
export const GET_FREE_TRAIL_SUCCESS = "GET_FREE_TRAI_SUCCESS";
export const GET_FREE_TRAIL_FAILURE = "GET_FREE_TRAI_FAILURE";
export const GET_FREE_TRAIL_RESET = "GET_FREE_TRAI_RESET";

//get po number list
export const GET_PO_NUMBER_LIST_REQUEST = "GET_PO_NUMBER_LIST_REQUEST";
export const GET_PO_NUMBER_LIST_SUCCESS = "GET_PO_NUMBER_LIST_SUCCESS";
export const GET_PO_NUMBER_LIST_FAILURE = "GET_PO_NUMBER_LIST_FAILURE";
export const GET_PO_NUMBER_LIST_RESET = "GET_PO_NUMBER_LIST_RESET";

//get sap vendor list

export const GET_SAP_VENDOR_LIST_REQUEST = "GET_SAP_VENDOR_LIST_REQUEST";
export const GET_SAP_VENDOR_LIST_SUCCESS = "GET_SAP_VENDOR_LIST_SUCCESS";
export const GET_SAP_VENDOR_LIST_FAILURE = "GET_SAP_VENDOR_LIST_FAILURE";
export const GET_SAP_VENDOR_LIST_RESET = "GET_SAP_VENDOR_LIST_RESET";

// websocket
export const WS_CONNECT = "WS_CONNECT";
export const WS_DISCONNECT = "WS_DISCONNECT";
export const WS_SEND_MESSAGE = "WS_SEND_MESSAGE";
export const WS_CONNECTED = "WS_CONNECTED";
export const WS_DISCONNECTED = "WS_DISCONNECTED";
export const WS_MESSAGE_RECEIVED = "WS_MESSAGE_RECEIVED";

export const PROFILE_DELETE_REQUEST = "PROFILE_DELETE_REQUEST";
export const PROFILE_DELETE_FAILURE = "PROFILE_DELETE_FAILURE";
export const PROFILE_DELETE_SUCCESS = "PROFILE_DELETE_SUCCESS";
export const PROFILE_DELETE_RESET = "PROFILE_DELETE_RESET";

//validate temp Email

export const GET_TEMP_MAIL_VALIDATE_REQUEST = "GET_TEMP_MAIL_VALIDATE_REQUEST";
export const GET_TEMP_MAIL_VALIDATE_FAILURE = "GET_TEMP_MAIL_VALIDATE_FAILURE";
export const GET_TEMP_MAIL_VALIDATE_SUCCESS = "GET_TEMP_MAIL_VALIDATE_SUCCESS";
export const GET_TEMP_MAIL_VALIDATE_RESET = "GET_TEMP_MAIL_VALIDATE_RESET";

export const TRANSFER_INVOICE_REQUEST = "TRANSFER_INVOICE_REQUEST";
export const TRANSFER_INVOICE_FAILURE = "TRANSFER_INVOICE_FAILURE";
export const TRANSFER_INVOICE_SUCCESS = "TRANSFER_INVOICE_SUCCESS";
export const TRANSFER_INVOICE_RESET = "TRANSFER_INVOICE_RESET";

export const ACCOUNT_DELETE_REQUEST = "ACCOUNT_DELETE_REQUEST";
export const ACCOUNT_DELETE_FAILURE = "ACCOUNT_DELETE_FAILURE";
export const ACCOUNT_DELETE_SUCCESS = "ACCOUNT_DELETE_SUCCESS";
export const ACCOUNT_DELETE_RESET = "ACCOUNT_DELETE_RESET";
//GET SAP FINANCIAL YEAR

export const GET_SAP_FINANCIAL_YEAR_REQUEST = "GET_SAP_FINANCIAL_YEAR_REQUEST";
export const GET_SAP_FINANCIAL_YEAR_FAILURE = "GET_SAP_FINANCIAL_YEAR_FAILURE";
export const GET_SAP_FINANCIAL_YEAR_SUCCESS = "GET_SAP_FINANCIAL_YEAR_SUCCESS";
export const GET_SAP_FINANCIAL_YEAR_RESET = "GET_SAP_FINANCIAL_YEAR_RESET";

export const BACKUP_DATA_REQUEST = "BACKUP_DATA_REQUEST";
export const BACKUP_DATA_FAILURE = "BACKUP_DATA_FAILURE";
export const BACKUP_DATA_SUCCESS = "BACKUP_DATA_SUCCESS";
export const BACKUP_DATA_RESET = "BACKUP_DATA_RESET";
//GET_TASK_DASHBOARD

export const GET_TASK_DASHBOARD_REQUEST = "GET_TASK_DASHBOARD_REQUEST";
export const GET_TASK_DASHBOARD_FAILURE = "GET_TASK_DASHBOARD_FAILURE";
export const GET_TASK_DASHBOARD_SUCCESS = "GET_TASK_DASHBOARD_SUCCESS";
export const GET_TASK_DASHBOARD_RESET = "GET_TASK_DASHBOARD_RESET";

//get task report list

export const GET_TASK_REPORT_LIST_REQUEST = "GET_TASK_REPORT_LIST_REQUEST";
export const GET_TASK_REPORT_LIST_FAILURE = "GET_TASK_REPORT_LIST_FAILURE";
export const GET_TASK_REPORT_LIST_SUCCESS = "GET_TASK_REPORT_LIST_SUCCESS";
export const GET_TASK_REPORT_LIST_RESET = "GET_TASK_REPORT_LIST_RESET";

//GET TASK REPORT COUNT

export const GET_TASK_REPORT_COUNT_REQUEST = "GET_TASK_REPORT_COUNT_REQUEST";
export const GET_TASK_REPORT_COUNT_FAILURE = "GET_TASK_REPORT_COUNT_FAILURE";
export const GET_TASK_REPORT_COUNT_SUCCESS = "GET_TASK_REPORT_COUNT_SUCCESS";
export const GET_TASK_REPORT_COUNT_RESET = "GET_TASK_REPORT_COUNT_RESET";

//GET TASK REPORT CREATED_BY USERS

export const GET_TASK_REPORTS_CREATEDBYUSER_REQUEST = "GET_TASK_REPORTS_CREATEDBYUSER_REQUEST";
export const GET_TASK_REPORTS_CREATEDBYUSER_FAILURE = "GET_TASK_REPORTS_CREATEDBYUSER_FAILURE";
export const GET_TASK_REPORTS_CREATEDBYUSER_SUCCESS = "GET_TASK_REPORTS_CREATEDBYUSER_SUCCESS";
export const GET_TASK_REPORTS_CREATEDBYUSER_RESET = "GET_TASK_REPORTS_CREATEDBYUSER_RESET";

// Get Billing summary

export const GET_LICENSE_SUMMARY_REQUEST = "GET_LICENSE_SUMMARY_REQUEST";
export const GET_LICENSE_SUMMARY_FAILURE = "GET_LICENSE_SUMMARY_FAILURE";
export const GET_LICENSE_SUMMARY_SUCCESS = "GET_LICENSE_SUMMARY_SUCCESS";
export const GET_LICENSE_SUMMARY_RESET = "GET_LICENSE_SUMMARY_RESET";

// Get License Usage Summary

export const GET_LICENSE_USAGE_REQUEST = "GET_LICENSE_USAGE_REQUEST";
export const GET_LICENSE_USAGE_FAILURE = "GET_LICENSE_USAGE_FAILURE";
export const GET_LICENSE_USAGE_SUCCESS = "GET_LICENSE_USAGE_SUCCESS";
export const GET_LICENSE_USAGE_RESET = "GET_LICENSE_USAGE_RESET";
//GET_LICENSE_REPORT_LIST

export const GET_LICENSE_REPORT_LIST_REQUEST = "GET_LICENSE_REPORT_LIST_REQUEST";
export const GET_LICENSE_REPORT_LIST_FAILURE = "GET_LICENSE_REPORT_LIST_FAILURE";
export const GET_LICENSE_REPORT_LIST_SUCCESS = "GET_LICENSE_REPORT_LIST_SUCCESS";
export const GET_LICENSE_REPORT_LIST_RESET = "GET_LICENSE_REPORT_LIST_RESET";

//GET_LICENSE_REPORT_COUNT

export const GET_LICENSE_REPORT_COUNT_REQUEST = "GET_LICENSE_REPORT_COUNT_REQUEST";
export const GET_LICENSE_REPORT_COUNT_FAILURE = "GET_LICENSE_REPORT_COUNT_FAILURE";
export const GET_LICENSE_REPORT_COUNT_SUCCESS = "GET_LICENSE_REPORT_COUNT_SUCCESS";
export const GET_LICENSE_REPORT_COUNT_RESET = "GET_LICENSE_REPORT_COUNT_RESET";

//SIGNUP_COUNTRY_LIST

export const GET_LIST_OF_CITIES_REQUEST = "GET_LIST_OF_CITIES_REQUEST";
export const GET_LIST_OF_CITIES_FAILURE = "GET_LIST_OF_CITIES_FAILURE";
export const GET_LIST_OF_CITIES_SUCCESS = "GET_LIST_OF_CITIES_SUCCESS";
export const GET_LIST_OF_CITIES_RESET = "GET_LIST_OF_CITIES_RESET";

//View Task Attchments

export const GET_VIEW_TASK_ATTACHMENT_REQUEST = "GET_VIEW_TASK_ATTACHMENT_REQUEST";
export const GET_VIEW_TASK_ATTACHMENT_FAILURE = "GET_VIEW_TASK_ATTACHMENT_FAILURE";
export const GET_VIEW_TASK_ATTACHMENT_SUCCESS = "GET_VIEW_TASK_ATTACHMENT_SUCCESS";
export const GET_VIEW_TASK_ATTACHMENT_RESET = "GET_VIEW_TASK_ATTACHMENT_RESET";

//View Task Attchments

export const GET_REJECT_INVOICE_REQUEST = "GET_REJECT_INVOICE_REQUEST";
export const GET_REJECT_INVOICE_FAILURE = "GET_REJECT_INVOICE_FAILURE";
export const GET_REJECT_INVOICE_SUCCESS = "GET_REJECT_INVOICE_SUCCESS";
export const GET_REJECT_INVOICE_RESET = "GET_REJECT_INVOICE_RESET";

//export inv list data

export const EXPORT_INVOICE_LIST_REQUEST = "EXPORT_INVOICE_LIST_REQUEST";
export const EXPORT_INVOICE_LIST_FAILURE = "EXPORT_INVOICE_LIST_FAILURE";
export const EXPORT_INVOICE_LIST_SUCCESS = "EXPORT_INVOICE_LIST_SUCCESS";
export const EXPORT_INVOICE_LIST_RESET = "EXPORT_INVOICE_LIST_RESET";

//export invoice reports
export const EXPORT_INVOICE_REPORTS_REQUEST = "EXPORT_INVOICE_REPORTS_REQUEST";
export const EXPORT_INVOICE_REPORTS_FAILURE = "EXPORT_INVOICE_REPORTS_FAILURE";
export const EXPORT_INVOICE_REPORTS_SUCCESS = "EXPORT_INVOICE_REPORTS_SUCCESS";
export const EXPORT_INVOICE_REPORTS_RESET = "EXPORT_INVOICE_REPORTS_RESET";

//export ageing reports
export const EXPORT_AGEING_REPORTS_REQUEST = "EXPORT_AGEING_REPORTS_REQUEST";
export const EXPORT_AGEING_REPORTS_FAILURE = "EXPORT_AGEING_REPORTS_FAILURE";
export const EXPORT_AGEING_REPORTS_SUCCESS = "EXPORT_AGEING_REPORTS_SUCCESS";
export const EXPORT_AGEING_REPORTS_RESET = "EXPORT_AGEING_REPORTS_RESET";

//export task reports
export const EXPORT_TASK_REPORTS_REQUEST = "EXPORT_TASK_REPORTS_REQUEST";
export const EXPORT_TASK_REPORTS_FAILURE = "EXPORT_TASK_REPORTS_FAILURE";
export const EXPORT_TASK_REPORTS_SUCCESS = "EXPORT_TASK_REPORTS_SUCCESS";
export const EXPORT_TASK_REPORTS_RESET = "EXPORT_TASK_REPORTS_RESET";

//export billing table
export const EXPORT_BILLING_REPORTS_REQUEST = "EXPORT_BILLING_REPORTS_REQUEST";
export const EXPORT_BILLING_REPORTS_FAILURE = "EXPORT_BILLING_REPORTS_FAILURE";
export const EXPORT_BILLING_REPORTS_SUCCESS = "EXPORT_BILLING_REPORTS_SUCCESS";
export const EXPORT_BILLING_REPORTS_RESET = "EXPORT_BILLING_REPORTS_RESET";

//Mail Send  billing
export const SEND_BILLING_MAIL_REQUEST = "SEND_BILLING_MAIL_REQUEST";
export const SEND_BILLING_MAIL_FAILURE = "SEND_BILLING_MAIL_FAILURE";
export const SEND_BILLING_MAIL_SUCCESS = "SEND_BILLING_MAIL_SUCCESS";
export const SEND_BILLING_MAIL_RESET = "SEND_BILLING_MAIL_RESET";

//validate ERP
export const ERP_VALIDATIONBYMATCHING_REQUEST = "ERP_VALIDATIONBYMATCHING_REQUEST";
export const ERP_VALIDATIONBYMATCHING_FAILURE = "ERP_VALIDATIONBYMATCHING_FAILURE";
export const ERP_VALIDATIONBYMATCHING_SUCCESS = "ERP_VALIDATIONBYMATCHING_SUCCESS";
export const ERP_VALIDATIONBYMATCHING_RESET = "ERP_VALIDATIONBYMATCHING_RESET";
export const ERP_VALIDATIONBYMATCHING_UPDATE = "ERP_VALIDATIONBYMATCHING_UPDATE";
//Month Closing
export const GET_MONTH_CLOSING_REQUEST = "GET_MONTH_CLOSING_REQUEST";
export const GET_MONTH_CLOSING_SUCCESS = "GET_MONTH_CLOSING_SUCCESS";
export const GET_MONTH_CLOSING_RESET = "GET_MONTH_CLOSING_RESET";
export const GET_MONTH_CLOSING_FAILURE = "GET_MONTH_CLOSING_FAILURE";

export const POST_MONTH_CLOSING_REQUEST = "POST_MONTH_CLOSING_REQUEST";
export const POST_MONTH_CLOSING_FAILURE = "POST_MONTH_CLOSING_FAILURE";
export const POST_MONTH_CLOSING_SUCCESS = "POST_MONTH_CLOSING_SUCCESS";
export const POST_MONTH_CLOSING_RESET = "POST_MONTH_CLOSING_RESET";
//Chatbot post
export const SEND_CHATBOT_REQUEST = "SEND_CHATBOT_REQUEST";
export const SEND_CHATBOT_FAILURE = "SEND_CHATBOT_FAILURE";
export const SEND_CHATBOT_SUCCESS = "SEND_CHATBOT_SUCCESS";
export const SEND_CHATBOT_RESET = "SEND_CHATBOT_RESET";
export const SEND_CHATBOT_TEXT_SUCCESS = "SEND_CHATBOT_TEXT_SUCCESS";

//layout post
export const SEND_LAYOUT_REQUEST = "SEND_LAYOUT_REQUEST";
export const SEND_LAYOUT_FAILURE = "SEND_LAYOUT_FAILURE";
export const SEND_LAYOUT_SUCCESS = "SEND_LAYOUT_SUCCESS";
export const SEND_LAYOUT_RESET = "SEND_LAYOUT_RESET";

export const GET_ACCRUAL_VALIDATE_FAILURE = "GET_ACCRUAL_VALIDATE_FAILURE";
export const GET_ACCRUAL_VALIDATE_SUCCESS = "GET_ACCRUAL_VALIDATE_SUCCESS";
export const GET_ACCRUAL_VALIDATE_RESET = "GET_ACCRUAL_VALIDATE_RESET";
export const GET_ACCRUAL_VALIDATE_REQUEST = "GET_ACCRUAL_VALIDATE_REQUEST";

//layoutCountry

export const GET_LAYOUT_COUNTRYBYORG_REQUEST = "GET_LAYOUT_COUNTRYBYORG_REQUEST";
export const GET_LAYOUT_COUNTRYBYORG_SUCCESS = "GET_LAYOUT_COUNTRYBYORG_SUCCESS";
export const GET_LAYOUT_COUNTRYBYORG_RESET = "GET_LAYOUT_COUNTRYBYORG_RESET";
export const GET_LAYOUT_COUNTRYBYORG_FAILURE = "GET_LAYOUT_COUNTRYBYORG_FAILURE";
export const GET_ON_TIME_PROCESS_FAILURE = "GET_ON_TIME_PROCESS_FAILURE";
export const GET_ON_TIME_PROCESS_SUCCESS = "GET_ON_TIME_PROCESS_SUCCESS";
export const GET_ON_TIME_PROCESS_RESET = "GET_ON_TIME_PROCESS_RESET";
export const GET_ON_TIME_PROCESS_REQUEST = "GET_ON_TIME_PROCESS_REQUEST";

export const POST_ON_TIME_PROCESS_FAILURE = "POST_ON_TIME_PROCESS_FAILURE";
export const POST_ON_TIME_PROCESS_SUCCESS = "POST_ON_TIME_PROCESS_SUCCESS";
export const POST_ON_TIME_PROCESS_RESET = "POST_ON_TIME_PROCESS_RESET";
export const POST_ON_TIME_PROCESS_REQUEST = "POST_ON_TIME_PROCESS_REQUEST";

//INTERNAL ORDER ACCOUNT SYNC

export const GET_INTERNAL_ORDER_ACCOUNT_SYNC_REQUEST = "GET_INTERNAL_ORDER_ACCOUNT_SYNC_REQUEST";
export const GET_INTERNAL_ORDER_ACCOUNT_SYNC_SUCCESS = "GET_INTERNAL_ORDER_ACCOUNT_SYNC_SUCCESS";
export const GET_INTERNAL_ORDER_ACCOUNT_SYNC_RESET = "GET_INTERNAL_ORDER_ACCOUNT_SYNC_RESET";
export const GET_INTERNAL_ORDER_ACCOUNT_SYNC_FAILURE = "GET_INTERNAL_ORDER_ACCOUNT_SYNC_FAILURE";

// output management

export const GET_COLUMN_REQUEST = "GET_COLUMN_REQUEST";
export const GET_COLUMN_SUCCESS = "GET_COLUMN_SUCCESS";
export const GET_COLUMN_RESET = "GET_COLUMN_RESET";
export const GET_COLUMN_FAILURE = "GET_COLUMN_FAILURE";
export const GET_COLUMN_UPDATE = "GET_COLUMN_UPDATE";
export const GET_COLUMN_LOAD_UPDATE = "GET_COLUMN_LOAD_UPDATE";

export const GET_CUSTOM_REPORTS_LABEL_REQUEST = "GET_CUSTOM_REPORTS_LABEL_REQUEST";
export const GET_CUSTOM_REPORTS_LABEL_SUCCESS = "GET_CUSTOM_REPORTS_LABEL_SUCCESS";
export const GET_CUSTOM_REPORTS_LABEL_RESET = "GET_CUSTOM_REPORTS_LABEL_RESET";
export const GET_CUSTOM_REPORTS_LABEL_FAILURE = "GET_CUSTOM_REPORTS_LABEL_FAILURE";

export const CUSTOM_REPORTS_LIST_REQUEST = "CUSTOM_REPORTS_LIST_REQUEST";
export const CUSTOM_REPORTS_LIST_SUCCESS = "CUSTOM_REPORTS_LIST_SUCCESS";
export const CUSTOM_REPORTS_LIST_RESET = "CUSTOM_REPORTS_LIST_RESET";
export const CUSTOM_REPORTS_LIST_FAILURE = "CUSTOM_REPORTS_LIST_FAILURE";

export const GET_CUSTOM_REPORTS_CONFIGLIST_REQUEST = "GET_CUSTOM_REPORTS_CONFIGLIST_REQUEST";
export const GET_CUSTOM_REPORTS_CONFIGLIST_SUCCESS = "GET_CUSTOM_REPORTS_CONFIGLIST_SUCCESS";
export const GET_CUSTOM_REPORTS_CONFIGLIST_RESET = "GET_CUSTOM_REPORTS_CONFIGLIST_RESET";
export const GET_CUSTOM_REPORTS_CONFIGLIST_FAILURE = "GET_CUSTOM_REPORTS_CONFIGLIST_FAILURE";

export const GET_CUSTOM_REPORTS_DELETE_REQUEST = "GET_CUSTOM_REPORTS_DELETE_REQUEST";
export const GET_CUSTOM_REPORTS_DELETE_SUCCESS = "GET_CUSTOM_REPORTS_DELETE_SUCCESS";
export const GET_CUSTOM_REPORTS_DELETE_RESET = "GET_CUSTOM_REPORTS_DELETE_RESET";
export const GET_CUSTOM_REPORTS_DELETE_FAILURE = "GET_CUSTOM_REPORTS_DELETE_FAILURE";

export const GET_CUSTOM_REPORTS_PUBLISH_UNPUBLISH_REQUEST = "GET_CUSTOM_REPORTS_PUBLISH_UNPUBLISH_REQUEST";
export const GET_CUSTOM_REPORTS_PUBLISH_UNPUBLISH_SUCCESS = "GET_CUSTOM_REPORTS_PUBLISH_UNPUBLISH_SUCCESS";
export const GET_CUSTOM_REPORTS_PUBLISH_UNPUBLISH_RESET = "GET_CUSTOM_REPORTS_PUBLISH_UNPUBLISH_RESET";
export const GET_CUSTOM_REPORTS_PUBLISH_UNPUBLISH_FAILURE = "GET_CUSTOM_REPORTS_PUBLISH_UNPUBLISH_FAILURE";

//fillServiceLineItems

export const POST_SERVICE_LINE_ITEMS_REQUEST = "POST_SERVICE_LINE_ITEMS_REQUEST";
export const POST_SERVICE_LINE_ITEMS_SUCCESS = "POST_SERVICE_LINE_ITEMS_SUCCESS";
export const POST_SERVICE_LINE_ITEMS_RESET = "POST_SERVICE_LINE_ITEMS_RESET";
export const POST_SERVICE_LINE_ITEMS_FAILURE = "POST_SERVICE_LINE_ITEMS_FAILURE";
export const POST_SERVICE_LINE_ITEMS_UPDATE = "POST_SERVICE_LINE_ITEMS_UPDATE";

//master filter dropdown

export const POST_FILTER_DROPDOWN_REQUEST = "POST_FILTER_DROPDOWN_REQUEST";
export const POST_FILTER_DROPDOWN_SUCCESS = "POST_FILTER_DROPDOWN_SUCCESS";
export const POST_FILTER_DROPDOWN_RESET = "POST_FILTER_DROPDOWN_RESET";
export const POST_FILTER_DROPDOWN_FAILURE = "POST_FILTER_DROPDOWN_FAILURE";

//master filter dropdown

export const GET_PREVIEW_DATA_REQUEST = "GET_PREVIEW_DATA_REQUEST";
export const GET_PREVIEW_DATA_SUCCESS = "GET_PREVIEW_DATA_SUCCESS";
export const GET_PREVIEW_DATA_RESET = "GET_PREVIEW_DATA_RESET";
export const GET_PREVIEW_DATA_FAILURE = "GET_PREVIEW_DATA_FAILURE";
//GET PO TYPE
export const GET_PO_TYPE_REQUEST = "GET_PO_TYPE_REQUEST";
export const GET_PO_TYPE_SUCCESS = "GET_PO_TYPE_SUCCESS";
export const GET_PO_TYPE_FAILURE = "GET_PO_TYPE_FAILURE";
export const GET_PO_TYPE_RESET = "GET_PO_TYPE_RESET";

export const POST_TABLER_DATA_REQUEST = "POST_TABLER_DATA_REQUEST";
export const POST_TABLER_DATA_SUCCESS = "POST_TABLER_DATA_SUCCESS";
export const POST_TABLER_DATA_RESET = "POST_TABLER_DATA_RESET";
export const POST_TABLER_DATA_FAILURE = "POST_TABLER_DATA_FAILURE";

export const GET_PREVIEW_TABLER_REQUEST = "GET_PREVIEW_TABLER_REQUEST";
export const GET_PREVIEW_TABLER_SUCCESS = "GET_PREVIEW_TABLER_SUCCESS";
export const GET_PREVIEW_TABLER_RESET = "GET_PREVIEW_TABLER_RESET";
export const GET_PREVIEW_TABLER_FAILURE = "GET_PREVIEW_TABLER_FAILURE";

export const UPDATE_TABLER_DATA_REQUEST = "UPDATE_TABLER_DATA_REQUEST";
export const UPDATE_TABLER_DATA_SUCCESS = "UPDATE_TABLER_DATA_SUCCESS";
export const UPDATE_TABLER_DATA_RESET = "UPDATE_TABLER_DATA_RESET";
export const UPDATE_TABLER_DATA_FAILURE = "UPDATE_TABLER_DATA_FAILURE";

//TAX MASTER ACCOUNT SYNC

export const GET_TAX_MASTER_SYNC_REQUEST = "GET_TAX_MASTER_SYNC_REQUEST";
export const GET_TAX_MASTER_SYNC_SUCCESS = "GET_TAX_MASTER_SYNC_SUCCESS";
export const GET_TAX_MASTER_SYNC_RESET = "GET_TAX_MASTER_SYNC_RESET";
export const GET_TAX_MASTER_SYNC_FAILURE = "GET_TAX_MASTER_SYNC_FAILURE";

//TAX MASTER LIST

export const GET_TAX_CODE_LIST_REQUEST = "GET_TAX_CODE_LIST_REQUEST";
export const GET_TAX_CODE_LIST_SUCCESS = "GET_TAX_CODE_LIST_SUCCESS";
export const GET_TAX_CODE_LIST_RESET = "GET_TAX_CODE_LIST_RESET";
export const GET_TAX_CODE_LIST_FAILURE = "GET_TAX_CODE_LIST_FAILURE";

//delete Status on work flow automation
export const WORKFLOW_STATUS_DELETE_REQUEST = "WORKFLOW_STATUS_DELETE_REQUEST";
export const WORKFLOW_STATUS_DELETE_SUCCESS = "WORKFLOW_STATUS_DELETE_SUCCESS";
export const WORKFLOW_STATUS_DELETE_RESET = "WORKFLOW_STATUS_DELETE_RESET";
export const WORKFLOW_STATUS_DELETE_FAILURE = "WORKFLOW_STATUS_DELETE_FAILURE";

export const GET_REPORT_DROPDOWN_REQUEST = "GET_REPORT_DROPDOWN_REQUEST";
export const GET_REPORT_DROPDOWN_SUCCESS = "GET_REPORT_DROPDOWN_SUCCESS";
export const GET_REPORT_DROPDOWN_RESET = "GET_REPORT_DROPDOWN_RESET";
export const GET_REPORT_DROPDOWN_FAILURE = "GET_REPORT_DROPDOWN_FAILURE";
//get the time on time processing by org
export const GET_TIME_FOR_TIMEPROCESSING_BYORG_REQUEST = "GET_TIME_FOR_TIMEPROCESSING_BYORG_REQUEST";
export const GET_TIME_FOR_TIMEPROCESSING_BYORG_SUCCESS = "GET_TIME_FOR_TIMEPROCESSING_BYORG_SUCCESS";
export const GET_TIME_FOR_TIMEPROCESSING_BYORG_RESET = "GET_TIME_FOR_TIMEPROCESSING_BYORG_RESET";
export const GET_TIME_FOR_TIMEPROCESSING_BYORG_FAILURE = "GET_TIME_FOR_TIMEPROCESSING_BYORG_FAILURE";

//get work flow automation by org
export const GET_WORKFLOW_DATA_REQUEST = "GET_WORKFLOW_DATA_REQUEST";
export const GET_WORKFLOW_DATA_SUCCESS = "GET_WORKFLOW_DATA_SUCCESS";
export const GET_WORKFLOW_DATA_RESET = "GET_WORKFLOW_DATA_RESET";
export const GET_WORKFLOW_DATA_FAILURE = "GET_WORKFLOW_DATA_FAILURE";

//get workflow data layout template by organisation
export const GET_WORKFLOW_PERMISSIONLAYOUT_REQUEST = "GET_WORKFLOW_PERMISSIONLAYOUT_REQUEST";
export const GET_WORKFLOW_PERMISSIONLAYOUT_SUCCESS = "GET_WORKFLOW_PERMISSIONLAYOUT_SUCCESS";
export const GET_WORKFLOW_PERMISSIONLAYOUT_RESET = "GET_WORKFLOW_PERMISSIONLAYOUT_RESET";
export const GET_WORKFLOW_PERMISSIONLAYOUT_FAILURE = "GET_WORKFLOW_PERMISSIONLAYOUT_FAILURE";

//addupdate workflow aconfig by organsiastion
export const POST_WORKFLOW_AUTOMATION_BYORG_REQUEST = "POST_WORKFLOW_AUTOMATION_BYORG_REQUEST";
export const POST_WORKFLOW_AUTOMATION_BYORG_SUCCESS = "POST_WORKFLOW_AUTOMATION_BYORG_SUCCESS";
export const POST_WORKFLOW_AUTOMATION_BYORG_RESET = "POST_WORKFLOW_AUTOMATION_BYORG_RESET";
export const POST_WORKFLOW_AUTOMATION_BYORG_FAILURE = "POST_WORKFLOW_AUTOMATION_BYORG_FAILURE";

//* Get Workflow of an invoice id
export const GET_WORKFLOW_DATA_BY_INVOICE_ID_REQUEST = "GET_WORKFLOW_DATA_BY_INVOICE_ID_REQUEST";
export const GET_WORKFLOW_DATA_BY_INVOICE_ID_SUCCESS = "GET_WORKFLOW_DATA_BY_INVOICE_ID_SUCCESS";
export const GET_WORKFLOW_DATA_BY_INVOICE_ID_RESET = "GET_WORKFLOW_DATA_BY_INVOICE_ID_RESET";
export const GET_WORKFLOW_DATA_BY_INVOICE_ID_FAILURE = "GET_WORKFLOW_DATA_BY_INVOICE_ID_FAILURE";

//* Get Workflow Filter
export const GET_WORKFLOW_FILTER_REQUEST = "GET_WORKFLOW_FILTER_REQUEST";
export const GET_WORKFLOW_FILTER_SUCCESS = "GET_WORKFLOW_FILTER_SUCCESS";
export const GET_WORKFLOW_FILTER_RESET = "GET_WORKFLOW_FILTER_RESET";
export const GET_WORKFLOW_FILTER_FAILURE = "GET_WORKFLOW_FILTER_FAILURE";

//* Get Workflow Filter
export const POST_CHANGE_WORKFLOW_STATUS_REQUEST = "POST_CHANGE_WORKFLOW_STATUS_REQUEST";
export const POST_CHANGE_WORKFLOW_STATUS_SUCCESS = "POST_CHANGE_WORKFLOW_STATUS_SUCCESS";
export const POST_CHANGE_WORKFLOW_STATUS_RESET = "POST_CHANGE_WORKFLOW_STATUS_RESET";
export const POST_CHANGE_WORKFLOW_STATUS_FAILURE = "POST_CHANGE_WORKFLOW_STATUS_FAILURE";

// * get Document Type
export const GET_DOCUMENT_TYPE_REQUEST = "GET_DOCUMENT_TYPE_REQUEST";
export const GET_DOCUMENT_TYPE_SUCCESS = "GET_DOCUMENT_TYPE_SUCCESS";
export const GET_DOCUMENT_TYPE_RESET = "GET_DOCUMENT_TYPE_RESET";
export const GET_DOCUMENT_TYPE_FAILURE = "GET_DOCUMENT_TYPE_FAILURE";

// * Post Document Type
export const POST_DOCUMENT_TYPE_REQUEST = "POST_DOCUMENT_TYPE_REQUEST";
export const POST_DOCUMENT_TYPE_SUCCESS = "POST_DOCUMENT_TYPE_SUCCESS";
export const POST_DOCUMENT_TYPE_RESET = "POST_DOCUMENT_TYPE_RESET";
export const POST_DOCUMENT_TYPE_FAILURE = "POST_DOCUMENT_TYPE_FAILURE";
// Get Advance Recovery details for Create
export const GET_ADVANCE_DETAILS_FOR_CREATING_REQUEST = "GET_ADVANCE_DETAILS_FOR_CREATING_REQUEST";
export const GET_ADVANCE_DETAILS_FOR_CREATING_SUCCESS = "GET_ADVANCE_DETAILS_FOR_CREATING_SUCCESS";
export const GET_ADVANCE_DETAILS_FOR_CREATING_RESET = "GET_ADVANCE_DETAILS_FOR_CREATING_RESET";
export const GET_ADVANCE_DETAILS_FOR_CREATING_FAILURE = "GET_ADVANCE_DETAILS_FOR_CREATING_FAILURE";

//GET Advance History for Advance request
export const GET_ADVANCE_HISTORY_LIST_REQUEST = "GET_ADVANCE_HISTORY_LIST_REQUEST";
export const GET_ADVANCE_HISTORY_LIST_SUCCESS = "GET_ADVANCE_HISTORY_LIST_SUCCESS";
export const GET_ADVANCE_HISTORY_LIST_RESET = "GET_ADVANCE_HISTORY_LIST_RESET";
export const GET_ADVANCE_HISTORY_LIST_FAILURE = "GET_ADVANCE_HISTORY_LIST_FAILURE";

//get Document from s3 for advance request
export const GET_DOCUMENT_FROM_S3_FOR_ADVANCE_REQUEST = "GET_DOCUMENT_FROM_S3_FOR_ADVANCE_REQUEST";
export const GET_DOCUMENT_FROM_S3_FOR_ADVANCE_SUCCESS = "GET_DOCUMENT_FROM_S3_FOR_ADVANCE_SUCCESS";
export const GET_DOCUMENT_FROM_S3_FOR_ADVANCE_RESET = "GET_DOCUMENT_FROM_S3_FOR_ADVANCE_RESET";
export const GET_DOCUMENT_FROM_S3_FOR_ADVANCE_FAILURE = "GET_DOCUMENT_FROM_S3_FOR_ADVANCE_FAILURE";

//add update advance request
export const ADD_UPDATE_ADVANCE_TYPE_REQUEST = "ADD_UPDATE_ADVANCE_TYPE_REQUEST";
export const ADD_UPDATE_ADVANCE_TYPE_SUCCESS = "ADD_UPDATE_ADVANCE_TYPE_SUCCESS";
export const ADD_UPDATE_ADVANCE_TYPE_RESET = "ADD_UPDATE_ADVANCE_TYPE_RESET";
export const ADD_UPDATE_ADVANCE_TYPE_FAILURE = "ADD_UPDATE_ADVANCE_TYPE_FAILURE";

// ADD UPDATE COMMENTS
export const ADD_UPDATE_COMMENTS_REQUEST = "ADD_UPDATE_COMMENTS_REQUEST";
export const ADD_UPDATE_COMMENTS_SUCCESS = "ADD_UPDATE_COMMENTS_SUCCESS";
export const ADD_UPDATE_COMMENTS_RESET = "ADD_UPDATE_COMMENTS_RESET";
export const ADD_UPDATE_COMMENTS_FAILURE = "ADD_UPDATE_COMMENTS_FAILURE";
//*Exchange Rate
export const ADD_UPDATE_EXCHANGE_RATE_REQUEST = "ADD_UPDATE_EXCHANGE_RATE_REQUEST";
export const ADD_UPDATE_EXCHANGE_RATE_SUCCESS = "ADD_UPDATE_EXCHANGE_RATE_SUCCESS";
export const ADD_UPDATE_EXCHANGE_RATE_RESET = "ADD_UPDATE_EXCHANGE_RATE_RESET";
export const ADD_UPDATE_EXCHANGE_RATE_FAILURE = "ADD_UPDATE_EXCHANGE_RATE_FAILURE";

export const DELETE_EXCHANGE_RATE_REQUEST = "DELETE_EXCHANGE_RATE_REQUEST";
export const DELETE_EXCHANGE_RATE_SUCCESS = "DELETE_EXCHANGE_RATE_SUCCESS";
export const DELETE_EXCHANGE_RATE_RESET = "DELETE_EXCHANGE_RATE_RESET";
export const DELETE_EXCHANGE_RATE_FAILURE = "DELETE_EXCHANGE_RATE_FAILURE";

export const UPLOAD_EXCHANGE_RATE_REQUEST = "UPLOAD_EXCHANGE_RATE_REQUEST";
export const UPLOAD_EXCHANGE_RATE_SUCCESS = "UPLOAD_EXCHANGE_RATE_SUCCESS";
export const UPLOAD_EXCHANGE_RATE_RESET = "UPLOAD_EXCHANGE_RATE_RESET";
export const UPLOAD_EXCHANGE_RATE_FAILURE = "UPLOAD_EXCHANGE_RATE_FAILURE";

export const LIST_EXCHANGE_RATE_REQUEST = "LIST_EXCHANGE_RATE_REQUEST";
export const LIST_EXCHANGE_RATE_SUCCESS = "LIST_EXCHANGE_RATE_SUCCESS";
export const LIST_EXCHANGE_RATE_RESET = "LIST_EXCHANGE_RATE_RESET";
export const LIST_EXCHANGE_RATE_FAILURE = "LIST_EXCHANGE_RATE_FAILURE";

export const CURRENCY_DROPDOWN_EXCHANGE_RATE_REQUEST = "CURRENCY_DROPDOWN_EXCHANGE_RATE_REQUEST";
export const CURRENCY_DROPDOWN_EXCHANGE_RATE_SUCCESS = "CURRENCY_DROPDOWN_EXCHANGE_RATE_SUCCESS";
export const CURRENCY_DROPDOWN_EXCHANGE_RATE_RESET = "CURRENCY_DROPDOWN_EXCHANGE_RATE_RESET";
export const CURRENCY_DROPDOWN_EXCHANGE_RATE_FAILURE = "CURRENCY_DROPDOWN_EXCHANGE_RATE_FAILURE";

export const CURRENCY_DROPDOWN_BYORG_EXCHANGE_RATE_REQUEST = "CURRENCY_DROPDOWN_BYORG_EXCHANGE_RATE_REQUEST";
export const CURRENCY_DROPDOWN_BYORG_EXCHANGE_RATE_SUCCESS = "CURRENCY_DROPDOWN_BYORG_EXCHANGE_RATE_SUCCESS";
export const CURRENCY_DROPDOWN_BYORG_EXCHANGE_RATE_RESET = "CURRENCY_DROPDOWN_BYORG_EXCHANGE_RATE_RESET";
export const CURRENCY_DROPDOWN_BYORG_EXCHANGE_RATE_FAILURE = "CURRENCY_DROPDOWN_BYORG_EXCHANGE_RATE_FAILURE";

export const HISTORY_EXCHANGE_RATE_REQUEST = "HISTORY_EXCHANGE_RATE_REQUEST";
export const HISTORY_EXCHANGE_RATE_SUCCESS = "HISTORY_EXCHANGE_RATE_SUCCESS";
export const HISTORY_EXCHANGE_RATE_RESET = "HISTORY_EXCHANGE_RATE_RESET";
export const HISTORY_EXCHANGE_RATE_FAILURE = "HISTORY_EXCHANGE_RATE_FAILURE";

// ADVANCE STATUS LIST
export const GET_ADVANCE_STATUS_LIST_REQUEST = "GET_ADVANCE_STATUS_LIST_REQUEST";
export const GET_ADVANCE_STATUS_LIST_SUCCESS = "GET_ADVANCE_STATUS_LIST_SUCCESS";
export const GET_ADVANCE_STATUS_LIST_RESET = "GET_ADVANCE_STATUS_LIST_RESET";
export const GET_ADVANCE_STATUS_LIST_FAILURE = "GET_ADVANCE_STATUS_LIST_FAILURE";

//GET PROFORMA INVOICE VIEW
export const GET_PROFORMA_INVOICE_VIEW_REQUEST = "GET_PROFORMA_INVOICE_VIEW_REQUEST";
export const GET_PROFORMA_INVOICE_VIEW_SUCCESS = "GET_PROFORMA_INVOICE_VIEW_SUCCESS";
export const GET_PROFORMA_INVOICE_VIEW_RESET = "GET_PROFORMA_INVOICE_VIEW_RESET";
export const GET_PROFORMA_INVOICE_VIEW_FAILURE = "GET_PROFORMA_INVOICE_VIEW_FAILURE";

//GET_ADVANCE_REQUEST_VIEW
export const GET_ADVANCE_REQUEST_VIEW_REQUEST = "GET_ADVANCE_REQUEST_VIEW_REQUEST";
export const GET_ADVANCE_REQUEST_VIEW_SUCCESS = "GET_ADVANCE_REQUEST_VIEW_SUCCESS";
export const GET_ADVANCE_REQUEST_VIEW_RESET = "GET_ADVANCE_REQUEST_VIEW_RESET";
export const GET_ADVANCE_REQUEST_VIEW_FAILURE = "GET_ADVANCE_REQUEST_VIEW_FAILURE";

//GET ADVANCE LIST WITH COUNT
export const GET_ADVANCE_LIST_REQUEST = "GET_ADVANCE_LIST_REQUEST";
export const GET_ADVANCE_LIST_SUCCESS = "GET_ADVANCE_LIST_SUCCESS";
export const GET_ADVANCE_LIST_RESET = "GET_ADVANCE_LIST_RESET";
export const GET_ADVANCE_LIST_FAILURE = "GET_ADVANCE_LIST_FAILURE";

//GET_PO_RETENTION_HISTORY
export const GET_PO_RETENTION_HISTORY_REQUEST = "GET_PO_RETENTION_HISTORY_REQUEST";
export const GET_PO_RETENTION_HISTORY_SUCCESS = "GET_PO_RETENTION_HISTORY_SUCCESS";
export const GET_PO_RETENTION_HISTORY_RESET = "GET_PO_RETENTION_HISTORY_RESET";
export const GET_PO_RETENTION_HISTORY_FAILURE = "GET_PO_RETENTION_HISTORY_FAILURE";

//export advance list
export const EXPORT_ADVANCE_REQUEST_LIST_SUCCESS = "EXPORT_ADVANCE_REQUEST_LIST_SUCCESS";
export const EXPORT_ADVANCE_REQUEST_LIST_RESET = "EXPORT_ADVANCE_REQUEST_LIST_RESET";
//GET INVOICE CALCULATION VIEW
export const GET_INVOICE_CALC_VIEW_REQUEST = "GET_INVOICE_CALC_VIEW_REQUEST";
export const GET_INVOICE_CALC_VIEW_SUCCESS = "GET_INVOICE_CALC_VIEW_SUCCESS";
export const GET_INVOICE_CALC_VIEW_UPDATE = "GET_INVOICE_CALC_VIEW_UPDATE";
export const GET_INVOICE_CALC_VIEW_RESET = "GET_INVOICE_CALC_VIEW_RESET";
export const GET_INVOICE_CALC_VIEW_FAILURE = "GET_INVOICE_CALC_VIEW_FAILURE";

//GET INVOICE CALCULATION DETAIL VIEW
export const GET_INVOICE_CALC_DETAIL_VIEW_REQUEST = "GET_INVOICE_CALC_DETAIL_VIEW_REQUEST";
export const GET_INVOICE_CALC_DETAIL_VIEW_SUCCESS = "GET_INVOICE_CALC_DETAIL_VIEW_SUCCESS";
export const GET_INVOICE_CALC_DETAIL_VIEW_RESET = "GET_INVOICE_CALC_DETAIL_VIEW_RESET";
export const GET_INVOICE_CALC_DETAIL_VIEW_FAILURE = "GET_INVOICE_CALC_DETAIL_VIEW_FAILURE";
//GET_RETENTION_RELEASE_VIEW
export const GET_RETENTION_RELEASE_VIEW_REQUEST = "GET_RETENTION_RELEASE_VIEW_REQUEST";
export const GET_RETENTION_RELEASE_VIEW_SUCCESS = "GET_RETENTION_RELEASE_VIEW_SUCCESS";
export const GET_RETENTION_RELEASE_VIEW_RESET = "GET_RETENTION_RELEASE_VIEW_RESET";
export const GET_RETENTION_RELEASE_VIEW_FAILURE = "GET_RETENTION_RELEASE_VIEW_FAILURE";

//POST_RETENTION_RELEASE
export const POST_RETENTION_RELEASE_REQUEST = "POST_RETENTION_RELEASE_REQUEST";
export const POST_RETENTION_RELEASE_SUCCESS = "POST_RETENTION_RELEASE_SUCCESS";
export const POST_RETENTION_RELEASE_RESET = "POST_RETENTION_RELEASE_RESET";
export const POST_RETENTION_RELEASE_FAILURE = "POST_RETENTION_RELEASE_FAILURE";
//miscellaneous

export const GET_MISC_LIST_REQUEST = "GET_MISC_LIST_REQUEST";
export const GET_MISC_LIST_SUCCESS = "GET_MISC_LIST_SUCCESS";
export const GET_MISC_LIST_RESET = "GET_MISC_LIST_RESET";
export const GET_MISC_LIST_FAILURE = "GET_MISC_LIST_FAILURE";

export const ADD_UPDATE_MISC_REQUEST = "ADD_UPDATE_MISC_REQUEST";
export const ADD_UPDATE_MISC_SUCCESS = "ADD_UPDATE_MISC_SUCCESS";
export const ADD_UPDATE_MISC_RESET = "ADD_UPDATE_MISC_RESET";
export const ADD_UPDATE_MISC_FAILURE = "ADD_UPDATE_MISC_FAILURE";

export const VIEW_MISC_REQUEST = "VIEW_MISC_REQUEST";
export const VIEW_MISC_SUCCESS = "VIEW_MISC_SUCCESS";
export const VIEW_MISC_RESET = "VIEW_MISC_RESET";
export const VIEW_MISC_FAILURE = "VIEW_MISC_FAILURE";

export const GET_MISC_PO_LIST_REQUEST = "GET_MISC_PO_LIST_REQUEST";
export const GET_MISC_PO_LIST_SUCCESS = "GET_MISC_PO_LIST_SUCCESS";
export const GET_MISC_PO_LIST_RESET = "GET_MISC_PO_LIST_RESET";
export const GET_MISC_PO_LIST_FAILURE = "GET_MISC_PO_LIST_FAILURE";

export const GET_MISC_VENDOR_LIST_SUCCESS = "GET_MISC_VENDOR_LIST_SUCCESS";
export const GET_MISC_VENDOR_LIST_RESET = "GET_MISC_VENDOR_LIST_RESET";
export const GET_MISC_VENDOR_LIST_FAILURE = "GET_MISC_VENDOR_LIST_FAILURE";
export const GET_MISC_VENDOR_LIST_REQUEST = "GET_MISC_VENDOR_LIST_REQUEST";

export const GET_MISC_WBS_LIST_SUCCESS = "GET_MISC_WBS_LIST_SUCCESS";
export const GET_MISC_WBS_LIST_RESET = "GET_MISC_WBS_LIST_RESET";
export const GET_MISC_WBS_LIST_FAILURE = "GET_MISC_WBS_LIST_FAILURE";
export const GET_MISC_WBS_LIST_REQUEST = "GET_MISC_WBS_LIST_REQUEST";

export const TDS_LIST_SUCCESS = "TDS_LIST_SUCCESS";
export const TDS_LIST_RESET = "TDS_LIST_RESET";
export const TDS_LIST_FAILURE = "TDS_LIST_FAILURE";
export const TDS_LIST_REQUEST = "TDS_LIST_REQUEST";
//WAV - WBS ASSET VENDOR
export const GET_MISC_WAV_SYNC_SUCCESS = "GET_MISC_WAV_SYNC_SUCCESS";
export const GET_MISC_WAV_SYNC_RESET = "GET_MISC_WAV_SYNC_RESET";
export const GET_MISC_WAV_SYNC_FAILURE = "GET_MISC_WAV_SYNC_FAILURE";
export const GET_MISC_WAV_SYNC_REQUEST = "GET_MISC_WAV_SYNC_REQUEST";

//advancePOVendorList
export const GET_PO_AND_VENDOR_FOR_ADVANCE_REQUEST = "GET_PO_AND_VENDOR_FOR_ADVANCE_REQUEST";
export const GET_PO_AND_VENDOR_FOR_ADVANCE_SUCCESS = "GET_PO_AND_VENDOR_FOR_ADVANCE_SUCCESS";
export const GET_PO_AND_VENDOR_FOR_ADVANCE_RESET = "GET_PO_AND_VENDOR_FOR_ADVANCE_RESET";
export const GET_PO_AND_VENDOR_FOR_ADVANCE_FAILURE = "GET_PO_AND_VENDOR_FOR_ADVANCE_FAILURE";

//get userlist for comments

export const GET_USER_LIST_FOR_COMMENNTS_DROPDOWN_REQUEST = "GET_USER_LIST_FOR_COMMENNTS_DROPDOWN_REQUEST";
export const GET_USER_LIST_FOR_COMMENNTS_DROPDOWN_SUCCESS = "GET_USER_LIST_FOR_COMMENNTS_DROPDOWN_SUCCESS";
export const GET_USER_LIST_FOR_COMMENNTS_DROPDOWN_RESET = "GET_USER_LIST_FOR_COMMENNTS_DROPDOWN_RESET";
export const GET_USER_LIST_FOR_COMMENNTS_DROPDOWN_FAILURE = "GET_USER_LIST_FOR_COMMENNTS_DROPDOWN_FAILURE";

//get exchange rate by date

export const GET_EXCHANGE_RATE_BY_DATE_REQUEST = "GET_EXCHANGE_RATE_BY_DATE_REQUEST";
export const GET_EXCHANGE_RATE_BY_DATE_SUCCESS = "GET_EXCHANGE_RATE_BY_DATE_SUCCESS";
export const GET_EXCHANGE_RATE_BY_DATE_RESET = "GET_EXCHANGE_RATE_BY_DATE_RESET";
export const GET_EXCHANGE_RATE_BY_DATE_FAILURE = "GET_EXCHANGE_RATE_BY_DATE_FAILURE";

//get gl indicator list

export const GET_GL_INDICATOR_LIST_REQUEST = "GET_GL_INDICATOR_LIST_REQUEST";
export const GET_GL_INDICATOR_LIST_SUCCESS = "GET_GL_INDICATOR_LIST_SUCCESS";
export const GET_GL_INDICATOR_LIST_RESET = "GET_GL_INDICATOR_LIST_RESET";
export const GET_GL_INDICATOR_LIST_FAILURE = "GET_GL_INDICATOR_LIST_FAILURE";

//status change for advance request
export const UPDATE_STATUS_FOR_ADVANCE_REQUEST = "UPDATE_STATUS_FOR_ADVANCE_REQUEST";
export const UPDATE_STATUS_FOR_ADVANCE_SUCCESS = "UPDATE_STATUS_FOR_ADVANCE_SUCCESS";
export const UPDATE_STATUS_FOR_ADVANCE_RESET = "UPDATE_STATUS_FOR_ADVANCE_RESET";
export const UPDATE_STATUS_FOR_ADVANCE_FAILURE = "UPDATE_STATUS_FOR_ADVANCE_FAILURE";

//GET RETENTION LIST WITH COUNT
export const GET_RETENTION_LIST_REQUEST = "GET_RETENTION_LIST_REQUEST";
export const GET_RETENTION_LIST_SUCCESS = "GET_RETENTION_LIST_SUCCESS";
export const GET_RETENTION_LIST_RESET = "GET_RETENTION_LIST_RESET";
export const GET_RETENTION_LIST_FAILURE = "GET_RETENTION_LIST_FAILURE";

//export RETENTION list
export const EXPORT_RETENTION_REQUEST_LIST_SUCCESS = "EXPORT_RETENTION_REQUEST_LIST_SUCCESS";
export const EXPORT_RETENTION_REQUEST_LIST_RESET = "EXPORT_RETENTION_REQUEST_LIST_RESET";

//Update Proforma invoice view
export const POST_PROFORMA_INVOICE_VIEW_REQUEST = "POST_PROFORMA_INVOICE_VIEW_REQUEST";
export const POST_PROFORMA_INVOICE_VIEW_SUCCESS = "POST_PROFORMA_INVOICE_VIEW_SUCCESS";
export const POST_PROFORMA_INVOICE_VIEW_RESET = "POST_PROFORMA_INVOICE_VIEW_RESET";
export const POST_PROFORMA_INVOICE_VIEW_FAILURE = "POST_PROFORMA_INVOICE_VIEW_FAILURE";

//Advance Request Reports
export const GET_ADVANCE_REPORTS_REQUEST = "GET_ADVANCE_REPORTS_REQUEST";
export const GET_ADVANCE_REPORTS_SUCCESS = "GET_ADVANCE_REPORTS_SUCCESS";
export const GET_ADVANCE_REPORTS_RESET = "GET_ADVANCE_REPORTS_RESET";
export const GET_ADVANCE_REPORTS_FAILURE = "GET_ADVANCE_REPORTS_FAILURE";

//export RETENTION list
export const EXPORT_ADVANCE_REPORT_LIST_SUCCESS = "EXPORT_ADVANCE_REPORT_LIST_SUCCESS";
export const EXPORT_ADVANCE_REPORT_LIST_RESET = "EXPORT_ADVANCE_REPORT_LIST_RESET";

//PO List For Advance Request
export const GET_PO_List_For_Advance_Request = "GET_PO_List_For_Advance_Request";
export const GET_PO_List_For_Advance_SUCCESS = "GET_PO_List_For_Advance_SUCCESS";
export const GET_PO_List_For_Advance_RESET = "GET_PO_List_For_Advance_RESET";
export const GET_PO_List_For_Advance_FAILURE = "GET_PO_List_For_Advance_FAILURE";