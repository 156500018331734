import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { USER_ADD_RESET } from "../../../redux/types/types";
import Button from "../../../components/Button";
import Input from "../../../components/Input";
import UseForm from "../../../Hooks/useForm";
import { userAdd } from "../../../redux/actions/user";
import { validateUserAdd } from "../../../Hooks/validate";
import MessageBox from "../../../components/UI/Alert";
import SuccessMessageBox from "../../../components/UI/Alert/sucess";
import ReactPhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { encryptId } from "../../../utils/helpers";
import { MenuItem, Select } from "@mui/material";
import { useContext } from "react";
import { ThemeContext } from "../../../Hooks/Themes/Themeprovider";
import { getCurrentUser } from "../../../redux/actions/auth";

const successMsg = "User is added successfully";
const errormsg = "Something went wrong please contact admin";
const AddUserModal = (props) => {
    const { currentMode } = useContext(ThemeContext);
    const CurrentUser = getCurrentUser();
    const { handleChange, handleSubmit, values, InitialState, errors, setErrors, setValues, isSubmit, setSubmit } = UseForm(submit, validateUserAdd);
    const dispatch = useDispatch();
    const Createdby = localStorage.getItem("mainid");
    const { profile, fetching, error, requestComplete } = useSelector((state) => state?.userAddState);
    const [phoneNumber, phoneNoChange] = useState();
    function submit() {
        const phonevalue = phoneNumber ? "+" + phoneNumber : phoneNumber;
        setSubmit(true);
        let formdata = new FormData();
        formdata.append("Image", props?.fileName);
        formdata.append(
            "emailaddress",

            encryptId(values?.emailaddress?.toLowerCase())
        );
        formdata.append("mobilenumber", phoneNumber && phoneNumber?.length < 5 ? phoneNumber.slice(5) : phonevalue);
        formdata.append("firstname", values?.firstname?.trim());
        formdata.append("lastname", values?.lastname?.trim());
        formdata.append("isdefault_img", props?.fileName == "" ? true : false);

        // formdata.append("role_id", "");
        formdata.append("country", +values?.country);
        if (values?.firstname !== "" && values?.lastname !== "" && values?.emailaddress !== "" && values?.country !== "") {
            dispatch(userAdd(formdata));
        }
    }
    // let isreassign = props?.isOpened;
    if (requestComplete && profile?.status) {
        setTimeout(function () {
            props.cartClose();
            window.location.reload();
        }, 2000);
    }
    if (requestComplete && !profile?.status) {
        setTimeout(function () {
            dispatch({ type: USER_ADD_RESET });
        }, 2000);
    }

    const cartClose = () => {
        props?.cartClose();
        setErrors({});
        setValues(InitialState);
        phoneNoChange();
    };

    const { country } = useSelector((state) => state?.countryState);
    const { role } = useSelector((state) => state?.roleState);

    // const navigate = useNavigate();
    useEffect(() => {}, [phoneNumber]);

    const phoneNoSelect = (value) => {
        phoneNoChange(value);
        const countrySelect = value ? country?.find((item) => item.country_code.slice(1) === value) : "";
        if (countrySelect) {
            values.country_id = countrySelect?.country_id;
            values.country_name = countrySelect?.country_name;
            values.country = values.country_id;
        }
    };

    let countryObj = values?.country ? country?.find((item) => item.country_id === +values?.country) : "";
    if (values?.firstname == "" && values?.lastname == "" && values?.emailaddress == "" && phoneNumber == undefined && props?.isOpened) {
        var textarea = document?.getElementById("input1");
        textarea?.focus();
    }
    const dropdownColor = currentMode === "dark" ? "#333333" : `white`;
    const dropdownText = currentMode === "dark" ? "#ECECEC" : `#555555`;
    return (
        <>
            {requestComplete && profile?.status && <SuccessMessageBox success={successMsg} />}
            {isSubmit && requestComplete && !profile.status && <MessageBox error={profile.message} />}
            {isSubmit && error && !profile?.status && <MessageBox error={errormsg} />}
            <form onSubmit={handleSubmit} className={`mx-[35px] flex justify-between flex-col h-full`}>
                <div>
                    <div className="flex mb-5 space-x-7 viewStyles">
                        <div className="basis-1/2">
                            <label className="block text-sm pb-3">
                                <span className="text-[red] mr-1">*</span>First Name
                            </label>
                            <Input
                                name="firstname"
                                onChange={handleChange}
                                value={values?.firstname}
                                maxLength={50}
                                onFocus
                                id="input1"
                                placeholder="Enter the first name"
                                className={`border-[1px] border-[rgba(19,14,14,0.3)] rounded-[8px] px-[20px] py-2 w-full bg-${currentMode}-810`}
                            />
                            {errors && <p className="text-light-50 text-xs">{errors?.firstname}</p>}
                        </div>
                        <div className="basis-1/2">
                            <label className="block text-sm pb-3">
                                <span className="text-[red] mr-1">*</span>Last Name
                            </label>
                            <Input
                                name="lastname"
                                onChange={handleChange}
                                value={values?.lastname}
                                maxLength={50}
                                id="input2"
                                placeholder="Enter the last name"
                                className={`border-[1px] border-${currentMode}-840 rounded-[8px] px-[20px] py-2 w-full bg-${currentMode}-810`}
                            />
                            {errors && <p className="text-light-50 text-xs">{errors?.lastname}</p>}
                        </div>
                    </div>
                    <div className="flex mb-5 space-x-7 viewStyles  e-disabled">
                        <div className="basis-1/2">
                            <label className="block text-sm pb-3 ">
                                <span className="text-[red] mr-1">*</span>
                                Business Email
                            </label>
                            <Input
                                placeholder="patrick@email.com"
                                onChange={handleChange}
                                value={values?.emailaddress}
                                maxLength={250}
                                id="input3"
                                name="emailaddress"
                                className={`border-[1px] border-${currentMode}-840 rounded-[8px] px-[20px] py-2 w-full text-transform: lowercase bg-${currentMode}-810`}
                            />
                            {errors && <p className="text-light-50 text-xs">{errors?.emailaddress}</p>}
                        </div>
                        <div className="basis-1/2">
                            <label className="block text-sm pb-3">
                                <span className="text-[red] mr-1">*</span>
                                Country
                            </label>
                            <Select
                                onChange={handleChange}
                                value={values?.country || "Select"}
                                name="country"
                                id="input4"
                                className={`w-full bg-${currentMode}-810 text-${currentMode}-copy`}
                                MenuProps={{
                                    sx: {
                                        "& .MuiMenu-paper": {
                                            backgroundColor: dropdownColor,
                                            color: dropdownText,
                                        },
                                        "& .MuiMenuItem-root:hover": {
                                            backgroundColor: "rgba(218, 249, 244, 0.3)",
                                        },
                                        "& .Mui-selected": {
                                            backgroundColor: "rgba(218, 249, 244, 0.3) !important",
                                        },
                                    },
                                }}
                                SelectDisplayProps={{
                                    style: {
                                        color: `${currentMode !== "dark" ? `#555555` : `#ECECEC`}`,
                                    },
                                }}
                                IconComponent={() => (
                                    <img
                                        className="absolute right-[15px] opacity-[.5] pointer-events-none"
                                        src={currentMode !== "dark" ? `/images/header/chevronDown.svg` : `/images/header/chevronDown-white.svg`}
                                        alt="chevronDown"
                                    />
                                )}
                            >
                                <MenuItem selected value={values?.country_id || "Select"}>
                                    {values?.country_name || "Select"}
                                </MenuItem>
                                {country?.map((elem, index) => {
                                    return (
                                        <MenuItem key={index} value={elem?.country_id}>
                                            {elem?.country_name}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                            {errors && <p className="text-light-50 text-xs">{errors?.country}</p>}
                        </div>
                    </div>
                    <div className="flex mb-5 mr-7 viewStyles">
                        {/* <div className="basis-1/2">
            <label className="block text-sm pb-3">
              <span className="text-[red] mr-1">*</span>Role
            </label>
            <select
              onChange={handleChange}
              value={values?.role_id}
              name="role_id"
              className="border-[1px] border-[rgba(85,85,85,.3)] customDropdown bordereds rounded-[8px] mr-5 pl-[20px] py-3 w-full"
            >
              <option selected value={values?.role_id?.id || ""}>
                {values?.role_id?.role || "Select"}
              </option>
              {role?.map((elem) => {
                return (
                  <option key={elem?.id} value={elem?.id}>
                    {elem?.role}
                  </option>
                );
              })}
            </select>
            {errors && (
              <p className="text-light-50 text-xs">{errors?.role_id}</p>
            )}
          </div> */}
                        <div className={`basis-1/2 `}>
                            <label className="block text-sm pb-3">Mobile Number</label>
                            {props?.isOpened && (
                                <ReactPhoneInput
                                    value={countryObj?.country_code || values?.mobilenumber || CurrentUser?.country_code}
                                    name="mobilenumber"
                                    id="input5"
                                    country={CurrentUser?.country_code == "+1" ? "us" : ""}
                                    className={`bg-${currentMode}-copy text-${currentMode}-copy`}
                                    onChange={(e) => phoneNoSelect(e)}
                                />
                            )}
                            {/* <Input
                name="mobilenumber"
                onChange={handleChange}
                placeholder="Enter your mobile number"
                className="border-[1px] border-[rgba(85,85,85,.3)] rounded-[8px] px-[20px] py-3 w-full"
              />
            errors && (
                <p className="text-light-50 text-xs">{errors?.mobilenumber}</p>
              )} */}
                        </div>
                    </div>
                </div>
                <div
                    className={`border-t-[1px] mx-[-35px] px-[35px] py-5 bg-${currentMode}-card text-${currentMode}-copy border-${currentMode}-590 mt-6`}
                >
                    <div className="space-x-2 flex">
                        <Button
                            disabled={fetching || (requestComplete && profile?.status)}
                            title="To Create the User"
                            type="submit"
                            className="bg-light-20 flex text-base font-interR py-[.5rem] px-[1.5rem] rounded-[8px] text-white"
                        >
                            {`Create`}
                        </Button>

                        <Button
                            type="button"
                            title="To Cancel"
                            onClick={cartClose}
                            className="bg-light-240 flex text-base font-interR py-[.5rem] px-[1.7rem] rounded-[8px] text-light-40 closeModalPop"
                        >
                            Cancel
                        </Button>
                    </div>
                </div>
            </form>
        </>
    );
};

export default AddUserModal;
