import { ClickAwayListener, FormControl, FormControlLabel, MenuItem, Popper, Radio, RadioGroup, Select } from "@mui/material";
import { useFormik } from "formik";
import { memo, useContext, useMemo, useState } from "react";
import { useDrag, useDrop } from "react-dnd";
import ReactDOM from "react-dom";
import { useDispatch } from "react-redux";
import Button from "../../components/Button";
import { ThemeContext } from "../../Hooks/Themes/Themeprovider";
import { FilterModal } from "../../pages/Reports/ScheduledReports/FilterModal";
import { getCurrentUser } from "../../redux/actions/auth";
import { GetFilterDropdown } from "../../redux/actions/output-management";
export const Card = ({ index, id, value, moveCard, findCard, areaName, handleRemove, type }) => {
    const dispatch = useDispatch();
    const { currentMode } = useContext(ThemeContext); //Dark and light mode Custom Hook fetch details!
    const [placement, setPlacement] = useState();
    const [filterItems, setFilterItems] = useState({});
    const [aggricate, setAggricate] = useState([]);
    const [isFilters, setIsFilter] = useState(false);
    const [popstate, setPopState] = useState({
        value: null,
        anchorEl: null,
        id: null,
    });

    const DataType = value?.data_type;
    const formik = useFormik({
        initialValues: {
            Agg: "",
            Sorting: "",
        },
    });
    useMemo(() => {
        if (DataType === "s" || DataType === "dd" || DataType === "b") {
            setAggricate(["Count"]);
        } else if (DataType === "n" || DataType === "m" || DataType === "p") {
            setAggricate(["Sum", "Avg", "Count", "Min.", "Max."]);
        } else if (DataType === "d") {
            setAggricate(["Count", "Min.", "Max."]);
        }
        formik?.setFieldValue("Agg", value?.Agg);
        formik?.setFieldValue("Sorting", value?.Sorting);
        if (value?.Filter?.[0]?.operator != 0 || value?.Filter?.[0]?.val1 !== "" || value?.Filter?.[0]?.val2 !== "") {
            setIsFilter(true);
            dispatch({ type: "CHECK_FILTER_ADDED", payload: true });
        } //This one is if user in edit page filter should activate on view
    }, [value]);

    const [openFilter, setOpenFilter] = useState(false);

    const handleClickAway = () => {
        setPopState({ id: false, anchorEl: false, value: "" });
    };
    const handleClick = (newPlacement, value, event, id = null) => {
        setPopState({ value, anchorEl: event.currentTarget, id }, (prev) => placement !== newPlacement || !prev);
        setPlacement(newPlacement);
    };

    const originalIndex = findCard(id).index;
    const [{ isDragging }, drag] = useDrag(
        () => ({
            type: "card",
            item: { id, originalIndex },
            collect: (monitor) => ({
                isDragging: monitor.isDragging(),
            }),
            end: (item, monitor) => {
                const { id: droppedId, originalIndex } = item;
                const didDrop = monitor.didDrop();
                if (!didDrop) {
                    moveCard(droppedId, originalIndex);
                }
            },
        }),
        [id, originalIndex, moveCard]
    );
    const [, drop] = useDrop(
        () => ({
            accept: "card",
            hover({ id: draggedId }) {
                if (draggedId !== id) {
                    const { index: overIndex } = findCard(id);
                    moveCard(draggedId, overIndex);
                }
            },
        }),
        [findCard, moveCard]
    );
    const opacity = isDragging ? `opacity-0` : `opacity-[1]`;
    const OrgId = localStorage.getItem("orgId");
    const User = getCurrentUser();
    const onOpenFilter = (data) => {
        setOpenFilter(true);
        setFilterItems(data);
        if (DataType === "dd") {
            const ReqObj = {
                orgid: +OrgId,
                userid: User?.id,
                column_name: value?.column_name,
                master_id: +type,
            };
            dispatch(GetFilterDropdown(ReqObj));
        }
    };

    const isfilter = (isFilter) => {
        setIsFilter(isFilter);
        dispatch({ type: "CHECK_FILTER_ADDED", payload: isFilter });
    }; //calback func from child component(FilterModal)

    const DynamicType = () => {
        switch (areaName) {
            case "columns":
                return "UPDATE_COLUMN_AGG";
            case "field":
                return "UPDATE_FIELD_AGG";
            case "label":
                return "UPDATE_LABEL_AGG";
            case "matrixcolumns":
                return "UPDATE_MATRIXCOL_AGG";
            case "rows":
                return "UPDATE_ROWS_AGG";
            case "sorting":
                return "UPDATE_SORTING";
            default:
                return "UPDATE_COLUMN_AGG";
        }
    };
    const _handleClick = (e) => {
        formik?.handleChange(e);
        dispatch({ type: DynamicType(), payload: e?.target?.value, Id: value?.id, label: e?.target?.name });
    };

    let ShowFilter = areaName === "columns" || areaName === "field" || areaName === "label" || areaName === "matrixcolumns" || areaName === "rows";

    return (
        <div
            ref={(node) => drag(drop(node))}
            className={`flex items-center ${
                isDragging ? `grabbed-inner` : `border`
            } cursor-grab space-x-[5px] mr-[10px] mb-[10px] border-${currentMode}-560  py-[5px] shadow-[0px_4px_20px_0px] w-max shadow-${currentMode}-1230 bg-${currentMode}-card rounded-[8px] px-[10px]`}>
            {ShowFilter && (
                <div className={`relative ${isFilters ? "activeFilter" : ""}`}>
                    <img
                        className={`cursor-pointer ${opacity}`}
                        src={isFilters ? `/images/reports/active-filter.svg` : `/images/reports/filter-ico.svg`}
                        onClick={() => onOpenFilter(value)}
                        alt="filter-ico"
                    />
                </div>
            )}
            {areaName === "morefilter" && (
                <div className={`relative ${isFilters ? "activeFilter" : ""}`}>
                    <img
                        className={`cursor-pointer ${opacity}`}
                        src={isFilters ? `/images/reports/active-filter.svg` : `/images/reports/filter-ico.svg`}
                        onClick={() => onOpenFilter(value)}
                        alt="filter-ico"
                    />
                </div>
            )}
            <span className={opacity}>{value?.field_display_name}</span>
            {ShowFilter && (
                <>
                    <span
                        onClick={(e) => handleClick("bottom-end", 1, e)}
                        className={`flex ${opacity} cursor-pointer bg-${currentMode}-1210 px-[6px] rounded-[3px] text-xs py-[1px]`}>
                        {formik?.values?.Agg !== null ? `${formik?.values?.Agg}` : `Apply`}{" "}
                        <img className="ml-[5px]" src="/images/reports/chevron-down-grey.svg" alt="chevron-down-grey" />
                    </span>
                    <ClickAwayListener mouseEvent="onMouseDown" touchEvent="onTouchStart" onClickAway={handleClickAway}>
                        <Popper
                            className={`bg-${currentMode}-card ${opacity} text-${currentMode}-copy  p-4 border-[1px] z-[12] border-${currentMode}-700 shadow-[0px_4px_20px_0px] shadow-${currentMode}-1230 rounded-[10px] reportsPopper `}
                            open={1 === popstate.value}
                            anchorEl={popstate.anchorEl}
                            id={1}
                            placement={placement}>
                            <Button className={`flex justify-between font-interR mb-5`}>
                                <RadioGroup
                                    name="Agg"
                                    value={formik?.values?.Agg}
                                    onChange={_handleClick}
                                    className="flex flex-col item-start text-left ml-1">
                                    <ul>
                                        {aggricate?.map((el, i) => (
                                            <li className={`transition duration-300 ease-in-out mx-[-20px] px-[15px] hover:bg-${currentMode}-960`}>
                                                <FormControlLabel
                                                    key={i}
                                                    value={el?.replace(/\./g, "")}
                                                    control={
                                                        <Radio
                                                            size="small"
                                                            sx={{
                                                                color: "#555555",
                                                                "&.Mui-checked": {
                                                                    color: "#00B399",
                                                                },
                                                            }}
                                                        />
                                                    }
                                                    label={el}
                                                />
                                            </li>
                                        ))}
                                    </ul>
                                </RadioGroup>
                            </Button>
                            <span
                                onClick={() => {
                                    formik?.setFieldValue("Agg", null);
                                    dispatch({ type: DynamicType(), payload: null, Id: value?.id, label: "Agg" });
                                }}
                                title="Clear"
                                className={`text-${currentMode}-10 font-interM cursor-pointer text-[13px] absolute bottom-[12px] left-[15px]`}>
                                Clear
                            </span>
                        </Popper>
                    </ClickAwayListener>
                </>
            )}
            {areaName === "sorting" && (
                <FormControl sx={{ maxWidth: 100 }}>
                    <Select
                        name="Sorting"
                        value={formik?.values?.Sorting || "asc"}
                        fullWidth
                        onChange={_handleClick}
                        className={`bg-${currentMode}-1210 GreenSelect`}
                        IconComponent={() => (
                            <img
                                className="absolute right-[10px] w-[10px] h-[10px] object-contain opacity-50 pointer-events-none"
                                src={currentMode !== "dark" ? `/images/header/chevronDown.svg` : `/images/header/chevronDown-white.svg`}
                                alt="chevronDown"
                            />
                        )}>
                        <MenuItem tabIndex={1} value={"asc"} className="truncate trunc-1 block">
                            Ascending
                        </MenuItem>
                        <MenuItem tabIndex={2} value={"dsc"} className="truncate trunc-1 block">
                            Descending
                        </MenuItem>
                    </Select>
                </FormControl>
            )}
            <span className={`convertWhite cursor-pointer ${opacity}`} onClick={() => handleRemove(value?.id)}>
                <img src="/images/reports/x-close.svg" alt="x-close" />
            </span>
            {ReactDOM.createPortal(
                <FilterModal
                    isOpened={openFilter}
                    areaName={areaName}
                    mode={currentMode}
                    Items={filterItems}
                    handleClose={() => setOpenFilter(false)}
                    className={openFilter && "Show"}
                    addedFilter={isfilter}
                />,
                document.getElementById("user-modal-root")
            )}
        </div>
    );
};
