import axios from "axios";
import configMyiQ from "../../configuration";
import authHeader from "./auth-header";
import localforage from "localforage";
import Cookies from "js-cookie";
import { checkApiStatus } from "../../utils/helpers";
import {
	GET_GENERAL_LEDGER_SYNC_LIST_REQUEST,
	GET_GENERAL_LEDGER_SYNC_LIST_SUCCESS,
	GET_GENERAL_LEDGER_SYNC_LIST_FAILURE,
	GET_GL_ACCOUNT_SYNC_FAILURE,
	GET_GL_ACCOUNT_SYNC_REQUEST,
	GET_GL_ACCOUNT_SYNC_SUCCESS,
	GET_COST_CENTER_ACCOUNT_SYNC_FAILURE,
	GET_COST_CENTER_ACCOUNT_SYNC_REQUEST,
	GET_COST_CENTER_ACCOUNT_SYNC_SUCCESS,
	GET_INTERNAL_ORDER_ACCOUNT_SYNC_REQUEST,
	GET_INTERNAL_ORDER_ACCOUNT_SYNC_RESET,
	GET_INTERNAL_ORDER_ACCOUNT_SYNC_SUCCESS,
	GET_INTERNAL_ORDER_ACCOUNT_SYNC_FAILURE,
	GET_TAX_MASTER_SYNC_FAILURE,
	GET_TAX_MASTER_SYNC_SUCCESS,
	GET_TAX_MASTER_SYNC_REQUEST,
} from "../types/types.js";
const API_URL = process.env?.REACT_APP_NODE_API_URL;
export const GetGeneralLedgerSyncList = () => async (dispatch) => {
	try {
		dispatch({ type: GET_GENERAL_LEDGER_SYNC_LIST_REQUEST });

		axios
			.get(`${API_URL}api/GlCostCentreAccount/getGlSync`, {
				headers: authHeader(),
			})
			.then((response) => {
				dispatch({
					type: GET_GENERAL_LEDGER_SYNC_LIST_SUCCESS,
					payload: response.data.datalist,
				});
			})
			.catch(function (error) {
				if (error?.response?.status == 401) {
					checkApiStatus();
				}
				dispatch({
					payload: { error: error.message },
					type: GET_GENERAL_LEDGER_SYNC_LIST_FAILURE,
				});
			});
	} catch (error) {
		dispatch({
			type: GET_GENERAL_LEDGER_SYNC_LIST_FAILURE,
		});
	}
};

//gl account sync

export const GetGeneralLedgerAccountSync = (id) => async (dispatch) => {
	try {
		dispatch({ type: GET_GL_ACCOUNT_SYNC_REQUEST });

		axios
			.get(`${API_URL}api/getSAPGroupLedgerAccount?orgid=${id}`, {
				headers: authHeader(),
			})
			.then((response) => {
				dispatch({
					type: GET_GL_ACCOUNT_SYNC_SUCCESS,
					payload: response.data,
				});
			})
			.catch(function (error) {
				if (error?.response?.status == 401) {
					checkApiStatus();
				}
				dispatch({
					payload: { error: error.message },
					type: GET_GL_ACCOUNT_SYNC_FAILURE,
				});
			});
	} catch (error) {
		dispatch({
			type: GET_GL_ACCOUNT_SYNC_FAILURE,
		});
	}
};

//cost center account sync
export const GetCostCenterAccountSync = (id) => async (dispatch) => {
	try {
		dispatch({ type: GET_COST_CENTER_ACCOUNT_SYNC_REQUEST });

		axios
			.get(`${API_URL}api/getSAPCostCentreAccount?orgid=${id}`, {
				headers: authHeader(),
			})
			.then((response) => {
				dispatch({
					type: GET_COST_CENTER_ACCOUNT_SYNC_SUCCESS,
					payload: response.data,
				});
			})
			.catch(function (error) {
				if (error?.response?.status == 401) {
					checkApiStatus();
				}
				dispatch({
					payload: { error: error.message },
					type: GET_COST_CENTER_ACCOUNT_SYNC_FAILURE,
				});
			});
	} catch (error) {
		dispatch({
			type: GET_COST_CENTER_ACCOUNT_SYNC_FAILURE,
		});
	}
};

//INTERNAL ORDER SYNC
//gl account sync

export const GetInternalOrderAccountSync = (id) => async (dispatch) => {
	try {
		dispatch({ type: GET_INTERNAL_ORDER_ACCOUNT_SYNC_REQUEST });

		axios
			.get(`${API_URL}api/getSAPInternalOrderAccount?orgid=${id}`, {
				headers: authHeader(),
			})
			.then((response) => {
				dispatch({
					type: GET_INTERNAL_ORDER_ACCOUNT_SYNC_SUCCESS,
					payload: response.data,
				});
			})
			.catch(function (error) {
				if (error?.response?.status == 401) {
					checkApiStatus();
				}
				dispatch({
					payload: { error: error.message },
					type: GET_INTERNAL_ORDER_ACCOUNT_SYNC_FAILURE,
				});
			});
	} catch (error) {
		dispatch({
			type: GET_INTERNAL_ORDER_ACCOUNT_SYNC_FAILURE,
		});
	}
};
//TAX MASTER SYNC

export const GetTaxMasterSync = (id) => async (dispatch) => {
	try {
		dispatch({ type: GET_TAX_MASTER_SYNC_REQUEST });

		axios

			.get(`${API_URL}api/getSAPTaxCode?orgid=${id}`, {
				headers: authHeader(),
			})
			.then((response) => {
				dispatch({
					type: GET_TAX_MASTER_SYNC_SUCCESS,
					payload: response.data,
				});
			})
			.catch(function (error) {
				if (error?.response?.status == 401) {
					checkApiStatus();
				}
				dispatch({
					payload: { error: error.message },
					type: GET_TAX_MASTER_SYNC_FAILURE,
				});
			});
	} catch (error) {
		dispatch({
			type: GET_TAX_MASTER_SYNC_FAILURE,
		});
	}
};
