import Button from "../../components/Button";
import Modal from "../../components/UI/Modal/Modal";
import { useSelector, useDispatch } from "react-redux";
import React, { useState, useEffect, useContext } from "react";
import Swal from "sweetalert2";
import { SEND_BILLING_MAIL_RESET } from "../../redux/types/types";
import { ThemeContext } from "../../Hooks/Themes/Themeprovider";
import { getCurrentUser } from "../../redux/actions/auth";
import { encryptId } from "../../utils/helpers";
import { sendBillingEmail } from "../../redux/actions/report";
import { Dialog } from "@mui/material";

const ReminderModal = (props) => {
    const { currentMode } = useContext(ThemeContext);
    const currentUser = getCurrentUser();
    const dispatch = useDispatch();
    const emailStatus = useSelector((state) => state?.sendBillingEmailState);
    const handleEMailSend = () => {
        const reqObj = {
            customerName: currentUser?.username,
            customerEmail: currentUser?.emailaddress,
            phone: currentUser?.mobilenumber,
            toEmail: encryptId(process.env?.REACT_APP_SALES_EMAIL),
        };
        dispatch(sendBillingEmail(reqObj));
        Swal.fire({
            title: "Thank you for using our free trial version!",
            text: "MyiQ.Ai has received your request for an upgrade. Our team will reach to you shortly.",
            closeOnClickOutside: false,
            showCloseButton: true,
            icon: "success",
            button: "Ok",
        });
    };

    const ModalClose = () => {
        props?.handleClose();
        localStorage.setItem("LoginDate", null);
    };
    if (emailStatus?.requestComplete) {
        ModalClose();
        dispatch({ type: SEND_BILLING_MAIL_RESET });
    }
    return (
        <>
            {/* <div
                cartClose={ModalClose}
                bottom="bottom"
                overlay
                className={`${props?.className}`}
            > */}
            {props?.isOpened && (
                <div
                    className={` fixed bottom-[30px] z-[10] right-[30px] border-[rgba(223,225,226,0.56)] px-[20px] text-center py-[20px] rounded-[10px] chatbot_box chatbot_popup 
                        shadow-[0px_4px_12px_0px] shadow-${currentMode}-1170 
                         bg-${currentMode}-1160 text-${currentMode}-copy border-${currentMode}-700`}
                >
                    <Button
                        onClick={ModalClose}
                        tabIndex="3"
                        title="Close"
                        className={`cursor-pointer absolute right-[20px] top-[20px] convertWhite `}
                    >
                        <img src="/images/common/closeBlk.svg" alt="closeBlk" className="md:fill-cyan-700" />
                    </Button>
                    <span className="font-interSb text-base text-left flex items-center  mb-[23px]">
                        <span className="max-w-[400px] truncate"> Hey {currentUser?.username}</span>,<br></br>{" "}
                        {<img src="/images/gif/hand.gif" className="w-[30px] h-[30px] transform rotate-[-30deg] flex-shrink-0"></img>}
                    </span>
                    <p className="mb-[18px] text-left">We hope you've been enjoying your experience with MyiQ.Ai.</p>
                    <p className="text-left font-interM mb-[19px]">
                        Ready to take your MyiQ.Ai experience to the next level?
                        <br></br>
                        click below to upgrade now!
                    </p>
                    <div className="flex mt-7 space-x-2">
                        <Button
                            className="bg-light-20 px-[25px] py-2 text-base text-white rounded-[8px]"
                            type="submit"
                            tabIndex="1"
                            title="Upgrade"
                            onClick={handleEMailSend}
                            disabled={emailStatus?.fetching}
                        >
                            Upgrade
                        </Button>
                    </div>
                </div>
            )}
            {/* </div> */}
        </>
    );
};

export default ReminderModal;
