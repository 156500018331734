import { Autocomplete, Checkbox, Chip, TextField, ClickAwayListener, } from "@mui/material";
import ReactQuill from "react-quill";
import { decryptId, encryptId } from "../../../utils/helpers";
import { useState, useEffect, useContext, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GET_INVOICE_ALLOCATION_RESET } from "../../../redux/types/types";
import Input from "../../../components/Input";
import Button from "../../../components/Button";
import Card from "../../../components/UI/Card";
import { ThemeContext } from "../../../Hooks/Themes/Themeprovider";
import { getEmailTemplatePlaceHolderList } from "../../../redux/actions/emailtask";
import { Inbox } from "@mui/icons-material";
import { CKEditor } from "ckeditor4-react";
const NotificationAllocationModal = (props) => {
    const { currentMode, toggleMode } = useContext(ThemeContext);
    const AllocListData = useSelector(
        (state) => state?.getInvoiceAllocationListState
    );
    const regex =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const receivers = props?.receivers;
    const bccreceivers = props?.bccreceivers;
    const dispatch = useDispatch();
    var invAllocList = AllocListData?.dataList;
    const Value = invAllocList?.[0];
    const [title, setTitle] = useState(Value?.notification_title);
    const [notificationenable, setnotificationEnable] = useState(
        Value?.notification_enabled
    );
    const [error1, setError] = useState(false);
    const [message, setMessage] = useState(Value?.notification_msg);
    const [Emailenable, setemailEnable] = useState(Value?.email_enabled);
    const [subject, setSubject] = useState("");
    const [emailMessage, setEmailmessage] = useState(Value?.email_body);
    const CC = Value?.email_cc;
    const BCC = Value?.email_bcc;
    const emailcc = CC?.map((i) => decryptId(i));
    const emailbcc = BCC?.map((i) => decryptId(i));
    const [emailCC, setEmailCC] = useState(emailcc);
    const [emailBcc, setEmailbcc] = useState(emailbcc);
    const [inputValue, setInputValue] = useState("");
    const [inputValue2, setInputValue2] = useState("");
    const setReceivers = props?.setReceivers(emailCC);
    const setBccRecievers = props?.setBccRecievers(emailBcc);

    useEffect(() => {
        setEmailCC(emailcc);
        setEmailbcc(emailbcc);
        if (AllocListData?.fetching) {
            dispatch({ type: GET_INVOICE_ALLOCATION_RESET });
        }
        setTitle(
            Value?.notification_title == undefined
                ? ""
                : Value?.notification_title
        );
        setnotificationEnable(Value?.notification_enabled);
        setMessage(
            Value?.notification_msg == null ? "" : Value?.notification_msg
        );
        setemailEnable(Value?.email_enabled);
        setSubject(Value?.email_subject == null ? "" : Value?.email_subject);
        setEmailmessage(Value?.email_body);
        dispatch(getEmailTemplatePlaceHolderList());
    }, [Value, Value?.email_cc, Value?.email_bcc, Value?.notification_msg, dispatch]);

    const handlenotificationcheckChange = (event, id) => {
        setnotificationEnable(event.target.value);
    };
    const handleEmailCheckChange = (event, id) => {
        setemailEnable(event.target.value);
    };
    const orgName = props?.orgName;
    const orgSelect = props?.orgSelect;
    const notification_enabled = props?.notification_enabled;
    const email_enabled = props?.email_enabled;
    const viewEmailBcc = props?.viewEmailBcc;
    const emailCheck = props?.emailCheck;
    const checkChange = props?.checkChange;
    const notificationTitle = props?.notification_title;
    const handleEmailSubject = props?.handleEmailSubject(subject);
    const handleEmailMessage = props?.handleEmailMessage(emailMessage);
    const email_sub = props?.email_sub;
    const listData = props?.listData;
    const notificationMessage = props?.notification_msg;
    const email_body = props?.email_body;
    const emailCheckChange = props?.emailCheckChange(Emailenable);
    let handleNotificationTitle = props?.handleNotificationTitle(title);
    const handleSelectEmailBcc = props?.handleSelectEmailBcc;
    const handleNotificationMessage = props?.handleNotificationMessage(message);
    const handleSelectEmailCC = props?.handleSelectEmailCC;
    const handleEmailBCC = props?.handleEmailBCC;
    let handlenotificationcheck =
        props?.handleNotificationCheck(notificationenable);
    const messageRef = useRef(null);
    const ReactQuillRef = useRef(null);
    const modules = {
        toolbar: [
            ["bold", "italic", "underline", "blockquote"],
            [{ list: "ordered" }, { list: "bullet" }],
            ["clean"],
        ],
    };
    const formats = [
        // "header",
        "bold",
        "italic",
        "underline",
        // "strike",
        "blockquote",
        "list",
        "bullet",
        // "indent",
        "link",
        "image",

    ];
    const { data: placeHolData } = useSelector(
        (state) => state?.emailTemplatePlaceholderListState
    );
    const invoicePlaceHolderList = placeHolData?.filter(
        (el) => el?.placeholder_group_name == "Invoice"
    );
    const [showing, setShowing] = useState(null);
    const [showing2, setShowing2] = useState(null);

    const [focused, setFocused] = useState("");




    const handleClick = (event) => {
        if (orgName !== 0 && notification_enabled == true) {
            setShowing(true);
        }
    };
    const ReacthandleClick = (event) => {
        if (email_enabled) {
            setShowing2(true);
        }
    };
    const handleClickAway = (e) => {
        setShowing(false);
        setShowing2(false);
    };

    const handleInvoiceClick = (name) => {
        const percent = "%" + name + "%";
        const newContent = message + percent;
        setMessage(newContent);
        messageRef?.current?.editor?.setData(newContent);
    }

    const handleMessageClick = (name) => {
        const percent = "%" + name + "%";
        const replacemessage = emailMessage?.replace(null, '')
        const NewMessage = (replacemessage == undefined ? '' : replacemessage) + (percent);
        setEmailmessage(NewMessage);
        ReactQuillRef?.current?.editor?.setData(NewMessage);
    }
    const onFocus = (event) => {
        setFocused("message");
    };
    const ReactFocus = () => {
        setFocused("React")
    }
    const editorConfig = {
        // Remove the plugins responsible for the header and footer
        removePlugins: ['CKFinder', 'UploadAdapter', 'Image', 'ImageCaption', 'ImageStyle', 'ImageToolbar', 'ImageUpload', 'MediaEmbed', 'TableToolbar', 'TableProperties'],
    }
    // Example of a drop event handler
    const handleDrop = (event) => {
        event.preventDefault();
        const data = "%" + event.dataTransfer.getData("text/plain") + "%";
        const inputElement = event.target;
        const currentValue = inputElement.value
        // Append the dropped data to the existing value, separated by a space
        const newValue = currentValue ? `${currentValue} ${data}` : data;
        // Update the state with the new value
        setMessage(newValue);
    };
    const handleEmailDrop = (e) => {
        e.preventDefault();
        const data = "%" + e.dataTransfer.getData("text/plain") + "%";
        // Access the Quill instance using the quillRef
        const quill = ReactQuillRef.current.getEditor();
        const currentContent = quill.getText();
        const selection = quill.getSelection();
        const insertIndex = selection ? selection.index : currentContent.length;
        // Append the dropped data to the existing content
        const updatedContent = currentContent.slice(0, insertIndex) + data + currentContent.slice(insertIndex);
        quill.setText(updatedContent); // Update the content in Quill
        // Set the editorHtml state with the updated content
        setEmailmessage(updatedContent);
    };

    return (
        <>
            <Card
                tabIndex={0}
                className={`${orgName !== 0 ? `` : `opacity-75`
                    } basis-[35%] p-4 xl:p-[20px] border-[1px] border-b-[0] border-${currentMode}-600 h-[calc(100vh_-_40rem)] xl:h-[calc(100vh_-_17rem)] overflow-y-auto rounded-t-[8px] rounded-b-[0] ${currentMode === "dark"
                        ? ""
                        : "shadow-[0px_4px_20px_rgba(238,238,238,0.5)]"
                    }`}
            >
                <>
                    <div>
                        <div
                            className={`border-b-[1px] flex items-start justify-between border-${currentMode}-700`}
                        >
                            <div className="flex items-start flex-auto">
                                <Checkbox
                                    tabIndex={0}
                                    name="signup_acceptTerms"
                                    size="small"
                                    // disabled={orgName !== 0}
                                    checked={notificationenable ? true : false}
                                    // value={notificationenable}
                                    className="mr-2"
                                    onChange={(event) => {
                                        handlenotificationcheckChange({
                                            target: {
                                                name: event.target.name,
                                                value: event.target.checked,
                                            },
                                        });
                                    }}
                                    sx={{
                                        padding: 0,
                                        color: "rgba(85,85,85,0.7)",
                                        "&.Mui-checked": {
                                            color: "#028C78",
                                        },
                                    }}
                                />
                                <div>
                                    <span className="mb-1 inline-block">
                                        Notification
                                    </span>
                                    <p className="text-xs mb-[10px]">
                                        Send a message as a normal
                                        notification.
                                    </p>
                                </div>
                            </div>
                            <div className="flex-initial">
                                <img
                                    title="Notification"
                                    src="/images/main/notification-icon.svg"
                                    alt="notification-icon"
                                />
                            </div>
                        </div>
                        <div
                            className={`${notification_enabled ? "" : "opacity-60"
                                }`}
                        >
                            <div
                                className={`border-b-[1px] py-[20px] border-${currentMode}-700`}
                            >
                                <span className="block">Title</span>
                                <Input
                                    tabIndex={0}
                                    type="text"
                                    placeholder="Enter your notification title"
                                    className={`w-full border-${currentMode}-830 bg-transparent`}
                                    disabled={
                                        orgName !== 0 && notification_enabled
                                            ? false
                                            : true
                                    }
                                    value={title}
                                    onChange={(e) => setTitle(e.target.value)}
                                />
                            </div>
                            <div
                                className={`border-b-[1px] mx-[-20px] px-[20px] py-[20px] border-${currentMode}-700  `}
                            >
                                <div className="flex relative">
                                    <span className="block mb-2">Message</span>
                                    <div className="ml-[45px]" >
                                        <span
                                            onClick={handleClick}
                                            // aria-describedby={id}
                                            className={`flex absolute  right-[5px] top-[5px] block ${notification_enabled == true && orgName !== 0 ? 'cursor-pointer' : 'cursor-not-allowed'} `}
                                        >
                                            Insert Placeholders{" "}
                                            <img
                                                className="ml-2 convertWhite"
                                                src="/images/common/chevron-grey.svg"
                                                alt="chevronDown"
                                            />
                                        </span>
                                        {showing &&
                                            <ClickAwayListener
                                                mouseEvent="onMouseDown"
                                                touchEvent="onTouchStart"
                                                onClickAway={(e) =>
                                                    handleClickAway(e)
                                                }
                                            >
                                                <div
                                                    className={`bg-${currentMode}-card absolute top-[40px] right-0 border-${currentMode}-1020 border-[1px] z-[2] mt-[32px]  ${currentMode !== "dark"
                                                        ? `shadow-[0px_4px_20px_rgba(85,85,85,0.2)]`
                                                        : ``
                                                        }  rounded-[10px]`}
                                                >
                                                    <div className="grid grid-cols-1 gap-4">
                                                        <div
                                                            className={`py-[20px]`}
                                                        >
                                                            <span draggable="false" className="font-interSb mb-2 block pointer-events-none px-[30px]">
                                                                Invoice
                                                            </span>

                                                            <ul className="cursor-pointer">
                                                                {invoicePlaceHolderList?.length !== 0 ? (
                                                                    invoicePlaceHolderList?.map((el, i) => {
                                                                        return (
                                                                            <li
                                                                                onDragStart={(event) => {
                                                                                    event.dataTransfer.setData("text/plain", el.placeholder_display_name);
                                                                                }}
                                                                                onDragEnd={(event) => {
                                                                                    event.dataTransfer.clearData();
                                                                                }}
                                                                                className={`hover:bg-${currentMode}-960 px-[30px] userDrag py-2 w-full`}
                                                                                key={i}
                                                                                draggable="true"
                                                                                onClick={() => handleInvoiceClick(el.placeholder_display_name)}
                                                                            >
                                                                                {el.placeholder_display_name}
                                                                            </li>
                                                                        );
                                                                    })
                                                                ) : (
                                                                    <div className="px-[20px]">
                                                                        No data found
                                                                    </div>
                                                                )}
                                                            </ul>
                                                        </div>


                                                    </div>
                                                </div>
                                            </ClickAwayListener>
                                        }


                                    </div>

                                </div>

                                <div >

                                    <Input
                                        placeholder="Enter your message"
                                        onDragOver={(event) => event.preventDefault()}
                                        onDrop={handleDrop}
                                        value={message}
                                        tabIndex={0}
                                        autoFocus
                                        id={"targetInput"}
                                        onFocus={onFocus}
                                        ref={messageRef}

                                        onChange={(e) => setMessage(e.target.value)}
                                        disabled={
                                            orgName !== 0 && notification_enabled
                                                ? false
                                                : true
                                        }
                                        type="text"
                                        className={`border-[1px] rounded-[8px] py-2 px-[12px] w-full border-${currentMode}-840 bg-${currentMode}-810`}
                                    />
                                    {/* <CKEditor
                                        placeholder="Enter your Message"
                                        value={message}
                                        config={editorConfig}
                                        tabIndex={0}
                                        autoFocus
                                        id={"targetInput"}
                                        onFocus={onFocus}
                                        ref={messageRef}
                                        onChange={(e) => setMessage(e.target.value)}
                                        disabled={
                                            orgName !== 0 && notification_enabled
                                                ? false
                                                : true
                                        }
                                        type="text"
                                        className={`border-[1px] rounded-[8px] py-2 px-[12px] w-full border-${currentMode}-840 bg-${currentMode}-810`}
                                    //style={{ height: "600px" }}
                                    /> */}
                                </div>

                            </div>

                        </div>

                        <div
                            className={`border-b-[1px] flex items-start py-[20px] justify-between border-${currentMode}-700`}
                        >
                            <div className="flex items-start">
                                <Checkbox
                                    name="signup_acceptTerms"
                                    size="small"
                                    tabIndex={0}
                                    onChange={(event) => {
                                        handleEmailCheckChange({
                                            target: {
                                                name: event.target.name,
                                                value: event.target.checked,
                                            },
                                        });
                                    }}
                                    checked={Emailenable ? true : false}
                                    disabled={orgName == 0 || undefined}
                                    className="mr-2"
                                    //   onChange={formik.handleChange}
                                    sx={{
                                        padding: 0,
                                        color: "rgba(85,85,85,0.7)",
                                        "&.Mui-checked": {
                                            color: "#028C78",
                                        },
                                    }}
                                />
                                <div>
                                    <span className="mb-1 inline-block">
                                        Email
                                    </span>
                                    <p className="text-xs">
                                        Send a message to the email
                                        notification.
                                    </p>
                                </div>
                            </div>
                            <div>
                                <img
                                    title="Email"
                                    src="/images/main/mail-icon.svg"
                                    alt="email-icon"
                                />
                            </div>
                        </div>
                        <div
                            className={`${orgName !== 0 && Emailenable ? "" : "opacity-60"
                                }`}
                        >
                            <div
                                className={`flex w-full border-b-[1px] items-center border-${currentMode}-700 pt-[22px] justify-between`}
                            >
                                <span className="mr-3 block">Cc:</span>
                                <Autocomplete
                                    tabIndex={0}
                                    multiple
                                    sx={{
                                        "& .MuiInputBase-root": {
                                            border: 0,
                                        },
                                        "& .MuiChip-label": {
                                            color: `${currentMode == "dark"
                                                ? "white"
                                                : "black"
                                                }`,
                                        },
                                    }}
                                    id="tags-filled"
                                    options={[]}
                                    disabled={!email_enabled}
                                    value={emailCC == undefined ? [] : emailCC}
                                    inputValue={inputValue}
                                    // disableCloseOnSelect
                                    //disableClearable
                                    // autoHighlight={true}
                                    onInputChange={(e, newValue) => {
                                        setInputValue(newValue);
                                    }}
                                    onChange={(e, newval, reason) => {
                                        const errorEmail = newval.find(
                                            (email) => !regex.test(email)
                                        );
                                        if (errorEmail) {
                                            // set value displayed in the textbox
                                            setInputValue(errorEmail);
                                            setError(true);
                                        } else {
                                            setError(false);
                                        }

                                        setEmailCC(
                                            newval?.filter((email) =>
                                                regex.test(email)
                                            )
                                        );

                                        //  (newval);
                                    }}
                                    disableCloseOnSelect
                                    autoHighlight={true}
                                    freeSolo
                                    style={{ width: "100%", border: 0 }}
                                    limitTags={1}
                                    renderTags={(value, getTagProps) =>
                                        value &&
                                        value?.map((option, index) => {
                                            let ValueOption = encryptId(option);
                                            // setEmailCC(ValueOption)
                                            return (
                                                <Chip
                                                    key={index}
                                                    variant="outlined"
                                                    label={option}
                                                    {...getTagProps({ index })}
                                                    onDelete={() => {
                                                        setEmailCC(
                                                            emailCC?.filter(
                                                                (e) =>
                                                                    e !== option
                                                            )
                                                        );
                                                    }}
                                                />
                                            );
                                        })
                                    }
                                    renderInput={(params) => (
                                        <TextField
                                            fullWidth
                                            {...params}
                                            placeholder="Add Users"
                                        />
                                    )}
                                />
                                {!viewEmailBcc ? (
                                    <Button
                                        className="p-0"
                                        onClick={handleEmailBCC}
                                    >
                                        <span className="block">Bcc</span>
                                    </Button>
                                ) : (
                                    ""
                                )}
                            </div>
                            {viewEmailBcc ? (
                                <div
                                    className={`flex w-full items-center border-b-[1px] border-${currentMode}-910 pt-[22px] justify-between`}
                                >
                                    <span className="mr-3 block">Bcc:</span>
                                    <Autocomplete
                                        multiple
                                        sx={{
                                            "& .MuiInputBase-root": {
                                                border: 0,
                                            },
                                            "& .MuiChip-label": {
                                                color: `${currentMode == "dark"
                                                    ? "white"
                                                    : "black"
                                                    }`,
                                            },
                                        }}
                                        id="tags-filled"
                                        tabIndex={0}
                                        options={[]}
                                        value={
                                            emailBcc == undefined
                                                ? []
                                                : emailBcc
                                        }
                                        inputValue={inputValue2}
                                        // disableCloseOnSelect
                                        //disableClearable
                                        // autoHighlight={true}
                                        onInputChange={(e, newValue) => {
                                            setInputValue2(newValue);
                                        }}
                                        onChange={(e, newval, reason) => {
                                            const errorEmail = newval.find(
                                                (email) => !regex.test(email)
                                            );
                                            if (errorEmail) {
                                                // set value displayed in the textbox
                                                setInputValue2(errorEmail);
                                                setError(true);
                                            } else {
                                                setError(false);
                                            }

                                            setEmailbcc(
                                                newval?.filter((email) =>
                                                    regex.test(email)
                                                )
                                            );

                                            //  (newval);
                                        }}
                                        disableCloseOnSelect
                                        disabled={!email_enabled}
                                        freeSolo
                                        style={{ width: "100%", border: 0 }}
                                        limitTags={1}
                                        renderTags={(value, getTagProps) =>
                                            value &&
                                            value?.map((option, index) => {
                                                // let ValueOptions = encryptId(option);
                                                // bccreceivers.push(ValueOptions);
                                                return (
                                                    <Chip
                                                        key={index}
                                                        variant="outlined"
                                                        label={option}
                                                        {...getTagProps({
                                                            index,
                                                        })}
                                                        onDelete={() => {
                                                            setEmailbcc(
                                                                emailBcc?.filter(
                                                                    (e) =>
                                                                        e !==
                                                                        option
                                                                )
                                                            );
                                                        }}
                                                    />
                                                );
                                            })
                                        }
                                        renderInput={(params) => (
                                            <TextField
                                                fullWidth
                                                {...params}
                                                placeholder="Add Users"
                                            />
                                        )}
                                    />
                                </div>
                            ) : (
                                ""
                            )}

                            <div className="py-[20px]">
                                <span className="block mb-2">Subject</span>
                                <Input
                                    tabIndex={0}
                                    value={subject}
                                    onChange={(e) => setSubject(e.target.value)}
                                    className={`border-[1px] rounded-[8px] py-2 px-[12px] w-full border-${currentMode}-840 bg-${currentMode}-810`}
                                    placeholder="Enter your subject"
                                    disabled={!email_enabled}
                                />
                            </div>
                        </div>

                        <div className={` allocate-textarea relative ${orgName !== 0 && email_enabled ? "" : "opacity-60"
                            }`}>
                            <span className="mb-2 block">Message</span>
                            <div className="ml-[45px]">
                                <span
                                    onClick={ReacthandleClick}
                                    // aria-describedby={id}
                                    className={`flex absolute right-[5px] top-[5px] block  ${email_enabled ? 'cursor-pointer' : 'cursor-not-allowed'} `}
                                >
                                    Insert Placeholders{" "}
                                    <img
                                        className="ml-2 convertWhite"
                                        src="/images/common/chevron-grey.svg"
                                        alt="chevronDown"
                                    />
                                </span>
                                {showing2 &&
                                    <ClickAwayListener
                                        mouseEvent="onMouseDown"
                                        touchEvent="onTouchStart"
                                        onClickAway={(e) =>
                                            handleClickAway(e)
                                        }
                                    >
                                        <div
                                            className={`bg-${currentMode}-card absolute top-[-410px] right-0 border-${currentMode}-1020 border-[1px] z-[8] mt-[32px]  ${currentMode !== "dark"
                                                ? `shadow-[0px_4px_20px_rgba(85,85,85,0.2)]`
                                                : ``
                                                }  rounded-[10px]`}
                                        >
                                            <div className="grid grid-cols-1 gap-4">
                                                <div
                                                    className={`py-[20px]`}
                                                >
                                                    <span draggable="false" className="font-interSb mb-2 block pointer-events-none px-[30px]">
                                                        Invoice
                                                    </span>
                                                    <ul className="cursor-pointer"
                                                    >
                                                        {invoicePlaceHolderList?.length !==
                                                            0 ? (
                                                            invoicePlaceHolderList?.map(
                                                                (el, i) => {
                                                                    return (
                                                                        <li
                                                                            onDragStart={(event) => {
                                                                                event.dataTransfer.setData("text/plain", el.placeholder_display_name);
                                                                            }}
                                                                            onDragEnd={(event) => {
                                                                                event.dataTransfer.clearData();
                                                                            }}
                                                                            className={`hover:bg-${currentMode}-960 px-[30px] userDrag py-2 w-full`}
                                                                            key={i}
                                                                            draggable="true"
                                                                            onClick={() => handleMessageClick(el.placeholder_display_name)}
                                                                        >
                                                                            {el.placeholder_display_name}
                                                                        </li>
                                                                    );
                                                                }
                                                            )
                                                        ) : (
                                                            <div className="px-[20px]">
                                                                No data
                                                                found
                                                            </div>
                                                        )}
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </ClickAwayListener>
                                }
                            </div>

                            <div onDrop={handleEmailDrop}>
                                <ReactQuill
                                    tabIndex={0}
                                    onDragOver={(event) => event.preventDefault()}
                                    theme="snow"
                                    onFocus={ReactFocus}
                                    readOnly={!email_enabled}
                                    ref={ReactQuillRef}
                                    style={{ borderRadius: "8px" }}
                                    value={emailMessage}
                                    formats={formats}
                                    modules={modules}
                                    disabled={!email_enabled}
                                    onChange={(e) => setEmailmessage(e)}
                                />
                            </div>
                        </div>


                    </div>

                </>

            </Card>
        </>
    );
};

export default NotificationAllocationModal;
