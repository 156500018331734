import { FormControl, MenuItem, Select, TextField } from "@mui/material";
import { useState, useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { DashboardVandorList } from "../../redux/actions/dashboard";
import Loader from "../../components/Loader";
import { Link } from "react-router-dom";
import Button from "../../components/Button";
import Card from "../../components/UI/Card";
import { Barchart } from "../../organisms/BarChart";
import Filter from "./Filter";
import { decryptId, ThisWeek, ThisYear } from "../../utils/helpers";
import moment from "moment/moment";
import { Bar, CartesianGrid, Legend, Tooltip, XAxis, YAxis } from "recharts";
import { getCurrentUser } from "../../redux/actions/auth";
import { ThemeContext } from "../../Hooks/Themes/Themeprovider";

const Orgname = localStorage.getItem("Orgdemo");
const VendorList = (props) => {
    const userId = props?.userId;
    const ChangeOrg = localStorage.getItem("ChangeOrg");
    const [age, setAge] = useState("");
    const dispatch = useDispatch();
    const location = useLocation();
    let organisationId = 0;
    const CurrentUser = getCurrentUser();
    const letuserId = CurrentUser?.role_id == 1 ? CurrentUser?.id : userId;
    const handleChange = (event) => {
        setAge(event.target.value);
    };

    const getDataItems = (type, year) => {
        organisationId = localStorage.getItem("orgId") || 0;
        dispatch(DashboardVandorList(type, organisationId, year, letuserId));
    };
    const { data, error, fetching, requestComplete } = useSelector((state) => state?.DashboardVendorListState);
    const { currentMode } = useContext(ThemeContext);
    const orgId = localStorage.getItem("orgId");

    const OrgdemoId = Orgname == "true" ? 3 : 1;
    useEffect(() => {
        organisationId = localStorage.getItem("orgId") || 0;
        // let bodys = document.querySelector("body").classList;
        // location.pathname === "/dashboard" &&
        // //     bodys.remove(`bg-${currentMode}-base`);

        setTimeout(() => {
            dispatch(DashboardVandorList(OrgdemoId, organisationId, Orgname == "true" ? ThisYear : ThisWeek, letuserId));
        }, 500);
    }, [Orgname, organisationId, dispatch]);

    const truncateLabel = (label) => {
        if (label.length > 5) {
            return label.substring(0, 5) + "...";
        }
        return label;
    };

    if (data?.datalist) {
        data?.datalist?.forEach((item) => {
            item.Count = parseInt(item?.Count);
        });
    }
    return (
        // <div className="basis-1/2">
        <Card
            // className={`mb-[20px] ${!requestComplete && "h-[29rem]"} ${data?.datalist?.length == 0 && "h-[31.5rem]"
            //     }`}
            className="h-full"
        >
            <div>
                <div className={`flex justify-between items-center px-[20px] py-[24px] mb-6 border-b-[1px] border-${currentMode}-700`}>
                    <div className="flex-1">
                        <h4 className="font-interM text-lg">Top 10 Vendors by Invoice Count</h4>
                    </div>
                    <Filter getDataItems={getDataItems} />
                </div>
                <div className="px-[20px] py-5">
                    {!requestComplete ? (
                        <>
                            <Loader className="min-h-[22rem] flex justify-center items-center" />
                        </>
                    ) : data?.datalist?.length !== 0 ? (
                        <Barchart
                            margin={{
                                top: 0,
                                bottom: 0,
                            }}
                            data={data?.datalist}
                            width={600}
                            // height={330}
                            color={"#EE89C2"}
                            xDataKey={"vendor"}
                            yDataKey={"Count"}
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis
                                xDataKey={"vendor"}
                                tick={{
                                    fontSize: 2,
                                    width: 80,
                                    transform: "translate(-20, 0)",
                                }}
                                tickFormatter={truncateLabel}
                                interval={0}
                            />
                            <YAxis yDataKey={"Count"} domain={[0, "dataMax"]} />
                            <Tooltip cursor={false} />
                            <Legend />
                            <Bar dataKey="pv" barSize={20} fill="#EE89C2" label={{ position: "top" }} />
                        </Barchart>
                    ) : (
                        <div className="px-[30px] h-[20rem] grid place-items-center place-content-center">
                            {" "}
                            <img className="mb-3" src="/images/common/delivery.png" alt="searching" />
                            <h4 className="font-interSb block">No Data found</h4>
                        </div>
                    )}
                </div>
            </div>
        </Card>
        // </div>
    );
};

export default VendorList;
