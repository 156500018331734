import React, { useContext, useEffect, useState } from "react";
import { FormControl, Input, MenuItem, Select } from "@mui/material";
import Button from "../../components/Button";
import { useDispatch, useSelector } from "react-redux";
import { SettingData, settingValues, updateSettingAction } from "../../redux/actions/setting";

import MessageBox from "../../components/UI/Alert/index";
import SuccessMessageBox from "../../components/UI/Alert/sucess";
import { getCurrentUser } from "../../redux/actions/auth";
import { SETTING_RESET, SETTING_UPDATE_RESET, SETTING_VALUE_RESET } from "../../redux/types/types";
import Loader from "../../components/Loader/index";
import { ThemeContext } from "../../Hooks/Themes/Themeprovider";
const currentUser = getCurrentUser();
const successMsg = "Updated Successfully.";
const errormsg = "Something went wrong please contact admin";
let formatVal;
export default function Settings() {
    const dispatch = useDispatch();
    const { currentMode } = useContext(ThemeContext);
    const [dateValue, handleDateChange] = useState("");
    const [setValue, handleValueChange] = useState("");
    const [numberValue, handleNumberChange] = useState("");
    const [loader, setLoader] = useState(false);
    const handleNumberValueChange = (value) => {
        handleNumberChange(value);
    };

    const Setting = useSelector((state) => state?.settingState);
    const { data: settingdata } = Setting;
    let filteredDateFormatList = settingdata?.filter((elem) => elem?.code === "CUTOFF");
    if (filteredDateFormatList?.length > 0) {
        filteredDateFormatList = JSON.parse(filteredDateFormatList[0]?.data_list);
    }
    let filteredNumberFormatList = settingdata?.filter((elem) => elem?.code === "NUMFOR");
    if (filteredNumberFormatList?.length > 0) {
        filteredNumberFormatList = JSON.parse(filteredNumberFormatList[0]?.data_list);
    }
    const getupdatedNumberFormat = settingdata?.find((item) => item?.code == "NUMFOR");
    if (numberValue == "1.23.456,78") {
        formatVal = "2";
    } else if (numberValue == "1 23 456.78") {
        formatVal = "3";
    } else {
        formatVal = "1";
    }
    localStorage.setItem("userNumberFormat", formatVal);
    const selectedDate = settingdata?.find((elem) => elem?.code === "CUTOFF");
    const StoreDateValue = selectedDate?.value;
    const selectedValue = settingdata?.find((elem) => elem?.code === "NUMFOR");

    if (selectedDate?.value && dateValue === "") {
        handleDateChange(selectedDate?.value);
    }

    if (selectedValue?.value && numberValue === "") {
        handleNumberValueChange(selectedValue?.value);
    }

    const { data, fetching, error, requestComplete } = useSelector((state) => state?.settingUpdateState);

    const user = JSON.parse(localStorage.getItem("user"));

    const UpdateSetting = () => {
        const Str = [
            { name: "CUTOFF", value: `${dateValue}` },
            { name: "NUMFOR", value: `${numberValue}` },
        ];
        const OBj = {
            json: JSON.stringify(Str),
            ref_type: 1,
            ref_id: user?.id,
        };
        dispatch(updateSettingAction(OBj));
    };
    useEffect(() => {
        const reqOBj = {
            ref_type: 1,
            ref_id: user?.id,
        };
        dispatch(SettingData(reqOBj));
    }, [dispatch]);
    const reqOBj = {
        ref_type: 1,
        ref_id: user?.id,
    };
    if (requestComplete && data?.status) {
        localStorage.setItem("userDateFormat", dateValue);
        setTimeout(function () {
            dispatch({ type: SETTING_UPDATE_RESET });
            dispatch(SettingData(reqOBj));
        }, 2000);
    }
    const dropdownColor = currentMode === "dark" ? "#333333" : `white`;
    const dropdownText = currentMode === "dark" ? "#ECECEC" : `#555555`;
    return (
        <>
            {requestComplete && data?.status && <SuccessMessageBox success={successMsg} />}
            {requestComplete && !data?.status && <MessageBox error={data.message} />}
            {error && !data?.status && <MessageBox error={errormsg} />}
            <div className="flex justify-between items-center mb-7">
                <h3 className="text-2xl font-interM text-light-20">Settings</h3>
            </div>
            {Setting?.fetching || loader ? (
                <Loader className="mt-[-3rem]" />
            ) : (
                <div>
                    <div className="flex flex-col space-y-5 mb-5 viewStyles">
                        <div className="w-full max-w-[400px]">
                            <span className="block text-sm pb-3">Date Format</span>
                            <FormControl sx={{ p: 0, minWidth: 240 }}>
                                <Select
                                    value={dateValue}
                                    onChange={(e) => handleDateChange(e.target.value)}
                                    defaultValue={"test"}
                                    displayEmpty
                                    inputProps={{
                                        "aria-label": "Without label",
                                    }}
                                    IconComponent={() => (
                                        <img
                                            className="absolute right-[15px] opacity-[.5] pointer-events-none"
                                            src={currentMode !== "dark" ? `/images/header/chevronDown.svg` : `/images/header/chevronDown-white.svg`}
                                            alt="chevronDown"
                                        />
                                    )}
                                    MenuProps={{
                                        sx: {
                                            "& .MuiMenu-paper": {
                                                backgroundColor: dropdownColor,
                                                color: dropdownText,
                                            },
                                            "& .MuiMenuItem-root:hover": {
                                                backgroundColor: "rgba(218, 249, 244, 0.3)",
                                            },
                                            "& .Mui-selected": {
                                                backgroundColor: "rgba(218, 249, 244, 0.3) !important",
                                            },
                                        },
                                    }}
                                    SelectDisplayProps={{
                                        style: {
                                            color: `${currentMode !== "dark" ? `#555555` : `#ECECEC`}`,
                                        },
                                    }}
                                >
                                    {/* <div className="bg-white mx-3 mb-4 border-[rgba(223, 225, 226, 0.56)] shadow-[0px_4px_20px_rgba(238,238,238,0.501967)] border-[1px] flex justify-between py-[3px] px-[16px] rounded-[8px]">
               
                <Input
           

                    value={dateValue}

                  className="border-0 w-full outline-0"
                  disableUnderline={true}
             
                />
              </div> */}

                                    {filteredDateFormatList &&
                                        filteredDateFormatList?.map((listelem, i) => {
                                            return (
                                                <MenuItem key={i} name="dateformat" value={listelem?.value}>
                                                    {listelem?.value}
                                                </MenuItem>
                                            );
                                        })}
                                </Select>
                            </FormControl>
                        </div>

                        <div className="w-full max-w-[400px]">
                            <span className="block text-sm pb-3">Number Format</span>
                            <FormControl sx={{ p: 0, minWidth: 240 }}>
                                <Select
                                    value={numberValue}
                                    onChange={(e) => handleNumberValueChange(e.target.value)}
                                    displayEmpty
                                    inputProps={{
                                        "aria-label": "Without label",
                                    }}
                                    MenuProps={{
                                        sx: {
                                            "& .MuiMenu-paper": {
                                                backgroundColor: dropdownColor,
                                                color: dropdownText,
                                            },
                                            "& .MuiMenuItem-root:hover": {
                                                backgroundColor: "rgba(218, 249, 244, 0.3)",
                                            },
                                            "& .Mui-selected": {
                                                backgroundColor: "rgba(218, 249, 244, 0.3) !important",
                                            },
                                        },
                                    }}
                                    IconComponent={() => (
                                        <img
                                            className="absolute right-[15px] opacity-[.5] pointer-events-none"
                                            src={currentMode !== "dark" ? `/images/header/chevronDown.svg` : `/images/header/chevronDown-white.svg`}
                                            alt="chevronDown"
                                        />
                                    )}
                                    SelectDisplayProps={{
                                        style: {
                                            color: `${currentMode !== "dark" ? `#555555` : `#ECECEC`}`,
                                        },
                                    }}
                                >
                                    {/* <div className="bg-white mx-3 mb-4 border-[rgba(223, 225, 226, 0.56)] shadow-[0px_4px_20px_rgba(238,238,238,0.501967)] border-[1px] flex justify-between py-[3px] px-[16px] rounded-[8px]">
               
                <Input
                value={setValue}
                  disableUnderline={true}
                />
              </div> */}
                                    {/* {filteredNumberFormatList?.map((elem,i) => {
               
                return <MenuItem key={i} name="numberformat" value={elem?.value}>
                    {elem?.value }
                  </MenuItem>
              })} */}

                                    {filteredNumberFormatList &&
                                        filteredNumberFormatList?.map((listelem, i) => {
                                            return (
                                                <MenuItem key={i} name="dateformat" value={listelem?.value}>
                                                    {listelem?.name}
                                                </MenuItem>
                                            );
                                        })}
                                </Select>
                            </FormControl>
                        </div>
                    </div>

                    <Button
                        onClick={UpdateSetting}
                        title="To update personal Settings"
                        disabled={fetching}
                        className="bg-light-20 flex text-base font-interR py-[.5rem] px-[2.24rem] rounded-[8px] text-white"
                    >
                        {fetching ? `Submitting...` : `Submit`}
                    </Button>
                </div>
            )}
        </>
    );
}
