import Input from "../../components/Input";
import Button from "../../components/Button";
import { useDispatch, useSelector } from "react-redux";
import UseForm from "../../Hooks/useForm";
import { Profileedit, ProfileviewList } from "../../redux/actions/profile";
import { validateProfileEdit } from "../../Hooks/validate";
import { countryList } from "../../redux/actions/country";
import { useContext, useEffect } from "react";
import { userId } from "../../Hooks/userId/Index";
import SuccessMessageBox from "../../components/UI/Alert/sucess";
import MessageBox from "../../components/UI/Alert";
import ReactPhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useState } from "react";
import { decryptId } from "../../utils/helpers";
import { MenuItem, Select } from "@mui/material";
import Loader from "../../components/Loader/index";
import authHeader from "../../redux/actions/auth-header";
import { getCurrentUser } from "../../redux/actions/auth";
import { type } from "@testing-library/user-event/dist/type";
import { PROFILE_EDIT_RESET } from "../../redux/types/types";
import { ThemeContext } from "../../Hooks/Themes/Themeprovider";
const succesMsg = "Profile is updated successfully";
const errormsg = "Something went wrong please contact admin";
const ProfileEdit = (props) => {
    const { currentMode } = useContext(ThemeContext);
    const EditData = props?.datas;
    const dispatch = useDispatch();
    const currentUser = getCurrentUser();
    const [phoneNumber, phoneNoChange] = useState("");
    const { handleChange, handleSubmit, values, errors } = UseForm(submit, validateProfileEdit, EditData);
    const profileData = useSelector((state) => state?.profileState);

    const userEditResponse = useSelector((state) => state?.profileEditState);
    const { fetching, requestComplete, profile, error } = userEditResponse;
    const { country } = useSelector((state) => state?.countryState);
    const countryObj = values?.country ? country?.find((item) => item.country_id === +values?.country) : "";
    function submit() {
        const phonevalue = "+" + phoneNumber;
        const phone = countryObj?.country_code && phoneNumber?.length == 0 ? "" : phonevalue == "+" ? values?.mobilenumber : phonevalue;
        let formdata = new FormData();
        formdata.append("Image", EditData?.user_image);
        formdata.append("emailaddress", values?.user_emailaddress.trim());
        formdata.append("mobilenumber", phone);
        formdata.append("firstname", values?.firstname.trim());
        formdata.append("lastname", values?.lastname.trim());
        //formdata.append("role_id", values?.role_id?.id);
        formdata.append("country", +values?.country || +values?.country?.country_id);
        formdata.append("id", values?.id);
        dispatch(Profileedit(formdata));
    }

    if (requestComplete && profile?.status) {
        localStorage.setItem("Username", ((values?.firstname) + " " + (values?.lastname)))
        setTimeout(function () {
            // window.location.reload();
            dispatch({ type: PROFILE_EDIT_RESET });
            dispatch(ProfileviewList({ id: currentUser?.id }));
            props?.Remove();
        }, 1000);
    }
    const response = useSelector((state) => state?.profileEditState);
    const [countryFlagCode, setcountryFlagCode] = useState();
    const phoneNoSelect = (value, countryCode) => {
        setcountryFlagCode(countryCode?.countryCode);
        phoneNoChange(value);
        const countrySelect = value ? country?.find((item) => item.country_code.slice(1) === value) : "";
        if (countrySelect) {
            values.country_id = countrySelect?.country_id;
            values.country_name = countrySelect?.country_name;
            values.country = values.country_id;
        }
        values.mobilenumber = value;
    };

    useEffect(() => {
        if (country?.length === 0) {
            dispatch(countryList());
        }
    }, [dispatch]);
    const dropdownColor = currentMode === "dark" ? "#333333" : `white`;
    const dropdownText = currentMode === "dark" ? "#ECECEC" : `#555555`;
    const CountryLabel =
        values?.mobilenumber == "" && countryObj?.country_code == undefined && currentUser?.country_id == +values?.country?.country_id
            ? currentUser?.country_code
            : values?.mobilenumber;
    return (
        <>
            {requestComplete && profile !== undefined && profile.status !== undefined && profile.status && <SuccessMessageBox success={succesMsg} />}
            {requestComplete && profile !== undefined && profile.status !== undefined && !profile.status && <MessageBox error={profile.message} />}
            {error && !profile?.status && <MessageBox error={errormsg} />}
            {profileData?.fetching ? (
                <Loader />
            ) : (
                <form onSubmit={handleSubmit} noValidate>
                    <div className="editProfile">
                        <div className="flex justify-between items-center mb-7">
                            <h3 className="text-2xl font-interM text-light-20">Profile</h3>
                        </div>
                        <div className="flex mb-5 space-x-7 viewStyles">
                            <div className="basis-1/2">
                                <span className="block text-sm">
                                    <span className="text-[red] mr-1">*</span>
                                    First Name
                                </span>
                                <Input
                                    value={values?.firstname}
                                    onChange={handleChange}
                                    maxLength={51}
                                    name="firstname"
                                    className={`border-[1px] border-${currentMode}-840 rounded-[8px] px-[20px] py-2 w-full bg-${currentMode}-810`}
                                />
                                {errors && <p className="text-light-50 text-xs">{errors?.firstname}</p>}
                            </div>
                            <div className="basis-1/2">
                                <span className="block text-sm">
                                    <span className="text-[red] mr-1">*</span>
                                    Last Name
                                </span>
                                <Input
                                    value={values?.lastname}
                                    onChange={handleChange}
                                    maxLength={51}
                                    name="lastname"
                                    className={`border-[1px] border-${currentMode}-840 rounded-[8px] px-[20px] py-2 w-full bg-${currentMode}-810`}
                                />
                                {errors && <p className="text-light-50 text-xs">{errors?.lastname}</p>}
                            </div>
                        </div>
                        <div className="flex mb-5 space-x-7 viewStyles  e-disabled">
                            <div className="basis-1/2 ">
                                <span className="block text-sm">
                                    <span className="text-[red] mr-1">*</span>
                                    Business Email
                                </span>
                                <Input
                                    value={values?.user_emailaddress ? decryptId(values?.user_emailaddress) : ""}
                                    required={true}
                                    name="user_emailaddress"
                                    className={`border-[1px] border-${currentMode}-840 rounded-[8px] px-[20px] py-2 w-full bg-${currentMode}-810`}
                                    disabled
                                />
                            </div>

                            <div className="basis-1/2">
                                <span className="block text-sm">Mobile Number</span>
                                <ReactPhoneInput
                                    value={countryObj?.country_code || CountryLabel}
                                    // country="in"
                                    name="mobilenumber"
                                    // country={countryFlagCode}
                                    className={`bg-${currentMode}-copy text-${currentMode}-copy`}
                                    onChange={phoneNoSelect}
                                />
                            </div>
                        </div>
                        <div className="flex mb-5 space-x-7 viewStyles">
                            <div className="basis-1/2">
                                <span className="block text-sm">
                                    <span className="text-[red] mr-1">*</span>
                                    Country
                                </span>
                                <Select
                                    onChange={handleChange}
                                    name="country"
                                    value={+values?.country || +values?.country?.country_id || "0"}
                                    className={`w-full bg-${currentMode}-810`}
                                    MenuProps={{
                                        sx: {
                                            "& .MuiMenu-paper": {
                                                backgroundColor: dropdownColor,
                                                color: dropdownText,
                                                maxWidth: "30rem !important",
                                            },
                                            "& .MuiMenuItem-root:hover": {
                                                backgroundColor: "rgba(218, 249, 244, 0.3)",
                                            },
                                            "& .Mui-selected": {
                                                backgroundColor: "rgba(148, 148, 148, 0.07) !important",
                                            },
                                        },
                                    }}
                                    IconComponent={() => (
                                        <img
                                            className="absolute right-[15px] opacity-[.5] pointer-events-none"
                                            src={currentMode !== "dark" ? `/images/header/chevronDown.svg` : `/images/header/chevronDown-white.svg`}
                                            alt="chevronDown"
                                        />
                                    )}
                                    SelectDisplayProps={{
                                        style: {
                                            paddingTop: "9px",
                                            paddingBottom: "9px",
                                            color: `${currentMode !== "dark" ? `#555555` : `#ECECEC`}`,
                                            paddingRight: "30px",
                                            border: "1px solid rgba(85, 85, 85, 0.3)",
                                            borderRadius: "8px",
                                        },
                                    }}
                                >
                                    <MenuItem key={0} value={0} name="country">
                                        {"Select"}
                                    </MenuItem>
                                    {country?.map((elem, i) => {
                                        return (
                                            <MenuItem key={i + 1} name="country" value={elem?.country_id}>
                                                {elem?.country_name}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                                {errors && <p className="text-light-50 text-xs">{errors?.country}</p>}
                            </div>
                        </div>
                        <div className="flex mt-6 space-x-2">
                            <Button
                                disabled={fetching}
                                title="To update the Profile changes"
                                type="submit"
                                className="bg-light-20 flex text-base font-interR py-[.5rem] px-[2.24rem] rounded-[8px] text-white"
                            >
                                {fetching && Object.keys(errors).length === 0 ? `Updating...` : `Update`}
                            </Button>
                            <Button
                                title="To Cancel"
                                onClick={props?.Remove}
                                className="bg-light-240 flex text-base font-interR py-[.5rem] px-[2.24rem] rounded-[8px] text-light-40"
                            >
                                Cancel
                            </Button>
                        </div>
                    </div>
                </form>
            )}
        </>
    );
};

export default ProfileEdit;
