import Modal from "../../../../components/UI/Modal/Modal";
import Stepper from "../../../Rules/Stepper";
import Loader from "../../../../components/Loader";
import { useContext, useEffect } from "react";
import { ThemeContext } from "../../../../Hooks/Themes/Themeprovider";
import Button from "../../../../components/Button";
import { useSelector } from "react-redux";
import { GetCurrencyListExchangeRate } from "../../../../redux/actions/exchange-rate";
import { countryList } from "../../../../redux/actions/country";
import { useDispatch } from "react-redux";

const RuleDetailsModal = (props) => {
	const { currentMode, toggleMode } = useContext(ThemeContext);
	const ruleDataList = props?.ruleDataList;
	const DataValue = props?.DataValue;
	const DataItems = props?.DataItems;
	const RuleList = props?.ruleList;
	const Currency = useSelector((state) => state?.currencyListState);
	const currencies = Currency?.data;
	const { country } = useSelector((state) => state?.countryState);
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(GetCurrencyListExchangeRate());
		dispatch(countryList());
	}, [dispatch]);
	return (
		<>
			<Modal
				cartClose={props?.handleClose}
				center="center"
				className={`${props?.className}`}
			>
				{RuleList?.fetching ? (
					<Loader className="" />
				) : (
					<div
						className={`p-[20px] overflow-y-auto max-h-[340px]  max-w-[500px] mx-auto  relative bg-${currentMode}-card text-${currentMode}-copy border-${currentMode}-700 border-t-[1px] rounded-[10px] ${
							currentMode === "dark" ? "" : "shadow-[0px_4px_4px_0px_rgba(0,0,0,0.10),0px_4px_4px_0px_rgba(0,0,0,0.06)]"
						}`}
					>
						<span
							onClick={props?.handleClose}
							className="cursor-pointer w-full mb-5 block text-end"
						>
							<img
								className="ml-auto"
								src="/images/common/closeBlk.svg"
								alt="closeBlk"
							/>
						</span>
						{DataValue == undefined && DataItems == undefined ? (
							<h4 className="font-interSb mb-3">No Data found</h4>
						) : (
							<>
								<h4 className="font-interSb mb-3 break-normal md:break-all">{DataValue?.rule_name}</h4>
								<p className="mb-7 break-normal md:break-all max-w-[700px]">{DataValue?.rule_description}</p>
								<>
									{DataItems !== null &&
										DataItems?.map((elem, index) => {
											let Valuemap = elem?.value;
											const FilterCurrency = currencies?.filter((item) => elem?.value?.includes(item?.currency_id));
											const FindCurrency = FilterCurrency?.map((items) => items?.currency_code);
											return (
												<div className="grid ">
													<Stepper no={index + 1}>
														<div
															className={`bg-${currentMode}-card text-${currentMode}-copy border-${currentMode}-700 border-[1px] border-[rgba(223,225,226,0.56)] rounded-[8px] shadow-[0px_4px_20px_rgba(85,85,85,0.2)] py-[16px] px-[15px]`}
														>
															{elem?.field_name} <span className="text-light-10">{elem?.operators} </span>
															{elem?.field_id == 7 || elem?.field_id == 8 ? (
																<span
																	title={
																		elem?.field_id == 8
																			? FindCurrency?.join(", ")
																			: elem?.field_id == 7
																			? country
																					?.filter((item) => elem?.value?.includes(item?.country_id))
																					?.map((items) => items?.country_name)
																			: ""
																	}
																	className="truncate w-[200px]"
																>
																	{elem?.field_id == 8
																		? FindCurrency?.join(", ")
																		: elem?.field_id == 7
																		? country
																				?.filter((item) => elem?.value?.includes(item?.country_id))
																				?.map((items) => items?.country_name)
																				?.join(", ")
																		: ""}
																</span>
															) : (
																<>
																	{Valuemap?.map((el, index) => {
																		return (
																			<>
																				<span
																					title={
																						elem?.field_name == "Document Type" && el == [1]
																							? "Po Invoice"
																							: elem?.field_name == "Document Type" && el == [2]
																							? "Non-PO Invoice"
																							: el
																					}
																					className={`${
																						elem?.field_id == 1 &&
																						"text-light-40 bg-light-400 rounded-[2px] mr-[10px] p-1"
																					}`}
																				>
																					{elem?.field_name == "Document Type" && el == [1]
																						? "Po Invoice"
																						: elem?.field_name == "Document Type" && el == [2]
																						? "Non-PO Invoice"
																						: el}
																				</span>
																				{index !== Valuemap?.length - 1 && ", "}
																			</>
																		);
																	})}
																</>
															)}
														</div>
													</Stepper>
												</div>
											);
										})}
								</>
								<Button
									tabIndex={0}
									className="bg-light-20 text-white text-base rounded-[8px] mt-5 ml-1 py-[10px] px-[15px]"
									onClick={() => {
										props?.handleClose();
									}}
									title={"OK"}
									disabled={false}
								>
									{"OK"}
								</Button>
							</>
						)}
					</div>
				)}
			</Modal>
		</>
	);
};

export default RuleDetailsModal;
