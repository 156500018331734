import * as React from "react";
import { useState, useEffect } from "react";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import { Alert, Slide } from "@mui/material";

const MessageBox = (props) => {
    const { error } = props;
    const [open, setOpen] = useState(false);
    useEffect(() => {
        if (error) setOpen(true);
    }, [error]);

    const TransitionLeft = (props) => {
        return <Slide {...props} direction="left" />;
    };

    const action = (
        <React.Fragment>
            <Alert severity="error">{error}</Alert>
            {!props?.closeHide && (
                <IconButton
                    size="small"
                    aria-label="close"
                    color="inherit"
                    onClick={() => setOpen(false)}
                >
                    <img src="/images/common/close.png" alt="close" className="convertWhite" />
                </IconButton>
            )}
        </React.Fragment>
    );

    return (
        <div>
            <Snackbar
                TransitionComponent={TransitionLeft}
                open={open}
                autoHideDuration={null}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                sx={{
                    "& .MuiSnackbarContent-root": {
                        width: "100%",
                        color: "#EA3D2F",
                        backgroundColor: "rgb(253, 237, 237)",
                        paddingLeft: "0",
                        border: "0 !important", boxShadow: "none !important"
                    },
                    "& .MuiPaper-root": {
                        border: "0 !important",
                        boxShadow: "none !important"
                    },
                    "& .MuiSnackbarContent-action": { padding: "0", marginLeft: 0 },
                }}
                onClose={() => setOpen(false)}
                action={action}
            />
        </div>
    );
};

export default MessageBox;
