import Button from "../../../components/Button";
import Input from "../../../components/Input";
import { userEdit, userView, userByOrg } from "../../../redux/actions/user";
import { useDispatch, useSelector } from "react-redux";
import UseForm from "../../../Hooks/useForm";
import { validateUserEdit } from "../../../Hooks/validate";
import { Link, useNavigate } from "react-router-dom";
import SuccessMessageBox from "../../../components/UI/Alert/sucess";
import MessageBox from "../../../components/UI/Alert";
import ReactPhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useState, useEffect } from "react";
import {
    RESEND_EMAIL_LINK_RESET,
    USER_EDIT_RESET,
} from "../../../redux/types/types";
import { decryptId, encryptId } from "../../../utils/helpers";
import Swal from "sweetalert2";
import { getCurrentUser, resendEmailLink } from "../../../redux/actions/auth";
import Loader from "../../../components/Loader";
import { MenuItem, Select } from "@mui/material";
import { useContext } from "react";
import { ThemeContext } from "../../../Hooks/Themes/Themeprovider";

let showConfirm = false;

const EditUserModal = (props) => {
    const { currentMode } = useContext(ThemeContext);
    const successMsg = "User is  updated successfully";
    const errormsg = "Something went wrong please contact admin";
    const UserEditData = useSelector((state) => state?.userViewState);
    const { viewData } = UserEditData;
    const CurrentUser = getCurrentUser();
    const [phoneNumber, phoneNoChange] = useState("");
    const [updateEmail, handleUpdateEmail] = useState(false);
    const currentUser = getCurrentUser();

    const {
        handleChange,
        handleSubmit,
        values,
        errors,
        setErrors,
        setValues,
        InitialState,
        isSubmit,
        setSubmit
    } = UseForm(submit, validateUserEdit, viewData);

    const [errorMsg, seterrorMsg] = useState(false);
    const phoneNoSelect = (value) => {
        phoneNoChange(value);
        const countrySelect = value
            ? country?.find((item) => item.country_code.slice(1) === value)
            : "";
        if (countrySelect) {
            values.country_id = countrySelect?.country_id;
            values.country_name = countrySelect?.country_name;
            values.country = values.country_id;
        }
        values.mobilenumber = value;
    };
    const dispatch = useDispatch();
    const { country } = useSelector((state) => state?.countryState);
    let { data, fetching, error, requestComplete } = useSelector(
        (state) => state?.userEditState
    );
    const countryObj = values?.country
        ? country?.find((item) => item.country_id === +values?.country)
        : "";

    if (
        decryptId(viewData?.user_emailaddress) ===
        decryptId(values.user_emailaddress)
    ) {
        try {
            values.user_emailaddress = decryptId(viewData?.user_emailaddress);
        } catch (ex) { }
    }
    function submit() {
        setSubmit(true);
        const phonevalue = "+" + phoneNumber;
        const phone =
            countryObj?.country_code && phoneNumber?.length == 0
                ? ""
                : phonevalue == "+"
                    ? values?.mobilenumber
                    : phonevalue;
        let formdata = new FormData();
        formdata.append("Image", props?.fileName);
        formdata.append("emailaddress", encryptId(values?.user_emailaddress));
        formdata.append("mobilenumber", phone);
        formdata.append(
            "isdefault_img",
            props?.fileName == "" ? viewData?.isdefault_img : false
        );
        formdata.append("firstname", values?.firstname?.trim());
        formdata.append("lastname", values?.lastname?.trim());
        //formdata.append("role_id", values?.role_id?.id);
        formdata.append(
            "country",
            +values?.country || +values?.country?.country_id
        );
        formdata.append("id", values?.id);
        if (
            decryptId(viewData?.user_emailaddress) !== values.user_emailaddress
        ) {
            showConfirm = true;
            handleUpdateEmail(true);
        }
        dispatch(userEdit(formdata));
    }

    const cartReset = () => {
        if (requestComplete && !data?.status) {
            props?.cartClose();
            handleUpdateEmail(false);
            dispatch({ type: USER_EDIT_RESET });
        } else {
            props?.cartClose();
            setValues({
                InitialState,
            });
        }
        setErrors({ errors: "" });
    };

    const navigate = useNavigate();
    const reqObj = {
        order_by: 1,
        order_column: "",
        pg_no: 1,
        rec_cnt: 20,
        role_id: 0,
        search_text: "",
        status: 2,
    };


    if (requestComplete &&
        values?.id == CurrentUser?.id) {
        localStorage.setItem("Username", data?.data?.user_name)
    }
    if (
        requestComplete &&
        data?.data?.user_image !== undefined &&
        values?.id == CurrentUser?.id
    ) {
        localStorage.setItem("UserImage", data?.data?.user_image);
    }
    useEffect(() => {
        if (
            requestComplete &&
            props?.fileName !== "" &&
            values?.id == CurrentUser?.id
        ) {
            setTimeout(() => {
                dispatch({ type: USER_EDIT_RESET });
            }, 1000);

            setTimeout(() => {
                props.cartClose();
                // dispatch(ProfileviewList({ id: parseInt(CurrentUser.id) }));
                dispatch(userByOrg(reqObj));
            }, 2500);
        }
        if (requestComplete && !data?.status) {
            setTimeout(() => {
                dispatch({ type: USER_EDIT_RESET });
            }, 2000);

        }
        return () => {
            dispatch({ type: USER_EDIT_RESET });
        };
    }, [dispatch, navigate, phoneNumber]);
    // if (requestComplete && data?.status) {
    //   setTimeout(function () {
    //     props.cartClose();
    //     window.location.reload();
    //   }, 2000);
    // }
    // if (requestComplete && data?.status && !fetching && !showConfirm) {
    //   alert(showConfirm)

    if (updateEmail && requestComplete && data?.status) {
        showConfirm = true;
        requestComplete = false;

        Swal.fire({
            text: `Are you sure you want to send the invite mail to user?`,
            icon: "warning",
            closeOnClickOutside: false,
            showCloseButton: true,
            showCancelButton: true,
            buttons: {
                cancel: "Cancel",
                confirm: "Confirm",
            },
        }).then((confirm) => {
            if (confirm?.isConfirmed) {
                dispatch(
                    resendEmailLink({
                        email: encryptId(values?.user_emailaddress),
                        type: 2,
                    })
                );
                dispatch({ type: USER_EDIT_RESET });
                dispatch({ type: RESEND_EMAIL_LINK_RESET });
                setTimeout(function () {
                    props.cartClose();
                    window.location.reload();
                }, 2000);
            } else {
                setTimeout(function () {
                    props.cartClose();
                    window.location.reload();
                }, 1000);
            }
        });
    } else if (requestComplete && data?.status && !fetching && !showConfirm) {
        requestComplete = false;
        setTimeout(function () {
            props.cartClose();
            window.location.reload();
        }, 1000);
    }
    const dropdownColor = currentMode === "dark" ? "#333333" : `white`;
    const dropdownText = currentMode === "dark" ? "#ECECEC" : `#555555`;
    return (
        <>
            {requestComplete && data?.status && (
                <SuccessMessageBox success={successMsg} />
            )}
            {isSubmit && requestComplete && !data?.status && (
                <MessageBox error={data?.message} />
            )}
            {isSubmit && error && !data?.status && <MessageBox error={errormsg} />}
            {viewData?.length === 0 || UserEditData?.fetching ? (
                <Loader className="h-[calc(100vh_-_15rem)]" />
            ) : (
                <form className="mx-[35px]" onSubmit={handleSubmit}>
                    <div className="flex mb-5 space-x-7 viewStyles">
                        <div className="basis-1/2">
                            <span className="block text-sm pb-3">
                                <span className="text-[red] mr-1">*</span>First
                                Name
                            </span>
                            <Input
                                name="firstname"
                                value={values?.firstname}
                                onChange={handleChange}
                                maxLength={50}
                                placeholder="Enter the first name"
                                className={`border-[1px] truncate border-[rgba(85,85,85,.3)] rounded-[8px] px-[20px] py-2 w-full bg-${currentMode}-810`}
                            />
                            {errors && (
                                <p className="text-light-50 text-xs">
                                    {errors?.firstname}
                                </p>
                            )}
                        </div>
                        <div className="basis-1/2">
                            <span className="block text-sm pb-3">
                                <span className="text-[red] mr-1">*</span>Last
                                Name
                            </span>
                            <Input
                                name="lastname"
                                value={values?.lastname}
                                onChange={handleChange}
                                maxLength={50}
                                placeholder="Enter the last name"
                                className={`border-[1px] truncate border-[rgba(85,85,85,.3)] rounded-[8px] px-[20px] py-2 w-full bg-${currentMode}-810`}
                            />
                            {errors && (
                                <p className="text-light-50 text-xs">
                                    {errors?.lastname}
                                </p>
                            )}
                        </div>
                    </div>
                    <div className="flex mb-5 space-x-7 viewStyles  e-disabled">
                        <div className="basis-1/2">
                            <span className="block text-sm pb-3 ">
                                <span className="text-[red] mr-1">*</span>
                                Business Email
                            </span>
                            {viewData?.isactivated === false &&
                                viewData.user_isactive === false ? (
                                <span>
                                    <Input
                                        disabled={false}
                                        onChange={handleChange}
                                        value={values?.user_emailaddress}
                                        placeholder="patrick@email.com"
                                        maxLength={250}
                                        name="user_emailaddress"
                                        className={`border-[1px] truncate border-[rgba(85,85,85,.3)] rounded-[8px] px-[20px] py-2 w-full text-transform: lowercase bg-${currentMode}-810`}
                                    />
                                    {errors && (
                                        <p className="text-light-50 text-xs">
                                            {errors?.user_emailaddress}
                                        </p>
                                    )}
                                </span>
                            ) : (
                                <Input
                                    disabled={true}
                                    onChange={handleChange}
                                    value={values?.user_emailaddress}
                                    placeholder="patrick@email.com"
                                    maxLength={250}
                                    name="user_emailaddress"
                                    className={`border-[1px] truncate border-[rgba(85,85,85,.3)] rounded-[8px] px-[20px] py-2 w-full bg-${currentMode}-810`}
                                />
                            )}
                        </div>
                        <div className="basis-1/2">
                            <span className="block text-sm pb-3">
                                <span className="text-[red] mr-1">*</span>
                                Country
                            </span>
                            <Select
                                onChange={handleChange}
                                name="country"
                                value={
                                    +values?.country ||
                                    +values?.country?.country_id ||
                                    "0"
                                }
                                className={`w-full bg-${currentMode}-810 text-${currentMode}-copy`}
                                MenuProps={{
                                    sx: {
                                        "& .MuiMenu-paper": {
                                            backgroundColor: dropdownColor,
                                            color: dropdownText,
                                        },
                                        "& .MuiMenuItem-root:hover": {
                                            backgroundColor:
                                                "rgba(218, 249, 244, 0.3)",
                                        },
                                        "& .Mui-selected": {
                                            backgroundColor:
                                                "rgba(218, 249, 244, 0.3) !important",
                                        },
                                    },
                                }}
                                SelectDisplayProps={{
                                    style: {
                                        color: `${currentMode !== "dark"
                                            ? `#555555`
                                            : `#ECECEC`
                                            }`,
                                    },
                                }}
                                IconComponent={() => (
                                    <img
                                        className="absolute right-[15px] opacity-[.5] pointer-events-none"
                                        src={
                                            currentMode !== "dark"
                                                ? `/images/header/chevronDown.svg`
                                                : `/images/header/chevronDown-white.svg`
                                        }
                                        alt="chevronDown"
                                    />
                                )}
                            >
                                <MenuItem key={0} value={0} name="country">
                                    {"Select"}
                                </MenuItem>
                                {country?.map((elem, i) => {
                                    return (
                                        <MenuItem
                                            key={i + 1}
                                            name="country"
                                            value={elem?.country_id}
                                        >
                                            {elem?.country_name}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                            {errors && (
                                <p className="text-light-50 text-xs">
                                    {errors?.country}
                                </p>
                            )}
                        </div>
                    </div>
                    <div className="flex mb-5 space-x-7 viewStyles">
                        {/* <div className="basis-1/2">
              <span className="block text-sm pb-3 text-[rgba(85,85,85,0.5)]">
                <span className="text-[red] mr-1">*</span>
                Role
              </span>
              <Input
                disabled={true}
                value={values?.role_id?.role}
                className="border-[1px] border-[rgba(85,85,85,.3)] rounded-[8px] px-[20px] py-3 w-full"
                placeholder="Enter your designation"
              />
            </div> */}
                        <div className="basis-1/2">
                            <span className="block text-sm pb-3">
                                Mobile Number
                            </span>
                            {/* <Input
              name="mobilenumber"
              value={values?.mobilenumber}
              onChange={handleChange}
              placeholder="Enter your mobile number"
              className="border-[1px] border-[rgba(85,85,85,.3)] rounded-[8px] px-[20px] py-3 w-full"
              required
            />
            {errors && (
              <p className="text-light-50 text-sm">{errors?.mobilenumber}</p>
            )} */}
                            <ReactPhoneInput
                                value={
                                    countryObj?.country_code ||
                                    values?.mobilenumber
                                }
                                name="mobilenumber"
                                className={`bg-${currentMode}-810`}
                                onChange={(e) => phoneNoSelect(e)}
                            />
                        </div>
                        {/* {errorMsg && (
              <p className="text-light-50 text-sm">Phone number is invalid</p>
            )}  */}
                    </div>
                    <div className="flex mt-6 space-x-3 absolute border-t-[1px] border-[rgba(85,85,85,0.1)] w-full mx-[-35px] px-[35px] py-5 bottom-0">
                        <Button
                            disabled={fetching}
                            title="To Update the User"
                            type="submit"
                            className="bg-light-20 flex text-base font-interR py-[.5rem] px-[1.5rem] rounded-[8px] text-white"
                        >
                            {`Update`}
                        </Button>

                        <Link
                            title="To Cancel"
                            onClick={cartReset}
                            className="bg-light-240 flex text-base font-interR py-[.5rem] px-[1.7rem] rounded-[8px] text-light-40 closeModal"
                        >
                            Cancel
                        </Link>
                    </div>
                </form>
            )}
        </>
    );
};

export default EditUserModal;
