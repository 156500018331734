const Loader = (props) => {
    return (
        <div
            className={`${props?.className} h-full w-full flex justify-center flex-col items-center `}
        >
            <img
                className="w-[7rem]"
                src="/images/gif/Loading-Gif.gif"
                alt="Loading-Gif"
            />
            {props?.children}
        </div>
    );
};

export default Loader;
