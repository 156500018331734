import { useContext } from "react";
import { Link } from "react-router-dom";
import Modal from "../../../components/UI/Modal/Modal";
import { ThemeContext } from "../../../Hooks/Themes/Themeprovider";
import FolderConfigModal from "./FolderConfig";

const FolderConfigs = (props) => {
    const handleClose = () => {
        const btnClose = document.querySelector(".closeFolderModal");
        btnClose.click();
    };
    const { currentMode } = useContext(ThemeContext);

    return (
        <>
            <Modal cartClose={props?.handleClose} className={`${props?.className}`}>
                <div onClick={props?.handleClose} className="p-[6.5px] absolute right-[562px] bg-light-10 cursor-pointer">
                    <img src="/images/common/whiteClose.svg" alt="whiteClose" />
                </div>
                <div
                    className={`w-[562px] bg-${currentMode}-card transition duration-150 ease-out ml-auto flex-col flex h-screen max-h-[-webkit-fill-available] overflow-y-auto`}
                >
                    <div className={` px-[30px] py-[24px] border-b-[1px] border-${currentMode}-590 mb-[1.8rem] relative`}>
                        <h4 className="font-interSb text-lg">Folder Configuration</h4>
                    </div>
                    <FolderConfigModal orgId={props?.orgId} updateOrgList={props?.updateOrgList} cartClose={props?.handleClose} />
                </div>
            </Modal>
        </>
    );
};

export default FolderConfigs;
