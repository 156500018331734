import styles from "./index.module.scss";

const Backdrop = (props) => {
  return (
    <div
      onClick={props?.onClick}
      className={`${styles.backdrop} ${styles[props.className]} ${
        props?.overlap ? `z-[999] ` : ``
      }`}
    ></div>
  );
};

export default Backdrop;
