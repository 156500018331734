import UseForm from "../../Hooks/useForm";
import Button from "../Button";


const EditBox = (props) => {
    return (
        <>
            <div className="space-x-1 items-center flex border-0 p-0">
                <Button
                    onClick={props?.handleClick}
                    className="bg-light-20 p-1 w-[20px] h-[20px]"
                >
                    <img src="/images/common/tickWhite.svg" />
                </Button>
                <Button className="bg-light-20 p-1 text-center w-[20px] h-[20px]" onClick={props?.closeClick}>
                    <img className="mx-auto" src="/images/common/close-white.svg" />
                </Button>
            </div>
        </>
    );
};

export default EditBox;
