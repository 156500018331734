import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Rnd } from "react-rnd";
import { Link, useLocation } from "react-router-dom";
import { useMediaQuery } from "@react-hook/media-query";

export const FixedUpload = () => {
    const location = useLocation();
    const isMediumScreen = useMediaQuery("(max-width: 1366px)");
    const DisplayRole = useSelector((state) => state?.DisplayRolePermissionStateAction);
    const { data: DataList } = DisplayRole;
    const RoleList = DataList?.role_info;
    const RoleMenu = RoleList?.menu;
    const RoleUpload = RoleMenu?.find((elem) => elem?.menu_id === 12);
    const RoleActUpload = RoleUpload?.actions;
    const checkUpload = RoleActUpload?.find((element) => element.id === 21);
    const viewportWidth = window.innerWidth; // Get the width of the viewport
    const viewportHeight = window.innerHeight; // Get the height of the viewport
    const elementWidth = 55; // Set the desired width of the element
    const elementHeight = 50; // Set the desired height of the element
    const initialX = viewportWidth - elementWidth; // Calculate the initial x-coordinate
    const initialY = (viewportHeight - elementHeight) / 2; // Calculate the initial y-coordinate
    // Calculate the maximum y value to restrict within the viewport
    const maxY = viewportHeight - elementHeight;
    // Initialize state to store position and size, and retrieve from localStorage
    const getViewPortWidth = JSON.parse(localStorage.getItem("viewPortWidth"));
    if (!getViewPortWidth) {
        localStorage.setItem("viewPortWidth", JSON.stringify(viewportWidth));
    }
    const initial = () => {
        const savedPosition = JSON.parse(localStorage.getItem("elementPosition"));
        const savedSize = JSON.parse(localStorage.getItem("elementSize"));
        return savedPosition && savedSize && savedPosition?.x == initialX
            ? { ...savedPosition, ...savedSize }
            : {
                  x: initialX,
                  y: initialY,
                  width: elementWidth,
                  height: elementHeight,
              };
    };
    const [elementState, setElementState] = useState(initial);

    useMemo(() => {
        // Save values to localStorage whenever elementState changes
        localStorage.setItem(
            "elementPosition",
            JSON.stringify({
                x: elementState.x,
                y: elementState.y,
            })
        );
        localStorage.setItem(
            "elementSize",
            JSON.stringify({
                width: elementState.width,
                height: elementState.height,
            })
        );
    }, [elementState]);
    return (
        <Rnd
            bounds="window"
            size={{ width: elementState.width, height: elementState.height }}
            position={{ x: elementState.x, y: elementState.y }}
            cancel=".cancel-drag"
            onDragStop={(e, d) => {
                // Update the y position in state, restricting it within the viewport
                setElementState((prev) => ({
                    ...prev,
                    y: d.y,
                }));
            }}
            enableResizing={false}
            disableDragging={isMediumScreen}
            onResizeStop={(e, direction, ref, delta, position) => {
                setElementState((prev) => ({
                    ...prev,
                    y: position.y,
                    width: elementState.width,
                    height: elementState.height,
                }));
            }}
            className="z-[11] fixed p-3">
            {checkUpload?.isallowed == true && location?.pathname !== "/file-upload" && (
                <Link to="/file-upload" className={`fixed cancel-drag fixUpload flex items-center bg-light-20 rounded-l-[8px] p-[11px] right-0`}>
                    <img src="/images/sidebar/fileupload-white.svg" alt="fileupload-white" />
                    <span className={`text-white ml-3`}>Invoice Upload</span>
                </Link>
            )}
        </Rnd>
    );
};
