import axios from "axios";
import authHeader from "./auth-header";
import {
    CATEGORY_FAILURE,
    CATEGORY_REQUEST,
    CATEGORY_SUCCESS,
    DASHBORAD_VENDOR_REQUEST,
    DASHBORAD_VENDOR_SUCCESS,
    DASHBORAD_VENDOR_FAILURE,
    DASHBORAD_DATE_REQUEST,
    DASHBORAD_DATE_SUCCESS,
    DASHBORAD_DATE_FAILURE,
    DASHBORAD_VENDOR_LIST_REQUEST,
    DASHBORAD_VENDOR_LIST_SUCCESS,
    DASHBORAD_VENDOR_LIST_FAILURE,
    GET_TASK_DASHBOARD_REQUEST,
    GET_TASK_DASHBOARD_SUCCESS,
    GET_TASK_DASHBOARD_FAILURE,
    GET_ON_TIME_PROCESS_FAILURE,
    GET_ON_TIME_PROCESS_REQUEST,
    GET_ON_TIME_PROCESS_SUCCESS,
    POST_ON_TIME_PROCESS_FAILURE,
    POST_ON_TIME_PROCESS_REQUEST,
    POST_ON_TIME_PROCESS_SUCCESS,
    GET_TIME_FOR_TIMEPROCESSING_BYORG_REQUEST,
    GET_TIME_FOR_TIMEPROCESSING_BYORG_SUCCESS,
    GET_TIME_FOR_TIMEPROCESSING_BYORG_FAILURE,
} from "../types/types.js";
import configMyiQ from "../../configuration";
import { checkApiStatus } from "../../utils/helpers";

const API_URL = process.env?.REACT_APP_API_URL;
const NODE_API_URL = process.env?.REACT_APP_NODE_API_URL;
export const DashboardCategory = (organisation, userId, type) => async (dispatch) => {
    try {
        dispatch({ type: CATEGORY_REQUEST });
        axios
            .get(`${NODE_API_URL}api/dashBoard/InvoiceCountsWithCategory?organisation=${organisation}&allo_user_id=${userId}&docgroup=${type}`, {
                headers: authHeader(),
            })
            .then((response) => {
                dispatch({
                    type: CATEGORY_SUCCESS,
                    payload: response?.data,
                    doctype: type,
                });
            });
    } catch (error) {
        if (error?.response?.status == 401) {
            checkApiStatus();
        }
        dispatch({
            type: CATEGORY_FAILURE,
        });
    }
};

export const DashboardVendors = (type, organisation, date, userId) => async (dispatch) => {
    try {
        dispatch({ type: DASHBORAD_VENDOR_REQUEST });
        axios
            .get(
                `${NODE_API_URL}api/dashBoard/GetTop10VendorByInvoiceCount?organisation=${organisation}&from_date=${date?.from}&to_date=${date?.to}&allo_user_id=${userId}`,
                {
                    headers: authHeader(),
                }
            )
            .then((response) => {
                dispatch({
                    type: DASHBORAD_VENDOR_SUCCESS,
                    payload: response?.data,
                });
            });
    } catch (error) {
        if (error?.response?.status == 401) {
            checkApiStatus();
        }
        dispatch({
            type: DASHBORAD_VENDOR_FAILURE,
        });
    }
};

export const DashboarChartList = (type, organisation, date, userId) => async (dispatch) => {
    //1- this week, 2- this month, 3- this year
    try {
        dispatch({ type: DASHBORAD_DATE_REQUEST });
        axios
            .get(
                `${NODE_API_URL}api/dashBoard/InvoiceCountChart?organisation=${organisation}&from_date=${date?.from}&to_date=${date?.to}&allo_user_id=${userId}`,
                {
                    headers: authHeader(),
                }
            )
            .then((response) => {
                dispatch({
                    type: DASHBORAD_DATE_SUCCESS,
                    payload: response?.data,
                });
            });
    } catch (error) {
        if (error?.response?.status == 401) {
            checkApiStatus();
        }
        dispatch({
            type: DASHBORAD_DATE_FAILURE,
        });
    }
};

export const DashboardVandorList = (type, organisation, date, userId) => async (dispatch) => {
    try {
        dispatch({ type: DASHBORAD_VENDOR_LIST_REQUEST });

        axios
            .get(
                `${NODE_API_URL}api/dashBoard/GetTop10Vendor?organisation=${organisation}&from_date=${date?.from}&to_date=${date?.to}&allo_user_id=${userId}`,
                {
                    headers: authHeader(),
                }
            )
            .then((response) => {
                dispatch({
                    type: DASHBORAD_VENDOR_LIST_SUCCESS,
                    payload: response?.data,
                });
            });
    } catch (error) {
        if (error?.response?.status == 401) {
            checkApiStatus();
        }
        dispatch({
            type: DASHBORAD_VENDOR_LIST_FAILURE,
        });
    }
};
//GET TASK  DASHBOARD

export const DashboardTaskList = (organisation, userId, task_due) => async (dispatch) => {
    try {
        dispatch({ type: GET_TASK_DASHBOARD_REQUEST });
        axios
            .get(`${NODE_API_URL}api/taskReports/getDashboardTask?org_id=${organisation}&user_id=${userId}&task_due=${task_due}`, {
                headers: authHeader(),
            })
            .then((response) => {
                dispatch({
                    type: GET_TASK_DASHBOARD_SUCCESS,
                    payload: response?.data,
                });
            });
    } catch (error) {
        if (error?.response?.status == 401) {
            checkApiStatus();
        }
        dispatch({
            type: GET_TASK_DASHBOARD_FAILURE,
        });
    }
};
export const GetOnTimeProcessAction = (type, organisation, date, userId) => async (dispatch) => {
    try {
        dispatch({ type: GET_ON_TIME_PROCESS_REQUEST });
        axios
            .get(
                `${NODE_API_URL}api/dashBoard/GetOntimeProcessing?organisation=${organisation}&from_date=${date?.from}&to_date=${date?.to}&allo_user_id=${userId}`,
                {
                    headers: authHeader(),
                }
            )
            .then((response) => {
                dispatch({
                    type: GET_ON_TIME_PROCESS_SUCCESS,
                    payload: response?.data,
                });
            });
    } catch (error) {
        if (error?.response?.status == 401) {
            checkApiStatus();
        }
        dispatch({
            type: GET_ON_TIME_PROCESS_FAILURE,
        });
    }
};
export const PostValidTimeAction = (data) => async (dispatch) => {
    try {
        dispatch({ type: POST_ON_TIME_PROCESS_REQUEST });
        axios
            .post(`${NODE_API_URL}api/dashBoard/UpdateOntimeProcessing`, data, {
                headers: authHeader(),
            })
            .then((response) => {
                dispatch({
                    type: POST_ON_TIME_PROCESS_SUCCESS,
                    payload: response?.data,
                });
            });
    } catch (error) {
        if (error?.response?.status == 401) {
            checkApiStatus();
        }
        dispatch({
            type: POST_ON_TIME_PROCESS_FAILURE,
        });
    }
};

//get time on time processing by org

export const GetOnTimeProcessTimeByOrg = (orgId) => async (dispatch) => {
    try {
        dispatch({ type: GET_TIME_FOR_TIMEPROCESSING_BYORG_REQUEST });
        axios
            .get(`${NODE_API_URL}api/dashBoard/GetOntimeProcessInterval?organisation=${orgId}`, {
                headers: authHeader(),
            })
            .then((response) => {
                dispatch({
                    type: GET_TIME_FOR_TIMEPROCESSING_BYORG_SUCCESS,
                    payload: response?.data,
                });
            });
    } catch (error) {
        if (error?.response?.status == 401) {
            checkApiStatus();
        }
        dispatch({
            type: GET_TIME_FOR_TIMEPROCESSING_BYORG_FAILURE,
        });
    }
};
