import { Checkbox, ClickAwayListener, FormControlLabel, MenuItem, Popper, Select } from "@mui/material";
import _, { remove, upperCase } from "lodash";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../components/Button";
import Input from "../../components/Input";
import Loader from "../../components/Loader";
import Card from "../../components/UI/Card";
import { getReportsAgeingList, reportsAgeingCountTotal, getSummaryAgingList } from "../../redux/actions/report";
import ExportExcel from "../ExportFiles/Excel";
import TogglePopup from "../organisations/Toggle";
import { GET_SUMMARY_AGING_RESET, GET_REPORTS_AGEING_RESET, REPORTS_AGEING_COUNT_RESET } from "../../redux/types/types";
import { getCurrentUser } from "../../redux/actions/auth";
import { ThemeContext } from "../../Hooks/Themes/Themeprovider";
import { encryptId } from "../../utils/helpers";
import { Link, NavLink, useNavigate, useLocation, json } from "react-router-dom";

const Table = (props) => {
    const { currentMode } = useContext(ThemeContext);
    let optionval = ["Invoice Id", "Invoice No", "Vendor Name", "Invoice Date", "Net Amount", "Status"];
    const [values, setValues] = useState(optionval);
    const [showOptions, setShowOptions] = useState("");
    const [isdownloadClick, onClickDownload] = useState(false);
    const [exportFile, setExportFile] = useState(false);
    const [isShow, setisShow] = useState(false);
    const location = useLocation();
    const [VendorName, setVendorName] = useState([]);
    // const [props?.currentPageNo, props?.changePageNo] = useState(1);
    const selAging = props?.selAging;
    const [popstate, setPopState] = useState({
        value: null,
        anchorEl: null,
        id: null,
    });
    const CurrentUser = getCurrentUser();
    const userDate = CurrentUser?.user_date;
    const DateFormat = userDate?.toUpperCase();
    const [placement, setPlacement] = useState();

    const handleClickAway = () => {
        setPopState({ id: false, anchorEl: false, value: "" });
    };
    const handleClick = (newPlacement, value, event, id = null) => {
        setPopState({ value, anchorEl: event.currentTarget, id }, (prev) => placement !== newPlacement || !prev);
        setPlacement(newPlacement);
    };

    const checkChange = (e) => {
        const elem = e.target.value;
        if (!e.target.checked) {
            const Remove = values?.filter((el) => el !== elem);
            setValues(Remove);
            localStorage.setItem("ageingOption", JSON.stringify(values));
        } else {
            setValues([...values, elem]);
            localStorage.setItem("ageingOption", JSON.stringify(values));
        }
    };

    useEffect(() => {
        let Check = JSON.parse(localStorage.getItem("ageingOption"));
        if (Check !== null && Check !== undefined && Check !== "") {
            setValues(JSON.parse(localStorage.getItem("ageingOption")));
        } else {
            localStorage.setItem("ageingOption", JSON.stringify(optionval));
            setValues(optionval);
        }
    }, []);

    useEffect(() => {
        if (!localStorage.getItem("ageingOption")) {
            localStorage.setItem("ageingOption", JSON.stringify(values));
            localStorage.getItem("ageingOption");
        }
    }, [values, props?.agingType]);

    const [refreshValue, handleRefrxeshValue] = useState({
        isStatus: [2],
        invNumber: "",
        isSupplier: "",
    });
    const [isAllSelected, setAllSelected] = useState(false);
    const [isIndeterminate, setIndeterminate] = useState(false);
    // const [props?.currentPageItem, props?.changePageItem] = useState(10);
    const [isSorting, setSorting] = useState(0);
    const [isMount, setIsMount] = useState(false);
    const allocid = localStorage.getItem("UserDropdownId");

    const pageNo = props?.currentPageNo;
    const entries = props?.currentPageItem;

    const pageData = useSelector((state) => state?.getReportAgeingCountState);
    const { dataList: summaryList } = useSelector((state) => state?.GetSummaryAgeingState);
    const pageCount = pageData && pageData.data && pageData.data?.data ? pageData.data.data.data.rec_cnt : 0;
    let start = (pageNo - 1) * entries + 1;

    const nextPageCount = Math.ceil(pageCount / props?.currentPageItem);

    const dispatch = useDispatch();

    const { dataList, requestComplete } = useSelector((state) => state?.GetreportAgeingSummaryState);
    const childCompRef = useRef();
    const exportRef = useRef();
    const onExportItems = (value, id) => {};
    const ExportList = [
        {
            text: "Excel",
            title: "To allow Organisation Access",
            src: "/images/common/excel.svg",
            value: 0,
        },
        {
            text: "CSV",
            title: "To allow Organisation Access",
            src: "/images/common/excel.svg",
            value: 1,
        },
        {
            text: "PDF",
            title: "To View the Organisation Access",
            src: "/images/common/excel.svg",
            value: 2,
        },
        {
            text: "JPG",
            title: "To View the Organisation Access",
            src: "/images/common/excel.svg",
            value: 4,
        },
    ];

    const onFilterItems = (value, id) => {
        setShowOptions(value);
    };
    const filterMenuList = [
        "Invoice Id",
        "Invoice No",
        "Invoice Date",
        "Vendor Name",
        "Net Amount",
        "Status",
        "Created Date",
        "Exception Message",
        "Aging days",
        "Assigned to",
        "Approver",
    ];
    const { dataList: listData, requestComplete: reqComp, fetching, exportData } = useSelector((state) => state?.GetreportAgeingListState);
    const CheckLineItems = JSON.parse(localStorage.getItem("ageingOption"));
    const downloadeExcel = () => {
        if (exportData && selAging == 1) {
            const invoiceExcelData = [];
            exportData?.forEach((item) => {
                let invHeaders = {
                    [values.includes("Invoice Id") && "Invoice Id"]: values.includes("Invoice Id") && item?.inv_seqno,
                    [values.includes("Invoice No") && "Invoice No"]: values.includes("Invoice No") && item?.invoice_no,
                    [values.includes("Vendor Name") && "Vendor Name"]: values.includes("Vendor Name") && item?.vendor,
                    [values.includes("Created Date") && "Created Date"]: values.includes("Created Date") && item?.created_date,
                    // ? moment(item?.created_date).format("YYYY-MM-DD")
                    // : "",
                    [values.includes("Assigned to") && "Assigned To"]: values.includes("Assigned to") && item?.assigned_to,
                    [values.includes("Aging days") && "Aging days"]: values.includes("Aging days") && item?.ageing_days,
                    [values.includes("Approver") && "Approver"]: values.includes("Approver") && item?.approver,
                    [values.includes("Net Amount") && "Net Amount"]: values.includes("Net Amount") && item?.amount,
                    [values.includes("Status") && "Status"]: values.includes("Status") && item?.status,
                    [values.includes("Exception Message") && "Exception Message"]: values.includes("Exception Message") && item?.exp_message,
                    [values.includes("Invoice Date") && "Invoice Date"]: values.includes("Invoice Date") && item?.invoice_date,
                };
                invoiceExcelData.push(_.pickBy(invHeaders));
            });
            ExportExcel({ excelData: invoiceExcelData, fileName: "MyiQ.Ai" });
            onClickDownload(false);
            setExportFile(false);
        }
        if (summaryList && selAging == 2) {
            const invoiceExcelData = [];
            summaryList?.forEach((items) => {
                let AgeingList = {
                    "Vendor Name": items?.vendor_name,
                    "0 to 30 Days": props?.agingType == 1 ? items?.invoice_count_30 : items?.count_30_value,
                    "30 to 60 Days": props?.agingType == 1 ? items?.invoice_count_60 : items?.count_60_value,
                    "60 to 90 Days": props?.agingType == 1 ? items?.invoice_count_90 : items?.count_90_value,
                    "Greater than 90 Days": props?.agingType == 1 ? items?.invoice_count_greater_90 : items?.above_90_value,
                };
                let invoiceFields = _.map(items.invoice_items, (object) => {
                    return _.pick(object, ["label_name", "label_value"]);
                });
                invoiceFields.forEach((elee) => {
                    AgeingList[_.capitalize(elee.label_name.replace("_", " "))] = elee.label_value;
                });
                invoiceExcelData.push(AgeingList);
            });
            ExportExcel({ excelData: invoiceExcelData, fileName: "MyiQ.Ai" });
            onClickDownload(false);
        }
    };
    const onExportExcel = () => {
        if (summaryList?.length > 0) {
            onClickDownload(true);
        }
    };
    if (isdownloadClick && selAging == 2) {
        let downloadInvoiceList = summaryList;
        downloadeExcel(downloadInvoiceList);
    } else if (isdownloadClick && selAging == 1 && exportFile) {
        let downloadInvoiceList = exportData;
        downloadeExcel(downloadInvoiceList);
    }

    const ExportToExcelAgeing = () => {
        setExportFile(true);
        const reqObj = {
            status_type: 1,
            status_id: status?.length === 0 ? [0] : status,
            organisation: +orgId,
            vendor_id: supplier?.length !== 0 ? supplier : null,
            invoice_date_from: fromDate !== null ? moment(new Date(fromDate)).format("YYYY-MM-DD") : fromDate,
            invoice_date_to: toDate !== null ? moment(new Date(toDate)).format("YYYY-MM-DD") : toDate,
            invoice_no: "",
            order_column: "",
            order_by: 0,
            pg_no: 1,
            rec_cnt: pageCount,
            type: ageing,
            alloc_id: +letuserId,
            document_type: 1,
        };
        if (listData !== null) {
            dispatch(getReportsAgeingList(reqObj, "download"));
        }
        if (exportData?.length > 0) {
            onClickDownload(true);
        }
    };
    useEffect(() => {
        if (exportData?.length > 0 && exportFile) {
            onClickDownload(true);
        }
    }, [exportData?.length > 0]);
    const { isSupplier: supplier, isStatus: status, fromDate: fromDate, toDate: toDate, ageing: ageing, page: page } = props;
    const orgId = localStorage.getItem("orgId");
    const letuserId = CurrentUser?.role_id == 1 ? CurrentUser?.id : props?.user;
    const VendorId = supplier?.length !== 0 ? supplier : null;
    const reqCountObj = {
        status_type: 1,
        status_id: status?.length === 0 ? [0] : status,
        organisation: +orgId,
        vendor_id: VendorId,
        invoice_date_from: fromDate !== null ? moment(new Date(fromDate)).format("YYYY-MM-DD") : fromDate,
        invoice_date_to: toDate !== null ? moment(new Date(toDate)).format("YYYY-MM-DD") : toDate,
        invoice_no: "",
        order_column: "",
        order_by: 0,
        pg_no: props?.currentPageNo,
        rec_cnt: props?.currentPageItem,
        type: ageing,
        alloc_id: +letuserId,
        document_type: 1,
    };

    const reqObj = {
        status_type: 1,
        status_id: status?.length === 0 ? [0] : status,
        organisation: +orgId,
        vendor_id: VendorId,
        invoice_date_from: fromDate !== null ? moment(new Date(fromDate)).format("YYYY-MM-DD") : fromDate,
        invoice_date_to: toDate !== null ? moment(new Date(toDate)).format("YYYY-MM-DD") : toDate,
        invoice_no: "",
        order_column: "",
        order_by: 0,
        pg_no: props?.currentPageNo,
        rec_cnt: props?.currentPageItem,
        type: ageing,
        alloc_id: +letuserId,
        document_type: 1,
    };
    const CountCalc = pageCount / props?.currentPageItem;

    const Rounded = Math.ceil(CountCalc);
    const [sortColumn, columnSorting] = useState("");

    const onPageNumberSelect = (event) => {
        let pageNumber = event.target.value;
        props?.changePageNo(parseInt(pageNumber));
    };
    const onPageItemSelect = (event) => {
        setAllSelected(false);
        let pageItemVal = event.target.value;
        props?.changePageItem(parseInt(pageItemVal));
        props?.changePageNo(1);
    };
    let EmptyArr = [];
    for (let i = 0; i < Rounded; i++) {
        EmptyArr.push(i);
    }
    const previousBtnClick = () => {
        if (isAllSelected === true) {
            setAllSelected(false);
        }
        if (isIndeterminate == true) {
            setIndeterminate(false);
        }
        props?.changePageNo(props?.currentPageNo - 1);
    };
    const nextBtnClick = () => {
        if (isAllSelected === true) {
            setAllSelected(false);
        }
        if (isIndeterminate == true) {
            setIndeterminate(false);
        }
        props?.changePageNo(props?.currentPageNo + 1);
    };
    const onSortingIconChange = () => {
        if (requestComplete) {
            if (isSorting === 1) {
                document.getElementById(sortColumn).classList.add("Up");
            } else {
                document.getElementById(sortColumn).classList.remove("Up");
            }
        }
    };
    useEffect(() => {
        if (isMount) {
            async function fetchEmployees() {
                if (selAging == 1) {
                    //  props?.changePageNo(1);
                    //  props?.changePageItem(10)
                    await dispatch(reportsAgeingCountTotal(reqCountObj));
                    await dispatch(getReportsAgeingList(reqObj));
                    onSortingIconChange();
                }
            }
            fetchEmployees();
        } else {
            setIsMount(true);
            dispatch({ type: GET_SUMMARY_AGING_RESET });
            dispatch({ type: REPORTS_AGEING_COUNT_RESET });
            dispatch({ type: GET_REPORTS_AGEING_RESET });
        }
    }, [dispatch, props?.currentPageNo, props?.currentPageItem, sortColumn, isSorting, props?.clickTrack, page]);

    const { dataList: summaryData, requestComplete: reqCompl } = useSelector((state) => state?.GetreportAgeingListState);
    let totalPageCount = listData ? listData.length * (props?.currentPageNo ? props?.currentPageNo : 1) : 0;
    const totalPageExceed = props?.currentPageNo * props?.currentPageItem;
    if (totalPageExceed >= pageCount) {
        totalPageCount = pageCount;
    }
    const dropdownColor = currentMode === "dark" ? "#333333" : `white`;
    const dropdownText = currentMode === "dark" ? "#ECECEC" : `#555555`;
    const dropdownShadow = currentMode !== "dark" ? "0px 4px 20px rgba(238, 238, 238, 0.5)" : "";
    const dropdownBorder = currentMode !== "dark" ? "223, 225, 226, 0.56" : "";

    async function OpenDetails(name, id) {
        props?.setDrillOpen(true);
        setisShow(true);
        props?.setSupplierValue([name]);
        setVendorName(name);
        reqObj.vendor_id = [name];
        reqCountObj.vendor_id = [name];
        // props?.setSupplierValue([{ vendor_name: name, org_id: 0 }])
        if (id == 1) {
            props?.setAgeingValue(1);
            reqObj.type = 1;
            reqCountObj.type = 1;
        } else if (id == 2) {
            props?.setAgeingValue(2);
            reqObj.type = 2;
            reqCountObj.type = 2;
        } else if (id == 3) {
            props?.setAgeingValue(3);
            reqObj.type = 3;
            reqCountObj.type = 3;
        } else if (id == 4) {
            props?.setAgeingValue(4);
            reqObj.type = 4;
            reqCountObj.type = 4;
        }
        await dispatch(reportsAgeingCountTotal(reqCountObj));
        await dispatch(getReportsAgeingList(reqObj));
        props?.setSelectAging(1);
    }
    const DisplayRole = useSelector((state) => state?.DisplayRolePermissionStateAction);
    const { data: DataList } = DisplayRole;
    const RoleList = DataList?.role_info;
    const RoleMenu = RoleList?.menu;
    const RoleProcess = RoleMenu?.find((elem) => elem?.menu_id === 8);
    const RoleProcessAction = RoleProcess?.actions;
    const RoleActProcessView = RoleProcessAction?.find((element) => element.id === 3);
    const ViewOpen = RoleActProcessView?.isallowed;
    return (
        <>
            {selAging == 1 ? (
                <Card className="mb-[4rem]">
                    <div className="flex justify-between">
                        {listData?.length !== 0 && (
                            <div className="flex my-auto px-[30px] py-[20px]">
                                <div className="">
                                    <span>Show</span>
                                    <Select
                                        name="entrycount"
                                        className={`insideTble mx-3 text-xs entries bg-${currentMode}-card`}
                                        value={props?.currentPageItem}
                                        onChange={(e) => onPageItemSelect(e)}
                                        IconComponent={() => (
                                            <img
                                                className="absolute opacity-50 right-[8px] pointer-events-none"
                                                src={
                                                    currentMode !== "dark" ? `/images/header/chevronDown.svg` : `/images/header/chevronDown-white.svg`
                                                }
                                            />
                                        )}
                                        SelectDisplayProps={{
                                            style: {
                                                paddingTop: "5px",
                                                display: "flex",
                                                paddingBottom: "5px",
                                                paddingLeft: "10px",
                                                border: 0,
                                                color: currentMode === "dark" ? `#ECECEC` : `#555555`,
                                                paddingRight: "25px",
                                            },
                                        }}
                                        MenuProps={{
                                            sx: {
                                                "& .MuiMenu-paper": {
                                                    backgroundColor: dropdownColor,
                                                    color: dropdownText,
                                                    boxShadow: dropdownShadow,
                                                    border: dropdownBorder,
                                                    borderRadius: "8px",
                                                },
                                            },
                                        }}
                                    >
                                        <MenuItem value={10}>{10}</MenuItem>
                                        <MenuItem value={15}>{15}</MenuItem>
                                        <MenuItem value={20}>{20}</MenuItem>
                                        <MenuItem value={25}>{25}</MenuItem>
                                        <MenuItem value={50}>{50}</MenuItem>
                                        <MenuItem value={75}>{75}</MenuItem>
                                        <MenuItem value={100}>{100}</MenuItem>
                                    </Select>
                                    <span>entries</span>
                                </div>
                            </div>
                        )}
                        {listData?.length !== 0 && (
                            <div className="flex items-center space-x-2 px-[30px] py-[20px]">
                                <div>
                                    <Button
                                        title="To Export Invoice to Excel"
                                        onClick={selAging == 1 ? () => ExportToExcelAgeing() : () => onExportExcel()}
                                        className={`export-step px-5 py-2 rounded-[8px] font-interM text-sm flex justify-center items-center border-[1px] text-${currentMode}-copy  border-light-20`}
                                    >
                                        <img className="mr-[.4rem]" src="/images/common/download-excel.svg" alt="upload" /> Download
                                    </Button>
                                </div>
                            </div>
                        )}
                    </div>

                    {/* margin below while changing the details */}
                    <div className={`heightFix invoices relative report ${listData?.length == 0 ? `rounded-t-[8px]` : ``}`}>
                        <table className="table-auto TblNormal invoice reports w-full">
                            <thead className="sticky top-0 z-[1]">
                                <tr>
                                    {values?.includes("Invoice Id") ? <th>Invoice Id </th> : ""}
                                    {values?.includes("Invoice No") ? <th>Invoice Number </th> : ""}
                                    {values?.includes("Vendor Name") ? <th>Vendor Name </th> : ""}
                                    {values?.includes("Invoice Date") ? <th>Invoice Date </th> : ""}
                                    {values?.includes("Net Amount") ? <th className={listData?.length > 1 ? "text-end" : ""}>Net Amount </th> : ""}
                                    {values?.includes("Created Date") ? <th>Created Date </th> : ""}
                                    {values?.includes("Status") ? <th>Status </th> : ""}
                                    {values?.includes("Aging days") ? <th>Aging days </th> : ""}
                                    {values?.includes("Exception Message") ? <th>Exception Message </th> : ""}
                                    {values?.includes("Assigned to") ? <th>Assigned To</th> : ""}
                                    {values?.includes("Approver") ? <th>Approver </th> : ""}

                                    <th>
                                        <img
                                            className="cursor-pointer max-w-[inherit] convertWhite"
                                            onClick={(e) => handleClick("bottom-end", 1, e)}
                                            src="/images/main/columns.svg"
                                            alt="columns"
                                        />
                                    </th>
                                </tr>
                            </thead>
                            {fetching ? (
                                <div className="grid place-items-center absolute left-[50%] translate-x-[-50%]">
                                    <Loader className="h-[calc(100vh_-_26rem)] w-full" />
                                </div>
                            ) : (
                                <>
                                    <tbody>
                                        {listData?.length !== 0 ? (
                                            listData?.map((elem, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td className={`${!ViewOpen ? "pointer-events-none" : ""}`}>
                                                            <Link
                                                                state={{ from: location.pathname }}
                                                                to={`${"/invoice-view?"}${encryptId(
                                                                    `{"inv":${+elem?.id},"type":${7},"seq":"${+elem?.inv_seqno}","status":${
                                                                        elem?.ext_status
                                                                    }}`
                                                                )}
                                                           `}
                                                                style={{
                                                                    textDecoration: "underline",
                                                                }}
                                                                className="truncate w-[150px]"
                                                                title={elem?.inv_seqno}
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                            >
                                                                {elem?.inv_seqno}
                                                            </Link>
                                                        </td>
                                                        <td className={`${!ViewOpen ? "pointer-events-none" : ""}`}>
                                                            <Link
                                                                state={{ from: location.pathname }}
                                                                to={`${"/invoice-view?"}${encryptId(
                                                                    `{"inv":${+elem?.id},"type":${7},"seq":"${+elem?.inv_seqno}","status":${
                                                                        elem?.ext_status
                                                                    }}`
                                                                )}
                                                           `}
                                                                style={{
                                                                    textDecoration: "underline",
                                                                }}
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                            >
                                                                {elem?.invoice_no}
                                                            </Link>
                                                        </td>
                                                        <td>{elem?.vendor}</td>
                                                        <td>{elem?.invoice_date}</td>
                                                        <td className="text-end">{elem?.amount}</td>
                                                        {values?.includes("Created Date") ? <td>{elem?.created_date}</td> : ""}
                                                        {values?.includes("Status") ? (
                                                            <td>
                                                                <Button
                                                                    className={`cursor-default ${
                                                                        elem?.status === "Processed"
                                                                            ? `bg-light-480`
                                                                            : elem?.status === "Approved"
                                                                            ? `bg-light-490`
                                                                            : elem?.status === "Rejected"
                                                                            ? `bg-light-180`
                                                                            : elem?.status === "Submitted"
                                                                            ? `bg-light-510`
                                                                            : elem?.status === "Posted"
                                                                            ? `bg-light-510`
                                                                            : elem?.status === "ForApproval"
                                                                            ? `bg-light-520`
                                                                            : ""
                                                                    } w-full max-w-[100px] font-interR rounded-[5px] py-[2px] px-3 text-black`}
                                                                >
                                                                    {elem?.status}
                                                                </Button>
                                                            </td>
                                                        ) : (
                                                            ``
                                                        )}

                                                        {values?.includes("Aging days") ? <td>{elem?.ageing_days}</td> : ``}

                                                        {values?.includes("Exception Message") ? <td>{elem?.exp_message}</td> : ``}
                                                        {values?.includes("Assigned to") ? <td>{elem?.assigned_to}</td> : ""}
                                                        {values?.includes("Approver") ? <td>{elem?.approver}</td> : ""}
                                                        <td></td>
                                                    </tr>
                                                );
                                            })
                                        ) : (
                                            <div className="absolute top-[50%] left-[50%] translate-y-[-50%] translate-x-[-50%]">
                                                <img src="/images/common/delivery.png" alt="delivery" />
                                                <h4 className="font-interSb text-center mt-3">No data found</h4>
                                            </div>
                                        )}
                                    </tbody>
                                </>
                            )}
                        </table>
                    </div>

                    {/* {addedInvoice?.length !== 0 && ProcessInv?.dataList !== null && ( */}
                    <ClickAwayListener mouseEvent="onMouseDown" touchEvent="onTouchStart" onClickAway={handleClickAway}>
                        <Popper
                            className={`bg-${currentMode}-card text-${currentMode}-copy  p-4 border-[1px] z-[12] border-${currentMode}-700  shadow-[0px_4px_20px_rgba(238,238,238,0.5)] rounded-[10px] reportsPopper `}
                            open={1 === popstate.value}
                            anchorEl={popstate.anchorEl}
                            id={1}
                            placement={placement}
                        >
                            <ul>
                                {filterMenuList?.map((el, i) => {
                                    return (
                                        <li
                                            key={i}
                                            className={`transition duration-300 ease-in-out mx-[-17px]  py-1 px-[20px] hover:bg-${currentMode}-960`}
                                        >
                                            <Button className={`flex ${1} items-center justify-between font-interR`}>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            size="small"
                                                            value={el}
                                                            onChange={checkChange}
                                                            disabled={!Boolean(optionval?.indexOf(el) === -1)}
                                                            defaultChecked={!Boolean(values?.indexOf(el) === -1)}
                                                            sx={{
                                                                padding: 0,
                                                                color: "#008785",
                                                                margin: "0 12px",
                                                                "&.Mui-checked": {
                                                                    color: "#008785",
                                                                },
                                                            }}
                                                        />
                                                    }
                                                    label={el}
                                                />
                                            </Button>
                                        </li>
                                    );
                                })}
                            </ul>
                        </Popper>
                    </ClickAwayListener>
                    <TogglePopup ref={exportRef} menuList={ExportList} selectedItemChange={onExportItems} />
                    {
                        <div className={`bg-${currentMode}-460 flex justify-between items-center py-[10px] px-[30px]`}>
                            {listData?.length !== 0 && (
                                <div>
                                    <div>
                                        Showing <span>{`${start} to ${totalPageCount}`}</span> entries of <span>{pageCount}</span>
                                    </div>
                                </div>
                            )}

                            <div className="flex items-center">
                                <span>The page you’re on</span>
                                <Select
                                    name=""
                                    className={`bg-${currentMode}-card insideTble mx-3 text-xs  entries`}
                                    id=""
                                    value={props?.currentPageNo}
                                    onChange={(e) => onPageNumberSelect(e)}
                                    IconComponent={() => (
                                        <img
                                            className="absolute opacity-50 right-[8px] pointer-events-none"
                                            src={currentMode !== "dark" ? `/images/header/chevronDown.svg` : `/images/header/chevronDown-white.svg`}
                                        />
                                    )}
                                    SelectDisplayProps={{
                                        style: {
                                            paddingTop: "5px",
                                            display: "flex",
                                            paddingBottom: "5px",
                                            paddingLeft: "10px",
                                            border: 0,
                                            color: currentMode === "dark" ? `#ECECEC` : `#555555`,
                                            paddingRight: "25px",
                                        },
                                    }}
                                    MenuProps={{
                                        sx: {
                                            "& .MuiMenu-paper": {
                                                backgroundColor: dropdownColor,
                                                color: dropdownText,
                                                boxShadow: dropdownShadow,
                                                border: dropdownBorder,
                                                borderRadius: "8px",
                                            },
                                        },
                                    }}
                                >
                                    {EmptyArr?.map((elem) => (
                                        <MenuItem value={elem + 1}>{elem + 1}</MenuItem>
                                    ))}
                                </Select>
                                <div className={`flex space-x-2 border-l-[1px] pl-3 border-${currentMode}-830`}>
                                    <Button
                                        title="Previous page"
                                        disabled={props?.currentPageNo > 1 ? false : true}
                                        onClick={() => previousBtnClick()}
                                        className={`border-${currentMode}-840 border-[1px] rounded-[5px] bg-${currentMode}-card px-2 py-3`}
                                    >
                                        <img className="rotate-180 convertWhite" src="/images/common/arrowRight.svg" alt="arrowRight" />
                                    </Button>

                                    <Button
                                        title="Next page"
                                        className={`border-${currentMode}-840 border-[1px] rounded-[5px] bg-${currentMode}-card px-2 py-3`}
                                        disabled={props?.currentPageNo >= nextPageCount ? true : false}
                                        onClick={() => nextBtnClick()}
                                        value={props?.currentPageNo}
                                    >
                                        <img className="convertWhite" src="/images/common/arrowRight.svg" alt="arrowRight" />
                                    </Button>
                                </div>
                            </div>
                        </div>
                    }
                    {/* )} */}
                </Card>
            ) : (
                <Card className="mb-[4rem]">
                    {summaryList?.length !== 0 && (
                        <div className="flex justify-end px-[30px] py-[20px]">
                            <div>
                                <Button
                                    title="To Export Invoice to Excel"
                                    // onClick={handleClick("top-start")}
                                    onClick={() => onExportExcel()}
                                    className={`border-20 export-step px-5 py-2 rounded-[8px] font-interM text-sm flex justify-center items-center border-[1px] text-${currentMode}-copy border-light-20`}
                                >
                                    <img className="mr-[.4rem]" src="/images/common/download-excel.svg" alt="upload" /> Download
                                </Button>
                            </div>
                        </div>
                    )}
                    <div className={`heightFix invoices relative report  ${summaryList?.length == 0 ? `rounded-t-[8px]` : ``}`}>
                        <table className="table-auto TblNormal invoice mb-0 reports w-full">
                            <thead className={`sticky top-0 z-[1]`}>
                                <tr>
                                    <th>Vendor Name</th>
                                    <th>0 to 30 Days </th>
                                    <th>31 to 60 Days </th>
                                    <th>61 to 90 Days </th>
                                    <th>{`> 90 Days`} </th>
                                </tr>
                            </thead>
                            {fetching ? (
                                <div className="grid place-items-center absolute left-[50%] translate-x-[-50%]">
                                    <Loader className="h-[calc(100vh_-_26rem)] w-full" />
                                </div>
                            ) : (
                                <>
                                    <tbody>
                                        {summaryList !== null && summaryList?.length !== 0 ? (
                                            summaryList?.map((elem, index) => {
                                                const LastEl = index === summaryList.length - 1;
                                                return (
                                                    <tr
                                                        key={index}
                                                        className={
                                                            LastEl ? `bg-${currentMode}-450 sticky bottom-0 left-0 right-0 cursor-default` : ``
                                                        }
                                                    >
                                                        <td
                                                            className={`
                                                               ${LastEl ? `font-interSb` : ``} cursor-default `}
                                                        >
                                                            <p className="w-[450px] truncate">
                                                                {" "}
                                                                {props?.agingType == 2 && elem?.vendor_name == "TOTAL COUNT"
                                                                    ? "TOTAL VALUE"
                                                                    : elem?.vendor_name}
                                                            </p>
                                                        </td>
                                                        <td
                                                            onClick={() => OpenDetails(elem?.vendor_name, 1)}
                                                            className={`
                                                               ${LastEl ? `font-interSb` : ``} cursor-pointer hover:text-[#028C78] hover:underline`}
                                                        >
                                                            {props?.agingType == 1 ? elem?.invoice_count_30 : elem?.count_30_value}
                                                        </td>
                                                        <td
                                                            onClick={() => OpenDetails(elem?.vendor_name, 2)}
                                                            className={`
                                                               ${LastEl ? `font-interSb` : ``} cursor-pointer hover:text-[#028C78] hover:underline`}
                                                        >
                                                            {props?.agingType == 1 ? elem?.invoice_count_60 : elem?.count_60_value}
                                                        </td>
                                                        <td
                                                            onClick={() => OpenDetails(elem?.vendor_name, 3)}
                                                            className={`
                                                               ${LastEl ? `font-interSb` : ``} cursor-pointer hover:text-[#028C78] hover:underline`}
                                                        >
                                                            {props?.agingType == 1 ? elem?.invoice_count_90 : elem?.count_90_value}
                                                        </td>
                                                        <td
                                                            onClick={() => OpenDetails(elem?.vendor_name, 4)}
                                                            className={`
                                                               ${LastEl ? `font-interSb` : ``} cursor-pointer hover:text-[#028C78] hover:underline`}
                                                        >
                                                            {props?.agingType == 1 ? elem?.invoice_count_greater_90 : elem?.above_90_value}
                                                        </td>
                                                    </tr>
                                                );
                                            })
                                        ) : (
                                            <div className="absolute top-[50%] left-[50%] translate-y-[-50%] translate-x-[-50%]">
                                                <img src="/images/common/delivery.png" alt="delivery" />
                                                <h4 className="font-interSb text-center mt-3">No data found</h4>
                                            </div>
                                        )}
                                    </tbody>
                                </>
                            )}
                        </table>
                    </div>

                    {/* {addedInvoice?.length !== 0 && ProcessInv?.dataList !== null && ( */}
                    <TogglePopup ref={exportRef} menuList={ExportList} selectedItemChange={onExportItems} />
                </Card>
            )}
        </>
    );
};

export default Table;
