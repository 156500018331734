import { useContext } from "react";
import Modal from "../../components/UI/Modal/Modal";
import Button from "../../components/Button";
import Card from "../../components/UI/Card";
import { ThemeContext } from "../../Hooks/Themes/Themeprovider";
import { useSelector } from "react-redux";
import moment from "moment";
import Loader from "../../components/Loader";

const HistoryModal = ({ historyModalOpen, historyModalClose, className, currencyCode }) => {
    const { currentMode } = useContext(ThemeContext);
    const modalTitle = `${currencyCode} - Exchange Rate`;
    const HistoryData = useSelector((state) => state?.historyExchangeRateState);
    const userDateFormat = localStorage?.getItem("userDateFormat");
    const tableData = HistoryData?.dataList;
    // const tableData = [
    //     {
    //         validFrom: "01/01/2023",
    //         currencyCode: 1.0,
    //         isCurrent: true,
    //     },
    //     { validFrom: "01/01/2023", currencyCode: 0.014 },
    //     { validFrom: "01/01/2023", currencyCode: 0.014 },
    //     {
    //         validFrom: "01/01/2023",
    //         currencyCode: 1.0,
    //     },
    //     {
    //         validFrom: "01/01/2023",
    //         currencyCode: 1.0,
    //     },
    //     {
    //         validFrom: "01/01/2023",
    //         currencyCode: 1.0,
    //     },
    //     {
    //         validFrom: "01/01/2023",
    //         currencyCode: 1.0,
    //     },
    //     {
    //         validFrom: "01/01/2023",
    //         currencyCode: 1.0,
    //     },
    //     {
    //         validFrom: "01/01/2023",
    //         currencyCode: 1.0,
    //     },
    //     {
    //         validFrom: "01/01/2023",
    //         currencyCode: 1.0,
    //     },
    //     {
    //         validFrom: "01/01/2023",
    //         currencyCode: 1.0,
    //     },
    //     {
    //         validFrom: "01/01/2023",
    //         currencyCode: 1.0,
    //     },
    //     {
    //         validFrom: "01/01/2023",
    //         currencyCode: 1.0,
    //     },
    // ];
    return (
        <Modal historymodalOpen={historyModalOpen} historymodalClose={historyModalClose} center="center" className={`${className}`}>
            <Card>
                <div
                    className={`flex flex-col relative min-w-[460px] min-h-[152px] max-h-[340px] bg-${currentMode}-card text-${currentMode}-copy border-${currentMode}-700 rounded-[10px] ${
                        currentMode === "dark" ? "" : "shadow-[0px_4px_20px_rgba(238,238,238,0.5)]"
                    }`}
                >
                    {HistoryData?.fetching ? (
                        <Loader className="h-[calc(100vh_-_17rem)]" />
                    ) : (
                        <>
                            <div className="flex justify-between items-center py-0 px-6 mt-[12px]">
                                <p className={`text-xs`}>Recent Activity</p>
                                <Button className="text-gray-500 hover:text-gray-700 mt-4" onClick={historyModalClose}>
                                    <img src="/images/common/closeBlk.svg" alt="closeBlk" className="convertWhite w-3.5 h-3.5 mr-3" />
                                </Button>
                            </div>
                            <span className="text-lg font-bold mb-2 px-6">{modalTitle}</span>
                            <div className="relative report overflow-y-auto" style={{ maxHeight: "340px" }}>
                                <table className="rolemasterTable lastBorder text-left w-full">
                                    <thead className="sticky top-0">
                                        <tr>
                                            <th className="px-4 pl-10 !rounded-tl-[0]">Valid From</th>
                                            <th className="px-4 pl-10 !rounded-tr-[0]">Exchange Rate</th>
                                        </tr>
                                    </thead>
                                    <tbody className="border border-gray-300 text-left">
                                        {tableData?.length > 0 ? (
                                            tableData?.map((row, index) => (
                                                <tr key={index}>
                                                    <td className="px-4 py-3 pl-10">
                                                        {moment(row?.validfrom_date).isValid()
                                                            ? moment(row?.validfrom_date).format(userDateFormat?.toUpperCase())
                                                            : ""}
                                                    </td>
                                                    <td className="px-4 py-3 pl-10">
                                                        {row?.exchange_rate}
                                                        {row.validto_date == null ? (
                                                            <span className={`ml-4 p-0.5 rounded bg-[#d3faf4] text-black py-[3px] px-[2px] `}>
                                                                Current Rate
                                                            </span>
                                                        ) : (
                                                            ""
                                                        )}
                                                    </td>{" "}
                                                    {/* Added indicator */}
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan="2">
                                                    <div className="bg-white">
                                                        <div className="text-center">
                                                            <img className="mx-auto" src="/images/common/delivery.png" alt="delivery" />
                                                            <h4 className="font-interSb">No Data found</h4>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </>
                    )}
                </div>
            </Card>
        </Modal>
    );
};

export default HistoryModal;
