import axios from "axios";
import configMyiQ from "../../configuration";
import authHeader from "./auth-header";
import localforage from "localforage";
import Cookies from "js-cookie";
import {
    GET_ORGANISATION_FAILURE,
    GET_ORGANISATION_REQUEST,
    GET_ORGANISATION_SUCCESS,
    GET_ORGCOUNT_FAILURE,
    GET_ORGCOUNT_REQUEST,
    GET_ORGCOUNT_SUCCESS,
    ORG_ADD_FAILURE,
    ORG_ADD_REQUEST,
    ORG_ADD_SUCCESS,
    ORG_EDIT_FAILURE,
    ORG_EDIT_REQUEST,
    ORG_EDIT_SUCCESS,
    ORG_VIEW_FAILURE,
    ORG_VIEW_REQUEST,
    ORG_VIEW_SUCCESS,
    ORG_STATUS_UPDATE_REQUEST,
    ORG_STATUS_UPDATE_SUCCESS,
    ORG_STATUS_UPDATE_FAILURE,
    ORG_DOMAIN_REQUEST,
    ORG_DOMAIN_SUCCESS,
    ORG_DOMAIN_FAILURE,
    ORG_DOMAIN_RESET,
    ORG_VIEW_RESET,
    GET_ORGLIST_DROP_DOWN_REQUEST,
    GET_ORGLIST_DROP_DOWN_SUCCESS,
    GET_ORGLIST_DROP_DOWN_FAILURE,
    GET_SAP_FINANCIAL_YEAR_SUCCESS,
    GET_SAP_FINANCIAL_YEAR_REQUEST,
    GET_SAP_FINANCIAL_YEAR_FAILURE,
} from "../types/types";
import { checkApiStatus } from "../../utils/helpers";
const Token = localStorage.getItem("token");
const API_URL = process.env?.REACT_APP_API_URL;
const API_URL_NODE = process.env?.REACT_APP_NODE_API_URL;

//orglist
export const getOrganisation = (data) => async (dispatch) => {
    try {
        dispatch({ type: GET_ORGANISATION_REQUEST });

        axios
            .post(`${API_URL}Organisation/GetOrganisationList`, data, {
                headers: authHeader(),
            })
            .then((response) => {
                dispatch({
                    type: GET_ORGANISATION_SUCCESS,
                    payload: response?.data?.datalist,
                });
            })
            .catch((ex) => {
                if (ex?.response?.status == 401) {
                    checkApiStatus();
                }
                dispatch({
                    type: GET_ORGANISATION_FAILURE,
                    payload: ex,
                });
            });
    } catch (error) {
        dispatch({
            type: GET_ORGANISATION_FAILURE,
        });
    }
};
//organisationcount
export const organisationCount = (data) => async (dispatch) => {
    try {
        dispatch({ type: GET_ORGCOUNT_REQUEST });

        axios
            .post(`${API_URL}Organisation/GetOrgCount`, data, {
                headers: authHeader(),
            })
            .then((response) => {
                dispatch({
                    type: GET_ORGCOUNT_SUCCESS,
                    payload: response,
                });
            });
    } catch (error) {
        if (error?.response?.status == 401) {
            checkApiStatus();
        }
        dispatch({
            type: GET_ORGCOUNT_FAILURE,
        });
    }
};
//vieworganisation
export const organisationView = (id) => async (dispatch) => {
    dispatch({ type: ORG_VIEW_REQUEST });

    axios
        .get(`${API_URL_NODE}api/Organisation/GetOrganisationById?organisation=${id}`, {
            headers: authHeader(),
        })
        .then((response) => {
            dispatch({
                type: ORG_VIEW_SUCCESS,
                payload: response.data?.data,
            });
        })
        .catch((error) => {
            if (error?.response?.status == 401) {
                checkApiStatus();
            }
            dispatch({
                type: ORG_VIEW_FAILURE,
            });
        });

    dispatch({ type: ORG_VIEW_RESET });
};

//editorganisation
export const organisationEdit = (data) => async (dispatch) => {
    try {
        dispatch({ type: ORG_EDIT_REQUEST });

        axios
            .post(`${API_URL_NODE}api/Organisation/UpdateOrganisation`, data, {
                headers: authHeader(),
            })
            .then((response) => {
                dispatch({
                    type: ORG_EDIT_SUCCESS,
                    payload: response.data,
                });
            })
            .catch((error) => {
                if (error?.response?.status == 401) {
                    checkApiStatus();
                }
                dispatch({
                    type: ORG_EDIT_FAILURE,
                    payload: error.response.data,
                });
            });
    } catch (error) {
        if (error?.response?.status == 401) {
            checkApiStatus();
        }
        dispatch({
            type: ORG_EDIT_FAILURE,
        });
    }
};
export const organisationAdd = (data) => async (dispatch) => {
    try {
        dispatch({ type: ORG_ADD_REQUEST });
        axios
            .post(`${API_URL_NODE}api/Organisation/AddOrganisation`, data, {
                headers: authHeader(),
            })
            .then((response) => {
                dispatch({
                    type: ORG_ADD_SUCCESS,
                    payload: response.data,
                });
            })
            .catch((error) => {
                if (error?.response?.status == 401) {
                    checkApiStatus();
                }
                dispatch({
                    type: ORG_ADD_FAILURE,
                    payload: error.response.data,
                });
            });
    } catch (error) {
        if (error?.response?.status == 401) {
            checkApiStatus();
        }
        dispatch({
            type: ORG_ADD_FAILURE,
            payload: error.response.data,
        });
    }
};

export const organisationUpdateStatus = (data) => async (dispatch) => {
    try {
        dispatch({ type: ORG_STATUS_UPDATE_REQUEST });

        axios
            .get(`${API_URL}Organisation/ActivateOrganisation?id=${data.id}&isactive=${data.status}`, {
                headers: authHeader(),
            })
            .then((response) => {
                dispatch({
                    type: ORG_STATUS_UPDATE_SUCCESS,
                    payload: response.data,
                });
            });
    } catch (error) {
        if (error?.response?.status == 401) {
            checkApiStatus();
        }
        dispatch({
            type: ORG_STATUS_UPDATE_FAILURE,
        });
    }
};

export const organisationDomainDetails = (data) => async (dispatch) => {
    try {
        dispatch({ type: ORG_DOMAIN_REQUEST });

        axios
            .get(`${API_URL}Organisation/OrganisationFromDomain?domainname=${data.domainName}`, {
                headers: authHeader(),
            })
            .then((response) => {
                dispatch({
                    type: ORG_DOMAIN_SUCCESS,
                    payload: response.data,
                });
            })
            .catch((error) => {
                if (error?.response?.status == 401) {
                    checkApiStatus();
                }
                dispatch({
                    type: ORG_DOMAIN_FAILURE,
                    payload: error,
                });
            });
    } catch (error) {
        dispatch({
            type: ORG_DOMAIN_FAILURE,
        });
    }
};

//org dropdown list

export const listOrganisationDropDown = () => async (dispatch) => {
    try {
        dispatch({ type: GET_ORGLIST_DROP_DOWN_REQUEST });

        axios
          .get(`${API_URL_NODE}api/Organisation/DropDown`, {
                headers: authHeader(),
            })
            .then((response) => {
                dispatch({
                    type: GET_ORGLIST_DROP_DOWN_SUCCESS,
                    payload: response?.data?.data,
                });
            })
            .catch((ex) => {
                if (ex?.response?.status == 401) {
                    checkApiStatus();
                }
                dispatch({
                    type: GET_ORGLIST_DROP_DOWN_FAILURE,
                    payload: ex,
                });
            });
    } catch (error) {
        dispatch({
            type: GET_ORGLIST_DROP_DOWN_FAILURE,
        });
    }
};
export const getSapFinancialList = (data) => async (dispatch) => {
    try {
        dispatch({ type: GET_SAP_FINANCIAL_YEAR_REQUEST });

        axios
            .post(`${API_URL_NODE}api/getSAPFinancialYear`, data, {
                headers: authHeader(),
            })
            .then((response) => {
                dispatch({
                    type: GET_SAP_FINANCIAL_YEAR_SUCCESS,
                    payload: response.data,
                });
            })
            .catch((error) => {
                if (error?.response?.status == 401) {
                    checkApiStatus();
                }
                dispatch({
                    type: GET_SAP_FINANCIAL_YEAR_FAILURE,
                    payload: error,
                });
            });
    } catch (error) {
        dispatch({
            type: GET_SAP_FINANCIAL_YEAR_FAILURE,
        });
    }
};
