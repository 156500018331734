import { Link, NavLink } from "react-router-dom";
import axios from "axios";
import { useContext, useState } from "react";
import Button from "../../../components/Button";
import Modal from "../../../components/UI/Modal/Modal";
import AddOrganisationModal from "./AddOrganisation";
import configMyiQ from "../../../configuration";
import { ThemeContext } from "../../../Hooks/Themes/Themeprovider";

const AddOrganisation = (props) => {
    const constantFicalPeriod = props?.orgList?.find((elem) => elem?.organisation_name !== "Demo Company");
    const [fileName, onFileUpload] = useState("");
    const [saveFile, onSaveFile] = useState("");
    const onChange = (e) => {
        let file = e.target.files[0];

        if (file) {
            onSaveFile(file);
            const reader = new FileReader();
            reader.onload = _handleReaderLoaded.bind(this);
            reader.readAsBinaryString(file);
            // handleUpload();
        }
    };
    const _handleReaderLoaded = (e) => {
        let binaryString = e.target.result;
        onFileUpload(btoa(binaryString));
    };
    const handleUpload = () => {
        let formdata = new FormData();

        formdata.append("Organisation_id", localStorage.getItem("orgid"));
        formdata.append("Image", fileName);

        axios({
            url: `${process.env?.REACT_APP_API_URL}Organisation/UploadLogo`,
            method: "POST",
            headers: {
                authorization: `Bearer ${localStorage.getItem("token")}`,
            },
            data: formdata,
        }).then(
            (res) => {},
            (err) => {}
        );
    };
    const handleClose = () => {
        const btnClose = document.querySelector(".closeModalId");
        btnClose.click();
    };
    const { currentMode } = useContext(ThemeContext);
    return (
        <Modal cartClose={props?.handleClose} className={`${props?.className}`}>
            <span tabIndex={0} onClick={() => handleClose()} className="p-[6.5px] absolute z-[1] cursor-pointer right-[712px] bg-light-10">
                <img src="/images/common/whiteClose.svg" alt="whiteClose" />
            </span>

            <div
                className={`max-w-[712px] bg-${currentMode}-card flex flex-col transition duration-150 ease-out  ml-auto h-screen max-h-[-webkit-fill-available]`}
            >
                <div>
                    <AddOrganisationModal
                        constantFicalPeriod={constantFicalPeriod}
                        isOpened={props?.isOpened}
                        orgId={props.orgId}
                        fileName={saveFile}
                        cartClose={props?.handleClose}
                        actionType={props?.actionType}
                    />
                </div>
            </div>
        </Modal>
    );
};

export default AddOrganisation;
