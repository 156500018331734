import axios from "axios";
import authHeader from "./auth-header";
import { checkApiStatus } from "../../utils/helpers";
import {
	GET_RULE_LIST_REQUEST,
	GET_RULE_LIST_SUCCESS,
	GET_RULE_LIST_FAILURE,
	ADD_RULE_LIST_REQUEST,
	ADD_RULE_LIST_SUCCESS,
	ADD_RULE_LIST_FAILURE,
	GET_VIEW_RULE_REQUEST,
	GET_VIEW_RULE_SUCCESS,
	GET_VIEW_RULE_FAILURE,
	FIELDS_RULE_LIST_REQUEST,
	FIELDS_RULE_LIST_SUCCESS,
	FIELDS_RULE_LIST_FAILURE,
	OPERATORS_RULE_LIST_REQUEST,
	OPERATORS_RULE_LIST_SUCCESS,
	OPERATORS_RULE_LIST_FAILURE,
	GET_RULE_VIEW_REQUEST,
	GET_RULE_VIEW_SUCCESS,
	GET_RULE_VIEW_FAILURE,
	ACTIVATE_DEACTIVATE_RULE_REQUEST,
	ACTIVATE_DEACTIVATE_RULE_SUCCESS,
	ACTIVATE_DEACTIVATE_RULE_FAILURE,
} from "../types/types.js";

const API_URL = process.env?.REACT_APP_API_URL;
const API = process.env?.REACT_APP_NODE_API_URL;

export const GetRuleList = (docgroup) => async (dispatch) => {
	try {
		dispatch({ type: GET_RULE_LIST_REQUEST });

		axios
			.get(`${API}api/getRuleLists?docgroup=${docgroup}`, {
				headers: authHeader(),
			})
			.then((response) => {
				dispatch({
					type: GET_RULE_LIST_SUCCESS,
					payload: response.data.datalist,
				});
			})
			.catch(function (error) {
				if (error?.response?.status == 401) {
					checkApiStatus();
				}
				dispatch({
					payload: { error: error.message },
					type: GET_RULE_LIST_FAILURE,
				});
			});
	} catch (error) {
		dispatch({
			type: GET_RULE_LIST_FAILURE,
		});
	}
};

export const AddUpdateRule = (data) => async (dispatch) => {
	try {
		dispatch({ type: ADD_RULE_LIST_REQUEST });

		axios
			.post(`${API}api/addupdateRuleMaster`, data, {
				headers: authHeader(),
			})
			.then((response) => {
				dispatch({
					type: ADD_RULE_LIST_SUCCESS,
					payload: response.data,
				});
			})
			.catch(function (error) {
				if (error?.response?.status == 401) {
					checkApiStatus();
				}
				dispatch({
					payload: { error: error.message },
					type: ADD_RULE_LIST_FAILURE,
				});
			});
	} catch (error) {
		dispatch({
			type: ADD_RULE_LIST_FAILURE,
		});
	}
};

export const RuleFields = () => async (dispatch) => {
	try {
		dispatch({ type: FIELDS_RULE_LIST_REQUEST });

		axios
			.get(`${API}api/getListOfFields`, {
				headers: authHeader(),
			})
			.then((response) => {
				dispatch({
					type: FIELDS_RULE_LIST_SUCCESS,
					payload: response?.data?.data,
				});
			})
			.catch(function (error) {
				if (error?.response?.status == 401) {
					checkApiStatus();
				}
				dispatch({
					payload: { error: error.message },
					type: FIELDS_RULE_LIST_FAILURE,
				});
			});
	} catch (error) {
		dispatch({
			type: FIELDS_RULE_LIST_FAILURE,
		});
	}
};

export const RuleOperators = () => async (dispatch) => {
	try {
		dispatch({ type: OPERATORS_RULE_LIST_REQUEST });

		axios
			.get(`${API}api/getListOfOperators`, {
				headers: authHeader(),
			})
			.then((response) => {
				dispatch({
					type: OPERATORS_RULE_LIST_SUCCESS,
					payload: response.data.datalist,
				});
			})
			.catch(function (error) {
				if (error?.response?.status == 401) {
					checkApiStatus();
				}
				dispatch({
					payload: { error: error.message },
					type: OPERATORS_RULE_LIST_FAILURE,
				});
			});
	} catch (error) {
		dispatch({
			type: OPERATORS_RULE_LIST_FAILURE,
		});
	}
};

export const GetRuleView = (ruleId, org) => async (dispatch) => {
	try {
		dispatch({ type: GET_RULE_VIEW_REQUEST });

		axios
			.get(`${API}api/getRuleViewWithItems?ruleid=${ruleId}`, {
				headers: authHeader(),
			})
			.then((response) => {
				dispatch({
					type: GET_RULE_VIEW_SUCCESS,
					payload: response.data?.datalist,
				});
			})
			.catch(function (error) {
				if (error?.response?.status == 401) {
					checkApiStatus();
				}
				dispatch({
					payload: { error: error.message },
					type: GET_RULE_VIEW_FAILURE,
				});
			});
	} catch (error) {
		dispatch({
			type: GET_RULE_VIEW_FAILURE,
		});
	}
};

export const ActivateDeactivateRule = (ruleId, status) => async (dispatch) => {
	try {
		dispatch({ type: ACTIVATE_DEACTIVATE_RULE_REQUEST });

		axios
			.get(`${API}api/updateActiveDeactivateRule?id=${ruleId}&isactive=${status}`, {
				headers: authHeader(),
			})
			.then((response) => {
				dispatch({
					type: ACTIVATE_DEACTIVATE_RULE_SUCCESS,
					payload: response.data,
				});
			})
			.catch(function (error) {
				if (error?.response?.status == 401) {
					checkApiStatus();
				}
				dispatch({
					payload: error?.response?.data,
					type: ACTIVATE_DEACTIVATE_RULE_FAILURE,
				});
			});
	} catch (error) {
		dispatch({
			type: ACTIVATE_DEACTIVATE_RULE_FAILURE,
		});
	}
};

export const GetViewRuleList = (ruleId) => async (dispatch) => {
	try {
		dispatch({ type: GET_VIEW_RULE_REQUEST });

		axios
			.get(`${API}api/GetViewRule?ruleid=${ruleId}`, {
				headers: authHeader(),
			})
			.then((response) => {
				dispatch({
					type: GET_VIEW_RULE_SUCCESS,
					payload: response.data,
				});
			})
			.catch(function (error) {
				if (error?.response?.status == 401) {
					checkApiStatus();
				}
				dispatch({
					payload: { error: error.message },
					type: GET_VIEW_RULE_FAILURE,
				});
			});
	} catch (error) {
		dispatch({
			type: GET_VIEW_RULE_FAILURE,
		});
	}
};
