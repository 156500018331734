import axios from "axios";
import configMyiQ from "../../configuration";
import authHeader from "./auth-header";
import { checkApiStatus } from "../../utils/helpers";
import {
    COUNTRY_FAILURE,
    COUNTRY_REQUEST,
    COUNTRY_SUCCESS,
    GET_LIST_OF_CITIES_REQUEST,
    GET_LIST_OF_CITIES_FAILURE,
    GET_LIST_OF_CITIES_SUCCESS,
} from "../types/types";
const API_URL = process.env?.REACT_APP_API_URL;
export const countryList = () => async (dispatch) => {
    try {
        dispatch({ type: COUNTRY_REQUEST });
        axios
            .get(`${API_URL}Country/GetListOfCountries`, {
                headers: authHeader(),
            })
            .then((response) => {
                dispatch({
                    type: COUNTRY_SUCCESS,
                    payload: response?.data?.datalist,
                });
            });
    } catch (error) {
        if (error?.response?.status == 401) {
            checkApiStatus();
        }
        dispatch({
            type: COUNTRY_FAILURE,
        });
    }
};

//signup country

export const signupCountryList = () => async (dispatch) => {
    try {
        dispatch({ type: GET_LIST_OF_CITIES_REQUEST });
        axios
            .get(`${API_URL}SignUp/GetListOfCountrySignUp`, {
                headers: authHeader(),
            })
            .then((response) => {
                dispatch({
                    type: GET_LIST_OF_CITIES_SUCCESS,
                    payload: response?.data?.datalist,
                });
            });
    } catch (error) {
        if (error?.response.status == 401) {
            checkApiStatus();
        }
        dispatch({
            type: GET_LIST_OF_CITIES_FAILURE,
        });
    }
};
