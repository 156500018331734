import { FormControl, MenuItem, Select, TextField } from "@mui/material";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { DashboarChartList } from "../../redux/actions/dashboard";
import Loader from "../../components/Loader";
import { Link } from "react-router-dom";
import Button from "../../components/Button";
import Card from "../../components/UI/Card";
import Main from "../../components/UI/Main";
import { decryptId, encryptId, ThisWeek, ThisYear } from "../../utils/helpers";
import { Barchart } from "../../organisms/BarChart";
import Filter from "./Filter";
import { Bar, BarChart, CartesianGrid, Cell, Legend, Tooltip, XAxis, YAxis } from "recharts";
import { getCurrentUser } from "../../redux/actions/auth";
import { useContext } from "react";
import { ThemeContext } from "../../Hooks/Themes/Themeprovider";

const DashboardChart = (props) => {
    const userId = props?.userId;
    const [focusBar, setFocusBar] = useState(null);
    const Orgname = localStorage.getItem("Orgdemo");
    const dispatch = useDispatch();
    const location = useLocation();
    const [age, setAge] = useState("");
    const orgData = useSelector((state) => state?.ListorganisationDropDownState);
    const dataList = orgData.viewData;
    let organisationId = 0;
    const handleChange = (event) => {
        setAge(event.target.value);
    };
    const CurrentUser = getCurrentUser();
    const letuserId = CurrentUser?.role_id == 1 ? CurrentUser?.id : userId;
    const getDataItems = (type, year) => {
        organisationId = localStorage.getItem("orgId") || 0;
        dispatch(DashboarChartList(type, organisationId, year, letuserId));
    };

    const { data, error, fetching, requestComplete } = useSelector((state) => state?.DashboarChartState);

    const { currentMode } = useContext(ThemeContext);
    const textColor = currentMode === "dark" ? `#ECECEC` : `#555555`;
    useEffect(() => {
        organisationId = localStorage.getItem("orgId") || 0;
        // let bodys = document.querySelector("body").classList;
        // location.pathname === "/dashboard" &&
        //     bodys.remove(`bg-${currentMode}-base`);
        // if (Orgname !== "undefined" && Orgname !== undefined) {
        setTimeout(() => {
            dispatch(DashboarChartList(Orgname == "true" ? 3 : 1, organisationId, Orgname == "true" ? ThisYear : ThisWeek, letuserId));
        }, 500);
    }, [dispatch, organisationId, Orgname]);
    if (data?.datalist) {
        data?.datalist?.forEach((item) => {
            item.Invoices = parseInt(item?.Invoices);
        });
    }
    return (
        // <div className="basis-1/2">
        <Card
            className="h-full"
            // className={`${!requestComplete && "h-[29rem]"} ${data?.datalist?.length == 0 && "h-[31.5rem]"
            //     }`
            // }
        >
            <div>
                <div className={`flex justify-between mb-6 px-[20px] py-[24px] border-b-[1px] border-${currentMode}-700 items-center`}>
                    <h4 className="font-interM text-lg">Processed Invoice</h4>
                    <Filter getDataItems={getDataItems} />
                </div>
                <div className="px-[20px] py-5">
                    {!requestComplete ? (
                        <Loader className={`w-full flex justify-center min-h-[22rem] items-center`} />
                    ) : data?.datalist?.length !== 0 ? (
                        <Barchart
                            margin={{
                                top: 0,
                                bottom: 0,
                            }}
                            data={data?.datalist}
                            // width={700}
                            // height={330}
                            color={"#BE9DDF"}
                            xDataKey={"YearMonth"}
                            yDataKey={"Invoices"}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis xDataKey={"YearMonth"} fontSize={10} />
                            <YAxis yDataKey={"Invoices"} fontSize={10} domain={[0, "dataMax"]} />
                            <Tooltip cursor={false} />
                            <Legend />
                            <Bar dataKey="pv" barSize={20} label={{ fill: textColor }} fill="#BE9DDF" />
                        </Barchart>
                    ) : (
                        <div className="px-[30px] h-[20rem] grid place-items-center place-content-center">
                            {" "}
                            <img className="mb-3" src="/images/common/delivery.png" alt="searching" />
                            <h4 className="font-interSb block">No Data found</h4>
                        </div>
                    )}
                </div>
            </div>
        </Card>
        // </div>
    );
};

export default DashboardChart;
