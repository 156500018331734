import axios from "axios";
import {
    SEND_LAYOUT_FAILURE,
    SEND_LAYOUT_REQUEST,
    SEND_LAYOUT_SUCCESS,
    GET_LAYOUT_COUNTRYBYORG_REQUEST,
    GET_LAYOUT_COUNTRYBYORG_SUCCESS,
    GET_LAYOUT_COUNTRYBYORG_RESET,
    GET_LAYOUT_COUNTRYBYORG_FAILURE,
} from "../types/types";
import authHeader from "./auth-header";

const API_URL = process.env?.REACT_APP_NODE_API_URL;
export const layoutRequest = (data) => async (dispatch) => {
    dispatch({ type: SEND_LAYOUT_REQUEST });

    axios
        .post(`${API_URL}api/dashBoard/UpdateDynamicDashboard`, data, {
            headers: authHeader(),
        })
        .then((response) => {
            dispatch({
                type: SEND_LAYOUT_SUCCESS,
                payload: response?.data?.data,
            });
        })
        .catch((error) => {
            dispatch({
                type: SEND_LAYOUT_FAILURE,
                payload: error,
            });
        });
};

export const getLayoutCountryList = () => async (dispatch) => {
    dispatch({ type: GET_LAYOUT_COUNTRYBYORG_REQUEST });
    axios
        .get(`${API_URL}api/LayoutSettings/InvoiceLayoutCountry`, {
            headers: authHeader(),
        })
        .then((response) => {
            dispatch({
                type: GET_LAYOUT_COUNTRYBYORG_SUCCESS,
                payload: response.data.datalist,
            });
        })
        .catch((error) => {
            dispatch({
                type: GET_LAYOUT_COUNTRYBYORG_FAILURE,
            });
        });
};
