import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import TriggerSwitch from "../../../components/TriggerSwitch";
import Modal from "../../../components/UI/Modal/Modal";
import { getCurrentUser } from "../../../redux/actions/auth";
import { emailconfigAdd, emailConfigStatusMode, emailconfigUpdate, getEmailConfig } from "../../../redux/actions/emailconfig";
import {
    ACTIVATE_DEACTIVATE_EMAIL_CONFIG_RESET,
    GET_EMAIL_CONFIG_RESET,
    GET_ORGANISATION_RESET,
    UPDATE_EMAIL_CONFIG_RESET,
} from "../../../redux/types/types";
import { decryptId } from "../../../utils/helpers";
import Loader from "../../../components/Loader";
import { FormControl, MenuItem, Select } from "@mui/material";
import Button from "../../../components/Button";
import { ThemeContext } from "../../../Hooks/Themes/Themeprovider";

const ViewEmailConfigs = (props) => {
    const orgStatus = props?.orgStatus;
    const { configData, requestComplete: reqComp } = useSelector((state) => state?.emailConfigState);
    const [invoiceType, setInvoiceType] = useState(0);
    const { data, requestComplete } = useSelector((state) => state?.emailConfigStatusState);
    const { data: updateData, requestComplete: reqComplete } = useSelector((state) => state?.emailConfigUpdateState);
    const onChangeInvoiceType = (id, invoice_type, e, emailaddress) => {
        Swal.fire({
            text: `Are you sure you want to change the Invoice Type?`,
            icon: "warning",
            closeOnClickOutside: false,
            showCloseButton: true,
            showCancelButton: true,
            buttons: {
                confirm: "Confirm",
                cancel: "Cancel",
            }
        }).then((confirm) => {
            if (confirm.isConfirmed) {
                configDataList.forEach((item) => {
                    if (item.id === id) {
                        item.invoice_type = +e.target.value;
                    }
                    setViewConfigData([...configDataList]);
                    dispatch(
                        emailconfigUpdate({
                            id: id,
                            emailaddress: emailaddress,
                            invoice_type: +e.target.value,
                        })
                    );
                });
            }
        });
        localStorage.setItem("UpdateOrgId", props?.orgId);
    };
    if (reqComplete && updateData?.status) {
        Swal.fire({
            text: "Updated Successfully!",
            icon: "success",
            closeOnClickOutside: false,
            showCloseButton: true,
            button: "Ok",
        }).then((confirm) => {
            if (confirm) {
                dispatch(getEmailConfig(orgId));
                dispatch({ type: UPDATE_EMAIL_CONFIG_RESET });
            }
        });
    }
    const [configDataList, setViewConfigData] = useState(configData);
    if (configData && configData !== configDataList) {
        setViewConfigData(configData);
    }
    const dispatch = useDispatch();
    const currentUser = getCurrentUser();
    const orgId = props?.orgId;
    const handleChange = (id, status) => {
        Swal.fire({
            text: `Are you sure you want to ${status ? "inactivate" : "activate"
                } the Email?`,
            icon: "warning",
            closeOnClickOutside: false,
            showCloseButton: true,
            showCancelButton: true,
            buttons: {
                cancel: "Cancel",
                confirm: "Confirm",
            }
        }).then((confirm) => {
            if (confirm.isConfirmed) {
                configDataList.forEach((item) => {
                    if (item.id === id) {
                        item.isactive = status ? false : true;
                    }
                });
                setViewConfigData([...configDataList]);
                dispatch(
                    emailConfigStatusMode({
                        id: id,
                        isactive: status ? false : true,
                        userId: currentUser.id,
                    })
                );
                dispatch({ type: ACTIVATE_DEACTIVATE_EMAIL_CONFIG_RESET });
                if (requestComplete && data.status) {
                    Swal.fire({
                        text: "Status is updated successfully!",
                        icon: "success",
                        closeOnClickOutside: false,
                        showCloseButton: true,
                        button: "Ok",
                    }).then((confirm) => {
                        if (confirm) {
                            dispatch(getEmailConfig(orgId));
                        }
                    });
                }
            }
        });
    };
    useEffect(() => {
        //  setViewConfigData(configData);
    }, [configDataList]);

    const DisplayRole = useSelector((state) => state?.DisplayRolePermissionStateAction);
    const { data: DataList } = DisplayRole;
    const RoleList = DataList?.role_info;
    const RoleMenu = RoleList?.menu;
    const RoleEmail = RoleMenu?.find((elem) => elem?.menu_id === 2);
    const RoleEmailAction = RoleEmail?.actions;
    const RoleActEmailActive = RoleEmailAction?.find((element) => element.id === 4);
    const RoleActEmailDeactive = RoleEmailAction?.find((element) => element.id === 5);
    const handleClose = () => {
        props?.handleClose();
        dispatch({ type: GET_EMAIL_CONFIG_RESET });
        window.location.reload();
    };
    const closeCart = () => {
        props?.handleClose();
        dispatch({ type: GET_EMAIL_CONFIG_RESET });
    };
    const { data: listData } = useSelector((state) => state?.getDocumentTypeListState);
    const { currentMode } = useContext(ThemeContext);
    const dropdownColor = currentMode === "dark" ? "#333333" : `white`;
    const dropdownText = currentMode === "dark" ? "#ECECEC" : `#555555`;
    const dropdownShadow = currentMode !== "dark" ? "0px 4px 20px rgba(238, 238, 238, 0.5)" : "";
    const dropdownBorder = currentMode !== "dark" ? "223, 225, 226, 0.56" : "";
    return (
        <>
            <Modal orgId={props.orgId} cartClose={props?.handleClose} className={`${props?.className}`}>
                <span
                    onClick={requestComplete && data?.status ? handleClose : closeCart}
                    className="p-[6.5px] cursor-pointer absolute right-[565px] bg-light-10"
                >
                    <img src="/images/common/whiteClose.svg" alt="whiteClose" />
                </span>
                <div
                    className={`max-w-[565px] ml-auto transition bg-${currentMode}-card duration-150 ease-out flex-col flex h-screen max-h-[-webkit-fill-available] overflow-y-auto`}
                >
                    <div className={`bg-${currentMode}-card px-[30px] py-[24px] border-b-[1px] border-${currentMode}-590 mb-[1rem] relative`}>
                        <h4 className="font-interSb text-lg">Email Configuration</h4>
                    </div>
                    {configDataList?.map((elem, index) => {
                        return (
                            <div className="px-[30px]">
                                <div className="flex space-x-3 items-center">
                                    <div key={elem?.id} className="basis-[50%]">
                                        <span className="font-interM flex">
                                            <img
                                                className="mr-2"
                                                src={
                                                    elem?.emailtype?.emailtype_id === 1
                                                        ? `/images/common/googleIcon.svg`
                                                        : `/images/common/microsoftIcon.svg`
                                                }
                                                alt="googleIcon"
                                            />
                                            {decryptId(elem?.emailaddress)}
                                        </span>
                                        <span className="flex text620 mb-2">{elem?.last_read?.replaceAll(`-`, ``)}</span>
                                    </div>
                                    <div className={`flex basis-[45%]`}>
                                        <FormControl
                                            fullWidth
                                        // sx={{
                                        //   minWidth: "150px",
                                        // }}
                                        >
                                            <Select
                                                name="orgName"
                                                value={elem?.invoice_type}
                                                // defaultValue={elem?.emailtype?.emailtype_id}
                                                disabled={
                                                    orgStatus &&
                                                        RoleActEmailActive?.isallowed &&
                                                        elem?.isactive == true
                                                        ? false
                                                        : true
                                                }
                                                className="Org"
                                                IconComponent={() => (
                                                    <img
                                                        className="absolute right-[10px] opacity-50 pointer-events-none"
                                                        src={
                                                            currentMode !==
                                                                "dark"
                                                                ? `/images/header/chevronDown.svg`
                                                                : `/images/header/chevronDown-white.svg`
                                                        }
                                                        alt="chevronDown"
                                                    />
                                                )}
                                                MenuProps={{
                                                    sx: {
                                                        "& .MuiMenu-paper": {
                                                            backgroundColor: dropdownColor,
                                                            color: dropdownText,
                                                            boxShadow: dropdownShadow,
                                                            border: dropdownBorder,
                                                            //   maxWidth: "30rem !important",
                                                        },
                                                        "& .MuiMenuItem-root:hover":
                                                        {
                                                            backgroundColor:
                                                                "rgba(218, 249, 244, 0.3)",
                                                        },
                                                        "& .Mui-selected": {
                                                            backgroundColor: "rgba(218, 249, 244, 0.3) !important",
                                                        },
                                                    },
                                                }}
                                                SelectDisplayProps={{
                                                    style: {
                                                        color: dropdownText,
                                                    },
                                                }}
                                                onChange={(e) => onChangeInvoiceType(elem?.id, elem?.invoice_type, e, elem?.emailaddress)}
                                            >
                                                <MenuItem key={0} value={0} name="invoice_type">
                                                    {"Select"}
                                                </MenuItem>
                                                {listData &&
                                                    listData?.map((listelem, i) => {
                                                        return (
                                                            <MenuItem key={i + 1} name="invoice_type" value={listelem?.id}>
                                                                {listelem?.invoice_type}
                                                            </MenuItem>
                                                        );
                                                    })}
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div className="text-end basis-[10%]">
                                        {/* {RoleActEmailActive?.isallowed && !elem.isactive ? (
                      <TriggerSwitch
                        disabled={false}
                        sx={{ m: 0 }}
                        checked={elem.isactive}
                        onChange={(e) => handleChange(elem.id, elem.isactive)}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    ) : RoleActEmailDeactive?.isallowed && elem.isactive ? (
                      <TriggerSwitch
                        disabled={false}
                        sx={{ m: 0 }}
                        checked={elem.isactive}
                        onChange={(e) => handleChange(elem.id, elem.isactive)}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    ) : (
                      <TriggerSwitch
                        disabled={true}
                        onChange={(e) => handleChange(elem.id, elem.isactive)}
                        sx={{ m: 0 }}
                        checked={elem.isactive}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    )} */}
                                        <TriggerSwitch
                                            disabled={
                                                orgStatus &&
                                                    RoleActEmailActive?.isallowed
                                                    ? false
                                                    : true
                                            }
                                            sx={{ m: 0 }}
                                            checked={elem.isactive}
                                            onChange={(e) => handleChange(elem.id, elem.isactive)}
                                            inputProps={{
                                                "aria-label": "controlled",
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="mx-[-30px] my-4">
                                    <hr className={`border-${currentMode}-600`} />
                                </div>
                            </div>
                        );
                    })}
                    {!configData ||
                        (requestComplete && configDataList?.length === 0) ? (
                        <div className="px-[30px] h-full grid place-items-center place-content-center">
                            {" "}
                            <img className="mb-3" src="/images/common/delivery.png" alt="searching" />
                            <h4 className="font-interSb block">No Data found</h4>
                        </div>
                    ) : (
                        ""
                    )}
                    {!reqComp && configDataList?.length === 0 ? <Loader /> : ""}
                </div>
            </Modal>
        </>
    );
};

export default ViewEmailConfigs;
