import { useContext, useState } from "react";
import { useSelector } from "react-redux";
import Input from "../../../components/Input";
import Modal from "../../../components/UI/Modal/Modal";
import { ThemeContext } from "../../../Hooks/Themes/Themeprovider";

export const LedgerModal = (props) => {
    const { currentMode, toggleMode } = useContext(ThemeContext);
    const data = useSelector((state) => state?.generalLedgerListState);
    const listdata = data?.data;
    const [searchShow, setSearchShow] = useState(false);
    const [searchField, setSearchField] = useState("");

    const _handleChange = (e) => {
        setSearchField(e.target.value);
        if (e.target.value == "") {
            setSearchShow(false);
        } else {
            setSearchShow(true);
        }
    };
    const resetInputField = () => {
        setSearchField("");
    };
    const filteredData = listdata?.filter((ele) => {
        return (
            ele.description.toLowerCase().includes(searchField.toLowerCase()) || ele.account_code.toLowerCase().includes(searchField.toLowerCase())
        );
    });
    return (
        <>
            <Modal upperLay={true} cartClose={props?.handleClose} center="center" className={`${props?.className}`}>
                <div
                    className={`min-h-[350px] max-w-[490px] mx-auto relative transition bg-${currentMode}-card border-[rgba(236,236,236,0.88)] ${
                        currentMode === "dark" ? "" : "shadow-[0px_4px_20px_rgba(238,238,238,0.5)]"
                    } rounded-[10px] duration-150 ease-out p-[30px]`}
                >
                    <div className={`bg-${currentMode}-card mx-[-30px] z-[9] py-[20px] px-[50px]`}>
                        <span className="cursor-pointer absolute right-[2rem] convertWhite top-[1.3rem]" onClick={props?.handleClose}>
                            <img src="/images/common/close-one.svg" alt="close-one" />
                        </span>
                        <div
                            className={`bg-${currentMode}-card sticky top-[-20px] border-[1px] mb-4 flex pl-3 pr-4 py-2 rounded-[8px] border-${currentMode}-840`}
                        >
                            <Input
                                placeholder="Search"
                                value={searchField}
                                onChange={_handleChange}
                                className="bg-transparent py-0 flex-1 border-0"
                            />
                            {searchField && searchField.length !== 0 ? (
                                <img onClick={resetInputField} src="/images/common/closeBlk.svg" alt="close" />
                            ) : (
                                <img className="flex-initial" src="/images/main/search-grn.svg" alt="search-white" />
                            )}
                        </div>
                        <div className=" max-h-[400px] rounded-[8px] overflow-y-auto">
                            <table className={`w-full border-[1px] rounded-[8px] border-${currentMode}-700 rolemasterTable text-left`}>
                                <thead className={`sticky top-0 bg-${currentMode}-450`}>
                                    <tr>
                                        <th>Account Code</th>
                                        <th>Description</th>
                                    </tr>
                                </thead>
                                <tbody className="max-h-[100%] overflow-y-auto">
                                    {listdata?.length !== 0 ? (
                                        filteredData?.length !== 0 ? (
                                            searchField?.length !== 0 ? (
                                                filteredData?.map((item, index) => {
                                                    return (
                                                        <tr
                                                            key={index}
                                                            className=" hover:bg-[rgba(218,249,244,0.5)] cursor-pointer"
                                                            onClick={(e) => {
                                                                props?.handleSelect(item?.account_code);
                                                                setSearchField("");
                                                            }}
                                                        >
                                                            <td>{item?.account_code}</td>
                                                            <td>{item?.description}</td>
                                                        </tr>
                                                    );
                                                })
                                            ) : (
                                                listdata?.map((el, index) => {
                                                    return (
                                                        <tr
                                                            key={index}
                                                            className=" hover:bg-[rgba(218,249,244,0.5)] cursor-pointer"
                                                            onClick={(e) => {
                                                                props?.handleSelect(el?.account_code);
                                                                setSearchField("");
                                                            }}
                                                        >
                                                            <td>{el?.account_code}</td>
                                                            <td>{el?.description}</td>
                                                        </tr>
                                                    );
                                                })
                                            )
                                        ) : (
                                            <div className="flex items-center justify-center ">
                                                <span className="mx-auto">
                                                    <div className="absolute  top-[70%] left-[45%] translate-y-[-50%] translate-x-[-50%]">
                                                        <img src="/images/common/delivery.png" alt="delivery" />
                                                        <h4 className="font-interSb text-center mt-3">No search results found</h4>
                                                    </div>
                                                </span>
                                            </div>
                                        )
                                    ) : (
                                        <div className="flex items-center justify-center ">
                                            <span className="mx-auto">
                                                <div className="absolute top-[70%] left-[45%] translate-y-[-50%] translate-x-[-50%]">
                                                    <img src="/images/common/delivery.png" alt="delivery" />
                                                    <h4 className="font-interSb text-center mt-3">No data found</h4>
                                                </div>
                                            </span>
                                        </div>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    );
};
