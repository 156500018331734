import { useState } from "react";
import axios from "axios";
import { Link, NavLink } from "react-router-dom";
import Button from "../../../components/Button";
import Modal from "../../../components/UI/Modal/Modal";
import AddUserModal from "./AddUser";
import configMyiQ from "../../../configuration";
import authHeader from "../../../redux/actions/auth-header";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import styled from "@emotion/styled";
import Swal from "sweetalert2";
import { useContext } from "react";
import { ThemeContext } from "../../../Hooks/Themes/Themeprovider";

const AddUser = (props) => {
    const { currentMode } = useContext(ThemeContext);
    const LightTooltip = styled(({ className, ...props }) => (
        <Tooltip
            arrow
            {...props}
            placement="bottom-start"
            PopperProps={{
                sx: {
                    "& .MuiTooltip-tooltip": {
                        padding: "0 5px",
                    },
                },
                modifiers: [
                    {
                        name: "offset",
                        options: {
                            offset: [0, 0],
                        },
                    },
                ],
            }}
            classes={{ popper: className }}
        />
    ))(({}) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: "white",
            color: "rgba(0, 0, 0, 0.87)",
            borderRadius: "10px",
            boxShadow: "0px 4px 20px rgba(85,85,85,0.2)",
        },
        [`& .${tooltipClasses.arrow}`]: {
            width: "30px",
            height: "17px",
            marginTop: "-18px !important",
            "&::before": {
                backgroundColor: "white",
                border: "1px solid #DCDCDC",
            },
        },
    }));
    const [fileName, onFileUpload] = useState("");
    const [saveFile, onSaveFile] = useState("");
    const fileTypes = ["image/png", "image/jpg", "image/jpeg"];
    const onChange = (e) => {
        let file = e.target.files[0];
        const fixExist = fileTypes?.find((item) => item == file?.type);

        const fileSize = (file.size / (1 * 1024)).toFixed(2); //mb conversion
        if (!fixExist) {
            Swal.fire({
                text: "File type is not supported!",
                icon: "warning",
                closeOnClickOutside: false,
                showCloseButton: true,
                buttons: {
                    confirm: true,
                },
            });
        } else if (fileSize > 50) {
            Swal.fire({
                text: "File size should be less than 50kb!",
                icon: "warning",
                closeOnClickOutside: false,
                showCloseButton: true,
                buttons: {
                    confirm: true,
                },
            });
        } else {
            onSaveFile(file);
            const reader = new FileReader();
            reader.onload = _handleReaderLoaded.bind(this);
            reader.readAsBinaryString(file);
            // handleUpload();
        }
    };

    const _handleReaderLoaded = (e) => {
        let binaryString = e.target.result;
        onFileUpload(btoa(binaryString));
    };
    // const handleFile = (e) => {
    //   let Image = e.target.files[0];
    //   onFileHandle([Image]);
    // };
    const handleUpload = () => {
        let formdata = new FormData();

        formdata.append("Id", localStorage.getItem("mainid"));

        formdata.append("Image", fileName);
        const Token = localStorage.getItem("token");
        axios({
            url: `${process.env?.REACT_APP_API_URL}User/UploadPhoto`,
            method: "POST",
            header: authHeader(),
            data: formdata,
        }).then(
            (res) => {},
            (err) => {}
        );
    };
    const handleClose = () => {
        const btnClose = document.querySelector(".closeModalPop");
        btnClose.click();
    };

    return (
        <Modal cartClose={props?.handleClose} className={`${props?.className}`} tabIndex={0}>
            <Link onClick={() => handleClose()} className="p-[6.5px] absolute right-[712px] bg-light-10" title="Close">
                <img src="/images/common/whiteClose.svg" title="Close" alt="whiteClose" />
            </Link>
            <div
                className={`max-w-[712px] relative overflow-y-auto ml-auto transition duration-150 ease-out flex-col flex h-screen max-h-[-webkit-fill-available] bg-${currentMode}-card text-${currentMode}-copy border-${currentMode}-700`}
            >
                <div className={`bg-${currentMode}-580  min-h-[150px] mb-[6.25rem] relative`}>
                    <div className="absolute bottom-[-50%] left-[50%] translate-x-[-50%]">
                        <div
                            className={`bg-${currentMode}-card w-[120px] h-[115px] mx-auto mb-2 ${
                                currentMode !== "dark" ? "shadow-[0px_4px_37px_#EEEEEE]" : ""
                            }  p-2 rounded-full`}
                        >
                            {/* <LightTooltip
                title={
                  <div className="flex items-start p-3 rounded-[10px]">
                   <div className="text-[15px] flex flex-col space-y-1">
                    <Link className="mx-[-17px] px-[17px] hover:bg-[rgba(218,249,244,0.5)]">Upload</Link>
                    <Link className="mx-[-17px] px-[17px] hover:bg-[rgba(218,249,244,0.5)]">Remove</Link>
                   </div>
                  </div>
                }
              > */}
                            <div>
                                <img
                                    className="rounded-[50%] mx-auto object-cover w-[100px] h-[100px]"
                                    src={saveFile !== "" ? `data:image;base64,${fileName}` : `/images/common/profileImg.jpg`}
                                    alt="Profile"
                                />
                            </div>
                            {/* </LightTooltip> */}
                        </div>
                        <div className="upload-btn-wrapper" title="To add Profile Photo">
                            <NavLink to="#!" className="flex cursor-pointer items-center mb-2 justify-center">
                                <img className="mr-1" src="/images/common/camera.svg" alt="camera" /> Upload
                            </NavLink>
                            <input className="cursor-pointer" type="file" name="myfile" accept=".jpg, .jpeg, .png" onChange={(e) => onChange(e)} />
                        </div>
                    </div>
                </div>
                <AddUserModal cartClose={props?.handleClose} fileName={saveFile} isOpened={props?.isOpened} />
            </div>
        </Modal>
    );
};

export default AddUser;
