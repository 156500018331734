import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { ContainerCard } from "../../../organisms/DropTarget/Target";
import { SubTtlTarget } from "../../../organisms/DropTarget/SubTtl";
import Button from "../../../components/Button";
import { SortingTarget } from "../../../organisms/DropTarget/Sorting";
import { MoreFilterTarget } from "../../../organisms/DropTarget/MoreFilter";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import Loader from "../../../components/Loader";
import { GetPreviewData } from "../../../redux/actions/output-management";
import { setDroppedValues } from "../../../redux/actions/filter";
import { extractTextFromHTML, showSwal } from "../../../utils/helpers";
import _ from "lodash";
import { Tooltip } from "@mui/material";

export const Tabular = ({
    addItems,
    setAddItems,
    currentMode,
    accordionItems,
    setAccordionItems,
    filteredAccordionItems,
    type,
    setFilteredAccordionItems,
    prevtype,
}) => {
    let SNo = 0;
    const dispatch = useDispatch();
    const OrgId = localStorage.getItem("orgId");
    const { preview, fetching } = useSelector((state) => state?.getPreviewState);
    const { droppedValues, subTtalValues, sortingValues, morefilterValues } = useSelector((state) => state?.droppedState);
    const handleDrop = (itemValue, areaName) => {
        const conditions = {
            columns: true,
            subtotal: itemValue?.checked,
            subtotal: itemValue?.checked && (itemValue?.data_type === "n" || itemValue?.data_type === "m"),
            sorting: itemValue?.checked,
            morefilter: !itemValue?.checked,
        };

        if (conditions[areaName]) {
            dispatch(setDroppedValues(itemValue, true, areaName));
        } else {
            if (areaName === "subtotal" && itemValue?.checked && (itemValue?.data_type !== "n" || itemValue?.data_type !== "m")) {
                showSwal(`Subtotal should be number type`);
            } else {
                showSwal(
                    `${_.startCase(areaName == "morefilter" ? `More filter` : areaName)} is allowed for the ${areaName == "morefilter" ? `un` : ``
                    }selected fields/columns`
                );
            }
        }
    };
    const _handlePreview = () => {

        if (droppedValues?.length >= 1) {
            const firstColumnNames = subTtalValues?.map((obj) => obj?.column_name);
            const filteredSecondArray = _.filter(droppedValues, (obj) => firstColumnNames?.includes(obj?.column_name));
            const checkSubtlAgg = filteredSecondArray?.filter((item) => item?.Agg == null);
            if (subTtalValues?.length > 0 && checkSubtlAgg?.length !== 0) {
                showSwal(`Add the aggregate function applicable to the ${checkSubtlAgg?.map((el) => ` ${el?.field_display_name}`)} columns!`);
                return false;
            }
            setAddItems({ ...addItems, preview: true });
            const ReqObj = {
                dynamicquery: [...droppedValues, ...subTtalValues, ...sortingValues, ...morefilterValues],
                orgid: +OrgId,
                dataset_id: +type,
                output_type: +prevtype,
            };
            dispatch(GetPreviewData(ReqObj));
        } else {
            showSwal(`Select at least one column to proceed!`);
        }
    };

    // Map the values based on header order
    const mappedData = preview?.data?.map((item) => {
        const mappedItem = {};
        preview?.header?.forEach((header) => {
            const { column_name, field_display_name } = header;
            mappedItem[field_display_name] = item[column_name];
        });
        if (item['issubtotal'] != undefined) {
            mappedItem['issubtotal'] = item['issubtotal'];
        }
        return mappedItem;
    });
    const ToolTip = ({ text }) => (
        <Tooltip
            title={text}
            disablePortal
            placement="top"
            disableInteractive
            PopperProps={{
                sx: {
                    "& .MuiTooltip-tooltip": {
                        fontSize: "0.9em",
                        maxHeight: "120px",
                        maxWidth: "300px",
                        background: "#DAF9F4",
                        color: "#555555",
                        borderRadius: "5px",
                        paddingBottom: "10px",
                        paddingTop: "10px",
                        paddingLeft: "15px",
                    },

                    "& .MuiTooltip-arrow": {
                        color: "#DAF9F4",
                    },
                },
            }}
            arrow>
            <img className="ml-2 cursor-pointer" src="/images/main/noun-information.svg" alt="noun-information" />
        </Tooltip>
    );
    return (
        <>
            <div className="p-[20px] flex space-x-[10px] justify-end">
                <Button
                    disabled={addItems?.sorting}
                    title="Add Sorting"
                    onClick={() => setAddItems({ ...addItems, sorting: true })}
                    className={`border-${currentMode}-840 border rounded-[8px] px-[15px] py-[3.5px]`}>
                    Add Sorting
                </Button>
                <Button
                    disabled={addItems?.filters}
                    title="More Filters"
                    onClick={() => setAddItems({ ...addItems, filters: true })}
                    className={`border-${currentMode}-840 border rounded-[8px] px-[15px] py-[3.5px]`}>
                    More Filters
                </Button>
                <Button
                    disabled={addItems?.subtotal}
                    title="Add Subtotal"
                    onClick={() => setAddItems({ ...addItems, subtotal: true })}
                    className={`border-${currentMode}-840 border rounded-[8px] px-[15px] py-[3.5px]`}>
                    Add Subtotal
                </Button>
                <Button
                    disabled={fetching}
                    title="Preview Data"
                    onClick={_handlePreview}
                    className={`border-${currentMode}-20 bg-${currentMode}-20 text-white border rounded-[8px] px-[15px] py-[3.5px]`}>
                    Preview Data
                </Button>
            </div>
            <div className={`basis-full border-y border-${currentMode}-1240`}>
                <div className={`py-[12px] px-[20px] flex w-full bg-${currentMode}-card border-b border-${currentMode}-680 inline-block `}>
                    <span className={`font-interM block`}>Columns</span>
                    <ToolTip text="Drag and drop or select the Column data field to specify which data attributes should be included in generating the tabular reports." />
                </div>
                <div className="overflow-y-auto max-h-[15rem]">
                    <DndProvider backend={HTML5Backend}>
                        <ContainerCard
                            onDrop={handleDrop}
                            setAccordionItems={setAccordionItems}
                            accordionItems={accordionItems}
                            setFilteredAccordionItems={setFilteredAccordionItems}
                            filteredAccordionItems={filteredAccordionItems}
                            areaName="columns"
                            type={type}
                            currentMode={currentMode}
                        />
                    </DndProvider>
                </div>
            </div>
            <div className="flex">
                {addItems?.subtotal && (
                    <div className={`${addItems?.filters ? `basis-1/2` : `basis-[100%]`} border-b border-r border-${currentMode}-680`}>
                        <div className={`py-[12px] px-[20px] flex w-full bg-${currentMode}-card border-b border-${currentMode}-680 inline-block `}>
                            <span className="font-interM">Subtotal</span>
                            <ToolTip text="Dynamically calculate and display subtotal values for specific groups of data within your reports. Subtotal provides aggregated summary information." />
                        </div>
                        <div className="overflow-y-auto max-h-[15rem]">
                            <DndProvider backend={HTML5Backend}>
                                <SubTtlTarget
                                    onDrop={handleDrop}
                                    setAccordionItems={setAccordionItems}
                                    accordionItems={accordionItems}
                                    areaName="subtotal"
                                    type={type}
                                    currentMode={currentMode}
                                />
                            </DndProvider>
                        </div>
                    </div>
                )}
                {addItems?.filters && (
                    <div className={`${addItems?.subtotal ? `basis-1/2` : `basis-[100%]`} border-b border-${currentMode}-680`}>
                        <div className={`py-[12px] px-[20px] flex w-full bg-${currentMode}-card border-b border-${currentMode}-680 inline-block `}>
                            <span className="font-interM">More Filters</span>
                            <ToolTip text="Apply more filters to select and display only the relevant data rows in your tabular reports." />
                        </div>
                        <div className="overflow-y-auto max-h-[15rem]">
                            <DndProvider backend={HTML5Backend}>
                                <MoreFilterTarget
                                    onDrop={handleDrop}
                                    areaName="morefilter"
                                    currentMode={currentMode}
                                    filterCheck={droppedValues}
                                    setAccordionItems={setAccordionItems}
                                    accordionItems={accordionItems}
                                    type={type}
                                    setFilteredAccordionItems={setFilteredAccordionItems}
                                    filteredAccordionItems={filteredAccordionItems}
                                />
                            </DndProvider>
                        </div>
                    </div>
                )}
            </div>
            {addItems?.sorting && (
                <div className={`basis-full border-b border-${currentMode}-680`}>
                    <div className={`py-[12px] flex px-[20px] w-full bg-${currentMode}-card border-b border-${currentMode}-680 inline-block `}>
                        <span className="font-interM">Sorting</span>
                        <ToolTip text="Organize your data rows in a logical sequence for easier interpretation and analysis by using the sorting feature." />
                    </div>
                    <div className="overflow-y-auto max-h-[15rem]">
                        <DndProvider backend={HTML5Backend}>
                            <SortingTarget onDrop={handleDrop} type={type} areaName="sorting" currentMode={currentMode} />
                        </DndProvider>
                    </div>
                </div>
            )}
            {addItems?.preview && (
                <div className="max-h-[380px] mt-7 overflow-auto">
                    {fetching ? (
                        <Loader className="w-full flex items-center justify-center" />
                    ) : Object?.keys(preview || {})?.length !== 0 ? (
                        <table className="w-full relative overflow-auto rolemasterTable rounded-[0] lastBorder onTimeTbl text-left">
                            <thead className="sticky top-0 z-[2]">
                                <tr>
                                    <th className={`sticky left-0 z-[2] !rounded-tl-[0] text-center bg-${currentMode}-450`}>#</th>
                                    {preview?.header?.map((head, index) => (
                                        <th
                                            className={`${index === preview?.header?.length - 1 ? `!rounded-tr-[0]` : ``} whitespace-nowrap`}
                                            key={index}>
                                            {head?.field_display_name}
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {mappedData?.map((el, i) => {
                                    const filterMap = mappedData?.filter((el) => el?.issubtotal <= 0)
                                    SNo = SNo + (el?.issubtotal > 0 ? 0 : 1);
                                    return (

                                        <tr key={i} className={`${el?.issubtotal > 0 ? 'bg-[#EEFCFA]' : ''}`}>
                                            {

                                                <td className={`text-center sticky left-0 z-[1] ${el?.issubtotal > 0 ? 'bg-[#EEFCFA] font-interSb' : `bg-${currentMode}-card`}`}>{el?.issubtotal > 1 ? "Total" : (el?.issubtotal == 1) ? "" : SNo}</td>
                                            }


                                            {
                                                preview?.header?.map((head, index) => (
                                                    <td
                                                        title={extractTextFromHTML(el?.[head?.field_display_name])}
                                                        className={`whitespace-nowrap truncate max-w-[30rem] ${el?.issubtotal > 0 ? 'font-interSb' : ''}`}
                                                        key={index}>
                                                        {extractTextFromHTML(el?.[head?.field_display_name])}
                                                    </td>
                                                ))
                                            }
                                        </tr>
                                    );

                                })}
                            </tbody>
                        </table>
                    ) : (
                        <b className="text-center block">Preview data not found</b>
                    )}
                </div>
            )
            }
        </>
    );
};
