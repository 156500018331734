import Card from "../../../components/UI/Card";
import Loader from "../../../components/Loader/index.jsx";
import { Popper, Tooltip } from "@mui/material";
import StatusIcon from "./StatusIcon";
import FournotFour from "../../404";
import { ThemeContext } from "../../../Hooks/Themes/Themeprovider";
import { ErrorBoundary } from "react-error-boundary";
import Layout from "../../../Hoc/Layout";
import Button from "../../../components/Button";
import { Link } from "react-router-dom";
import Main from "../../../components/UI/Main";
import Footer from "../../../organisms/Footer";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
// import { getPoNumberList } from "../../../redux/actions/invoice";
import POHeader from "./POHeader";
import PaymentDetails from "./PaymentDetails";
import TogglePopup from "../../organisations/Toggle";
import { useState, useEffect, useRef, useContext, useMemo } from "react";
import ReactDOM from "react-dom";
import Swal from "sweetalert2";
import Activity from "../../../organisms/ActivityModal/index";
import CommentCard from "../../../organisms/CommentsCard/index";
import { ActivityHistory, getCurrentUser } from "../../../redux/actions/auth";
import { decryptId, encryptId } from "../../../utils/helpers.js";
import {
    GetAdvanceDetailsforCreate,
    addUpdateAdvanceRequest,
    getAdvanceRequestView,
    GetListAdvanceHistory,
    GetListUserForComments,
    getExchangeRateByDateData,
    GetGLIndicatorList,
    GetListAdvanceReqForPo,
} from "../../../redux/actions/advance-request.js";
import AdvanceHistoryTable from "./AdvanceHistoryTable";
import AdvanceFileUpload from "../../../organisms/AdvanceModals/fileUpload";
import StatusButton from "../../../organisms/AdvanceModals/advanceStatusCard";
import ServiceEntrySheetTable from "./serviceEntrySheetTable";
import * as XLSX from "xlsx";
import {
    GET_ADVANCE_DETAILS_FOR_CREATING_RESET,
    ADD_UPDATE_COMMENTS_RESET,
    ADD_UPDATE_ADVANCE_TYPE_RESET,
    GET_EXCHANGE_RATE_BY_DATE_RESET,
    GET_ADVANCE_HISTORY_LIST_RESET,
} from "../../../redux/types/types.js";
import ValidationModal from "./ValidationModal";
import moment from "moment";
import SuccessMessageBox from "../../../components/UI/Alert/sucess";
import MessageBox from "../../../components/UI/Alert";
import { GetUserActivity } from "../../../redux/actions/user.js";
import { formatNumber, showNumber, handleDecimal } from "../../../utils/helpers.js";
import _ from "lodash";
import ToReassign from "../../../pages/Invoice/Reassign/index";
import { postChangeWorkflowStatus } from "../../../redux/actions/workflow-automation.js";
import SelectUserModal from "../../../pages/Invoice/sidebar/SelectUserModel";

let findCreateNewRequest = false;
let findPoCurrency;

// const ErrorFallback = ({ error }) => {
//     return (
//         <>
//             <FournotFour />
//         </>
//     );
// };
const MobilizationRequest = () => {
    const childCompRef = useRef();
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isreassign, setReassign] = useState(false);
    const params = new URLSearchParams(window.location.search);
    const [userEmail, setUserEmail] = useState([]);
    const reqId = params.get("reqId") || null;
    const createReqId = params.get("createReqId") || 0;
    const CurrentUser = getCurrentUser();
    const UserDateFormat = CurrentUser?.user_date;
    const { currentMode, toggleMode } = useContext(ThemeContext);
    const orgId = localStorage.getItem("orgId") || 0;
    const { data: data, fetching, requestComplete } = useSelector((state) => state?.GetListAdvanceReqForPoDataState);
    const {
        data: createdata,
        fetching: createFetch,
        requestComplete: createReqComp,
        error: createError,
    } = useSelector((state) => state?.GetAdvanceDetailsforCreateState);
    const { data: viewData, fetching: viewFetch, requestComplete: viewReq } = useSelector((state) => state?.getAdvanceRequestViewState);
    const [checkClick, setCheckClick] = useState(false);
    const [popperOpen, setpopperOpen] = useState(false);
    const [filteredPoNoList, setFilteredPoNoList] = useState([]);
    const [insertPoVal, setInsertPoVal] = useState("");
    const [vendorName, setVendorName] = useState("");
    const [anchorEls, setAnchorEls] = useState(null);
    const [checkChange, setcheckChange] = useState(false);
    const [taxCodeOpen, setTaxcodeOpen] = useState(false);
    const poNumberList = data;
    const findPoSelected = insertPoVal !== "" && !popperOpen;
    //  && checkChange && poNumberList && poNumberList?.length !== 0;
    const createDataFetch = false;
    const createDataObject = reqId && viewData?.data ? viewData?.data : createReqId && viewData?.data ? viewData?.data : createdata?.data;
    const [reqObj, setReqObj] = useState(createDataObject?.recoveryInfo);
    const [adhocHistory, setAdhocHistory] = useState([]);
    const [MobilizationHistory, setMobilizationHistory] = useState([]);
    const [totalHistory, setTotalHistory] = useState([]);
    const calculateTaxAmount = (netVal, advPercentage) => {
        const taxAmount = (formatNumber(netVal) * formatNumber(advPercentage)) / 100;
        return taxAmount;
    };
    const getAllowAdvanceVal =
        location.pathname === "/adhoc-advance-request"
            ? formatNumber(totalHistory?.[0]?.inv_amt_till_date?.props?.value) > 0
                ? createDataObject?.poHeadersArray?.find((el) => el?.fieldName === "basic_value")?.value -
                  (formatNumber(totalHistory?.[0]?.adv_amt_unadjusted?.props?.value) +
                      formatNumber(totalHistory?.[0]?.inv_amt_till_date?.props?.value))
                : createDataObject?.poHeadersArray?.find((el) => el?.fieldName === "basic_value")?.value -
                  formatNumber(totalHistory?.[0]?.adv_till_date?.props?.value)
            : // calculateTaxAmount(
              //       createDataObject?.poHeadersArray?.find((el) => el?.fieldName === "basic_value")?.value,
              createDataObject?.poHeadersArray?.find((el) => el?.fieldName === "DownPaymentAmount")?.value;
    const DisplayRoles = useSelector((state) => state?.DisplayRolePermissionStateAction);
    const DisplayRole = useSelector((state) => state?.DisplayRolePermissionStateAction)?.data?.role_info?.menu?.find((item) => item?.menu_id == 21);
    const { data: DataList } = DisplayRoles;
    const RoleList = DataList?.role_info;
    const RoleMenu = RoleList?.menu;
    const RoleAdv = RoleMenu?.find((elem) => elem?.menu_id === 21);
    const RoleActAdvAdd = RoleAdv?.actions?.filter((el) => el?.id == 1)?.[0]?.isallowed;
    const RoleActAdvEdit = RoleAdv?.actions?.filter((el) => el?.id == 2)?.[0]?.isallowed;
    const RoleActAdvreject = RoleAdv?.actions?.filter((el) => el?.id == 9)?.[0]?.isallowed;
    const RoleActAdvActivity = RoleAdv?.actions?.filter((el) => el?.id == 13)?.[0]?.isallowed;
    const POHeaderList = createDataObject?.poHeadersArray?.find((el) => el?.fieldName === "currency")?.value;
    const DisableInput = (!RoleActAdvEdit && reqId) || (!RoleActAdvAdd && !reqId);
    const nextUserList = viewData?.data?.getData?.wfuser_list;
    const [selectUserAnchor, setSelectUserAnchor] = useState(false);
    const statusReqObj = {
        inv_id: reqId,
        org_id: +orgId,
        type: 1,
        groupType: 2,
        status_id: null,
        masterref_id: null,
        user_id: null,
    };
    useEffect(() => {
        if (viewData?.status || createdata?.status) {
            if (createdata?.status) {
                const updateDefauktObj = {
                    ...createDataObject.recoveryInfo,
                    request_date: moment(new Date()).format("YYYY-MM-DD"),
                    // reference_date: moment(new Date()).format("YYYY-MM-DD"),
                    currency: POHeaderList,
                    exec_rate: createDataObject.recoveryInfo?.exec_rate ? createDataObject.recoveryInfo?.exec_rate : "1",
                };
                setReqObj(updateDefauktObj);
            } else if (viewData?.status && createReqId) {
                const updateDefauktObj = {
                    ...createDataObject.recoveryInfo,
                    request_date: moment(new Date()).format("YYYY-MM-DD"),
                    // reference_date: moment(new Date()).format("YYYY-MM-DD"),
                    currency: POHeaderList,
                    exec_rate: createDataObject.recoveryInfo?.exec_rate ? createDataObject.recoveryInfo?.exec_rate : "1",
                };
                setReqObj(updateDefauktObj);
            } else {
                setReqObj(createDataObject?.recoveryInfo);
            }
            if (!findCreateNewRequest) {
                setInsertPoVal(viewData?.data?.po_number);
                setVendorName(viewData?.data?.vendor_name);
                setUploadFile(viewData?.data?.file_name);
                setFileType(viewData?.data?.file_type);
                setFileSize(viewData?.data?.file_size);
            }
        }
    }, [viewData, createdata]);
    const overallErrors = createDataObject?.error_message
        ? createDataObject?.error_message
              ?.split("* ")
              ?.filter(Boolean)
              ?.filter((item) => item?.trim()?.length > 0)
        : [];
    const { data: exchangeData, requestComplete: ExchangeReqComp, error: exchangeErr } = useSelector((state) => state?.getExchangeRateByDateState);
    useEffect(() => {
        if (exchangeData && ExchangeReqComp) {
            setReqObj((prevVal) => ({
                ...prevVal,
                currency: exchangeData?.[0]?.to_currency_code,
                exec_rate: exchangeData?.[0]?.exchange_rate,
            }));
        }
    }, [ExchangeReqComp]);
    const [paymentAmt, setpaymentAmt] = useState("0.00");
    const prepareDataForDownload = () => {
        return [
            {
                "PO Number": insertPoVal,
                "Vendor Name": vendorName,
                "Request Date": moment(new Date(reqObj?.request_date)).format(UserDateFormat?.toUpperCase()),
                "Reference Number": reqObj?.reference_no,
                "Reference Date": moment(new Date(reqObj?.reference_date)).format(UserDateFormat?.toUpperCase()),
                "Advance Amount": reqObj?.adv_amount,
                "Tax Code": taxListData?.find((ele) => ele?.tax_id === +reqObj?.tax_id)?.tax_code,
                "Tax Amount": reqObj?.tax_amount,
                "Payment Amount": paymentAmt,
            },
        ];
    };
    const handleDownload = () => {
        const data = prepareDataForDownload();
        const ws = XLSX.utils.json_to_sheet(data);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
        XLSX.writeFile(wb, "Advance-request.xlsx");
    };
    useEffect(() => {
        if (insertPoVal || poNumberList) {
            const filteredFav = poNumberList?.filter((fav) => {
                return fav.PoNumber.includes(insertPoVal);
            });
            setFilteredPoNoList(filteredFav);
        }
        if (createReqId && insertPoVal) {
            dispatch(GetAdvanceDetailsforCreate(insertPoVal, location?.pathname == "/mobilization-request" ? 1 : 2, orgId));
        }
    }, [poNumberList, insertPoVal]);

    // useEffect(() => {
    //     const findEdit = poNumberList?.find((el) => el?.PoNumber == insertPoVal);
    //     if (insertPoVal && vendorName !== "" && !findEdit) {
    //         setVendorName("");
    //     }
    // }, [insertPoVal]);

    useEffect(() => {
        if (orgId) {
            dispatch(GetGLIndicatorList(location.pathname == "/mobilization-request" ? 3 : 4));
            if (reqId || createReqId) {
                dispatch(getAdvanceRequestView(createReqId ? createReqId : reqId, orgId));
                findCreateNewRequest = false;
                dispatch(GetListUserForComments(2, orgId));
            } else {
                dispatch(GetListAdvanceReqForPo(orgId, location.pathname === "/mobilization-request" && 1));
                findCreateNewRequest = true;
            }
        }
    }, [orgId]);

    useEffect(() => {
        if (checkClick == "click") {
            setpopperOpen(false);
        }
        if (checkChange && checkClick !== "click") {
            setpopperOpen(true);
        }
    }, [filteredPoNoList, checkClick]);

    const [openValidationModal, setOpenValidationModal] = useState(false);
    const handleChange = (e, isClick, poNumb, VendorName) => {
        if (isClick && poNumb) {
            setInsertPoVal(poNumb);
            setVendorName(VendorName);
            setCheckClick("click");
            if (!reqId) {
                dispatch(GetAdvanceDetailsforCreate(poNumb, location?.pathname == "/mobilization-request" ? 1 : 2, orgId));
                dispatch(GetListAdvanceHistory(poNumb, orgId, location?.pathname == "/mobilization-request" ? 1 : 2));
            }
        } else {
            setCurrencyMisMatch(false);
            setInsertPoVal(e.target.value);
            setCheckClick("");
            dispatch({ type: GET_ADVANCE_DETAILS_FOR_CREATING_RESET });
        }
        setcheckChange(true);
        setAnchorEls(e.currentTarget);
    };
    const resetPoNumb = () => {
        setInsertPoVal("");
        setVendorName("");
    };
    const currentStatusInfo = createDataObject?.curr_status;
    const nextStatusInfo = createDataObject?.next_status;
    const prevStatusInfo = createDataObject?.prev_status;
    const filterMenuList = [
        viewData?.data ? { text: "Download", src: "/images/common/download-grn.svg", value: 0 } : "",
        { text: "Reset", src: "/images/mobilization/rotate-resetsvg.svg", value: 1 },
        RoleActAdvActivity && { text: "Activity", src: "/images/mobilization/activity_icon.svg", value: 2 },
        !DisableInput &&
            prevStatusInfo && {
                text: "Move to prev status",
                src: "/images/invoice/move-to-pre-state.svg",
                value: 3,
            },
    ];
    const [isUserActivity, setIsUserActivity] = useState(false);
    const { data: taxListData, requestComplete: reqCom } = useSelector((state) => state?.taxCodeWithPercentageListState);
    const { data: dataList, requestComplete: GlIndReqComp } = useSelector((state) => state?.getGLIndicatorListState);
    const glIndicData = dataList?.[0]?.special_gl_indicator;
    const handleOpenActivity = () => {
        setIsUserActivity(true);
        const reqObj = {
            transaction_id: location?.pathname == "/mobilization-request" ? 18 : 19,
            transaction_key: reqId,
            action: 0,
        };
        dispatch(ActivityHistory(reqObj));
    };
    const onFilterItems = (value, id) => {
        switch (value) {
            case 0:
                handleDownload();
                break;
            case 1:
                window.location.reload();
                break;
            case 2:
                handleOpenActivity();
                break;
            case 3:
                setReassign(true);
                break;
            default:
                break;
        }
    };
    const [uploadFile, setUploadFile] = useState("");
    const [fileContent, setFileContent] = useState("");
    const [fileSize, setFileSize] = useState("");
    const [fileType, setFileType] = useState("");
    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });
    useEffect(() => {
        const handleResize = () => {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        };
        setInsertPoVal("");
        setVendorName("");
        dispatch({ type: GET_ADVANCE_DETAILS_FOR_CREATING_RESET });
        setMobilizationHistory([]);
        setAdhocHistory([]);
        setTotalHistory([]);
        // Attach the event listener
        window.addEventListener("resize", handleResize);
        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []); // Empty dependency array to only run the effect on mount and unmount
    const findScreeSize = windowSize?.width;
    const [currencyMisMatch, setCurrencyMisMatch] = useState(false);
    const [selectedUserId, setSelectedUserId] = useState(null);
    const _handleSelectUser = (index, value, reason, type) => {
        if (reason == "selectOption") {
            setUserEmail([value?.[value?.length - 1]]);
        } else setUserEmail([]);
    };
    //* Function to call the change the status of the invoice
    function changeStatus(data) {
        if (data?.type == 1) {
            setSelectedUserId(data?.user_id);
        } else {
            setSelectedUserId(createDataObject?.prev_status?.[0]?.user_id);
        }
        dispatch(postChangeWorkflowStatus({ upd_status: [data] }));
    }
    useEffect(() => {
        if ((currencyMisMatch && createdata) || (currencyMisMatch && viewData)) {
            if (createdata) {
                findPoCurrency = createdata?.data?.poHeadersArray?.find((el) => el?.fieldName == "currency")?.value;
            } else if (viewData) {
                findPoCurrency = viewData?.poHeadersArray?.find((el) => el?.fieldName == "currency")?.value;
            }
            if (findPoCurrency) {
                dispatch(getExchangeRateByDateData(orgId, findPoCurrency, reqObj?.request_date));
            }
        }
    }, [currencyMisMatch, createdata, viewData]);
    // const {
    //     data: commentData,
    //     fetching: commentFetching,
    //     requestComplete: commentReqComp,
    //     error: commentError,
    // } = useSelector((state) => state?.addUpdateCommentsState);
    const cmtData = useSelector((state) => state?.addUpdateCommentsState);
    // Transforming the array
    const poHeaders = createDataObject?.poHeadersArray.reduce((acc, item) => {
        acc[item.fieldName] = item.value;
        return acc;
    }, {});
    // //* Function to call the change the status of the invoice
    // function changeStatus(data) {
    //     if (data?.type == 1) {
    //         setSelectedUserId(data?.user_id);
    //     } else {
    //         setSelectedUserId(dataList?.datalist[0]?.prev_status?.[0]?.user_id);
    //     }
    //     dispatch(postChangeWorkflowStatus({ upd_status: [data] }));
    // }
    const editDisable =
        // !DisplayRole?.actions?.find((ele) => ele?.id == 2)?.isallowed ||
        currentStatusInfo?.[0]?.master_refid == 3 || currentStatusInfo?.[0]?.master_refid == 4 || currentStatusInfo?.[0]?.master_refid == 5;
    const handleSubmit = () => {
        if (!reqObj?.request_date || !reqObj?.adv_amount || formatNumber(reqObj?.adv_amount) === 0) {
            Swal.fire({
                text: `${formatNumber(reqObj?.adv_amount) === 0 ? "Advance amount cannot be zero" : "Mandatory fields are required!"}`,
                icon: "warning",
                allowOutsideClick: false,
                showCloseButton: true,
                buttons: {
                    confirm: "Confirm",
                },
            }).then((confirm) => {
                if (confirm?.isConfirmed) {
                    return false;
                }
            });
        } else {
            const reqDate =
                typeof reqObj?.request_date == "object" && reqObj?.request_date?.$d
                    ? reqObj?.request_date.format("YYYY-MM-DD")
                    : reqObj?.request_date?.includes("T")
                    ? reqObj?.request_date.format("YYYY-MM-DD")
                    : reqObj?.request_date;
            const refDate = location.pathname !== "/adhoc-advance-request" ? reqObj?.reference_date.format("YYYY-MM-DD") : reqObj?.reference_date;
            delete createdata?.data?.vendorMaster?.__metadata;
            const reqObject = {
                request_id: createReqId ? createReqId : reqId ? reqId : 0,
                po_number: insertPoVal,
                vendor_name: vendorName,
                org_id: orgId,
                adv_type: location?.pathname == "/mobilization-request" ? 1 : 2,
                recoveryInfo: {
                    ...reqObj, // assuming reqObj is an object with existing properties
                    adv_amount: formatNumber(reqObj?.adv_amount),
                    tax_amount: formatNumber(reqObj?.tax_amount),
                    request_date: reqObj?.request_date ? reqDate : null, // set new value for request_date
                    reference_date: reqObj?.reference_date ? refDate : null, // set new value for reference_date
                    gl_indicator: glIndicData?.length <= 1 ? glIndicData?.[0]?.value : reqObj?.gl_indicator,
                },
                poHeaders: poHeaders,
                poLineItems: createDataObject?.poLineItems,
                filecontent: fileContent?.filecontent,
                filename: fileContent?.filename,
                fileUrl: "",
                file_size: fileSize,
                excep_perc: 0,
                vendorMaster: createdata?.data?.vendorMaster || null,
            };
            dispatch(addUpdateAdvanceRequest(reqObject));
        }
    };
    // __metadata;
    if (createError || exchangeErr) {
        Swal.fire({
            text: `${exchangeErr ? exchangeErr?.error : createError?.message}`,
            icon: "warning",
            allowOutsideClick: false,
            showCloseButton: true,
            buttons: {
                confirm: "Confirm",
            },
        }).then((confirm) => {
            if (confirm) {
                if (location?.pathname === "/mobilization-request") {
                    dispatch({ type: GET_EXCHANGE_RATE_BY_DATE_RESET });
                    dispatch({ type: GET_ADVANCE_DETAILS_FOR_CREATING_RESET });
                    dispatch({ type: GET_ADVANCE_HISTORY_LIST_RESET });
                    setInsertPoVal("");
                    setVendorName("");
                    setMobilizationHistory([]);
                    setTotalHistory([]);
                    setAdhocHistory([]);
                } else {
                    dispatch({ type: GET_EXCHANGE_RATE_BY_DATE_RESET });
                    dispatch({ type: GET_ADVANCE_DETAILS_FOR_CREATING_RESET });
                    dispatch({ type: GET_ADVANCE_HISTORY_LIST_RESET });
                    navigate(`${location?.state?.from}${location?.state?.search ? location?.state?.search : ""}`);
                }
            }
        });
    }
    const {
        data: commentData,
        fetching: addCmtFetch,
        requestComplete: addCmtReqComp,
        error: addCmtErr,
    } = useSelector((state) => state?.addUpdateCommentsState);
    const {
        data: postAdvReqData,
        fetching: postAdvReqFetch,
        requestComplete: postAdvReqReqComp,
        error: postAdvReqErr,
    } = useSelector((state) => state?.addUpdateAdvanceRequestState);
    useEffect(() => {
        if (commentData && addCmtReqComp) {
            setTimeout(() => {
                dispatch({ type: ADD_UPDATE_COMMENTS_RESET });
            }, 2000);
        }
        if ((postAdvReqReqComp && postAdvReqData?.status) || (postAdvReqReqComp && postAdvReqErr)) {
            Swal.fire({
                text: postAdvReqData ? postAdvReqData?.message : "Can't save the data",
                icon: `${postAdvReqData ? "success" : "warning"}`,
                allowOutsideClick: false,
                showCloseButton: true,
                buttons: {
                    confirm: "Confirm",
                },
            }).then((confirm) => {
                if (postAdvReqData && confirm?.isConfirmed) {
                    dispatch({ type: ADD_UPDATE_ADVANCE_TYPE_RESET });
                    navigate("/advance-list");
                } else {
                    dispatch({ type: ADD_UPDATE_ADVANCE_TYPE_RESET });
                    return false;
                }
            });
        }
    }, [addCmtReqComp, postAdvReqReqComp]);
    const [showStatusEqual, setShowStatusEqual] = useState(true);
    const showStatusEqualSwal = () => {
        Swal.fire({
            text: "Current status and next status are same. Please reconfigure the workflow",
            allowOutsideClick: false,
            showCloseButton: true,
            icon: "warning",
            buttons: {
                confirm: "Confirm",
            },
            dangerMode: true,
        }).then((confirm) => {
            if (confirm?.isConfirmed) {
                setShowStatusEqual(false);
            }
        });
    };
    useEffect(() => {
        if (
            nextStatusInfo?.[0]?.next_status &&
            currentStatusInfo?.[0]?.status_id &&
            nextStatusInfo?.[0]?.next_status == currentStatusInfo?.[0]?.status_id &&
            showStatusEqual
        ) {
            showStatusEqualSwal();
            setShowStatusEqual(false);
        }
    }, []);
    return (
        <>
            {addCmtReqComp && commentData?.status && <SuccessMessageBox success={"Comment Added Successfully"} />}
            {addCmtReqComp && !commentData?.status && addCmtErr && <MessageBox error={commentData?.message} />}
            <Layout>
                {/* <ErrorBoundary FallbackComponent={ErrorFallback}> */}
                {(createDataFetch || viewFetch || postAdvReqFetch || createFetch) && (
                    <div className="h-screen overflow-hidden flex bg-[rgb(231,225,225,0.5)] absolute top-0 z-[12] left-0 right-0 justify-center items-center w-full">
                        {" "}
                        <img className="w-[7rem]" src="/images/gif/Loading-Gif.gif" alt="Loading-Gif" />
                    </div>
                )}
                <Main className="overflow-x-hidden">
                    <div className="mb-5 flex justify-between">
                        <div>
                            <h4 className={`text-2xl font-interSb text-${currentMode}-copy`}>
                                {" "}
                                {location.pathname !== "/mobilization-request" ? "Adhoc Advance Request" : "Mobilization Request"}
                            </h4>
                        </div>
                        <div className=" flex text-right">
                            {!findCreateNewRequest && overallErrors?.length !== 0 && (
                                <Button
                                    onClick={() => setOpenValidationModal(true)}
                                    className="bg-[#FFE9E9] text-[#FA5A5A] border border-solid border-[#FFD8D8] rounded-[8px] py-2 px-5"
                                >
                                    {`${overallErrors?.length} field${overallErrors?.length > 1 && `(s)`} failed to extract!`}
                                </Button>
                            )}
                            <Link
                                tabIndex={0}
                                // disabled={}
                                state={{ from: location.pathname }}
                                to={
                                    location.pathname == "/mobilization-request" || (location.pathname == "/adhoc-advance-request" && reqId)
                                        ? "/advance-list"
                                        : `${location.state.from}${location.state.search}`
                                }
                                title="Cancel"
                            >
                                {" "}
                                <Button className="bg-light-240 ml-2 text-light-40 text-base rounded-[8px] py-2 px-5">Cancel</Button>
                            </Link>

                            <Button
                                tabIndex={0}
                                disabled={postAdvReqFetch || vendorName?.length == 0 || DisableInput || editDisable}
                                onClick={handleSubmit}
                                className="bg-light-20 text-white text-base rounded-[8px] ml-2 py-2 px-7"
                                title={"Save"}
                            >
                                {"Save"}
                            </Button>
                        </div>
                    </div>
                    <div className="overflow-y-auto ">
                        <div className="xl:flex xl:space-x-2 ">
                            <div className="xl:basis-[70%] space-y-2 ">
                                {!findCreateNewRequest && findScreeSize < 1181 && !createReqId && (
                                    <Card
                                        className={`${
                                            findPoSelected || taxCodeOpen ? "" : "opacity-[.5] pointer-events-none"
                                        } xl:mb-0 xl:mt-0 border-[1px] xl:w-[300px] xl:h-[62px] md:h-[84px] border-[rgba(223,225,226,0.56)] 2xl:w-full ${
                                            currentMode === "dark" ? "" : "shadow-[0px_4px_20px_rgba(238,238,238,0.5)]"
                                        }`}
                                    >
                                        <ul className="flex justify-between xl:py-5 xl:px-5 md:px-[62px] md:py-[30px]">
                                            <StatusButton
                                                handleDownload={handleDownload}
                                                handleOpenActivity={handleOpenActivity}
                                                statusReqObj={statusReqObj}
                                                setSelectUserAnchor={setSelectUserAnchor}
                                                overallErrors={overallErrors}
                                                changeStatus={changeStatus}
                                                nextUserList={nextUserList}
                                                reqId={reqId}
                                                currentStatusInfo={currentStatusInfo}
                                                nextStatusInfo={nextStatusInfo}
                                                RoleActAdvEdit={RoleActAdvEdit}
                                                DisableInput={DisableInput}
                                                RoleActAdvreject={RoleActAdvreject}
                                                setIsUserActivity={setIsUserActivity}
                                                RoleActAdvActivity={RoleActAdvActivity}
                                                createDataObject={createDataObject}
                                                findScreeSize={findScreeSize}
                                                childCompRef={childCompRef}
                                                filterMenuList={filterMenuList}
                                                onFilterItems={onFilterItems}
                                                DisplayRole={DisplayRole}
                                            />
                                        </ul>
                                    </Card>
                                )}
                                <Card
                                    className={` xl:p-[30px] mb-4 xl:mr-4 p-4 shadow-md border-[1px] border-[rgba(223,225,226,0.56)] h-[132px] xl:w-[976px] 2xl:w-full ${
                                        currentMode === "dark" ? "" : "shadow-[0px_4px_20px_rgba(238,238,238,0.5)]"
                                    }`}
                                    style={{
                                        backgroundColour: "#FCFCFC",
                                    }}
                                >
                                    <div className="flex space-x-6">
                                        <div>
                                            <span className="text-[14px] font-medium">
                                                <h3>PO Number:</h3>
                                            </span>
                                            <div className="relative">
                                                <input
                                                    autoFocus
                                                    type="number"
                                                    maxLength={31}
                                                    disabled={DisableInput}
                                                    placeholder="Enter PO number"
                                                    onChange={(e) => handleChange(e)}
                                                    value={insertPoVal}
                                                    readOnly={reqId || location.pathname === "/adhoc-advance-request"}
                                                    class="border-[rgba(85,85,85,0.5)] w-[300px] mt-3 h-[40px] bg-transparent px-[10px] py-1 border-[1px] rounded-[5px] "
                                                />

                                                {insertPoVal?.length !== 0 && !reqId && !createReqId ? (
                                                    <Button onClick={resetPoNumb} disabled={DisableInput}>
                                                        <img
                                                            className="absolute ml-[-25px] mt-[-10px]"
                                                            src="/images/common/closeBlk.svg"
                                                            alt="close"
                                                        />
                                                    </Button>
                                                ) : (
                                                    ""
                                                )}
                                            </div>
                                            {popperOpen && insertPoVal ? (
                                                <Popper
                                                    open={Boolean(popperOpen)}
                                                    anchorEl={anchorEls}
                                                    disablePortal
                                                    className="popperTbl z-[22]"
                                                    // onClickAway={() => handleClickAway()}
                                                >
                                                    <div
                                                        className={`max-h-[340px] overflow-y-auto ${
                                                            currentMode === "dark" ? "" : "shadow-[0px_4px_20px_rgba(238,238,238,0.5)]"
                                                        } ml-[26px] bg-${currentMode}-card border rounded-[8px]`}
                                                    >
                                                        <table
                                                            className={`w-[300px] max-h-[230px] overflow-y-auto bg-${currentMode}-card rolemasterTable  rounded-[8px] text-left`}
                                                        >
                                                            <thead className="sticky top-0">
                                                                <tr className="">
                                                                    <th className="">PO.No.</th>
                                                                    <th className="">Vendor</th>
                                                                    {/* <th>Vendor type</th> */}
                                                                </tr>
                                                            </thead>{" "}
                                                            {
                                                                <tbody className="">
                                                                    {fetching && !requestComplete ? (
                                                                        <tr>
                                                                            <td></td>
                                                                            <td>
                                                                                <Loader className="flex h-[calc(100vh_-_17rem)]" />
                                                                            </td>
                                                                            <td></td>
                                                                        </tr>
                                                                    ) : insertPoVal ? (
                                                                        filteredPoNoList !== undefined && filteredPoNoList?.length !== 0 ? (
                                                                            filteredPoNoList?.map((elem) => {
                                                                                return (
                                                                                    <tr
                                                                                        className="cursor-pointer"
                                                                                        onClick={(e) =>
                                                                                            handleChange(e, "click", elem?.PoNumber, elem?.VendorName)
                                                                                        }
                                                                                    >
                                                                                        <td className="pl-1 pr-1">{elem?.PoNumber}</td>
                                                                                        <td className="max-w-[390px] pl-1 truncate">
                                                                                            {elem?.VendorName}
                                                                                        </td>
                                                                                        {/* <td className="flex">{elem?.VendorType}</td> */}
                                                                                    </tr>
                                                                                );
                                                                            })
                                                                        ) : (
                                                                            <tr>
                                                                                <td
                                                                                    colSpan={12}
                                                                                    style={{
                                                                                        textAlign: "center",
                                                                                    }}
                                                                                >
                                                                                    No Datas Found
                                                                                </td>
                                                                            </tr>
                                                                        )
                                                                    ) : (
                                                                        poNumberList?.map((item) => {
                                                                            return (
                                                                                <tr
                                                                                    className="cursor-pointer"
                                                                                    onClick={(e) =>
                                                                                        handleChange(e, "click", item?.PoNumber, item?.VendorName)
                                                                                    }
                                                                                >
                                                                                    <td className="pl-1 pr-1">{item?.PoNumber}</td>
                                                                                    <td
                                                                                        title={item?.VendorName}
                                                                                        className="max-w-[390px] pl-1 truncate"
                                                                                    >
                                                                                        {item?.VendorName}
                                                                                    </td>
                                                                                    {/* <td className="flex">{item?.VendorType}</td> */}
                                                                                </tr>
                                                                            );
                                                                        })
                                                                    )}
                                                                </tbody>
                                                            }
                                                        </table>
                                                    </div>
                                                </Popper>
                                            ) : (
                                                // <popperDropDownModal open={Boolean(popperOpen)} anchorEl={anchorEl}/>
                                                ""
                                            )}
                                        </div>
                                        <div>
                                            <span className="text-[14px] font-medium">
                                                <h3>Vendor:</h3>
                                            </span>
                                            <div className="relative">
                                                <input
                                                    type="text"
                                                    placeholder="Vendor Name"
                                                    value={vendorName}
                                                    disabled
                                                    class="border-[rgba(85,85,85,0.5)] w-[300px] mt-3 h-[40px] bg-transparent px-[10px] py-1 border-[1px] rounded-[5px] "
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </Card>
                                <div className={` ${findPoSelected || taxCodeOpen ? "" : "opacity-[.5] pointer-events-none"}`}>
                                    <POHeader createDataObject={createDataObject} RoleActAdvEdit={RoleActAdvEdit} DisableInput={DisableInput} />
                                    {location?.pathname == "/adhoc-advance-request" && !reqId && (
                                        <ServiceEntrySheetTable
                                            createReqId={createReqId}
                                            adhocCreatdata={createdata?.data?.poSesGrn}
                                            findPoSelected={findPoSelected}
                                            createDataObject={createDataObject}
                                        />
                                    )}
                                    <PaymentDetails
                                        getAllowAdvanceVal={getAllowAdvanceVal}
                                        setCurrencyMisMatch={setCurrencyMisMatch}
                                        RoleActAdvEdit={RoleActAdvEdit}
                                        DisableInput={DisableInput}
                                        currencyMisMatch={currencyMisMatch}
                                        glIndicData={glIndicData}
                                        createReqComp={createReqComp}
                                        setTaxcodeOpen={setTaxcodeOpen}
                                        taxCodeOpen={taxCodeOpen}
                                        reqId={reqId}
                                        taxListData={taxListData}
                                        setpaymentAmt={setpaymentAmt}
                                        paymentAmt={paymentAmt}
                                        reqObj={reqObj}
                                        setReqObj={setReqObj}
                                        findScreeSize={findScreeSize}
                                        childCompRef={childCompRef}
                                        createDataObject={createDataObject}
                                        setAdhocHistory={setAdhocHistory}
                                        adhocHistory={adhocHistory}
                                        MobilizationHistory={MobilizationHistory}
                                        setMobilizationHistory={setMobilizationHistory}
                                        setTotalHistory={setTotalHistory}
                                        totalHistory={totalHistory}
                                        exchangeData={exchangeData}
                                        editDisable={editDisable}
                                        viewReq={viewReq}
                                        exchangeErr={exchangeErr}
                                        vendorName={vendorName}
                                    />
                                </div>
                            </div>
                            <div className={`${findPoSelected || taxCodeOpen ? "" : "opacity-[.5] pointer-events-none"} xl:basis-[30%]  space-y-2`}>
                                {!findCreateNewRequest && findScreeSize > 1180 && !createReqId && (
                                    <Card
                                        className={`xl:mb-0 xl:mt-0 border-[1px] shadow-md xl:w-[300px] xl:h-[62px] md:h-[84px] border-[rgba(223,225,226,0.56)] 2xl:w-full ${
                                            currentMode === "dark" ? "" : "shadow-[0px_4px_20px_rgba(238,238,238,0.5)]"
                                        }`}
                                    >
                                        <ul className="flex justify-between xl:py-5 xl:px-5 md:px-[62px] md:py-[30px]">
                                            <StatusButton
                                                handleDownload={handleDownload}
                                                handleOpenActivity={handleOpenActivity}
                                                statusReqObj={statusReqObj}
                                                setSelectUserAnchor={setSelectUserAnchor}
                                                overallErrors={overallErrors}
                                                changeStatus={changeStatus}
                                                nextUserList={nextUserList}
                                                reqId={reqId}
                                                currentStatusInfo={currentStatusInfo}
                                                nextStatusInfo={nextStatusInfo}
                                                RoleActAdvEdit={RoleActAdvEdit}
                                                DisableInput={DisableInput}
                                                RoleActAdvreject={RoleActAdvreject}
                                                setIsUserActivity={setIsUserActivity}
                                                RoleActAdvActivity={RoleActAdvActivity}
                                                createDataObject={createDataObject}
                                                findScreeSize={findScreeSize}
                                                childCompRef={childCompRef}
                                                filterMenuList={filterMenuList}
                                                onFilterItems={onFilterItems}
                                                DisplayRole={DisplayRole}
                                            />
                                        </ul>
                                    </Card>
                                )}
                                {location?.pathname == "/adhoc-advance-request" && (
                                    <Card
                                        className={`xl:mb-0 ${
                                            !findCreateNewRequest ? "mt-[20px]" : ""
                                        }  border-[1px] shadow-md xl:w-[300px] xl:h-[62px] md:h-[84px] border-[rgba(223,225,226,0.56)] 2xl:w-full ${
                                            currentMode === "dark" ? "" : "shadow-[0px_4px_20px_rgba(238,238,238,0.5)]"
                                        }`}
                                    >
                                        <div className="mx-auto pt-[18px]">
                                            <Link
                                                state={{ from: location.pathname }}
                                                to={`${"/proforma-invoice-view?"}${encryptId(
                                                    `{"inv":${+createDataObject?.getData?.invoice_id},"type":${8},"seq":"${+123}","status":${
                                                        currentStatusInfo?.[0]?.master_refid
                                                    }}`
                                                )}`}
                                                className="flex cursor-pointer underline text-[16px] text-[#00B399] justify-center"
                                            >
                                                View Proforma Invoice
                                            </Link>
                                        </div>
                                    </Card>
                                    // </Link>
                                )}
                                <div
                                    className={`bg-[#FFF7F0] shadow-md text-${currentMode}-copy border-[1px] border-${currentMode}-700  rounded-[10px] mt-[20px] mb-[3rem]  xl:mt-0 border-[1px] h-[256px] border-[rgba(223,225,226,0.56)] xl:w-[300px] 2xl:w-full
                                            ${currentMode === "dark" ? "" : "shadow-[0px_4px_20px_rgba(238,238,238,0.5)]"}`}
                                >
                                    <AdvanceFileUpload
                                        fileType={fileType}
                                        RoleActAdvEdit={RoleActAdvEdit}
                                        DisableInput={DisableInput}
                                        uploadFile={uploadFile}
                                        setUploadFile={setUploadFile}
                                        setFileSize={setFileSize}
                                        fileSize={fileSize}
                                        findCreateNewRequest={findCreateNewRequest}
                                        setFileContent={setFileContent}
                                        fileContent={fileContent}
                                        url={viewData?.data?.file_url}
                                    />
                                </div>
                                {!findCreateNewRequest && !createReqId && (
                                    <Card
                                        className={` border-[1px]  flex flex-col mt-[20px] justify-between h-[345px] border-[rgba(223,225,226,0.56)] xl:w-[300px] 2xl:w-full ${
                                            currentMode === "dark" ? "" : "shadow-[0px_4px_20px_rgba(238,238,238,0.5)]"
                                        }`}
                                    >
                                        <CommentCard
                                            createDataObject={createDataObject}
                                            reqId={reqId}
                                            type={2}
                                            RoleActAdvEdit={RoleActAdvEdit}
                                            DisableInput={DisableInput}
                                        />
                                    </Card>
                                )}
                            </div>
                        </div>
                        <Card
                            className={` ${
                                findPoSelected || taxCodeOpen ? "" : "opacity-[.5] pointer-events-none"
                            } mt-4 mb-[3rem] border-[1px]  border-[rgba(223,225,226,0.56)] h-[232px] xl:w-[1296px]  2xl:w-full ${
                                currentMode === "dark" ? "" : "shadow-[0px_4px_20px_rgba(238,238,238,0.5)]"
                            }`}
                        >
                            <AdvanceHistoryTable
                                reqId={reqId}
                                createReqId={createReqId}
                                setAdhocHistory={setAdhocHistory}
                                adhocHistory={adhocHistory}
                                MobilizationHistory={MobilizationHistory}
                                setMobilizationHistory={setMobilizationHistory}
                                setTotalHistory={setTotalHistory}
                                totalHistory={totalHistory}
                                viewData={viewData}
                            />
                        </Card>
                        {ReactDOM.createPortal(
                            <Activity isOpened={isUserActivity} handleClose={() => setIsUserActivity(false)} className={isUserActivity && "Show"} />,
                            document.getElementById("user-modal-root")
                        )}

                        {ReactDOM.createPortal(
                            <ValidationModal
                                overallErrors={overallErrors}
                                createDataObject={createDataObject}
                                isOpened={openValidationModal}
                                handleClose={() => setOpenValidationModal(false)}
                                className={`${openValidationModal && "Show"}`}
                            />,
                            document.getElementById("user-modal-root")
                        )}
                        {ReactDOM.createPortal(
                            <ToReassign
                                isOpened={isreassign}
                                apiProps={{
                                    inv_id: reqId,
                                    org_id: +orgId,
                                    type: 2,
                                    status_id: prevStatusInfo?.[0]?.prev_status ? prevStatusInfo?.[0]?.prev_status : null,
                                    user_id: null,
                                    masterref_id: null,
                                    groupType: 2,
                                }}
                                changeStatus={changeStatus}
                                statusName={prevStatusInfo?.[0]?.status_name ? prevStatusInfo?.[0]?.status_name : null}
                                handleClose={() => setReassign(false)}
                                className={`${isreassign && "Show"}`}
                            />,
                            document.getElementById("user-modal-root")
                        )}
                        {selectUserAnchor && (
                            <>
                                {ReactDOM.createPortal(
                                    <SelectUserModal
                                        id={1}
                                        isOpened={selectUserAnchor}
                                        setSelectUserAnchor={setSelectUserAnchor}
                                        currentMode={currentMode}
                                        className={selectUserAnchor && "Show"}
                                        getEmail={userEmail}
                                        userList={nextUserList}
                                        setUserEmail={setUserEmail}
                                        changeStatus={changeStatus}
                                        statusReqObj={statusReqObj}
                                        handleSelectUser={(e, value, reason) => _handleSelectUser(1, value, reason, "rule_id")}
                                    />,
                                    document.getElementById("Modal-root")
                                )}
                            </>
                        )}
                    </div>
                </Main>
                <Footer />
                {/* </ErrorBoundary> */}
            </Layout>
        </>
    );
};

export default MobilizationRequest;
