import { MenuItem, Select } from "@mui/material";
import Button from "../../../components/Button";
import Input from "../../../components/Input";

export const CreateTicketModal = (props) => {
    return (
        <>
            <span className="mx-[35px] font-interSb mb-[20px]">
                Ticket Details:
            </span>
            <form
                // onSubmit={handleSubmit}
                className="mx-[35px] flex justify-between flex-col h-full"
            >
                <div>
                    <div className="mb-5 space-x-7 viewStyles">
                        <div>
                            <label className="block text-sm pb-3">
                                Subject
                            </label>
                            <Input
                                name="firstname"
                                // onChange={handleChange}
                                // value={values?.firstname}
                                maxLength={50}
                                placeholder="Enter your subject"
                                className="border-[1px] border-[rgba(85,85,85,.3)] rounded-[8px] px-[20px] py-[11px] w-full"
                            />
                            {/* {errors && (
                            <p className="text-light-50 text-xs">{errors?.firstname}</p>
                        )} */}
                        </div>
                    </div>
                    <div className="flex mb-5 space-x-7 viewStyles e-disabled">
                        <div className="basis-1/2">
                            <label className="block text-sm pb-3 ">
                                Contact Name
                            </label>
                            <Input
                                placeholder="Enter your name"
                                // onChange={handleChange}
                                // value={values?.emailaddress}
                                maxLength={250}
                                name="emailaddress"
                                className="border-[1px] border-[rgba(85,85,85,.3)] rounded-[8px] px-[20px] py-[11px] w-full"
                            />
                            {/* {errors && (
                            <p className="text-light-50 text-xs">{errors?.emailaddress}</p>
                        )} */}
                        </div>
                        <div className="basis-1/2">
                            <label className="block text-sm pb-3">Email</label>
                            <Input
                                placeholder="Enter your email"
                                // onChange={handleChange}
                                // value={values?.emailaddress}
                                maxLength={250}
                                name="emailaddress"
                                className="border-[1px] border-[rgba(85,85,85,.3)] rounded-[8px] px-[20px] py-[11px] w-full "
                            />
                            {/* {errors && (
                            <p className="text-light-50 text-xs">{errors?.country}</p>
                        )} */}
                        </div>
                    </div>
                    <div className="flex mb-5 space-x-7 viewStyles e-disabled">
                        <div className="basis-1/2">
                            <label className="block text-sm pb-3">
                                Priority
                            </label>
                            <Select
                                // onChange={handleChange}
                                // value={values?.country || "Select"}
                                value={"default"}
                                name="priority"
                                className=" w-full"
                            >
                                <MenuItem
                                    value="default"
                                    sx={{ display: "none" }}
                                >
                                    Select
                                </MenuItem>
                                {/* <MenuItem selected value={values?.country_id || "Select"}>
                                {values?.country_name || "Select"}
                            </MenuItem>
                            {country?.map((elem, index) => {
                                return (
                                    <MenuItem key={index} value={elem?.country_id}>
                                        {elem?.country_name}
                                    </MenuItem>
                                );
                            })} */}
                            </Select>
                        </div>
                        <div className="basis-1/2">
                            <label className="block text-sm pb-3">Type</label>
                            <Select
                                // onChange={handleChange}
                                // defaultValue="default"
                                value={"default"}
                                name="type"
                                className=" w-full"
                            >
                                <MenuItem
                                    value="default"
                                    sx={{ display: "none" }}
                                >
                                    Select
                                </MenuItem>
                                {/* {country?.map((elem, index) => {
                                return (
                                    <MenuItem key={index} value={elem?.country_id}>
                                        {elem?.country_name}
                                    </MenuItem>
                                );
                            })} */}
                            </Select>
                        </div>
                    </div>
                    <div className="mb-5 space-x-7 viewStyles">
                        <div>
                            <label className="block text-sm pb-3">
                                Subject
                            </label>
                            <textarea
                                name="firstname"
                                // onChange={handleChange}
                                // value={values?.firstname}
                                // maxLength={50}
                                rows={5}
                                placeholder="Enter your description"
                                className="border-[1px] resize-none border-[rgba(85,85,85,.3)] rounded-[8px] px-[20px] py-[11px] w-full"
                            />
                            {/* {errors && (
                            <p className="text-light-50 text-xs">{errors?.firstname}</p>
                        )} */}
                        </div>
                    </div>
                    <div className="flex items-center space-x-3">
                        <label
                            htmlFor="file-input"
                            className="file-label bg-light-20 cursor-pointer relative w-[40px] h-[40px] rounded-full grid place-items-center"
                        >
                            <input
                                type="file"
                                id="file-input"
                                class="file-input"
                            />
                            <span class="file-icon">
                                <img
                                    src="/images/main/attach-white.svg"
                                    alt=""
                                />
                            </span>
                        </label>
                        <span>Attachment(s)Up to 5 MB</span>
                    </div>
                </div>
                <div className="border-t-[1px] mx-[-35px] px-[35px] py-5 border-[rgba(236,236,236,0.88)] mt-6">
                    <div className="space-x-2 flex">
                        <Button
                            // disabled={fetching || (requestComplete && profile?.status)}
                            title="To Create the User"
                            type="submit"
                            className="bg-light-20 flex text-base font-interR py-[.5rem] px-[1.6rem] rounded-[8px] text-white"
                        >
                            {`Create`}
                        </Button>
                        <Button
                            type="button"
                            title="To Cancel"
                            onClick={props?.cartClose}
                            className="bg-light-240 flex text-base font-interR py-[.5rem] px-[1.7rem] rounded-[8px] text-light-40 closeModal"
                        >
                            Cancel
                        </Button>
                    </div>
                </div>
            </form>
        </>
    );
};
