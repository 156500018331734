import axios from "axios";
import configMyiQ from "../../configuration";
import authHeader from "./auth-header";
import { fetchMenuArr } from "../../utils/helpers";
import localforage from "localforage";
import { checkApiStatus } from "../../utils/helpers";
import {
    ROLE_FAILURE,
    ROLE_REQUEST,
    ROLE_SUCCESS,
    USER_ROLE_BYORG_FAILURE,
    USER_ROLE_BYORG_REQUEST,
    USER_ROLE_BYORG_SUCCESS,
    USER_ROLEBYORG_VIEW_FAILURE,
    USER_ROLEBYORG_VIEW_REQUEST,
    USER_ROLEBYORG_VIEW_SUCCESS,
    LIST_ORG_ROLEBYUSER_REQUEST,
    LIST_ORG_ROLEBYUSER_SUCCESS,
    LIST_ORG_ROLEBYUSER_FAILURE,
    ADD_UPDATE_USER_ROLEBYUSER_REQUEST,
    ADD_UPDATE_USER_ROLEBYUSER_SUCCESS,
    ADD_UPDATE_USER_ROLEBYUSER_FAILURE,
    ROLE_PERMISSION_LIST_REQUEST,
    ROLE_PERMISSION_LIST_SUCCESS,
    ROLE_PERMISSION_LIST_FAILURE,
    ROLE_PERMISSION_ADD_REQUEST,
    ROLE_PERMISSION_ADD_SUCCESS,
    ROLE_PERMISSION_ADD_FAILURE,
    ROLE_PERMISSION_VIEW_REQUEST,
    ROLE_PERMISSION_VIEW_SUCCESS,
    ROLE_PERMISSION_VIEW_FAILURE,
    ROLE_PERMISSION_DISPLAY_REQUEST,
    ROLE_PERMISSION_DISPLAY_SUCCESS,
    ROLE_PERMISSION_DISPLAY_FAILURE,
    GET_ROLE_LIST_ALLOCATION_REQUEST,
    GET_ROLE_LIST_ALLOCATION_SUCCESS,
    GET_ROLE_LIST_ALLOCATION_FAILURE,
} from "../types/types";
import Cookies from "js-cookie";

const Token = localStorage.getItem("token");
const API_URL = process.env?.REACT_APP_API_URL;
const API = process.env?.REACT_APP_NODE_API_URL;

export const roleid = () => async (dispatch) => {
    try {
        dispatch({ type: ROLE_REQUEST });
        const orgId = localStorage.getItem("orgId");

        axios
            .get(`${API_URL}Role/GetListOfRoles`, {
                headers: authHeader(),
            })
            .then((response) => {
                dispatch({
                    type: ROLE_SUCCESS,
                    payload: response?.data?.datalist,
                });
            });
    } catch (error) {
        if (error?.response?.status == 401) {
            checkApiStatus();
        }
        dispatch({
            type: ROLE_FAILURE,
        });
    }
};

export const AddupdateroleOrg = (data) => async (dispatch) => {
    try {
        dispatch({ type: USER_ROLE_BYORG_REQUEST });

        axios
            .post(`${API_URL}Role/AddUpdateUserRole_ByOrganisation`, data, {
                headers: authHeader(),
            })
            .then((response) => {
                dispatch({
                    type: USER_ROLE_BYORG_SUCCESS,
                    payload: response.data,
                });
            })
            .catch(function (error) {
                dispatch({
                    payload: { error: error.message },
                    type: USER_ROLE_BYORG_FAILURE,
                });
            });
    } catch (error) {
        if (error?.response?.status == 401) {
            checkApiStatus();
        }
        dispatch({
            type: USER_ROLE_BYORG_FAILURE,
        });
    }
};

export const viewUserRoleByOrg = (data) => async (dispatch) => {
    try {
        dispatch({ type: USER_ROLEBYORG_VIEW_REQUEST });

        axios
            .post(`${API_URL}Role/GetRoles_byOrg`, data, {
                headers: authHeader(),
            })
            .then((response) => {
                dispatch({
                    type: USER_ROLEBYORG_VIEW_SUCCESS,
                    payload: response.data.datalist,
                });
            })
            .catch(function (error) {
                dispatch({
                    payload: { error: error.message },
                    type: USER_ROLEBYORG_VIEW_FAILURE,
                });
            });
    } catch (error) {
        if (error?.response?.status == 401) {
            checkApiStatus();
        }
        dispatch({
            type: USER_ROLEBYORG_VIEW_FAILURE,
        });
    }
};

export const listOrgRoleByUser = (data) => async (dispatch) => {
    try {
        dispatch({ type: LIST_ORG_ROLEBYUSER_REQUEST });

        axios
            .post(`${API_URL}User/GetAllUsersByOrg`, data, {
                headers: authHeader(),
            })
            .then((response) => {
                dispatch({
                    type: LIST_ORG_ROLEBYUSER_SUCCESS,
                    payload: response.data,
                });
            })
            .catch(function (error) {
                if (error?.response?.status == 401) {
                    checkApiStatus();
                }
                dispatch({
                    payload: { error: error.message },
                    type: LIST_ORG_ROLEBYUSER_FAILURE,
                });
            });
    } catch (error) {
        dispatch({
            type: LIST_ORG_ROLEBYUSER_FAILURE,
        });
    }
};

export const addUpdateUserRoleByUser = (data) => async (dispatch) => {
    try {
        dispatch({ type: ADD_UPDATE_USER_ROLEBYUSER_REQUEST });

        axios
            .post(`${API_URL}Role/AddUpdateUserRole_ByUser`, data, {
                headers: authHeader(),
            })
            .then((response) => {
                dispatch({
                    type: ADD_UPDATE_USER_ROLEBYUSER_SUCCESS,
                    payload: response.data,
                });
            })
            .catch(function (error) {
                if (error?.response?.status == 401) {
                    checkApiStatus();
                }
                dispatch({
                    payload: { error: error.message },
                    type: ADD_UPDATE_USER_ROLEBYUSER_FAILURE,
                });
            });
    } catch (error) {
        dispatch({
            type: ADD_UPDATE_USER_ROLEBYUSER_FAILURE,
        });
    }
};

export const RolePermissionList = () => async (dispatch) => {
    try {
        dispatch({ type: ROLE_PERMISSION_LIST_REQUEST });

        axios
            .get(`${API_URL}Role/GetRolePermission_List`, {
                headers: authHeader(),
            })
            .then((response) => {
                dispatch({
                    type: ROLE_PERMISSION_LIST_SUCCESS,
                    payload: response.data.datalist,
                });
            })
            .catch(function (error) {
                if (error?.response?.status == 401) {
                    checkApiStatus();
                }
                dispatch({
                    payload: { error: error.message },
                    type: ROLE_PERMISSION_LIST_FAILURE,
                });
            });
    } catch (error) {
        dispatch({
            type: ROLE_PERMISSION_LIST_FAILURE,
        });
    }
};

export const AddRolePermission = (data) => async (dispatch) => {
    try {
        dispatch({ type: ROLE_PERMISSION_ADD_REQUEST });

        axios
            .post(`${API}api/v1/rolePermission/createRole`, data, {
                headers: authHeader(),
            })
            .then((response) => {
                dispatch({
                    type: ROLE_PERMISSION_ADD_SUCCESS,
                    payload: response.data,
                });
            })
            .catch(function (error) {
                if (error?.response?.status == 401) {
                    checkApiStatus();
                }
                dispatch({
                    payload: { error: error?.response?.data?.message },
                    type: ROLE_PERMISSION_ADD_FAILURE,
                });
            });
    } catch (error) {
        dispatch({
            type: ROLE_PERMISSION_ADD_FAILURE,
        });
    }
};

export const ViewRolePermission = (data) => async (dispatch) => {
    try {
        dispatch({ type: ROLE_PERMISSION_VIEW_REQUEST });

        axios
            .get(`${API}api/v1/rolePermission/roleView?roleid=${data?.roleid}`, {
                headers: authHeader(),
            })
            .then((response) => {
                dispatch({
                    type: ROLE_PERMISSION_VIEW_SUCCESS,
                    payload: response?.data?.data,
                });
            })

            .catch(function (error) {
                if (error?.response?.status == 401) {
                    checkApiStatus();
                }
                dispatch({
                    payload: { error: error.message },
                    type: ROLE_PERMISSION_VIEW_FAILURE,
                });
            });
    } catch (error) {
        dispatch({
            type: ROLE_PERMISSION_VIEW_FAILURE,
        });
    }
};

export const RolePermissionDisplay = (data) => async (dispatch) => {
    try {
        fetchMenuArr().then((res) => {
            if (res !== undefined || res !== null) {
                dispatch({
                    type: ROLE_PERMISSION_DISPLAY_REQUEST,
                    payload: res,
                });
            }
            axios
                .get(`${API}api/v1/rolePermission/rolePermissions?orgid=${data?.orgid == null || data?.orgid == "undefined" ? "0" : data?.orgid}`, {
                    headers: authHeader(),
                })
                .then((response) => {
                    dispatch({
                        type: ROLE_PERMISSION_DISPLAY_SUCCESS,
                        payload: response?.data?.datalist?.[0],
                    });
                    localforage.setItem("MenuItems", response?.data?.datalist?.[0]);
                    // console.log(response?.data?.data, "000");
                })
                .catch(function (error) {
                    if (error?.response?.status == 401) {
                        checkApiStatus();
                    }
                    dispatch({
                        payload: { error: error.message },
                        type: ROLE_PERMISSION_DISPLAY_FAILURE,
                    });
                });
        });
    } catch (error) {
        dispatch({
            type: ROLE_PERMISSION_DISPLAY_FAILURE,
        });
    }
};

export const getRoleListAllocation = (orgid) => async (dispatch) => {
    try {
        dispatch({ type: GET_ROLE_LIST_ALLOCATION_REQUEST });

        axios
            .get(`${API}api/getrolelistforallocation?orgid=${orgid}`, {
                headers: authHeader(),
            })
            .then((response) => {
                dispatch({
                    type: GET_ROLE_LIST_ALLOCATION_SUCCESS,
                    payload: response?.data?.datalist,
                });
            })

            .catch(function (error) {
                if (error?.response?.status == 401) {
                    checkApiStatus();
                }
                dispatch({
                    payload: { error: error.message },
                    type: GET_ROLE_LIST_ALLOCATION_FAILURE,
                });
            });
    } catch (error) {
        dispatch({
            type: GET_ROLE_LIST_ALLOCATION_FAILURE,
        });
    }
};
