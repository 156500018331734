import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from "recharts";
import { ThemeContext } from "../../../../Hooks/Themes/Themeprovider";
import { useContext } from "react";
import { extractTextFromHTML } from "../../../../utils/helpers";

const Linechart = ({ preview }) => {
    const { currentMode } = useContext(ThemeContext);

    const dataKeys = preview?.data && Object.keys(preview?.data?.[0]);
    const hasNoData = preview?.data?.every((item) => item[dataKeys?.[0]] === null || item[dataKeys?.[0]] === 0);
    const textColor = currentMode === "dark" ? `#ECECEC` : `#000`;
    const CustomizedAxisTick = ({ x, y, payload }) => {
        const maxLength = preview?.data?.length > 5 ? 2 : 10; // Maximum length for label
        const text = payload.value.length > maxLength ? payload.value.substring(0, maxLength) + ".." : payload.value;

        return (
            <g transform={`translate(${x},${y})`}>
                <text x={0} y={0} dy={16} style={{ fontSize: "13px" }} textAnchor="middle" fill={textColor} className="xAxisLabel">
                    {`${text}`}
                </text>
            </g>
        );
    };
    const CustomTooltip = ({ active, payload }) => {
        if (active && payload && payload?.length) {
            const entry = payload[0]?.payload;

            return (
                <div className={` py-3 px-5 border recharts-default-tooltip rounded-[8px]`}>
                    <p className="label">{`${extractTextFromHTML(entry?.[dataKeys?.[1]])} : ${entry?.[dataKeys?.[0]]}`}</p>
                </div>
            );
        }
    };

    return !hasNoData ? (
        <ResponsiveContainer width="100%" height={380}>
            <LineChart
                data={preview?.data}
                margin={{
                    top: 5,
                    right: 35,
                    left: 5,
                    bottom: 5,
                }}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey={dataKeys?.[1]} tick={<CustomizedAxisTick />} />
                <YAxis />
                <Tooltip content={<CustomTooltip />} />
                <Legend verticalAlign="top" height={36} iconType={"square"} align="right" />
                <Line type="monotone" dataKey={dataKeys?.[0]} stroke="#a21231" />
            </LineChart>
        </ResponsiveContainer>
    ) : (
        <b className="text-center block">Preview data not found</b>
    );
};

export default Linechart;
