import { useDispatch, useSelector } from "react-redux";
import Button from "../../../components/Button";
import Input from "../../../components/Input";
import ReactDOM from "react-dom";
import UseForm from "../../../Hooks/useForm";
import { getOrganisation, organisationAdd, organisationDomainDetails, organisationEdit } from "../../../redux/actions/organisation";
import { useEffect, useState, useRef, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { ORG_ADD_RESET, ORG_DOMAIN_RESET, ORG_EDIT_RESET, ORG_VIEW_RESET, SETTING_RESET } from "../../../redux/types/types";
import { validateOrganisationAdd } from "../../../Hooks/validate";
import MessageBox from "../../../components/UI/Alert";
import SuccessMessageBox from "../../../components/UI/Alert/sucess";
import { NavLink } from "react-router-dom";
import { Checkbox, FormControl, MenuItem, Select, InputLabel, Tabs, Tab, Avatar, Box, Typography, TextField, Tooltip } from "@mui/material";
import { SettingData } from "../../../redux/actions/setting";
import Swal from "sweetalert2";
import { getCurrentUser } from "../../../redux/actions/auth";
import Loader from "../../../components/Loader";
import AddAlias from "./AddAlias";
import { ThemeContext } from "../../../Hooks/Themes/Themeprovider";
import PropTypes from "prop-types";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

const currentUser = getCurrentUser();
const successMsg = "New organization has been created successfully.";
const UpdateSuccessMsg = "Organization is updated successfully";

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
            {value === index && (
                <div className="p-[30px] pb-0">
                    <div>{children}</div>
                </div>
            )}
        </div>
    );
}
//tab panel
CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}
let getCurrency;
const AddOrganisationModal = (props) => {
    const { country } = useSelector((state) => state?.countryState);
    const [tabvalue, setTabValue] = useState(0);

    const currencyArr = country?.map((ele) => {
        return {
            currency: ele?.currency,
            currency_id: ele?.currency_id,
        };
    });
    const uniqueObjects = new Set();
    const filteredCurrencyData = currencyArr.filter((obj) => {
        const jsonString = JSON.stringify(obj);
        if (!uniqueObjects.has(jsonString)) {
            uniqueObjects.add(jsonString);
            return true;
        }

        return false;
    });
    const [checkSubmit, setCheckSubmit] = useState(true);
    const _tabHandleChange = (event, newValue, next) => {
        if (next == "next") {
            setCheckSubmit(false);
            setErrors(validateOrganisationAdd(values));
            setTabValue(newValue);
        } else {
            setTabValue(newValue);
            if (newValue == 0) {
                setTooltipIsOpen(false);
            }
        }
    };
    const OrgEditData = useSelector((state) => (props?.actionType == 1 ? state?.organisationViewState : ""));
    const { viewData } = OrgEditData;
    let ListDatas = viewData?.org_alias_list;
    const [errormsgs, seterrormsg] = useState(false);
    //useforms
    const { handleChange, checkChange, handleSubmit, setIsSubmitting, values, errors, setErrors, setValues, InitialState } = UseForm(
        submit,
        validateOrganisationAdd,
        viewData
    );
    // states to maintain data
    const [dateValue, handleDateChange] = useState("");
    const [numberValue, handleNumberChange] = useState("");
    const [countryValue, setCountryValue] = useState("");
    const [duplicateAlias, setDuplicateAlias] = useState(false);
    const [emptyAlias, setEmptyAlias] = useState(false);
    const [matchOrgAlias, setmatchOrgAlias] = useState(false);
    const Setting = useSelector((state) => state?.settingState);
    const [addAlias, setAddAlias] = useState(false);
    const [aliasInfo, setaliasInfo] = useState([]);
    const [newaliasInfo, setnewaliasInfo] = useState([]);
    const [fromMonth, setFromMonth] = useState(values?.fy_start !== "" ? values?.fy_start : props?.constantFicalPeriod?.fy_start);
    const [toMonth, setToMonth] = useState(values?.fy_end !== "" ? values?.fy_end : props?.constantFicalPeriod?.fy_end);
    const aliasOrgnameDuplication = newaliasInfo?.filter((el) => el?.alias_name == values?.organisation_name && el?.alias_name !== "");
    //fiscalFrom
    const fiscalFromOnchange = (e) => {
        setFromMonth(e.target.value);
        if (e.target.value == 1) {
            setToMonth(12);
        } else {
            setToMonth(e.target.value - 1);
        }
    };
    //fiscalTo
    const fiscalToOnchange = (e) => {
        setToMonth(e.target.value);
        if (e.target.value == 12) {
            setFromMonth(1);
        } else {
            setFromMonth(e.target.value + 1);
        }
    };
    const aliasOrgDuplication = aliasInfo?.filter((el) => el?.alias_name == values?.organisation_name && el?.alias_name !== "");
    const aliasDuplication = aliasOrgnameDuplication?.length > 0 || aliasOrgDuplication?.length > 0;
    const { data: settingdata } = Setting;
    const handleNumberValueChange = (value) => {
        handleNumberChange(value);
    };
    const handleDateValueChange = (value) => {
        handleDateChange(value);
    };
    const [checkCurrency, setCheckCurrency] = useState(false);
    //onchange-country
    const handleCountryValueChange = (value) => {
        setCheckCurrency(true);
        setCountryValue(value);
        setHandleRender(true);
    };
    const [isCurrency, setIsCurrency] = useState("");
    //onChange-currency
    const handleCurrencyId = (e) => {
        setIsCurrency(e.target.value);
        values.currency_id = e.target.value;
    };
    let filteredDateFormatList = settingdata?.filter((elem) => elem?.code === "CUTOFF");

    const months = [
        { monthName: "January", val: 1 },
        { monthName: "February", val: 2 },
        { monthName: "March", val: 3 },
        { monthName: "April", val: 4 },
        { monthName: "May", val: 5 },
        { monthName: "June", val: 6 },
        { monthName: "July", val: 7 },
        { monthName: "August", val: 8 },
        { monthName: "September", val: 9 },
        { monthName: "October", val: 10 },
        { monthName: "November", val: 11 },
        { monthName: "December", val: 12 },
    ];
    const DateValue = filteredDateFormatList[0]?.value;
    if (filteredDateFormatList?.length > 0) {
        filteredDateFormatList = JSON.parse(filteredDateFormatList[0]?.data_list);
    }
    let filteredNumberFormatList = settingdata?.filter((elem) => elem?.code === "NUMFOR");

    if (filteredNumberFormatList?.length > 0) {
        filteredNumberFormatList = JSON.parse(filteredNumberFormatList[0]?.data_list);
    }

    const NumValue = filteredNumberFormatList[0]?.value;
    const aliasDataa = aliasInfo?.filter((el) => el?.alias_name !== "");
    const aliasLength = aliasDataa?.length;
    const aliasData = newaliasInfo?.filter((el) => el?.alias_name !== "");
    const aliasaddLength = aliasData?.length;
    const ListData = viewData?.org_alias_list;
    const AddNew = ListData == undefined || ListData == null;
    const LengthAddNew = newaliasInfo?.length == 1 && newaliasInfo?.map((el) => el?.alias_name == "");
    const LengthRow = aliasInfo?.length == 1 && aliasInfo?.map((el) => el?.alias_name == "");

    const Row = ListData !== undefined && ListData !== null;

    const aliasNameAdded = aliasaddLength > 1 ? " alias names added" : " alias name added";

    const aliasnameValue = aliasLength > 1 ? " alias names added" : " alias name added";

    let aliasValue = aliasaddLength > 0 && AddNew ? aliasaddLength + aliasNameAdded : aliasLength > 0 && Row ? aliasLength + aliasnameValue : "";

    const [aliasState, setAliasState] = useState("");
    const [aliastrue, setaliasTrue] = useState(false);
    // condition for country
    if (viewData?.length !== 0 && props?.orgId) {
        values.organisation = !values.organisation ? viewData?.organisation_name : values.organisation;
        values.org_country_id = viewData?.organisation_country?.country_id;
        values.org_country_name = viewData?.organisation_country?.country_name;
    }
    const dispatch = useDispatch();
    const [isParking, setIsParking] = useState(null);
    //onchange- parking
    const handleIsParking = (e) => {
        setIsParking(e.target.value);
        values.is_parking = e.target.value;
    };
    const [isThreeWayMatching, setIsThreeWayMatching] = useState(null);
    //onchange-3 way matching
    const handleThreeWayMatching = (e) => {
        setHandleRender(true);
        setIsThreeWayMatching(e.target.value);
        values.three_way_matching = e.target.value;
    };

    const [fileName, onFileUpload] = useState("");
    const [handleRender, setHandleRender] = useState(false);
    // to maintain fiscal data on every render
    useEffect(() => {
        if (props?.actionType == 0 && props?.constantFicalPeriod?.fy_start !== undefined && props?.constantFicalPeriod?.fy_end !== undefined) {
            setFromMonth(props?.constantFicalPeriod?.fy_start);
            setToMonth(props?.constantFicalPeriod?.fy_end);
        } else if (props?.actionType == 1 && props?.isOpened == true) {
            setHandleRender(false);
            setFromMonth(values?.fy_start);
            setToMonth(values?.fy_end);
            setCountryValue(values?.country_id);
            handleNumberChange(values?.numformat);
            handleDateChange(values?.dateformat);
        } else {
            setFromMonth("0");
            setToMonth("0");
        }
    }, [values?.fy_start, values?.fy_end, props?.constantFicalPeriod?.fy_start, props?.constantFicalPeriod?.fy_end, props?.isOpened]);
    const selectedDate = settingdata?.find((elem) => elem?.code === "CUTOFF");
    const selectedValue = settingdata?.find((elem) => elem?.code === "NUMFOR");
    if (selectedDate?.value && dateValue === "") {
        handleDateValueChange(selectedDate?.value);
    }
    if (selectedValue?.value && numberValue === "") {
        handleNumberValueChange(selectedValue?.value);
    }
    if (values?.org_country_id && countryValue === "") {
        handleCountryValueChange(values?.org_country_id);
    }
    const reqStr = {
        org_alias: JSON.stringify(aliasInfo),
    };
    //submit function
    function submit() {
        if (props?.actionType == 1 && (countryValue == "" || countryValue == 0)) {
            seterrormsg(true);
        } else {
            seterrormsg(false);
        }
        let formdata = new FormData();
        const Str = [
            { name: "CUTOFF", value: `${dateValue}` },
            { name: "NUMFOR", value: `${numberValue}` },
        ];
        const reqObj = {
            id: +values?.id,
            Logo: fileName,
            organisation: values?.organisation_name?.trim(),
            registration_no: values?.registration_no?.trim(),
            address_line1: values?.address_line1?.trim(),
            zipcode: values?.zipcode,
            organisation_code: values?.organisation_code,
            fy_start: +fromMonth,
            fy_end: +toMonth,
            org_tolerance: values?.tolerance,
            org_currency: +values?.currency_id,
            org_parkingIsRequired: values?.is_parking,
            org_threeWayMatching: values?.three_way_matching,
            url: values?.url,
            country: props?.actionType !== 0 ? +countryValue : values?.country_id,
            org_alias:
                Row && (LengthRow[0] == false || !LengthRow) ? aliasInfo : AddNew && (LengthAddNew[0] == false || !LengthAddNew) ? newaliasInfo : "",
            match_netamount: values?.match_netamount,
            retention_recovery: values?.retention_recovery,
            advance_recovery: values?.advance_recovery,
            json: Str,
            is_default: isDefaultLogo,
        };
        if (duplicateAlias) {
            Swal.fire({
                text: "Alias name should not have duplicate names!",
                icon: "warning",
                closeOnClickOutside: false,
                showCloseButton: true,
                buttons: {
                    confirm: true,
                },
            });
        } else if (matchOrgAlias) {
            Swal.fire({
                text: "Alias name should not match with organization name!",
                icon: "warning",
                closeOnClickOutside: false,
                showCloseButton: true,
                buttons: {
                    confirm: true,
                },
            });
        } else if (aliasOrgDuplication?.length > 0) {
            Swal.fire({
                text: "This organization name is used as alias! ",
                icon: "warning",
                closeOnClickOutside: false,
                showCloseButton: true,
                buttons: {
                    confirm: true,
                },
            });
        } else if (values.id && props?.actionType !== 0 && countryValue !== "" && countryValue !== 0) {
            seterrormsg(false);
            if (orgDomainResponse?.fetching) {
                setTimeout(() => {
                    dispatch(organisationEdit(reqObj));
                }, 700);
            } else {
                dispatch(organisationEdit(reqObj));
            }
            setIsSubmitting(false);
            return false;
        } else if (aliasOrgnameDuplication?.length > 0) {
            Swal.fire({
                text: "This organisation name is used as Alias!",
                icon: "warning",
                closeOnClickOutside: false,
                showCloseButton: true,
                buttons: {
                    confirm: true,
                },
            });
        } else if (values?.address_line1 == "") {
            // console.log("");
        } else {
            if (props?.actionType == 0 && checkSubmit) {
                dispatch(organisationAdd(reqObj));
            }
        }
    }
    const [newrow, setnewRow] = useState([]);
    //modalClose function
    const cartClose = () => {
        seterrormsg(false);
        handleDateChange("");
        handleNumberChange("");
        setValues(InitialState);
        onFileUpload("");
        setDuplicateAlias(false);
        props?.cartClose();
        setErrors({});
        _tabHandleChange("", 0);
        dispatch({ type: SETTING_RESET });
        dispatch({ type: ORG_VIEW_RESET });
        dispatch({ type: ORG_ADD_RESET });
        dispatch({ type: ORG_EDIT_RESET });
        setaliasInfo([]);
        setnewRow([]);
        setFromMonth(props?.constantFicalPeriod?.fy_start);
        setToMonth(props?.constantFicalPeriod?.fy_end);
        setaliasTrue(true);
        setmatchOrgAlias(false);
    };

    const [isDomain, onDomainError] = useState(false);
    const { fetching, error, requestComplete, data, profile } = useSelector((state) =>
        props.orgId ? state?.organisationEditState : state?.organisationAddState
    );
    let check = values?.country_id;
    //maintain data on render
    useEffect(() => {
        if (values?.tolerance !== "") {
            values.tolerance = +values?.tolerance > 100 ? "100" : values?.tolerance;
        }
        if (values?.is_parking !== null) {
            setIsParking(values?.is_parking);
        }
        if (values?.three_way_matching == true || values?.three_way_matching == false) {
            setIsThreeWayMatching(values?.three_way_matching);
        }
    }, [values?.tolerance, values?.is_parking, values?.three_way_matching]);

    //get data from country list when select country dropdown

    useEffect(() => {
        if (values?.country_id && props?.actionType == 0) {
            getCurrency = country.find((item) => item?.country_id == values?.country_id)?.currency_id;
            const getNumberFormat = country.find((item) => item?.country_id == values?.country_id)?.number_format;
            const getDateFormat = country.find((item) => item?.country_id == values?.country_id)?.date_format;
            setCountryValue(values?.country_id);
            values.currency_id = getCurrency;
            handleNumberChange(getNumberFormat);
            handleDateChange(getDateFormat);
        } else if (props?.actionType !== 0 && countryValue && props?.isOpened && handleRender) {
            getCurrency = country.find((item) => item?.country_id == countryValue)?.currency_id;
            const getNumberFormat = country.find((item) => item?.country_id == countryValue)?.number_format;
            const getDateFormat = country.find((item) => item?.country_id == countryValue)?.date_format;
            values.currency_id = getCurrency;
            // setCountryValue(values?.country_id);
            handleNumberChange(getNumberFormat);
            handleDateChange(getDateFormat);
        }
    }, [values?.country_id, countryValue]);
    //reset condition
    if (requestComplete && profile?.status) {
        setTimeout(() => {
            localStorage.setItem("selectedOrgId", profile?.data?.o_id);
            localStorage.setItem("UpdateOrgId", profile?.data?.o_id);
            dispatch({ type: ORG_ADD_RESET });
        }, 2000);
    }
    const orgDomainResponse = useSelector((state) => state?.OrgDetailsOnDomainState);
    const orgResult = orgDomainResponse?.requestComplete ? orgDomainResponse?.result : "";
    //refresh condition
    if (requestComplete && data?.status) {
        setTimeout(() => {
            dispatch({ type: ORG_EDIT_RESET });
            cartClose();
            const reqObj = {
                status: 2,
                search_text: "",
                // organsiation: orgId,
            };
            localStorage.setItem("selectedOrgId", +values?.id);
            localStorage.setItem("UpdateOrgId", +values?.id);
            //dispatch(getOrganisation(reqObj));

            window.location.reload();
        }, 2000);
    }
    const [isDefaultLogo, setIsDefaultLogo] = useState(true);
    const navigate = useNavigate();
    //for alias data maintaining
    useEffect(() => {
        setAliasState(aliasValue);
        setaliasTrue(false);
        if (aliastrue) {
            setAliasState("");
            setaliasInfo([]);
            setnewaliasInfo([]);
        }
        dispatch({ type: ORG_DOMAIN_RESET });
        bindOrgData();
        if (fileName !== "") {
            setIsDefaultLogo(false);
        }
        return () => {
            dispatch({ type: ORG_ADD_RESET });
        };
    }, [navigate, isDomain, fileName, viewData, aliasValue]);
    const reqObj = {
        status: 2,
        search_text: "",
        // organsiation: orgId,
    };
    if (requestComplete && profile?.status) {
        setTimeout(function () {
            props.cartClose();
            window.location.reload();
            // dispatch(getOrganisation(reqObj));
        }, 2000);
    } else if (requestComplete && !fetching) {
        setTimeout(function () {
            // setSubmitClick(false);

            dispatch({ type: ORG_ADD_RESET });

            dispatch({ type: ORG_EDIT_RESET });
        }, 1000);
    } else if (error) {
        setTimeout(() => {
            dispatch({ type: ORG_EDIT_RESET });
        }, 2000);
    }
    const [tooltipIsOpen, setTooltipIsOpen] = useState(false);
    const countryObj = values?.country ? country?.find((item) => item.country_id === +values?.country) : "";

    const fileTypes = ["image/png", "image/jpg", "image/jpeg"];
    // onchange function for logo
    // const onChange = (e) => {
    //     let file = e.target.files[0];
    //     var input = document.getElementById("imageInput");
    //     const fixExist = fileTypes?.find((item) => item == file?.type);
    //     const fileSize = (file.size / (1 * 1024)).toFixed(2); //mb conversion
    //     if (input.files && input.files[0]) {
    //         var reader = new FileReader();
    //         reader.onload = function (e) {
    //             var image = new Image();
    //             image.onload = function () {
    //                 var width = image.width;
    //                 var height = image.height;
    //                 console.log("Image Width: " + width + "px");
    //                 console.log("Image Height: " + height + "px");
    //             };
    //             image.src = e.target.result; // Set the source after the reader has finished reading the file.
    //         };
    //         reader.readAsDataURL(input.files[0]);
    //     }

    //     if (!fixExist) {
    //         Swal.fire({
    //             text: "File type is not supported!",
    //             icon: "warning",
    //             closeOnClickOutside: false,
    //             showCloseButton: true,
    //             buttons: {
    //                 confirm: true,
    //             },
    //         });
    //     } else if (fileSize > 100) {
    //         Swal.fire({
    //             text: "File size should be less than 100kb!",
    //             icon: "warning",
    //             closeOnClickOutside: false,
    //             showCloseButton: true,
    //             buttons: {
    //                 confirm: true,
    //             },
    //         });
    //     } else {
    //         // onSaveFile(file);
    //         const reader = new FileReader();
    //         reader.onload = _handleReaderLoaded.bind(this);
    //         reader.readAsBinaryString(file);
    //         // handleUpload();
    //     }
    // };

    const onChange = (e) => {
        let file = e.target.files[0];
        const input = document.getElementById("imageInput");
        const fixExist = fileTypes?.find((item) => item === file?.type);
        const fileSize = (file.size / (1 * 1024)).toFixed(2); // KB conversion
        if (!fixExist) {
            Swal.fire({
                text: "File type is not supported!",
                icon: "warning",
                closeOnClickOutside: false,
                showCloseButton: true,
                buttons: {
                    confirm: true,
                },
            });
        } else if (fileSize > 100) {
            Swal.fire({
                text: "File size should be less than 100kb!",
                icon: "warning",
                closeOnClickOutside: false,
                showCloseButton: true,
                buttons: {
                    confirm: true,
                },
            });
        } else if (input.files && input?.files[0]) {
            const reader = new FileReader();
            reader.onload = function (e) {
                const image = new Image();
                image.src = e.target.result; // Set the image source to the loaded result
                image.onload = function () {
                    const width = image.width;
                    const height = image.height;
                    if (width > 60 || height > 60) {
                        Swal.fire({
                            text: "Image dimensions must be 60x60 or smaller!",
                            icon: "warning",
                            closeOnClickOutside: false,
                            showCloseButton: true,
                            buttons: {
                                confirm: true,
                            },
                        });
                    } else {
                        // onSaveFile(file);
                        const reader = new FileReader();
                        reader.onload = _handleReaderLoaded.bind(this);
                        reader.readAsBinaryString(file);
                        // handleUpload();
                    }
                };
            };
            reader.readAsDataURL(input.files[0]);
        }
    };

    const _handleReaderLoaded = (e) => {
        let binaryString = e.target.result;

        errors.org_logo = "";

        values.org_logo = binaryString;

        onFileUpload(btoa(binaryString));
    };
    //get domain response
    const getDomainSearch = (domainName) => {
        if (values?.url == " ") {
            errors.url = "Organization Domain Name is required";
            setErrors(errors);
            onDomainError(true);
        } else if (
            !/^(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,}|[a-z0-9-]{4,30}\.[a-z]{2,})*$/i.test(
                values?.url
            )
        ) {
            errors.url = "Organization Domain Name is invalid";
            setErrors(errors);
            onDomainError(true);
        } else {
            errors.url = "";
            setErrors(errors);
            onDomainError(false);
            const domainObj = {
                domainName: domainName,
            };
            dispatch(organisationDomainDetails(domainObj));
            onFileUpload("");
        }
    };
    const checkDate = filteredDateFormatList?.find((ele) => ele?.value == dateValue);

    const checkNumber = filteredNumberFormatList?.find((ite) => ite?.value == numberValue);
    useEffect(() => {
        if (checkDate == undefined) {
            handleDateChange("dd/mm/yyyy");
        }
        if (checkNumber == undefined) {
            handleNumberChange("1,23,456.78");
        }
    }, [checkDate, checkNumber]);

    const bindOrgData = () => {
        let countryFromDomain = null;
        if (orgResult) {
            if (orgResult?.location?.country) {
                countryFromDomain = country.find((item) => item.country_name.toLowerCase() === orgResult?.location?.country.toLowerCase());
                values.org_country_id = countryFromDomain?.country_id;
                values.org_country_name = countryFromDomain?.country_name;
            }
            if (orgResult?.org_logo && fileName == "") {
                let image;
                image = new Image();
                image.crossOrigin = "Anonymous";
                image.addEventListener("load", function () {
                    let canvas = document.createElement("canvas");
                    let context = canvas.getContext("2d");
                    canvas.width = image.width;
                    canvas.height = image.height;
                    context.drawImage(image, 0, 0);
                    try {
                        onFileUpload(canvas.toDataURL("image/png").replace("data:image/png;base64,", ""));
                    } catch (err) {
                        console.error(err);
                    }
                });
                image.src = orgResult?.org_logo;
            }
            if (orgResult?.name) {
                values.organisation = orgResult?.name;
            }
            if (orgResult?.location?.name) {
                values.address_line1 = orgResult?.location?.name;
            }
        } else if (viewData && props?.orgId) {
            // values.organisation = !values.organisation ? viewData?.organisation_name : values.organisation;
            // values.org_country_id = viewData?.organisation_country?.country_id;
            // values.org_country_name = viewData?.organisation_country?.country_name;

            if (viewData?.org_logo && !fileName) {
                onFileUpload(viewData?.org_logo);
            }
        }
    };
    if (
        values?.url == "" &&
        (values?.organisation_name == undefined || values?.organisation_name == "") &&
        values?.registration_no == "" &&
        values?.address_line1 == "" &&
        values?.country_id == "" &&
        values?.currency_id == "" &&
        values?.tolerance == "" &&
        values?.organisation_code == "" &&
        props?.actionType == 0 &&
        !addAlias &&
        props?.isOpened
    ) {
        var textarea = document?.getElementById("orgnameId");
        textarea?.focus();
    }

    const { currentMode } = useContext(ThemeContext);
    const dropdownColor = currentMode === "dark" ? "#333333" : `white`;
    const dropdownText = currentMode === "dark" ? "#ECECEC" : `#555555`;
    // maintain error handling
    useEffect(() => {
        if (
            errors?.url ||
            errors?.address_line1 ||
            errors?.organisation ||
            errors?.organisation_code ||
            errors?.country_id ||
            errors?.registration_no
        ) {
            setTooltipIsOpen(true);
        } else {
            setTooltipIsOpen(false);
        }
    }, [errors]);
    return (
        <>
            {error && <MessageBox error={error?.message} />}
            {requestComplete && profile?.status && <SuccessMessageBox success={successMsg} />}
            {requestComplete && !profile?.status && <MessageBox error={profile?.message} />}
            {requestComplete && data?.status && <SuccessMessageBox success={UpdateSuccessMsg} />}
            {requestComplete && !data?.status && <MessageBox error={data?.message} />}
            {props?.actionType !== 0 && viewData?.length === 0 ? (
                <Loader className="h-[calc(100vh_-_10rem)]" />
            ) : (
                <>
                    <div className={` px-[30px] py-[24px] border-b-[1px] border-${currentMode}-590 relative text-${currentMode}-copy`}>
                        <h4 className="font-interSb text-lg">{props?.actionType == 0 ? "Create Organization" : "Update Organization"}</h4>
                    </div>
                    <Tabs
                        value={tabvalue}
                        className="profileTab orgTab"
                        onChange={_tabHandleChange}
                        aria-label="basic tabs example"
                        TabIndicatorProps={{
                            sx: {
                                backgroundColor: "#00B399",
                                color: "#00B399",
                            },
                        }}
                    >
                        <Tab
                            label={
                                <Tooltip
                                    title={"Please fill in the mandatory fields"}
                                    open={tooltipIsOpen}
                                    onOpen={() => setTooltipIsOpen(true)}
                                    onClose={() => setTooltipIsOpen(false)}
                                    placement="top"
                                    disableInteractive
                                    disablePortal
                                    disableHoverListener={true}
                                    PopperProps={{
                                        sx: {
                                            "& .MuiTooltip-tooltip": {
                                                fontSize: "0.9em",
                                                maxHeight: "100px",
                                                maxWidth: "250px",
                                                background: "#FAE4E8",
                                                color: "#555555",
                                                borderRadius: "5px",
                                                border: "1px solid #E6A6B1",
                                                marginTop: "0 !important",
                                                padding: "10px",
                                                marginLeft: "150px",
                                            },
                                            "& .MuiTooltip-arrow": {
                                                color: "#FAE4E8",
                                            },
                                        },
                                    }}
                                    arrow
                                >
                                    <span>Organization Details</span>
                                </Tooltip>
                            }
                            {...a11yProps(0)}
                            iconPosition="start"
                            className={`${
                                errors?.url ||
                                errors?.address_line1 ||
                                errors?.organisation ||
                                errors?.organisation_code ||
                                errors?.country_id ||
                                errors?.registration_no
                                    ? "text-[#CE2644]"
                                    : ""
                            }`}
                            icon={
                                errors?.url ||
                                errors?.address_line1 ||
                                errors?.organisation ||
                                errors?.organisation_code ||
                                errors?.country_id ||
                                errors?.registration_no ? (
                                    <img src="/images/common/org-details-red.svg" />
                                ) : tabvalue == 0 ? (
                                    <img className="convertWhite" src="/images/common/org_details.svg" />
                                ) : (
                                    <img className="convertWhite" src="/images/common/org-details-black.svg" />
                                )
                            }
                        />{" "}
                        <Tab
                            label="Account Details"
                            {...a11yProps(1)}
                            iconPosition="start"
                            icon={
                                tabvalue == 1 ? (
                                    <img className="convertWhite" src="/images/common/org_acc_green.svg" />
                                ) : (
                                    <img className="convertWhite" src="/images/common/org_acc_details.svg" />
                                )
                            }
                        />
                    </Tabs>
                    <form onSubmit={handleSubmit} className=" flex justify-between flex-col h-full">
                        <CustomTabPanel value={tabvalue} index={0}>
                            <div className="h-[calc(100vh_-_250px)] overflow-y-auto">
                                <div className="space-y-[25px]">
                                    <div className="flex space-x-7 viewStyles">
                                        <div className="basis-1/2" onClick={() => getDomainSearch(values?.url)}>
                                            <span className="block text-sm pb-3">
                                                <span className="text-[red] mr-1">*</span>
                                                Organization Domain Name
                                            </span>

                                            <Input
                                                type="text"
                                                name="url"
                                                autoFocus
                                                tabIndex={0}
                                                id="orgnameId"
                                                onChange={handleChange}
                                                maxLength={101}
                                                value={values?.url}
                                                placeholder="www.domainname.com"
                                                className={`border-[1px] bg-${currentMode}-card border-${currentMode}-840 rounded-[8px] px-[15px] py-2 w-full`}
                                            />

                                            {errors && <p className="text-light-50 text-xs">{errors?.url}</p>}
                                        </div>

                                        <div className="basis-1/2">
                                            <span className="block text-sm pb-3">Organization Logo</span>
                                            <div className="flex space-x-3">
                                                <div
                                                    className={`bg-${currentMode}-card w-[40px] h-[20px]  ${
                                                        currentMode === "dark" ? "" : "shadow-[0px_4px_37px_#EEEEEE]"
                                                    } rounded-full`}
                                                >
                                                    <img
                                                        className="rounded-[50%] object-cover w-[40px] h-[40px]"
                                                        src={fileName ? `data:image;base64,${fileName}` : `/images/common/orgLogo.png`}
                                                        alt="Profile"
                                                    />
                                                </div>

                                                <div className="upload-btn-wrapper" title="To add Organization Logo">
                                                    <input
                                                        tabIndex={0}
                                                        className="cursor-pointer"
                                                        type="file"
                                                        name="Logo"
                                                        id="imageInput"
                                                        accept=".jpg, .jpeg, .png"
                                                        onChange={(e) => onChange(e)}
                                                    />

                                                    <NavLink to="#!" tabIndex={-1} className="flex cursor-pointer items-center mb-2 justify-center">
                                                        <img className="mr-1" src="/images/common/camera.svg" alt="camera" /> Upload
                                                    </NavLink>
                                                </div>
                                            </div>{" "}
                                            <span className="block text-[10px] ml-[53px] mt-[-10px]">
                                                Image with 60 × 60 px and max size of 100 KB.
                                            </span>
                                        </div>
                                    </div>
                                    <div className="flex space-x-7 viewStyles">
                                        <div className="basis-1/2">
                                            <span className={`block text-sm pb-3`}>
                                                <span className="text-[red] mr-1">*</span>
                                                Organization Name
                                            </span>
                                            <input
                                                name="organisation_name"
                                                tabIndex={0}
                                                onChange={handleChange}
                                                value={values?.organisation_name}
                                                maxLength={251}
                                                placeholder="Enter your Organization Name"
                                                className={`border-[1px] bg-${currentMode}-card border-${currentMode}-840 rounded-[8px] px-[15px] py-2 w-full `}
                                            />

                                            {errors && <p className="text-light-50 text-xs">{errors?.organisation}</p>}
                                        </div>

                                        <div className="basis-1/2">
                                            <span className="block text-sm pb-3">Organization Alias Name</span>
                                            <span
                                                className={`border-[1px] bg-${currentMode}-card pr-[40px] border-${currentMode}-840 borderLeft flex items-center rounded-[8px] pl-[15px] py-2 w-full`}
                                                onClick={() => setAddAlias(true)}
                                            >
                                                <Input
                                                    name="aliasname"
                                                    disabled="true"
                                                    value={aliasState}
                                                    title="Create Alias names"
                                                    placeholder="Click the icon to add an alias name"
                                                    className={`w-full bg-${currentMode}-card border-${currentMode}-840 truncate cursor-default`}
                                                />

                                                <span
                                                    className="cursor-pointer absolute right-[0px] pr-2 color-40"
                                                    onClick={() => setAddAlias(true)}
                                                    title="Create Alias Name"
                                                >
                                                    <img className="opacity-[.5]" src="/images/common/plus-circle.svg" alt="divide-circle" />
                                                </span>
                                            </span>
                                        </div>
                                    </div>
                                    <div className="flex space-x-7 viewStyles  e-disabled">
                                        <div className="basis-1/2">
                                            <span className="block text-sm pb-3">
                                                <span className="text-[red] mr-1">*</span>
                                                Register Number
                                            </span>
                                            <Input
                                                name="registration_no"
                                                tabIndex={0}
                                                onChange={handleChange}
                                                maxLength={26}
                                                value={values?.registration_no}
                                                placeholder="Enter the register number"
                                                className={`border-[1px] border-${currentMode}-840 bg-${currentMode}-card rounded-[8px] px-[15px] py-2 w-full`}
                                            />

                                            {errors && <p className="text-light-50 text-xs">{errors?.registration_no}</p>}
                                        </div>
                                        <div className="basis-1/2">
                                            <span className={`block text-sm pb-3`}>
                                                <span className="text-[red] mr-1">*</span>
                                                Organization Code
                                            </span>
                                            <input
                                                name="organisation_code"
                                                tabIndex={0}
                                                onChange={handleChange}
                                                value={values?.organisation_code}
                                                maxLength={26}
                                                placeholder="Enter your Organization Code"
                                                className={`border-[1px] bg-${currentMode}-card border-${currentMode}-840 rounded-[8px] px-[15px] py-2 w-full `}
                                            />

                                            {errors && <p className="text-light-50 text-xs">{errors?.organisation_code}</p>}
                                        </div>
                                    </div>
                                    <div className="flex space-x-7 viewStyles">
                                        <div className="basis-1/2">
                                            <span className="block text-sm pb-3 ">
                                                <span className="text-[red] mr-1">*</span>
                                                Address
                                            </span>
                                            <Input
                                                onChange={handleChange}
                                                placeholder="Enter the Address"
                                                value={values?.address_line1}
                                                maxLength={251}
                                                tabIndex={0}
                                                name="address_line1"
                                                className={`border-[1px] bg-${currentMode}-card border-${currentMode}-840 rounded-[8px] px-[15px] py-2 w-full`}
                                            />

                                            {errors && <p className="text-light-50 text-xs">{errors?.address_line1}</p>}
                                        </div>
                                        <div className="basis-1/2">
                                            <span className="block text-sm pb-3">
                                                <span className="text-[red] mr-1">*</span>
                                                Country/Region
                                            </span>

                                            {props?.actionType == 0 && (
                                                <div>
                                                    <Select
                                                        value={values?.country_id || "0"}
                                                        name="country_id"
                                                        fullWidth
                                                        onChange={handleChange}
                                                        MenuProps={{
                                                            sx: {
                                                                "& .MuiMenu-paper": {
                                                                    backgroundColor: dropdownColor,
                                                                    color: dropdownText,
                                                                },

                                                                "& .MuiMenuItem-root:hover": {
                                                                    backgroundColor: "rgba(218, 249, 244, 0.3) !important",
                                                                },

                                                                "& .Mui-selected": {
                                                                    backgroundColor: "rgba(218, 249, 244, 0.3) !important",
                                                                },
                                                            },
                                                        }}
                                                        IconComponent={() => (
                                                            <img
                                                                className="absolute right-[15px] opacity-[.5] pointer-events-none"
                                                                src={
                                                                    currentMode !== "dark"
                                                                        ? `/images/header/chevronDown.svg`
                                                                        : `/images/header/chevronDown-white.svg`
                                                                }
                                                                alt="chevronDown"
                                                            />
                                                        )}
                                                        SelectDisplayProps={{
                                                            style: {
                                                                color: dropdownText,
                                                            },
                                                        }}
                                                        className=""
                                                    >
                                                        <MenuItem key={0} name="country_id" value={0}>
                                                            {"Select"}
                                                        </MenuItem>

                                                        {country &&
                                                            country?.map((listelem, i) => {
                                                                return (
                                                                    <MenuItem key={i + 1} name="country_id" value={listelem?.country_id}>
                                                                        {listelem?.country_name}
                                                                    </MenuItem>
                                                                );
                                                            })}
                                                    </Select>

                                                    {errors && <p className="text-light-50 text-xs">{errors?.country_id}</p>}
                                                </div>
                                            )}

                                            {props?.actionType !== 0 && (
                                                <div>
                                                    <Select
                                                        value={countryValue || "0"}
                                                        name="countryValue"
                                                        onChange={(e) => handleCountryValueChange(e.target.value)}
                                                        MenuProps={{
                                                            sx: {
                                                                "& .MuiMenu-paper": {
                                                                    backgroundColor: dropdownColor,
                                                                    color: dropdownText,
                                                                },

                                                                "& .MuiMenuItem-root:hover": {
                                                                    backgroundColor: "rgba(218, 249, 244, 0.3) !important",
                                                                },

                                                                "& .Mui-selected": {
                                                                    backgroundColor: "rgba(218, 249, 244, 0.3) !important",
                                                                },
                                                            },
                                                        }}
                                                        IconComponent={() => (
                                                            <img
                                                                className="absolute right-[15px] opacity-[.5] pointer-events-none"
                                                                src={
                                                                    currentMode !== "dark"
                                                                        ? `/images/header/chevronDown.svg`
                                                                        : `/images/header/chevronDown-white.svg`
                                                                }
                                                                alt="chevronDown"
                                                            />
                                                        )}
                                                        SelectDisplayProps={{
                                                            style: {
                                                                color: dropdownText,
                                                            },
                                                        }}
                                                        className="w-full"
                                                    >
                                                        <MenuItem key={0} name="country_name" value={0}>
                                                            {"Select"}
                                                        </MenuItem>

                                                        {country &&
                                                            country?.map((listelem, i) => {
                                                                return (
                                                                    <MenuItem key={i + 1} name="country_name" value={listelem?.country_id}>
                                                                        {listelem?.country_name}
                                                                    </MenuItem>
                                                                );
                                                            })}
                                                    </Select>

                                                    {errormsgs && <p className="text-light-50 text-xs">Country is required</p>}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                className={`border-t-[1px] mx-[-30px] px-[30px] py-5 sticky bottom-0 bg-${currentMode}-card border-${currentMode}-590 mt-6`}
                            >
                                <div className="space-x-2 flex">
                                    {props?.actionType !== 0 ? (
                                        <Button
                                            onClick={(e) => _tabHandleChange(e, 1)}
                                            title="Next"
                                            type="button"
                                            tabIndex={0}
                                            className="bg-light-20 flex text-base font-interR py-[.5rem] px-[2.24rem] min-w-[103px] justify-center rounded-[8px] text-white"
                                        >
                                            {"Next"}
                                        </Button>
                                    ) : (
                                        <Button
                                            disabled={fetching || (requestComplete && profile?.status)}
                                            title="Next"
                                            type="button"
                                            onClick={(e) => _tabHandleChange(e, 1, "next")}
                                            tabIndex={0}
                                            className="bg-light-20 flex text-base font-interR py-[.5rem] min-w-[103px] justify-center px-[1rem] rounded-[8px] text-white"
                                        >
                                            {"Next"}
                                        </Button>
                                    )}
                                    <Button
                                        type="button"
                                        title="To Cancel"
                                        tabIndex={0}
                                        onClick={cartClose}
                                        className="bg-light-240 flex text-base font-interR py-[.5rem] px-[1rem] min-w-[103px] justify-center rounded-[8px] text-light-40 closeModalId"
                                    >
                                        Cancel
                                    </Button>
                                </div>
                            </div>
                        </CustomTabPanel>
                        <CustomTabPanel value={tabvalue} index={1}>
                            <div className="h-[calc(100vh_-_250px)] overflow-y-auto">
                                <div className="space-y-[25px]">
                                    <div className="flex viewStyles items-end space-x-6 ">
                                        <div className="basis-1/2">
                                            <span className="block text-sm pb-3">Fiscal period</span>

                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={fromMonth}
                                                disabled={viewData?.target_type == "SAP"}
                                                label="fy_start"
                                                fullWidth
                                                name="fy_start"
                                                onChange={fiscalFromOnchange}
                                                IconComponent={() => (
                                                    <img
                                                        className="absolute right-[15px] opacity-[.5] pointer-events-none"
                                                        src={
                                                            currentMode !== "dark"
                                                                ? `/images/header/chevronDown.svg`
                                                                : `/images/header/chevronDown-white.svg`
                                                        }
                                                        alt="chevronDown"
                                                    />
                                                )}
                                                MenuProps={{
                                                    sx: {
                                                        "& .MuiMenu-paper": {
                                                            backgroundColor: dropdownColor,
                                                            color: dropdownText,
                                                        },

                                                        "& .MuiMenuItem-root:hover": {
                                                            backgroundColor: "rgba(218, 249, 244, 0.3) !important",
                                                        },

                                                        "& .Mui-selected": {
                                                            backgroundColor: "rgba(218, 249, 244, 0.3) !important",
                                                        },
                                                    },
                                                }}
                                                SelectDisplayProps={{
                                                    style: {
                                                        color: dropdownText,
                                                    },
                                                }}
                                            >
                                                <MenuItem value="0" sx={{ display: "none" }}>
                                                    From
                                                </MenuItem>
                                                {months &&
                                                    months?.map((elem, ind) => {
                                                        return (
                                                            <MenuItem name="From" value={elem?.val}>
                                                                {elem?.monthName}
                                                            </MenuItem>
                                                        );
                                                    })}
                                            </Select>
                                        </div>
                                        <div className="basis-1/2">
                                            <Select
                                                fullWidth
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={toMonth}
                                                // tabIndex="1"
                                                IconComponent={() => (
                                                    <img
                                                        className="absolute right-[15px] opacity-[.5] pointer-events-none"
                                                        src={
                                                            currentMode !== "dark"
                                                                ? `/images/header/chevronDown.svg`
                                                                : `/images/header/chevronDown-white.svg`
                                                        }
                                                        alt="chevronDown"
                                                    />
                                                )}
                                                disabled={viewData?.target_type == "SAP"}
                                                label="fy_end"
                                                name="fy_end"
                                                onChange={fiscalToOnchange}
                                                MenuProps={{
                                                    sx: {
                                                        "& .MuiMenu-paper": {
                                                            backgroundColor: dropdownColor,
                                                            color: dropdownText,
                                                        },

                                                        "& .MuiMenuItem-root:hover": {
                                                            backgroundColor: "rgba(218, 249, 244, 0.3) !important",
                                                        },

                                                        "& .Mui-selected": {
                                                            backgroundColor: "rgba(218, 249, 244, 0.3) !important",
                                                        },
                                                    },
                                                }}
                                                SelectDisplayProps={{
                                                    style: {
                                                        color: dropdownText,
                                                    },
                                                }}
                                            >
                                                <MenuItem value="0" sx={{ display: "none" }}>
                                                    To
                                                </MenuItem>
                                                {months &&
                                                    months?.map((elem, ind) => {
                                                        return (
                                                            <MenuItem name="To" value={elem?.val}>
                                                                {elem?.monthName}
                                                            </MenuItem>
                                                        );
                                                    })}
                                            </Select>
                                        </div>
                                    </div>
                                    <div className="flex viewStyles space-x-[21px]">
                                        <div className="basis-1/2">
                                            <span className="block text-sm pb-3">Date Format</span>
                                            <Select
                                                value={dateValue}
                                                onChange={(e) => handleDateChange(e.target.value)}
                                                MenuProps={{
                                                    sx: {
                                                        "& .MuiMenu-paper": {
                                                            backgroundColor: dropdownColor,
                                                            color: dropdownText,
                                                        },

                                                        "& .MuiMenuItem-root:hover": {
                                                            backgroundColor: "rgba(218, 249, 244, 0.3) !important",
                                                        },

                                                        "& .Mui-selected": {
                                                            backgroundColor: "rgba(218, 249, 244, 0.3) !important",
                                                        },
                                                    },
                                                }}
                                                IconComponent={() => (
                                                    <img
                                                        className="absolute right-[15px] opacity-[.5] pointer-events-none"
                                                        src={
                                                            currentMode !== "dark"
                                                                ? `/images/header/chevronDown.svg`
                                                                : `/images/header/chevronDown-white.svg`
                                                        }
                                                        alt="chevronDown"
                                                    />
                                                )}
                                                SelectDisplayProps={{
                                                    style: {
                                                        color: dropdownText,
                                                    },
                                                }}
                                                defaultValue={"test"}
                                                displayEmpty
                                                className="w-full"
                                            >
                                                {filteredDateFormatList &&
                                                    filteredDateFormatList?.map((listelem, i) => {
                                                        return (
                                                            <MenuItem key={i} name="dateformat" value={listelem?.value}>
                                                                {listelem?.value}
                                                            </MenuItem>
                                                        );
                                                    })}
                                            </Select>
                                        </div>
                                        <div className="basis-1/2">
                                            <span className="block text-sm pb-3">Number Format</span>
                                            <Select
                                                value={numberValue}
                                                // tabIndex="1"
                                                onChange={(e) => handleNumberValueChange(e.target.value)}
                                                displayEmpty
                                                inputProps={{
                                                    "aria-label": "Without label",
                                                }}
                                                className="w-full"
                                                MenuProps={{
                                                    sx: {
                                                        "& .MuiMenu-paper": {
                                                            backgroundColor: dropdownColor,
                                                            color: dropdownText,
                                                        },

                                                        "& .MuiMenuItem-root:hover": {
                                                            backgroundColor: "rgba(218, 249, 244, 0.3) !important",
                                                        },

                                                        "& .Mui-selected": {
                                                            backgroundColor: "rgba(218, 249, 244, 0.3) !important",
                                                        },
                                                    },
                                                }}
                                                IconComponent={() => (
                                                    <img
                                                        className="absolute right-[15px] opacity-[.5] pointer-events-none"
                                                        src={
                                                            currentMode !== "dark"
                                                                ? `/images/header/chevronDown.svg`
                                                                : `/images/header/chevronDown-white.svg`
                                                        }
                                                        alt="chevronDown"
                                                    />
                                                )}
                                                SelectDisplayProps={{
                                                    style: {
                                                        color: dropdownText,
                                                    },
                                                }}
                                            >
                                                {filteredNumberFormatList &&
                                                    filteredNumberFormatList?.map((listelem, i) => {
                                                        return (
                                                            <MenuItem key={i} name="dateformat" value={listelem?.value}>
                                                                {listelem?.name}
                                                            </MenuItem>
                                                        );
                                                    })}
                                            </Select>
                                        </div>
                                    </div>
                                    <div className="flex viewStyles space-x-[21px]">
                                        <div className="basis-1/2">
                                            <span className="block text-sm pb-3 ">
                                                <span className="text-[red] mr-1.5">*</span>
                                                Currency
                                            </span>
                                            <Select
                                                fullWidth
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={values?.currency_id || 0}
                                                onChange={(e) => handleCurrencyId(e)}
                                                // tabIndex="1"
                                                IconComponent={() => (
                                                    <img
                                                        className="absolute right-[15px] opacity-[.5] pointer-events-none"
                                                        src={
                                                            currentMode !== "dark"
                                                                ? `/images/header/chevronDown.svg`
                                                                : `/images/header/chevronDown-white.svg`
                                                        }
                                                        alt="chevronDown"
                                                    />
                                                )}
                                                label="currency_id"
                                                name="currency_id"
                                                MenuProps={{
                                                    sx: {
                                                        "& .MuiMenu-paper": {
                                                            backgroundColor: dropdownColor,
                                                            color: dropdownText,
                                                        },

                                                        "& .MuiMenuItem-root:hover": {
                                                            backgroundColor: "rgba(218, 249, 244, 0.3) !important",
                                                        },

                                                        "& .Mui-selected": {
                                                            backgroundColor: "rgba(218, 249, 244, 0.3) !important",
                                                        },
                                                    },
                                                }}
                                                SelectDisplayProps={{
                                                    style: {
                                                        color: dropdownText,
                                                        paddingRight: "40px",
                                                    },
                                                }}
                                            >
                                                <MenuItem key={0} name="currency_id" value={0}>
                                                    {"Select"}
                                                </MenuItem>
                                                {filteredCurrencyData &&
                                                    filteredCurrencyData?.map((elem, ind) => {
                                                        return (
                                                            <MenuItem name="currency_id" value={elem?.currency_id}>
                                                                {elem?.currency}
                                                            </MenuItem>
                                                        );
                                                    })}
                                            </Select>
                                            {errors && <p className="text-light-50 text-xs">{errors?.currency_id}</p>}
                                        </div>
                                        <div className="basis-1/2">
                                            <span className="flex text-sm pb-3">
                                                Tolerance
                                                <Tooltip
                                                    title={
                                                        "Tolerance limit refers to a threshold or acceptable range for financial transactions and to control how much variance is allowed between the purchase order amount and the invoice amount."
                                                    }
                                                    disablePortal
                                                    placement="top"
                                                    disableInteractive
                                                    PopperProps={{
                                                        sx: {
                                                            "& .MuiTooltip-tooltip": {
                                                                fontSize: "0.9em",
                                                                maxHeight: "120px",
                                                                maxWidth: "250px",
                                                                background: "#DAF9F4",
                                                                color: "#555555",
                                                                borderRadius: "5px",
                                                                marginTop: "0 !important",
                                                            },

                                                            "& .MuiTooltip-arrow": {
                                                                color: "#DAF9F4",
                                                            },
                                                        },
                                                    }}
                                                    arrow
                                                >
                                                    <img className="ml-2" src="/images/main/noun-information.svg" alt="noun-information" />
                                                </Tooltip>
                                            </span>
                                            <div className="relative">
                                                <Input
                                                    type="number"
                                                    name="tolerance"
                                                    tabIndex={0}
                                                    placeholder="Enter a Tolerance"
                                                    onChange={(e) => {
                                                        handleChange((e = +e.target.value > 100 ? "100" : e));
                                                    }}
                                                    maxLength={3}
                                                    value={values?.tolerance}
                                                    className={`border-[1px] border-${currentMode}-840 bg-${currentMode}-card rounded-[8px] px-[15px] py-2 w-full`}
                                                />
                                                <span
                                                    className={`absolute right-[1px] border-l border-${currentMode}-1060 top-[50%] translate-y-[-50%] h-[38px] w-[40px] rounded-r-[8px] grid place-items-center place-content-center bg-${currentMode}-970`}
                                                >
                                                    <img src="/images/main/percentage.svg" alt="percentage" />
                                                </span>
                                            </div>
                                            {errors && <p className="text-light-50 text-xs">{errors?.tolerance}</p>}
                                        </div>
                                        {/* <div className="basis-1/3">
                                            <span className="flex text-sm pb-3">
                                                <span className="text-[red] mr-1.5">
                                                    *
                                                </span>
                                                Parking Required
                                                <Tooltip
                                                    title={
                                                        "The process of temporarily saving or holding a financial document or transaction in a parked status before it is posted to the accounting system"
                                                    }
                                                    disablePortal
                                                    placement="top"
                                                    disableInteractive
                                                    PopperProps={{
                                                        sx: {
                                                            "& .MuiTooltip-tooltip":
                                                                {
                                                                    fontSize:
                                                                        "0.9em",
                                                                    maxHeight:
                                                                        "100px",
                                                                    maxWidth:
                                                                        "250px",
                                                                    background:
                                                                        "#DAF9F4",
                                                                    color: "#555555",
                                                                    borderRadius:
                                                                        "5px",
                                                                    marginTop:
                                                                        "0 !important",
                                                                },
                                                            "& .MuiTooltip-arrow":
                                                                {
                                                                    color: "#DAF9F4",
                                                                },
                                                        },
                                                    }}
                                                    arrow
                                                >
                                                    <img
                                                        className="ml-2"
                                                        src="/images/main/noun-information.svg"
                                                        alt="noun-information"
                                                    />
                                                </Tooltip>
                                            </span>
                                            <Select
                                                value={values?.is_parking}
                                                onChange={(e) =>
                                                    handleIsParking(e)
                                                }
                                                displayEmpty
                                                name="is_parking"
                                                inputProps={{
                                                    "aria-label":
                                                        "Without label",
                                                }}
                                                className="w-full"
                                                MenuProps={{
                                                    sx: {
                                                        "& .MuiMenu-paper": {
                                                            backgroundColor:
                                                                dropdownColor,
                                                            color: dropdownText,
                                                        },

                                                        "& .MuiMenuItem-root:hover":
                                                            {
                                                                backgroundColor:
                                                                    "rgba(218, 249, 244, 0.3) !important",
                                                            },

                                                        "& .Mui-selected": {
                                                            backgroundColor:
                                                                "rgba(218, 249, 244, 0.3) !important",
                                                        },
                                                    },
                                                }}
                                                IconComponent={() => (
                                                    <img
                                                        className="absolute right-[15px] opacity-[.5] pointer-events-none"
                                                        src={
                                                            currentMode !==
                                                            "dark"
                                                                ? `/images/header/chevronDown.svg`
                                                                : `/images/header/chevronDown-white.svg`
                                                        }
                                                        alt="chevronDown"
                                                    />
                                                )}
                                                SelectDisplayProps={{
                                                    style: {
                                                        color: dropdownText,
                                                    },
                                                }}
                                            >
                                                <MenuItem value={null} selected>
                                                    Select
                                                </MenuItem>
                                                <MenuItem value={true}>
                                                    Yes
                                                </MenuItem>
                                                <MenuItem value={false}>
                                                    No
                                                </MenuItem>
                                            </Select>
                                            {errors && (
                                                <p className="text-light-50 text-xs">
                                                    {errors?.is_parking}
                                                </p>
                                            )}
                                        </div>

                                        <div className="basis-1/3 mb-1">
                                            <span className="flex text-sm pb-3">
                                                <span className="text-[red] mr-1.5">
                                                    *
                                                </span>
                                                3 Way Matching Required
                                                <Tooltip
                                                    title={
                                                        "It involves the comparison of three key documents or pieces of information (PO, GRN, Invoice) and to ensure accuracy and control in the procurement and accounts payable processes."
                                                    }
                                                    disablePortal
                                                    placement="top"
                                                    disableInteractive
                                                    PopperProps={{
                                                        sx: {
                                                            "& .MuiTooltip-tooltip":
                                                                {
                                                                    fontSize:
                                                                        "0.9em",
                                                                    maxHeight:
                                                                        "120px",
                                                                    maxWidth:
                                                                        "250px",
                                                                    background:
                                                                        "#DAF9F4",
                                                                    color: "#555555",
                                                                    borderRadius:
                                                                        "5px",
                                                                    marginTop:
                                                                        "0 !important",
                                                                },

                                                            "& .MuiTooltip-arrow":
                                                                {
                                                                    color: "#DAF9F4",
                                                                },
                                                        },
                                                    }}
                                                    arrow
                                                >
                                                    <img
                                                        className="ml-2"
                                                        src="/images/main/noun-information.svg"
                                                        alt="noun-information"
                                                    />
                                                </Tooltip>
                                            </span>
                                            <Select
                                                name="three_way_matching"
                                                value={
                                                    values?.three_way_matching
                                                }
                                                onChange={(e) =>
                                                    handleThreeWayMatching(e)
                                                }
                                                displayEmpty
                                                inputProps={{
                                                    "aria-label":
                                                        "Without label",
                                                }}
                                                className="w-full"
                                                MenuProps={{
                                                    sx: {
                                                        "& .MuiMenu-paper": {
                                                            backgroundColor:
                                                                dropdownColor,
                                                            color: dropdownText,
                                                        },

                                                        "& .MuiMenuItem-root:hover":
                                                            {
                                                                backgroundColor:
                                                                    "rgba(218, 249, 244, 0.3) !important",
                                                            },

                                                        "& .Mui-selected": {
                                                            backgroundColor:
                                                                "rgba(218, 249, 244, 0.3) !important",
                                                        },
                                                    },
                                                }}
                                                IconComponent={() => (
                                                    <img
                                                        className="absolute right-[15px] opacity-[.5] pointer-events-none"
                                                        src={
                                                            currentMode !==
                                                            "dark"
                                                                ? `/images/header/chevronDown.svg`
                                                                : `/images/header/chevronDown-white.svg`
                                                        }
                                                        alt="chevronDown"
                                                    />
                                                )}
                                                SelectDisplayProps={{
                                                    style: {
                                                        color: dropdownText,
                                                    },
                                                }}
                                            >
                                                {" "}
                                                <MenuItem value={null} selected>
                                                    Select
                                                </MenuItem>
                                                <MenuItem value={true}>
                                                    Yes
                                                </MenuItem>
                                                <MenuItem value={false}>
                                                    No
                                                </MenuItem>
                                            </Select>
                                            {errors && (
                                                <p className="text-light-50 text-xs">
                                                    {errors?.three_way_matching}
                                                </p>
                                            )}
                                        </div> */}
                                    </div>
                                    <div>
                                        <div className="flex mb-4 form-group items-center form-check">
                                            <Checkbox
                                                name="is_parking"
                                                size="small"
                                                tabIndex={0}
                                                className="mr-2"
                                                checked={values?.is_parking}
                                                onChange={(e) => checkChange(e)}
                                                sx={{
                                                    padding: 0,
                                                    color: "rgba(85,85,85,0.7)",
                                                    "&.Mui-checked": {
                                                        color: "#028C78",
                                                    },
                                                }}
                                            />
                                            Is Invoice Parking Required?
                                            <Tooltip
                                                title={
                                                    "Parking an invoice refers to the process of temporarily holding invoice data (for example, incomplete information or pending reviews) as a document in the ERP system before it is posted."
                                                }
                                                disablePortal
                                                placement="top"
                                                disableInteractive
                                                PopperProps={{
                                                    sx: {
                                                        "& .MuiTooltip-tooltip": {
                                                            fontSize: "0.9em",
                                                            maxHeight: "120px",
                                                            maxWidth: "300px",
                                                            background: "#DAF9F4",
                                                            color: "#555555",
                                                            borderRadius: "5px",
                                                            // marginTop: "0 !important",
                                                            paddingBottom: "10px",
                                                            paddingTop: "10px",
                                                            paddingLeft: "15px",
                                                        },

                                                        "& .MuiTooltip-arrow": {
                                                            color: "#DAF9F4",
                                                        },
                                                    },
                                                }}
                                                arrow
                                            >
                                                <img className="ml-2" src="/images/main/noun-information.svg" alt="noun-information" />
                                            </Tooltip>
                                        </div>
                                        <div className="flex mb-4 form-group items-center form-check">
                                            <Checkbox
                                                name="three_way_matching"
                                                size="small"
                                                tabIndex={0}
                                                className="mr-2"
                                                checked={values?.three_way_matching}
                                                onChange={(e) => checkChange(e)}
                                                sx={{
                                                    padding: 0,
                                                    color: "rgba(85,85,85,0.7)",
                                                    "&.Mui-checked": {
                                                        color: "#028C78",
                                                    },
                                                }}
                                            />
                                            Is 3-Way Matching Required?
                                            <Tooltip
                                                title={
                                                    "The 3-way matching involves comparing the purchase order, goods receipt, and supplier invoice to ensure that details such as quantities, prices, and terms match accurately."
                                                }
                                                disablePortal
                                                placement="top"
                                                disableInteractive
                                                PopperProps={{
                                                    sx: {
                                                        "& .MuiTooltip-tooltip": {
                                                            fontSize: "0.9em",
                                                            maxHeight: "120px",
                                                            maxWidth: "300px",
                                                            background: "#DAF9F4",
                                                            color: "#555555",
                                                            borderRadius: "5px",
                                                            marginTop: "0 !important",
                                                            paddingBottom: "10px",
                                                            paddingTop: "10px",
                                                            paddingLeft: "15px",
                                                        },

                                                        "& .MuiTooltip-arrow": {
                                                            color: "#DAF9F4",
                                                        },
                                                    },
                                                }}
                                                arrow
                                            >
                                                <img className="ml-2" src="/images/main/noun-information.svg" alt="noun-information" />
                                            </Tooltip>
                                        </div>
                                        <div className="flex mb-4 form-group items-center form-check">
                                            <Checkbox
                                                name="match_netamount"
                                                size="small"
                                                tabIndex={0}
                                                className="mr-2"
                                                checked={values?.match_netamount}
                                                onChange={(e) => checkChange(e)}
                                                sx={{
                                                    padding: 0,
                                                    color: "rgba(85,85,85,0.7)",
                                                    "&.Mui-checked": {
                                                        color: "#028C78",
                                                    },
                                                }}
                                            />
                                            Match Invoice Line Item without Tax = Invoice Net Amount
                                            <Tooltip
                                                title={
                                                    "Select this checkbox to match the invoice net amount with the total amount of line items, if the tax amount is zero at the line items level."
                                                }
                                                disablePortal
                                                placement="top"
                                                disableInteractive
                                                PopperProps={{
                                                    sx: {
                                                        "& .MuiTooltip-tooltip": {
                                                            fontSize: "0.9em",
                                                            maxHeight: "120px",
                                                            maxWidth: "300px",
                                                            background: "#DAF9F4",
                                                            color: "#555555",
                                                            borderRadius: "5px",
                                                            marginTop: "0 !important",
                                                            paddingBottom: "10px",
                                                            paddingTop: "10px",
                                                            paddingLeft: "15px",
                                                        },

                                                        "& .MuiTooltip-arrow": {
                                                            color: "#DAF9F4",
                                                        },
                                                    },
                                                }}
                                                arrow
                                            >
                                                <img className="ml-2" src="/images/main/noun-information.svg" alt="noun-information" />
                                            </Tooltip>
                                        </div>
                                        <div className="flex mb-4 form-group items-center form-check">
                                            <Checkbox
                                                name="retention_recovery"
                                                size="small"
                                                tabIndex={0}
                                                className="mr-2"
                                                checked={values?.retention_recovery}
                                                onChange={(e) => checkChange(e)}
                                                sx={{
                                                    padding: 0,
                                                    color: "rgba(85,85,85,0.7)",
                                                    "&.Mui-checked": {
                                                        color: "#028C78",
                                                    },
                                                }}
                                            />
                                            Is Invoice Retention Recovery Required?
                                            <Tooltip
                                                title={
                                                    "Recovering a portion of the retained invoice amount from vendors under specified terms and conditions."
                                                }
                                                disablePortal
                                                placement="top"
                                                disableInteractive
                                                PopperProps={{
                                                    sx: {
                                                        "& .MuiTooltip-tooltip": {
                                                            fontSize: "0.9em",
                                                            maxHeight: "120px",
                                                            maxWidth: "300px",
                                                            background: "#DAF9F4",
                                                            color: "#555555",
                                                            borderRadius: "5px",
                                                            marginTop: "0 !important",
                                                            paddingBottom: "10px",
                                                            paddingTop: "10px",
                                                            paddingLeft: "15px",
                                                        },

                                                        "& .MuiTooltip-arrow": {
                                                            color: "#DAF9F4",
                                                        },
                                                    },
                                                }}
                                                arrow
                                            >
                                                <img className="ml-2" src="/images/main/noun-information.svg" alt="noun-information" />
                                            </Tooltip>
                                        </div>
                                        <div className="flex mb-4 form-group items-center form-check">
                                            <Checkbox
                                                name="advance_recovery"
                                                size="small"
                                                tabIndex={0}
                                                className="mr-2"
                                                checked={values?.advance_recovery}
                                                onChange={(e) => checkChange(e)}
                                                sx={{
                                                    padding: 0,
                                                    color: "rgba(85,85,85,0.7)",
                                                    "&.Mui-checked": {
                                                        color: "#028C78",
                                                    },
                                                }}
                                            />
                                            Is Invoice Advance Recovery Required?
                                            <Tooltip
                                                title={
                                                    "Advance recovery refers to the process wherein an organization seeks to reclaim an advance payment previously made to a vendor."
                                                }
                                                disablePortal
                                                placement="top"
                                                disableInteractive
                                                PopperProps={{
                                                    sx: {
                                                        "& .MuiTooltip-tooltip": {
                                                            fontSize: "0.9em",
                                                            maxHeight: "120px",
                                                            maxWidth: "300px",
                                                            background: "#DAF9F4",
                                                            color: "#555555",
                                                            borderRadius: "5px",
                                                            marginTop: "0 !important",
                                                            paddingBottom: "10px",
                                                            paddingTop: "10px",
                                                            paddingLeft: "15px",
                                                        },

                                                        "& .MuiTooltip-arrow": {
                                                            color: "#DAF9F4",
                                                        },
                                                    },
                                                }}
                                                arrow
                                            >
                                                <img className="ml-2" src="/images/main/noun-information.svg" alt="noun-information" />
                                            </Tooltip>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                className={`border-t-[1px] mx-[-30px] px-[30px] py-5 sticky bottom-0 bg-${currentMode}-card border-${currentMode}-590 mt-6`}
                            >
                                <div className="space-x-2 flex">
                                    <Button
                                        type="button"
                                        title="Back"
                                        tabIndex={0}
                                        onClick={(e) => _tabHandleChange(e, 0)}
                                        className="bg-light-240 flex text-base font-interR py-[.5rem] min-w-[103px] px-[2rem] rounded-[8px] text-light-40 "
                                    >
                                        Back
                                    </Button>
                                    {props?.actionType !== 0 ? (
                                        <Button
                                            disabled={fetching || (requestComplete && data?.status)}
                                            type="submit"
                                            tabIndex={0}
                                            title="to Update"
                                            className="bg-light-20 flex text-base font-interR py-[.5rem] min-w-[103px] px-[2rem] justify-center rounded-[8px] text-white"
                                        >
                                            {"Update"}
                                        </Button>
                                    ) : (
                                        <Button
                                            disabled={fetching || (requestComplete && profile?.status)}
                                            type="submit"
                                            tabIndex={0}
                                            onClick={() => setCheckSubmit(Object.keys(errors).length > 0 ? false : true)}
                                            title="To Create the Organization"
                                            className="bg-light-20 flex text-base font-interR py-[.5rem] min-w-[103px] justify-center px-[1rem] rounded-[8px] text-white"
                                        >
                                            {"Create"}
                                        </Button>
                                    )}

                                    <Button
                                        type="button"
                                        title="To Cancel"
                                        tabIndex={0}
                                        onClick={cartClose}
                                        className="bg-light-240 flex text-base font-interR py-[.5rem] px-[1rem] justify-center min-w-[103px] rounded-[8px] text-light-40 closeModalId"
                                    >
                                        Cancel
                                    </Button>
                                </div>
                            </div>
                        </CustomTabPanel>
                    </form>
                </>
            )}

            {ReactDOM.createPortal(
                <AddAlias
                    aliasInfo={aliasInfo}
                    aliastrue={aliastrue}
                    setmatchOrgAlias={setmatchOrgAlias}
                    setDuplicateAlias={setDuplicateAlias}
                    newrow={newrow}
                    setEmptyAlias={setEmptyAlias}
                    setnewRow={setnewRow}
                    orgname={values?.organisation_name}
                    setnewaliasInfo={setnewaliasInfo}
                    setAliasState={setAliasState}
                    aliasState={aliasState}
                    setaliasInfo={setaliasInfo}
                    isOpened={addAlias}
                    handleClose={() => setAddAlias(false)}
                    className={addAlias && "Show"}
                />,

                document.getElementById("user-modal-root")
            )}
        </>
    );
};

export default AddOrganisationModal;
