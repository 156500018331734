import React, { useContext } from "react";

import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from "recharts";
import { ThemeContext } from "../../Hooks/Themes/Themeprovider";

export const Barchart = (props) => {
    const { currentMode } = useContext(ThemeContext);
    const textColor = currentMode === "dark" ? `#ECECEC` : `#000`;
    const truncateLabel = (label) => {
        if (label.length > 3) {
            return label.substring(0, 3) + "...";
        }
        return label;
    };
    const CustomizedAxisTick = ({ x, y, payload }) => {
        const maxLength = props?.data?.length > 5 ? 2 : 10; // Maximum length for label
        const text = payload.value.length > maxLength ? payload.value.substring(0, maxLength) + ".." : payload.value;

        return (
            <g transform={`translate(${x},${y})`}>
                <text
                    x={0}
                    y={0}
                    dy={16}
                    style={{ fontSize: "13px" }}
                    textAnchor="middle"
                    fill={textColor}
                    // transform="rotate(-45)"
                    className="xAxisLabel">
                    {text}
                </text>
            </g>
        );
    };
    return (
        <ResponsiveContainer width="100%" height={380}>
            <BarChart data={props.data} margin={props?.margin}>
                {/* <CartesianGrid strokeDasharray="0 0" /> */}
                <XAxis
                    // tick={{ fill: textColor }}
                    dataKey={props.xDataKey}
                    stroke={currentMode === "dark" ? "#dfe1e28f" : "#E3E3E3"}
                    // wordSpacing={10}
                    // minTickGap={-10}
                    // axisLine={false}
                    // dx={15}
                    // dy={20}
                    // angle={45}
                    // tick={{
                    //     fontSize: 10,
                    //     width: 80,
                    //     transform: "translate(-20, 0)",
                    // }}
                    //tickFormatter={<CustomizedAxisTick />}
                    tick={<CustomizedAxisTick />}
                    interval={0}
                />
                <YAxis tick={{ fill: textColor, fontSize: "13px" }} stroke={currentMode === "dark" ? "#dfe1e28f" : "#E3E3E3"} />
                <Tooltip cursor={{ fill: "transparent" }} />
                {/* <Legend iconType="circle" align="left" /> */}
                <Bar dataKey={props.yDataKey} barSize={30} fill={props.color} radius={[100, 100, 0, 0]} />
                {/* <Bar dataKey="Revenue" fill="#90BE6D" radius={[10, 10, 0, 0]} /> */}
            </BarChart>
        </ResponsiveContainer>
    );
};
