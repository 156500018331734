import React from "react";
import { Tabs, Tab } from "@mui/material";
import { ThemeContext } from "../../../Hooks/Themes/Themeprovider";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { useState, useContext } from "react";
import Modal from "../../../components/UI/Modal/Modal";
import Button from "../../../components/Button";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
const SceheduledOutputModal = (props) => {
    const dispatch = useDispatch();
    const { currentMode, toggleMode } = useContext(ThemeContext);
    function CustomTabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
                {value === index && (
                    <div className=" pb-0">
                        <div>{children}</div>
                    </div>
                )}
            </div>
        );
    }
    //tab panel
    CustomTabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.number.isRequired,
        value: PropTypes.number.isRequired,
    };

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            "aria-controls": `simple-tabpanel-${index}`,
        };
    }
    const [tabvalue, setTabValue] = useState(0);
    const _tabHandleChange = (event, newValue, next) => {
        setTabValue(newValue);
    };
    const handleSubmit = (type) => {
        dispatch({ type: "STORE_REPORT_DATA_CREATE", payload: type });
    };
    const { Reprt } = useSelector((state) => state?.storeReportState);

    return (
        <>
            <Modal cartClose={props?.handleClose} center="center" className={`${props?.className}`}>
                <div
                    className={`xl:w-[1094px] min-h-[676px] mx-auto relative rounded-[10px] ${
                        currentMode === "dark" ? "" : "shadow-[0px_4px_20px_rgba(238,238,238,0.5)] "
                    } bg-${currentMode}-card text-${currentMode}-copy border-${currentMode}-700`}>
                    <Tabs
                        value={tabvalue}
                        className="profileTab orgTab"
                        onChange={_tabHandleChange}
                        aria-label="basic tabs example"
                        TabIndicatorProps={{
                            sx: {
                                backgroundColor: "#00B399",
                                color: "#00B399",
                            },
                        }}>
                        <Tab
                            label={`${Reprt?.report_type == 1 ? "Custom Report" : "Scheduled Output"}`}
                            {...a11yProps(0)}
                            iconPosition="start"
                            icon={
                                tabvalue == 0 ? (
                                    <img className="convertWhite" src="/images/Output-manage/Scheduled-Output.svg" />
                                ) : (
                                    <img className="convertWhite" src="/images/Output-manage/Scheduled-Output-black.svg" />
                                )
                            }
                        />{" "}
                        <Tab label="" {...a11yProps(1)} iconPosition="start" className="pointer-events-none" />
                        <Button onClick={props?.handleClose} tabIndex="4" className="cursor-pointer absolute right-[20px] top-[20px] convertWhite">
                            <img src="/images/common/closeBlk.svg" alt="closeBlk" />
                        </Button>
                    </Tabs>
                    <div>
                        <CustomTabPanel value={tabvalue} index={0}>
                            <div className="w-[100%]  px-4 xl:px-[34px] py-[30px] xl:max-h-full max-h-[600px] overflow-auto" id="scroll-container">
                                <div id="section6" className="scroll-mt-[20px]">
                                    <span className="text-base font-interM block">Create Reports from Scratch</span>
                                    <p className="mb-[30px]">Create new reports for your business needs</p>
                                    <div className="grid grid-cols-2 xl:grid-cols-3 gap-y-[.5rem] mb-4 gap-x-5">
                                        <div
                                            className={`rounded-lg border border-solid border-${currentMode}-560 bg-${currentMode}-card shadow-xl text-center px-[21px] py-[28px] hover:bg-${currentMode}-960 `}>
                                            <Link to={`/reports/scheduled?type=${Reprt?.report_type || 1}&prev=${1}`} onClick={() => handleSubmit(1)}>
                                                <div className="mr-3 mx-auto mb-[15px]">
                                                    <img
                                                        src="/images/Output-manage/tabular-reports.svg"
                                                        className="convertCheckWhite mx-auto"
                                                        alt="tab-report"
                                                    />
                                                </div>
                                                <div className="">
                                                    <span className="font-interSb block mb-[10px]">Tabular Reports</span>
                                                    <p>
                                                        Tabular reports are a common type of structured report used to present data in a table format.
                                                    </p>
                                                </div>
                                            </Link>
                                        </div>
                                        <div
                                            className={`rounded-lg border border-solid border-${currentMode}-560 bg-${currentMode}-card shadow-xl text-center px-[21px] py-[28px] hover:bg-${currentMode}-960 `}>
                                            <Link to={`/reports/scheduled?type=${Reprt?.report_type || 1}&prev=${2}`} onClick={() => handleSubmit(2)}>
                                                <div className="mr-3 mx-auto mb-[15px]">
                                                    <img
                                                        src="/images/Output-manage/matrix-report.svg"
                                                        className="convertCheckWhite mx-auto"
                                                        alt="matrix"
                                                    />
                                                </div>
                                                <div className="">
                                                    <span className="font-interSb block mb-[10px]">Matrix Reports</span>
                                                    <p>
                                                        Matrix reports are a type of structured report used to display data in a two-dimensional grid
                                                        format.
                                                    </p>
                                                </div>
                                            </Link>
                                        </div>
                                        <div
                                            className={`rounded-lg border border-solid border-${currentMode}-560 bg-${currentMode}-card shadow-xl text-center px-[21px] py-[28px] hover:bg-${currentMode}-960 `}>
                                            <Link to={`/reports/scheduled?type=${Reprt?.report_type || 1}&prev=${3}`} onClick={() => handleSubmit(3)}>
                                                <div className="mr-3 mx-auto mb-[15px]">
                                                    <img
                                                        src="/images/Output-manage/chart-report.svg"
                                                        className="convertCheckWhite mx-auto"
                                                        alt="chart"
                                                    />
                                                </div>
                                                <div className="">
                                                    <span className="font-interSb block mb-[10px]">Charts</span>
                                                    <p>Charts are visual representations of data that make complex information understandable.</p>
                                                </div>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                {Reprt?.report_type == 2 && (
                                    <>
                                        <hr className={`mb-[30px] mt-[30px] border-${currentMode}-1240`}></hr>
                                        <div id="section1" className="scroll-mt-[20px]">
                                            <span className=" text-base font-interM mb-[30px] block">
                                                Create Reports exclusively in JSON and XML formats{" "}
                                            </span>

                                            <div className="grid grid-cols-2 xl:grid-cols-3 gap-y-[3rem] gap-x-5">
                                                <div
                                                    className={`rounded-lg border border-solid border-${currentMode}-560 bg-${currentMode}-card shadow-xl text-center px-[19px] py-[30px] hover:bg-${currentMode}-960  `}>
                                                    <Link
                                                        to={`/reports/scheduled?type=${Reprt?.report_type || 1}&prev=${4}`}
                                                        onClick={() => handleSubmit(4)}>
                                                        <div className="mx-auto mb-4">
                                                            <img
                                                                src="/images/Output-manage/json-new.svg"
                                                                className="convertCheckWhite mx-auto"
                                                                alt="chart"
                                                            />
                                                        </div>
                                                        <div className="">
                                                            <span className="font-interSb block mb-[10px]">JSON</span>
                                                            <p>
                                                                Design and download your custom reports in JSON format for easier to read and write,
                                                                to exchange data across applications.
                                                            </p>
                                                        </div>
                                                    </Link>
                                                </div>

                                                {/* <div
                                                    className={`rounded-lg border border-solid border-${currentMode}-560 bg-${currentMode}-card shadow-xl text-center px-[21px] py-[30px] hover:bg-${currentMode}-960  `}>
                                                    <Link
                                                        to={`/reports/scheduled?type=${Reprt?.report_type || 1}&prev=${5}`}
                                                        onClick={() => handleSubmit(5)}>
                                                        <div className="mx-auto mb-4">
                                                            <img
                                                                src="/images/Output-manage/xml-new.svg"
                                                                className="convertCheckWhite mx-auto"
                                                                alt="chart"
                                                            />
                                                        </div>
                                                        <div className="">
                                                            <span className="font-interSb block mb-[10px]">XML</span>
                                                            <p>
                                                                Create and download your custom business reports in XML format to exchange data across
                                                                platforms.
                                                            </p>
                                                        </div>
                                                    </Link>
                                                </div> */}
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div>
                        </CustomTabPanel>

                        <CustomTabPanel value={tabvalue} index={1}>
                            <div className="px-[32px] py-[82px] overflow-auto" id="scroll-container">
                                <div id="section6" className="scroll-mt-[20px]">
                                    <span className="text-light-10 text-base font-interSb mb-3 block">Organization Management</span>

                                    <div className="grid grid-cols-1 xl:grid-cols-2 gap-y-[3rem] mb-5 gap-x-5">
                                        <Link
                                            to="/organisations"
                                            className={`flex max-w-[26rem] p-4 rounded-[8px] transition duration-300 ease-in-out hover:bg-${currentMode}-1010 hover:border-[#333333] hover:shadow-[0px_4px_20px_rgba(85,85,85,0.2)]`}>
                                            <div className="mr-3">
                                                <img src="/images/common/organisation-new.svg" className="convertWhite" alt="org-ico" />
                                            </div>
                                            <div className="flex-1">
                                                <span className="font-interSb block mb-2">Organization</span>
                                                <p>Centrally manage all our Organizations setup.</p>
                                            </div>
                                        </Link>
                                    </div>
                                </div>

                                <div id="section1" className="scroll-mt-[20px]">
                                    <span className="text-light-10 text-base font-interSb mb-3 block">Users Management</span>

                                    <div className="grid grid-cols-1 xl:grid-cols-2 gap-y-[3rem] mb-5 gap-x-5">
                                        <Link
                                            to="/users"
                                            className={`flex max-w-[26rem] p-4 rounded-[8px] transition duration-300 ease-in-out hover:bg-${currentMode}-1010 hover:border-[#333333] hover:shadow-[0px_4px_20px_rgba(85,85,85,0.2)]`}>
                                            <div className="mr-3">
                                                <img src="/images/settings/user-blk.svg" className="convertWhite" alt="users-ico" />
                                            </div>
                                            <div className="flex-1">
                                                <span className="font-interSb block mb-2">Users</span>
                                                <p>Create or Invite users to manage their profiles and access.</p>
                                            </div>
                                        </Link>{" "}
                                        <Link
                                            to="/role-master"
                                            className={`flex max-w-[26rem] p-4 rounded-[8px] transition duration-300 ease-in-out hover:bg-${currentMode}-1010 hover:border-[#333333] hover:shadow-[0px_4px_20px_rgba(85,85,85,0.2)]`}>
                                            <div className="mr-3">
                                                <img src="/images/common/user-permissions.svg" className="convertWhite" alt="users-ico" />
                                            </div>
                                            <div className="flex-1">
                                                <span className="font-interSb block mb-2">User Roles & Permissions</span>
                                                <p>
                                                    Empower your team with pre-defined roles or tailored user roles and permissions. Fine-tune access
                                                    control to enhance security and productivity effortlessly.
                                                </p>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </CustomTabPanel>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default SceheduledOutputModal;
