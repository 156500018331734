import Modal from "../../../components/UI/Modal/Modal";
import Stepper from "../../Rules/Stepper";
import { useContext } from "react";
import { useSelector } from "react-redux";
import Loader from "../../../components/Loader";
import { ThemeContext } from "../../../Hooks/Themes/Themeprovider";
import { Button } from "@mui/material";

const RoleDetails = (props) => {
    const { currentMode, toggleMode } = useContext(ThemeContext);
    const RoleViewList = useSelector((state) => state?.ViewRolePermissionStateAction);
    const { data: RoleView } = RoleViewList;
    return (
        <>
            <Modal cartClose={props?.handleClose} center="center" className={`${props?.className}`}>
                {RoleViewList?.fetching ? (
                    <Loader className="" />
                ) : (
                    <div
                        className={`p-[20px] max-w-[750px] mx-auto text-center relative bg-${currentMode}-card text-${currentMode}-copy border-${currentMode}-700 border-[rgba(223,225,226,0.56)] rounded-[10px] ${
                            currentMode === "dark" ? "" : "shadow-[0px_4px_20px_rgba(238,238,238,0.5)]"
                        }`}
                    >
                        <span onClick={props?.handleClose} className="cursor-pointer w-full mb-5 block text-end">
                            <img className="ml-auto" src="/images/common/closeBlk.svg" alt="closeBlk" />
                        </span>
                        {RoleView?.role_name == null && RoleView?.role_description == null ? (
                            <h4 className="font-interSb mb-3">No Data Found</h4>
                        ) : (
                            <div className="grid place-items-center place-content-center">
                                <div className="flex items-center space-x-3 mb-3">
                                    <p>Role Name:</p>
                                    <h4 className="font-interSb">{RoleView?.role_name}</h4>
                                </div>
                                <div className="flex items-center space-x-3 mb-3">
                                    <p>Description:</p>
                                    <p>{RoleView?.role_description}</p>
                                </div>
                                {/* <>
                            {DataItems !== null
                                ? DataItems?.map((ele, index) => {
                                    return (
                                        <div className="grid place-content-center">
                                            <Stepper no={index + 1}>
                                                <div
                                                    className={`bg-white border-[1px] border-[rgba(223,225,226,0.56)] rounded-[8px] shadow-[0px_4px_20px_rgba(85,85,85,0.2)] py-[16px] px-[15px]`}
                                                >
                                                    {ele?.field_name}{" "}
                                                    <span className="text-light-10">
                                                        {ele?.operators}
                                                    </span>{" "}
                                                    {ele?.value}
                                                </div>
                                            </Stepper>
                                        </div>
                                    );
                                })
                                : "NO Data Found"}
                        </> */}
                            </div>
                        )}
                    </div>
                )}
            </Modal>
        </>
    );
};

export default RoleDetails;
