import axios from "axios";
import configMyiQ from "../../configuration";
import authHeader from "./auth-header";

import {
    ACTIVATE_DEACTIVATE_EMAIL_CONFIG_FAILURE,
    ACTIVATE_DEACTIVATE_EMAIL_CONFIG_REQUEST,
    ACTIVATE_DEACTIVATE_EMAIL_CONFIG_SUCCESS,
    ADD_EMAIL_CONFIG_FAILURE,
    ADD_EMAIL_CONFIG_REQUEST,
    ADD_EMAIL_CONFIG_SUCCESS,
    GET_EMAIL_CONFIG_FAILURE,
    GET_EMAIL_CONFIG_REQUEST,
    GET_EMAIL_CONFIG_SUCCESS,
    UPDATE_EMAIL_CONFIG_FAILURE,
    UPDATE_EMAIL_CONFIG_REQUEST,
    UPDATE_EMAIL_CONFIG_SUCCESS,
} from "../types/types";
import { checkApiStatus } from "../../utils/helpers";
//emaillist
const API_URL = process.env?.REACT_APP_API_URL;
export const getEmailConfig = (organisation, type) => async (dispatch) => {
    try {
        dispatch({ type: GET_EMAIL_CONFIG_REQUEST });
        axios
            .get(
                `${API_URL}Emailconfig/GetEmailconfigByOrg?organisation=${organisation}&invoice_type=${type}`,
                {
                    headers: authHeader(),
                }
            )
            .then((response) => {
                dispatch({
                    type: GET_EMAIL_CONFIG_SUCCESS,
                    payload: response?.data?.datalist,
                });
            });
    } catch (error) {
        if (error?.response?.status == 401) {
            checkApiStatus();
        }
        dispatch({
            type: GET_EMAIL_CONFIG_FAILURE,
        });
    }
};

//add email
export const emailconfigAdd = (data) => async (dispatch) => {
    try {
        dispatch({ type: ADD_EMAIL_CONFIG_REQUEST });

        axios
            .post(`${API_URL}Emailconfig/AddUpdateEmailconfig`, data, {
                headers: authHeader(),
            })
            .then((response) => {
                dispatch({
                    type: ADD_EMAIL_CONFIG_SUCCESS,
                    payload: response.data,
                });
            });
    } catch (error) {
        if (error?.response?.status == 401) {
            checkApiStatus();
        }
        dispatch({
            type: ADD_EMAIL_CONFIG_FAILURE,
        });
    }
};

//active-inactive email config
export const emailConfigStatusMode = (data) => async (dispatch) => {
    try {
        dispatch({ type: ACTIVATE_DEACTIVATE_EMAIL_CONFIG_REQUEST });

        axios
            .get(
                `${API_URL}Emailconfig/ActiveDeactivateEmailconfig?id=${data?.id}&isactive=${data?.isactive}`,
                {
                    headers: authHeader(),
                }
            )
            .then((response) => {
                dispatch({
                    type: ACTIVATE_DEACTIVATE_EMAIL_CONFIG_SUCCESS,
                    payload: response.data,
                });
            });
    } catch (error) {
        if (error?.response?.status == 401) {
            checkApiStatus();
        }
        dispatch({
            type: ACTIVATE_DEACTIVATE_EMAIL_CONFIG_FAILURE,
        });
    }
};
//update email
export const emailconfigUpdate = (data) => async (dispatch) => {
    try {
        dispatch({ type: UPDATE_EMAIL_CONFIG_REQUEST });

        axios
            .post(`${API_URL}Emailconfig/UpdateEmailconfig`, data, {
                headers: authHeader(),
            })
            .then((response) => {
                dispatch({
                    type: UPDATE_EMAIL_CONFIG_SUCCESS,
                    payload: response.data,
                });
            });
    } catch (error) {
        if (error?.response?.status == 401) {
            checkApiStatus();
        }
        dispatch({
            type: UPDATE_EMAIL_CONFIG_FAILURE,
        });
    }
};
