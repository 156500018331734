import {
    UPLOAD_INVOICE_FAILURE,
     UPLOAD_INVOICE_REQUEST,
      UPLOAD_INVOICE_RESET, 
      UPLOAD_INVOICE_SUCCESS 
    } from "../types/types";

    //INVOICE UPLOAD
const invoiceAuthUploadState = {
    file: [],
    fetching: false,
    requestComplete: false,
    error: "",
};

export function uploadInvoice(state = invoiceAuthUploadState, action) {
	switch (action.type) {
		case UPLOAD_INVOICE_REQUEST:
			return {
				...state,
				fetching: true,
				requestComplete: false,
				error: "",
			};
		case UPLOAD_INVOICE_SUCCESS:
			return {
				file: action.payload,
				fetching: false,
				requestComplete: true,
				error: "",
			};
		case UPLOAD_INVOICE_RESET:
			return {
				file: {},
				fetching: false,
				requestComplete: false,
				error: "",
			};
		case UPLOAD_INVOICE_FAILURE:
			return {
				fetching: false,
				requestComplete: false,
				error: action.payload,
			};
		default:
			return state;
	}
}

