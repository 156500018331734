import {
    ADD_SERVER_CONFIG_FAILURE,
    ADD_SERVER_CONFIG_REQUEST,
    ADD_SERVER_CONFIG_SUCCESS,
    SERVER_TARGET_TYPE_FAILURE,
    SERVER_TARGET_TYPE_REQUEST,
    SERVER_TARGET_TYPE_SUCCESS,
    TEST_SERVER_CONFIG_FAILURE,
    TEST_SERVER_CONFIG_REQUEST,
    TEST_SERVER_CONFIG_SUCCESS,
    VIEW_SERVER_CONFIG_FAILURE,
    VIEW_SERVER_CONFIG_REQUEST,
    VIEW_SERVER_CONFIG_SUCCESS,
} from "../types/types";
import configMyiQ from "../../configuration";
import authHeader from "../actions/auth-header";
import axios from "axios";
import { checkApiStatus } from "../../utils/helpers";

const Token = localStorage.getItem("token");
const API_URL = process.env?.REACT_APP_API_URL;
const API = process.env?.REACT_APP_NODE_API_URL;

//add server
export const serverconfigAdd = (data) => async (dispatch) => {
    try {
        dispatch({ type: ADD_SERVER_CONFIG_REQUEST });

        axios
            .post(`${API}api/addUpdateServerConfig`, data, {
                headers: authHeader(),
            })
            .then((response) => {
                dispatch({
                    type: ADD_SERVER_CONFIG_SUCCESS,
                    payload: response.data,
                });
            });
    } catch (error) {
        if (error?.response?.status == 401) {
            checkApiStatus();
        }
        dispatch({
            type: ADD_SERVER_CONFIG_FAILURE,
        });
    }
};

export const serverconfigView = (organisation) => async (dispatch) => {
    try {
        dispatch({ type: VIEW_SERVER_CONFIG_REQUEST });
        axios
            .get(`${API}api/getServerConfig?organisation=${organisation?.id}`, {
                headers: authHeader(),
            })
            .then((response) => {
                dispatch({
                    type: VIEW_SERVER_CONFIG_SUCCESS,
                    payload: response?.data?.datalist,
                });
            });
    } catch (error) {
        if (error?.response?.status == 401) {
            checkApiStatus();
        }
        dispatch({
            type: VIEW_SERVER_CONFIG_FAILURE,
        });
    }
};

//target type
export const serverConfigTargetType = (data) => async (dispatch) => {
    try {
        dispatch({ type: SERVER_TARGET_TYPE_REQUEST });

        axios
            .post(`${API_URL}ServerConfig/SetTargetType`, data, {
                headers: authHeader(),
            })
            .then((response) => {
                dispatch({
                    type: SERVER_TARGET_TYPE_SUCCESS,
                    payload: response.data,
                });
            });
    } catch (error) {
        if (error?.response?.status == 401) {
            checkApiStatus();
        }
        dispatch({
            type: SERVER_TARGET_TYPE_FAILURE,
        });
    }
};
//server-test

export const serverConfigTest = (data) => async (dispatch) => {
    try {
        dispatch({ type: TEST_SERVER_CONFIG_REQUEST });

        axios
            .post(`${API}api/erpAuthenticate`, data, {
                headers: authHeader(),
            })
            .then((response) => {
                dispatch({
                    type: TEST_SERVER_CONFIG_SUCCESS,
                    payload: response.data,
                });
            })
            .catch((ex) => {
                if (ex?.response?.status == 401) {
                    checkApiStatus();
                }
                dispatch({
                    type: TEST_SERVER_CONFIG_FAILURE,
                    payload: ex,
                });
            });
    } catch (error) {
        if (error?.response?.status == 401) {
            checkApiStatus();
        }
        dispatch({
            type: TEST_SERVER_CONFIG_FAILURE,
        });
    }
};
