import { FormControl, MenuItem, Select, TextField } from "@mui/material";
import { useState, useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { DashboardVendors } from "../../redux/actions/dashboard";
import Loader from "../../components/Loader";
import { Link } from "react-router-dom";
import Button from "../../components/Button";
import Card from "../../components/UI/Card";
import moment from "moment/moment";
import { Barchart } from "../../organisms/BarChart";
import Filter from "./Filter";
import { Bar, BarChart, CartesianGrid, Legend, Tooltip, XAxis, YAxis } from "recharts";
import { getCurrentUser } from "../../redux/actions/auth";
import { ThemeContext } from "../../Hooks/Themes/Themeprovider";
import { ThisWeek, ThisYear } from "../../utils/helpers";

const Orgname = localStorage.getItem("Orgdemo");

const VendorInvoiceList = (props) => {
    const userId = props?.userId;

    const [age, setAge] = useState("");

    const dispatch = useDispatch();

    const location = useLocation();

    const [dates, setDates] = useState();

    let organisationId = 0;

    const handleChange = (event) => {
        setAge(event.target.value);
    };

    const CurrentUser = getCurrentUser();

    const letuserId = CurrentUser?.role_id == 1 ? CurrentUser?.id : userId;

    const getDataItems = (type, year) => {
        organisationId = localStorage.getItem("orgId") || 0;

        dispatch(DashboardVendors(type, organisationId, year, letuserId));
    };

    const { data, error, fetching, requestComplete } = useSelector((state) => state?.DashboardvendorState);

    const listData = data?.datalist;
    const scaledData = listData?.map((item) => ({ vendor: item?.vendor, Value: item.Value / 10 }));

    const { currentMode } = useContext(ThemeContext);

    useEffect(() => {
        organisationId = localStorage.getItem("orgId") || 0;

        // if (Orgname !== "undefined" && Orgname !== undefined) {
        setTimeout(() => {
            dispatch(
                DashboardVendors(
                    Orgname == "true" ? 3 : 1,

                    organisationId,

                    Orgname == "true" ? ThisYear : ThisWeek,

                    letuserId
                )
            );
        }, 500);
    }, [dispatch, location.pathname, Orgname]);
    if (listData) {
        listData?.forEach((item) => {
            item.Value = parseInt(item?.Value);
        });
    }

    return (
        // <div className="basis-4/6 overflow-hidden">
        <Card
            // className={`mb-[20px] ${!requestComplete && "h-[29rem]"} ${data?.datalist?.length == 0 && "h-[31.5rem]"
            //     }`}
            className="h-full"
        >
            <div className={`flex justify-between px-[20px] py-[24px] mb-6 border-b-[1px] border-${currentMode}-700 items-center`}>
                <h4 className="font-interM text-lg">Top 10 Vendors by Invoice Value</h4>

                <Filter getDataItems={getDataItems} />
            </div>

            <div className="px-[30px] py-5 home">
                {!requestComplete ? (
                    <Loader className={`w-full flex justify-center min-h-[22rem] items-center`} />
                ) : data?.datalist?.length !== 0 ? (
                    <Barchart
                        margin={{
                            top: 0,

                            bottom: 0,
                        }}
                        data={listData}
                        width={1250}
                        // height={300}
                        color={"#88AFEE"}
                        xDataKey={"vendor"}
                        yDataKey={"Value"}
                    >
                        <CartesianGrid strokeDasharray="3 3" />

                        <XAxis
                            xDataKey={"vendor"}
                            // minTickGap={-200}

                            // axisLine={false}

                            // dx={15}

                            // dy={20}

                            // angle={45}

                            interval="preserveStartEnd"
                            angle={95}
                            textAnchor={"end"}
                            axisLine={false}
                            offset={5}
                            tickMargin={10}
                            style={{
                                textAlign: "center",
                            }}
                        />

                        <YAxis yDataKey={"Value"} domain={[0, "dataMax"]} />

                        <Tooltip cursor={false} />

                        <Legend />

                        <Bar dataKey="Value" barSize={20} fill="#88AFEE" />
                    </Barchart>
                ) : (
                    <div className="px-[30px] h-[20rem] grid place-items-center place-content-center">
                        {" "}
                        <img className="mb-3" src="/images/common/delivery.png" alt="searching" />
                        <h4 className="font-interSb block">No Data found</h4>
                    </div>
                )}
            </div>
        </Card>
        // </div>
    );
};

export default VendorInvoiceList;
