import { Link } from "react-router-dom";
import Button from "../../components/Button";
import Card from "../../components/UI/Card";
import Main from "../../components/UI/Main";
import Footer from "../../organisms/Footer";
import Header from "../../organisms/Header";
import Sidebar from "../../organisms/Sidebar";

const FournotFour = () => {
    return (
        <>
            <Header />
            <Sidebar />
            <Main>
                <Card className="h-[calc(100vh_-_20vh)] grid place-items-center place-content-center">
                    <div className="flex items-center min-w-[1100px] mx-auto">
                        <div className="basis-1/2">
                            <h4 className="text-[32px] leading-[38px] mb-2">Something went</h4>
                            <b className="block font-interB mb-4 text-[32px] leading-[38px]">WRONG!</b>

                            <p className="text-[20px] leading-[38px] mb-2">Please contact to support team</p>
                            {/* <Link to="/dashboard">
                <Button className="text-light-20 font-interSb px-6 rounded-[8px] py-[8px] border-[1px] border-20">
                  Back to Home
                </Button>
              </Link> */}
                        </div>
                        <div className="basis-1/2">
                            <img src="/images/common/404.png" alt="404" />
                        </div>
                    </div>
                </Card>
            </Main>
            <Footer />
        </>
    );
};

export default FournotFour;
