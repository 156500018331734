import Card from "../../../components/UI/Card";
import { Tooltip, Checkbox } from "@mui/material";
import { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
const PermissionsModal = (props) => {
	const {
		currentMode,
		selectedId,
		setSelectedId,
		check,
		setCheck,
		workflowData,
		updatedPermissionData,
		setUpdatedPermissionData,
		cloneData,
		setCloneData,
	} = props;
	const masterRefId = check?.[selectedId]?.master_refid;
	const [permissionData, setPermissionData] = useState();
	const permission = check?.[selectedId]?.permission;
	//set data for permission by selected status
	useEffect(() => {
		if (selectedId !== null) {
			check.filter((ele, i) => {
				if (i == selectedId) {
					setPermissionData(ele?.permission);
					setCheckBoxClick((prevCheck) => {
						const updateCheckBox = prevCheck.map((item, i) => {
							return {
								...item,
								isChecked: item?.label_name == "matching_flag" ? ele?.permission?.matching_flag : ele?.permission?.parking_flag,
							};
						});
						return updateCheckBox;
					});
				}
			});
		}
	}, [selectedId]);
	//check box  for 3 way matching and parking
	const [checkBoxClick, setCheckBoxClick] = useState([
		{
			label_name: "matching_flag",
			displayName: `${workflowData?.org_3way ? "2-Way and 3-Way Matching" : "2-Way Matching"}`,
			isChecked: permission?.matching_flag,
			tooltip: "Select this checkbox to perform the mandatory 2 & 3 way matching validation process in the Invoice.",
			disable: false,
		},
		{
			label_name: "parking_flag",
			displayName: "Parking Invoice",
			isChecked: permission?.parking_flag,
			tooltip: "Select this checkbox to park the uploaded invoice after completing the GRN, PO released process.",
			disable: !workflowData?.org_parking,
		},
	]);
	//* Accordian Variables
	const [expanded, setExpanded] = useState(1);
	//t expand the accordion based on id
	const handleChange = (panel) => (event, newExpanded) => {
		setExpanded(newExpanded ? panel : false);
	};
	const Accordion = styled((props) => (
		<MuiAccordion
			disableGutters
			elevation={0}
			square
			{...props}
		/>
	))(({ theme }) => ({
		border: `0`,
		"&:not(:last-child)": {
			borderBottom: 0,
		},
		"&:before": {
			display: "none",
		},
	}));
	const filtedArrByType = permissionData?.field_list?.filter((el) => el?.label_type == 1 && el?.inv_display);
	const [staticTableData, setStaticTableData] = useState(filtedArrByType);
	useEffect(() => {
		if (expanded) {
			const filtedArrByType = permissionData?.field_list?.filter((el) => el?.label_type == expanded && el?.inv_display);
			setStaticTableData(filtedArrByType);
		}
	}, [expanded, permissionData?.field_list]);
	const AccordionSummary = styled((props) => (
		<MuiAccordionSummary
			expandIcon={
				<img
					src={`${
						props?.unique
							? `${currentMode == "dark" ? "/images/header/chevronDown-white.svg" : "/images/invoice/chevron-down.svg"}`
							: `/images/common/accordion-active.svg`
					}`}
					alt="accordion-active"
				/>
			}
			{...props}
		/>
	))((data) => ({
		padding: "11px 30px",
		margin: 0,
		flexDirection: "row-reverse",
		"& .MuiAccordionSummary-expandIconWrapper": {
			marginRight: 10,
			transform: data?.unique ? "rotate(180deg)" : "rotate(270deg)",
			filter: `${currentMode === "dark" ? "filter: brightness(0) invert(1)" : "brightness(0) invert(0)"}`,
		},
		"& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
			transform: "rotate(360deg)",
			filter: "none",
		},
		"& .MuiAccordionSummary-content": {
			margin: 0,
		},
	}));
	const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
		padding: 0,
		border: 0,
	}));
	//onchange function for the 3 way matching & parking
	const handleCheckPermission = (e, label_name) => {
		setCheck((prevCheck) => {
			// Assuming selectedId is the index where you want to update the user_set property
			const updatedCheck = prevCheck.map((ele, i) => {
				if (i === selectedId) {
					return {
						...ele,
						permission: {
							matching_flag: label_name == "matching_flag" ? e.target.checked : ele.permission.matching_flag,
							parking_flag: label_name == "parking_flag" ? e.target.checked : ele.permission.parking_flag,
							field_list: ele?.permission?.field_list,
						},
					};
				}
				return ele;
			});
			const updatedObj = updatedCheck?.find((ele, i) => i === selectedId);
			setPermissionData(updatedObj?.permission);
			return updatedCheck;
		});
		setCheckBoxClick((prevCheck) => {
			const updateCheckBox = prevCheck.map((item, i) => {
				if (item?.label_name == label_name) {
					return {
						...item,
						isChecked: e.target.checked,
					};
				}
				return item;
			});
			setCheckBoxClick(updateCheckBox);
		});
	};
	//onchange function for the view and edit for permission module
	const handleSetFieldPermission = (e, id, type) => {
		setCheck((prevCheck) => {
			const updatedCheck = prevCheck.map((item, i) => {
				return {
					...item,
					permission: {
						matching_flag: item.permission.matching_flag,
						parking_flag: item.permission.parking_flag,
						field_list: item?.permission?.field_list?.map((ite) => {
							if (ite?.id === id && type === "view_flag" && selectedId === i) {
								const updatedIte = {
									...ite,
									view_flag: e.target.checked,
									edit_flag: e.target.checked == false && e.target.checked,
								};
								// Add or replace in updatedPermissionData based on checkbox status
								setUpdatedPermissionData((prevArray) => {
									const index = prevArray.findIndex((obj) => obj.id === id && obj.type === type);
									if (index !== -1) {
										// Replace the old object
										prevArray[index] = { ...updatedIte, type };
										return [...prevArray];
									} else {
										// Add the new object
										return [...prevArray, { ...updatedIte, type }];
									}
								});
								return updatedIte;
							} else if (ite?.id === id && type === "edit_flag" && selectedId === i) {
								const updatedIte = {
									...ite,
									edit_flag: e.target.checked,
								};
								// Add or replace in updatedPermissionData based on checkbox status
								setUpdatedPermissionData((prevArray) => {
									const index = prevArray.findIndex((obj) => obj.id === id && obj.type === type);
									if (index !== -1) {
										// Replace the old object
										prevArray[index] = { ...updatedIte, type };
										return [...prevArray];
									} else {
										// Add the new object
										return [...prevArray, { ...updatedIte, type }];
									}
								});
								return updatedIte;
							}
							return ite;
						}),
					},
				};
			});
			const updatedObj = updatedCheck.find((ele, i) => i === selectedId);
			setPermissionData(updatedObj?.permission);
			return updatedCheck;
		});
		setStaticTableData((prevCheck) => {
			const updateObj = prevCheck.map((ele, checkIndex) => {
				if (ele?.id === id && type === "view_flag") {
					return {
						...ele,
						view_flag: e.target.checked,
						edit_flag: e.target.checked == false && e.target.checked,
					};
				} else if (ele?.id === id && type === "edit_flag") {
					return {
						...ele,
						edit_flag: e.target.checked,
					};
				}
				return ele;
			});
			setStaticTableData(updateObj);
		});
	};
	//component to return on map
	const AccordianComponent = ({ type }) => (
		<div
			className=" paperBorder xl:w-full overflow-x-auto"
			id="ListView"
		>
			<Accordion
				expanded={expanded === type}
				onChange={handleChange(type)}
			>
				<AccordionSummary
					aria-controls="panel1d-content"
					id="panel1d-header"
					className={`bg-${currentMode}-card text-${currentMode}-copy  `}
				>
					<span className="font-interM">{type == 1 ? "Invoice" : type == 2 ? "Line Items" : "Tax Items"}</span>
				</AccordionSummary>
				<AccordionDetails>
					<div className="flex flex-col ">
						{staticTableData?.map((item, index, originalArray) => {
							return (
								<div className="flex ">
									<span
										className={`basis-[75%] ${
											index == 0
												? "border-t-[1px]"
												: index == originalArray?.length - 1 && (type == 1 || type == 2)
												? "border-b-[1px]"
												: ""
										} border-r-[1px] pl-[50px] py-2 border-${currentMode}-700`}
									>
										{item?.label_display}
									</span>
									<div
										className={`basis-[25%]  ${
											index == 0
												? "border-t-[1px]"
												: index == originalArray?.length - 1 && (type == 1 || type == 2)
												? "border-b-[1px]"
												: ""
										} border-r-[1px] text-center py-2 border-${currentMode}-700`}
									>
										<Checkbox
											checked={item?.view_flag}
											onChange={(event) => {
												handleSetFieldPermission(event, item?.id, "view_flag");
											}}
											sx={{
												padding: 0,

												color: "rgba(85,85,85,0.7)",

												"&.Mui-checked": {
													color: "#028C78",
												},
											}}
										/>
									</div>
									<div
										className={`basis-[25%]  ${
											index == 0
												? "border-t-[1px]"
												: index == originalArray?.length - 1 && (type == 1 || type == 2)
												? "border-b-[1px]"
												: ""
										}  text-center py-2 border-${currentMode}-700`}
									>
										<Checkbox
											checked={item?.edit_flag}
											onChange={(event) => {
												handleSetFieldPermission(event, item?.id, "edit_flag");
											}}
											disabled={item?.view_flag == false}
											sx={{
												padding: 0,
												color: "rgba(85,85,85,0.7)",
												"&.Mui-checked": {
													color: "#028C78",
												},
											}}
										/>
									</div>
								</div>
							);
						})}
					</div>
				</AccordionDetails>
			</Accordion>
		</div>
	);
	return (
		<>
			<Card
				tabIndex={0}
				className={` p-4  mt-3 border-[0px] border-t-[0] border-b-[0] border-${currentMode}-600  overflow-y-auto rounded-t-[8px] rounded-b-[0] `}
			>
				{props?.documentGroup == 1 && (
					<div className={`flex-col border-b-[1px] pb-[20px] flex items-start justify-between border-${currentMode}-700`}>
						<span className="mb-1 inline-block font-interM">Invoice Settings Permissions</span>
						<p className="text-xs mb-[10px] mt-[10px]">
							Invoice settings permissions refers to the specific access rights related to the invoice-related settings.
						</p>
						{
							<div className="flex flex-col items-start  flex-auto">
								{checkBoxClick?.map((item, index) => {
									return (
										<div className="flex py-[5px]">
											<Checkbox
												tabIndex={0}
												name="permissions-checkbox"
												size="small"
												// disabled={orgName !== 0}
												checked={item?.isChecked}
												disabled={masterRefId == 2 || item?.disable}
												// value={notificationenable}
												className="mr-2"
												onChange={(event) => handleCheckPermission(event, item?.label_name)}
												sx={{
													padding: 0,
													color: "rgba(85,85,85,0.7)",
													"&.Mui-checked": {
														color: "#028C78",
													},
												}}
											/>
											<span className="inline-block">{item?.displayName}</span>
											<Tooltip
												title={item?.tooltip}
												disablePortal
												placement="right"
												disableInteractive
												PopperProps={{
													sx: {
														"& .MuiTooltip-tooltip": {
															fontSize: "0.9em",
															maxHeight: "120px",
															maxWidth: "250px",
															background: "#DAF9F4",
															color: "#555555",
															borderRadius: "5px",
															marginTop: "0 !important",
														},

														"& .MuiTooltip-arrow": {
															color: "#DAF9F4",
														},
													},
												}}
												arrow
											>
												<img
													className="ml-2"
													src="/images/main/noun-information.svg"
													alt="noun-information"
												/>
											</Tooltip>
										</div>
									);
								})}
							</div>
						}
					</div>
				)}
				{props?.documentGroup == 2 && (
					<div
						class={`bg-[#FFF4E9] text-${currentMode}-copy border py-[12px] px-[19px] rounded-[8px]  border-[#FBDDBF] ${
							currentMode === "dark" ? "" : ` shadow-[0px_4px_20px] shadow-${currentMode}-1130`
						} `}
					>
						<div className="flex space-x-3">
							<img
								className="bg-message  w-[24px] h-[21px] mb-8"
								src="/images/swal/caution-icon.svg"
								alt="alert"
							/>
							<p className="font-interM mr-5"> Permissions for invoice fields apply when the selected document type is Adhoc.</p>
						</div>
					</div>
				)}
				<div className={`flex flex-col border-[0] items-start justify-between mt-5`}>
					<span className="mb-1 inline-block font-interM">Invoice Fields Permissions</span>
					<p className="text-xs mb-[10px] mt-[10px]">
						Invoice field permissions refers to the specific access rights for the users or roles in the invoice fields.
					</p>

					<Card
						tabIndex={0}
						className={`w-full  mt-3 border-[1px] border-${currentMode}-600 overflow-y-auto rounded-[8px] ${
							currentMode === "dark" ? "" : "shadow-[0px_4px_20px_rgba(238,238,238,0.5)]"
						}`}
					>
						<div
							className={`bg-${currentMode}-450  xl:w-full overflow-x-auto font-interM flex  left-0 right-0 z-[1] rounded-t-[8px] top-0 py-[12px] `}
						>
							<div className="basis-[75%] pl-[30px]">Fields</div>
							<div className="basis-[25%] text-center">View</div>
							<div className="basis-[25%] text-center">Edit</div>
						</div>
						<AccordianComponent type={1} />
						<AccordianComponent type={2} />
						<AccordianComponent type={3} />
					</Card>
				</div>
			</Card>
		</>
	);
};
export default PermissionsModal;
