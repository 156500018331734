import { useState, useEffect } from "react";
import Loader from "../../../components/Loader";
import ReactDOM from "react-dom";
import ViewAlias from "./ViewAlias";
import { FormControl, MenuItem, Select, InputLabel, Tabs, Tab, Avatar, Box, Typography, TextField, Tooltip, Checkbox } from "@mui/material";
import PropTypes from "prop-types";
import { useContext } from "react";
import { ThemeContext } from "../../../Hooks/Themes/Themeprovider";
function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
            {value === index && (
                <div className="p-[30px] pb-0">
                    <div>{children}</div>
                </div>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}
const ViewOrganisationModal = (props) => {
    const [viewAlias, setViewAlias] = useState(false);
    const { viewedData: viewData } = props;
    const aliasLength = viewData?.org_alias_list?.length;
    const [tabvalue, setTabValue] = useState(0);

    const _tabHandleChange = (event, newValue) => {
        setTabValue(newValue);
    };
    const aliasnameValue = aliasLength > 1 ? " alias names added" : " alias name added";
    const alias = aliasLength == undefined ? "No alias name added" : aliasLength + aliasnameValue;
    const months = [
        { monthName: "January", val: 1 },
        { monthName: "February", val: 2 },
        { monthName: "March", val: 3 },
        { monthName: "April", val: 4 },
        { monthName: "May", val: 5 },
        { monthName: "June", val: 6 },
        { monthName: "July", val: 7 },
        { monthName: "August", val: 8 },
        { monthName: "September", val: 9 },
        { monthName: "October", val: 10 },
        { monthName: "November", val: 11 },
        { monthName: "December", val: 12 },
    ];
    const [fiscalStart, setFiscalStart] = useState("");
    const [fiscalEnd, setFiscalEnd] = useState("");
    useEffect(() => {
        if (viewData) {
            setFiscalStart(months?.find((el) => el?.val == viewData?.fy_start));
            setFiscalEnd(months?.find((el) => el?.val == viewData?.fy_end));
        }
    }, [viewData]);
    const { currentMode } = useContext(ThemeContext);
    return (
        <div>
            {viewData?.length === 0 ? (
                <Loader className="h-[calc(20vh_-_19rem)]" />
            ) : (
                <>
                    <div className={` px-[30px] py-[24px] border-b-[1px] border-${currentMode}-590 relative text-${currentMode}-copy`}>
                        <h4 className="font-interSb text-lg">View Organization</h4>
                    </div>
                    <Tabs
                        value={tabvalue}
                        className="profileTab orgTab"
                        onChange={_tabHandleChange}
                        aria-label="basic tabs example"
                        TabIndicatorProps={{
                            sx: {
                                backgroundColor: "#00B399",
                                color: "#00B399",
                            },
                        }}>
                        <Tab
                            label="Organization Details"
                            {...a11yProps(0)}
                            iconPosition="start"
                            icon={
                                tabvalue == 0 ? (
                                    <img className="convertWhite" src="/images/common/org_details.svg" />
                                ) : (
                                    <img className="convertWhite" src="/images/common/org-details-black.svg" />
                                )
                            }
                        />{" "}
                        <Tab
                            label="Account Details"
                            {...a11yProps(1)}
                            iconPosition="start"
                            icon={
                                tabvalue == 1 ? (
                                    <img className="convertWhite" src="/images/common/org_acc_green.svg" />
                                ) : (
                                    <img className="convertWhite" src="/images/common/org_acc_details.svg" />
                                )
                            }
                        />
                    </Tabs>
                    <CustomTabPanel value={tabvalue} index={0}>
                        <div className="mx-[35px] max-w-[682px]">
                            <div className="flex mb-5 viewStyles">
                                <div className="basis-1/2">
                                    <span className="block text-sm"> Organization Domain Name</span>
                                    <p className="text-base inline-block trunc-2 max-w-[220px]" title={viewData?.url}>
                                        <b className=""> {viewData?.url}</b>
                                    </p>
                                </div>
                                <div className="basis-1/2 w-[200px]">
                                    <img
                                        className="rounded-[50%] mr-80 object-cover w-[46px] h-[46px]"
                                        src={viewData?.org_logo ? `data:image;base64,${viewData?.org_logo}` : `/images/common/orgLogo.png`}
                                        alt="Profile"
                                    />
                                </div>
                            </div>

                            {/* <span className="font-interSb font-sm block mb-[10px]">
                                Organization Details:
                            </span> */}
                            <div className="flex mb-5 viewStyles">
                                <div className="basis-1/2">
                                    <span className="block text-sm"> Organization Name</span>
                                    <p className="text-base  inline-block w-[200px] trunc-2" title={viewData?.organisation_name}>
                                        <b>{viewData?.organisation_name}</b>
                                    </p>
                                </div>
                                <div className="basis-1/2 flex items-end space-x-3">
                                    <div>
                                        <span className="block text-sm">Organization Alias Name</span>

                                        <b
                                            className={`text-base ${aliasLength == undefined ? "" : "underline cursor-pointer"}   `}
                                            onClick={() => setViewAlias(aliasLength == undefined ? false : true)}>
                                            {alias}{" "}
                                        </b>
                                    </div>
                                    {/* {aliasLength !== undefined && (
                                        <span
                                            title="To View Alias Name"
                                            className="cursor-pointer"
                                            onClick={() => setViewAlias(true)}
                                        >
                                            <img
                                                className="convertWhite"
                                                src="/images/main/eye-blk.svg"
                                                alt="eye-blk"
                                            />
                                        </span>
                                    )} */}
                                </div>
                                {/* <div className="basis-1/2">
          <span className="block text-sm">Postal Code</span>
          <b className="text-base">{viewData?.zipcode}</b>
        </div> */}
                            </div>
                            <div className="flex mb-5 viewStyles">
                                <div className="basis-1/2">
                                    <span className="block text-sm">Register Number</span>
                                    <b className="text-base">
                                        <p className="truncate w-[200px]" title={viewData?.registration_no}>
                                            {viewData?.registration_no}
                                        </p>
                                    </b>
                                </div>
                                <div className="basis-1/2">
                                    <span className="block text-sm">Organization Code</span>
                                    <b className="text-base">
                                        <p className="truncate w-[200px]" title={viewData?.organisation_code}>
                                            {viewData?.organisation_code}
                                        </p>
                                    </b>
                                </div>
                            </div>
                            <div className="flex mb-5 viewStyles">
                                <div className="basis-1/2">
                                    <span className="block text-sm">Address</span>
                                    <b className="text-base block">{viewData?.address_line1}</b>
                                </div>
                                {/* <div className="basis-1/2">
                                    <span className="block text-sm">
                                        Fiscal Period
                                    </span>
                                    <b className="text-base block">
                                        {fiscalStart?.monthName}-
                                        {fiscalEnd?.monthName}
                                    </b>
                                </div> */}
                                <div className="basis-1/2">
                                    <span className="block text-sm">Country</span>
                                    <b className="text-base block">{viewData?.country_name}</b>
                                </div>
                            </div>
                            {/* <span className="font-interSb font-sm block mb-[10px]">Format Details:</span>
                            <div className="flex mb-5 viewStyles">
                                <div className="basis-1/2">
                                    <span className="block text-sm">Number Format</span>
                                    <b className="text-base block">{viewData?.numformat_display}</b>
                                </div>
                                <div className="basis-1/2">
                                    <span className="block text-sm">Date Format</span>
                                    <b className="text-base block">{viewData?.dateformat}</b>
                                </div>
                            </div> */}
                        </div>
                    </CustomTabPanel>
                    <CustomTabPanel value={tabvalue} index={1}>
                        <div className="mx-[35px] max-w-[682px]">
                            <div className="flex mb-5 viewStyles">
                                <div className="basis-1/2">
                                    <span className="block text-sm">Fiscal Period</span>
                                    <b className="text-base">{fiscalStart?.monthName !== undefined ? fiscalStart?.monthName : "-"}</b>
                                </div>
                                <div className="basis-1/2">
                                    <span className="block text-sm opacity-0">text</span>
                                    <b className="text-base">{fiscalEnd?.monthName !== undefined ? fiscalEnd?.monthName : "-"}</b>
                                </div>
                            </div>

                            <div className="flex mb-5 viewStyles">
                                <div className="basis-1/2">
                                    <span className="block text-sm">Date Format</span>
                                    <b className="text-base block">{viewData?.dateformat}</b>
                                </div>
                                <div className="basis-1/2">
                                    <span className="block text-sm">Number Format</span>
                                    <b className="text-base block">{viewData?.numformat_display}</b>
                                </div>
                            </div>
                            <div className="flex mb-5 viewStyles">
                                <div className="basis-1/2">
                                    <span className="block text-sm">Currency</span>
                                    <b className="text-base block">{viewData?.currency}</b>
                                </div>
                                <div className="basis-1/2">
                                    <span className="block text-sm">Tolerance</span>
                                    <b className="text-base block">{viewData?.tolerance + (viewData?.tolerance !== "" ? "%" : "")}</b>
                                </div>
                                {/* <div className="basis-1/2">
                                    <span className="block text-sm">
                                        Is Parking Required
                                    </span>
                                    <b className="text-base block">
                                        {viewData?.is_parking == true
                                            ? "Yes"
                                            : "No"}
                                    </b>
                                </div> */}
                            </div>
                            {/* <div className="flex mb-5 viewStyles">
                                <div className="basis-1/2">
                                    <span className="block text-sm">
                                        3 Way Matching Required
                                    </span>
                                    <b className="text-base block">
                                        {viewData?.is_parking == true
                                            ? "Yes"
                                            : "No"}
                                    </b>
                                </div>
                            </div> */}
                            <div className="flex  form-group items-center form-check mb-5">
                                <Checkbox
                                    name="is_parking"
                                    size="small"
                                    tabIndex={0}
                                    className="mr-2"
                                    checked={viewData?.is_parking}
                                    sx={{
                                        padding: 0,
                                        color: "rgba(85,85,85,0.7)",
                                        "&.Mui-checked": {
                                            color: "#028C78",
                                        },
                                    }}
                                />
                                Is Invoice Parking Required?
                            </div>

                            <div className="flex  form-group items-center form-check mb-5">
                                <Checkbox
                                    name="three_way_matching"
                                    size="small"
                                    tabIndex={0}
                                    className="mr-2"
                                    checked={viewData?.three_way_matching}
                                    sx={{
                                        padding: 0,
                                        color: "rgba(85,85,85,0.7)",
                                        "&.Mui-checked": {
                                            color: "#028C78",
                                        },
                                    }}
                                />
                                Is 3-Way Matching Required?
                            </div>
                            <div className="flex form-group items-center form-check mb-5">
                                <Checkbox
                                    name="match_netamount"
                                    size="small"
                                    tabIndex={0}
                                    className="mr-2"
                                    checked={viewData?.match_netamount}
                                    sx={{
                                        padding: 0,
                                        color: "rgba(85,85,85,0.7)",
                                        "&.Mui-checked": {
                                            color: "#028C78",
                                        },
                                    }}
                                />
                                Match Invoice Line Item without Tax = Invoice Net Amount
                            </div>
                            <div className="flex form-group items-center form-check mb-5">
                                <Checkbox
                                    name="retention_recovery"
                                    size="small"
                                    tabIndex={0}
                                    className="mr-2"
                                    checked={viewData?.retention_recovery}
                                    sx={{
                                        padding: 0,
                                        color: "rgba(85,85,85,0.7)",
                                        "&.Mui-checked": {
                                            color: "#028C78",
                                        },
                                    }}
                                />
                                Is Invoice Retention Recovery Required?
                            </div>
                            <div className="flex form-group items-center form-check mb-5">
                                <Checkbox
                                    name="advance_recovery"
                                    size="small"
                                    tabIndex={0}
                                    className="mr-2"
                                    checked={viewData?.advance_recovery}
                                    sx={{
                                        padding: 0,
                                        color: "rgba(85,85,85,0.7)",
                                        "&.Mui-checked": {
                                            color: "#028C78",
                                        },
                                    }}
                                />
                                Is Invoice Advance Recovery Required?
                            </div>
                        </div>
                    </CustomTabPanel>
                </>
            )}
            {ReactDOM.createPortal(
                <ViewAlias isOpened={viewAlias} handleClose={() => setViewAlias(false)} className={viewAlias && "Show"} />,
                document.getElementById("user-modal-root")
            )}
        </div>
    );
};

export default ViewOrganisationModal;
