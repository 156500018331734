import axios from "axios";
import authHeader from "./auth-header";
import { checkApiStatus } from "../../utils/helpers";
import {
    GET_CUSTOM_REPORTS_LABEL_FAILURE,
    GET_CUSTOM_REPORTS_LABEL_SUCCESS,
    GET_CUSTOM_REPORTS_LABEL_REQUEST,
    CUSTOM_REPORTS_LIST_REQUEST,
    CUSTOM_REPORTS_LIST_SUCCESS,
    CUSTOM_REPORTS_LIST_FAILURE,
    GET_COLUMN_REQUEST,
    GET_COLUMN_SUCCESS,
    GET_COLUMN_FAILURE,
    POST_FILTER_DROPDOWN_REQUEST,
    POST_FILTER_DROPDOWN_SUCCESS,
    POST_FILTER_DROPDOWN_FAILURE,
    GET_PREVIEW_DATA_REQUEST,
    GET_PREVIEW_DATA_SUCCESS,
    GET_PREVIEW_DATA_FAILURE,
    GET_CUSTOM_REPORTS_CONFIGLIST_FAILURE,
    GET_CUSTOM_REPORTS_CONFIGLIST_SUCCESS,
    GET_CUSTOM_REPORTS_CONFIGLIST_REQUEST,
    GET_CUSTOM_REPORTS_CONFIGLIST_RESET,
    GET_CUSTOM_REPORTS_DELETE_REQUEST,
    GET_CUSTOM_REPORTS_DELETE_SUCCESS,
    GET_CUSTOM_REPORTS_DELETE_FAILURE,
    GET_CUSTOM_REPORTS_PUBLISH_UNPUBLISH_FAILURE,
    GET_CUSTOM_REPORTS_PUBLISH_UNPUBLISH_SUCCESS,
    GET_CUSTOM_REPORTS_PUBLISH_UNPUBLISH_REQUEST,
    POST_TABLER_DATA_REQUEST,
    POST_TABLER_DATA_SUCCESS,
    POST_TABLER_DATA_FAILURE,
    GET_PREVIEW_TABLER_REQUEST,
    GET_PREVIEW_TABLER_SUCCESS,
    GET_PREVIEW_TABLER_FAILURE,
    GET_COLUMN_LOAD_UPDATE,
    GET_REPORT_DROPDOWN_REQUEST,
    GET_REPORT_DROPDOWN_SUCCESS,
    GET_REPORT_DROPDOWN_FAILURE,
} from "../types/types.js";
import { useSelector } from "react-redux";
const API_URL = process.env?.REACT_APP_NODE_API_URL;

export const GetCustomLabelInfo = (id, orgid) => async (dispatch) => {
    try {
        dispatch({ type: GET_CUSTOM_REPORTS_LABEL_REQUEST });

        axios
            .get(`${API_URL}api/v1/OutputManagement/CustomReports-Label-Info?id=${id}&orgid=${orgid}`, {
                headers: authHeader(),
            })
            .then((response) => {
                dispatch({
                    type: GET_CUSTOM_REPORTS_LABEL_SUCCESS,
                    payload: response?.data?.datalist,
                });
            })
            .catch(function (error) {
                if (error?.response?.status == 401) {
                    checkApiStatus();
                }
                dispatch({
                    payload: { error: error.message },
                    type: GET_CUSTOM_REPORTS_LABEL_FAILURE,
                });
            });
    } catch (error) {
        dispatch({
            type: GET_CUSTOM_REPORTS_LABEL_FAILURE,
        });
    }
};

export const CustomReportsList = (data) => async (dispatch) => {
    try {
        dispatch({ type: CUSTOM_REPORTS_LIST_REQUEST });

        axios
            .post(`${API_URL}api/v1/OutputManagement/CustomReportsList`, data, {
                headers: authHeader(),
            })
            .then((response) => {
                dispatch({
                    type: CUSTOM_REPORTS_LIST_SUCCESS,
                    payload: response?.data?.datalist,
                });
            })
            .catch(function (error) {
                if (error?.response?.status == 401) {
                    checkApiStatus();
                }
                dispatch({
                    payload: { error: error.message },
                    type: CUSTOM_REPORTS_LIST_FAILURE,
                });
            });
    } catch (error) {
        dispatch({
            type: GET_CUSTOM_REPORTS_LABEL_FAILURE,
        });
    }
};
// Below Action (API) call to get the get view tabler reports
export const GetViewTablerList = (id) => async (dispatch) => {
    try {
        dispatch({ type: GET_PREVIEW_TABLER_REQUEST });

        axios
            .get(`${API_URL}api/v1/OutputManagement/CustomReports-View?id=${+id}`, {
                headers: authHeader(),
            })
            .then((response) => {
                dispatch({
                    type: GET_PREVIEW_TABLER_SUCCESS,
                    payload: response?.data?.datalist,
                });
                dispatch({
                    type: "TABLER_REPORTS_VIEW",
                    payload: response?.data?.datalist,
                });
            })
            .catch(function (error) {
                if (error?.response?.status == 401) {
                    checkApiStatus();
                }
                dispatch({
                    payload: { error: error.message },
                    type: GET_PREVIEW_TABLER_FAILURE,
                });
            });
    } catch (error) {
        dispatch({
            type: GET_PREVIEW_TABLER_FAILURE,
        });
    }
};
// Below Action (API) call to get the Columns list
export const GetColumnsList = (data) => async (dispatch) => {
    try {
        dispatch({ type: GET_COLUMN_REQUEST });

        axios
            .get(`${API_URL}api/v1/OutputManagement/ColumnList?type=${data?.type}&orgid=${data?.orgid}&output_type=${data?.outputType}`, {
                headers: authHeader(),
            })
            .then((response) => {
                dispatch({
                    type: GET_COLUMN_SUCCESS,
                    payload: response?.data?.datalist,
                });
                if (response?.data?.datalist?.length !== 0) {
                    dispatch({
                        type: "FIRST_SET_OPEN",
                        payload: response?.data?.datalist?.map((item, index) => `panel${index}`),
                    });
                    dispatch({ type: GET_COLUMN_LOAD_UPDATE, payload: { dropped: data?.droppedValues, accord: response?.data?.datalist } });
                }
            })
            .catch(function (error) {
                if (error?.response?.status == 401) {
                    checkApiStatus();
                }
                dispatch({
                    payload: { error: error.message },
                    type: GET_COLUMN_FAILURE,
                });
            });
    } catch (error) {
        dispatch({
            type: GET_COLUMN_FAILURE,
        });
    }
};

// Below Action (API) call to get the Master filter dropdown data
export const GetFilterDropdown = (data) => async (dispatch) => {
    try {
        dispatch({ type: POST_FILTER_DROPDOWN_REQUEST });

        axios
            .post(`${API_URL}api/v1/OutputManagement/Master-Filter`, data, {
                headers: authHeader(),
            })
            .then((response) => {
                dispatch({
                    type: POST_FILTER_DROPDOWN_SUCCESS,
                    payload: response?.data?.datalist,
                });
            })
            .catch(function (error) {
                if (error?.response?.status == 401) {
                    checkApiStatus();
                }
                dispatch({
                    payload: { error: error.message },
                    type: POST_FILTER_DROPDOWN_FAILURE,
                });
            });
    } catch (error) {
        dispatch({
            type: POST_FILTER_DROPDOWN_FAILURE,
        });
    }
};

// Below Action (API) call to get the Preview data table data
export const GetPreviewData = (data) => async (dispatch) => {
    try {
        dispatch({ type: GET_PREVIEW_DATA_REQUEST });

        axios
            .post(`${API_URL}api/v1/OutputManagement/PreviewData`, data, {
                headers: authHeader(),
            })
            .then((response) => {
                dispatch({
                    type: GET_PREVIEW_DATA_SUCCESS,
                    payload: response?.data?.datalist,
                });
            })
            .catch(function (error) {
                if (error?.response?.status == 401) {
                    checkApiStatus();
                }
                dispatch({
                    payload: { error: error.message },
                    type: GET_PREVIEW_DATA_FAILURE,
                });
            });
    } catch (error) {
        dispatch({
            type: GET_PREVIEW_DATA_FAILURE,
        });
    }
};

export const CustomreportsConfigList = (data) => async (dispatch) => {
    try {
        dispatch({ type: GET_CUSTOM_REPORTS_CONFIGLIST_REQUEST });

        axios
            .post(`${API_URL}api/v1/OutputManagement/ReportsSummaryList`, data, {
                headers: authHeader(),
            })
            .then((response) => {
                dispatch({
                    type: GET_CUSTOM_REPORTS_CONFIGLIST_SUCCESS,
                    payload: response?.data,
                });
            })
            .catch(function (error) {
                if (error?.response?.status == 401) {
                    checkApiStatus();
                }
                dispatch({
                    payload: { error: error.message },
                    type: GET_CUSTOM_REPORTS_CONFIGLIST_FAILURE,
                });
            });
    } catch (error) {
        dispatch({
            type: GET_CUSTOM_REPORTS_CONFIGLIST_FAILURE,
        });
    }
};

export const CustomreportsConfigDelete = (data) => async (dispatch) => {
    try {
        dispatch({ type: GET_CUSTOM_REPORTS_DELETE_REQUEST });

        axios
            .delete(`${API_URL}api/v1/OutputManagement/Report`, {
                headers: authHeader(),
                data: data,
            })
            .then((response) => {
                dispatch({
                    type: GET_CUSTOM_REPORTS_DELETE_SUCCESS,
                    payload: response,
                });
            })
            .catch(function (error) {
                if (error?.response?.status == 401) {
                    checkApiStatus();
                }
                dispatch({
                    payload: error?.response?.data,
                    type: GET_CUSTOM_REPORTS_DELETE_FAILURE,
                });
            });
    } catch (error) {
        dispatch({
            type: GET_CUSTOM_REPORTS_DELETE_FAILURE,
            payload: error?.response?.data,
        });
    }
};

export const CustomreportsPublishUnpublish = (data) => async (dispatch) => {
    try {
        dispatch({ type: GET_CUSTOM_REPORTS_PUBLISH_UNPUBLISH_REQUEST });

        axios
            .post(`${API_URL}api/v1/OutputManagement/Publish-UnPublish-Report`, data, {
                headers: authHeader(),
            })
            .then((response) => {
                dispatch({
                    type: GET_CUSTOM_REPORTS_PUBLISH_UNPUBLISH_SUCCESS,
                    payload: response,
                });
            })
            .catch(function (error) {
                if (error?.response?.status == 401) {
                    checkApiStatus();
                }
                dispatch({
                    payload: error?.response?.data,
                    type: GET_CUSTOM_REPORTS_PUBLISH_UNPUBLISH_FAILURE,
                });
            });
    } catch (error) {
        dispatch({
            payload: error?.response?.data,
            type: GET_CUSTOM_REPORTS_PUBLISH_UNPUBLISH_FAILURE,
        });
    }
};
// Below Action (API) call to Add table reports table data
export const postTablerdata = (data) => async (dispatch) => {
    try {
        dispatch({ type: POST_TABLER_DATA_REQUEST });

        axios
            .post(`${API_URL}api/v1/OutputManagement/AddUpdateReport`, data, {
                headers: authHeader(),
            })
            .then((response) => {
                dispatch({
                    type: POST_TABLER_DATA_SUCCESS,
                    payload: response?.data,
                });
            })
            .catch(function (error) {
                if (error?.response?.status == 401) {
                    checkApiStatus();
                }
                dispatch({
                    payload: { error: error?.response?.data },
                    type: POST_TABLER_DATA_FAILURE,
                });
            });
    } catch (error) {
        dispatch({
            type: POST_TABLER_DATA_FAILURE,
        });
    }
};

// Below Action (API) call to get the report from dropdown and delivery format
export const GetDropdownList = (orgId) => async (dispatch) => {
    try {
        dispatch({ type: GET_REPORT_DROPDOWN_REQUEST });

        axios
            .get(`${API_URL}api/v1/OutputManagement/Dropdowns?orgid=${orgId}`, {
                headers: authHeader(),
            })
            .then((response) => {
                dispatch({
                    type: GET_REPORT_DROPDOWN_SUCCESS,
                    payload: response?.data?.datalist,
                });
            })
            .catch(function (error) {
                if (error?.response?.status == 401) {
                    checkApiStatus();
                }
                dispatch({
                    payload: { error: error.message },
                    type: GET_REPORT_DROPDOWN_FAILURE,
                });
            });
    } catch (error) {
        dispatch({
            type: GET_REPORT_DROPDOWN_FAILURE,
        });
    }
};
