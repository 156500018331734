import { json, Link, useLocation } from "react-router-dom";
import Button from "../../../components/Button";
import Modal from "../../../components/UI/Modal/Modal";
import { Autocomplete, Checkbox, Chip, FormControl, MenuItem, Paper, Select, TextField } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import UseForm from "../../../Hooks/useForm";
import { getOrganisation } from "../../../redux/actions/organisation";
import { useState, useEffect } from "react";
import { getCurrentUser } from "../../../redux/actions/auth";
import { AddupdateroleOrg, roleid } from "../../../redux/actions/role";
import { decryptId } from "../../../utils/helpers";
import MessageBox from "../../../components/UI/Alert";
import { USER_ROLE_BYORG_RESET, USER_ROLE_DELETE_RESET } from "../../../redux/types/types";
import SuccessMessageBox from "../../../components/UI/Alert/sucess";
import { userByOrg } from "../../../redux/actions/user";
import { useContext } from "react";
import { ThemeContext } from "../../../Hooks/Themes/Themeprovider";

const AddUserOrg = (props) => {
    const { currentMode } = useContext(ThemeContext);
    const reqObj = {
        order_by: 1,
        order_column: "",
        pg_no: 1,
        rec_cnt: 20,
        role_id: 0,
        search_text: "",
        status: 2,
    };
    const { handleChange, handleSubmit, values, InitialState, errors, setErrors, setValues } = UseForm(submit);
    const successMsg = "Role is  assigned successfully.";
    const errormsgs = "Something went wrong please contact admin";
    const currentUser = getCurrentUser();
    const dispatch = useDispatch();
    dispatch({ type: USER_ROLE_DELETE_RESET });
    const [errormsg, seterrormsg] = useState(false);
    const { role } = useSelector((state) => state?.roleState);
    let result = useSelector((state) => state?.RoleByOrgState);
    const orgData = useSelector((state) => state?.organisationListState);
    let profileData = useSelector((state) => state?.userListState);
    const { userlist: listData } = profileData?.data;
    let userList = listData?.filter((item) => item.id === props?.userId);
    const orgList = orgData.OrgList;
    let filteredList = orgList?.filter((elem) => elem?.organisation_isactive === true);
    let orgListItem = [];
    if (userList && userList[0]?.organisationRole_list) {
        const userSelectedRoles = userList[0]?.organisationRole_list;
        filteredList?.forEach((item) => {
            const isSelected = userSelectedRoles.find((orgItem) => orgItem.organisation_id === item.id);
            if (!isSelected) {
                orgListItem.push(item);
            }
        });
    } else {
        orgListItem = filteredList;
    }
    const [isadult, setIsAdult] = useState([]);
    const [val, setVal] = useState([]);
    const [chkValue, setCheckbox] = useState();

    const handleCheckboxChange = (event, orgId) => {
        setIsAdult({
            ...isadult,
            [event.target.name]: event.target.value,
        });

        if (!event.target.value) {
            if (val.length > 0) {
                const isExistID = val.find((item) => item.id === orgId);
                if (isExistID) {
                    const removeIdList = val.filter((item) => item.id !== orgId);
                    setVal([...removeIdList]);
                }
            }
            // if (selectedUserList.length > 0) {
            //   const userExist = val.find(item => item.id === userId);
            //   if (!userExist) {
            //     selectedUserList.push(userId);
            //     setSelectedUser([...selectedUserList]);
            //   }
            // } else {
            //   selectedUserList.push(userId);
            //   setSelectedUser([...selectedUserList]);
            // }
        }
    };

    const handleClose = () => {
        props?.handleClose();
        setValues(InitialState);
        setVal([]);
        seterrormsg(false);
    };
    function submit() {
        if (val.length > 0 && values?.role_id > 0) {
            seterrormsg(false);
            let getOrgIdList = [];
            val.forEach((item) => {
                getOrgIdList.push(item.id);
            });
            const jsonreq = {
                id: getOrgIdList,
                user_id: props?.userId,
                role_id: parseInt(values?.role_id),
            };
            dispatch(AddupdateroleOrg(jsonreq));
        } else {
            seterrormsg(true);
        }
    }
    const reqObjList = {
        role_id: 0,
        status: 2,
        order_column: "",
        search_text: "",
        order_by: 1,
        pg_no: 1,
        rec_cnt: 10,
    };

    if (result?.requestComplete && result?.roleResult?.status) {
        setTimeout(() => {
            dispatch({ type: USER_ROLE_BYORG_RESET });
            handleClose();
            dispatch(userByOrg(reqObjList));
            window.location.reload();
        }, 1000);
    }
    const dropdownColor = currentMode === "dark" ? "#333333" : `white`;
    const dropdownText = currentMode === "dark" ? "#ECECEC" : `#555555`;
    const dropdownShadow = currentMode !== "dark" ? "0px 4px 20px rgba(238, 238, 238, 0.5)" : "";
    const dropdownBorder = currentMode !== "dark" ? "223, 225, 226, 0.56" : "";
    const Ico = () => (
        <img
            className="opacity-[.5] pr-[5px] pointer-events-none"
            src={currentMode !== "dark" ? `/images/header/chevronDown.svg` : `/images/header/chevronDown-white.svg`}
            alt="chevronDown"
        />
    );
    const placeholderColor = currentMode !== "dark" ? "#555555" : "#ECECEC";
    return (
        <>
            {result?.requestComplete && result?.roleResult?.status && <SuccessMessageBox success={successMsg} />}
            {result?.requestComplete && !result?.roleResult?.status && <MessageBox error={result?.roleResult?.message} />}
            {result?.error && !result?.roleResult?.status && <MessageBox error={errormsgs} />}
            <Modal cartClose={props?.handleClose} className={`${props?.className}`}>
                <span onClick={handleClose} className="p-[6.5px] absolute right-[565px] cursor-pointer bg-light-10" title="Close">
                    <img src="/images/common/whiteClose.svg" alt="whiteClose" />
                </span>
                <div
                    className={`max-w-[565px] transition duration-150 ease-out ml-auto flex-col flex h-screen max-h-[-webkit-fill-available] bg-${currentMode}-card text-${currentMode}-copy border-${currentMode}-700`}>
                    <div
                        className={`px-[30px] py-[24px] border-b-[1px] border-[rgba(236,236,236,0.88)] mb-[1.8rem] relative bg-${currentMode}-card text-${currentMode}-copy border-${currentMode}-700`}>
                        <h4 className={`bg-${currentMode}-card text-${currentMode}-copy border-${currentMode}-700 font-interSb text-lg`}>
                            Organization and Roles
                        </h4>
                    </div>
                    <div className={`px-[35px] bg-${currentMode}-card`}>
                        {/* <span className="text-sm font-interM mb-5 block">
            Organization & Roles
            </span> */}
                        <form onSubmit={handleSubmit} className="h-full flex flex-col">
                            <div className="max-w-[500px] h-[calc(100vh_-_210px)] overflow-y-auto">
                                <div className="mb-4 filter">
                                    <Autocomplete
                                        // className={`bg-${currentMode}-810`}
                                        multiple
                                        id="checkboxes-tags-demo"
                                        options={orgListItem?.length == 0 ? [] : orgListItem}
                                        clearOnBlur={false}
                                        popupIcon={<Ico />}
                                        disableCloseOnSelect
                                        onChange={(e, newValue) => setVal(newValue)}
                                        getOptionLabel={(option) => option?.organisation_name}
                                        value={val}
                                        sx={{
                                            "& .MuiChip-label": {
                                                color: `${currentMode == "dark" ? "white" : "black"}`,
                                            },
                                        }}
                                        PaperComponent={({ children }) => (
                                            <Paper
                                                style={{
                                                    backgroundColor: dropdownColor,
                                                    color: dropdownText,
                                                    boxShadow: dropdownShadow,
                                                    border: dropdownBorder,
                                                }}>
                                                {children}
                                            </Paper>
                                        )}
                                        // onChange={props?.handleSelectApprovedUser}
                                        renderOption={(props, option, { selected }) => (
                                            <li {...props}>
                                                <Checkbox
                                                    style={{ marginRight: 8 }}
                                                    sx={{
                                                        color: "rgba(85,85,85,0.7)",
                                                        borderRadius: "3px !important",
                                                        "&.Mui-checked": {
                                                            color: "#028C78",
                                                        },
                                                    }}
                                                    type="checkbox"
                                                    checked={selected}
                                                />
                                                <img
                                                    className=" mx-2 w-[22px] h-[22px] object-cover rounded-full"
                                                    alt="Natacha"
                                                    src={option?.logo ? `data:image;base64,${option?.logo}` : `/images/common/profileImg.jpg`}
                                                />
                                                {option?.organisation_name}
                                            </li>
                                        )}
                                        limitTags={1}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                fullWidth
                                                className="p-0"
                                                InputProps={{
                                                    ...params.InputProps,
                                                }}
                                                placeholder="Select Organizations"
                                            />
                                        )}
                                    />
                                    {/* <Autocomplete
                    multiple
                    id="checkboxes-tags-demo"
                    sx={{
                      "& .MuiAutocomplete-popper": {
                        backgroundColor: "white",
                        boxShadow: "0px 4px 20px rgba(238, 238, 238, 0.5)",
                        border: "1px solid rgba(223, 225, 226, 0.56)",
                        borderRadius: "8px",
                      },
                      "& .MuiMenuItem-root:hover": {
                        backgroundColor: "rgba(218, 249, 244, 0.3)",
                      },
                      "& .Mui-selected": {
                        backgroundColor: "rgba(218, 249, 244, 0.3) !important",
                      },
                      "& .MuiAutocomplete-popupIndicator": {
                        padding: 0,
                        marginTop: "5px",
                        marginRight: "10px",
                      },
                      "& .MuiAutocomplete-popupIndicatorOpen": {
                        padding: 0,
                        marginRight: "10px",
                      },
                    }}
                    PaperComponent={({ children }) => (
                      <Paper
                        style={{
                          backgroundColor: "white",
                          boxShadow: "0px 4px 20px rgba(238, 238, 238, 0.5)",
                          border: "1px solid rgba(223, 225, 226, 0.56)",
                        }}
                      >
                        {children}
                      </Paper>
                    )}
                    popupIcon={
                      <img
                        className="m-0 pointer-events-none"
                        src="/images/header/chevronDown.svg"
                      />
                    }
                    options={orgListItem}
                    clearOnBlur={false}
                    disableCloseOnSelect
                    onChange={(e, newValue) => setVal(newValue)}
                    getOptionLabel={(option) => option?.organisation_name}
                    renderTags={() => {
                      // return optionse?.map((option, index) => (

                      return val?.map((option, index) => {
                        const label = option?.organisation_name;
                        return (
                          <div className="mr-1 mb-2">
                            <Chip
                              sx={{ borderRadius: "8px" }}
                              // onClick={() => removeUser(index, option.id)}
                              key={label}
                              label={label}
                              // onDelete={()=>removeSelectedItems(option.id)}
                              onDelete={() => {
                                setVal([
                                  ...val.filter(
                                    (entry) => entry.id !== option.id
                                  ),
                                ]);
                              }}
                              deleteIcon={
                                <img src="/images/common/closeBlk.svg" />
                              }
                              avatar={
                                <img
                                  alt="profileImg"
                                  className="w-[22px] h-[22px] object-cover rounded-full"
                                  src={
                                    option?.logo
                                      ? `data:image;base64,${option?.logo}`
                                      : `/images/common/profileImg.jpg`
                                  }
                                />
                              }
                            />
                          </div>
                        );
                      });
                    }}
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox
                          type="checkbox"
                          key={props.label}
                          size="small"
                          label={props.label}
                          checked={selected}
                          onChange={(event) => {
                            handleCheckboxChange(
                              {
                                target: {
                                  name: event.target.name,
                                  value: event.target.checked,
                                },
                              },
                              option.id
                            );
                          }}
                          style={{ marginRight: 2 }}
                          sx={{
                            padding: 0,
                            color: "rgba(85,85,85,0.7)",
                            "&.Mui-checked": {
                              color: "#00B399",
                            },
                          }}
                        />
                        <img
                          className=" mx-2 w-[22px] h-[22px] object-cover rounded-full"
                          src={
                            option?.logo
                              ? `data:image;base64,${option?.logo}`
                              : `/images/common/profileImg.jpg`
                          }
                          alt="Profile"
                        />
                        {option?.organisation_name}
                      </li>
                    )}
                    style={{ width: 500 }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Select Organization"
                      />
                    )}
                  /> */}
                                </div>
                                <FormControl fullWidth>
                                    <Select
                                        onChange={handleChange}
                                        value={values?.role_id || "0"}
                                        name="role_id"
                                        className={`text-${currentMode}-copy w-full`}
                                        MenuProps={{
                                            sx: {
                                                "& .MuiMenu-paper": {
                                                    backgroundColor: dropdownColor,
                                                    color: dropdownText,
                                                    //   maxWidth: "30rem !important",
                                                },
                                                "& .MuiMenuItem-root:hover": {
                                                    backgroundColor: "rgba(218, 249, 244, 0.3)",
                                                },
                                                "& .Mui-selected": {
                                                    backgroundColor: "rgba(218, 249, 244, 0.3) !important",
                                                },
                                            },
                                        }}
                                        SelectDisplayProps={{
                                            style: {
                                                color: dropdownText,
                                                padding: "12px 30px 12px 15px",
                                                border: `1px solid rgba(85, 85, 85, 0.4)`,
                                                borderRadius: "8px",
                                            },
                                        }}
                                        IconComponent={() => (
                                            <img
                                                className="absolute right-[15px] opacity-[.5] pointer-events-none"
                                                src={
                                                    currentMode !== "dark" ? `/images/header/chevronDown.svg` : `/images/header/chevronDown-white.svg`
                                                }
                                                alt="chevronDown"
                                            />
                                        )}>
                                        <MenuItem key={0} value={0}>
                                            {"Select Role"}
                                        </MenuItem>
                                        {role?.map((elem, i) => {
                                            return (
                                                <MenuItem key={i + 1} value={elem?.id}>
                                                    {elem?.role}
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                    {errormsg && <p className="text-light-50 text-xs">Please Select Role and Organization</p>}
                                </FormControl>
                            </div>
                            {/* <div className={`border-t-[1px] py-5 mx-[-35px] border-${currentMode}-590 mt-6`}> */}
                            <div className={`border-t-[1px]  left-0 py-5 border-${currentMode}-590 mx-[-35px] mt-6`}>
                                <div className="px-[35px] space-x-2 flex">
                                    <Button
                                        type="submit"
                                        disabled={result?.fetching || (result?.requestComplete && result?.roleResult?.status)}
                                        onClick={submit}
                                        title="Create"
                                        className="bg-light-20 flex text-base font-interR py-[.5rem] px-[2.24rem] rounded-[8px] text-white">
                                        Create
                                    </Button>
                                    <Button
                                        type="button"
                                        title="Cancel"
                                        onClick={handleClose}
                                        className="bg-light-240 flex text-base font-interR py-[.5rem] px-[2.24rem] rounded-[8px] text-light-40 closeModal">
                                        Cancel
                                    </Button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default AddUserOrg;
