import { Link } from "react-router-dom";
import Button from "../../../components/Button";
import Input from "../../../components/Input";
import Card from "../../../components/UI/Card";
import Main from "../../../components/UI/Main";
import Layout from "../../../Hoc/Layout";
import Footer from "../../../organisms/Footer";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useContext } from "react";
import {
    RolePermissionList,
    ViewRolePermission,
} from "../../../redux/actions/role";
import Loader from "../../../components/Loader";
import { ROLE_PERMISSION_VIEW_RESET } from "../../../redux/types/types";
import { ThemeContext } from "../../../Hooks/Themes/Themeprovider";

const RoleMaster = () => {
    const { currentMode, toggleMode } = useContext(ThemeContext);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch({ type: ROLE_PERMISSION_VIEW_RESET });
        dispatch(RolePermissionList());
    }, [dispatch]);
    const Role = useSelector((state) => state?.RolePermissionStateAction);
    const { data: RoleData, requestComplete } = Role;

    const editrole = (id) => {
        // dispatch(ViewRolePermission({roleid:id}))
    };

    return (
        <>
            <Layout>
                <Main>
                    <div className="flex justify-between items-center mb-6">
                        <div>
                            <h4
                                className={`text-2xl font-interSb text-${currentMode}-copy`}
                            >
                                User Roles
                            </h4>
                        </div>
                        <div className="flex space-x-2">
                            <Link to="/new-role">
                                <Button
                                    title="Add Role"
                                    className={`border-20 border-${currentMode}-800 bg-light-20 px-5 py-2 rounded-[8px] font-interM text-sm flex items-center border-[1px] text-white`}
                                >
                                    <img
                                        className="mr-1"
                                        src="/images/common/plus.svg"
                                        alt="plus"
                                    />{" "}
                                    Add Role
                                </Button>
                            </Link>
                        </div>
                    </div>
                    <Card
                        className={`h-[calc(100vh_-_20rem)] xl:h-[calc(100vh_-_13rem)] ${currentMode === "dark"
                            ? ""
                            : "shadow-[0px_4px_20px_rgba(238,238,238,0.5)]"
                            }  overflow-y-auto`}
                    >
                        {!requestComplete ? (
                            <Loader className="h-[calc(100vh_-_26rem)]" />
                        ) : (
                            <table className="w-full rolemasterTable lastBorder text-left">
                                <thead>
                                    <tr>
                                        <th>Role Name</th>
                                        <th>Description</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {RoleData &&
                                        RoleData?.map((elem, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>{elem?.name}</td>
                                                    <td>{elem?.description}</td>
                                                    <td>
                                                        <div className="flex space-x-4">
                                                            {elem?.template_id ==
                                                                0 && (
                                                                    <Link
                                                                        to={`${"/new-role?roleid="}${elem?.role_id
                                                                            }`}
                                                                    >
                                                                        <img
                                                                            src="/images/common/pencil-green.svg"
                                                                            alt="pencil-green"
                                                                            title="Edit"
                                                                            onClick={() =>
                                                                                editrole(
                                                                                    elem?.role_id
                                                                                )
                                                                            }
                                                                        />
                                                                    </Link>
                                                                )}
                                                            {elem?.template_id !==
                                                                0 && (
                                                                    <Link
                                                                        to={`${"/new-role?View-roleid="}${elem?.role_id
                                                                            }`}
                                                                    >
                                                                        <img
                                                                            src="/images/common/eye.svg"
                                                                            title="View"
                                                                            alt="pencil-green"
                                                                            onClick={() =>
                                                                                editrole(
                                                                                    elem?.role_id
                                                                                )
                                                                            }
                                                                        />
                                                                    </Link>
                                                                )}
                                                            <Link
                                                                to={`${"/new-role?Clone-roleid="}${elem?.role_id
                                                                    }`}
                                                            >
                                                                <img
                                                                    src="/images/common/copy-ico.svg"
                                                                    title="Clone"
                                                                    alt="copy-ico"
                                                                />
                                                            </Link>
                                                        </div>
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    {RoleData == null && (
                                        <>
                                            <tr>
                                                <td
                                                    colSpan={12}
                                                    style={{
                                                        textAlign: "center",
                                                    }}
                                                >
                                                    No Datas Found
                                                </td>
                                            </tr>
                                        </>
                                    )}
                                </tbody>
                            </table>
                        )}
                    </Card>
                </Main>
                <Footer />
            </Layout>
        </>
    );
};

export default RoleMaster;
