import axios from "axios";
import authHeader from "./auth-header";
import { checkApiStatus } from "../../utils/helpers";
import {
    INBOX_BODY_VIEW_FAILURE,
    INBOX_BODY_VIEW_REQUEST,
    INBOX_BODY_VIEW_SUCCESS,
    INBOX_BODY_VIEW_RESET,
    INBOX_CHILD_PREVIEW_GET_FAILURE,
    INBOX_CHILD_PREVIEW_GET_REQUEST,
    INBOX_CHILD_PREVIEW_GET_SUCCESS,
    INBOX_EMAIL_MARK_AS_FAILURE,
    INBOX_EMAIL_MARK_AS_REQUEST,
    INBOX_EMAIL_MARK_AS_SUCCESS,
    INBOX_FOLDER_GET_FAILURE,
    INBOX_FOLDER_GET_REQUEST,
    INBOX_FOLDER_GET_SUCCESS,
    INBOX_PREVIEW_GET_FAILURE,
    INBOX_PREVIEW_GET_REQUEST,
    INBOX_PREVIEW_GET_SCROLL_REQUEST,
    INBOX_PREVIEW_GET_SCROLL_SUCCESS,
    INBOX_PREVIEW_GET_SUCCESS,
    POST_SEND_MS_MAIL_REQUEST,
    POST_SEND_MS_MAIL_SUCCESS,
    POST_SEND_MS_MAIL_FAILURE,
    DELETE_MICROSOFT_MAIL_SUCCESS,
    DELETE_MICROSOFT_MAIL_REQUEST,
    DELETE_MICROSOFT_MAIL_FAILURE,
    GET_ATTACHMENT_PREVIEW_SUCCESS,
    GET_ATTACHMENT_PREVIEW_FAILURE,
    GET_ATTACHMENT_PREVIEW_REQUEST,
    DOWNLOAD_ATTACHMENT_FAILURE,
    DOWNLOAD_ATTACHMENT_SUCCESS,
    TOGGLE_MARK_AS_READ,
    DELETE_MICROSOFT_EMAIL_ACTION,
    DELETE_MICROSOFT_CHILD_EMAIL_ACTION,
    COUNT_UPDATE_EMAIL_ACTION,
    TOGGLE_CHILD_MARK_AS_READ,
    INBOX_ONCLICK_READ_ACTION,
    INBOX_ONCLICK_READ_CHILD_ACTION,
} from "../types/types";
import localforage from "localforage";
import { fetchEmails } from "../../utils/helpers";
import { storeDataInLocalForage } from "../../utils/helpers";
import { useSelector } from "react-redux";

const API_URL = process.env?.REACT_APP_NODE_API_URL;

const storeEmails = (id, data, scrollView, dispatch) => {
    // fetchEmails(id)
    //   .then((response) => {
    //     let parseData = [];
    //     if (response?.length > 0) {
    //       parseData = [...response, ...data];
    //     } else {
    //       parseData = data;
    //     }
    //     const uniqueSet = new Set(parseData.map(JSON.stringify));
    //     const uniqueArray = Array.from(uniqueSet).map(JSON.parse);
    //     storeDataInLocalForage(id, uniqueArray);
    //     if (scrollView === "normal" && response?.data?.data?.length !== 0) {
    //       dispatch({
    //         type: INBOX_PREVIEW_GET_SUCCESS,
    //         payload: response?.data?.data,
    //       });
    //       return false;
    //     } else {
    //       dispatch({
    //         type: INBOX_PREVIEW_GET_SCROLL_SUCCESS,
    //         payload: response?.data?.data,
    //       });
    //     }
    //     if (scrollView === "scrollView" && response?.data?.data.length === 0) {
    //       dispatch({
    //         type: INBOX_PREVIEW_GET_FAILURE,
    //       });
    //     }
    //     return parseData;
    //   })
    // .catch(function (error) {
    //   console.log("Error:", error);
    // });
};

export const getInboxFolder = (action) => async (dispatch) => {
    try {
        axios
            .get(`${API_URL}api/getMailFoldersFromLocal`, {
                headers: authHeader(),
            })
            ?.then((res) => {
                dispatch({
                    type: INBOX_FOLDER_GET_REQUEST,
                    payload: res?.data?.data?.value,
                });
                let parseData = [];
                axios
                    .get(`${API_URL}api/getMailFolders`, {
                        headers: authHeader(),
                    })
                    .then((response) => {
                        if (action != "Delete") {
                            localStorage?.setItem(
                                "folderId",
                                response?.data?.data?.value?.[0]?.id
                            );
                        }
                        localStorage?.setItem(
                            "deleteId",
                            response?.data?.data?.value?.[3]?.id
                        );
                        if (response?.data?.data?.value?.length !== 0) {
                            dispatch({
                                type: INBOX_FOLDER_GET_SUCCESS,
                                payload: response?.data?.data?.value,
                            });
                        }
                    })
                    .catch(function (error) {
                        if (error?.response?.status == 401) {
                            checkApiStatus();
                        }

                        dispatch({
                            type: INBOX_FOLDER_GET_FAILURE,
                        });
                    });
            })
            .catch(function (error) {
                if (error?.response.status == 401) {
                    checkApiStatus();
                }
                dispatch({
                    type: INBOX_FOLDER_GET_FAILURE,
                });
            });
    } catch (error) {
        dispatch({
            type: INBOX_FOLDER_GET_FAILURE,
        });
    }
};

export const getInboxPrevView =
    (size, id, time, type, scrollView, emailPrevChildData, isNext) =>
    async (dispatch) => {
        const NextPage = localStorage.getItem("nextpage");
        let localDbLatestDate = "";
        let pageSize = 20;

        try {
            const getInbox = (value) => {
                try {
                    let parseData = [];
                    axios
                        .get(
                            `${API_URL}api/getEmailByFolder?PageSize=${pageSize}&FolderID=${id}&recievedDate=${time}&filterType=${type}`,
                            {
                                headers: authHeader(),
                            }
                        )
                        .then((response) => {
                            let sortedData = null;
                            if (value?.length > 0) {
                                parseData = [...response?.data?.data, ...value];
                                localStorage.setItem(
                                    "nextpage",
                                    type == 1
                                        ? isNext
                                        : response?.data?.nextpage
                                );
                            } else {
                                parseData = response?.data?.data;
                                localStorage.setItem(
                                    "nextpage",
                                    type == 1
                                        ? isNext
                                        : response?.data?.nextpage
                                );
                            }
                            // Using
                            const uniqueSets = new Set();
                            const uniqueArrays = [];

                            parseData?.map((elem) => {
                                const obj = elem;
                                if (!uniqueSets.has(obj?.bodypreview?.id)) {
                                    uniqueSets.add(obj?.bodypreview?.id);
                                    uniqueArrays.push(obj);
                                }
                            });
                            sortedData = [...uniqueArrays].sort((a, b) => {
                                const timeA = new Date(
                                    a?.bodypreview?.receivedDateTime
                                ).getTime();
                                const timeB = new Date(
                                    b?.bodypreview?.receivedDateTime
                                ).getTime();
                                return timeB - timeA;
                            });
                            const responseLatestDate =
                                response?.data?.data?.length == 0
                                    ? ""
                                    : response?.data?.data[
                                          response?.data?.data?.length - 1
                                      ]?.bodypreview?.receivedDateTime;

                            // storeDataInLocalForage(id, uniqueArrays, "normal");

                            if (
                                localDbLatestDate != "" &&
                                responseLatestDate != ""
                                    ? new Date(responseLatestDate) <=
                                      new Date(localDbLatestDate)
                                    : true ||
                                      scrollView === "scrollView" ||
                                      type == 1
                            ) {
                                if (
                                    scrollView === "normal" &&
                                    response?.data?.data?.length !== 0 &&
                                    NextPage == "false"
                                ) {
                                    dispatch({
                                        type: INBOX_PREVIEW_GET_SUCCESS,
                                        payload: {
                                            data: sortedData,
                                            isId: id,
                                            hasMore:
                                                type == 1
                                                    ? isNext
                                                    : response?.data?.nextpage,
                                        },
                                    });
                                    return false;
                                } else {
                                    dispatch({
                                        type: INBOX_PREVIEW_GET_SCROLL_SUCCESS,
                                        payload: {
                                            data: sortedData,
                                            isId: id,
                                            hasMore:
                                                type == 1
                                                    ? isNext
                                                    : response?.data?.nextpage,
                                        },
                                    });
                                }

                                if (
                                    response?.data?.length === 0 &&
                                    (NextPage == "false" ||
                                        NextPage == false) &&
                                    scrollView === "scrollView"
                                ) {
                                    dispatch({
                                        type: INBOX_PREVIEW_GET_FAILURE,
                                    });
                                }
                            } else {
                                dispatch({
                                    type: INBOX_PREVIEW_GET_REQUEST,
                                    payload: {
                                        data: sortedData,
                                        isId: id,
                                    },
                                });
                                pageSize =
                                    pageSize + 20 >= 50 ? 50 : pageSize + 20;
                                time = responseLatestDate;
                                getInbox(sortedData);
                            }
                        })
                        .catch((error) => {
                            if (error?.response.status == 401) {
                                checkApiStatus();
                            }
                            dispatch({
                                type: INBOX_PREVIEW_GET_FAILURE,
                            });
                        });
                } catch (error) {
                    dispatch({
                        type: INBOX_PREVIEW_GET_FAILURE,
                    });
                }
            };
            if (type == 1) {
                getInbox(emailPrevChildData);
            } else if (scrollView === "normal") {
                dispatch({
                    type: INBOX_PREVIEW_GET_REQUEST,
                    payload: { data: [], isId: id },
                });
                axios
                    .get(
                        `${API_URL}api/getEmailsByFolderFromLocal?folderId=${id}`,
                        {
                            headers: authHeader(),
                        }
                    )
                    .then((response) => {
                        dispatch({
                            type: INBOX_PREVIEW_GET_REQUEST,
                            payload: { data: response?.data?.data, isId: id },
                        });
                        localDbLatestDate =
                            response?.data?.data?.length == 0
                                ? ""
                                : response?.data?.data[0]?.bodypreview
                                      ?.receivedDateTime;

                        getInbox(response?.data?.data);
                    });
            } else {
                if (NextPage == "true" || NextPage == true) {
                    dispatch({
                        type: INBOX_PREVIEW_GET_SCROLL_REQUEST,
                        payload: { data: emailPrevChildData, isId: id },
                    });
                }
                getInbox(emailPrevChildData);
            }
        } catch (error) {
            if (error?.response.status == 401) {
                checkApiStatus();
            }
            dispatch({
                type: INBOX_PREVIEW_GET_FAILURE,
            });
        }
    };

export const getInboxChildView = (id) => async (dispatch) => {
    try {
        dispatch({ type: INBOX_CHILD_PREVIEW_GET_REQUEST });
        axios
            .get(`${API_URL}api/getEmailChild?ConversationId=${id}`, {
                headers: authHeader(),
            })
            .then((response) => {
                dispatch({
                    type: INBOX_CHILD_PREVIEW_GET_SUCCESS,
                    payload: response?.data?.data,
                    id,
                });
            });
    } catch (error) {
        if (error?.response?.status == 401) {
            checkApiStatus();
        }
        dispatch({
            type: INBOX_CHILD_PREVIEW_GET_FAILURE,
        });
    }
};
// Toggle Mark Read EMail
export const ToggleMarkasRead = (data) => async (dispatch) => {
    if (data?.type == "normal") {
        dispatch({
            type: TOGGLE_MARK_AS_READ,
            payload: data,
        });
    } else {
        dispatch({
            type: TOGGLE_CHILD_MARK_AS_READ,
            payload: data,
        });
    }
};

// OnClick the unread mail to read action
export const onClickRead = (data) => async (dispatch) => {
    if (data?.type == "normal") {
        dispatch({
            type: INBOX_ONCLICK_READ_ACTION,
            payload: data,
        });
    } else {
        dispatch({
            type: INBOX_ONCLICK_READ_CHILD_ACTION,
            payload: data,
        });
    }
};

// Delete EMail action
export const deleteEmailAction = (id, normal) => async (dispatch) => {
    if (normal == "normal") {
        dispatch({
            type: DELETE_MICROSOFT_EMAIL_ACTION,
            payload: id,
        });
    } else {
        dispatch({ type: DELETE_MICROSOFT_CHILD_EMAIL_ACTION, payload: id });
        dispatch({ type: COUNT_UPDATE_EMAIL_ACTION, payload: normal });
    }
};

// get email body view
export const getInboxBodyView = (id) => async (dispatch) => {
    try {
        dispatch({ type: INBOX_BODY_VIEW_REQUEST });
        axios
            .get(`${API_URL}api/getMsEmailBodyView?MessageID=${id}`, {
                headers: authHeader(),
            })
            .then((response) => {
                dispatch({
                    type: INBOX_BODY_VIEW_SUCCESS,
                    payload: response?.data,
                });
            });
    } catch (error) {
        if (error?.response?.status == 401) {
            checkApiStatus();
        }
        dispatch({
            type: INBOX_BODY_VIEW_FAILURE,
        });
    }
};
// read and unread api call
export const emailMarkasToggle = (data) => async (dispatch) => {
    try {
        dispatch({ type: INBOX_EMAIL_MARK_AS_REQUEST });

        axios
            .post(`${API_URL}api/msMarkEmailAsRead`, data, {
                headers: authHeader(),
            })
            .then((response) => {
                dispatch({
                    type: INBOX_EMAIL_MARK_AS_SUCCESS,
                    payload: response?.data,
                });
                // dispatch(getInboxFolder());
            });
    } catch (error) {
        if (error?.response?.status == 401) {
            checkApiStatus();
        }
        dispatch({
            type: INBOX_EMAIL_MARK_AS_FAILURE,
        });
    }
};
//post new ms mail

export const postSendMsMail = (data) => async (dispatch) => {
    try {
        dispatch({ type: POST_SEND_MS_MAIL_REQUEST });

        axios
            .post(`${API_URL}api/sendMsMail`, data, {
                headers: authHeader(),
            })
            .then((response) => {
                dispatch({
                    type: POST_SEND_MS_MAIL_SUCCESS,
                    payload: response.data,
                });
            })
            .catch(function (error) {
                if (error?.response?.status == 401) {
                    checkApiStatus();
                }
                dispatch({
                    payload: { error: error.message },
                    type: POST_SEND_MS_MAIL_FAILURE,
                });
            });
    } catch (error) {
        dispatch({
            type: POST_SEND_MS_MAIL_FAILURE,
        });
    }
};

//delete ms mail

export const deleteSendMsMail = (data) => async (dispatch) => {
    try {
        dispatch({ type: DELETE_MICROSOFT_MAIL_REQUEST });

        axios
            .post(`${API_URL}api/deleteMsMails`, data, {
                headers: authHeader(),
            })
            .then((response) => {
                dispatch({
                    type: DELETE_MICROSOFT_MAIL_SUCCESS,
                    payload: response.data,
                });
            })
            .catch(function (error) {
                if (error?.response?.status == 401) {
                    checkApiStatus();
                }
                dispatch({
                    payload: { error: error.message },
                    type: DELETE_MICROSOFT_MAIL_FAILURE,
                });
            });
    } catch (error) {
        dispatch({
            type: DELETE_MICROSOFT_MAIL_FAILURE,
        });
    }
};

//GET Attachment preview

export const getAttachmentPreviewCall = (id) => async (dispatch) => {
    try {
        dispatch({ type: GET_ATTACHMENT_PREVIEW_REQUEST });

        axios
            .get(`${API_URL}api/getMsAttachmentList?MessageID=${id}`, {
                headers: authHeader(),
            })
            .then((response) => {
                dispatch({
                    type: GET_ATTACHMENT_PREVIEW_SUCCESS,
                    payload: response.data,
                });
            })
            .catch(function (error) {
                if (error?.response?.status == 401) {
                    checkApiStatus();
                }
                dispatch({
                    payload: { error: error.message },
                    type: GET_ATTACHMENT_PREVIEW_FAILURE,
                });
            });
    } catch (error) {
        dispatch({
            type: GET_ATTACHMENT_PREVIEW_FAILURE,
        });
    }
};

//GET Attachment preview

export const downloadAttachment =
    (attach_id, message_id) => async (dispatch) => {
        try {
            dispatch({ type: GET_ATTACHMENT_PREVIEW_REQUEST });

            axios
                .get(
                    `${API_URL}api/getMsAttachment?MessageID=${message_id}&AttachmentID=${attach_id}`,
                    {
                        headers: authHeader(),
                    }
                )
                .then((response) => {
                    dispatch({
                        type: DOWNLOAD_ATTACHMENT_SUCCESS,
                        payload: response.data,
                    });
                })
                .catch(function (error) {
                    if (error?.response?.status == 401) {
                        checkApiStatus();
                    }
                    dispatch({
                        payload: { error: error.message },
                        type: DOWNLOAD_ATTACHMENT_FAILURE,
                    });
                });
        } catch (error) {
            dispatch({
                type: DOWNLOAD_ATTACHMENT_FAILURE,
            });
        }
    };
