import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Modal from "../../components/UI/Modal/Modal";
import { cartToggleAction } from "../../redux/actions/filter";
import Button from "../../components/Button";
import Input from "../../components/Input";
import UseForm from "../../Hooks/useForm";
import { invoiceCountTotal, InvoiceList, invoiceVendorList } from "../../redux/actions/invoice";
import { getCurrentUser } from "../../redux/actions/auth";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { useState, useEffect, useContext } from "react";
import moment from "moment";
import { ClickAwayListener, Fade, MenuItem, Paper, Popper, Select, TextField } from "@mui/material";
import { useLocation } from "react-router-dom";
import { orgId, userId } from "../../Hooks/userId/Index";
import { ThemeContext } from "../../Hooks/Themes/Themeprovider";
import { parse } from "date-fns";

const InvoiceProcessFilterModal = (props) => {
    const filteredApproval = localStorage.getItem("filteredApproval");
    const { handleSubmit, handleChange, InitialState, values, setValues } = UseForm(submit);
    const dispatch = useDispatch();
    const searchValue = localStorage.getItem("invSearch");
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const cartClose = (e) => {
        e.preventDefault();
        dispatch(cartToggleAction(false));
    };
    const currentUser = getCurrentUser();
    const location = useLocation();
    const orgId = localStorage.getItem("orgId") || 1;
    let { data: workflowFilter } = useSelector((state) => state?.getWorkflowFilterState);
    const parseId = +workflowFilter?.datalist?.find((item) => item?.master_refid == (location.pathname === "/approve" ? 7 : 2))?.id;

    // console.log("Filter:", options);
    function submit() {
        props?.setfilterStatus(values?.status_id);
        const reqObj = {
            invoice_no: "" + values?.invoice_no,
            status_type:
                location.pathname === "/invoice"
                    ? 4
                    : location.pathname === "/exception"
                        ? 1
                        : location.pathname === "/processed"
                            ? 2
                            : location.pathname === "/approve"
                                ? 3
                                : "",
            status_id:
                values?.status_id == "" &&
                    (location.pathname === "/invoice" || location.pathname === "/exception" || location.pathname === "/processed")
                    ? [parseId]
                    : location.pathname === "/approve" && values?.status_id == ""
                        ? [parseId]
                        : [+values?.status_id],
            org_group: currentUser?.organisation,
            vendor_id: "" + values?.vendor_name,
            invoice_date_from: null,
            invoice_date_to: null,
            search_text: searchValue || "",
            order_column: "",
            order_by: 1,
            pg_no: 1,
            rec_cnt: 10,
            organisation: +orgId,
            isflagged: values?.status_id == 2 || values?.status_id == 7 ? true : false,
        };
        if (fromDate && toDate) {
            reqObj.invoice_date_from = moment(new Date(fromDate)).format("YYYY-MM-DD");
            reqObj.invoice_date_to = moment(new Date(toDate)).format("YYYY-MM-DD");
        }
        // alert(reqObj.status_id);
        // props.filterSubmit(reqObj);
        const reqObjCount = {
            status_type:
                location.pathname === "/invoice"
                    ? 4
                    : location.pathname === "/exception"
                        ? 1
                        : location.pathname === "/processed"
                            ? 2
                            : location.pathname === "/approve"
                                ? 3
                                : "",
            status_id:
                values?.status_id == "" && (location.pathname === "/invoice" || location.pathname === "/exception")
                    ? [parseId]
                    : location.pathname === "/approve" && values?.status_id == ""
                        ? [parseId]
                        : [+values?.status_id],
            invoice_date_from: null,
            invoice_date_to: null,
            invoice_no: "" + values?.invoice_no,
            vendor_id: "" + values?.vendor_name,
            search_text: searchValue || "",
            organisation: +orgId,
            isflagged: values?.status_id == 2 || values?.status_id == 7 ? true : false,
        };
        if (fromDate && toDate) {
            reqObjCount.invoice_date_from = moment(new Date(fromDate)).format("YYYY-MM-DD");
            reqObjCount.invoice_date_to = moment(new Date(toDate)).format("YYYY-MM-DD");
        }
        props.filterSubmit(reqObj);
        dispatch(invoiceCountTotal(reqObjCount));
        dispatch(cartToggleAction(false));
        dispatch(InvoiceList(reqObj));
    }
    const handleClear = (e) => {
        e.preventDefault();
        props.filterSubmit(null);
        setValues(InitialState);
        setFromDate("");
        setToDate("");
    };
    const Onrefresh = props?.Onrefresh;
    useEffect(() => {
        props.filterSubmit(null);
        if (props?.Onrefresh) {
            props.filterSubmit(null);
            setValues(InitialState);
            setFromDate("");
            setToDate("");
            setTimeout(() => {
                props?.setOnrefresh(false);
            }, 500);
        }
        setValues(InitialState);
        setFromDate("");
        setToDate("");
    }, [location, props?.Onrefresh]);
    useEffect(() => {
        if (filteredApproval == "true") {
            values.status_id = [3];
            // setFromDate(new Date());
            // setToDate(new Date());
            localStorage.setItem("filteredApproval", false);
        } else {
            values.status_id = values.status_id;
        }
    }, []);
    const handleValue = (newValue) => {
        if (newValue) {
            setFromDate(newValue);
        }
    };
    const handleToDate = (toDate) => {
        if (toDate) {
            setToDate(toDate);
        }
    };
    const [anchorEl, setAnchorEl] = useState(null);
    const [open, setOpen] = useState(false);
    const [placement, setPlacement] = useState();
    const lastDateOfYear = `12/31/${new Date().getFullYear()}`;
    const handleClickAway = () => {
        setOpen(false);
    };

    const handleClick = (newPlacement) => (event) => {
        setAnchorEl(event.currentTarget);
        setOpen((prev) => placement !== newPlacement || !prev);
        setPlacement(newPlacement);
    };

    const AccessIcon = () => <img src="/images/common/date-ico.svg" alt="date-ico" />;
    const { vebdorlist } = useSelector((state) => state?.InvoiceVendorListState);
    const { currentMode } = useContext(ThemeContext);
    // useEffect(() => {
    //     let bodys = document.querySelector("body").classList;
    //     location.pathname === "/profile" &&
    //         bodys.remove(`bg-${currentMode}-base`);
    // }, [dispatch]);
    const dropdownColor = currentMode === "dark" ? "#333333" : `white`;
    const dropdownText = currentMode === "dark" ? "#ECECEC" : `#555555`;
    return (
        <Modal cartClose={cartClose} className={props?.className}>
            <span onClick={cartClose} className="p-[6.5px] cursor-pointer absolute right-[400px] bg-light-10">
                <img src="/images/common/whiteClose.svg" alt="whiteClose" />
            </span>
            <form onSubmit={handleSubmit}>
                <div
                    className={`max-w-[400px] ml-auto bg-${currentMode}-card overflow-y-auto transition duration-150 ease-out flex-col flex justify-between h-screen max-h-[-webkit-fill-available]`}
                >
                    <div>
                        <div className="flex justify-between px-[30px] py-[23px] items-center">
                            <h3 className="text-lg font-interSb">More Filters</h3>
                        </div>
                        <div className={`flex flex-col justify-between px-[30px] py-[23px]  border-t-[1px] border-${currentMode}-600`}>
                            <span className="block text-light-60 mb-2">Invoice Number</span>
                            <Input
                                value={values?.invoice_no}
                                onChange={handleChange}
                                name="invoice_no"
                                type="search"
                                className={`rounded-[8px] py-[7px] bg-light-230 border-${currentMode}-840 border
                                       px-4`}
                                placeholder="Search invoice"
                            />
                        </div>
                        <div className={`flex flex-col justify-between px-[30px] py-[23px]  border-t-[1px] border-${currentMode}-600`}>
                            <span className="block text-light-60">Vendor</span>
                            <Select
                                name="vendor_name"
                                value={values?.vendor_name === "" ? "default" : values?.vendor_name}
                                className="font-interR Org"
                                defaultValue="default"
                                onChange={handleChange}
                                MenuProps={{
                                    sx: {
                                        "& .MuiMenu-paper": {
                                            backgroundColor: dropdownColor,
                                            color: dropdownText,
                                            maxWidth: "200px",
                                        },
                                        "& .MuiMenuItem-root:hover": {
                                            backgroundColor: "rgba(218, 249, 244, 0.3)",
                                        },
                                        "& .Mui-selected": {
                                            backgroundColor: "rgba(218, 249, 244, 0.3) !important",
                                        },
                                    },
                                }}
                                SelectDisplayProps={{
                                    style: {
                                        paddingTop: 2,
                                        display: "flex",
                                        paddingBottom: 2,
                                        paddingLeft: "0 !important",
                                        border: 0,
                                        color: currentMode === "dark" ? `#ECECEC` : `#555555`,
                                        paddingRight: "30px",
                                        marginLeft: "-15px",
                                    },
                                }}
                                displayEmpty
                                IconComponent={() => (
                                    <img
                                        className="absolute right-[15px] opacity-[.5] pointer-events-none"
                                        src={currentMode !== "dark" ? `/images/header/chevronDown.svg` : `/images/header/chevronDown-white.svg`}
                                        alt="chevronDown"
                                    />
                                )}
                            >
                                <MenuItem value="default" sx={{ display: "none" }}>
                                    Select Vendor
                                </MenuItem>
                                {vebdorlist?.length !== 0 &&
                                    vebdorlist?.map((elem) => {
                                        return (
                                            <MenuItem value={elem?.vendor_name} title={elem?.vendor_name}>
                                                {elem?.vendor_name}
                                            </MenuItem>
                                        );
                                    })}
                            </Select>
                        </div>
                        <div className={`flex  border-t-[1px] border-${currentMode}-600 flex-col justify-between px-[30px] py-[23px]`}>
                            <span className="block text-light-60">Invoice Period</span>
                            <Button className="font-interR mr-auto" onClick={handleClick("bottom-start")}>
                                {fromDate && toDate
                                    ? moment(new Date(fromDate)).format("DD-MM-YYYY") + " to " + moment(new Date(toDate)).format("DD-MM-YYYY")
                                    : "Select data range"}
                            </Button>
                            <ClickAwayListener mouseEvent="onMouseDown" touchEvent="onTouchStart" onClickAway={handleClickAway}>
                                <Popper open={open} anchorEl={anchorEl} placement={placement} className="z-[111] filterPop" transition>
                                    {({ TransitionProps }) => (
                                        <Fade {...TransitionProps} timeout={350}>
                                            <Paper>
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <ul className={`space-y-4 px-[15px] py-4 bg-${currentMode}-card  border-${currentMode}-700`}>
                                                        <li className="flex justify-between">
                                                            <DesktopDatePicker
                                                                selectsStart
                                                                inputFormat="DD/MM/YYYY"
                                                                className="border-0"
                                                                components={{
                                                                    OpenPickerIcon: AccessIcon,
                                                                }}
                                                                in
                                                                value={fromDate}
                                                                label="From"
                                                                minDate={new Date(1999, 12)}
                                                                maxDate={new Date(lastDateOfYear)}
                                                                name="invoice_date_from"
                                                                onChange={handleValue}
                                                                renderInput={(params) => (
                                                                    <TextField
                                                                        sx={{
                                                                            "& .MuiInputBase-input": {
                                                                                color: `${currentMode !== "dark" ? `#555555` : `#ECECEC`}`,
                                                                            },
                                                                        }}
                                                                        // placeholder={params.inputProps?.placeholder?.replace(
                                                                        //     "From"
                                                                        // )}
                                                                        {...params}
                                                                    />
                                                                )}
                                                            />
                                                        </li>
                                                        <li className="flex justify-between">
                                                            <DesktopDatePicker
                                                                selectsEnd
                                                                className="border-0"
                                                                inputFormat="DD/MM/YYYY"
                                                                name="invoice_date_to"
                                                                components={{
                                                                    OpenPickerIcon: AccessIcon,
                                                                }}
                                                                value={toDate}
                                                                onChange={handleToDate}
                                                                label="To"
                                                                minDate={fromDate}
                                                                maxDate={new Date(lastDateOfYear)}
                                                                renderInput={(params) => (
                                                                    <TextField
                                                                        sx={{
                                                                            "& .MuiInputBase-input": {
                                                                                color: `${currentMode !== "dark" ? `#555555` : `#ECECEC`}`,
                                                                            },
                                                                        }}
                                                                        placeholder={params.inputProps?.placeholder?.replace("To")}
                                                                        {...params}
                                                                    />
                                                                )}
                                                            />
                                                            {/* <img
                            src="/images/common/date-ico.svg"
                            alt="date-ico"
                          /> */}
                                                        </li>
                                                    </ul>
                                                </LocalizationProvider>
                                            </Paper>
                                        </Fade>
                                    )}
                                </Popper>
                            </ClickAwayListener>
                        </div>
                        <hr className={`border-${currentMode}-600`} />
                        {location.pathname !== "/exception" ? (
                            <div className="flex flex-col justify-between px-[30px] py-[23px]">
                                <span className="block text-light-60">Status</span>
                                <Select
                                    name="status_id"
                                    className="font-interR Org"
                                    value={values?.status_id === "" ? parseId : values?.status_id}
                                    onChange={handleChange}
                                    id=""
                                    MenuProps={{
                                        sx: {
                                            "& .MuiMenu-paper": {
                                                backgroundColor: dropdownColor,
                                                color: dropdownText,
                                            },
                                            "& .MuiMenuItem-root:hover": {
                                                backgroundColor: "rgba(218, 249, 244, 0.3)",
                                            },
                                            "& .Mui-selected": {
                                                backgroundColor: "rgba(218, 249, 244, 0.3) !important",
                                            },
                                        },
                                    }}
                                    SelectDisplayProps={{
                                        style: {
                                            paddingTop: 2,
                                            display: "flex",
                                            paddingBottom: 2,
                                            paddingLeft: "0 !important",
                                            marginLeft: "-15px",
                                            border: 0,
                                            color: currentMode === "dark" ? `#ECECEC` : `#555555`,
                                            paddingRight: "30px",
                                        },
                                    }}
                                    displayEmpty
                                    IconComponent={() => (
                                        <img
                                            className="absolute right-[15px] opacity-[.5] pointer-events-none"
                                            src={currentMode !== "dark" ? `/images/header/chevronDown.svg` : `/images/header/chevronDown-white.svg`}
                                            alt="chevronDown"
                                        />
                                    )}
                                >
                                    {workflowFilter?.datalist
                                        ?.filter((item) => (location.pathname === "/approve" ? item?.master_refid != 2 : item))
                                        ?.map((ele, index) => {
                                            return (
                                                <MenuItem key={index} value={ele?.id}>
                                                    {ele?.name}
                                                </MenuItem>
                                            );
                                        })}
                                </Select>
                            </div>
                        ) : null}
                    </div>
                    <div className={`flex border-t-[1px] border-${currentMode}-600 space-x-3 items-center px-[30px] py-[23px]`}>
                        <Button
                            title="Done"
                            onClick={submit}
                            type="submit"
                            className="bg-light-20 flex text-base font-interR py-[.5rem] px-[1.24rem] rounded-[8px] text-white"
                        >
                            {`Done`}
                        </Button>
                        <Button
                            type="button"
                            title="Clear All"
                            onClick={handleClear}
                            className="bg-light-240 flex text-base font-interR py-[.5rem] px-[1.7rem] rounded-[8px] text-light-40 closeModal"
                        >
                            Clear All
                        </Button>
                        {/* <Button  className="text-xs rounded-[5px] bg-light-240 px-5 py-1">Clear All</Button> */}
                    </div>
                </div>
            </form>
        </Modal>
    );
};

export default InvoiceProcessFilterModal;
