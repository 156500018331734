import { useContext, useState, useEffect } from "react";
import Modal from "../../components/UI/Modal/Modal";
import Input from "../../components/Input";
import Button from "../../components/Button";
import Card from "../../components/UI/Card";
import { ThemeContext } from "../../Hooks/Themes/Themeprovider";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TextField } from "@mui/material";
import { AddUpdateExchangeRate, ExchangeRateListAction } from "../../redux/actions/exchange-rate";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { ADD_UPDATE_EXCHANGE_RATE_RESET } from "../../redux/types/types";
import Swal from "sweetalert2";
import moment from "moment";

const EditModal = (props) => {
    const { currentMode } = useContext(ThemeContext);
    const [exchangeRate, setExchangeRate] = useState(props?.EditOpen?.exchangeRate);
    const CreateData = useSelector((state) => state?.addUpdateExchangeRateState);
    const [validFrom, setValidFrom] = useState(null);
    const lastDateOfYear = `12/31/${new Date().getFullYear()}`;
    const modalTitle = `${props?.FindCurrency} to ${props?.currencyCode} Exchange Rate`;
    const dispatch = useDispatch();
    const [exchangeError, setExchangerror] = useState({
        message: "",
    });
    const DisableSave = exchangeRate == props?.EditOpen?.exchangeRate && validFrom == props?.EditOpen?.ValidFrom;

    useEffect(() => {
        // if (id !== null && tableData[id]) {
        //     const { exchangeRate, validFrom } = tableData[id];
        //     setExchangeRate(exchangeRate || "");
        //     setValidFrom(validFrom || null);
        // }
        setExchangeRate(props?.EditOpen?.exchangeRate);
        setValidFrom(props?.EditOpen?.ValidFrom);
    }, [props]);
    const AccessIcon = () => <img src="/images/invoice/calendar.svg" alt="calendar" className="convertWhite" />;

    const handleValidFromChange = (newValidFrom) => {
        setValidFrom(newValidFrom?.$d);
    };
    const updatedCurrency = {
        validfrom: moment(new Date(validFrom))?.format("YYYY-MM-DD") || "",
        exchangerate: exchangeRate,
        exchangerateid: props?.EditOpen?.Id,
        fromcurrency: props?.EditOpen?.fromCurrency,
        tocurrency: props?.EditOpen?.ToCurrency,
    };
    const [save, setSave] = useState(false);
    const handleSave = () => {
        setSave(true);

        // handleEditCurrency(id, updatedCurrency);
        // setExchangeRate("");
        // setValidFrom(null);
        // editModalClose();
        if (!exchangeRate) {
            setExchangerror({
                message: "Exchange Rate is Required or Invalid!",
            });
        } else {
            setExchangerror({
                message: "",
            });
            dispatch(AddUpdateExchangeRate(updatedCurrency));
        }
    };
    const ModalClose = () => {
        setExchangeRate(props?.EditOpen?.ExchangeRate);
        setValidFrom(props?.EditOpen?.ValidFroms);
        props?.editModalClose();
        setExchangerror({
            message: "",
        });
        dispatch({ type: ADD_UPDATE_EXCHANGE_RATE_RESET });
    };
    const handleSuccess = () => {
        Swal.fire({
            text: "Exchange rate is updated successfully!",
            icon: "success",
            closeOnClickOutside: false,
            showCloseButton: true,
            button: "Ok",
        }).then((confirm) => {
            if (confirm) {
                ModalClose();
                dispatch({ type: ADD_UPDATE_EXCHANGE_RATE_RESET });
                dispatch(ExchangeRateListAction(props?.currency));
                setSave(false);
            }
        });
    };
    const handleError = () => {
        Swal.fire({
            text: CreateData?.error?.error,
            icon: "error",
            closeOnClickOutside: false,
            showCloseButton: true,
            button: "Ok",
        }).then((confirm) => {
            if (confirm) {
                dispatch({ type: ADD_UPDATE_EXCHANGE_RATE_RESET });
            }
        });
    };
    if (CreateData?.requestComplete && updatedCurrency?.exchangerateid !== 0 && updatedCurrency?.exchangerateid !== "" && save) {
        handleSuccess();
    } else if (CreateData?.error) {
        handleError();
    }
    return (
        <Modal editModalOpen={props?.editModalOpen} editModalClose={ModalClose} center="center" className={`${props?.className}`}>
            <Card>
                <div className="fixed z-10 inset-0 overflow-y-auto flex items-center justify-center min-h-screen px-4">
                    {/* Modal */}
                    <div
                        className={`flex flex-col relative  min-w-[460px] xl:ml-[450px] min-h-[320px] md:mx-auto bg-${currentMode}-card text-${currentMode}-copy border-${currentMode}-700 px-[25px] pb-[50px] rounded-[10px] ${currentMode === "dark" ? "" : "shadow-[0px_4px_20px_rgba(238,238,238,0.5)]"
                            }`}
                    >
                        {/* Title and Close Button */}
                        <div className={`flex justify-between items-center border-b border-${currentMode}-700 py-2 absolute inset-x-0`}>
                            <p className="text-base font-bold ml-8 mt-2 pb-1">{modalTitle}</p>
                            <Button className="text-gray-500 hover:text-gray-700 mr-8 mt-2" onClick={props?.editModalClose}>
                                <img src="/images/common/closeBlk.svg" alt="closeBlk" className="convertWhite w-3.5 h-3.5 " />
                            </Button>
                        </div>
                        {/* Dropdown and Text fields */}
                        <div className="px-2 mt-[50px]">
                            <div className="mt-[20px]">
                                <span className={`block text-sm font-semibold pb-3 flex-auto`}>
                                    <span className="text-[red] ml-1 mr-2">*</span>
                                    Date
                                </span>
                                <div className="datePic" title="Calendar">
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DesktopDatePicker
                                            inputFormat={props?.userDateFormat?.toUpperCase()}
                                            className={`border-${currentMode}-1390 h-[45px]`}
                                            components={{
                                                OpenPickerIcon: AccessIcon,
                                            }}
                                            disableFuture={true}
                                            value={validFrom}
                                            name="validFrom"
                                            minDate={props?.EditOpen?.ValidFrom}
                                            maxDate={new Date()}
                                            onChange={handleValidFromChange}
                                            renderInput={(params) => (
                                                <TextField
                                                    value={validFrom}
                                                    sx={{
                                                        "& .MuiInputBase-input": {
                                                            color: `${currentMode === "dark" ? "white" : "black"} !important`,
                                                        },
                                                    }}
                                                    fullWidth
                                                    placeholder={params.inputProps?.placeholder?.replace("From")}
                                                    {...params}
                                                    inputProps={{
                                                        ...params.inputProps,
                                                        readOnly: true,
                                                    }}
                                                />
                                            )}
                                        />
                                    </LocalizationProvider>
                                </div>
                            </div>
                            <div className="mt-[20px]">
                                <span className={`block text-sm font-semibold pb-3 flex-auto`}>
                                    <span className="text-[red] mr-2">*</span>
                                    Exchange Rate
                                </span>
                                <input
                                    id="exchangeRate"
                                    type="number"
                                    value={exchangeRate}
                                    placeholder="Enter exchange rate"
                                    onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                                    className={`appearance-none bg-transparent border border-${currentMode}-1390 rounded-lg w-full py-3 px-4 leading-tight focus:outline-none focus:shadow-outline`}
                                    onChange={(e) => setExchangeRate(e.target.value)}
                                />
                                {exchangeError?.message && <p className="text-light-50 text-xs">{exchangeError?.message}</p>}
                            </div>
                        </div>
                        {/* Buttons */}
                        <div className="flex justify-left pt-6 pl-4 space-x-3  mt-[10px] mb-[-10px] ">
                            <Button
                                className="border-transparent approve-step border-440 w-[103px] h-[40px] p-5 bg-light-20 rounded-[8px] text-base flex justify-center items-center border-[1px] text-white"
                                onClick={handleSave}
                                disabled={CreateData?.fetching || DisableSave}
                            >
                                Save
                            </Button>
                            <Button
                                className=" border-transparent approve-step border-440 !font-interR w-[103px] h-[40px] p-5 bg-gray-200 rounded-[8px] text-base flex justify-center items-center border-[1px] text-black"
                                onClick={ModalClose}
                            >
                                Cancel
                            </Button>
                        </div>
                    </div>
                </div>
            </Card>
        </Modal>
    );
};

export default EditModal;
