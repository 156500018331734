import { Input } from "@mui/material";
import Modal from "../../../components/UI/Modal/Modal";
import Button from "../../../components/Button";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ReassignInvoice } from "../../../redux/actions/invoice";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { POST_INVOICE_REASSIGN_RESET } from "../../../redux/types/types";
import { decryptId, encryptId } from "../../../utils/helpers";
import { useLocation } from "react-router-dom";

const ToReassign = (props) => {
    const { apiProps, changeStatus, statusName } = props;
    const navigate = useNavigate();
    let isreassign = props?.isOpened;
    const param = new URLSearchParams(window.location.search);
    const Reassign = useSelector((state) => state?.InvoiceReassignState);
    const { data, fetching: statusUpdateLoading, requestComplete, error } = useSelector((state) => state?.postWorkFlowStatusChangeState);

    if (isreassign) {
        var textarea = document?.getElementById("myTextarea");
        textarea?.focus();
    }
    const location = useLocation();
    const [errormsg, seterrormsg] = useState({ message: "" });
    const urlData = location?.search?.slice(1)?.replace(/\n\s+/g, "\n")?.trim();
    const urlObj =
        location.pathname === "/mobilization-request" || location.pathname === "/adhoc-advance-request" || location.pathname === "/retention-release" || location.pathname === "/miscellaneous-request"
            ? []
            : JSON.parse(decryptId(urlData));
    let invNumber = urlObj?.inv;
    let pageType = urlObj?.type;
    const orgId = localStorage.getItem("orgId");
    const disptach = useDispatch();
    const [isremarks, setRemarks] = useState("");
    const submit = () => {
        if (isremarks == "") {
            seterrormsg({
                message: "Add any Remarks to reassign",
            });
        } else if (/^\s*$/.test(isremarks)) {
            seterrormsg({
                message: "Add any Reamarks to reassign",
            });
        } else {
            seterrormsg({
                message: "",
            });

            const data = {
                ...apiProps,
                remarks: isremarks,
            };
            changeStatus(data);
        }
    };
    if (Reassign?.requestComplete) {
        Swal.fire({
            text: "Invoice is Reassigned Successfully!",
            icon: "success",
            closeOnClickOutside: false,
            showCloseButton: true,
            buttons: {
                confirm: true,
            },
        }).then((confirm) => {
            if (confirm) {
                disptach({ type: POST_INVOICE_REASSIGN_RESET });
                navigate(
                    pageType == 1
                        ? "/invoice"
                        : pageType == 2
                            ? "/exception"
                            : pageType == 3
                                ? "/approve"
                                : location.pathname == "/mobilization-request"
                                    ? "/advance-list"
                                    : location.pathname == "/miscellaneous-request" ? "/miscellaneous-table" : null
                );
            }
        });
    } else if (Reassign?.error?.message) {
        Swal.fire({
            text: "Unable To Reassign! ",
            icon: "warning",
            closeOnClickOutside: false,
            showCloseButton: true,
            buttons: {
                confirm: true,
            },
        }).then((confirm) => {
            if (confirm) {
                disptach({ type: POST_INVOICE_REASSIGN_RESET });
            }
        });
    }

    const cartClose = () => {
        props?.handleClose();
        seterrormsg({ message: "" });
        setRemarks("");
    };
    return (
        <>
            <Modal cartClose={cartClose} center="center" className={`${props?.className}`}>
                <div className="p-[20px] max-w-[600px] mx-auto text-center relative bg-white border-[rgba(223,225,226,0.56)] rounded-[10px] shadow-[0px_4px_20px_rgba(238,238,238,0.5)]">
                    <span onClick={cartClose} title="Close" className="cursor-pointer w-full mb-5 block text-end">
                        <img className="ml-auto" src="/images/common/closeBlk.svg" alt="closeBlk" />
                    </span>
                    <>
                        <div>
                            <p className="font-interB mb-[40px] text-black">
                                {`Remarks for Reassigning the ${location.pathname == "/mobilization-request" ? "Request" : "Invoice"
                                    } to Previous Status: ` + statusName}
                            </p>
                            <textarea
                                id="myTextarea"
                                className="border-[1px] focus-within:outline-0 px-4 w-[500px] h-[150px] py-[4px] border620 bg-white text-black rounded-[8px] shadow-[0px_4px_20px_rgba(238,238,238,0.5)] resize-none"
                                name="remarks"
                                placeholder="Any remarks to be added?"
                                maxLength={250}
                                value={isremarks}
                                onChange={(e) => setRemarks(e.target.value)}
                            />
                            {errormsg?.message && <p className="text-light-50 text-xs">{errormsg?.message}</p>}
                        </div>
                        <div className="  flex items-center justify-center mx-[-35px] px-[35px] py-5 border-[rgba(236,236,236,0.88)] mt-6">
                            <div className="space-x-2 flex">
                                <Button
                                    title="Send"
                                    type="submit"
                                    onClick={submit}
                                    disabled={statusUpdateLoading}
                                    className="bg-light-20 w-[103px]  flex items-center justify-center text-base font-interR py-[.5rem] px-[1.24rem] rounded-[8px] text-white"
                                >
                                    {`Send`}
                                </Button>

                                <Button
                                    type="button"
                                    title="To Cancel"
                                    onClick={cartClose}
                                    className="bg-light-240 flex text-base font-interR py-[.5rem] px-[1.7rem] rounded-[8px] text-light-40 closeModal"
                                >
                                    Cancel
                                </Button>
                            </div>
                        </div>
                    </>
                </div>
            </Modal>
        </>
    );
};

export default ToReassign;
