import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import Button from "../../components/Button";
import Input from "../../components/Input";
import Container from "../../components/UI/Container";
import UseForm from "../../Hooks/useForm";
import { setPasswordRequest } from "../../redux/actions/auth";

const Card = (props) => (
    <div className="bg-white px-[6.25rem] py-[5.125rem] shadow-[0px_4px_20px_rgba(238,238,238,0.501967)] rounded-[10px] border-[1px] border-[#dfe1e28f]">
        {props?.children}
    </div>
);

const SetPassword = () => {
    const { handleChange, handleSubmit, values, errors } = UseForm(submit);
    const dispatch = useDispatch();
    function submit() {
        // dispatch(
        //   setPasswordRequest({
        //     emid: values?.emid,
        //     password: values?.password
        //   })
        // );
    }
    return (
        <>
            <Container className="mt-10">
                <Link
                    to={"//https://www.myiq.ai/"}
                    target="_blank"
                    className="mb-10 block"
                >
                    <img src="/images/logo/MyiqFinal.svg" alt="logoiq" />
                </Link>
                <div className="flex justify-center">
                    <div className="basis-[44%]">
                        <Card className="w-[34.375rem] h-[31.25rem]">
                            <h4 className="font-interSb text-center text-2xl mb-[2.813rem]">
                                Set your password
                            </h4>
                            <form action="" onSubmit={handleSubmit}>
                                <div className="form-control">
                                    <label
                                        htmlFor=""
                                        className="text-light-40 font-interR text-sm mb-2"
                                    >
                                        <span className="text-[red] mr-1">
                                            *
                                        </span>{" "}
                                        Password
                                    </label>
                                    <Input
                                        className="border-[1px] border-[rgba(85,85,85,.3)] rounded-[8px] px-[20px] py-3 w-full"
                                        type="password"
                                        onChange={handleChange}
                                        placeholder="&bull; &bull; &bull; &bull; &bull; &bull;"
                                        name="password"
                                    />
                                </div>
                                <div className="form-control">
                                    <label
                                        htmlFor=""
                                        className="text-light-40 text-sm font-interR mb-2"
                                    >
                                        <span className="text-[red] mr-1">
                                            *
                                        </span>{" "}
                                        Confirm Password
                                    </label>
                                    <Input
                                        className="border-[1px] border-[rgba(85,85,85,.3)] rounded-[8px] px-[20px] py-3 w-full"
                                        type="password"
                                        onChange={handleChange}
                                        placeholder="&bull; &bull; &bull; &bull; &bull; &bull;"
                                        name="confirmpassword"
                                    />
                                </div>
                                <Button className="w-full rounded-[8px] text-white font-interSb text-lg bg-light-20 py-[14px]">
                                    Save password
                                </Button>
                            </form>
                        </Card>
                    </div>
                </div>
            </Container>
        </>
    );
};

export default SetPassword;
