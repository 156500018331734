import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from "recharts";
import { ThemeContext } from "../../../../Hooks/Themes/Themeprovider";
import { useContext } from "react";
import { extractTextFromHTML } from "../../../../utils/helpers";

const CustomLinechart = (props) => {
    const data = props?.data;
    const { currentMode } = useContext(ThemeContext);

    const dataKeys = data && Object.keys(data?.[0]);
    const textColor = currentMode === "dark" ? `#ECECEC` : `#000`;
    const CustomizedAxisTick = ({ x, y, payload }) => {
        const maxLength = data?.length > 5 ? 2 : 10; // Maximum length for label
        const Value = extractTextFromHTML(payload?.value)
        const text = Value?.length > maxLength ? Value.substring(0, maxLength) + ".." : Value;


        return (
            <g transform={`translate(${x},${y})`}>
                <text x={0} y={0} dy={16} style={{ fontSize: "13px" }} textAnchor="middle" fill={textColor} className="xAxisLabel">
                    {text}
                </text>
            </g>
        );
    };
    const CustomTooltip = ({ active, payload }) => {
        if (active && payload && payload?.length) {
            const entry = payload[0]?.payload;

            return (
                <div className={` py-3 px-5 border recharts-default-tooltip rounded-[8px]`}>
                    <p className="label">{`${extractTextFromHTML(entry?.[dataKeys?.[1]])} : ${entry?.[dataKeys?.[0]]}`}</p>
                </div>
            );
        }
    };

    return (
        <ResponsiveContainer width="100%" height={380} className="pb-10">
            <LineChart
                data={data}
                margin={{
                    top: -17,
                    right: 35,
                    left: 5,
                    bottom: 20,
                }}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey={dataKeys?.[1]} tick={<CustomizedAxisTick />} />
                <YAxis />
                <Tooltip content={<CustomTooltip />} />
                <Legend verticalAlign="top" height={36} iconType={"square"} align="right" />
                <Line type="monotone" dataKey={dataKeys?.[0]} stroke="#a21231" />
            </LineChart>
        </ResponsiveContainer>
    );
};

export default CustomLinechart;
