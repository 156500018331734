import { FormControl, MenuItem, Select } from "@mui/material";
import Button from "../../../components/Button";
import Input from "../../../components/Input";
import Card from "../../../components/UI/Card";
import Main from "../../../components/UI/Main";
import Layout from "../../../Hoc/Layout";
import Footer from "../../../organisms/Footer";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect, useContext } from "react";

import { GetInvoiceIdConfig, InvoiceIdConfig } from "../../../redux/actions/invoice";
import { Link } from "react-router-dom";
import SuccessMessageBox from "../../../components/UI/Alert/sucess";
import MessageBox from "../../../components/UI/Alert";
import Loader from "../../../components/Loader";
import { INVOICE_ID_CONFIG_RESET } from "../../../redux/types/types";
import { stringify } from "@firebase/util";
import { ThemeContext } from "../../../Hooks/Themes/Themeprovider";
import { listOrganisationDropDown } from "../../../redux/actions/organisation";

const IdConfig = () => {
    const { currentMode, toggleMode } = useContext(ThemeContext);
    const orgId = localStorage.getItem("orgId");
    const succesMsg = "Updated successfully";
    const errormsg = "Something went wrong please contact admin";
    const InvoiceConfig = useSelector((state) => state?.GetInvIdConfigState);
    const orgData = useSelector((state) => state?.ListorganisationDropDownState);
    const dataList = orgData.viewData;
    const { data: ListData } = InvoiceConfig;
    const [documentGroup, setDcoumetGroup] = useState(1);

    // const OrgInfo = ListData?.data;
    const FilterInvList = ListData?.datalist?.data?.filter((el) => el?.master_id == 1);
    const AdvanceList = ListData?.datalist?.data?.filter((el) => el?.master_id == 2);
    const MiscList = ListData?.datalist?.data?.filter((el) => el?.master_id == 3);
    const RetentionList = ListData?.datalist?.data?.filter((el) => el?.master_id == 4);
    const Filteredlist =
        documentGroup == 1
            ? FilterInvList?.[0] || FilterInvList
            : documentGroup == 2
            ? AdvanceList?.[0] || AdvanceList
            : documentGroup == 3
            ? MiscList?.[0] || MiscList
            : RetentionList?.[0] || RetentionList;
    const OrgInfo = Filteredlist;
    const DocumentList = ListData?.datalist?.dropdown;

    const dispatch = useDispatch();
    const [orgList, setorgList] = useState(orgId);
    const [NumberSize, setNumberSize] = useState(OrgInfo?.padding);
    const reqObj = {
        org_id: +orgList,
    };

    useEffect(() => {
        if (orgList !== 0) {
            dispatch(GetInvoiceIdConfig(+orgList));
        }
        setNumberSize(OrgInfo?.padding);
        setNumberStart(OrgInfo?.next_no);
        setPrefix(OrgInfo?.prefix);
        setSuffix(OrgInfo?.suffix);
    }, [dispatch, orgList, OrgInfo?.padding, OrgInfo?.next_no, OrgInfo?.prefix, OrgInfo?.suffix]);

    const { fetching, requestComplete, error, data } = useSelector((state) => state?.AddInvIdConfigState);
    const [prefix, setPrefix] = useState(OrgInfo?.prefix);
    const [Suffix, setSuffix] = useState(OrgInfo?.suffix);
    const [NumberStart, setNumberStart] = useState(OrgInfo?.next_no);
    const [errormsgs, seterrormsg] = useState({
        message: "",
    });
    const [previewError, setPreviewError] = useState({
        message: "",
    });
    const [NumberError, setNumberError] = useState({
        message: "",
    });
    const handleOrgValueChange = (value) => {
        if (value !== 0) {
            setorgList(value);
            // setPrefix("");
            // setSuffix("");
            // setNumberSize("");
            // setNumberStart("");
            // setorgList(0);
        }
        seterrormsg({
            message: "",
        });
        setPreviewError({
            message: "",
        });
        setNumberError({
            message: "",
        });
    };
    const handleDocumentChange = (value) => {
        if (value !== 0) {
            setDcoumetGroup(value);
        }
        seterrormsg({
            message: "",
        });
        setPreviewError({
            message: "",
        });
        setNumberError({
            message: "",
        });
    };
    const handleprefixChange = (value) => {
        setPrefix(value);
    };
    const handleSuffixChange = (value) => {
        setSuffix(value);
    };
    const handleNumberStartChange = (value) => {
        setNumberStart(value);
    };
    const handlepaddingChange = (value) => {
        setNumberSize(value);
    };

    // const Padding = Array.from({ length: NumberSize == undefined ? OrgInfo?.padding - 1 : NumberSize - 1 }, (x, i) => 0);
    const numberstartDisplay = NumberStart == 0 && orgList == 0 ? "" : NumberStart;
    const Padding = String(numberstartDisplay)?.padStart(NumberSize, "0");

    const preview = (prefix == undefined ? OrgInfo?.prefix : prefix) + Padding + (Suffix == undefined ? OrgInfo?.suffix : Suffix);
    const previewString = stringify(preview);
    const pre = previewString.replace(/,/g, "");
    const NumbersizeValue = NumberSize !== "" && NumberSize !== 0 && NumberSize !== "0";
    function submit() {
        if (!/^(?:[1-9]|0[1-9]|10)$/.test(NumberSize) && orgList !== 0 && NumbersizeValue) {
            seterrormsg({
                message: " The numeric value should range from 1 to 10 ",
            });
            return false;
        } else {
            seterrormsg({
                message: "",
            });
        }
        if (!/^[0-9]*$/.test(NumberStart) && orgList !== 0) {
            setNumberError({
                message: "Value should be numeric",
            });
            return false;
        } else if (NumberStart == "" || NumberStart == 0) {
            setNumberError({
                message: "Number Starts with is Invalid",
            });
            return false;
        } else {
            setNumberError({
                message: "",
            });
        }
        if (pre?.length > 27) {
            setPreviewError({
                message: "Preview should not exceed 25 characters ",
            });
            return false;
        } else {
            setPreviewError({
                message: "",
            });
        }

        dispatch(
            InvoiceIdConfig({
                prefix: prefix,
                suffix: Suffix,
                padding: +NumberSize,
                org_id: +orgList,
                next_no: +NumberStart,
                document_group: +documentGroup,
            })
        );
    }

    const timer = (requestComplete && !data.status) || (error && !data?.status) ? 5000 : 2000;
    if (requestComplete || (requestComplete && !data.status) || (error && !data?.status)) {
        setTimeout(function () {
            // dispatch(listOrganisationDropDown());
            dispatch(GetInvoiceIdConfig(reqObj));
            dispatch({ type: INVOICE_ID_CONFIG_RESET });
        }, timer);
    }
    let Title;
    let ViewTitle = dataList?.find((el) => el?.id == orgList)?.preview;
    const dropdownColor = currentMode === "dark" ? "#333333" : `white`;
    const dropdownText = currentMode === "dark" ? "#ECECEC" : `#555555`;
    return (
        <>
            {requestComplete && data?.status && <SuccessMessageBox success={succesMsg} />}
            {requestComplete && !data?.status && (
                <MessageBox
                    error={
                        data?.message == "Duplication of sequence number"
                            ? `Invoice number ${NumberStart} is already allocated, configure with a new number series!`
                            : data?.message
                    }
                    // error={data?.message}
                />
            )}
            {error && !data?.status && <MessageBox error={error?.message} />}
            <Layout>
                <Main>
                    <div className={`text-${currentMode}-copy flex justify-between items-center mb-6`}>
                        <div>
                            <h4 className="text-2xl font-interSb"> ID Configuration</h4>
                        </div>
                    </div>
                    <Card
                        className={`h-[calc(100vh_-_22rem)] xl:h-[calc(100vh_-_17.5rem)] p-[30px] border-b-0 rounded-t-[10px] rounded-b-[0] w-full overflow-y-auto ${
                            currentMode === "dark" ? "" : "shadow-[0px_4px_20px_rgba(238,238,238,0.5)]"
                        }  pb-0 relative bg-${currentMode}-card text-${currentMode}-copy border-${currentMode}-700`}>
                        {InvoiceConfig?.fetching || requestComplete ? (
                            <Loader className="h-[calc(100vh_-_17rem)] " />
                        ) : (
                            <form action="">
                                <div className="max-w-[483px] invoiceConfig">
                                    <div className="flex items-center justify-between mb-5">
                                        <span>Organization</span>
                                        <div>
                                            <Select
                                                value={orgList || 0}
                                                name="OrganisationList"
                                                title={dataList?.find((el) => el?.id == orgList)?.organisation_name}
                                                onChange={(e) => handleOrgValueChange(e.target.value)}
                                                inputProps={{
                                                    "aria-label": "Without label",
                                                }}
                                                fullWidth
                                                IconComponent={() => (
                                                    <img
                                                        className="absolute right-[15px] opacity-50 pointer-events-none"
                                                        src={
                                                            currentMode !== "dark"
                                                                ? `/images/header/chevronDown.svg`
                                                                : `/images/header/chevronDown-white.svg`
                                                        }
                                                        alt="chevronDown"
                                                    />
                                                )}
                                                MenuProps={{
                                                    sx: {
                                                        "& .MuiMenu-paper": {
                                                            backgroundColor: dropdownColor,
                                                            color: dropdownText,
                                                            maxWidth: "255px",
                                                        },
                                                        "& .MuiMenuItem-root:hover": {
                                                            backgroundColor: "rgba(218, 249, 244, 0.3)",
                                                        },
                                                        "& .Mui-selected": {
                                                            backgroundColor: "rgba(218, 249, 244, 0.3) !important",
                                                        },
                                                    },
                                                }}
                                                SelectDisplayProps={{
                                                    style: {
                                                        paddingTop: "9px",
                                                        paddingBottom: "9px",
                                                        color: `${currentMode !== "dark" ? `#555555` : `#ECECEC`}`,

                                                        paddingRight: "30px",
                                                        maxWidth: "254px",
                                                        minWidth: "254px",
                                                        border: `1px solid rgba(85,85,85,0.4)`,
                                                        borderRadius: "8px",
                                                    },
                                                }}>
                                                <MenuItem key={0} name="OrganisationList" value={0}>
                                                    {"Select Organization"}
                                                </MenuItem>
                                                {dataList &&
                                                    dataList?.map((listelem, i) => {
                                                        return (
                                                            <MenuItem key={i + 1} name="OrganisationList" title={Title} value={listelem?.id}>
                                                                {listelem?.organisation_name}
                                                            </MenuItem>
                                                        );
                                                    })}
                                            </Select>
                                        </div>
                                    </div>
                                    <div className="flex items-center justify-between mb-5">
                                        <span>Document Type</span>
                                        <div>
                                            <Select
                                                value={documentGroup || "0"}
                                                name="documentGroup"
                                                title={dataList?.find((el) => el?.id == orgList)?.organisation_name + "(" + ViewTitle + ")"}
                                                onChange={(e) => handleDocumentChange(e.target.value)}
                                                inputProps={{
                                                    "aria-label": "Without label",
                                                }}
                                                fullWidth
                                                IconComponent={() => (
                                                    <img
                                                        className="absolute right-[15px] opacity-50 pointer-events-none"
                                                        src={
                                                            currentMode !== "dark"
                                                                ? `/images/header/chevronDown.svg`
                                                                : `/images/header/chevronDown-white.svg`
                                                        }
                                                        alt="chevronDown"
                                                    />
                                                )}
                                                MenuProps={{
                                                    sx: {
                                                        "& .MuiMenu-paper": {
                                                            backgroundColor: dropdownColor,
                                                            color: dropdownText,
                                                            maxWidth: "255px",
                                                        },
                                                        "& .MuiMenuItem-root:hover": {
                                                            backgroundColor: "rgba(218, 249, 244, 0.3)",
                                                        },
                                                        "& .Mui-selected": {
                                                            backgroundColor: "rgba(218, 249, 244, 0.3) !important",
                                                        },
                                                    },
                                                }}
                                                SelectDisplayProps={{
                                                    style: {
                                                        paddingTop: "9px",
                                                        paddingBottom: "9px",
                                                        color: `${currentMode !== "dark" ? `#555555` : `#ECECEC`}`,

                                                        paddingRight: "30px",
                                                        maxWidth: "254px",
                                                        minWidth: "254px",
                                                        border: `1px solid rgba(85,85,85,0.4)`,
                                                        borderRadius: "8px",
                                                    },
                                                }}>
                                                <MenuItem key={0} name="DocumentType" value={0}>
                                                    {"Select Document"}
                                                </MenuItem>
                                                {DocumentList &&
                                                    DocumentList?.map((listelem, i) => {
                                                        Title = listelem?.master_name + "(" + listelem?.master_preview + ")";
                                                        return (
                                                            <MenuItem
                                                                key={i + 1}
                                                                name="DocumentType"
                                                                title={Title}
                                                                value={listelem?.master_id}
                                                                className="truncate trunc-1 block">
                                                                {listelem?.master_name}({listelem?.master_preview})
                                                            </MenuItem>
                                                        );
                                                    })}
                                            </Select>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="flex items-center justify-between mb-5">
                                            <span>Prefix</span>
                                            <Input
                                                className={`border-${currentMode}-840 bg-${currentMode}-810 min-w-[300px] border-[1px] px-[10px] py-[8px] rounded-[8px] `}
                                                placeholder="eg: INV-"
                                                maxLength={10}
                                                name="prefix"
                                                disabled={orgList == 0 ? true : false}
                                                value={prefix == undefined ? OrgInfo?.prefix : prefix}
                                                onChange={(e) => handleprefixChange(e.target.value)}
                                            />
                                        </div>
                                        <div className="flex items-center justify-between mb-5">
                                            <span>Running Number Size</span>
                                            <div>
                                                <Input
                                                    placeholder="eg: 5"
                                                    className={`border620 border-${currentMode}-840 bg-${currentMode}-810 min-w-[300px] border-[1px] px-[10px] py-[8px] rounded-[8px]`}
                                                    value={NumberSize == 0 ? "" : NumberSize}
                                                    name="NumberSize"
                                                    type="text"
                                                    maxLength="2"
                                                    disabled={orgList == 0 ? true : false}
                                                    onChange={(e) => handlepaddingChange(e.target.value)}
                                                />
                                                {errormsgs?.message && <p className="text-light-50 block text-xs">{errormsgs?.message}</p>}
                                            </div>
                                        </div>
                                        <div className="flex items-center justify-between mb-5">
                                            <span>Suffix</span>
                                            <Input
                                                placeholder="eg: 123"
                                                className={`border620  border-${currentMode}-840 bg-${currentMode}-810 min-w-[300px] border-[1px] px-[10px] py-[8px] rounded-[8px]`}
                                                value={Suffix == undefined ? OrgInfo?.suffix : Suffix}
                                                disabled={orgList == 0 ? true : false}
                                                maxLength={10}
                                                onChange={(e) => handleSuffixChange(e.target.value)}
                                            />
                                        </div>
                                        <div className="flex items-center justify-between mb-5">
                                            <span>Numbers Starts with</span>
                                            <div>
                                                <Input
                                                    placeholder="eg: 1"
                                                    className={`border620 border-${currentMode}-840 bg-${currentMode}-810 min-w-[300px] border-[1px] px-[10px] py-[8px] rounded-[8px]`}
                                                    value={NumberStart}
                                                    disabled={orgList == 0 ? true : false}
                                                    maxLength={5}
                                                    onChange={(e) => handleNumberStartChange(e.target.value)}
                                                />
                                                {NumberError?.message && <p className="text-light-50 block text-xs">{NumberError?.message}</p>}
                                            </div>
                                        </div>

                                        <div className="flex items-center mb-5 justify-between">
                                            <div className="w-[38%]">
                                                <span>Preview</span>
                                            </div>
                                            <div className="w-[62%]">
                                                <div
                                                    className={`relative font-interM border-b-[1px] border-${currentMode}-590  w-full text-[#555555)] bg-${currentMode}-1330 ${
                                                        currentMode !== "dark" ? "shadow-[0px_4px_20px_rgba(238,238,238,0.5)]" : ""
                                                    } flex justify-center items-center h-[80px] min-w-[300px] border-[1px] px-[10px] py-[8px] rounded-[8px]`}>
                                                    <span title={`${prefix}${Padding}${Suffix}`} className="trunc-1 cursor-default">
                                                        {prefix}
                                                        {Padding}
                                                        {Suffix}
                                                    </span>
                                                    {NumberStart == "" &&
                                                        (prefix == "" || prefix == undefined) &&
                                                        (Suffix == "" || Suffix == undefined) &&
                                                        NumberSize == "" && <span className="text-light-120">eg: INV-00001-123</span>}
                                                </div>

                                                {previewError?.message && <p className="text-light-50 text-xs">{previewError?.message}</p>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        )}
                    </Card>
                    <div
                        className={`bg-${currentMode}-card text-${currentMode}-copy border-${currentMode}-700 py-[20px] space-x-2 px-[30px] sticky left-0 right-0 shadow-[0px_4px_20px_rgba(85,85,85,0.2)] border-[1px] border-[#dfe1e28f] rounded-b-[10px]`}>
                        <Button
                            className="bg-light-20 text-white text-base rounded-[8px] py-2 px-7"
                            onClick={submit}
                            title={"Save"}
                            disabled={orgList == 0 || fetching || documentGroup == 0}>
                            {"Save"}
                        </Button>
                        <Link to="/admin-setting" title="Cancel">
                            <Button className="bg-light-240 text-light-40 text-base rounded-[8px] py-2 px-7"> Cancel</Button>
                        </Link>
                    </div>
                </Main>
                <Footer />
            </Layout>
        </>
    );
};

export default IdConfig;