import { useContext, useEffect, useMemo, useState } from "react";
import Layout from "../../Hoc/Layout";
import { ThemeContext } from "../../Hooks/Themes/Themeprovider";
import { FormControl, MenuItem, Select } from "@mui/material";
import Button from "../../components/Button";
import Footer from "../../organisms/Footer";
import { Link } from "react-router-dom";
import Main from "../../components/UI/Main";
import Card from "../../components/UI/Card";
import Input from "../../components/Input";
import { useFormik } from "formik";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { UpdateDocumentType, getDocumentType } from "../../redux/actions/invoice";
import Loader from "../../components/Loader";
import { DocumentValidateSchema } from "../../Hooks/validateschema";
import Swal from "sweetalert2";
import { POST_DOCUMENT_TYPE_RESET } from "../../redux/types/types";
import SuccessMessageBox from "../../components/UI/Alert/sucess";
import { showSwal } from "../../utils/helpers";

export const DocumentConfig = () => {
    const dispatch = useDispatch();
    const { getDocument, fetching } = useSelector((state) => state?.getDocumentTypeState);
    const { updateDocument, fetching: fetch, requestComplete } = useSelector((state) => state?.postdocumentTypeState);
    const { currentMode } = useContext(ThemeContext);

    useEffect(() => {
        dispatch(getDocumentType());
    }, [dispatch]); //Api call for get Document type...
    const InitialObj = {
        document_type: 1,
        document_name: "",
        document_code: "",
        document_specialgl: [{ id: 1, value: "" }],
    };

    const formik = useFormik({
        initialValues: InitialObj,
        validationSchema: DocumentValidateSchema,
        onSubmit: (values) => {
            const Str = JSON.stringify(values?.document_specialgl);
            const reqObj = {
                id: "" + values?.document_type,
                document_name: values?.document_name,
                document_code: values?.document_code,
                special_gl_indicator: Str?.slice(1, -1),
            };
            // Check for duplicate values
            const checkDub = values?.document_specialgl?.map((obj) => obj.value);
            const hasDuplicates = new Set(checkDub).size !== checkDub?.length;

            if (hasDuplicates) {
                showSwal("Duplicate G/L indicators are not allowed.");
            } else {
                dispatch(UpdateDocumentType(reqObj));
            }
        },
    });
    if (requestComplete) {
        setTimeout(() => {
            dispatch({ type: POST_DOCUMENT_TYPE_RESET });
        }, 1500);
    }

    const { document_type, document_name, document_code, document_specialgl } = formik?.values;
    const TextLabes = (doc) => {
        switch (doc) {
            case 1:
                return "A PO invoice issued by a seller to request payment for goods/services based on a purchase order. It documents supplied items/services for payment.";
            case 2:
                return "A Non-PO invoice issued by a seller to request payment for goods/services without a purchase order. Typically used for ad-hoc purchases.";
            case 3:
                return "Mobilization Advance Request is made by vendors/contractors for upfront payment. It's to mobilize resources and start project activities.";
            case 4:
                return "Adhoc Advance Request is for irregular advance payments outside normal procedures. Used for non-standard payment requests.";
            case 5:
                return "Miscellaneous Payment is for non-standard invoices, like refunds or one-time payments. It covers expenses beyond typical invoices.";
            case 6:
                return "Retention Release involves releasing withheld funds to vendors/contractors upon milestone completion. Typically occurs at specific project phases.";
            default:
                break;
        }
    };
    const getData = () => {
        return updateDocument?.length > 0 ? updateDocument?.data : getDocument;
    };
    const [Update] = getData()?.data?.filter((el) => el?.document_typeid == document_type) || [];
    useMemo(() => {
        if (document_type == 1) {
            const String = Update?.special_gl_indicator?.replace(/'/g, '"');
            const AddArr = `[${String}]`;
            const Add = String && JSON.parse(AddArr);
            formik?.setFieldValue("document_name", Update?.document_name || "");
            formik?.setFieldValue("document_code", Update?.document_code || "");
            formik?.setFieldValue("document_specialgl", Add || [{ id: 1, value: "" }]);
        }
    }, [Update]); //on first render set the values..
    const _AddNew = () => {
        if (document_specialgl?.length < 50) {
            formik?.setFieldValue("document_specialgl", [...document_specialgl, { id: document_specialgl?.length + 1, value: "" }]);
        } else {
            showSwal("The addition of a new Special G/L Indicator is restricted to 50.");
        }
    };
    const Checkrm = (id, val) => {
        Swal.fire({
            text: `Are you sure you want to remove ${val ? `"${val}"` : "this"} indicator?`,
            icon: "warning",
            showCloseButton: true,
            showCancelButton: true,
            buttons: {
                cancel: "Cancel",
                confirm: "Confirm",
            },
        }).then((confirm) => {
            if (confirm?.isConfirmed) {
                _remove(id);
            }
        });
    };
    const _remove = (id) => {
        // Remove the item with the specified ID from the array
        const updatedSpecial = document_specialgl?.filter((item) => item?.id != id);
        // Update the IDs of the remaining items to maintain continuity
        const reorderedSpecial = updatedSpecial?.map((item, index) => ({
            ...item,
            id: index + 1, // Update the ID to be sequential starting from 1
        }));
        formik?.setFieldValue("document_specialgl", reorderedSpecial);
    };
    const _handleTypeChange = (e) => {
        formik?.handleChange(e);
        formik.setTouched({}, false);
        const [Update] = getDocument?.data?.filter((el) => el?.document_typeid == e?.target?.value) || [];
        formik?.setFieldValue("document_name", Update?.document_name || "");
        formik?.setFieldValue("document_code", Update?.document_code || "");
        formik?.setFieldValue(
            "document_specialgl",
            Update?.special_gl_indicator != null ? JSON.parse(`[${Update?.special_gl_indicator}]`) : [{ id: 1, value: "" }]
        );
        dispatch(getDocumentType());
    };
    const successMsg = `Document type updated successfully!`;
    return (
        <>
            {requestComplete && updateDocument?.status && <SuccessMessageBox success={successMsg} />}
            {/* {error && <MessageBox error={error?.error?.message || errorMsg} />} */}
            <Layout>
                <Main>
                    <div className={`text-${currentMode}-copy flex justify-between items-center mb-6`}>
                        <div>
                            <h4 className="text-2xl font-interSb">Document Type Setup</h4>
                        </div>
                    </div>
                    <Card
                        className={`h-[calc(100vh_-_22rem)] xl:h-[calc(100vh_-_17.5rem)] p-[30px] border-b-0 rounded-t-[10px] rounded-b-[0] w-full overflow-y-auto ${
                            currentMode === "dark" ? "" : "shadow-[0px_4px_20px_rgba(238,238,238,0.5)]"
                        }  pb-0 relative bg-${currentMode}-card text-${currentMode}-copy border-${currentMode}-700`}>
                        {fetching || fetch ? (
                            <Loader className="h-[calc(100vh_-_17rem)] " />
                        ) : getDocument && getDocument?.data?.length > 0 ? (
                            <div className="basis-[50%] invoiceConfig">
                                <div className="flex items-start justify-between mb-5 max-w-[600px]">
                                    <span className="font-interM min-w-[186px] mt-[10px]">
                                        Document Type <span className="text-[red]">*</span>
                                    </span>
                                    <div className="w-full">
                                        <FormControl className="min-w-[300px]">
                                            <Select
                                                value={document_type || 1}
                                                name="document_type"
                                                onChange={_handleTypeChange}
                                                fullWidth
                                                IconComponent={() => (
                                                    <img
                                                        className="absolute right-[15px] opacity-50 pointer-events-none"
                                                        src={
                                                            currentMode !== "dark"
                                                                ? `/images/header/chevronDown.svg`
                                                                : `/images/header/chevronDown-white.svg`
                                                        }
                                                        alt="chevronDown"
                                                    />
                                                )}>
                                                {getDocument?.length == 0 && (
                                                    <MenuItem key={1} value={1}>
                                                        {"Select document type"}
                                                    </MenuItem>
                                                )}

                                                {getDocument?.dropdown &&
                                                    getDocument?.dropdown?.map((elem, i) => {
                                                        return (
                                                            <MenuItem
                                                                key={elem?.document_typeid}
                                                                title={elem?.master_name}
                                                                value={elem?.document_typeid}>
                                                                {elem?.master_name}
                                                            </MenuItem>
                                                        );
                                                    })}
                                            </Select>
                                        </FormControl>
                                        {TextLabes(document_type) != undefined && <p className="text-xs mt-2">{TextLabes(document_type)}</p>}
                                    </div>
                                </div>

                                <div className="flex items-center justify-between mb-5 max-w-[483px]">
                                    <span className="font-interM min-w-[186px]">
                                        Document Name <span className="text-[red]">*</span>
                                    </span>
                                    <div className="w-full" title={document_name}>
                                        <Input
                                            placeholder="Enter document name"
                                            name="document_name"
                                            onChange={formik?.handleChange}
                                            value={document_name}
                                            maxLength={121}
                                            className={`border py-2 w-full max-w-[300px] truncate bg-${currentMode}-card rounded-[8px] px-[15px]  border-${currentMode}-840`}
                                        />
                                        {formik?.touched?.document_name && formik?.errors?.document_name && (
                                            <p className="text-[red] text-left">{formik?.errors?.document_name}</p>
                                        )}
                                    </div>
                                </div>
                                <div className="flex items-center justify-between mb-5 max-w-[483px]">
                                    <span className="font-interM min-w-[186px]">
                                        Document Code <span className="text-[red]">*</span>
                                    </span>
                                    <div className="w-full">
                                        <Input
                                            placeholder="Enter document code"
                                            name="document_code"
                                            maxLength={51}
                                            onChange={formik?.handleChange}
                                            value={document_code}
                                            className={`border py-2 w-full max-w-[300px] bg-${currentMode}-card rounded-[8px] px-[15px]  border-${currentMode}-840`}
                                        />
                                        {formik?.touched?.document_code && formik?.errors?.document_code && (
                                            <p className="text-[red] text-left">{formik?.errors?.document_code}</p>
                                        )}
                                    </div>
                                </div>
                                <div className="flex items-start justify-between mb-5 max-w-[508px]">
                                    <span className="font-interM min-w-[186px] mt-[10px]">
                                        Special G/L Indicator <span className="text-[red]">*</span>
                                    </span>
                                    <div className="flex w-full">
                                        <div className="w-full text-end">
                                            {document_specialgl?.map((el, i) => (
                                                <div className="mb-5">
                                                    <div className="flex space-x-[10px] items-center" key={el?.id}>
                                                        <Input
                                                            placeholder="Enter special g/l indicator"
                                                            name={`document_specialgl[${i}].value`}
                                                            value={el?.value}
                                                            maxLength={1}
                                                            onChange={formik?.handleChange}
                                                            className={`border py-2 w-full max-w-[300px] bg-${currentMode}-card rounded-[8px] px-[15px]  border-${currentMode}-840`}
                                                        />
                                                        <Button
                                                            title="Delete"
                                                            disabled={document_specialgl?.length == 1}
                                                            onClick={() => Checkrm(el?.id, el?.value)}>
                                                            <img src="/images/common/divide-circle.svg" alt="divide-circle" />
                                                        </Button>
                                                    </div>
                                                    {formik?.touched?.document_specialgl && formik?.errors?.document_specialgl && (
                                                        <p className="text-[red] text-left">{formik?.errors?.document_specialgl?.[i]?.value}</p>
                                                    )}
                                                </div>
                                            ))}

                                            <span
                                                className="text-light-10 mt-2 mb-5 font-interM mr-[1.8rem] cursor-pointer"
                                                onClick={_AddNew}
                                                title="Add New Special G/L">
                                                + Add New
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div className="flex jsustify-center items-center absolute top-[50%] translate-y-[-50%] left-[50%] translate-x-[-50%] h-full">
                                <div>
                                    <img src="/images/common/delivery.png" alt="delivery" />
                                    <h4 className="font-interSb text-center mt-3">No data found</h4>
                                </div>
                            </div>
                        )}
                    </Card>
                    <div
                        className={`bg-${currentMode}-card text-${currentMode}-copy border-${currentMode}-700 py-[20px] space-x-2 px-[30px] sticky left-0 right-0 shadow-[0px_4px_20px_rgba(85,85,85,0.2)] border-[1px] border-[#dfe1e28f] rounded-b-[10px]`}>
                        <Button
                            className="bg-light-20 text-white text-base rounded-[8px] py-2 px-7"
                            onClick={formik?.handleSubmit}
                            title={"Save"}
                            type="submit"
                            disabled={fetch || getDocument?.data?.length == 0}>
                            {"Save"}
                        </Button>
                        <Link to="/admin-setting" title="Cancel">
                            <Button className="bg-light-240 text-light-40 text-base rounded-[8px] py-2 px-7">Cancel</Button>
                        </Link>
                    </div>
                </Main>
                <Footer />
            </Layout>
        </>
    );
};
