import Button from "../../../components/Button";
import Input from "../../../components/Input";
import { encryptId } from "../../../utils/helpers";
import { useState, useEffect, useContext } from "react";
import { folderconfigAdd } from "../../../redux/actions/folderconfig";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { GoogleLogin } from "react-google-login";
import { gapi } from "gapi-script";
import Swal from "sweetalert2";
import { ADD_FOLDER_CONFIG_RESET } from "../../../redux/types/types";
import ReactLoginMS from "react-ms-login";
import Loader from "../../../components/Loader";
import { FormControl, MenuItem, Select } from "@mui/material";
import { ThemeContext } from "../../../Hooks/Themes/Themeprovider";
import { Checkbox } from "@mui/material";
const btnContent = () => {
    return (
        <span className="flex">
            <img className="mr-2" src="/images/common/onedrive.svg" alt="onedrive" /> OneDrive
        </span>
    );
};

const FolderConfigModal = (props) => {
    const dispatch = useDispatch();
    const [profile, setProfile] = useState([]);
    const [email, setEmail] = useState("");
    const [selectedNumber, setSelectedNumber] = useState();
    const [folderName, setFolderName] = useState();
    const [validEmail, checkValidEmail] = useState(false);
    const [isFeaching, setIsFeaching] = useState(true);
    const [invoiceType, setInvoiceType] = useState(0);
    // const [scope, setScope] = useState("https://www.googleapis.com/auth/drive");

    const clientId = process.env?.REACT_APP_GOOGLE_CLIENT_ID;
    const msClientId = process.env?.REACT_APP_MS_APP_ID;
    // useEffect(() => {
    //     const initClient = () => {
    //         gapi?.client?.init({
    //             clientId: clientId,
    //             accessType: "offline",
    //             prompt: "consent",
    //             scope: "https://www.googleapis.com/auth/drive",
    //         });
    //     };
    //     gapi?.load("client:auth2", initClient());
    // }, []);

    const onSuccess = (res) => {
        // setProfile(res)
        // setEmail(res?.profileObj?.email);
        const data = {
            code: res?.code,
            type: 2,
        };
        axios.post(process.env.REACT_APP_NODE_API_URL + "getOauthGmailToken", data).then((response) => {
            if (response.status == 200) {
                setProfile(response?.data);
                setSelectedNumber(1);
                if (response?.data?.email) {
                    // if(!/^(?!.+@(gmail|google|yahoo|outlook|hotmail|msn)\..+)(.+@.+\..+)$/.test(response?.data?.email)){
                    //   checkValidEmail(false);
                    // } else {
                    //   checkValidEmail(true);
                    // }
                    checkValidEmail(true);
                    setEmail(response?.data?.email);
                }
            }
        });
    };

    const loginMicrosoftHandler = (msData) => {
        const data = {
            code: msData?.code,
            type: 2,
        };
        setIsFeaching(false);
        axios.post(process.env.REACT_APP_NODE_API_URL + "getOauthMSToken", data).then((response) => {
            if (response.status == 200) {
                setIsFeaching(true);
                const tokenInfoData = {
                    access_token: response?.data?.accessToken,
                    refresh_token: response?.data?.refreshToken,
                };
                setProfile({ tokenInfo: tokenInfoData });
                setSelectedNumber(2);
                if (response?.data?.account?.username) {
                    checkValidEmail(true);
                    setEmail(response?.data?.account?.username);
                }
            }
        });
    };
    const onFailure = (err) => {};
    const emailOnchange = (e) => {
        setEmail(e.target.value);
    };
    const cartClose = () => {
        if (email !== "") {
            window.location.reload();
        } else {
            props?.cartClose();
            setEmail("");
            setInvoiceType(0);
            setFolderName("");
            responseMail.requestComplete = false;
            dispatch({ type: ADD_FOLDER_CONFIG_RESET });
        }
    };

    const addFolderConfig = () => {
        dispatch(
            folderconfigAdd({
                foldertype: selectedNumber,
                access_token: profile?.tokenInfo?.access_token,
                refresh_token: profile?.tokenInfo?.refresh_token,
                emailaddress: encryptId(email),
                organisation_id: props?.orgId,
                invoice_type: invoiceType,
                locationurl: folderName,
            })
        );
        localStorage.setItem("UpdateOrgId", props?.orgId);
    };

    let responseMail = useSelector((state) => state?.folderAddConfigState);
    if (responseMail.requestComplete && responseMail.profile.status) {
        Swal.fire({
            text: "Folder configuration is created successfully!",
            icon: "success",
            closeOnClickOutside: false,
            showCloseButton: true,
            button: "Ok",
        }).then((confirm) => {
            if (confirm) {
                cartClose();
                window.location.reload();
                // props.updateOrgList();
            }
        });
    } else if (responseMail?.requestComplete && !responseMail?.fetching && !responseMail.profile.status) {
        Swal.fire({
            text: responseMail.profile.message,
            icon: "warning",
            closeOnClickOutside: false,
            showCloseButton: true,
            button: "Ok",
        }).then((confirm) => {
            if (confirm) {
                setEmail("");
                setFolderName("");
                responseMail.requestComplete = false;
                dispatch({ type: ADD_FOLDER_CONFIG_RESET });
            }
        });
    }
    const onChangeInvoiceType = (e) => {
        setInvoiceType(+e.target.value);
    };
    const { data: listData } = useSelector((state) => state?.getDocumentTypeListState);
    const { currentMode } = useContext(ThemeContext);
    const dropdownColor = currentMode === "dark" ? "#333333" : `white`;
    const dropdownText = currentMode === "dark" ? "#ECECEC" : `#555555`;
    // const handleButtonClick = () => {
    //     // Change the scope based on the button click
    //     setScope("https://www.googleapis.com/auth/drive"); // Replace with the desired scope
    // };

    // const onClicked = (onClickHandler) => () => {
    //     handleButtonClick(); // Call the dynamic scope change function
    //     onClickHandler(); // Trigger the original onClick event of renderProps
    // };
    const [acceptTerms, setAcceptTerms] = useState(false);
    return (
        <div className="px-[35px]">
            <span className="text-sm font-interM mb-5 block">Map a folder configuration</span>
            <form>
                <div className="h-[calc(97vh_-_250px)]">
                    <div className="flex space-x-2 mb-7">
                        <GoogleLogin
                            clientId={clientId}
                            render={(renderProps) => (
                                <Button
                                    onClick={renderProps.onClick}
                                    className={`border-${currentMode}-840 px-5 py-[9px] rounded-[8px] font-interSb text-sm flex items-center border-[1px] text-${currentMode}-40`}
                                >
                                    <img className="mr-2" src="/images/common/googledrive.svg" alt="googledrive" />
                                    GoogleDrive
                                </Button>
                            )}
                            onSuccess={onSuccess}
                            onFailure={onFailure}
                            cookiePolicy={"single_host_origin"}
                            accessType={"offline"}
                            prompt={"consent"}
                            responseType={"code"}
                            scope={"https://www.googleapis.com/auth/drive"}
                        />

                        <ReactLoginMS
                            clientId={msClientId}
                            redirectUri={process.env?.REACT_APP_MS_REDIRECT_URL + "/msAuthComplete.html"}
                            scopes={["Mail.ReadWrite", "MailboxSettings.ReadWrite"]}
                            responseType="code"
                            cssClass={`border-${currentMode}-840 px-5 py-[9px] rounded-[8px] font-interSb text-sm flex items-center border-[1px] text-${currentMode}-40`}
                            btnContent={btnContent}
                            handleLogin={(data) => loginMicrosoftHandler(data)}
                        />
                    </div>
                    <div className="min-w-[500px]">
                        <div className="mb-4">
                            <Input
                                disabled
                                value={email}
                                onChange={(e) => emailOnchange(e)}
                                placeholder="Email Id"
                                name="emailaddress"
                                className={`w-full py-2 rounded-[8px] px-5  border-[1px] border-${currentMode}-840 bg-${currentMode}-810`}
                            />
                            {email && !validEmail ? <p className="text-light-50 text-xs">{"Please add your business Email Id"}</p> : ""}
                        </div>
                        <div className="mb-4">
                            <Input
                                onChange={(e) => setFolderName(e.target.value)}
                                placeholder="Folder Name"
                                name="foldername"
                                value={folderName}
                                className={`w-full py-2 rounded-[8px] px-5 bg-${currentMode}-card  border-[1px] border-${currentMode}-840`}
                            />
                        </div>
                    </div>
                    <FormControl
                        sx={{
                            minWidth: "500px",
                            marginBottom: 3,
                        }}
                    >
                        <Select
                            name="orgName"
                            value={invoiceType}
                            // defaultValue={orgName}
                            MenuProps={{
                                sx: {
                                    "& .MuiMenu-paper": {
                                        backgroundColor: dropdownColor,
                                        color: dropdownText,
                                    },

                                    "& .MuiMenuItem-root:hover": {
                                        backgroundColor: "rgba(218, 249, 244, 0.3) !important",
                                    },

                                    "& .Mui-selected": {
                                        backgroundColor: "rgba(218, 249, 244, 0.3) !important",
                                    },
                                },
                            }}
                            IconComponent={() => (
                                <img
                                    className="absolute right-[15px] opacity-[.5] pointer-events-none"
                                    src={currentMode !== "dark" ? `/images/header/chevronDown.svg` : `/images/header/chevronDown-white.svg`}
                                    alt="chevronDown"
                                />
                            )}
                            SelectDisplayProps={{
                                style: {
                                    paddingTop: "",
                                    color: dropdownText,
                                    paddingBottom: "",
                                    padding: "13px 30px 13px 15px",
                                    borderRadius: "8px",
                                },
                            }}
                            onChange={(e) => onChangeInvoiceType(e)}
                        >
                            <MenuItem key={0} value={0} name="invoice_type">
                                {"Select"}
                            </MenuItem>
                            {listData &&
                                listData?.map((listelem, i) => {
                                    return (
                                        <MenuItem key={i + 1} name="invoice_type" value={listelem?.id}>
                                            {listelem?.invoice_type}
                                        </MenuItem>
                                    );
                                })}
                        </Select>
                    </FormControl>
                </div>
                <div className="font-interR text-xs mb-4">
                    <div className="flex form-group items-center form-check">
                        <Checkbox
                            name="signup_acceptTerms"
                            size="small"
                            tabIndex={0}
                            className="mr-2"
                            onChange={(e) => setAcceptTerms(e.target.checked)}
                            value={acceptTerms}
                            sx={{
                                padding: 0,
                                color: "rgba(85,85,85,0.7)",
                                "&.Mui-checked": {
                                    color: "#00B399",
                                },
                            }}
                        />
                        I agree to the MyiQ.Ai
                        <a href={"https://www.myiq.ai/terms-condition"} relative="path" target="_blank" className="inline-block">
                            <div title="Terms & Conditions">
                                <span className="text-light-20 mx-1">Terms & Conditions</span>
                            </div>
                        </a>
                        and
                        <a href={"https://www.myiq.ai/privacy-policy"} relative="path" target="_blank" className="inline-block">
                            <div title="Privacy Policy">
                                <span className="text-light-20 ml-1">Privacy Policy</span>
                            </div>
                        </a>
                    </div>
                </div>
                <div className={`border-t-[1px]  left-0 py-5 border-${currentMode}-590 mx-[-35px] mt-6`}>
                    <div className="px-[35px] space-x-2 flex">
                        <Button
                            onClick={() => addFolderConfig()}
                            disabled={email && validEmail && folderName && acceptTerms && invoiceType !== 0 ? false : true}
                            type="button"
                            title="Create"
                            className="bg-light-20 flex text-base font-interR py-[.5rem] px-[2.24rem] rounded-[8px] text-white"
                        >
                            Create
                        </Button>

                        <Button
                            type="button"
                            title="Cancel"
                            onClick={cartClose}
                            className="bg-light-240 flex text-base font-interR py-[.5rem] px-[2.24rem] rounded-[8px] text-light-40 closeFolderModal"
                        >
                            Cancel
                        </Button>
                    </div>
                </div>
            </form>
            {!isFeaching ? <Loader className="h-[calc(100vh_-_15rem)]" /> : ""}
        </div>
    );
};

export default FolderConfigModal;
