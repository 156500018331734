import {
    Autocomplete,
    Checkbox,
    Chip,
    createTheme,
    FormControl,
    FormControlLabel,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    TextField,
    ThemeProvider,
} from "@mui/material";
import ReactLoginMS from "react-ms-login";
import { GoogleLogin } from "react-google-login";
import { DesktopDatePicker, TimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { AccessIcon, ClockIcon, isEmailregex, SelectDropdwnImg } from "../../../utils/helpers";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { useEffect, useState } from "react";
import Button from "../../../components/Button";
import Input from "../../../components/Input";
import { useSelector } from "react-redux";
import { getCurrentUser } from "../../../redux/actions/auth";
import { Days, DaysRange, months, starting } from "../../../utils/constants";
import moment from "moment";
import axios from "axios";

const btnContent = () => {
    return (
        <span className="flex">
            <img className="mr-2" src="/images/common/onedrive.svg" alt="onedrive" /> OneDrive
        </span>
    );
};
export const SchedulerType = ({
    formik,
    currentMode,
    setProfile,
    profile,
    folderName,
    setFolderName,
    email,
    setEmail,
    validEmail,
    checkValidEmail,
    oAuth,
    setOauth,
    viewData,
    prevtype,
    toInput,
    setToInput,
    bccInput,
    setBccInput,
    ccInput,
    setCcInput,
}) => {
    const [showBcc, setShowBcc] = useState(false);
    const { dropdown } = useSelector((state) => state?.getDropdownReportState);
    const removeArr = dropdown?.download_format?.filter((item) => item.id !== 4 && item.id !== 5);
    const [deliverys, setDeliverys] = useState(removeArr);
    useEffect(() => {
        if (prevtype == 3) {
            const chartType = removeArr?.filter((item) => item?.id === 1);
            setDeliverys(chartType);
        } else if (prevtype == 2) {
            const matrixType = removeArr?.filter((item) => item?.id !== 3);
            setDeliverys(matrixType);
        } else {
            setDeliverys(removeArr);
        }
    }, [prevtype]);
    const {
        report_from,
        delivery_format,
        frequency,
        delivery_opt,
        events_types,
        scheduled_time,
        startdate,
        enddate,
        type,
        day,
        day_select,
        week,
        on,
        on_month,
        on_starting,
        selectedOption,
        delivery,
    } = formik?.values; //value destructure for easy to read and access...
    const CurrentUser = getCurrentUser();
    const UserDateFormat = CurrentUser?.user_date;
    let parseStartDate = moment(startdate).format(UserDateFormat?.toUpperCase());
    let parseEndDate = moment(enddate).format(UserDateFormat?.toUpperCase());
    // Create a custom theme for TimePicker Mui...!
    const theme = createTheme({
        palette: {
            primary: {
                main: "#028C78", // Change this to your desired primary color
            },
        },
    });
    const handleKeyPress = (event) => {
        // Get the key code of the pressed key
        const keyCode = event.which || event.keyCode;
        // Allow only numeric keys (0-9) and prevent others
        if (keyCode < 48 || keyCode > 57) {
            event.preventDefault();
        }
        // Get the current value of the input field
        const currentValue = event.target.value;
        // Allow only values up to 31
        if (parseInt(currentValue + event.key, 10) > 31) {
            event.preventDefault();
        } else {
            formik?.handleChange(event);
        }
    }; //keypress func to restrict max value < 31
    const handleDayKeyPress = (event) => {
        // Get the key code of the pressed key
        const keyCode = event.which || event.keyCode;

        // Allow only numeric keys (0-9) and prevent others
        if (keyCode < 48 || keyCode > 57) {
            event.preventDefault();
        }

        // Get the current value of the input field
        const currentValue = event.target.value;

        // Allow only values up to 99
        if (parseInt(currentValue + event.key, 10) > 99) {
            event.preventDefault();
            formik?.handleChange(event);
        } else {
            formik?.handleChange(event);
        }
    };
    const [isRevealPwd, setIsRevealPwd] = useState(false);

    const currentMonth = moment().format("M");
    const currentDay = moment().format("d");
    const currentdate = moment().format("DD");
    const dayWithoutLeadingZero = parseInt(currentdate, 10);
    const [selectItems, setSelectItems] = useState([]);
    const handleDays = (e, value, text) => {
        let { checked } = e?.target;
        if (checked) {
            formik?.setFieldValue("week", [...week, value]);
            setSelectItems([...selectItems, text]);
        } else if (week?.length > 1) {
            formik?.setFieldValue(
                "week",
                week?.filter((dayValue) => dayValue !== value)
            );
            let Check = selectItems?.filter((textVal) => textVal !== text);
            setSelectItems(Check);
        }
    };
    const Disable = (opt) => (formik?.values?.selectedOption != opt ? `pointer-events-none opacity-[.4]` : ``);
    const handleRadio = (val) => {
        formik?.setFieldValue("selectedOption", val);
        formik?.setFieldValue("day", dayWithoutLeadingZero);
        formik?.setFieldValue("day_select", +currentDay + 1);
        formik?.setFieldValue("on_starting", 1);
        formik?.setFieldValue("on_month", currentMonth);
    }; //Group Radio handlechange for event based type (week,month,year)...!

    const clientId = process.env?.REACT_APP_GOOGLE_CLIENT_ID;
    const msClientId = process.env?.REACT_APP_MS_APP_ID;
    const onSuccess = (res) => {
        const data = {
            code: res?.code,
            type: 2,
        };
        axios.post(process.env.REACT_APP_NODE_API_URL + "getOauthGmailToken", data).then((response) => {
            if (response.status == 200) {
                const tokenInfoData = {
                    access_token: response?.data?.tokenInfo?.access_token,
                    refresh_token: response?.data?.tokenInfo?.refresh_token,
                    exp: response?.data?.tokenInfo?.expiry_date,
                    oauth: 1,
                };
                setProfile(tokenInfoData);
                if (response?.data?.email) {
                    checkValidEmail(true);
                    setEmail(response?.data?.email);
                    setOauth(1);
                }
            }
        });
    }; //succes func for google button onclick...!!
    const emailOnchange = (e) => {
        setEmail(e.target.value);
    };

    const loginMicrosoftHandler = (msData) => {
        const data = {
            code: msData?.code,
            type: 2,
        };
        axios.post(process.env.REACT_APP_NODE_API_URL + "getOauthMSToken", data).then((response) => {
            if (response.status == 200) {
                const tokenInfoData = {
                    access_token: response?.data?.accessToken,
                    refresh_token: response?.data?.refreshToken,
                    exp: response?.data?.account?.idTokenClaims?.exp,
                    oauth: 2,
                };
                setProfile(tokenInfoData);
                if (response?.data?.account?.username) {
                    checkValidEmail(true);
                    setEmail(response?.data?.account?.username);
                    setOauth(2);
                }
            }
        });
    }; //succes func for outlook button onclick...!!
    const onFailure = (err) => {};
    //This text func is based on the user selected date and time, day in event based (week, month, year)...!
    const OccureText = () => {
        let types = "";
        if (type == 1 && type > 1) {
            types = "weeks";
        } else if (type == 2) {
            types = `month${on > 1 ? `s` : ``}`;
        } else if (type == 3) {
            types = `year`;
        }
        let Selected = DaysRange?.filter((item) => week?.includes(item?.value));
        let monthconfig =
            type == 2
                ? `on day ${
                      selectedOption == "option2"
                          ? ` ${starting?.filter((el) => el?.val == on_starting)?.map((el) => el?.start)} ${Days?.filter(
                                (el) => el?.val == day_select
                            )?.map((el) => el?.dayName)}`
                          : `${!day ? dayWithoutLeadingZero : day}`
                  }`
                : ``;
        let yearconfig =
            type == 3
                ? `on ${
                      selectedOption == "option2"
                          ? `the ${starting?.filter((el) => el?.val == on_starting)?.map((el) => el?.start)} ${Days?.filter(
                                (el) => el?.val == day_select
                            )?.map((el) => el?.dayName)} `
                          : `day ${!day ? dayWithoutLeadingZero : day}`
                  } of ${months?.filter((el) => el?.val == on_month)?.map((el) => el?.monthName)}`
                : ``;
        return `Occurs ${type == 1 || type == 2 ? (on > 1 ? on : ``) : ``} every ${types !== "" ? types : ``}  ${monthconfig} ${yearconfig} 
        ${type == 1 ? (Object?.keys(viewData)?.length !== 0 ? Selected?.map((el) => ` ${el?.text}`) : selectItems?.join(", ")) : ``} 
        ${type !== 3 ? `starting` : ``}
       ${parseEndDate !== "Invalid date" ? `${type !== 3 ? `from` : ``}` : ``} ${type != 3 ? parseStartDate : ``}
       ${parseEndDate !== "Invalid date" ? `${type !== 3 ? `to` : `untill`} ${parseEndDate}.` : ``}`;
    };
    const [dropdownFilter, setDropdownFilter] = useState([]);
    const EventFilter = (id) => {
        let eventDropdown = dropdown?.event_based?.filter((el) => el?.dataset_id == id);
        setDropdownFilter(eventDropdown);
    }; //filter the data based on the report
    useEffect(() => {
        EventFilter(report_from);
    }, [report_from]); //based on the report from slected filter the data
    return (
        <>
            {" "}
            {prevtype != 4 && prevtype != 5 && (
                <div className="flex items-center px-[30px]">
                    <label className="xl:basis-[10%] basis-[30%]" htmlFor="">
                        Delivery Format:
                    </label>
                    <div className="basis-[90%]">
                        <FormControl sx={{ minWidth: 200 }}>
                            <Select
                                value={delivery_format || "0"}
                                fullWidth
                                name="delivery_format"
                                onChange={formik?.handleChange}
                                IconComponent={() => SelectDropdwnImg(currentMode)}>
                                <MenuItem key={0} name="OrganisationList" value={0}>
                                    {"Select "}
                                </MenuItem>
                                {deliverys?.map((el, i) => (
                                    <MenuItem key={el?.id} tabIndex={el?.id} value={el?.id} className="truncate trunc-1 block">
                                        {el?.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                </div>
            )}
            <div className={`flex ${frequency === "" ? "items-center" : "items-start"} px-[30px]`}>
                <label className={`xl:basis-[10%] ${frequency === "" ? "" : "mt-[10px]"} basis-[30%]`} htmlFor="">
                    Frequency:
                </label>
                <div className="basis-[90%] space-y-[20px]">
                    <FormControl className="block" sx={{ maxWidth: 200 }}>
                        <Select
                            name="frequency"
                            disabled={prevtype == 4 || prevtype == 5}
                            value={frequency || "0"}
                            fullWidth
                            onChange={formik?.handleChange}
                            inputProps={{
                                "aria-label": "Without label",
                                tabIndex: "1",
                            }}
                            IconComponent={() => SelectDropdwnImg(currentMode)}>
                            <MenuItem key={0} name="OrganisationList" value={0}>
                                {"Select "}
                            </MenuItem>
                            <MenuItem tabIndex={1} value={1} className="truncate trunc-1 block">
                                Event Based
                            </MenuItem>
                            {prevtype != 4 && prevtype != 5 && (
                                <MenuItem tabIndex={2} value={2} className="truncate trunc-1 block">
                                    Time Based
                                </MenuItem>
                            )}
                        </Select>
                    </FormControl>
                    {frequency === 1 && (
                        <FormControl className="block" sx={{ maxWidth: 200 }}>
                            <Select
                                name="events_types"
                                value={events_types || "0"}
                                fullWidth
                                title={dropdownFilter?.filter((el) => el?.id == events_types)?.map((el) => el?.name)}
                                onChange={formik?.handleChange}
                                inputProps={{
                                    "aria-label": "Without label",
                                    tabIndex: "1",
                                }}
                                IconComponent={() => SelectDropdwnImg(currentMode)}>
                                <MenuItem key={0} name="OrganisationList" value={0}>
                                    {"Select "}
                                </MenuItem>
                                {dropdownFilter?.map((el, i) => (
                                    <MenuItem key={el?.id} tabIndex={el?.id} className="truncate trunc-1 block" value={el?.id}>
                                        {el?.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    )}
                    {frequency === 2 && (
                        <>
                            {" "}
                            <div className="flex items-center space-x-[10px]">
                                <div className={`datePic`}>
                                    <span className="block mb-1">Start Date</span>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DesktopDatePicker
                                            className={`border-0 max-w-[200px]`}
                                            components={{
                                                OpenPickerIcon: AccessIcon,
                                            }}
                                            name="startdate"
                                            inputFormat={UserDateFormat?.toUpperCase()}
                                            value={startdate}
                                            minDate={new Date()}
                                            onChange={(date) => {
                                                formik?.setFieldValue("startdate", date?.$d);
                                                formik?.setFieldValue("enddate", null);
                                            }}
                                            sx={{
                                                ".MuiOutlinedInput-input": {
                                                    padding: "10px",
                                                },
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    sx={{
                                                        "& .MuiInputBase-input": {
                                                            color: `${currentMode !== "dark" ? `#555555` : `#ECECEC`}`,
                                                        },
                                                    }}
                                                    {...params}
                                                    inputProps={{
                                                        ...params.inputProps,
                                                        readOnly: true,
                                                    }}
                                                />
                                            )}
                                        />
                                    </LocalizationProvider>
                                </div>
                                <div>
                                    <span className="block mb-1">Scheduled Time</span>
                                    <div className={`flex items-center datePic `}>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <ThemeProvider theme={theme}>
                                                <TimePicker
                                                    className={`border-0 max-w-[200px]`}
                                                    components={{
                                                        OpenPickerIcon: ClockIcon,
                                                    }}
                                                    placeholder="Pick the time"
                                                    renderInput={(params) => (
                                                        <TextField
                                                            sx={{
                                                                "& .MuiInputBase-input": {
                                                                    color: `${currentMode !== "dark" ? `#555555` : `#ECECEC`}`,
                                                                },
                                                            }}
                                                            {...params}
                                                            inputProps={{
                                                                ...params.inputProps,
                                                                readOnly: true,
                                                            }}
                                                        />
                                                    )}
                                                    value={scheduled_time}
                                                    name="scheduled_time"
                                                    onChange={(value) => formik?.setFieldValue("scheduled_time", value)}
                                                    referenceDate={new Date()}
                                                />
                                            </ThemeProvider>
                                        </LocalizationProvider>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <span className="block mb-1">Repeat Every</span>
                                <div className={`flex items-center space-x-[10px]`}>
                                    {type !== 3 && (
                                        <Input
                                            placeholder=""
                                            type="number"
                                            onKeyPress={handleDayKeyPress}
                                            name="on"
                                            min={1}
                                            onChange={(e) => {
                                                const enteredValue = e.target.value;

                                                // Ensure the entered value is within the specified range (1 to 31)
                                                if (enteredValue >= 1 && enteredValue <= 99) {
                                                    formik?.setFieldValue("on", enteredValue);
                                                } else {
                                                    formik?.setFieldValue("on", "");
                                                }
                                            }}
                                            value={on}
                                            className={`py-[8px] border rounded-[8px] border-${currentMode}-840 bg-${currentMode}-card w-[50px] px-[5px] text-center`}
                                        />
                                    )}

                                    <FormControl sx={{ minWidth: 140 }}>
                                        <Select
                                            name="type"
                                            value={type || "0"}
                                            fullWidth
                                            onChange={formik?.handleChange}
                                            inputProps={{
                                                "aria-label": "Without label",
                                                tabIndex: "1",
                                            }}
                                            IconComponent={() => SelectDropdwnImg(currentMode)}>
                                            <MenuItem key={1} name="OrganisationList" value={0}>
                                                Select
                                            </MenuItem>
                                            <MenuItem value={1}>Week</MenuItem>
                                            <MenuItem tabIndex={2} value={2} className="truncate trunc-1 block">
                                                Month
                                            </MenuItem>
                                            <MenuItem tabIndex={3} value={3} className="truncate trunc-1 block">
                                                Year
                                            </MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>
                            {type === 1 &&
                                DaysRange?.map((el, i) => (
                                    <Checkbox
                                        name="week"
                                        value={week}
                                        checked={week?.includes(el?.value)}
                                        onChange={(e) => handleDays(e, el?.value, el?.text)}
                                        className="p-[6px]"
                                        icon={<img src={el?.imgNormal} alt="tue-normal" />}
                                        checkedIcon={<img src={el?.imgActive} alt="tue-active" />}
                                    />
                                ))}
                            {type === 2 && (
                                <RadioGroup
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    defaultValue={`${selectedOption}`}
                                    name="radio-buttons-group"
                                    className="flex flex-col block space-y-[10px]">
                                    <FormControlLabel
                                        value={"option1"}
                                        className="w-fit"
                                        name="selectedOption"
                                        onChange={() => handleRadio("option1")}
                                        control={
                                            <Radio
                                                icon={<img src="/images/reports/check-nrml.svg" alt="check-nrml" />}
                                                checkedIcon={<img src="/images/reports/check-active.svg" alt="check-active" />}
                                                sx={{
                                                    color: "#D9D9D9",
                                                    "&.Mui-checked": {
                                                        color: "#00B399",
                                                    },
                                                }}
                                            />
                                        }
                                        label={
                                            <div className={`flex items-center space-x-[10px]`}>
                                                <label htmlFor="">On day</label>
                                                <Input
                                                    placeholder=""
                                                    name="day"
                                                    type="tel"
                                                    max="31"
                                                    min="1"
                                                    value={day}
                                                    onKeyPress={handleKeyPress}
                                                    onChange={(e) => {
                                                        const enteredValue = e.target.value;

                                                        // Ensure the entered value is within the specified range (1 to 31)
                                                        if (enteredValue >= 1 && enteredValue <= 31) {
                                                            formik?.setFieldValue("day", enteredValue);
                                                        } else {
                                                            formik?.setFieldValue("day", "");
                                                        }
                                                    }}
                                                    className={`py-[8px] ${Disable(
                                                        "option1"
                                                    )} border rounded-[8px] bg-${currentMode}-card border-${currentMode}-840 w-[50px] px-[5px] text-center`}
                                                />
                                            </div>
                                        }
                                    />
                                    <FormControlLabel
                                        value="option2"
                                        name="selectedOption"
                                        className="w-fit"
                                        onChange={() => handleRadio("option2")}
                                        control={
                                            <Radio
                                                icon={<img src="/images/reports/check-nrml.svg" alt="check-nrml" />}
                                                checkedIcon={<img src="/images/reports/check-active.svg" alt="check-active" />}
                                                sx={{
                                                    color: "#D9D9D9",
                                                    "&.Mui-checked": {
                                                        color: "#00B399",
                                                    },
                                                }}
                                            />
                                        }
                                        label={
                                            <div className={` flex items-center space-x-[10px]`}>
                                                <label htmlFor="" className="mr-[2px]">
                                                    On the
                                                </label>
                                                <div className={`${Disable("option2")} space-x-[10px]`}>
                                                    <FormControl
                                                        sx={{
                                                            minWidth: 140,
                                                        }}>
                                                        <Select
                                                            name="on_starting"
                                                            value={on_starting}
                                                            fullWidth
                                                            onChange={formik?.handleChange}
                                                            IconComponent={() => SelectDropdwnImg(currentMode)}>
                                                            {starting?.map((el, i) => (
                                                                <MenuItem tabIndex={el?.val} value={el?.val} className="truncate trunc-1 block">
                                                                    {el?.start}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                    <FormControl
                                                        sx={{
                                                            minWidth: 140,
                                                        }}>
                                                        <Select
                                                            name="day_select"
                                                            value={selectedOption == "option2" ? day_select : +currentDay + 1}
                                                            fullWidth
                                                            onChange={formik?.handleChange}
                                                            IconComponent={() => SelectDropdwnImg(currentMode)}>
                                                            {Days?.map((el, i) => (
                                                                <MenuItem tabIndex={el?.val} value={el?.val} className="truncate trunc-1 block">
                                                                    {el?.dayName}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                </div>
                                            </div>
                                        }
                                    />
                                </RadioGroup>
                            )}
                            {type === 3 && (
                                <RadioGroup
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    defaultValue={`${selectedOption}`}
                                    name="radio-buttons-group"
                                    className="flex flex-col space-y-[10px]">
                                    <FormControlLabel
                                        value="option1"
                                        className="w-fit"
                                        name="selectedOption"
                                        onChange={() => handleRadio("option1")}
                                        control={
                                            <Radio
                                                icon={<img src="/images/reports/check-nrml.svg" alt="check-nrml" />}
                                                checkedIcon={<img src="/images/reports/check-active.svg" alt="check-active" />}
                                                sx={{
                                                    color: "#D9D9D9",
                                                    "&.Mui-checked": {
                                                        color: "#00B399",
                                                    },
                                                }}
                                            />
                                        }
                                        label={
                                            <div className={`flex items-center space-x-[10px]`}>
                                                <label htmlFor="">On</label>
                                                <div className={`${Disable("option1")}  space-x-[10px]`}>
                                                    <FormControl
                                                        sx={{
                                                            minWidth: 140,
                                                        }}>
                                                        <Select
                                                            name="on_month"
                                                            value={selectedOption == "option1" ? on_month : currentMonth}
                                                            fullWidth
                                                            onChange={formik?.handleChange}
                                                            IconComponent={() => SelectDropdwnImg(currentMode)}>
                                                            {months?.map((el, i) => (
                                                                <MenuItem tabIndex={el?.val} value={el?.val} className="truncate trunc-1 block">
                                                                    {el?.monthName}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                    <Input
                                                        placeholder=""
                                                        name="day"
                                                        type="tel"
                                                        max={31}
                                                        min={1}
                                                        onKeyPress={handleKeyPress}
                                                        value={day}
                                                        onChange={(e) => {
                                                            const enteredValue = e.target.value;

                                                            // Ensure the entered value is within the specified range (1 to 31)
                                                            if (enteredValue >= 1 && enteredValue <= 31) {
                                                                formik?.setFieldValue("day", enteredValue);
                                                            } else {
                                                                formik?.setFieldValue("day", "");
                                                            }
                                                        }}
                                                        className={`py-[8px] border rounded-[8px] bg-${currentMode}-card border-${currentMode}-840 w-[50px] px-[5px] text-center`}
                                                    />
                                                </div>
                                            </div>
                                        }
                                    />
                                    <FormControlLabel
                                        name="selectedOption"
                                        value="option2"
                                        className="w-fit"
                                        onChange={() => handleRadio("option2")}
                                        control={
                                            <Radio
                                                icon={<img src="/images/reports/check-nrml.svg" alt="check-nrml" />}
                                                checkedIcon={<img src="/images/reports/check-active.svg" alt="check-active" />}
                                                sx={{
                                                    color: "#D9D9D9",
                                                    "&.Mui-checked": {
                                                        color: "#00B399",
                                                    },
                                                }}
                                            />
                                        }
                                        label={
                                            <div className={` flex items-center space-x-[10px]`}>
                                                <label htmlFor="" className="mr-[2px]">
                                                    On the
                                                </label>
                                                <div className={`${Disable("option2")} flex items-center  space-x-[10px]`}>
                                                    <FormControl
                                                        sx={{
                                                            minWidth: 140,
                                                        }}>
                                                        <Select
                                                            name="on_starting"
                                                            value={selectedOption == "option2" ? on_starting : 1}
                                                            fullWidth
                                                            onChange={formik?.handleChange}
                                                            IconComponent={() => SelectDropdwnImg(currentMode)}>
                                                            {starting?.map((el, i) => (
                                                                <MenuItem tabIndex={el?.val} value={el?.val} className="truncate trunc-1 block">
                                                                    {el?.start}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                    <FormControl
                                                        sx={{
                                                            minWidth: 140,
                                                        }}>
                                                        <Select
                                                            name="day_select"
                                                            value={selectedOption == "option2" ? day_select : +currentDay + 1}
                                                            fullWidth
                                                            onChange={formik?.handleChange}
                                                            IconComponent={() => SelectDropdwnImg(currentMode)}>
                                                            {Days?.map((el, i) => (
                                                                <MenuItem tabIndex={el?.val} value={el?.val} className="truncate trunc-1 block">
                                                                    {el?.dayName}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                    <label htmlFor="" className="mr-[2px]">
                                                        of
                                                    </label>
                                                    <FormControl
                                                        className="block"
                                                        sx={{
                                                            minWidth: 140,
                                                        }}>
                                                        <Select
                                                            name="on_month"
                                                            value={selectedOption == "option2" ? on_month : currentMonth}
                                                            fullWidth
                                                            onChange={formik?.handleChange}
                                                            IconComponent={() => SelectDropdwnImg(currentMode)}>
                                                            {months?.map((el, i) => (
                                                                <MenuItem tabIndex={el?.val} value={el?.val} className="truncate trunc-1 block">
                                                                    {el?.monthName}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                </div>
                                            </div>
                                        }
                                    />
                                </RadioGroup>
                            )}
                            <div className="flex items-center space-x-[10px]">
                                <div className={`datePic`}>
                                    <span className="block mb-1">End Date</span>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DesktopDatePicker
                                            className={`border-0 max-w-[200px]`}
                                            components={{
                                                OpenPickerIcon: AccessIcon,
                                            }}
                                            inputFormat={UserDateFormat?.toUpperCase()}
                                            value={enddate}
                                            name="enddate"
                                            minDate={startdate}
                                            onChange={(date) => formik?.setFieldValue("enddate", date?.$d)}
                                            sx={{
                                                ".MuiOutlinedInput-input": {
                                                    padding: "10px",
                                                },
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    sx={{
                                                        "& .MuiInputBase-input": {
                                                            color: `${currentMode !== "dark" ? `#555555` : `#ECECEC`}`,
                                                        },
                                                    }}
                                                    {...params}
                                                    inputProps={{
                                                        ...params.inputProps,
                                                        readOnly: true,
                                                    }}
                                                />
                                            )}
                                        />
                                    </LocalizationProvider>
                                </div>
                            </div>
                            <p>{OccureText()}</p>
                        </>
                    )}
                </div>
            </div>
            <div className={`flex ${delivery_opt === "" ? "items-center" : "items-start"} px-[30px]`}>
                <label className={`xl:basis-[10%] ${delivery_opt === "" ? "" : "mt-[10px]"} basis-[30%]`} htmlFor="">
                    Delivery Option:
                </label>
                <div className="basis-[90%] space-y-[10px] filter solo">
                    <div className="space-x-[10px]">
                        <FormControl sx={{ minWidth: 200 }}>
                            <Select
                                name="delivery_opt"
                                value={delivery_opt || "0"}
                                fullWidth
                                onChange={formik?.handleChange}
                                inputProps={{
                                    "aria-label": "Without label",
                                    tabIndex: "1",
                                }}
                                IconComponent={() => SelectDropdwnImg(currentMode)}>
                                <MenuItem key={0} name="OrganisationList" value={0}>
                                    {"Select "}
                                </MenuItem>
                                <MenuItem tabIndex={1} value={1} className="truncate trunc-1 block">
                                    Email
                                </MenuItem>
                                <MenuItem tabIndex={2} value={2} className="truncate trunc-1 block">
                                    Drive
                                </MenuItem>
                                <MenuItem tabIndex={3} value={3} className="truncate trunc-1 block">
                                    FTP
                                </MenuItem>
                                <MenuItem tabIndex={4} value={4} className="truncate trunc-1 block">
                                    SFTP
                                </MenuItem>
                                {(prevtype == 4 || prevtype == 5) && (
                                    <MenuItem tabIndex={5} value={5} className="truncate trunc-1 block">
                                        API
                                    </MenuItem>
                                )}
                            </Select>
                        </FormControl>
                    </div>
                    {delivery_opt === 1 && (
                        <>
                            {" "}
                            <Input
                                className={`border py-2 w-full max-w-[410px] rounded-[8px] px-[15px] bg-${currentMode}-card border-${currentMode}-840`}
                                placeholder="Subject"
                                name="delivery.subject"
                                onChange={formik?.handleChange}
                                value={delivery?.subject}
                            />
                            <div>
                                <Autocomplete
                                    multiple
                                    options={[]}
                                    name="delivery.to"
                                    value={delivery?.to === undefined ? [] : delivery?.to}
                                    disablePortal
                                    disableClearable
                                    freeSolo
                                    style={{ maxWidth: 410 }}
                                    limitTags={1}
                                    inputValue={toInput}
                                    onInputChange={(e, newValue) => {
                                        setToInput(newValue);
                                        formik?.setFieldError("delivery.to", "");
                                    }}
                                    onChange={(e, newValues, reason) => {
                                        // Validate emails
                                        const invalidEmail = newValues.find((email) => !isEmailregex.test(email));
                                        // Set the error if an invalid email is found
                                        if (invalidEmail) {
                                            formik?.setFieldError("delivery.to", "Invalid email address");
                                        } else {
                                            formik?.setFieldError("delivery.to", ""); // Clear the error
                                            formik.setFieldValue("delivery.to", newValues);
                                        }
                                    }}
                                    renderTags={(value, getTagProps) =>
                                        value.length > 0 &&
                                        value?.map((option, index) => (
                                            <Chip
                                                key={index}
                                                variant="outlined"
                                                label={option}
                                                {...getTagProps({
                                                    index,
                                                })}
                                            />
                                        ))
                                    }
                                    renderInput={(params) => (
                                        <div>
                                            <TextField placeholder="To" fullWidth {...params} />
                                        </div>
                                    )}
                                />
                                {formik?.errors?.delivery?.to && <p className="text-[red]">{formik?.errors?.delivery?.to}</p>}
                            </div>
                            <div>
                                <div className="relative w-[410px]">
                                    <Autocomplete
                                        multiple
                                        options={[]}
                                        name="delivery.cc"
                                        value={delivery?.cc === undefined ? [] : delivery?.cc}
                                        disablePortal
                                        disableClearable
                                        freeSolo
                                        style={{ maxWidth: 410 }}
                                        inputValue={ccInput}
                                        onInputChange={(e, newValue) => {
                                            setCcInput(newValue);
                                            formik?.setFieldError("delivery.cc", "");
                                        }}
                                        limitTags={1}
                                        onChange={(e, newValues, reason) => {
                                            // Validate emails
                                            const invalidEmail = newValues.find((email) => !isEmailregex.test(email));
                                            // Set the error if an invalid email is found
                                            if (invalidEmail) {
                                                formik?.setFieldError("delivery.cc", "Invalid email address");
                                            } else {
                                                formik?.setFieldError("delivery.cc", ""); // Clear the error
                                                formik.setFieldValue("delivery.cc", newValues);
                                            }
                                        }}
                                        renderTags={(value, getTagProps) =>
                                            value.length > 0 &&
                                            value?.map((option, index) => (
                                                <Chip
                                                    key={index}
                                                    variant="outlined"
                                                    label={option}
                                                    {...getTagProps({
                                                        index,
                                                    })}
                                                />
                                            ))
                                        }
                                        renderInput={(params) => (
                                            <div>
                                                <TextField placeholder="Cc" fullWidth {...params} />
                                            </div>
                                        )}
                                    />
                                    {!showBcc && (
                                        <span
                                            onClick={() => setShowBcc(true)}
                                            className={`absolute cursor-pointer right-[1px] text-${currentMode}-1260 border-l border-${currentMode}-840 top-[50%] translate-y-[-50%] bg-${currentMode}-1250 py-[8px] px-[10px] rounded-r-[8px]`}>
                                            Bcc
                                        </span>
                                    )}
                                </div>

                                {formik?.errors?.delivery?.cc && <p className="text-[red]">{formik?.errors?.delivery?.cc}</p>}
                            </div>
                            {showBcc && (
                                <div>
                                    <Autocomplete
                                        multiple
                                        options={[]}
                                        name="delivery.bcc"
                                        value={delivery?.bcc === undefined ? [] : delivery?.bcc}
                                        disablePortal
                                        disableClearable
                                        freeSolo
                                        style={{ maxWidth: 410 }}
                                        limitTags={1}
                                        inputValue={bccInput}
                                        onInputChange={(e, newValue) => {
                                            setBccInput(newValue);
                                            formik?.setFieldError("delivery.bcc", "");
                                        }}
                                        onChange={(e, newValues, reason) => {
                                            // Validate emails
                                            const invalidEmail = newValues.find((email) => !isEmailregex.test(email));
                                            // Set the error if an invalid email is found
                                            if (invalidEmail) {
                                                formik?.setFieldError("delivery.bcc", "Invalid email address");
                                            } else {
                                                formik?.setFieldError("delivery.bcc", ""); // Clear the error
                                                formik?.setFieldValue("delivery.bcc", newValues);
                                            }
                                        }}
                                        renderTags={(value, getTagProps) =>
                                            value.length > 0 &&
                                            value?.map((option, index) => (
                                                <Chip
                                                    key={index}
                                                    variant="outlined"
                                                    label={option}
                                                    {...getTagProps({
                                                        index,
                                                    })}
                                                />
                                            ))
                                        }
                                        renderInput={(params) => (
                                            <div>
                                                <TextField placeholder="Bcc" fullWidth {...params} />
                                            </div>
                                        )}
                                    />
                                    {formik?.errors?.Email?.bcc && <p className="text-[red]">{formik?.errors?.delivery?.bcc}</p>}
                                </div>
                            )}
                            <textarea
                                placeholder="Enter message"
                                rows="5"
                                name="delivery.message"
                                cols=""
                                value={delivery?.message}
                                onChange={formik?.handleChange}
                                className={`border-[1px] min-w-[410px] bg-${currentMode}-card rounded-[8px] px-[15px] py-2 resize-none border-${currentMode}-840`}
                            />
                        </>
                    )}
                    {delivery_opt === 2 && (
                        <div>
                            <div className="flex space-x-2 mb-[10px]">
                                <GoogleLogin
                                    clientId={clientId}
                                    render={(renderProps) => (
                                        <Button
                                            onClick={renderProps?.onClick}
                                            className={`border-${currentMode}-840 px-5 py-[9px] ${
                                                oAuth == 1 ? `bg-${currentMode}-680` : ``
                                            } rounded-[8px] font-interSb text-sm flex items-center border-[1px] text-${currentMode}-40`}>
                                            <img className="mr-2" src="/images/common/googledrive.svg" alt="googledrive" />
                                            GoogleDrive
                                        </Button>
                                    )}
                                    onSuccess={onSuccess}
                                    onFailure={onFailure}
                                    cookiePolicy={"single_host_origin"}
                                    accessType={"offline"}
                                    prompt={"consent"}
                                    responseType={"code"}
                                    scope={"https://www.googleapis.com/auth/drive"}
                                />

                                <ReactLoginMS
                                    clientId={msClientId}
                                    redirectUri={process.env?.REACT_APP_MS_REDIRECT_URL + "/msAuthComplete.html"}
                                    scopes={["Mail.ReadWrite", "MailboxSettings.ReadWrite"]}
                                    responseType="code"
                                    cssClass={` ${
                                        oAuth == 2
                                            ? `bg-${currentMode}-1290 border-[1.5px] border-${currentMode}-20`
                                            : `border-${currentMode}-840 border`
                                    }
                      px-5 py-[9px] rounded-[8px] font-interSb text-sm flex items-center  text-${currentMode}-40`}
                                    btnContent={btnContent}
                                    handleLogin={(data) => loginMicrosoftHandler(data)}
                                />
                            </div>
                            <div className="max-w-[410px]">
                                <div className="mb-[10px]">
                                    <Input
                                        disabled
                                        value={email}
                                        onChange={(e) => emailOnchange(e)}
                                        placeholder="Email Id"
                                        name="emailaddress"
                                        className={`w-full py-2 rounded-[8px] px-5 bg-${currentMode}-card  border-[1px] border-${currentMode}-840`}
                                    />
                                    {email && !validEmail ? <p className="text-light-50 text-xs">{"Please add your business Email Id"}</p> : ""}
                                </div>
                                <div className="mb-[10px]">
                                    <Input
                                        onChange={(e) => setFolderName(e.target.value)}
                                        placeholder="Folder Name"
                                        name="foldername"
                                        value={folderName}
                                        className={`w-full py-2 rounded-[8px] px-5 bg-${currentMode}-card  border-[1px] border-${currentMode}-840`}
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                    {(delivery_opt === 3 || delivery_opt === 4) && (
                        <>
                            <div className="flex space-x-[10px]">
                                <div>
                                    <span className="block mb-1">{delivery_opt == 3 ? `FTP` : `SFTP`} Server - Host Name</span>
                                    <Input
                                        className={`border min-w-[200px] max-w-[200px] py-2 rounded-[8px] px-[15px] bg-${currentMode}-card border-${currentMode}-840`}
                                        placeholder="Enter host name"
                                        value={delivery?.host}
                                        name="delivery.host"
                                        onChange={formik?.handleChange}
                                    />
                                </div>
                                <div>
                                    <span className="block mb-1">Port</span>
                                    <Input
                                        className={`border min-w-[200px] max-w-[200px] py-2 rounded-[8px] px-[15px] bg-${currentMode}-card border-${currentMode}-840`}
                                        placeholder="Enter port no."
                                        value={delivery?.port}
                                        name="delivery.port"
                                        type="number"
                                        onChange={formik?.handleChange}
                                    />
                                </div>
                            </div>
                            <div className="flex space-x-[10px]">
                                <div>
                                    <span className="block mb-1">User Name</span>
                                    <Input
                                        value={delivery?.user}
                                        name="delivery.user"
                                        autoComplete="off"
                                        onChange={formik?.handleChange}
                                        className={`border min-w-[200px] max-w-[200px] py-2 rounded-[8px] bg-${currentMode}-card px-[15px] border-${currentMode}-840`}
                                        placeholder="User name"
                                    />
                                </div>
                                <div>
                                    <span className="block mb-1">Password</span>
                                    <div className="relative">
                                        <Input
                                            value={delivery?.password}
                                            name="delivery.password"
                                            autoComplete="new-password"
                                            type={isRevealPwd ? "text" : "password"}
                                            onChange={formik?.handleChange}
                                            className={`border max-w-[200px] py-2 rounded-[8px] pl-[15px] pr-[30px] bg-${currentMode}-card border-${currentMode}-840`}
                                            placeholder="&bull; &bull; &bull; &bull; &bull; &bull;"
                                        />
                                        <Button
                                            type="button"
                                            onClick={() => setIsRevealPwd((prevState) => !prevState)}
                                            className="absolute cursor-pointer top-[57%] translate-y-[-57%] right-[10px]">
                                            <img
                                                className="w-[15px]"
                                                title={isRevealPwd ? "Hide password" : "Show password"}
                                                src={isRevealPwd ? `/images/common/eyeOpen.svg` : `/images/common/eyeClose.svg`}
                                            />
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                    {delivery_opt == 5 && (
                        <>
                            <div className="flex space-x-[10px]">
                                <div>
                                    <span className="block mb-1">URL</span>
                                    <Input
                                        name="delivery.url"
                                        value={delivery?.url}
                                        onChange={formik?.handleChange}
                                        className={`border min-w-[200px] max-w-[200px] py-2 rounded-[8px] px-[15px] border-${currentMode}-840`}
                                        placeholder="https://www.example.com"
                                    />
                                    {formik?.errors?.delivery?.url && <p className="text-[red]">{formik?.errors?.delivery?.url}</p>}
                                </div>
                                <div>
                                    <span className="block mb-1">Authentication</span>
                                    <FormControl sx={{ minWidth: 200 }}>
                                        <Select
                                            name="delivery.authentic"
                                            value={delivery?.authentic || "1"}
                                            onChange={formik?.handleChange}
                                            fullWidth
                                            inputProps={{
                                                "aria-label": "Without label",
                                                tabIndex: "1",
                                            }}
                                            IconComponent={() => SelectDropdwnImg(currentMode)}>
                                            <MenuItem tabIndex={1} value={1} className="truncate trunc-1 block">
                                                No Authentication
                                            </MenuItem>
                                            <MenuItem tabIndex={2} value={2} className="truncate trunc-1 block">
                                                Basic Authentication
                                            </MenuItem>
                                            <MenuItem tabIndex={3} value={3} className="truncate trunc-1 block">
                                                API Key
                                            </MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>
                            {delivery?.authentic == 3 && (
                                <>
                                    <div className="flex space-x-[10px]">
                                        <div>
                                            <span className="block mb-1">Key</span>
                                            <Input
                                                name="delivery.key"
                                                value={delivery?.key}
                                                onChange={formik?.handleChange}
                                                className={`border min-w-[200px] max-w-[200px] py-2 rounded-[8px] px-[15px] border-${currentMode}-840`}
                                                placeholder="Enter Key"
                                            />
                                        </div>
                                        <div>
                                            <span className="block mb-1">Value</span>
                                            <Input
                                                name="delivery.value"
                                                value={delivery?.value}
                                                onChange={formik?.handleChange}
                                                className={`border min-w-[200px] max-w-[200px] py-2 rounded-[8px] px-[15px] border-${currentMode}-840`}
                                                placeholder="{{my-username}}"
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        <span className="block mb-1">Add to</span>
                                        <FormControl sx={{ minWidth: 200 }}>
                                            <Select
                                                name="delivery.add_to"
                                                value={delivery?.add_to || "0"}
                                                onChange={formik?.handleChange}
                                                fullWidth
                                                inputProps={{
                                                    "aria-label": "Without label",
                                                    tabIndex: "1",
                                                }}
                                                IconComponent={() => SelectDropdwnImg(currentMode)}>
                                                <MenuItem key={0} name="OrganisationList" value={0}>
                                                    {"Select "}
                                                </MenuItem>
                                                <MenuItem tabIndex={1} value={1} className="truncate trunc-1 block">
                                                    Header
                                                </MenuItem>
                                                <MenuItem tabIndex={2} value={2} className="truncate trunc-1 block">
                                                    Query Params
                                                </MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div>
                                </>
                            )}
                            {delivery?.authentic == 2 && (
                                <div className="flex space-x-[10px]">
                                    <div>
                                        <span className="block mb-1">Username</span>
                                        <Input
                                            name="delivery.username"
                                            value={delivery?.username}
                                            onChange={formik?.handleChange}
                                            className={`border min-w-[200px] max-w-[200px] py-2 rounded-[8px] px-[15px] border-${currentMode}-840`}
                                            placeholder="Enter the Username"
                                        />
                                    </div>
                                    <div>
                                        <span className="block mb-1">Password</span>
                                        <div className="relative">
                                            <Input
                                                name="delivery.apipasword"
                                                value={delivery?.apipasword}
                                                autoComplete="new-password"
                                                type={isRevealPwd ? "text" : "password"}
                                                onChange={formik?.handleChange}
                                                className={`border min-w-[200px] max-w-[200px] py-2 rounded-[8px] pl-[15px] pr-[30px] border-${currentMode}-840`}
                                                placeholder="&bull; &bull; &bull; &bull; &bull; &bull;"
                                            />
                                            <Button
                                                type="button"
                                                onClick={() => setIsRevealPwd((prevState) => !prevState)}
                                                className="absolute cursor-pointer top-[50%] translate-y-[-50%] right-[10px]">
                                                <img
                                                    className="w-[15px]"
                                                    title={isRevealPwd ? "Hide password" : "Show password"}
                                                    src={isRevealPwd ? `/images/common/eyeOpen.svg` : `/images/common/eyeClose.svg`}
                                                />
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </>
                    )}
                </div>
            </div>
        </>
    );
};
