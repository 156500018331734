import Input from "../../../components/Input";
import { useState, useContext } from "react";
import Swal from "sweetalert2";
import { ThemeContext } from "../../../Hooks/Themes/Themeprovider";
const InputTrigger = (props) => {
    const { currentMode } = useContext(ThemeContext);
    const [mockTimer, setMockTimer] = useState(props?.timerValue);
    const handleTimerVal = (e) => {
        if (+e.target.value > 60) {
            Swal.fire({
                text: `${`Cannot set interval more than 60 seconds`}`,
                icon: "warning",
                closeOnClickOutside: false,
                showCloseButton: true,
                button: "Ok",
            }).then((confirm) => {
                if (confirm) {
                    setMockTimer("");
                    props?.setTimerValue("");
                }
            });
        } else {
            setMockTimer(e.target.value);
            localStorage.setItem("PlayInterval", e.target.value);
        }
    };
    return (
        <>
            <Input
                onChange={handleTimerVal}
                type="number"
                className={`bg-[#DAF9F4] mt-[6px] w-[36px] ml-[4px] ${
                    currentMode == "dark" ? "text-black" : ""
                }  text-center px-[4px] rounded-[5px] h-[22px]`}
                value={mockTimer}
                maxLength={3}
                onBlur={() => props?.setTimerValue(mockTimer)}
            />
        </>
    );
};

export default InputTrigger;
