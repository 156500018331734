import { Checkbox, ClickAwayListener, FormControl, FormControlLabel, MenuItem, Popper, Select } from "@mui/material";
import _, { remove, upperCase } from "lodash";
import { useContext, useEffect, useState } from "react";
import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../../../components/Button";
import Loader from "../../../../components/Loader";
import Card from "../../../../components/UI/Card";
import ExportExcel from "../../../ExportFiles/Excel";
import { jsPDF } from "jspdf";
import TogglePopup from "../../../organisations/Toggle";
import "jspdf-autotable";
import html2canvas from "html2canvas";
import { ThemeContext } from "../../../../Hooks/Themes/Themeprovider";
import { CustomReportsList } from "../../../../redux/actions/output-management";
import { CUSTOM_REPORTS_LIST_RESET } from "../../../../redux/types/types";
import * as XLSX from "xlsx";
import { extractTextFromHTML } from "../../../../utils/helpers";
let exportArr = [];
const CustomReportsTable = (props) => {
    const { currentMode } = useContext(ThemeContext);
    let optionval = ["Task Id", "Task Name", "Invoice No.", "Created By", "Assignees", "Due date", "Status"];
    const [values, setValues] = useState(optionval);
    const [showOptions, setShowOptions] = useState("");
    const [selectedValue, changeFilter] = useState(1);
    const [currentPageNo, changePageNo] = useState(1);
    const [currentPageItem, changePageItem] = useState(10);
    const [isdownloadClick, onClickDownload] = useState(false);
    const [exportFile, setExportFile] = useState(false);
    const selAging = props?.selAging;
    const [popstate, setPopState] = useState({
        value: null,
        anchorEl: null,
        id: null,
    });
    const [isMount, setIsMount] = useState(false);
    const [placement, setPlacement] = useState();
    const { data, fetching } = useSelector((state) => state?.customReportsListState);
    const ListData = useSelector((state) => state?.customReportsListState);
    const datalist = data?.[0]?.data_info;

    const pageCountDetail = data?.[0]?.record_count;
    const pageCount = localStorage.getItem("CustomPageCount");

    const reqBody = {
        json: props?.extractedData,
        id: props?.reqBody?.id,
        pgno: props?.currentPageNo,
        reccnt: props?.currentPageItem,
    };
    useEffect(() => {
        if (props?.currentPageNo == 1) {
            localStorage.setItem("CustomPageCount", pageCountDetail);
        }
        props?.setSearch(reqBody);
    }, [currentPageNo, currentPageItem, pageCountDetail, props?.props?.setSearch, props?.currentPageNo]);

    const handleClickAway = () => {
        setPopState({ id: false, anchorEl: false, value: "" });
    };
    const handleClick = (newPlacement, value, event, id = null) => {
        setPopState({ value, anchorEl: event.currentTarget, id }, (prev) => placement !== newPlacement || !prev);
        setPlacement(newPlacement);
    };
    const checkChange = (e) => {
        const elem = e.target.value;
        if (!e.target.checked) {
            const Remove = values?.filter((el) => el !== elem);
            setValues(Remove);
            localStorage.setItem("taskReportOption", JSON.stringify(values));
        } else {
            setValues([...values, elem]);
            localStorage.setItem("taskReportOption", JSON.stringify(values));
        }
    };

    useEffect(() => {
        let Check = JSON.parse(localStorage.getItem("taskReportOption"));
        if (Check !== null && Check !== undefined && Check !== "") {
            setValues(JSON.parse(localStorage.getItem("taskReportOption")));
        } else {
            localStorage.setItem("taskReportOption", JSON.stringify(optionval));
            setValues(optionval);
        }
    }, []);
    useEffect(() => {
        if (!localStorage.getItem("taskReportOption")) {
            localStorage.setItem("taskReportOption", JSON.stringify(values));
        }
        localStorage.setItem("taskReportOption", JSON.stringify(values));
    }, [values]);

    const [isAllSelected, setAllSelected] = useState(false);
    const [isIndeterminate, setIndeterminate] = useState(false);
    const [isSorting, setSorting] = useState(0);
    const allocid = localStorage.getItem("UserDropdownId");

    const pageNo = props?.currentPageNo;
    const entries = props?.currentPageItem;

    const pageData = useSelector((state) => state?.reportsLicenseCountState);

    let start = (pageNo - 1) * entries + 1;
    let totalPageCount = datalist ? datalist.length * (pageNo ? pageNo : 1) : 0;
    const totalPageExceed = pageNo * entries;
    if (totalPageExceed >= pageCount) {
        totalPageCount = pageCount;
    }
    const nextPageCount = Math.ceil(pageCount / entries);
    const CountCalc = pageCount / entries;

    const Rounded = Math.ceil(CountCalc);
    let EmptyArr = [];
    for (let i = 0; i < Rounded; i++) {
        EmptyArr.push(i);
    }
    const onPageItemSelect = (event) => {
        // setAllSelected(false);
        let pageItemVal = event.target.value;
        props?.changePageItem(parseInt(pageItemVal));
        props?.changePageNo(1);
    };
    const onPageNumberSelect = (event) => {
        let pageNumber = event.target.value;
        props?.changePageNo(parseInt(pageNumber));
    };
    const previousBtnClick = () => {
        props?.changePageNo(props?.currentPageNo - 1);
    };
    const nextBtnClick = () => {
        props?.changePageNo(props?.currentPageNo + 1);
    };
    const dispatch = useDispatch();
    // const datalist = [
    //     {
    //         "id": 25283,
    //         "invoice_no": "1164767",
    //         "Vendor_name": "ven"
    //     },
    //     {
    //         "id": 3359,
    //         "invoice_no": 116476798,
    //         "Vendor_name": "vendor"
    //     }
    // ];
    const exportRef = useRef();

    const onExportExcel = () => {
        setExportFile(true);
        const reqBodyCsv = {
            json: props?.extractedData,
            id: props?.reqBody?.id,
            pgno: 1,
            reccnt: pageCount,
            output_type: props?.reqBody?.output_type,
        };
        if (datalist !== null) {
            dispatch(CustomReportsList(props?.reqBody, "download"));
        }
        if (datalist?.length > 0) {
            onClickDownload(true);
            dispatch(CustomReportsList(reqBodyCsv));
            setExportFile(true);
        }
    };

    const DataHeaders = props?.HeaderList;
    const DataHeader = DataHeaders?.sort((a, b) => a?.Order - b?.Order);
    const tableData = datalist?.map((item) => {
        const rowData = {};
        DataHeader?.forEach((header) => {
            rowData[header?.column_name] = item[header?.column] !== null ? item[header?.column_name] : "";
        });
        return rowData;
    });

    useEffect(() => {
        if (isMount) {
            async function fetchEmployees() {
                await dispatch(CustomReportsList(props?.reqBody));
            }
            fetchEmployees();
        } else {
            setIsMount(true);
            dispatch({ type: CUSTOM_REPORTS_LIST_RESET });
        }
    }, [dispatch, props?.currentPageNo, props?.currentPageItem]);
    const downloadeExcel = (downloadInvoiceList) => {
        if (downloadInvoiceList) {
            const invoiceExcelData = [];

            datalist?.forEach((item) => {
                const { id, ...rest } = item;
                const newObj = { id };

                for (const key in rest) {
                    newObj[key] = rest[key];
                }

                invoiceExcelData.push(newObj);
            });

            ExportExcel({ excelData: invoiceExcelData, fileName: "MyiQ.Ai" });

            onClickDownload(false);
            setAllSelected(false);
        }
    };
    if (isdownloadClick) {
        let downloadInvoiceList = datalist;
        downloadeExcel(downloadInvoiceList);
    }
    const ExportList = [
        {
            text: "Excel",
            title: "To allow Organization Access",
            src: "/images/common/excel.svg",
            value: 0,
        },
        {
            text: "CSV",
            title: "To allow Organization Access",
            src: "/images/common/excel.svg",
            value: 1,
        },
        {
            text: "PDF",
            title: "To View the Organization Access",
            src: "/images/common/excel.svg",
            value: 2,
        },
        {
            text: "JPG",
            title: "To View the Organization Access",
            src: "/images/common/excel.svg",
            value: 4,
        },
    ];

    const onFilterItems = (value, id) => {
        setShowOptions(value);
    };
    const filterMenuList = ["Task Id", "Task Name", "Invoice No.", "Assignees", "Due date", "Created By", "Status", "Priority"];

    const dropdownColor = currentMode === "dark" ? "#333333" : `white`;
    const dropdownText = currentMode === "dark" ? "#ECECEC" : `#555555`;
    const dropdownShadow = currentMode !== "dark" ? "0px 4px 20px rgba(238, 238, 238, 0.5)" : "";
    const dropdownBorder = currentMode !== "dark" ? "223, 225, 226, 0.56" : "";

    const dataHeader = [{ column: "invoice_no" }, { column: "Vendor_name" }];

    const handleChange = (event) => {
        changeFilter(event.target.value);
    };
    const jsonData = [
        { name: "John Doe", age: 30, email: "john@example.com" },
        { name: "Jane Smith", age: 25, email: "jane@example.com" },
    ];
    const [csvDownload, setCsvDownlaod] = useState(false);
    const [pdfDownload, setPdfDownload] = useState(false);
    const [excelDownload, setexcelDownload] = useState(false);
    const tableRef = useRef(null);
    const downloadPdf = () => {
        if (!tableRef.current) return;

        // Get the table element
        const table = tableRef.current;

        // Convert the table to an image using html2canvas
        html2canvas(table).then((canvas) => {
            const imgData = canvas.toDataURL("image/png");
            const pdf = new jsPDF();
            const imgWidth = 210;
            const imgHeight = (canvas.height * imgWidth) / canvas.width;

            // Add the image to the PDF
            pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight);
            pdf.save(`${props?.reportName}.pdf`);
        });
    };

    // const generatePDF = (datalist, dataHeader) => {
    //     const doc = new jsPDF();
    //     const tableData = [];

    //     if (datalist?.length !== 0 && datalist !== undefined && datalist !== null && datalist !== null) {
    //         datalist?.forEach((item) => {
    //             const rowData = [];
    //             DataHeader?.forEach((header) => {
    //                 const cellValue = item[header?.column_name] !== null ? item[header?.column_name] : '';
    //                 rowData?.push(cellValue);
    //             });
    //             tableData?.push(rowData);
    //         });
    //     } else {
    //         doc.text("No data found", 10, 10);
    //     }

    //     // Calculate column widths
    //     const columnWidths = DataHeader?.map((header) => ({
    //         columnWidth: Math?.max(doc?.getStringUnitWidth(header?.display_name) * 5, DataHeader?.length), // Adjust the multiplier (6) as needed
    //     }));

    //     // Create table in the PDF
    //     doc?.autoTable({
    //         head: [DataHeader?.map((header) => header?.display_name)],
    //         body: tableData,
    //         margin: { top: 20 }, // Adjust the margin as needed
    //         startY: 25, // Adjust the startY as needed
    //         styles: { overflow: "linebreak" },
    //         columnStyles: columnWidths,
    //     });

    //     doc?.save(`${props?.reportName}.pdf`);
    //     setPdfDownload(false)
    // };

    const generateCSV = (datalist, DataHeader) => {
        const itemsPerPage = 20; // Number of rows per slide
        let csvContent = "";

        // Add header row
        csvContent += DataHeader.map((header) => `"${header?.display_name}"`).join(",") + "\n";

        // Add data rows
        datalist.forEach((item) => {
            csvContent += DataHeader.map((header) => `"${extractTextFromHTML(item[header.column_name]) ?? ""}"`).join(",") + "\n";
        });
        // Download CSV file
        const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
        const link = document.createElement("a");
        if (link.download !== undefined) {
            const url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", `${props?.reportName}.csv`);
            link.style.visibility = "hidden";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
        setCsvDownlaod(false);
    };
    const generateExcelSlides = (datalist, DataHeader) => {
        const wb = XLSX.utils.book_new();
        const wsData = [
            DataHeader.map((header) => header?.display_name), // Header row
            ...datalist.map((item) => DataHeader.map((header) => extractTextFromHTML(item[header.column_name]) ?? "")), // Data rows
        ];
        const ws = XLSX.utils.aoa_to_sheet(wsData);
        XLSX.utils.book_append_sheet(wb, ws, "Data");
        XLSX.writeFile(wb, `${props?.reportName}.xlsx`);
        setexcelDownload(false);
    };

    const handleCSVDownload = async () => {
        setCsvDownlaod(true);
        dispatch({ type: CUSTOM_REPORTS_LIST_RESET });
        const reqBodyCsv = {
            json: props?.extractedData,
            id: props?.reqBody?.id,
            pgno: 1,
            reccnt: pageCount,
            output_type: props?.reqBody?.output_type,
        };
        dispatch(CustomReportsList(reqBodyCsv));
    };
    const handlePdfDownload = async () => {
        setPdfDownload(true);
        dispatch({ type: CUSTOM_REPORTS_LIST_RESET });
        const reqBodyCsv = {
            json: props?.extractedData,
            id: props?.reqBody?.id,
            pgno: 1,
            reccnt: pageCount,
            output_type: props?.reqBody?.output_type,
        };
        dispatch(CustomReportsList(reqBodyCsv));
    };
    const handleExcelDownLoad = async () => {
        setexcelDownload(true);
        dispatch({ type: CUSTOM_REPORTS_LIST_RESET });
        const reqBodyCsv = {
            json: props?.extractedData,
            id: props?.reqBody?.id,
            pgno: 1,
            reccnt: pageCount,
            output_type: props?.reqBody?.output_type,
        };
        dispatch(CustomReportsList(reqBodyCsv));
    };

    if (csvDownload && datalist?.length == pageCount) {
        generateCSV(datalist, DataHeader);
    }
    // if (pdfDownload && datalist?.length == pageCount) {
    //     generatePDF(datalist, DataHeader);
    // }
    if (excelDownload && datalist?.length == pageCount) {
        generateExcelSlides(datalist, DataHeader);
    }

    return (
        <>
            <Card className="mb-[4rem] ">
                <div className="flex justify-between px-[30px] py-[20px]">
                    <div className="flex my-auto">
                        {datalist?.length !== 0 && datalist !== undefined && datalist !== null && (
                            <div className="">
                                <span>Show</span>
                                <Select
                                    name="entrycount"
                                    className={`insideTble mx-3 text-xs entries bg-${currentMode}-card`}
                                    value={props?.currentPageItem}
                                    onChange={(e) => onPageItemSelect(e)}
                                    IconComponent={() => (
                                        <img
                                            className="absolute right-[8px] opacity-50 pointer-events-none"
                                            src={currentMode !== "dark" ? `/images/header/chevronDown.svg` : `/images/header/chevronDown-white.svg`}
                                            alt="chevronDown"
                                        />
                                    )}
                                    SelectDisplayProps={{
                                        style: {
                                            paddingTop: "5px",
                                            display: "flex",
                                            paddingBottom: "5px",
                                            paddingLeft: "10px",
                                            border: 0,
                                            color: currentMode === "dark" ? `#ECECEC` : `#555555`,
                                            paddingRight: "25px",
                                        },
                                    }}
                                    MenuProps={{
                                        sx: {
                                            "& .MuiMenu-paper": {
                                                backgroundColor: dropdownColor,
                                                color: dropdownText,
                                                boxShadow: dropdownShadow,
                                                border: dropdownBorder,
                                                borderRadius: "8px",
                                            },
                                        },
                                    }}
                                >
                                    <MenuItem value={10}>{10}</MenuItem>
                                    <MenuItem value={15}>{15}</MenuItem>
                                    <MenuItem value={20}>{20}</MenuItem>
                                    <MenuItem value={25}>{25}</MenuItem>
                                    <MenuItem value={50}>{50}</MenuItem>
                                    <MenuItem value={75}>{75}</MenuItem>
                                    <MenuItem value={100}>{100}</MenuItem>
                                </Select>
                                <span>entries</span>
                            </div>
                        )}
                    </div>

                    {datalist?.length !== 0 && datalist !== undefined && datalist !== null && (
                        <div className="flex items-center space-x-2">
                            {/* <div>
                                <Button
                                    title="To Export Invoice to Excel"
                                    onClick={() => onExportExcel()}
                                    className={`border-20 mb-2 export-step px-5 py-2 rounded-[8px] font-interM text-sm flex justify-center items-center border-[1px] border-20 mb-2 export-step px-5 py-2 rounded-[8px] font-interM text-sm flex justify-center items-center border-[1px] text-${currentMode}-copy border-light-20`}
                                >
                                    <img
                                        className="mr-[.4rem]"
                                        src="/images/common/download-excel.svg"
                                        alt="upload"
                                    />{" "}
                                    Download
                                </Button>
                            </div> */}

                            <FormControl
                                sx={{
                                    fontSize: "14px",
                                    m: 0,
                                }}
                            >
                                <Select
                                    select
                                    value={selectedValue}
                                    // inputProps={{
                                    //     style: { filter: "grayscale(100%)" }, // Apply filter to the native select
                                    // }}
                                    className={` text-${currentMode}-copy`}
                                    onChange={handleChange}
                                    displayEmpty
                                    IconComponent={() => (
                                        <img
                                            className="absolute right-[.4rem] opacity-50 pointer-events-none"
                                            src={currentMode !== "dark" ? `/images/header/chevronDown.svg` : `/images/header/chevronDown-white.svg`}
                                            alt="chevronDown"
                                        />
                                    )}
                                >
                                    <MenuItem selected value={1}>
                                        <Button
                                            title="To Export Invoice to Excel"
                                            onClick={() => handleExcelDownLoad()}
                                            className={`export-step rounded-[8px] font-interM text-sm flex justify-center items-center text-${currentMode}-copy`}
                                        >
                                            <img className="mr-[.4rem]" src="/images/common/download-excel.svg" alt="upload" /> EXCEL
                                        </Button>
                                    </MenuItem>

                                    <MenuItem value={2}>
                                        {" "}
                                        <Button
                                            title="To Export Invoice to PDF"
                                            onClick={() => downloadPdf()}
                                            className={`export-step rounded-[8px] font-interM text-sm flex justify-center items-center text-${currentMode}-copy`}
                                        >
                                            <img className="mr-[.4rem]" src="/images/Output-manage/pdf1.svg" title="Pdf" alt="upload" /> PDF
                                        </Button>
                                    </MenuItem>

                                    <MenuItem value={3}>
                                        <Button
                                            title="To Export Invoice to CSV"
                                            onClick={() => handleCSVDownload()}
                                            className={`export-step rounded-[8px] font-interM text-sm flex justify-center items-center text-${currentMode}-copy`}
                                        >
                                            <img className="mr-[.4rem]" src="/images/Output-manage/csv2.svg" title="CSV" alt="upload" /> CSV
                                        </Button>
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                    )}
                    {/* )} */}
                </div>
                <div className="heightFix invoices relative report">
                    <table className={`table-auto TblNormal invoice reports overflow-y-auto w-full`} ref={tableRef}>
                        <thead className="sticky top-0 z-[1]">
                            <tr>
                                {DataHeader?.map((header) => (
                                    <th key={header?.column_name}>{header?.display_name}</th>
                                ))}
                            </tr>
                        </thead>

                        {fetching || pageCount == null ? (
                            <Loader className="h-[calc(100vh_-_26rem)] absolute left-[50%] translate-x-[-50%]" />
                        ) : (
                            <>
                                <tbody>
                                    {datalist?.length !== 0 && datalist !== undefined && datalist !== null ? (
                                        datalist?.map((item, index) => {
                                            return (
                                                <tr key={index} className={`${item?.issubtotal > 0 ? "bg-[#EEFCFA]" : ""}`}>
                                                    {DataHeader?.map((header) => (
                                                        <td
                                                            key={header?.column_name}
                                                            title={extractTextFromHTML(
                                                                item[header?.column] !== null ? item[header?.column_name] : "N/A"
                                                            )}
                                                            className={`whitespace-nowrap truncate max-w-[30rem] ${
                                                                item?.issubtotal > 0 ? "font-interSb" : ""
                                                            } `}
                                                        >
                                                            {/* <div
                                                                dangerouslySetInnerHTML={{
                                                                    __html: item[header?.column] !== null ? item[header?.column_name] : "N/A",
                                                                }}
                                                            /> */}
                                                            {extractTextFromHTML(item[header?.column] !== null ? item[header?.column_name] : "N/A")}
                                                        </td>
                                                    ))}
                                                </tr>
                                            );
                                        })
                                    ) : (
                                        <div className="absolute top-[50%] left-[50%] translate-y-[-50%] translate-x-[-50%]">
                                            <img src="/images/common/delivery.png" alt="delivery" />
                                            <h4 className="font-interSb text-center mt-3">No data found</h4>
                                        </div>
                                    )}
                                </tbody>
                            </>
                        )}
                    </table>
                </div>

                {/* {addedInvoice?.length !== 0 && ProcessInv?.dataList !== null && ( */}
                <ClickAwayListener mouseEvent="onMouseDown" touchEvent="onTouchStart" onClickAway={handleClickAway}>
                    <Popper
                        className={`bg-${currentMode}-card text-${currentMode}-copy  p-4 border-[1px] z-[12] border-${currentMode}-700  shadow-[0px_4px_20px_rgba(238,238,238,0.5)] rounded-[10px] reportsPopper `}
                        open={1 === popstate.value}
                        anchorEl={popstate.anchorEl}
                        id={1}
                        placement={placement}
                    >
                        <ul>
                            {filterMenuList?.map((el, i) => {
                                return (
                                    <li
                                        key={i}
                                        className={`transition duration-300 ease-in-out mx-[-17px]  py-1 px-[20px] hover:bg-${currentMode}-960`}
                                    >
                                        <Button className={`flex ${1} items-center justify-between font-interR`}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        size="small"
                                                        value={el}
                                                        onChange={checkChange}
                                                        disabled={!Boolean(optionval?.indexOf(el) === -1)}
                                                        defaultChecked={!Boolean(values?.indexOf(el) === -1)}
                                                        sx={{
                                                            padding: 0,
                                                            color: "#008785",
                                                            margin: "0 12px",
                                                            "&.Mui-checked": {
                                                                color: "#008785",
                                                            },
                                                        }}
                                                    />
                                                }
                                                label={el}
                                            />
                                        </Button>
                                    </li>
                                );
                            })}
                        </ul>
                    </Popper>
                </ClickAwayListener>
                <TogglePopup
                    ref={exportRef}
                    menuList={ExportList}
                    // selectedItemChange={onExportItems}
                />
                {
                    <div className={`bg-${currentMode}-460 flex justify-between items-center py-[10px] px-[30px]`}>
                        {data?.length !== 0 && (
                            <div>
                                <div>
                                    Showing <span>{`${start} to ${totalPageCount}`}</span> entries of <span>{pageCount}</span>
                                </div>
                            </div>
                        )}
                        {data?.length !== 0 && (
                            <div className="flex items-center">
                                <span>The page you’re on</span>
                                <Select
                                    name=""
                                    className={`insideTble  bg-${currentMode}-card mx-3 text-xs entries`}
                                    id=""
                                    value={props?.currentPageNo}
                                    onChange={(e) => onPageNumberSelect(e)}
                                    IconComponent={() => (
                                        <img
                                            className="absolute opacity-50 right-[8px] pointer-events-none"
                                            src={currentMode !== "dark" ? `/images/header/chevronDown.svg` : `/images/header/chevronDown-white.svg`}
                                            alt="chevronDown"
                                        />
                                    )}
                                    SelectDisplayProps={{
                                        style: {
                                            paddingTop: "5px",
                                            display: "flex",
                                            paddingBottom: "5px",
                                            paddingLeft: "10px",
                                            border: 0,
                                            color: currentMode === "dark" ? `#ECECEC` : `#555555`,
                                            paddingRight: "25px",
                                        },
                                    }}
                                    MenuProps={{
                                        sx: {
                                            "& .MuiMenu-paper": {
                                                backgroundColor: dropdownColor,
                                                color: dropdownText,
                                                boxShadow: dropdownShadow,
                                                border: dropdownBorder,
                                                borderRadius: "8px",
                                            },
                                        },
                                    }}
                                >
                                    {EmptyArr?.map((elem, i) => (
                                        <MenuItem key={i} value={elem + 1}>
                                            {elem + 1}
                                        </MenuItem>
                                    ))}
                                </Select>
                                <div className={`flex space-x-2 border-l-[1px] pl-3 border-${currentMode}-830`}>
                                    <Button
                                        title="Previous page"
                                        disabled={props?.currentPageNo > 1 ? false : true}
                                        onClick={() => previousBtnClick()}
                                        className={`border-${currentMode}-840 border-[1px] rounded-[5px] bg-${currentMode}-card px-2 py-3`}
                                    >
                                        <img className="rotate-180 convertWhite" src="/images/common/arrowRight.svg" alt="arrowRight" />
                                    </Button>

                                    <Button
                                        title="Next page"
                                        className={`border-${currentMode}-840 border-[1px] rounded-[5px] bg-${currentMode}-card px-2 py-3`}
                                        disabled={props?.currentPageNo >= nextPageCount ? true : false}
                                        onClick={() => nextBtnClick()}
                                        value={props?.currentPageNo}
                                    >
                                        <img className="convertWhite" src="/images/common/arrowRight.svg" alt="arrowRight" />
                                    </Button>
                                </div>
                            </div>
                        )}
                    </div>
                }
                {/* )} */}
            </Card>
        </>
    );
};

export default CustomReportsTable;
