import Modal from "../../../components/UI/Modal/Modal";
import Stepper from "../../Rules/Stepper";
import Loader from "../../../components/Loader";
import { useContext } from "react";
import { ThemeContext } from "../../../Hooks/Themes/Themeprovider";
import { Button } from "@mui/material";

const UserDetailsModal = (props) => {
    const { currentMode, toggleMode } = useContext(ThemeContext);
    const ruleDataList = props?.ruleDataList;
    const DataValue = props?.DataValue;
    const DataItems = props?.DataItems;
    const RuleList = props?.ruleList;
    return (
        <>
            <Modal cartClose={props?.handleClose} center="center" className={`${props?.className}`}>
                {RuleList?.fetching ? (
                    <Loader className="" />
                ) : (
                    <div
                        className={`p-[20px]  max-w-[750px] mx-auto text-center relative bg-${currentMode}-card text-${currentMode}-copy border-${currentMode}-700 border-[rgba(223,225,226,0.56)] rounded-[10px] ${
                            currentMode === "dark" ? "" : "shadow-[0px_4px_20px_rgba(238,238,238,0.5)]"
                        }`}
                    >
                        <span onClick={props?.handleClose} className="cursor-pointer w-full mb-5 block text-end">
                            <img className="ml-auto" src="/images/common/closeBlk.svg" alt="closeBlk" />
                        </span>
                        {DataValue == undefined && DataItems == undefined ? (
                            <h4 className="font-interSb mb-3">No Data found</h4>
                        ) : (
                            <>
                                <h4 className="font-interSb mb-3 break-normal md:break-all">{DataValue?.rule_name}</h4>
                                <p className="mb-7 text-light-70 break-normal md:break-all max-w-[700px]">{DataValue?.rule_description}</p>
                                <>
                                    {DataItems !== null &&
                                        DataItems?.map((ele, index) => {
                                            return (
                                                <div className="grid place-content-center">
                                                    <Stepper no={index + 1}>
                                                        <div
                                                            className={`bg-${currentMode}-card text-${currentMode}-copy border-${currentMode}-700 border-[1px] border-[rgba(223,225,226,0.56)] rounded-[8px] shadow-[0px_4px_20px_rgba(85,85,85,0.2)] py-[16px] px-[15px]`}
                                                        >
                                                            {ele?.field_name} <span className="text-light-10">{ele?.operators}</span>{" "}
                                                            {ele?.value?.map((el, index) => {
                                                                return (
                                                                    <>
                                                                        <span className={`${ele?.field_id == 1 && "text-light-40  rounded-[2px]"}`}>
                                                                            {el}
                                                                        </span>
                                                                        {index !== ele?.value?.length - 1 && ", "}
                                                                    </>
                                                                );
                                                            })}
                                                        </div>
                                                    </Stepper>
                                                </div>
                                            );
                                        })}
                                </>
                            </>
                        )}
                    </div>
                )}
            </Modal>
        </>
    );
};

export default UserDetailsModal;
