import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import configMyiQ from "../../configuration";

const firebaseApp = initializeApp(configMyiQ.FirebaseConfig);
const messaging = getMessaging(firebaseApp);
export const getOrRegisterServiceWorker = async () => {
    // if ("serviceWorker" in navigator) {
    //     const serviceWorker =
    //         await window.navigator.serviceWorker.getRegistration(
    //             "/firebase-push-notification-scope"
    //         );
    //     if (serviceWorker) return serviceWorker;

    //     return window.navigator.serviceWorker.register(
    //         "../../../public/firebase-messaging-sw.js",
    //         {
    //             scope: "/firebase-push-notification-scope",
    //         }
    //     );
    // }
    if ("serviceWorker" in navigator) {
        navigator.serviceWorker
            .register("../../../public/firebase-messaging-sw.js")
            .then(function (registration) {
                // console.log(
                //    "Registration successful, scope is:",
                //   registration.scope
                //  );
            })
            .catch(function (err) {
                // console.log("Service worker registration failed, error:", err);
            });
    }
    throw new Error("The browser doesn't support service worker.");
};
export const fetchToken = async () => {
    //   getOrRegisterServiceWorker();
    getOrRegisterServiceWorker();
    const deviceToken = await getToken(messaging, {
        vapidKey: configMyiQ.FcbServerId,
    })
        .then((currentToken) => {
            if (currentToken) {
                // console.log("current token for client: ", currentToken);
                return currentToken;
                // Track the token -> client mapping, by sending to backend server
                // show on the UI that permission is secured
            } else {
                // console.log(
                //     "No registration token available. Request permission to generate one."
                // );
                return "";
                // shows on the UI that permission is required
            }
        })
        .catch((err) => {
            // console.log("An error occurred while retrieving token. ", err);
            return "";
            // catch error while creating client token
        });
    return deviceToken;
};

export const onMessageListener = () =>
    new Promise((resolve) => {
        onMessage(messaging, (payload) => {
            resolve(payload);
        });
    });
