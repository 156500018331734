import React, { useContext, useEffect, useState } from "react";
import Modal from "../../components/UI/Modal/Modal";
import Input from "../../components/Input";
import Button from "../../components/Button";
import Card from "../../components/UI/Card";
import { ThemeContext } from "../../Hooks/Themes/Themeprovider";
import Loader from "../../components/Loader";
import styles from "./index.module.scss";
import { getCurrentUser } from "../../redux/actions/auth";
import * as XLSX from "xlsx";
import { useSelector } from "react-redux";
import ExportExcel from "../ExportFiles/Excel";
import { async } from "@firebase/util";
import { useDispatch } from "react-redux";
import { AddUpdateExchangeRate, ExchangeRateListAction, GetCurrencyListExchangeRate, UploadExchangeRate } from "../../redux/actions/exchange-rate";
import Swal from "sweetalert2";
import { UPLOAD_EXCHANGE_RATE_RESET } from "../../redux/types/types";
import moment from "moment";
let uploadedFiles = [];
let fileStatusList = [];

const SampleCsv = "http://localhost:3000/sample_exchangerate.csv";

const ErrorTableModal = (props) => {
    const { currentMode } = useContext(ThemeContext);
    const currentUser = getCurrentUser();
    const updatedFromArray = props?.unmatchedCurrencies?.map((item) => ({
        ...item,
        error: 'From Currency is mismatch'
    }));
    const updatedValidCurrencyCheck = props?.ValidCurrencyCheck?.map((item) => ({
        ...item,
        error: 'To Currency is mismatch'
    }));
    const updatedFindSameData = props?.FindSameData?.map((item) => ({
        ...item,
        error: 'From currency is same as the To currency'
    }));
    const updatedinvalidExchangeRates = props?.invalidExchangeRates?.map((item) => ({
        ...item,
        error: 'The Exchage Rate is invalid'
    }));
    const isupdatedArray = updatedFromArray ? updatedFromArray : [];
    const isupdatedValidCurrencyCheck = updatedValidCurrencyCheck ? updatedValidCurrencyCheck : [];
    const isupdatedFindSameData = updatedFindSameData ? updatedFindSameData : [];
    const isupdatedinvalidExchangeRates = updatedinvalidExchangeRates ? updatedinvalidExchangeRates : [];
    const DataArray = [...isupdatedArray, ...isupdatedValidCurrencyCheck, ...isupdatedFindSameData, ...isupdatedinvalidExchangeRates]

    return (
        <Modal editModalOpen={props?.uploadModalOpen} editModalClose={props?.uploadModalClose} center="center" overlap={true} className={`${props?.className}`}>
            <Card
                className={`h-[376px] w-[834px] overflow-y-auto px-30 py-[60px] border-[1px] relative ${currentMode === "dark" ? "" : "shadow-[0px_4px_20px_rgba(238,238,238,0.5)]"
                    } border-[rgba(223,225,226,0.56)] flex items-center flex-col justify-center`}>
                <Button onClick={props?.uploadModalClose} className="absolute top-0 right-0 mt-2 mr-2 text-gray-500 hover:text-gray-700 ">
                    <img src="/images/common/closeBlk.svg" alt="closeBlk" className="convertWhite pt-4 pr-4" />
                </Button>



                <div className="max-h-[380px] mt-7 overflow-auto">
                    <div className="flex mb-[25px]"><img src="/images/swal/red-alert.svg"></img><span className="ml-2 text-black font-inter text-base font-medium leading-5">The following table displays the list of currency conversion and exchange rate valid date:</span></div>
                    <table className="w-full relative overflow-auto rolemasterTable rounded-[0] lastBorder onTimeTbl text-left rounded-lg border border-[rgba(223,225,226,0.56)]  shadow-[0_4px_20px_rgba(238,238,238,0.50)]">
                        <thead className="sticky top-0 z-[2]">
                            <tr>
                                <th className={`sticky left-0 z-[2] !rounded-tl-[0] text-center bg-${currentMode}-450`}>#</th>


                                <th
                                    className={` whitespace-nowrap`}>
                                    {"From Currency"}
                                </th>
                                <th
                                    className={` whitespace-nowrap`}>
                                    {"To Currency"}
                                </th>
                                <th
                                    className={` whitespace-nowrap`}>
                                    {"Valid From"}
                                </th>
                                <th
                                    className={` whitespace-nowrap`}>
                                    {"Exchange Rate"}
                                </th>
                                <th
                                    className={` whitespace-nowrap  w-[200px]`}>
                                    {"Error"}
                                </th>


                            </tr>
                        </thead>
                        <tbody>

                            {DataArray?.length > 0 ? (
                                DataArray?.map((row, index) => (
                                    <tr>
                                        <td className={`text-center sticky left-0 z-[1] `}>{index + 1}</td>
                                        <td>{row?.['From Currency']}</td>
                                        <td>{row?.['To Currency']}</td>
                                        <td>{row?.['Valid From']}</td>
                                        <td>{row?.['Exchange Rate']}</td>
                                        <td className={`text-[#FA5A5A] w-[200px]`}>{row?.error}</td>
                                    </tr>
                                ))
                            ) : (
                                ''

                            )}


                        </tbody>
                    </table>


                </div>
                {/* <Button
                    type="submit"
                    title="Ok"
                    onClick={props?.uploadModalClos}
                    className="mt-6 border-transparent approve-step border-440 w-[103px] h-[40px] px-4 py-3 bg-light-20 rounded-[8px] text-base flex  border-[1px] text-white items-left">
                    Ok
                </Button> */}
            </Card >
        </Modal >
    );
};

export default ErrorTableModal;
