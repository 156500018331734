import {
    GET_PO_RETENTION_HISTORY_REQUEST,
    GET_PO_RETENTION_HISTORY_FAILURE,
    GET_PO_RETENTION_HISTORY_SUCCESS,
    GET_PO_RETENTION_HISTORY_RESET,
    GET_RETENTION_RELEASE_VIEW_REQUEST,
    GET_RETENTION_RELEASE_VIEW_SUCCESS,
    GET_RETENTION_RELEASE_VIEW_RESET,
    GET_RETENTION_RELEASE_VIEW_FAILURE,
    POST_RETENTION_RELEASE_REQUEST,
    POST_RETENTION_RELEASE_SUCCESS,
    POST_RETENTION_RELEASE_RESET,
    POST_RETENTION_RELEASE_FAILURE,
} from "../types/types";

const getPoRetentionReleaseState = {
    data: [],
    fetching: false,
    requestComplete: false,
    error: "",
};

export function getPoRetentionRelease(state = getPoRetentionReleaseState, action) {
    switch (action.type) {
        case GET_PO_RETENTION_HISTORY_REQUEST:
            return {
                ...state,
                fetching: true,
                requestComplete: false,
                error: "",
            };
        case GET_PO_RETENTION_HISTORY_SUCCESS:
            return {
                data: action.payload,
                fetching: false,
                requestComplete: true,
                error: "",
            };
        case GET_PO_RETENTION_HISTORY_RESET:
            return {
                data: [],
                fetching: false,
                requestComplete: false,
                error: "",
            };
        case GET_PO_RETENTION_HISTORY_FAILURE:
            return {
                fetching: false,
                requestComplete: false,
                error: action.payload,
            };
        default:
            return state;
    }
}

const getRetentionReleaseViewState = {
    data: [],
    fetching: false,
    requestComplete: false,
    error: "",
};

export function getRetentionReleaseView(state = getRetentionReleaseViewState, action) {
    switch (action.type) {
        case GET_RETENTION_RELEASE_VIEW_REQUEST:
            return {
                ...state,
                fetching: true,
                requestComplete: false,
                error: "",
            };
        case GET_RETENTION_RELEASE_VIEW_SUCCESS:
            return {
                data: action.payload,
                fetching: false,
                requestComplete: true,
                error: "",
            };
        case GET_RETENTION_RELEASE_VIEW_RESET:
            return {
                data: [],
                fetching: false,
                requestComplete: false,
                error: "",
            };
        case GET_RETENTION_RELEASE_VIEW_FAILURE:
            return {
                fetching: false,
                requestComplete: false,
                error: action.payload,
            };
        default:
            return state;
    }
}

const postRetentionReleaseState = {
    data: [],
    fetching: false,
    requestComplete: false,
    error: "",
};

export function postRetentionRelease(state = postRetentionReleaseState, action) {
    switch (action.type) {
        case POST_RETENTION_RELEASE_REQUEST:
            return {
                ...state,
                fetching: true,
                requestComplete: false,
                error: "",
            };
        case POST_RETENTION_RELEASE_SUCCESS:
            return {
                data: action.payload,
                fetching: false,
                requestComplete: true,
                error: "",
            };
        case POST_RETENTION_RELEASE_RESET:
            return {
                data: [],
                fetching: false,
                requestComplete: false,
                error: "",
            };
        case POST_RETENTION_RELEASE_FAILURE:
            return {
                fetching: false,
                requestComplete: true,
                error: action.payload,
            };
        default:
            return state;
    }
}
