import Card from "../../../components/UI/Card";
import { useContext } from "react";
import { ThemeContext } from "../../../Hooks/Themes/Themeprovider";
import Button from "../../../components/Button";
import { useState, useEffect } from "react";
import POHeaderLineItemPopUp from "./POHeaderLineItemPopUp";
import ReactDOM from "react-dom";
import { useNavigate, useLocation } from "react-router-dom";
import _ from "lodash";
const ServiceEntrySheetTable = (props) => {
    const location = useLocation();
    const { currentMode, toggleMode } = useContext(ThemeContext);
    const adhocCreatdata = props?.adhocCreatdata;
    const sesListData = props?.createReqId ? adhocCreatdata : props?.createDataObject?.poSesGrn;
    return (
        <>
            <Card
                className={` ${
                    props?.findPoSelected ? "" : "opacity-[.5] pointer-events-none"
                } mb-[1rem] border-[1px]  border-[rgba(223,225,226,0.56)] h-[144px] xl:w-[976px]  2xl:w-full ${
                    currentMode === "dark" ? "" : "shadow-[0px_4px_20px_rgba(238,238,238,0.5)]"
                }`}
            >
                <div className="flex mt-[19px] ml-[20px] mb-[20px]">
                    <span className="text-[18px] font-medium">
                        <h3>Service Entry Sheet</h3>
                    </span>
                </div>
                <div className="max-h-[80px] overflow-y-auto">
                    <table className=" 2xl:w-full overflow-x-auto border-0 rolemasterTable rounded-[0] text-left">
                        <thead>
                            <tr className="text-[14px]">
                                <th className="px-[10px] rounded-l-[0]">PO Number</th>
                                <th className="px-[10px]">Line Item</th>
                                <th className="">Year</th>
                                <th>Document No.</th>
                                <th>Line</th>
                                <th className="rounded-r-[0]">Amount</th>
                                <th className="rounded-r-[0]">Quantity</th>
                                <th className="rounded-r-[0]">Order Unit</th>
                                {/* <th className="rounded-r-[0]">SES Test</th>
                                <th className="rounded-r-[0]">Tax Code</th> */}
                            </tr>
                        </thead>
                        <tbody className="relative">
                            {sesListData?.length !== 0 ? (
                                sesListData?.map((el, inde) => {
                                    return (
                                        <tr key={inde}>
                                            <td className="px-[10px]">{el?.PoNumber}</td>
                                            <td className="">{el?.RefDocumentItem}</td>
                                            <td className="">{el?.RefYear}</td>
                                            <td className="">{el?.RefDocumentNo}</td>
                                            <td className="">{inde + 1}</td>
                                            <td className="">{el?.Amount}</td>
                                            <td className="">{el?.GoodsReceiptQuantity}</td>
                                            <td className="">{el?.GrnUnit}</td>
                                            {/* <td className="">RMZ123</td>
                                            <td className="">IB</td> */}
                                        </tr>
                                    );
                                })
                            ) : (
                                <div className="  left-[50%] translate-y-[-25%] top-[50%] translate-x-[350%]">
                                    <h4 className="font-interSb text-center mt-3">No data found</h4>
                                </div>
                            )}
                        </tbody>
                    </table>
                </div>
            </Card>
        </>
    );
};

export default ServiceEntrySheetTable;
