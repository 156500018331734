import {
    COUNTRY_FAILURE,
    COUNTRY_REQUEST,
    COUNTRY_RESET,
    COUNTRY_SUCCESS,
    GET_LIST_OF_CITIES_REQUEST,
    GET_LIST_OF_CITIES_SUCCESS,
    GET_LIST_OF_CITIES_RESET,
    GET_LIST_OF_CITIES_FAILURE,
} from "../types/types";

const intAuthState = {
    country: [],
    fetching: false,
    requestComplete: false,
    error: "",
};

export function country(state = intAuthState, action) {
    switch (action.type) {
        case COUNTRY_REQUEST:
            return {
                ...state,
                fetching: true,
                requestComplete: false,
                error: "",
            };
        case COUNTRY_SUCCESS:
            return {
                country: action.payload,
                fetching: false,
                requestComplete: true,
                error: "",
            };
        case COUNTRY_RESET:
            return {
                country: [],
                fetching: false,
                requestComplete: false,
                error: "",
            };
        case COUNTRY_FAILURE:
            return {
                fetching: false,
                requestComplete: false,
                error: action.payload,
            };
        default:
            return state;
    }
}
//SIGNUP_COUNTRY
const signupCountryListAuthState = {
    country: [],
    fetching: false,
    requestComplete: false,
    error: "",
};

export function signUpCountryList(state = signupCountryListAuthState, action) {
    switch (action.type) {
        case GET_LIST_OF_CITIES_REQUEST:
            return {
                ...state,
                fetching: true,
                requestComplete: false,
                error: "",
            };
        case GET_LIST_OF_CITIES_SUCCESS:
            return {
                country: action.payload,
                fetching: false,
                requestComplete: true,
                error: "",
            };
        case GET_LIST_OF_CITIES_RESET:
            return {
                country: [],
                fetching: false,
                requestComplete: false,
                error: "",
            };
        case GET_LIST_OF_CITIES_FAILURE:
            return {
                fetching: false,
                requestComplete: false,
                error: action.payload,
            };
        default:
            return state;
    }
}
