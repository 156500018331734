import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { MenuItem, Select } from "@mui/material";
import Button from "../../components/Button";
import Input from "../../components/Input";
import { signupRequest } from "../../redux/actions/auth";
import { useRef, useEffect } from "react";
import { SIGNUP_RESET } from "../../redux/types/types";
import MessageBox from "../../components/UI/Alert";
import { useFormik } from "formik";
import { SignupValidateSchema } from "../../Hooks/validateschema";
import { encryptId } from "../../utils/helpers";
import { useState } from "react";
import { Checkbox } from "@mui/material";
import { ValidateTempMail } from "../../redux/actions/auth.js";
import { GET_TEMP_MAIL_VALIDATE_RESET } from "../../redux/types/types.js";
import { loadCaptchaEnginge, LoadCanvasTemplate, validateCaptcha } from "react-simple-captcha";
import axios from "axios";
import { useContext } from "react";
import { signupCountryList } from "../../redux/actions/country";
import { ThemeContext } from "../../Hooks/Themes/Themeprovider";
const successMsg = "Account created is successfully.";
const errormsg = "Something went wrong please contact admin";
const Form = () => {
    const [emailId, setEmailID] = useState("");
    const dispatch = useDispatch();
    const [valid_token, setValidToken] = useState(false);
    const [val, setVal] = useState(false);
    const [dataCenter, setDataCenter] = useState("");
    const { currentMode } = useContext(ThemeContext);
    const [checkVal, setCheckVal] = useState(false);
    const siteVerifyUrl = "https://www.google.com/recaptcha/api/siteverify";
    const SITE_KEY = "6LehbP0mAAAAALrYcSolrw9qQjTUP8QVnULAxbr5";
    const secretKey = "6LehbP0mAAAAAOknxb6yaOiELP3D9Sdfis--LTkM";
    const captchaRef = useRef(null);
    // const { country } = useSelector((state) => state?.countryState);
    const { data: checkdata, requestComplete: reqComp, fetching: fetch, error: err } = useSelector((state) => state?.tempMailValidateState);

    // const country = [
    //     {
    //         country_id: 1,
    //         country_name: "japan",
    //         val: "japan data center",
    //     },
    //     {
    //         country_id: 2,
    //         country_name: "india",
    //         val: "india data center",
    //     },
    //     {
    //         country_id: 3,
    //         country_name: "china",
    //         val: "china data center",
    //     },
    // ];
    const { country } = useSelector((state) => state?.signUpCountryListArrState);
    const [btnDisabled, setBtnDisabled] = useState(false);
    const formik = useFormik({
        initialValues: {
            signup_firstname: "",
            signup_lastname: "",
            signup_emailaddress: "",
            signup_country: 0,
            signup_acceptTerms: false,
        },
        validationSchema: SignupValidateSchema,

        onSubmit: async (values) => {
            let user_captcha_value = document.getElementById("user_captcha_input").value;
            if (validateCaptcha(user_captcha_value) == true) {
                setBtnDisabled(true);
                setTimeout(() => {
                    setBtnDisabled(false);
                }, 4000);
                setEmailID(encryptId(values?.signup_emailaddress.toLowerCase()));
                const reqObj = {
                    emailAddress: encryptId(values?.signup_emailaddress.toLowerCase()),
                };
                dispatch(
                    ValidateTempMail(reqObj, {
                        emailaddress: encryptId(values?.signup_emailaddress.toLowerCase()),
                        firstname: values?.signup_firstname.trim(),
                        lastname: values?.signup_lastname.trim(),
                        country_id: values?.signup_country,
                    })
                );
                if (reqComp && checkdata?.status) {
                    dispatch(
                        signupRequest({
                            emailaddress: encryptId(values?.signup_emailaddress.toLowerCase()),

                            firstname: values?.signup_firstname.trim(),
                            lastname: values?.signup_lastname.trim(),
                        })
                    );
                } else {
                    dispatch({ type: GET_TEMP_MAIL_VALIDATE_RESET });
                }
            } else {
                if (document.getElementById("user_captcha_input").value == "") {
                    setVal(true);
                } else {
                    setValidToken(true);
                    document.getElementById("user_captcha_input").value = "";
                }
                setTimeout(() => {
                    setValidToken(false);
                    setVal(false);
                }, 2000);
            }
            // let token = captchaRef.current.getValue();

            // if (token) {

            // } else {
            //     // captchaRef.current.reset();
            //     captchaRef.current.reset();
            //     console.log("you are a Bot");
            // }
        },
    });
    const handleDataCenter = country?.find((el) => el?.country_id === formik?.values?.signup_country)?.datacenter;
    const { data, fetching, error, requestComplete } = useSelector((state) => state?.registerState);
    const navigate = useNavigate();

    // useEffect(() => {
    //     return () => {
    //         dispatch({ type: SIGNUP_RESET });
    //     };
    // }, [dispatch, navigate]);
    useEffect(() => {
        if (country?.length == 0) {
            dispatch(signupCountryList());
        }
    }, []);
    if (requestComplete) {
        if (data.status) {
            setTimeout(function () {
                navigate(`/thankyousignup?email=${emailId}`);
            }, 2000);
        }
    }
    useEffect(() => {
        loadCaptchaEnginge(6, "#E5EDE6");
    }, []);
    if ((error && !data?.status) || (requestComplete && !data?.status)) {
        setTimeout(() => {
            document.getElementById("user_captcha_input").value = "";
        }, 1000);
    }
    if (requestComplete) {
        setTimeout(() => {
            dispatch({ type: SIGNUP_RESET });
        }, 2000);
    }
    // useEffect(() => {
    //     dispatch(ValidateTempMail());
    // }, []);
    return (
        <>
            {requestComplete && !data?.status && <MessageBox error={data.message} />}
            {error && !data?.status && <MessageBox error={errormsg} />}
            {valid_token && <MessageBox error={"The Captcha is invalid,Please try again"} />}
            {val && <MessageBox error={"Please fill the Captcha"} />}
            <form onSubmit={formik.handleSubmit} noValidate className="w-full">
                <h4 className="text-light-20 text-2xl mb-4">Create account</h4>
                <div className="form-control">
                    <label htmlFor="" className="text-light-40 mb-2">
                        <span className="text-[red] mr-1 ">*</span>
                        First Name
                    </label>
                    <Input
                        className="border border-light-840 rounded-[8px] px-[15px] py-2 w-full "
                        tabIndex={0}
                        type="text"
                        maxLength={51}
                        name="signup_firstname"
                        onChange={formik.handleChange}
                        placeholder="First name"
                        autoFocus

                        // ref={textInput}
                    />
                    {formik.errors.signup_firstname && formik.touched.signup_firstname ? (
                        <p className="text-light-50 text-xs">{formik.errors?.signup_firstname}</p>
                    ) : null}
                </div>
                <div className="form-control">
                    <label htmlFor="" className="text-light-40 mb-2">
                        <span className="text-[red] mr-1">*</span>
                        Last Name
                    </label>
                    <Input
                        className="border border-light-840 rounded-[8px] px-[15px] py-2 w-full "
                        type="text"
                        tabIndex={0}
                        maxLength={51}
                        name="signup_lastname"
                        placeholder="Last Name"
                        onChange={formik.handleChange}
                    />
                    {formik.errors.signup_lastname && formik.touched.signup_lastname ? (
                        <p className="text-light-50 text-xs">{formik.errors?.signup_lastname}</p>
                    ) : null}
                </div>
                <div className="form-control">
                    <label htmlFor="" className="text-light-40 mb-2">
                        <span className="text-[red] mr-1">*</span>
                        Business Email
                    </label>

                    <Input
                        className="border border-light-840 rounded-[8px] px-[15px] py-2 w-full "
                        type="email"
                        tabIndex={0}
                        name="signup_emailaddress"
                        maxLength={251}
                        placeholder="email@address.com"
                        onChange={formik.handleChange}
                    />

                    {formik.errors.signup_emailaddress && formik.touched.signup_emailaddress ? (
                        <p className="text-light-50 text-xs">{formik.errors?.signup_emailaddress}</p>
                    ) : null}
                </div>
                <div className="form-control">
                    <label htmlFor="" className="text-light-40 mb-2">
                        <span className="text-[red] mr-1">*</span>
                        Country
                    </label>

                    <Select
                        onChange={(e) => formik.handleChange(e)}
                        name="signup_country"
                        inputProps={{ tabIndex: 0 }}
                        IconComponent={() => (
                            <img
                                className="absolute opacity-[.5] right-[15px] pointer-events-none"
                                src={currentMode !== "dark" ? `/images/header/chevronDown.svg` : `/images/header/chevronDown-white.svg`}
                            />
                        )}
                        id="signup_country"
                        value={formik?.values?.signup_country || 0}
                        className={`w-full h-[47px] rounded-[8px]`}
                        SelectDisplayProps={{
                            style: {
                                background: "white",
                            },
                        }}
                    >
                        <MenuItem value={0}>{"Select"}</MenuItem>

                        {country?.map((elem, index) => {
                            return (
                                <MenuItem key={index} value={elem?.country_id}>
                                    {elem?.country_name}
                                </MenuItem>
                            );
                        })}
                    </Select>
                    {formik.errors.signup_country && formik.touched.signup_country ? (
                        <p className="text-light-50 text-xs">{formik.errors?.signup_country}</p>
                    ) : null}
                    {handleDataCenter !== null && handleDataCenter !== "" && handleDataCenter !== undefined && (
                        <p className="text-light-40 mt-3 ">{`Your data will be in ${handleDataCenter} data center.`}</p>
                    )}
                </div>
                {/* <div>
                    <ReCAPTCHA
                        className="recaptcha mt-3 mb-5"
                        sitekey={SITE_KEY}
                        ref={captchaRef}
                        type="text"
                        //   data-theme="dark"
                        onChange={onChange}
                    /> */}
                <label htmlFor="" className="text-light-40 mb-2 block">
                    {/* <span className="text-[red] mr-1">*</span> */}
                    Captcha
                </label>
                <div className="flex justify-between mb-5 items-center space-x-3">
                    <div className="xl:basis-[45%] basis-1/2 captchaDiv">
                        <LoadCanvasTemplate />
                    </div>
                    {/* </div> */}

                    <div className="xl:basis-[55%] basis-1/2">
                        <Input
                            className="border-[1px] border-light-840 rounded-[8px] px-[0px] pl-5 ml-7 py-1 w-[230px] h-[48px]"
                            type="text"
                            name="user_captcha_input"
                            maxLength={6}
                            id="user_captcha_input"
                            placeholder="Enter Captcha Value"
                            tabIndex={0}
                        />
                    </div>
                </div>
                <div className="font-interR text-xs mb-5">
                    <div className="flex form-group items-center form-check">
                        <Checkbox
                            name="signup_acceptTerms"
                            size="small"
                            tabIndex={0}
                            className="mr-2"
                            onChange={formik.handleChange}
                            sx={{
                                padding: 0,
                                color: "rgba(85,85,85,0.7)",
                                "&.Mui-checked": {
                                    color: "#00B399",
                                },
                            }}
                        />
                        I agree with
                        {/* <Input className="mr-2" type="checkbox" name="signup_acceptTerms" tabIndex="4" onChange={formik.handleChange}/> I agree with{" "} */}
                        <a href={"https://www.myiq.ai/terms-condition"} relative="path" target="_blank" className="inline-block">
                            <div title="Terms">
                                <span className="text-light-20 mx-1">Terms </span>
                            </div>
                        </a>
                        and
                        <a href={"https://www.myiq.ai/privacy-policy"} relative="path" target="_blank" className="inline-block">
                            <div title="Privacy">
                                <span className="text-light-20 ml-1"> Privacy</span>
                            </div>
                        </a>
                    </div>
                    {formik.errors.signup_acceptTerms && formik.touched.signup_acceptTerms ? (
                        <p className="text-light-50 text-xs">{formik.errors?.signup_acceptTerms}</p>
                    ) : null}
                </div>

                <Button
                    disabled={fetching || (requestComplete && data?.status) || btnDisabled}
                    title="Sign up"
                    // onClick={() => doSubmit()}
                    tabIndex={0}
                    className="w-full shadow-[0px_12.1167px_24.2334px_rgba(0,179,153,0.05)] rounded-[8px] text-lg text-white bg-light-20 py-[8px]"
                >
                    {fetching ? `...` : `Sign up`}
                </Button>

                <div className="OrLine my-[40px]">OR</div>
                <p className="text-sm text-center font-interR">
                    Already have an account?{" "}
                    <Link to="/" tabIndex={0} className="text-light-20">
                        <u>Login</u>
                    </Link>
                </p>
            </form>
        </>
    );
};

export default Form;
