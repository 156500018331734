import Button from "../../components/Button";
import Modal from "../../components/UI/Modal/Modal";
import { Link, useNavigate } from "react-router-dom";
import { ThemeContext } from "../../Hooks/Themes/Themeprovider";
import { useContext, useEffect, useState } from "react";
import Input from "../../components/Input";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import Card from "../../components/UI/Card";
import { helperVideoMenu } from "../../utils/helpers.js";
let filterArr = [];
const HelperVideoMain = (props) => {
    const { currentMode } = useContext(ThemeContext);
    const [value, setValue] = useState(0);
    const [isPlaying, setIsPlaying] = useState(false);
    const [videoId, setVideoId] = useState("");
    const [searchValue, handleSearchText] = useState("");
    function TabPanel(props) {
        const { children, value, index, ...other } = props;
        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                className="h-full"
                id={`vertical-tabpanel-${index}`}
                aria-labelledby={`vertical-tab-${index}`}
                {...other}
            >
                {value === index && <Box sx={{ p: 3, height: "100%" }}>{children}</Box>}
            </div>
        );
    }
    const onPlayVideo = (url) => {
        setIsPlaying(true);
        setVideoId(url);
    };
    const resetInputField = () => {
        handleSearchText("");
    };
    const onCloseVideo = () => {
        setIsPlaying(false);
    };
    TabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.number.isRequired,
        value: PropTypes.number.isRequired,
    };
    const handleChange = (event, newValue) => {
        setValue(newValue);
        setIsPlaying(false);
    };
    function a11yProps(index) {
        return {
            id: `vertical-tab-${index}`,
            "aria-controls": `vertical-tabpanel-${index}`,
        };
    }
    const [filteredArr, setFilteredArr] = useState([]);
    filterArr =
        searchValue?.length > 0 ? filteredArr?.filter((ele) => ele?.menu_id === value) : helperVideoMenu?.filter((ele) => ele?.menu_id === value);
    useEffect(() => {
        if (searchValue) {
            setFilteredArr(helperVideoMenu?.filter((ele) => ele?.description?.toLowerCase().includes(searchValue.toLowerCase())));
        } else {
            setFilteredArr([]);
        }
    }, [searchValue]);
    const EditContent = (
        <>
            <div className="flex flex-wrap ">
                {filterArr?.length > 0 ? (
                    filterArr?.map((el) => {
                        return (
                            <Card
                                className={` ${
                                    currentMode !== "dark" ? "shadow-[0px_4px_20px_rgba(238,238,238,0.5)]" : ""
                                } xl:w-[226px] w-[200px] h-[250px] ml-4 mb-4`}
                            >
                                <div
                                    onClick={() => onPlayVideo(el?.url)}
                                    className="bg-[#00B399] h-[160px] xl:w-[226px] w-[200px] rounded-t-lg cursor-pointer flex flex-col items-center justify-center relative"
                                >
                                    <img className="h-[120px] w-[175px]" src={el?.src} alt="Template Image" onClick={() => onPlayVideo(el?.url)} />
                                    <img
                                        className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
                                        src="/images/tour-helper/play-template.svg"
                                        alt="Play Button"
                                    />
                                </div>

                                <div className=" py-2 px-2 min-h-[90px]">
                                    <span title={el?.description} className="text-[14px] font-medium trunc-2">
                                        {el?.description}
                                    </span>
                                    <p onClick={() => onPlayVideo(el?.url)} className={`text-[#00B399] underline mt-2 cursor-pointer`}>
                                        Watch video
                                    </p>
                                </div>
                            </Card>
                        );
                    })
                ) : (
                    <div className="text-center ml-[250px]">
                        <div className="mx-auto  my-5">
                            <img className="mt-[100px] mx-auto" src="/images/common/delivery.png" alt="delivery" />
                        </div>
                        <h4 className="font-interSb">No Search result found</h4>
                    </div>
                )}
            </div>
        </>
    );
    return (
        <>
            <Modal cartClose={props?.handleClose} center="center" className={`${props?.className}`}>
                <div
                    className={`w-[700px] xl:w-[1024px] h-[700px] mx-auto relative bg-${currentMode}-card border-[rgba(223,225,226,0.56)]  rounded-[10px] ${
                        currentMode === "dark" ? "" : "shadow-[0px_4px_20px_rgba(238,238,238,0.5)]"
                    }]`}
                >
                    <div className="flex">
                        <span className={`text-[#00B399] mt-[25px] text-[16px] xl:px-11 pl-[2.75rem] ml-[-20px] font-semibold`}>
                            MyiQ.Ai Features
                        </span>
                        {!isPlaying && (
                            <div className=" mt-[-15px] xl:ml-[170px] ml-[70px] px-[50px] pt-[30px]">
                                <Input
                                    placeholder="Search..."
                                    className={` bg-${currentMode}-230 border-${currentMode}-590 border py-[8px] px-[16px]  w-[250px] rounded-[8px] text-${currentMode}-copy`}
                                    //disableUnderline={true}
                                    // disabled={dataList?.length === undefined ? true : false}
                                    maxLength={250}
                                    value={searchValue}
                                    name="searchId"
                                    id="SearchId"
                                    onChange={(e) => handleSearchText(e.target.value)}
                                    autoComplete="off"
                                    // onFocus={() => onBlurSearch()}
                                />
                                {searchValue && searchValue.length !== 0 ? (
                                    <img
                                        className="z-[-1] mt-[-25px] ml-[220px] convertWhite"
                                        onClick={resetInputField}
                                        src="/images/common/closeBlk.svg"
                                        alt="close"
                                    />
                                ) : (
                                    <img className="z-[-1] mt-[-29px] ml-[220px]" src="/images/header/search.svg" alt="search" />
                                )}
                            </div>
                        )}
                    </div>
                    <span
                        onClick={isPlaying ? onCloseVideo : props?.handleClose}
                        className="cursor-pointer absolute right-[30px] top-[30px] convertWhite"
                    >
                        <img src="/images/common/closeBlk.svg" alt="closeBlk" />
                    </span>
                    <div className=" mt-[25px]">
                        <hr className={`border-${currentMode}-560`} />
                    </div>
                    <div className="flex h-full">
                        {isPlaying ? (
                            <iframe
                                title="YouTube Video"
                                width="100%"
                                height="100%"
                                src={`https://www.youtube.com/embed/${videoId}`}
                                allowFullScreen
                                className="px-3 h-[610px]"
                            ></iframe>
                        ) : (
                            <>
                                <div className="xl:px-[3.125rem]  max-w-[242px] xl:py-[1.875rem] ">
                                    <div className="xl:my-4 lg:hidden">
                                        <hr className={`border-${currentMode}-690`} />
                                    </div>
                                    <Tabs
                                        className={`profileTab helperTab helperVideo xl:min-w-[242px] min-w-[200px] `}
                                        orientation="vertical"
                                        variant="scrollable"
                                        value={value}
                                        onChange={handleChange}
                                        aria-label="Vertical tabs"
                                        sx={{ borderRight: 1, borderColor: "divider", color: "#000000" }}
                                    >
                                        {/* <Tab
                                            label={`Dashboard ${
                                                searchValue?.length > 0 && filteredArr?.length > 0
                                                    ? `(${filteredArr?.filter((el) => el?.menu_id == 0)?.length})`
                                                    : ""
                                            }`}
                                            className={`${currentMode == "dark" ? `text-${currentMode}-copy` : ""}`}
                                            {...a11yProps(0)}
                                            style={{
                                                // position: "absolute",
                                                display:
                                                    searchValue?.length > 0 &&
                                                    filteredArr?.length > 0 &&
                                                    filteredArr?.filter((el) => el?.menu_id === 0)?.length === 0
                                                        ? "none"
                                                        : "block",
                                            }}
                                        /> */}
                                        {/* <Tab
                                            label={`Organization Management${
                                                searchValue?.length > 0 && filteredArr?.length > 0
                                                    ? `(${filteredArr?.filter((el) => el?.menu_id == 1)?.length})`
                                                    : ""
                                            }`}
                                            className={`${currentMode == "dark" ? `text-${currentMode}-copy` : ""}`}
                                            {...a11yProps(1)}
                                            style={{
                                                display:
                                                    searchValue?.length > 0 &&
                                                    filteredArr?.length > 0 &&
                                                    filteredArr?.filter((el) => el?.menu_id === 1)?.length === 0
                                                        ? "none"
                                                        : "block",
                                            }}
                                        /> */}
                                        <Tab
                                            className={`${currentMode == "dark" ? `text-${currentMode}-copy` : ""}`}
                                            label={`Users Management${
                                                searchValue?.length > 0 && filteredArr?.length > 0
                                                    ? `(${filteredArr?.filter((el) => el?.menu_id == 0)?.length})`
                                                    : ""
                                            }`}
                                            {...a11yProps(0)}
                                            style={{
                                                display:
                                                    searchValue?.length > 0 &&
                                                    filteredArr?.length > 0 &&
                                                    filteredArr?.filter((el) => el?.menu_id === 0)?.length === 0
                                                        ? "none"
                                                        : "block",
                                            }}
                                        />
                                        <Tab
                                            className={`${currentMode == "dark" ? `text-${currentMode}-copy` : ""}`}
                                            label={`Invoice Management${
                                                searchValue?.length > 0 && filteredArr?.length > 0
                                                    ? `(${filteredArr?.filter((el) => el?.menu_id == 1)?.length})`
                                                    : ""
                                            }`}
                                            {...a11yProps(1)}
                                            style={{
                                                display:
                                                    searchValue?.length > 0 &&
                                                    filteredArr?.length > 0 &&
                                                    filteredArr?.filter((el) => el?.menu_id === 1)?.length === 0
                                                        ? "none"
                                                        : "block",
                                            }}
                                        />
                                        {/* <Tab
                                            className={`${currentMode == "dark" ? `text-${currentMode}-copy` : ""}`}
                                            label={`Task Management${
                                                searchValue?.length > 0 && filteredArr?.length > 0
                                                    ? `(${filteredArr?.filter((el) => el?.menu_id == 4)?.length})`
                                                    : ""
                                            }`}
                                            {...a11yProps(4)}
                                            style={{
                                                display:
                                                    searchValue?.length > 0 &&
                                                    filteredArr?.length > 0 &&
                                                    filteredArr?.filter((el) => el?.menu_id === 4)?.length === 0
                                                        ? "none"
                                                        : "block",
                                            }}
                                        /> */}
                                        <Tab
                                            className={`${currentMode == "dark" ? `text-${currentMode}-copy` : ""}`}
                                            label={`Account Management${
                                                searchValue?.length > 0 && filteredArr?.length > 0
                                                    ? `(${filteredArr?.filter((el) => el?.menu_id == 2)?.length})`
                                                    : ""
                                            }`}
                                            {...a11yProps(2)}
                                            style={{
                                                display:
                                                    searchValue?.length > 0 &&
                                                    filteredArr?.length > 0 &&
                                                    filteredArr?.filter((el) => el?.menu_id === 2)?.length === 0
                                                        ? "none"
                                                        : "block",
                                            }}
                                        />
                                        {/* <Tab
                                            className={`${currentMode == "dark" ? `text-${currentMode}-copy` : ""}`}
                                            label={`ERP${
                                                searchValue?.length > 0 && filteredArr?.length > 0
                                                    ? `(${filteredArr?.filter((el) => el?.menu_id == 6)?.length})`
                                                    : ""
                                            }`}
                                            {...a11yProps(6)}
                                            style={{
                                                display:
                                                    searchValue?.length > 0 &&
                                                    filteredArr?.length > 0 &&
                                                    filteredArr?.filter((el) => el?.menu_id === 6)?.length === 0
                                                        ? "none"
                                                        : "block",
                                            }}
                                        /> */}
                                    </Tabs>
                                </div>
                                <div
                                    className={`${
                                        currentMode !== "dark"
                                            ? "shadow-[0px_4px_20px_rgba(238,238,238,0.5)] "
                                            : "bg-[#121212] border-l-[1px] border-[#333333]"
                                    } min-h-[625px]  xl:w-[800px] w-[700px] inline-block max-h-[626px] overflow-y-auto`}
                                >
                                    <TabPanel value={value} index={0}>
                                        {EditContent}
                                    </TabPanel>
                                    <TabPanel value={value} index={1}>
                                        {EditContent}
                                    </TabPanel>
                                    <TabPanel value={value} index={2}>
                                        {EditContent}
                                    </TabPanel>
                                    <TabPanel value={value} index={3}>
                                        {EditContent}
                                    </TabPanel>
                                    <TabPanel value={value} index={4}>
                                        {EditContent}
                                    </TabPanel>
                                    <TabPanel value={value} index={5}>
                                        {EditContent}
                                    </TabPanel>
                                    <TabPanel value={value} index={6}>
                                        {EditContent}
                                    </TabPanel>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default HelperVideoMain;
