import React from "react";
import { PieChart, Pie, Cell, Sector } from "recharts";
import { Link } from "react-router-dom";

const DonutChart = ({ data, currentMode, Colors, totalValue, totalCount }) => {
    return (
        <div className="items-center justify-between ">
            <div className="relative max-w-[220px] mx-auto max-h-[220px]">
                <PieChart width={220} height={220}>
                    <Pie
                        data={data}
                        cx="50%"
                        cy="50%"
                        stroke={"transparent"}
                        innerRadius={70}
                        outerRadius={100}
                        // cornerRadius={5}
                        fill="#8884d8"
                        dataKey="value"
                        // paddingAngle={3}
                    >
                        {data?.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={Colors[index]} />
                        ))}
                    </Pie>
                </PieChart>

                <div
                    style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        textAlign: "center",
                    }}>
                    <span className={`font-interSb text-[18px] text-${currentMode}-copy`}>{totalCount}</span>
                    <br />
                    <span className={`text-${currentMode}-1280`}>{totalValue}</span>
                </div>
            </div>
            <div className="basis-[60%] max-h-[180px] pr-5 overflow-y-auto mt-3">
                <ul className="legend text-xs">
                    {data.map((entry, index) => {
                        return (
                            <li key={`legend-${index}`} className="w-full flex items-center justify-between py-1 px-2">
                                <div className="flex items-center w-full">
                                    <div className="flex justify-between w-full">
                                        <div className="flex items-center">
                                            <span
                                                className="donut-legend-color inline-block min-w-[8px] min-h-[8px] rounded-[50%]"
                                                style={{
                                                    backgroundColor: Colors[index],
                                                }}
                                            />
                                            <span
                                                title={entry?.name}
                                                className={`text-[16px] max-w-[150px] cursor-pointer ml-2 truncate text-${currentMode}-copy`}>
                                                {entry?.name}
                                            </span>
                                        </div>
                                        <span className={`text-${currentMode}-copy ml-[4rem]`}>
                                            <b className={`font-interM text-[16px]  text-${currentMode}-copy`}>{entry?.value || 0}</b>
                                        </span>
                                    </div>
                                </div>
                            </li>
                        );
                    })}
                </ul>
            </div>
        </div>
    );
};

export default DonutChart;
