import Button from "../../../components/Button";
import Input from "../../../components/Input";
import { emailconfigAdd } from "../../../redux/actions/emailconfig";
import { encryptId } from "../../../utils/helpers";
import { useState, useEffect, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { GoogleLogin } from "react-google-login";
import { gapi } from "gapi-script";
import Swal from "sweetalert2";
import ReactLoginMS from "react-ms-login";
import Loader from "../../../components/Loader";
import { FormControl, MenuItem, Select } from "@mui/material";
import { getDocumentTypeList } from "../../../redux/actions/invoice";
import { ThemeContext } from "../../../Hooks/Themes/Themeprovider";
import { ADD_EMAIL_CONFIG_RESET } from "../../../redux/types/types";
import { Checkbox } from "@mui/material";
const EmailConfigModal = (props) => {
    const { currentMode } = useContext(ThemeContext);

    const btnContent = () => {
        return (
            <span className={`flex text-${currentMode}-copy`}>
                <img className="mr-2" src="/images/common/microsoftIcon.svg" alt="plus" /> Microsoft
            </span>
        );
    };
    const dropdownColor = currentMode === "dark" ? "#333333" : `white`;
    const dropdownText = currentMode === "dark" ? "#ECECEC" : `#555555`;
    const dispatch = useDispatch();
    const [profile, setProfile] = useState([]);
    const [email, setEmail] = useState("");
    const [selectedNumber, setSelectedNumber] = useState();
    const [validEmail, checkValidEmail] = useState(false);
    const [isFeaching, setIsFeaching] = useState(true);
    const [scopes, setScopes] = useState("https://www.googleapis.com/auth/gmail.readonly");

    const clientId = process.env?.REACT_APP_GOOGLE_CLIENT_ID;
    const msClientId = process.env?.REACT_APP_MS_APP_ID;

    // useEffect(() => {
    //     const initClient = () => {
    //         gapi?.client?.init({
    //             clientId: clientId,
    //             accessType: "offline",
    //             prompt: "consent",
    //             scope: "https://www.googleapis.com/auth/gmail.readonly",
    //         });
    //     };

    //     gapi?.load("client:auth2", initClient());
    // });
    useEffect(() => {
        setScopes("https://www.googleapis.com/auth/gmail.readonly");
    }, []);

    const onSuccess = (res) => {
        const data = {
            code: res?.code,
            type: 1,
        };
        setIsFeaching(false);
        axios.post(process.env.REACT_APP_NODE_API_URL + "getOauthGmailToken", data).then((response) => {
            if (response.status == 200) {
                setIsFeaching(true);
                setProfile(response?.data);
                setSelectedNumber(1);
                if (response?.data?.email) {
                    // if(!/^(?!.+@(gmail|google|yahoo|outlook|hotmail|msn)\..+)(.+@.+\..+)$/.test(response?.data?.email)){
                    //   checkValidEmail(false);
                    // } else {
                    //   checkValidEmail(true);
                    // }
                    checkValidEmail(true);
                    setEmail(response?.data?.email);
                }
            }
        });
    };
    const [invoiceType, setInvoiceType] = useState(0);

    const onChangeInvoiceType = (e) => {
        setInvoiceType(+e.target.value);
    };
    const loginMicrosoftHandler = (msData) => {
        const data = {
            code: msData?.code,
            type: 1,
        };
        setIsFeaching(false);
        axios.post(process.env.REACT_APP_NODE_API_URL + "getOauthMSToken", data).then((response) => {
            if (response.status == 200) {
                setIsFeaching(true);
                const tokenInfoData = {
                    access_token: response?.data?.accessToken,
                    refresh_token: response?.data?.refreshToken,
                };
                setProfile({ tokenInfo: tokenInfoData });
                setSelectedNumber(2);
                if (response?.data?.account?.username) {
                    checkValidEmail(true);
                    setEmail(response?.data?.account?.username);
                }
            }
        });
    };
    const onFailure = (err) => {};
    const handleClose = () => {
        if (email !== "") {
            window.location.reload();
        } else {
            props?.cartClose();
            setInvoiceType(0);
            setEmail("");
        }
    };
    const emailOnchange = (e) => {
        setEmail(e.target.value);
    };
    const hanldeAddEmailConfig = () => {
        setEmail("");
        responseMail.requestComplete = false;
        props.cartClose(true);
    };

    const addEmailConfig = () => {
        dispatch(
            emailconfigAdd({
                emailtype: selectedNumber,
                access_token: profile?.tokenInfo?.access_token,
                refresh_token: profile?.tokenInfo?.refresh_token,
                emailaddress: encryptId(email),
                invoice_type: invoiceType,
                organisation_id: props?.orgId,
            })
        );
        localStorage.setItem("UpdateOrgId", props?.orgId);
    };
    const responseMail = useSelector((state) => state?.emailAddConfigState);
    if (responseMail) {
        if (responseMail?.requestComplete && responseMail?.profile.status) {
            Swal.fire({
                text: "Email configuration is created successfully!",
                icon: "success",
                closeOnClickOutside: false,
                showCloseButton: true,
                button: "Ok",
            }).then((confirm) => {
                if (confirm) {
                    hanldeAddEmailConfig();
                    window.location.reload();
                }
            });
        }
        if (responseMail?.requestComplete && !responseMail?.profile.status) {
            Swal.fire({
                text: responseMail?.profile?.message,
                icon: "warning",
                closeOnClickOutside: false,
                showCloseButton: true,
                button: "Ok",
            }).then((confirm) => {
                if (confirm) {
                    dispatch({ type: ADD_EMAIL_CONFIG_RESET });
                    setEmail("");
                    setInvoiceType(0);
                    responseMail.requestComplete = true;
                    responseMail.status = false;
                }
            });
        }
    }
    const { data: listData } = useSelector((state) => state?.getDocumentTypeListState);
    const [acceptTerms, setAcceptTerms] = useState(false);
    return (
        <div className="px-[35px] ">
            <span className="text-sm font-interM mb-5 block">Map an email configuration</span>
            <form>
                <div className="h-[calc(97vh_-_250px)]">
                    <div className={`flex space-x-2 mb-7 google `}>
                        <GoogleLogin
                            clientId={clientId}
                            render={(renderProps) => (
                                <div onClick={() => localStorage.setItem("configClick", true)}>
                                    <Button
                                        onClick={renderProps.onClick}
                                        className={`border-${currentMode}-840 px-7 py-[9px] rounded-[8px] font-interSb text-sm flex items-center border-[1px]  text-${currentMode}-copy`}
                                    >
                                        <img className="mr-2" src="/images/common/googleIcon.svg" alt="plus" />
                                        Google
                                    </Button>
                                </div>
                            )}
                            // redirectUri={}
                            onSuccess={onSuccess}
                            onFailure={onFailure}
                            cookiePolicy={"single_host_origin"}
                            accessType={"offline"}
                            prompt={"consent"}
                            responseType={"code"}
                            scope={scopes}
                        />
                        <ReactLoginMS
                            clientId={msClientId}
                            redirectUri={process.env?.REACT_APP_MS_REDIRECT_URL + "/msAuthComplete.html"}
                            scopes={["Files.Read.All", "Files.ReadWrite.All", "Sites.Read.All", "Sites.ReadWrite.All"]}
                            responseType="code"
                            cssClass={`border-${currentMode}-840 px-5 py-[9px] rounded-[8px] font-interSb text-sm flex items-center border-[1px] text-light-40`}
                            btnContent={btnContent}
                            handleLogin={(data) => loginMicrosoftHandler(data)}
                        />
                    </div>
                    <div className=" max-w-[500px]">
                        <div className="mb-4">
                            <Input
                                disabled
                                onChange={(e) => emailOnchange(e)}
                                placeholder="Email Id"
                                name="emailaddress"
                                value={email}
                                className={`w-full py-2 rounded-[8px] px-5  border-[1px] border-${currentMode}-830 bg-${currentMode}-810`}
                            />
                            {email && !validEmail ? <p className="text-light-50 text-xs">{"Please add your business Email Id"}</p> : ""}
                        </div>
                    </div>
                    <FormControl
                        sx={{
                            minWidth: "494px",
                            marginBottom: 3,
                        }}
                    >
                        <Select
                            name="orgName"
                            value={invoiceType}
                            className="w-full "
                            MenuProps={{
                                sx: {
                                    "& .MuiMenu-paper": {
                                        backgroundColor: dropdownColor,
                                        color: dropdownText,
                                        //   maxWidth: "30rem !important",
                                    },
                                    "& .MuiMenuItem-root:hover": {
                                        backgroundColor: "rgba(218, 249, 244, 0.3)",
                                    },
                                    "& .Mui-selected": {
                                        backgroundColor: "rgba(218, 249, 244, 0.3) !important",
                                    },
                                },
                            }}
                            IconComponent={() => (
                                <img
                                    className="absolute right-[15px] opacity-[.5] pointer-events-none"
                                    src={currentMode !== "dark" ? `/images/header/chevronDown.svg` : `/images/header/chevronDown-white.svg`}
                                    alt="chevronDown"
                                />
                            )}
                            SelectDisplayProps={{
                                style: {
                                    paddingTop: "13px",
                                    paddingBottom: "13px",
                                    color: `${currentMode !== "dark" ? `#555555` : `#ECECEC`}`,
                                    paddingRight: "30px",
                                    paddingLeft: "15px",
                                    borderRadius: "8px",
                                },
                            }}
                            onChange={(e) => onChangeInvoiceType(e)}
                        >
                            <MenuItem key={0} value={0} name="invoice_type">
                                {"Select Document Type"}
                            </MenuItem>
                            {listData &&
                                listData?.map((listelem, i) => {
                                    return (
                                        <MenuItem key={i + 1} name="invoice_type" value={listelem?.id}>
                                            {listelem?.invoice_type}
                                        </MenuItem>
                                    );
                                })}
                            {/* {dataList &&
                    dataList?.map((elem, i) => {
                      return (
                        <MenuItem
                          name="organisation_name"
                          key={i + 1}
                          value={elem?.id}
                          className="truncate trunc-1 block"
                        >
                          {elem?.organisation_name}
                        </MenuItem>
                      );
                    })} */}
                        </Select>
                    </FormControl>
                </div>
                <div className="font-interR text-xs mb-4">
                    <div className="flex form-group items-center form-check">
                        <Checkbox
                            name="signup_acceptTerms"
                            size="small"
                            tabIndex={0}
                            className="mr-2"
                            onChange={(e) => setAcceptTerms(e.target.checked)}
                            value={acceptTerms}
                            sx={{
                                padding: 0,
                                color: "rgba(85,85,85,0.7)",
                                "&.Mui-checked": {
                                    color: "#00B399",
                                },
                            }}
                        />
                        I agree to the MyiQ.Ai
                        <a href={"https://www.myiq.ai/terms-condition"} relative="path" target="_blank" className="inline-block">
                            <div title="Terms & Conditions">
                                <span className="text-light-20 mx-1">Terms & Conditions</span>
                            </div>
                        </a>
                        and
                        <a href={"https://www.myiq.ai/privacy-policy"} relative="path" target="_blank" className="inline-block">
                            <div title="Privacy Policy">
                                <span className="text-light-20 ml-1">Privacy Policy</span>
                            </div>
                        </a>
                    </div>
                </div>
                <div className={`border-t-[1px] py-5 border-${currentMode}-700 mx-[-35px] mt-6`}>
                    <div className="px-[35px] space-x-2 flex">
                        <Button
                            onClick={() => addEmailConfig()}
                            disabled={email && acceptTerms && validEmail && invoiceType !== 0 ? false : true}
                            type="button"
                            title="Create"
                            className="bg-light-20 flex text-base btn-primary font-interR py-[.5rem] px-[2.24rem] rounded-[8px] text-white"
                        >
                            Create
                        </Button>
                        <Button
                            type="button"
                            title="Cancel"
                            onClick={handleClose}
                            className="bg-light-240 flex text-base font-interR py-[.5rem] px-[2.24rem] rounded-[8px] text-light-40 closeEmailModel"
                        >
                            Cancel
                        </Button>
                    </div>
                </div>
            </form>
            {!isFeaching ? <Loader className="h-[calc(100vh_-_15rem)]" /> : ""}
        </div>
    );
};

export default EmailConfigModal;
