import { ClickAwayListener, Box } from "@mui/material";
import { Popover, Popper } from "@mui/material";
import Button from "../../components/Button";
const ColourSelector = (props) => {
    const { id, anchorEl, placement, open, handleClickAway, currentMode, type, colour, changeColour } = props;
    //customise the colour based on id
    const colours = [
        {
            id: 1,
            backgroundColor: "#F5A622",
            isActive: colour == "#F5A622",
        },
        {
            id: 2,
            backgroundColor: "#FF007E",
            isActive: colour == "#FF007E",
        },
        {
            id: 3,
            backgroundColor: "#B100FB",
            isActive: colour == "#B100FB",
        },
        {
            id: 4,
            backgroundColor: "#007BFF",
            isActive: colour == "#007BFF",
        },
        {
            id: 5,
            backgroundColor: "#00BFD8",
            isActive: colour == "#00BFD8",
        },
        {
            id: 6,
            backgroundColor: "#FF6300",
            isActive: colour == "#FF6300",
        },
        {
            id: 7,
            backgroundColor: "#697D88",
            isActive: colour == "#697D88",
        },
        {
            id: 8,
            backgroundColor: "#00A0FF",
            isActive: colour == "#00A0FF",
        },
        {
            id: 9,
            backgroundColor: "#173F5F",
            isActive: colour == "#173F5F",
        },
        {
            id: 10,
            backgroundColor: "#6E16FF",
            isActive: colour == "#6E16FF",
        },
        {
            id: 11,
            backgroundColor: "#886C64",
            isActive: colour == "#886C64",
        },
        {
            id: 12,
            backgroundColor: "#00E000",
            isActive: colour == "#00E000",
        },
        {
            id: 13,
            backgroundColor: "#CB0A44",
            isActive: colour == "#CB0A44",
        },
        {
            id: 14,
            backgroundColor: "#CF3099",
            isActive: colour == "#CF3099",
        },
        {
            id: 15,
            backgroundColor: "#DAAF19",
            isActive: colour == "#DAAF19",
        },
        {
            id: 16,
            backgroundColor: "#97308D",
            isActive: colour == "#97308D",
        },
        {
            id: 17,
            backgroundColor: "#434343",
            isActive: colour == "#434343",
        },
        {
            id: 18,
            backgroundColor: "#00B399",
            isActive: colour == "#00B399",
        },
    ];
    return (
        <>
            {/* <ClickAwayListener mouseEvent="onMouseDown" touchEvent="onTouchStart" onClickAway={handleClickAway}> */}
            <Popover
                // className={`bg-white px-3 py-3 border-[1px] border-[rgba(223,225,226,0.56)] rounded-[10px] `}
                className={`min-h-[115px] min-w-[110px] border-[1px] workflowPopOver rounded-[10px] `}
                open={open}
                anchorEl={anchorEl}
                id={id}
                placement={placement}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
                onClose={handleClickAway}
                transition
            >
                <div className="flex flex-col">
                    <span className={`ml-[20px] mt-[13px] text-${currentMode}-copy`}>Set Colour</span>
                    <div className="grid grid-cols-9 gap-2 p-4 mb-3 ">
                        {colours?.map((item) => {
                            return (
                                <div
                                    className={`flex relative items-center rounded-[50%] cursor-pointer colourSelector ${
                                        item?.isActive ? "border-[#D9D9D9]" : ""
                                    }`}
                                    onClick={(event) => {
                                        changeColour(id, item?.backgroundColor);
                                    }}
                                >
                                    <span
                                        className=" w-[28px] h-[28px] mx-[2px] my-[2px] h rounded-[50%] cursor-pointer"
                                        style={{
                                            backgroundColor: item?.backgroundColor,
                                        }}
                                    />
                                    {item?.isActive && (
                                        <img className="absolute left-[22%] " src="/images/workflow-automation/check.svg" alt="tick-icon" />
                                    )}
                                </div>
                            );
                        })}
                    </div>
                </div>
            </Popover>
            {/* </ClickAwayListener> */}
        </>
    );
};
export default ColourSelector;
