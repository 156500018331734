import { Autocomplete, Button, Checkbox, Chip, Paper, TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Modal from "../../../components/UI/Modal/Modal";
import { decryptId } from "../../../utils/helpers";
import { useDispatch, useSelector } from "react-redux";
import { useContext, useEffect, useState } from "react";
import { ThemeContext } from "../../../Hooks/Themes/Themeprovider";

const AllocationUserModal = (props) => {
    const { currentMode, toggleMode } = useContext(ThemeContext);
    const listData = props?.listData;
    const defaultId = props?.defaultId;
    const userListdata = listData;
    const handleSelectUser = props?.handleSelectUser;
    const AllocListData = useSelector((state) => state?.getInvoiceAllocationListState);
    var invAllocList = AllocListData?.dataList;
    let allocationList = [];
    allocationList = invAllocList?.[0]?.allocation;
    const idData = allocationList?.map((el) => el?.assign_users);

    const GetFilter = userListdata?.filter((o) => defaultId?.includes(+o.user_id));
    const UseridData = userListdata?.map((el) => el?.user_emailaddress);
    const MixArray = idData;
    const string = MixArray?.toString();

    const [selectedOptions, setSelectedOptions] = useState([]);

    useEffect(() => {
        const updatedSelectedOptions = userListdata?.filter((o) => defaultId?.includes(+o.user_id));
        setSelectedOptions(updatedSelectedOptions);
    }, [defaultId]);

    const [close, setClose] = useState(false);
    const [selectstate, setselect] = useState(true);
    const cartClose = () => {
        setClose(true);
        props?.handleClose();
        if (true) {
            setselect(false);
        }
    };
    const dropdownColor = currentMode === "dark" ? "#333333" : `white`;
    const dropdownText = currentMode === "dark" ? "#ECECEC" : `#555555`;
    const dropdownShadow = currentMode !== "dark" ? "0px 4px 20px rgba(238, 238, 238, 0.5)" : "";
    const dropdownBorder = currentMode !== "dark" ? "223, 225, 226, 0.56" : "";
    const useStyles = makeStyles((theme) => ({
        dropdown: {
            "& .MuiAutocomplete-inputRoot": {
                "& .MuiAutocomplete-input": {
                    color: "white", // Replace with the color you want
                    "&::placeholder": {
                        color: "gray", // Replace with the placeholder color you want
                    },
                },
            },
            "& .MuiAutocomplete-popupIndicator": {
                color: "black", // Replace with the dropdown icon color you want
            },
        },
    }));

    const classes = useStyles();

    return (
        <>
            <Modal cartClose={cartClose} center="center" className={`${props?.className}`}>
                <div
                    className={`max-w-[559px] mx-auto relative bg-${currentMode}-card text-${currentMode}-copy border-${currentMode}-700  rounded-[10px] ${
                        currentMode === "dark" ? "" : "shadow-[0px_4px_20px_rgba(238,238,238,0.5)]"
                    }`}
                >
                    <div className={`flex justify-between items-center px-[30px] py-[24px] border-b-[1px] border-${currentMode}-590`}>
                        <h4 className="font-interSb font-lg">Add Users</h4>
                        <span onClick={cartClose} className="cursor-pointer" tabIndex={0}>
                            <img src="/images/common/closeBlk.svg" alt="closeBlk" className="convertWhite" />
                        </span>
                    </div>
                    <div className="flex justify-center filter items-center py-7">
                        <Autocomplete
                            classes={{
                                paper: classes?.dropdown,
                            }}
                            sx={{
                                "& .MuiChip-label": {
                                    color: `${currentMode == "dark" ? "white" : "black"}`,
                                },
                            }}
                            PaperComponent={({ children }) => (
                                <Paper
                                    style={{
                                        backgroundColor: dropdownColor,
                                        color: dropdownText,
                                        boxShadow: dropdownShadow,
                                        border: dropdownBorder,
                                    }}
                                >
                                    {children}
                                </Paper>
                            )}
                            popupIcon={<img className="m-0 opacity-50 pr-1 pointer-events-none" src="/images/header/chevronDown.svg" />}
                            multiple
                            //   defaultValue={GetFilter}
                            value={GetFilter?.length !== 0 ? GetFilter : []}
                            id="checkboxes-tags-demo"
                            options={userListdata == undefined ? [] : userListdata}
                            disableCloseOnSelect
                            limitTags={2}
                            getOptionLabel={(option) => decryptId(option?.user_emailaddress)}
                            onChange={handleSelectUser}
                            renderOption={(props, option, { selected }) => {
                                return (
                                    <li {...props}>
                                        <Checkbox
                                            style={{ marginRight: 8 }}
                                            sx={{
                                                color: "rgba(85,85,85,0.7)",
                                                borderRadius: "3px !important",
                                                "&.Mui-checked": {
                                                    color: "#028C78",
                                                },
                                            }}
                                            //   onChange={handleCheckboxChange(option)}
                                            checked={selected}
                                        />
                                        <img
                                            className="w-[22px] h-[22px] object-cover rounded-full"
                                            alt="Natacha"
                                            src={`/images/common/profileImg.jpg`}
                                        />
                                        {decryptId(option?.user_emailaddress)}
                                    </li>
                                );
                            }}
                            style={{ width: 500 }}
                            renderInput={(params) => (
                                <TextField
                                    sx={{
                                        "& .MuiInputBase-input": {
                                            color: `${currentMode !== "dark" ? `#555555` : `#ECECEC`}`,
                                        },
                                    }}
                                    {...params}
                                    placeholder={GetFilter?.length == 0 ? "Select Users" : ""}
                                    autoComplete="off"
                                />
                            )}
                        />
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default AllocationUserModal;
