import {
    GET_NOTIFICATION_FAILURE,
    GET_NOTIFICATION_REQUEST,
    GET_NOTIFICATION_SUCCESS,
    GET_NOTIFICATION_RESET,
    GET_NOTIFICATION_LIST_FAILURE,
    GET_NOTIFICATION_LIST_REQUEST,
    GET_NOTIFICATION_LIST_SUCCESS,
    GET_NOTIFICATION_LIST_RESET,
    UPDATE_NOTIFICATION_FAILURE,
    UPDATE_NOTIFICATION_REQUEST,
    UPDATE_NOTIFICATION_SUCCESS,
    UPDATE_NOTIFICATION_RESET,
    GET_NOTIFICATION_SCROLL_FAILURE,
    GET_NOTIFICATION_SCROLL_REQUEST,
    GET_NOTIFICATION_SCROLL_SUCCESS,
    GET_NOTIFICATION_SCROLL_RESET,
} from "../types/types";

const getNotify = {
    data: [],
    fetching: false,
    requestComplete: false,
    error: "",
    isScroll: false,
    page: 1,
    hasMore: true,
};

export function GetPushNotificationReducer(state = getNotify, action) {
    switch (action.type) {
        case GET_NOTIFICATION_REQUEST:
            return {
                ...state,
                fetching: true,
                requestComplete: false,
                error: "",
            };
        case GET_NOTIFICATION_SCROLL_REQUEST:
            return {
                ...state,
                isScroll: true,
            };
        case GET_NOTIFICATION_SUCCESS:
            return {
                ...state,
                data: action.payload,
                fetching: false,
                requestComplete: true,
                error: "",
                page: 1,
                hasMore: action?.payload?.length < 10 ? false : true,
            };
        case GET_NOTIFICATION_SCROLL_SUCCESS:
            const uniqueSets = new Set();
            const uniqueArrays = [];
            const parseData = [...state.data, ...action.payload];
            parseData?.map((elem) => {
                const obj = elem;
                if (!uniqueSets.has(obj?.id)) {
                    uniqueSets.add(obj?.id);
                    uniqueArrays.push(obj);
                }
            });
            return {
                //data: [...state.data, ...action.payload],
                data: uniqueArrays,
                fetching: false,
                requestComplete: true,
                error: "",
                isScroll: false,
                page: state.page + 1,
                hasMore: action?.payload?.length == 0 ? false : true,
            };

        case GET_NOTIFICATION_RESET:
            return {
                data: [],
                fetching: false,
                requestComplete: false,
                error: "",
            };
        case GET_NOTIFICATION_SCROLL_RESET:
            return {
                data: [],
                fetching: false,
                requestComplete: false,
                error: "",
            };
        case GET_NOTIFICATION_FAILURE:
            return {
                fetching: false,
                requestComplete: false,
                error: action.payload,
            };
        case GET_NOTIFICATION_SCROLL_FAILURE:
            return {
                fetching: false,
                requestComplete: false,
                error: action.payload,
            };
        default:
            return state;
    }
}

const getNotifyList = {
    data: [],
    fetching: false,
    requestComplete: false,
    error: "",
};

export function GetNotificationListReducer(state = getNotifyList, action) {
    switch (action.type) {
        case GET_NOTIFICATION_LIST_REQUEST:
            return {
                ...state,
                fetching: true,
                requestComplete: false,
                error: "",
            };
        case GET_NOTIFICATION_SCROLL_SUCCESS:
            return {
                data: action.payload,
                fetching: false,
                requestComplete: true,
                error: "",
            };
        case GET_NOTIFICATION_LIST_RESET:
            return {
                data: {},
                fetching: false,
                requestComplete: false,
                error: "",
            };
        case GET_NOTIFICATION_LIST_FAILURE:
            return {
                fetching: false,
                requestComplete: false,
                error: action.payload,
            };
        default:
            return state;
    }
}

const UpdateNotify = {
    Data: [],
    fetching: false,
    requestComplete: false,
    error: "",
};

export function UpdateNotificationReducer(state = UpdateNotify, action) {
    switch (action.type) {
        case UPDATE_NOTIFICATION_REQUEST:
            return {
                ...state,
                fetching: true,
                requestComplete: false,
                error: "",
            };
        case UPDATE_NOTIFICATION_SUCCESS:
            return {
                Data: action.payload,
                fetching: false,
                requestComplete: true,
                error: "",
            };
        case UPDATE_NOTIFICATION_RESET:
            return {
                Data: [],
                fetching: false,
                requestComplete: false,
                error: "",
            };
        case UPDATE_NOTIFICATION_FAILURE:
            return {
                fetching: false,
                requestComplete: false,
                error: action.payload,
            };
        default:
            return state;
    }
}
