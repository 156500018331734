import Modal from "../../../../components/UI/Modal/Modal";
import { MyTaskModal } from "./MyTaskModal";
import { useContext } from "react";
import { ThemeContext } from "../../../../Hooks/Themes/Themeprovider";

export const MyTask = (props) => {
    const { currentMode } = useContext(ThemeContext);

    return (
        <Modal cartClose={props?.handleClose} className={`${props?.className}`}>
            <span onClick={props?.handleClose} className="cursor-pointer p-[6.5px] absolute right-[775px] bg-light-10">
                <img src="/images/common/whiteClose.svg" alt="whiteClose" />
            </span>
            <div
                className={`max-w-[775px] ml-auto bg-${currentMode}-card overflow-y-auto transition duration-150 ease-out h-screen max-h-[-webkit-fill-available]`}
            >
                <MyTaskModal cartClose={props?.handleClose} />
            </div>
        </Modal>
    );
};
