import { useContext } from "react";
import Main from "../../components/UI/Main";
import Layout from "../../Hoc/Layout";
import Card from "../../components/UI/Card";
import Footer from "../../organisms/Footer";
import "./Tree.scss";
import { ThemeContext } from "../../Hooks/Themes/Themeprovider";
import Button from "../../components/Button";
import { Link } from "react-router-dom";
import TogglePopup from "../organisations/Toggle";
import { useState, useEffect, useRef } from "react";
import StatusPopper from "../WorkflowAutomation/statusPopper";
import { v4 as uuidv4 } from "uuid";
import CreateEditStatusName from "../WorkflowAutomation/CreateEditStatusName";
import ReactDOM from "react-dom";
import NotificationAllocation from "../WorkflowAutomation/WorkflowConfiguration/NotificationAllocation";
import { encryptId } from "../../utils/helpers";
import PermissionsModal from "../WorkflowAutomation/WorkflowConfiguration/PermissionsModal";
import UsersModal from "../WorkflowAutomation/WorkflowConfiguration/UsersModal";
import FournotFour from "../404";
import { ErrorBoundary } from "react-error-boundary";
import EditBox from "../../components/UserCard/EditBox";
import { useDispatch } from "react-redux";
import StatusInputField from "../WorkflowAutomation/StatusInputField";
import _, { cloneDeep } from "lodash";
import Swal from "sweetalert2";
import ColourSelector from "../WorkflowAutomation/ColourSelector";
import DynamicProfile from "../WorkflowAutomation/dynamicProfile";
import {
    getWorkFlowDataByOrg,
    postWorkFlowByOrg,
    DeleteWorkFlowStatus,
    getWorkFlowPermissionLayoutByOrg,
} from "../../redux/actions/workflow-automation";
import { useSelector } from "react-redux";
import { POST_WORKFLOW_AUTOMATION_BYORG_RESET, WORKFLOW_STATUS_DELETE_RESET } from "../../redux/types/types";
import Loader from "../../components/Loader/index.jsx";
import AddExceptionCategory from "./ExceptionCategory";
import { Accordion, Checkbox } from "@mui/material";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { styled } from "@mui/material/styles";
import { CheckBox } from "@mui/icons-material";
import { DaysRange } from "../../utils/constants";
import EmailCategory from "./Email";
import CreateReminderModal from "./CreateReminder";
import EmailConfig from "./EmailConfigModal";
import AcknowledgementCard from "./Acknowledgement";
let getId;
let defaultVal = "";
let createDisplayOrder;
let deleteID = null;

const ErrorFallback = ({ error }) => {
    return (
        <>
            <FournotFour />
        </>
    );
};
const ExceptionAutomation = () => {
    //* Variable Decleartion
    const { currentMode, toggleMode } = useContext(ThemeContext);
    const outsideCreate = [
        {
            id: 1,
            src: "/images/workflow-automation/add-status.svg",
            name: "Add Status",
        },
        {
            id: 2,
            src: "/images/workflow-automation/add-conditions.svg",
            name: "Add Conditional Status",
        },
    ];
    const insideCreate = [
        {
            id: 2,
            src: "/images/workflow-automation/add-conditions.svg",
            name: "Add Conditional Status",
        },
    ];
    const mainStatusCreate = [
        {
            id: 1,
            src: "/images/workflow-automation/add-status.svg",
            name: "Add Status",
        },
    ];
    const [filter, setFilter] = useState(false);
    const filterMenuList = [
        {
            text: "View",
            title: "View",
            src: "/images/Exception-Automation/eye.svg",
            tabIndex: "1",
            className: "convertWhite",
            value: 1,
        },

        {
            text: "Add Child",
            title: "Add Child",
            src: "/images/Exception-Automation/plusiconblack.svg",
            tabIndex: "1",
            className: "convertWhite",
            value: 2,
        },
    ];
    const onFilterItems = (value, id) => {
        setFilter(id);
    };
    const [selectedId, setSelectedId] = useState(null);
    const dispatch = useDispatch();
    const { data: Data, requestComplete } = useSelector((state) => state?.getWorkFlowDataState);
    const { data: dataList } = useSelector((state) => state?.getWorkFlowPermissionByOrgState);
    const { fetching: postFetch, requestComplete: postReqComp, data: postData } = useSelector((state) => state?.postWorkFlowByOrgState);
    const { requestComplete: deleteReqComp, error: deleteError, data: deleteData } = useSelector((state) => state?.workFlowStatusDeleteState);
    const [workflowData, setWorkFlowData] = useState();
    const [updatedPermissionData, setUpdatedPermissionData] = useState([]);
    const [openCategory, setOpenCategory] = useState(false);
    const [openReminder, setOpenReminder] = useState(false);
    const [openEmailConfig, setOpenEmailConfig] = useState(false);
    const orgId = localStorage.getItem("orgId");
    const user = localStorage?.getItem("user");
    const licenseType = Data?.license_type || 3;
    const gridData = [
        {
            id: 1,
            data: "PO Approved",
        },
        {
            id: 1,
            data: "PO Approved",
        },
        {
            id: 1,
            data: "PO Approved",
        },
        {
            id: 1,
            data: "PO Approved",
        },
        {
            id: 1,
            data: "PO Approved",
        },


    ];
    //get workflow data based on organisation
    // useEffect(() => {
    //     if (orgId) {
    //         dispatch(getWorkFlowDataByOrg(orgId));
    //         setSelectedId(null);
    //         setActiveParams(false);
    //     }
    // }, [orgId]);
    useEffect(() => {
        if (Data) {
            setWorkFlowData(Data);
            // Create a new array with modified objects
            const newArray = _.map(Data?.work_flow, (obj) => ({
                ...obj,
                inside_id: uuidv4(),
                outside_id: uuidv4(),
            }));

            const sortedWorkflow = _.sortBy(newArray, "disp_order");
            setCheck(sortedWorkflow);
        }
    }, [Data]);
    //* For donut chart
    const data = [
        { name: "Submitted", value: 400 },
        { name: "Managing Director Approval", value: 300 },
        { name: "Finance Manager Approval Finance approval", value: 300 },
        { name: "CFO Approval", value: 200 },
    ];
    //* Static Variable --> Change this state with reducer
    const sortedWorkflow = _.sortBy(workflowData?.work_flow, "disp_order");
    const cloneData = _.cloneDeep(sortedWorkflow);
    const [check, setCheck] = useState(sortedWorkflow);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const [editAnchorEl, setEditAnchorEl] = useState(null);
    const [colourSelectorAnchor, setColourSelectorAnchor] = useState(null);
    const colorSelectorOpen = Boolean(colourSelectorAnchor);
    //* Variables for workflow Configuration
    const [params, setParams] = useState([
        {
            id: 1,
            name: "Users",
            isActive: false,
            message:
                "Users are part of the organization to perform specific workflow tasks. A user can occupy several roles and add multiple roles and rules.",
        },
        {
            id: 2,
            name: "Permissions",
            isActive: false,
            message: "Permissions define the level of access and actions for the users or roles within an organization.",
        },
        {
            id: 3,
            name: "Notification",
            isActive: false,
            message:
                "Notifications are automated communication sent to individuals or groups to inform about the status, progress, or specific events within a business process.",
        },
    ]);
    // const noActiveParams = selectedId !== null && params?.find((item) => item?.isActive == true);
    const [noActiveParams, setActiveParams] = useState(false);
    const [selectedStatus, setSelectedStatus] = useState();
    useEffect(() => {
        if (selectedId !== null) {
            const getArr = check.find((el, i) => i == selectedId);
            setSelectedStatus(getArr);
            if (getArr?.master_refid == 3 || getArr?.master_refid == 4) {
                setActiveParams(false);
            } else {
                setActiveParams(params?.find((item) => item?.isActive == true));
            }
        }
    }, [selectedId, params]);

    const messageToShow = (
        params?.find((item) => item?.isActive == true) ?? {
            message: "Start your workflow configuration by adding multiple roles and rules before the approval process",
        }
    )?.message;

    //* Variables for Notification
    const [receivers, setReceivers] = useState([]);
    const [bccreceivers, setBccRecievers] = useState([]);
    const [notificationenable, setnotificationEnable] = useState(false);
    const [Title, setTitle] = useState();
    const [message, setMessage] = useState("");
    const [Emailenable, setemailEnable] = useState();
    const [subject, setSubject] = useState("");
    const [emailMessage, setEmailmessage] = useState();
    const [reset, setReset] = useState(false);
    const divBorder = currentMode == "dark" ? "#FFFFFF" : "#E4E4E4";

    //* Function Calls for tree structure
    const handleDivClick = (index) => {
        setCheck((prevCheck) =>
            prevCheck.map((item, checkIndex) => ({
                ...item,
                isActive: checkIndex === index,
            }))
        );
        setSelectedId(index);
        setParams((prevCheck) =>
            prevCheck.map((item, checkIndex) => ({
                ...item,
                isActive: item?.name == "Users",
            }))
        );
        setColourSelectorAnchor(false);
        if (index !== selectedId) {
            setIsStatusEdit(false);
        }
    };
    //to hide the popper
    const handleClickAway = () => {
        getId = null;
        setAnchorEl(false);
        createDisplayOrder = null;
    };
    const [isStatusEdit, setIsStatusEdit] = useState(false);
    const [createStatusType, setCreateStatusType] = useState(null);
    //click to enable edit field for status name
    const openEdit = (item, index, isEdit) => {
        defaultVal = item?.status_name;
        handleClickAway();
        if (isEdit === "edit") {
            setIsStatusEdit(true);
        } else {
            setEditAnchorEl(true);
            setIsStatusEdit(false);
            setCreateStatusType(isEdit);
            createDisplayOrder = index + 1;
        }
    };
    //update the status name by click confirm
    const updateUser = (value, id, ind) => {
        const isDuplicate = check?.filter((item, index) => index != ind)?.some((ele) => ele?.status_name?.toLowerCase() === value?.toLowerCase());
        if (isDuplicate || value == "" || value?.length > 30) {
            Swal.fire({
                text: `${value?.length === 0
                    ? "Status Name cannot be empty"
                    : value?.length > 30
                        ? "Status Name should be minimum 3 to maximum 30 characters"
                        : "Status Name cannot be same"
                    }`,
                icon: "warning",
                allowOutsideClick: false,
                showCloseButton: true,
                buttons: {
                    confirm: true,
                },
            }).then((confirm) => {
                if (confirm) {
                    check?.forEach((ele, index) => {
                        if (index === ind) {
                            ele.status_name = "";
                            setIsStatusEdit(true);
                        }
                    });
                }
            });
        } else {
            check.forEach((ele, index) => {
                if (index == ind) {
                    ele.status_name = value;
                }
            });
            setIsStatusEdit(false);
        }
    };
    //remove the status based on id index
    const handleDeleteStatus = (obj, ind) => {
        if (obj) {
            Swal.fire({
                text: "Are you sure you want to delete the Status?",
                icon: "warning",
                allowOutsideClick: false,
                showCancelButton: true,
                showCloseButton: true,
                buttons: {
                    cancel: "Cancel",
                    confirm: "Confirm",
                },
            }).then((confirm) => {
                if (confirm?.isConfirmed) {
                    setActiveParams(false);
                    if (!obj?.is_default) {
                        if (obj?.id !== 0) {
                            dispatch(DeleteWorkFlowStatus(obj?.id, orgId));
                            deleteID = obj?.id;
                        } else if (obj?.id == 0) {
                            setCheck((prevCheck) => {
                                // Filter out the object with id 0 at the specified index
                                const filteredCheck = prevCheck.filter((ele, index) => index !== ind);
                                // Update the disp_order values based on the new array positions
                                const updatedCheck = filteredCheck.map((ele, index) => ({
                                    ...ele,
                                    disp_order: index + 1,
                                }));
                                return updatedCheck;
                            });
                        }
                    }
                }
            });
        }
    };
    //click to active the user , permission, notification module based on index
    const handleSelectModule = (index) => {
        setParams((prevCheck) =>
            prevCheck.map((item, checkIndex) => ({
                ...item,
                isActive: checkIndex === index,
            }))
        );
        closeUpdate();
    };
    //discard the edited or open edited field for status name
    const closeUpdate = (value, id, i) => {
        check.forEach((ele) => {
            if (ele?.id == id) {
                ele.status_name = defaultVal;
            }
        });
        setIsStatusEdit(false);
    };
    // to colour picker open
    const colourSelectorClickAway = () => {
        setColourSelectorAnchor(false);
    };

    //to select the colour on picker based on id for specific status
    const changeColour = (id, bgColour) => {
        setCheck((prev) => {
            return prev.map((item, index) => {
                if (index == id) {
                    return { ...item, status_color: bgColour };
                } else {
                    return item;
                }
            });
        });
    };
    //check status length on every new creation
    const checkStatusDataLength = () => {
        Swal.fire({
            text: "unable to create a status more than twenty.",
            icon: "warning",
            allowOutsideClick: false,
            showCloseButton: true,
            buttons: {
                confirm: "Confirm",
            },
        });
    };
    //to open status create field
    const handleCreateId = (Id, val) => {
        getId = Id;
        if (check.length >= 20) {
            checkStatusDataLength();
        } else {
            if (dataList?.field_list?.length == undefined) {
                dispatch(getWorkFlowPermissionLayoutByOrg(orgId));
            }
            setAnchorEl(val);
        }
    };
    let isRuleFlagTrue = true;
    const oneRuleCondition = (status_name) => {
        const config = {
            icon: "warning",
            closeOnClickOutside: false,
            showCloseButton: true,
            button: "Ok",
            html: `<p>Atleast One Rule need to be selected in Conditional Status- ${status_name}</p>`, // Enable HTML rendering
        };
        Swal.fire(config);
        return true;
    };
    // Define a function to check conditions for a single user_set item
    const checkConditions = (item, mainObj, updatedArr) => {
        if (mainObj?.status_type == 2) {
            // Assuming mainObj.user_set is your array
            isRuleFlagTrue = _.some(mainObj?.user_set, { rule_flag: true });
        }
        if (!mainObj?.user_set && mainObj?.status_type != 2) return false;
        const config = {
            icon: "warning",
            closeOnClickOutside: false,
            showCloseButton: true,
            button: "Ok",
            html: true, // Enable HTML rendering
        };

        if (!item?.rule_flag && !item?.user_flag && !item?.role_flag && !item?.round_robin) {
            config.html = `<p>Select any one of the following rules checkbox in <strong class="font-interB">${mainObj.status_name
                }</strong> the status and before clicking the <strong class="font-interB">Save</strong> button.</p>
            <br/>
            <strong class="font-interB">Round Robin</strong> or
            ${licenseType != 3 ? "<strong class='font-interB'>Rule Based</strong> or" : ""}
            <strong class="font-interB">Role Based</strong> or <strong class="font-interB">Select Users</strong>`;
        } else if (mainObj?.status_type == 2 && !isRuleFlagTrue) {
            config.html = `<p>Atleast One Rule need to be selected in Conditional Status - ${mainObj.status_name}</p>`;
        }
        //  else if (mainObj.status_type === 2 && !item.rule_flag && !item.user_flag) {
        //     config.html = `<p>Rule or Users must be selected for conditional status - ${mainObj.status_name}</p>`;
        // }
        else if (item.rule_flag && item.rule_id === 0) {
            config.html = `${mainObj.status_type === 2
                ? `<p>Rule must be selected for conditional status - ${mainObj.status_name}</p>`
                : `<p>Please select Rule for status - ${mainObj.status_name}</p>`
                }`;
        } else if (item.role_flag && item.role_id === 0) {
            config.html = `${mainObj.status_type === 2
                ? `<p>Role must be selected for conditional status - ${mainObj.status_name}</p>`
                : `<p>Please select Role for status - ${mainObj.status_name}</p>`
                }`;
        } else if (item.rule_flag && item.rule_id !== 0 && (!item.rule_user || item.rule_user.length === 0)) {
            config.html = `${mainObj.status_type === 2
                ? `<p>User must be selected based on rule for conditional status - ${mainObj.status_name}</p>`
                : `<p>Please select Users based on Rule - ${mainObj.status_name}</p>`
                }`;
        } else if (item.user_flag && (!item.user_list || item.user_list.length === 0)) {
            config.html = `${mainObj.status_type === 2
                ? `<p>User must be selected for conditional status - ${mainObj.status_name}</p>`
                : `<p>Please select Users - ${mainObj.status_name}</p>`
                }`;
        } else {
            updatedArr?.map((el) => {
                if (el?.notification) {
                    if (el?.notification?.push_flag && (!el?.notification?.push_title || !el?.notification?.push_message)) {
                        config.html = `<p>Need to fill all the fields on Push Notification in <b>${el?.status_name}</b> Status</p>`;
                        // return true;
                    } else if (
                        el?.notification?.email_flag &&
                        (!el?.notification?.email_body || !el?.notification?.email_subject || el?.notification?.email_body == `<p><br></p>`)
                    ) {
                        config.html = `<p>Need to fill the fields Subject and Body on Email Notification in <b>${el?.status_name}</b> Status</p>`;
                        // return true;
                    } else {
                        return false;
                    }
                }
            });
        }
        // Check if there's an HTML message to display
        if (config.html !== true) {
            Swal.fire(config);
            return true;
        }
    };

    //add update post API call
    const handleSubmitWorkFlow = () => {
        // Use _.map to iterate through each object and _.omit to remove properties
        const updatedArr = cloneDeep(_.map(check, (obj) => _.omit(obj, ["inside_id", "outside_id", "isActive"])));
        // Use _.differenceWith to get the difference between updatedArr and clonedData for each main object
        const updatedArrWithoutDuplicates = updatedArr.map((mainObj, index) => {
            const clonedObj = cloneData[index];
            mainObj.notification.email_bcc =
                mainObj.notification.email_bcc?.length > 0 ? mainObj.notification.email_bcc?.map((i) => encryptId(i)) : [];
            mainObj.notification.email_cc = mainObj.notification.email_cc?.length > 0 ? mainObj.notification.email_cc?.map((i) => encryptId(i)) : [];
            if (clonedObj && clonedObj.permission.field_list) {
                // Use _.differenceWith to get the difference between field_list arrays
                const updatedFieldListWithoutDuplicates = _.differenceWith(mainObj.permission.field_list, clonedObj.permission.field_list, _.isEqual);
                // Clone the main object and update the field_list
                return {
                    ...mainObj,
                    permission: {
                        ...mainObj.permission,
                        field_list: updatedFieldListWithoutDuplicates,
                    },
                };
            }

            // If there is no cloned object or field_list, return the original main object
            return mainObj;
        });
        const reqObj = {
            orgid: +orgId,
            work_flow: updatedArrWithoutDuplicates,
            docgroup: 1,
        };
        // Use some to check if atleast one condition is true for atleast one object in any user_set array of all main objects
        const shouldPreventApiCall = updatedArr.some((mainObj) => {
            const Obj = mainObj?.master_refid !== 3 && mainObj?.master_refid !== 4 && mainObj;
            return (!Obj?.user_set || Obj?.user_set?.length == 0) && mainObj?.status_type == 2
                ? oneRuleCondition(mainObj?.status_name)
                : Obj?.user_set?.some((item) => checkConditions(item, mainObj, updatedArr));
            // Add more conditions as needed
        });
        if (!shouldPreventApiCall) {
            // If the condition is not met for any object, proceed with the API call
            dispatch(postWorkFlowByOrg(reqObj));
        }
    };
    //message display based on API response
    useEffect(() => {
        if (postReqComp) {
            if (postReqComp && postData?.status) {
                Swal.fire({
                    text: `${postData?.message}`,
                    icon: "success",
                    closeOnClickOutside: false,
                    showCloseButton: true,
                    button: "Ok",
                }).then((confirm) => {
                    if (confirm) {
                        dispatch({ type: POST_WORKFLOW_AUTOMATION_BYORG_RESET });
                        // window.location.reload();
                        setSelectedId(null);
                        dispatch(getWorkFlowDataByOrg(orgId));
                        setActiveParams(false);
                    }
                });
            }
            if (postReqComp && !postData?.status) {
                Swal.fire({
                    text: `${postData?.message}`,
                    icon: "warning",
                    closeOnClickOutside: false,
                    showCloseButton: true,
                    button: "Ok",
                }).then((confirm) => {
                    if (confirm) {
                        dispatch({ type: POST_WORKFLOW_AUTOMATION_BYORG_RESET });
                        // window.location.reload();
                        // dispatch(getWorkFlowDataByOrg(orgId));
                    }
                });
            }
        }
        if (deleteReqComp && deleteData?.status) {
            Swal.fire({
                text: `${deleteData?.message}`,
                icon: "success",
                closeOnClickOutside: false,
                showCloseButton: true,
                button: "Ok",
            }).then((confirm) => {
                if (confirm) {
                    dispatch({ type: WORKFLOW_STATUS_DELETE_RESET });
                    setCheck((prevCheck) => {
                        // Filter out the object with id 0 at the specified index
                        const filteredCheck = prevCheck?.filter((ele, index) => {
                            return ele?.id != deleteID;
                        });
                        // Update the disp_order values based on the new array positions
                        const updatedCheck = filteredCheck.map((ele, index) => ({
                            ...ele,
                            disp_order: index + 1,
                        }));
                        return updatedCheck;
                    });
                    setSelectedId(null);
                }
            });
        }
        if (deleteReqComp && !deleteData?.status) {
            Swal.fire({
                text: `${deleteError?.message}`,
                icon: "warning",
                closeOnClickOutside: false,
                showCloseButton: true,
                button: "Ok",
            }).then((confirm) => {
                if (confirm) {
                    dispatch({ type: WORKFLOW_STATUS_DELETE_RESET });
                }
            });
        }
    }, [postReqComp, deleteReqComp]);
    const [expandMe, setExpandMe] = useState("1");
    const handleChangeMe = (panel) => (event, newExpanded) => {
        setExpandMe(newExpanded ? panel : false);
    };
    const [expandPref, setExpandPref] = useState("0");
    const handleChangePref = (panel) => (event, newExpanded) => {
        setExpandPref(newExpanded ? panel : false);
    };
    const [expandEmail, setExpandEmail] = useState("0");
    const handleChangeEmail = (panel) => (event, newExpanded) => {
        setExpandEmail(newExpanded ? panel : false);
    };
    const [expandEscal, setExpandEscal] = useState("0");
    const handleChangeEscal = (panel) => (event, newExpanded) => {
        setExpandEscal(newExpanded ? panel : false);
    };
    const [expandReminder, setExpandReminder] = useState("0");
    const handleChangeReminder = (panel) => (event, newExpanded) => {
        setExpandReminder(newExpanded ? panel : false);
    };
    const [expandAcknow, setExpandAcknow] = useState("0");
    const handleChangeAcknow = (panel) => (event, newExpanded) => {
        setExpandAcknow(newExpanded ? panel : false);
    };
    const childCompRef = useRef();
    const AccordionSummary = styled((props) => (
        <MuiAccordionSummary
            expandIcon={
                <img
                    src="/images/header/chevronDown.svg"
                    alt="accordion-active"
                />
            }
            {...props}
        />
    ))(({ theme }) => ({
        padding: "11px 30px",
        margin: 0,
        // flexDirection: "row-reverse",
        "& .MuiAccordionSummary-expandIconWrapper": {
            marginRight: 10,
            transform: "rotate(270deg)",
            filter: `${currentMode === "dark" ? "filter: brightness(0) invert(1)" : "brightness(0) invert(0)"}`,
        },
        "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
            transform: "rotate(360deg)",
            filter: "none",
        },
        "& .MuiAccordionSummary-content": {
            margin: 0,
        },
    }));
    const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
        padding: 0,
        border: 0,
    }));

    const [folders, setFolders] = useState([
        {
            id: 1,
            name: "Folder 1",
            children: [
                {
                    id: 2,
                    name: "Subfolder 1",
                    children: [
                        {
                            id: 4,
                            name: "File 1",
                        },
                        {
                            id: 5,
                            name: "File 2",
                        },
                    ],
                },
                {
                    id: 3,
                    name: "Subfolder 2",
                    children: [
                        {
                            id: 6,
                            name: "File 3",
                        },
                    ],
                },
            ],
        },
    ]);

    const toggleFolder = (id) => {
        setFolders((prevFolders) => prevFolders.map((folder) => (folder.id === id ? { ...folder, isOpen: !folder.isOpen } : folder)));
    };

    const renderFolder = (folder) => {
        return (
            <li key={folder.id}>
                <span
                    className={`cursor-pointer ${folder.isOpen ? "rotate-90" : "rotate-0"} mr-2`}
                    onClick={() => toggleFolder(folder.id)}
                >
                    &#9658;
                </span>
                <span className="folder">{folder.name}</span>
                {folder.isOpen && <ul>{folder.children.map((child) => (child.children ? renderFolder(child) : renderFile(child)))}</ul>}
            </li>
        );
    };

    const renderFile = (file) => {
        return (
            <li key={file.id}>
                <span className="file">{file.name}</span>
            </li>
        );
    };

    function toggle(id) {
        const element = document.getElementById(id);
        element.classList.toggle("hidden");
    }
    const TreeNode = ({ label, children }) => {
        return (
            <li>
                <div className="flex justify-between bg-transparent ">
                    <span>
                        {label}
                        <Button
                            onClick={(e) => childCompRef.current.handleClick("bottom-end", 1, e)}
                            className={`px-2 userMore-step ml-[60px]  `}
                            tabIndex={0}
                        >
                            <img
                                src="/images/Exception-Automation/threedotsbalck.svg"
                                alt="threedots-black"
                                className="convertWhite"
                            />
                        </Button>
                        {/* <TogglePopup ref={childCompRef} className="bg-blue-500" menuList={filterMenuList} selectedItemChange={onFilterItems} /> */}
                    </span>
                </div>

                {children && <ul>{children}</ul>}
            </li>
        );
    };

    return (
        <>
            <Layout>
                <ErrorBoundary FallbackComponent={ErrorFallback}>
                    <Main>
                        <div className="mb-5 flex justify-between">
                            <div>
                                <h4 className={`text-2xl font-interSb text-${currentMode}-copy`}>Exception-Automation</h4>
                            </div>
                            <div className=" flex text-right">
                                <Link
                                    tabIndex={0}
                                    to="/admin-setting"
                                    // onClick={}
                                    title="Cancel"
                                >
                                    {" "}
                                    <Button className="bg-light-240 text-light-40 text-base rounded-[8px] py-2 px-5">Cancel</Button>
                                </Link>
                                <Button
                                    tabIndex={0}
                                    className="bg-light-20 text-white text-base rounded-[8px] ml-2 py-2 px-7"
                                    title={"Save"}
                                >
                                    {"Save"}
                                </Button>
                            </div>
                        </div>
                        {check && (
                            <div className="xl:flex xl:space-x-3">
                                <Card
                                    className={` basis-[60%] bg-[linear-gradient(to_right,#80808012_0.1px,transparent_1px),linear-gradient(to_bottom,#80808012_0.1px,transparent_1px)] bg-[size:12px_12px] xl:p-[30px] p-4 border-[1px]  border-[rgba(223,225,226,0.56)] xl:h-[calc(100vh_-_13rem)] h-[calc(100vh_-_17rem)] overflow-y-auto xl:overflow-x-hidden  ${currentMode === "dark" ? "" : "shadow-[0px_4px_20px_rgba(238,238,238,0.5)]"
                                        }`}
                                    style={{
                                        backgroundColour: "#FCFCFC",
                                    }}
                                >
                                    <div className="flex-col  flex">
                                        <span className="font-inter text-base font-medium  text-left mb-2">Exception Categories</span>
                                        <span className="Inter-Regular ">
                                            You can select from the list of default exception category or create a new exception category applicable
                                            to your ERP systems.
                                        </span>
                                        <Accordion
                                            sx={{
                                                "&.MuiPaper-root": {
                                                    boxShadow: "none",
                                                    border: "none !important",
                                                },
                                            }}
                                            expanded={expandMe === "1"}
                                            onChange={handleChangeMe("1")}
                                        >
                                            <AccordionSummary
                                                aria-controls="panel1d-content"
                                                id="panel1d-header"
                                                className={`bg-[#EDFCF9]  `}
                                            >
                                                <span className={`font-interM text-black `}>PO not Released</span>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <div className="grid grid-cols-1 pl-[20px] ">
                                                    <div className="w-[250px] h-search  rounded-lg border border-gray-300 py-[12px] px-[11px] flex justify-between mt-8">
                                                        <span>Po not released</span>
                                                        <Button
                                                            onClick={(e) => childCompRef.current.handleClick("bottom-end", 1, e)}
                                                            className={`px-2 userMore-step `}
                                                            tabIndex={0}
                                                        >
                                                            <img
                                                                src="/images/Exception-Automation/threedotsbalck.svg"
                                                                alt="threedots-black"
                                                                className="convertWhite"
                                                            />
                                                        </Button>
                                                        <TogglePopup
                                                            ref={childCompRef}
                                                            className="bg-blue-500 "
                                                            menuList={filterMenuList}
                                                            selectedItemChange={onFilterItems}
                                                        />
                                                    </div>

                                                    <div className="flex">
                                                        {/* <div className=""><img className="ml-[125px]" src="/images/Exception-Automation/Vecotor.svg"></img></div> */}
                                                        {/* <div className="grid grid-cols-1 gap-0 relative bottom-[25px]">
                                                            <div className="tree ml-[20px]">
                                                                <ul>
                                                                    <ul id="page1">
                                                                        {gridData?.map((item, index) => {
                                                                            return (
                                                                                <>
                                                                                    <TreeNode label={
                                                                                        <a className={`ml-2 `} href="#!">{item?.data}</a>

                                                                                    }
                                                                                    />

                                                                                </>
                                                                            );

                                                                        })}

                                                                    </ul>

                                                                </ul>

                                                            </div>




                                                        </div> */}

                                                        <div>

                                                            <ul className={`tree ${gridData?.length == 1 ? "tree-before" : ""} `}>
                                                                <li className="pt-0 firstLi">
                                                                    {" "}
                                                                    <div className="unsetstyle">
                                                                        <img
                                                                            className="ml-[100px]"
                                                                            src="/images/Exception-Automation/Vecotor.svg"
                                                                        ></img>
                                                                    </div>
                                                                    <ul className={` ${gridData?.length == 1 ? "mt-[-30px]" : gridData?.length == 2 ? "mt-[-60px]" : "mt-[-100px]"} `}>
                                                                        {gridData?.map((item, index) => {
                                                                            return (
                                                                                <>
                                                                                    <TreeNode label={
                                                                                        <a className={`ml-2 `} href="#!">{item?.data}</a>

                                                                                    }
                                                                                    />

                                                                                </>
                                                                            );

                                                                        })}
                                                                    </ul>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </AccordionDetails>
                                        </Accordion>
                                        <Button
                                            className="text-[#028C78] mt-4 text-left"
                                            onClick={() => setOpenCategory(true)}
                                        >
                                            {" "}
                                            + Create new exception category
                                        </Button>
                                    </div>

                                    <div
                                        className="flex-col  flex mt-10 w-full items-center justify-center"
                                        key={"whole-chart"}
                                    ></div>
                                </Card>
                                <Card
                                    className={`basis-[40%] md:mt-5 mb-[3rem] xl:mb-0  xl:mt-0 border-[1px]  border-[rgba(223,225,226,0.56)] xl:h-[calc(100vh_-_13rem)] h-[calc(100vh_-_26rem)] ${noActiveParams?.id == 1 ? "" : "overflow-y-auto"
                                        }  xl:overflow-x-hidden ${currentMode === "dark" ? "" : "shadow-[0px_4px_20px_rgba(238,238,238,0.5)]"}`}
                                >
                                    <div className="flex flex-col">
                                        <Accordion
                                            sx={{
                                                "&.MuiPaper-root": {
                                                    boxShadow: "none", // Your desired border color
                                                    border: "none !important",
                                                },
                                            }}
                                            expanded={expandPref === "1"}
                                            onChange={handleChangePref("1")}
                                            className="border-[#DCFCE7]"
                                        >
                                            <AccordionSummary
                                                aria-controls="panel1d-content"
                                                id="panel1d-header"
                                                className={`${expandPref == 0 ? "bg-[#FFFFFF]" : " bg-[#EDFCF9] "}text-${currentMode}-copy `}
                                            >
                                                <img src="/images/Exception-Automation/prefrences.svg"></img>{" "}
                                                <span className="font-interM ml-3 ">Preferences</span>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <div className="text-justify ml-[20px]">
                                                    <div className="grid grid-cols-1 gap-4">
                                                        <p className="text-[#000000] text-xs mt-[20px]">
                                                            (Select to send reminders during business hours or not?)
                                                        </p>
                                                    </div>
                                                    <div className="flex ">
                                                        <Checkbox
                                                            size="small"
                                                            sx={{
                                                                color: "rgba(85,85,85,0.7)",
                                                                "&.Mui-checked": {
                                                                    color: "#028C78",
                                                                },
                                                            }}
                                                        />
                                                        <p className="mt-[10px]">Working hours</p>
                                                    </div>
                                                    <div className="flex mt-[20px]">
                                                        <div>
                                                            <p>Start Time</p>
                                                            <div className="flex justify-between border border-gray-300 rounded-lg overflow-hidden w-[115px] mt-[10px]">
                                                                <div className="basis-[50%] border-r-2 py-[10px] px-[10px]">
                                                                    <input
                                                                        type="text"
                                                                        className=" w-full  focus:outline-none"
                                                                        name="time"
                                                                    />
                                                                </div>
                                                                <div className="basis-[50%]">
                                                                    <select className="py-[10px] px-[10px]">
                                                                        <option>AM</option>
                                                                        <option>PM</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="ml-[10px]">
                                                            <p>End Time</p>
                                                            <div className="flex justify-between border border-gray-300 rounded-lg overflow-hidden w-[115px] mt-[10px]">
                                                                <div className="basis-[50%] border-r-2 py-[10px] px-[10px]">
                                                                    <input
                                                                        type="text"
                                                                        className=" w-full  focus:outline-none"
                                                                        name="time"
                                                                    />
                                                                </div>
                                                                <div className="basis-[50%]">
                                                                    <select className="py-[10px] px-[10px]">
                                                                        <option>AM</option>
                                                                        <option>PM</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <p className="mt-[11px]">Reminders to send from 10:00 a.m. to 6:30 p.m.</p>
                                                    <hr className="mt-[20px]" />
                                                    <div className="flex">
                                                        <Checkbox
                                                            size="small"
                                                            sx={{
                                                                color: "rgba(85,85,85,0.7)",
                                                                "&.Mui-checked": {
                                                                    color: "#028C78",
                                                                },
                                                            }}
                                                        />
                                                        <p className="mt-[10px]">Working Days</p>
                                                    </div>
                                                    {DaysRange?.map((el, i) => (
                                                        <Checkbox
                                                            name="week"
                                                            className="p-[6px]"
                                                            icon={
                                                                <img
                                                                    src={el?.imgNormal}
                                                                    alt="tue-normal"
                                                                />
                                                            }
                                                            checkedIcon={
                                                                <img
                                                                    src={el?.imgActive}
                                                                    alt="tue-active"
                                                                />
                                                            }
                                                        />
                                                    ))}
                                                    <p className="mt-[20px] mb-[20px]">Reminders to send every Monday, Wednesday, and Friday.</p>
                                                </div>
                                            </AccordionDetails>
                                        </Accordion>
                                        <div
                                            className={`items-center mt-[20px] xl:w-full px-[20px] py-[14px] border-b-[1px] min-h-[70px] border-${currentMode}-700 ${currentMode === "dark"
                                                ? ""
                                                : "shadow-[0px_4px_4px_0px_rgba(0,0,0,0.10),0px_4px_4px_0px_rgba(0,0,0,0.06)] "
                                                } ${noActiveParams?.id == 1 ? "sticky top-0 z-[1]" : ""} ${currentMode == "dark" ? "bg-[#1F1F1F]" : "bg-[#F0F0F0]"
                                                }`}
                                        >
                                            <p className="font-medium text-lg">{"Configuration for PO not released exception"}</p>
                                        </div>
                                        <Accordion
                                            sx={{
                                                "&.MuiPaper-root": {
                                                    boxShadow: "none",
                                                    border: "none !important",
                                                },
                                            }}
                                            expanded={expandEmail === "1"}
                                            onChange={handleChangeEmail("1")}
                                        >
                                            <AccordionSummary
                                                aria-controls="panel1d-content"
                                                id="panel1d-header"
                                                className={`${expandEmail == 0 ? "bg-[#FFFFFF]" : " bg-[#EDFCF9] "} text-${currentMode}-copy `}
                                            >
                                                <img src="/images/Exception-Automation/email.svg"></img>{" "}
                                                <span className="font-interM ml-3">Email</span>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <EmailCategory
                                                    receivers={receivers}
                                                    setReceivers={setReceivers}
                                                    bccreceivers={bccreceivers}
                                                    setBccRecievers={setBccRecievers}
                                                    email_enabled={Emailenable}
                                                    notification_enabled={notificationenable}
                                                    notification_title={Title}
                                                    handleEmailSubject={setSubject}
                                                    handleEmailMessage={setEmailmessage}
                                                    email_sub={subject}
                                                    listData={[]}
                                                    notification_msg={message}
                                                    email_body={emailMessage}
                                                    emailCheckChange={setemailEnable}
                                                    // handleNotificationTitle={setTitle}
                                                    handleNotificationMessage={setMessage}
                                                    invAllocList={[]}
                                                    notificationListData={[]}
                                                    handleNotificationCheck={setnotificationEnable}
                                                    AllocListData={[]}
                                                    reset={reset}
                                                    selectedId={selectedId}
                                                    setSelectedId={setSelectedId}
                                                    check={check}
                                                    setCheck={setCheck}
                                                    currentMode={currentMode}
                                                />
                                            </AccordionDetails>
                                        </Accordion>
                                        <Accordion
                                            sx={{
                                                "&.MuiPaper-root": {
                                                    boxShadow: "none",
                                                    border: "none !important",
                                                },
                                            }}
                                            expanded={expandEscal === "1"}
                                            onChange={handleChangeEscal("1")}
                                        >
                                            <AccordionSummary
                                                aria-controls="panel1d-content"
                                                id="panel1d-header"
                                                className={`${expandEscal == 0 ? "bg-[#FFFFFF]" : " bg-[#EDFCF9] "}  text-${currentMode}-copy `}
                                            >
                                                <img src="/images/Exception-Automation/reminder.svg"></img>{" "}
                                                <span className="font-interM ml-3 ">Escalation and Reminder</span>
                                            </AccordionSummary>
                                            <AccordionDetails className="px-[20px] py-[20px]">
                                                <Accordion
                                                    sx={{
                                                        "&.MuiPaper-root": {
                                                            boxShadow: "none",
                                                            border: "none !important",
                                                        },
                                                    }}
                                                    expanded={expandReminder === "1"}
                                                    onChange={handleChangeReminder("1")}
                                                >
                                                    <AccordionSummary
                                                        aria-controls="panel1d-content"
                                                        id="panel1d-header"
                                                        className={`bg-[#EDFCF9] text-${currentMode}-copy `}
                                                    >
                                                        <span className="font-interM ">Reminder 1</span>
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        <div className="ml-[20px]">
                                                            <div>
                                                                <p className="mt-[20px]">
                                                                    If the recipient does not respond to an exception email, you can send the
                                                                    escalation email.
                                                                </p>

                                                                <div className="flex mt-[20px]">
                                                                    <div>
                                                                        <p className="text-[#000] font-inter font-medium text-base">
                                                                            Set your escalation in days:
                                                                        </p>
                                                                        <div className="flex justify-between border border-gray-300 rounded-lg overflow-hidden w-[115px] mt-[10px]">
                                                                            <div className="basis-[50%] border-r-2 py-[10px] px-[10px]">
                                                                                <input
                                                                                    type="text"
                                                                                    className=" w-full  focus:outline-none"
                                                                                    name="time"
                                                                                />
                                                                            </div>
                                                                            <div className="basis-[50%]">
                                                                                <input
                                                                                    type="text"
                                                                                    className=" w-full py-[12px] px-[10px] focus:outline-none"
                                                                                    value={"Days"}
                                                                                    name="time"
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="ml-[10px] mt-[20px]">
                                                                        <div className="flex justify-between border border-gray-300 rounded-lg overflow-hidden w-[115px] mt-[10px]">
                                                                            <div className="basis-[50%] border-r-2 py-[10px] px-[10px]">
                                                                                <input
                                                                                    type="text"
                                                                                    className=" w-full  focus:outline-none"
                                                                                    name="time"
                                                                                />
                                                                            </div>
                                                                            <div className="basis-[50%]">
                                                                                <input
                                                                                    type="text"
                                                                                    className=" w-full py-[12px] px-[10px] focus:outline-none"
                                                                                    value={"Days"}
                                                                                    name="time"
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <hr className="mt-3" />

                                                            <p className="mt-[20px]">The reminder email is sent based on the frequency set.</p>
                                                            <div className="">
                                                                <p className="text-[#000] font-inter font-medium text-base mt-[10px]">
                                                                    Set your reminder frequency in hours:
                                                                </p>
                                                                <div className="flex justify-between mb-[30px]">
                                                                    <div className="flex justify-between border border-gray-300 rounded-lg overflow-hidden w-[115px] mt-[10px]">
                                                                        <div className="basis-[50%] border-r-2 py-[10px] px-[10px]">
                                                                            <input
                                                                                type="text"
                                                                                className=" w-full  focus:outline-none"
                                                                                name="time"
                                                                            />
                                                                        </div>
                                                                        <div className="basis-[50%]">
                                                                            <input
                                                                                type="text"
                                                                                className=" w-full py-[12px] px-[7px]  focus:outline-none"
                                                                                value={"Hours"}
                                                                                name="time"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className=" mt-[20px]">
                                                                        <Button
                                                                            className="text-[#028C78] mt-4 text-left"
                                                                            onClick={() => setOpenEmailConfig(true)}
                                                                        >
                                                                            {" "}
                                                                            + Email Configuration
                                                                        </Button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </AccordionDetails>
                                                </Accordion>

                                                <Button
                                                    className="text-[#028C78] mt-4 text-left mb-[20px] ml-[20px]"
                                                    onClick={() => setOpenReminder(true)}
                                                >
                                                    {" "}
                                                    + Add New Escalation and Reminder
                                                </Button>
                                            </AccordionDetails>
                                        </Accordion>
                                        <Accordion
                                            sx={{
                                                "&.MuiPaper-root": {
                                                    boxShadow: "none",
                                                    border: "none !important",
                                                },
                                            }}
                                            expanded={expandAcknow === "1"}
                                            onChange={handleChangeAcknow("1")}
                                        >
                                            <AccordionSummary
                                                aria-controls="panel1d-content"
                                                id="panel1d-header"
                                                className={`${expandAcknow == 0 ? "bg-[#FFFFFF]" : " bg-[#EDFCF9] "} text-${currentMode}-copy `}
                                            >
                                                <img src="/images/Exception-Automation/Acknowledge.svg"></img>{" "}
                                                <span className="font-interM ml-3">Acknowledgement</span>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <AcknowledgementCard
                                                    receivers={receivers}
                                                    setReceivers={setReceivers}
                                                    bccreceivers={bccreceivers}
                                                    setBccRecievers={setBccRecievers}
                                                    email_enabled={Emailenable}
                                                    notification_enabled={notificationenable}
                                                    notification_title={Title}
                                                    handleEmailSubject={setSubject}
                                                    handleEmailMessage={setEmailmessage}
                                                    email_sub={subject}
                                                    listData={[]}
                                                    notification_msg={message}
                                                    email_body={emailMessage}
                                                    emailCheckChange={setemailEnable}
                                                    // handleNotificationTitle={setTitle}
                                                    handleNotificationMessage={setMessage}
                                                    invAllocList={[]}
                                                    notificationListData={[]}
                                                    handleNotificationCheck={setnotificationEnable}
                                                    AllocListData={[]}
                                                    reset={reset}
                                                    selectedId={selectedId}
                                                    setSelectedId={setSelectedId}
                                                    check={check}
                                                    setCheck={setCheck}
                                                    currentMode={currentMode}
                                                />
                                            </AccordionDetails>
                                        </Accordion>

                                        {ReactDOM.createPortal(
                                            <AddExceptionCategory
                                                isOpened={openCategory}
                                                handleClose={() => setOpenCategory(false)}
                                                className={openCategory && "Show"}
                                            />,

                                            document.getElementById("user-modal-root")
                                        )}
                                        {ReactDOM.createPortal(
                                            <CreateReminderModal
                                                isOpened={openReminder}
                                                handleClose={() => setOpenReminder(false)}
                                                className={openReminder && "Show"}
                                            />,

                                            document.getElementById("user-modal-root")
                                        )}
                                        {ReactDOM.createPortal(
                                            <EmailConfig
                                                isOpened={openEmailConfig}
                                                handleClose={() => setOpenEmailConfig(false)}
                                                className={openEmailConfig && "Show"}
                                                receivers={receivers}
                                                setReceivers={setReceivers}
                                                bccreceivers={bccreceivers}
                                                setBccRecievers={setBccRecievers}
                                                email_enabled={Emailenable}
                                                notification_enabled={notificationenable}
                                                notification_title={Title}
                                                handleEmailSubject={setSubject}
                                                handleEmailMessage={setEmailmessage}
                                                email_sub={subject}
                                                listData={[]}
                                                notification_msg={message}
                                                email_body={emailMessage}
                                                emailCheckChange={setemailEnable}
                                                // handleNotificationTitle={setTitle}
                                                handleNotificationMessage={setMessage}
                                                invAllocList={[]}
                                                notificationListData={[]}
                                                handleNotificationCheck={setnotificationEnable}
                                                AllocListData={[]}
                                                reset={reset}
                                                selectedId={selectedId}
                                                setSelectedId={setSelectedId}
                                                check={check}
                                                setCheck={setCheck}
                                                currentMode={currentMode}
                                            />,

                                            document.getElementById("user-modal-root")
                                        )}
                                    </div>
                                </Card>
                            </div>
                        )}
                    </Main>
                    <Footer />
                </ErrorBoundary>
            </Layout>
        </>
    );
};

export default ExceptionAutomation;
