import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Card from "../../../components/UI/Card";
import Main from "../../../components/UI/Main";
import Layout from "../../../Hoc/Layout";
import Button from "../../../components/Button";
import Footer from "../../../organisms/Footer";
import ReactLoginMS from "react-ms-login";
import { GoogleLogin } from "react-google-login";
import { gapi } from "gapi-script";
import axios from "axios";
import { userId } from "../../../Hooks/userId/Index";
import { ThemeContext } from "../../../Hooks/Themes/Themeprovider";
import authHeader from "../../../redux/actions/auth-header";

const InboxConfigConfirmation = () => {
    const { currentMode } = useContext(ThemeContext);
    const { useid } = userId();
    const navigate = useNavigate();
    const [isFeaching, setIsFeaching] = useState(true);
    const [email, setEmail] = useState();
    const [profile, setProfile] = useState([]);
    const [validEmail, checkValidEmail] = useState(false);
    const [selectedNumber, setSelectedNumber] = useState();

    const btnContent = () => {
        return <span className="flex">Continue</span>;
    };
    const msClientId = process.env?.REACT_APP_MS_APP_ID;
    const clientId = process.env?.REACT_APP_GOOGLE_CLIENT_ID;

    const queryString = window.location.href.includes("google");

    useEffect(() => {
        const initClient = () => {
            gapi?.client?.init({
                clientId: clientId,
                accessType: "offline",
                prompt: "consent",
                // scope: "email profile openid https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/gmail.readonly https://www.googleapis.com/auth/gmail.modify https://www.googleapis.com/auth/gmail.labels https://www.googleapis.com/auth/contacts.readonly",
                scope: "email profile openid https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/gmail.labels https://www.googleapis.com/auth/contacts.readonly https://mail.google.com/ https://www.googleapis.com/auth/pubsub",
            });
        };

        gapi?.load("client:auth2", initClient());
    }, []);
    const getUserId = JSON.parse(localStorage.getItem("user"));

    const loginMicrosoftHandler = (msData) => {
        const data = {
            code: msData?.code,
            userid: getUserId?.id,
        };
        setIsFeaching(false);
        axios
            .post(
                process.env.REACT_APP_NODE_API_URL + "getInboxOauthMsToken",
                data,
                {
                    headers: authHeader(),
                }
            )
            .then((response) => {
                if (response.status == 200) {
                    setIsFeaching(true);
                    const tokenInfoData = {
                        access_token: response?.data?.accessToken,
                        refresh_token: response?.data?.refreshToken,
                    };
                    setProfile({ tokenInfo: tokenInfoData });
                    setSelectedNumber(2);
                    if (response?.data?.account?.username) {
                        checkValidEmail(true);
                        setEmail(response?.data?.account?.username);
                    }
                    navigate("/inbox-settings");
                }
            });
    };

    const onSuccess = (res) => {
        const data = {
            code: res?.code,
            userid: getUserId?.id,
        };
        setIsFeaching(false);
        axios
            .post(
                process.env.REACT_APP_NODE_API_URL + "getInboxOauthGmailToken",
                data,
                {
                    headers: authHeader(),
                }
            )
            .then((response) => {
                if (response.status == 200) {
                    setIsFeaching(true);
                    setProfile(response?.data);
                    setSelectedNumber(1);
                    if (response?.data?.email) {
                        // if(!/^(?!.+@(gmail|google|yahoo|outlook|hotmail|msn)\..+)(.+@.+\..+)$/.test(response?.data?.email)){
                        //   checkValidEmail(false);
                        // } else {
                        //   checkValidEmail(true);
                        // }
                        checkValidEmail(true);
                        setEmail(response?.data?.email);
                    }
                    navigate("/inbox-settings");
                }
            });
    };
    const onFailure = (err) => {};
    const setLocal = () => {
        localStorage.setItem("inboxClick", "true");
        window.dispatchEvent(new Event("storageChange"));
    };

    return (
        <>
            <Layout>
                <Main>
                    <div className="flex justify-between items-center mb-6">
                        <div>
                            <h4
                                className={`text-2xl font-interSb text-${currentMode}-copy`}
                            >
                                Inbox Configuration
                            </h4>
                        </div>
                    </div>
                    <Card className="p-[20px] text-center h-[calc(100vh_-_13rem)] pt-[4rem] overflow-y-auto">
                        <h4 className="mb-5 text-lg font-interM">
                            Configure your{" "}
                            {queryString
                                ? `Google account`
                                : `Microsoft Outlook account`}
                        </h4>
                        <div className="flex justify-center mx-auto items-center w-[100px] bg-light-420 rounded-full  h-[100px] ">
                            {queryString ? (
                                <img
                                    className="max-h-[40px] object-cover"
                                    src="/images/main/gmail-icon.svg"
                                    alt="gmail-icon"
                                />
                            ) : (
                                <img
                                    className="max-h-[40px] object-cover"
                                    src="/images/main/outlook-icon.svg"
                                    alt="outlook-icon"
                                />
                            )}
                        </div>
                        <div className=" text-left xl:w-[750px] mx-auto mt-5  ">
                            <span className="font-interM align-l  block">
                                Here's what to expect:
                            </span>

                            <ul className="list-disc ml-[20px]">
                                <li className="mt-4">
                                    Your emails will be logged in your Myiq.Ai
                                    inbox.
                                </li>
                                <li className="mt-3">
                                    Anyone with inbox access will be able to
                                    view and respond to emails.
                                </li>
                                <li className="mt-3">
                                    {`You can send and receive ${
                                        queryString
                                            ? `Gmail`
                                            : `Microsoft Outlook`
                                    } messages, schedule
                  email sends, and modify email drafts in the Myiq.Ai.`}
                                </li>
                            </ul>
                            {queryString ? (
                                <GoogleLogin
                                    clientId={clientId}
                                    render={(renderProps) => (
                                        <div onClick={setLocal}>
                                            <Button
                                                onClick={renderProps.onClick}
                                                className="bg-light-20 py-[11px] rounded-[8px] text-white px-9 mt-6"
                                            >
                                                Confirm
                                            </Button>
                                        </div>
                                    )}
                                    onSuccess={onSuccess}
                                    redirectUri={
                                        process.env?.REACT_APP_MS_REDIRECT_URL
                                    }
                                    onFailure={onFailure}
                                    cookiePolicy={"single_host_origin"}
                                    accessType={"offline"}
                                    prompt={"consent"}
                                    responseType={"code"}
                                    scope={
                                        "email profile openid https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/gmail.labels https://www.googleapis.com/auth/contacts.readonly https://mail.google.com/ https://www.googleapis.com/auth/pubsub"
                                        //"email profile https://mail.google.com/ https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/gmail.labels https://www.googleapis.com/auth/contacts.readonly"
                                    }
                                />
                            ) : (
                                <ReactLoginMS
                                    clientId={msClientId}
                                    redirectUri={
                                        process.env?.REACT_APP_MS_REDIRECT_URL +
                                        "/msAuthComplete.html"
                                    }
                                    scopes={[
                                        "Mail.ReadWrite",
                                        "MailboxSettings.ReadWrite",
                                        "Contacts.ReadWrite",
                                        "Mail.Send",
                                        "User.Read",
                                        "Mail.Read",
                                    ]}
                                    responseType="code"
                                    cssClass={`${"bg-light-20 py-[11px] rounded-[8px] text-white px-9 mt-6"} `}
                                    btnContent={btnContent}
                                    handleLogin={(data) =>
                                        loginMicrosoftHandler(data)
                                    }
                                />
                            )}
                        </div>
                    </Card>
                </Main>
                <Footer />
            </Layout>
        </>
    );
};
export default InboxConfigConfirmation;
