import _ from "lodash";
import { memo, useCallback, useMemo, useState } from "react";
import { useDrop } from "react-dnd";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import { setDroppedValues } from "../../redux/actions/filter";
import { Card } from "./Card";

export const MatrixColTarget = memo(function Container({
    currentMode,
    onDrop,
    type,
    areaName,
    setAccordionItems,
    accordionItems,
    setFilteredAccordionItems,
    filteredAccordionItems,
}) {
    const { matrixColValues } = useSelector((state) => state?.droppedState);
    const [dropped, setDropped] = useState([]);
    const resultArray = _.uniqBy(matrixColValues, "column_name");
    useMemo(() => {
        setDropped(resultArray);
    }, [matrixColValues]);
    const findCard = useCallback(
        (id) => {
            const card = dropped.filter((c) => `${c.id}` === id)[0];
            return {
                card,
                index: dropped.indexOf(card),
            };
        },
        [dropped]
    );
    const moveCard = useCallback(
        (id, atIndex) => {
            const { card, index } = findCard(id);
            if (card?.Type == areaName) {
                dispatch({ type: "MOVE_THE_CARD", payload: { card, index, atIndex, name: "matrixColValues" } }); //This action for after card swapped original array should be modified!!
            }
        },
        [findCard, dropped, setDropped]
    );
    const [, drop] = useDrop(() => ({ accept: "card" }));
    const dispatch = useDispatch();

    const [{ isOver }, drag] = useDrop({
        accept: "DRAGGABLE_ITEM",
        drop: (item) => {
            onDrop(item?.value, areaName);
        },
        collect: (monitor) => ({
            isOver: !!monitor.isOver(),
        }),
    });
    const reomoveItem = async (idToRemove) => {
        const needCheck = matrixColValues?.filter((item) => item?.column_name == idToRemove);
        const updatedAccordionItem = accordionItems?.map((parentObj) => {
            const updatedContent = parentObj?.data?.map((contentObj) => {
                const matchingDroppedValue = needCheck?.find((droppedObj) => droppedObj?.column_name === contentObj?.column_name);
                if (matchingDroppedValue) {
                    // Update the checked property in the matching content object
                    return { ...contentObj, checked: false };
                }
                return contentObj;
            });

            return { ...parentObj, data: updatedContent };
        });
        const updatedFilterItems = filteredAccordionItems?.map((parentObj) => {
            const updatedContent = parentObj?.data?.map((contentObj) => {
                const matchingDroppedValue = needCheck?.find((droppedObj) => droppedObj?.column_name === contentObj?.column_name);
                if (matchingDroppedValue) {
                    // Update the checked property in the matching content object
                    return { ...contentObj, checked: false };
                }
                return contentObj;
            });

            return { ...parentObj, data: updatedContent };
        });
        dispatch({
            type: "DELETE_CHECKBOX_MATRIX_COLUMN",
            payload: idToRemove,
        });
        dispatch(setDroppedValues(needCheck, false, "matrixcolumns"));
        // Update the state with the new array
        await setAccordionItems(updatedAccordionItem);
        await setFilteredAccordionItems(updatedFilterItems);
    };
    const checkRemove = (name, card) => {
        Swal.fire({
            text: `Are you sure you want to delete ${card?.field_display_name} from ${_.startCase(card?.Type)}?`,
            icon: "warning",
            showCloseButton: true,
            showCancelButton: true,
            buttons: {
                cancel: "Cancel",
                confirm: "Confirm",
            },
        }).then((confirm) => {
            if (confirm?.isConfirmed) {
                reomoveItem(name);
            }
        });
    };
    return (
        <div ref={drag}>
            <div ref={drop} className={`flex ${isOver ? "bg-[#f0f0f0]" : ""} py-[15px] px-[20px] flex-wrap items-center w-full`}>
                {dropped?.length === 0 && <p className={`text-${currentMode}-1220 w-full text-center`}>Select/Drag and Drop the columns here</p>}
                {dropped?.map((card, i) => (
                    <Card
                        key={card?.id}
                        index={i}
                        id={`${card?.id}`}
                        value={card}
                        type={type}
                        moveCard={moveCard}
                        findCard={findCard}
                        areaName={areaName}
                        handleRemove={() => checkRemove(card?.column_name, card)}
                    />
                ))}
            </div>
        </div>
    );
});
