import { useState, useEffect, useRef, useContext, useMemo } from "react";
import Card from "../../components/UI/Card";
import Button from "../../components/Button";
import { ThemeContext } from "../../Hooks/Themes/Themeprovider";
import { ClickAwayListener, Fade, FormControl, MenuItem, Popper, Select, TextField, Tooltip, Autocomplete, Paper } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { MentionsInput, Mention } from "react-mentions";
import { addUpdateCommentsRequest } from "../../redux/actions/advance-request.js";
import { useNavigate, useLocation } from "react-router-dom";
import { dateTimeConvertStringComponent } from "../../utils/helpers.js";
import Swal from "sweetalert2";

const CommentCard = (props) => {
    const { currentMode, toggleMode } = useContext(ThemeContext);
    const [inputValue, setInputValue] = useState("");
    const commentListRef = useRef(null);
    const [commentId, setCommentId] = useState(0);
    const [commentIndex, setCommentIndex] = useState(0);
    const location = useLocation();
    const dispatch = useDispatch();
    const orgId = localStorage.getItem("orgId") || 0;
    const [commentListData, setCommentListData] = useState([]);

    useEffect(() => {
        if (props?.createDataObject) {
            if (props?.createDataObject?.hasOwnProperty("comments_json")) {
                setCommentListData(props?.createDataObject?.comments_json || []);
            } else {
                setCommentListData(props?.createDataObject?.comments_info || []);
            }
        }
    }, [props?.createDataObject]);

    const handleInputChange = (event, newValue, mentionPlainText, mention) => {
        setInputValue(newValue);
    };

    const renderUserSuggestion = (suggestion, search, highlightedDisplay, index, focused) => (
        <div className="max-h-[110px] overflow-y-auto">
            <div className={`flex w-[286px] hover:bg-[#D3FAF445]`}>
                <img className="rounded-full w-[40px] h-[40px] m-4" src={`data:image;base64,${suggestion?.photo}`} alt="user-new" />
                <span className="mt-5"> {highlightedDisplay}</span>
            </div>
        </div>
    );

    const customDisplayTransform = (id, display) => `@${display}`;
    const customMentionStyle = {
        backgroundColor: "#D1E4FD",
        borderRadius: "4px",
    };

    const {
        data: datas,
        fetching: userFetch,
        requestComplete: userReqComp,
        error: userErr,
    } = useSelector((state) => state?.getUserForCommentsListState);

    const userList = datas?.filter((el) => el?.isactivated !== false || el?.user_isactive !== false);
    const {
        data: commentData,
        fetching: addCmtFetch,
        requestComplete: addCmtReqComp,
        error: addCmtErr,
    } = useSelector((state) => state?.addUpdateCommentsState);

    const handleSubmit = () => {
        const reqObj = {
            document_type: props?.type,
            comment: inputValue,
            users_list: [],
            org_id: orgId,
            id: commentId,
            request_id: props?.reqId,
        };
        dispatch(addUpdateCommentsRequest(reqObj));
    };

    useEffect(() => {
        setCommentId(0);
        setInputValue("");
        setCommentId(0);
        if (commentData?.data?.[0]?.comments_json?.[0]) {
            if (commentId === 0) {
                let newArr = commentListData;
                newArr?.push(commentData?.data?.[0]?.comments_json?.[0]);
                setCommentListData(newArr);
            } else {
                const newArr = commentListData?.map((ele, index) => {
                    if (commentIndex === index) {
                        ele = commentData?.data?.[0]?.comments_json?.[0];
                    }
                    return ele;
                });
                setCommentListData(newArr);
            }
        }

        setTimeout(() => {
            // Scroll to the last comment when component updates
            if (commentListRef.current) {
                commentListRef.current.scrollTop = commentListRef.current.scrollHeight;
            }
        }, 2000);
    }, [addCmtReqComp]);
    const filteredList = [...new Set(commentListData)];
    return (
        <>
            <div>
                <div
                    className={`flex  ${
                        currentMode == "dark" ? "bg-[#121212] border-l-[1px] border-[#333333]" : "bg-[#F3FEFC] border-b-[1px]"
                    }  py-[12px] rounded-t-[10px] w-full`}
                >
                    <img
                        className="h-[14px] w-[14px] ml-4 mr-[10px] mt-1"
                        src={`${currentMode == "dark" ? "/images/mobilization/dark-mode-message.svg" : "/images/mobilization/message-circle.svg"}`}
                    />

                    <h4 className={`font-interM text-[14px] font-medium text-${currentMode}-40`}>Comments</h4>
                </div>

                {filteredList && filteredList?.length !== 0 ? (
                    <div ref={commentListRef} className="max-h-[235px] overflow-y-auto ">
                        {filteredList?.map((ele, ind) => {
                            const dateString = dateTimeConvertStringComponent(ele?.commented_on);

                            return (
                                <div className="flex">
                                    <img
                                        className="rounded-full w-[40px] h-[40px] m-4"
                                        src={ele?.Photo ? `data:image;base64,${ele?.Photo}` : `/images/profile/user-ico.svg`}
                                        alt="user-new"
                                    />

                                    <div>
                                        <span className="flex">
                                            <p title={ele?.user_name} className="text-[14px] mt-[16px] font-medium max-w-[100px] truncate">
                                                {ele?.user_name}
                                            </p>

                                            <div title={dateString?.date} className="flex text-[8px] ml-1 mt-[18px] max-w-[90px] truncate">
                                                <p>{dateString?.date}</p>

                                                <p className="ml-2">{dateString?.time}</p>
                                            </div>

                                            <img
                                                className={`ml-4 mt-[21px] ${
                                                    props?.isEdit ? "pointer-events-none" : ""
                                                } justify-end cursor-pointer w-[13px] h-[13px] comment-text `}
                                                src={commentId == ele?.id ? "/images/main/edit-green.svg" : "/images/main/edit-black.svg"}
                                                alt="pencil-medium"
                                                onClick={() => {
                                                    const originalText = ele?.comment_text;
                                                    setInputValue(originalText);
                                                    setCommentId(ele?.id);
                                                    setCommentIndex(ind);
                                                }}
                                            />
                                        </span>

                                        <div className="text-[12px] font-medium pr-[40px]">
                                            {/* Transform comment text to include @ before mentions */}

                                            {ele?.comment_text?.replaceAll("[", "")?.replaceAll("]", "")}
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                ) : (
                    <div className={`bg-${currentMode}-card`}>
                        <div className="text-center">
                            <div className="mx-auto my-5">
                                <img className="mx-auto" src="/images/common/delivery.png" alt="delivery" />
                            </div>

                            <h4 className="font-interSb">No Comments found</h4>
                        </div>
                    </div>
                )}
            </div>

            <div
                className={`h-[60px] rounded-b-[10px] ${
                    currentMode == "dark" ? "bg-[#121212]  border-[#333333]" : "bg-[#FFF] shadow-[0px_4px_20px_0px_rgba(85,85,85,0.20)]"
                }  `}
            >
                <div
                    className={`flex mx-6 border-[1px] h-[40px] my-[10px] items-center space-3 rounded-[7px] justify-between border-${currentMode}-700`}
                >
                    <div className={`flex items-center basis-[90%]  border-${currentMode}-700 commentScroll`}>
                        <MentionsInput
                            value={inputValue}
                            onChange={handleInputChange}
                            className="w-[165px] 2xl:w-[400px]  ml-4 bg-transparent relative"
                            placeholder="Post a comment..."
                            maxLength={250}
                            disabled={props?.isEdit}
                            singleLine={true}
                            allowSuggestionsAboveCursor={true}
                            appendSpaceOnAdd={true}
                            allowSpaceInQuery={true}
                            onKeyDown={(e) => {
                                if (e?.key == "Enter" && inputValue.trim().length != 0) {
                                    handleSubmit(e);
                                }
                            }}
                        >
                            <Mention
                                trigger="@"
                                markup={`@[__display__](__id__)`} // Use custom markup for mention
                                data={userList.map((user) => ({ id: user.user_id, display: user.user_name, photo: user.user_image }))}
                                displayTransform={customDisplayTransform}
                                renderSuggestion={renderUserSuggestion}
                                style={customMentionStyle}
                            />
                        </MentionsInput>
                    </div>

                    {commentId != 0 && (
                        <img
                            src="/images/common/closeBlk.svg"
                            className="my-2 mr-1 ml-[-48px] cursor-pointer "
                            alt="add-comments"
                            onClick={() => {
                                setCommentId(0);
                                setInputValue("");
                                setCommentIndex(0);
                            }}
                        />
                    )}

                    <Button
                        disabled={inputValue?.length == 0 || addCmtFetch}
                        onClick={handleSubmit}
                        className="border-[0] w-[30px] mr-1 rounded-[8px] flex items-center bg-[rgba(0,177,162,1)] cursor-pointer text-white"
                    >
                        <img src="/images/mobilization/send.svg" className="ml-1 pr-2 h-[30px] w-[30px]" alt="post-comments" />
                    </Button>
                </div>
            </div>
        </>
    );
};

export default CommentCard;
