import axios from "axios";
import configMyiQ from "../../configuration";
import authHeader from "./auth-header";
import { checkApiStatus } from "../../utils/helpers";
import { GET_FREE_TRAIL_REQUEST, GET_FREE_TRAIL_FAILURE, GET_FREE_TRAIL_SUCCESS } from "../types/types";

const API_URL = process.env?.REACT_APP_API_URL;
const NODE_API_URL = process.env?.REACT_APP_NODE_API_URL;
export const getFreeTrialDispatch = (data) => async (dispatch) => {
    dispatch({ type: GET_FREE_TRAIL_REQUEST });
    axios
        .post(`${API_URL}User/UserTrialExtend`, data, {
            headers: authHeader(),
        })
        .then((response) => {
            // localStorage.removeItem("user");
            dispatch({
                type: GET_FREE_TRAIL_SUCCESS,
                payload: response.data,
            });
            // dispatch({ type: LOGIN_RESET });
        })
        .catch((error) => {
            if (error?.response?.status == 401) {
                checkApiStatus();
            }
            dispatch({
                type: GET_FREE_TRAIL_FAILURE,
            });
        });
};
