import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Loader from "../../../components/Loader";
import Card from "../../../components/UI/Card";
import Main from "../../../components/UI/Main";
import Layout from "../../../Hoc/Layout";
import { ThemeContext } from "../../../Hooks/Themes/Themeprovider";
import Footer from "../../../organisms/Footer";
import { getInboxSettingList } from "../../../redux/actions/inbox-setting";
import { GET_INBOX_SETTINGS_RESET } from "../../../redux/types/types";

const InboxConfiguration = () => {
    const { currentMode } = useContext(ThemeContext);
    const navigate = useNavigate();
    const data = useSelector((state) => state?.inboxSettingListState);
    const checkMailConfig = data?.configData?.[0]?.isactive;
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getInboxSettingList(navigate));
        if (checkMailConfig) {
            navigate("/inbox-compose");
            dispatch({ type: GET_INBOX_SETTINGS_RESET })
        }
    }, [dispatch])

    return (
        <>
            <Layout>

                <Main>
                    {data?.fetching || checkMailConfig ? (
                        <Loader className="flex justify-center items-center h-screen" />
                    ) : (
                        <>
                            <div className="flex justify-between items-center mb-6">
                                <div>
                                    <h4 className={`text-2xl font-interSb text-${currentMode}-copy`}>Inbox Configuration</h4>
                                </div>
                            </div>
                            <Card className="p-[20px] text-center h-[calc(100vh_-_13rem)] pt-[5rem]">
                                <h4 className="mb-5 text-lg font-interM">
                                    What type of email would you like to connect with?
                                </h4>
                                <div className="flex justify-center items-center space-x-4">
                                    <Link
                                        className={`bg-${currentMode}-card hover:border-10 ease duration-500 border-[1px] max-w-[220px] rounded-[10px] px-[20px] py-[30px] border-${currentMode}-700 ${currentMode == "dark" ? '' : 'shadow-[0px_4px_20px_rgba(238,238,238,0.501967)]'} `}
                                        to="/inbox-config/google"
                                    >
                                        <img
                                            className="mb-4 mx-auto"
                                            src="/images/main/gmail-icon.svg"
                                            alt="gmail-icon"
                                        />
                                        <span className="font-interSb mb-3 block">Gmail</span>
                                        <p className="text-xs">Connect a Gmail account to your inbox.</p>
                                    </Link>
                                    <Link
                                        className={`bg-${currentMode}-card hover:border-10 ease duration-500 border-[1px] max-w-[220px] rounded-[10px] px-[20px] py-[30px] border-${currentMode}-700 ${currentMode == "dark" ? '' : 'shadow-[0px_4px_20px_rgba(238,238,238,0.501967)]'} `}
                                        to="/inbox-config/outlook"
                                    >
                                        <img
                                            className="mb-4 mx-auto"
                                            src="/images/main/outlook-icon.svg"
                                            alt="outlook-icon"
                                        />
                                        <span className="font-interSb mb-3 block">
                                            Microsoft Outlook
                                        </span>
                                        <p className="text-xs">
                                            Connect a Microsoft Outlook account to your inbox
                                        </p>
                                    </Link>
                                </div>

                            </Card>
                        </>
                    )}
                </Main>
                <Footer />
            </Layout>
        </>
    );
};
export default InboxConfiguration;
