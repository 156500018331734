// useDraggable.js
import { useDrag } from "react-dnd";

const useDraggable = (type, item) => {
    const [{ isDragging }, drag] = useDrag({
        type,
        item,
        collect: (monitor) => ({
            isDragging: !!monitor.isDragging(),
        }),
    });

    return { isDragging, drag };
};

export default useDraggable;
