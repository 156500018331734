import { json, Link, useLocation } from "react-router-dom";
import Button from "../../../components/Button";
import Modal from "../../../components/UI/Modal/Modal";
import { Autocomplete, Checkbox, Chip, FormControl, MenuItem, Paper, Select, TextField, autocompleteClasses } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import UseForm from "../../../Hooks/useForm";
import { userByOrg } from "../../../redux/actions/user";
import { useState, useEffect, useContext } from "react";
import { getCurrentUser } from "../../../redux/actions/auth";
import { AddupdateroleOrg, addUpdateUserRoleByUser, roleid } from "../../../redux/actions/role";
import { decryptId } from "../../../utils/helpers";
import { validateRolebyOrg } from "../../../Hooks/validate";
import MessageBox from "../../../components/UI/Alert";
import SuccessMessageBox from "../../../components/UI/Alert/sucess";
import { ADD_UPDATE_USER_ROLEBYUSER_RESET } from "../../../redux/types/types";
import { ThemeContext } from "../../../Hooks/Themes/Themeprovider";

const UserRole = (props) => {
    const reqObj = {
        order_by: 1,
        order_column: "",
        pg_no: 1,
        rec_cnt: 20,
        role_id: 0,
        search_text: "",
        status: 2,
    };
    const { handleChange, handleSubmit, values, InitialState, errors, setErrors, setValues } = UseForm(submit);
    const successMsg = "Role is  assigned successfully.";
    const errormsgs = "Something went wrong please contact admin";
    const currentUser = getCurrentUser();
    const dispatch = useDispatch();
    const [selectedUserList, setSelectedUser] = useState([]);
    const [errormsg, seterrormsg] = useState(false);
    const [unselectid, unsetSelectid] = useState([]);
    const { role } = useSelector((state) => state?.roleState);
    const userlist = useSelector((state) => state?.ViewUserByRoleOrgState);
    let result = useSelector((state) => state?.AddUpdateUserRoleByUserState);
    const { data: dataList } = userlist;
    //const profileData = useSelector((state) => state?.userListState);
    const profileData = useSelector((state) => state?.userListDropDownState);
    const { data: listData } = profileData;
    const filterListData = listData?.filter((elem) => elem?.isadmin == false);
    let userList = filterListData?.filter((org) => org?.organisationRole_list === null);

    let userListItem = [];
    if (userList) {
        userListItem.push(...userList);
    } else {
        userListItem = filterListData;
    }
    const [isadult, setIsAdult] = useState([]);
    const [val, setVal] = useState([]);
    const handleCheckboxChange = (event, userId) => {
        setIsAdult({
            ...isadult,
            [event.target.name]: event.target.value,
        });
        if (!event.target.value) {
            if (val?.length > 0) {
                const isExistID = val?.find((item) => item?.id === userId);
                if (isExistID) {
                    const removeIdList = val?.filter((item) => item?.id !== userId);
                    setVal([...removeIdList]);
                }
            }
            // if (selectedUserList.length > 0) {
            //   const userExist = val.find(item => item.id === userId);
            //   if (!userExist) {
            //     selectedUserList.push(userId);
            //     setSelectedUser([...selectedUserList]);
            //   }
            // } else {
            //   selectedUserList.push(userId);
            //   setSelectedUser([...selectedUserList]);
            // }
        }
    };
    const handleChangeVal = (e, newValue) => {
        setVal(newValue);
    };
    useEffect(() => {
        //dispatch(userByOrg(reqObj));
        //dispatch(roleid());
    }, [selectedUserList, dispatch]);

    if (result?.requestComplete && result?.roleResult?.status) {
        setTimeout(function () {
            dispatch({ type: ADD_UPDATE_USER_ROLEBYUSER_RESET });
            handleClose();
            window.location.reload();
        }, 2000);
    }

    function submit() {
        if (val?.length > 0 && values?.role_id > 0) {
            seterrormsg(false);
            let getUserIdList = [];
            val.forEach((item) => {
                getUserIdList.push(item?.id);
            });
            const jsonreq = {
                id: getUserIdList,
                user_id: currentUser?.id,
                org_id: props?.orgId,
                role_id: parseInt(values?.role_id),
            };
            dispatch(addUpdateUserRoleByUser(jsonreq));
            localStorage.setItem("selectedOrgId", props?.orgId);
            localStorage.setItem("UpdateOrgId", props?.orgId);
        } else {
            seterrormsg(true);
        }
    }

    const handleClose = () => {
        props?.handleClose();
        setValues(InitialState);
        setVal([]);
        seterrormsg(false);
        //dispatch(userByOrg(reqObj));
    };

    // const valHtml = val?.map((option, index) => {
    //   const label = decryptId(option?.user_emailaddress);

    //   return (
    //     <div className="mr-1 mb-2">
    //       <Chip
    //         sx={{ borderRadius: "8px" }}
    //         // onClick={() => removeUser(index, option.id)}
    //         key={label}
    //         label={label}
    //         // onDelete={()=>removeSelectedItems(option.id)}
    //         onDelete={() => {
    //           setVal(
    //             val.filter((entry) => decryptId(entry) !== decryptId(option))
    //           );
    //         }}
    //         deleteIcon={<img src="/images/common/closeBlk.svg" />}
    //         avatar={
    //           <img
    //             alt="Natacha"
    //             src={
    //               option?.user_image
    //                 ? `data:image;base64,${option?.user_image}`
    //                 : `/images/common/profileImg.jpg`
    //             }
    //           />
    //         }
    //       />
    //     </div>
    //   );
    // });

    const { currentMode } = useContext(ThemeContext);
    const dropdownColor = currentMode === "dark" ? "#333333" : `white`;
    const dropdownText = currentMode === "dark" ? "#ECECEC" : `#555555`;
    const dropdownShadow = currentMode !== "dark" ? "0px 4px 20px rgba(238, 238, 238, 0.5)" : "";
    const dropdownBorder = currentMode !== "dark" ? "223, 225, 226, 0.56" : "";
    const Ico = () => (
        <img
            className="opacity-[.5] pr-[5px] pointer-events-none"
            src={currentMode !== "dark" ? `/images/header/chevronDown.svg` : `/images/header/chevronDown-white.svg`}
            alt="chevronDown"
        />
    );

    return (
        <div>
            {result?.requestComplete && result?.roleResult?.status && <SuccessMessageBox success={successMsg} />}
            {result?.requestComplete && !result?.roleResult?.status && <MessageBox error={result?.roleResult?.message} />}
            {result?.error && !result?.roleResult?.status && <MessageBox error={errormsgs} />}
            <Modal cartClose={props?.handleClose} className={`${props?.className}`}>
                <Link onClick={handleClose} className="p-[6.5px] absolute right-[565px] bg-light-10" title="Close">
                    <img src="/images/common/whiteClose.svg" alt="whiteClose" />
                </Link>
                <div
                    className={`max-w-[565px] bg-${currentMode}-card ml-auto transition duration-150 ease-out flex-col flex h-screen max-h-[-webkit-fill-available]`}>
                    <div className={` px-[30px] py-[24px] border-b-[1px] border-${currentMode}-590 mb-[1.8rem] relative`}>
                        <h4 className="font-interSb text-lg">Users & Roles</h4>
                    </div>
                    <div className={`px-[35px] bg-${currentMode}-card`}>
                        <form onSubmit={handleSubmit} className="h-full flex justify-between flex-col">
                            <div className="max-w-[500px] h-[calc(100vh_-_210px)]">
                                <div className="mb-4 filter">
                                    <Autocomplete
                                        multiple
                                        id="checkboxes-tags-demo"
                                        options={userList?.length > 0 ? userList : []}
                                        disableCloseOnSelect
                                        getOptionLabel={(option) => decryptId(option?.user_emailaddress)}
                                        onChange={handleChangeVal}
                                        value={val}
                                        popupIcon={<Ico />}
                                        sx={{
                                            "& .MuiChip-label": {
                                                color: `${currentMode == "dark" ? "white" : "black"}`,
                                            },
                                            ".MuiOutlinedInput-input::placeholder": {
                                                color: "grey", // Change the color to your desired color
                                                opacity: 1,
                                            },
                                            [`& .${autocompleteClasses.popupIndicator}`]: {
                                                transform: "none",
                                            },
                                        }}
                                        PaperComponent={({ children }) => (
                                            <Paper
                                                style={{
                                                    backgroundColor: dropdownColor,
                                                    color: dropdownText,
                                                    boxShadow: dropdownShadow,
                                                    border: dropdownBorder,
                                                }}>
                                                {children}
                                            </Paper>
                                        )}
                                        SelectDisplayProps={{
                                            style: {
                                                color: dropdownText,
                                            },
                                        }}
                                        renderOption={(props, option, { selected }) => (
                                            <li {...props}>
                                                <Checkbox
                                                    style={{
                                                        marginRight: 8,
                                                        padding: 1.5,
                                                    }}
                                                    sx={{
                                                        color: "rgba(85,85,85,0.7)",
                                                        borderRadius: "3px !important",
                                                        "&.Mui-checked": {
                                                            color: "#028C78",
                                                        },
                                                    }}
                                                    type="checkbox"
                                                    checked={selected}
                                                />
                                                <img
                                                    className="w-[22px] mr-2 h-[22px] object-cover rounded-full"
                                                    alt="Natacha"
                                                    src={
                                                        option?.user_image
                                                            ? `data:image;base64,${option?.user_image}`
                                                            : `/images/common/profileImg.jpg`
                                                    }
                                                />
                                                {decryptId(option?.user_emailaddress)}
                                            </li>
                                        )}
                                        limitTags={1}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                fullWidth
                                                InputProps={{
                                                    ...params.InputProps,
                                                    // endAdornment: null,
                                                }}
                                                placeholder="Select Users"
                                            />
                                        )}
                                    />
                                </div>

                                <FormControl fullWidth>
                                    <Select
                                        onChange={handleChange}
                                        value={values?.role_id || "0"}
                                        name="role_id"
                                        className="w-full"
                                        IconComponent={() => (
                                            <img
                                                className="absolute right-[15px] opacity-[.5] pointer-events-none"
                                                src={
                                                    currentMode !== "dark" ? `/images/header/chevronDown.svg` : `/images/header/chevronDown-white.svg`
                                                }
                                                alt="chevronDown"
                                            />
                                        )}
                                        MenuProps={{
                                            sx: {
                                                "& .MuiMenu-paper": {
                                                    backgroundColor: dropdownColor,
                                                    color: dropdownText,
                                                    //   maxWidth: "30rem !important",
                                                },
                                                "& .MuiMenuItem-root:hover": {
                                                    backgroundColor: "rgba(218, 249, 244, 0.3)",
                                                },
                                                "& .Mui-selected": {
                                                    backgroundColor: "rgba(218, 249, 244, 0.3) !important",
                                                },
                                            },
                                        }}
                                        SelectDisplayProps={{
                                            style: {
                                                color: dropdownText,
                                                padding: "12px 30px 12px 15px",
                                                border: `1px solid rgba(85, 85, 85, 0.4)`,
                                                borderRadius: "8px",
                                            },
                                        }}>
                                        <MenuItem key={0} value={0}>
                                            {"Select Role"}
                                        </MenuItem>
                                        {role?.map((elem, i) => {
                                            return (
                                                <MenuItem key={i + 1} value={elem?.id}>
                                                    {elem?.role}
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                    {errormsg && <p className="text-light-50 text-xs">Please Select Role and Users</p>}
                                </FormControl>
                            </div>
                            {/* <div className={`border-t-[1px] py-5 mx-[-35px] border-${currentMode}-700 mt-6`}> */}
                            <div className={`border-t-[1px]  left-0 py-5 border-${currentMode}-590 mx-[-35px] mt-6`}>
                                <div className="px-[35px] space-x-2 flex">
                                    <Button
                                        type="submit"
                                        disabled={result?.fetching || (result?.requestComplete && result?.roleResult?.status)}
                                        onClick={submit}
                                        title="Create"
                                        className="bg-light-20 flex text-base font-interR py-[.5rem] px-[2.24rem] rounded-[8px] text-white">
                                        Create
                                    </Button>
                                    <Button
                                        type="button"
                                        title="Cancel"
                                        onClick={handleClose}
                                        className="bg-light-240 flex text-base font-interR py-[.5rem] px-[2.24rem] rounded-[8px] text-light-40 closeModal">
                                        Cancel
                                    </Button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default UserRole;
