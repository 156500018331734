import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from "recharts";
import { useContext } from "react";
import { ThemeContext } from "../../../../Hooks/Themes/Themeprovider";
import { extractTextFromHTML } from "../../../../utils/helpers";


const CustomBarchartPreview = (props) => {
    const data = props?.data
    const { currentMode } = useContext(ThemeContext);
    const dataKeys = data && Object.keys(data?.[0]);
    const textColor = currentMode === "dark" ? `#ECECEC` : `#000`;
    const CustomizedAxisTick = ({ x, y, payload }) => {
        const maxLength = data?.length > 5 ? 2 : 10; // Maximum length for label
        const Value = extractTextFromHTML(payload?.value)
        const text = payload.value.length > maxLength ? Value.substring(0, maxLength) + ".." : Value;

        return (
            <g transform={`translate(${x},${y})`}>
                <text x={0} y={0} dy={16} style={{ fontSize: "13px" }} textAnchor="middle" fill={textColor} className="xAxisLabel">
                    {text}
                </text>
            </g>
        );
    };
    const CustomTooltip = ({ active, payload }) => {
        if (active && payload && payload?.length) {
            const entry = payload[0]?.payload;

            return (
                <div className={` py-3 px-5 border recharts-default-tooltip rounded-[8px]`}>
                    <p className="label">{`${extractTextFromHTML(entry?.[dataKeys?.[1]])} : ${entry?.[dataKeys?.[0]]}`}</p>
                </div>
            );
        }
    };
    return (
        <ResponsiveContainer width="100%" height={380}>
            <BarChart
                margin={{
                    top: 5,
                    right: 35,
                    left: 5,
                    bottom: 5,
                }}
                data={data}>
                <XAxis dataKey={dataKeys?.[1]} stroke={currentMode === "dark" ? "#dfe1e28f" : "#E3E3E3"} tick={<CustomizedAxisTick />} interval={0} />
                <YAxis tick={{ fill: textColor, fontSize: "13px" }} stroke={currentMode === "dark" ? "#dfe1e28f" : "#E3E3E3"} />
                <Tooltip cursor={{ fill: "transparent" }} content={<CustomTooltip />} />
                <Legend
                    align="right"
                    verticalAlign="top"
                    wrapperStyle={{
                        position: "absolute",
                        top: "-30px", // Adjust top position as needed
                    }}
                />
                <Bar dataKey={dataKeys?.[0]} barSize={40} fill={`#F07584`} radius={[100, 100, 0, 0]} />
            </BarChart>
        </ResponsiveContainer>
    );
};

export default CustomBarchartPreview;
