import Card from "../../components/UI/Card";
import { ThemeContext } from "../../Hooks/Themes/Themeprovider";
import Button from "../../components/Button";
import ReactDOM from "react-dom";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import Input from "../../components/Input";
import Swal from "sweetalert2";
import TogglePopup from "../../pages/organisations/Toggle";
import { useState, useEffect, useRef, useContext } from "react";
import { getDocumentFromS3ForAdvance } from "../../redux/actions/advance-request.js";
import { GET_DOCUMENT_FROM_S3_FOR_ADVANCE_RESET } from "../../redux/types/types.js";
const AdvanceFileUpload = (props) => {
    const dispatch = useDispatch();
    const findCreateNewRequest = props?.findCreateNewRequest;
    const childCompRef = useRef();
    const uploadFile = props?.uploadFile;
    const setUploadFile = props?.setUploadFile;
    const fileSize = props?.fileSize;
    const fileType = props?.fileType;
    const setFileSize = props?.setFileSize;
    const filterMenuUpload = [
        !findCreateNewRequest && props?.url && { text: "Download", src: "/images/common/download-grn.svg", value: 0 },

        !props?.DisableInput && { text: "Delete", src: "/images/mobilization/delete.svg", value: 1 },
    ];
    const fileTypes = [
        "image/png",
        "image/jpg",
        "image/jpeg",
        "application/pdf",
        "application/zip",
        "application/vnd.ms-excel",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    ];
    const location = useLocation();
    const { currentMode, toggleMode } = useContext(ThemeContext);
    const downloadDocument = () => {
        dispatch(getDocumentFromS3ForAdvance(props?.url));
    };
    const { dataList: data, fetching, requestComplete } = useSelector((state) => state?.documentFromS3ForAdvanceState);
    if (requestComplete) {
        if (data?.status) {
            const linkSource = `data:${fileType};base64,${data?.datalist}`;
            const downloadLink = document.createElement("a");
            const fileName = uploadFile;
            downloadLink.href = linkSource;
            downloadLink.download = fileName;
            downloadLink.click();
        }
        dispatch({ type: GET_DOCUMENT_FROM_S3_FOR_ADVANCE_RESET });
    }
    const onChange = (e) => {
        const file = e.target.files[0];
        const fileExt = fileTypes.find((item) => item == file.type);
        if (fileExt) {
            setUploadFile(file?.name);
            if (file) {
                const fileSizeInBytes = file.size;
                const fileSizeInKB = fileSizeInBytes / 1024;

                if (fileSizeInKB > 5120) {
                    // 5120 KB = 5 MB
                    // File size exceeds 5MB, display alert message
                    Swal.fire({
                        text: "File size exceeds 5MB. Please select a smaller file.",
                        icon: "warning",
                        closeOnClickOutside: false,
                        showCloseButton: true,
                        buttons: {
                            confirm: true,
                        },
                    }).then((confirm) => {
                        if (confirm) {
                            // Optionally, you can reset the input to clear the selected file
                            setUploadFile("");
                            setFileSize("");
                        }
                    });
                } else {
                    let fileSizeString;
                    if (fileSizeInKB < 1024) {
                        // Display in KB if below 1 MB
                        fileSizeString = `${fileSizeInKB.toFixed(2)} KB`;
                    } else {
                        // Convert to MB if 1 MB or above
                        const fileSizeInMB = fileSizeInKB / 1024;
                        fileSizeString = `${fileSizeInMB.toFixed(2)} MB`;
                    }
                    setFileSize(fileSizeString);
                    uploadWithJSON(file, file.name);
                }
            }
        } else {
            Swal.fire({
                text: `UnSupported File format!`,
                icon: "warning",
                closeOnClickOutside: false,
                showCloseButton: true,
                buttons: {
                    confirm: true,
                },
            }).then((confirm) => {
                if (confirm) {
                    // Optionally, you can reset the input to clear the selected file
                    setUploadFile("");
                    setFileSize("");
                }
            });
        }
    };
    async function uploadWithJSON(file, filename) {
        const reader = new FileReader();
        reader.onload = await _handleReaderLoaded(filename).bind(this);
        reader.readAsBinaryString(file);
    }

    const _handleReaderLoaded = (filename) => async (e) => {
        let binaryString = e.target.result;
        let imgObject = {
            filecontent: btoa(binaryString),
            filename: filename,
        };
        props?.setFileContent(imgObject);
    };
    const onFilterItemsUpload = (value, id) => {
        switch (value) {
            case 0:
                downloadDocument();
                break;
            case 1:
                Swal.fire({
                    text: `Are you sure you want  to delete this file? ("${uploadFile}") ?`,
                    icon: "warning",
                    closeOnClickOutside: false,
                    showCloseButton: true,
                    showCancelButton: true,
                    buttons: {
                        cancel: "Cancel",
                        confirm: "Confirm",
                    },
                }).then((confirm) => {
                    if (confirm?.isConfirmed) {
                        // Optionally, you can reset the input to clear the selected file
                        setUploadFile("");
                        setFileSize("");
                        props?.setFileContent("");
                    } else {
                        return false;
                    }
                });
                break;
            default:
                break;
        }
    };
    const checkEdit = !props?.RoleActAdvEdit && location?.pathname == "/mobilization-request";
    return (
        <>
            <div>
                {" "}
                <form>
                    <Input
                        type="file"
                        className="file cursor-pointer absolute p-0 z-index-[1] w-full h-[256px] opacity-0 xl:w-[300px] 2xl:w-full"
                        onChange={(e) => onChange(e)}
                        // disabled={fetching || currentUser?.licenseInvoice_Expired == true
                        disabled={props?.DisableInput}
                        name="image"
                        accept=".pdf, .png, .docx, .jpg, .zip, .xls, .doc, .xlsx, application/pdf, image/png, image/jpg, application/zip, application/vnd.ms-excel, application/msword"
                    />
                    {uploadFile || props?.fileContent ? (
                        <div>
                            <div className="flex flex-col items-end">
                                <Button
                                    title="More Options"
                                    className="cursor-pointer"
                                    onClick={(e) => {
                                        e.preventDefault(); // Add this line to prevent the default behavior
                                        childCompRef.current.handleClick("bottom-end", 1, e);
                                    }}
                                >
                                    <img className="mt-[15px] mr-7" src={`${"/images/mobilization/more-vertical.svg"} `} />
                                </Button>
                            </div>
                            <div className="flex flex-col items-center">
                                <div className="text-center mt-[70px]">
                                    <div className="flex">
                                        <Button
                                            className="cursor-pointer"
                                            onClick={(e) => childCompRef.current.handleClick("bottom-end", 1, e)}
                                            title="More actions"
                                        >
                                            <img className="ml-[30px] cursor-pointer" src={`${"/images/mobilization/document.svg"} `} />
                                        </Button>
                                        <span className="mr-1" title={uploadFile}>
                                            <p className="text-[14px] mt-[4px] max-w-[150px] truncate text-black">{uploadFile}</p>
                                            <p className="flex items-start mt-[-8px] text-[10px] text-[#555555]">{fileSize}</p>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <TogglePopup
                                lowOverlay={true}
                                invoice={true}
                                ref={childCompRef}
                                menuList={filterMenuUpload}
                                selectedItemChange={onFilterItemsUpload}
                            />
                        </div>
                    ) : (
                        <div className="flex flex-col items-center">
                            <img className="mt-[73px]" src="/images/mobilization/upload_icon.svg" alt="fileUpload" />
                            <div className="text-center mt-1">
                                <span className="text-[16px] text-black mb-1 font-interM">Drop files here or click to browse</span>
                                <div className="text-[12px] font-medium text-gray-600">
                                    <p>File types: PDF, JPG, PNG, ZIP, Xls, Doc.</p>
                                    <span>Up to 5MB</span>
                                </div>
                            </div>
                        </div>
                    )}
                </form>
            </div>
        </>
    );
};

export default AdvanceFileUpload;
