// qa-new: https://qa.myiq.ai:8083/
//qa-now : http:/10.0.140.172/
//local : http://35.178.109.255/
//production: http://10.0.143.55/

//Node API
//dev : http://18.170.230.99:4000/
//qa : http://3.8.215.133:4000/

//Https Ip details:

// Dev backend : https://dev.myiq.ai:8082/
// qly backend : https://qa.myiq.ai:8083/

// Dev Front-end (site) : https://dev.myiq.ai
// qly Front-end (site) : https://qa.myiq.ai

const configMyiQ = {
  API_URL: "https://api.qa.myiq.ai/",
  Node_API_URL: "http://localhost:4000/",
  SessionTimeoutValue: 30 * 60,
  SessionRunningTime: 1000,
  FirebaseConfig: {
    apiKey: "AIzaSyDKD9SwUS2mTQRB9s0vZkczaUabYcsFM90",
    authDomain: "readinginbox.firebaseapp.com",
    projectId: "readinggmailinbox",
    storageBucket: "readinggmailinbox.appspot.com",
    messagingSenderId: "624414269495",
    appId: "1:624414269495:web:eec0bb86afe853fb2b1fb2",
    measurementId: "G-3CC71MYEQR",
  },
  FcbServerId:
    "BMivhm0hSriLVq_MjoYew2eYoKPe-ugHTpLY7LpV3CgSAyYFB-e3ijPFL1L_qmqww_6o0-5NCXs6oJoPatJvCEY",
};

export default configMyiQ;
