import React, { useEffect, useState } from "react";
import { WS_CONNECT, WS_DISCONNECTED } from "../../../redux/types/types";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { io } from "socket.io-client";

const WebSocketComponent = () => {
    // const URL_WEB_SOCKET =
    //     "ws://localhost:4001/api/receiveMSEmailSubscription/emailaddress:" +
    //     localStorage.getItem("SyncMail");
    // const request = {
    //     method: "SUBSCRIBE",
    //     params: ["btcusdt@trade"],
    //     id: 1,
    // };
    // const { data: ws, message } = useSelector(
    //     (state) => state?.getSocketConnectState
    // );
    // const dispatch = useDispatch();
    // const [trades, setTrades] = useState([]);
    // useEffect(() => {
    //     dispatch({
    //         type: WS_CONNECT,
    //         payload: { url: URL_WEB_SOCKET },
    //         request: request,
    //         dispatch,
    //     });
    // }, [dispatch]);
    // useEffect(() => {
    //     dispatch({ type: WS_DISCONNECTED });
    // }, [ws]);
    // useEffect(() => {
    //     const socket = io("http://localhost:4001");
    //     socket.ad
    //     socket.disconnect();
    // }, []);
};

export default WebSocketComponent;
