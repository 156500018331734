import { useEffect, useRef, useState } from "react";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import TogglePopup from "../../pages/organisations/Toggle";
import Button from "../Button";
import Input from "../Input";
import Card from "../UI/Card";
import ReactDOM from "react-dom";
import UsersView from "../../organisms/UserModal/UserRole/UsersView";
import AddUserOrg from "../../organisms/UserModal/UserRole/AddUserOrg";
import UserActivity from "../../organisms/UserModal/UserActivity";
import { decryptId, encryptId } from "../../utils/helpers";
import UseForm from "../../Hooks/useForm";
import { validateUserEdit } from "../../Hooks/validate";
import { ActivateDeactivateUsers, userByOrg, userEdit, GetUserActivity, userView } from "../../redux/actions/user";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import EditBox from "./EditBox";
import { listOrgRoleByUser, roleid } from "../../redux/actions/role";
import { getCurrentUser, resendEmailLink } from "../../redux/actions/auth";
import Swal from "sweetalert2";
import SuccessMessageBox from "../../components/UI/Alert/sucess";
import MessageBox from "../../components/UI/Alert";
import { GETALLUSER_BYORG__RESET, RESEND_EMAIL_LINK_RESET, USER_STATUS_UPDATE_RESET } from "../../redux/types/types";
import { getOrganisation } from "../../redux/actions/organisation";
import EditUser from "../../organisms/UserModal/EditUser";
import { countryList } from "../../redux/actions/country";
import { type } from "@testing-library/user-event/dist/type";
import { useContext } from "react";
import { ThemeContext } from "../../Hooks/Themes/Themeprovider";
const statusMsg = "Status is updated successfully";
const successMsg = "User is updated successfully";
const errorMsg = "Something went wrong please contact admin";
const mailMsg = "Invite mail sent to user successfully";
const UserCard = (props) => {
    const currentUser = getCurrentUser();
    const { currentMode } = useContext(ThemeContext);
    const dropdownColor = currentMode === "dark" ? "#333333" : `white`;
    const dropdownText = currentMode === "dark" ? "#ECECEC" : `#555555`;
    const reqObj = {
        order_by: 1,
        order_column: "",
        pg_no: 1,
        rec_cnt: 20,
        role_id: 0,
        search_text: "",
        status: 2,
    };
    const [editvalue, setEditvalue] = useState({
        firstname: "",
        lastname: "",
        user_emailaddress: "",
        mobilenumber: "",
        fieldType: "",
        country_id: "",
    });
    let profileData = useSelector((state) => state?.userListState);
    const UserId = currentUser?.id === editvalue?.id;
    let Users = props?.ListData;
    const date = new Date();
    const d = date.toLocaleDateString("en-GB");

    // Users.user_emailaddress = decryptId(Users.user_emailaddress);
    const handleChange = (e) => {
        const { name, value } = e.target;
        setEditvalue({
            ...editvalue,
            [name]: value,
            fieldType: name,
        });
    };

    useEffect(() => {
        if (Users) {
            setEditvalue({
                ...editvalue,
                ...Users,
            });
        }
    }, [Users]);
    const LightTooltip = styled(({ className, ...props }) => (
        <Tooltip
            enterTouchDelay={0}
            {...props}
            PopperProps={{
                sx: {
                    "& .MuiTooltip-tooltip": {
                        padding: "0 5px",
                    },
                },
                modifiers: [
                    {
                        name: "offset",
                        options: {
                            offset: [0, 0],
                        },
                    },
                ],
            }}
            classes={{ popper: className }}
        />
    ))(({}) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: dropdownColor,
            color: dropdownText,
            borderRadius: "10px",
            boxShadow: "0px 4px 20px rgba(85,85,85,0.2)",
        },
        [`& .${tooltipClasses.arrow}`]: {
            width: "30px",
            height: "17px",
            marginTop: "-18px !important",
            "&::before": {
                backgroundColor: "white",
                border: "1px solid #DCDCDC",
            },
        },
    }));
    const dispatch = useDispatch();
    const [isEdits, setIsEdits] = useState("");
    const [isAdd, setIsAdd] = useState(false);
    const [userId, setuserId] = useState("");
    const [isView, setIsView] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [isClose, setIsClose] = useState(true);
    const [fieldType, setFieldType] = useState("");
    const [isUserActivity, setIsUserActivity] = useState(false);
    const [fileName, onFileUpload] = useState("");
    const [isStatusUpdate, handleStatusUpdate] = useState(false);
    const updateStatusData = useSelector((state) => state?.UsersUpdateStatusState);
    useEffect(() => {}, [dispatch]);

    const handleListOrgRoleByUser = () => {
        dispatch(listOrgRoleByUser());
    };

    const onFilterItems = (value, id) => {
        setuserId(id);
        if (value === 0) {
            setIsView(true);
        }
        if (value === 1) {
            setIsAdd(true);
            dispatch(getOrganisation(reqObj));
            dispatch(roleid());
        }
        if (value === 3) {
            setIsUserActivity(true);

            dispatch(GetUserActivity({ user_id: Users?.id, filter_key: 1 }));
        }
        if (value === 2) {
            dispatch(
                resendEmailLink({
                    email: editvalue?.user_emailaddress,
                    type: 2,
                })
            );
            dispatch({ type: RESEND_EMAIL_LINK_RESET });
        }
        if (value === 4) {
            Swal.fire({
                text: `Are you sure you want to activate the user?`,
                icon: "warning",
                closeOnClickOutside: false,
                showCloseButton: true,
                showCancelButton: true,
                buttons: {
                    cancel: "Cancel",
                    confirm: "Confirm",
                },
            }).then((confirm) => {
                if (confirm.isConfirmed) {
                    onStatusChange(editvalue?.user_isactive);
                    dispatch({ type: GETALLUSER_BYORG__RESET });
                    dispatch(userByOrg(reqObj));
                    setTimeout(() => {
                        dispatch(userByOrg(reqObj));
                    }, 1000);
                }
            });
        }
        if (value === 6) {
            const isInactive = editvalue?.organisationRole_list?.some((ele) => ele?.org_isactive == "true");
            Swal.fire({
                text: `Are you sure you want to inactivate the user?`,
                icon: "warning",
                closeOnClickOutside: false,
                showCloseButton: true,
                showCancelButton: true,
                buttons: {
                    cancel: "Cancel",
                    confirm: "Confirm",
                },
            }).then((confirm) => {
                if (confirm.isConfirmed) {
                    onStatusChange(editvalue?.user_isactive);
                    dispatch({ type: GETALLUSER_BYORG__RESET });
                    setTimeout(() => {
                        dispatch(userByOrg(reqObj));
                    }, 1000);
                }
            });
            if (isInactive) {
                Swal.fire({
                    text: ` User cannot be inactivated when there are active organisations!`,
                    icon: "error",
                    closeOnClickOutside: false,
                    showCloseButton: true,
                    buttons: {
                        confirm: true,
                    },
                }).then((confirm) => {
                    return false;
                });
            } else if (editvalue?.istask) {
                Swal.fire({
                    text: `Tasks created by this user are pending! `,
                    icon: "error",
                    closeOnClickOutside: false,
                    showCloseButton: true,
                    buttons: {
                        confirm: true,
                    },
                }).then((confirm) => {
                    return false;
                });
            }
        }

        if (value === 5) {
            setIsEdit(true);
            dispatch(userView(editvalue?.id));
            if (country?.length === 0) {
                dispatch(countryList());
            }
        }
    };
    if (!updateStatusData?.data?.status && updateStatusData?.requestComplete && updateStatusData?.data?.message !== "") {
        Swal.fire({
            text: updateStatusData?.data?.message,
            icon: "error",
            closeOnClickOutside: false,
            showCloseButton: true,
            buttons: {
                confirm: true,
            },
        }).then((confirm) => {
            if (confirm.isConfirmed) {
                dispatch({ type: USER_STATUS_UPDATE_RESET });
            }
        });
    }
    if (updateStatusData?.error !== "" && updateStatusData?.requestComplete) {
        Swal.fire({
            text: updateStatusData?.error,
            icon: "error",
            closeOnClickOutside: false,
            showCloseButton: true,
            buttons: {
                confirm: true,
            },
        }).then((confirm) => {
            if (confirm.isConfirmed) {
                dispatch({ type: USER_STATUS_UPDATE_RESET });
            }
        });
    }
    const handleViewOrgRole = () => {
        setIsView(true);
        handleListOrgRoleByUser();
    };
    const handleEdit = (id, fieldType) => {
        setIsClose(false);
        setIsEdits(id);
        setFieldType(fieldType);
    };
    const { data: resendResponse, error: errors, requestComplete: requestCompleted } = useSelector((state) => state?.ResendEmailLinkState);
    const responseData = resendResponse;
    const requestComp = requestCompleted;
    const navigate = useNavigate();
    const { country } = useSelector((state) => state?.countryState);

    const countryObj = Users?.country ? country?.find((item) => item.country_id === parseInt(Users?.country)) : "";

    if (requestComp && responseData?.status) {
        setTimeout(() => {
            dispatch({ type: RESEND_EMAIL_LINK_RESET });
        }, 2500);
    }
    const onStatusChange = (value) => {
        const UserStatus = {
            id: Users?.id,
            status: value ? false : true,
        };
        dispatch(ActivateDeactivateUsers(UserStatus));
        // handleStatusUpdate(isStatusUpdate ? false : true);
    };
    const DisplayRole = useSelector((state) => state?.DisplayRolePermissionStateAction);
    const { data: DataList } = DisplayRole;
    const RoleList = DataList?.role_info;
    const RoleMenu = RoleList?.menu;
    const RoleUser = RoleMenu?.find((elem) => elem?.menu_id === 6);
    const RoleUserAction = RoleUser?.actions;
    const RoleActUserEdit = RoleUserAction?.find((element) => element.id === 2);
    const RoleActUserTask = RoleUserAction?.find((element) => element.id === 13);
    const RoleActUserActivate = RoleUserAction?.find((element) => element.id === 4);
    const RoleActUserDeactivate = RoleUserAction?.find((element) => element.id === 5);
    const OrgRole = RoleMenu?.find((elem) => elem?.menu_id === 7);
    const OrgRoleAction = OrgRole?.actions;
    const OrgRoleAdd = OrgRoleAction?.find((element) => element.id === 1);
    const OrgRoleView = OrgRoleAction?.find((element) => element.id === 3);

    const targetDateStr = currentUser?.expiring_date;
    const targetDate = new Date(targetDateStr);
    const currentDate = new Date();
    const matchdate = targetDate < currentDate;
    const ResendLinkshow =
        (!matchdate && currentUser?.license_type == 2) ||
        currentUser?.license_type == 1 ||
        currentUser?.license_type == 3 ||
        currentUser?.license_type == 4;
    const filterMenuList = [
        (editvalue?.user_isactive === false && editvalue?.isactivated === true) || false
            ? false
            : editvalue?.isadmin == false &&
              OrgRoleAdd?.isallowed == true && {
                  text: "Add Organization",
                  title: "To allow Organization Access",
                  src: "/images/common/org-ico-green.png",
                  tabIndex: "1",

                  value: 1,
              },
        editvalue?.isadmin == false &&
            OrgRoleView?.isallowed == true && {
                text: "View Organization",
                title: "To View the Organization Access",
                src: "/images/common/eye.svg",
                tabIndex: "2",
                value: 0,
            },

        RoleActUserTask?.isallowed == true &&
            editvalue?.isactivated === true && {
                text: "User Activity",
                title: "To View the User Activity Log",
                src: "/images/invoice/activity_icon.svg",
                value: 3,
                tabIndex: "3",
            },
        editvalue?.isactivated && editvalue?.isadmin == false
            ? editvalue.user_isactive && RoleActUserDeactivate?.isallowed == true && !UserId
                ? {
                      text: "Deactivate",
                      title: "To Inactivate the User",
                      src: "/images/common/inactive-ico.svg",
                      value: 6,
                      tabIndex: "4",
                      task: editvalue?.isTask,
                  }
                : !editvalue?.user_isactive &&
                  RoleActUserActivate?.isallowed == true &&
                  !UserId && {
                      text: "Activate",
                      title: "To Activate the User",
                      src: "/images/common/active-ico.svg",
                      value: 4,
                  }
            : false,
        editvalue?.isactivated === false &&
            ResendLinkshow && {
                text: "Resend invite link",
                title: "To Resend the Invite Link",
                src: "/images/common/resend-ico.svg",
                value: 2,
                tabIndex: "5",
            },
        RoleActUserEdit?.isallowed == false || (editvalue.user_isactive === false && editvalue?.isactivated === true) || false
            ? false
            : {
                  text: "Edit user",
                  title: "To Edit the User Details",
                  src: "/images/common/pencilEdit.svg",
                  value: 5,
                  tabIndex: "6",
              },
    ];
    const childCompRef = useRef();

    const updateUser = (fieldType, value, userId) => {
        let formdata = new FormData();
        formdata.append(fieldType, value);
        formdata.append("id", userId);
        dispatch(userEdit(formdata));
        setIsClose(true);
    };
    const closeUpdate = () => {
        setIsClose(true);
    };
    const { data, fetching, error, requestComplete } = useSelector((state) => state?.userEditState);
    const roleList = Users?.organisationRole_list ? Users?.organisationRole_list : [];

    const closeAddRoleUser = () => {
        setIsAdd(false);
        // dispatch(userByOrg(reqObj));
    };
    const closeEditUser = () => {
        setIsEdit(false);
    };
    // const updateProfilePic = (userId)=>{
    //   const e = new Event("click");
    //   const element = document.querySelector('#fileChange')
    //   element.click();
    // }
    const removeProfilePic = (userId) => {
        let formdata = new FormData();
        formdata.append("Image", "");
        formdata.append("id", userId);
        dispatch(userEdit(formdata));
    };

    const onChangeFileUpload = (e, userId) => {
        let file = e.target.files[0];

        if (file) {
            const reader = new FileReader();
            reader.onload = _handleReaderLoaded(userId).bind(this);
            reader.readAsBinaryString(file);
            // handleUpload();
        }
    };
    const _handleReaderLoaded = (userId) => (e) => {
        let binaryString = e.target.result;
        onFileUpload(btoa(binaryString));
        let formdata = new FormData();
        formdata.append("Image", btoa(binaryString));
        formdata.append("id", userId);
        dispatch(userEdit(formdata));
    };

    const Datas = (props) =>
        profileData?.data?.org_list
            ?.filter((el) => el?.id === props?.id)
            .map((elem) => {
                return (
                    <>
                        <div className="flex items-center p-3 rounded-[10px]">
                            <div className="relative">
                                <img
                                    className="w-[30px] h-[30px] rounded-full object-contain"
                                    src={elem?.logo ? `data:image;base64,${elem?.logo}` : `/images/common/orgLogo.png`}
                                    alt="user-1"
                                />
                                {!elem?.organisation_isactive ? (
                                    <img
                                        className="absolute right-[-.3rem] bottom-[-.3rem]"
                                        src="/images/common/org-inactive.svg"
                                        alt="org-inactive"
                                    />
                                ) : null}
                            </div>
                            <div className="ml-3">
                                <span className="text-light-10 inline-block max-w-[140px] truncate text-sm">{elem?.organisation_name}</span>
                                <p className="text-xs">Role:{props?.rolename}</p>
                            </div>
                        </div>
                    </>
                );
            });

    const InSideOrg = (props) =>
        profileData?.data?.org_list
            ?.filter((el) => el?.id === props?.id)
            .map((element) => {
                return (
                    <>
                        <div>
                            <img
                                src={element?.logo ? `data:image;base64,${element?.logo}` : "/images/common/orgLogo.png"}
                                className={`object-contain w-[30px] h-[30px] rounded-full`}
                                alt="org-1"
                            />
                            {!element?.organisation_isactive ? (
                                <img className="absolute right-[-.3rem] bottom-[-.3rem]" src="/images/common/org-inactive.svg" alt="org-inactive" />
                            ) : null}
                        </div>
                    </>
                );
            });

    // !element?.org_isactive ?
    //                          : null

    return (
        <>
            <Card isuser={true} className={`${currentMode !== "dark" ? "shadow-[0px_4px_20px_rgba(238,238,238,0.5)]" : ""} relative p-[30px]`}>
                {requestComplete && data?.status && <SuccessMessageBox success={successMsg} />}
                {requestComp && responseData.status && <SuccessMessageBox success={mailMsg} />}
                {errors && !responseData.status && <MessageBox error={errorMsg} />}
                {requestComplete && !data?.status && <MessageBox error={data?.message} />}
                {error && !data?.status && <MessageBox error={errorMsg} />}
                <span className="absolute right-[30px]">
                    <img
                        src={
                            !Users?.isactivated && !Users?.user_isactive
                                ? `/images/common/pending-ico.svg`
                                : Users?.user_isactive
                                ? `/images/common/active-ico.svg`
                                : `/images/common/inactive-ico.svg`
                        }
                        title={
                            !Users?.isactivated && !Users?.user_isactive
                                ? `User not accepted the invitation yet`
                                : Users?.user_isactive
                                ? `Active User`
                                : `Inactive User`
                        }
                        alt="active-ico"
                    />
                </span>
                <div className="flex">
                    <div className="text-center flex-inherit mr-[20px] w-[190px]">
                        <div
                            className={`relative bg-${currentMode}-card rounded-full p-[5px] w-[190px] h-[190px] shadow-[0px_4px_20px_rgba(85,85,85,0.15)] mb-4`}>
                            <div className="profileHover userImage w-full h-full cursor-default">
                                {fileName ? (
                                    <img className="rounded-full w-full h-full object-cover " src={`data:image;base64,${fileName}`} alt="users-1" />
                                ) : (
                                    <img
                                        className="rounded-full w-full h-full object-cover"
                                        src={
                                            editvalue?.user_image !== null
                                                ? `data:image;base64,${editvalue?.user_image}`
                                                : `/images/common/default-user-pic.png`
                                        }
                                        alt="users-1"
                                    />
                                )}
                                {/* <div className="flex items-start px-[20px] rounded-[10px] py-3 boxed">
                  <div className="text-[15px] flex flex-col space-y-1">
                    <div className="upload-btn-wrapper mx-[-17px] px-[20px] hover:bg-[rgba(218,249,244,0.5)]" title="Upload">
                      <div
                        to="#!"
                        className="flex cursor-pointer items-center justify-center"
                      >
                         <img
                          className="mr-1"
                          src="/images/common/camera.svg"
                          alt="camera"
                        />{" "} 
                        Upload
                      </div>
                      <input
                        className="cursor-pointer"
                        type="file"
                        name="myfile1"
                        style={{ pointerEvents: "auto" }}
                        accept=".jpg, .jpeg, .png"
                        onChange={(e) => onChangeFileUpload(e, Users?.id)}
                      />
                    </div>
                    <Link
                      className="mx-[-17px] px-[20px] hover:bg-[rgba(218,249,244,0.5)]"
                      onClick={() => removeProfilePic(Users?.id)}
                    >
                      Remove
                    </Link>
                  </div>
                </div> */}
                            </div>
                        </div>
                        <h5 className="text-light-10 break-all font-interSb text-center text-base">{editvalue?.user_name}</h5>
                    </div>
                    <div className="flex-auto w-full">
                        <div className="xl:flex flex-wrap w-full items-center justify-between">
                            <div className="basis-[48%] mb-6">
                                <label className="text-xs block mb-1">First Name</label>
                                <div title={editvalue?.firstname} className="flex justify-between items-center Editable relative">
                                    <Input
                                        readOnly={fieldType === "firstname" ? false : true}
                                        className={`block truncate w-full mb-2 font-interSb bg-transparent`}
                                        name="firstname"
                                        value={editvalue?.firstname}
                                        onChange={(e) => handleChange(e)}
                                        placeholder="Enter the first name"
                                    />
                                    {/* {errors && (
                    <p className="text-light-50 text-xs">
                      {errors?.firstname}
                    </p>
                  )} */}
                                    {fieldType === "firstname" && !isClose && (
                                        <EditBox
                                            handleClick={() => updateUser("firstname", editvalue?.firstname, Users?.id)}
                                            id={Users?.id}
                                            closeClick={() => closeUpdate()}
                                        />
                                    )}
                                    {/* {isClose && (
                    <Button
                      className="Edit"
                      id={Users?.id}
                      onClick={() => handleEdit(Users?.id, "firstname")}
                    >
                      <img
                        src="/images/common/pencilEdit.svg"
                        alt="pencilEdit"
                      />
                    </Button>
                  )} */}
                                </div>
                                <hr className={`border-${currentMode}-590`} />
                            </div>
                            <div className="basis-[48%] mb-6">
                                <label htmlFor="" className="text-xs block mb-1">
                                    Last Name
                                </label>
                                <div
                                    title={editvalue?.lastname}
                                    className={`flex justify-between items-center Editable relative border-${currentMode}-690`}>
                                    <Input
                                        readOnly={fieldType === "lastname" ? false : true}
                                        name="lastname"
                                        className={`mb-2 truncate w-full bg-transparent `}
                                        value={editvalue?.lastname}
                                        onChange={(e) => handleChange(e)}
                                        placeholder="Enter the last name"
                                    />
                                    {fieldType === "lastname" && !isClose && (
                                        <EditBox
                                            handleClick={() => updateUser("lastname", editvalue?.lastname, Users?.id)}
                                            id={Users?.id}
                                            closeClick={() => closeUpdate()}
                                        />
                                    )}
                                    {/* {isClose && (
                    <Button
                      className="Edit"
                      id={Users?.id}
                      onClick={() => handleEdit(Users?.id, "lastname")}
                    >
                      <img
                        src="/images/common/pencilEdit.svg"
                        alt="pencilEdit"
                      />
                    </Button>
                  )} */}
                                </div>
                                <hr className={`border-${currentMode}-590`} />
                            </div>
                            <div className="basis-full mb-4">
                                <label htmlFor="" className="text-xs block mb-1">
                                    Email Address
                                </label>
                                <div title={decryptId(editvalue?.user_emailaddress)} className="flex justify-between items-center Editable relative">
                                    <Input
                                        readOnly={true}
                                        className={`block truncate mb-2 w-full font-interSb lowercase bg-transparent`}
                                        onChange={(e) => handleChange(e)}
                                        type="email"
                                        value={decryptId(editvalue?.user_emailaddress) || decryptId(Users?.user_emailaddress)}
                                        placeholder="example@email.com"
                                        name="user_emailaddress"
                                    />
                                    {/* {fieldType === "email" && !isClose && (
                    <EditBox
                      handleClick={() =>
                        updateUser(
                          "email",
                          editvalue?.user_emailaddress,
                          Users?.id
                        )
                      }
                      id={Users?.id}
                      closeClick={() => closeUpdate()}
                    />
                  )}
                  {isClose && (
                    <Button
                      className="Edit"
                      id={Users?.id}
                      onClick={() => handleEdit(Users?.id, "email")}
                    >
                      <img
                        src="/images/common/pencilEdit.svg"
                        alt="pencilEdit"
                      />
                    </Button>
                  )} */}
                                </div>
                                <hr className={`border-${currentMode}-590`} />
                            </div>
                            <div className="basis-[48%] mb-6">
                                <label htmlFor="" className="text-xs block mb-1">
                                    Mobile Number
                                </label>
                                <div title={editvalue?.mobilenumber} className="flex justify-between items-center Editable relative">
                                    <Input
                                        readOnly={fieldType === "mobilenumber" ? false : true}
                                        className={`block truncate mb-2 w-full font-interSb bg-transparent`}
                                        value={editvalue?.mobilenumber !== "undefined" ? editvalue?.mobilenumber : "" || ""}
                                        onChange={(e) => handleChange(e)}
                                        name="mobilenumber"
                                        maxLength="13"
                                    />
                                    {fieldType === "mobilenumber" && !isClose && (
                                        <EditBox
                                            handleClick={() => updateUser("mobilenumber", editvalue?.mobilenumber, Users?.id)}
                                            id={Users?.id}
                                            closeClick={() => closeUpdate()}
                                        />
                                    )}
                                    {/* {isClose && (
                    <Button
                      className="Edit"
                      id={Users?.id}
                      onClick={() => handleEdit(Users?.id, "mobilenumber")}
                    >
                      <img
                        src="/images/common/pencilEdit.svg"
                        alt="pencilEdit"
                      />
                    </Button>
                  )} */}
                                </div>
                                <hr className={`border-${currentMode}-590`} />
                            </div>
                            <div className="basis-[48%] mb-6">
                                <label htmlFor="" className="text-xs block mb-1">
                                    Country
                                </label>
                                <div className="flex justify-between items-center Editable relative">
                                    {fieldType === "country" && !isClose ? (
                                        <span>
                                            <select className="w-full truncate pb-2" onChange={handleChange} name="country_id">
                                                <option selected value={Users?.country?.country_id || editvalue?.country_id || "0"}>
                                                    {Users?.country?.country_name || "Select"}
                                                </option>
                                                {country?.map((elem, index) => {
                                                    return (
                                                        <option key={index} value={elem?.country_id}>
                                                            {elem?.country_name}
                                                        </option>
                                                    );
                                                })}
                                            </select>
                                            <EditBox
                                                handleClick={() => updateUser("country", editvalue?.country_id, Users?.id)}
                                                id={Users?.id}
                                                closeClick={() => closeUpdate()}
                                            />
                                        </span>
                                    ) : (
                                        <>
                                            <span className="mb-2 h-[22px] font-interSb truncs-1">{editvalue?.country?.country_name}</span>
                                            {/* {isClose && (
                        <Button
                          className="Edit"
                          id={Users?.id}
                          onClick={() => handleEdit(Users?.id, "country")}
                        >
                          <img
                            src="/images/common/pencilEdit.svg"
                            alt="pencilEdit"
                          />
                        </Button>
                      )} */}
                                        </>
                                    )}
                                </div>
                                <hr className={`border-${currentMode}-590`} />
                            </div>
                        </div>
                        <div className="flex basis-full mt-7 justify-between items-center">
                            {roleList && roleList?.length > 0 ? (
                                <ul className="flex items-center space-x-4">
                                    {roleList?.map((element, index) => {
                                        if (index <= 2) {
                                            return (
                                                <li
                                                    className={`${
                                                        currentMode !== "dark" ? "drop-shadow-[0px_4px_20px_#D7D7D7]" : ""
                                                    } cursor-pointer`}>
                                                    <LightTooltip title={<Datas id={element?.organisation_id} rolename={element?.role_name} />}>
                                                        <div onTouchStart={(e) => e.preventDefault()}>
                                                            <InSideOrg id={element?.organisation_id} />
                                                        </div>
                                                    </LightTooltip>
                                                </li>
                                            );
                                        }
                                    })}
                                    {roleList.length > 4 && roleList.length !== 5 && (
                                        <li className="cursor-pointer" onClick={handleViewOrgRole}>
                                            + {roleList.length - 5} more
                                        </li>
                                    )}
                                </ul>
                            ) : (
                                ``
                            )}
                            {editvalue?.isadmin === true ? (
                                <ul className="flex items-center space-x-4">
                                    <li className={`bg-light-490 px-2 py-1 font-interM rounded-[5px] text-black`}>Super Admin</li>
                                </ul>
                            ) : (
                                ``
                            )}
                            {editvalue?.isadmin === false && roleList?.length === 0 && (
                                <ul className="flex items-center space-x-4">
                                    <li className={`bg-180 px-2 py-1 rounded-[5px] text-black bg-[#F3CECA]`}>Organization is not allocated</li>
                                </ul>
                            )}
                            <Button
                                onClick={(e) => childCompRef.current.handleClick("bottom-end", 1, e, Users?.id)}
                                className={`px-2 userMore-step`}
                                tabIndex={0}>
                                <img src="/images/common/threeDots.svg" alt="threedots-white" />
                            </Button>
                            <TogglePopup ref={childCompRef} className="bg-blue-500" menuList={filterMenuList} selectedItemChange={onFilterItems} />
                        </div>
                    </div>
                </div>
            </Card>

            {ReactDOM.createPortal(
                <AddUserOrg isOpened={isAdd} userId={userId} handleClose={() => closeAddRoleUser()} className={isAdd && "Show"} />,
                document.getElementById("user-modal-root")
            )}
            {ReactDOM.createPortal(
                <EditUser isOpened={isEdit} userId={userId} handleClose={closeEditUser} className={isEdit && "Show"} />,
                document.getElementById("user-modal-root")
            )}
            {ReactDOM.createPortal(
                <UsersView
                    userId={userId}
                    userRoleList={roleList}
                    isOpened={isView}
                    UsersData={props?.userData}
                    handleClose={() => setIsView(false)}
                    className={isView && "Show"}
                />,
                document.getElementById("user-modal-root")
            )}
            {ReactDOM.createPortal(
                <UserActivity
                    isOpened={isUserActivity}
                    handleClose={() => setIsUserActivity(false)}
                    className={isUserActivity && "Show"}
                    userid={Users}
                />,
                document.getElementById("user-modal-root")
            )}
        </>
    );
};

export default UserCard;
