import { Link } from "react-router-dom";
import Button from "../../components/Button";
import Container from "../../components/UI/Container";
import { useNavigate } from "react-router-dom";
const OAuthError = () => {
    const navigate = useNavigate();

    const backToLogin = () => {
        navigate("/signup");
    };
    return (
        <div className="WrapCenter">
            <Container className="mt-10">
                <Link to={'//www.myiq.ai/'} target="_blank" className="mb-10 inline-block">
                    <img className="w-[121px] h-[75px]" src="/images/logo/MyiqFinal.svg" alt="logoiq" />
                </Link>
                <div className="flex justify-center items-center h-[calc(80vh_-_120px)]">
                    <div className="basis-1/2 2xl:basis-full text-center">
                        <h3 className="text-[36px] font-interSb leading-[43px] text-light-20 mb-2">
                            Sorry, Your email id was not matching in our account
                        </h3>
                        <p className="mb-10 text-sm">
                            Please click on sign-up and create your account
                        </p>

                        <Button
                            type="submit"
                            onClick={() => backToLogin()}
                            className="w-full max-w-[250px] text-lg shadow-[0px_12.1167px_24.2334px_rgba(0,179,153,0.05)] rounded-[8px] text-white bg-light-20 py-[14px]"
                        >
                            Sign Up
                        </Button>
                    </div>
                </div>
            </Container>
        </div>
    );
};

export default OAuthError;
