import { useContext } from "react";
import { useLocation } from "react-router-dom";
import { ThemeContext } from "../../../Hooks/Themes/Themeprovider";
// import { ChatBot } from "../../../organisms/ChatBot";
import { FixedUpload } from "../../../organisms/FixedUpload";

const Main = (props) => {
    const location = useLocation();
    const { currentMode } = useContext(ThemeContext);
    const checkpath = location.pathname == "/" || location.pathname == "/signup";
    return (
        <div className={`${props?.className} lg:pl-[7.188rem] relative pl-[6.5rem] pt-[6rem] pl-4 pr-[.8rem] lg:pr-[25px] main`}>
{/*             {!checkpath && <ChatBot />} */}
            <FixedUpload />
            {props?.children}
        </div>
    );
};

export default Main;
