import Button from "../../../components/Button";
import Main from "../../../components/UI/Main";
import Layout from "../../../Hoc/Layout";
import Table from "./Table";
import Footer from "../../../organisms/Footer";
import { ThemeContext } from "../../../Hooks/Themes/Themeprovider";
import { useState, useEffect, useContext, useMemo } from "react";
import { Link } from "react-router-dom";
import { getAdvanceList, getAdvanceRequestView } from "../../../redux/actions/advance-request.js";
import { postChangeWorkflowStatus } from "../../../redux/actions/workflow-automation";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentUser } from "../../../redux/actions/auth";
import * as XLSX from "xlsx";
import { GET_ADVANCE_REQUEST_VIEW_RESET, VIEW_MISC_RESET } from "../../../redux/types/types.js";
import Swal from "sweetalert2";
import { ClickAwayListener, Fade, FormControl, MenuItem, Popper, Select } from "@mui/material";
import { POST_CHANGE_WORKFLOW_STATUS_RESET } from "../../../redux/types/types.js";

const MiscellaneousPaymentList = () => {
    const dispatch = useDispatch();
    const [popstate, setPopState] = useState({ id: null, anchorEl: null });
    const { currentMode } = useContext(ThemeContext);
    const dropdownColor = currentMode === "dark" ? "#333333" : `white`;
    const dropdownText = currentMode === "dark" ? "#ECECEC" : `#555555`;
    const [Onrefresh, setOnrefresh] = useState(false);
    const [isAllSelected, setAllSelected] = useState(false);
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [filterSupplierValue, setFilterSupplierValue] = useState("0");
    const [poNumber, setPoNumber] = useState("0");
    const [invoiceType, setInvoiceType] = useState(0);
    const [searchValue, handleSearchChange] = useState("");
    let [statusOptionArray, setstatusOptionArray] = useState([]);
    const [filterStatusValue, setFilterStatusValue] = useState([2]);
    const [isdownloadClick, onClickDownload] = useState(false);
    const { data: dataObj, fetching, requestComplete, error, exportData } = useSelector((state) => state?.advanceRequestListState);
    const listData = dataObj?.data?.listData;
    const countData = dataObj?.data?.countData;
    const currentUser = getCurrentUser();
    const [excelDownload, setexcelDownload] = useState(false);
    const DisplayRole = useSelector((state) => state?.DisplayRolePermissionStateAction);
    const { data: DataList } = DisplayRole;
    const RoleList = DataList?.role_info;
    const RoleMenu = RoleList?.menu;
    const RoleMisc = RoleMenu?.find((elem) => elem?.menu_id === 22);
    const RoleActAdvCreate = RoleMisc?.actions?.filter((el) => el?.id == 1)?.[0]?.isallowed;
    const RoleActAdvreject = RoleMisc?.actions?.filter((el) => el?.id == 9)?.[0]?.isallowed;
    const RoleActAdvApprove = RoleMisc?.actions?.filter((el) => el?.id == 6)?.[0]?.isallowed;
    const {
        data: advanceStatusFilter,
        fetching: statusFilterFetch,
        requestComplete: statusFilterReqComp,
    } = useSelector((state) => state?.getAdvanceStatusListState);
    const defaultStatusId = advanceStatusFilter?.find((ele) => ele?.master_refid == 2);
    const parseId = advanceStatusFilter
        ?.filter((item) => {
            if (![3, 4, 5]?.includes(item?.master_refid)) {
                return item?.id;
            }
        })
        ?.map((item) => item?.id);
    const [user, setUser] = useState(0);
    const {
        data: datas,
        fetching: userFetch,
        requestComplete: userReqComp,
        error: userErr,
    } = useSelector((state) => state?.getUserForCommentsListState);
    const dataLists = datas?.filter((el) => el?.isactivated !== false || el?.user_isactive !== false);
    const handleClickAway = () => {
        setPopState({ id: false, anchorEl: false, value: "" });
    };
    const handleClickAwayMore = () => {
        setPopState({ id: false, anchorEl: false });
    };
    const handleUserChange = (value) => {
        setUser(value);
    };
    const [typeStatusChange, settypeStatusChange] = useState("");
    const generateExcelSlides = (datalist) => {
        const Check = JSON.parse(localStorage.getItem("MiscListOption"));
        // Create a new workbook
        const wb = XLSX.utils.book_new();
        // Prepare the data for the worksheet
        const wsData = datalist.map((item) => ({
            [Check.includes("Misc Id.") && "Misc Id."]: Check.includes("Misc Id.") && item?.sequence_no,
            [Check.includes("Vendor Name") && "Vendor Name"]: Check.includes("Vendor Name") && item?.vendor_name,
            [Check.includes("Document No.") && "Document no"]: Check.includes("Document No.") && item?.document_no,
            // [Check.includes("Reference no") && "Reference no"]: Check.includes("Reference no") && item?.reference_no,
            [Check.includes("Request Date") && "Request Date"]: Check.includes("Request Date") && item?.request_date,
            // [Check.includes("Reference date") && "Reference date"]: Check.includes("Reference date") && item?.reference_date,
            [Check.includes("Miscellaneous Amount") && "Miscellaneous Amount"]: Check.includes("Miscellaneous Amount") && item?.miscellaneous_amount,
            [Check.includes("Payable Amount") && "Payable Amount"]: Check.includes("Payable Amount") && item?.payable_amount,
            // [Check.includes("Error message") && "Error message"]: (Check.includes("Error message") && item?.error_message) || "",
            [Check.includes("Status") && "Status"]: (Check.includes("Status") && item?.status_info[0]?.status_name) || "",
        }));
        // Convert data to worksheet
        const ws = XLSX.utils.json_to_sheet(wsData);
        // Append worksheet to the workbook
        XLSX.utils.book_append_sheet(wb, ws, "Miscellaneous_Data");
        // Save the workbook as an Excel file
        XLSX.writeFile(wb, "misc_request_list.xlsx");
    };
    const orgId = localStorage.getItem("orgId") || 0;

    const onExportExcel = () => {
        const reqObj = {
            status_id: [0],
            po_id: poNumber,
            vendor_id: filterSupplierValue,
            search_text: searchValue,
            order_column: "",
            order_by: 0,
            pg_no: 1,
            rec_cnt: +countData,
            org_id: orgId,
            adv_type: 3,
            from_date: fromDate,
            to_date: toDate,
            alloc_user: user,
        };
        if (listData !== null) {
            dispatch(getAdvanceList(reqObj, "download"));
        }
        setexcelDownload(true);
    };
    useEffect(() => {
        if (exportData && exportData?.data?.listData?.length !== 0 && excelDownload) {
            generateExcelSlides(exportData?.data?.listData);
        }
    }, [exportData, excelDownload]);
    const downloadeExcel = (dataObj) => {
        const recoverObj = dataObj?.recoveryInfo;
        const DataObj = {
            "PO Number": dataObj?.po_number,
            "Vendor Name": dataObj?.vendor_name,
            "Request Date": recoverObj?.request_date,
            "Reference Number": recoverObj?.reference_no,
            "Reference Date": recoverObj?.reference_date,
            "Miscellaneous Amount": recoverObj?.adv_amount,
            "Tax Code": recoverObj?.tax_id,
            "Tax Amount": recoverObj?.tax_amount,
            Currency: recoverObj?.currency,
            "Exchange rate": recoverObj?.exec_rate,
            "GL Indicator": recoverObj?.gl_indicator,
            // "Payment Amount": paymentAmt,
        };
        const ws = XLSX.utils.json_to_sheet([DataObj]); // Wrap DataObj in an array to make it iterable
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
        XLSX.writeFile(wb, "Advance-request.xlsx");
        setTimeout(() => {
            onClickDownload(false);
            dispatch({ type: GET_ADVANCE_REQUEST_VIEW_RESET });
        }, 2000);
    };

    const downloadInvoice = (reqId) => {
        if (reqId) {
            onClickDownload(true);
            dispatch(getAdvanceRequestView(reqId, orgId));
        }
    };
    const { data: viewData, fetching: viewFetch, requestComplete: viewReq } = useSelector((state) => state?.getAdvanceRequestViewState);
    const {
        data,
        fetching: statusUpdateLoading,
        requestComplete: statusUpdateReqComp,
        error: statuUpdateErr,
    } = useSelector((state) => state?.postWorkFlowStatusChangeState);
    useEffect(() => {
        if (parseId && statusFilterReqComp) {
            setstatusOptionArray(parseId);
        }
        dispatch({ type: VIEW_MISC_RESET })
    }, []);
    useEffect(() => {
        if (statusUpdateReqComp && data?.status) {
            Swal.fire({
                text: `${typeStatusChange == "reject" ? "Rejected Successfully" : typeStatusChange == "approve" && " Approved Successfully"}`,
                icon: "success",
                closeOnClickOutside: false,
                showCloseButton: true,
                showCancelButton: true,
                buttons: true,
                dangerMode: true,
            }).then((result) => {
                if (result.isConfirmed) {
                    dispatch({ type: POST_CHANGE_WORKFLOW_STATUS_RESET });
                    window.location.reload();
                }
            });
        }
    }, [statusUpdateReqComp]);
    if (isdownloadClick && viewReq && viewData) {
        downloadeExcel(viewData?.data);
    }

    const onApproveOrReject = (process) => {
        settypeStatusChange(process);
        if (listData.length > 0) {
            let dataArr = [];
            const selectedInvoiceList = listData.filter((item) => item.checked === true);
            const checkRejectedStatus = selectedInvoiceList?.filter((item) => item?.ext_status_id == 5);
            const checkApproveStatusUpdt = selectedInvoiceList?.filter((item) => item?.status_info?.[0]?.master_refid !== 7);
            const selectedInvIds = selectedInvoiceList.map((item) => {
                return { reqId: item?.req_id, statusId: item?.status_info?.[0]?.status_id };
            });
            const statusReqObjArray = selectedInvIds.map((iten) => ({
                inv_id: iten?.reqId,
                org_id: +orgId,
                type: 1,
                groupType: 3,
                status_id: null,
                masterref_id: process === "approve" ? 3 : 5,
            }));

            if (selectedInvIds?.length == 0) {
                Swal.fire({
                    text: "Select any one Request!",
                    icon: "warning",
                    closeOnClickOutside: false,
                    showCloseButton: true,
                    button: "Ok",
                });
            }
            else if (checkApproveStatusUpdt?.length !== 0 && process === "approve") {
                Swal.fire({
                    text: `Miscellaneous requests in the For Approval status can be moved to the ${process} status.`,
                    icon: "warning",
                    closeOnClickOutside: false,
                    showCloseButton: true,
                    button: "Ok",
                });
            } else if (checkRejectedStatus?.length !== 0) {
                Swal.fire({
                    text: `Unable to ${process} the selected Request(s)!`,
                    icon: "warning",
                    closeOnClickOutside: false,
                    showCloseButton: true,
                    button: "Ok",
                });
            } else
                Swal.fire({
                    text: `Are you sure you want to ${process} selected Request(s) ?`,
                    icon: "warning",
                    closeOnClickOutside: false,
                    showCloseButton: true,
                    showCancelButton: true,
                    buttons: true,
                    dangerMode: true,
                }).then((result) => {
                    if (result.isConfirmed && process === "approve") {
                        dispatch(postChangeWorkflowStatus({ upd_status: statusReqObjArray }));
                    } else if (result.isConfirmed && process === "reject") {
                        dispatch(postChangeWorkflowStatus({ upd_status: statusReqObjArray }));
                    }
                });
        } else if (listData?.length == 0) {
            Swal.fire({
                text: "No Data found for this action!",
                icon: "warning",
                closeOnClickOutside: false,
                showCloseButton: true,
                button: "Ok",
            });
        } else {
            Swal.fire({
                text: "Please select any one Request to " + process + "!",
                icon: "warning",
                closeOnClickOutside: false,
                showCloseButton: true,
                button: "Ok",
            });
        }
    };

    return (
        <>
            <Layout>
                <Main>
                    <>
                        <div className="lg:flex justify-between mb-[10px] space-y-4 xl:space-y-0 items-center">
                            <div className="flex items-center space-x-2">
                                <h4 className={`text-2xl text-${currentMode}-copy lg:mb-0 font-interSb`}>Miscellaneous Payment Request</h4>
                            </div>

                            <div className="flex flex-wrap xl:space-x-2 md:space-x-2">
                                {currentUser?.role_id === 2 && (
                                    <div>
                                        <FormControl
                                            sx={{
                                                minWidth: "125px",
                                            }}
                                        >
                                            <Select
                                                value={user || "0"}
                                                name="UserList"
                                                onChange={(e) => handleUserChange(e.target.value)}
                                                inputProps={{
                                                    "aria-label": "Without label",
                                                }}
                                                IconComponent={() => (
                                                    <img
                                                        className="absolute opacity-50 right-[15px] pointer-events-none"
                                                        src={
                                                            currentMode !== "dark"
                                                                ? `/images/header/chevronDown.svg`
                                                                : `/images/header/chevronDown-white.svg`
                                                        }
                                                    />
                                                )}
                                                MenuProps={{
                                                    sx: {
                                                        "& .MuiMenu-paper": {
                                                            backgroundColor: dropdownColor,
                                                            color: dropdownText,
                                                        },
                                                        "& .MuiMenuItem-root:hover": {
                                                            backgroundColor: "rgba(218, 249, 244, 0.3)",
                                                        },
                                                        "& .Mui-selected": {
                                                            backgroundColor: "rgba(218, 249, 244, 0.3) !important",
                                                        },
                                                    },
                                                }}
                                                SelectDisplayProps={{
                                                    style: {
                                                        paddingTop: "9px",
                                                        paddingBottom: "9px",
                                                        color: `${currentMode !== "dark" ? `#555555` : `#ECECEC`}`,
                                                        paddingRight: "30px",
                                                        border: "1px solid rgba(85, 85, 85, 0.40)",
                                                        borderRadius: "8px",
                                                    },
                                                }}
                                            >
                                                <MenuItem key={0} name="UserList" value={0}>
                                                    {"All Users"}
                                                </MenuItem>
                                                {dataLists &&
                                                    dataLists?.map((listelem, i) => {
                                                        return (
                                                            <MenuItem key={i + 1} name="UserList" value={+listelem?.user_id}>
                                                                {listelem?.user_name}
                                                            </MenuItem>
                                                        );
                                                    })}
                                            </Select>
                                        </FormControl>
                                    </div>
                                )}

                                <Button
                                    title="To Export Miscellaneous Requests to Excel"
                                    onClick={() => onExportExcel()}
                                    className={`border-light-20 mr-2 xl:mr-0 mb-2 export-step px-[15px] py-2 rounded-[8px] font-interM text-sm flex justify-center items-center border-[1px] text-${currentMode}-copy`}
                                >
                                    <img className="mr-[.4rem]" src="/images/common/download-excel.svg" alt="upload" /> {"Download"}
                                </Button>
                                {RoleActAdvreject && (
                                    <Button
                                        title="To Reject Miscellaneous Requests"
                                        type="submit"
                                        onClick={() => onApproveOrReject("reject")}
                                        className=" mr-2 mb-2 reject-step border-[#F04438] w-[120px] bg-[#F04438] px-[15px] py-2 rounded-[8px] font-interM text-sm flex justify-center items-center border-[1px] text-white"
                                    >
                                        <img className="mr-[.4rem]" src="/images/Invoice-Status/reject-new-button.svg" alt="upload" /> Reject
                                    </Button>
                                )}
                                {RoleActAdvApprove && (
                                    <Button
                                        title="To Approve Miscellaneous Requests"
                                        type="submit"
                                        onClick={() => onApproveOrReject("approve")}
                                        className=" mb-2 border-transparent approve-step border-440 w-[120px] bg-light-440 px-7 py-2 rounded-[8px] font-interM text-sm flex justify-center items-center border-[1px] text-white"
                                    >
                                        <img className="mr-[.4rem]" src="/images/common/tickWhite.svg" alt="upload" /> Approve
                                    </Button>
                                )}
                                {RoleActAdvCreate &&
                                    <div className="flex space-x-2">
                                        <Link
                                            to="/miscellaneous-request"
                                            title="To Create New Miscellaneous Request"
                                            className={`mb-2 border-${currentMode}-800 bg-light-20 px-7 py-2 rounded-[8px] font-interM text-sm flex items-center border-[1px] text-white `}
                                        >
                                            Create Miscellaneous
                                        </Link>
                                    </div>
                                }

                            </div>
                        </div>
                        <Table
                            setFilterStatusValue={setFilterStatusValue}
                            filterStatusValue={filterStatusValue}
                            setstatusOptionArray={setstatusOptionArray}
                            statusOptionArray={statusOptionArray}
                            handleSearchChange={handleSearchChange}
                            searchValue={searchValue}
                            setInvoiceType={setInvoiceType}
                            invoiceType={invoiceType}
                            setFilterSupplierValue={setFilterSupplierValue}
                            filterSupplierValue={filterSupplierValue}
                            setOnrefresh={setOnrefresh}
                            fromDate={fromDate}
                            setToDate={setToDate}
                            toDate={toDate}
                            setFromDate={setFromDate}
                            Onrefresh={Onrefresh}
                            setAllSelected={setAllSelected}
                            isAllSelected={isAllSelected}
                            setPoNumber={setPoNumber}
                            poNumber={poNumber}
                            downloadInvoice={downloadInvoice}
                            handleClickAway={handleClickAway}
                            setPopState={setPopState}
                            popstate={popstate}
                            handleClickAwayMore={handleClickAwayMore}
                            viewFetch={viewFetch}
                            viewReq={viewReq}
                            listData={listData}
                            countData={countData}
                            user={user}
                            advanceStatusFilter={advanceStatusFilter}
                            parseId={parseId}
                            statusFilterReqComp={statusFilterReqComp}
                        />
                    </>
                </Main>
                <Footer />
            </Layout>
        </>
    );
};

export default MiscellaneousPaymentList;
