import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Button from "../../../components/Button";
import Modal from "../../../components/UI/Modal/Modal";
import { ORG_VIEW_RESET, SETTING_RESET } from "../../../redux/types/types";
import ViewOrganisationModal from "./ViewOrganisation";
import UseForm from "../../../Hooks/useForm";
import { useContext } from "react";
import { ThemeContext } from "../../../Hooks/Themes/Themeprovider";

const ViewOrganisation = (props) => {
    const OrganisationEditData = useSelector((state) => state?.organisationViewState);
    const { viewData } = OrganisationEditData;
    const dispatch = useDispatch();
    const { handleChange, handleSubmit, values, errors, setErrors, setValues, InitialState } = UseForm(viewData);
    const handleResetValue = () => {
        const btnClose = document.querySelector(".closeModal");
        btnClose.click();
        props?.handleClose();
        dispatch({ type: ORG_VIEW_RESET });
        setValues(InitialState);
    };
    const { currentMode } = useContext(ThemeContext);
    return (
        <Modal cartClose={handleResetValue} className={`${props?.className}`}>
            <span onClick={handleResetValue} className="p-[6.5px] absolute cursor-pointer right-[712px] bg-light-10">
                <img src="/images/common/whiteClose.svg" alt="whiteClose" />
            </span>
            <div
                className={`w-[712px] bg-${currentMode}-card ml-auto overflow-y-auto transition duration-150 ease-out flex-col flex h-screen max-h-[-webkit-fill-available]`}
            >
                <div className={`relative rounded-t-[10px]`}>
                    {/* <p> View Organization</p> */}
                    {/* <div className="absolute bottom-[-50%] left-[50%] translate-x-[-50%]">
                        <div
                            className={`bg-${currentMode}-card mb-2  ${
                                currentMode === "dark"
                                    ? ""
                                    : "shadow-[0px_4px_37px_#EEEEEE]"
                            }  p-2 rounded-full`}
                        >
                            <div>
                                <img
                                    className="rounded-[50%] object-cover w-[100px] h-[100px]"
                                    src={
                                        viewData?.org_logo
                                            ? `data:image;base64,${viewData?.org_logo}`
                                            : `/images/common/orgLogo.png`
                                    }
                                    alt="Profile"
                                />
                            </div>
                        </div>
                    </div> */}
                </div>
                <ViewOrganisationModal viewedData={viewData} cartClose={props?.handleClose} />
            </div>
        </Modal>
    );
};

export default ViewOrganisation;
