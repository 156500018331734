import Modal from "../../../components/UI/Modal/Modal";
import Button from "../../../components/Button";
import DynamicProfile from "../../WorkflowAutomation/dynamicProfile";
import { Diversity1Outlined } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getWorkFlowByInvoiceId } from "../../../redux/actions/workflow-automation";
import Loader from "../../../components/Loader";
import { GET_WORKFLOW_DATA_BY_INVOICE_ID_RESET } from "../../../redux/types/types.js";
import { Link, useLocation } from "react-router-dom";

const StatusInfoComponent = (props) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const { className, setStatusInfoAnchor, currentMode, invoiceId, orgId, seqNo } = props;
    const { fetching, data, requestComplete, error } = useSelector((state) => state?.getWorkflowByInvoiceIdState);
    const getData = data?.data ? data?.data[0] : null;
    const errorMessage = getData ? getData?.err_message : "";
    const modalBorder = currentMode == "dark" ? "#FFFFFF" : "#E4E4E4";
    const sortData = getData ? getData?.workflowdata : [];
    const activeIndex =
        sortData?.length > 0
            ? sortData?.findIndex((item) => item?.user_set == null) == -1
                ? sortData?.length - 1
                : sortData?.findIndex((item) => item?.user_set == null) - 1
            : null;
    const workflowData =
        sortData?.length > 0
            ? sortData?.map((item, index) => {
                  return {
                      ...item,
                      isActive: getData?.curr_msid == item?.id && index == activeIndex,
                      isPending: activeIndex < index,
                      border_color:
                          activeIndex < index
                              ? "#8C5FE9"
                              : activeIndex == index && item?.master_refid == 4
                              ? "#00B399"
                              : activeIndex == index && !errorMessage
                              ? [3, 4]?.includes(item?.master_refid)
                                  ? "#00B399"
                                  : "#F5A622"
                              : activeIndex == index && errorMessage?.length > 0
                              ? "#F04438"
                              : "#00B399",
                  };
              })
            : [];
    // const [demoStatus, setDemoStatus] = useState([]);
    const item = {
        id: 1,
        master_refid: 2,
        status_name: "Submitted",
        status_color: "#00B399",
        assigned_to: "Surendran",
        assigned_on: "10/12/2023",
    };
    const demoStatus = [
        {
            id: 1,
            master_refid: 2,
            status_color: "#00B399",
            status_name: "Submitted",
            assigned_to: "Surendran",
            assigned_on: "10/12/2023",
        },
        {
            id: 2,
            master_refid: 1,
            status_color: "#F5A622",
            status_name: "Finance",
            assigned_to: "Surendran",
            assigned_on: "10/12/2023",
        },
        {
            id: 3,
            master_refid: 1,
            status_color: "#F5A622",
            status_name: "Finance MANAGER DA NAAANU",
            assigned_to: "Surendran",
            assigned_on: "10/12/2023",
        },
        {
            id: 4,
            master_refid: 3,
            status_color: "#8C5FE9",
            status_name: "Approved",
            assigned_to: null,
            assigned_on: "",
        },
        {
            id: 5,
            master_refid: 4,
            status_color: "#8C5FE9",
            status_name: "Posted",
            assigned_to: null,
            assigned_on: "",
        },
    ];
    const statusProcess = [
        {
            id: 1,
            name: "Completed",
            colour: "#00B399",
        },
        {
            id: 2,
            name: "Waiting",
            colour: "#F5A622",
        },

        {
            id: 3,
            name: "Exception",
            colour: "#F04438",
        },

        {
            id: 4,
            name: "Pending",
            colour: "#8C5FE9",
        },
    ];
    useEffect(() => {
        dispatch(
            getWorkFlowByInvoiceId({
                orgId,
                invId: invoiceId,
                type:
                    location.pathname == "/advance-list" || location.pathname == "/reports/advance-reports"
                        ? 2
                        : location?.pathname == "/retention-release-list" || location?.pathname == "/reports/retention-reports"
                        ? 4
                        : location?.pathname == "/miscellaneous-table" || location?.pathname == "/reports/miscellaneous-reports"
                        ? 3
                        : 1,
            })
        );
    }, [dispatch]);
    const handleClose = () => {
        setStatusInfoAnchor(false);
        dispatch({ type: GET_WORKFLOW_DATA_BY_INVOICE_ID_RESET });
    };
    return (
        <Modal cartClose={handleClose} center="center" className={`${className}`}>
            <div>
                <div
                    className={`flex items-center flex-col relative mx-auto max-w-[922px] bg-${currentMode}-card text-${currentMode}-copy border-${currentMode}-700  rounded-[10px] ${
                        currentMode === "dark" ? "" : "shadow-[0px_4px_4px_0px_rgba(0,0,0,0.10),0px_4px_4px_0px_rgba(0,0,0,0.06)]"
                    }`}
                    style={{
                        border: `1px solid ${modalBorder} `,
                    }}
                >
                    <Button tabIndex="4" className="cursor-pointer right-[0] px-[20px] py-[20px]  absolute" onClick={handleClose}>
                        <img src="/images/common/closeBlk.svg" alt="closeBlk" className="convertWhite" />
                    </Button>
                    <div className="w-full border-b-[1px] pt-[30px] pb-[10px] px-[30px]">
                        <span className="block">
                            <span className="font-interSb text-[16px] text-[#00B399]">
                                {location?.pathname == "/advance-list" ||
                                location?.pathname == "/retention-release-list" ||
                                location?.pathname == "/miscellaneous-table" ||
                                location?.pathname == "/reports/advance-reports" ||
                                location?.pathname == "/reports/miscellaneous-reports" ||
                                location?.pathname == "/reports/retention-reports"
                                    ? `Request No:`
                                    : `Invoice ID:`}{" "}
                                {seqNo}
                            </span>
                        </span>
                    </div>
                    {fetching ? (
                        <Loader className="h-[calc(100vh_-_15rem)]" />
                    ) : workflowData?.length == 0 ? (
                        <>
                            <div className="flex flex-col justify-center items-center mx-auto my-5">
                                <img className="mx-auto" src="/images/common/delivery.png" alt="delivery" />
                                <h4 className="font-interSb mt-2">No Data Found</h4>
                            </div>
                        </>
                    ) : (
                        <div className="flex items-center max-w-[922px] overflow-x-auto pb-20 pt-10 px-10  ">
                            {workflowData?.map((item, index) => (
                                <>
                                    <div
                                        className={`flex flex-col  relative  max-w-[276px]  ${
                                            !item?.user_set || item?.isPending ? "min-h-[58px] min-w-[176px]" : "min-h-[78px] min-w-[235px]"
                                        } Inter-Medium rounded-[8px]
                                                         ${
                                                             currentMode === "dark"
                                                                 ? "shadow-[0px_4px_4px_0px_rgba(0,0,0,0.10),0px_4px_4px_0px_rgba(0,0,0,0.06)]"
                                                                 : "shadow-[0px_4px_4px_0px_rgba(0,0,0,0.10),0px_4px_4px_0px_rgba(0,0,0,0.06)] "
                                                         }
                                                        
                                                        `}
                                        key={2}
                                        style={{
                                            border: `3px solid ${item?.border_color}`,
                                        }}
                                    >
                                        <div className={`flex items-center ${!item?.user_set || item?.isPending ? "" : "border-b-[1px]"}`}>
                                            <DynamicProfile backgroundColour={item?.status_color} masterId={item?.master_refid} statusApi={true} />
                                            <span title={item?.status_name} className={`max-w-[100px] block truncate  cursor-pointer`}>
                                                {item?.status_name}
                                            </span>
                                        </div>
                                        {item?.user_set && !item?.isPending && (
                                            <div className="flex flex-col py-3 px-3">
                                                <span className="text-[12px] cursor-pointer block truncate">
                                                    Assigned to:{" "}
                                                    <span
                                                        className="font-interSb text-[12px]"
                                                        title={!item?.user_set?.assigned_to ? "Open to all users" : item?.user_set?.assigned_to}
                                                    >
                                                        {!item?.user_set?.assigned_to ? "Open to all users" : item?.user_set?.assigned_to}
                                                    </span>
                                                </span>
                                                <span className="text-[12px] block truncate" title={item?.user_set?.assigned_on}>
                                                    Assigned on: <span className="font-interSb text-[12px]">{item?.user_set?.assigned_on}</span>
                                                </span>
                                            </div>
                                        )}
                                    </div>
                                    {index != workflowData?.length - 1 && <img src="/images/workflow-automation/arrow.svg" alt="arrow-line" />}
                                </>
                            ))}
                        </div>
                    )}
                    <div className=" flex w-full p-3 flex-wrap gap-3 justify-center border-t-[1px]">
                        {statusProcess?.map((item) => {
                            return (
                                <div className="flex ">
                                    <div
                                        className={`w-[20px] h-[20px] items-center rounded-[50%] mr-2 `}
                                        style={{
                                            border: `3px solid ${item?.colour}`,
                                        }}
                                    />
                                    <span className="max-w-[100px] block truncate">{item?.name}</span>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </Modal>
    );
};
export default StatusInfoComponent;
