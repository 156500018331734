import { Box, Tab, Tabs } from "@mui/material";
import Swal from "sweetalert2";
import Button from "../../components/Button";
import Card from "../../components/UI/Card";
import Main from "../../components/UI/Main";
import Layout from "../../Hoc/Layout";
import PropTypes from "prop-types";
import { useState, useEffect } from "react";
import Input from "../../components/Input";
import Loader from "../../components/Loader";
import Footer from "../../organisms/Footer";
import { useSelector, useDispatch } from "react-redux";
import {
    emailconfigAdd,
    emailConfigStatusMode,
    getEmailConfig,
} from "../../redux/actions/emailconfig";
import UseForm from "../../Hooks/useForm";
import { validateEmailConfig } from "../../Hooks/validate";
import SharedConfig from "../SharedLocationConfig";
import { ADD_EMAIL_CONFIG_RESET } from "../../redux/types/types";
import { encryptId } from "../../utils/helpers";

let isEnabled = null;
function TabPanel(props) {
    const { children, value, index, ...other } = props;
    const Username = localStorage.getItem("user_name");
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            className="h-full"
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3, height: "100%" }}>{children}</Box>
            )}
        </div>
    );
}
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};
function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        "aria-controls": `vertical-tabpanel-${index}`,
    };
}
const EmailConfig = (props) => {
    const {
        handleChange,
        handleSubmit,
        values,
        InitialState,
        errors,
        setErrors,
        setValues,
    } = UseForm(submit, validateEmailConfig);
    const tabHandleChange = (event, newValue) => {
        setValue(newValue);
    };
    const [isAddNew, handleAddNew] = useState(false);
    const [isAddFolder, handleAddFolder] = useState(false);
    const [isClose, setIsClose] = useState(true);
    const [selectedNumber, setSelectedNumber] = useState(2);
    const [isLoader, setIsLoader] = useState(false);
    const [isStatus, setIsStatus] = useState(false);
    const [value, setValue] = useState(0);
    const [success, sentMail] = useState(false);
    const onChangeMail = (option) => {
        setSelectedNumber(option);
    };
    const TabWithCount = ({ children }) => {
        return <div className="text-left py-[15px] pl-3">{children}</div>;
    };
    //LIST
    const { configData } = useSelector((state) => state?.emailConfigState);
    const dispatch = useDispatch();
    //ADD

    let responseMail = useSelector((state) => state?.emailAddConfigState);
    if (responseMail?.fetching) {
        Swal.fire({
            text: "Activation Link sent to your Email,Please authenticate to activate the configure",
            icon: "success",
            closeOnClickOutside: false,
            showCloseButton: true,
            button: "Ok",
        }).then((confirm) => {
            if (confirm) {
                setIsClose(true);
                handleAddNew(false);
                window.location.reload();
                responseMail.fetching = false;
            }
        });
    }

    function submit() {
        const isExist =
            configData &&
            configData.find((item) => {
                return item.emailaddress === values?.emailaddress;
            });
        if (isExist == undefined) {
            dispatch(
                emailconfigAdd({
                    emailtype: selectedNumber,
                    emailaddress: encryptId(values?.emailaddress),
                })
            );

            if (Object.keys(errors).length !== 0) {
                setIsClose(false);
            }
        } else {
            Swal.fire({
                text: "Email already exists!",
                showCloseButton: true,
                icon: "warning",
                closeOnClickOutside: false,
                button: "Ok",
            });
        }
    }
    const handleCancel = () => {
        handleAddNew(false);
    };

    //ACTIVE-IN-ACTIVE
    const response = useSelector((state) => state?.emailConfigStatusState);
    const handleEnableClick = (value, id) => {
        isEnabled = value;
        dispatch(
            emailConfigStatusMode({
                id: id,
                isactive: value,
            })
        );
    };
    if (response.requestComplete && response.data?.status) {
        response.requestComplete = false;
        {
            Swal.fire("Are you sure ,you want to change the status?", {
                showCancelButton: true,
                closeOnClickOutside: false,
                showCloseButton: true,
                buttons: {
                    cancel: "Cancel",
                    confirm: "Confirm",
                },
            }).then((confirm) => {
                if (confirm?.isConfirmed) {
                    Swal.fire({
                        text: "Status is updated successfully!",
                        icon: "success",
                        closeOnClickOutside: false,
                        showCloseButton: true,
                        button: "Ok",
                    }).then((confirm) => {
                        if (confirm) {
                            dispatch(getEmailConfig());
                        }
                    });
                }
            });
        }
    }
    useEffect(() => {
        dispatch(getEmailConfig());
    }, [dispatch, selectedNumber, response.requestComplete, isStatus]);
    return (
        <Layout>
            <Main>
                <>
                    {value === 0 && (
                        <div className="flex justify-between items-center mb-4">
                            <h4 className="text-2xl font-interSb">
                                {" "}
                                Source Configuration
                            </h4>
                            <div className="flex space-x-5">
                                <Button
                                    onClick={() => handleAddNew(true)}
                                    className="border-20 bg-light-20 px-5 py-2 rounded-[8px] font-interM text-sm flex items-center border-[1px] text-white"
                                >
                                    <img
                                        className="mr-1"
                                        src="/images/common/plus.svg"
                                        alt="plus"
                                    />{" "}
                                    Add Source
                                </Button>
                            </div>
                        </div>
                    )}
                    {value === 1 && (
                        <div className="flex justify-between items-center mb-4">
                            <h4 className="text-2xl font-interSb">
                                Configuration
                            </h4>
                            <div className="flex space-x-5">
                                <Button
                                    onClick={() => handleAddFolder(true)}
                                    className="border-20 bg-light-20 px-5 py-2 rounded-[8px] font-interM text-sm flex items-center border-[1px] text-white"
                                >
                                    <img
                                        className="mr-1"
                                        src="/images/common/plus.svg"
                                        alt="plus"
                                    />{" "}
                                    Add Source
                                </Button>
                            </div>
                        </div>
                    )}
                    <Card className="h-[670px] overflow-y-auto border-[1px] shadow-[0px_4px_20px_rgba(238,238,238,0.5)] border-[rgba(223,225,226,0.56)]">
                        <div className="lg:flex h-full">
                            <div className="basis-1/4">
                                <Tabs
                                    orientation="vertical"
                                    variant="scrollable"
                                    value={value}
                                    className="configTab"
                                    onChange={tabHandleChange}
                                    aria-label="Vertical tabs"
                                    sx={{
                                        borderRight: 1,
                                        borderColor: "divider",
                                    }}
                                >
                                    <Tab
                                        title="Email Configuration"
                                        iconPosition="start"
                                        sx={{ maxWidth: "100%" }}
                                        label={
                                            <TabWithCount count="0">
                                                <div className="bg-light-370 mb-4 w-[40px] h-[40px] rounded-full grid place-items-center">
                                                    <img
                                                        src="/images/common/messageIcon.svg"
                                                        alt="messageIcon"
                                                    />
                                                </div>
                                                <h4 className="font-interSb normal-case mb-1">
                                                    Email Configuration
                                                </h4>
                                                <p className="text-xs font-interR normal-case">
                                                    Configure emails, Host name,
                                                    Port, Default Sender Id,
                                                    Protocol, Security Mode,
                                                    Key, Value.
                                                </p>
                                            </TabWithCount>
                                        }
                                    />
                                    <Tab
                                        title="Shared Location Configuration"
                                        iconPosition="start"
                                        sx={{ maxWidth: "100%" }}
                                        label={
                                            <TabWithCount count="1">
                                                <div className="bg-light-380 mb-4 w-[40px] h-[40px] rounded-full grid place-items-center">
                                                    <img
                                                        src="/images/common/fileicon.svg"
                                                        alt="fileicon"
                                                    />
                                                </div>
                                                <h4 className="font-interSb normal-case mb-1">
                                                    Shared Location
                                                    Configuration
                                                </h4>
                                                <p className="text-xs font-interR normal-case">
                                                    This document provides
                                                    common data sharing and
                                                    collaboration scenarios.
                                                </p>
                                            </TabWithCount>
                                        }
                                    />
                                </Tabs>
                            </div>
                            <div className="basis-3/4">
                                {isLoader ? (
                                    <div>
                                        <Loader className="h-[calc(100vh_-_15rem)]">
                                            <div className="text-center max-w-[430px]">
                                                <h5 className="font-interM mb-2 text-2xl font-sm">
                                                    Custom email connection
                                                    successful!
                                                </h5>
                                                <p className="font-interR">
                                                    A email has been sent to
                                                    your admin. Click the link
                                                    and add key then your
                                                    account will activate.
                                                </p>
                                            </div>
                                        </Loader>
                                    </div>
                                ) : isStatus ? (
                                    <div className="text-center relative flex flex-col justify-center items-center h-full">
                                        <Button
                                            className="absolute top-[10px] right-[10px]"
                                            onClick={() => setIsStatus(false)}
                                        >
                                            <img
                                                src="/images/common/iconblkclose.svg"
                                                alt="iconblkclose"
                                            />
                                        </Button>
                                        <h4 className="text-3xl text-light-20 mb-5">
                                            Congratulations Jessica
                                        </h4>
                                        <p className="text-base">
                                            Your email is{" "}
                                            {isEnabled
                                                ? "Activated"
                                                : "Deactivated"}
                                        </p>
                                    </div>
                                ) : (
                                    <>
                                        <TabPanel value={value} index={0}>
                                            <>
                                                <span className="text-sm font-interM mb-5 block">
                                                    Create a customer email
                                                    account
                                                </span>

                                                <form onSubmit={handleSubmit}>
                                                    <div className="flex space-x-4 mb-7">
                                                        {/* <Button onClick={() => onChangeMail(1)} className={`${"border-[rgba(85,85,85,0.1)] px-5 py-2 rounded-[8px] font-interSb text-sm flex items-center border-[1px] text-light-40"} ${selectedNumber == 1 ? "bg-light-390" : ''}`}>
                                <img
                                  className="mr-2"
                                  src="/images/common/googleIcon.svg"
                                  alt="plus"
                                />
                                Google
                              </Button> */}
                                                        <Button
                                                            type="button"
                                                            onClick={() =>
                                                                onChangeMail(2)
                                                            }
                                                            className={`${"border620 px-5 py-2 rounded-[8px] font-interSb text-sm flex items-center border-[1px] text-light-40"} ${selectedNumber ==
                                                                2
                                                                ? "bg-light-390"
                                                                : ""
                                                                }`}
                                                        >
                                                            <img
                                                                className="mr-2"
                                                                src="/images/common/microsoftIcon.svg"
                                                                alt="plus"
                                                            />{" "}
                                                            Microsoft
                                                        </Button>
                                                    </div>
                                                    {isAddNew && isClose && (
                                                        <div className="max-w-[500px]">
                                                            <div className="mb-4">
                                                                <Input
                                                                    placeholder="jessica@gmail.com"
                                                                    value={
                                                                        values.emailaddress
                                                                    }
                                                                    onChange={
                                                                        handleChange
                                                                    }
                                                                    name="emailaddress"
                                                                    className="w-full py-3 rounded-[8px] px-5  border-[1px] border620"
                                                                />
                                                                {errors && (
                                                                    <p className="text-light-50 text-xs">
                                                                        {
                                                                            errors?.emailaddress
                                                                        }
                                                                    </p>
                                                                )}
                                                            </div>
                                                            <div className="flex justify-end space-x-5 mb-7">
                                                                <Button
                                                                    type="button"
                                                                    onClick={() =>
                                                                        handleCancel()
                                                                    }
                                                                    className="bg-light-240 flex text-base font-interR py-[.5rem] px-[2.24rem] rounded-[8px] text-light-40"
                                                                >
                                                                    Cancel
                                                                </Button>
                                                                <Button
                                                                    type="submit"
                                                                    className="border-20 bg-light-20 px-5 py-2 rounded-[8px] font-interM text-sm flex items-center border-[1px] text-white"
                                                                >
                                                                    {"Save"}
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    )}
                                                </form>
                                            </>

                                            {/* LIST*/}
                                            <div className="flex max-w-[800px] overflow-y-auto flex-col space-y-5">
                                                {configData?.map((elem) => {
                                                    return (
                                                        <div className="rounded-[10px] flex justify-between items-center pl-7 pr-4 py-4 border-[rgba(223,225,226,0.56)] border-[1px] bg-white shadow-[0px_4px_20px_rgba(238,238,238,0.5)]">
                                                            <div key={elem?.id}>
                                                                <span className="font-interM flex mb-2">
                                                                    <img
                                                                        className="mr-2"
                                                                        src={
                                                                            elem
                                                                                ?.emailtype
                                                                                ?.emailtype_id ===
                                                                                1
                                                                                ? `/images/common/googleIcon.svg`
                                                                                : `/images/common/microsoftIcon.svg`
                                                                        }
                                                                        alt="googleIcon"
                                                                    />
                                                                    {
                                                                        elem?.emailaddress
                                                                    }
                                                                </span>
                                                                {/* <small className="block text-sm text620">
                            Last email read an hour ago.
                          </small> */}
                                                            </div>
                                                            <form className="flex space-x-3">
                                                                <Button
                                                                    title="Enable"
                                                                    type="button"
                                                                    onClick={() =>
                                                                        handleEnableClick(
                                                                            true,
                                                                            elem?.id
                                                                        )
                                                                    }
                                                                    disabled={
                                                                        elem.isactive
                                                                    }
                                                                    className={`${" border-[1px]  font-interM border-400 text-sm rounded-[5px] px-[6.4px] py-[4px]"} ${elem.isactive ===
                                                                        true
                                                                        ? "opacity-100 text-light-40 bg-light-400"
                                                                        : ""
                                                                        }`}
                                                                >
                                                                    Enable
                                                                </Button>
                                                                <Button
                                                                    title="Disable"
                                                                    type="button"
                                                                    onClick={() =>
                                                                        handleEnableClick(
                                                                            false,
                                                                            elem?.id
                                                                        )
                                                                    }
                                                                    disabled={
                                                                        !elem.isactive
                                                                            ? true
                                                                            : false
                                                                    }
                                                                    className={`${"text-sm border-400 text-sm font-interM border-[1px] rounded-[5px] px-[6.4px] py-[4px]"} ${elem.isactive ===
                                                                        false
                                                                        ? "opacity-100 text-light-40 bg-light-400"
                                                                        : ""
                                                                        }`}
                                                                >
                                                                    Disable
                                                                </Button>
                                                            </form>
                                                        </div>
                                                    );
                                                })}
                                                {/* <div className="rounded-[10px] flex justify-between items-center pl-7 pr-4 py-4 border-[rgba(223,225,226,0.56)] border-[1px] bg-white shadow-[0px_4px_20px_rgba(238,238,238,0.5)]">
                    <div>
                      <span className="font-interM flex mb-2">
                        <img
                          className="mr-2"
                          src="/images/common/microsoftIcon.svg"
                          alt="googleIcon"
                        />
                        endrikrovan.accounts@gmail.com
                      </span>
                      <small className="block text-sm text620">
                        Last email read an hour ago.
                      </small>
                    </div>
                    <div className="flex space-x-3">
                      <Button className="bg-light-400 opacity-50 border-[1px]  font-interM border-400 text-sm rounded-[5px] px-[6.4px] py-[4px]">
                        Enable
                      </Button>
                      <Button className="text-sm border-400 text-sm font-interM border-[1px] rounded-[5px] bg-light-400 px-[6.4px] py-[4px]">
                        Disable
                      </Button>
                    </div>
                  </div> */}
                                            </div>
                                        </TabPanel>
                                        <TabPanel value={value} index={1}>
                                            <SharedConfig
                                                setIsLoader={setIsLoader}
                                                setIsClose={setIsClose}
                                                setIsStatus={setIsStatus}
                                                isAddFolder={isAddFolder}
                                                handleAddFolder={
                                                    handleAddFolder
                                                }
                                                isClose={isClose}
                                            />
                                        </TabPanel>
                                    </>
                                )}
                            </div>
                        </div>
                    </Card>
                </>
            </Main>
            <Footer />
        </Layout>
    );
};

export default EmailConfig;
