import { useContext } from "react";
import { ThemeContext } from "../../../Hooks/Themes/Themeprovider";
import styles from "./index.module.scss";

const ModalOverlay = (props) => {
    const { currentMode } = useContext(ThemeContext);
    return (
        <div
            className={`${styles.Modal} text-${currentMode}-copy ${props?.highOverlay ? `!z-[14]` : ``} ${props?.upperLay ? `z-[1000]` : ``} ${props?.overlap ? `z-[999] ` : ``
                } ${styles[props?.className]} ${styles[props?.autowidth]} ${styles[props?.center]} ${styles[props?.bottom]}`}
        >
            {props.children}
        </div>
    );
};

export default ModalOverlay;
