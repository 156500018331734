import React from "react";
import XLSX from "sheetjs-style";
import FileSaver from "file-saver";
const exportToExcel = async(excelData, fileName, fileType, fileExtension)=>{
    const ws = XLSX.utils.json_to_sheet(excelData);
    const wb = {Sheets:{'Invoice':ws},SheetNames:['Invoice']};
    // ws["A1"].t = {
    //     fill: {
    //       fgColor: { rgb: "FFA3F4B1" } //add background color
    //     }
    //   };
    const excelBuffer = XLSX.write(wb, {bookType:'xlsx', type:'array'});
    const data = new Blob([excelBuffer], {type:fileType});
    FileSaver.saveAs(data,fileName+fileExtension);
 }
const ExportExcel = ({excelData, fileName}) => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    exportToExcel(excelData,fileName, fileType, fileExtension)
};
export default ExportExcel