import {
    Input,
    MenuItem,
    Paper,
    Popper,
    TextField,
    Autocomplete,
    Chip,
} from "@mui/material";
import { useRef } from "react";
import { useContext, useState } from "react";
import { ThemeContext } from "../../Hooks/Themes/Themeprovider";

const MailChipComponent = (props) => {
    const { currentMode } = useContext(ThemeContext);
    const [error, setError] = useState(false);

    const [keyerror, setKeyError] = useState(false);
    const regex =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const redColor = "#ff0000";
    const whiteColor = "#ffffff";
    const inputRef = useRef(null);

    const handlePaste = (event) => {
        event.preventDefault();
        const pastedText = event.clipboardData.getData("text/plain");
        let email = props?.email;
        let input = " ";
        let errorMail = [];
        const newVal = pastedText?.split(";");
        newVal?.map((item) => {
            if (regex.test(item)) {
                if (!email?.includes(item)) {
                    email?.push(item);
                }
            } else {
                if (!errorMail?.includes(item)) {
                    errorMail?.push(item);
                }
            }
        });
        input = errorMail?.length > 0 ? errorMail?.join(";") : "";
        props?.updateTo(input, email, props?.id);
        if (inputRef.current) {
            inputRef.current.focus();
        }
    };

    return (
        // props?.overallOptions != undefined &&
        props?.email != undefined && (
            <>
                <Autocomplete
                    multiple
                    id={props?.id}
                    sx={{
                        "&.MuiAutocomplete-root .MuiInputBase-root": {
                            border: 0,
                            marginLeft: "20px",
                            overflow: "hidden",
                        },
                        ".MuiInputBase-input": {
                            color: `${
                                currentMode == "dark" ? "white" : "black"
                            } !important`,
                        },
                        "&.MuiFormHelperText-root .MuiFormHelperText-root": {
                            marginLeft: "35px",
                        },
                        "& .MuiChip-label": {
                            color: `${
                                currentMode == "dark" ? "white" : "black"
                            }`,
                        },
                    }}
                    style={{ width: "800px" }}
                    options={
                        props?.overallOptions == undefined
                            ? []
                            : props?.overallOptions?.filter(
                                  (item) => !props?.email.includes(item)
                              )
                    }
                    value={props?.email}
                    inputValue={props?.inputValue}
                    disableClearable
                    onInputChange={(e, newValue) => {
                        props?.updateInput(newValue, props?.id);
                        setError(false);
                    }}
                    freeSolo
                    limitTags={2}
                    onChange={(e, newval, reason) => {
                        const errorEmail = newval.find(
                            (email) => !regex.test(email)
                        );
                        let input = "";
                        if (errorEmail) {
                            setError(true);
                            input = errorEmail;
                            //errorEmailArr.push(errorEmail);
                        }
                        props?.updateTo(
                            input,
                            newval.filter((email) => regex.test(email)),
                            props?.id
                        );
                        // setCcEmail(newval.filter((email) => regex.test(email)));
                    }}
                    renderTags={(value, getTagProps) =>
                        value.length > 0 &&
                        value?.map((option, index) => {
                            return (
                                <Chip
                                    key={index}
                                    variant="outlined"
                                    disable={false}
                                    label={option}
                                    {...getTagProps({ index })}
                                    onDelete={() => {
                                        //  setCcEmail(ccEmail.filter((e) => e !== option));
                                        const email = props?.updateTo(
                                            props?.inputValue,
                                            props?.email?.filter(
                                                (e) => e !== option
                                            ),
                                            props?.id
                                        );
                                    }}
                                />
                            );
                        })
                    }
                    renderInput={(params) => (
                        <>
                            <TextField
                                fullWidth
                                {...params}
                                // onPaste={handlePaste}
                                inputRef={inputRef}
                                onKeyDown={(e) => {
                                    if (
                                        e.key === ";" &&
                                        e.target.value.trim()
                                    ) {
                                        let input = "";
                                        let email = props?.email;
                                        if (regex.test(e.target.value)) {
                                            email.push(e.target.value);
                                            setKeyError(false);
                                        } else {
                                            input = e.target.value;
                                            setKeyError(true);
                                        }
                                        props?.updateTo(
                                            input,
                                            email,
                                            props?.id
                                        );
                                    } else {
                                        setKeyError(false);
                                    }
                                }}
                            />
                            <p className="ml-[37px] text-[red] text-xs">
                                {" "}
                                {(error || props?.error || keyerror) &&
                                    "Enter a valid email address"}
                            </p>
                        </>
                    )}
                />
            </>
        )
    );
};

export default MailChipComponent;
