import {
    ADD_SERVER_CONFIG_FAILURE,
    ADD_SERVER_CONFIG_REQUEST,
    ADD_SERVER_CONFIG_RESET,
    ADD_SERVER_CONFIG_SUCCESS,
    SERVER_TARGET_TYPE_FAILURE,
    SERVER_TARGET_TYPE_REQUEST,
    SERVER_TARGET_TYPE_RESET,
    SERVER_TARGET_TYPE_SUCCESS,
    TEST_SERVER_CONFIG_FAILURE,
    TEST_SERVER_CONFIG_REQUEST,
    TEST_SERVER_CONFIG_RESET,
    TEST_SERVER_CONFIG_SUCCESS,
    VIEW_SERVER_CONFIG_FAILURE,
    VIEW_SERVER_CONFIG_REQUEST,
    VIEW_SERVER_CONFIG_RESET,
    VIEW_SERVER_CONFIG_SUCCESS,
} from "../types/types";

//serverAdd
const serverConfigAddState = {
    profile: [],
    fetching: false,
    requestComplete: false,
    error: "",
};
export function serverConfigAdd(state = serverConfigAddState, action) {
    switch (action.type) {
        case ADD_SERVER_CONFIG_REQUEST:
            return {
                ...state,
                fetching: true,
                requestComplete: false,
                error: "",
            };
        case ADD_SERVER_CONFIG_SUCCESS:
            return {
                profile: action.payload,
                fetching: false,
                requestComplete: true,
                error: "",
            };
        case ADD_SERVER_CONFIG_RESET:
            return {
                profile: {},
                fetching: false,
                requestComplete: false,
                error: "",
            };
        case ADD_SERVER_CONFIG_FAILURE:
            return {
                fetching: false,
                requestComplete: false,
                error: action.payload,
            };
        default:
            return state;
    }
}

//SERVER-View
const serverAuthViewState = {
    data: [],
    fetching: false,
    requestComplete: false,
    error: "",
};
export function serverConfigView(state = serverAuthViewState, action) {
    switch (action.type) {
        case VIEW_SERVER_CONFIG_REQUEST:
            return {
                ...state,
                fetching: true,
                requestComplete: false,
                error: "",
            };
        case VIEW_SERVER_CONFIG_SUCCESS:
            return {
                data: action.payload,
                fetching: false,
                requestComplete: true,
                error: "",
            };
        case VIEW_SERVER_CONFIG_RESET:
            return {
                data: [],
                fetching: false,
                requestComplete: false,
                error: "",
            };
        case VIEW_SERVER_CONFIG_FAILURE:
            return {
                fetching: false,
                requestComplete: false,
                error: action.payload,
            };
        default:
            return state;
    }
}

const serverTargetTypeState = {
    data: [],
    fetching: false,
    requestComplete: false,
    error: "",
};
export function serverTargetType(state = serverTargetTypeState, action) {
    switch (action.type) {
        case SERVER_TARGET_TYPE_REQUEST:
            return {
                ...state,
                fetching: true,
                requestComplete: false,
                error: "",
            };
        case SERVER_TARGET_TYPE_SUCCESS:
            return {
                data: action.payload,
                fetching: false,
                requestComplete: true,
                error: "",
            };
        case SERVER_TARGET_TYPE_RESET:
            return {
                data: [],
                fetching: false,
                requestComplete: false,
                error: "",
            };
        case SERVER_TARGET_TYPE_FAILURE:
            return {
                fetching: false,
                requestComplete: false,
                error: action.payload,
            };
        default:
            return state;
    }
}
//server-test

const serverConfigTestState = {
    data: [],
    fetching: false,
    requestComplete: false,
    error: "",
};
export function serverConfigTest(state = serverConfigTestState, action) {
    switch (action.type) {
        case TEST_SERVER_CONFIG_REQUEST:
            return {
                ...state,
                fetching: true,
                requestComplete: false,
                error: "",
            };
        case TEST_SERVER_CONFIG_SUCCESS:
            return {
                data: action.payload,
                fetching: false,
                requestComplete: true,
                error: "",
            };
        case TEST_SERVER_CONFIG_RESET:
            return {
                data: [],
                fetching: false,
                requestComplete: false,
                error: "",
            };
        case TEST_SERVER_CONFIG_FAILURE:
            return {
                data: action.payload,
                fetching: false,
                requestComplete: true,
                error: action.payload,
            };
        default:
            return state;
    }
}
