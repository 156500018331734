import {
    CATEGORY_FAILURE,
    CATEGORY_REQUEST,
    CATEGORY_SUCCESS,
    CATEGORY_RESET,
    DASHBORAD_VENDOR_REQUEST,
    DASHBORAD_VENDOR_SUCCESS,
    DASHBORAD_VENDOR_FAILURE,
    DASHBORAD_VENDOR_RESET,
    DASHBORAD_DATE_REQUEST,
    DASHBORAD_DATE_SUCCESS,
    DASHBORAD_DATE_RESET,
    DASHBORAD_DATE_FAILURE,
    DASHBORAD_VENDOR_LIST_REQUEST,
    DASHBORAD_VENDOR_LIST_SUCCESS,
    DASHBORAD_VENDOR_LIST_FAILURE,
    GET_TASK_DASHBOARD_REQUEST,
    GET_TASK_DASHBOARD_SUCCESS,
    GET_TASK_DASHBOARD_RESET,
    GET_TASK_DASHBOARD_FAILURE,
    GET_ON_TIME_PROCESS_FAILURE,
    GET_ON_TIME_PROCESS_REQUEST,
    GET_ON_TIME_PROCESS_SUCCESS,
    GET_ON_TIME_PROCESS_RESET,
    POST_ON_TIME_PROCESS_FAILURE,
    POST_ON_TIME_PROCESS_REQUEST,
    POST_ON_TIME_PROCESS_SUCCESS,
    POST_ON_TIME_PROCESS_RESET,
    GET_TIME_FOR_TIMEPROCESSING_BYORG_REQUEST,
    GET_TIME_FOR_TIMEPROCESSING_BYORG_SUCCESS,
    GET_TIME_FOR_TIMEPROCESSING_BYORG_RESET,
    GET_TIME_FOR_TIMEPROCESSING_BYORG_FAILURE,
} from "../types/types.js";

const DashboardCategoryState = {
    data: [],
    overall: {},
    fetching: false,
    requestComplete: false,
    error: "",
};
export function DashboardState(state = DashboardCategoryState, action) {
    switch (action.type) {
        case CATEGORY_REQUEST:
            return {
                ...state,
                fetching: true,
                requestComplete: false,
                error: "",
            };
        case CATEGORY_SUCCESS: {
            let store = { [action.doctype]: action.payload.datalist };
            return {
                data: action.payload,
                fetching: false,
                overall: { ...state.overall, ...store },
                requestComplete: true,
                error: "",
            };
        }
        case CATEGORY_RESET:
            return {
                data: [],
                fetching: false,
                requestComplete: false,
                error: "",
            };
        case CATEGORY_FAILURE:
            return {
                fetching: false,
                requestComplete: false,
                error: action.payload,
            };
        default:
            return state;
    }
}

const DashboardvendorState = {
    data: [],
    fetching: false,
    requestComplete: false,
    error: "",
};
export function DashboardVendorStatus(state = DashboardvendorState, action) {
    switch (action.type) {
        case DASHBORAD_VENDOR_REQUEST:
            return {
                ...state,
                fetching: true,
                requestComplete: false,
                error: "",
            };
        case DASHBORAD_VENDOR_SUCCESS:
            return {
                data: action.payload,
                fetching: false,
                requestComplete: true,
                error: "",
            };
        case DASHBORAD_VENDOR_RESET:
            return {
                data: [],
                fetching: false,
                requestComplete: false,
                error: "",
            };
        case DASHBORAD_VENDOR_FAILURE:
            return {
                fetching: false,
                requestComplete: false,
                error: action.payload,
            };
        default:
            return state;
    }
}
const DashboarddateState = {
    data: [],
    fetching: false,
    requestComplete: false,
    error: "",
};
export function DashboardDateStatus(state = DashboarddateState, action) {
    switch (action.type) {
        case DASHBORAD_DATE_REQUEST:
            return {
                ...state,
                fetching: true,
                requestComplete: false,
                error: "",
            };
        case DASHBORAD_DATE_SUCCESS:
            return {
                data: action.payload,
                fetching: false,
                requestComplete: true,
                error: "",
            };
        case DASHBORAD_DATE_RESET:
            return {
                data: [],
                fetching: false,
                requestComplete: false,
                error: "",
            };
        case DASHBORAD_DATE_FAILURE:
            return {
                fetching: false,
                requestComplete: false,
                error: action.payload,
            };
        default:
            return state;
    }
}

const DashboardVendorListState = {
    data: [],
    fetching: false,
    requestComplete: false,
    error: "",
};

export function DashboardVendorList(state = DashboardVendorListState, action) {
    switch (action.type) {
        case DASHBORAD_VENDOR_LIST_REQUEST:
            return {
                ...state,
                fetching: true,
                requestComplete: false,
                error: "",
            };
        case DASHBORAD_VENDOR_LIST_SUCCESS:
            return {
                data: action.payload,
                fetching: false,
                requestComplete: true,
                error: "",
            };
        case DASHBORAD_VENDOR_LIST_FAILURE:
            return {
                fetching: false,
                requestComplete: false,
                error: action.payload,
            };
        default:
            return state;
    }
}

//
const DashboardTaskState = {
    data: [],
    fetching: false,
    requestComplete: false,
    error: "",
};
export function DashboardTaskList(state = DashboardTaskState, action) {
    switch (action.type) {
        case GET_TASK_DASHBOARD_REQUEST:
            return {
                ...state,
                fetching: true,
                requestComplete: false,
                error: "",
            };
        case GET_TASK_DASHBOARD_SUCCESS:
            return {
                data: action.payload,
                fetching: false,
                requestComplete: true,
                error: "",
            };
        case GET_TASK_DASHBOARD_RESET:
            return {
                data: [],
                fetching: false,
                requestComplete: false,
                error: "",
            };
        case GET_TASK_DASHBOARD_FAILURE:
            return {
                fetching: false,
                requestComplete: false,
                error: action.payload,
            };
        default:
            return state;
    }
}

const OnTimeProcessState = {
    data: [],
    fetching: false,
    requestComplete: false,
    error: "",
};
export function OnTimeProcessReducer(state = OnTimeProcessState, action) {
    switch (action.type) {
        case GET_ON_TIME_PROCESS_REQUEST:
            return {
                ...state,
                fetching: true,
                requestComplete: false,
                error: "",
            };
        case GET_ON_TIME_PROCESS_SUCCESS:
            return {
                data: action.payload,
                fetching: false,
                requestComplete: true,
                error: "",
            };
        case GET_ON_TIME_PROCESS_RESET:
            return {
                data: [],
                fetching: false,
                requestComplete: false,
                error: "",
            };
        case GET_ON_TIME_PROCESS_FAILURE:
            return {
                fetching: false,
                requestComplete: false,
                error: action.payload,
            };
        default:
            return state;
    }
}

const ValidTimeProcessState = {
    data: [],
    fetching: false,
    requestComplete: false,
    error: "",
};
export function ValidTimeReducer(state = ValidTimeProcessState, action) {
    switch (action.type) {
        case POST_ON_TIME_PROCESS_REQUEST:
            return {
                ...state,
                fetching: true,
                requestComplete: false,
                error: "",
            };
        case POST_ON_TIME_PROCESS_SUCCESS:
            return {
                data: action.payload,
                fetching: false,
                requestComplete: true,
                error: "",
            };
        case POST_ON_TIME_PROCESS_RESET:
            return {
                data: [],
                fetching: false,
                requestComplete: false,
                error: "",
            };
        case POST_ON_TIME_PROCESS_FAILURE:
            return {
                fetching: false,
                requestComplete: false,
                error: action.payload,
            };
        default:
            return state;
    }
}

const getTimeProcessTimeState = {
    data: [],
    fetching: false,
    requestComplete: false,
    error: "",
};
export function getTimeForOnTimeProcessing(state = getTimeProcessTimeState, action) {
    switch (action.type) {
        case GET_TIME_FOR_TIMEPROCESSING_BYORG_REQUEST:
            return {
                ...state,
                fetching: true,
                requestComplete: false,
                error: "",
            };
        case GET_TIME_FOR_TIMEPROCESSING_BYORG_SUCCESS:
            return {
                data: action.payload,
                fetching: false,
                requestComplete: true,
                error: "",
            };
        case GET_TIME_FOR_TIMEPROCESSING_BYORG_RESET:
            return {
                data: [],
                fetching: false,
                requestComplete: false,
                error: "",
            };
        case GET_TIME_FOR_TIMEPROCESSING_BYORG_FAILURE:
            return {
                fetching: false,
                requestComplete: false,
                error: action.payload,
            };
        default:
            return state;
    }
}
