import axios from "axios";
import authHeader from "./auth-header";
import { checkApiStatus } from "../../utils/helpers";
import {
    GET_ADVANCE_DETAILS_FOR_CREATING_REQUEST,
    GET_ADVANCE_DETAILS_FOR_CREATING_SUCCESS,
    GET_ADVANCE_DETAILS_FOR_CREATING_FAILURE,
    GET_ADVANCE_HISTORY_LIST_REQUEST,
    GET_ADVANCE_HISTORY_LIST_SUCCESS,
    GET_ADVANCE_HISTORY_LIST_FAILURE,
    GET_DOCUMENT_FROM_S3_FOR_ADVANCE_REQUEST,
    GET_DOCUMENT_FROM_S3_FOR_ADVANCE_SUCCESS,
    GET_DOCUMENT_FROM_S3_FOR_ADVANCE_FAILURE,
    ADD_UPDATE_ADVANCE_TYPE_REQUEST,
    ADD_UPDATE_ADVANCE_TYPE_SUCCESS,
    ADD_UPDATE_ADVANCE_TYPE_FAILURE,
    ADD_UPDATE_COMMENTS_REQUEST,
    ADD_UPDATE_COMMENTS_SUCCESS,
    ADD_UPDATE_COMMENTS_FAILURE,
    GET_ADVANCE_STATUS_LIST_REQUEST,
    GET_ADVANCE_STATUS_LIST_SUCCESS,
    GET_ADVANCE_STATUS_LIST_FAILURE,
    GET_PROFORMA_INVOICE_VIEW_REQUEST,
    GET_PROFORMA_INVOICE_VIEW_SUCCESS,
    GET_PROFORMA_INVOICE_VIEW_FAILURE,
    GET_ADVANCE_REQUEST_VIEW_REQUEST,
    GET_ADVANCE_REQUEST_VIEW_SUCCESS,
    GET_ADVANCE_REQUEST_VIEW_FAILURE,
    GET_ADVANCE_LIST_REQUEST,
    GET_ADVANCE_LIST_SUCCESS,
    GET_ADVANCE_LIST_FAILURE,
    EXPORT_ADVANCE_REQUEST_LIST_SUCCESS,
    GET_PO_AND_VENDOR_FOR_ADVANCE_REQUEST,
    GET_PO_AND_VENDOR_FOR_ADVANCE_SUCCESS,
    GET_PO_AND_VENDOR_FOR_ADVANCE_FAILURE,
    GET_USER_LIST_FOR_COMMENNTS_DROPDOWN_REQUEST,
    GET_USER_LIST_FOR_COMMENNTS_DROPDOWN_SUCCESS,
    GET_USER_LIST_FOR_COMMENNTS_DROPDOWN_FAILURE,
    GET_EXCHANGE_RATE_BY_DATE_REQUEST,
    GET_EXCHANGE_RATE_BY_DATE_SUCCESS,
    GET_EXCHANGE_RATE_BY_DATE_FAILURE,
    GET_GL_INDICATOR_LIST_REQUEST,
    GET_GL_INDICATOR_LIST_SUCCESS,
    GET_GL_INDICATOR_LIST_FAILURE,
    UPDATE_STATUS_FOR_ADVANCE_REQUEST,
    UPDATE_STATUS_FOR_ADVANCE_SUCCESS,
    UPDATE_STATUS_FOR_ADVANCE_FAILURE,
    INVOICE_GET_REQUEST,
    INVOICE_GET_SUCCESS,
    INVOICE_GET_FAILURE,
    POST_PROFORMA_INVOICE_VIEW_REQUEST,
    POST_PROFORMA_INVOICE_VIEW_SUCCESS,
    POST_PROFORMA_INVOICE_VIEW_FAILURE,
    INVOICE_UPDATE_REQUEST,
    INVOICE_UPDATE_SUCCESS,
    INVOICE_UPDATE_FAILURE,
    GET_PO_List_For_Advance_Request,
    GET_PO_List_For_Advance_SUCCESS,
    GET_PO_List_For_Advance_RESET,
    GET_PO_List_For_Advance_FAILURE
} from "../types/types.js";
const API_URL = process.env?.REACT_APP_NODE_API_URL;
// get advance data for creation
export const GetAdvanceDetailsforCreate = (poNo, type, orgId) => async (dispatch) => {
    try {
        dispatch({ type: GET_ADVANCE_DETAILS_FOR_CREATING_REQUEST });
        axios
            .get(`${API_URL}api/Recovery/GetPoHeaders?poNumber=${poNo}&type=${type}&org_id=${orgId}`, {
                headers: authHeader(),
            })
            .then((response) => {
                dispatch({
                    type: GET_ADVANCE_DETAILS_FOR_CREATING_SUCCESS,
                    payload: response.data,
                });
            })
            .catch(function (error) {
                if (error?.response?.status == 401) {
                    checkApiStatus();
                }
                dispatch({
                    payload: error?.response?.data,
                    type: GET_ADVANCE_DETAILS_FOR_CREATING_FAILURE,
                });
            });
    } catch (error) {
        dispatch({
            type: GET_ADVANCE_DETAILS_FOR_CREATING_FAILURE,
        });
    }
};
//get Advance history data
export const GetListAdvanceHistory = (refId, orgId, type) => async (dispatch) => {
    try {
        dispatch({ type: GET_ADVANCE_HISTORY_LIST_REQUEST });
        axios
            .get(`${API_URL}api/Recovery/GetAdvanceHistory?ref_id=${refId}&org_id=${orgId}&adv_type=${type}`, {
                headers: authHeader(),
            })
            .then((response) => {
                dispatch({
                    type: GET_ADVANCE_HISTORY_LIST_SUCCESS,
                    payload: response.data.datalist,
                });
            })
            .catch(function (error) {
                if (error?.response?.status == 401) {
                    checkApiStatus();
                }
                dispatch({
                    payload: { error: error.message },
                    type: GET_ADVANCE_HISTORY_LIST_FAILURE,
                });
            });
    } catch (error) {
        dispatch({
            type: GET_ADVANCE_DETAILS_FOR_CREATING_FAILURE,
        });
    }
};
// GET DOCUMENT FROM S3 FOR ADVANCE REQUEST
export const getDocumentFromS3ForAdvance = (data) => async (dispatch) => {
    try {
        dispatch({ type: GET_DOCUMENT_FROM_S3_FOR_ADVANCE_REQUEST });
        axios
            .get(`${API_URL}api/Recovery/GetDocuments?imageUrl=${data}`, {
                headers: authHeader(),
            })
            .then((response) => {
                dispatch({
                    type: GET_DOCUMENT_FROM_S3_FOR_ADVANCE_SUCCESS,
                    payload: response?.data,
                    id: data,
                });
            })
            .catch((ex) => {
                dispatch({
                    type: GET_DOCUMENT_FROM_S3_FOR_ADVANCE_FAILURE,
                    payload: ex,
                });
            });
    } catch (error) {
        if (error?.response?.status == 401) {
            checkApiStatus();
        }
        dispatch({
            type: GET_DOCUMENT_FROM_S3_FOR_ADVANCE_FAILURE,
        });
    }
};

//ADD UPDATE ADVANCE REQUEST FOR MOB AND ADHOC
export const addUpdateAdvanceRequest = (data) => async (dispatch) => {
    try {
        dispatch({ type: ADD_UPDATE_ADVANCE_TYPE_REQUEST });
        axios
            .post(`${API_URL}api/Recovery/AddUpdateRequest`, data, {
                headers: authHeader(),
            })
            .then((response) => {
                dispatch({
                    type: ADD_UPDATE_ADVANCE_TYPE_SUCCESS,
                    payload: response?.data,
                });
            })
            .catch(function (error) {
                if (error?.response?.status == 401) {
                    checkApiStatus();
                }
                dispatch({
                    payload: { error: error.message },
                    type: ADD_UPDATE_ADVANCE_TYPE_FAILURE,
                });
            });
    } catch (error) {
        dispatch({
            payload: { error: error.message },
            type: ADD_UPDATE_ADVANCE_TYPE_FAILURE,
        });
    }
};

//ADD UPDATE COMMENTS
export const addUpdateCommentsRequest = (data) => async (dispatch) => {
    try {
        dispatch({ type: ADD_UPDATE_COMMENTS_REQUEST });
        axios
            .post(`${API_URL}api/Recovery/AddUpdateComments`, data, {
                headers: authHeader(),
            })
            .then((response) => {
                dispatch({
                    type: ADD_UPDATE_COMMENTS_SUCCESS,
                    payload: response?.data,
                });
            })
            .catch(function (error) {
                if (error?.response?.status == 401) {
                    checkApiStatus();
                }
                dispatch({
                    payload: { error: error.message },
                    type: ADD_UPDATE_COMMENTS_FAILURE,
                });
            });
    } catch (error) {
        dispatch({
            type: ADD_UPDATE_COMMENTS_FAILURE,
        });
    }
};

//GET ADAVANCE STATUS LIST

export const GetAdvanceStatusList = (orgId, type) => async (dispatch) => {
    try {
        dispatch({ type: GET_ADVANCE_STATUS_LIST_REQUEST });
        axios
            .get(`${API_URL}api/workflow/getAdvStatusList?orgid=${orgId}&doc_type=${type}`, {
                headers: authHeader(),
            })
            .then((response) => {
                dispatch({
                    type: GET_ADVANCE_STATUS_LIST_SUCCESS,
                    payload: response.data.datalist,
                    orgId: orgId,
                });
            })
            .catch(function (error) {
                if (error?.response?.status == 401) {
                    checkApiStatus();
                }
                dispatch({
                    payload: { error: error.message },
                    type: GET_ADVANCE_STATUS_LIST_FAILURE,
                });
            });
    } catch (error) {
        dispatch({
            type: GET_ADVANCE_STATUS_LIST_FAILURE,
        });
    }
};

//GET PROFORMA INVOICE VIEW

export const getProformaInvoiceItems = (data) => async (dispatch) => {
    try {
        dispatch({ type: INVOICE_GET_REQUEST });
        axios
            .post(`${API_URL}api/Recovery/GetPerformaInvoiceView`, data, {
                headers: authHeader(),
            })
            .then((response) => {
                dispatch({
                    type: INVOICE_GET_SUCCESS,
                    payload: response?.data,
                });
            })
            .catch((ex) => {
                if (ex?.response?.status == 401) {
                    checkApiStatus();
                }
                dispatch({
                    type: INVOICE_GET_FAILURE,
                    payload: ex,
                });
            });
    } catch (error) {
        dispatch({
            type: INVOICE_GET_FAILURE,
        });
    }
};

//GET ADAVANCE REQUEST VIEW

export const getAdvanceRequestView = (reqId, orgId) => async (dispatch) => {
    try {
        dispatch({ type: GET_ADVANCE_REQUEST_VIEW_REQUEST });
        axios
            .get(`${API_URL}api/Recovery/GetAdvanceView?req_id=${reqId}&org_id=${orgId}`, {
                headers: authHeader(),
            })
            .then((response) => {
                dispatch({
                    type: GET_ADVANCE_REQUEST_VIEW_SUCCESS,
                    payload: response.data,
                });
            })
            .catch(function (error) {
                if (error?.response?.status == 401) {
                    checkApiStatus();
                }
                dispatch({
                    payload: { error: error.message },
                    type: GET_ADVANCE_REQUEST_VIEW_FAILURE,
                });
            });
    } catch (error) {
        dispatch({
            type: GET_ADVANCE_REQUEST_VIEW_FAILURE,
        });
    }
};

//GET ADVANCE LIST

export const getAdvanceList = (data, download) => async (dispatch) => {
    try {
        if (download !== "download") {
            dispatch({ type: GET_ADVANCE_LIST_REQUEST });
        }
        axios
            .post(`${API_URL}api/Recovery/GetDocumentListcount`, data, {
                headers: authHeader(),
            })
            .then((response) => {
                if (download === "download") {
                    dispatch({
                        type: EXPORT_ADVANCE_REQUEST_LIST_SUCCESS, //export
                        payload: response?.data,
                    });
                } else {
                    dispatch({
                        type: GET_ADVANCE_LIST_SUCCESS,
                        payload: response?.data,
                    });
                }
            })
            .catch((ex) => {
                dispatch({
                    type: GET_ADVANCE_LIST_FAILURE,
                    payload: ex,
                });
            });
    } catch (error) {
        if (error?.response?.status == 401) {
            checkApiStatus();
        }
        dispatch({
            type: GET_ADVANCE_LIST_FAILURE,
        });
    }
};

//get po and vendor list for advance request filter
export const GetListAdvanceReqForPoAndVendor = (type, orgId) => async (dispatch) => {
    try {
        dispatch({ type: GET_PO_AND_VENDOR_FOR_ADVANCE_REQUEST });
        axios
            .get(`${API_URL}api/Recovery/GetPoVendorFilterList?adv_type=${type}&org_id=${orgId}`, {
                headers: authHeader(),
            })
            .then((response) => {
                dispatch({
                    type: GET_PO_AND_VENDOR_FOR_ADVANCE_SUCCESS,
                    payload: response.data.data,
                });
            })
            .catch(function (error) {
                if (error?.response?.status == 401) {
                    checkApiStatus();
                }
                dispatch({
                    payload: { error: error.message },
                    type: GET_PO_AND_VENDOR_FOR_ADVANCE_FAILURE,
                });
            });
    } catch (error) {
        dispatch({
            type: GET_PO_AND_VENDOR_FOR_ADVANCE_FAILURE,
        });
    }
};

//comments UserList

export const GetListUserForComments = (type, orgId) => async (dispatch) => {
    try {
        dispatch({ type: GET_USER_LIST_FOR_COMMENNTS_DROPDOWN_REQUEST });
        axios
            .get(`${API_URL}api/Recovery/CommentsUsersList?adv_type=${type}&org_id=${orgId}`, {
                headers: authHeader(),
            })
            .then((response) => {
                dispatch({
                    type: GET_USER_LIST_FOR_COMMENNTS_DROPDOWN_SUCCESS,
                    payload: response.data.data,
                });
            })
            .catch(function (error) {
                if (error?.response?.status == 401) {
                    checkApiStatus();
                }
                dispatch({
                    payload: { error: error.message },
                    type: GET_USER_LIST_FOR_COMMENNTS_DROPDOWN_FAILURE,
                });
            });
    } catch (error) {
        dispatch({
            type: GET_USER_LIST_FOR_COMMENNTS_DROPDOWN_FAILURE,
        });
    }
};

//get exchnage rate by date

export const getExchangeRateByDateData = (orgId, currency, reqDate) => async (dispatch) => {
    try {
        dispatch({ type: GET_EXCHANGE_RATE_BY_DATE_REQUEST });
        axios
            .get(`${API_URL}api/ExchangeRate/GetExchangeRateBydate?orgid=${orgId}&pocurrency=${currency}&requestdate=${reqDate}`, {
                headers: authHeader(),
            })
            .then((response) => {
                dispatch({
                    type: GET_EXCHANGE_RATE_BY_DATE_SUCCESS,
                    payload: response.data.datalist,
                });
            })
            .catch(function (error) {
                if (error?.response?.status == 401) {
                    checkApiStatus();
                }
                dispatch({
                    payload: { error: error?.response?.data?.message },
                    type: GET_EXCHANGE_RATE_BY_DATE_FAILURE,
                });
            });
    } catch (error) {
        dispatch({
            payload: { error: error?.response?.data?.message },
            type: GET_EXCHANGE_RATE_BY_DATE_FAILURE,
        });
    }
};

//GET GL INDICATOR LIST

export const GetGLIndicatorList = (type) => async (dispatch) => {
    try {
        dispatch({ type: GET_GL_INDICATOR_LIST_REQUEST });
        axios
            .get(`${API_URL}api/Recovery/GetGlIndicatorList?type=${type}`, {
                headers: authHeader(),
            })
            .then((response) => {
                dispatch({
                    type: GET_GL_INDICATOR_LIST_SUCCESS,
                    payload: response.data.data,
                });
            })
            .catch(function (error) {
                if (error?.response?.status == 401) {
                    checkApiStatus();
                }
                dispatch({
                    payload: { error: error.message },
                    type: GET_GL_INDICATOR_LIST_FAILURE,
                });
            });
    } catch (error) {
        dispatch({
            type: GET_GL_INDICATOR_LIST_FAILURE,
        });
    }
};

export const AdvanceStatusUpdate = (data) => async (dispatch) => {
    try {
        dispatch({ type: UPDATE_STATUS_FOR_ADVANCE_REQUEST });

        axios
            .post(`${API_URL}api/invoiceController/UpdateInvoiceStatusApproved`, data, {
                headers: authHeader(),
            })

            .then((response) => {
                dispatch({
                    type: UPDATE_STATUS_FOR_ADVANCE_SUCCESS,
                    payload: response?.data,
                });
            })
            .catch(function (error) {
                if (error?.response?.status == 401) {
                    checkApiStatus();
                }
                dispatch({
                    payload: error.response.data,
                    type: UPDATE_STATUS_FOR_ADVANCE_FAILURE,
                });
            });
    } catch (error) {
        dispatch({
            type: UPDATE_STATUS_FOR_ADVANCE_FAILURE,
        });
    }
};

//update proforma invoice
export const ProformaUpdateInvoice = (data) => async (dispatch) => {
    try {
        dispatch({ type: INVOICE_UPDATE_REQUEST });

        axios
            .post(`${API_URL}api/Recovery/UpdatePerformaInvoice`, data, {
                headers: authHeader(),
            })
            .then((response) => {
                dispatch({
                    type: INVOICE_UPDATE_SUCCESS,
                    payload: response?.data,
                });
            })
            .catch(function (error) {
                if (error?.response?.status == 401) {
                    checkApiStatus();
                }
                dispatch({
                    type: INVOICE_UPDATE_FAILURE,
                });
            });
    } catch (error) {
        dispatch({
            type: INVOICE_UPDATE_FAILURE,
        });
    }
};



//get po and vendor list for advance request filter
export const GetListAdvanceReqForPo = (orgId) => async (dispatch) => {
    try {
        dispatch({ type: GET_PO_List_For_Advance_Request });
        axios
            .get(`${API_URL}api/Recovery/GetDownpaymentPoList?orgid=${orgId}`, {
                headers: authHeader(),
            })
            .then((response) => {
                dispatch({
                    type: GET_PO_List_For_Advance_SUCCESS,
                    payload: response.data.data,
                });
            })
            .catch(function (error) {
                if (error?.response?.status == 401) {
                    checkApiStatus();
                }
                dispatch({
                    payload: { error: error.message },
                    type: GET_PO_List_For_Advance_FAILURE,
                });
            });
    } catch (error) {
        dispatch({
            type: GET_PO_List_For_Advance_FAILURE,
        });
    }
};