import { Box, ClickAwayListener, Fade, FormControl, MenuItem, Paper, Popper, Select, TextField, Tooltip } from "@mui/material";

import { useContext, useState, useEffect, useMemo } from "react";
import ReactDOM from "react-dom";
import { ThemeContext } from "../../Hooks/Themes/Themeprovider";

import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import moment from "moment";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateRangePicker } from "rsuite";

import "rsuite/dist/rsuite-no-reset.min.css";
import {
    DateFormat,
    endOfMonth,
    endOfWeek,
    endOfYear,
    startOfMonth,
    startOfWeek,
    startOfYear,
    getFirstDayOfWeek,
    formatDate,
    getFirstDayOfMonth,
    getFirstDayOfYear,
} from "../../utils/helpers";
import { getCurrentUser } from "../../redux/actions/auth";
import ValidTime from "./ValidTimeProcess";
import Button from "../../components/Button";
import { useDispatch, useSelector } from "react-redux";
import { GetOnTimeProcessTimeByOrg } from "../../redux/actions/dashboard";
const Filter = (props) => {
    const { drilldownData, setDrilldownData } = props;
    const formattedDateWeek = formatDate(startOfWeek);
    const TodayDate = formatDate(new Date());
    const [fromDate, setFromDate] = useState();
    const firstDayOfWeek = getFirstDayOfWeek(formattedDateWeek);
    const firstDayofMonth = getFirstDayOfMonth(startOfMonth);
    const firstMonthofYear = getFirstDayOfYear(startOfYear);
    const [ValidOpen, setValidOpen] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        if (ValidOpen) {
            localStorage.setItem("draggable", true);
        } else {
            localStorage.setItem("draggable", false);
        }
    }, [ValidOpen]);

    const currentUser = getCurrentUser();
    const [toDate, setToDate] = useState();

    const Orgname = localStorage.getItem("Orgdemo");

    const [selectedValue, changeFilter] = useState(Orgname == "true" ? 3 : 1);
    const [ChangeFilter, setChangeFilter] = useState(false);
    const [Obj, setObj] = useState();
    const orgId = localStorage.getItem("orgId");

    useEffect(() => {
        changeFilter(Orgname == "true" ? 3 : 1);
    }, [orgId, Orgname]);

    const Ico = () => (
        <img
            className="absolute opacity-[.5] right-[10px] pointer-events-none"
            src={currentMode !== "dark" ? `/images/header/chevronDown.svg` : `/images/header/chevronDown-white.svg`}
            alt="chevronDown"
        />
    );

    const { currentMode } = useContext(ThemeContext);

    const dropdownColor = currentMode === "dark" ? "#333333" : `white`;

    const dropdownText = currentMode === "dark" ? "#ECECEC" : `#555555`;

    const [selectedRange, setSelectedRange] = useState(null, null);
    const ThisYears = {
        from: moment(selectedRange?.[0]).format("YYYY-MM-DD"),
        to: moment(selectedRange?.[1]).format("YYYY-MM-DD"),
    };
    const ThisMonths = {
        from: moment(selectedRange?.[0]).format("YYYY-MM-DD"),
        to: moment(selectedRange?.[1]).format("YYYY-MM-DD"),
    };
    const ThisWeeks = {
        from: moment(selectedRange?.[0]).format("YYYY-MM-DD"),
        to: moment(selectedRange?.[1]).format("YYYY-MM-DD"),
    };

    const FilterChange = () => {
        switch (selectedValue) {
            case 1:
                return ThisWeeks;
            case 2:
                return ThisMonths;

            case 3:
                return ThisYears;

            default:
                break;
        }
    };
    const DateForm = selectedValue == 2 ? ThisMonths : selectedValue == 3 ? ThisYears : ThisWeeks;
    useEffect(() => {
        // let setDateForm = props?.setDateForm(DateForm)
        // let setSelectedValue = props?.setSelectedValue(selectedValue)
        if (ChangeFilter) {
            props.getDataItems(selectedValue, selectedValue == 2 ? ThisMonths : selectedValue == 3 ? ThisYears : ThisWeeks);

            setFromDate(ThisYears?.from);
            setToDate(ThisYears?.to);
        }
    }, [selectedRange]);

    useEffect(() => {
        switch (selectedValue) {
            case 1:
                setFromDate(startOfWeek);
                setToDate(new Date());
                setSelectedRange([new Date(firstDayOfWeek), new Date(TodayDate)]);
                break;

            case 2:
                setFromDate(startOfMonth);
                setToDate(new Date());
                setSelectedRange([new Date(firstDayofMonth), new Date(TodayDate)]);
                break;

            case 3:
                setFromDate(startOfYear);
                setToDate(new Date());
                setSelectedRange([new Date(firstMonthofYear), new Date(TodayDate)]);

                break;

            default:
                // setFromDate(fromDate);
                // setToDate(toDate);

                break;
        }
    }, [selectedValue]);

    const handleChange = (event) => {
        setChangeFilter(true);
        changeFilter(event.target.value);
        // props.getDataItems(
        //     event.target.value,
        //     event.target.value === 2
        //         ? ThisMonths
        //         : event.target.value === 3
        //             ? ThisYears
        //             : ThisWeeks
        // );
    };

    // const handleValue = (newValue) => {
    //     if (newValue) {
    //         setFromDate(newValue);

    //         props.getDataItems(selectedValue, {
    //             from: moment(
    //                 newValue?.$d == undefined ? newValue?._d : newValue?.$d
    //             ).format("YYYY-MM-DD"),

    //             to: moment(
    //                 toDate?.$d == undefined ? toDate?._d : toDate?.$d
    //             ).format("YYYY-MM-DD"),
    //         });
    //     }
    // };

    // useEffect(() => {
    //     props.getDataItems(selectedValue, {
    //         from: moment(fromDate?.$d).format(DateFormat()),
    //         to: moment(toDate?.$d).format(DateFormat()),
    //     });

    // }, [fromDate, toDate]);

    // const handleToDate = (newValue) => {
    //     if (newValue) {
    //         setToDate(newValue);

    //         props.getDataItems(selectedValue, {
    //             from: moment(
    //                 fromDate?.$d == undefined ? fromDate?._d : fromDate?.$d
    //             ).format("YYYY-MM-DD"),

    //             to: moment(
    //                 newValue?.$d == undefined ? newValue?._d : newValue?.$d
    //             ).format("YYYY-MM-DD"),
    //         });
    //     }
    // };

    const AccessIcon = () => <img src="/images/common/date-ico.svg" alt="date-ico" className="opacity-0" />;

    const [openDate, setopenDate] = useState(false);
    const OpenDatePicker = () => {
        setopenDate(true);
    };
    const handleClickAway = (e) => {
        setopenDate(false);
    };
    const dateFormat = currentUser?.user_date?.replace("mm", "MM");
    const customPresets = [
        {
            label: "Last 30 Days",
            value: [new Date(new Date().setDate(new Date().getDate() - 30)), new Date()],
        },
        {
            label: "Custom Range",
            value: null, // You can set your own custom range logic here
        },
    ];
    const minDate = new Date("1992-01-01");
    const maxDate = new Date();

    // Callback function to disable dates outside the range
    const disabledDate = (date) => {
        return date < minDate || date > maxDate;
    };
    const timedataList = useSelector((state) => state?.getTimeForOnTimeProcessingState);
    const SettingsOpen = () => {
        setValidOpen(true);
        props?.setValidOpen(true);
        props?.setDateForm(DateForm);
        props?.setSelectedValue(selectedValue);
        if (timedataList?.datalist?.length == 0 || !timedataList?.datalist) {
            dispatch(GetOnTimeProcessTimeByOrg(orgId));
        }
    };

    const handleDateChange = (range) => {
        setSelectedRange(range);
        setChangeFilter(true);
        changeFilter(4);
    };
    const handleBackClick = () => {
        setDrilldownData(null);
    };

    // const CalendarIcon = () => (

    // );

    return (
        <>
            <div className="flex items-center space-x-2">
                {/* </div> */}
                {drilldownData ? (
                    <Button className={`bg-[#F5F6F9] px-[23px] py-[9px] font-interR rounded-[5px]`} onClick={handleBackClick}>
                        Back
                    </Button>
                ) : (
                    <>
                        <DateRangePicker
                            className="my-custom-class"
                            value={selectedRange}
                            format={dateFormat}
                            placeholder={"dd-mm-yyyy"}
                            editable={true}
                            preventOverflow
                            cleanable={false}
                            disabledDate={disabledDate}
                            onChange={(e) => handleDateChange(e)}
                            caretAs={() => (
                                <img
                                    src="/images/dashboard/calendar.svg"
                                    alt="calendar"
                                    className="absolute top-50% convertWhite translate-y-[-50%] right-[10px]"
                                />
                            )}
                        />
                        <FormControl
                            sx={{
                                fontSize: "14px",
                                m: 0,
                            }}>
                            <Select
                                select
                                size="small"
                                value={selectedValue}
                                title={selectedValue == 4 ? "Custom Date" : ""}
                                inputProps={{
                                    style: { filter: "grayscale(100%)" }, // Apply filter to the native select
                                }}
                                className="whtIcon noborder"
                                onChange={handleChange}
                                displayEmpty
                                MenuProps={{
                                    sx: {
                                        // filter: "invert(100%) sepia(100%) saturate(0%) hue-rotate(311deg) brightness(101%) contrast(101%) !important"
                                        "& .MuiMenu-paper": {
                                            backgroundColor: dropdownColor,
                                            color: dropdownText,
                                            fontSize: "13px",
                                        },
                                        "& .MuiButtonBase-root": {
                                            padding: "6px !important",
                                        },
                                        "& .MuiMenuItem-root:hover": {
                                            backgroundColor: "rgba(218, 249, 244, 0.3)",
                                        },
                                        "& .Mui-selected": {
                                            backgroundColor: "rgba(218, 249, 244, 0.3) !important",
                                        },
                                        // "& .MuiSelect-select":
                                        // {
                                        // },
                                    },
                                }}
                                SelectDisplayProps={{
                                    style: {
                                        paddingTop: 9,
                                        backgroundColor: "#00B399",
                                        paddingBottom: 9,
                                        padding: 0,

                                        border: "0",

                                        paddingRight: "30px",

                                        borderRadius: "5px",

                                        color: dropdownText,
                                    },
                                }}
                                IconComponent={() => null}>
                                <MenuItem selected value={1}>
                                    <img src="/images/dashboard/week-color.svg" title="This Week"></img>
                                </MenuItem>

                                <MenuItem value={2}>
                                    {" "}
                                    <img src="/images/dashboard/month-color.svg" title="This Month"></img>
                                </MenuItem>

                                <MenuItem value={3}>
                                    <img src="/images/dashboard/year-color.svg" title="This Year"></img>
                                </MenuItem>
                                <MenuItem value={4} className="hidden">
                                    <img src="/images/dashboard/custom-svg.svg" title="Custom Date"></img>
                                </MenuItem>
                            </Select>
                        </FormControl>
                        {/* <div>
                            <img src="/images/dashboard/custom-btn.svg"></img>
                        </div> */}
                        {props?.Title == "On Time Processing" && currentUser?.role_id == 2 && (
                            <Button onClick={SettingsOpen} className="cursor-pointer">
                                <img src="/images/dashboard/dashboard-settings.svg"></img>
                            </Button>
                        )}
                    </>
                )}
            </div>
        </>
    );
};

export default Filter;
