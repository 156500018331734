import axios from "axios";
import configMyiQ from "../../configuration";
import { userId } from "../../Hooks/userId/Index";
import {
    SETTING_FAILURE,
    SETTING_REQUEST,
    SETTING_SUCCESS,
    SETTING_UPDATE_FAILURE,
    SETTING_UPDATE_REQUEST,
    SETTING_UPDATE_SUCCESS,
    SETTING_VALUE_REQUEST,
    SETTING_VALUE_SUCCESS,
    SETTING_VALUE_FAILURE,
    REMOVE_DEMO_DATA_FAILURE,
    REMOVE_DEMO_DATA_SUCCESS,
    REMOVE_DEMO_DATA_REQUEST,
    BACKUP_DATA_REQUEST,
    BACKUP_DATA_FAILURE,
    BACKUP_DATA_SUCCESS,
} from "../types/types";
import authHeader from "./auth-header";
import { checkApiStatus } from "../../utils/helpers";
const { BAuth } = userId();
const Token = localStorage.getItem("token");

const API_URL = process.env?.REACT_APP_API_URL;
const API = process.env?.REACT_APP_NODE_API_URL;

export const updateSettingAction = (data) => async (dispatch) => {
    try {
        dispatch({ type: SETTING_UPDATE_REQUEST });

        axios
            .post(`${API_URL}Settings/UpdateSettings`, data, {
                headers: authHeader(),
            })
            .then((response) => {
                dispatch({
                    type: SETTING_UPDATE_SUCCESS,
                    payload: response.data,
                });
            })
            .catch(function (error) {
                if (error?.response?.status == 401) {
                    checkApiStatus();
                }
                dispatch({
                    payload: { error: error.message },
                    type: SETTING_UPDATE_FAILURE,
                });
            });
    } catch (error) {
        dispatch({
            type: SETTING_UPDATE_FAILURE,
        });
    }
};
export const SettingData = (data) => async (dispatch) => {
    dispatch({ type: SETTING_REQUEST });

    axios
        .get(
            `${API_URL}Settings/GetSettingsList?ref_id=${data?.ref_id}&ref_type=${data?.ref_type}`,
            {
                headers: authHeader(),
            }
        )
        .then((response) => {
            dispatch({
                type: SETTING_SUCCESS,
                payload: response.data?.datalist,
            });
        })
        .catch((error) => {
            if (error?.response?.status == 401) {
                checkApiStatus();
            }
            dispatch({
                type: SETTING_FAILURE,
            });
        });
};

export const settingValues = (data) => async (dispatch) => {
    dispatch({ type: SETTING_VALUE_REQUEST });

    axios
        .post(
            `${API_URL}Settings/GetSettingsValue?ref_type=${data?.ref_type}&code=${data?.code}`,
            data,
            {
                headers: authHeader(),
            }
        )
        .then((response) => {
            dispatch({
                type: SETTING_VALUE_SUCCESS,
                payload: response.data,
            });
        })
        .catch((error) => {
            if (error?.response?.status == 401) {
                checkApiStatus();
            }
            dispatch({
                type: SETTING_VALUE_FAILURE,
            });
        });
};

export const RemoveData = (data) => async (dispatch) => {
    dispatch({ type: REMOVE_DEMO_DATA_REQUEST });

    axios
        .get(`${API_URL}DemoData/DeleteDemoData`, {
            headers: authHeader(),
        })
        .then((response) => {
            dispatch({
                type: REMOVE_DEMO_DATA_SUCCESS,
                payload: response.data,
            });
        })
        .catch((error) => {
            if (error?.response?.status == 401) {
                checkApiStatus();
            }
            dispatch({
                type: REMOVE_DEMO_DATA_FAILURE,
            });
        });
};

export const BackupDataApi = (data) => async (dispatch) => {
    dispatch({ type: BACKUP_DATA_REQUEST });

    axios
        .get(`${API}api/dataBackup/addBackup`, {
            headers: authHeader(),
        })
        .then((response) => {
            dispatch({
                type: BACKUP_DATA_SUCCESS,
                payload: response.data,
            });
        })
        .catch((error) => {
            if (error?.response?.status == 401) {
                checkApiStatus();
            }
            dispatch({
                type: BACKUP_DATA_FAILURE,
            });
        });
};
