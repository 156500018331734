import { styled } from "@mui/material/styles";
import {Switch} from "@mui/material";


const TriggerSwitch = (props) => {
    const EmailSwitch = styled((props) => (
      <Switch focusVisibleClassName=".Mui-checked"{...props} />
    ))(({ theme }) => ({
    padding: 8,
    "& .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track": {
      backgroundColor: "#00B399 !important",
      opacity: 1,
    },
    "& .Mui-checked .MuiSwitch-thumb": {
      "&:before": {
        display: "block",
      },
      "&:after": {
        display: "none",
      },
    },
    "& .MuiSwitch-track": {
      borderRadius: 22 / 2,
      backgroundColor: "#E8E8E8",
      opacity: 1,
    },
    '& .MuiSwitch-switchBase.Mui-disabled + .MuiSwitch-track':{
opacity: 1,
    },
    "& .MuiSwitch-thumb": {
      boxShadow: "none",
      backgroundColor: "#FFFFFF",
      width: 16,
      height: 16,
      margin: 2,
      "&:before, &:after": {
        content: '""',
        position: "absolute",
        top: "50%",
        transform: "translate(-50%,-50%)",
        left: "50%",
      },
      "&:after": {
        background: `url('/images/common/cls-green.svg') no-repeat`,
        width: 9,
        height: 8,
      },
      "&:before": {
        background: `url('/images/common/green-tick.svg') no-repeat`,
        display: "none",
        width: 10,
        height: 8,
      },
    },
  }));
  return (
    <>
      <EmailSwitch {...props}/>
    </>
  )
  }
export default TriggerSwitch;
