import {
    ADD_REPORTS_FAV_FAILURE,
    GET_TASK_ID_FAILURE,
    GET_TASK_ID_RESET,
    GET_TASK_ID_SUCCESS,
    GET_TASK_ID_REQUEST,
    GET_PRIORITY_CONFIG_FAILURE,
    GET_PRIORITY_CONFIG_RESET,
    GET_PRIORITY_CONFIG_SUCCESS,
    GET_PRIORITY_CONFIG_REQUEST,
    POST_TASK_ID_FAILURE,
    POST_TASK_ID_RESET,
    POST_TASK_ID_SUCCESS,
    POST_TASK_ID_REQUEST,
    POST_PRIORITY_CONFIG_FAILURE,
    POST_PRIORITY_CONFIG_RESET,
    POST_PRIORITY_CONFIG_SUCCESS,
    POST_PRIORITY_CONFIG_REQUEST,
    GET_MY_TASK_LIST_REQUEST,
    GET_MY_TASK_LIST_SUCCESS,
    GET_MY_TASK_LIST_FAILURE,
    GET_INVOICE_TASK_USERDROPDOWN_REQUEST,
    GET_INVOICE_TASK_USERDROPDOWN_SUCCESS,
    GET_INVOICE_TASK_USERDROPDOWN_FAILURE,
    Add_INVOICE_TODO_CREATE_TASK_REQUEST,
    Add_INVOICE_TODO_CREATE_TASK_SUCCESS,
    Add_INVOICE_TODO_CREATE_TASK_FAILURE,
    GET_TASK_FAILURE,
    GET_TASK_RESET,
    GET_TASK_REQUEST,
    GET_TASK_SUCCESS,
    POST_COMMENT_FAILURE,
    POST_COMMENT_SUCCESS,
    POST_COMMENT_REQUEST,
    POST_COMMENT_RESET,
    POST_COMPLETE_TASK_REQUEST,
    POST_COMPLETE_TASK_SUCCESS,
    POST_COMPLETE_TASK_FAILURE,
    GET_INVOICE_TASK_LIST_REQUEST,
    GET_INVOICE_TASK_LIST_SUCCESS,
    GET_INVOICE_TASK_LIST_FAILURE,
    GET_VIEW_TASK_ATTACHMENT_REQUEST,
    GET_VIEW_TASK_ATTACHMENT_FAILURE,
    GET_VIEW_TASK_ATTACHMENT_SUCCESS,
    GET_VIEW_TASK_ATTACHMENT_RESET,
} from "../types/types";
import axios from "axios";
import authHeader from "./auth-header";
import { checkApiStatus } from "../../utils/helpers";
const API_URL = process.env?.REACT_APP_NODE_API_URL;
export const getTaskNumberConfigList = () => async (dispatch) => {
    try {
        dispatch({ type: GET_TASK_ID_REQUEST });

        axios
            .get(
                `${API_URL}api/caseManagement/taskNumberConfig/taskNumberConfigList`,
                {
                    headers: authHeader(),
                }
            )
            .then((response) => {
                dispatch({
                    type: GET_TASK_ID_SUCCESS,
                    payload: response?.data?.datalist,
                });
            })
            .catch((ex) => {
                if (ex?.response?.status == 401) {
                    checkApiStatus();
                }
                dispatch({
                    type: GET_TASK_ID_FAILURE,
                    payload: ex,
                });
            });
    } catch (error) {
        dispatch({
            type: GET_TASK_ID_FAILURE,
        });
    }
};

export const getPriorityConfigList = () => async (dispatch) => {
    try {
        dispatch({ type: GET_PRIORITY_CONFIG_REQUEST });

        axios
            .get(`${API_URL}api/caseManagement/taskPriority/taskPriorityList`, {
                headers: authHeader(),
            })
            .then((response) => {
                dispatch({
                    type: GET_PRIORITY_CONFIG_SUCCESS,
                    payload: response?.data?.datalist,
                });
            })
            .catch((ex) => {
                if (ex?.response?.status == 401) {
                    checkApiStatus();
                }
                dispatch({
                    type: GET_PRIORITY_CONFIG_FAILURE,
                    payload: ex,
                });
            });
    } catch (error) {
        dispatch({
            type: GET_PRIORITY_CONFIG_FAILURE,
        });
    }
};

export const postTaskIdConfig = (data) => async (dispatch) => {
    try {
        dispatch({ type: POST_TASK_ID_REQUEST });

        axios
            .post(
                `${API_URL}api/caseManagement/taskNumberConfig/addUpdateTaskNumberConfig`,
                data,
                {
                    headers: authHeader(),
                }
            )
            .then((response) => {
                dispatch({
                    type: POST_TASK_ID_SUCCESS,
                    payload: response?.data,
                });
            })
            .catch((ex) => {
                if (ex?.response?.status == 401) {
                    checkApiStatus();
                }
                dispatch({
                    type: POST_TASK_ID_FAILURE,
                    payload: ex,
                });
            });
    } catch (error) {
        dispatch({
            type: POST_TASK_ID_FAILURE,
        });
    }
};

export const postPriorityConfig = (data) => async (dispatch) => {
    try {
        dispatch({ type: POST_PRIORITY_CONFIG_REQUEST });

        axios
            .post(
                `${API_URL}api/caseManagement/taskPriority/insertTaskPriority`,
                data,
                {
                    headers: authHeader(),
                }
            )
            .then((response) => {
                dispatch({
                    type: POST_PRIORITY_CONFIG_SUCCESS,
                    payload: response?.data,
                });
            })
            .catch((ex) => {
                if (ex?.response?.status == 401) {
                    checkApiStatus();
                }
                dispatch({
                    type: POST_PRIORITY_CONFIG_FAILURE,
                    payload: ex,
                });
            });
    } catch (error) {
        dispatch({
            type: POST_PRIORITY_CONFIG_FAILURE,
        });
    }
};

export const getMyTaskList = () => async (dispatch) => {
    try {
        dispatch({ type: GET_MY_TASK_LIST_REQUEST });

        axios
            .get(`${API_URL}api/caseManagement/MyTask/getMyTaskList`, {
                headers: authHeader(),
            })
            .then((response) => {
                dispatch({
                    type: GET_MY_TASK_LIST_SUCCESS,
                    payload: response?.data?.datalist,
                });
            })
            .catch((ex) => {
                if (ex?.response?.status == 401) {
                    checkApiStatus();
                }
                dispatch({
                    type: GET_MY_TASK_LIST_FAILURE,
                    payload: ex,
                });
            });
    } catch (error) {
        dispatch({
            type: GET_MY_TASK_LIST_FAILURE,
        });
    }
};
//invoice task user drop down
export const getToDOTaskUserList = (orgId) => async (dispatch) => {
    try {
        dispatch({ type: GET_INVOICE_TASK_USERDROPDOWN_REQUEST });

        axios
            .get(
                `${API_URL}api/caseManagement/InvoiceTask/getUserToDoTask?orgid=${orgId}`,
                {
                    headers: authHeader(),
                }
            )
            .then((response) => {
                dispatch({
                    type: GET_INVOICE_TASK_USERDROPDOWN_SUCCESS,
                    payload: response?.data?.datalist,
                });
            })
            .catch((ex) => {
                if (ex?.response?.status == 401) {
                    checkApiStatus();
                }
                dispatch({
                    type: GET_INVOICE_TASK_USERDROPDOWN_FAILURE,
                    payload: ex,
                });
            });
    } catch (error) {
        dispatch({
            type: GET_INVOICE_TASK_USERDROPDOWN_FAILURE,
        });
    }
};
//add  new todo task
export const addToDoTask = (data) => async (dispatch) => {
    try {
        dispatch({ type: Add_INVOICE_TODO_CREATE_TASK_REQUEST });

        axios
            .post(`${API_URL}api/caseManagement/InvoiceTask/createTask`, data, {
                headers: authHeader(),
            })
            .then((response) => {
                dispatch({
                    type: Add_INVOICE_TODO_CREATE_TASK_SUCCESS,
                    payload: response?.data,
                });
            })
            .catch((ex) => {
                if (ex?.response?.status == 401) {
                    checkApiStatus();
                }
                dispatch({
                    type: Add_INVOICE_TODO_CREATE_TASK_FAILURE,
                    payload: ex,
                });
            });
    } catch (error) {
        dispatch({
            type: Add_INVOICE_TODO_CREATE_TASK_FAILURE,
        });
    }
};

export const getTaskView = (id) => async (dispatch) => {
    try {
        dispatch({ type: GET_TASK_REQUEST });
        axios
            .get(
                `${API_URL}api/caseManagement/InvoiceTask/getInvoiceTaskView?id=${id}`,
                {
                    headers: authHeader(),
                }
            )
            .then((response) => {
                dispatch({
                    type: GET_TASK_SUCCESS,
                    payload: response?.data,
                });
            })
            .catch((ex) => {
                if (ex?.response?.status == 401) {
                    checkApiStatus();
                }
                dispatch({
                    type: GET_TASK_FAILURE,
                    payload: ex,
                });
            });
    } catch (error) {
        dispatch({
            type: GET_TASK_FAILURE,
        });
    }
};

export const postTaskComment = (data) => async (dispatch) => {
    try {
        dispatch({ type: POST_COMMENT_REQUEST });

        axios
            .post(
                `${API_URL}api/caseManagement/InvoiceTask/addUpdateComments`,
                data,
                {
                    headers: authHeader(),
                }
            )
            .then((response) => {
                dispatch({
                    type: POST_COMMENT_SUCCESS,
                    payload: response?.data,
                });
            })
            .catch((ex) => {
                if (ex?.response?.status == 401) {
                    checkApiStatus();
                }
                dispatch({
                    type: POST_COMMENT_FAILURE,
                    payload: ex,
                });
            });
    } catch (error) {
        dispatch({
            type: POST_COMMENT_FAILURE,
        });
    }
};

//post complete task

export const postTaskComplete = (data) => async (dispatch) => {
    try {
        dispatch({ type: POST_COMPLETE_TASK_REQUEST });

        axios
            .post(
                `${API_URL}api/caseManagement/InvoiceTask/updateTaskStaus`,
                data,
                {
                    headers: authHeader(),
                }
            )
            .then((response) => {
                dispatch({
                    type: POST_COMPLETE_TASK_SUCCESS,
                    payload: response?.data,
                });
            })
            .catch((ex) => {
                if (ex?.response?.status == 401) {
                    checkApiStatus();
                }
                dispatch({
                    type: POST_COMPLETE_TASK_FAILURE,
                    payload: ex,
                });
            });
    } catch (error) {
        dispatch({
            type: POST_COMPLETE_TASK_FAILURE,
        });
    }
};

//get invoice task list by invoice

export const getInvoiceTaskList = (id) => async (dispatch) => {
    try {
        dispatch({ type: GET_INVOICE_TASK_LIST_REQUEST });

        axios
            .get(
                `${API_URL}api/caseManagement/InvoiceTask/getInvoiceTaskList?invid=${id}`,
                {
                    headers: authHeader(),
                }
            )
            .then((response) => {
                dispatch({
                    type: GET_INVOICE_TASK_LIST_SUCCESS,
                    payload: response?.data?.datalist,
                });
            })
            .catch((ex) => {
                if (ex?.response?.status == 401) {
                    checkApiStatus();
                }
                dispatch({
                    type: GET_INVOICE_TASK_LIST_FAILURE,
                    payload: ex,
                });
            });
    } catch (error) {
        dispatch({
            type: GET_INVOICE_TASK_LIST_FAILURE,
        });
    }
};

//get task attachments

export const getViewTaskAttachment = (url) => async (dispatch) => {
    try {
        dispatch({ type: GET_VIEW_TASK_ATTACHMENT_REQUEST });

        axios
            .get(
                `${API_URL}api/caseManagement/myTask/getDocumentFroms3?imageUrl=${url}`,
                {
                    headers: authHeader(),
                }
            )
            .then((response) => {
                dispatch({
                    type: GET_VIEW_TASK_ATTACHMENT_SUCCESS,
                    payload: response?.data,
                });
            })
            .catch((ex) => {
                if (ex?.response?.status == 401) {
                    checkApiStatus();
                }
                dispatch({
                    type: GET_VIEW_TASK_ATTACHMENT_FAILURE,
                    payload: ex,
                });
            });
    } catch (error) {
        dispatch({
            type: GET_VIEW_TASK_ATTACHMENT_FAILURE,
        });
    }
};
