import { useState, useEffect, useRef } from "react";
import { Box, FormControl, MenuItem, Select, Tab, Tabs, TextField } from "@mui/material";
import { Link } from "react-router-dom";
import Button from "../../components/Button";
import Input from "../../components/Input";
import Card from "../../components/UI/Card";
import Main from "../../components/UI/Main";
import Footer from "../../organisms/Footer";
import Header from "../../organisms/Header";
import Sidebar from "../../organisms/Sidebar";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import { DummyOrg } from "../../utils/constants";
import ReactDOM from "react-dom";
import { useDispatch, useSelector } from "react-redux";
import AddOrganisation from "../../organisms/OrgModal/AddOrganisation";
import ViewOrganisation from "../../organisms/OrgModal/ViewOrganisation";
import { organisationView } from "../../redux/actions/organisation";

import EditOrganisation from "../../organisms/OrgModal/EditOrganisation";
import { getEmailConfig } from "../../redux/actions/emailconfig.js";
import { countryList } from "../../redux/actions/country";
import EmailConfigs from "../../organisms/ConfigModal/EmailConfig";
import LeftPanel from "./leftPanel";
import { getOrganisation, organisationUpdateStatus } from "../../redux/actions/organisation";
import Loader from "../../components/Loader";
import TogglePopup from "./Toggle";
import { ORG_VIEW_RESET } from "../../redux/types/types";
import FolderConfigs from "../../organisms/ConfigModal/FolderConfig";
import ServerConfigs from "../../organisms/ConfigModal/ServerConfig";
import UserConfig from "../../organisms/ConfigModal/UserConfig";
import Flow from "../../organisms/FlowChart/InitialElement";
import UserRole from "../../organisms/UserModal/UserRole";
import ViewEmailConfigs from "../../organisms/ConfigModal/EmailConfig/ViewEmail";
import ViewFolderConfigs from "../../organisms/ConfigModal/FolderConfig/ViewFolder";
import ViewUser from "../../organisms/UserModal/ViewUser";
import UserRoleView from "../../organisms/UserModal/UserRole/ViewUser";
import { getFolderConfig } from "../../redux/actions/folderconfig.js";
import { viewUserRoleByOrg } from "../../redux/actions/role";
import { SettingData } from "../../redux/actions/setting";
import { getCurrentUser } from "../../redux/actions/auth";
import Tour from "reactour";
import UseTour from "../../Hooks/ProductTour";
import { useContext } from "react";
import { ThemeContext } from "../../Hooks/Themes/Themeprovider";
import { decryptId } from "../../utils/helpers";
// import { useTour } from '@reactour/tour'

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}
const OrganisationListing = () => {
    const orgId = localStorage.getItem("orgId") || 0;
    const dispatch = useDispatch();
    const [value, setValue] = useState(0);
    const [isAddorEdit, setIsAddorEdit] = useState(false);
    const [isEmailView, setIsEmailView] = useState(false);
    const [isFolderView, setIsFolderView] = useState(false);
    const [orgActionType, setOrgActionType] = useState(0);
    localStorage.setItem("OrgactionType", orgActionType);
    const [isEmail, setIsEmail] = useState(false);
    const [isFolder, setIsFolder] = useState(false);
    const [isServer, setIsServerOpen] = useState(false);
    const [isShowServer, setIsShowServer] = useState(false);
    const [isUser, setIsUser] = useState(false);
    const [checked, setChecked] = useState(true);
    const [isUserView, setIsUserView] = useState(false);
    const [treeOpen, setTreeOpen] = useState(false);
    const setIsServer = (isOpen) => {
        setIsServerOpen(isOpen);
        if (isServer) {
            setIsShowServer(true);
        } else {
            setIsShowServer(false);
        }
    };

    const __handleChange = (event) => {
        setChecked(event.target.checked);
    };

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const AddEmail = () => {
        setIsEmail(true);
    };

    const [dateValue, handleDateChange] = useState("");
    const [numberValue, handleNumberChange] = useState("");
    const [searchValue, handleSearchChange] = useState("");
    const [isOrgViewDetails, setOrgViewDetails] = useState(false);
    const [isEmailViewDetails, setEmailViewDetails] = useState(false);
    const [isFolderViewDetails, setFolderViewDetails] = useState(false);
    const [isUserRoleViewDetails, setUserRoleFolderViewDetails] = useState(false);
    const [isStatusUpdate, handleStatusUpdate] = useState(false);
    const [selectedOrgId, setOrgSelectItem] = useState("");
    const [targetConfigCount, setTargetConfigCount] = useState(false);
    const [targetConfigType, setTargetConfigType] = useState(false);
    const [orgStatus, setOrgStatus] = useState(0);
    const [selectedOrgName, setOrgSelectItemName] = useState("");
    const [userCount, setUserCount] = useState(0);
    const [emailCount, setEmailCount] = useState(0);
    const [folderCount, setFolderCount] = useState(0);
    const [filterStatus, setFilterStatus] = useState("");
    const [isdeomo, setisDemo] = useState(false);
    const [isdemoDeleted, setisDemoDeleted] = useState(false);

    const handleNumberValueChange = (value) => {
        handleNumberChange(value);
    };
    const reqObj = {
        status: 2,
        search_text: searchValue,
        // organsiation: orgId,
    };

    useEffect(() => {
        getOrganisationDetails(reqObj);
    }, [dispatch, searchValue, isStatusUpdate]);

    const getOrganisationDetails = (reqObjList) => {
        dispatch(getOrganisation(reqObjList));
    };
    const onFilterItems = (value) => {
        reqObj.status = +value;
        if (value == 0) {
            setFilterStatus(true);
        } else {
            setFilterStatus(false);
        }
        getOrganisationDetails(reqObj);
    };

    const onStatusChange = (id = null, value) => {
        const OrgStatus = {
            id: id,
            status: value ? false : true,
        };
        dispatch(organisationUpdateStatus(OrgStatus));
    };
    const currentUser = getCurrentUser();
    const { country } = useSelector((state) => state?.countryState);
    const Setting = useSelector((state) => state?.settingState);

    useEffect(() => {
        if (country == [] || country?.length === 0) {
            dispatch(countryList());
        }
    }, [dispatch, country]);
    useEffect(() => {}, [selectedOrgName, selectedOrgId]);

    const orgData = useSelector((state) => state?.organisationListState);
    const [orgList, setOrgList] = useState([]);
    useEffect(() => {
        if (orgData?.OrgList?.length > 0) {
            setOrgList(orgData?.OrgList);
        }
    }, [orgData?.requestComplete]);
    const Addsubmit = (value) => {
        setIsAddorEdit(true);
        setTreeOpen(true);
        // if (isAddorEdit && country?.length === 0) {
        //   dispatch(countryList());
        // }
        dispatch(
            SettingData({
                ref_type: 2,
                ref_id: 0,
            })
        );
        if (selectedOrgId && value === 1) {
            setOrgSelectItem(selectedOrgId);
            dispatch(organisationView(selectedOrgId));
        } else {
            setOrgSelectItem("");
            dispatch({ type: ORG_VIEW_RESET });
        }
        setOrgActionType(value);
    };
    const EditSubmit = (value) => {
        setIsAddorEdit(true);
        // dispatch(countryList());
        dispatch(
            SettingData({
                ref_type: 2,
                ref_id: +selectedOrgId ? selectedOrgId : 0,
            })
        );
        if (selectedOrgId && value === 1) {
            setOrgSelectItem(selectedOrgId);
            dispatch(organisationView(selectedOrgId));
        } else {
            setOrgSelectItem("");
            dispatch({ type: ORG_VIEW_RESET });
        }
        setOrgActionType(value);
    };
    const onOrgViewDetails = () => {
        setOrgSelectItem(selectedOrgId);
        dispatch(organisationView(selectedOrgId));
        setOrgViewDetails(true);
    };
    const onEmailViewDetails = async () => {
        setOrgSelectItem(selectedOrgId);
        await dispatch(getEmailConfig(selectedOrgId, 1));
        setEmailViewDetails(true);
        localStorage.setItem("UpdateOrgId", selectedOrgId);
    };
    const onFolderViewDetails = async () => {
        setOrgSelectItem(selectedOrgId);
        await dispatch(getFolderConfig({ id: selectedOrgId }));
        setFolderViewDetails(true);
        localStorage.setItem("UpdateOrgId", selectedOrgId);
    };
    const reqObject = {
        org_id: +selectedOrgId,
    };
    const onUserRoleByOrgViewDetails = async () => {
        setOrgSelectItem(selectedOrgId);
        await dispatch(viewUserRoleByOrg(reqObject));
        setUserRoleFolderViewDetails(true);
    };
    const onSelectedItem = (orgId) => {
        setTreeOpen(false);
        setOrgSelectItem(orgId);
        const orgItem = orgList?.find((item) => item.id === orgId);
        if (orgItem) {
            setOrgSelectItemName(orgItem.organisation_name);
            setOrgStatus(orgItem.organisation_isactive);
            setUserCount(orgItem.user_count);
            setFolderCount(orgItem.folder_count);
            setEmailCount(orgItem.email_count);
            setTargetConfigCount(orgItem.targetconfig_count);
            setTargetConfigType(orgItem.target_type);
            setisDemo(orgItem?.demo_org);
            setisDemoDeleted(orgItem?.is_demodeleted);
        }
    };
    const filterMenuList = [
        {
            text: "All",
            src: "/images/common/all-ico.svg",
            value: 2,
            tabIndex: "0",
        },
        {
            text: "Active",
            src: "/images/common/active-ico.svg",
            value: 1,
            tabIndex: "0",
        },
        {
            text: "Inactive",
            src: "/images/common/inactive-ico.svg",
            value: 0,
            tabIndex: "0",
        },
    ];
    const statusMenuList = [
        { text: "Activate", src: "/images/common/active-ico.svg", value: 1 },
        { text: "Inactivate", src: "/images/common/plus.svg", value: 0 },
    ];
    const childCompRef = useRef();
    const childCompStatusRef = useRef();
    const OrgListFilter = orgList?.filter((elem) => elem?.organisation_isactive == true);
    if (selectedOrgName == "" && orgList && orgList?.length !== 0) {
        setOrgSelectItemName(OrgListFilter[0]?.organisation_name);
        setOrgStatus(OrgListFilter[0]?.organisation_isactive);
        setOrgSelectItem(OrgListFilter[0]?.id);
        setUserCount(OrgListFilter[0]?.user_count);
        setFolderCount(OrgListFilter[0]?.folder_count);
        setEmailCount(OrgListFilter[0]?.email_count);
        setTargetConfigCount(OrgListFilter[0]?.targetconfig_count);
        setTargetConfigType(OrgListFilter[0]?.target_type);
    }
    const DisplayRole = useSelector((state) => state?.DisplayRolePermissionStateAction);

    const { data: DataList } = DisplayRole;
    const RoleList = DataList?.role_info;
    const RoleMenu = RoleList?.menu;
    const Roleorg = RoleMenu?.find((elem) => elem?.menu_id === 1);
    const RoleOrgAction = Roleorg?.actions;
    const RoleActOrg = RoleOrgAction?.find((element) => element.id === 1);

    const DemoOrg = orgList?.map((el) => el?.demo_org)?.[0];
    const DeletedDemoOrg = orgList?.map((el) => el?.is_demodeleted)?.[0];
    const ShowProffesionIcon = currentUser?.license_type !== 4;
    const tourConfig = [
        {
            selector: ".first-step",
            content: "Click on this Add Organization button to add a new organisation",
        },
        {
            selector: ".second-step",
            content: "Click on this Organization kebab menu to view, edit and activate/inactivate the organisation",
        },
        {
            selector: ".email-step",
            content: "Click on this Email kebab menu to add and view/edit the email configurations",
        },
        {
            selector: ".folder-step",
            content: "Click on this Folder kebab menu to add and view/edit the folder configurations",
        },
        {
            selector: ".integrate-step",
            content: "Click on this Integrate dropdown to select the target",
        },
        {
            selector: ".target-step",
            content: "Click on this Target to configure the server",
        },
        {
            selector: ".users-step",
            content: "Click on this Users & Roles kebab menu to add and view/delete the users",
        },
    ];
    const { openTour, ProdTour } = UseTour(tourConfig);
    const { currentMode } = useContext(ThemeContext);
    let Orgid = JSON.parse(
        localStorage.getItem("selectedOrgId") == ("undefined" || null || undefined) ? "{}" : localStorage.getItem("selectedOrgId")
    );
    let emptyClick = localStorage.getItem("configClick");
    const handleCloseModal = () => {
        localStorage.setItem("selectedOrgId", Orgid);
        localStorage.setItem("UpdateOrgId", Orgid);
        setIsFolder(false);
        // window.location.reload();
    };
    const handleCloseEMailModal = () => {
        localStorage.setItem("selectedOrgId", Orgid);
        localStorage.setItem("UpdateOrgId", Orgid);
        setIsEmail(false);
        // window.location.reload();
    };

    return (
        <>
            <ProdTour />
            <Header />
            <Sidebar />
            <Main>
                <div className="flex justify-between items-center mb-6">
                    <div className="flex items-center space-x-2">
                        <h4 className={`text-2xl font-interSb text-${currentMode}-copy`}>Organization Management</h4>
                        {/* <img
              title="Organization Management Tour"
              className="cursor-pointer"
              onClick={openTour}
              src="/images/common/tour.svg"
              alt="tour"
            /> */}
                    </div>
                    {!treeOpen && orgList !== null && ShowProffesionIcon ? (
                        <div className="flex space-x-2">
                            <Button
                                title="To add new Organization"
                                onClick={() => Addsubmit(0)}
                                className={` first-step ${
                                    RoleActOrg?.isallowed == false && "hidden"
                                } border-${currentMode}-800 bg-light-20 px-5 py-2 rounded-[8px] font-interM text-sm flex items-center border-[1px] text-white `}
                            >
                                <img className="mr-1" src="/images/common/plus.svg" alt="plus" /> Add Organization
                            </Button>
                        </div>
                    ) : (
                        ""
                    )}
                </div>
                <div className="xl:flex xl:space-x-5">
                    <div className="2xl:basis-[33%] xl:basis-[31%] xl:min-w-[300px] mb-8 2xl:max-w-[420px]">
                        <Card className="px-5 pb-4 h-[calc(100vh_-_14rem)] overflow-y-auto">
                            <div className={`sticky top-0 pt-[1.5rem] z-[1] mx-[-20px] px-[20px] bg-${currentMode}-card`}>
                                <div className="flex space-x-2 mb-6">
                                    <div
                                        className={`bg-${currentMode}-230 flex flex-1 border-${currentMode}-560 border items-center justify-between py-[4px] px-[16px] min-w-[205px] rounded-[8px]`}
                                    >
                                        <Input
                                            placeholder="Search..."
                                            name="search"
                                            className="border-0 bg-transparent"
                                            disableUnderline={true}
                                            maxLength={250}
                                            onChange={(e) => handleSearchChange(e.target.value)}
                                            value={searchValue}
                                            autoComplete="off"
                                        />
                                        <div>
                                            {searchValue && searchValue.length !== 0 ? (
                                                <img onClick={() => handleSearchChange("")} src="/images/common/closeBlk.svg" alt="close" />
                                            ) : (
                                                <img src="/images/header/search.svg" alt="search" />
                                            )}
                                        </div>
                                        <TogglePopup ref={childCompRef} menuList={filterMenuList} selectedItemChange={onFilterItems} />
                                    </div>
                                    <div className="flex-inherit">
                                        <Button
                                            onClick={(e) => childCompRef.current.handleClick("bottom-end", 1, e)}
                                            className={`border-${currentMode}-560 bg-${currentMode}-360 px-3 py-2 rounded-[8px] font-interM text-sm flex items-center border-[1px] text-white`}
                                        >
                                            <img src="/images/common/filter-blue.svg" alt="filter-blue" title="For Advanced Filter" />
                                        </Button>
                                    </div>
                                </div>
                                <div className="mx-[-1.25rem] mb-6">
                                    <hr className={`border-${currentMode}-560`} />
                                </div>
                            </div>
                            <div className="space-y-4">
                                {!orgData?.requestComplete ? (
                                    <Loader className="h-[calc(100vh_-_15rem)]" />
                                ) : (orgList && orgList.length > 0) || DisplayRole?.fetching ? (
                                    <>
                                        <TogglePopup ref={childCompStatusRef} menuList={statusMenuList} selectedItemChange={onStatusChange} />
                                        <LeftPanel
                                            filterStatus={filterStatus}
                                            onSelectedItem={onSelectedItem}
                                            OrgList={orgList}
                                            searchValue={searchValue}
                                            selectedOrgName={selectedOrgName}
                                            OrgListFilter={OrgListFilter}
                                            setOrgSelectItemName={setOrgSelectItemName}
                                            treeOpen={treeOpen}
                                            selectedOrgId={selectedOrgId}
                                            statusUpdate={(e, id) => childCompStatusRef.current.handleClick("bottom-end", 1, e, id)}
                                        />
                                    </>
                                ) : (
                                    <div className="grid place-items-center text-center place-content-center h-[calc(100vh_-_37vh)]">
                                        <img className="mb-[2rem]" src="/images/common/delivery.png" alt="delivery" />
                                        <h5 className="font-interSb mb-1">No Organization found</h5>
                                        {/* <p className="text-xs">
                      Click add organisation button
                      <br /> then add your organisation details
                    </p> */}
                                    </div>
                                )}
                            </div>
                        </Card>
                    </div>
                    <div className="basis-[78%] overflow-hidden mb-[4rem] rounded-[10px]">
                        <Card className="pr-[5rem] text-light-copy pl-6 py-4  shadow-[0px_4px_20px_rgba(238,238,238,0.5)] h-[calc(100vh_-_14rem)]">
                            {!orgData?.requestComplete || DisplayRole?.fetching ? (
                                <Loader className="h-[calc(100vh_-_15rem)]" />
                            ) : (
                                <>
                                    {treeOpen || selectedOrgId === undefined ? (
                                        <Flow
                                            setOrgActionType={setOrgActionType}
                                            isAddNew={true}
                                            orgId={""}
                                            isdeomo={isdeomo}
                                            isdemoDeleted={isdemoDeleted}
                                            orgStatus={""}
                                            orgName={""}
                                            userCount={""}
                                            emailCount={""}
                                            folderCount={""}
                                            targetConfigCount={""}
                                            targetConfigType={""}
                                            statusChange={onStatusChange}
                                            orgOpen={Addsubmit}
                                            orgEditOpen={EditSubmit}
                                            orgView={onOrgViewDetails}
                                            EmailOpen={() => setIsEmail(true)}
                                            emailView={onEmailViewDetails}
                                            EmailviewOpen={() => setIsEmailView(true)}
                                            ServerOpen={() => setIsServer(true)}
                                            isServerOpen={isShowServer}
                                            FolderOpen={() => setIsFolder(true)}
                                            UserOpen={() => setIsUser(true)}
                                            folderView={onFolderViewDetails}
                                            userView={onUserRoleByOrgViewDetails}
                                        />
                                    ) : orgList?.length == 1 && DemoOrg && DeletedDemoOrg ? (
                                        <Flow
                                            isdeomo={DemoOrg}
                                            isdemoDeleted={DeletedDemoOrg}
                                            isAddNew={orgList && orgList.length > 0 ? false : true}
                                            setOrgActionType={setOrgActionType}
                                            orgList={orgList}
                                            orgId={selectedOrgId}
                                            orgStatus={orgStatus}
                                            orgName={selectedOrgName}
                                            userCount={userCount}
                                            emailCount={emailCount}
                                            folderCount={folderCount}
                                            treeOpen={treeOpen}
                                            targetConfigCount={targetConfigCount}
                                            targetConfigType={targetConfigType}
                                            statusChange={onStatusChange}
                                            orgOpen={Addsubmit}
                                            orgEditOpen={EditSubmit}
                                            orgView={onOrgViewDetails}
                                            EmailOpen={() => setIsEmail(true)}
                                            emailView={onEmailViewDetails}
                                            EmailviewOpen={() => setIsEmailView(true)}
                                            ServerOpen={() => setIsServer(true)}
                                            isServerOpen={isShowServer}
                                            FolderOpen={() => setIsFolder(true)}
                                            UserOpen={() => setIsUser(true)}
                                            folderView={onFolderViewDetails}
                                            userView={onUserRoleByOrgViewDetails}
                                        ></Flow>
                                    ) : (
                                        <Flow
                                            isAddNew={orgList && orgList.length > 0 ? false : true}
                                            isdeomo={isdeomo}
                                            isdemoDeleted={isdemoDeleted}
                                            setOrgActionType={setOrgActionType}
                                            orgList={orgList}
                                            orgId={selectedOrgId}
                                            orgStatus={orgStatus}
                                            orgName={selectedOrgName}
                                            userCount={userCount}
                                            emailCount={emailCount}
                                            folderCount={folderCount}
                                            treeOpen={treeOpen}
                                            targetConfigCount={targetConfigCount}
                                            targetConfigType={targetConfigType}
                                            statusChange={onStatusChange}
                                            orgOpen={Addsubmit}
                                            orgEditOpen={EditSubmit}
                                            orgView={onOrgViewDetails}
                                            EmailOpen={() => setIsEmail(true)}
                                            emailView={onEmailViewDetails}
                                            EmailviewOpen={() => setIsEmailView(true)}
                                            ServerOpen={() => setIsServer(true)}
                                            isServerOpen={isShowServer}
                                            FolderOpen={() => setIsFolder(true)}
                                            UserOpen={() => setIsUser(true)}
                                            folderView={onFolderViewDetails}
                                            userView={onUserRoleByOrgViewDetails}
                                        />
                                    )}
                                </>
                            )}
                        </Card>
                    </div>
                </div>
            </Main>
            <Footer />
            {ReactDOM.createPortal(
                <AddOrganisation
                    orgList={orgList}
                    actionType={orgActionType}
                    orgId={selectedOrgId}
                    isOpened={isAddorEdit}
                    handleClose={() => setIsAddorEdit(false)}
                    className={isAddorEdit && "Show"}
                />,
                document.getElementById("user-modal-root")
            )}
            {ReactDOM.createPortal(
                <ViewOrganisation
                    orgId={selectedOrgId}
                    isOpened={isOrgViewDetails}
                    handleClose={() => setOrgViewDetails(false)}
                    className={isOrgViewDetails && "Show"}
                />,
                document.getElementById("user-modal-root")
            )}

            {isEmail &&
                ReactDOM.createPortal(
                    <EmailConfigs
                        orgId={selectedOrgId}
                        isOpened={isEmail}
                        handleClose={handleCloseEMailModal}
                        className={isEmail && "Show"}
                        updateOrgList={() => getOrganisationDetails(reqObj)}
                    />,
                    document.getElementById("user-modal-root")
                )}
            {isFolder &&
                ReactDOM.createPortal(
                    <FolderConfigs
                        orgId={selectedOrgId}
                        isOpened={isFolder}
                        handleClose={handleCloseModal}
                        className={isFolder && "Show"}
                        updateOrgList={() => getOrganisationDetails(reqObj)}
                    />,
                    document.getElementById("user-modal-root")
                )}
            {ReactDOM.createPortal(
                <ServerConfigs
                    orgList={orgList}
                    orgId={selectedOrgId}
                    orgStatus={orgStatus}
                    isOpened={isServer}
                    handleClose={() => setIsServer(false)}
                    className={isServer && "Show"}
                />,
                document.getElementById("user-modal-root")
            )}
            {ReactDOM.createPortal(
                <UserRole orgId={selectedOrgId} isOpened={isUser} handleClose={() => setIsUser(false)} className={`${isUser && "Show"}`} />,
                document.getElementById("user-modal-root")
            )}
            {ReactDOM.createPortal(
                <ViewEmailConfigs
                    orgId={selectedOrgId}
                    orgStatus={orgStatus}
                    isOpened={isEmailViewDetails}
                    handleClose={() => setEmailViewDetails(false)}
                    className={isEmailViewDetails && "Show"}
                />,
                document.getElementById("user-modal-root")
            )}
            {ReactDOM.createPortal(
                <ViewFolderConfigs
                    orgId={selectedOrgId}
                    orgStatus={orgStatus}
                    isOpened={isFolderViewDetails}
                    handleClose={() => setFolderViewDetails(false)}
                    className={`${isFolderViewDetails && "Show"}`}
                />,
                document.getElementById("user-modal-root")
            )}
            {ReactDOM.createPortal(
                <UserRole orgId={selectedOrgId} isOpened={isUser} handleClose={() => setIsUser(false)} className={`${isUser && "Show"}`} />,
                document.getElementById("user-modal-root")
            )}
            {ReactDOM.createPortal(
                <UserRoleView
                    orgId={selectedOrgId}
                    orgStatus={orgStatus}
                    isOpened={isUserRoleViewDetails}
                    handleClose={() => setUserRoleFolderViewDetails(false)}
                    className={`${isUserRoleViewDetails && "Show"}`}
                />,
                document.getElementById("user-modal-root")
            )}
        </>
    );
};

export default OrganisationListing;
