import { useState, useEffect, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import Button from "../../components/Button";
import { ThemeContext } from "../../Hooks/Themes/Themeprovider";

const LeftPanel = (props) => {
    const params = new URLSearchParams(window.location.search);
    const organisation_id = params.get("org");
    if (organisation_id) {
        localStorage.setItem("selectedOrgId", organisation_id);
    }
    const searchValue = props?.searchValue;
    const navigate = useNavigate();
    let filterSearch = false;
    const treeOpen = props?.treeOpen;
    let selectedOrgId = localStorage.getItem("selectedOrgId");
    let selectedOrgIndex = 0;
    const { OrgList } = props;
    const OrgListFilter = props?.OrgListFilter?.filter((el) => el?.organisation_isactive === true);
    const OrgHighlight = OrgListFilter?.filter((el) =>
        OrgListFilter?.length == 1 && el?.demo_org == true && el?.is_demodeleted == false
            ? el?.organisation_isactive === true
            : el?.organisation_isactive === true && el?.demo_org !== true
    );
    const [orgSelect, setOrgSelection] = useState(0);
    const onItemSelect = (index, OrgId) => {
        props.onSelectedItem(OrgId);
        localStorage.setItem("selectedOrgId", OrgId);
        setOrgSelection(index);
    };
    useEffect(() => {
        if (selectedOrgId == null || (selectedOrgId == null && searchValue?.length == 0)) {
            localStorage.setItem("selectedOrgId", OrgHighlight?.[0]?.id);
            selectedOrgIndex = OrgList?.findIndex((item) => item.id == OrgHighlight[0]?.id);
            filterSearch = false;
            selectedOrgId = OrgHighlight?.[0]?.id;
            onItemSelect(selectedOrgIndex, OrgHighlight?.[0]?.id);
            props?.setOrgSelectItemName(OrgHighlight?.[0]?.organisation_name);
        }
        if (props?.filterStatus) {
            selectedOrgIndex = 0;
            selectedOrgId = OrgList[0]?.id;
            onItemSelect(selectedOrgIndex, selectedOrgId);
            props?.setOrgSelectItemName(OrgList[0]?.organisation_name);
            filterSearch = false;
        } else {
            const getOrgid = localStorage?.getItem("UpdateOrgId");
            const updateOrgId =
                getOrgid != 0 && getOrgid != "0" && getOrgid != "null" && getOrgid != null && getOrgid != undefined && getOrgid != "undefined"
                    ? JSON.parse(localStorage?.getItem("UpdateOrgId"))
                    : null;
            if (localStorage.getItem("UpdateOrgId") == undefined || localStorage.getItem("UpdateOrgId") == "undefined") {
                localStorage.setItem("UpdateOrgId", null);
            }

            if (updateOrgId !== null) {
                selectedOrgIndex = OrgList.findIndex((item) => item.id == updateOrgId);
                filterSearch = false;
                selectedOrgId = updateOrgId;
                onItemSelect(selectedOrgIndex, updateOrgId);
                props?.setOrgSelectItemName(OrgList[selectedOrgIndex]?.organisation_name);
                localStorage.setItem("UpdateOrgId", null);
            } else {
                selectedOrgIndex = OrgList?.findIndex((item) => item.id == OrgHighlight?.[0]?.id);
                filterSearch = false;
                selectedOrgId = OrgHighlight?.[0]?.id;
                onItemSelect(selectedOrgIndex, OrgHighlight?.[0]?.id);
                props?.setOrgSelectItemName(OrgHighlight?.[0]?.organisation_name);
            }
        }
    }, [props?.filterStatus]);

    useEffect(() => {
        if (selectedOrgId !== "0" || selectedOrgId !== 0) {
            selectedOrgIndex = OrgList?.findIndex((item) => item.id == selectedOrgId);
            onItemSelect(selectedOrgIndex, selectedOrgId);
            props?.setOrgSelectItemName(OrgList?.[0]?.organisation_name);
            const selVal = document.getElementById("span-" + selectedOrgId);
            if (selVal) {
                selVal.click();
                selVal.scrollIntoView({ behavior: "smooth", block: "center" });
            }
            if (organisation_id == selectedOrgId) {
                navigate("/organisations");
            }
        }
    }, [orgSelect, searchValue]);
    const { currentMode } = useContext(ThemeContext);
    return (
        <div className="space-y-4">
            {OrgList?.map((el, index) => {
                return (
                    <div
                        key={el?.id}
                        id={"span-" + el?.id}
                        onClick={() => onItemSelect(index, el?.id)}
                        className={`${`flex active justify-between cursor-pointer items-start bg-${currentMode}-230 border-${currentMode}-600 border-[1px] rounded-[8px] p-[15px]`} ${
                            !treeOpen ? (orgSelect == index ? `orgCard bg-${currentMode}-trans` : "") : ""
                        } `}
                    >
                        <div className="flex w-full">
                            <div className="mr-3 flex-none">
                                <img
                                    className="w-[26px] rounded-full object-cover h-[26px]"
                                    src={el?.logo ? `data:image;base64,${el?.logo}` : "/images/common/orgLogo.png"}
                                    alt="iq-logo"
                                />
                            </div>
                            <div className="flex-initial w-full">
                                <div className="flex justify-between">
                                    <h5 className="mb-2 font-interM trunc-2 max-w-[500px] xl:max-w-[180px]">{el?.organisation_name}</h5>
                                    {/* <Button onClick={(e)=>props.statusUpdate(e, el.id)}>
                    <img
                      src="/images/common/threeDots.svg"
                      alt="threeDots"
                    />
                  </Button> */}
                                </div>
                                <span className="text-sm inline-block trunc-1 w-[220px] mb-1">{el?.url}</span>
                                <div className="flex justify-between">
                                    <span className="text-sm block flex-1">{el?.organisation_country.country_name}</span>
                                    <Button
                                        className={`${
                                            el?.organisation_isactive ? `bg-light-20` : `bg-light-550`
                                        } w-[18px] flex justify-center items-center h-[18px] rounded-[50%]`}
                                    >
                                        <img
                                            src={`${el?.organisation_isactive ? `/images/common/tickWhite.svg` : `/images/common/close-small.svg`}`}
                                            className="w-[10px] object-contain"
                                            alt="tickWhite"
                                        />
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export default LeftPanel;
