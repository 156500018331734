import axios from "axios";
import configMyiQ from "../../configuration";
import authHeader from "./auth-header";
import localforage from "localforage";
import Cookies from "js-cookie";
import {
    PROFILE_EDIT_FAILURE,
    PROFILE_EDIT_REQUEST,
    PROFILE_EDIT_SUCCESS,
    PROFILE_VIEW_FAILURE,
    PROFILE_VIEW_REQUEST,
    PROFILE_VIEW_SUCCESS,
    PROFILE_DELETE_FAILURE,
    PROFILE_DELETE_REQUEST,
    PROFILE_DELETE_SUCCESS,
} from "../types/types";
import { checkApiStatus } from "../../utils/helpers";
const Token = localStorage.getItem("token");
const API_URL = process.env?.REACT_APP_API_URL;
const NODE_API_URL = process.env?.REACT_APP_NODE_API_URL;
export const ProfileviewList = (data) => async (dispatch) => {
    dispatch({ type: PROFILE_VIEW_REQUEST });
    let headerObj = authHeader();
    axios
        .get(`${API_URL}User/GetUserById?id=${data.id}`, {
            headers: headerObj,
        })
        .then((response) => {
            dispatch({
                type: PROFILE_VIEW_SUCCESS,
                payload: response.data?.data,
            });
        })
        .catch((error) => {
            if (error?.response?.status == 401) {
                checkApiStatus();
            }
            dispatch({
                type: PROFILE_VIEW_FAILURE,
            });
        });
};

export const Profileedit = (data) => async (dispatch) => {
    try {
        dispatch({ type: PROFILE_EDIT_REQUEST });

        axios
            .post(`${API_URL}User/UpdateUser`, data, {
                headers: authHeader(),
            })
            .then((response) => {
                dispatch({
                    type: PROFILE_EDIT_SUCCESS,
                    payload: response.data,
                });
            })
            .catch(function (error) {
                if (error?.response?.status == 401) {
                    checkApiStatus();
                }
                dispatch({
                    payload: { error: error.message },
                    type: PROFILE_EDIT_FAILURE,
                });
            });
    } catch (error) {
        dispatch({
            type: PROFILE_EDIT_FAILURE,
        });
    }
};

export const ProfileDelete = (id) => async (dispatch) => {
    try {
        dispatch({ type: PROFILE_DELETE_REQUEST });

        axios
            .get(`${API_URL}User/DeletePhoto?id=${id}&isdefault_img=${true}`, {
                headers: authHeader(),
            })
            .then((response) => {
                dispatch({
                    type: PROFILE_DELETE_SUCCESS,
                    payload: response.data,
                });
            })
            .catch(function (error) {
                if (error?.response?.status == 401) {
                    checkApiStatus();
                }
                dispatch({
                    payload: { error: error.message },
                    type: PROFILE_DELETE_FAILURE,
                });
            });
    } catch (error) {
        dispatch({
            type: PROFILE_DELETE_FAILURE,
        });
    }
};
