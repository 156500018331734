import React, { useState, useEffect } from 'react';
import Input from "../../components/Input";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { MenuItem } from "@mui/material";


export default function SearchDate() {

    const dispatch = useDispatch();


    const settingDate = useSelector((state) => state?.profileState);
    const { setting: viewDate } = settingDate;
    const Id = localStorage.getItem("mainid");

    // useEffect(() => {
    //   dispatch(settingdate({ id: +Id }));
    // }, [dispatch]);


    // const [filteredResults, setFilteredResults] = useState([]);
    // const [searchInput, setSearchInput] = useState('');


    // const searchItems = (searchValue) => {
    //     setSearchInput(searchValue)
    //     if (searchInput !== '') {
    //         const filteredData = APIData.filter((item) => {
    //             return Object.values(item).join('').toLowerCase().includes(searchInput.toLowerCase())
    //         })
    //         setFilteredResults(filteredData)
    //     }
    //     else{
    //         setFilteredResults(APIData)
    //     }


    return (
        <div style={{ padding: 20 }}>
            <Input className="bg-white mx-3 mb-4 border-[rgba(223, 225, 226, 0.56)] shadow-[0px_4px_20px_rgba(238,238,238,0.501967)] border-[1px] flex justify-between py-[8px] px-[16px] rounded-[8px]" type="text" >
                <img
                    src="/images/profile/searchsmall.svg"
                    className="mr-3"
                    alt="search"
                />

            </Input>
            <MenuItem>
                {viewDate?.data_list}
                <span className="text-light-90 ml-1"></span>
            </MenuItem>
            {/* <div itemsPerRow={3} style={{ marginTop: 20 }}>
                {searchInput.length > 1 ? (
                    filteredResults.map((item) => {
                        return (
                            <>
                         <MenuItem>
                          {item.datalist}
                            <span className="text-light-90 ml-1"> </span>
                        </MenuItem>
                        </>
                   
                        )
                    })
                ) : (
                   
                        )
                   
                }
            </div> */}
        </div>
    )
}

