import Modal from "../../components/UI/Modal/Modal";
import { ThemeContext } from "../../Hooks/Themes/Themeprovider";
import { useContext, useMemo, useState } from "react";
import Input from "../../components/Input";
import Button from "../../components/Button";
import { FormControl, InputAdornment, ListSubheader, MenuItem, Select, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

const AddExceptionCategory = (props) => {
    const { currentMode, toggleMode } = useContext(ThemeContext);
    const dropdownColor = currentMode === "dark" ? "#333333" : `white`;
    const dropdownText = currentMode === "dark" ? "#ECECEC" : `#555555`;
    const [category, setCategory] = useState("");
    const [searchText, setSearchText] = useState("");
    const Exceptions = [
        "PO not released ",
        "GRN Incomplete",
        "Invoice Variance",
        "Quantity Variance",
        "Currency Mismatch",
        "Incorrect Invoice Amount",
    ];
    const handleCategoryChange = (value) => {
        setCategory(value);
    };
    const containsText = (text, searchText) => text.toLowerCase().indexOf(searchText.toLowerCase()) > -1;
    const displayedOptions = useMemo(() => Exceptions?.filter((option) => containsText(option, searchText)), [searchText]);
    const ExceptionsMenu = searchText !== "" ? displayedOptions : Exceptions;
    return (
        <>
            <Modal cartClose={props?.handleClose} center="center" overlap={true} className={`${props?.className}`}>
                <div
                    className={`max-w-[500px] mx-auto relative bg-${currentMode}-card  boder-${currentMode}-700 text-${currentMode}-copy px-[90px] mx-auto  py-[48px] rounded-[10px] shadow-[0px_4px_20px_rgba(238,238,238,0.5)]`}
                >
                    <span onClick={props?.handleClose} title="Close" className="cursor-pointer absolute right-[20px] top-[20px]">
                        <img src="/images/common/closeBlk.svg" alt="closeBlk" />
                    </span>

                    <span className={`block text-sm pb-3`}>
                        <span className="text-[red] mr-1">*</span>
                        Select Category
                    </span>

                    <div id="myView" className="overflow-y-auto max-h-[20rem] pr-[20px] py-[11px] px-[10px]">
                        <div className="flex items-center space-x-3 mb-[1rem]">
                            <FormControl fullWidth>
                                <Select
                                    labelId="search-select-label"
                                    id="search-select"
                                    value={category}
                                    label="Options"
                                    onChange={(e) => handleCategoryChange(e?.target?.value)}
                                    onClose={() => setSearchText("")}
                                    renderValue={() => category}
                                    className={`w-full bg-${currentMode}-810 text-${currentMode}-copy`}
                                    MenuProps={{
                                        autoFocus: false,
                                        sx: {
                                            "& .MuiMenu-paper": {
                                                backgroundColor: dropdownColor,
                                                color: dropdownText,
                                            },
                                            "& .MuiMenuItem-root:hover": {
                                                backgroundColor: "rgba(218, 249, 244, 0.3)",
                                            },
                                            "& .Mui-selected": {
                                                backgroundColor: "rgba(218, 249, 244, 0.3) !important",
                                            },
                                        },
                                    }}
                                    SelectDisplayProps={{
                                        style: {
                                            color: `${currentMode !== "dark" ? `#555555` : `#ECECEC`}`,
                                        },
                                    }}
                                    IconComponent={() => (
                                        <img
                                            className="absolute right-[15px] opacity-[.5] pointer-events-none"
                                            src={currentMode !== "dark" ? `/images/header/chevronDown.svg` : `/images/header/chevronDown-white.svg`}
                                            alt="chevronDown"
                                        />
                                    )}
                                >
                                    <ListSubheader>
                                        <TextField
                                            size="small"
                                            autoFocus
                                            placeholder="Type your own category"
                                            className="border-l"
                                            fullWidth
                                            InputProps={{
                                                startAdornment: <InputAdornment position="start"></InputAdornment>,
                                            }}
                                            onChange={(e) => setSearchText(e.target.value)}
                                            onKeyDown={(e) => {
                                                if (e.key !== "Escape") {
                                                    e.stopPropagation();
                                                }
                                            }}
                                        />
                                    </ListSubheader>
                                    <MenuItem selected value={"Select"}>
                                        {"Select"}
                                    </MenuItem>
                                    {ExceptionsMenu?.map((option, i) => (
                                        <MenuItem key={i} value={option}>
                                            {option}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                    </div>

                    <div className="flex items-center mt-7 space-x-3">
                        <Button
                            className="bg-light-20 px-[103px] py-[14px] text-white rounded-[8px] w-[350px]"
                            type="submit"
                            title="Save"
                            //   disabled={fetching}
                        >
                            Create Exception Category
                        </Button>
                    </div>
                </div>
            </Modal>
        </>
    );
};
export default AddExceptionCategory;
