import { useContext, useEffect, useState } from "react";
import { ThemeContext } from "./Hooks/Themes/Themeprovider";
import { AllRoutes } from "./routes";
import { useDispatch } from "react-redux";
import Cookies from "js-cookie";
import { useNavigate, useLocation } from "react-router-dom";
import swal from "sweetalert";
import Swal from "sweetalert2";
import { getCurrentUser, LogoutRequest } from "./redux/actions/auth";
import { ChatBot } from "./organisms/ChatBot";
import { Rnd } from "react-rnd";
function App() {
	let timer = 0;
	const events = ["mousemove", "keypress", "load", "onchange", "onclick", "onkeydown", "onload", "DOMContentLoaded"];
	let timerValue = 0;
	let currentTimeCheck = 0;
	const resetTimer = () => {
		clearInterval(timer);
		timerValue = 0;
		handleSessionStart();
	};
	const { currentMode, toggleMode } = useContext(ThemeContext);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();
	const removeEventTimer = () => {
		clearInterval(timer);
		timerValue = 0;
	};
	let lastTimer;

	const logoutsubmit = async () => {
		let myItem = localStorage.getItem("customOptions");
		let myAdvanceItem = localStorage.getItem("advanceListOption");
		let myMiscItem = localStorage.getItem("MiscListOption");
		let intervalItem = localStorage.getItem("PlayInterval");
		let newItem = localStorage.getItem("ageingOption");
		let folderAccess = localStorage.getItem("FolderAccess");
		let secret = localStorage.getItem("secretId");
		dispatch(LogoutRequest());
		localStorage.clear();
		localStorage.setItem("advanceListOption", myAdvanceItem);
		localStorage.setItem("MiscListOption", myMiscItem);
		localStorage.setItem("customOptions", myItem);
		localStorage.setItem("PlayInterval", intervalItem);
		localStorage.setItem("ageingOption", newItem);
		localStorage.setItem("FolderAccess", folderAccess);
		localStorage.setItem("secretId", secret);
		Cookies.remove("jwt");
		setTimeout(function () {
			navigate("/");
			toggleMode("light");
		}, 1000);
	};
	const sessionTimerStart = () => {
		timerValue++;
		if (timerValue === parseInt(process.env?.REACT_APP_SESSION_TIMEOUT_VALUE)) {
			clearInterval(timer);
			let remainingTime = 60;
			let timerText = "You will be logged out within #1 seconds,Do you want to continue?";
			Swal?.fire({
				title: "Your session is about to expire",
				icon: "warning",
				html: `<div class="swal-text text-left my-0 pl-0">${timerText.replace(/#1/, remainingTime)}</div>`,
				closeOnClickOutside: false,
				timer: 60 * 1000,
				showCancelButton: true,
				showCloseButton: true,
				cancelButtonText: "Logout",
				confirmButtonText: "Continue",
				buttons: {
					confirm: "Confirm",
					cancel: "Cancel",
				},
			}).then((confirm) => {
				if (confirm?.isConfirmed) {
					clearInterval(timer);
					clearInterval(lastTimer);
					resetTimer();
				} else {
					clearInterval(timer);
					logoutsubmit();
				}
			});
			lastTimer = setInterval(() => {
				remainingTime--;
				const updatedText = timerText.replace(/#1/, remainingTime);
				document.querySelector(".swal-text").innerHTML = updatedText;
				if (remainingTime < 0) {
					clearInterval(lastTimer);
					clearInterval(timer);
					logoutsubmit();
				}
			}, parseInt(process.env?.REACT_APP_SESSION_RUNNING_TIME));
		}
	};
	if ("serviceWorker" in navigator) {
		navigator.serviceWorker.ready.then((registration) => {
			registration.unregister();
		});
	}
	const handleSessionStart = () => {
		let dates = 0;
		if (events) {
			dates = new Date()?.toLocaleTimeString();
		}
		if (location.pathname !== "/") {
			timer = setInterval(() => {
				currentTimeCheck = new Date()?.toLocaleTimeString();
				const minutesDifference = calculateTimeDifferenceInMinutes(dates, currentTimeCheck);
				sessionTimerStart();
				if (minutesDifference > 14) {
					clearInterval(timer);
					let remainingTime = 60;
					let timerText = "You will be logged out within #1 seconds,Do you want to continue?";
					Swal?.fire({
						title: "Your session is about to expire",
						icon: "warning",
						closeOnClickOutside: false,
						html: `<div class="swal-text text-left my-0 pl-0">${timerText.replace(/#1/, remainingTime)}</div>`,
						timer: 60 * 1000,
						showCancelButton: true,
						showCloseButton: true,
						cancelButtonText: "Logout",
						confirmButtonText: "Continue",
						buttons: {
							confirm: "Confirm",
							cancel: "Cancel",
						},
					}).then((confirm) => {
						if (confirm?.isConfirmed) {
							// clearInterval(timer);
							clearInterval(lastTimer);
							resetTimer();
						} else {
							clearInterval(timer);
							// clearInterval(lastTimer);
							logoutsubmit();
						}
					});
					lastTimer = setInterval(() => {
						remainingTime--;
						const updatedText = timerText.replace(/#1/, remainingTime);
						document.querySelector(".swal-text").innerHTML = updatedText;
						if (remainingTime < 0) {
							clearInterval(lastTimer);
							clearInterval(timer);
							logoutsubmit();
						}
					}, parseInt(process.env?.REACT_APP_SESSION_RUNNING_TIME));
				}
				if (minutesDifference > 15) {
					let myAdvanceItem = localStorage.getItem("advanceListOption");
					let myMiscItem = localStorage.getItem("MiscListOption");
					let myItem = localStorage.getItem("customOptions");
					let intervalItem = localStorage.getItem("PlayInterval");
					let newItem = localStorage.getItem("ageingOption");
					let folderAccess = localStorage.getItem("FolderAccess");
					let secret = localStorage.getItem("secretId");
					localStorage.clear();
					Cookies.remove("jwt");
					localStorage.setItem("customOptions", myItem);
					localStorage.setItem("advanceListOption", myAdvanceItem);
					localStorage.setItem("MiscListOption", myMiscItem);
					localStorage.setItem("PlayInterval", intervalItem);
					localStorage.setItem("ageingOption", newItem);
					localStorage.setItem("FolderAccess", folderAccess);
					localStorage.setItem("secretId", secret);
					window.location.href = "/";
					toggleMode("light");
				}
			}, parseInt(process.env?.REACT_APP_SESSION_RUNNING_TIME));
		}
	};
	const calculateTimeDifferenceInMinutes = (time1, time2) => {
		// Convert the time strings to Date objects
		const date1 = new Date(`January 1, 2023 ${time1}`);
		const date2 = new Date(`January 1, 2023 ${time2}`);
		// Calculate the time difference in milliseconds
		const timeDifferenceInMilliseconds = date2 - date1;
		// Convert milliseconds to minutes
		const minutesDifference = timeDifferenceInMilliseconds / (1000 * 60);
		// Round the result to avoid floating-point errors
		return minutesDifference;
	};
	const currentUser = getCurrentUser();

	// Compare year, month, day, hour, and minute values
	const date1 = new Date();
	const date2 = new Date(currentUser?.expiring_date);

	// Compare year, month, day, hour, and minute values
	const areSame =
		date1?.getFullYear() === date2?.getFullYear() &&
		date1?.getMonth() === date2?.getMonth() &&
		date1?.getDate() === date2?.getDate() &&
		date1?.getHours() === date2?.getHours() &&
		date1?.getMinutes() === date2?.getMinutes() &&
		date1?.getSeconds() === date2?.getSeconds();
	useEffect(() => {
		events.forEach((event) => {
			window.addEventListener(event, resetTimer);
		});
		resetTimer();
		return () => {
			events.forEach((event) => {
				window.removeEventListener(event, removeEventTimer);
			});
		};
	}, [dispatch]);

	useEffect(() => {
		if (window.addEventListener) {
			window.addEventListener("orientationchange", function () {
				window.location.reload();
			});
		}
		const keepAliveInterval = setInterval(() => {
			navigator.keepAlive && navigator.keepAlive();
		}, 600000); // 10 minutes in milliseconds

		return () => clearInterval(keepAliveInterval);
	}, []);
	useEffect(() => {
		if (areSame && location.pathname !== "/" && location.pathname !== "/signup") {
			Swal?.fire({
				icon: "warning",
				text: "Your trial period has expired",
				closeOnClickOutside: false,
				buttons: {
					confirm: {
						text: "OK",
						value: true,
						visible: true,
					},
				},
			}).then((confirm) => {
				if (confirm) {
					logoutsubmit();
				}
			});
			setTimeout(() => {
				logoutsubmit();
				swal?.close();
			}, 7000);
		}
		let body = document.querySelector("body");
		if (body) {
			if (currentMode === "dark") {
				body.classList.add(`dark`);
				body.classList.remove("classic");
				body.classList.remove("light");
			} else if (currentMode === "light") {
				body.classList.add(`light`);
				body.classList.remove("classic");
				body.classList.remove("dark");
			} else if (currentMode === "classic") {
				body.classList.add(`classic`);
				body.classList.remove("dark");
				body.classList.remove("light");
			}
		}
	}, [currentMode, areSame]);

	const [isMobile, setisMobile] = useState(window.innerWidth < 768);

	const updateMedia = () => {
		setisMobile(window.innerWidth < 768);
	};

	useEffect(() => {
		window.addEventListener("resize", updateMedia);
		return () => window.removeEventListener("resize", updateMedia);
	});
	const checkpath = location.pathname == "/" || location.pathname == "/signup";

	return (
		<>
			{!checkpath && isMobile && (
				<div
					className={`fixed p-10 text-center top-0 left-0 right-0 w-screen h-screen bg-${currentMode}-20 z-[12] grid place-items-center place-content-center`}
				>
					<div className="bg-white rounded-[8px] py-[30px] px-[25px]">
						<div className="mx-auto mb-5">
							<img
								className="mx-auto"
								src="/images/main/descripe-ico.svg"
								alt="descripe-ico"
							/>
						</div>
						<h4 className="font-interSb text-2xl mb-3">Try This Tool for Free!</h4>
						<p className="text-base">For the best experience, open this tool on a Desktop/Tab device to upload your documents.</p>
					</div>
				</div>
			)}
			<AllRoutes />
		</>
	);
}

export default App;
