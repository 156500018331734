import {
  GET_RULE_LIST_REQUEST,
  GET_RULE_LIST_SUCCESS,
  GET_RULE_LIST_FAILURE,
  GET_RULE_LIST_RESET,
  ADD_RULE_LIST_REQUEST,
  ADD_RULE_LIST_SUCCESS,
  ADD_RULE_LIST_FAILURE,
  ADD_RULE_LIST_RESET,
  GET_VIEW_RULE_REQUEST,
  GET_VIEW_RULE_SUCCESS,
  GET_VIEW_RULE_RESET,
  GET_VIEW_RULE_FAILURE,
  FIELDS_RULE_LIST_REQUEST,
  FIELDS_RULE_LIST_SUCCESS,
  FIELDS_RULE_LIST_FAILURE,
  OPERATORS_RULE_LIST_REQUEST,
  OPERATORS_RULE_LIST_SUCCESS,
  OPERATORS_RULE_LIST_FAILURE,
  FIELDS_RULE_LIST_RESET,
  OPERATORS_RULE_LIST_RESET,
  GET_RULE_VIEW_REQUEST,
  GET_RULE_VIEW_SUCCESS,
  GET_RULE_VIEW_FAILURE,
  GET_RULE_VIEW_RESET,
  ACTIVATE_DEACTIVATE_RULE_REQUEST,
  ACTIVATE_DEACTIVATE_RULE_SUCCESS,
  ACTIVATE_DEACTIVATE_RULE_FAILURE,
  ACTIVATE_DEACTIVATE_RULE_RESET,
} from "../types/types.js";

const intRuleList = {
  data: [],
  fetching: false,
  requestComplete: false,
  error: "",
};

export function GetRulebasedList(state = intRuleList, action) {
  switch (action.type) {
    case GET_RULE_LIST_REQUEST:
      return {
        ...state,
        fetching: true,
        requestComplete: false,
        error: "",
      };
    case GET_RULE_LIST_SUCCESS:
      return {
        data: action.payload,
        fetching: false,
        requestComplete: true,
        error: "",
      };
    case GET_RULE_LIST_RESET:
      return {
        data: [],
        fetching: false,
        requestComplete: false,
        error: "",
      };
    case GET_RULE_LIST_FAILURE:
      return {
        fetching: false,
        requestComplete: false,
        error: action.payload,
      };
    default:
      return state;
  }
}

const intAddRule = {
  data: [],
  fetching: false,
  requestComplete: false,
  error: "",
};

export function AddRulebased(state = intAddRule, action) {
  switch (action.type) {
    case ADD_RULE_LIST_REQUEST:
      return {
        ...state,
        fetching: true,
        requestComplete: false,
        error: "",
      };
    case ADD_RULE_LIST_SUCCESS:
      return {
        data: action.payload,
        fetching: false,
        requestComplete: true,
        error: "",
      };
    case ADD_RULE_LIST_RESET:
      return {
        data: [],
        fetching: false,
        requestComplete: false,
        error: "",
      };
    case ADD_RULE_LIST_FAILURE:
      return {
        fetching: false,
        requestComplete: false,
        error: action.payload,
      };
    default:
      return state;
  }
}

const intFieldRule = {
  data: [],
  fetching: false,
  requestComplete: false,
  error: "",
};

export function FieldRuleBesed(state = intFieldRule, action) {
  switch (action.type) {
    case FIELDS_RULE_LIST_REQUEST:
      return {
        ...state,
        fetching: true,
        requestComplete: false,
        error: "",
      };
    case FIELDS_RULE_LIST_SUCCESS:
      return {
        data: action.payload,
        fetching: false,
        requestComplete: true,
        error: "",
      };
    case FIELDS_RULE_LIST_RESET:
      return {
        data: [],
        fetching: false,
        requestComplete: false,
        error: "",
      };
    case FIELDS_RULE_LIST_FAILURE:
      return {
        fetching: false,
        requestComplete: false,
        error: action.payload,
      };
    default:
      return state;
  }
}

const intOperator = {
  data: [],
  fetching: false,
  requestComplete: false,
  error: "",
};

export function OperatorRuleBased(state = intOperator, action) {
  switch (action.type) {
    case OPERATORS_RULE_LIST_REQUEST:
      return {
        ...state,
        fetching: true,
        requestComplete: false,
        error: "",
      };
    case OPERATORS_RULE_LIST_SUCCESS:
      return {
        data: action.payload,
        fetching: false,
        requestComplete: true,
        error: "",
      };
    case OPERATORS_RULE_LIST_RESET:
      return {
        data: [],
        fetching: false,
        requestComplete: false,
        error: "",
      };
    case OPERATORS_RULE_LIST_FAILURE:
      return {
        fetching: false,
        requestComplete: false,
        error: action.payload,
      };
    default:
      return state;
  }
}

const intRuleView = {
  data: [],
  fetching: false,
  requestComplete: false,
  error: "",
};

export function RuleView(state = intRuleView, action) {
  switch (action.type) {
    case GET_RULE_VIEW_REQUEST:
      return {
        ...state,
        fetching: true,
        requestComplete: false,
        error: "",
      };
    case GET_RULE_VIEW_SUCCESS:
      return {
        data: action.payload,
        fetching: false,
        requestComplete: true,
        error: "",
      };
    case GET_RULE_VIEW_RESET:
      return {
        data: [],
        fetching: false,
        requestComplete: false,
        error: "",
      };
    case GET_RULE_VIEW_FAILURE:
      return {
        fetching: false,
        requestComplete: false,
        error: action.payload,
      };
    default:
      return state;
  }
}

const intActivateDeactivate = {
  data: [],
  fetching: false,
  requestComplete: false,
  error: "",
};

export function ActivateDeactivateRuleBased(
  state = intActivateDeactivate,
  action
) {
  switch (action.type) {
    case ACTIVATE_DEACTIVATE_RULE_REQUEST:
      return {
        ...state,
        fetching: true,
        requestComplete: false,
        error: "",
      };
    case ACTIVATE_DEACTIVATE_RULE_SUCCESS:
      return {
        data: action.payload,
        fetching: false,
        requestComplete: true,
        error: "",
      };
    case ACTIVATE_DEACTIVATE_RULE_RESET:
      return {
        data: [],
        fetching: false,
        requestComplete: false,
        error: "",
      };
    case ACTIVATE_DEACTIVATE_RULE_FAILURE:
      return {
        fetching: false,
        requestComplete: false,
        error: action.payload,
      };
    default:
      return state;
  }
}
const GetViewRuleListState = {
  data: {},
  fetching: false,
  requestComplete: false,
  error: "",
};
export function GetViewRuleList(state = GetViewRuleListState, action) {
  switch (action.type) {
    case GET_VIEW_RULE_REQUEST:
      return {
        ...state,
        fetching: true,
        requestComplete: false,
        error: "",
      };
    case GET_VIEW_RULE_SUCCESS:
      return {
        data: action.payload,
        fetching: false,
        requestComplete: true,
        error: "",
      };
    case GET_VIEW_RULE_RESET:
      return {
        data: [],
        fetching: false,
        requestComplete: false,
        error: "",
      };
    case GET_VIEW_RULE_FAILURE:
      return {
        fetching: false,
        requestComplete: false,
        error: action.payload,
      };
    default:
      return state;
  }
}
