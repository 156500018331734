import axios from "axios";
import configMyiQ from "../../configuration";
import authHeader from "./auth-header";
import { checkApiStatus } from "../../utils/helpers";
import {
    ACTIVATE_DEACTIVATE_EMAIL_TEMPLATE_FAILURE,
    ACTIVATE_DEACTIVATE_EMAIL_TEMPLATE_REQUEST,
    ACTIVATE_DEACTIVATE_EMAIL_TEMPLATE_SUCCESS,
    ADD_UPDATE_EMAIL_TEMPLATE_FAILURE,
    ADD_UPDATE_EMAIL_TEMPLATE_REQUEST,
    ADD_UPDATE_EMAIL_TEMPLATE_SUCCESS,
    GET_EMAIL_TEMPLATE_LIST_FAILURE,
    GET_EMAIL_TEMPLATE_LIST_REQUEST,
    GET_EMAIL_TEMPLATE_LIST_SUCCESS,
    LIST_EMAIL_TEMPLATE_PLACEHOLDER_FAILURE,
    LIST_EMAIL_TEMPLATE_PLACEHOLDER_REQUEST,
    LIST_EMAIL_TEMPLATE_PLACEHOLDER_SUCCESS,
    VIEW_EMAIL_TEMPLATE_FAILURE,
    VIEW_EMAIL_TEMPLATE_REQUEST,
    VIEW_EMAIL_TEMPLATE_SUCCESS,
    GET_TEMPLATE_LIST_FAILURE,
    GET_TEMPLATE_LIST_RESET,
    GET_TEMPLATE_LIST_SUCCESS,
    GET_TEMPLATE_LIST_REQUEST,
    GET_EMAIL_CONFIG_FAILURE,
} from "../types/types";

const API = process.env?.REACT_APP_NODE_API_URL;
//listemail template
export const getEmailTemplateList = () => async (dispatch) => {
    try {
        dispatch({ type: GET_EMAIL_TEMPLATE_LIST_REQUEST });
        axios
            .get(`${API}api/caseManagement/emailTemplate/emailTemplateList`, {
                headers: authHeader(),
            })
            .then((response) => {
                dispatch({
                    type: GET_EMAIL_TEMPLATE_LIST_SUCCESS,
                    payload: response?.data?.datalist,
                });
            });
    } catch (error) {
        if (error?.response?.status == 401) {
            checkApiStatus();
        }
        dispatch({
            type: GET_EMAIL_TEMPLATE_LIST_FAILURE,
        });
    }
};
//view email template
export const getEmailTemplateView = (id) => async (dispatch) => {
    try {
        dispatch({ type: VIEW_EMAIL_TEMPLATE_REQUEST });
        axios
            .get(
                `${API}api/caseManagement/emailTemplate/emailTemplateView?id=${id}`,
                {
                    headers: authHeader(),
                }
            )
            .then((response) => {
                dispatch({
                    type: VIEW_EMAIL_TEMPLATE_SUCCESS,
                    payload: response?.data,
                });
            });
    } catch (error) {
        if (error?.response?.status == 401) {
            checkApiStatus();
        }
        dispatch({
            type: VIEW_EMAIL_TEMPLATE_FAILURE,
        });
    }
};
// email template placeholder list
export const getEmailTemplatePlaceHolderList = () => async (dispatch) => {
    try {
        dispatch({ type: LIST_EMAIL_TEMPLATE_PLACEHOLDER_REQUEST });
        axios
            .get(
                `${API}api/caseManagement/emailTemplate/emailTemplatePlaceholderList`,
                {
                    headers: authHeader(),
                }
            )
            .then((response) => {
                dispatch({
                    type: LIST_EMAIL_TEMPLATE_PLACEHOLDER_SUCCESS,
                    payload: response?.data?.datalist,
                });
            });
    } catch (error) {
        if (error?.response?.status == 401) {
            checkApiStatus();
        }
        dispatch({
            type: LIST_EMAIL_TEMPLATE_PLACEHOLDER_FAILURE,
        });
    }
};

//email template add-update

//add email
export const emailTemplateAddUpdate = (data) => async (dispatch) => {
    try {
        dispatch({ type: ADD_UPDATE_EMAIL_TEMPLATE_REQUEST });

        axios
            .post(
                `${API}api/caseManagement/emailTemplate/addEmailTemplate`,
                data,
                {
                    headers: authHeader(),
                }
            )
            .then((response) => {
                dispatch({
                    type: ADD_UPDATE_EMAIL_TEMPLATE_SUCCESS,
                    payload: response.data,
                });
            });
    } catch (error) {
        if (error?.response?.status == 401) {
            checkApiStatus();
        }
        dispatch({
            type: ADD_UPDATE_EMAIL_TEMPLATE_FAILURE,
        });
    }
};

//active-inactive email template
export const emailTemplateStatusMode = (data) => async (dispatch) => {
    try {
        dispatch({ type: ACTIVATE_DEACTIVATE_EMAIL_TEMPLATE_REQUEST });

        axios
            .post(
                `${API}api/caseManagement/emailTemplate/updateEmailTemplateStatus`,
                data,
                {
                    headers: authHeader(),
                }
            )
            .then((response) => {
                dispatch({
                    type: ACTIVATE_DEACTIVATE_EMAIL_TEMPLATE_SUCCESS,
                    payload: response.data,
                });
            });
    } catch (error) {
        if (error?.response?.status == 401) {
            checkApiStatus();
        }
        dispatch({
            type: ACTIVATE_DEACTIVATE_EMAIL_TEMPLATE_FAILURE,
        });
    }
};

//view email template
export const getEmailTemplateListCreation = () => async (dispatch) => {
    try {
        dispatch({ type: GET_TEMPLATE_LIST_REQUEST });
        axios
            .get(
                `${API}api/caseManagement/InvoiceTask/getEmailTemplateViewForInvoice`,
                {
                    headers: authHeader(),
                }
            )
            .then((response) => {
                dispatch({
                    type: GET_TEMPLATE_LIST_SUCCESS,
                    payload: response?.data,
                });
            });
    } catch (error) {
        if (error?.response?.status == 401) {
            checkApiStatus();
        }
        dispatch({
            type: GET_TEMPLATE_LIST_FAILURE,
        });
    }
};
