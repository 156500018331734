import Button from "../../components/Button";
import Input from "../../components/Input";
import Card from "../../components/UI/Card";
import Main from "../../components/UI/Main";
import Layout from "../../Hoc/Layout";
import Footer from "../../organisms/Footer";
import styles from "./index.module.scss";
import UseForm from "../../Hooks/useForm";
import Swal from "sweetalert2";
import { useSelector, useDispatch } from "react-redux";
import { invoiceUpload } from "../../redux/actions/uploadinvoice";
import { useState, useEffect } from "react";
import _ from "lodash";
import { async } from "@firebase/util";
import Loader from "../../components/Loader";
import MessageBox from "../../components/UI/Alert/index";
import { UPLOAD_INVOICE_RESET } from "../../redux/types/types";
import { FormControl, MenuItem, Select } from "@mui/material";
import { getDocumentTypeList } from "../../redux/actions/invoice";
import ReactDOM from "react-dom";
import UploadLimit from "./UploadLimit";
import { getCurrentUser } from "../../redux/actions/auth";
import { ThemeContext } from "../../Hooks/Themes/Themeprovider";
import { useContext } from "react";
import SuccessMessageBox from "../../components/UI/Alert/sucess";

let uploadedFiles = [];
let fileStatusList = [];
const FileUpload = () => {
    const { currentMode } = useContext(ThemeContext);
    const Orgname = localStorage.getItem("Orgdemo");
    const OrganisationName = localStorage.getItem("OrganisationName");
    const date = new Date();
    const d = date.toLocaleDateString("en-GB");
    const currentUser = getCurrentUser();
    const errormsg = "Something went wrong please contact admin";
    const selectedOrgId = localStorage.getItem("orgId");
    const [openLimit, setOpenLimit] = useState(false);
    const { handleSubmit } = UseForm(submit);
    const responseData = useSelector((state) => state?.UploadInvoiceState);
    const { fetching, requestComplete, file, error, data } = responseData;
    const user = JSON.parse(localStorage.getItem("user"));
    const dispatch = useDispatch();
    const [fileName, onFileUpload] = useState("");
    const [fileList, setFileStatusList] = useState([]);
    const [warning, setWarning] = useState(true);
    const [isbutton, setisButton] = useState(false);
    const orgData = useSelector(
        (state) => state?.ListorganisationDropDownState
    );
    const dataList = orgData.viewData
    const fileTypes = [
        "image/png",
        "image/jpg",
        "image/jpeg",
        "application/pdf",
    ];

    const onChange = async (e) => {
        let files = e.target.files;
        if (files) {
            for (let i = 1; i <= files.length; i++) {
                const fileExt = fileTypes.find(
                    (item) => item == files[i - 1].type
                );
                // const fileCount = fileStatusList.find(item=>item.fileName == files[i-1].name);
                if (files[i - 1]) {
                    const fileSize = (
                        files[i - 1].size /
                        (1024 * 1024)
                    ).toFixed(2); //mb conversion
                    if (fileSize > 10) {
                        fileStatusList.push({
                            fileName: files[i - 1].name,
                            error: "File size should be less than 10MB",
                            status: false,
                        });
                    } else if (!fileExt) {
                        fileStatusList.push({
                            fileName: files[i - 1].name,
                            error: "File type is not supported",
                            status: false,
                        });
                    } else {
                        fileStatusList.push({
                            fileName: files[i - 1].name,
                            error: "",
                            status: true,
                        });
                        await uploadWithJSON(files[i - 1], files[i - 1].name);
                    }
                }
            }
            setFileStatusList([...fileStatusList]);
            e.target.value = "";
        }
    };

    async function uploadWithJSON(file, fileName) {
        const reader = new FileReader();
        reader.onload = await _handleReaderLoaded(fileName).bind(this);
        reader.readAsBinaryString(file);
    }
    const orgId = localStorage.getItem("orgId");
    const _handleReaderLoaded = (fileName) => async (e) => {
        let binaryString = e.target.result;
        let imgObject = { image: btoa(binaryString), fileName: fileName };
        uploadedFiles.push(imgObject);
    };
    const [invoiceType, setInvoiceType] = useState(0);
    const onChangeInvoiceType = (e) => {
        setInvoiceType(+e.target.value);
    };
    function submit() {
        if (dataList == null) {
            Swal.fire({
                text: "Select any Organization to Manual Upload!",
                icon: "warning",
                closeOnClickOutside: false,
                showCloseButton: true,
                button: "Ok",
            });
        }
        else if (
            uploadedFiles.length > 0 &&
            invoiceType !== 0 &&
            dataList != null
        ) {
            if (uploadedFiles.length <= 10) {
                const finalUploadList = [];
                uploadedFiles.forEach((element) => {
                    finalUploadList.push({
                        filename: element?.fileName,
                        image: element?.image,
                    });
                });
                let formdata = new FormData();
                formdata.append("image", JSON.stringify(finalUploadList));
                formdata.append("orgid", +orgId);
                formdata.append("document_type", +invoiceType);
                const reqObj = {
                    image: JSON.stringify(finalUploadList),
                    orgid: +orgId,
                    document_type: invoiceType,
                };
                const sizeInBytes = new TextEncoder().encode(
                    JSON.stringify(finalUploadList)
                ).length;

                // Convert size to megabytes
                const sizeInMB = sizeInBytes / (1024 * 1024);
                if (Orgname == "true" && dataList?.length > 1) {
                    Swal.fire({
                        text: `You're  uploading invoices under ${OrganisationName}. Are you sure you want to continue?`,
                        icon: "warning",
                        showCloseButton: true,
                        showCancelButton: true,
                        closeOnClickOutside: false,
                        buttons: {
                            cancel: "Cancel",
                            confirm: "Confirm",
                        },
                    }).then((confirm) => {
                        if (confirm?.isConfirmed) {
                            dispatch(invoiceUpload(formdata));
                        }
                    });
                }
                else {
                    dispatch(invoiceUpload(formdata));
                }

            } else {
                const file = document.querySelector(".file");
                file.value = "";
                Swal.fire({
                    text: "You can upload upto 10 files! ",
                    icon: "warning",
                    closeOnClickOutside: false,
                    showCloseButton: true,
                    button: "Ok",
                });
            }
        } else if (invoiceType == 0) {
            Swal.fire({
                text: "Select the Invoice Type!",
                icon: "warning",
                closeOnClickOutside: false,
                showCloseButton: true,
                button: "Ok",
            });
        }

        else {

            uploadedFiles = [];
            Swal.fire({
                text: "Select and upload the valid PDF, JPG, and PNG files!",
                icon: "error",
                closeOnClickOutside: false,
                showCloseButton: true,
                button: "Ok",
            });
        }

    }

    if (requestComplete && file?.status && uploadedFiles.length > 0) {
        uploadedFiles = [];
        setFileStatusList([]);
        setInvoiceType(0);
        // Swal.fire({
        //     text: "Invoice uploaded Successfully",
        //     icon: "success",
        //     closeOnClickOutside: false,
        //     button: "Ok",
        // }).then(async (confirm) => {
        setTimeout(() => {
            // dispatch({ type: UPLOAD_INVOICE_RESET });
            fileStatusList = [];
            uploadedFiles = [];
            requestComplete = false;
            setFileStatusList([]);
        }, 2000);

        // });
    }
    const removeFile = (index, fileName) => {
        const reMovedFileList = fileList;
        reMovedFileList.splice(index, 1);
        setFileStatusList([...reMovedFileList]);
        uploadedFiles = uploadedFiles.filter(
            (item) => item.fileName !== fileName
        );
        fileStatusList = reMovedFileList;
    };
    useEffect(() => {
        if (fileList.length === 0) {
            fileStatusList = [];
            uploadedFiles = [];
        }
    }, [fileList]);
    if (requestComplete) {
        setTimeout(() => {
            dispatch({ type: UPLOAD_INVOICE_RESET });
        }, 2000);
    }
    const { data: listData } = useSelector(
        (state) => state?.getDocumentTypeListState
    );
    const targetDateStr = currentUser?.expiring_date;
    const targetDate = new Date(targetDateStr);
    const currentDate = new Date();
    const matchdate = targetDate < currentDate;

    useEffect(() => {
        if (listData?.length == 0) {
            dispatch(getDocumentTypeList());
        }
        if (matchdate && currentUser?.license_type == 2) {
            setisButton(true);
        }
        if (currentUser?.licenseInvoice_Expired == true) {
            setOpenLimit(true);
        }
    }, []);
    const dropdownColor = currentMode === "dark" ? "#333333" : `white`;
    const dropdownText = currentMode === "dark" ? "#ECECEC" : `#555555`;
    return (
        <>
            <Layout>
                <Main>
                    {selectedOrgId ? (
                        <>
                            {requestComplete && !file?.status && (
                                <MessageBox error={file?.message} />
                            )}
                            {error && !file?.status && (
                                <MessageBox error={errormsg} />
                            )}
                            {requestComplete && file?.status && (
                                <SuccessMessageBox success={file?.message} />
                            )}
                            <Card
                                className={`h-[calc(100vh_-_156px)] overflow-y-auto px-10 py-10 border-[1px] relative ${currentMode === "dark"
                                    ? ""
                                    : "shadow-[0px_4px_20px_rgba(238,238,238,0.5)]"
                                    } border-[rgba(223,225,226,0.56)] flex items-center flex-col justify-center`}
                            >
                                <h4 className="font-interM text-xl mb-5">
                                    Select an Invoice Type
                                </h4>
                                <FormControl
                                    sx={{
                                        minWidth: "300px",
                                        marginBottom: 5,
                                    }}
                                >
                                    <Select
                                        name="docType"
                                        value={invoiceType}
                                        // defaultValue={orgName}
                                        MenuProps={{
                                            sx: {
                                                "& .MuiMenu-paper": {
                                                    backgroundColor:
                                                        dropdownColor,
                                                    color: dropdownText,
                                                    //   maxWidth: "30rem !important",
                                                },
                                                "& .MuiMenuItem-root:hover": {
                                                    backgroundColor:
                                                        "rgba(218, 249, 244, 0.3)",
                                                },
                                                "& .Mui-selected": {
                                                    backgroundColor:
                                                        "rgba(218, 249, 244, 0.3) !important",
                                                },
                                            },
                                        }}
                                        IconComponent={() => (
                                            <img
                                                className="absolute right-[15px] opacity-50 pointer-events-none"
                                                src={
                                                    currentMode !== "dark"
                                                        ? `/images/header/chevronDown.svg`
                                                        : `/images/header/chevronDown-white.svg`
                                                }
                                                alt="chevronDown"
                                            />
                                        )}
                                        SelectDisplayProps={{
                                            style: {
                                                paddingTop: "9px",
                                                paddingBottom: "9px",
                                                paddingLeft: "15px",
                                                color: `${currentMode !== "dark"
                                                    ? `#555555`
                                                    : `#ECECEC`
                                                    }`,
                                                paddingRight: "30px",
                                                border: "1px solid rgba(85, 85, 85, 0.4)",
                                                borderRadius: "8px",
                                            },
                                        }}
                                        onChange={(e) => onChangeInvoiceType(e)}
                                    >
                                        <MenuItem
                                            key={0}
                                            value={0}
                                            name="document_type"
                                        >
                                            {"Select"}
                                        </MenuItem>
                                        {listData &&
                                            listData?.map((listelem, i) => {
                                                return (
                                                    <MenuItem
                                                        key={i + 1}
                                                        name="document_type"
                                                        value={listelem?.id}
                                                    >
                                                        {listelem?.invoice_type}
                                                    </MenuItem>
                                                );
                                            })}

                                        {/* {dataList &&
                    dataList?.map((elem, i) => {
                      return (
                        <MenuItem
                          name="organisation_name"
                          key={i + 1}
                          value={elem?.id}
                          className="truncate trunc-1 block"
                        >
                          {elem?.organisation_name}
                        </MenuItem>
                      );
                    })} */}
                                    </Select>
                                </FormControl>
                                <form
                                    onSubmit={handleSubmit}
                                    className={styles?.fileUpload}
                                >
                                    <Input
                                        type="file"
                                        className="file cursor-pointer"
                                        onChange={(e) => onChange(e)}
                                        disabled={
                                            fetching ||
                                            currentUser?.licenseInvoice_Expired ==
                                            true
                                        }
                                        name="image"
                                        accept="application/pdf, image/png, image/jpg"
                                        multiple
                                    />
                                    {fetching ? (
                                        <Loader className="h-full" />
                                    ) : (
                                        <>
                                            <img
                                                className="mb-4"
                                                src="/images/common/fileUpload.svg"
                                                alt="fileUpload"
                                            />
                                            <span
                                                className={`text-xl mb-1 font-interM`}
                                            >
                                                Upload your files directly here
                                            </span>
                                            {/* <p>
                                                Drag and drop or click to add
                                                files you want to process
                                            </p> */}
                                            {/* <button type="submit">Upload</button> */}
                                        </>
                                    )}
                                </form>
                                {fileStatusList.length > 0 &&
                                    fileList.length > 0 && (
                                        <div className="bg-[rgba(222,248,243,0.5)] mt-5 p-[20px] rounded-[8px] overflowscroll w-full max-w-[740px]">
                                            <ul className="space-y-2">
                                                {fileList.map((item, index) => {
                                                    return (
                                                        <li
                                                            key={index}
                                                            className="bg-light-10 flex px-3 py-2 space-x-2 rounded-[5px] flex items-center justify-between text-white w-full"
                                                        >
                                                            <div className="flex min-w-[21rem] flex-1 items-center space-x-5">
                                                                <Button
                                                                    className={`${!item.status
                                                                        ? `bg-transparent`
                                                                        : `bg-white`
                                                                        } w-[15px] grid place-items-center h-[15px] rounded-full`}
                                                                >
                                                                    <img
                                                                        src={
                                                                            !item.status
                                                                                ? `/images/common/warning-ico.svg`
                                                                                : `/images/common/tick-small.svg`
                                                                        }
                                                                        alt="tick-small"
                                                                    />
                                                                </Button>{" "}
                                                                <span
                                                                    title={
                                                                        item.fileName
                                                                    }
                                                                    className="truncate flex-1"
                                                                >
                                                                    {" "}
                                                                    {
                                                                        item.fileName
                                                                    }
                                                                </span>
                                                            </div>
                                                            {!item.status && (
                                                                <span
                                                                    title={
                                                                        item.error
                                                                    }
                                                                    className="truncate flex-1 text-light-100"
                                                                >
                                                                    Error:{" "}
                                                                    {item.error}
                                                                </span>
                                                            )}
                                                            <Button
                                                                disabled={
                                                                    fetching
                                                                }
                                                                onClick={() =>
                                                                    removeFile(
                                                                        index,
                                                                        item.fileName
                                                                    )
                                                                }
                                                            >
                                                                <img
                                                                    src="/images/common/close-small.svg"
                                                                    alt="close-small"
                                                                    title="To remove this document"
                                                                />
                                                            </Button>
                                                        </li>
                                                    );
                                                })}
                                            </ul>
                                        </div>
                                    )}
                                <Button
                                    type="submit"
                                    disabled={
                                        fetching ||
                                        isbutton ||
                                        currentUser?.licenseInvoice_Expired ==
                                        true
                                    }
                                    onClick={submit}
                                    title="To manually upload the Invoices"
                                    className={`text-left border-${currentMode}-590 bg-light-20  px-7 py-2 rounded-[8px] font-interM text-sm mt-8 flex items-center border-[1px] text-white`}
                                >
                                    Submit
                                </Button>
                            </Card>
                        </>
                    ) : (
                        <div className="grid place-items-center text-center place-content-center h-[calc(100vh_-_37vh)]">
                            <img
                                className="mb-[2rem]"
                                src="/images/common/delivery.png"
                                alt="delivery"
                            />
                            <h5 className="font-interSb mb-1">
                                No Organization Selected
                            </h5>
                            <p className="text-xs">
                                Go to Organization menu
                                <br /> then add your Organization details
                            </p>
                        </div>
                    )}
                </Main>
                <Footer />
            </Layout>
            {ReactDOM.createPortal(
                <UploadLimit
                    isOpened={openLimit}
                    handleClose={() => setOpenLimit(false)}
                    className={openLimit && "Show"}
                />,
                document.getElementById("user-modal-root")
            )}
        </>
    );
};

export default FileUpload;
