import { Autocomplete, Chip, FormControl, MenuItem, Select, TextareaAutosize, TextField } from "@mui/material";
import { useState, useContext } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Button from "../../components/Button";
import Input from "../../components/Input";
import Card from "../../components/UI/Card";
import Main from "../../components/UI/Main";
import Layout from "../../Hoc/Layout";
import Footer from "../../organisms/Footer";
import { styled } from "@mui/material/styles";
import { AddUpdateRule, GetRuleView, RuleFields, RuleOperators } from "../../redux/actions/rule-based";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import ViewRuleItems from "./ViewRule";
import SuccessMessageBox from "../../components/UI/Alert/sucess";
import MessageBox from "../../components/UI/Alert";
import Swal from "sweetalert2";
import Loader from "../../components/Loader";
import { ADD_RULE_LIST_RESET } from "../../redux/types/types";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { ThemeContext } from "../../Hooks/Themes/Themeprovider";
import MultiSelectComponent from "./MultiSelect";
import MultiSelectCountry from "./MultiSelectCountry";
import { RoleDisplay } from "../../utils/helpers";
// import { makeStyles } from '@mui/styles';
let Amountarry = [];
let ValueArr = [];
let getId = null;
const RuleBased = () => {
    const { currentMode, toggleMode } = useContext(ThemeContext);
    const [view, setView] = useState(false);
    const Operators = useSelector((state) => state?.OperatorsRuleState);
    const { data: ViewRule } = useSelector((state) => state?.RuleViewState);
    const DataValue = ViewRule?.[0];
    const [main, setMain] = useState([]);
    const DataItems = DataValue?.rules_info;
    const RequestStatus = useSelector((state) => state?.RuleViewState);
    const { requestComplete, data, error, fetching } = useSelector((state) => state?.AddUpdateRuleState);
    const [DefaultExistvalue, setDefaultExistvalue] = useState([]);
    const [OperatorsList, setOperators] = useState("");
    const [fieldstatus, setFieldStatus] = useState(false);
    const [Items, setItems] = useState([DataItems]);
    const OperatorsData = Operators?.data;
    const [fieldsValue, setfieldValue] = useState({
        normal: OperatorsData,
        num: [],
        string: [],
    });
    const [ValueArray, setValueArray] = useState([]);
    const dispatch = useDispatch();
    let RuleID;
    let Cloneid;
    const params = new URLSearchParams(window.location.search);
    RuleID = params.get("ruleid");
    Cloneid = params.get("cloneid");
    const succesMsg = RuleID == null ? "Created Successfully" : "Updated successfully";
    const errormsg = "Something went wrong please contact admin";
    const [FiledList, setFieldList] = useState({});
    const [fieldChange, setfieldChange] = useState([]);
    const [fieldstore, setFieldstore] = useState({});
    const dataele = DataItems?.map((el) => el);
    const DataElements = dataele?.map((el) => el?.field_id);
    const fieldNumValue = fieldChange == 2 || fieldChange == 3 || DataElements == 2;
    const fieldStringValue = fieldChange == 1 || fieldChange == 4;
    const EmptyArr = [];
    const [AddItems, setAddItems] = useState(EmptyArr);
    const mapArr = DataItems == undefined ? AddItems : DataItems;
    const operatorsNumValue = OperatorsData?.filter((el) => el?.operator_type == "int");
    const stringOperators = OperatorsData?.filter((el) => el?.operator_type == "s");
    const EqualOperators = OperatorsData?.filter((el) => el?.operators == "=");

    const DisableDoc = !RuleID && !Cloneid && AddItems?.filter((el) => el?.field_id !== 0 || el?.operators_id !== 0 || el?.value?.length !== 0);
    useEffect(() => {
        dispatch(RuleFields());
        dispatch(RuleOperators());

        if (RuleID !== null || Cloneid !== null) {
            dispatch(GetRuleView(RuleID == null ? Cloneid : RuleID));
        }
    }, [dispatch]);
    DataItems?.forEach((element, index) => {
        element.seq_no = index;
    });
    AddItems?.forEach((element, index) => {
        element.seq_no = index;
    });
    const Filed = useSelector((state) => state?.FieldRuleState);
    const fieldData = Filed?.data;
    const fieldTypeValid = AddItems?.filter((el) => el?.field_id == 0);
    const operatorTypeValid = AddItems?.filter((el) => el?.operators_id == 0);

    const fieldEditTypeValid = DataItems?.filter((el) => el?.field_id == 0);
    const operatorEditValid = DataItems?.filter((el) => el?.operators_id == 0);
    const DuplicateField = AddItems?.map((elem) => elem);
    const DuplicateExist = DataItems?.map((elem) => elem);
    const [ruleName, setRuleName] = useState(DataValue?.rule_name);
    const [description, setDescription] = useState(DataValue?.rule_description);
    const [ExistValueArray, setExistValueArray] = useState([]);
    const [documentGroup, setDcoumetGroup] = useState(RuleID ? DataValue?.document_group : 0);
    const handleDocumentChange = (value) => {
        if (value !== 0) {
            setDcoumetGroup(value);
        }
    };
    const RuleDisplay = Cloneid !== null ? ruleName : RuleID !== null && ruleName == undefined ? DataValue?.rule_name : ruleName;
    const DescriptionDisplay =
        Cloneid !== null ? description : RuleID !== null && description == undefined ? DataValue?.rule_description : description;
    useEffect(() => {
        DataItems?.forEach((element, index) => {
            if (Cloneid !== null) {
                element.action_type = 1;
                element.identity_id = 0;
                element.rule_id = 0;
            }
        });
        setDcoumetGroup(RuleID || Cloneid ? DataValue?.document_group : 0);
    }, [ValueArr, DataValue?.document_group]);
    const [errormsgs, seterrormsg] = useState({
        message: "",
    });
    const [descriptionError, setDescriptionError] = useState({
        message: "",
    });

    const handleFieldValueChange = (event, inde) => {
        DataItems.forEach((element, index) => {
            if (inde == index) {
                element.field_id = event.target.value;
            }

            if (RuleID !== null && inde == index && element?.identity_id !== 0) {
                element.action_type = 2;
            }

            if (Cloneid !== null) {
                element.action_type = 1;
            }
        });

        setItems([...Items]);
    };

    const handleOperatorsChange = (event, inde) => {
        setOperators(event.target.value);

        DataItems.forEach((element, index) => {
            if (inde == index) {
                element.operators_id = event.target.value;
            }

            if (RuleID !== null && inde == index && element?.identity_id !== 0) {
                element.action_type = 2;
            }

            if (Cloneid !== null) {
                element.action_type = 1;
            }
        });

        setItems([...Items]);
    };
    const [ExistindexAuto, setExistIndexAuto] = useState(null);
    const handleValueChange = (event, inde, ValueItem, reason, seq_no) => {
        const Update = _.set(_.find(DataItems, { seq_no: inde }), "value", ValueItem);
        const New = DataItems?.map((el, i) => (el?.seq == Update?.seq ? Update : el));
        if (DataItems?.length > 1) {
            setAddItems(DataItems, New);
        } else {
            setAddItems(New);
        }
        DataItems?.forEach((element, index) => {
            if (inde == index) {
                element.rule_id = +RuleID;
            }
            if (RuleID !== null && inde == index && element?.identity_id !== 0) {
                element.action_type = 2;
            }
            if (Cloneid !== null) {
                element.action_type = 1;
            }
        });

        setItems([...Items]);
    };
    const handleAmountValueChange = (event, inde) => {
        DataItems.forEach((element, index) => {
            if (inde == index) {
                element.value = [event.target.value];
                element.rule_id = +RuleID;
            }
            if (RuleID !== null && inde == index && element?.identity_id !== 0) {
                element.action_type = 2;
            }
            if (Cloneid !== null) {
                element.action_type = 1;
            }
        });
        setItems([...Items]);
    };
    const handleDocValueChange = (event, inde) => {
        DataItems.forEach((element, index) => {
            if (inde == index) {
                element.value = [event.target.value];
                element.rule_id = +RuleID;
            }
            if (RuleID !== null && inde == index && element?.identity_id !== 0) {
                element.action_type = 2;
            }
            if (Cloneid !== null) {
                element.action_type = 1;
            }
        });
        setItems([...Items]);
    };
    const OperatorChange = fieldNumValue && fieldstatus;

    const Operator2Change = fieldStringValue && fieldstatus;
    const handleAddFieldValueChange = (names, value, i) => {
        setfieldChange(value);
        setFieldstore({ ...fieldstore, [names]: value });
        setFieldList({ ...FiledList, [names]: value, index: i });

        AddItems?.forEach((element, index) => {
            if (i == index) {
                element.field_id = value;
            }
            if (i == index && fieldNumValue) {
                setfieldValue({ ...fieldsValue, num: operatorsNumValue });
            }
            if (i == index && fieldStringValue) {
                setfieldValue({ ...fieldsValue, stringOperators });
            }
        });

        setAddItems([...AddItems]);
    };

    const handleAddOperatorsChange = (event, inde) => {
        setOperators(event.target.value);

        AddItems?.forEach((element, index) => {
            if (inde == index) {
                element.operators_id = event.target.value;
            }
        });

        setAddItems([...AddItems]);
    };
    const handleAddValueChange = (event, inde, reason, seq, value) => {
        const Update = _.set(_.find(AddItems, { seq_no: inde }), "value", value);
        const New = AddItems?.map((el, i) => (el?.seq == Update?.seq ? Update : el));
        if (AddItems?.length > 1) {
            setAddItems(AddItems, New);
        } else {
            setAddItems(New);
        }
    };
    const handleAmountAddValueChange = (event, inde) => {
        AddItems?.forEach((element, index) => {
            if (inde == index) {
                element.value = [event.target.value];
            }
        });

        setAddItems([...AddItems]);
    };
    const handleDocAddValueChange = (event, inde) => {
        AddItems?.forEach((element, index) => {
            if (inde == index) {
                element.value = [event.target.value];
            }
        });

        setAddItems([...AddItems]);
    };

    const addRowTable = () => {
        const data = {
            rule_id: 0,
            value: [],
            action_type: 1,
            identity_id: 0,
            field_id: 0,
            operators_id: 0,
            seq_no: 0,
        };

        DataItems.push(data);
        setExistValueArray([]);
        setItems([...Items]);
    };

    if (RuleID == null && Cloneid == null) {
        const data = {
            rule_id: 0,
            value: [],
            action_type: 1,
            identity_id: 0,
            field_id: 0,
            operators_id: 0,
            seq_no: 0,
        };
        EmptyArr?.push(data);
        // setAddItems([...AddItems]);
    }

    const addRow = () => {
        const data = {
            rule_id: 0,
            value: "",
            action_type: 1,
            identity_id: 0,
            field_id: 0,
            operators_id: 0,
            seq_no: AddItems?.length + 1,
        };
        setValueArray([]);
        AddItems?.push(data);
        setAddItems([...AddItems]);
    };
    const keys = ["field_id", "operators_id", "value"];
    const hasDuplicates = (values) => new Set(values?.map((v) => JSON?.stringify(keys?.map((key) => v[key])))).size < values?.length;
    hasDuplicates(DuplicateField);
    const keynew = ["field_id", "operators_id", "value"];
    const hasExistDuplicates = (values) => new Set(values?.map((v) => JSON?.stringify(keynew?.map((key) => v[key])))).size < values?.length;
    hasExistDuplicates(DuplicateExist);
    const keyValue = ["field_id", "operators_id"];
    const FieldOperatorDuplicates = (values) => new Set(values?.map((v) => JSON?.stringify(keyValue?.map((key) => v[key])))).size < values?.length;
    FieldOperatorDuplicates(DuplicateField);
    const VendorNameStartValid = AddItems?.filter((el) => el?.operators_id == 6);
    const VenodrNameEndValid = AddItems?.filter((el) => el?.operators_id == 7);
    const VendorNameContainsValid = AddItems?.filter((el) => el?.operators_id == 8);
    const NetAmountValid = mapArr?.filter((el) => el?.field_id == 2 && (el?.operators_id == 1 || el?.operators_id == 2 || el?.operators_id == 3));
    const GrossAmountValid = mapArr?.filter((el) => el?.field_id == 3 && el?.operators_id == 1 && el?.operators_id == 2 && el?.operators_id == 3);
    const TaxAmountValid = mapArr?.filter((el) => el?.field_id == 5 && el?.operators_id == 1 && el?.operators_id == 2 && el?.operators_id == 3);
    const NetAmountLesserThan = mapArr?.filter((el) => el?.field_id == 2 && (el?.operators_id == 1 || el?.operators_id == 2));
    const NetAountLessThan = mapArr?.filter((el) => el?.field_id == 2 && (el?.operators_id == 3 || el?.operators_id == 2));
    const GrossAmountLesserThan = mapArr?.filter((el) => el?.field_id == 3 && (el?.operators_id == 1 || el?.operators_id == 2));
    const GrossAmountLessThan = mapArr?.filter((el) => el?.field_id == 3 && (el?.operators_id == 3 || el?.operators_id == 2));
    const TaxAmountLesserThan = mapArr?.filter((el) => el?.field_id == 5 && (el?.operators_id == 1 || el?.operators_id == 2));
    const TaxAmountLessThan = mapArr?.filter((el) => el?.field_id == 5 && (el?.operators_id == 3 || el?.operators_id == 2));
    const NetAmountGreaterThan = mapArr?.filter((el) => el?.field_id == 2 && (el?.operators_id == 4 || el?.operators_id == 5));
    const NetAmountGreater = mapArr?.filter((el) => el?.field_id == 2 && (el?.operators_id == 4 || el?.operators_id == 1));
    const GrossAmountGreaterThan = mapArr?.filter((el) => el?.field_id == 3 && (el?.operators_id == 4 || el?.operators_id == 5));
    const GrossAmountGreater = mapArr?.filter((el) => el?.field_id == 3 && (el?.operators_id == 4 || el?.operators_id == 1));
    const TaxAmountGreaterThan = mapArr?.filter((el) => el?.field_id == 5 && (el?.operators_id == 4 || el?.operators_id == 5));
    const TaxAmountGreater = mapArr?.filter((el) => el?.field_id == 5 && (el?.operators_id == 1 || el?.operators_id == 4));
    const AmountGreater =
        NetAmountGreaterThan?.length > 1 ||
        NetAmountGreater?.length > 1 ||
        GrossAmountGreaterThan?.length > 1 ||
        GrossAmountGreater?.length > 1 ||
        TaxAmountGreater?.length > 1 ||
        TaxAmountGreaterThan?.length > 1;
    const NetValueLower = mapArr?.filter((el) => (el?.field_id == 2 && el?.operators_id == 2) || (el?.field_id == 2 && el?.operators_id == 3));
    const NetValueHigher = mapArr?.filter((el) => (el?.field_id == 2 && el?.operators_id == 4) || (el?.field_id == 2 && el?.operators_id == 5));
    const NetLength = NetValueLower?.length !== 0 && NetValueHigher?.length !== 0;
    const NetLowerMap = NetValueLower?.map((el) => el?.value);
    const NetHigherMap = NetValueHigher?.map((el) => el?.value);
    const NetValueCompare = +NetLowerMap <= +NetHigherMap && NetLength;
    const GrossValueLower = mapArr?.filter((el) => (el?.field_id == 3 && el?.operators_id == 2) || (el?.field_id == 3 && el?.operators_id == 3));
    const GrossValueHigher = mapArr?.filter((el) => (el?.field_id == 3 && el?.operators_id == 4) || (el?.field_id == 3 && el?.operators_id == 5));
    const Grosslength = GrossValueLower?.length !== 0 && GrossValueHigher?.length !== 0;
    const GrossLower = GrossValueLower?.map((el) => el?.value);
    const GrossHigher = GrossValueHigher?.map((el) => el?.value);
    const GrossValueCompare = +GrossLower <= +GrossHigher && Grosslength;
    const TaxValueLower = mapArr?.filter((el) => (el?.field_id == 5 && el?.operators_id == 2) || (el?.field_id == 5 && el?.operators_id == 3));
    const TaxValueHigher = mapArr?.filter((el) => (el?.field_id == 5 && el?.operators_id == 4) || (el?.field_id == 5 && el?.operators_id == 5));
    const TaxLength = TaxValueLower?.length !== 0 && TaxValueHigher?.length !== 0;
    const TaxLower = TaxValueLower?.map((el) => el?.value);
    const TaxHigher = TaxValueHigher?.map((el) => el?.value);
    const TaxValueCompare = +TaxLower <= +TaxHigher && TaxLength;
    const numericRegex = /^[0-9]*\.?[0-9]*$/;

    const AddItemsValue = AddItems?.filter((el) => el?.value && el?.field_id !== 1 && el?.field_id !== 7 && el?.field_id !== 8);
    const DataItemsValue = DataItems?.filter((el) => el?.value && el?.field_id !== 1 && el?.field_id !== 7 && el?.field_id !== 8);
    const Submit = () => {
        let InvalidValue = false;
        const ItemArray = AddItems?.length == 0 ? DataItemsValue : AddItemsValue;
        const ValueValidation = AddItems?.filter((el) => el?.value == "" && el?.field_id !== 1);
        const ValueditValid = DataItems?.filter((el) => el?.value == "" && el?.field_id !== 1);
        const multipleValueAddValid = AddItems?.filter((el) => el?.value?.length == 0 && el?.field_id == 1);
        const multipleValueEditValid = DataItems?.filter((el) => el?.value?.length == 0 && el?.field_id == 1);

        const ValueValidationFinal =
            ValueValidation?.length > 0 || ValueditValid?.length > 0 || multipleValueAddValid?.length > 0 || multipleValueEditValid?.length > 0;
        if (RuleID !== 0) {
            DataItems?.forEach((element, index) => {
                if (ExistindexAuto == index) {
                    element.value = ExistValueArray;
                }
            });
        }
        if (RuleDisplay) {
            if (!/^(?=.*[a-zA-Z])[A-Za-z0-9_ ]+$/.test(ruleName)) {
                seterrormsg({
                    message: "Rulename is Invalid",
                });
                return false;
            } else {
                seterrormsg({
                    message: "",
                });
            }
            seterrormsg(false);

            if (description?.length > 250) {
                setDescriptionError({
                    message: "Maximum length should not exceed 250",
                });

                return false;
            } else {
                setDescriptionError({
                    message: "",
                });
            }

            setDescriptionError(false);
        } else {
            seterrormsg({
                message: "Rule Name is required",
            });
        }

        if (fieldTypeValid?.length > 0 || fieldEditTypeValid?.length > 0) {
            Swal.fire({
                text: "Select a field type",
                closeOnClickOutside: false,
                showCloseButton: true,
                icon: "warning",
                button: "Ok",
            });

            return false;
        }
        // else if (NetAmountValid?.length > 0) {
        //   Swal.fire({
        //     text: "Net Amount should not have all the three operators",
        //     icon: "warning",
        //     button: "Ok",
        //   });

        //   return false;
        // }
        else if (NetAmountLesserThan?.length > 1) {
            Swal.fire({
                text: "Invalid amount configuration!",
                closeOnClickOutside: false,
                icon: "warning",
                showCloseButton: true,
                button: "Ok",
            });

            return false;
        } else if (NetAountLessThan?.length > 1) {
            Swal.fire({
                text: "Invalid amount configuration!",
                closeOnClickOutside: false,
                showCloseButton: true,
                icon: "warning",
                button: "Ok",
            });

            return false;
        } else if (GrossAmountLesserThan?.length > 1) {
            Swal.fire({
                text: "Invalid amount configuration!",
                closeOnClickOutside: false,
                icon: "warning",
                showCloseButton: true,
                button: "Ok",
            });

            return false;
        } else if (GrossAmountLessThan?.length > 1) {
            Swal.fire({
                text: "Invalid amount configuration!",
                closeOnClickOutside: false,
                icon: "warning",
                showCloseButton: true,
                button: "Ok",
            });

            return false;
        } else if (NetValueCompare) {
            Swal.fire({
                text: `In Net Amount, the "Greater than" value must be smaller than the "Lesser than" value.`,
                closeOnClickOutside: false,
                icon: "warning",
                showCloseButton: true,
                button: "Ok",
            });
            return false;
        } else if (GrossValueCompare) {
            Swal.fire({
                text: `In Gross Amount, the "Greater than" value must be smaller than the "Lesser than" value.`,
                closeOnClickOutside: false,
                icon: "warning",
                showCloseButton: true,
                button: "Ok",
            });
            return false;
        } else if (TaxValueCompare) {
            Swal.fire({
                // text:"Testing",
                text: `For the Tax Amount, the "Greater than" value must be smaller than the "Lesser than" value.`,
                closeOnClickOutside: false,
                icon: "warning",
                showCloseButton: true,
                button: "Ok",
            });
            return false;
        } else if (TaxAmountLesserThan?.length > 1) {
            Swal.fire({
                text: "Invalid amount configuration!",
                closeOnClickOutside: false,
                icon: "warning",
                showCloseButton: true,
                button: "Ok",
            });

            return false;
        } else if (TaxAmountLessThan?.length > 1) {
            Swal.fire({
                text: "Invalid amount configuration!",
                closeOnClickOutside: false,
                icon: "warning",
                showCloseButton: true,
                button: "Ok",
            });

            return false;
        } else if (AmountGreater) {
            Swal.fire({
                text: "Invalid amount configuration!",
                closeOnClickOutside: false,
                icon: "warning",
                showCloseButton: true,
                button: "Ok",
            });

            return false;
        }

        if (operatorTypeValid?.length > 0 || operatorEditValid?.length > 0) {
            Swal.fire({
                text: "Select a operator!",
                closeOnClickOutside: false,
                icon: "warning",
                showCloseButton: true,
                button: "Ok",
            });

            return false;
        }

        if (ValueValidationFinal) {
            Swal.fire({
                text: "Value is required!",
                closeOnClickOutside: false,
                icon: "warning",
                showCloseButton: true,
                button: "Ok",
            });

            return false;
        }
        ItemArray?.forEach((element) => {
            if (!numericRegex?.test(element?.value)) {
                InvalidValue = true;
            }
            return false;
        });
        if (InvalidValue) {
            Swal.fire({
                text: `Amount value is invalid!`,
                closeOnClickOutside: false,
                icon: "warning",
                showCloseButton: true,
                buttons: {
                    confirm: true,
                },
            });
            return false;
        }
        if (hasDuplicates(DuplicateField)) {
            Swal.fire({
                text: `Rules must not have duplicates!`,
                closeOnClickOutside: false,
                icon: "warning",
                showCloseButton: true,
                buttons: {
                    confirm: true,
                },
            });
            return false;
        }
        if (FieldOperatorDuplicates(DuplicateField)) {
            Swal.fire({
                text: `Rules must not have duplicates!`,
                closeOnClickOutside: false,
                icon: "warning",
                showCloseButton: true,
                buttons: {
                    confirm: true,
                },
            });
            return false;
        }
        if (FieldOperatorDuplicates(DuplicateExist)) {
            Swal.fire({
                text: `Rules must not have duplicates!`,
                closeOnClickOutside: false,
                icon: "warning",
                showCloseButton: true,
                buttons: {
                    confirm: true,
                },
            });
            return false;
        }
        if (hasExistDuplicates(DuplicateExist)) {
            Swal.fire({
                text: `Rules must not have duplicates!`,
                closeOnClickOutside: false,
                icon: "warning",
                showCloseButton: true,
                buttons: {
                    confirm: true,
                },
            });
            return false;
        } else if (VendorNameStartValid?.length > 1) {
            Swal.fire({
                text: `Vendor name's operator value should not be repeated!`,
                closeOnClickOutside: false,
                icon: "warning",
                showCloseButton: true,
                buttons: {
                    confirm: true,
                },
            });
            return false;
        } else if (VenodrNameEndValid?.length > 1) {
            Swal.fire({
                text: `Vendor name's operator value should not be repeated!`,
                closeOnClickOutside: false,
                icon: "warning",
                showCloseButton: true,
                buttons: {
                    confirm: true,
                },
            });
            return false;
        } else if (VenodrNameEndValid?.length > 1) {
            Swal.fire({
                text: `Vendor name's operator value should not be repeated!`,
                closeOnClickOutside: false,
                icon: "warning",
                showCloseButton: true,
                buttons: {
                    confirm: true,
                },
            });
            return false;
        }
        const RuleInt = RuleID == null ? 0 : RuleID;
        const ItemArr = RuleID == null && Cloneid == null ? AddItems : DataItems;
        const RuleHeader = Cloneid !== null ? ruleName : ruleName || DataValue?.rule_name;
        const descriptionHeader = Cloneid !== null ? description : description || DataValue?.rule_description;
        const reqobj = {
            headers: [
                {
                    rule_id: +RuleInt,
                    rule_name: RuleHeader?.trim(),
                    description: descriptionHeader,
                    doc_group: documentGroup,
                },
            ],
            items: ItemArr,
        };

        if (ItemArr?.length == 0) {
            Swal.fire({
                text: "Please fill any Rule Permissions.",
                closeOnClickOutside: false,
                icon: "warning",
                showCloseButton: true,
                button: "Ok",
            });
            return false;
        }

        const reqStr = {
            json: reqobj,
        };

        if (RuleDisplay !== undefined && RuleDisplay !== "") {
            dispatch(AddUpdateRule(reqStr));
        }
    };
    const tableRowRemove = (id, inde) => {
        Swal.fire({
            text: "Are you sure you want to delete the details?",
            closeOnClickOutside: false,
            icon: "warning",
            showCloseButton: true,
            showCancelButton: true,
            buttons: {
                cancel: "Cancel",
                confirm: "Confirm",
            },
        }).then((confirm) => {
            if ((confirm?.isConfirmed && RuleID !== null) || Cloneid !== null) {
                DataItems.splice(id, 1);
                setItems([...Items]);
            }

            if (confirm?.isConfirmed && RuleID == null) {
                AddItems?.splice(id, 1);
                setAddItems([...AddItems]);
            }
        });
    };

    const navigate = useNavigate();

    if (requestComplete) {
        if (requestComplete) {
            setTimeout(() => {
                dispatch({ type: ADD_RULE_LIST_RESET });
            }, 2000);
        }

        if (data?.status) {
            setTimeout(() => {
                navigate(`/invoice-rule`);
            }, 1000);
        }
    }
    const LightTooltip = styled(({ className, ...props }) => (
        <Tooltip
            {...props}
            placement="bottom"
            PopperProps={{
                sx: {
                    "& .MuiTooltip-tooltip": {
                        padding: "0 5px",
                    },
                },
                modifiers: [
                    {
                        name: "offset",
                        options: {
                            offset: [0, -100],
                        },
                    },
                ],
            }}
            classes={{ popper: className }}
        />
    ))(({ }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: `${currentMode === "dark" ? "#1F1F1F" : "white"}`,
            color: "rgba(0, 0, 0, 0.87)",
            borderRadius: "10px",
            boxShadow: `${currentMode === "dark" ? "" : "0px 4px 20px rgba(85,85,85,0.2)"}`,
            border: `${currentMode === "dark" ? "1px solid rgba(85, 85, 85, 0.4)" : ""}`,
        },
        [`& .${tooltipClasses.arrow}`]: {
            width: "30px",
            height: "17px",
            marginTop: "-18px !important",
            "&::before": {
                backgroundColor: `${currentMode === "dark" ? "#1F1F1F" : "white"}`,
                border: "1px solid #DCDCDC",
            },
        },
    }));
    const dataDisplay = RuleID !== null || Cloneid !== null;
    const addDataDisplay = RuleID == null && Cloneid == null;
    // if (location.pathname == "/invoice-rulebased") {
    //     var textarea = document?.getElementById("rulebar");
    //     textarea?.focus();
    // }

    const dropdownColor = currentMode === "dark" ? "#333333" : `white`;
    const dropdownText = currentMode === "dark" ? "#ECECEC" : `#555555`;
    const RoleMenu = RoleDisplay();
    const RoleAdvance = RoleMenu?.find((elem) => elem?.menu_id === 21);
    const RoleAdvanceAction = RoleAdvance?.actions;
    const RoleActAdvance = RoleAdvanceAction?.find((element) => element.id === 3)?.isallowed;
    const RoleMisc = RoleMenu?.find((elem) => elem?.menu_id === 22);
    const RoleMiscAction = RoleMisc?.actions;
    const RoleActMisc = RoleMiscAction?.find((element) => element.id === 3)?.isallowed;
    const RoleReten = RoleMenu?.find((elem) => elem?.menu_id === 23);
    const RoleRetenAction = RoleReten?.actions;
    const RoleActReten = RoleRetenAction?.find((element) => element.id === 3)?.isallowed;
    const documentArray = [
        { id: 1, value: "Invoice" },
        { id: 2, value: "Advance Request" },
        { id: 3, value: "Miscellaneous Payment Request" },
        { id: 4, value: "Retention Release" },
    ];

    const HandleSelect = (value, reason, inde, field) => {
        const arrayId = value?.map((el) => (field == 7 ? el?.country_id : el?.currency_id));
        if (!RuleID && !Cloneid) {
            AddItems?.forEach((element, index) => {
                if (inde == index) {
                    element.value = arrayId;
                }
            });
            setAddItems([...AddItems]);
        } else {
            DataItems?.forEach((element, index) => {
                if (inde == index) {
                    element.value = arrayId;
                    element.rule_id = +RuleID;
                }
                if (RuleID !== null && inde == index && element?.identity_id !== 0) {
                    element.action_type = 2;
                }
                if (Cloneid !== null) {
                    element.action_type = 1;
                }
            });
            setItems([...Items]);
        }
    };
    const HandleSelectCountry = (value, reason, inde, field) => {
        const arrayId = value?.map((el) => el?.country_id);
        if (!RuleID && !Cloneid) {
            AddItems?.forEach((element, index) => {
                if (inde == index) {
                    element.value = arrayId;
                }
            });
            setAddItems([...AddItems]);
        } else {
            DataItems?.forEach((element, index) => {
                if (inde == index) {
                    element.value = arrayId;
                    element.rule_id = +RuleID;
                }
                if (RuleID !== null && inde == index && element?.identity_id !== 0) {
                    element.action_type = 2;
                }
                if (Cloneid !== null) {
                    element.action_type = 1;
                }
            });
            setItems([...Items]);
        }
    };
    // const useStyles = makeStyles((theme) => ({
    //     disabledSelect: {
    //         color: 'black', // Change this to the color you desire for disabled text
    //     },
    // }));
    const FilterCommanFields = fieldData?.filter((el) => el?.document_group == null);
    const FilterFields =
        documentGroup == 1
            ? fieldData?.filter((el) => el?.document_group == 1)
            : documentGroup == 2
                ? fieldData?.filter((el) => el?.document_group == 2)
                : documentGroup == 3
                    ? fieldData?.filter((el) => el?.document_group == 3)
                    : documentGroup == 4
                        ? fieldData?.filter((el) => el?.document_group == 4)
                        : [];
    const FilteredFields = FilterCommanFields?.concat(FilterFields);

    return (
        <>
            {requestComplete && data?.status && <SuccessMessageBox success={succesMsg} />}

            {requestComplete && !data?.status && <MessageBox error={data?.message} />}

            {error && !data?.status && <MessageBox error={errormsg} />}

            <Layout>
                <Main>
                    <div className={`flex justify-between items-center mb-6 text-${currentMode}-copy`}>
                        <div>
                            <h4 className="text-2xl font-interSb">Rule Master</h4>
                        </div>
                    </div>

                    {view ? (
                        <ViewRuleItems />
                    ) : (
                        <Card
                            className={`${view ? `` : `h-[calc(100vh_-_22rem)] xl:h-[calc(100vh_-_18rem)]`
                                } border-b-0 rounded-t-[10px] rounded-b-[0] w-full overflow-y-auto ${currentMode === "dark" ? "" : "shadow-[0px_4px_20px_rgba(238,238,238,0.5)]"
                                } pb-0 relative `}
                        >
                            {RequestStatus?.fetching ? (
                                <Loader className="h-[calc(100vh_-_17rem)]" />
                            ) : (
                                <>
                                    <div className="mb-[2.5rem] pt-[30px] px-[30px] w-full">
                                        <div className="form-group flex mb-4">
                                            <label className="mt-3">
                                                Document Type <span className="text-light-150">*</span>
                                            </label>
                                            <div className="py-2 ml-[2rem] px-3">
                                                <Select
                                                    value={documentGroup || "0"}
                                                    disabled={RuleID || (DisableDoc?.length > 0 && documentGroup !== 0) || Cloneid}
                                                    name="documentGroup"
                                                    onChange={(e) => handleDocumentChange(e.target.value)}
                                                    inputProps={{
                                                        "aria-label": "Without label",
                                                    }}
                                                    className="selectDisabled"
                                                    fullWidth
                                                    IconComponent={() => (
                                                        <img
                                                            className="absolute right-[15px] opacity-50 pointer-events-none"
                                                            src={
                                                                currentMode !== "dark"
                                                                    ? `/images/header/chevronDown.svg`
                                                                    : `/images/header/chevronDown-white.svg`
                                                            }
                                                            alt="chevronDown"
                                                        />
                                                    )}
                                                    // MenuProps={{
                                                    //     sx: {
                                                    //         "& .MuiMenu-paper": {
                                                    //             backgroundColor: dropdownColor,
                                                    //             color: dropdownText,
                                                    //         },
                                                    //         "& .MuiMenuItem-root:hover": {
                                                    //             backgroundColor: "rgba(218, 249, 244, 0.3)",
                                                    //         },
                                                    //         "& .Mui-selected": {
                                                    //             backgroundColor: "rgba(218, 249, 244, 0.3) !important",
                                                    //         }
                                                    //     },
                                                    // }}
                                                    SelectDisplayProps={{
                                                        style: {
                                                            paddingTop: "9px",
                                                            paddingBottom: "9px",
                                                            color: `${currentMode !== "dark" ? `#555555` : `#ECECEC`}`,
                                                            paddingRight: "30px",
                                                            maxWidth: "253px",
                                                            minWidth: "253px",
                                                            border: `1px solid rgba(85,85,85,0.4)`,
                                                            borderRadius: "8px",
                                                        },
                                                    }}
                                                >
                                                    <MenuItem value={0} className="truncate trunc-1 block">
                                                        {"Select Document Type"}
                                                    </MenuItem>

                                                    <MenuItem value={1} className="truncate trunc-1 block">
                                                        {"Invoice"}
                                                    </MenuItem>

                                                    {RoleAdvance && (
                                                        <MenuItem value={2} className="truncate trunc-1 block">
                                                            {"Advance Request"}
                                                        </MenuItem>
                                                    )}
                                                    {RoleActMisc && (
                                                        <MenuItem value={3} className="truncate trunc-1 block">
                                                            {"Miscellaneous Payment Request"}
                                                        </MenuItem>
                                                    )}
                                                    {RoleActReten && (
                                                        <MenuItem value={4} className="truncate trunc-1 block">
                                                            {"Retention Release"}
                                                        </MenuItem>
                                                    )}
                                                </Select>
                                            </div>
                                        </div>
                                        <div className="form-group flex mb-4">
                                            <label className="mt-3">
                                                Rule Name <span className="text-light-150">*</span>
                                            </label>

                                            <Input
                                                className={`border-${currentMode}-840 bg-${currentMode}-810 min-w-[300px] border-[1px] rounded-[8px] py-2 ml-[5rem] px-3`}
                                                placeholder="Rule Name"
                                                type="text"
                                                name="rulename"
                                                autoFocus
                                                id="rulebar"
                                                maxLength={100}
                                                value={RuleDisplay}
                                                disabled={documentGroup == 0}
                                                onChange={(e) => setRuleName(e.target.value)}
                                            />
                                        </div>

                                        {errormsgs?.message && (
                                            <p className="text-light-50 text-xs mt-[-.9rem] ml-[170px] mb-4">{errormsgs?.message}</p>
                                        )}

                                        <div className="form-group flex mb-7">
                                            <label className="mt-3">Description</label>

                                            <textarea
                                                placeholder="Max. 250 characters"
                                                rows="3"
                                                cols=""
                                                name="description"
                                                maxLength={250}
                                                className={`ml-[5.5rem] border-[1px] min-w-[300px] rounded-[8px] border-${currentMode}-840 px-3 py-2 resize-none bg-${currentMode}-810 `}
                                                value={DescriptionDisplay}
                                                disabled={documentGroup == 0}
                                                onChange={(e) => setDescription(e.target.value)}
                                            />
                                        </div>

                                        {descriptionError?.message && (
                                            <p className="text-light-50 text-xs ml-[200px] mb-2">{descriptionError?.message}</p>
                                        )}

                                        <table
                                            className={`w-full mb-4 rolemasterTable border max-w-[820px] mr-[30px] border-${currentMode}-590 accrual`}
                                        >
                                            <thead className={`bg-${currentMode}-450`}>
                                                <tr>
                                                    <th>Sl.No.</th>

                                                    <th>Field</th>

                                                    <th>Operator</th>

                                                    <th>Value</th>

                                                    <th>Action</th>
                                                </tr>
                                            </thead>

                                            <tbody>
                                                {addDataDisplay &&
                                                    AddItems?.map((elem, index) => {
                                                        const fieldNumCondition =
                                                            elem?.field_id == 2 ||
                                                            elem?.field_id == 3 ||
                                                            elem?.field_id == 5 ||
                                                            elem?.field_id == 9 ||
                                                            elem?.field_id == 10 ||
                                                            elem?.field_id == 11;
                                                        return (
                                                            <tr>
                                                                <td>{index + 1}</td>

                                                                <td>
                                                                    <FormControl
                                                                        sx={{
                                                                            minWidth: 170,
                                                                        }}
                                                                    >
                                                                        <Select
                                                                            value={elem?.field_id}
                                                                            className="selectDisabled"
                                                                            name={`filed-${index}`}
                                                                            disabled={documentGroup == 0}
                                                                            onChange={(event) => {
                                                                                handleAddFieldValueChange(
                                                                                    event.target.name,

                                                                                    event.target.value,

                                                                                    index
                                                                                );
                                                                            }}
                                                                            inputProps={{
                                                                                "aria-label": "Without label",
                                                                            }}
                                                                            IconComponent={() => (
                                                                                <img
                                                                                    className="absolute right-[15px] opacity-50 pointer-events-none"
                                                                                    src={
                                                                                        currentMode !== "dark"
                                                                                            ? `/images/header/chevronDown.svg`
                                                                                            : `/images/header/chevronDown-white.svg`
                                                                                    }
                                                                                    alt="chevronDown"
                                                                                />
                                                                            )}
                                                                            MenuProps={{
                                                                                sx: {
                                                                                    "& .MuiMenu-paper": {
                                                                                        backgroundColor: dropdownColor,
                                                                                        color: dropdownText,
                                                                                    },

                                                                                    "& .MuiMenuItem-root:hover": {
                                                                                        backgroundColor: "rgba(218, 249, 244, 0.3)",
                                                                                    },

                                                                                    "& .Mui-selected": {
                                                                                        backgroundColor: "rgba(218, 249, 244, 0.3) !important",
                                                                                    },
                                                                                },
                                                                            }}
                                                                            SelectDisplayProps={{
                                                                                style: {
                                                                                    paddingTop: "9px",

                                                                                    paddingBottom: "9px",

                                                                                    color: `${currentMode !== "dark" ? `#555555` : `#ECECEC`}`,

                                                                                    paddingRight: "30px",

                                                                                    border: "1px solid rgba(85, 85, 85, 0.3)",

                                                                                    borderRadius: "8px",
                                                                                },
                                                                            }}
                                                                        >
                                                                            <MenuItem key={0} name="field_name" value={0}>
                                                                                {"Select "}
                                                                            </MenuItem>

                                                                            {FilteredFields &&
                                                                                FilteredFields?.map((listelem, i) => {
                                                                                    return (
                                                                                        <MenuItem key={i + 1} name="field_name" value={listelem?.id}>
                                                                                            {listelem?.field}
                                                                                        </MenuItem>
                                                                                    );
                                                                                })}
                                                                        </Select>
                                                                    </FormControl>
                                                                </td>

                                                                <td>
                                                                    <FormControl
                                                                        sx={{
                                                                            minWidth: 170,
                                                                        }}
                                                                    >
                                                                        <Select
                                                                            value={elem?.operators_id}
                                                                            className="selectDisabled"
                                                                            disabled={documentGroup == 0}
                                                                            name={`fileds-${index}`}
                                                                            onChange={(event) => {
                                                                                handleAddOperatorsChange(
                                                                                    {
                                                                                        target: {
                                                                                            name: event.target.name,

                                                                                            value: event.target.value,
                                                                                        },
                                                                                    },

                                                                                    index
                                                                                );
                                                                            }}
                                                                            inputProps={{
                                                                                "aria-label": "Without label",
                                                                            }}
                                                                            IconComponent={() => (
                                                                                <img
                                                                                    className="absolute right-[15px] opacity-50 pointer-events-none"
                                                                                    src={
                                                                                        currentMode !== "dark"
                                                                                            ? `/images/header/chevronDown.svg`
                                                                                            : `/images/header/chevronDown-white.svg`
                                                                                    }
                                                                                    alt="chevronDown"
                                                                                />
                                                                            )}
                                                                            MenuProps={{
                                                                                sx: {
                                                                                    "& .MuiMenu-paper": {
                                                                                        backgroundColor: dropdownColor,
                                                                                        color: dropdownText,
                                                                                    },

                                                                                    "& .MuiMenuItem-root:hover": {
                                                                                        backgroundColor: "rgba(218, 249, 244, 0.3)",
                                                                                    },

                                                                                    "& .Mui-selected": {
                                                                                        backgroundColor: "rgba(218, 249, 244, 0.3) !important",
                                                                                    },
                                                                                },
                                                                            }}
                                                                            SelectDisplayProps={{
                                                                                style: {
                                                                                    paddingTop: "9px",

                                                                                    paddingBottom: "9px",

                                                                                    color: `${currentMode !== "dark" ? `#555555` : `#ECECEC`}`,

                                                                                    paddingRight: "30px",

                                                                                    border: "1px solid rgba(85, 85, 85, 0.3)",

                                                                                    borderRadius: "8px",
                                                                                },
                                                                            }}
                                                                        >
                                                                            <MenuItem key={0} name="operators" value={0}>
                                                                                {"Select "}
                                                                            </MenuItem>

                                                                            {fieldNumCondition &&
                                                                                operatorsNumValue?.map((listelem, i) => {
                                                                                    return (
                                                                                        <MenuItem key={i + 1} name="operators" value={listelem?.id}>
                                                                                            {listelem?.operators}
                                                                                        </MenuItem>
                                                                                    );
                                                                                })}
                                                                            {(elem?.field_id == 6 || elem?.field_id == 7 || elem?.field_id == 8) &&
                                                                                EqualOperators?.map((listelem, i) => {
                                                                                    return (
                                                                                        <MenuItem key={i + 1} name="operators" value={listelem?.id}>
                                                                                            {listelem?.operators}
                                                                                        </MenuItem>
                                                                                    );
                                                                                })}
                                                                            {elem?.field_id == 1 &&
                                                                                stringOperators?.map((listelem, i) => {
                                                                                    return (
                                                                                        <MenuItem key={i + 1} name="operators" value={listelem?.id}>
                                                                                            {listelem?.operators}
                                                                                        </MenuItem>
                                                                                    );
                                                                                })}
                                                                        </Select>
                                                                    </FormControl>
                                                                </td>

                                                                <td>
                                                                    {elem?.field_id == 7 && (
                                                                        <MultiSelectCountry
                                                                            defaultList={elem?.value}
                                                                            field={elem?.field_id}
                                                                            currentMode={currentMode}
                                                                            HandleSelect={(e, value, reason) =>
                                                                                HandleSelectCountry(value, reason, index, elem?.field_id)
                                                                            }
                                                                        ></MultiSelectCountry>
                                                                    )}
                                                                    {elem?.field_id == 8 && (
                                                                        <MultiSelectComponent
                                                                            defaultList={elem?.value}
                                                                            field={elem?.field_id}
                                                                            currentMode={currentMode}
                                                                            HandleSelect={(e, value, reason) =>
                                                                                HandleSelect(value, reason, index, elem?.field_id)
                                                                            }
                                                                        ></MultiSelectComponent>
                                                                    )}

                                                                    {elem?.field_id == 6 && (
                                                                        <FormControl
                                                                            sx={{
                                                                                minWidth: 230,
                                                                            }}
                                                                        >
                                                                            <Select
                                                                                value={elem?.value?.length == 0 ? 0 : elem?.value}
                                                                                name={`fileds-${index}`}
                                                                                className="selectDisabled"
                                                                                disabled={documentGroup == 0}
                                                                                onChange={(event) => {
                                                                                    handleDocAddValueChange(
                                                                                        {
                                                                                            target: {
                                                                                                name: event.target.name,

                                                                                                value: event.target.value,
                                                                                            },
                                                                                        },

                                                                                        index
                                                                                    );
                                                                                }}
                                                                                inputProps={{
                                                                                    "aria-label": "Without label",
                                                                                }}
                                                                                IconComponent={() => (
                                                                                    <img
                                                                                        className="absolute right-[15px] opacity-50 pointer-events-none"
                                                                                        src={
                                                                                            currentMode !== "dark"
                                                                                                ? `/images/header/chevronDown.svg`
                                                                                                : `/images/header/chevronDown-white.svg`
                                                                                        }
                                                                                        alt="chevronDown"
                                                                                    />
                                                                                )}
                                                                                MenuProps={{
                                                                                    sx: {
                                                                                        "& .MuiMenu-paper": {
                                                                                            backgroundColor: dropdownColor,
                                                                                            color: dropdownText,
                                                                                        },

                                                                                        "& .MuiMenuItem-root:hover": {
                                                                                            backgroundColor: "rgba(218, 249, 244, 0.3)",
                                                                                        },

                                                                                        "& .Mui-selected": {
                                                                                            backgroundColor: "rgba(218, 249, 244, 0.3) !important",
                                                                                        },
                                                                                    },
                                                                                }}
                                                                                SelectDisplayProps={{
                                                                                    style: {
                                                                                        paddingTop: "9px",

                                                                                        paddingBottom: "9px",

                                                                                        color: `${currentMode !== "dark" ? `#555555` : `#ECECEC`}`,

                                                                                        paddingRight: "30px",

                                                                                        border: "1px solid rgba(85, 85, 85, 0.3)",

                                                                                        borderRadius: "8px",
                                                                                    },
                                                                                }}
                                                                            >
                                                                                <MenuItem key={0} name="DocumnetType" value={0}>
                                                                                    {"Select "}
                                                                                </MenuItem>
                                                                                <MenuItem value={1}>PO Invoice</MenuItem>
                                                                                <MenuItem value={2}>Non-PO Invoice</MenuItem>
                                                                            </Select>
                                                                        </FormControl>
                                                                    )}
                                                                    {elem?.field_id == 1 && (
                                                                        <div className="flex items-center space-x-4 justify-between">
                                                                            <div className="flex-initial filter solo">
                                                                                <Autocomplete
                                                                                    className={`w-full min-w-[230px] border-[1px]  pl-[15px] pr-[20px] bg-${currentMode}-810 text-${currentMode}-copy border620 rounded-[8px] `}
                                                                                    multiple
                                                                                    disabled={documentGroup == 0}
                                                                                    onInputChange={(e, newValue) => {
                                                                                        elem.value = [e.target.value];
                                                                                    }}
                                                                                    sx={{
                                                                                        "& .MuiInputBase-root": {
                                                                                            border: 0,
                                                                                            padding: "0 !important",
                                                                                            borderColor: `${currentMode == "dark"
                                                                                                ? "rgba(118, 118, 118, 0.3)"
                                                                                                : "#e5e7eb"
                                                                                                }`,
                                                                                        },
                                                                                        ".MuiOutlinedInput-input::placeholder": {
                                                                                            color: "grey", // Change the color to your desired color
                                                                                            opacity: 1,
                                                                                        },
                                                                                        "& .MuiChip-label": {
                                                                                            color: `${currentMode == "dark" ? "white" : "black"}`,
                                                                                        },
                                                                                        ".MuiAutocomplete-endAdornment": {
                                                                                            right: "-12px !important",
                                                                                        },
                                                                                    }}
                                                                                    id="tags-filled"
                                                                                    options={[]}
                                                                                    // disabled={!email_enabled}
                                                                                    onChange={(event, value, reason) => {
                                                                                        handleAddValueChange(
                                                                                            {
                                                                                                target: {
                                                                                                    name: event.target.name,
                                                                                                    value: event.target.value,
                                                                                                },
                                                                                            },
                                                                                            index,
                                                                                            reason,
                                                                                            elem?.seq_no,
                                                                                            value,
                                                                                            event
                                                                                        );
                                                                                    }}
                                                                                    // defaultValue={[]}
                                                                                    // value={ValueArray}
                                                                                    disablePortal
                                                                                    disableCloseOnSelect
                                                                                    autoHighlight={true}
                                                                                    freeSolo
                                                                                    style={{
                                                                                        width: "100%",
                                                                                        border: "1px solid rgb(140 144 151)",
                                                                                        padding: 0,
                                                                                    }}
                                                                                    limitTags={1}
                                                                                    renderTags={(value, getTagProps) =>
                                                                                        value &&
                                                                                        value?.map((option, index) => {
                                                                                            // setValueArray(value);

                                                                                            return (
                                                                                                <Chip
                                                                                                    key={index}
                                                                                                    variant="outlined"
                                                                                                    label={option}
                                                                                                    {...getTagProps({
                                                                                                        index,
                                                                                                    })}
                                                                                                />
                                                                                            );
                                                                                        })
                                                                                    }
                                                                                    renderInput={(params) => (
                                                                                        <TextField
                                                                                            sx={{
                                                                                                "& .MuiInputBase-input": {
                                                                                                    color: `${currentMode == "dark" ? "white" : "black"
                                                                                                        } !important`,
                                                                                                },
                                                                                            }}
                                                                                            fullWidth
                                                                                            {...params}
                                                                                            placeholder={
                                                                                                elem?.value?.length > 0 ? "" : "Input Value and Enter"
                                                                                            }
                                                                                        // onKeyDown={(e, valueTags) => {
                                                                                        //   if (e.key === ";") {
                                                                                        //     setValueArray(ValueArray.concat(e.target.value)
                                                                                        //     );

                                                                                        //   }

                                                                                        // }}
                                                                                        />
                                                                                    )}
                                                                                />
                                                                            </div>

                                                                            <span className={`flex-1 `}>
                                                                                <LightTooltip
                                                                                    title={
                                                                                        <div className={`p-3`}>
                                                                                            <span className={`text-${currentMode}-copy text-sm `}>
                                                                                                Type a vendor value and press Enter. Repeat the same
                                                                                                steps to add more vendor values.
                                                                                            </span>
                                                                                        </div>
                                                                                    }
                                                                                >
                                                                                    <img
                                                                                        className="max-w-[inherit]"
                                                                                        src="/images/common/Info-icon.svg"
                                                                                    ></img>
                                                                                </LightTooltip>
                                                                            </span>
                                                                        </div>
                                                                    )}

                                                                    {elem?.field_id !== 1 &&
                                                                        elem?.field_id !== 6 &&
                                                                        elem?.field_id !== 7 &&
                                                                        elem?.field_id !== 8 && (
                                                                            <Input
                                                                                className={`h-[40px] min-w-[230px] border-[1px] py-[2.3px] px-5 border-${currentMode}-840 bg-${currentMode}-810 text-${currentMode}-copy rounded-[8px]  placeholder:text-[grey] pl-[15px]`}
                                                                                placeholder="Input Value"
                                                                                disabled={documentGroup == 0}
                                                                                value={elem?.value}
                                                                                type="text"
                                                                                name="value"
                                                                                onChange={(event) => {
                                                                                    handleAmountAddValueChange(
                                                                                        {
                                                                                            target: {
                                                                                                name: event.target.name,

                                                                                                value: event.target.value,
                                                                                            },
                                                                                        },

                                                                                        index
                                                                                    );
                                                                                }}
                                                                            />
                                                                        )}
                                                                </td>
                                                                <td className="text-center p-[10px] ">
                                                                    <Button
                                                                        onClick={() => tableRowRemove(index)}
                                                                        disabled={AddItems?.length == 1 || documentGroup == 0}
                                                                    >
                                                                        <img
                                                                            src="/images/main/divide-circle.svg"
                                                                            alt="divide-circle"
                                                                            className="convertWhite"
                                                                        />
                                                                    </Button>
                                                                </td>
                                                            </tr>
                                                        );
                                                    })}

                                                {dataDisplay &&
                                                    DataItems?.map((elem, index) => {
                                                        const fieldNumCondition =
                                                            elem?.field_id == 2 ||
                                                            elem?.field_id == 3 ||
                                                            elem?.field_id == 5 ||
                                                            elem?.field_id == 9 ||
                                                            elem?.field_id == 10 ||
                                                            elem?.field_id == 11;
                                                        return (
                                                            <tr>
                                                                <td>{index + 1}</td>

                                                                <td>
                                                                    <FormControl
                                                                        sx={{
                                                                            minWidth: 170,
                                                                        }}
                                                                    >
                                                                        <Select
                                                                            disabled={documentGroup == 0}
                                                                            value={elem?.field_id}
                                                                            className="selectDisabled"
                                                                            name={`field-${index}`}
                                                                            onChange={(event) => {
                                                                                handleFieldValueChange(
                                                                                    {
                                                                                        target: {
                                                                                            name: event.target.name,

                                                                                            value: event.target.value,
                                                                                        },
                                                                                    },

                                                                                    index
                                                                                );
                                                                            }}
                                                                            inputProps={{
                                                                                "aria-label": "Without label",
                                                                            }}
                                                                            IconComponent={() => (
                                                                                <img
                                                                                    className="absolute right-[15px] opacity-50 pointer-events-none"
                                                                                    src={
                                                                                        currentMode !== "dark"
                                                                                            ? `/images/header/chevronDown.svg`
                                                                                            : `/images/header/chevronDown-white.svg`
                                                                                    }
                                                                                    alt="chevronDown"
                                                                                />
                                                                            )}
                                                                            MenuProps={{
                                                                                sx: {
                                                                                    "& .MuiMenu-paper": {
                                                                                        backgroundColor: dropdownColor,
                                                                                        color: dropdownText,
                                                                                    },

                                                                                    "& .MuiMenuItem-root:hover": {
                                                                                        backgroundColor: "rgba(218, 249, 244, 0.3)",
                                                                                    },

                                                                                    "& .Mui-selected": {
                                                                                        backgroundColor: "rgba(218, 249, 244, 0.3) !important",
                                                                                    },
                                                                                },
                                                                            }}
                                                                            SelectDisplayProps={{
                                                                                style: {
                                                                                    paddingTop: "9px",

                                                                                    paddingBottom: "9px",

                                                                                    color: `${currentMode !== "dark" ? `#555555` : `#ECECEC`}`,

                                                                                    paddingRight: "30px",

                                                                                    border: "1px solid rgba(85, 85, 85, 0.3)",

                                                                                    borderRadius: "8px",
                                                                                },
                                                                            }}
                                                                        >
                                                                            <MenuItem key={0} name="field_name" value={0}>
                                                                                {"Select "}
                                                                            </MenuItem>

                                                                            {FilteredFields &&
                                                                                FilteredFields?.map((listelem, i) => {
                                                                                    return (
                                                                                        <MenuItem key={i + 1} name="field_name" value={listelem?.id}>
                                                                                            {listelem?.field}
                                                                                        </MenuItem>
                                                                                    );
                                                                                })}
                                                                        </Select>
                                                                    </FormControl>
                                                                </td>

                                                                <td>
                                                                    <FormControl
                                                                        sx={{
                                                                            minWidth: 170,
                                                                        }}
                                                                    >
                                                                        <Select
                                                                            disabled={documentGroup == 0}
                                                                            value={elem?.operators_id}
                                                                            name="operators"
                                                                            className="selectDisabled"
                                                                            onChange={(event) => {
                                                                                handleOperatorsChange(
                                                                                    {
                                                                                        target: {
                                                                                            name: event.target.name,

                                                                                            value: event.target.value,
                                                                                        },
                                                                                    },

                                                                                    index
                                                                                );
                                                                            }}
                                                                            inputProps={{
                                                                                "aria-label": "Without label",
                                                                            }}
                                                                            IconComponent={() => (
                                                                                <img
                                                                                    className="absolute right-[15px] opacity-50 pointer-events-none"
                                                                                    src={
                                                                                        currentMode !== "dark"
                                                                                            ? `/images/header/chevronDown.svg`
                                                                                            : `/images/header/chevronDown-white.svg`
                                                                                    }
                                                                                    alt="chevronDown"
                                                                                />
                                                                            )}
                                                                            MenuProps={{
                                                                                sx: {
                                                                                    "& .MuiMenu-paper": {
                                                                                        backgroundColor: dropdownColor,
                                                                                        color: dropdownText,
                                                                                    },

                                                                                    "& .MuiMenuItem-root:hover": {
                                                                                        backgroundColor: "rgba(218, 249, 244, 0.3)",
                                                                                    },

                                                                                    "& .Mui-selected": {
                                                                                        backgroundColor: "rgba(218, 249, 244, 0.3) !important",
                                                                                    },
                                                                                },
                                                                            }}
                                                                            SelectDisplayProps={{
                                                                                style: {
                                                                                    paddingTop: "9px",

                                                                                    paddingBottom: "9px",

                                                                                    color: `${currentMode !== "dark" ? `#555555` : `#ECECEC`}`,

                                                                                    paddingRight: "30px",

                                                                                    border: "1px solid rgba(85, 85, 85, 0.3)",

                                                                                    borderRadius: "8px",
                                                                                },
                                                                            }}
                                                                        >
                                                                            <MenuItem key={0} name="operators" value={0}>
                                                                                {"Select "}
                                                                            </MenuItem>

                                                                            {fieldNumCondition &&
                                                                                operatorsNumValue?.map((listelem, i) => {
                                                                                    return (
                                                                                        <MenuItem key={i + 1} name="operators" value={listelem?.id}>
                                                                                            {listelem?.operators}
                                                                                        </MenuItem>
                                                                                    );
                                                                                })}
                                                                            {(elem?.field_id == 6 || elem?.field_id == 7 || elem?.field_id == 8) &&
                                                                                EqualOperators?.map((listelem, i) => {
                                                                                    return (
                                                                                        <MenuItem key={i + 1} name="operators" value={listelem?.id}>
                                                                                            {listelem?.operators}
                                                                                        </MenuItem>
                                                                                    );
                                                                                })}
                                                                            {elem?.field_id == 1 &&
                                                                                stringOperators?.map((listelem, i) => {
                                                                                    return (
                                                                                        <MenuItem key={i + 1} name="operators" value={listelem?.id}>
                                                                                            {listelem?.operators}
                                                                                        </MenuItem>
                                                                                    );
                                                                                })}
                                                                        </Select>
                                                                    </FormControl>
                                                                </td>

                                                                <td>
                                                                    {elem?.field_id == 6 && (
                                                                        <FormControl
                                                                            sx={{
                                                                                minWidth: 230,
                                                                            }}
                                                                        >
                                                                            <Select
                                                                                value={elem?.value?.length == 0 ? 0 : elem?.value}
                                                                                disabled={documentGroup == 0}
                                                                                name={`fileds-${index}`}
                                                                                className="selectDisabled"
                                                                                onChange={(event) => {
                                                                                    handleDocValueChange(
                                                                                        {
                                                                                            target: {
                                                                                                name: event.target.name,

                                                                                                value: event.target.value,
                                                                                            },
                                                                                        },

                                                                                        index
                                                                                    );
                                                                                }}
                                                                                inputProps={{
                                                                                    "aria-label": "Without label",
                                                                                }}
                                                                                IconComponent={() => (
                                                                                    <img
                                                                                        className="absolute right-[15px] opacity-50 pointer-events-none"
                                                                                        src={
                                                                                            currentMode !== "dark"
                                                                                                ? `/images/header/chevronDown.svg`
                                                                                                : `/images/header/chevronDown-white.svg`
                                                                                        }
                                                                                        alt="chevronDown"
                                                                                    />
                                                                                )}
                                                                                MenuProps={{
                                                                                    sx: {
                                                                                        "& .MuiMenu-paper": {
                                                                                            backgroundColor: dropdownColor,
                                                                                            color: dropdownText,
                                                                                        },

                                                                                        "& .MuiMenuItem-root:hover": {
                                                                                            backgroundColor: "rgba(218, 249, 244, 0.3)",
                                                                                        },

                                                                                        "& .Mui-selected": {
                                                                                            backgroundColor: "rgba(218, 249, 244, 0.3) !important",
                                                                                        },
                                                                                    },
                                                                                }}
                                                                                SelectDisplayProps={{
                                                                                    style: {
                                                                                        paddingTop: "9px",

                                                                                        paddingBottom: "9px",

                                                                                        color: `${currentMode !== "dark" ? `#555555` : `#ECECEC`}`,

                                                                                        paddingRight: "30px",

                                                                                        border: "1px solid rgba(85, 85, 85, 0.3)",

                                                                                        borderRadius: "8px",
                                                                                    },
                                                                                }}
                                                                            >
                                                                                <MenuItem key={0} name="DocumnetType" value={0}>
                                                                                    {"Select "}
                                                                                </MenuItem>
                                                                                <MenuItem value={1}>PO Invoice</MenuItem>
                                                                                <MenuItem value={2}>Non-Po Invoice</MenuItem>
                                                                            </Select>
                                                                        </FormControl>
                                                                    )}
                                                                    {elem?.field_id == 1 && (
                                                                        <div className="flex items-center space-x-4 justify-between">
                                                                            <div className="flex-initial  solo">
                                                                                <Autocomplete
                                                                                    className={`w-full min-w-[230px] border-[1px] py-[2.3px] px-5 bg-${currentMode}-810 text-${currentMode}-copy border620 rounded-[8px] `}
                                                                                    multiple
                                                                                    disabled={documentGroup == 0}
                                                                                    onInputChange={(e, newValue) => {
                                                                                        setExistValueArray(newValue);
                                                                                        elem.value = [newValue];
                                                                                        elem.rule_id = +RuleID;
                                                                                    }}
                                                                                    sx={{
                                                                                        "& .MuiInputBase-root": {
                                                                                            border: 0,
                                                                                            padding: "0 !important",
                                                                                            borderColor: `${currentMode == "dark"
                                                                                                ? "rgba(118, 118, 118, 0.3)"
                                                                                                : "#e5e7eb"
                                                                                                }`,
                                                                                        },
                                                                                        ".MuiOutlinedInput-input::placeholder": {
                                                                                            color: "grey", // Change the color to your desired color
                                                                                            opacity: 1,
                                                                                        },
                                                                                        "& .MuiChip-label": {
                                                                                            color: `${currentMode == "dark" ? "white" : "black"}`,
                                                                                        },
                                                                                    }}
                                                                                    id="tags-filled"
                                                                                    options={[]}
                                                                                    // disabled={!email_enabled}
                                                                                    disablePortal
                                                                                    onChange={(event, value, reason) => {
                                                                                        handleValueChange(
                                                                                            {
                                                                                                target: {
                                                                                                    name: event.target.name,
                                                                                                    value: event.target.value,
                                                                                                },
                                                                                            },
                                                                                            index,
                                                                                            value,
                                                                                            reason,
                                                                                            elem?.seq_no
                                                                                        );
                                                                                    }}
                                                                                    // value={elem?.value}
                                                                                    defaultValue={elem?.value}
                                                                                    // disableClearable
                                                                                    disableCloseOnSelect
                                                                                    autoHighlight={true}
                                                                                    freeSolo
                                                                                    style={{
                                                                                        width: "100%",
                                                                                        border: "1px solid rgb(140 144 151)",
                                                                                        padding: 0,
                                                                                    }}
                                                                                    limitTags={1}
                                                                                    renderTags={(value, getTagProps) =>
                                                                                        value &&
                                                                                        value?.map((option, index) => {
                                                                                            setExistValueArray(value);

                                                                                            return (
                                                                                                <Chip
                                                                                                    key={index}
                                                                                                    variant="outlined"
                                                                                                    label={option}
                                                                                                    // onDelete={() => {
                                                                                                    //   setExistValueArray(mailCc.filter((e) => e !== option));
                                                                                                    // }}

                                                                                                    {...getTagProps({
                                                                                                        index,
                                                                                                    })}
                                                                                                />
                                                                                            );
                                                                                        })
                                                                                    }
                                                                                    renderInput={(params) => (
                                                                                        <TextField
                                                                                            sx={{
                                                                                                "& .MuiInputBase-input": {
                                                                                                    color: `${currentMode == "dark" ? "white" : "black"
                                                                                                        } !important`,
                                                                                                },
                                                                                                ".MuiAutocomplete-endAdornment": {
                                                                                                    right: "-12px !important",
                                                                                                },
                                                                                            }}
                                                                                            fullWidth
                                                                                            {...params}
                                                                                            placeholder={
                                                                                                elem?.value?.length > 0 ? "" : "Input Value and Enter"
                                                                                            }
                                                                                        />
                                                                                    )}
                                                                                />
                                                                            </div>
                                                                            <span className={`flex-1`}>
                                                                                <LightTooltip
                                                                                    title={
                                                                                        <div className=" p-3">
                                                                                            <span className={`text-${currentMode}-copy text-sm`}>
                                                                                                Multiple entries can be made in a vendor's value field
                                                                                                at once.
                                                                                            </span>
                                                                                        </div>
                                                                                    }
                                                                                >
                                                                                    <img
                                                                                        className="max-w-[inherit]"
                                                                                        src="/images/common/Info-icon.svg"
                                                                                    ></img>
                                                                                </LightTooltip>
                                                                            </span>
                                                                        </div>
                                                                    )}
                                                                    {elem?.field_id !== 1 &&
                                                                        elem?.field_id !== 6 &&
                                                                        elem?.field_id !== 7 &&
                                                                        elem?.field_id !== 8 && (
                                                                            <Input
                                                                                className={`h-[40px] min-w-[230px] border-[1px] py-[2.3px] px-5 border-${currentMode}-840 bg-${currentMode}-810 text-${currentMode}-copy rounded-[8px]  placeholder:text-[grey] pl-[25px]`}
                                                                                placeholder="Input Value"
                                                                                value={elem?.value}
                                                                                disabled={documentGroup == 0}
                                                                                type="text"
                                                                                onChange={(event) => {
                                                                                    handleAmountValueChange(
                                                                                        {
                                                                                            target: {
                                                                                                name: event.target.name,

                                                                                                value: event.target.value,
                                                                                            },
                                                                                        },

                                                                                        index
                                                                                    );
                                                                                }}
                                                                            />
                                                                        )}
                                                                    {elem?.field_id == 7 && (
                                                                        <MultiSelectCountry
                                                                            field={elem?.field_id}
                                                                            documentGroup={documentGroup}
                                                                            defaultList={elem?.value}
                                                                            currentMode={currentMode}
                                                                            HandleSelect={(e, value, reason) =>
                                                                                HandleSelectCountry(value, reason, index)
                                                                            }
                                                                        ></MultiSelectCountry>
                                                                    )}
                                                                    {elem?.field_id == 8 && (
                                                                        <MultiSelectComponent
                                                                            field={elem?.field_id}
                                                                            documentGroup={documentGroup}
                                                                            defaultList={elem?.value}
                                                                            currentMode={currentMode}
                                                                            HandleSelect={(e, value, reason) => HandleSelect(value, reason, index)}
                                                                        ></MultiSelectComponent>
                                                                    )}
                                                                </td>

                                                                <td className="text-center p-[10px]">
                                                                    <Button
                                                                        onClick={() => tableRowRemove(index)}
                                                                        disabled={DataItems?.length == 1 || documentGroup == 0}
                                                                    >
                                                                        <img
                                                                            src="/images/main/divide-circle.svg"
                                                                            alt="divide-circle"
                                                                            className="convertWhite"
                                                                        />
                                                                    </Button>
                                                                </td>
                                                            </tr>
                                                        );
                                                    })}
                                            </tbody>
                                        </table>

                                        {addDataDisplay && (
                                            <Button disabled={documentGroup == 0} onClick={() => addRow()} className="text-light-20">
                                                + Add New
                                            </Button>
                                        )}

                                        {dataDisplay && (
                                            <Button disabled={documentGroup == 0} onClick={() => addRowTable()} className="text-light-20">
                                                + Add New
                                            </Button>
                                        )}
                                    </div>
                                </>
                            )}
                        </Card>
                    )}

                    {!view && (
                        <div
                            className={`bg-${currentMode}-card text-${currentMode}-copy border-${currentMode}-700 py-[20px] space-x-2 px-[30px] sticky left-0 right-0 shadow-[0px_4px_20px_rgba(85,85,85,0.2)] border-[1px] border-[#dfe1e28f] rounded-b-[10px]`}
                        >
                            <Button
                                className="bg-light-20 text-white text-base rounded-[8px] py-2 px-7"
                                onClick={Submit}
                                title={"Save"}
                                disabled={fetching || documentGroup == 0}
                            >
                                {"Save"}
                            </Button>

                            <Link to="/invoice-rule" title="Cancel" >
                                <Button className="bg-light-240  text-light-40 text-base  rounded-[8px] py-2 px-7">
                                    Cancel
                                </Button>
                            </Link>
                        </div>
                    )}
                </Main>

                <Footer />
            </Layout>
        </>
    );
};

export default RuleBased;
