import axios from "axios";
import configMyiQ from "../../configuration";
import authHeader from "./auth-header";
import { checkApiStatus } from "../../utils/helpers";
import {
	GET_ACCRUAL_ACCOUNTING_SUCCESS,
	GET_ACCRUAL_ACCOUNTING_FAILURE,
	GET_ACCRUAL_ACCOUNTING_RESET,
	GET_ACCRUAL_ACCOUNTING_REQUEST,
	ADD_ACCRUAL_ACCOUNTING_SUCCESS,
	ADD_ACCRUAL_ACCOUNTING_FAILURE,
	ADD_ACCRUAL_ACCOUNTING_RESET,
	ADD_ACCRUAL_ACCOUNTING_REQUEST,
	GET_ACCRUAL_POSTING_REQUEST,
	GET_ACCRUAL_POSTING_SUCCESS,
	GET_ACCRUAL_POSTING_FAILURE,
	ADD_ACCRUAL_POSTING_REQUEST,
	ADD_ACCRUAL_POSTING_SUCCESS,
	ADD_ACCRUAL_POSTING_FAILURE,
	GET_ACCRUAL_POSTING_DATES_REQUEST,
	GET_ACCRUAL_POSTING_DATES_SUCCESS,
	GET_ACCRUAL_POSTING_DATES_FAILURE,
	GET_MONTH_CLOSING_REQUEST,
	GET_MONTH_CLOSING_SUCCESS,
	GET_MONTH_CLOSING_FAILURE,
	POST_MONTH_CLOSING_REQUEST,
	POST_MONTH_CLOSING_SUCCESS,
	POST_MONTH_CLOSING_FAILURE,
	GET_ACCRUAL_VALIDATE_FAILURE,
	GET_ACCRUAL_VALIDATE_REQUEST,
	GET_ACCRUAL_VALIDATE_SUCCESS,
} from "../types/types.js";
import localforage from "localforage";
import Cookies from "js-cookie";

const API_URL = process.env?.REACT_APP_API_URL;
const NODE_API_URL = process.env?.REACT_APP_NODE_API_URL;

export const GetAccrualAccounting = (organisation) => async (dispatch) => {
	try {
		dispatch({ type: GET_ACCRUAL_ACCOUNTING_REQUEST });

		axios
			.get(
				`${NODE_API_URL}api/accrualAccounting/getAccrualConfig?organisation=${organisation}`,
				{
					headers: authHeader(),
				}
			)
			.then((response) => {
				dispatch({
					type: GET_ACCRUAL_ACCOUNTING_SUCCESS,
					payload: response?.data,
				});
			})
			.catch(function (error) {
				if (error?.response?.status == 401) {
					checkApiStatus();
				}
				dispatch({
					payload: { error: error.message },
					type: GET_ACCRUAL_ACCOUNTING_FAILURE,
				});
			});
	} catch (error) {
		dispatch({
			type: GET_ACCRUAL_ACCOUNTING_FAILURE,
		});
	}
};

export const AddAccrualAccounting = (data) => async (dispatch) => {
	try {
		dispatch({ type: ADD_ACCRUAL_ACCOUNTING_REQUEST });

		axios
			.post(`${API_URL}AccrualAccounting/AccuralAccountingConfig`, data, {
				headers: authHeader(),
			})
			.then((response) => {
				dispatch({
					type: ADD_ACCRUAL_ACCOUNTING_SUCCESS,
					payload: response.data,
				});
			})
			.catch(function (error) {
				if (error?.response?.status == 401) {
					checkApiStatus();
				}
				dispatch({
					payload: { error: error.message },
					type: ADD_ACCRUAL_ACCOUNTING_FAILURE,
				});
			});
	} catch (error) {
		dispatch({
			type: ADD_ACCRUAL_ACCOUNTING_FAILURE,
		});
	}
};
export const GetAccrualPosting = () => async (dispatch) => {
	try {
		dispatch({ type: GET_ACCRUAL_POSTING_REQUEST });

		axios
			.get(`${API_URL}AccrualAccounting/GetAccrualPosting`, {
				headers: authHeader(),
			})
			.then((response) => {
				dispatch({
					type: GET_ACCRUAL_POSTING_SUCCESS,
					payload: response.data.datalist,
				});
			})
			.catch(function (error) {
				if (error?.response?.status == 401) {
					checkApiStatus();
				}
				dispatch({
					payload: { error: error.message },
					type: GET_ACCRUAL_POSTING_FAILURE,
				});
			});
	} catch (error) {
		dispatch({
			type: GET_ACCRUAL_POSTING_FAILURE,
		});
	}
};
export const AddAccrualPosting = (data) => async (dispatch) => {
	try {
		dispatch({ type: ADD_ACCRUAL_POSTING_REQUEST });

		axios
			.post(`${API_URL}AccrualAccounting/AccrualPosting`, data, {
				headers: authHeader(),
			})
			.then((response) => {
				dispatch({
					type: ADD_ACCRUAL_POSTING_SUCCESS,
					payload: response.data,
				});
			})
			.catch(function (error) {
				if (error?.response?.status == 401) {
					checkApiStatus();
				}
				dispatch({
					payload: { error: error.message },
					type: ADD_ACCRUAL_POSTING_FAILURE,
				});
			});
	} catch (error) {
		dispatch({
			type: ADD_ACCRUAL_POSTING_FAILURE,
		});
	}
};

export const GetAccrualPostingDate = (orgId) => async (dispatch) => {
	try {
		dispatch({ type: GET_ACCRUAL_POSTING_DATES_REQUEST });

		axios
			.get(
				`${API_URL}AccrualAccounting/GetAccrualPostingDates?orgid=${orgId}`,
				{
					headers: authHeader(),
				}
			)
			.then((response) => {
				dispatch({
					type: GET_ACCRUAL_POSTING_DATES_SUCCESS,
					payload: response.data.datalist,
				});
			})
			.catch(function (error) {
				if (error?.response?.status == 401) {
					checkApiStatus();
				}
				dispatch({
					payload: { error: error.message },
					type: GET_ACCRUAL_POSTING_DATES_FAILURE,
				});
			});
	} catch (error) {
		dispatch({
			type: GET_ACCRUAL_POSTING_DATES_FAILURE,
		});
	}
};

export const GetMonthClosingAction = (organisation) => async (dispatch) => {
	try {
		dispatch({ type: GET_MONTH_CLOSING_REQUEST });

		axios
			.get(
				`${NODE_API_URL}api/accrualAccounting/getAccrualMonthEnd?org_id=${organisation}`,
				{
					headers: authHeader(),
				}
			)
			.then((response) => {
				dispatch({
					type: GET_MONTH_CLOSING_SUCCESS,
					payload: response.data.datalist,
				});
			})
			.catch(function (error) {
				if (error?.response?.status == 401) {
					checkApiStatus();
				}
				dispatch({
					payload: { error: error.message },
					type: GET_MONTH_CLOSING_FAILURE,
				});
			});
	} catch (error) {
		dispatch({
			type: GET_MONTH_CLOSING_FAILURE,
		});
	}
};

export const MonthClosingPostAction = (data) => async (dispatch) => {
	try {
		dispatch({ type: POST_MONTH_CLOSING_REQUEST });

		axios
			.post(
				`${NODE_API_URL}api/accrualAccounting/addAccrualMonEnd`,
				data,
				{
					headers: authHeader(),
				}
			)
			.then((response) => {
				dispatch({
					type: POST_MONTH_CLOSING_SUCCESS,
					payload: response?.data,
				});
			})
			.catch(function (error) {
				if (error?.response?.status == 401) {
					checkApiStatus();
				}
				dispatch({
					payload: { error: error.message },
					type: POST_MONTH_CLOSING_FAILURE,
				});
			});
	} catch (error) {
		dispatch({
			type: POST_MONTH_CLOSING_FAILURE,
		});
	}
};

export const GetAccrualValidatePostAct = (org, month) => async (dispatch) => {
	try {
		dispatch({ type: GET_ACCRUAL_VALIDATE_REQUEST });

		axios
			.get(
				`${NODE_API_URL}api/accrualAccounting/getAccrualMonthEndPosting?org_id=${org}&month_closed=${month}`,
				{
					headers: authHeader(),
				}
			)
			.then((response) => {
				dispatch({
					type: GET_ACCRUAL_VALIDATE_SUCCESS,
					payload: response.data.datalist,
				});
			})
			.catch(function (error) {
				if (error?.response?.status == 401) {
					checkApiStatus();
				}
				dispatch({
					payload: { error: error.message },
					type: GET_ACCRUAL_VALIDATE_FAILURE,
				});
			});
	} catch (error) {
		dispatch({
			type: GET_ACCRUAL_VALIDATE_FAILURE,
		});
	}
};
