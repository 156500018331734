import styles from "./index.module.scss";
import Stepper from "./Stepper";
import Card from "../../components/UI/Card";
import Layout from "../../Hoc/Layout";
import Main from "../../components/UI/Main";
import Footer from "../../organisms/Footer";
import { Link } from "react-router-dom";
import { GetViewRuleList } from "../../redux/actions/rule-based";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useContext } from "react";
import Loader from "../../components/Loader";
import { GET_RULE_VIEW_RESET } from "../../redux/types/types";
import { GetRuleView } from "../../redux/actions/rule-based";
import { ThemeContext } from "../../Hooks/Themes/Themeprovider";
import { GetCurrencyListExchangeRate } from "../../redux/actions/exchange-rate";
import { countryList } from "../../redux/actions/country";
const ViewRuleItems = () => {
    const { currentMode, toggleMode } = useContext(ThemeContext);
    const dispatch = useDispatch();
    const params = new URLSearchParams(window.location.search);
    let RuleID = params.get("ruleid");
    useEffect(() => {
        dispatch(GetRuleView(RuleID));
        dispatch(GetCurrencyListExchangeRate());
        dispatch(countryList());
    }, [dispatch]);
    const { data: ViewRule } = useSelector((state) => state?.RuleViewState);
    const { requestComplete, fetching, data, error } = useSelector(
        (state) => state?.RuleViewState
    );
    const DataValue = ViewRule?.[0];
    const DataItems = DataValue?.rules_info;
    const DataIym = DataItems?.[0];
    const datahd = DataIym?.value;
    const aryuygd = datahd?.map((el) => el);
    const handleClose = () => {
        dispatch({ type: GET_RULE_VIEW_RESET });
    };
    const Currency = useSelector((state) => state?.currencyListState);
    const currencies = Currency?.data
    const { country } = useSelector((state) => state?.countryState);


    return (
        <Layout>
            <Main>
                <div
                    className={`text-${currentMode}-copy flex justify-between items-center mb-6`}
                >
                    <div>
                        <h4 className="text-2xl font-interSb">Rule</h4>
                    </div>
                </div>
                <Card
                    className={`h-[calc(100vh_-_13rem)] mb-[2.5rem] pt-[30px] px-[30px] w-full overflow-y-auto ${currentMode === "dark"
                        ? ""
                        : "shadow-[0px_4px_20px_rgba(238,238,238,0.5)]"
                        } relative bg-${currentMode}-card text-${currentMode}-copy border-${currentMode}-700`}
                >
                    {fetching ? (
                        <Loader className="h-[calc(100vh_-_17rem)]" />
                    ) : (
                        <>
                            <Link to="/invoice-rule">
                                <img
                                    className="absolute right-[30px] cursor-pointer top-[30px] convertWhite"
                                    src="/images/common/closeBlk.svg"
                                    onClick={handleClose}
                                />
                            </Link>
                            <div className="flex items-start mb-10">
                                <Link to="/invoice-rule">
                                    <img
                                        className="mr-4 cursor-pointer convertWhite"
                                        src="/images/main/noun-back.svg"
                                        alt="noun-back"
                                        onClick={handleClose}
                                    />
                                </Link>

                                <div>
                                    <h5 className="flex mb-3">
                                        {DataValue?.rule_name}
                                    </h5>

                                    <p className="text-xs text-light-70 break-normal md:break-all max-w-[700px]">
                                        {DataValue?.rule_description}
                                    </p>
                                </div>
                            </div>
                            <div className={styles?.stepperMain}>
                                {DataItems?.map((elem, index) => {
                                    let Valuemap = elem?.value;
                                    let Value =
                                        elem?.fiel_id !== 1
                                            ? elem.value
                                            : Valuemap?.map((el) => el);
                                    const FilterCurrency = currencies?.filter(item => elem?.value?.includes(item?.currency_id))
                                    const FindCurrency = FilterCurrency?.map((items) => items?.currency_code)

                                    return (
                                        <Stepper no={index + 1}>
                                            <div
                                                className={`${styles[`steppers-right`]
                                                    }  text-${currentMode}-copy border-${currentMode}-700 border-[1px]  rounded-[8px] ${currentMode === "dark"
                                                        ? ""
                                                        : "shadow-[0px_4px_20px_rgba(85,85,85,0.2)]"
                                                    }  py-[16px] px-[15px] truncate w-[400px]`}
                                            >
                                                {elem?.field_name}{" "}
                                                <span className="text-light-10">
                                                    {elem?.operators}{" "}
                                                </span>
                                                {elem?.field_id == 7 || elem?.field_id == 8 ? (
                                                    <span
                                                        title={elem?.field_id == 8 ? FindCurrency?.join(", ")
                                                            : elem?.field_id == 7 ? country?.filter(item => elem?.value?.includes(item?.country_id))?.map((items) => items?.country_name) :
                                                                ""}
                                                    >
                                                        {elem?.field_id == 8 ? FindCurrency?.join(", ")
                                                            : elem?.field_id == 7 ? country?.filter(item => elem?.value?.includes(item?.country_id))?.map((items) => items?.country_name)?.join(", ") :
                                                                ""}

                                                    </span>) : (
                                                    <>
                                                        {Valuemap?.map((el, index) => {

                                                            return (
                                                                <>
                                                                    <span
                                                                        title={elem?.field_name ==
                                                                            "Document Type" &&
                                                                            el == [1]
                                                                            ? "Po Invoice"
                                                                            : elem?.field_name ==
                                                                                "Document Type" &&
                                                                                el == [2]
                                                                                ? "Non-PO Invoice"
                                                                                :
                                                                                el}
                                                                        className={`${elem?.field_id ==
                                                                            1 &&
                                                                            "text-light-40 bg-light-400 rounded-[2px] mr-[10px] p-1"
                                                                            }`}
                                                                    >

                                                                        {elem?.field_name ==
                                                                            "Document Type" &&
                                                                            el == [1]
                                                                            ? "Po Invoice"
                                                                            : elem?.field_name ==
                                                                                "Document Type" &&
                                                                                el == [2]
                                                                                ? "Non-PO Invoice"
                                                                                :
                                                                                el}

                                                                    </span>
                                                                    {index !==
                                                                        Valuemap?.length -
                                                                        1 && ", "}
                                                                </>
                                                            );
                                                        })}
                                                    </>)}
                                            </div>
                                        </Stepper>
                                    );
                                })}
                            </div>
                            <div>
                                {DataItems == undefined && (
                                    <div className="h-full grid place-items-center place-content-center">
                                        <img
                                            className="mb-3"
                                            src="/images/common/searching.png"
                                            alt="searching"
                                        />
                                        <h4 className="font-interSb">
                                            No Datas found
                                        </h4>
                                    </div>
                                )}
                            </div>
                        </>
                    )}
                </Card>
            </Main>

            <Footer />
        </Layout >
    );
};
export default ViewRuleItems;
