// import { Tooltip } from "@mui/material";
import { ClickAwayListener, MenuItem, Select } from "@mui/material";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { useState } from "react";
import Card from "../../../components/UI/Card";
import VerticalStepper from "../../../components/VerticalStepper";
import { GetUserActivity } from "../../../redux/actions/user";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Input from "../../../components/Input";
import Loader from "../../../components/Loader";
import { useContext } from "react";
import { ThemeContext } from "../../../Hooks/Themes/Themeprovider";
const UserActivityModal = (props) => {
    const { currentMode } = useContext(ThemeContext);
    // const currentSelect = props?.currentSelect;
    //  const onPageItemSelect = props?.onPageItemSelect;
    const userid = props?.userid;
    const [currentSelect, setcurrentSelect] = useState(1);
    let reqObj = {
        user_id: userid?.id,
        filter_key: currentSelect,
    };
    const [filteredResults, setFilteredResults] = useState([]);
    const dispatch = useDispatch();

    let searchInput = props?.searchInput;
    let setSearchInput = props?.setSearchInput;

    useEffect(() => {}, [currentSelect]);
    const [open, setOpen] = useState(false);
    const onPageItemSelect = (event) => {
        let pageItemVal = event.target.value;
        reqObj.filter_key = parseInt(pageItemVal);
        setcurrentSelect(parseInt(pageItemVal));
        dispatch(GetUserActivity(reqObj));
    };

    const handleTooltipClose = () => {
        setOpen(false);
    };

    const handleTooltipOpen = () => {
        setOpen(true);
    };

    const ActivityList = useSelector((state) => state?.UserActivityListState);
    const Activitydata = useSelector((state) => state?.ActivityHistoryState);

    const { data: actData } = ActivityList;
    // const Activitydata = useSelector((state) => state?.ActivityHistoryState);
    // const { data: actDatas } = Activitydata;
    const searchItems = (searchValue) => {
        setSearchInput(searchValue);
        if (searchValue !== "") {
            const filteredData = actData?.filter((item) => {
                return Object?.values(item)?.join("")?.toLowerCase()?.includes(searchValue?.toLowerCase());
            });
            setFilteredResults(filteredData);
        } else {
            setFilteredResults(actData);
        }
    };
    const resetInputField = () => {
        searchItems("");
    };

    const LightTooltip = styled(({ className, ...props }) => (
        <Tooltip
            {...props}
            placement="top-start"
            PopperProps={{
                sx: {
                    "& .MuiTooltip-tooltip": {
                        padding: "0 5px",
                    },
                },
                modifiers: [
                    {
                        name: "offset",
                        options: {
                            offset: [-14, -80],
                        },
                    },
                ],
            }}
            classes={{ popper: className }}
        />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: theme.palette.common.white,
            color: "rgba(0, 0, 0, 0.87)",
            borderRadius: "10px",
            minWidth: "250px",
        },
    }));

    const Details = (props) => (
        <>
            <Card
                className={`flex w-full mb-3 items-center justify-between ${
                    currentMode === "dark" ? "" : "shadow-[0px_4px_20px_rgba(238,238,238,0.5)]"
                } rounded-[10px] px-2 pt-3 pb-4`}
            >
                {props?.children}
                {props?.dynamic === `modify` && (
                    <>
                        <ClickAwayListener mouseEvent="onMouseDown" touchEvent="onTouchStart" onClickAway={handleTooltipClose}>
                            <Tooltip
                                arrow
                                PopperProps={{
                                    disablePortal: true,
                                    sx: {
                                        "& .MuiTooltip-tooltip": {
                                            padding: "0",
                                            backgroundColor: "#fff",
                                            color: "#555555",
                                            minWidth: "500px",
                                            borderRadius: "8px",
                                            border: ".5px solid rgba(223, 225, 226, 0.56)",
                                            boxShadow: "0px 4px 20px rgba(178, 178, 178, 0.5)",
                                        },
                                        "& .MuiTooltip-arrow": {
                                            width: "30px",
                                            height: "17px",
                                            marginTop: "-18px !important",
                                            "&::before": {
                                                backgroundColor: "#229988",
                                            },
                                        },
                                    },
                                    modifiers: [
                                        {
                                            name: "offset",
                                            options: {
                                                offset: [0, 0],
                                            },
                                        },
                                    ],
                                }}
                                onClose={handleTooltipClose}
                                className="bg-white shadow-[0px_4px_20px_rgba(178,178,178,0.5)] z-[111] rounded-[8px] border-[1px] border-[rgba(223,225,226,0.56)]"
                                open={open}
                                disableFocusListener
                                disableHoverListener
                                disableTouchListener
                                title={
                                    <>
                                        <table className="compareTbl">
                                            <thead>
                                                <tr>
                                                    <th></th>
                                                    <th>Before modification</th>
                                                    <th>After modification</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>Invoice Number</td>
                                                    <td>7865430</td>
                                                    <td>
                                                        <span className="bg-light-210">7865432</span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Invoice Date</td>
                                                    <td>01.05.2022</td>
                                                    <td>
                                                        <span className="bg-light-210">03.06.2022</span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Po Number</td>
                                                    <td>5678540</td>
                                                    <td>
                                                        <span className="bg-light-210">5678541</span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Total amount</td>
                                                    <td>780.00</td>
                                                    <td>
                                                        <span className="bg-light-210">786.90</span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Items</td>
                                                    <td>2 way type D receptacle housing, 0.55 in</td>
                                                    <td>
                                                        <span className="bg-light-210">2 way type D receptacle housing, 0.25 in</span>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </>
                                }
                            >
                                <div
                                    onClick={handleTooltipOpen}
                                    className="bg-light-220 cursor-pointer grid place-items-center w-[38px] h-[38px] rounded-full"
                                >
                                    <img src="/images/common/libra.png" alt="libra" />
                                </div>
                            </Tooltip>
                        </ClickAwayListener>
                    </>
                )}
            </Card>
        </>
    );
    const dropdownColor = currentMode === "dark" ? "#333333" : `white`;
    const dropdownText = currentMode === "dark" ? "#ECECEC" : `#555555`;
    return (
        <>
            <div
                className={`  text-${currentMode}-copy border-${currentMode}-700 px-[30px] py-[24px] border-b-[1px] xl:flex justify-between items-center border-[rgba(236,236,236,0.88)] mb-[1.8rem] relative`}
            >
                <h4 className="font-interSb text-lg mb-5 xl:mb-0">Recent Activity</h4>
                <div className={` xl:flex xl:space-x-2 space-y-4 xl:space-y-0`}>
                    <div className={``}>
                        <Select
                            fullWidth
                            name="status_id"
                            className={`font-interR  bg-${currentMode}-1340 text-${currentMode}-copy rounded-[8px]`}
                            value={currentSelect}
                            onChange={(e) => onPageItemSelect(e)}
                            id=""
                            MenuProps={{
                                sx: {
                                    "& .MuiMenu-paper": {
                                        backgroundColor: dropdownColor,
                                        color: dropdownText,
                                    },
                                    "& .MuiMenuItem-root:hover": {
                                        backgroundColor: "rgba(218, 249, 244, 0.3)",
                                    },
                                    "& .Mui-selected": {
                                        backgroundColor: "rgba(218, 249, 244, 0.3) !important",
                                    },
                                },
                            }}
                            SelectDisplayProps={{
                                style: {
                                    paddingTop: 2,
                                    display: "flex",
                                    paddingBottom: 2,
                                    paddingLeft: "0",
                                    minWidth: 100,
                                    color: currentMode === "dark" ? `#ECECEC` : `#555555`,
                                    paddingRight: "30px",
                                },
                            }}
                            displayEmpty
                            IconComponent={() => (
                                <img
                                    className="absolute right-[10px] opacity-[.5] pointer-events-none"
                                    src={currentMode !== "dark" ? `/images/header/chevronDown.svg` : `/images/header/chevronDown-white.svg`}
                                    alt="chevronDown"
                                />
                            )}
                        >
                            <MenuItem value={1}>Last week</MenuItem>
                            <MenuItem value={2}>Last month</MenuItem>
                            <MenuItem value={3}>Last 3 months</MenuItem>
                        </Select>
                    </div>
                    <div
                        className={`bg-${currentMode}-card text-${currentMode}-copy flex relative lg:flex border-[1px] border-${currentMode}-840 justify-between py-[8px] px-[16px] py-2 xl:max-w-[120px] rounded-[8px]`}
                    >
                        <Input
                            placeholder="Search"
                            className="border-0 bg-transparent w-full"
                            disableUnderline={true}
                            autoComplete="off"
                            value={searchInput}
                            onChange={(e) => searchItems(e.target.value)}
                        />
                        {searchInput && searchInput.length !== 0 ? (
                            <img className="h-[16px] w-[16px] my-auto" onClick={resetInputField} src="/images/common/closeBlk.svg" alt="close" />
                        ) : (
                            <img className="h-[16px] w-[16px] my-auto" src="/images/header/search.svg" />
                        )}
                    </div>
                </div>
            </div>
            {!ActivityList?.requestComplete ? (
                <Loader className="h-[calc(100vh_-_15rem)]" />
            ) : (
                <div className={`overflow-y-auto`}>
                    {searchInput.length > 1
                        ? filteredResults.map((elem, index) => {
                              return (
                                  <div className={`pl-[25px] pr-[30px]`}>
                                      <VerticalStepper
                                          dynamic="approve"
                                          // deactivate activate
                                          img={`${
                                              elem?.action === "insert"
                                                  ? `/images/common/inserted.svg`
                                                  : elem?.action === "rejected"
                                                  ? "/images/common/rejected.svg"
                                                  : elem?.action === "update"
                                                  ? "/images/common/updated.svg"
                                                  : elem?.action === "approved"
                                                  ? "/images/common/approved.svg"
                                                  : elem?.action === "logout"
                                                  ? "/images/common/logedout.svg"
                                                  : elem?.action === "flag"
                                                  ? "/images/common/flag.svg"
                                                  : elem?.action === "login"
                                                  ? "/images/main/login.svg"
                                                  : elem?.action === "view"
                                                  ? "/images/main/view-ico.svg"
                                                  : elem?.action === "unflag"
                                                  ? "/images/common/unflagged.svg"
                                                  : elem?.action === "deactivate"
                                                  ? "/images/common/deactivate.svg"
                                                  : elem?.action === "activate"
                                                  ? "/images/common/activate.svg"
                                                  : elem?.action === "validate"
                                                  ? "/images/common/validated.svg"
                                                  : elem?.action === "delete"
                                                  ? "/images/common/delete.svg"
                                                  : elem?.action === "ownership" || elem?.action === "invoice transfer"
                                                  ? "/images/common/change-ownership.svg"
                                                  : ""
                                          } `}
                                          card={
                                              <Details>
                                                  <div className="flex">
                                                      {/* <img src="/images/common/user-border.svg" alt="user-1" /> */}
                                                      <div className="ml-3">
                                                          <p className="trunc-1 w-[21rem]">
                                                              <em className="text-light-20 not-italic"> </em> {elem?.remarks}
                                                          </p>
                                                          <span className="text-light-30 text-xs">
                                                              {elem?.modified_date} at {elem?.modified_time}
                                                          </span>
                                                      </div>
                                                  </div>
                                              </Details>
                                          }
                                      />
                                  </div>
                              );
                          })
                        : actData.map((elem, index) => {
                              return (
                                  <div className="pl-[25px] pr-[30px]">
                                      <VerticalStepper
                                          dynamic="approve"
                                          img={`${
                                              elem?.action === "insert"
                                                  ? `/images/common/inserted.svg`
                                                  : elem?.action === "rejected"
                                                  ? "/images/common/rejected.svg"
                                                  : elem?.action === "update"
                                                  ? "/images/common/updated.svg"
                                                  : elem?.action === "approved"
                                                  ? "/images/common/approved.svg"
                                                  : elem?.action === "logout"
                                                  ? "/images/common/logedout.svg"
                                                  : elem?.action === "flag"
                                                  ? "/images/common/flag.svg"
                                                  : elem?.action === "login"
                                                  ? "/images/main/login.svg"
                                                  : elem?.action === "view"
                                                  ? "/images/main/view-ico.svg"
                                                  : elem?.action === "unflag"
                                                  ? "/images/common/unflag.svg"
                                                  : elem?.action === "deactivate"
                                                  ? "/images/common/deactivate.svg"
                                                  : elem?.action === "activate"
                                                  ? "/images/common/activate.svg"
                                                  : elem?.action === "validate"
                                                  ? "/images/common/validated.svg"
                                                  : elem?.action === "delete"
                                                  ? "/images/common/delete.svg"
                                                  : elem?.action === "ownership" || elem?.action === "invoice transfer"
                                                  ? "/images/common/change-ownership.svg"
                                                  : ""
                                          } `}
                                          card={
                                              <Details>
                                                  <div className="flex">
                                                      {/* <img src="/images/common/user-border.svg" alt="user-1" /> */}
                                                      <div className="ml-3">
                                                          <p className="trunc-1 w-[21rem]">
                                                              <em className="text-light-20 not-italic"> </em> {elem?.remarks}
                                                          </p>{" "}
                                                          <span className="text-light-30 text-xs">
                                                              {elem?.modified_date} at {elem?.modified_time}
                                                          </span>
                                                      </div>
                                                  </div>
                                              </Details>
                                          }
                                      />
                                  </div>
                              );
                          })}

                    {actData == "" && (
                        <>
                            <div className="h-full grid place-items-center place-content-center">
                                <img className="mb-3" src="/images/common/delivery.png" alt="searching" />
                                <h4 className="font-interSb">No Activity found</h4>
                            </div>
                        </>
                    )}
                </div>
            )}
            {searchInput?.length > 1 && filteredResults.length === 0 ? (
                <div className="h-full grid place-items-center place-content-center">
                    <img className="mb-3" src="/images/common/delivery.png" alt="searching" />
                    <h4 className="font-interSb">No Activity found</h4>
                </div>
            ) : null}
        </>
    );
};

export default UserActivityModal;
