import {
    GET_CUSTOM_REPORTS_LABEL_FAILURE,
    GET_CUSTOM_REPORTS_LABEL_SUCCESS,
    GET_CUSTOM_REPORTS_LABEL_REQUEST,
    GET_CUSTOM_REPORTS_LABEL_RESET,
    CUSTOM_REPORTS_LIST_REQUEST,
    CUSTOM_REPORTS_LIST_SUCCESS,
    CUSTOM_REPORTS_LIST_FAILURE,
    CUSTOM_REPORTS_LIST_RESET,
    GET_CUSTOM_REPORTS_CONFIGLIST_REQUEST,
    GET_CUSTOM_REPORTS_CONFIGLIST_SUCCESS,
    GET_CUSTOM_REPORTS_CONFIGLIST_RESET,
    GET_CUSTOM_REPORTS_CONFIGLIST_FAILURE,
    GET_CUSTOM_REPORTS_DELETE_SUCCESS,
    GET_CUSTOM_REPORTS_DELETE_FAILURE,
    GET_CUSTOM_REPORTS_DELETE_REQUEST,
    GET_CUSTOM_REPORTS_DELETE_RESET,
    GET_CUSTOM_REPORTS_PUBLISH_UNPUBLISH_FAILURE,
    GET_CUSTOM_REPORTS_PUBLISH_UNPUBLISH_REQUEST,
    GET_CUSTOM_REPORTS_PUBLISH_UNPUBLISH_RESET,
    GET_CUSTOM_REPORTS_PUBLISH_UNPUBLISH_SUCCESS,
    GET_COLUMN_REQUEST,
    GET_COLUMN_SUCCESS,
    GET_COLUMN_RESET,
    GET_COLUMN_FAILURE,
    POST_FILTER_DROPDOWN_REQUEST,
    POST_FILTER_DROPDOWN_SUCCESS,
    POST_FILTER_DROPDOWN_RESET,
    POST_FILTER_DROPDOWN_FAILURE,
    GET_PREVIEW_DATA_REQUEST,
    GET_PREVIEW_DATA_SUCCESS,
    GET_PREVIEW_DATA_RESET,
    GET_PREVIEW_DATA_FAILURE,
    POST_TABLER_DATA_REQUEST,
    POST_TABLER_DATA_SUCCESS,
    POST_TABLER_DATA_RESET,
    POST_TABLER_DATA_FAILURE,
    GET_PREVIEW_TABLER_REQUEST,
    GET_PREVIEW_TABLER_SUCCESS,
    GET_PREVIEW_TABLER_RESET,
    GET_PREVIEW_TABLER_FAILURE,
    GET_COLUMN_UPDATE,
    GET_COLUMN_LOAD_UPDATE,
    GET_REPORT_DROPDOWN_REQUEST,
    GET_REPORT_DROPDOWN_SUCCESS,
    GET_REPORT_DROPDOWN_RESET,
    GET_REPORT_DROPDOWN_FAILURE,
} from "../types/types.js";

const getlabelInfoState = {
    data: [],
    fetching: false,
    requestComplete: false,
    error: "",
};
export function getLabelInfoReducer (state = getlabelInfoState, action) {
    switch (action.type) {
        case GET_CUSTOM_REPORTS_LABEL_REQUEST:
            return {
                ...state,
                fetching: true,
                requestComplete: false,
                error: "",
            };
        case GET_CUSTOM_REPORTS_LABEL_SUCCESS:
            return {
                data: action.payload,
                fetching: false,
                requestComplete: true,
                error: "",
            };
        case GET_CUSTOM_REPORTS_LABEL_RESET:
            return {
                data: [],
                fetching: false,
                requestComplete: false,
                error: "",
            };
        case GET_CUSTOM_REPORTS_LABEL_FAILURE:
            return {
                fetching: false,
                requestComplete: false,
                error: action.payload,
            };
        default:
            return state;
    }
}
const customReportsListState = {
    data: [],
    fetching: false,
    requestComplete: false,
    error: "",
};
export function CustomReportsListReducer (state = customReportsListState, action) {
    switch (action.type) {
        case CUSTOM_REPORTS_LIST_REQUEST:
            return {
                ...state,
                fetching: true,
                requestComplete: false,
                error: "",
            };
        case CUSTOM_REPORTS_LIST_SUCCESS:
            return {
                data: action.payload,
                fetching: false,
                requestComplete: true,
                error: "",
            };
        case CUSTOM_REPORTS_LIST_RESET:
            return {
                data: [],
                fetching: false,
                requestComplete: false,
                error: "",
            };
        case CUSTOM_REPORTS_LIST_FAILURE:
            return {
                fetching: false,
                requestComplete: false,
                error: action.payload,
            };
        default:
            return state;
    }
}

const customReportsConfigListState = {
    data: [],
    fetching: false,
    requestComplete: false,
    error: "",
};

export function CustomReportsConfigListReducer (state = customReportsConfigListState, action) {
    switch (action.type) {
        case GET_CUSTOM_REPORTS_CONFIGLIST_REQUEST:
            return {
                ...state,
                fetching: true,
                requestComplete: false,
                error: "",
            };
        case GET_CUSTOM_REPORTS_CONFIGLIST_SUCCESS:
            return {
                data: action.payload,
                fetching: false,
                requestComplete: true,
                error: "",
            };
        case GET_CUSTOM_REPORTS_CONFIGLIST_FAILURE:
            return {
                data: [],
                fetching: false,
                requestComplete: false,
                error: "",
            };
        case GET_CUSTOM_REPORTS_CONFIGLIST_RESET:
            return {
                fetching: false,
                requestComplete: false,
                error: action.payload,
            };
        default:
            return state;
    }
}

const customReportsDeleteState = {
    data: [],
    fetching: false,
    requestComplete: false,
    error: "",
};

export function CustomreportsConfigDeleteReducer (state = customReportsDeleteState, action) {
    switch (action.type) {
        case GET_CUSTOM_REPORTS_DELETE_REQUEST:
            return {
                ...state,
                fetching: true,
                requestComplete: false,
                error: "",
            };
        case GET_CUSTOM_REPORTS_DELETE_SUCCESS:
            return {
                data: action.payload,
                fetching: false,
                requestComplete: true,
                error: "",
            };
        case GET_CUSTOM_REPORTS_DELETE_FAILURE:
            return {
                data: [],
                fetching: false,
                requestComplete: false,
                error: action.payload,
            };
        case GET_CUSTOM_REPORTS_DELETE_RESET:
            return {
                fetching: false,
                requestComplete: false,
                error: action.payload,
            };
        default:
            return state;
    }
}

const customReportsPublishUnpublishState = {
    data: [],
    fetching: false,
    requestComplete: false,
    error: "",
};

export function CustomReportsPublishUnpublishReducer (state = customReportsPublishUnpublishState, action) {
    switch (action.type) {
        case GET_CUSTOM_REPORTS_PUBLISH_UNPUBLISH_REQUEST:
            return {
                ...state,
                fetching: true,
                requestComplete: false,
                error: "",
            };
        case GET_CUSTOM_REPORTS_PUBLISH_UNPUBLISH_SUCCESS:
            return {
                data: action.payload,
                fetching: false,
                requestComplete: true,
                error: "",
            };
        case GET_CUSTOM_REPORTS_PUBLISH_UNPUBLISH_FAILURE:
            return {
                data: [],
                fetching: false,
                requestComplete: false,
                error: action.payload,
            };
        case GET_CUSTOM_REPORTS_PUBLISH_UNPUBLISH_RESET:
            return {
                fetching: false,
                requestComplete: false,
                error: action.payload,
            };
        default:
            return state;
    }
}

const getColumnCountState = {
    columns: [],
    opened: [],
    fetching: false,
    requestComplete: false,
    error: "",
};

// Below Reducer call is (middleware for store and action) received data from api to send the redux storefor the Columns list
export function getColumnReducer (state = getColumnCountState, action) {
    switch (action.type) {
        case GET_COLUMN_REQUEST:
            return {
                ...state,
                fetching: true,
                requestComplete: false,
                error: "",
            };
        case GET_COLUMN_SUCCESS:
            return {
                columns: action.payload,
                fetching: false,
                requestComplete: true,
                error: "",
            };
        case GET_COLUMN_UPDATE:
            let { parentId, childId, isChecked } = action.payload;
            let Update = state?.columns?.map((item) =>
                item?.id === parentId
                    ? {
                        ...item,
                        data: item?.data?.map((child) => (child?.id === childId ? { ...child, checked: isChecked } : child)),
                    }
                    : item
            );
            return {
                ...state,
                fetching: false,
                columns: Update,
                requestComplete: false,
                error: "",
            };
        case "FIRST_SET_OPEN":
            return {
                ...state,
                opened: action.payload,
            };

        case GET_COLUMN_LOAD_UPDATE:
            const { dropped, accord } = action?.payload;
            const updatedItems = accord?.map((parentObj) => {
                const updatedContent = parentObj?.data?.map((contentObj) => {
                    const matchingDroppedValue = dropped?.find((droppedObj) => droppedObj?.column_name === contentObj?.column_name);
                    if (matchingDroppedValue) {
                        // Update the checked property in the matching content object
                        return { ...contentObj, checked: true };
                    }
                    return contentObj;
                });

                return { ...parentObj, data: updatedContent };
            });
            return {
                ...state,
                columns: updatedItems,
            };
        case GET_COLUMN_RESET:
            return {
                columns: [],
                fetching: false,
                requestComplete: false,
                error: "",
            };
        case GET_COLUMN_FAILURE:
            return {
                fetching: false,
                requestComplete: false,
                error: action.payload,
            };
        default:
            return state;
    }
}

const getFilterDropdownState = {
    dropdown: [],
    fetching: false,
    requestComplete: false,
    error: "",
};

// Below Reducer call is (middleware for store and action) received data from api to send the redux store for the Columns list
export function getFilterDropdownReducer (state = getFilterDropdownState, action) {
    switch (action.type) {
        case POST_FILTER_DROPDOWN_REQUEST:
            return {
                ...state,
                fetching: true,
                requestComplete: false,
                error: "",
            };
        case POST_FILTER_DROPDOWN_SUCCESS:
            return {
                dropdown: action.payload,
                fetching: false,
                requestComplete: true,
                error: "",
            };
        case POST_FILTER_DROPDOWN_RESET:
            return {
                dropdown: [],
                fetching: false,
                requestComplete: false,
                error: "",
            };
        case POST_FILTER_DROPDOWN_FAILURE:
            return {
                fetching: false,
                requestComplete: false,
                error: action.payload,
            };
        default:
            return state;
    }
}

const getpreviewDataState = {
    preview: [],
    fetching: false,
    requestComplete: false,
    error: "",
};

// Below Reducer call is (middleware for store and action) received data from api to send the redux store for the preview data
export function getPreviewDataReducer (state = getpreviewDataState, action) {
    switch (action.type) {
        case GET_PREVIEW_DATA_REQUEST:
            return {
                ...state,
                fetching: true,
                requestComplete: false,
                error: "",
            };
        case GET_PREVIEW_DATA_SUCCESS:
            return {
                preview: action.payload,
                fetching: false,
                requestComplete: true,
                error: "",
            };
        case GET_PREVIEW_DATA_RESET:
            return {
                preview: [],
                fetching: false,
                requestComplete: false,
                error: "",
            };
        case GET_PREVIEW_DATA_FAILURE:
            return {
                fetching: false,
                requestComplete: false,
                error: action.payload,
            };
        default:
            return state;
    }
}

const getAddDataState = {
    data: {},
    fetching: false,
    requestComplete: false,
    error: "",
};

// Below Reducer call is (middleware for store and action) received data from api to send the redux store for the Add Reports (after clcik Save button!)
export function getAddResponseReducer (state = getAddDataState, action) {
    switch (action.type) {
        case POST_TABLER_DATA_REQUEST:
            return {
                ...state,
                fetching: true,
                requestComplete: false,
                error: "",
            };
        case POST_TABLER_DATA_SUCCESS:
            return {
                data: action.payload,
                fetching: false,
                requestComplete: true,
                error: "",
            };
        case POST_TABLER_DATA_RESET:
            return {
                data: {},
                fetching: false,
                requestComplete: false,
                error: "",
            };
        case POST_TABLER_DATA_FAILURE:
            return {
                fetching: false,
                requestComplete: false,
                error: action.payload,
            };
        default:
            return state;
    }
}

const getTablerViewState = {
    datas: [],
    fetching: false,
    requestComplete: false,
    error: "",
};

// Below Reducer call is (middleware for store and action) received data from api to send the redux storefor the Columns list
export function getTablerViewReducer (state = getTablerViewState, action) {
    switch (action.type) {
        case GET_PREVIEW_TABLER_REQUEST:
            return {
                ...state,
                fetching: true,
                requestComplete: false,
                error: "",
            };
        case GET_PREVIEW_TABLER_SUCCESS:
            const filteredColumns = action?.payload?.[0].field_info?.filter((item) => item.Type === "columns");

            return {
                datas: filteredColumns,
                fetching: false,
                requestComplete: true,
                error: "",
            };
        case GET_PREVIEW_TABLER_RESET:
            return {
                datas: [],
                fetching: false,
                requestComplete: false,
                error: "",
            };
        case GET_PREVIEW_TABLER_FAILURE:
            return {
                fetching: false,
                requestComplete: false,
                error: action.payload,
            };
        default:
            return state;
    }
}

const getREportDropdownState = {
    dropdown: [],
    fetching: false,
    requestComplete: false,
    error: "",
};

// Below Reducer call is (middleware for store and action) received data from api to send the redux storefor the Columns list
export function getReportDropdownReducer (state = getREportDropdownState, action) {
    switch (action.type) {
        case GET_REPORT_DROPDOWN_REQUEST:
            return {
                ...state,
                fetching: true,
                requestComplete: false,
                error: "",
            };
        case GET_REPORT_DROPDOWN_SUCCESS:
            const resultObject = action?.payload?.reduce((result, item) => {
                if (item.code === 1) {
                    result.report_from = result.report_from || [];
                    result.report_from.push(item);
                } else if (item.code === 2) {
                    result.download_format = result.download_format || [];
                    result.download_format.push(item);
                } else if (item.code === 3) {
                    result.event_based = result.event_based || [];
                    result.event_based.push(item);
                }
                return result;
            }, {});
            return {
                dropdown: resultObject,
                fetching: false,
                requestComplete: true,
                error: "",
            };
        case GET_REPORT_DROPDOWN_RESET:
            return {
                dropdown: [],
                fetching: false,
                requestComplete: false,
                error: "",
            };
        case GET_REPORT_DROPDOWN_FAILURE:
            return {
                fetching: false,
                requestComplete: false,
                error: action.payload,
            };
        default:
            return state;
    }
}
