import Input from "../../components/Input";
import { useRef, useState, useEffect } from "react";
import Button from "../../components/Button/index.jsx";
import { Tooltip } from "@mui/material";
const StatusInputField = (props) => {
    const { item, dynamicClass, ind, EditBox, updateUser, closeUpdate, isStatusEdit } = props;
    const [inputValue, setInputValue] = useState(item?.status_name);
    const inputRef = useRef(null);
    //stay in focus while on handlechange
    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    }, [inputValue]);
    return (
        <>
            <Input
                ref={inputRef}
                maxLength={31}
                className={dynamicClass}
                value={inputValue}
                onChange={(e) => {
                    setInputValue(e.target.value);
                }}
                name={item?.field_name}
            />

            {isStatusEdit && item?.isActive && (
                <EditBox
                    className="Edit ml-9"
                    handleClick={() => updateUser(inputValue, item?.id, ind)}
                    id={item?.id}
                    closeClick={() => closeUpdate(item, item?.id, ind)}
                />
            )}
        </>
    );
};
export default StatusInputField;
