import { Link, useLocation } from "react-router-dom";
import Button from "../../components/Button";
import Container from "../../components/UI/Container";
import { useNavigate } from "react-router-dom";

import { resendEmailLink } from "../../redux/actions/auth";
import { useDispatch, useSelector } from "react-redux";
import SuccessMessageBox from "../../components/UI/Alert/sucess";
import MessageBox from "../../components/UI/Alert";

const successMsg = "set password link sent to email successfully";
const errorMsg = "Something went wrong please contact admin";
const ThankyouSignup = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const backToLogin = () => {
        navigate("/");
    };
    const params = new URLSearchParams(window.location.search);
    // let emailIDPlus;
    let emailID = params.get("email");
    if (emailID) {
        emailID = emailID.replace(" ", "+");
    }
    const handleResendLink = () => {
        const reqObj = {
            email: emailID,
            type: 1,
        };
        dispatch(resendEmailLink(reqObj));
    };
    const resendDetail = useSelector((state) => state?.ResendEmailLinkState);
    const { requestComplete, error, data, fetching } = resendDetail;

    return (
        <>
            {requestComplete && data?.status && (
                <SuccessMessageBox success={successMsg} />
            )}
            {requestComplete && !data?.status && <MessageBox error={data?.message} />}
            {error && !data?.status && <MessageBox error={errorMsg} />}
            <div className="WrapCenter">
                <Container className="mt-10">
                    <Link to={"//www.myiq.ai/"} target="_blank">
                        <img
                            className="w-[121px] h-[95px]"
                            src="/images/logo/MyiqFinal.svg"
                            alt="logoiq"
                        />
                    </Link>
                    <div className="flex justify-center items-center h-[calc(80vh_-_120px)] mx-auto">
                        <div className="basis-1/2 2xl:basis-full text-center flex justify-center items-center flex-col">
                            <div className="bg-message rounded-[50%] w-[100px] h-[100px] grid place-items-center mb-8">
                                <img
                                    className=""
                                    src="images/common/email-green.svg"
                                    alt="logoiq"
                                />
                            </div>
                            <h3 className="text-[36px] font-interSb leading-[43px] text-light-20 mb-4">
                                Thanks for signing up!
                            </h3>
                            <p className="mb-[6rem] text-[14px] text-inter">
                                We have sent a link to your registered email to set your
                                password
                            </p>
                            <div className="w-full h-full">
                                <hr className="border-[rgba(223,225,226,0.51)]" />
                            </div>
                            <p className="mt-4 text-center">
                                <span className="text-[red]">*</span> If you {`haven’t`}{" "}
                                received click here to{" "}
                                <Button
                                    onClick={handleResendLink}
                                    className="text-light-10 inline-block"
                                >
                                    resend confirmation mail
                                </Button>
                            </p>
                        </div>
                    </div>
                </Container>
            </div>
        </>
    );
};

export default ThankyouSignup;
