import Button from "../../../components/Button";
import Modal from "../../../components/UI/Modal/Modal";
import Stack from "@mui/joy/Stack";
import LinearProgress from "@mui/joy/LinearProgress";
import { useContext } from "react";
import { ThemeContext } from "../../../Hooks/Themes/Themeprovider";
import { useSelector } from "react-redux";
import Loader from "../../../components/Loader";
const SendToApprovePopup = (props) => {
    const { currentMode } = useContext(ThemeContext);
    const selectedInvoiceList = props?.InvId;
    const sendToapprove = useSelector((state) => state?.SendToApproverState);
    const PageCount = props?.pageCountValue;
    const SelectedCount = props?.SelectedLength;
    const selectedInvoiceId = props?.selectedInvoiceId;
    const selectedInvoiceIdItem = selectedInvoiceId?.map((el) => el?.id);

    const StatusTrueCount = props?.StatusTrueCount?.length;
    const StatusFalseCount = props?.StatusFalseCount?.length;
    const FilterFailedInv = props?.FilterFailedInv;
    const FailedInvoices = FilterFailedInv?.map((el) => el?.InvId);
    const SeparatedFailedInv = FailedInvoices?.join(",");

    return (
        <>
            <Modal cartClose={props?.handleClose} center="center" tabIndex={0} className={`${props?.className}`}>
                {selectedInvoiceId?.length == props?.index && !sendToapprove?.fetching && (
                    <div
                        className={` max-w-[500px] mx-auto relative bg-${currentMode}-card text-${currentMode}-copy border-${currentMode}-700 px-[31px] py-[20px] rounded-[10px] ${
                            currentMode === "dark" ? "" : "shadow-[0px_4px_20px_rgba(238,238,238,0.5)]"
                        }`}
                    >
                        <div>
                            <Button
                                onClick={props?.handleClose}
                                tabIndex={0}
                                className="cursor-pointer absolute right-[20px] top-[20px] convertWhite"
                            >
                                <img src="/images/common/closeBlk.svg" alt="closeBlk" />
                            </Button>

                            <p className="mb-4 font-interSb text-left">Total invoices send to approver : {SelectedCount}</p>

                            <span className="flex items-center mb-[15px]">
                                <img className="mr-2" src="/images/invoice/thumbsup.svg" alt="thumbsup" /> Successful invoices : {StatusTrueCount}
                            </span>
                            <span className="flex items-center mb-[10px]">
                                <img className="mr-2" src="/images/invoice/inv-exceptions.svg" alt="thumbsdown" /> Failed invoices :{" "}
                                {StatusFalseCount}
                            </span>
                            {StatusFalseCount !== 0 && (
                                <p className="mb-[35px] trunc-1 w-[500px] cursor-default" title={SeparatedFailedInv}>
                                    (Invoice id - {SeparatedFailedInv})
                                </p>
                            )}
                            <div className="flex justify-end w-full mb-[31px]">
                                <Button className="bg-light-20 px-[30px] py-[9px] text-white rounded-[8px] ml-auto" onClick={props?.handleClose}>
                                    Ok
                                </Button>
                            </div>
                        </div>
                    </div>
                )}

                {sendToapprove?.fetching && (
                    <div
                        className={` max-w-[500px] mx-auto relative bg-${currentMode}-card text-${currentMode}-copy border-${currentMode}-700 px-[31px] py-[20px] rounded-[10px] ${
                            currentMode === "dark" ? "" : "shadow-[0px_4px_20px_rgba(238,238,238,0.5)]"
                        }`}
                    >
                        <div>
                            <Button
                                onClick={props?.handleClose}
                                tabIndex={0}
                                className="cursor-pointer absolute right-[20px] top-[20px] convertWhite"
                            >
                                <img src="/images/common/closeBlk.svg" alt="closeBlk" />
                            </Button>
                            <div className="items-center grid place-items-center space-y-3  trunc-2 mb-[20px]">
                                <>
                                    <p className="ml-4">
                                        Send to approver: <span className="text-[#00B399] font-interSb"> Invoice ID {props?.showInvName}</span>
                                    </p>
                                </>
                            </div>
                            <div className="mb-[20px]">
                                <Stack spacing={2} sx={{ margin: "20px auto 0", maxWidth: 200 }}>
                                    <LinearProgress
                                        sx={{
                                            backgroundColor: "rgba(2,140,120,0.22)",
                                            "&:before": { background: "#028C78" },
                                        }}
                                        determinate
                                        value={props?.progress}
                                    />
                                </Stack>
                            </div>
                            <p className="mb-[20px]">
                                {SelectedCount} of {PageCount} invoices transferring to approver
                            </p>
                        </div>
                    </div>
                )}
            </Modal>
        </>
    );
};

export default SendToApprovePopup;
