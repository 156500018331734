import React from "react";
import InvTable from "../../../components/UI/Table";
import Button from "../../../components/Button";
import Card from "../../../components/UI/Card";
import { Link } from "react-router-dom";
import { Input } from "@mui/material";

const InvoiceTable = () => {
    return (
        <InvTable>
            <div className="position-absolute w-[102px] h-[ 17px]  left-[30px] text-white top-[26px] font-sans not-italic font-medium text-sm leading-7 pt-[26px] pl-[30px]">
                Line Item Table
            </div>
            <Button className="border-20 bg-light-20 px-5 py-2 rounded-[8px] font-interM text-sm flex items-center border-[1px] text-white">
                <img className="mr-1" src="/images/common/plus.svg" alt="plus" />{" "}
                Confirm
            </Button>
            <button className="bg-light-40 px-[30px] py-[4px] text-white rounded-[5px] flex items-center">
                <img
                    className="mr-2"
                    src="/images/common/whiteClose.svg"
                    alt="whiteClose"
                />
                Cancel
            </button>
            <div>
                <div className="position-absolute w-[102px] h-[ 17px]  left-[30px] text-white top-[26px] font-sans not-italic font-medium text-sm leading-7 pt-[26px] pl-[30px]">
                    <img className="mr-1" src="/images/common/plus.svg" alt="plus" />
                    Add row
                </div>
            </div>
            <>
                <Card>
                    <div className="heightFix bg-black border-[1px]">
                        <table className="table-auto TblNormal w-full invoiceTable">
                            <thead className="sticky top-0">
                                <tr className="bg-black">
                                    <th></th>
                                    <th>
                                        <Link to="#!" className="flex tableHead text-white">
                                            Other{" "}
                                            <img
                                                src="/images/common/chevronDown.svg"
                                                alt="chevronDown"
                                            />
                                        </Link>
                                    </th>
                                    <th>
                                        <Link to="#!" className="flex tableHead text-white">
                                            Description{" "}
                                            <img
                                                src="/images/common/chevronDown.svg"
                                                alt="chevronDown"
                                            />
                                        </Link>
                                    </th>
                                    <th>
                                        <Link to="#!" className="flex tableHead text-white">
                                            Tax Rate{" "}
                                            <img
                                                src="/images/common/chevronDown.svg"
                                                alt="chevronDown"
                                            />
                                        </Link>
                                    </th>
                                    <th>
                                        <Link to="#!" className="flex tableHead text-white">
                                            Quantity{" "}
                                            <img
                                                src="/images/common/chevronDown.svg"
                                                alt="chevronDown"
                                            />
                                        </Link>
                                    </th>
                                    <th>
                                        <Link to="#!" className="flex tableHead text-white">
                                            Unit{" "}
                                            <img
                                                src="/images/common/chevronDown.svg"
                                                alt="chevronDown"
                                            />
                                        </Link>
                                    </th>
                                    <th>
                                        <Link to="#!" className="flex tableHead text-white">
                                            Unit Price
                                        </Link>
                                    </th>
                                    <th>
                                        <Link to="#!" className="flex tableHead text-white">
                                            Total
                                        </Link>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        {/* <div className="bg-[rgba(0,179,153,0.5)] mx-auto w-[24px] h-[24px] rounded-full grid place-items-center">
                    <img src="/images/common/tickWhite.svg" alt="tickWhite" />
                  </div> */}
                                    </td>
                                    <td>dbhc</td>
                                    <td>dsbvcd</td>
                                    <td>djcbjd</td>
                                    <td>dchdkj</td>
                                    <td>jkdbcvjd</td>
                                    <td>sdbfds</td>
                                    <td>fv</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </Card>
            </>
        </InvTable>
    );
};

export default InvoiceTable;
