import axios from "axios";
import authHeader from "./auth-header";
import { checkApiStatus } from "../../utils/helpers";
import {
    GET_MS_CONTACT_LIST_REQUEST,
    GET_MS_CONTACT_LIST_SUCCESS,
    GET_MS_CONTACT_LIST_FAILURE,
} from "../types/types.js";
const API_URL = process.env?.REACT_APP_NODE_API_URL;

export const GetMicrosoftContactList = () => async (dispatch) => {
    try {
        dispatch({ type: GET_MS_CONTACT_LIST_REQUEST });

        axios
            .get(`${API_URL}api/getMsContacts`, {
                headers: authHeader(),
            })
            .then((response) => {
                dispatch({
                    type: GET_MS_CONTACT_LIST_SUCCESS,
                    payload: response.data,
                });
            })
            .catch(function (error) {
                if (error?.response?.status == 401) {
                    checkApiStatus();
                }
                dispatch({
                    payload: { error: error.message },
                    type: GET_MS_CONTACT_LIST_FAILURE,
                });
            });
    } catch (error) {
        dispatch({
            type: GET_MS_CONTACT_LIST_FAILURE,
        });
    }
};
