import { FormControl, MenuItem, Select } from "@mui/material";
import { SelectDropdwnImg, formatNumber } from "../../../../utils/helpers";
import Button from "../../../../components/Button";
import { useEffect, useMemo, useState } from "react";
import { getInvoiceCalcDetail, getTaxCodeList } from "../../../../redux/actions/invoice";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import Loader from "../../../../components/Loader";
import { TdsApiAction } from "../../../../redux/actions/miscellaneous-request";
import moment from "moment";

export const TaxDetuction = ({ currentMode, handleClose, invid, type, listItems }) => {
    const dispatch = useDispatch();
    // "TDS Amount", "TDS Base Amount", removed told by pavi!
    const DummyData = {
        taxHeader: ["Tax Code", "Condition Type", "Percentage (%)", "LTDC Percent (%)", "Threshold Limit", ""],
    };

    let invDate;
    const getinvDateId = listItems?.invoice_items?.filter((el, i) => el?.label_name == "invoice_date");
    if (getinvDateId?.length > 0) {
        invDate = document.getElementById(`Inv-li-${getinvDateId?.[0]?.id}`);
    }
    const { invCalcview, fetching } = useSelector((state) => state?.getInvCalcDetailState);
    const getSelected = invCalcview?.filter((el) => el?.indicator == true);
    const [select, setSelect] = useState();
    const ref_date = moment(invDate?.textContent, "DD/MM/YYYY")?.toISOString();
    useEffect(() => {
        if (type) {
            dispatch(getInvoiceCalcDetail(invid, type, 0, 0, 0, ref_date));
        }
    }, [type]);
    useMemo(() => {
        if (getSelected?.length > 0) {
            setSelect(getSelected?.[0]?.tax_id);
        }
    }, [invCalcview]);
    const onSave = () => {
        const filtered = invCalcview?.filter((el) => el?.tax_id == select);
        let per;
        // if (filtered?.length !== 0) {
        const updatedListData = filtered?.map((el) => {
            if (formatNumber(el?.threshold_limit) > 0) {
                const calcPer = 100 - formatNumber(el?.exemption_percentage);
                const goog = (calcPer * formatNumber(el?.tax_percentage)) / 100;
                per = goog;
                return { ...el, actual_tax_percentage: goog };
            }
            return el;
        });
        dispatch({ type: "STORE_TAX_CODE", payload: per });
        dispatch({ type: "STORE_TDS_TAX", payload: updatedListData });
        handleClose();
    };
    const _handleClick = (val) => {
        setSelect(val);
    };
    return (
        <div className={`${currentMode == "dark" ? "" : "shadow-[0px_4px_20px_0px_rgba(238,238,238,0.50)]"}`}>
            <div className={`flex justify-between items-center px-[30px] border-b border-${currentMode}-1380 py-[14px]`}>
                <span className="text-[18px] font-interM">Tax Deductions</span>
                <span onClick={handleClose} className="cursor-pointer convertWhite">
                    <img src="/images/common/closeBlk.svg" alt="closeBlk" />
                </span>
            </div>
            {fetching ? (
                <Loader />
            ) : (
                <>
                    <table className={`w-full border-b border-${currentMode}-830 rolemasterTable !rounded-[0] text-left mb-[30px]`}>
                        <thead>
                            <tr>
                                {DummyData?.taxHeader?.map((el) => (
                                    <th className="rounded-[0]">{el}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody className={`relative`}>
                            {invCalcview?.map((el, i) => (
                                <tr
                                    onClick={() => _handleClick(el?.tax_id)}
                                    className={`cursor-pointer ${select == el?.tax_id ? "bg-[rgba(211,250,244,0.40)]" : ""}`}
                                    key={i}>
                                    <td>{el?.tax_code}</td>
                                    <td>{el?.tax_type}</td>
                                    <td>{el?.tax_percentage}</td>
                                    <td>{el?.exemption_percentage}</td>
                                    <td>{el?.threshold_limit}</td>
                                    <td>{select == el?.tax_id ? <img src="/images/common/tick.svg" alt="tick" /> : ""}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <div className="space-x-2 flex justify-end pb-[30px] pr-[30px]">
                        <Button title={"Save"} onClick={onSave} className="bg-light-20 text-base text-white rounded-[8px] py-2 px-7">
                            {"Save"}
                        </Button>
                        <Button title="Cancel" onClick={handleClose} className={`bg-light-240 text-light-40 text-base rounded-[8px] py-2 px-7`}>
                            Cancel
                        </Button>
                    </div>
                </>
            )}
        </div>
    );
};
