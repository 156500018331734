import { useSelector, useDispatch } from "react-redux";
import React, { useContext } from "react";
import { ThemeContext } from "../../../Hooks/Themes/Themeprovider";
import Button from "../../../components/Button";
import Modal from "../../../components/UI/Modal/Modal";
import { InvoiceLabelLayoutAdd } from "../../../redux/actions/invoice";

const AlertLayout = (props) => {
    const { currentMode, toggleMode } = useContext(ThemeContext);
    const dispatch = useDispatch();
    const { fetching, requestComplete, error, data } = useSelector(
        (state) => state?.InvoiceLabelLayoutAddState
    );
    function submit() {
        dispatch(InvoiceLabelLayoutAdd(props?.reqobj));
        setTimeout(() => {
            props?.handleClose()
        }, 1000);
    }

    return (
        <>
            {/* {requestComplete && !data?.status && <MessageBox error={errormsg} />} */}
            {/* {error && !data?.status && <MessageBox error={errormsg} />} */}
            <Modal
                cartClose={props?.handleClose}
                center="center"
                className={`${props?.className}`}
            >
                <div
                    className={`max-w-[500px] mx-auto relative  border-[rgba(223,225,226,0.56)]  px-[30px] py-[48px] rounded-[10px] ${currentMode === "dark"
                        ? ""
                        : "shadow-[0px_4px_20px_rgba(238,238,238,0.5)]"
                        }  bg-${currentMode}-card text-${currentMode}-copy border-${currentMode}-700`}
                >
                    <div className="flex  space-x-4 ">

                        <span className="font-interSb text-base block  mb-4 mt-4">
                            Modifying the invoice layout may alter the following
                            invoice fields:
                        </span>
                    </div>

                    <Button
                        onClick={props?.handleClose}
                        tabIndex="3"
                        className={`cursor-pointer absolute right-[20px] top-[20px] convertWhite `}
                    >
                        <img
                            src="/images/common/closeBlk.svg"
                            alt="closeBlk"
                            className="md:fill-cyan-700"
                        />
                    </Button>



                    <p className="mb-4">
                        - Display
                        <br></br>
                        - Edit
                        <br></br>
                        - Mandatory
                        <br></br>
                        - Maximum Characters
                    </p>
                    <p className="mb-4 font-inter text-base font-medium"> Are you sure you want  to continue?</p>
                    <div className=" mt-[42px] space-x-2">
                        <Button
                            className="bg-light-20 px-[25px] py-2 text-base text-white rounded-[8px]"
                            type="submit"
                            tabIndex="1"
                            onClick={submit}
                            disabled={fetching}
                        >
                            Confirm
                        </Button>
                        <Button
                            className="bg-light-240 text-light-40 px-[28px] text-base py-2 rounded-[8px]"
                            onClick={props?.handleClose}
                            tabIndex="2"
                        >
                            Cancel
                        </Button>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default AlertLayout;
