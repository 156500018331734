import {
    GLOBAL_SEARCH_FAILURE,
    GLOBAL_SEARCH_REQUEST,
    GLOBAL_SEARCH_RESET,
    GLOBAL_SEARCH_SUCCESS
} from "../types/types";


const ListGlobalSearch = {
    Data: [],
    fetching: false,
    requestComplete: false,
    error: "",
};

export function GlobalSearchRedu(state = ListGlobalSearch, action) {
    switch (action.type) {
        case GLOBAL_SEARCH_REQUEST:
            return {
                ...state,
                fetching: true,
                requestComplete: false,
                error: "",
            };
        case GLOBAL_SEARCH_SUCCESS:
            return {
                Data: action.payload,
                fetching: false,
                requestComplete: true,
                error: "",
            };
        case GLOBAL_SEARCH_RESET:
            return {
                Data: [],
                fetching: false,
                requestComplete: false,
                error: "",
            };
        case GLOBAL_SEARCH_FAILURE:
            return {
                fetching: false,
                requestComplete: false,
                error: action.payload,
            };
        default:
            return state;
    }
}