import axios from "axios";
import configMyiQ from "../../configuration";
import authHeader from "./auth-header";
import Cookies from "js-cookie";
import Swal from "sweetalert2";
import { checkApiStatus } from "../../utils/helpers";
import {
    CHANGE_PASSWORD_FAILURE,
    CHANGE_PASSWORD_REQUEST,
    CHANGE_PASSWORD_SUCCESS,
    LOGIN_FAILURE,
    LOGIN_REQUEST,
    LOGIN_SUCCESS,
    RESET_PASSWORD_FAILURE,
    RESET_PASSWORD_REQUEST,
    RESET_PASSWORD_SUCCESS,
    SET_PASSWORD_FAILURE,
    SET_PASSWORD_REQUEST,
    SET_PASSWORD_SUCCESS,
    SIGNUP_FAILURE,
    SIGNUP_REQUEST,
    SIGNUP_SUCCESS,
    LOGOUT_REQUEST,
    LOGOUT_SUCCESS,
    LOGOUT_FAILURE,
    FORGOT_PASS_FAILURE,
    FORGOT_PASS_REQUEST,
    FORGOT_PASS_SUCCESS,
    EXPIRY_LINK_FAILURE,
    EXPIRY_LINK_REQUEST,
    EXPIRY_LINK_SUCCESS,
    RESEND_EMAIL_LINK_REQUEST,
    RESEND_EMAIL_LINK_SUCCESS,
    RESEND_EMAIL_LINK_FAILURE,
    RESEND_EMAIL_LINK_RESET,
    ACTIVITY_HISTORY_REQUEST,
    ACTIVITY_HISTORY_SUCCESS,
    ACTIVITY_HISTORY_FAILURE,
    TRANSFER_OWNER_FAILURE,
    TRANSFER_OWNER_REQUEST,
    TRANSFER_OWNER_SUCCESS,
    TOKEN_AUTHORIZATION_REQUEST,
    TOKEN_AUTHORIZATION_SUCCESS,
    TOKEN_AUTHORIZATION_FAILURE,
    GET_TEMP_MAIL_VALIDATE_REQUEST,
    GET_TEMP_MAIL_VALIDATE_SUCCESS,
    GET_TEMP_MAIL_VALIDATE_FAILURE,
    GET_TEMP_MAIL_VALIDATE_RESET,
    LOGIN_RESET,
    ACCOUNT_DELETE_FAILURE,
    ACCOUNT_DELETE_REQUEST,
    ACCOUNT_DELETE_SUCCESS,
    ACCOUNT_DELETE_RESET,
} from "../types/types";
import { encryptId } from "../../utils/helpers";
const API_URL = process.env?.REACT_APP_API_URL;
const NODE_API_URL = process.env?.REACT_APP_NODE_API_URL;

export const signupRequest = (data) => async (dispatch) => {
    try {
        dispatch({ type: SIGNUP_REQUEST });

        axios
            .post(`${API_URL}SignUp/AddUser`, data)
            .then((response) => {
                dispatch({
                    type: SIGNUP_SUCCESS,
                    payload: response.data,
                });
            })
            .catch(function (error) {
                dispatch({
                    payload: { error: error.message },
                    type: SIGNUP_FAILURE,
                });
            });
    } catch (error) {
        dispatch({
            type: SIGNUP_FAILURE,
        });
    }
};

export const loginRequest = (data) => async (dispatch) => {
    try {
        dispatch({ type: LOGIN_REQUEST });
        const res = await axios.post(`${API_URL}Authenticate/authenticate`, data);
        if (res?.data?.token) {
            Cookies.set("jwt", res?.data?.token);
            let userformatVal;
            if (res?.data?.number_format == "1.23.456,78") {
                userformatVal = "2";
            } else if (res?.data?.number_format == "1 23 456.78") {
                userformatVal = "3";
            } else {
                userformatVal = "1";
            }
            let value = { ...res?.data };
            delete value.token;
            localStorage.setItem("user", JSON.stringify(value));
            localStorage.setItem("userDateFormat", value?.user_date);
            localStorage.setItem("mailSyncEmail", Boolean(res?.data?.sync_isactive));
            localStorage.setItem("orgId", res?.data?.default_orgid);
            sessionStorage.setItem("orgId", res?.data?.default_orgid);
            localStorage.setItem("menuId", res?.data?.allowed_menu);
            localStorage.setItem("userNumberFormat", userformatVal);
        }
        dispatch({ type: LOGIN_SUCCESS, payload: res?.data });
    } catch (error) {
        dispatch({ type: LOGIN_FAILURE, payload: error });
    }
};

export const changepasswordRequest = (data) => async (dispatch) => {
    try {
        dispatch({ type: CHANGE_PASSWORD_REQUEST });
        axios
            .post(`${API_URL}Password/ChangePassword`, data, {
                headers: authHeader(),
            })
            .then((response) => {
                dispatch({
                    type: CHANGE_PASSWORD_SUCCESS,
                    payload: response.data,
                });
            })
            .catch(function (error) {
                if (error?.response?.status == 401) {
                    checkApiStatus();
                }
                dispatch({
                    payload: { error: error.message },
                    type: CHANGE_PASSWORD_FAILURE,
                });
            });
    } catch (error) {
        dispatch({
            type: CHANGE_PASSWORD_FAILURE,
        });
    }
};

export const setPasswordRequest = (data) => async (dispatch) => {
    try {
        dispatch({ type: SET_PASSWORD_REQUEST });
        const headers = {
            id: data.id,
        };
        axios
            .post(`${API_URL}SignUp/SetPassword`, data, {
                headers: headers,
            })
            .then((response) => {
                dispatch({
                    type: SET_PASSWORD_SUCCESS,
                    payload: response.data,
                });
            })
            .catch(function (error) {
                if (error?.response?.status == 401) {
                    checkApiStatus();
                }
                dispatch({
                    payload: { error: error.message },
                    type: SET_PASSWORD_FAILURE,
                });
            });
    } catch (error) {
        dispatch({
            type: SET_PASSWORD_FAILURE,
        });
    }
};
export const resetPasswordRequest = (data) => async (dispatch) => {
    dispatch({ type: RESET_PASSWORD_REQUEST });

    axios
        .post(`${API_URL}Password/ResetPassword`, data, {
            headers: authHeader(),
        })
        .then((response) => {
            dispatch({
                type: RESET_PASSWORD_SUCCESS,
                payload: response.data?.data,
            });
        })
        .catch((error) => {
            if (error?.response?.status == 401) {
                checkApiStatus();
            }
            dispatch({
                type: RESET_PASSWORD_FAILURE,
            });
        });
};

export const LogoutRequest = () => async (dispatch) => {
    dispatch({ type: LOGOUT_REQUEST });
    axios
        .get(`${API_URL}User/UserSignOut`, {
            headers: authHeader(),
        })
        .then((response) => {
            localStorage.removeItem("user");
            dispatch({
                type: LOGOUT_SUCCESS,
                payload: response.data?.data,
            });
            // dispatch({ type: LOGIN_RESET });
        })
        .catch((error) => {
            if (error?.response?.status == 401) {
                checkApiStatus();
            }
            dispatch({
                type: LOGOUT_FAILURE,
            });
        });
};

export const forgotpassword = (data) => async (dispatch) => {
    try {
        dispatch({ type: FORGOT_PASS_REQUEST });

        axios
            .post(`${API_URL}SignUp/ForgetPassword`, data)
            .then((response) => {
                dispatch({
                    type: FORGOT_PASS_SUCCESS,
                    payload: response.data,
                });
            })
            .catch(function (error) {
                if (error?.response?.status == 401) {
                    checkApiStatus();
                }
                dispatch({
                    payload: { error: error.message },
                    type: FORGOT_PASS_FAILURE,
                });
            });
    } catch (error) {
        dispatch({
            type: FORGOT_PASS_FAILURE,
        });
    }
};

export const getCurrentUser = () => {
    return JSON.parse(localStorage.getItem("user"));
};

export const logout = () => {
    let myAdvanceItem = localStorage.getItem("advanceListOption");
    let myItem = localStorage.getItem("customOptions");
    let intervalItem = localStorage.getItem("PlayInterval");
    let newItem = localStorage.getItem("ageingOption");
    let folderAccess = localStorage.getItem("FolderAccess");
    let secret = localStorage.getItem("secretId", secret);
    localStorage.clear();
    Cookies.remove("jwt");
    localStorage.setItem("advanceListOption", myAdvanceItem);
    localStorage.setItem("customOptions", myItem);
    localStorage.setItem("PlayInterval", intervalItem);
    localStorage.setItem("ageingOption", newItem);
    localStorage.setItem("FolderAccess", folderAccess);
    localStorage.setItem("secretId", secret);
};

export const expirylink = (data) => async (dispatch) => {
    dispatch({ type: EXPIRY_LINK_REQUEST });

    axios
        .post(`${API_URL}SignUp/ExpiryValidation`, data)
        .then((response) => {
            dispatch({
                type: EXPIRY_LINK_SUCCESS,
                payload: response.data,
            });
        })
        .catch((error) => {
            if (error?.response?.status == 401) {
                checkApiStatus();
            }
            dispatch({
                type: EXPIRY_LINK_FAILURE,
            });
        });
};

export const resendEmailLink = (data) => async (dispatch) => {
    try {
        dispatch({ type: RESEND_EMAIL_LINK_REQUEST });

        axios
            .post(`${API_URL}SignUp/ResendEmail`, data, {
                headers: authHeader(),
            })
            .then((response) => {
                dispatch({
                    type: RESEND_EMAIL_LINK_SUCCESS,
                    payload: response.data,
                });
            })
            .catch(function (error) {
                dispatch({
                    payload: { error: error.message },
                    type: RESEND_EMAIL_LINK_FAILURE,
                });
            });
    } catch (error) {
        if (error?.response?.status == 401) {
            checkApiStatus();
        }
        dispatch({
            type: RESEND_EMAIL_LINK_FAILURE,
        });
    }
    dispatch({
        type: RESEND_EMAIL_LINK_RESET,
    });
};

export const ActivityHistory = (data) => async (dispatch) => {
    try {
        dispatch({ type: ACTIVITY_HISTORY_REQUEST });

        axios
            .post(`${API_URL}Activity/GetActivityHistory`, data, {
                headers: authHeader(),
            })
            .then((response) => {
                dispatch({
                    type: ACTIVITY_HISTORY_SUCCESS,

                    payload: response?.data?.datalist,
                });
            })
            .catch(function (error) {
                if (error?.response?.status == 401) {
                    checkApiStatus();
                }
                dispatch({
                    payload: { error: error.message },
                    type: ACTIVITY_HISTORY_FAILURE,
                });
            });
    } catch (error) {
        dispatch({
            type: ACTIVITY_HISTORY_FAILURE,
        });
    }
};

export const TransferOwner = (data) => async (dispatch) => {
    try {
        dispatch({ type: TRANSFER_OWNER_REQUEST });

        axios
            .post(`${API_URL}User/ChangeOwnership`, data, {
                headers: authHeader(),
            })
            .then((response) => {
                dispatch({
                    type: TRANSFER_OWNER_SUCCESS,

                    payload: response?.data,
                });
            })
            .catch(function (error) {
                if (error?.response?.status == 401) {
                    checkApiStatus();
                }
                dispatch({
                    payload: { error: error.message },
                    type: TRANSFER_OWNER_FAILURE,
                });
            });
    } catch (error) {
        dispatch({
            type: TRANSFER_OWNER_FAILURE,
        });
    }
};

export const tokenAuthRequest = (data) => async (dispatch) => {
    try {
        dispatch({ type: TOKEN_AUTHORIZATION_REQUEST });
        const res = await axios.post(`${API_URL}Authenticate/TokenExpiry?token=${data}`);
        dispatch({ type: TOKEN_AUTHORIZATION_SUCCESS, payload: res?.data });
        if (res?.data?.status === false) {
            window.location.href = "/";
            let myAdvanceItem = localStorage.getItem("advanceListOption");
            let myItem = localStorage.getItem("customOptions");
            let intervalItem = localStorage.getItem("PlayInterval");
            let newItem = localStorage.getItem("ageingOption");
            let folderAccess = localStorage.getItem("FolderAccess");
            let secret = localStorage.getItem("secretId");
            localStorage.clear();
            Cookies.remove("jwt");
            localStorage.setItem("advanceListOption", myAdvanceItem);
            localStorage.setItem("customOptions", myItem);
            localStorage.setItem("PlayInterval", intervalItem);
            localStorage.setItem("ageingOption", newItem);
            localStorage.setItem("FolderAccess", folderAccess);
            localStorage.setItem("secretId", secret);
        }
    } catch (error) {
        dispatch({ type: TOKEN_AUTHORIZATION_FAILURE, payload: error });
    }
};

// VALIDATE TEMP MAIL

export const ValidateTempMail = (data, json) => async (dispatch) => {
    try {
        dispatch({ type: GET_TEMP_MAIL_VALIDATE_REQUEST });

        axios
            .post(
                `${NODE_API_URL}api/emailService/isValidateEmail`,
                data
                // {
                // headers: authHeader(),
                // }
            )
            .then((response) => {
                dispatch(signupRequest(json));
                dispatch({
                    type: GET_TEMP_MAIL_VALIDATE_SUCCESS,
                    payload: response.data,
                });
                dispatch({ type: GET_TEMP_MAIL_VALIDATE_RESET });
            })
            .catch(function (error) {
                if (error) {
                    Swal.fire({
                        text: `Invalid email address`,
                        icon: "warning",
                        closeOnClickOutside: false,
                        showCloseButton: true,
                        button: true,
                    }).then((confirm) => {
                        if (confirm) {
                            dispatch({
                                type: GET_TEMP_MAIL_VALIDATE_RESET,
                            });
                        }
                    });
                }
                dispatch({
                    payload: { error: error.message },
                    type: GET_TEMP_MAIL_VALIDATE_FAILURE,
                });
            });
    } catch (error) {
        if (error?.response?.status == 401) {
            checkApiStatus();
        }
        dispatch({
            type: GET_TEMP_MAIL_VALIDATE_FAILURE,
        });
    }
};
export const AccountDeleteApi = (data) => async (dispatch) => {
    try {
        dispatch({ type: ACCOUNT_DELETE_REQUEST });

        axios
            .post(`${NODE_API_URL}api/accountDelt/accountdeltcontroller`, data, {
                headers: authHeader(),
            })
            .then((response) => {
                dispatch({
                    type: ACCOUNT_DELETE_SUCCESS,

                    payload: response?.data,
                });
            })
            .catch(function (error) {
                if (error?.response?.status == 401) {
                    checkApiStatus();
                }
                dispatch({
                    payload: { error: error.message },
                    type: ACCOUNT_DELETE_FAILURE,
                });
            });
    } catch (error) {
        dispatch({
            type: ACCOUNT_DELETE_FAILURE,
        });
    }
};
