import Button from "../../components/Button";
import Modal from "../../components/UI/Modal/Modal";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState, useContext } from "react";
import { ThemeContext } from "../../Hooks/Themes/Themeprovider";
import Stack from "@mui/joy/Stack";
import LinearProgress from "@mui/joy/LinearProgress";
import {
    GetGeneralLedgerAccountSync,
    GetCostCenterAccountSync,
    GetInternalOrderAccountSync,
    GetTaxMasterSync,
} from "../../redux/actions/account-sync.js";
import {
    GET_GL_ACCOUNT_SYNC_RESET,
    GET_COST_CENTER_ACCOUNT_SYNC_RESET,
    GET_INTERNAL_ORDER_ACCOUNT_SYNC_RESET,
    GET_TAX_MASTER_SYNC_RESET,
    GET_MISC_WAV_SYNC_RESET,
} from "../../redux/types/types.js";
import { GetGeneralLedgerSyncList } from "../../redux/actions/account-sync.js";
import { WbsVendorAssestSyncAction } from "../../redux/actions/miscellaneous-request";
let temp;
let InvId = [];
let StatusValue = [];
const AccountSync = (props) => {
    const { currentMode, toggleMode } = useContext(ThemeContext);
    // const [open, setOpen] = useState(false);
    const open = props?.open;
    const setOpen = props?.setOpen;
    const orgData = useSelector((state) => state?.ListorganisationDropDownState);
    const orgListData = orgData?.viewData?.filter((ele) => ele?.istarget == true);
    const orgId = orgListData?.map((el) => el?.id);
    const [index, setIndex] = useState(0);
    const { fetching: GLFetch, requestComplete, error, glData } = useSelector((state) => state?.GetGLAccountSyncState);
    const { fetching: CCFetch, requestComplete: reqComp, error: costError } = useSelector((state) => state?.GetCostCenterAccountSyncState);
    const { fetching: IoFetch, requestComplete: req, error: IOError } = useSelector((state) => state?.GetIOAccountSyncState);
    const { fetching: taxFetch, requestComplete: taxReqComp, error: TaxError } = useSelector((state) => state?.GetTaxMasterSyncState);
    const { fetching: WAVFetch, requestComplete: WbsReqComp, error: WavError } = useSelector((state) => state?.getWavSyncState);
    const WavData = useSelector((state) => state?.getWavSyncState);
    const [sync, setSync] = useState(false);
    const [syncType, setSyncType] = useState("");

    const showOrgName = props?.showOrgName;
    const setShowOrgName = props?.setShowOrgName;
    const getOrgName = orgListData?.filter((el) => el?.id == showOrgName);
    const dispatch = useDispatch();
    const ProgressClose = () => {
        props?.handleClose();
        setOpen(false);
        dispatch({ type: GET_MISC_WAV_SYNC_RESET });
        dispatch(GetGeneralLedgerSyncList());
        setProgress(0);
        setSyncType("");
    };
    const progress = props?.progress;
    const setProgress = props?.setProgress;
    const DisplayRole = useSelector((state) => state?.DisplayRolePermissionStateAction);
    const orgLogo = localStorage.getItem("orgLogo");
    const { data: DataList } = DisplayRole;
    const RoleList = DataList?.role_info;
    const RoleMenu = RoleList?.menu;
    const RoleMisc = RoleMenu?.find((elem) => elem?.menu_id === 22);
    const RoleActMisc = RoleMisc?.actions;
    const checkMisc = RoleActMisc?.some((element) => element.isallowed === true);
    const { syncData } = useSelector((state) => state?.GetGeneralLedgerSyncState);

    const FilterSyncData = checkMisc ? syncData : syncData?.filter((el) => el?.sync_type !== 5 && el?.sync_type !== 6 && el?.sync_type !== 7);
    const handleSyncAcc = async (sync_type) => {
        setOpen(true);
        setSync(true);
        if (sync_type == 1) {
            setIndex(0);
            setProgress(0);
            setSyncType("GL");
        } else if (sync_type == 2) {
            setIndex(0);
            setProgress(0);
            setSyncType("CC");
        } else if (sync_type == 3) {
            setIndex(0);
            setProgress(0);
            setSyncType("IO");
        } else if (sync_type == 4) {
            setIndex(0);
            setProgress(0);
            setSyncType("TM");
        } else if (sync_type == 5) {
            setIndex(0);
            setProgress(0);
            setSyncType("WBS");
        } else if (sync_type == 6) {
            setIndex(0);
            setProgress(0);
            setSyncType("AS");
        } else if (sync_type == 7) {
            setIndex(0);
            setProgress(0);
            setSyncType("VM");
        }
    };

    const FilterSuccessInv = InvId?.filter((el) => el?.status == true);
    const FilterFailedInv = InvId?.filter((el) => el?.status !== true);
    const OrgLength = props?.OrgLength;
    const FailedLength = props?.FailedLength;
    useEffect(() => {
        if (requestComplete == false && reqComp == false && req == false && taxReqComp == false && WbsReqComp == false && orgId?.length > index) {
            if (sync) {
                if (syncType == "GL") {
                    dispatch(GetGeneralLedgerAccountSync(orgId[index]));
                } else if (syncType == "CC") {
                    dispatch(GetCostCenterAccountSync(orgId[index]));
                } else if (syncType == "IO") {
                    dispatch(GetInternalOrderAccountSync(orgId[index]));
                } else if (syncType == "TM") {
                    dispatch(GetTaxMasterSync(orgId[index]));
                } else if (syncType == "WBS" || syncType == "AS" || syncType == "VM") {
                    dispatch(WbsVendorAssestSyncAction(orgId[index], syncType == "WBS" ? 2 : syncType == "AS" ? 1 : 3));
                }
                temp = index + 1;
                setIndex(temp);
                if (orgId?.length == temp) {
                    setSync(false);
                }
            }
            // setShowOrgName(orgId[index]);
        } else if (syncType == "GL" && requestComplete) {
            setProgress((index * 100) / orgId?.length);
            dispatch({ type: GET_GL_ACCOUNT_SYNC_RESET });
        } else if (syncType == "CC" && reqComp) {
            setProgress((index * 100) / orgId?.length);
            dispatch({ type: GET_COST_CENTER_ACCOUNT_SYNC_RESET });
        } else if (syncType == "IO" && req) {
            setProgress((index * 100) / orgId?.length);
            dispatch({ type: GET_INTERNAL_ORDER_ACCOUNT_SYNC_RESET });
        } else if (syncType == "TM" && taxReqComp) {
            setProgress((index * 100) / orgId?.length);
            dispatch({ type: GET_TAX_MASTER_SYNC_RESET });
        } else if ((syncType == "WBS" || syncType == "AS" || syncType == "VM") && WbsReqComp) {
            // const ErrorStatus = WavError;
            // const status = WavData?.data?.status;
            // const myObject = {
            //     InvId: ShowSeqName,
            //     status: status,
            // };
            const ErrorStatus = WavData?.error?.error;
            const status = WavData?.data?.status ? WavData?.data?.status : ErrorStatus;
            const myObject = {
                orgId: orgId[index],
                status: status,
            };
            InvId?.push(myObject);
            StatusValue?.push(status);
            setProgress((index * 100) / orgId?.length);
            dispatch({ type: GET_MISC_WAV_SYNC_RESET });
        }
        //  else {
        //     setInterval(() => {
        //         setProgress(100);
        //     }, 1800);
        // }
    }, [requestComplete, sync, reqComp, req, taxReqComp, WbsReqComp]);
    return (
        <>
            <Modal cartClose={props?.handleClose} center="center" tabIndex={0} className={`${props?.className}`}>
                {open && (
                    <div
                        className={`max-w-[500px] mx-auto relative bg-${currentMode}-card text-${currentMode}-copy border-${currentMode}-700 px-[38px] text-center py-[48px] rounded-[10px] shadow-[0px_4px_20px_rgba(238,238,238,0.5)]`}>
                        <Button onClick={ProgressClose} tabIndex={0} className="cursor-pointer absolute right-[20px] top-[20px] convertWhite">
                            <img src="/images/common/closeBlk.svg" alt="closeBlk" />
                        </Button>
                        <div className="items-center grid place-items-center space-y-3 min-w-[100px] trunc-2">
                            {progress == 100 ? (
                                <>
                                    <img src="/images/common/progress-tick.svg"></img>
                                    <p className="ml-4">
                                        {OrgLength} {`${OrgLength > 1 ? "Organizations" : "Organization"}`} Synced
                                    </p>
                                    {FailedLength > 0 && (
                                        <p className="ml-4 text-[#FA5A5A]">
                                            {FailedLength} {`${FailedLength > 1 ? "Organizations" : "Organization"}`} Sync Failed
                                        </p>
                                    )}
                                </>
                            ) : (
                                <p className="ml-4">{getOrgName[0]?.organisation_name}</p>
                            )}
                            <p className="text-light-40 mb-5 convertWhite">
                                {progress == 100
                                    ? syncType !== "" && orgId?.length > 0
                                        ? `The process is over, and the ${
                                              syncType == "GL"
                                                  ? "GL Account"
                                                  : syncType == "CC"
                                                  ? "Cost Center Account"
                                                  : syncType == "IO"
                                                  ? "Internal Order Account"
                                                  : syncType == "TM"
                                                  ? "Tax Master"
                                                  : syncType == "WBS"
                                                  ? "WBS Element"
                                                  : syncType == "AS"
                                                  ? "Asset"
                                                  : "Vendor Master"
                                          } is synced.`
                                        : "The process is over, There is no organization configured yet."
                                    : "Please wait while the process is complete"}
                            </p>
                        </div>
                        <Stack spacing={2} sx={{ margin: "20px auto 0", maxWidth: 200 }}>
                            <LinearProgress
                                sx={{
                                    backgroundColor: "rgba(2,140,120,0.22)",
                                    "&:before": { background: "#028C78" },
                                }}
                                determinate
                                value={progress}
                            />
                        </Stack>
                    </div>
                )}
            </Modal>
        </>
    );
};

export default AccountSync;
