import { Checkbox } from "@mui/material";
import { useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Card from "../../components/UI/Card";
import Main from "../../components/UI/Main";
import Layout from "../../Hoc/Layout";
import Footer from "../../organisms/Footer";
import { expirylink, getCurrentUser } from "../../redux/actions/auth";
import Loader from "../../components/Loader";
import InfiniteScroll from "react-infinite-scroll-component";
import authHeader from "../../redux/actions/auth-header";
import { GET_NOTIFICATION_RESET } from "../../redux/types/types";
import {
    GetNotificationList,
    GetPushNotification,
    UpdateNotification,
} from "../../redux/actions/notification";
import { date } from "yup";
import Post from "./post";
import { Link, useLocation } from "react-router-dom";
import { ThemeContext } from "../../Hooks/Themes/Themeprovider";
const API_URL = process.env?.REACT_APP_NODE_API_URL;
// let page = 1;

// const fetchData = (setItems, items) => {
//   axios
//     .get(`${API_URL}api/getpushnotification?isread=false&pgno=${page}&reccnt=${6}`, {
//       headers: authHeader(),
//     })
//     .then((res) => {
//       setItems([...items, ...res.data.datalist]);
//       page = page + 1;
//     });
// };

const refresh = (setItems) => { };

export const Notification = () => {
    const location = useLocation();
    const { currentMode } = useContext(ThemeContext);
    const date = new Date();
    const dispatch = useDispatch();
    const {
        data: Data,
        page,
        isScroll,
        fetching,
        hasMore,
    } = useSelector((state) => state?.getpushNotificationState);

    const isReadIconShow = Data?.filter((el) => el?.isread == true);

    const BtnCkick = () => {
        dispatch(expirylink());
    };

    const [items, setItems] = useState(Data);

    useEffect(() => {
        dispatch(GetPushNotification(false, 1, 20, "normal"));
    }, []);

    const d = date.toLocaleDateString("en-GB");
    const MatchDate = Data?.filter((el) => el?.created_date == d);
    const EarlierItem = Data?.filter((el) => el?.created_date !== d);
    const checkhandleChange = (event, Id) => {
        EarlierItem?.forEach((element) => {
            if (Id == element?.id) {
                element.isread = event.target.value;
            }
        });
        MatchDate?.forEach((element) => {
            if (Id == element?.id) {
                element.isread = event.target.value;
            }
        });
        setItems([...items]);
        dispatch(UpdateNotification({ id: +Id }));
        // setTimeout(() => {
        //     dispatch(GetPushNotification(false, 1, 20, 'normal'))
        // }, 1000);
    };

    const ReadAll = () => {
        dispatch(UpdateNotification({ id: 0 }));
        setTimeout(() => {
            window.location.reload();
            dispatch(GetPushNotification(false, 1, 20, "normal"));
        }, 1000);
    };
    const [scrollPosition, setScrollPosition] = useState(0);

    const containerRef = useRef(null);
    useEffect(() => {
        const handleScroll = () => {
            const position = window.pageYOffset;
            setScrollPosition(position);
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    useEffect(() => {
        const loadMoreData = () => {
            if (containerRef.current && !isScroll && hasMore) {
                const container = containerRef.current;
                const { scrollTop, scrollHeight, clientHeight } = container;
                const isInViewport =
                    scrollTop + clientHeight >= scrollHeight - 10; // Add a threshold to trigger the event slightly before reaching the bottom

                if (isInViewport) {
                    dispatch(GetPushNotification(false, page, 10, "scroll"));
                }
            }
        };

        containerRef.current?.addEventListener("scroll", loadMoreData);
        // if (location.pathname !== "/notification") {
        //     dispatch({ type: GET_NOTIFICATION_RESET });
        // }
        return () => {
            containerRef.current?.removeEventListener("scroll", loadMoreData);
        };
    }, [isScroll, page]);
    const ResetBack = () => {
        dispatch({ type: GET_NOTIFICATION_RESET });
    };

    return (
        <>
            <Layout>
                <Main>
                    <div
                        className={`flex justify-between items-center mb-6 text-${currentMode}-copy`}
                    >
                        <div>
                            <h4 className={`text-2xl mb-7 font-interSb`}>
                                Notifications
                            </h4>
                        </div>
                        <Link
                            className="flex"
                            to="/dashboard"
                            onClick={ResetBack}
                        >
                            <img
                                className="mr-2 convertWhite"
                                src="/images/main/noun-back.svg"
                                alt="noun-back"
                            />{" "}
                            Back
                        </Link>
                    </div>

                    {/* <Card className="p-[30px] h-[calc(100vh_-_13rem)]"> */}

                    <>
                        <Card className="">
                            <div
                                ref={containerRef}
                                className="h-[calc(100vh_-_15rem)] p-[20px] overflow-y-auto"
                            >
                                {fetching && <Loader className="h-[40px]" />}
                                <ul>
                                    <li>
                                        {Data?.length !== 0 && (
                                            <>
                                                <div
                                                    className={`flex text-light-70 pb-2 text-xs justify-between items-center border-${currentMode}-680 mx-[-30px] px-[30px]`}
                                                >
                                                    <span>
                                                        Today (
                                                        {MatchDate?.length})
                                                    </span>
                                                    {isReadIconShow?.length >
                                                        0 && (
                                                            <span
                                                                className="flex cursor-pointer"
                                                                onClick={ReadAll}
                                                            >
                                                                <img
                                                                    className="mr-1"
                                                                    src="/images/main/double-tick.svg"
                                                                    alt="double-tick"
                                                                />{" "}
                                                                Mark all as read
                                                            </span>
                                                        )}
                                                </div>
                                                <hr
                                                    className={`my-5 border-${currentMode}-700 `}
                                                />
                                            </>
                                        )}
                                        {MatchDate &&
                                            MatchDate?.map((elem, index) => {
                                                return (
                                                    <div
                                                        className={`flex justify-between border-b-[1px] border-${currentMode}-680 py-[20px] mx-[-30px] px-[30px]`}
                                                    >
                                                        <div className="flex space-x-4 flex-1">
                                                            {/* <div className="flex-initial">
                      <img src="/images/main/userNew.svg" alt="userNew" />
                    </div> */}
                                                            <div className="flex-1">
                                                                <span className="font-interM">
                                                                    {
                                                                        elem?.username
                                                                    }{" "}
                                                                    {
                                                                        elem?.title
                                                                    }
                                                                </span>{" "}
                                                                <p className="text-[13px] leading-[19px] mb-1"></p>{" "}
                                                                <span>
                                                                    {
                                                                        elem?.message
                                                                    }
                                                                </span>
                                                                <p></p>
                                                            </div>
                                                        </div>
                                                        <div className="flex flex-initial items-center justify-between space-x-7">
                                                            <span className="flex-1">
                                                                {
                                                                    elem?.createddt
                                                                }
                                                            </span>
                                                            <div className="flex-initial">
                                                                <Checkbox
                                                                    checked={
                                                                        elem?.isread
                                                                    }
                                                                    onChange={(
                                                                        event
                                                                    ) => {
                                                                        checkhandleChange(
                                                                            {
                                                                                target: {
                                                                                    name: event
                                                                                        .target
                                                                                        .name,
                                                                                    value: event
                                                                                        .target
                                                                                        .checked,
                                                                                },
                                                                            },
                                                                            elem?.id
                                                                        );
                                                                    }}
                                                                    icon={
                                                                        <img
                                                                            src="/images/main/notifi-normal.svg"
                                                                            alt="notifi-normal"
                                                                        />
                                                                    }
                                                                    checkedIcon={
                                                                        <img
                                                                            src="/images/main/notifi-checked.svg"
                                                                            alt="notifi-checked"
                                                                        />
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                    </li>
                                    <li>
                                        {Data?.length !== 0 && (
                                            <div
                                                className={`flex text-light-70 pb-2 text-xs justify-between items-center  border-${currentMode}-700 mx-[-30px] px-[30px] mt-[20px]`}
                                            >
                                                <span className="">
                                                    Older ({EarlierItem?.length}
                                                    ){" "}
                                                </span>
                                            </div>
                                        )}
                                        {EarlierItem &&
                                            EarlierItem?.map((elem, index) => {
                                                return (
                                                    <div
                                                        className={`flex justify-between border-b-[1px] border-${currentMode}-700 py-[20px] mx-[-30px] px-[30px]`}
                                                    >
                                                        <div className="flex space-x-4 flex-1">
                                                            {/* <div className="flex-initial">
                      <img src="/images/main/userNew.svg" alt="userNew" />
                    </div> */}
                                                            <div className="flex-1">
                                                                <span className="font-interM">
                                                                    {
                                                                        elem?.username
                                                                    }{" "}
                                                                    {
                                                                        elem?.title
                                                                    }
                                                                </span>{" "}
                                                                <p className="text-[13px] leading-[19px] mb-1"></p>{" "}
                                                                <span>
                                                                    {
                                                                        elem?.message
                                                                    }
                                                                </span>
                                                                <p></p>
                                                            </div>
                                                        </div>
                                                        <div className="flex flex-initial items-center justify-between space-x-7">
                                                            <span className="flex-1">
                                                                {
                                                                    elem?.createddt
                                                                }
                                                            </span>
                                                            <div className="flex-initial">
                                                                <Checkbox
                                                                    checked={
                                                                        elem?.isread
                                                                    }
                                                                    onChange={(
                                                                        event
                                                                    ) => {
                                                                        checkhandleChange(
                                                                            {
                                                                                target: {
                                                                                    name: event
                                                                                        .target
                                                                                        .name,
                                                                                    value: event
                                                                                        .target
                                                                                        .checked,
                                                                                },
                                                                            },
                                                                            elem?.id
                                                                        );
                                                                    }}
                                                                    icon={
                                                                        <img
                                                                            src="/images/main/notifi-normal.svg"
                                                                            alt="notifi-normal"
                                                                            className="convertCheckWhite"
                                                                        />
                                                                    }
                                                                    checkedIcon={
                                                                        <img
                                                                            src="/images/main/notifi-checked.svg"
                                                                            alt="notifi-checked"
                                                                        />
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                    </li>
                                    {isScroll && (
                                        <Loader className="h-[10px]" />
                                    )}
                                    {!hasMore && Data?.length !== 0 && (
                                        <span className="flex justify-center">
                                            No more notification available
                                        </span>
                                    )}
                                </ul>

                                {Data?.length == 0 && (
                                    <div className="grid h-full place-items-center place-content-center">
                                        <img
                                            className="mb-4"
                                            src="/images/common/delivery.png"
                                        ></img>
                                        No notifications are found
                                    </div>
                                )}
                            </div>
                        </Card>
                    </>
                    {/* </Card> */}
                </Main>
                <Footer />
            </Layout>
        </>
    );
};
