import update from "immutability-helper";
import _ from "lodash";
import { memo, useCallback, useMemo, useState } from "react";
import { useDrop } from "react-dnd";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import { Card } from "./Card";

export const MoreFilterTarget = memo(function Container({ currentMode, onDrop, areaName, items, type, filterCheck }) {
    const dispatch = useDispatch();
    const { droppedValues, morefilterValues } = useSelector((state) => state?.droppedState);
    const [dropped, setDropped] = useState([]);

    const resultArray = _.uniqBy(morefilterValues, "column_name");
    useMemo(() => {
        const uniqueArray = _.differenceBy(resultArray, filterCheck, "column_name");
        setDropped(uniqueArray);
    }, [morefilterValues, filterCheck]);

    const findCard = useCallback(
        (id) => {
            const card = dropped.filter((c) => `${c.id}` === id)[0];
            return {
                card,
                index: dropped.indexOf(card),
            };
        },
        [dropped]
    );
    const moveCard = useCallback(
        (id, atIndex) => {
            const { card, index } = findCard(id);
            if (card?.Type == areaName) {
                dispatch({ type: "MOVE_THE_CARD", payload: { card, index, atIndex, name: "morefilterValues" } }); //This action for after card swapped original array should be modified!!
            }
        },
        [findCard, dropped, setDropped]
    );
    const [, drop] = useDrop(() => ({ accept: "card" }));
    const [{ isOver }, drag] = useDrop({
        accept: "DRAGGABLE_ITEM",
        drop: (item) => {
            onDrop(item?.value, areaName);
        },
        collect: (monitor) => ({
            isOver: !!monitor.isOver(),
        }),
    });
    const reomoveItem = async (idToRemove) => {
        dispatch({
            type: "DELETE_CHECKBOX_MORE_FILTER",
            payload: idToRemove,
        });
    };
    const checkRemove = (name, card) => {
        Swal.fire({
            text: `Are you sure you want to delete ${card?.field_display_name} from ${_.startCase(card?.Type)}?`,
            icon: "warning",
            showCloseButton: true,
            showCancelButton: true,
            buttons: {
                cancel: "Cancel",
                confirm: "Confirm",
            },
        }).then((confirm) => {
            if (confirm?.isConfirmed) {
                reomoveItem(name);
            }
        });
    };

    return (
        <div ref={drag}>
            <div ref={drop} className={`flex ${isOver ? "bg-[#f0f0f0]" : ""} py-[15px] px-[20px] flex-wrap items-center w-full`}>
                {dropped?.length === 0 && <p className={`text-${currentMode}-1220 w-full text-center`}>Drag and Drop the More Filters here</p>}
                {dropped?.map((card, i) => (
                    <Card
                        key={card?.id}
                        index={i}
                        id={`${card?.id}`}
                        value={card}
                        moveCard={moveCard}
                        findCard={findCard}
                        type={type}
                        areaName={areaName}
                        handleRemove={() => checkRemove(card?.column_name, card)}
                    />
                ))}
            </div>
        </div>
    );
});
