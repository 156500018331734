import {
  GET_INBOX_SETTINGS_REQUEST,
  GET_INBOX_SETTINGS_SUCCESS,
  GET_INBOX_SETTINGS_RESET,
  GET_INBOX_SETTINGS_FAILURE,
  ACTIVATE_DEACTIVATE_INBOX_SETTINGS_REQUEST,
  ACTIVATE_DEACTIVATE_INBOX_SETTINGS_SUCCESS,
  ACTIVATE_DEACTIVATE_INBOX_SETTINGS_FAILURE,
  ACTIVATE_DEACTIVATE_INBOX_SETTINGS_RESET,
  INBOX_SETTINGS_DELETE_REQUEST,
  INBOX_SETTINGS_DELETE_SUCCESS,
  INBOX_SETTINGS_DELETE_FAILURE,
  INBOX_SETTINGS_DELETE_RESET,
} from "../types/types";

//inbox settings list

const ListInboxSettings = {
  configData: [],
  fetching: false,
  requestComplete: false,
  error: "",
};

export function inboxSettingsList(state = ListInboxSettings, action) {
  switch (action.type) {
    case GET_INBOX_SETTINGS_REQUEST:
      return {
        ...state,
        fetching: true,
        requestComplete: false,
        error: "",
      };
    case GET_INBOX_SETTINGS_SUCCESS:
      return {
        configData: action.payload,
        fetching: false,
        requestComplete: true,
        error: "",
      };
    case GET_INBOX_SETTINGS_RESET:
      return {
        configData: [],
        fetching: false,
        requestComplete: false,
        error: "",
      };
    case GET_INBOX_SETTINGS_FAILURE:
      return {
        fetching: false,
        requestComplete: false,
        error: action.payload,
      };
    default:
      return state;
  }
}

//inbox active-inactive

const inboxSettingsStatusState = {
  data: [],
  fetching: false,
  requestComplete: false,
  error: "",
};

export function inboxSettingStatus(state = inboxSettingsStatusState, action) {
  switch (action.type) {
    case ACTIVATE_DEACTIVATE_INBOX_SETTINGS_REQUEST:
      return {
        ...state,
        fetching: true,
        requestComplete: false,
        error: "",
      };
    case ACTIVATE_DEACTIVATE_INBOX_SETTINGS_SUCCESS:
      return {
        data: action.payload,
        fetching: false,
        requestComplete: true,
        error: "",
      };
    case ACTIVATE_DEACTIVATE_INBOX_SETTINGS_RESET:
      return {
        data: {},
        fetching: false,
        requestComplete: false,
        error: "",
      };
    case ACTIVATE_DEACTIVATE_INBOX_SETTINGS_FAILURE:
      return {
        fetching: false,
        requestComplete: false,
        error: action.payload,
      };
    default:
      return state;
  }
}

//delete inbox mail
const inboxSettingsDeleteState = {
  data: [],
  fetching: false,
  requestComplete: false,
  error: "",
};

export function inboxSettingsDelete(state = inboxSettingsDeleteState, action) {
  switch (action.type) {
    case INBOX_SETTINGS_DELETE_REQUEST:
      return {
        ...state,
        fetching: true,
        requestComplete: false,
        error: "",
      };
    case INBOX_SETTINGS_DELETE_SUCCESS:
      return {
        data: action.payload,
        fetching: false,
        requestComplete: true,
        error: "",
      };
    case INBOX_SETTINGS_DELETE_RESET:
      return {
        data: [],
        fetching: false,
        requestComplete: false,
        error: "",
      };
    case INBOX_SETTINGS_DELETE_FAILURE:
      return {
        fetching: false,
        requestComplete: false,
        error: action.payload,
      };
    default:
      return state;
  }
}
