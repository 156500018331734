import { ThemeContext } from "../../Hooks/Themes/Themeprovider";
import Card from "../../components/UI/Card";
import React, { useContext, useState } from "react";
import Button from "../../components/Button";
import { formatNumber } from "../../utils/helpers";
import { NumericFormat } from "react-number-format";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import { encryptId } from "../../utils/helpers";

const RetentionTable = (props) => {
    const {
        poReleseTable,
        setPoReleaseTable,
        setReleaseAmount,
        isPopUpNeed,
        setIsPopUpNeed,
        setAddHoldAmount,
        viewFetching,
        editDisable,
        thousandSeperator,
        decSeperator,
        viewId,
    } = props;
    const { currentMode } = useContext(ThemeContext);
    const [placement, setPlacement] = useState();
    const [popstate, setPopState] = useState({ id: null, anchorEl: null });
    const data = {
        total_retention: 0,
        add_hold: 0,
        total_amount: 0,
    };
    poReleseTable?.map((item) => {
        data.total_retention = data?.total_retention + formatNumber(item?.retention_amount || "0");
        data.add_hold = data?.add_hold + formatNumber(item?.addhold_amount || "0");
        data.total_amount = data?.total_amount + formatNumber(item?.retention_amount || "0") + formatNumber(item?.addhold_amount || "0");
    });
    const handleClick = (newPlacement, id) => (event) => {
        setPopState({ id, anchorEl: event.currentTarget }, (prev) => placement !== newPlacement || !prev);
        setPlacement(newPlacement);
    };
    function updatePoTable(index, ref_val, id) {
        setPoReleaseTable((prev) => {
            const updatedTable = prev.map((item, prevIndex) => {
                if (prevIndex === index) {
                    let refer_bal = id == 1 ? item?.refer_ret_amt : item?.refer_add_hold;

                    let repaymentAmount = ref_val >= refer_bal ? refer_bal : ref_val === 0 ? "" : ref_val;
                    return {
                        ...item,
                        //  balance_amount: value >= item?.refer_bal ? 0 : item?.refer_bal - value,
                        [id == 1 ? "retention_repayment_amount" : "addhold_repayment_amount"]: repaymentAmount,
                        [id == 1 ? "retention_repayment_char" : "addhold_repayment_char"]: repaymentAmount,
                        [id == 1 ? "show_ret" : "show_add"]: true,
                    };
                }
                return item;
            });
            const total = updatedTable?.reduce((init, value) => {
                init += +value?.[id == 1 ? "retention_repayment_amount" : "addhold_repayment_amount"] || 0;
                return init;
            }, 0);
            if (id == 1) {
                setReleaseAmount(total);
            } else {
                setAddHoldAmount(total);
            }
            return updatedTable; // Return the updated table
        });
    }
    const handleChange = (index, value, id, amount) => {
        if (!isPopUpNeed?.[id == 1 ? "retention" : "addHold"]) {
            setIsPopUpNeed({
                ...isPopUpNeed,
                [id == 1 ? "retention" : "addHold"]: true,
            });
        }

        let ref_val = formatNumber(value || "0");
        if (ref_val > amount) {
            setPoReleaseTable((prev) => {
                return prev?.map((item, ind) => {
                    if (ind === index) {
                        return {
                            ...item,
                            [id == 1 ? "show_ret" : "show_add"]: false,
                        };
                    }
                    return item; // Make sure to return item for other indices
                });
            });
            showPopup(index, ref_val, id, amount);
        } else {
            updatePoTable(index, ref_val, id);
        }
    };
    const showPopup = (index, ref_val, id, amount) => {
        Swal.fire({
            text: `The entered ${id == 1 ? "Retention" : "Add Hold"} Repayment Amount should be lesser than or equal to ${amount}  `,
            icon: "success",
            closeOnClickOutside: false,
            showCloseButton: true,
            button: "Ok",
        }).then((result) => {
            updatePoTable(index, ref_val, id);
        });
    };
    const NumericConverter = ({ value, className }) => (
        <NumericFormat
            autoComplete="off"
            allowNegative={false}
            readOnly={true}
            decimalScale={2}
            maxLength={23}
            displayType="input"
            thousandSeparator={thousandSeperator}
            decimalSeparator={decSeperator}
            // disabled={status == "7" || status == "4" || InputDisable}
            value={value}
            className={className}
        />
    );
    return (
        <>
            <Card
                className={` mb-20 border-[1px] border-[rgba(223,225,226,0.56)] 2xl:w-full ${
                    currentMode === "dark" ? "" : "shadow-[0px_4px_20px_rgba(238,238,238,0.5)]"
                }`}
            >
                <div className="flex px-[20px] py-[20px]">
                    <span className="text-[18px] font-interM">
                        <h3>Retention</h3>
                    </span>
                </div>

                <div className="md:max-h-[420px] overflow-y-auto">
                    {poReleseTable?.length == 0 || viewFetching ? (
                        <>
                            <div className="flex flex-col items-center py-10">
                                <img src="/images/common/delivery.png" alt="delivery" />
                                <h4 className="font-interSb text-center mt-3">No Invoices with Retention found</h4>
                            </div>
                        </>
                    ) : (
                        <table className="tblRetention border-0 w-max xl:w-full rounded-[0] text-center">
                            <thead className="sticky top-0 z-[1]">
                                <tr className={`bg-${currentMode}-450 text-[14px]`}>
                                    <th className="px-[15px] rounded-l-[0]">#</th>
                                    <th className="">Invoice Number</th>
                                    <th className="">Invoice Date</th>
                                    <th>Document No.</th>
                                    <th>Retention Amt.</th>
                                    <th>Additional Hold</th>
                                    <th className="flex border-0 flex-col text-left ">
                                        {" "}
                                        Total Amount
                                        <span class="font-interR border-0 text-xs m-0 p-0">(Retention + Additional Hold)</span>{" "}
                                    </th>
                                    {/* <th className="">Balance Amount</th> */}
                                    <th className="">Retention Amount Repayment</th>
                                    <th className="">Additional Hold Repayment</th>
                                </tr>
                            </thead>

                            <tbody className="relative">
                                {poReleseTable?.map((item, index) => {
                                    return (
                                        <tr key={index}>
                                            <td className="px-[10px]">{index + 1}</td>
                                            <td className="">
                                                <Link
                                                    to={`${"/invoice-view?"}${encryptId(
                                                        `{"inv":${+item?.invoice_id},"type":${9},"seq":"${
                                                            item?.inv_seq
                                                        }","status":${+item?.ext_status},"ret_id":${viewId ? viewId : 0}}`
                                                    )}
                                                    

                                                       `}
                                                    style={{
                                                        textDecoration: "underline",
                                                    }}
                                                    target="_blank"
                                                >
                                                    {item?.invoice_number}
                                                </Link>
                                            </td>
                                            <td className="">{item?.invoice_date}</td>
                                            <td className="">{item?.document_number}</td>
                                            <td className="">
                                                <NumericConverter value={item?.retention_amount || 0} className={"text-center bg-transparent"} />
                                            </td>
                                            <td className="">
                                                <NumericConverter value={item?.addhold_amount || 0} className={"text-center bg-transparent"} />
                                            </td>
                                            <td className="">
                                                <NumericConverter
                                                    value={(+item?.addhold_amount || 0) + (+item?.retention_amount || 0)}
                                                    className={"text-center bg-transparent"}
                                                />
                                            </td>
                                            {/* <td className="">
                                            
                                            <NumericConverter value={item?.balance_amount || 0} className={"text-center"} />
                                        </td> */}
                                            <td className="">
                                                {" "}
                                                {item?.show_ret && (
                                                    <NumericFormat
                                                        id={1}
                                                        autoComplete="off"
                                                        allowNegative={false}
                                                        readOnly={editDisable}
                                                        decimalScale={2}
                                                        maxLength={23}
                                                        displayType="input"
                                                        thousandSeparator={thousandSeperator}
                                                        decimalSeparator={decSeperator}
                                                        // disabled={status == "7" || status == "4" || InputDisable}
                                                        value={item?.retention_repayment_amount || ""}
                                                        onChange={(e) => {
                                                            handleChange(index, e.target.value || 0, e.target.id, item?.refer_ret_amt);
                                                        }}
                                                        placeholder="Enter release amount"
                                                        className={
                                                            "border-[rgba(85,85,85,0.5)] w-[150px] h-[40px] bg-transparent px-[10px] py-1 border-[1px] rounded-[8px] "
                                                        }
                                                    />
                                                )}
                                                {/* <input
                                                    id={1}
                                                    readOnly={editDisable}
                                                    autoFocus
                                                    type="number"
                                                    value={item?.retention_repayment_amount}
                                                    onChange={(e) => {
                                                        handleChange(index, +e.target.value || 0, e.target.id);
                                                    }}
                                                    placeholder="Enter release amount"
                                                    class="border-[rgba(85,85,85,0.5)] w-[150px] h-[40px] bg-transparent px-[10px] py-1 border-[1px] rounded-[8px] "
                                                /> */}
                                            </td>
                                            <td className="">
                                                {" "}
                                                {item?.show_add && (
                                                    <NumericFormat
                                                        id={2}
                                                        autoComplete="off"
                                                        allowNegative={false}
                                                        readOnly={editDisable}
                                                        decimalScale={2}
                                                        maxLength={23}
                                                        displayType="input"
                                                        thousandSeparator={thousandSeperator}
                                                        decimalSeparator={decSeperator}
                                                        // disabled={status == "7" || status == "4" || InputDisable}
                                                        value={item?.addhold_repayment_amount || ""}
                                                        onChange={(e) => {
                                                            handleChange(index, e.target.value || 0, e.target.id, item?.refer_add_hold);
                                                        }}
                                                        placeholder="Enter addhold amount"
                                                        className={
                                                            "border-[rgba(85,85,85,0.5)] w-[150px] h-[40px] bg-transparent px-[10px] py-1 border-[1px] rounded-[8px] "
                                                        }
                                                    />
                                                )}
                                                {/* {item?.show_add && (
                                                    <input
                                                        id={2}
                                                        readOnly={editDisable}
                                                        autoFocus
                                                        type="number"
                                                        value={item?.addhold_repayment_amount}
                                                        onChange={(e) => {
                                                            handleChange(index, +e.target.value || 0, e.target.id, item?.refer_add_hold);
                                                        }}
                                                        placeholder="Enter release amount"
                                                        class="border-[rgba(85,85,85,0.5)] w-[150px] h-[40px] bg-transparent px-[10px] py-1 border-[1px] rounded-[8px] "
                                                    />
                                                )} */}
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>

                            {/* bg-color is transparent neede to chick it out */}
                            <tfoot className="sticky bottom-0 z-[1]">
                                <tr className={`bg-${currentMode}-card ${currentMode === "dark" ? "" : ""} `}>
                                    <td colSpan={"4"} className="text-right font-interSb rounded-l-[8px]">
                                        Total
                                    </td>
                                    <td className={`font-interSb bg-${currentMode}-1360 ${currentMode === "dark" ? "" : ""}`}>
                                        {/* {poReleseTable.reduce((init, value) => {
                                        init += +value?.retention_amount || 0;
                                        return init;
                                    }, 0)} */}
                                        {/* {data?.total_retention} */}
                                        <NumericConverter
                                            value={data?.total_retention}
                                            className={`bg-${currentMode}-1360 ${currentMode === "dark" ? "" : ""} text-center`}
                                        />
                                    </td>
                                    <td className={`font-interSb bg-${currentMode}-1360 ${currentMode === "dark" ? "" : ""}`}>
                                        {" "}
                                        {/* {poReleseTable.reduce((init, value) => {
                                        init += +value?.addhold_amount || 0;
                                        return init;
                                    }, 0)} */}
                                        {/* {data?.add_hold} */}
                                        <NumericConverter
                                            value={data?.add_hold}
                                            className={`bg-${currentMode}-1360 ${currentMode === "dark" ? "" : ""} text-center`}
                                        />
                                    </td>
                                    <td className={`font-interSb bg-${currentMode}-1360 ${currentMode === "dark" ? "" : ""}`}>
                                        {/* {poReleseTable.reduce((init, value) => {
                                        init += (+value?.addhold_amount || 0) + (+value?.retention_amount || 0);
                                        return init;
                                    }, 0)} */}
                                        <NumericConverter
                                            value={data?.total_amount}
                                            className={`bg-${currentMode}-1360 ${currentMode === "dark" ? "" : ""} text-center`}
                                        />
                                        {/*  {data?.total_amount}*/}
                                    </td>
                                    {/* <td className={`font-interSb rounded-r-[8px] bg-${currentMode}-1360 ${currentMode === "dark" ? "" : ""}`}>
                                    <NumericConverter
                                        value={poReleseTable.reduce((init, value) => {
                                            init += formatNumber(value?.balance_amount || 0);
                                            return init;
                                        }, 0)}
                                        className={`bg-${currentMode}-1360 ${currentMode === "dark" ? "" : ""} text-center `}
                                    />
                                </td> */}
                                    <td className={`font-interSb rounded-r-[8px] bg-${currentMode}-1360 ${currentMode === "dark" ? "" : ""}`}>
                                        <NumericConverter
                                            value={poReleseTable?.reduce((init, value) => {
                                                init += +value?.retention_repayment_amount || 0;
                                                return init;
                                            }, 0)}
                                            className={`bg-${currentMode}-1360 ${currentMode === "dark" ? "" : ""} text-center`}
                                        />
                                    </td>
                                    <td className={`font-interSb rounded-r-[8px] bg-${currentMode}-1360 ${currentMode === "dark" ? "" : ""}`}>
                                        <NumericConverter
                                            value={poReleseTable?.reduce((init, value) => {
                                                init += +value?.addhold_repayment_amount || 0;
                                                return init;
                                            }, 0)}
                                            className={`bg-${currentMode}-1360 ${currentMode === "dark" ? "" : ""} text-center`}
                                        />
                                    </td>
                                </tr>
                            </tfoot>
                        </table>
                    )}
                </div>
            </Card>
        </>
    );
};

export default RetentionTable;
