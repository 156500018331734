import { FormControl, MenuItem, Select, TextField, Autocomplete, Chip, CircularProgress, Paper } from "@mui/material";
import Modal from "../../../../components/UI/Modal/Modal";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Button from "../../../../components/Button";
import { useDispatch, useSelector } from "react-redux";
import { useContext, useEffect, useState } from "react";
import moment from "moment";
import { Add_INVOICE_TODO_CREATE_TASK_RESET, GET_INVOICE_TASK_LIST_RESET } from "../../../../redux/types/types";
import Loader from "../../../../components/Loader";
import MessageBox from "../../../../components/UI/Alert";
import SuccessMessageBox from "../../../../components/UI/Alert/sucess";
import { getPriorityConfigList, addToDoTask } from "../../../../redux/actions/my-task.js";
import { decryptId } from "../../../../utils/helpers";
import { getMyTaskList, getToDOTaskUserList, getInvoiceTaskList } from "../../../../redux/actions/my-task";
import { useLocation } from "react-router-dom";
import { ThemeContext } from "../../../../Hooks/Themes/Themeprovider";
const InvoiceTodoTask = (props) => {
    const { currentMode, toggleMode } = useContext(ThemeContext);
    const getConfig = useSelector((state) => state?.getPriorityConfigState);
    const priorityListData = getConfig?.dataList;
    const [selected, setSelected] = useState("");
    const [forInput, setForInput] = useState("");
    const listData = useSelector((state) => state?.myTaskUserListState);
    const userListData = listData?.dataList;

    useEffect(() => {
        const defaultVal = priorityListData?.find((el) => el?.isdefault == true);
        const parseVal = defaultVal == undefined ? priorityListData[0] : defaultVal;
        setSelected(parseVal?.task_priority);
        const defaultdate = addDays(new Date(), parseVal?.due_days);
        setDueDate(defaultdate);
    }, [getConfig]);

    const dispatch = useDispatch();
    const [subject, setSubject] = useState("");
    const currentDate = new Date();
    const [dueDate, setDueDate] = useState(currentDate);
    const [emailMessage, setEmailmessage] = useState("");
    const [parse, setParse] = useState([]);
    const { fetching, requestComplete, error, data } = useSelector((state) => state?.addMyTaskCreateToDoState);
    const succesMsg = "Task is created successfully";
    const errormsg = "Something went wrong please contact admin";

    function addDays(theDate, days) {
        return new Date(theDate.getTime() + days * 24 * 60 * 60 * 1000);
    }

    var newDate = addDays(new Date(), 5);
    const [forUser, setForUser] = useState([]);
    const [forUserId, setForUserId] = useState(null);

    const filterChange = (event) => {
        setSelected(event.target.value);
        const defaultVal = priorityListData?.find((el) => el?.task_priority == event.target.value);
        const temp = addDays(new Date(), defaultVal?.due_days);
        setDueDate(temp);
    };

    const handleValueChange = (newval) => {
        const findVal = userListData.find((el) => el?.username == newval);
        setForUserId(findVal?.id);
    };
    const handleValue = (newValue) => {
        if (newValue) {
            setDueDate(newValue?.$d);
        }
    };
    const location = useLocation();
    const params = new URLSearchParams(window.location.search);
    const urlData = location?.search.slice(1)?.replace(/\n\s+/g, "\n")?.trim();

    const urlObj = JSON?.parse(decryptId(urlData));
    let invId = urlObj?.inv;
    const reqObj = {
        json: [
            {
                task_type: 1,
                email_tempid: null,
                task_subject: subject,
                task_body: emailMessage,
                task_to: forUserId,
                task_cc: null,
                task_id: 0,
                task_bcc: null,
                task_priority: selected,
                due_date: moment(dueDate).format("YYYY-MM-DD").toString(),
                parent_id: props?.parentId,
                sender: null,
                receiver: null,
                email_type: 1,
            },
        ],
        attachment: null,
        invid: +invId,
    };
    const AccessIcon = () => <img src="/images/invoice/calendar.svg" alt="calendar" className="convertWhite" />;
    const handleCreateToDoTask = () => {
        dispatch(addToDoTask(reqObj));
    };

    useEffect(() => {
        const temp = [];
        userListData?.map((item) => {
            if (!temp.includes(item?.username)) {
                temp.push(item?.username);
            }
            setParse(temp);
        });
    }, [listData]);
    useEffect(() => {
        if (props?.isOpened) {
            if (getConfig?.requestComplete == false || listData?.requestComplete == false) {
                if (priorityListData?.length == 0) {
                    dispatch(getPriorityConfigList());
                }
                // dispatch(getToDOTaskUserList());
            }
            dispatch(getToDOTaskUserList(localStorage.getItem("orgId")));
        } else {
            setSelected("");
            setEmailmessage("");
            setSubject("");
            setForUser([]);
            const defaultVal = priorityListData?.find((el) => el?.isdefault == true);
            const parseVal = defaultVal == undefined ? priorityListData[0] : defaultVal;
            setSelected(parseVal?.task_priority);
            const defaultdate = addDays(new Date(), parseVal?.due_days);
            setDueDate(defaultdate);
        }
    }, [props?.isOpened]);

    if (requestComplete || (requestComplete && !data?.status) || (error && !data?.status)) {
        setTimeout(function () {
            if (data?.status) {
                if (props?.isOpened) {
                    dispatch(getInvoiceTaskList(+invId));
                    props?.handleClose();
                    props?.handleCloseInvView();
                }
                // dispatch({ type: GET_INVOICE_TASK_LIST_RESET });
                dispatch({ type: Add_INVOICE_TODO_CREATE_TASK_RESET });

                // props?.handleClose();
                //  props?.handleCloseInvView();
                // window.location.reload();
            }
        }, 2000);
    } else if (error && !data?.status) {
        {
            setTimeout(function () {
                dispatch({ type: Add_INVOICE_TODO_CREATE_TASK_RESET });
            }, 2000);
        }
    }
    const dropdownColor = currentMode === "dark" ? "#333333" : `white`;
    const dropdownText = currentMode === "dark" ? "#ECECEC" : `#555555`;
    const dropdownShadow = currentMode !== "dark" ? "0px 4px 20px rgba(238, 238, 238, 0.5)" : "";
    const dropdownBorder = currentMode !== "dark" ? "223, 225, 226, 0.56" : "";
    return (
        <Modal className={`${props?.className}`} upperLay={true}>
            <span onClick={props?.handleClose} className="p-[6.5px] absolute right-[775px] bg-light-10 cursor-pointer" title="Close">
                <img src="/images/common/whiteClose.svg" title="Close" alt="whiteClose" />
            </span>
            {requestComplete && data?.status && <SuccessMessageBox success={succesMsg} />}
            {requestComplete && !data?.status && <MessageBox error={errormsg} />}
            {error && !data?.status && <MessageBox error={errormsg} />}
            {getConfig?.requestComplete == true &&
                getConfig?.fetching == false &&
                selected != undefined &&
                props?.isOpened == true &&
                listData?.fetching == false ? (
                <>
                    <div
                        className={`flex max-w-[775px] ml-auto bg-${currentMode}-card overflow-y-auto justify-between flex-col h-screen max-h-[-webkit-fill-available]`}
                    >
                        <div className="h-[calc(100vh_-_4rem)] overflow-y-auto max-h-[-webkit-fill-available]">
                            <div className={`flex py-[15px] px-[30px] justify-between items-center bg-${currentMode}-580`}>
                                <span className="block font-interSb text-[18px]">Create Task</span>
                            </div>
                            <div className="flex py-[15px] px-[30px] items-center">
                                <span className="text-base">Subject:</span>
                                <input
                                    placeholder=""
                                    className="w-full ml-7 bg-transparent"
                                    disabled={false}
                                    value={subject}
                                    onChange={(e) => setSubject(e.target.value)}
                                />
                            </div>
                            <div className="flex items-center py-[15px] px-[30px] justify-between ">
                                <div className="flex ">
                                    <span className="text-[14px] block">For:</span>
                                    <img className="ml-2 convertWhite" src="/images/invoice/user-plusblk.svg" alt="user-plusblk" />
                                </div>
                                <div className={`filter ${forUser?.length > 0 ? `solo` : ``}`}>
                                    <Autocomplete
                                        disablePortal
                                        multiple
                                        sx={{
                                            "&.MuiAutocomplete-root .MuiInputBase-root": {
                                                border: 0,
                                                width: "410px",
                                            },
                                            "& .MuiChip-label": {
                                                color: `${currentMode == "dark" ? "white" : "black"}`,
                                            },
                                            "& .MuiAutocomplete-input": {
                                                color: `${currentMode == "dark" ? "white" : "black"}`,
                                            },
                                        }}
                                        PaperComponent={({ children }) => (
                                            <Paper
                                                style={{
                                                    backgroundColor: dropdownColor,
                                                    color: dropdownText,
                                                    boxShadow: dropdownShadow,
                                                    border: dropdownBorder,
                                                    borderRadius: "8px",
                                                }}
                                            >
                                                {children}
                                            </Paper>
                                        )}
                                        id="tags-filled"
                                        options={forUser?.length > 0 ? [] : parse}
                                        value={forUser}
                                        inputValue={forInput}
                                        disableClearable
                                        onInputChange={(e, newValue) => {
                                            setForInput(newValue);
                                        }}
                                        limitTags={1}
                                        onChange={(e, newval, reason) => {
                                            const findVal = userListData.find((el) => el?.username == newval);
                                            setForUserId(findVal?.id);
                                            setForUser(newval);
                                        }}
                                        renderTags={(value, getTagProps) =>
                                            value?.length > 0 &&
                                            value?.map((option, index) => {
                                                return (
                                                    <Chip
                                                        key={index}
                                                        variant="outlined"
                                                        disable={false}
                                                        label={option}
                                                        {...getTagProps({
                                                            index,
                                                        })}
                                                        onDelete={() => {
                                                            setForUser(forUser?.filter((e) => e !== option));
                                                        }}
                                                    />
                                                );
                                            })
                                        }
                                        renderInput={({ inputProps, ...params }) => (
                                            <TextField
                                                fullWidth
                                                {...params}
                                                inputProps={{
                                                    ...inputProps,
                                                    readOnly: forUser?.length > 0 ? true : false,
                                                    endAdornment: null,
                                                }}
                                            />
                                        )}
                                    />
                                </div>

                                <div className="flex items-center">
                                    <div>
                                        <FormControl fullWidth>
                                            <Select
                                                onChange={filterChange}
                                                name="filter"
                                                fullWidth
                                                className="Org"
                                                value={selected}
                                                MenuProps={{
                                                    sx: {
                                                        "& .MuiMenu-paper": {
                                                            backgroundColor: dropdownColor,
                                                            color: dropdownText,
                                                            boxShadow: `${currentMode === "dark" ? "" : "0px 4px 20px rgba(238, 238, 238, 0.5"}`,
                                                            border: `${currentMode === "dark" ? "" : "1px solid rgba(223, 225, 226, 0.56)"}`,
                                                            borderRadius: "8px",
                                                        },
                                                        "& .MuiMenuItem-root:hover": {
                                                            backgroundColor: "rgba(218, 249, 244, 0.3)",
                                                        },
                                                        "& .Mui-selected": {
                                                            backgroundColor: "rgba(218, 249, 244, 0.3) !important",
                                                        },
                                                    },
                                                }}
                                                IconComponent={() => (
                                                    <img
                                                        className="absolute right-[0px] pointer-events-none convertWhite"
                                                        src="/images/main/priority-icon.svg"
                                                        alt="user-plusblk"
                                                    />
                                                )}
                                                SelectDisplayProps={{
                                                    style: {
                                                        paddingTop: 1,
                                                        display: "flex",
                                                        paddingBottom: 1,
                                                        paddingLeft: "5px",
                                                        maxWidth: "120px",
                                                        marginLeft: "-15px",
                                                        border: 0,
                                                        paddingRight: "30px",
                                                        color: `${currentMode !== "dark" ? `#555555` : `#ECECEC`}`,
                                                    },
                                                }}
                                                displayEmpty
                                            >
                                                <MenuItem value={4}>Urgent</MenuItem>
                                                <MenuItem value={3}>High</MenuItem>
                                                <MenuItem value={2}>Medium</MenuItem>
                                                <MenuItem value={1}>Low</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div className="w-[120px]">
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DesktopDatePicker
                                                inputFormat={localStorage.getItem("userDateFormat").toUpperCase()}
                                                components={{
                                                    OpenPickerIcon: AccessIcon,
                                                }}
                                                disablePast
                                                value={dueDate}
                                                name="invoice_date_from"
                                                onChange={handleValue}
                                                renderInput={(params) => (
                                                    <TextField
                                                        sx={{
                                                            "& .MuiOutlinedInput-root": {
                                                                paddingRight: 0,
                                                            },
                                                            "& .MuiInputAdornment-root": {
                                                                marginRight: 0,
                                                            },
                                                            "& .MuiIconButton-edgeEnd": {
                                                                padding: "0",
                                                                marginRight: 0,
                                                            },
                                                            "& .MuiInputBase-input": {
                                                                color: `${currentMode == "dark" ? "white" : "black"} !important`,
                                                            },
                                                        }}
                                                        // value={dueDate}
                                                        fullWidth
                                                        {...params}
                                                        inputProps={{
                                                            ...params.inputProps,
                                                            placeholder: " ",
                                                            readOnly: true,
                                                        }}
                                                    />
                                                )}
                                            />
                                        </LocalizationProvider>
                                    </div>
                                </div>
                            </div>
                            <div className={`basics-[100%] quillContainer py-[15px] px-[20px] border-t-[1px] border-${currentMode}-700`}>
                                <textarea
                                    placeholder="Write your message"
                                    className="w-full outline-none ml-3 bg-transparent"
                                    disabled={false}
                                    rows="23"
                                    value={emailMessage}
                                    onChange={(e) => setEmailmessage(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className={`py-[15px] px-[30px] border-t-[1px] border-${currentMode}-700 `}>
                            <Button
                                title="To create todo task"
                                onClick={handleCreateToDoTask}
                                disabled={
                                    fetching ||
                                    subject?.length == 0 ||
                                    selected == undefined ||
                                    selected == "" ||
                                    emailMessage?.length == 0 ||
                                    forUser == "" ||
                                    forUser == undefined
                                }
                                className={`adduser-step users-page-step border-20 border-${currentMode}-800 bg-light-20 px-5 py-2 rounded-[8px] font-interM text-sm flex items-center border-[1px] text-white`}
                            >
                                {fetching && <CircularProgress size={20} color="inherit" />}

                                <span className="ml-2">Create Task</span>
                            </Button>
                        </div>
                    </div>
                </>
            ) : (
                <div className={`flex max-w-[712px] ml-auto bg-${currentMode}-card overflow-y-auto justify-between flex-col h-screen`}>
                    <div className={`flex py-[15px] px-[30px] justify-between items-center bg-${currentMode}-580`}>
                        <span className="block font-interSb text-[18px]">Create Task</span>
                    </div>
                    <Loader className="h-[calc(100vh_-_6rem)]" />
                </div>
            )}
        </Modal>
    );
};
export default InvoiceTodoTask;
