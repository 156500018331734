import {
    Checkbox,
    FormControl,
    FormControlLabel,
    MenuItem,
    Select,
    TextField,
} from "@mui/material";
import TextareaAutosize from "@mui/base/TextareaAutosize";
import { Link } from "react-router-dom";
import Button from "../../components/Button";
import Input from "../../components/Input";
import Card from "../../components/UI/Card";
import Main from "../../components/UI/Main";
import Layout from "../../Hoc/Layout";
import Footer from "../../organisms/Footer";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect, useContext } from "react";
import {
    AddAccrualAccounting,
    GetAccrualAccounting,
} from "../../redux/actions/accrual-accounting";
import SuccessMessageBox from "../../components/UI/Alert/sucess";
import MessageBox from "../../components/UI/Alert";
import Swal from "sweetalert2";
import {
    ADD_ACCRUAL_ACCOUNTING_RESET,
    GET_ACCRUAL_ACCOUNTING_RESET,
} from "../../redux/types/types";
import Loader from "../../components/Loader";
import { ThemeContext } from "../../Hooks/Themes/Themeprovider";

const AccrualAccounting = () => {
    const { currentMode, toggleMode } = useContext(ThemeContext);
    const orgId = localStorage.getItem("orgId");
    const succesMsg = "Updated successfully";
    const errormsg = "Something went wrong please contact admin";
    const orgData = useSelector(
        (state) => state?.ListorganisationDropDownState
    );
    const dataList = orgData.viewData;
    const AccrualData = useSelector((state) => state?.GetAccrualState);
    const ListData = AccrualData?.data?.datalist;
    const inv_filter = AccrualData?.data?.invfilter;
    const accCodeValid = ListData?.filter((el) => el?.account_code == "");
    const amountValid = ListData?.filter((el) => el?.amount_type == 0);
    const debCreditValid = ListData?.filter((el) => el?.debit_credit == 0);
    const [layoutItems, setLayoutItems] = useState([ListData]);
    const [deleteNo, setDeleteNo] = useState(ListData);
    const [click, setClick] = useState(false);
    const [PoNotReleased, setPoNotReleased] = useState(true);
    const [NoGrn, setNoGrn] = useState(true);
    const [NoPoInvoice, setNonPoInvoice] = useState(true);
    // const AccountCode =ListData?.map((ele)=>ele?.account_code);
    const { requestComplete, fetching, data, error } = useSelector(
        (state) => state?.AddAccrualState
    );
    const credit = ListData?.filter((el) => el?.debit_credit == 1);
    const debit = ListData?.filter((el) => el?.debit_credit == 2);
    const CreditCheck = credit?.map((el) => el.amount_type == 3);
    const DebitCheck = debit?.map(
        (el) => el?.amount_type == 1 && el?.amount_type == 2
    );
    const [AccValueValid, setAccValueValid] = useState(false);
    const creditStringCheck = String(CreditCheck);
    const debitStringCheck = String(DebitCheck);
    const [Amount1, setAmount1] = useState([]);
    const [Amount2, setAmount2] = useState([]);
    const [DebAmount1, setDebAmount1] = useState([]);
    const [DebAmount2, setDebAmount2] = useState([]);
    const [DebAmount3, setDebAmount3] = useState([]);
    const Amount1sort = Amount1.sort();
    const Amount1Join = Amount1sort.join("");
    const arrSplit = Amount1Join.split("");
    const arrjoin = arrSplit.sort();
    const Amount1stringfy = arrjoin.join("");
    const DebAmountsort = DebAmount1.sort();
    const DebAmount1Join = DebAmountsort.join("");
    const debtSplit = DebAmount1Join.split("");
    const debtJoin = debtSplit.sort();
    const DebtStringfy = debtJoin.join("");
    const dispatch = useDispatch();
    const [orgList, setorgList] = useState(orgId);
    const [CreditrowsData, setCreditRowsData] = useState([]);
    const [DebitrowData, setDebitRowsData] = useState([]);
    const [DCard, setCard] = useState("");
    const [accountCode, setAccountCode] = useState("");
    const [Description, setDescription] = useState("");
    const [Amount, setAmount] = useState("");
    const DuplicateField = ListData?.filter(
        (el) => el?.debit_credit && el?.account_code && el?.amount_type
    );
    const CheckBoxArr = [
        { InvType: 1, Exc: 1, Selected: PoNotReleased },
        { InvType: 1, Exc: 2, Selected: NoGrn },
        { InvType: 2, Exc: 3, Selected: NoPoInvoice },
    ];
    const CheckeveryArr = CheckBoxArr?.every((el) => el?.Selected == false);
    let uploadedFiles = [];
    let fileStatusList = [];
    const [fileList, setFileStatusList] = useState([]);
    const handleOrgValueChange = (value, name) => {
        setorgList(value);
        setDebAmount1([]);
        setAmount1([]);
    };

    const handleCardChange = (event, id, inde) => {
        setCard(event.target.value);
        ListData.forEach((element, index) => {
            if (element?.accrual_id !== 0) {
                element.action_type = 2;
            }
            if (index == inde) {
                element.debit_credit = event.target.value;
            }
        });
        setLayoutItems([...layoutItems]);
    };

    const handleAccountChange = (event, id, inde) => {
        setAccountCode(event.target.value);
        ListData.forEach((element, index) => {
            if (element?.accrual_id !== 0) {
                element.action_type = 2;
            }
            if (index == inde) {
                element.account_code = event.target.value;
            }
        });
        setLayoutItems([...layoutItems]);
    };
    const handleCostCenterChange = (event, id, inde) => {
        ListData.forEach((element, index) => {
            if (element?.accrual_id !== 0) {
                element.action_type = 2;
            }
            if (index == inde) {
                element.cost_centre = event.target.value;
            }
        });
        setLayoutItems([...layoutItems]);
    };

    const handleDescriptionChnage = (event, id, inde) => {
        setDescription(event.target.value);

        ListData.forEach((element, index) => {
            // if (id == element?.accrual_id) {

            //   element.account_description = event.target.value;

            // }

            if (element?.accrual_id !== 0) {
                element.action_type = 2;
            }

            if (index == inde) {
                element.account_description = event.target.value;
            }
        });

        setLayoutItems([...layoutItems]);
    };

    const handleAmountChange = (event, id, inde) => {
        setAmount(event.target.value);

        ListData.forEach((element, index) => {
            // if (id == element?.accrual_id) {

            //   element.amount_type = event.target.value;

            // }

            if (element?.accrual_id !== 0) {
                element.action_type = 2;
            }

            if (index == inde) {
                element.amount_type = event.target.value;
            }
        });

        setLayoutItems([...layoutItems]);
    };
    useEffect(() => {
        // ListData.forEach((element, index) => {
        //     if (!/^(?=.*[0-9])[A-Za-z0-9_ ]+$/.test(element?.account_code)) {
        //         setAccValueValid(true)
        //     }
        // });
        dispatch({ type: GET_ACCRUAL_ACCOUNTING_RESET });
        if (orgList !== "undefined" && orgList !== "0" && orgList !== 0) {
            dispatch(GetAccrualAccounting(orgList));
        }
    }, [dispatch, orgList]);

    const tableRowRemove = (id, inde) => {
        Swal.fire({
            text: "Are you sure you want to delete the details?",
            closeOnClickOutside: false,
            icon: "warning",
            showCloseButton: true,
            showCancelButton: true,

            buttons: {
                cancel: "Cancel",

                confirm: "Confirm",
            },
        }).then((confirm) => {
            if (confirm?.isConfirmed) {
                ListData.splice(inde, 1);

                setLayoutItems([...layoutItems]);

                ListData.forEach((element, index) => {
                    if (index == inde) {
                        const data = {
                            account_code: element.account_code,

                            account_description: element.account_description,

                            accrual_id: element.accrual_id,

                            amount_type: element.amount_type,

                            debit_credit: element.debit_credit,

                            isactive: true,

                            organisation_id: orgList,

                            action_type: 3,
                        };

                        deleteNo.push(data);
                    }
                });
            }
        });
    };

    if (ListData?.length == 0) {
        const data = {
            account_code: "",

            account_description: "",

            accrual_id: 0,

            amount_type: 0,

            debit_credit: 0,

            isactive: true,

            organisation_id: orgList,

            action_type: 0,
        };

        ListData.push(data);

        setLayoutItems([...layoutItems]);
    }

    const addRowTable = () => {
        const selVal = document.getElementById("Card");
        selVal.scrollIntoView({ behavior: "smooth", center: "x" });
        window.scroll(0, 500);

        const data = {
            account_code: "",

            account_description: "",

            accrual_id: 0,

            amount_type: 0,

            debit_credit: 0,

            isactive: true,

            organisation_id: orgList,

            action_type: 0,
        };

        ListData.push(data);

        setLayoutItems([...layoutItems]);
    };

    let accCode;
    let orgnameTitle;
    const keys = ["account_code", "debit_credit", "amount_type"];
    const hasDuplicates = (values) =>
        new Set(values?.map((v) => JSON?.stringify(keys?.map((key) => v[key]))))
            .size < values?.length;
    hasDuplicates(DuplicateField);
    const InsertAccrual = () => {
        setClick(true);

        ListData.forEach((element) => {
            if (element?.accrual_id !== 0) {
                element.action_type = 2;
            }

            if (element?.accrual_id == 0) {
                element.action_type = 1;
            }
            if (
                accCodeValid?.length > 0 &&
                amountValid?.length > 0 &&
                debCreditValid?.length > 0
            ) {
                Swal.fire({
                    text: "Rows should not be empty!",
                    icon: "warning",
                    closeOnClickOutside: false,
                    showCloseButton: true,
                    buttons: {
                        confirm: true,
                    },
                }).then((confirm) => {
                    if (confirm) {
                        setClick(false);
                    }
                });

                return false;
            } else if (debCreditValid?.length > 0) {
                Swal.fire({
                    text: "Select any Debit/Credit!",
                    icon: "warning",
                    closeOnClickOutside: false,
                    showCloseButton: true,
                    buttons: {
                        confirm: true,
                    },
                }).then((confirm) => {
                    if (confirm) {
                        setClick(false);
                    }
                });

                return false;
            } else if (accCodeValid?.length > 0) {
                Swal.fire({
                    text: "Account Code is required!",
                    showCloseButton: true,
                    icon: "warning",
                    closeOnClickOutside: false,
                    buttons: {
                        confirm: true,
                    },
                }).then((confirm) => {
                    if (confirm) {
                        setClick(false);
                    }
                });

                return false;
            } else if (amountValid?.length > 0) {
                Swal.fire({
                    text: "Amount Type  is required!",
                    showCloseButton: true,
                    icon: "warning",
                    closeOnClickOutside: false,
                    buttons: {
                        confirm: true,
                    },
                }).then((confirm) => {
                    if (confirm) {
                        setClick(false);
                    }
                });

                return false;
            } else if (hasDuplicates(DuplicateField)) {
                Swal.fire({
                    text: "Accrual Config should not have duplicate values!",
                    showCloseButton: true,
                    icon: "warning",
                    closeOnClickOutside: false,
                    buttons: {
                        confirm: true,
                    },
                }).then((confirm) => {
                    if (confirm) {
                        setClick(false);
                    }
                });

                return false;
            } else if (
                !/^(?=.*[0-9])[A-Za-z0-9_ ]+$/.test(element?.account_code)
            ) {
                setAccValueValid(true);
                Swal.fire({
                    text: "Account Code is Invalid!",
                    icon: "warning",
                    closeOnClickOutside: false,
                    showCloseButton: true,
                    buttons: {
                        confirm: true,
                    },
                }).then((confirm) => {
                    if (confirm) {
                        setAccValueValid(false);
                        setClick(false);
                    }
                });
                return false;
            }

            // if (
            //     element.debit_credit == 1 &&
            //     element?.amount_type == 3 &&
            //     element?.debit_credit == 2 &&
            //     element?.amount_type == 3
            // ) {
            //     Swal.fire({
            //         text: "Invalid Config",

            //         icon: "warning",

            //         buttons: {
            //             confirm: true,
            //         },
            //     }).then((confirm) => {
            //         if (confirm) {
            //             setClick(false);
            //         }
            //     });

            //     return false;
            // }
        });

        credit.forEach((element) => {
            if (element.amount_type == 1) {
                Amount1.push("x");

                Amount2.push(element.amount_type);
            }

            if (element.amount_type == 2) {
                Amount1.unshift("y");

                Amount2.push(element.amount_type);
            }

            if (element.amount_type == 3) {
                Amount1.unshift("xy");

                Amount2.push(element.amount_type);
            }
        });

        debit.forEach((element) => {
            if (element.amount_type == 1) {
                DebAmount1.unshift("x");

                DebAmount2.push(element.amount_type);
            }

            if (element.amount_type == 2) {
                DebAmount1.unshift("y");

                DebAmount2.push(element.amount_type);
            }

            if (element.amount_type == 3) {
                DebAmount1.unshift("xy");

                DebAmount2.push(element.amount_type);
            }
        });
        if (CheckeveryArr) {
            Swal.fire({
                text: "Please enable any one checkbox!",
                icon: "warning",
                closeOnClickOutside: false,
                showCloseButton: true,
                buttons: {
                    confirm: true,
                },
            }).then((confirm) => {
                if (confirm) {
                    setClick(false);
                }
            });
        }
    };

    const ifAcCode = !/^(?=.*[0-9])[A-Za-z0-9_ ]+$/.test(accountCode);

    useEffect(() => {
        if (inv_filter?.length) {
            setPoNotReleased(inv_filter?.[0]?.Selected);
            setNoGrn(inv_filter?.[1]?.Selected);
            setNonPoInvoice(inv_filter?.[2]?.Selected);
        } else {
            setPoNotReleased(true);
            setNoGrn(true);
            setNonPoInvoice(true);
        }
    }, [inv_filter]);

    useEffect(() => {
        if (Amount1stringfy !== DebtStringfy) {
            Swal.fire({
                text: "Invalid Configuration!",
                showCloseButton: true,
                icon: "warning",
                closeOnClickOutside: false,
                buttons: {
                    confirm: true,
                },
            }).then((confirm) => {
                if (confirm) {
                    setAmount1([]);

                    setDebAmount1([]);
                }
            });
        }

        const ifEmpty = Amount1stringfy !== "" && DebtStringfy !== "";
        if (
            ifEmpty &&
            Amount1stringfy === DebtStringfy &&
            accCodeValid?.length == 0 &&
            !AccValueValid &&
            !hasDuplicates(DuplicateField) &&
            click &&
            amountValid?.length == 0 &&
            !CheckeveryArr
        ) {
            const OBj = {
                json: JSON.stringify(ListData),
                organisation_id: +orgList,
                inv_filter: JSON.stringify([
                    { InvType: "1", Exc: "1", Selected: PoNotReleased },
                    { InvType: "1", Exc: "2", Selected: NoGrn },
                    { InvType: "2", Exc: "3", Selected: NoPoInvoice },
                ]),
            };
            dispatch(AddAccrualAccounting(OBj));
        }

        return () => {
            setClick(false);
        };
    }, [click, PoNotReleased]);

    if (requestComplete) {
        setTimeout(function () {
            dispatch({ type: ADD_ACCRUAL_ACCOUNTING_RESET });

            setAmount1([]);

            setDebAmount1([]);
        }, 1000);
    }

    const handleCheckBoxPoNotReleased = (event) => {
        setPoNotReleased(event.target.value);
    };
    const handleCheckBoxGrnChange = (event) => {
        setNoGrn(event.target.value);
    };
    const handleCheckboxChange = (event) => {
        setNonPoInvoice(event.target.value);
    };
    const dropdownColor = currentMode === "dark" ? "#333333" : `white`;
    const dropdownText = currentMode === "dark" ? "#ECECEC" : `#555555`;
    return (
        <>
            {requestComplete && data?.status && (
                <SuccessMessageBox success={succesMsg} />
            )}

            {requestComplete && !data?.status && (
                <MessageBox error={errormsg} />
            )}

            {error && !data?.status && <MessageBox error={errormsg} />}

            <Layout>
                <Main>
                    <div
                        className={` text-${currentMode}-copy flex justify-between items-center mb-6`}
                    >
                        <div>
                            <h4 className="text-2xl font-interSb">
                                Accrual Accounting
                            </h4>
                        </div>
                    </div>

                    <Card
                        className={`h-[calc(100vh_-_22rem)] px-[30px] xl:h-[calc(100vh_-_17.5rem)] card p-[40px] border-b-0 rounded-t-[10px] rounded-b-[0] w-full overflow-y-auto ${currentMode === "dark"
                            ? ""
                            : "shadow-[0px_4px_20px_rgba(238,238,238,0.5)]"
                            }  pb-0 relative`}
                    >
                        {AccrualData?.fetching || requestComplete ? (
                            <Loader className="h-[calc(100vh_-_17rem)] " />
                        ) : (
                            <>
                                <div className="flex items-center space-x-[2rem] mb-5">
                                    <span className="font-interM">
                                        Organization
                                    </span>

                                    <FormControl
                                        sx={{
                                            width: "300px",
                                        }}
                                    >
                                        <Select
                                            value={orgList || "0"}
                                            title={
                                                dataList?.find(
                                                    (el) => el?.id == orgList
                                                )?.organisation_name
                                            }
                                            name="OrganisationList"
                                            onChange={(e) =>
                                                handleOrgValueChange(
                                                    e.target.value,
                                                    e
                                                )
                                            }
                                            inputProps={{
                                                "aria-label": "Without label",
                                            }}
                                            IconComponent={() => (
                                                <img
                                                    className="absolute opacity-[.5] right-[15px] pointer-events-none"
                                                    src={
                                                        currentMode !== "dark"
                                                            ? `/images/header/chevronDown.svg`
                                                            : `/images/header/chevronDown-white.svg`
                                                    }
                                                    alt="chevronDown"
                                                />
                                            )}
                                            MenuProps={{
                                                sx: {
                                                    "& .MuiMenu-paper": {
                                                        backgroundColor:
                                                            dropdownColor,
                                                        color: dropdownText,
                                                        maxWidth:
                                                            "30rem !important",
                                                    },
                                                    "& .MuiMenuItem-root:hover":
                                                    {
                                                        backgroundColor:
                                                            "rgba(218, 249, 244, 0.3)",
                                                    },
                                                    "& .Mui-selected": {
                                                        backgroundColor:
                                                            "rgba(218, 249, 244, 0.3) !important",
                                                    },
                                                },
                                            }}
                                            SelectDisplayProps={{
                                                style: {
                                                    paddingTop: "9px",
                                                    paddingBottom: "9px",
                                                    color: `${currentMode !== "dark"
                                                        ? `#555555`
                                                        : `#ECECEC`
                                                        }`,
                                                    paddingRight: "30px",
                                                    border: "1px solid rgba(85, 85, 85, 0.4)",
                                                    borderRadius: "8px",
                                                },
                                            }}
                                        >
                                            <MenuItem
                                                key={0}
                                                name="OrganisationList"
                                                value={0 || "undefined"}
                                            >
                                                {"Select Organization"}
                                            </MenuItem>

                                            {dataList &&
                                                dataList?.map((listelem, i) => {
                                                    return (
                                                        <MenuItem
                                                            title={
                                                                listelem?.organisation_name
                                                            }
                                                            key={i + 1}
                                                            name={
                                                                listelem?.organisation_name
                                                            }
                                                            value={listelem?.id}
                                                        >
                                                            {
                                                                listelem?.organisation_name
                                                            }
                                                        </MenuItem>
                                                    );
                                                })}
                                        </Select>
                                    </FormControl>
                                </div>
                                <table
                                    className={`w-full rolemasterTable 2xl:max-w-[1119px] mb-[30px] mr-[30px] border rounded-[8px] xl:mr-0 xl:pr-0 border-${currentMode}-700`}
                                    id="Card"
                                >
                                    <thead className={`bg-${currentMode}-450`}>
                                        <tr>
                                            <th className="text-left">
                                                PO Invoice
                                            </th>
                                            <th className="text-left">
                                                Non-PO Invoice
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <td className="border-0">
                                            <ul>
                                                <li>
                                                    <FormControlLabel
                                                        className="block"
                                                        checked={PoNotReleased}
                                                        onChange={(event) => {
                                                            handleCheckBoxPoNotReleased(
                                                                {
                                                                    target: {
                                                                        name: event
                                                                            .target
                                                                            .name,
                                                                        value: event
                                                                            .target
                                                                            .checked,
                                                                    },
                                                                }
                                                            );
                                                        }}
                                                        control={
                                                            <Checkbox
                                                                sx={{
                                                                    color: "rgba(85,85,85,0.7)",
                                                                    "&.Mui-checked":
                                                                    {
                                                                        color: "#028C78",
                                                                    },
                                                                }}
                                                            />
                                                        }
                                                        label={
                                                            "PO is not released"
                                                        }
                                                    />
                                                </li>
                                                <li>
                                                    <FormControlLabel
                                                        className="block"
                                                        checked={NoGrn}
                                                        onChange={(event) => {
                                                            handleCheckBoxGrnChange(
                                                                {
                                                                    target: {
                                                                        name: event
                                                                            .target
                                                                            .name,
                                                                        value: event
                                                                            .target
                                                                            .checked,
                                                                    },
                                                                }
                                                            );
                                                        }}
                                                        control={
                                                            <Checkbox
                                                                sx={{
                                                                    color: "rgba(85,85,85,0.7)",
                                                                    "&.Mui-checked":
                                                                    {
                                                                        color: "#028C78",
                                                                    },
                                                                }}
                                                            />
                                                        }
                                                        label={
                                                            "GRN is incomplete"
                                                        }
                                                    />
                                                </li>
                                            </ul>
                                        </td>

                                        <td className="border-b-0">
                                            <FormControlLabel
                                                className="block"
                                                checked={NoPoInvoice}
                                                onChange={(event) => {
                                                    handleCheckboxChange({
                                                        target: {
                                                            name: event.target
                                                                .name,
                                                            value: event.target
                                                                .checked,
                                                        },
                                                    });
                                                }}
                                                control={
                                                    <Checkbox
                                                        sx={{
                                                            color: "rgba(85,85,85,0.7)",
                                                            "&.Mui-checked": {
                                                                color: "#028C78",
                                                            },
                                                        }}
                                                    />
                                                }
                                                label={"Non-PO Invoice"}
                                            />
                                        </td>
                                    </tbody>
                                </table>

                                <table
                                    className={`w-full rolemasterTable rounded-[8px] border-${currentMode}-700 !border-[1px] mr-[20px] xl:mr-0 2xl:max-w-[1119px] mb-4 accrual`}
                                    id="Card"
                                >
                                    <thead className={`bg-${currentMode}-450`}>
                                        <tr>
                                            <th>Debit / Credit</th>
                                            <th>Account Code</th>
                                            <th>Cost Center</th>
                                            <th>Account Description</th>
                                            <th>Amount</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {ListData?.map((elem, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>
                                                        <FormControl
                                                            sx={{
                                                                minWidth: 150,
                                                            }}
                                                        >
                                                            <Select
                                                                value={
                                                                    elem?.debit_credit
                                                                }
                                                                name="debit_credit"
                                                                onChange={(
                                                                    event
                                                                ) => {
                                                                    handleCardChange(
                                                                        {
                                                                            target: {
                                                                                name: event
                                                                                    .target
                                                                                    .name,
                                                                                value: event
                                                                                    .target
                                                                                    .value,
                                                                            },
                                                                        },
                                                                        elem?.accrual_id,
                                                                        index
                                                                    );
                                                                }}
                                                                inputProps={{
                                                                    "aria-label":
                                                                        "Without label",
                                                                }}
                                                                IconComponent={() => (
                                                                    <img
                                                                        className="absolute opacity-[.5] right-[15px] pointer-events-none"
                                                                        src={
                                                                            currentMode !==
                                                                                "dark"
                                                                                ? `/images/header/chevronDown.svg`
                                                                                : `/images/header/chevronDown-white.svg`
                                                                        }
                                                                        alt="chevronDown"
                                                                    />
                                                                )}
                                                                MenuProps={{
                                                                    sx: {
                                                                        "& .MuiMenu-paper":
                                                                        {
                                                                            backgroundColor:
                                                                                dropdownColor,
                                                                            color: dropdownText,
                                                                        },

                                                                        "& .MuiMenuItem-root:hover":
                                                                        {
                                                                            backgroundColor:
                                                                                "rgba(218, 249, 244, 0.3)",
                                                                        },

                                                                        "& .Mui-selected":
                                                                        {
                                                                            backgroundColor:
                                                                                "rgba(218, 249, 244, 0.3) !important",
                                                                        },
                                                                    },
                                                                }}
                                                                SelectDisplayProps={{
                                                                    style: {
                                                                        paddingTop:
                                                                            "9px",
                                                                        paddingBottom:
                                                                            "9px",
                                                                        color: `${currentMode !==
                                                                            "dark"
                                                                            ? `#555555`
                                                                            : `#ECECEC`
                                                                            }`,
                                                                        paddingRight:
                                                                            "30px",
                                                                        border: "1px solid rgba(85, 85, 85, 0.4)",
                                                                        borderRadius:
                                                                            "8px",
                                                                    },
                                                                }}
                                                            >
                                                                <MenuItem
                                                                    key={0}
                                                                    name="debit_credit"
                                                                    value={0}
                                                                >
                                                                    {"Select"}
                                                                </MenuItem>

                                                                <MenuItem
                                                                    key={1}
                                                                    name="debit_credit"
                                                                    value={1}
                                                                >
                                                                    {"Credit"}
                                                                </MenuItem>

                                                                <MenuItem
                                                                    key={2}
                                                                    name="debit_credit"
                                                                    value={2}
                                                                >
                                                                    {"Debit"}
                                                                </MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </td>

                                                    <td>
                                                        <Input
                                                            className={`w-full border-[1px] py-[8px] px-5 rounded-[8px] min-w-[150px] border-${currentMode}-840 bg-${currentMode}-trans text-${currentMode}-copy`}
                                                            name="account_code"
                                                            type="text"
                                                            placeholder="Account Code"
                                                            maxLength={20}
                                                            value={
                                                                elem?.account_code
                                                            }
                                                            onChange={(
                                                                event
                                                            ) => {
                                                                handleAccountChange(
                                                                    {
                                                                        target: {
                                                                            name: event
                                                                                .target
                                                                                .name,

                                                                            value: event
                                                                                .target
                                                                                .value,
                                                                        },
                                                                    },

                                                                    elem?.accrual_id,

                                                                    index
                                                                );
                                                            }}
                                                        />
                                                    </td>
                                                    <td>
                                                        <Input
                                                            className={`w-full border-[1px] py-[8px] px-5 rounded-[8px] min-w-[150px] border-${currentMode}-840 bg-${currentMode}-trans text-${currentMode}-copy`}
                                                            name="cost_center"
                                                            type="text"
                                                            placeholder="Cost center"
                                                            maxLength={20}
                                                            value={
                                                                elem?.cost_centre
                                                            }
                                                            onChange={(
                                                                event
                                                            ) => {
                                                                handleCostCenterChange(
                                                                    {
                                                                        target: {
                                                                            name: event
                                                                                .target
                                                                                .name,

                                                                            value: event
                                                                                .target
                                                                                .value,
                                                                        },
                                                                    },

                                                                    elem?.accrual_id,

                                                                    index
                                                                );
                                                            }}
                                                        />
                                                    </td>

                                                    <td>
                                                        <textarea
                                                            className={`border-[1px] focus-within:outline-0 px-4 w-[210px] py-[4px] border620 border-${currentMode}-840 bg-${currentMode}-trans text-${currentMode}-copy rounded-[8px]  resize-none`}
                                                            name="account_description"
                                                            value={
                                                                elem?.account_description
                                                            }
                                                            placeholder="Max. 250 characters"
                                                            maxLength={250}
                                                            onChange={(
                                                                event
                                                            ) => {
                                                                handleDescriptionChnage(
                                                                    {
                                                                        target: {
                                                                            name: event
                                                                                .target
                                                                                .name,

                                                                            value: event
                                                                                .target
                                                                                .value,
                                                                        },
                                                                    },

                                                                    elem?.accrual_id,

                                                                    index
                                                                );
                                                            }}
                                                        />
                                                    </td>

                                                    <td>
                                                        <FormControl
                                                            sx={{
                                                                minWidth: 150,
                                                            }}
                                                        >
                                                            <Select
                                                                value={
                                                                    elem?.amount_type
                                                                }
                                                                name="amount_type"
                                                                onChange={(
                                                                    event
                                                                ) => {
                                                                    handleAmountChange(
                                                                        {
                                                                            target: {
                                                                                name: event
                                                                                    .target
                                                                                    .name,
                                                                                value: event
                                                                                    .target
                                                                                    .value,
                                                                            },
                                                                        },
                                                                        elem?.accrual_id,
                                                                        index
                                                                    );
                                                                }}
                                                                inputProps={{
                                                                    "aria-label":
                                                                        "Without label",
                                                                }}
                                                                IconComponent={() => (
                                                                    <img
                                                                        className="absolute right-[15px] opacity-[.5] pointer-events-none"
                                                                        src={
                                                                            currentMode !==
                                                                                "dark"
                                                                                ? `/images/header/chevronDown.svg`
                                                                                : `/images/header/chevronDown-white.svg`
                                                                        }
                                                                        alt="chevronDown"
                                                                    />
                                                                )}
                                                                MenuProps={{
                                                                    sx: {
                                                                        "& .MuiMenu-paper":
                                                                        {
                                                                            backgroundColor:
                                                                                dropdownColor,
                                                                            color: dropdownText,
                                                                        },

                                                                        "& .MuiMenuItem-root:hover":
                                                                        {
                                                                            backgroundColor:
                                                                                "rgba(218, 249, 244, 0.3)",
                                                                        },

                                                                        "& .Mui-selected":
                                                                        {
                                                                            backgroundColor:
                                                                                "rgba(218, 249, 244, 0.3) !important",
                                                                        },
                                                                    },
                                                                }}
                                                                SelectDisplayProps={{
                                                                    style: {
                                                                        paddingTop:
                                                                            "9px",

                                                                        paddingBottom:
                                                                            "9px",

                                                                        color: `${currentMode !==
                                                                            "dark"
                                                                            ? `#555555`
                                                                            : `#ECECEC`
                                                                            }`,

                                                                        paddingRight:
                                                                            "30px",

                                                                        border: "1px solid rgba(85,85,85,0.4)",

                                                                        borderRadius:
                                                                            "8px",
                                                                    },
                                                                }}
                                                            >
                                                                <MenuItem
                                                                    key={0}
                                                                    name="amount_type"
                                                                    value={0}
                                                                >
                                                                    {"Select"}
                                                                </MenuItem>

                                                                <MenuItem
                                                                    key={1}
                                                                    name="amount_type"
                                                                    value={1}
                                                                >
                                                                    {
                                                                        "Net Amount"
                                                                    }
                                                                </MenuItem>

                                                                <MenuItem
                                                                    key={2}
                                                                    name="amount_type"
                                                                    value={2}
                                                                >
                                                                    {
                                                                        "Tax Amount"
                                                                    }
                                                                </MenuItem>

                                                                <MenuItem
                                                                    key={3}
                                                                    name="amount_type"
                                                                    value={3}
                                                                >
                                                                    {
                                                                        "Gross Amount"
                                                                    }
                                                                </MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </td>

                                                    <td className="text-center">
                                                        <Button
                                                            disabled={
                                                                ListData?.length ==
                                                                1
                                                            }
                                                            title="To Delete"
                                                            onClick={() =>
                                                                tableRowRemove(
                                                                    elem?.accrual_id,
                                                                    index
                                                                )
                                                            }
                                                        >
                                                            <img
                                                                className="convertWhite"
                                                                src="/images/common/remove-icon.svg"
                                                                alt="plus-circle"
                                                            />
                                                        </Button>
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                                <Button
                                    onClick={() => addRowTable()}
                                    className="text-light-20 mb-5"
                                    title="Add Row"
                                    id="AddRow"
                                >
                                    <div className="">+ Add New</div>
                                </Button>
                            </>
                        )}
                    </Card>
                    <div
                        className={`bg-${currentMode}-card text-${currentMode}-copy border-${currentMode}-700 py-[20px] space-x-2 px-[30px] sticky left-0 right-0 shadow-[0px_4px_20px_rgba(85,85,85,0.2)] rounded-b-[10px]`}
                    >
                        <Button
                            className="bg-light-20 text-white text-base rounded-[8px] py-2 px-7"
                            onClick={InsertAccrual}
                            disabled={orgList == 0 || fetching}
                            title={"Save"}
                        >
                            {"Save"}
                        </Button>
                        <Link
                            to="/admin-setting"
                            title="Cancel"
                        >
                            <Button  className="bg-light-240 text-light-40 text-base rounded-[8px] py-2 px-7">
                            Cancel
                            </Button>
                        </Link>
                    </div>
                </Main>
                <Footer />
            </Layout>
        </>
    );
};

export default AccrualAccounting;
