import { FormControlLabel, Checkbox } from "@mui/material";
import React, { useEffect, useContext, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import Button from "../../../../components/Button";
import Input from "../../../../components/Input";
import Card from "../../../../components/UI/Card";
import Main from "../../../../components/UI/Main";
import Layout from "../../../../Hoc/Layout";
import Footer from "../../../../organisms/Footer";
import { useDispatch, useSelector } from "react-redux";
import { AddRolePermission, ViewRolePermission } from "../../../../redux/actions/role";
import SuccessMessageBox from "../../../../components/UI/Alert/sucess";
import MessageBox from "../../../../components/UI/Alert";
import Loader from "../../../../components/Loader";
import { ROLE_PERMISSION_VIEW_RESET } from "../../../../redux/types/types";
import Swal from "sweetalert2";
import { ThemeContext } from "../../../../Hooks/Themes/Themeprovider";
import { getCurrentUser } from "../../../../redux/actions/auth";

const NewRole = () => {
    const { currentMode, toggleMode } = useContext(ThemeContext);
    const succesMsg = "Role is updated successfully";
    const addMsg = "Role is created successfully";
    const errormsg = "Something went wrong please contact admin";
    const dispatch = useDispatch();
    const [errormsgs, seterrormsg] = useState({
        message: "",
    });
    const [descriptionError, setDescriptionError] = useState({
        message: "",
    });
    const [disabled, setDisabled] = useState(true);

    const location = useLocation();
    const ParamsRoleId = location?.search?.replace("?roleid=", "");
    const RoleIDINt = parseInt(ParamsRoleId);
    const CloneRoleId = location?.search?.replace("?Clone-roleid=", "");

    const RoleViewList = useSelector((state) => state?.ViewRolePermissionStateAction);
    const { data: RoleViews } = RoleViewList;
    const RoleView = RoleViews?.[0];

    const RoleList = RoleView?.role_info;
    const RoleMenu = RoleList?.menu;
    const Roleorg = RoleMenu?.find((elem) => elem?.menu_id === 1);
    const OrgID = Roleorg?.identity_id;
    const EmailConfigID = RoleMenu?.find((elem) => elem?.menu_id === 2)?.identity_id;

    const FoderConfigID = RoleMenu?.find((elem) => elem?.menu_id === 3)?.identity_id;

    const TargetConfigID = RoleMenu?.find((elem) => elem?.menu_id === 4)?.identity_id;

    const UserRoleID = RoleMenu?.find((elem) => elem?.menu_id === 5)?.identity_id;

    const UserID = RoleMenu?.find((elem) => elem?.menu_id === 6)?.identity_id;

    const OrgRoleID = RoleMenu?.find((elem) => elem?.menu_id === 7)?.identity_id;

    const ProcessInvId = RoleMenu?.find((elem) => elem?.menu_id === 8)?.identity_id;

    const ExceptionID = RoleMenu?.find((elem) => elem?.menu_id === 9)?.identity_id;

    const ReportsID = RoleMenu?.find((elem) => elem?.menu_id === 10)?.identity_id;

    const DashboardID = RoleMenu?.find((elem) => elem?.menu_id === 11)?.identity_id;

    const ManualID = RoleMenu?.find((elem) => elem?.menu_id === 12)?.identity_id;

    const AccrualID = RoleMenu?.find((elem) => elem?.menu_id === 13)?.identity_id;

    const InvoiceReportID = RoleMenu?.find((elem) => elem?.menu_id === 14)?.identity_id;

    const ReportAgeingID = RoleMenu?.find((elem) => elem?.menu_id === 15)?.identity_id;

    const ApprovalID = RoleMenu?.find((elem) => elem?.menu_id === 16)?.identity_id;

    const AccrualPostingID = RoleMenu?.find((elem) => elem?.menu_id === 17)?.identity_id;

    const TaskReportsId = RoleMenu?.find((elem) => elem?.menu_id === 18)?.identity_id;

    const MonthClosingID = RoleMenu?.find((elem) => elem?.menu_id === 19)?.identity_id;

    const FindAdvance = RoleMenu?.find((elem) => elem?.menu_id === 21)?.identity_id;
    const FindMisc = RoleMenu?.find((elem) => elem?.menu_id === 22)?.identity_id;
    const FindRetention = RoleMenu?.find((elem) => elem?.menu_id === 23)?.identity_id;
    const AdvanceReportID = RoleMenu?.find((elem) => elem?.menu_id === 24)?.identity_id;
    const MiscReportID = RoleMenu?.find((elem) => elem?.menu_id === 25)?.identity_id;
    const RetentionReportID = RoleMenu?.find((elem) => elem?.menu_id === 26)?.identity_id;
    const ExchangeRateID = RoleMenu?.find((elem) => elem?.menu_id === 27)?.identity_id;

    const ShowPaymentReq = FindAdvance || FindMisc || FindRetention;

    const [roleName, setroleName] = useState("");
    const [isIndeterminate, setIndeterminate] = useState(false);
    const [description, setDescription] = useState("");

    const [orgSelectAll, setOrgSelectAll] = useState(false);
    const [userSelectAll, setUserSelectAll] = useState(false);
    const [invoiceSelectAll, setInvoiceSelectAll] = useState(false);

    const [reportsSelectAll, setreportsSelectAll] = useState(false);
    const [paymnetSelectAll, setpaymentSelectAll] = useState(false);
    const RoleActOrg = Roleorg?.actions;
    const [roleid, getQueryStringVal] = useState("");
    let RoleID;
    const paramsd = new URLSearchParams(window.location.search);
    let ViewRoleId = paramsd.get("View-roleid");
    RoleID = paramsd.get("roleid");

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        RoleID = params.get("roleid");
        let CloneRoleId = params.get("Clone-roleid");
        let ViewRoleId = params.get("View-roleid");
        getQueryStringVal(RoleID);
        // if (CloneRoleId !== null || ViewRoleId !== null || RoleID !== null) {
        dispatch(
            ViewRolePermission({
                roleid: RoleID == null && !ViewRoleId && !CloneRoleId ? 0 : RoleID ? RoleID : CloneRoleId ? CloneRoleId : ViewRoleId,
            })
        );
        // }
    }, [dispatch]);

    let updatedRoleList = [];
    let updatedEmailList = [];
    let updatedFolderList = [];
    let updatedTargetList = [];
    let updatedUserRoleList = [];
    let updatedUserList = [];
    let updatedOrgRoleList = [];
    let updatedProcessList = [];
    let updatedExceptionList = [];
    let updateApproveList = [];
    let updateManulUploadList = [];
    let updateReportLit = [];
    let updateDashboardList = [];
    let updateAccrualPosting = [];
    let updateacrualReport = [];
    let updateInvoicereport = [];
    let updatereportsAgeing = [];
    let updateTaskreport = [];
    let updateAdvreport = [];
    let updateMiscReort = [];
    let updateRetenReport = [];
    let updateMonthClosing = [];
    let updateExchangeRate = [];
    let updateAdvanceRequestList = [];
    let updateMiscList = [];
    let updateRetentionList = [];

    useEffect(() => {
        const updateListData = async () => {
            const params = new URLSearchParams(window.location.search);
            if (params.get("roleid") || params.get("View-roleid")) {
                setroleName(RoleView.role_name);

                setDescription(RoleView.role_description);
            }
            if (params.get("roleid") || params.get("View-roleid") || params.get("Clone-roleid")) {
                if (updatedRoleList.length === 0 && RoleMenu?.length > 0) {
                    updateRoleItems();
                    const updateFullAcess = updatedRoleList?.find((item) => item.isallowed === false && item.id !== 12 && item.id !== 0);
                    if (!updateFullAcess) {
                        updatedRoleList[0].isallowed = true;
                    }
                    setorgAct([...updatedRoleList]);
                }
                if (updatedExceptionList.length === 0 && RoleMenu?.length > 0) {
                    updateExceptionItems();
                    const updateFullAcess = updatedExceptionList?.find(
                        (item) => item.isallowed === false && item.id !== 15 && item.id !== 16 && item.id !== 17 && item.id !== 0
                    );
                    if (!updateFullAcess) {
                        updatedExceptionList[0].isallowed = true;
                    }
                    setExceptionAct([...updatedExceptionList]);
                }
                if (updateApproveList.length === 0 && RoleMenu?.length > 0) {
                    upadteApproveArrayItems();
                    const updateFullAcess = updateApproveList?.find(
                        (item) => item.isallowed === false && item.id !== 15 && item.id !== 16 && item.id !== 17 && item.id !== 0 && item.id !== 2
                    );
                    if (!updateFullAcess) {
                        updateApproveList[0].isallowed = true;
                    }
                    setApprovalAct([...updateApproveList]);
                }
                if (updatedProcessList.length === 0 && RoleMenu?.length > 0) {
                    updateProcessArrayitems();
                    const updateFullAcess = updatedProcessList.find(
                        (item) => item.isallowed === false && item.id !== 15 && item.id !== 16 && item.id !== 17 && item.id !== 0
                    );
                    if (!updateFullAcess) {
                        updatedProcessList[0].isallowed = true;
                    }
                    setProcessAct([...updatedProcessList]);
                }
                if (updatedOrgRoleList.length === 0 && RoleMenu?.length > 0) {
                    updateOrgArrayItems();
                    const updateFullAcess = updatedOrgRoleList.find(
                        (item) => item.isallowed === false && item.id !== 4 && item.id !== 5 && item.id !== 0
                    );
                    if (!updateFullAcess) {
                        updatedOrgRoleList[0].isallowed = true;
                    }
                    setOrganisationRoleAct([...updatedOrgRoleList]);
                }
                if (updatedUserList.length === 0 && RoleMenu?.length > 0) {
                    updateUserArrayItems();
                    const updateFullAcess = updatedUserList.find((item) => item.isallowed === false && item.id !== 12 && item.id !== 0);
                    if (!updateFullAcess) {
                        updatedUserList[0].isallowed = true;
                    }
                    setuserAct([...updatedUserList]);
                }

                if (updatedUserRoleList.length === 0 && RoleMenu?.length > 0) {
                    updateUserRoleArrayItems();
                    const updateFullAcess = updatedUserRoleList.find(
                        (item) => item.isallowed === false && item.id !== 4 && item.id !== 5 && item.id !== 0
                    );
                    if (!updateFullAcess) {
                        updatedUserRoleList[0].isallowed = true;
                    }
                    setuserRole([...updatedUserRoleList]);
                }

                if (updatedTargetList.length === 0 && RoleMenu?.length > 0) {
                    updateTargetArrayItems();
                    const updateFullAcess = updatedTargetList.find((item) => item.isallowed === false && item.id !== 12 && item.id !== 0);
                    if (!updateFullAcess) {
                        updatedTargetList[0].isallowed = true;
                    }
                    setTargetAct([...updatedTargetList]);
                }

                if (updatedEmailList.length === 0 && RoleMenu?.length > 0) {
                    updateEmailArrayItems();
                    const updateFullAcess = updatedEmailList.find((item) => item.isallowed === false && item.id !== 12 && item.id !== 0);
                    if (!updateFullAcess) {
                        updatedEmailList[0].isallowed = true;
                    }
                    setemailAct([...updatedEmailList]);
                }

                if (updatedFolderList.length === 0 && RoleMenu?.length > 0) {
                    updateFolderArrayItems();
                    const updateFullAcess = updatedFolderList.find((item) => item.isallowed === false && item.id !== 12 && item.id !== 0);
                    if (!updateFullAcess) {
                        updatedFolderList[0].isallowed = true;
                    }
                    setfolderAct([...updatedFolderList]);
                }
                if (updateManulUploadList.length === 0 && RoleMenu?.length > 0) {
                    updateManulUploadItems();
                    setUplaodAct([...updateManulUploadList]);
                }

                if (updateDashboardList.length == 0 && RoleMenu?.length > 0) {
                    updateDashboardItems();
                    setDashboardAct([...updateDashboardList]);
                }
                if (updateAccrualPosting.length == 0 && RoleMenu?.length > 0) {
                    updateAccrualPostingItems();
                    setAccrualAct([...updateAccrualPosting]);
                }
                if (updateMonthClosing?.length == 0 && RoleMenu?.length > 0) {
                    updateMonthClosingItems();
                    setMonthEndAct([...updateMonthClosing]);
                }
                if (updateExchangeRate?.length == 0 && RoleMenu?.length > 0) {
                    ExchangeRateItems();
                    setExchangeRateAct([...updateExchangeRate]);
                }

                if (updateacrualReport.length == 0 && RoleMenu?.length > 0) {
                    updateacrualreportItems();
                    setaccrualAct([...updateacrualReport]);
                }
                if (updateInvoicereport.length == 0 && RoleMenu?.length > 0) {
                    updateInvoicereportItems();
                    setInvreportAct([...updateInvoicereport]);
                }
                if (updatereportsAgeing.length == 0 && RoleMenu?.length > 0) {
                    updatereportsAgeingItems();
                    setreportAgeAct([...updatereportsAgeing]);
                }
                if (updateTaskreport.length == 0 && RoleMenu?.length > 0) {
                    upadateTaskReports();
                    settaskReportAct([...updateTaskreport]);
                }
                if (updateAdvreport.length == 0 && RoleMenu?.length > 0) {
                    updateAdvReports();
                    setadvReportAct([...updateAdvreport]);
                }
                if (updateMiscReort.length == 0 && RoleMenu?.length > 0) {
                    updateMiscReports();
                    setMiscReportAct([...updateMiscReort]);
                }
                if (updateRetenReport.length == 0 && RoleMenu?.length > 0) {
                    updateRetenReports();
                    setRetentionReportAct([...updateRetenReport]);
                }
                if (updateAdvanceRequestList?.length == 0 && RoleMenu?.length > 0) {
                    updateAdvanceReqItems();
                    setAdvanceAct([...updateAdvanceRequestList]);
                }
                if (updateMiscList?.length == 0 && RoleMenu?.length > 0) {
                    updateMiscItems();
                    setMiscAct([...updateMiscList]);
                }
                if (updateRetentionList?.length == 0 && RoleMenu?.length > 0) {
                    updateRetentionItems();
                    setRetentionAct([...updateRetentionList]);
                }
                setOrgSelectAll(checkAllOrgSelected(updatedRoleList, updatedEmailList, updatedFolderList, updatedTargetList, updatedUserRoleList));
                setUserSelectAll(checkAllUserSelected(updatedUserList, updatedOrgRoleList));
                setInvoiceSelectAll(checkAllInvoceSelected(updatedProcessList, updatedExceptionList, updateApproveList));
                setpaymentSelectAll(checkAllPaymentSelected(updateAdvanceRequestList, updateMiscList, updateRetentionList));
            }
        };
        updateListData();
    }, [RoleView]);
    const orgManagement = process.env.REACT_APP_ROLE_ORG_PERMISSION;
    const orgObject = JSON.parse(orgManagement);
    const orgAction = orgObject?.map((elem) => elem?.organisation);
    const organisationAct = orgAction?.map((elem) => elem?.org);
    const orgHeader = [orgAction?.[0]];
    const orgArrayAct = organisationAct?.[0];
    const EmailAction = orgObject?.map((elem) => elem?.Email);
    const EmailAct = EmailAction?.map((elem) => elem?.email);
    const emailArrayAct = EmailAct?.[1];
    const emailHeader = [EmailAction?.[1]];
    const FolderAction = orgObject?.map((elem) => elem?.Folder);
    const FolderAct = FolderAction?.map((elem) => elem?.folder);
    const folderArrayAct = FolderAct?.[2];
    const folderHeader = [FolderAction?.[2]];
    const TargetAction = orgObject?.map((elem) => elem?.Target);
    const TargetAct = TargetAction?.map((elem) => elem?.Target);
    const TargetArrayAct = TargetAct?.[3];
    const TargetHeader = [TargetAction?.[3]];
    const userRoleAction = orgObject?.map((elem) => elem?.UserRole);
    const UserRoleAct = userRoleAction?.map((elem) => elem?.userRole);
    const userRoleArrayAct = UserRoleAct?.[4];
    const userRoleHeader = [userRoleAction?.[4]];

    const userManagement = process.env.REACT_APP_ROLE_USER_PERMISSION;
    const userObject = JSON.parse(userManagement);
    const userAction = userObject?.map((elem) => elem?.user);
    const userAct = userAction?.map((elem) => elem?.user);
    const userArrayAct = userAct?.[0];
    const userHeader = [userAction?.[0]];
    const OrgRoleAction = userObject?.map((elem) => elem?.OrganisationRole);
    const orgRoleAct = OrgRoleAction?.map((elem) => elem?.orgRole);
    const orgRoleArrayAct = orgRoleAct?.[1];
    const orgRoleHeader = [OrgRoleAction?.[1]];

    const invoiceManagement = process.env.REACT_APP_ROLE_INVOICE_PERMISSION;
    const invObject = JSON.parse(invoiceManagement);
    const UplaodAction = invObject?.map((elem) => elem?.ManualUpload);
    const UploadAct = UplaodAction?.map((elem) => elem?.ManualUpload);
    const UploadArrayAct = UploadAct?.[0];
    const UploadHeader = [UplaodAction?.[0]];

    const ProcessAction = invObject?.map((elem) => elem?.Invoice);
    const ProcessAct = ProcessAction?.map((elem) => elem?.Process);
    const ProcessArrayAct = ProcessAct?.[1];
    const ProcessHeader = [ProcessAction?.[1]];

    const exceptionAction = invObject?.map((elem) => elem?.Exception);
    const ExceptionAct = exceptionAction?.map((elem) => elem?.Exception);
    const exceptionArrayAct = ExceptionAct?.[2];
    const ExceptionHeader = [exceptionAction?.[2]];

    const approvalAction = invObject?.map((elem) => elem?.Approval);
    const ApprovalAct = approvalAction?.map((elem) => elem?.Approval);
    const approvalArrayAct = ApprovalAct?.[3];
    const approvalHeader = [approvalAction?.[3]];

    const ManualManagement = process.env.REACT_APP_ROLE_MANUAL_DASHBOARD_PERMISSION;
    const reportObject = JSON.parse(ManualManagement);
    const ManualAction = reportObject?.map((elem) => elem?.ManualUpload);
    const ManualUploadAction = ManualAction?.map((elem) => elem?.ManualUpload);
    const ManualArrayAct = ManualUploadAction?.[0];
    const ManualHeader = [ManualAction?.[0]];

    const DashboardAction = reportObject?.map((elem) => elem?.Dashboard);
    const dashboardsAction = DashboardAction?.map((elem) => elem?.dashboard);
    const dashboardArrayAct = dashboardsAction?.[2];
    const dashboardHeader = [DashboardAction?.[2]];

    const AccrulAccountingAction = reportObject?.map((elem) => elem?.AccrualPosting);
    const AccrualAction = AccrulAccountingAction?.map((elem) => elem?.AccrualPosting);
    const accrualArrayAct = AccrualAction?.[3];
    const accrualHeader = [AccrulAccountingAction?.[3]];

    const MonthClosingAction = reportObject?.map((elem) => elem?.MonthClosing);
    const MonthEndAction = MonthClosingAction?.map((elem) => elem?.MonthClosing);
    const MonthEndArrayAct = MonthEndAction?.[4];
    const MonthEndHeader = [MonthClosingAction?.[4]];

    const ExchangeRateAction = reportObject?.map((elem) => elem?.ExchangeRate);
    const ExchangeRatearrayAction = ExchangeRateAction?.map((elem) => elem?.ExchangeRate);
    const ExchangeRateArrayAct = ExchangeRatearrayAction?.[5];
    const ExchangeRateHeader = [ExchangeRateAction?.[5]];

    const ReportManagemet = process.env.REACT_APP_ROLE_REPORTS_PERMISSION;
    const report = JSON.parse(ReportManagemet);

    const accrualreportAction = report?.map((elem) => elem?.AccrualReport);
    const accrualreportUploadAction = accrualreportAction?.map((elem) => elem?.AccrualReport);
    const accrualreportArrayAct = accrualreportUploadAction?.[0];
    const accrualreportHeader = [accrualreportAction?.[0]];

    const InvoicereportAction = report?.map((elem) => elem?.InvoiceReport);
    const InvoicereportUploadAction = InvoicereportAction?.map((elem) => elem?.InvoiceReport);
    const InvoicereportArrayAct = InvoicereportUploadAction?.[1];
    const InvoicereportHeader = [InvoicereportAction?.[1]];

    const reportAgeAction = report?.map((elem) => elem?.ReportsAgeing);
    const reportAgeUploadAction = reportAgeAction?.map((elem) => elem?.ReportsAgeing);
    const reportAgeArrayAct = reportAgeUploadAction?.[2];
    const reportAgeHeader = [reportAgeAction?.[2]];

    const tasReportAction = report?.map((elem) => elem?.TaskReports);
    const taskReportUploadAction = tasReportAction?.map((elem) => elem?.TaskReports);
    const taskReportArrayAct = taskReportUploadAction?.[3];
    const taskReportHeader = [tasReportAction?.[3]];

    const AdvReportAction = report?.map((elem) => elem?.AdvanceReport);
    const AdvReportUploadAction = AdvReportAction?.map((elem) => elem?.AdvanceReport);
    const AdvReportArrayAct = AdvReportUploadAction?.[4];
    const AdvReportHeader = [AdvReportAction?.[4]];

    const MiscReportAction = report?.map((elem) => elem?.MiscReports);
    const MiscReportUploadAction = MiscReportAction?.map((elem) => elem?.MiscReports);
    const MiscReportArrayAct = MiscReportUploadAction?.[5];
    const MiscReportHeader = [MiscReportAction?.[5]];

    const RetenReportAction = report?.map((elem) => elem?.RetentionReports);
    const retenReportUploadAction = RetenReportAction?.map((elem) => elem?.RetentionReports);
    const retenReportArrayAct = retenReportUploadAction?.[6];
    const retenReportHeader = [RetenReportAction?.[6]];

    const PaymentManagement = process.env.REACT_APP_PAYMENT_PERMISSION;
    const PaymentObject = JSON.parse(PaymentManagement);
    const AdvanceAction = PaymentObject?.map((elem) => elem?.Advance);
    const AdvanceAct = AdvanceAction?.map((elem) => elem?.Advance);
    const AdvanceArrayAct = AdvanceAct?.[0];
    const AdvanceHeader = [AdvanceAction?.[0]];
    const MiscAction = PaymentObject?.map((elem) => elem?.Misc);
    const MiscAct = MiscAction?.map((elem) => elem?.Misc);
    const MiscArrayAct = MiscAct?.[1];
    const MiscHeader = [MiscAction?.[1]];
    const RetentionAction = PaymentObject?.map((elem) => elem?.Retention);
    const RetentionAct = RetentionAction?.map((elem) => elem?.Retention);
    const RetentionArrayAct = RetentionAct?.[2];
    const RetentionHeader = [RetentionAction?.[2]];

    const [orgAct, setorgAct] = useState(orgArrayAct);
    const [emailACt, setemailAct] = useState(emailArrayAct);
    const [folderAct, setfolderAct] = useState(folderArrayAct);
    const [targetAct, setTargetAct] = useState(TargetArrayAct);
    const [userRoleAct, setuserRole] = useState(userRoleArrayAct);
    const [userActions, setuserAct] = useState(userArrayAct);
    const [organisationRoleAct, setOrganisationRoleAct] = useState(orgRoleArrayAct);
    const [processAct, setProcessAct] = useState(ProcessArrayAct);
    const [uploadAct, setUplaodAct] = useState(UploadArrayAct);
    const [exceptionAct, setExceptionAct] = useState(exceptionArrayAct);
    const [approvalAct, setApprovalAct] = useState(approvalArrayAct);
    const [manualAct, setManualAct] = useState(ManualArrayAct);
    const [dashboardAct, setDashboardAct] = useState(dashboardArrayAct);
    const [accrualPostAct, setAccrualAct] = useState(accrualArrayAct);
    const [accrualAct, setaccrualAct] = useState(accrualreportArrayAct);
    const [InvReportAct, setInvreportAct] = useState(InvoicereportArrayAct);
    const [reportAgeAct, setreportAgeAct] = useState(reportAgeArrayAct);
    const [taskReportAct, settaskReportAct] = useState(taskReportArrayAct);
    const [advReportAct, setadvReportAct] = useState(AdvReportArrayAct);
    const [miscReportAct, setMiscReportAct] = useState(MiscReportArrayAct);
    const [retentionReportAct, setRetentionReportAct] = useState(retenReportArrayAct);
    const [MonthEndAct, setMonthEndAct] = useState(MonthEndArrayAct);
    const [advanceAct, setAdvanceAct] = useState(AdvanceArrayAct);
    const [miscAct, setMiscAct] = useState(MiscArrayAct);
    const [retentionAct, setRetentionAct] = useState(RetentionArrayAct);
    const [exchangeRateAct, setExchangeRateAct] = useState(ExchangeRateArrayAct);
    const morePermissions = processAct?.filter((el) => el?.id == 18 || el?.id == 13 || el?.id == 26 || el?.id == 27);
    const ApprovalPermissions = approvalAct?.filter((el) => el?.id == 18 || el?.id == 13);

    const orgFilter = orgAct?.filter((el) => el?.id == 3 && el?.isallowed == true);
    const emailFilter = emailACt?.filter((el) => el?.id == 3 && el?.isallowed == true);
    const folderFilter = folderAct?.filter((el) => el?.id == 3 && el?.isallowed == true);
    const targetFilter = targetAct?.filter((el) => el?.id == 3 && el?.isallowed == true);
    const UserRoleFilter = userRoleAct?.filter((el) => el?.id == 3 && el?.isallowed == true);
    const UserFilter = userActions?.filter((el) => el?.id == 3 && el?.isallowed == true);
    const OrgRoleFilter = organisationRoleAct?.filter((el) => el?.id == 3 && el?.isallowed == true);
    const processFilter = processAct?.filter((el) => el?.id == 3 && el?.isallowed == true);
    const ExceptionFilter = exceptionAct?.filter((el) => el?.id == 3 && el?.isallowed == true);
    const ApproveFilter = approvalAct?.filter((el) => el?.id == 3 && el?.isallowed == true);
    const AdvanceFilter = advanceAct?.filter((el) => el?.id == 3 && el?.isallowed == true);
    const MiscFilter = miscAct?.filter((el) => el?.id == 3 && el?.isallowed == true);
    const retentionFilter = retentionAct?.filter((el) => el?.id == 3 && el?.isallowed == true);
    const OrgActFull = orgAct?.filter((el) => el?.id !== 12 && el?.id !== 0);
    const OrgFullAccess = OrgActFull?.some((el) => el?.isallowed == false);
    const OrgMinusFullAccess = OrgActFull?.every((el) => el?.isallowed == true);
    const EmailActFull = emailACt?.filter((el) => el?.id !== 12 && el?.id !== 0 && el?.id !== 2);
    const emailFullAccess = EmailActFull?.some((el) => el?.isallowed == false);
    const emailMinusFullAccess = EmailActFull?.every((el) => el?.isallowed == true);
    const FolderActFull = folderAct?.filter((el) => el?.id !== 12 && el?.id !== 0 && el?.id !== 2);
    const folderFullAccess = FolderActFull?.some((el) => el?.isallowed == false);
    const folderMinusFullAccess = FolderActFull?.every((el) => el?.isallowed == true);
    const targetActFull = targetAct?.filter((el) => el?.id !== 12 && el?.id !== 0);
    const targetFullAccess = targetActFull?.some((el) => el?.isallowed == false);
    const targetMinusFullAccess = targetActFull?.every((el) => el?.isallowed == true);
    const userRoleActFull = userRoleAct?.filter((el) => el?.id !== 4 && el?.id !== 0 && el?.id !== 2 && el?.id !== 5);
    const userRoleFullAccess = userRoleActFull?.some((el) => el?.isallowed == false);
    const userRoleMinusFullAccess = userRoleActFull?.every((el) => el?.isallowed == true);
    const UserActFull = userActions?.filter((el) => el?.id !== 12 && el?.id !== 0);
    const userFullAccess = UserActFull?.some((el) => el?.isallowed == false);
    const userMinusFullAccess = UserActFull?.every((el) => el?.isallowed == true);
    const OrgRoleActFull = organisationRoleAct?.filter((el) => el?.id !== 2 && el?.id !== 0 && el?.id !== 4 && el?.id !== 5 && el?.id !== 3);
    const OrgRoleFullAccess = OrgRoleActFull?.some((el) => el?.isallowed == false);
    const OrgRoleMinusFullAccess = OrgRoleActFull?.every((el) => el?.isallowed == true);
    const processRoleActFull = processAct?.filter((el) => el?.id !== 0 && el?.id !== 6);
    const processFullAccess = processRoleActFull?.some((el) => el?.isallowed == false);
    const processMinusFullAccess = processRoleActFull?.every((el) => el?.isallowed == true);
    const eceptionRoleActFull = exceptionAct?.filter((el) => el?.id !== 0 && el?.id !== 6);
    const exceptionFullAccess = eceptionRoleActFull?.some((el) => el?.isallowed == false);
    const exceptionMinusFullAccess = eceptionRoleActFull?.every((el) => el?.isallowed == true);
    const approvalRoleActFull = approvalAct?.filter((el) => el?.id !== 0 && el?.id !== 2);
    const aprovalFullAccess = approvalRoleActFull?.some((el) => el?.isallowed == false);
    const approvalMinusFullAccess = approvalRoleActFull?.every((el) => el?.isallowed == true);

    const advanceRoleActFull = advanceAct?.filter((el) => el?.id !== 0);
    const advanceFullAccess = advanceRoleActFull?.some((el) => el?.isallowed == false);
    const advanceMinusFullAccess = advanceRoleActFull?.every((el) => el?.isallowed == true);

    const MiscRoleActFull = miscAct?.filter((el) => el?.id !== 0);
    const MiscFullAccess = MiscRoleActFull?.some((el) => el?.isallowed == false);
    const miscMinusFullAccess = MiscRoleActFull?.every((el) => el?.isallowed == true);

    const retentionRoleActFull = retentionAct?.filter((el) => el?.id !== 0);
    const retentionFullAccess = retentionRoleActFull?.some((el) => el?.isallowed == false);
    const retentionMinusFullAccess = retentionRoleActFull?.every((el) => el?.isallowed == true);

    const uploadRoleActFull = uploadAct?.filter((el) => el?.id == 21);
    const uploadFullAccess = uploadRoleActFull?.some((el) => el?.isallowed == false);
    const uploadMinusFullAccess = uploadRoleActFull?.every((el) => el?.isallowed == true);

    const accrualMinus = accrualAct?.every((el) => el?.isallowed == true);
    const invreportMinus = InvReportAct?.every((el) => el?.isallowed == true);
    const reportAgeMinus = reportAgeAct?.every((el) => el?.isallowed == true);
    const taskReportMinus = taskReportAct?.every((el) => el?.isallowed == true);
    const AdvanceReportMinus = advReportAct?.every((el) => el?.isallowed == true);
    const MiscReportMinus = miscReportAct?.every((el) => el?.isallowed == true);
    const retenReportMinus = retentionReportAct?.every((el) => el?.isallowed == true);

    const SelectAllOrg = OrgMinusFullAccess && emailMinusFullAccess && folderMinusFullAccess && targetMinusFullAccess && userRoleMinusFullAccess;
    const SelectAllUser = userMinusFullAccess && OrgRoleMinusFullAccess;
    const SelectAllInvoice = processMinusFullAccess && approvalMinusFullAccess && uploadMinusFullAccess;
    const SelectAllReports =
        accrualMinus && invreportMinus && reportAgeMinus && taskReportMinus && AdvanceReportMinus && MiscReportMinus && retenReportMinus;
    const SelectAllPaymentReq = advanceMinusFullAccess && miscMinusFullAccess && retentionMinusFullAccess;

    useEffect(() => {
        // const merged = processAct?.concat(morePermissions)
        // setProcessAct(merged)

        if (SelectAllOrg) {
            setOrgSelectAll(true);
        } else if (!SelectAllOrg) {
            setOrgSelectAll(false);
        }
        if (SelectAllUser) {
            setUserSelectAll(true);
        } else if (!SelectAllUser) {
            setUserSelectAll(false);
        }
        if (SelectAllInvoice) {
            setInvoiceSelectAll(true);
        } else if (!SelectAllInvoice) {
            setInvoiceSelectAll(false);
        }
        if (SelectAllReports) {
            setreportsSelectAll(true);
        } else if (!SelectAllReports) {
            setreportsSelectAll(false);
        }
        if (SelectAllPaymentReq) {
            setpaymentSelectAll(true);
        } else if (!SelectAllPaymentReq) {
            setpaymentSelectAll(false);
        }
    }, [SelectAllOrg, SelectAllUser, SelectAllInvoice, SelectAllReports, SelectAllPaymentReq]);
    orgAct.forEach((element) => {
        if (orgFilter?.length > 0) {
            element.isactive = false;
        }
        if (OrgFullAccess && element?.id == 0) {
            element.isallowed = false;
        }

        if (OrgMinusFullAccess && element?.id == 0) {
            element.isallowed = true;
        }
    });
    emailACt.forEach((element) => {
        if (emailFilter?.length > 0) {
            element.isactive = false;
        }
        if (emailFullAccess && element?.id == 0) {
            element.isallowed = false;
        }

        if (emailMinusFullAccess && element?.id == 0) {
            element.isallowed = true;
        }
    });
    folderAct.forEach((element) => {
        if (folderFilter?.length > 0) {
            element.isactive = false;
        }
        if (folderFullAccess && element?.id == 0) {
            element.isallowed = false;
        }

        if (folderMinusFullAccess && element?.id == 0) {
            element.isallowed = true;
        }
    });
    targetAct.forEach((element) => {
        if (targetFilter?.length > 0) {
            element.isactive = false;
        }
        if (targetFullAccess && element?.id == 0) {
            element.isallowed = false;
        }

        if (targetMinusFullAccess && element?.id == 0) {
            element.isallowed = true;
        }
    });
    userRoleAct.forEach((element) => {
        if (UserRoleFilter?.length > 0) {
            element.isactive = false;
        }
        if (userRoleFullAccess && element?.id == 0) {
            element.isallowed = false;
        }

        if (userRoleMinusFullAccess && element?.id == 0) {
            element.isallowed = true;
        }
    });
    userActions.forEach((element) => {
        if (UserFilter?.length > 0) {
            element.isactive = false;
        }
        if (userFullAccess && element?.id == 0) {
            element.isallowed = false;
        }

        if (userMinusFullAccess && element?.id == 0) {
            element.isallowed = true;
        }
    });
    organisationRoleAct.forEach((element) => {
        if (OrgRoleFilter?.length > 0) {
            element.isactive = false;
        }
        if (OrgRoleFullAccess && element?.id == 0) {
            element.isallowed = false;
        }

        if (OrgRoleMinusFullAccess && element?.id == 0) {
            element.isallowed = true;
        }
    });
    processAct.forEach((element) => {
        if (processFilter?.length > 0) {
            element.isactive = false;
        }
        if (processFullAccess && element?.id == 0) {
            element.isallowed = false;
        }

        if (processMinusFullAccess && element?.id == 0) {
            element.isallowed = true;
        }
    });
    exceptionAct.forEach((element) => {
        if (ExceptionFilter?.length > 0) {
            element.isactive = false;
        }
        if (exceptionFullAccess && element?.id == 0) {
            element.isallowed = false;
        }

        if (exceptionMinusFullAccess && element?.id == 0) {
            element.isallowed = true;
        }
    });
    approvalAct?.forEach((element) => {
        if (ApproveFilter?.length > 0) {
            element.isactive = false;
        }
        if (aprovalFullAccess && element?.id == 0) {
            element.isallowed = false;
        }

        if (approvalMinusFullAccess && element?.id == 0) {
            element.isallowed = true;
        }
    });
    advanceAct?.forEach((element) => {
        if (AdvanceFilter?.length > 0) {
            element.isactive = false;
        }
        if (advanceFullAccess && element?.id == 0) {
            element.isallowed = false;
        }

        if (advanceMinusFullAccess && element?.id == 0) {
            element.isallowed = true;
        }
    });
    miscAct?.forEach((element) => {
        if (MiscFilter?.length > 0) {
            element.isactive = false;
        }
        if (MiscFullAccess && element?.id == 0) {
            element.isallowed = false;
        }

        if (miscMinusFullAccess && element?.id == 0) {
            element.isallowed = true;
        }
    });
    retentionAct?.forEach((element) => {
        if (retentionFilter?.length > 0) {
            element.isactive = false;
        }
        if (retentionFullAccess && element?.id == 0) {
            element.isallowed = false;
        }

        if (retentionMinusFullAccess && element?.id == 0) {
            element.isallowed = true;
        }
    });
    const updateRoleItems = () => {
        RoleMenu.forEach((item) => {
            if (item.menu_id === 1) {
                orgArrayAct?.forEach((actionItem) => {
                    const isExistId = item.actions.find((action) => action.id === +actionItem.id);
                    if (isExistId) {
                        actionItem.isallowed = isExistId.isallowed;
                        updatedRoleList.push(actionItem);
                    } else {
                        actionItem.isallowed = false;
                        updatedRoleList.push(actionItem);
                    }
                });
            }
        });
    };
    const getOrgSection = RoleMenu?.find((item) => item?.menu_id == 1);
    const isCheckView = getOrgSection?.actions?.find((item) => item?.id == 3);

    const getUserSection = RoleMenu?.find((item) => item?.menu_id == 6);
    const isUserCheckView = getUserSection?.actions?.find((item) => item?.id == 3);

    const updateExceptionItems = () => {
        RoleMenu.forEach((item) => {
            if (item.menu_id === 9) {
                exceptionArrayAct?.forEach((actionItem) => {
                    const isExistId = item.actions?.find((action) => action.id === +actionItem.id);
                    if (isExistId) {
                        actionItem.isallowed = isExistId.isallowed;
                        updatedExceptionList.push(actionItem);
                    } else {
                        actionItem.isallowed = false;
                        updatedExceptionList.push(actionItem);
                    }
                });
            }
        });
    };
    const upadteApproveArrayItems = () => {
        RoleMenu.forEach((item) => {
            if (item.menu_id === 16) {
                approvalArrayAct?.forEach((actionItem) => {
                    const isExistId = item.actions?.find((action) => action.id === +actionItem.id);
                    if (isExistId) {
                        actionItem.isallowed = isExistId.isallowed;
                        updateApproveList.push(actionItem);
                    } else {
                        actionItem.isallowed = false;
                        updateApproveList.push(actionItem);
                    }
                });
            }
        });
    };

    const updateProcessArrayitems = () => {
        RoleMenu.forEach((item) => {
            if (item.menu_id === 8) {
                ProcessArrayAct?.forEach((actionItem) => {
                    const isExistId = item.actions.find((action) => action.id === +actionItem.id);
                    if (isExistId) {
                        actionItem.isallowed = isExistId.isallowed;
                        updatedProcessList.push(actionItem);
                    } else {
                        actionItem.isallowed = false;
                        updatedProcessList.push(actionItem);
                    }
                });
            }
        });
    };
    const updateOrgArrayItems = () => {
        RoleMenu.forEach((item) => {
            if (item.menu_id === 7) {
                orgRoleArrayAct?.forEach((actionItem) => {
                    const isExistId = item.actions.find((action) => action.id === +actionItem.id);
                    if ((actionItem.id == 0 || actionItem.id == 3) && isCheckView.isallowed) {
                        actionItem.isactive = false;
                    }
                    if (isExistId) {
                        actionItem.isallowed = isExistId.isallowed;
                        updatedOrgRoleList.push(actionItem);
                    } else {
                        actionItem.isallowed = false;
                        updatedOrgRoleList.push(actionItem);
                    }
                });
            }
        });
    };
    const updateUserRoleArrayItems = () => {
        RoleMenu.forEach((item) => {
            if (item.menu_id === 5) {
                userRoleArrayAct?.forEach((actionItem) => {
                    const isExistId = item.actions.find((action) => action.id === +actionItem.id);
                    if ((actionItem.id == 0 || actionItem.id == 3) && isUserCheckView.isallowed) {
                        actionItem.isactive = false;
                        setDisabled(false);
                    }
                    if (isExistId) {
                        actionItem.isallowed = isExistId.isallowed;
                        updatedUserRoleList.push(actionItem);
                    } else {
                        actionItem.isallowed = false;
                        updatedUserRoleList.push(actionItem);
                    }
                });
            }
        });
    };
    const updateTargetArrayItems = () => {
        RoleMenu.forEach((item) => {
            if (item.menu_id === 4) {
                TargetArrayAct?.forEach((actionItem) => {
                    const isExistId = item.actions.find((action) => action.id === +actionItem.id);
                    if ((actionItem.id == 0 || actionItem.id == 3) && isCheckView.isallowed) {
                        actionItem.isactive = false;
                    }
                    if (isExistId) {
                        actionItem.isallowed = isExistId.isallowed;
                        updatedTargetList.push(actionItem);
                    } else {
                        actionItem.isallowed = false;
                        updatedTargetList.push(actionItem);
                    }
                });
            }
        });
    };
    const updateUserArrayItems = () => {
        RoleMenu.forEach((item) => {
            if (item.menu_id === 6) {
                userArrayAct?.forEach((actionItem) => {
                    const isExistId = item.actions.find((action) => action.id === +actionItem.id);
                    if (isExistId) {
                        actionItem.isallowed = isExistId.isallowed;
                        updatedUserList.push(actionItem);
                    } else {
                        actionItem.isallowed = false;
                        updatedUserList.push(actionItem);
                    }
                });
            }
        });
    };
    const updateEmailArrayItems = () => {
        RoleMenu.forEach((item) => {
            if (item.menu_id === 2) {
                emailArrayAct?.forEach((actionItem) => {
                    const isExistId = item.actions.find((action) => action.id === +actionItem.id);
                    if ((actionItem.id == 0 || actionItem.id == 3) && isCheckView.isallowed) {
                        actionItem.isactive = false;
                    }
                    if (isExistId) {
                        actionItem.isallowed = isExistId.isallowed;
                        updatedEmailList.push(actionItem);
                    } else {
                        actionItem.isallowed = false;
                        updatedEmailList.push(actionItem);
                    }
                });
            }
        });
    };
    const updateFolderArrayItems = () => {
        RoleMenu.forEach((item) => {
            if (item.menu_id === 3) {
                folderArrayAct?.forEach((actionItem) => {
                    const isExistId = item.actions.find((action) => action.id === +actionItem.id);
                    if ((actionItem.id == 0 || actionItem.id == 3) && isCheckView.isallowed) {
                        actionItem.isactive = false;
                    }
                    if (isExistId) {
                        actionItem.isallowed = isExistId.isallowed;
                        updatedFolderList.push(actionItem);
                    } else {
                        actionItem.isallowed = false;
                        updatedFolderList.push(actionItem);
                    }
                });
            }
        });
    };

    const updateManulUploadItems = () => {
        RoleMenu.forEach((item) => {
            if (item.menu_id === 12) {
                UploadArrayAct?.forEach((actionItem) => {
                    const isExistId = item.actions.find((action) => action.id === +actionItem.id);
                    if (isExistId) {
                        actionItem.isallowed = isExistId.isallowed;
                        updateManulUploadList.push(actionItem);
                    } else {
                        actionItem.isallowed = false;
                        updateManulUploadList.push(actionItem);
                    }
                });
            }
        });
    };

    const updateacrualreportItems = () => {
        RoleMenu.forEach((item) => {
            if (item.menu_id === 13) {
                accrualAct?.forEach((actionItem) => {
                    const isExistId = item.actions.find((action) => action.id === +actionItem.id);
                    if (isExistId) {
                        actionItem.isallowed = isExistId.isallowed;
                        updateacrualReport.push(actionItem);
                    } else {
                        actionItem.isallowed = false;
                        updateacrualReport.push(actionItem);
                    }
                });
            }
        });
    };
    const updateInvoicereportItems = () => {
        RoleMenu.forEach((item) => {
            if (item.menu_id === 14) {
                InvReportAct?.forEach((actionItem) => {
                    const isExistId = item.actions.find((action) => action.id === +actionItem.id);
                    if (isExistId) {
                        actionItem.isallowed = isExistId.isallowed;
                        updateInvoicereport.push(actionItem);
                    } else {
                        actionItem.isallowed = false;
                        updateInvoicereport.push(actionItem);
                    }
                });
            }
        });
    };
    const updatereportsAgeingItems = () => {
        RoleMenu.forEach((item) => {
            if (item.menu_id === 15) {
                reportAgeAct?.forEach((actionItem) => {
                    const isExistId = item.actions.find((action) => action.id === +actionItem.id);
                    if (isExistId) {
                        actionItem.isallowed = isExistId.isallowed;
                        updatereportsAgeing.push(actionItem);
                    } else {
                        actionItem.isallowed = false;
                        updatereportsAgeing.push(actionItem);
                    }
                });
            }
        });
    };

    const updateDashboardItems = () => {
        RoleMenu.forEach((item) => {
            if (item.menu_id === 11) {
                dashboardAct?.forEach((actionItem) => {
                    const isExistId = item.actions.find((action) => action.id === +actionItem.id);
                    if (isExistId) {
                        actionItem.isallowed = isExistId.isallowed;
                        updateDashboardList.push(actionItem);
                    } else {
                        actionItem.isallowed = false;
                        updateDashboardList.push(actionItem);
                    }
                });
            }
        });
    };
    const updateAccrualPostingItems = () => {
        RoleMenu.forEach((item) => {
            if (item.menu_id === 17) {
                accrualPostAct?.forEach((actionItem) => {
                    const isExistId = item.actions.find((action) => action.id === +actionItem.id);
                    if (isExistId) {
                        actionItem.isallowed = isExistId.isallowed;
                        updateAccrualPosting.push(actionItem);
                    } else {
                        actionItem.isallowed = false;
                        updateAccrualPosting.push(actionItem);
                    }
                });
            }
        });
    };
    const upadateTaskReports = () => {
        RoleMenu.forEach((item) => {
            if (item.menu_id === 18) {
                taskReportAct?.forEach((actionItem) => {
                    const isExistId = item.actions.find((action) => action.id === +actionItem.id);
                    if (isExistId) {
                        actionItem.isallowed = isExistId.isallowed;
                        updateTaskreport.push(actionItem);
                    } else {
                        actionItem.isallowed = false;
                        updateTaskreport.push(actionItem);
                    }
                });
            }
        });
    };
    const updateAdvReports = () => {
        RoleMenu.forEach((item) => {
            if (item.menu_id === 24) {
                advReportAct?.forEach((actionItem) => {
                    const isExistId = item.actions.find((action) => action.id === +actionItem.id);
                    if (isExistId) {
                        actionItem.isallowed = isExistId.isallowed;
                        updateAdvreport.push(actionItem);
                    } else {
                        actionItem.isallowed = false;
                        updateAdvreport.push(actionItem);
                    }
                });
            }
        });
    };
    const updateMiscReports = () => {
        RoleMenu.forEach((item) => {
            if (item.menu_id === 25) {
                miscReportAct?.forEach((actionItem) => {
                    const isExistId = item.actions.find((action) => action.id === +actionItem.id);
                    if (isExistId) {
                        actionItem.isallowed = isExistId.isallowed;
                        updateMiscReort.push(actionItem);
                    } else {
                        actionItem.isallowed = false;
                        updateMiscReort.push(actionItem);
                    }
                });
            }
        });
    };
    const updateRetenReports = () => {
        RoleMenu.forEach((item) => {
            if (item.menu_id === 26) {
                retentionReportAct?.forEach((actionItem) => {
                    const isExistId = item.actions.find((action) => action.id === +actionItem.id);
                    if (isExistId) {
                        actionItem.isallowed = isExistId.isallowed;
                        updateRetenReport.push(actionItem);
                    } else {
                        actionItem.isallowed = false;
                        updateRetenReport.push(actionItem);
                    }
                });
            }
        });
    };
    const updateMonthClosingItems = () => {
        RoleMenu.forEach((item) => {
            if (item.menu_id == 19) {
                MonthEndAct?.forEach((actionItem) => {
                    const isExistId = item.actions.find((action) => action.id === +actionItem.id);
                    if (isExistId) {
                        actionItem.isallowed = isExistId.isallowed;
                        updateMonthClosing.push(actionItem);
                    } else {
                        actionItem.isallowed = false;
                        updateMonthClosing.push(actionItem);
                    }
                });
            }
        });
    };
    const ExchangeRateItems = () => {
        RoleMenu.forEach((item) => {
            if (item.menu_id == 27) {
                exchangeRateAct?.forEach((actionItem) => {
                    const isExistId = item.actions.find((action) => action.id === +actionItem.id);
                    if (isExistId) {
                        actionItem.isallowed = isExistId.isallowed;
                        updateExchangeRate.push(actionItem);
                    } else {
                        actionItem.isallowed = false;
                        updateExchangeRate.push(actionItem);
                    }
                });
            }
        });
    };
    const updateAdvanceReqItems = () => {
        RoleMenu.forEach((item) => {
            if (item.menu_id === 21) {
                AdvanceArrayAct?.forEach((actionItem) => {
                    const isExistId = item.actions?.find((action) => action.id === +actionItem.id);
                    if (isExistId) {
                        actionItem.isallowed = isExistId.isallowed;
                        updateAdvanceRequestList.push(actionItem);
                    } else {
                        actionItem.isallowed = false;
                        updateAdvanceRequestList.push(actionItem);
                    }
                });
            }
        });
    };
    const updateMiscItems = () => {
        RoleMenu.forEach((item) => {
            if (item.menu_id === 22) {
                MiscArrayAct?.forEach((actionItem) => {
                    const isExistId = item.actions?.find((action) => action.id === +actionItem.id);
                    if (isExistId) {
                        actionItem.isallowed = isExistId.isallowed;
                        updateMiscList.push(actionItem);
                    } else {
                        actionItem.isallowed = false;
                        updateMiscList.push(actionItem);
                    }
                });
            }
        });
    };
    const updateRetentionItems = () => {
        RoleMenu.forEach((item) => {
            if (item.menu_id === 23) {
                RetentionArrayAct?.forEach((actionItem) => {
                    const isExistId = item.actions?.find((action) => action.id === +actionItem.id);
                    if (isExistId) {
                        actionItem.isallowed = isExistId.isallowed;
                        updateRetentionList.push(actionItem);
                    } else {
                        actionItem.isallowed = false;
                        updateRetentionList.push(actionItem);
                    }
                });
            }
        });
    };

    const checkAllOrgSelected = (_orgAct, _emailACt, _folderAct, _targetAct, _userRoleAct) => {
        const orgSelected = _orgAct?.find((item) => item.isallowed === true && item.id === 0);
        const emailSelected = _emailACt?.find((item) => item.isallowed === true && item.id === 0);
        const folderSelected = _folderAct?.find((item) => item.isallowed === true && item.id === 0);
        const targetSelected = _targetAct?.find((item) => item.isallowed === true && item.id === 0);
        const userSelected = _userRoleAct?.find((item) => item.isallowed === true && item.id === 0);
        if (orgSelected && emailSelected && folderSelected && targetSelected && userSelected) {
            return true;
        } else {
            return false;
        }
    };

    const checkAllUserSelected = (_userAction, _organisationRoleAct) => {
        const userSelected = _userAction?.find((item) => item.isallowed === true && item.id === 0);
        const orgSelected = _organisationRoleAct?.find((item) => item.isallowed === true && item.id === 0);

        if (userSelected && orgSelected) {
            return true;
        } else {
            return false;
        }
    };

    const checkAllInvoceSelected = (_uploadAct, _processAction, _exceptionAct, _approveAct) => {
        const processSelected = _processAction?.find((item) => item.isallowed === true && item.id === 0);
        const exceptionSelected = _exceptionAct?.find((item) => item.isallowed === true && item.id === 0);
        const approveSlected = _approveAct?.AccrualAction?.find((item) => item.isallowed === true && item.id === 0);
        if (processSelected && exceptionSelected && approveSlected) {
            return true;
        } else {
            return false;
        }
    };

    const checkAllreportsSelected = (_reportACt, _accrualAct, _InvReportAct, _reportAgeAct, _TaskReport, _AdvReport, _MiscReport, _RetenReport) => {
        const reportSelected = _reportACt?.find((item) => item.isallowed === true && item.id === 0);
        const accrualSelected = _accrualAct?.find((item) => item.isallowed === true && item.id === 0);
        const InvReportSelected = _InvReportAct?.find((item) => item.isallowed === true && item.id === 0);
        const reportAgeingSelected = _reportAgeAct?.find((item) => item.isallowed === true && item.id === 0);
        const TaskreportSelected = _TaskReport?.find((item) => item.isallowed === true && item.id === 0);
        const AdvreportSelected = _AdvReport?.find((item) => item.isallowed === true && item.id === 0);
        const MiscreportSelected = _MiscReport?.find((item) => item.isallowed === true && item.id === 0);
        const RetenreportSelected = _RetenReport?.find((item) => item.isallowed === true && item.id === 0);

        if (
            reportSelected &&
            accrualSelected &&
            InvReportSelected &&
            reportAgeingSelected &&
            TaskreportSelected &&
            AdvreportSelected &&
            MiscreportSelected &&
            RetenreportSelected
        ) {
            return true;
        } else {
            return false;
        }
    };
    const checkAllPaymentSelected = (_advanceAction, _miscAction, _retentionAct) => {
        const advanceSelected = _advanceAction?.find((item) => item.isallowed === true && item.id === 0);
        const miscSelected = _miscAction?.find((item) => item.isallowed === true && item.id === 0);
        const retentionSlected = _retentionAct?.find((item) => item.isallowed === true && item.id === 0);
        if (advanceSelected && miscSelected && retentionSlected) {
            return true;
        } else {
            return false;
        }
    };

    const handleCheckboxChange = (event, id, menu_id) => {
        if (id == 30) {
            orgAct.forEach((element) => {
                if (element?.id !== 12) {
                    element.isallowed = event.target.value;
                }
                if (element?.id !== 3 && element?.id !== 0) {
                    element.isactive = event.target.value ? false : true;
                }
            });

            emailACt.forEach((element) => {
                if (element?.id !== 12) {
                    element.isallowed = event.target.value;
                    element.isactive = event.target.value ? false : true;
                }
            });
            folderAct.forEach((element) => {
                if (element?.id !== 12) {
                    element.isallowed = event.target.value;
                    element.isactive = event.target.value ? false : true;
                }
            });
            targetAct.forEach((element) => {
                if (element?.id !== 12) {
                    element.isallowed = event.target.value;
                    element.isactive = event.target.value ? false : true;
                }
            });
            userRoleAct.forEach((element) => {
                if (element?.id !== 4 && element?.id !== 5) {
                    element.isallowed = event.target.value;
                    element.isactive = event.target.value ? false : true;
                }
            });
        }

        if (id == 31) {
            userActions.forEach((element) => {
                if (element?.id !== 12) {
                    element.isallowed = event.target.value;
                }
                if (element?.id !== 3 && element?.id !== 0) {
                    element.isactive = event.target.value ? false : true;
                }
            });
            organisationRoleAct.forEach((element) => {
                if (element?.id !== 2 && element?.id !== 4 && element?.id !== 5) {
                    element.isallowed = event.target.value;
                    element.isactive = event.target.value ? false : true;
                }
            });
        }
        if (id == 32) {
            processAct.forEach((element) => {
                if (element?.id !== 15 && element?.id !== 16 && element?.id !== 17) {
                    element.isallowed = event.target.value;
                }
                if (element?.id !== 3 && element?.id !== 0) {
                    element.isactive = event.target.value ? false : true;
                }
            });
            // exceptionAct.forEach((element) => {
            //     if (
            //         element?.id !== 15 &&
            //         element?.id !== 16 &&
            //         element?.id !== 17
            //     ) {
            //         element.isallowed = event.target.value;
            //     }
            // });
            approvalAct.forEach((element) => {
                if (element?.id !== 15 && element?.id !== 16 && element?.id !== 17) {
                    element.isallowed = event.target.value;
                }
                if (element?.id !== 3 && element?.id !== 0) {
                    element.isactive = event.target.value ? false : true;
                }
            });
            uploadAct.forEach((element) => {
                if (element?.id !== 15 && element?.id !== 16 && element?.id !== 17) {
                    element.isallowed = event.target.value;
                }
            });
        }
        if (id == 33) {
            accrualAct?.forEach((element) => {
                if (element?.id == 20) {
                    element.isallowed = event.target.value;
                }
            });
            InvReportAct?.forEach((element) => {
                if (element?.id == 20) {
                    element.isallowed = event.target.value;
                }
            });
            reportAgeAct?.forEach((element) => {
                if (element?.id == 20) {
                    element.isallowed = event.target.value;
                }
            });
            taskReportAct?.forEach((element) => {
                if (element?.id == 20) {
                    element.isallowed = event.target.value;
                }
            });
            advReportAct?.forEach((element) => {
                if (element?.id == 20) {
                    element.isallowed = event.target.value;
                }
            });
            miscReportAct?.forEach((element) => {
                if (element?.id == 20) {
                    element.isallowed = event.target.value;
                }
            });
            retentionReportAct?.forEach((element) => {
                if (element?.id == 20) {
                    element.isallowed = event.target.value;
                }
            });
        }
        if (id == 34) {
            advanceAct?.forEach((element) => {
                element.isallowed = event.target.value;
                if (element?.id !== 3 && element?.id !== 0) {
                    element.isactive = event.target.value ? false : true;
                }
            });
            miscAct?.forEach((element) => {
                element.isallowed = event.target.value;
                if (element?.id !== 3 && element?.id !== 0) {
                    element.isactive = event.target.value ? false : true;
                }
            });
            retentionAct?.forEach((element) => {
                element.isallowed = event.target.value;
                if (element?.id !== 3 && element?.id !== 0) {
                    element.isactive = event.target.value ? false : true;
                }
            });
        }
        if (menu_id == 1) {
            orgAct.forEach((element) => {
                if (id == 0 || id == 3) {
                    emailACt.forEach((item) => {
                        if (item.id == 3 || item.id == 0) {
                            item.isactive = event.target.value ? false : true;
                            if (item.isallowed && !event.target.value) {
                                item.isallowed = false;
                            }
                        } else {
                            item.isactive = true;
                            item.isallowed = false;
                        }
                    });
                    folderAct.forEach((item) => {
                        if (item.id == 3 || item.id == 0) {
                            item.isactive = event.target.value ? false : true;
                            if (item.isallowed && !event.target.value) {
                                item.isallowed = false;
                            }
                        } else {
                            item.isactive = true;
                            item.isallowed = false;
                        }
                    });
                    targetAct.forEach((item) => {
                        if (item.id == 3 || item.id == 0) {
                            item.isactive = event.target.value ? false : true;
                            if (item.isallowed && !event.target.value) {
                                item.isallowed = false;
                            }
                        } else {
                            item.isactive = true;
                            item.isallowed = false;
                        }
                    });
                    userRoleAct.forEach((item) => {
                        if (item.id == 3 || item.id == 0) {
                            item.isactive = event.target.value ? false : true;
                            if (item.isallowed && !event.target.value) {
                                item.isallowed = false;
                            }
                        } else {
                            item.isactive = true;
                            item.isallowed = false;
                        }
                    });
                }
                if (id == 0) {
                    element.isallowed = event.target.value;
                    if (!event.target.value && element.id !== 0 && element.id !== 3) {
                        element.isactive = true;
                    } else if (element.id !== 12) {
                        element.isactive = false;
                    }
                } else if (id == 3) {
                    if (id == element?.id) {
                        element.isallowed = event.target.value;
                    } else {
                        element.isallowed = false;
                    }
                    if (!event.target.value && element.id !== 0 && element.id !== 3) {
                        element.isactive = true;
                    } else if (event.target.value && element.id == 0) {
                        element.isallowed = false;
                    } else if (event.target.value && element.id !== 12) {
                        element.isactive = false;
                    }
                } else if (id == element?.id) {
                    element.isallowed = event.target.value;
                }
            });
        } else if (menu_id == 2) {
            emailACt.forEach((element) => {
                if (id == 0) {
                    element.isallowed = event.target.value;
                    if (!event.target.value && element.id !== 0 && element.id !== 3) {
                        element.isactive = true;
                    } else if (element.id !== 12 && element.id !== 2) {
                        element.isactive = false;
                    }
                } else if (id == 3) {
                    if (id == element?.id) {
                        element.isallowed = event.target.value;
                    } else {
                        element.isallowed = false;
                    }
                    if (!event.target.value && element.id !== 0 && element.id !== 3) {
                        element.isactive = true;
                    } else if (event.target.value && element.id == 0) {
                        element.isallowed = false;
                    } else if (event.target.value && element.id !== 12 && element.id !== 2) {
                        element.isactive = false;
                    }
                } else if (id == element?.id) {
                    element.isallowed = event.target.value;
                }
            });
        }
        if (menu_id == 3) {
            folderAct.forEach((element) => {
                if (id == 0) {
                    element.isallowed = event.target.value;
                    if (!event.target.value && element.id !== 0 && element.id !== 3) {
                        element.isactive = true;
                    } else if (element.id !== 12 && element.id !== 2) {
                        element.isactive = false;
                    }
                } else if (id == 3) {
                    if (id == element?.id) {
                        element.isallowed = event.target.value;
                    } else {
                        element.isallowed = false;
                    }
                    if (!event.target.value && element.id !== 0 && element.id !== 3) {
                        element.isactive = true;
                    } else if (event.target.value && element.id == 0) {
                        element.isallowed = false;
                    } else if (event.target.value && element.id !== 12 && element.id !== 2) {
                        element.isactive = false;
                    }
                } else if (id == element?.id) {
                    element.isallowed = event.target.value;
                }
            });
        } else if (menu_id == 4) {
            targetAct.forEach((element) => {
                if (id == 0) {
                    element.isallowed = event.target.value;
                    if (!event.target.value && element.id !== 0 && element.id !== 3) {
                        element.isactive = true;
                    } else if (element.id !== 12) {
                        element.isactive = false;
                    }
                } else if (id == 3) {
                    if (id == element?.id) {
                        element.isallowed = event.target.value;
                    } else {
                        element.isallowed = false;
                    }
                    if (!event.target.value && element.id !== 0 && element.id !== 3) {
                        element.isactive = true;
                    } else if (event.target.value && element.id == 0) {
                        element.isallowed = false;
                    } else if (event.target.value && element.id !== 12) {
                        element.isactive = false;
                    }
                } else if (id == element?.id) {
                    element.isallowed = event.target.value;
                }
            });
        }
        if (menu_id == 5) {
            userRoleAct.forEach((element) => {
                if (id == 0) {
                    element.isallowed = event.target.value;
                    if (!event.target.value && element.id !== 0 && element.id !== 3) {
                        element.isactive = true;
                    } else if (element.id !== 4 && element.id !== 5 && element.id !== 2) {
                        element.isactive = false;
                    }
                } else if (id == 3) {
                    if (id == element?.id) {
                        element.isallowed = event.target.value;
                    } else {
                        element.isallowed = false;
                    }
                    if (!event.target.value && element.id !== 0 && element.id !== 3) {
                        element.isactive = true;
                    } else if (event.target.value && element.id == 0) {
                        element.isallowed = false;
                    } else if (event.target.value && element.id !== 4 && element.id !== 5 && element.id !== 2) {
                        element.isactive = false;
                    }
                } else if (id == element?.id) {
                    element.isallowed = event.target.value;
                }
            });
        }

        if (menu_id == 6) {
            userActions.forEach((element) => {
                if (id == 0 || id == 3) {
                    organisationRoleAct.forEach((item) => {
                        if (item.id == 3 || item.id == 0) {
                            item.isactive = event.target.value ? false : true;
                            if (item.isallowed && !event.target.value) {
                                item.isallowed = false;
                            }
                        } else {
                            item.isactive = true;
                            item.isallowed = false;
                        }
                    });
                }

                if (id == 0) {
                    element.isallowed = event.target.value;
                    if (!event.target.value && element.id !== 0 && element.id !== 3) {
                        element.isactive = true;
                    } else if (element.id !== 12) {
                        element.isactive = false;
                    }
                } else if (id == 3) {
                    if (id == element?.id) {
                        element.isallowed = event.target.value;
                    } else {
                        element.isallowed = false;
                    }
                    if (!event.target.value && element.id !== 0 && element.id !== 3) {
                        element.isactive = true;
                    } else if (event.target.value && element.id == 0) {
                        element.isallowed = false;
                    } else if (event.target.value && element.id !== 12) {
                        element.isactive = false;
                    }
                } else if (id == element?.id) {
                    element.isallowed = event.target.value;
                }
            });
        }

        if (menu_id == 7) {
            organisationRoleAct.forEach((element) => {
                if (id == 0) {
                    element.isallowed = event.target.value;
                    if (!event.target.value && element.id !== 0 && element.id !== 3) {
                        element.isactive = true;
                    } else if (element.id !== 2 && element.id !== 4 && element.id !== 5) {
                        element.isactive = false;
                    }
                } else if (id == 3) {
                    if (id == element?.id) {
                        element.isallowed = event.target.value;
                    } else {
                        element.isallowed = false;
                    }
                    if (!event.target.value && element.id !== 0 && element.id !== 3) {
                        element.isactive = true;
                    } else if (event.target.value && element.id == 0) {
                        element.isallowed = false;
                    } else if (event.target.value && element.id !== 2 && element.id !== 4 && element.id !== 5) {
                        element.isactive = false;
                    }
                } else if (id == element?.id) {
                    element.isallowed = event.target.value;
                }
            });
        }
        if (menu_id == 8) {
            processAct.forEach((element) => {
                if (id == 0) {
                    element.isallowed = event.target.value;
                    if (!event.target.value && element.id !== 0 && element.id !== 3) {
                        element.isactive = true;
                    } else if (element.id !== 15 && element.id !== 16 && element.id !== 17) {
                        element.isactive = false;
                    }
                } else if (id == 3) {
                    if (id == element?.id) {
                        element.isallowed = event.target.value;
                    } else {
                        element.isallowed = false;
                    }
                    if (!event.target.value && element.id !== 0 && element.id !== 3 && element.id !== 6) {
                        element.isactive = true;
                    } else if (event.target.value && element.id == 0) {
                        element.isallowed = false;
                    } else if (event.target.value && element.id !== 15 && element.id !== 16 && element.id !== 17) {
                        element.isactive = false;
                    }
                } else if (id == element?.id) {
                    element.isallowed = event.target.value;
                }
            });
        }
        if (menu_id == 9) {
            exceptionAct.forEach((element) => {
                if (id == 0) {
                    element.isallowed = event.target.value;
                    if (!event.target.value && element.id !== 0 && element.id !== 3) {
                        element.isactive = true;
                    } else if (element.id !== 15 && element.id !== 16 && element.id !== 17) {
                        element.isactive = false;
                    }
                } else if (id == 3) {
                    if (id == element?.id) {
                        element.isallowed = event.target.value;
                    } else {
                        element.isallowed = false;
                    }
                    if (!event.target.value && element.id !== 0 && element.id !== 3) {
                        element.isactive = true;
                    } else if (event.target.value && element.id == 0) {
                        element.isallowed = false;
                    } else if (event.target.value && element.id !== 15 && element.id !== 16 && element.id !== 17 && element.id !== 6) {
                        element.isactive = false;
                    }
                } else if (id == element?.id) {
                    element.isallowed = event.target.value;
                }
            });
        }
        if (menu_id == 16) {
            approvalAct.forEach((element) => {
                if (id == 0) {
                    element.isallowed = event.target.value;
                    if (!event.target.value && element.id !== 0 && element.id !== 3) {
                        element.isactive = true;
                    } else if (element.id !== 15 && element.id !== 16 && element.id !== 17) {
                        element.isactive = false;
                    }
                } else if (id == 3) {
                    if (id == element?.id) {
                        element.isallowed = event.target.value;
                    } else {
                        element.isallowed = false;
                    }
                    if (!event.target.value && element.id !== 0 && element.id !== 3) {
                        element.isactive = true;
                    } else if (event.target.value && element.id == 0) {
                        element.isallowed = false;
                    } else if (event.target.value && element.id !== 15 && element.id !== 16 && element.id !== 17 && element.id !== 2) {
                        element.isactive = false;
                    }
                } else if (id == element?.id) {
                    element.isallowed = event.target.value;
                }
            });
        }
        if (menu_id == 21) {
            advanceAct?.forEach((element) => {
                if (id == 0) {
                    element.isallowed = event.target.value;
                    if (!event.target.value && element.id !== 0 && element.id !== 3) {
                        element.isactive = true;
                    } else if (element.id !== 1) {
                        element.isactive = false;
                    }
                } else if (id == 3) {
                    if (id == element?.id) {
                        element.isallowed = event.target.value;
                    } else {
                        element.isallowed = false;
                    }
                    if (!event.target.value && element.id !== 0 && element.id !== 3) {
                        element.isactive = true;
                    } else if (event.target.value && element.id == 0) {
                        element.isallowed = false;
                    } else if (event.target.value && element.id !== 1) {
                        element.isactive = false;
                    }
                } else if (id == element?.id) {
                    element.isallowed = event.target.value;
                }
            });
        }
        if (menu_id == 22) {
            miscAct?.forEach((element) => {
                if (id == 0) {
                    element.isallowed = event.target.value;
                    if (!event.target.value && element.id !== 0 && element.id !== 3) {
                        element.isactive = true;
                    } else if (element.id !== 1) {
                        element.isactive = false;
                    }
                } else if (id == 3) {
                    if (id == element?.id) {
                        element.isallowed = event.target.value;
                    } else {
                        element.isallowed = false;
                    }
                    if (!event.target.value && element.id !== 0 && element.id !== 3) {
                        element.isactive = true;
                    } else if (event.target.value && element.id == 0) {
                        element.isallowed = false;
                    } else if (event.target.value && element.id !== 1) {
                        element.isactive = false;
                    }
                } else if (id == element?.id) {
                    element.isallowed = event.target.value;
                }
            });
        }
        if (menu_id == 23) {
            retentionAct?.forEach((element) => {
                if (id == 0) {
                    element.isallowed = event.target.value;
                    if (!event.target.value && element.id !== 0 && element.id !== 3) {
                        element.isactive = true;
                    } else if (element.id !== 1) {
                        element.isactive = false;
                    }
                } else if (id == 3) {
                    if (id == element?.id) {
                        element.isallowed = event.target.value;
                    } else {
                        element.isallowed = false;
                    }
                    if (!event.target.value && element.id !== 0 && element.id !== 3) {
                        element.isactive = true;
                    } else if (event.target.value && element.id == 0) {
                        element.isallowed = false;
                    } else if (event.target.value && element.id !== 1) {
                        element.isactive = false;
                    }
                } else if (id == element?.id) {
                    element.isallowed = event.target.value;
                }
            });
        }
        if (menu_id == 11) {
            dashboardAct.forEach((element) => {
                if (id == element?.id) {
                    element.isallowed = event.target.value;
                }
            });
        }
        if (menu_id == 12) {
            uploadAct.forEach((element) => {
                if (id == element?.id) {
                    element.isallowed = event.target.value;
                }
            });
        }
        if (menu_id == 13) {
            accrualAct.forEach((element) => {
                if (id == element?.id) {
                    element.isallowed = event.target.value;
                }
            });
        }
        if (menu_id == 19) {
            MonthEndAct.forEach((element) => {
                if (id == element?.id) {
                    element.isallowed = event.target.value;
                }
            });
        }
        if (menu_id == 14) {
            InvReportAct.forEach((element) => {
                if (id == element?.id) {
                    element.isallowed = event.target.value;
                }
            });
        }
        if (menu_id == 15) {
            reportAgeAct.forEach((element) => {
                if (id == element?.id) {
                    element.isallowed = event.target.value;
                }
            });
        }
        if (menu_id == 18) {
            taskReportAct.forEach((element) => {
                if (id == element?.id) {
                    element.isallowed = event.target.value;
                }
            });
        }
        if (menu_id == 24) {
            advReportAct?.forEach((element) => {
                if (id == element?.id) {
                    element.isallowed = event.target.value;
                }
            });
        }
        if (menu_id == 25) {
            miscReportAct?.forEach((element) => {
                if (id == element?.id) {
                    element.isallowed = event.target.value;
                }
            });
        }
        if (menu_id == 26) {
            retentionReportAct?.forEach((element) => {
                if (id == element?.id) {
                    element.isallowed = event.target.value;
                }
            });
        }
        if (menu_id == 27) {
            exchangeRateAct?.forEach((element) => {
                if (id == element?.id) {
                    element.isallowed = event.target.value;
                }
            });
        }

        if (menu_id == 17) {
            accrualPostAct.forEach((element) => {
                if (id == element?.id) {
                    element.isallowed = event.target.value;
                }
            });
        }
        // setAction(id)
        // setIsAdult({
        //   ...isadult,
        //   [event.target.name]: event.target.value,
        // });

        setorgAct([...orgAct]);
        setemailAct([...emailACt]);
        setfolderAct([...folderAct]);
        setTargetAct([...targetAct]);
        setuserRole([...userRoleAct]);
        setuserAct([...userActions]);
        setOrganisationRoleAct([...organisationRoleAct]);
        setProcessAct([...processAct]);
        setExceptionAct([...exceptionAct]);
        setApprovalAct([...approvalAct]);
        setDashboardAct([...dashboardAct]);
        setAccrualAct([...accrualPostAct]);
        setManualAct([...manualAct]);
        setreportAgeAct([...reportAgeAct]);
        setaccrualAct([...accrualAct]);
        setInvreportAct([...InvReportAct]);
        settaskReportAct([...taskReportAct]);
        setUplaodAct([...uploadAct]);
        setMonthEndAct([...MonthEndAct]);
        setAdvanceAct([...advanceAct]);
        setMiscAct([...miscAct]);
        setRetentionAct([...retentionAct]);
        setadvReportAct([...advReportAct]);
        setMiscReportAct([...miscReportAct]);
        setRetentionReportAct([...retentionReportAct]);
        setExchangeRateAct([...exchangeRateAct]);
        setOrgSelectAll(checkAllOrgSelected(orgAct, emailACt, folderAct, targetAct, userRoleAct));
        setUserSelectAll(checkAllUserSelected(userActions, organisationRoleAct));
        setInvoiceSelectAll(checkAllInvoceSelected(processAct, exceptionAct, approvalAct, uploadAct));
        setreportsSelectAll(
            checkAllreportsSelected(reportAgeAct, accrualAct, InvReportAct, taskReportAct, advReportAct, miscReportAct, retentionReportAct)
        );
        setpaymentSelectAll(checkAllPaymentSelected(advanceAct, miscAct, retentionAct));
        setTooltipOpen(false);
        setInvoiceTooltipOpen(false);
        setUserTooltipOpen(false);
    };
    const { fetching, requestComplete, error, data } = useSelector((state) => state?.AddRolePermissionStateAction);
    const params = new URLSearchParams(window.location.search);
    let ViewRole = params.get("View-roleid");

    const ProcessAlloc = processAct?.filter((el) => (el?.id == 2 || el?.id == 3 || el?.id == 6) && el?.isallowed == false);
    const ExceptionAlloc = exceptionAct?.filter((el) => (el?.id == 2 || el?.id == 3 || el?.id == 6) && el?.isallowed == false);
    const ApprovalAlloc = approvalAct?.filter((el) => (el?.id == 3 || el?.id == 6) && el?.isallowed == false);
    const InvAlloc =
        RoleView?.isallocation && RoleIDINt && (ProcessAlloc?.length !== 0 || ExceptionAlloc?.length !== 0 || ApprovalAlloc?.length !== 0);
    const InvallocDisp = RoleView?.isallocation && (ProcessAlloc?.length !== 0 || ExceptionAlloc?.length !== 0 || ApprovalAlloc?.length !== 0);
    function submit() {
        if (roleName) {
            if (roleName?.length > 50) {
                seterrormsg({
                    message: "Maximum length should not exceed 50",
                });
                return false;
            }
            if (!/^(?=.*[a-zA-Z])[A-Za-z0-9_ ]+$/.test(roleName)) {
                seterrormsg({
                    message: "Rolename is Invalid",
                });
                return false;
            }
            seterrormsg(false);

            if (description?.length > 250) {
                setDescriptionError({
                    message: "Maximum length should not exceed 250",
                });
                return false;
            } else {
                setDescriptionError({
                    message: "",
                });
            }

            setDescriptionError(false);
            const orgSelected = orgAct?.find((item) => item?.isallowed === true);
            const emailSelected = emailACt?.find((item) => item?.isallowed === true);
            const folderSelected = folderAct?.find((item) => item?.isallowed === true);
            const targetSelected = targetAct?.find((item) => item?.isallowed === true);
            const userSelected = userRoleAct?.find((item) => item?.isallowed === true);
            const userRoleSelected = userActions?.find((item) => item?.isallowed === true);
            const orgRoleSelected = organisationRoleAct?.find((item) => item?.isallowed === true);
            const processSelected = processAct?.find((item) => item?.isallowed === true);
            const expSelected = exceptionAct?.find((item) => item?.isallowed === true);
            const approvalSelected = approvalAct?.find((item) => item?.isallowed === true);
            const dashboardSelected = dashboardAct?.find((item) => item?.isallowed === true);
            const AccrualPostingSelected = accrualPostAct?.find((item) => item.isallowed === true);
            const MonthEndSelected = MonthEndAct?.find((item) => item?.isallowed === true);
            const manualSelected = uploadAct?.find((item) => item.isallowed === true);
            const accrualReportSelected = accrualAct?.find((item) => item?.isallowed === true);
            const InvoiceReportSelected = InvReportAct?.find((item) => item?.isallowed === true);
            const ReportsAgeingSelected = reportAgeAct?.find((item) => item?.isallowed === true);
            const TaskReportsSelected = taskReportAct?.find((item) => item?.isallowed === true);
            const AdvancereqSelected = advanceAct?.find((item) => item?.isallowed === true);
            const MiscSelected = MiscAct?.find((item) => item?.isallowed === true);
            const RetentionSelected = retentionAct?.find((item) => item?.isallowed === true);
            const advReportSelected = advReportAct?.find((item) => item?.isallowed === true);
            const MiscReportSelected = miscReportAct?.find((item) => item?.isallowed === true);
            const RetentionReportSelected = retentionReportAct?.find((item) => item?.isallowed === true);
            const ExchangeRateSelected = exchangeRateAct?.find((item) => item?.isallowed === true);

            if (
                (orgSelected ||
                    emailSelected ||
                    folderSelected ||
                    targetSelected ||
                    userSelected ||
                    userRoleSelected ||
                    orgRoleSelected ||
                    processSelected ||
                    expSelected ||
                    dashboardSelected ||
                    manualSelected ||
                    accrualReportSelected ||
                    InvoiceReportSelected ||
                    ReportsAgeingSelected ||
                    approvalSelected ||
                    TaskReportsSelected ||
                    MonthEndSelected ||
                    AccrualPostingSelected ||
                    AdvancereqSelected ||
                    MiscSelected ||
                    RetentionSelected ||
                    advReportSelected ||
                    MiscReportSelected ||
                    RetentionReportSelected ||
                    ExchangeRateSelected) &&
                !InvallocDisp
            ) {
                const reqobj = {
                    headers: [
                        {
                            role_id: RoleIDINt ? RoleIDINt : 0,
                            description: description,
                            role_name: roleName.trim(),
                        },
                    ],
                    items: [
                        {
                            role_id: RoleIDINt ? RoleIDINt : 0,
                            menu: [
                                { menu_id: 1, actions: orgAct, identity_id: OrgID },
                                { menu_id: 2, actions: emailACt, identity_id: EmailConfigID },
                                { menu_id: 3, actions: folderAct, identity_id: FoderConfigID },
                                { menu_id: 4, actions: targetAct, identity_id: TargetConfigID },
                                { menu_id: 5, actions: userRoleAct, identity_id: UserRoleID },
                                { menu_id: 6, actions: userActions, identity_id: UserID },
                                { menu_id: 7, actions: organisationRoleAct, identity_id: OrgRoleID },
                                { menu_id: 8, actions: processAct, identity_id: ProcessInvId },
                                { menu_id: 9, actions: exceptionAct, identity_id: ExceptionID },
                                { menu_id: 11, actions: dashboardAct, identity_id: DashboardID },
                                { menu_id: 17, actions: accrualPostAct, identity_id: AccrualPostingID },
                                { menu_id: 12, actions: uploadAct, identity_id: ManualID },
                                { menu_id: 13, actions: accrualAct, identity_id: AccrualID },
                                { menu_id: 14, actions: InvReportAct, identity_id: InvoiceReportID },
                                { menu_id: 15, actions: reportAgeAct, identity_id: ReportAgeingID },
                                { menu_id: 16, actions: approvalAct, identity_id: ApprovalID },
                                { menu_id: 18, actions: taskReportAct, identity_id: TaskReportsId },
                                { menu_id: 19, actions: MonthEndAct, identity_id: MonthClosingID },
                                { menu_id: 21, actions: advanceAct, identity_id: FindAdvance },
                                { menu_id: 22, actions: miscAct, identity_id: FindMisc },
                                { menu_id: 23, actions: retentionAct, identity_id: FindRetention },
                                { menu_id: 24, actions: advReportAct, identity_id: AdvanceReportID },
                                { menu_id: 25, actions: miscReportAct, identity_id: MiscReportID },
                                { menu_id: 26, actions: retentionReportAct, identity_id: RetentionReportID },
                                { menu_id: 27, actions: exchangeRateAct, identity_id: ExchangeRateID },
                            ],
                        },
                    ],
                };

                const reqStr = {
                    json: reqobj,
                };
                dispatch(AddRolePermission(reqobj));
            } else {
                if (InvAlloc) {
                    Swal.fire({
                        text: "Modification of an allocated role is not permitted!",
                        icon: "warning",
                        showCloseButton: true,
                        closeOnClickOutside: false,
                        buttons: {
                            confirm: true,
                        },
                    });
                } else {
                    Swal.fire({
                        text: "Please select one or more check box for permission!",
                        closeOnClickOutside: false,
                        showCloseButton: true,
                        icon: "warning",
                        button: "Ok",
                    });
                }
            }
        } else {
            seterrormsg({
                message: "Role Name is required",
            });
        }
    }

    if (requestComplete && data?.status) {
        setTimeout(function () {
            window.location.href = "/role-master";
        }, 2000);
    }

    const Role = useSelector((state) => state?.RolePermissionStateAction);

    const LightTooltip = styled(({ className, ...props }) => (
        <Tooltip
            {...props}
            placement="top-start"
            PopperProps={{
                sx: {
                    "& .MuiTooltip-tooltip": {
                        padding: "0 5px",
                    },
                },
                modifiers: [
                    {
                        name: "offset",
                        options: {
                            offset: [0, -10],
                        },
                    },
                ],
            }}
            classes={{ popper: className }}
        />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: `${currentMode == "dark" ? "#1F1F1F" : theme.palette.common.white}`,
            color: "rgba(0, 0, 0, 0.87)",
            boxShadow: "0px 4px 20px rgba(85,85,85,0.2)",
            borderRadius: "10px",
            minWidth: "250px",
        },
    }));
    const Closepage = () => {
        dispatch({ type: ROLE_PERMISSION_VIEW_RESET });
    };
    const [isOpen, setIsOpen] = useState(false);
    const handleKeyDown = (event, name) => {
        if (event.key === "Enter") {
            setIsOpen(true);
        }
    };
    const handleClickTooltip = () => {
        setIsOpen(true);
    };
    //* For User - more Permissions
    const [isUserTooltipOpen, setUserTooltipOpen] = useState(false);

    const handleUserSpanClick = () => {
        setUserTooltipOpen(true);
    };
    const handleUserTooltipOpen = () => {
        setUserTooltipOpen(true);
        setInvoiceTooltipOpen(false);
        setTooltipOpen(false);
    };
    const [isPaymentTooltipOpen, setPaymentOpen] = useState({
        Advance: false,
        Misc: false,
        Retention: false,
    });
    const handlePaymentTooltipOpen = () => {
        setPaymentOpen(true);
        setInvoiceTooltipOpen(false);
        setTooltipOpen(false);
    };
    //* For invoice - more Permissions
    const [isInvoiceTooltipOpen, setInvoiceTooltipOpen] = useState(false);
    const handleInvoiceSpanClick = () => {
        setInvoiceTooltipOpen(true);
    };
    const handleInvoiceTooltipOpen = () => {
        setInvoiceTooltipOpen(true);
        setUserTooltipOpen(false);
        setTooltipOpen(false);
    };
    //* For approval - more Permissions
    const [isTooltipOpen, setTooltipOpen] = useState(false);
    const handleSpanClick = () => {
        setTooltipOpen(true);
    };
    const handleTooltipOpen = () => {
        setTooltipOpen(true);
        setUserTooltipOpen(false);
        setInvoiceTooltipOpen(false);
    };
    const currentUser = getCurrentUser();
    const ShowProffesionIcon = currentUser?.license_type !== 4;
    return (
        <>
            <Layout>
                {requestComplete && data !== undefined && data.status !== undefined && data.status && (
                    <SuccessMessageBox success={RoleIDINt ? succesMsg : addMsg} />
                )}
                {requestComplete && data !== undefined && data.status !== undefined && !data.status && <MessageBox error={data.message} />}
                {error && !data?.status && <MessageBox error={error?.error} />}

                <Main>
                    <div className="flex justify-between items-center mb-6">
                        <div>
                            <h4 className={`text-2xl font-interSb text-${currentMode}-copy`}>User Roles</h4>
                        </div>
                    </div>

                    <Card
                        className={`h-[calc(100vh_-_18.5rem)] xl:h-[calc(100vh_-_12.5rem)] max-h-[-webkit-fill-available] w-full overflow-y-auto ${
                            currentMode === "dark" ? "" : "shadow-[0px_4px_20px_rgba(238,238,238,0.5)]"
                        } pb-0 relative `}
                    >
                        {RoleViewList?.fetching ? (
                            <Loader className="h-[calc(100vh_-_15rem)]" />
                        ) : (
                            <>
                                <div className="my-[2.5rem] px-[30px] w-full">
                                    <div className="form-group flex mb-4">
                                        <label className="mt-3">
                                            Role Name <span className="text-light-150">*</span>
                                        </label>
                                        <Input
                                            className={`border-[rgba(85,85,85,0.4)] min-w-[300px] border-[1px] rounded-[8px] py-2 ml-[5rem] px-3  ${
                                                currentMode == "dark" ? "disabled:text-white" : "disabled:text-light-40"
                                            } bg-${currentMode}-810`}
                                            placeholder="Role Name"
                                            type="text"
                                            autoFocus
                                            disabled={ViewRoleId !== null}
                                            value={roleName}
                                            onChange={(e) => setroleName(e.target.value)}
                                        />
                                    </div>
                                    {errormsgs?.message && <p className="text-light-50 text-xs mt-[-.9rem] ml-[170px] mb-4">{errormsgs?.message}</p>}
                                    <div className="form-group flex">
                                        <label className="mt-3">Description</label>
                                        <textarea
                                            placeholder="Max. 250 characters"
                                            rows="5"
                                            cols=""
                                            disabled={ViewRoleId !== null}
                                            value={description}
                                            onChange={(e) => setDescription(e.target.value)}
                                            className={`ml-[5.5rem] border-[1px] min-w-[300px] rounded-[8px] border-[rgba(85,85,85,0.4)] px-3 py-2 resize-none bg-${currentMode}-810`}
                                        />
                                    </div>
                                    {descriptionError?.message && (
                                        <p className="text-light-50 text-xs ml-[200px] mb-2">{descriptionError?.message}</p>
                                    )}
                                </div>
                                <div className="mb-10 w-full">
                                    <div className="overflow-x-auto">
                                        <table
                                            className={`w-full mb-7 bg-${currentMode}-card rolemasterTable  dual text-left  border-${currentMode}-700 `}
                                        >
                                            <thead>
                                                <tr>
                                                    <th className="font-interSb">Organization Management</th>
                                                    <th></th>
                                                    <th></th>
                                                    <th></th>
                                                    <th></th>
                                                    <th></th>
                                                    <th></th>
                                                    {/* <th></th>
                            <th></th> */}
                                                    <th>
                                                        <FormControlLabel
                                                            sx={{
                                                                minWidth: "120px",
                                                            }}
                                                            control={
                                                                <Checkbox
                                                                    id={30}
                                                                    checked={orgSelectAll}
                                                                    disabled={ViewRoleId !== null}
                                                                    title="Checkbox"
                                                                    indeterminate={isIndeterminate}
                                                                    onChange={(event) => {
                                                                        handleCheckboxChange(
                                                                            {
                                                                                target: {
                                                                                    name: event.target.name,
                                                                                    value: event.target.checked,
                                                                                },
                                                                            },
                                                                            30
                                                                        );
                                                                    }}
                                                                    sx={{
                                                                        color: "rgba(85,85,85,0.7)",
                                                                        "&.Mui-checked": {
                                                                            color: "#028C78",
                                                                        },
                                                                        "&.Mui-disabled": {
                                                                            pointerEvents: "auto",
                                                                            cursor: "not-allowed",
                                                                            color: `${ViewRoleId !== null ? "028C78" : " rgba(85,85,85,0.7)"}`,
                                                                            opacity: "0.5",
                                                                        },
                                                                    }}
                                                                />
                                                            }
                                                            label={
                                                                <span
                                                                    style={{
                                                                        color: `${currentMode === "dark" ? "white" : "#000000"}`,
                                                                    }}
                                                                >
                                                                    Select All
                                                                </span>
                                                            }
                                                        />
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <>
                                                    <tr>
                                                        <>
                                                            <td></td>
                                                            <td>
                                                                Full <br></br> Access
                                                            </td>
                                                            <td>View</td>
                                                            <td>Add</td>
                                                            <td>Edit</td>
                                                            <td>Activate</td>
                                                            <td>Deactivate</td>
                                                            <td>Delete</td>
                                                            {/* <td></td>
                                <td></td> */}
                                                        </>
                                                    </tr>
                                                    {orgHeader?.map((ele, index) => {
                                                        return (
                                                            <React.Fragment key={index}>
                                                                <tr>
                                                                    <td>{ele?.title}</td>

                                                                    {orgAct?.map((elem, index) => {
                                                                        return (
                                                                            <React.Fragment key={index}>
                                                                                <td className={elem.id == 12 && `bg-${currentMode}-1310`}>
                                                                                    <Checkbox
                                                                                        checked={elem?.isallowed}
                                                                                        id={elem?.id}
                                                                                        title="Checkbox"
                                                                                        // indeterminate={elem?.isallowed}
                                                                                        disabled={elem?.isactive || ViewRoleId !== null}
                                                                                        className={elem.id == 12 && `hidden`}
                                                                                        onChange={(event) => {
                                                                                            handleCheckboxChange(
                                                                                                {
                                                                                                    target: {
                                                                                                        name: event.target.name,
                                                                                                        value: event.target.checked,
                                                                                                    },
                                                                                                },
                                                                                                elem?.id,
                                                                                                ele?.menu_id
                                                                                            );
                                                                                        }}
                                                                                        sx={{
                                                                                            padding: 0,
                                                                                            color: "rgba(85,85,85,0.7)",
                                                                                            "&.Mui-checked": {
                                                                                                color: "#028C78",
                                                                                            },
                                                                                            "&.Mui-disabled": {
                                                                                                pointerEvents: "auto",
                                                                                                cursor: "not-allowed",
                                                                                                color: `${
                                                                                                    ViewRoleId !== null
                                                                                                        ? "028C78"
                                                                                                        : " rgba(85,85,85,0.7)"
                                                                                                }`,
                                                                                                opacity: "0.5",
                                                                                            },
                                                                                        }}
                                                                                    />
                                                                                </td>
                                                                            </React.Fragment>
                                                                        );
                                                                    })}
                                                                    {/* <td></td>
                                    <td></td> */}
                                                                </tr>
                                                            </React.Fragment>
                                                        );
                                                    })}
                                                    {emailHeader?.map((ele, index) => {
                                                        return (
                                                            <React.Fragment key={index}>
                                                                <tr>
                                                                    <td>{ele?.title}</td>

                                                                    {emailACt?.map((elem, index) => {
                                                                        return (
                                                                            <React.Fragment key={index}>
                                                                                <td
                                                                                    className={
                                                                                        (elem.id == 12 || elem?.id == 2) && `bg-${currentMode}-1310`
                                                                                    }
                                                                                >
                                                                                    {" "}
                                                                                    <Checkbox
                                                                                        checked={elem?.isallowed}
                                                                                        id={elem?.id}
                                                                                        title="Checkbox"
                                                                                        disabled={elem?.isactive || ViewRoleId !== null}
                                                                                        className={
                                                                                            (elem.id == 12 || elem.id == 2) && `opacity-[0] invisible`
                                                                                        }
                                                                                        onChange={(event) => {
                                                                                            handleCheckboxChange(
                                                                                                {
                                                                                                    target: {
                                                                                                        name: event.target.name,
                                                                                                        value: event.target.checked,
                                                                                                    },
                                                                                                },
                                                                                                elem?.id,
                                                                                                ele?.menu_id
                                                                                            );
                                                                                        }}
                                                                                        sx={{
                                                                                            padding: 0,
                                                                                            color: "rgba(85,85,85,0.7)",
                                                                                            "&.Mui-checked": {
                                                                                                color: "#028C78",
                                                                                            },
                                                                                            "&.Mui-disabled": {
                                                                                                pointerEvents: "auto",
                                                                                                cursor: "not-allowed",
                                                                                                color: `${
                                                                                                    ViewRoleId !== null
                                                                                                        ? "028C78"
                                                                                                        : " rgba(85,85,85,0.7)"
                                                                                                }`,
                                                                                                opacity: "0.5",
                                                                                            },
                                                                                        }}
                                                                                    />
                                                                                </td>
                                                                            </React.Fragment>
                                                                        );
                                                                    })}

                                                                    {/* <td></td>
                                    <td></td> */}
                                                                </tr>
                                                            </React.Fragment>
                                                        );
                                                    })}
                                                    {folderHeader?.map((ele, index) => {
                                                        return (
                                                            <React.Fragment key={index}>
                                                                <tr>
                                                                    <td>{ele?.title}</td>

                                                                    {folderAct?.map((elem, index) => {
                                                                        return (
                                                                            <React.Fragment key={index}>
                                                                                <td
                                                                                    className={
                                                                                        (elem.id == 12 || elem?.id == 2) && `bg-${currentMode}-1310`
                                                                                    }
                                                                                >
                                                                                    {" "}
                                                                                    <Checkbox
                                                                                        checked={elem?.isallowed}
                                                                                        id={elem?.id}
                                                                                        title="Checkbox"
                                                                                        disabled={elem?.isactive || ViewRoleId !== null}
                                                                                        className={
                                                                                            (elem.id == 12 || elem.id == 2) && `opacity-[0] invisible`
                                                                                        }
                                                                                        onChange={(event) => {
                                                                                            handleCheckboxChange(
                                                                                                {
                                                                                                    target: {
                                                                                                        name: event.target.name,
                                                                                                        value: event.target.checked,
                                                                                                    },
                                                                                                },
                                                                                                elem?.id,
                                                                                                ele?.menu_id
                                                                                            );
                                                                                        }}
                                                                                        sx={{
                                                                                            padding: 0,
                                                                                            color: "rgba(85,85,85,0.7)",
                                                                                            "&.Mui-checked": {
                                                                                                color: "#028C78",
                                                                                            },
                                                                                            "&.Mui-disabled": {
                                                                                                pointerEvents: "auto",
                                                                                                cursor: "not-allowed",
                                                                                                color: `${
                                                                                                    ViewRoleId !== null
                                                                                                        ? "028C78"
                                                                                                        : " rgba(85,85,85,0.7)"
                                                                                                }`,
                                                                                                opacity: "0.5",
                                                                                            },
                                                                                        }}
                                                                                    />
                                                                                </td>
                                                                            </React.Fragment>
                                                                        );
                                                                    })}
                                                                    {/*
                                    <td></td>
                                    <td></td> */}
                                                                </tr>
                                                            </React.Fragment>
                                                        );
                                                    })}
                                                    {TargetHeader?.map((ele, index) => {
                                                        return (
                                                            <React.Fragment key={index}>
                                                                <tr>
                                                                    <td>{ele?.title}</td>

                                                                    {targetAct?.map((elem, index) => {
                                                                        return (
                                                                            <React.Fragment key={index}>
                                                                                <td className={elem.id == 12 && `bg-${currentMode}-1310`}>
                                                                                    {" "}
                                                                                    <Checkbox
                                                                                        checked={elem?.isallowed}
                                                                                        id={elem?.id}
                                                                                        title="Checkbox"
                                                                                        disabled={elem?.isactive || ViewRoleId !== null}
                                                                                        className={elem.id == 12 && `opacity-[0] invisible`}
                                                                                        onChange={(event) => {
                                                                                            handleCheckboxChange(
                                                                                                {
                                                                                                    target: {
                                                                                                        name: event.target.name,
                                                                                                        value: event.target.checked,
                                                                                                    },
                                                                                                },
                                                                                                elem?.id,
                                                                                                ele?.menu_id
                                                                                            );
                                                                                        }}
                                                                                        sx={{
                                                                                            padding: 0,
                                                                                            color: "rgba(85,85,85,0.7)",
                                                                                            "&.Mui-checked": {
                                                                                                color: "#028C78",
                                                                                            },
                                                                                            "&.Mui-disabled": {
                                                                                                pointerEvents: "auto",
                                                                                                cursor: "not-allowed",
                                                                                                color: `${
                                                                                                    ViewRoleId !== null
                                                                                                        ? "028C78"
                                                                                                        : " rgba(85,85,85,0.7)"
                                                                                                }`,
                                                                                                opacity: "0.5",
                                                                                            },
                                                                                        }}
                                                                                    />
                                                                                </td>
                                                                            </React.Fragment>
                                                                        );
                                                                    })}
                                                                    {/* <td></td>
                                    <td></td> */}
                                                                </tr>
                                                            </React.Fragment>
                                                        );
                                                    })}
                                                    {userRoleHeader?.map((ele, index) => {
                                                        return (
                                                            <React.Fragment key={index}>
                                                                <tr>
                                                                    <td>{ele?.title}</td>

                                                                    {userRoleAct?.map((elem, index) => {
                                                                        return (
                                                                            <React.Fragment key={index}>
                                                                                <td
                                                                                    className={
                                                                                        (elem.id == 4 || elem.id == 5 || elem.id == 2) &&
                                                                                        `bg-${currentMode}-1310`
                                                                                    }
                                                                                >
                                                                                    {" "}
                                                                                    <Checkbox
                                                                                        checked={elem?.isallowed}
                                                                                        id={elem?.id}
                                                                                        title="Checkbox"
                                                                                        disabled={elem?.isactive || ViewRoleId !== null}
                                                                                        className={
                                                                                            (elem.id == 4 || elem.id == 5 || elem.id == 2) &&
                                                                                            `opacity-[0] invisible`
                                                                                        }
                                                                                        onChange={(event) => {
                                                                                            handleCheckboxChange(
                                                                                                {
                                                                                                    target: {
                                                                                                        name: event.target.name,
                                                                                                        value: event.target.checked,
                                                                                                    },
                                                                                                },
                                                                                                elem?.id,
                                                                                                ele?.menu_id
                                                                                            );
                                                                                        }}
                                                                                        sx={{
                                                                                            color: "rgba(85,85,85,0.7)",
                                                                                            "&.Mui-checked": {
                                                                                                color: "#028C78",
                                                                                            },
                                                                                            "&.Mui-disabled": {
                                                                                                pointerEvents: "auto",
                                                                                                cursor: "not-allowed",
                                                                                                color: `${
                                                                                                    ViewRoleId !== null
                                                                                                        ? "028C78"
                                                                                                        : " rgba(85,85,85,0.7)"
                                                                                                }`,
                                                                                                opacity: "0.5",
                                                                                            },
                                                                                        }}
                                                                                    />
                                                                                </td>
                                                                            </React.Fragment>
                                                                        );
                                                                    })}
                                                                    {/* <td></td>
                                    <td></td> */}
                                                                </tr>
                                                            </React.Fragment>
                                                        );
                                                    })}
                                                </>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="overflow-x-auto">
                                        <table
                                            className={`xl:w-full w-max mb-7 bg-${currentMode}-card rolemasterTable dual text-left  border-${currentMode}-700 `}
                                        >
                                            <thead>
                                                <tr>
                                                    <th className="font-interSb">User Management</th>
                                                    <th></th>
                                                    <th></th>
                                                    <th></th>
                                                    <th></th>
                                                    <th></th>
                                                    <th></th>
                                                    <th></th>
                                                    <th>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    id={31}
                                                                    checked={userSelectAll}
                                                                    title="Checkbox"
                                                                    disabled={ViewRoleId !== null}
                                                                    onChange={(event) => {
                                                                        handleCheckboxChange(
                                                                            {
                                                                                target: {
                                                                                    name: event.target.name,
                                                                                    value: event.target.checked,
                                                                                },
                                                                            },
                                                                            31
                                                                        );
                                                                    }}
                                                                    sx={{
                                                                        color: "rgba(85,85,85,0.7)",
                                                                        "&.Mui-checked": {
                                                                            color: "#028C78",
                                                                        },
                                                                        "&.Mui-disabled": {
                                                                            pointerEvents: "auto",
                                                                            cursor: "not-allowed",
                                                                            color: `${ViewRoleId !== null ? "028C78" : " rgba(85,85,85,0.7)"}`,
                                                                            opacity: "0.5",
                                                                        },
                                                                    }}
                                                                />
                                                            }
                                                            label={
                                                                <span
                                                                    style={{
                                                                        color: `${currentMode === "dark" ? "white" : "#000000"}`,
                                                                    }}
                                                                >
                                                                    Select All
                                                                </span>
                                                            }
                                                        />
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <>
                                                        <td></td>
                                                        <td>
                                                            Full <br></br> Access
                                                        </td>
                                                        <td>View</td>
                                                        <td>Add</td>
                                                        <td>Edit</td>
                                                        <td>Activate</td>
                                                        <td>Deactivate</td>
                                                        <td>Delete</td>
                                                        <td></td>
                                                    </>
                                                </tr>
                                                {userHeader?.map((ele, index) => {
                                                    return (
                                                        <React.Fragment key={index}>
                                                            <tr>
                                                                <td>{ele?.title}</td>

                                                                {userActions?.map((elem, index) => {
                                                                    return (
                                                                        <React.Fragment key={index}>
                                                                            <td className={elem.id == 12 && `bg-${currentMode}-1310`}>
                                                                                {elem?.label == "Task Activity" ? (
                                                                                    <LightTooltip
                                                                                        title={
                                                                                            <div
                                                                                                className={`flex items-start px-4 py-3 rounded-[10px] bg-${currentMode}-card `}
                                                                                            >
                                                                                                <FormControlLabel
                                                                                                    control={
                                                                                                        <Checkbox
                                                                                                            // disabled={elem?.User?.activity===false&&true}

                                                                                                            // className={
                                                                                                            //     elem?.isactive &&
                                                                                                            //     `opacity-[0] invisible`
                                                                                                            // }
                                                                                                            id={elem?.id}
                                                                                                            title="Checkbox"
                                                                                                            checked={elem?.isallowed}
                                                                                                            disabled={
                                                                                                                elem?.isactive || ViewRoleId !== null
                                                                                                            }
                                                                                                            onChange={(event) => {
                                                                                                                handleCheckboxChange(
                                                                                                                    {
                                                                                                                        target: {
                                                                                                                            name: event.target.name,
                                                                                                                            value: event.target
                                                                                                                                .checked,
                                                                                                                        },
                                                                                                                    },
                                                                                                                    elem?.id,
                                                                                                                    ele?.menu_id
                                                                                                                );
                                                                                                            }}
                                                                                                            sx={{
                                                                                                                color: "rgba(85,85,85,0.7)",
                                                                                                                "&.Mui-checked": {
                                                                                                                    color: "#028C78",
                                                                                                                },
                                                                                                                "&.Mui-disabled": {
                                                                                                                    pointerEvents: "auto",
                                                                                                                    cursor: "not-allowed",
                                                                                                                    color: `${
                                                                                                                        ViewRoleId !== null
                                                                                                                            ? "028C78"
                                                                                                                            : " rgba(85,85,85,0.7)"
                                                                                                                    }`,
                                                                                                                    opacity: "0.5",
                                                                                                                },
                                                                                                            }}
                                                                                                        />
                                                                                                    }
                                                                                                    label={
                                                                                                        <span
                                                                                                            style={{
                                                                                                                color: `${
                                                                                                                    currentMode === "dark"
                                                                                                                        ? "white"
                                                                                                                        : "#555555"
                                                                                                                }`,
                                                                                                            }}
                                                                                                        >
                                                                                                            Activity log
                                                                                                        </span>
                                                                                                    }
                                                                                                />
                                                                                            </div>
                                                                                        }
                                                                                        open={isUserTooltipOpen}
                                                                                        onClose={() => setUserTooltipOpen(false)}
                                                                                    >
                                                                                        <span
                                                                                            className="cursor-pointer"
                                                                                            onMouseEnter={handleUserTooltipOpen}
                                                                                            onClick={handleUserTooltipOpen}
                                                                                        >
                                                                                            More Permissions
                                                                                        </span>
                                                                                    </LightTooltip>
                                                                                ) : (
                                                                                    <Checkbox
                                                                                        checked={elem?.isallowed}
                                                                                        id={elem?.id}
                                                                                        title="Checkbox"
                                                                                        disabled={elem?.isactive || ViewRoleId !== null}
                                                                                        className={elem?.id == 12 && `opacity-[0] invisible`}
                                                                                        onChange={(event) => {
                                                                                            handleCheckboxChange(
                                                                                                {
                                                                                                    target: {
                                                                                                        name: event.target.name,
                                                                                                        value: event.target.checked,
                                                                                                    },
                                                                                                },
                                                                                                elem?.id,
                                                                                                ele?.menu_id
                                                                                            );
                                                                                        }}
                                                                                        sx={{
                                                                                            color: "rgba(85,85,85,0.7)",
                                                                                            "&.Mui-checked": {
                                                                                                color: "#028C78",
                                                                                            },
                                                                                            "&.Mui-disabled": {
                                                                                                pointerEvents: "auto",
                                                                                                cursor: "not-allowed",
                                                                                                color: `${
                                                                                                    ViewRoleId !== null
                                                                                                        ? "028C78"
                                                                                                        : " rgba(85,85,85,0.7)"
                                                                                                }`,
                                                                                                opacity: "0.5",
                                                                                            },
                                                                                        }}
                                                                                    />
                                                                                )}
                                                                            </td>
                                                                        </React.Fragment>
                                                                    );
                                                                })}
                                                            </tr>
                                                        </React.Fragment>
                                                    );
                                                })}

                                                {orgRoleHeader?.map((ele, index) => {
                                                    return (
                                                        <React.Fragment key={index}>
                                                            <tr>
                                                                <td>{ele?.title}</td>

                                                                {organisationRoleAct?.map((elem, index) => {
                                                                    return (
                                                                        <React.Fragment key={index}>
                                                                            <td
                                                                                className={
                                                                                    (elem?.id == 2 || elem?.id == 4 || elem?.id == 5) &&
                                                                                    `bg-${currentMode}-1310`
                                                                                }
                                                                            >
                                                                                {" "}
                                                                                <Checkbox
                                                                                    checked={elem?.isallowed}
                                                                                    id={elem?.id}
                                                                                    title="Checkbox"
                                                                                    disabled={elem?.isactive || ViewRoleId !== null}
                                                                                    className={
                                                                                        (elem?.id == 2 || elem?.id == 4 || elem?.id == 5) &&
                                                                                        `opacity-[0] invisible`
                                                                                    }
                                                                                    onChange={(event) => {
                                                                                        handleCheckboxChange(
                                                                                            {
                                                                                                target: {
                                                                                                    name: event.target.name,
                                                                                                    value: event.target.checked,
                                                                                                },
                                                                                            },
                                                                                            elem?.id,
                                                                                            ele?.menu_id
                                                                                        );
                                                                                    }}
                                                                                    sx={{
                                                                                        color: "rgba(85,85,85,0.7)",
                                                                                        "&.Mui-checked": {
                                                                                            color: "#028C78",
                                                                                        },
                                                                                        "&.Mui-disabled": {
                                                                                            pointerEvents: "auto",
                                                                                            cursor: "not-allowed",
                                                                                            color: `${
                                                                                                ViewRoleId !== null ? "028C78" : " rgba(85,85,85,0.7)"
                                                                                            }`,
                                                                                            opacity: "0.5",
                                                                                        },
                                                                                    }}
                                                                                />
                                                                            </td>
                                                                        </React.Fragment>
                                                                    );
                                                                })}

                                                                <td className="bg-${currentMode}-1190"></td>
                                                            </tr>
                                                        </React.Fragment>
                                                    );
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="overflow-x-auto">
                                        <table
                                            className={`w-full mb-7 bg-${currentMode}-card rolemasterTable dual text-left  border-${currentMode}-700 `}
                                        >
                                            <thead>
                                                <tr>
                                                    <th className="font-interSb">Invoice Automation</th>
                                                    <th></th>
                                                    <th></th>
                                                    <th></th>
                                                    <th></th>
                                                    <th></th>
                                                    {/* <th></th> */}

                                                    <th>
                                                        <FormControlLabel
                                                            sx={{
                                                                minWidth: "120px",
                                                            }}
                                                            control={
                                                                <Checkbox
                                                                    id={32}
                                                                    title="Checkbox"
                                                                    checked={invoiceSelectAll}
                                                                    disabled={ViewRoleId !== null}
                                                                    onChange={(event) => {
                                                                        handleCheckboxChange(
                                                                            {
                                                                                target: {
                                                                                    name: event.target.name,
                                                                                    value: event.target.checked,
                                                                                },
                                                                            },
                                                                            32
                                                                        );
                                                                    }}
                                                                    sx={{
                                                                        color: "rgba(85,85,85,0.7)",
                                                                        "&.Mui-checked": {
                                                                            color: "#028C78",
                                                                        },
                                                                        "&.Mui-disabled": {
                                                                            pointerEvents: "auto",
                                                                            cursor: "not-allowed",
                                                                            color: `${ViewRoleId !== null ? "028C78" : " rgba(85,85,85,0.7)"}`,
                                                                            opacity: "0.5",
                                                                        },
                                                                    }}
                                                                />
                                                            }
                                                            label={
                                                                <span
                                                                    style={{
                                                                        color: `${currentMode === "dark" ? "white" : "#000000"}`,
                                                                    }}
                                                                >
                                                                    Select All
                                                                </span>
                                                            }
                                                        />
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <>
                                                        <td></td>
                                                        <td>
                                                            Full <br></br> Access
                                                        </td>
                                                        <td>View</td>
                                                        <td>Edit</td>
                                                        <td>Approve</td>
                                                        <td>Reject</td>
                                                        <td></td>
                                                    </>
                                                </tr>
                                                {UploadHeader?.map((ele, index) => {
                                                    return (
                                                        <React.Fragment key={index}>
                                                            <tr>
                                                                <td>{ele?.title}</td>

                                                                {uploadAct?.map((elem, index) => {
                                                                    return (
                                                                        <React.Fragment key={index}>
                                                                            <td className={elem?.id !== 21 && `bg-${currentMode}-1310`}>
                                                                                {" "}
                                                                                <Checkbox
                                                                                    checked={elem?.isallowed}
                                                                                    id={elem?.id}
                                                                                    title="Checkbox"
                                                                                    disabled={elem?.isactive || ViewRoleId !== null}
                                                                                    className={elem?.id !== 21 && `opacity-[0] invisible`}
                                                                                    onChange={(event) => {
                                                                                        handleCheckboxChange(
                                                                                            {
                                                                                                target: {
                                                                                                    name: event.target.name,
                                                                                                    value: event.target.checked,
                                                                                                },
                                                                                            },
                                                                                            elem?.id,
                                                                                            ele?.menu_id
                                                                                        );
                                                                                    }}
                                                                                    sx={{
                                                                                        color: "rgba(85,85,85,0.7)",
                                                                                        "&.Mui-checked": {
                                                                                            color: "#028C78",
                                                                                        },
                                                                                        "&.Mui-disabled": {
                                                                                            pointerEvents: "auto",
                                                                                            cursor: "not-allowed",
                                                                                            color: `${
                                                                                                ViewRoleId !== null ? "028C78" : " rgba(85,85,85,0.7)"
                                                                                            }`,
                                                                                            opacity: "0.5",
                                                                                        },
                                                                                    }}
                                                                                />
                                                                            </td>
                                                                        </React.Fragment>
                                                                    );
                                                                })}
                                                            </tr>
                                                        </React.Fragment>
                                                    );
                                                })}
                                                {ProcessHeader?.map((ele, index) => {
                                                    return (
                                                        <React.Fragment key={index}>
                                                            <tr>
                                                                <td>{"Invoice"}</td>

                                                                {processAct?.map((elem, index) => {
                                                                    let lastIndex = index === processAct?.length - 1;

                                                                    return lastIndex ? (
                                                                        <td>
                                                                            <LightTooltip
                                                                                title={
                                                                                    <div
                                                                                        className={`flex flex-col items-start px-4 py-3 rounded-[10px] bg-${currentMode}-card `}
                                                                                    >
                                                                                        {morePermissions?.map((element, index) => {
                                                                                            return (
                                                                                                <div>
                                                                                                    <FormControlLabel
                                                                                                        control={
                                                                                                            <Checkbox
                                                                                                                // disabled={elem?.User?.activity===false&&true}

                                                                                                                // className={
                                                                                                                //     elem?.isactive &&
                                                                                                                //     `opacity-[0] invisible`
                                                                                                                // }
                                                                                                                id={element?.id}
                                                                                                                title="Checkbox"
                                                                                                                checked={element?.isallowed}
                                                                                                                disabled={
                                                                                                                    element?.isactive ||
                                                                                                                    ViewRoleId !== null
                                                                                                                }
                                                                                                                onChange={(event) => {
                                                                                                                    handleCheckboxChange(
                                                                                                                        {
                                                                                                                            target: {
                                                                                                                                name: event.target
                                                                                                                                    .name,
                                                                                                                                value: event.target
                                                                                                                                    .checked,
                                                                                                                            },
                                                                                                                        },
                                                                                                                        element?.id,
                                                                                                                        ele?.menu_id
                                                                                                                    );
                                                                                                                }}
                                                                                                                sx={{
                                                                                                                    color: "rgba(85,85,85,0.7)",
                                                                                                                    "&.Mui-checked": {
                                                                                                                        color: "#028C78",
                                                                                                                    },
                                                                                                                    "&.Mui-disabled": {
                                                                                                                        pointerEvents: "auto",
                                                                                                                        cursor: "not-allowed",
                                                                                                                        color: `${
                                                                                                                            ViewRoleId !== null
                                                                                                                                ? "028C78"
                                                                                                                                : " rgba(85,85,85,0.7)"
                                                                                                                        }`,
                                                                                                                        opacity: "0.5",
                                                                                                                    },
                                                                                                                }}
                                                                                                            />
                                                                                                        }
                                                                                                        label={
                                                                                                            <span
                                                                                                                style={{
                                                                                                                    color: `${
                                                                                                                        currentMode === "dark"
                                                                                                                            ? "white"
                                                                                                                            : "#555555"
                                                                                                                    }`,
                                                                                                                }}
                                                                                                            >
                                                                                                                {element?.label}
                                                                                                            </span>
                                                                                                        }
                                                                                                    />
                                                                                                </div>
                                                                                            );
                                                                                        })}
                                                                                    </div>
                                                                                }
                                                                                open={isInvoiceTooltipOpen}
                                                                                onClose={() => setInvoiceTooltipOpen(false)}
                                                                            >
                                                                                <span
                                                                                    className="cursor-pointer text-center"
                                                                                    onMouseEnter={handleInvoiceTooltipOpen}
                                                                                    onClick={handleInvoiceTooltipOpen}
                                                                                >
                                                                                    More Permissions
                                                                                </span>
                                                                            </LightTooltip>
                                                                        </td>
                                                                    ) : (
                                                                        <React.Fragment key={index}>
                                                                            <td
                                                                                className={`${
                                                                                    elem?.id == 15 ||
                                                                                    elem?.id == 16 ||
                                                                                    elem?.id == 17 ||
                                                                                    (elem?.id == 6 && `bg-${currentMode}-1310`)
                                                                                } ${
                                                                                    elem?.id == 18 || elem?.id == 13 || elem?.id == 26 ? "hidden" : ""
                                                                                }`}
                                                                            >
                                                                                {" "}
                                                                                <Checkbox
                                                                                    checked={elem?.isallowed}
                                                                                    id={elem?.id}
                                                                                    title="Checkbox"
                                                                                    disabled={elem?.isactive || ViewRoleId !== null}
                                                                                    className={
                                                                                        (elem?.id == 15 ||
                                                                                            elem?.id == 16 ||
                                                                                            elem?.id == 17 ||
                                                                                            elem?.id == 6) &&
                                                                                        `opacity-[0] invisible`
                                                                                    }
                                                                                    onChange={(event) => {
                                                                                        handleCheckboxChange(
                                                                                            {
                                                                                                target: {
                                                                                                    name: event.target.name,
                                                                                                    value: event.target.checked,
                                                                                                },
                                                                                            },
                                                                                            elem?.id,
                                                                                            ele?.menu_id
                                                                                        );
                                                                                    }}
                                                                                    sx={{
                                                                                        color: "rgba(85,85,85,0.7)",
                                                                                        "&.Mui-checked": {
                                                                                            color: "#028C78",
                                                                                        },
                                                                                        "&.Mui-disabled": {
                                                                                            pointerEvents: "auto",
                                                                                            cursor: "not-allowed",
                                                                                            color: `${
                                                                                                ViewRoleId !== null ? "028C78" : " rgba(85,85,85,0.7)"
                                                                                            }`,
                                                                                            opacity: "0.5",
                                                                                        },
                                                                                    }}
                                                                                />
                                                                            </td>
                                                                        </React.Fragment>
                                                                    );
                                                                })}
                                                            </tr>
                                                        </React.Fragment>
                                                    );
                                                })}

                                                {approvalHeader?.map((ele, index) => {
                                                    return (
                                                        <React.Fragment key={index}>
                                                            <tr>
                                                                <td>{ele?.title}</td>

                                                                {approvalAct?.map((elem, index) => {
                                                                    let lastIndexApproval = index === approvalAct?.length - 1;

                                                                    return lastIndexApproval ? (
                                                                        <td>
                                                                            <LightTooltip
                                                                                title={
                                                                                    <div
                                                                                        className={`flex flex-col items-start px-4 py-3 rounded-[10px] bg-${currentMode}-card `}
                                                                                    >
                                                                                        {ApprovalPermissions?.map((element, index) => {
                                                                                            return (
                                                                                                <div>
                                                                                                    <FormControlLabel
                                                                                                        control={
                                                                                                            <Checkbox
                                                                                                                // disabled={elem?.User?.activity===false&&true}

                                                                                                                // className={
                                                                                                                //     elem?.isactive &&
                                                                                                                //     `opacity-[0] invisible`
                                                                                                                // }
                                                                                                                id={element?.id}
                                                                                                                title="Checkbox"
                                                                                                                checked={element?.isallowed}
                                                                                                                disabled={
                                                                                                                    element?.isactive ||
                                                                                                                    ViewRoleId !== null
                                                                                                                }
                                                                                                                onChange={(event) => {
                                                                                                                    handleCheckboxChange(
                                                                                                                        {
                                                                                                                            target: {
                                                                                                                                name: event.target
                                                                                                                                    .name,
                                                                                                                                value: event.target
                                                                                                                                    .checked,
                                                                                                                            },
                                                                                                                        },
                                                                                                                        element?.id,
                                                                                                                        ele?.menu_id
                                                                                                                    );
                                                                                                                }}
                                                                                                                sx={{
                                                                                                                    color: "rgba(85,85,85,0.7)",
                                                                                                                    "&.Mui-checked": {
                                                                                                                        color: "#028C78",
                                                                                                                    },
                                                                                                                    "&.Mui-disabled": {
                                                                                                                        pointerEvents: "auto",
                                                                                                                        cursor: "not-allowed",
                                                                                                                        color: `${
                                                                                                                            ViewRoleId !== null
                                                                                                                                ? "028C78"
                                                                                                                                : " rgba(85,85,85,0.7)"
                                                                                                                        }`,
                                                                                                                        opacity: "0.5",
                                                                                                                    },
                                                                                                                }}
                                                                                                            />
                                                                                                        }
                                                                                                        label={
                                                                                                            <span
                                                                                                                style={{
                                                                                                                    color: `${
                                                                                                                        currentMode === "dark"
                                                                                                                            ? "white"
                                                                                                                            : "#555555"
                                                                                                                    }`,
                                                                                                                }}
                                                                                                            >
                                                                                                                {element?.label}
                                                                                                            </span>
                                                                                                        }
                                                                                                    />
                                                                                                </div>
                                                                                            );
                                                                                        })}
                                                                                    </div>
                                                                                }
                                                                                open={isTooltipOpen}
                                                                                onClose={() => setTooltipOpen(false)}
                                                                            >
                                                                                <span
                                                                                    className="cursor-pointer"
                                                                                    onMouseEnter={handleTooltipOpen}
                                                                                    onClick={handleTooltipOpen}
                                                                                >
                                                                                    More Permissions
                                                                                </span>
                                                                            </LightTooltip>
                                                                        </td>
                                                                    ) : (
                                                                        <React.Fragment key={index}>
                                                                            <td
                                                                                className={`${
                                                                                    elem?.id == 15 ||
                                                                                    elem?.id == 16 ||
                                                                                    elem?.id == 17 ||
                                                                                    (elem?.id == 6 && `bg-${currentMode}-1310`)
                                                                                } ${elem?.id == 18 || elem?.id == 13 ? "hidden" : ""}`}
                                                                            >
                                                                                {" "}
                                                                                <Checkbox
                                                                                    checked={elem?.isallowed}
                                                                                    id={elem?.id}
                                                                                    title="Checkbox"
                                                                                    disabled={elem?.isactive || ViewRoleId !== null}
                                                                                    className={
                                                                                        (elem?.id == 15 ||
                                                                                            elem?.id == 16 ||
                                                                                            elem?.id == 17 ||
                                                                                            elem?.id == 2) &&
                                                                                        `opacity-[0] invisible`
                                                                                    }
                                                                                    onChange={(event) => {
                                                                                        handleCheckboxChange(
                                                                                            {
                                                                                                target: {
                                                                                                    name: event.target.name,
                                                                                                    value: event.target.checked,
                                                                                                },
                                                                                            },
                                                                                            elem?.id,
                                                                                            ele?.menu_id
                                                                                        );
                                                                                    }}
                                                                                    sx={{
                                                                                        color: "rgba(85,85,85,0.7)",
                                                                                        "&.Mui-checked": {
                                                                                            color: "#028C78",
                                                                                        },
                                                                                        "&.Mui-disabled": {
                                                                                            pointerEvents: "auto",
                                                                                            cursor: "not-allowed",
                                                                                            color: `${
                                                                                                ViewRoleId !== null ? "028C78" : " rgba(85,85,85,0.7)"
                                                                                            }`,
                                                                                            opacity: "0.5",
                                                                                        },
                                                                                    }}
                                                                                />
                                                                            </td>
                                                                        </React.Fragment>
                                                                    );
                                                                })}
                                                            </tr>
                                                        </React.Fragment>
                                                    );
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                    {ShowPaymentReq && (
                                        <div className="overflow-x-auto">
                                            <table
                                                className={`w-full mb-7 bg-${currentMode}-card rolemasterTable dual text-left  border-${currentMode}-700 `}
                                            >
                                                <thead>
                                                    <tr>
                                                        <th className="font-interSb">Payment Request</th>
                                                        <th></th>
                                                        <th></th>
                                                        <th></th>
                                                        <th></th>
                                                        <th></th>
                                                        <th></th>

                                                        <th>
                                                            <FormControlLabel
                                                                sx={{
                                                                    minWidth: "120px",
                                                                }}
                                                                control={
                                                                    <Checkbox
                                                                        id={34}
                                                                        title="Checkbox"
                                                                        checked={paymnetSelectAll}
                                                                        disabled={ViewRoleId !== null}
                                                                        onChange={(event) => {
                                                                            handleCheckboxChange(
                                                                                {
                                                                                    target: {
                                                                                        name: event.target.name,
                                                                                        value: event.target.checked,
                                                                                    },
                                                                                },
                                                                                34
                                                                            );
                                                                        }}
                                                                        sx={{
                                                                            color: "rgba(85,85,85,0.7)",
                                                                            "&.Mui-checked": {
                                                                                color: "#028C78",
                                                                            },
                                                                            "&.Mui-disabled": {
                                                                                pointerEvents: "auto",
                                                                                cursor: "not-allowed",
                                                                                color: `${ViewRoleId !== null ? "028C78" : " rgba(85,85,85,0.7)"}`,
                                                                                opacity: "0.5",
                                                                            },
                                                                        }}
                                                                    />
                                                                }
                                                                label={
                                                                    <span
                                                                        style={{
                                                                            color: `${currentMode === "dark" ? "white" : "#000000"}`,
                                                                        }}
                                                                    >
                                                                        Select All
                                                                    </span>
                                                                }
                                                            />
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <>
                                                            <td></td>
                                                            <td>
                                                                Full <br></br> Access
                                                            </td>
                                                            <td>View</td>
                                                            <td>Create</td>
                                                            <td>Edit</td>
                                                            <td>Approve</td>
                                                            <td>Reject</td>
                                                            <td></td>
                                                        </>
                                                    </tr>
                                                    {FindAdvance && (
                                                        <>
                                                            {AdvanceHeader?.map((ele, index) => {
                                                                return (
                                                                    <React.Fragment key={index}>
                                                                        <tr>
                                                                            <td>{ele?.title}</td>

                                                                            {advanceAct?.map((elem, index) => {
                                                                                return (
                                                                                    <React.Fragment key={index}>
                                                                                        <td>
                                                                                            {elem?.label == "Activity" ? (
                                                                                                <LightTooltip
                                                                                                    title={
                                                                                                        <div
                                                                                                            className={`flex items-start px-4 py-3 rounded-[10px] bg-${currentMode}-card `}
                                                                                                        >
                                                                                                            <FormControlLabel
                                                                                                                control={
                                                                                                                    <Checkbox
                                                                                                                        // disabled={elem?.User?.activity===false&&true}

                                                                                                                        // className={
                                                                                                                        //     elem?.isactive &&
                                                                                                                        //     `opacity-[0] invisible`
                                                                                                                        // }
                                                                                                                        id={elem?.id}
                                                                                                                        title="Checkbox"
                                                                                                                        checked={elem?.isallowed}
                                                                                                                        disabled={
                                                                                                                            elem?.isactive ||
                                                                                                                            ViewRoleId !== null
                                                                                                                        }
                                                                                                                        onChange={(event) => {
                                                                                                                            handleCheckboxChange(
                                                                                                                                {
                                                                                                                                    target: {
                                                                                                                                        name: event
                                                                                                                                            .target
                                                                                                                                            .name,
                                                                                                                                        value: event
                                                                                                                                            .target
                                                                                                                                            .checked,
                                                                                                                                    },
                                                                                                                                },
                                                                                                                                elem?.id,
                                                                                                                                ele?.menu_id
                                                                                                                            );
                                                                                                                        }}
                                                                                                                        sx={{
                                                                                                                            color: "rgba(85,85,85,0.7)",
                                                                                                                            "&.Mui-checked": {
                                                                                                                                color: "#028C78",
                                                                                                                            },
                                                                                                                            "&.Mui-disabled": {
                                                                                                                                pointerEvents: "auto",
                                                                                                                                cursor: "not-allowed",
                                                                                                                                color: `${
                                                                                                                                    ViewRoleId !==
                                                                                                                                    null
                                                                                                                                        ? "028C78"
                                                                                                                                        : " rgba(85,85,85,0.7)"
                                                                                                                                }`,
                                                                                                                                opacity: "0.5",
                                                                                                                            },
                                                                                                                        }}
                                                                                                                    />
                                                                                                                }
                                                                                                                label={
                                                                                                                    <span
                                                                                                                        style={{
                                                                                                                            color: `${
                                                                                                                                currentMode === "dark"
                                                                                                                                    ? "white"
                                                                                                                                    : "#555555"
                                                                                                                            }`,
                                                                                                                        }}
                                                                                                                    >
                                                                                                                        Activity log
                                                                                                                    </span>
                                                                                                                }
                                                                                                            />
                                                                                                        </div>
                                                                                                    }
                                                                                                    open={isPaymentTooltipOpen?.Advance}
                                                                                                    onClose={() => handlePaymentTooltipOpen(false)}
                                                                                                >
                                                                                                    <span
                                                                                                        className="cursor-pointer"
                                                                                                        onMouseEnter={handlePaymentTooltipOpen}
                                                                                                        onClick={handlePaymentTooltipOpen}
                                                                                                    >
                                                                                                        More Permissions
                                                                                                    </span>
                                                                                                </LightTooltip>
                                                                                            ) : (
                                                                                                <Checkbox
                                                                                                    checked={elem?.isallowed}
                                                                                                    id={elem?.id}
                                                                                                    title="Checkbox"
                                                                                                    disabled={elem?.isactive || ViewRoleId !== null}
                                                                                                    // className={elem?.id !== 21 && `opacity-[0] invisible`}
                                                                                                    onChange={(event) => {
                                                                                                        handleCheckboxChange(
                                                                                                            {
                                                                                                                target: {
                                                                                                                    name: event.target.name,
                                                                                                                    value: event.target.checked,
                                                                                                                },
                                                                                                            },
                                                                                                            elem?.id,
                                                                                                            ele?.menu_id
                                                                                                        );
                                                                                                    }}
                                                                                                    sx={{
                                                                                                        color: "rgba(85,85,85,0.7)",
                                                                                                        "&.Mui-checked": {
                                                                                                            color: "#028C78",
                                                                                                        },
                                                                                                        "&.Mui-disabled": {
                                                                                                            pointerEvents: "auto",
                                                                                                            cursor: "not-allowed",
                                                                                                            color: `${
                                                                                                                ViewRoleId !== null
                                                                                                                    ? "028C78"
                                                                                                                    : " rgba(85,85,85,0.7)"
                                                                                                            }`,
                                                                                                            opacity: "0.5",
                                                                                                        },
                                                                                                    }}
                                                                                                />
                                                                                            )}{" "}
                                                                                        </td>
                                                                                    </React.Fragment>
                                                                                );
                                                                            })}
                                                                        </tr>
                                                                    </React.Fragment>
                                                                );
                                                            })}
                                                        </>
                                                    )}
                                                    {FindMisc && (
                                                        <>
                                                            {MiscHeader?.map((ele, index) => {
                                                                return (
                                                                    <React.Fragment key={index}>
                                                                        <tr>
                                                                            <td>{ele?.title}</td>

                                                                            {miscAct?.map((elem, index) => {
                                                                                return (
                                                                                    <React.Fragment key={index}>
                                                                                        <td>
                                                                                            {elem?.label == "Activity" ? (
                                                                                                <LightTooltip
                                                                                                    title={
                                                                                                        <div
                                                                                                            className={`flex items-start px-4 py-3 rounded-[10px] bg-${currentMode}-card `}
                                                                                                        >
                                                                                                            <FormControlLabel
                                                                                                                control={
                                                                                                                    <Checkbox
                                                                                                                        // disabled={elem?.User?.activity===false&&true}

                                                                                                                        // className={
                                                                                                                        //     elem?.isactive &&
                                                                                                                        //     `opacity-[0] invisible`
                                                                                                                        // }
                                                                                                                        id={elem?.id}
                                                                                                                        title="Checkbox"
                                                                                                                        checked={elem?.isallowed}
                                                                                                                        disabled={
                                                                                                                            elem?.isactive ||
                                                                                                                            ViewRoleId !== null
                                                                                                                        }
                                                                                                                        onChange={(event) => {
                                                                                                                            handleCheckboxChange(
                                                                                                                                {
                                                                                                                                    target: {
                                                                                                                                        name: event
                                                                                                                                            .target
                                                                                                                                            .name,
                                                                                                                                        value: event
                                                                                                                                            .target
                                                                                                                                            .checked,
                                                                                                                                    },
                                                                                                                                },
                                                                                                                                elem?.id,
                                                                                                                                ele?.menu_id
                                                                                                                            );
                                                                                                                        }}
                                                                                                                        sx={{
                                                                                                                            color: "rgba(85,85,85,0.7)",
                                                                                                                            "&.Mui-checked": {
                                                                                                                                color: "#028C78",
                                                                                                                            },
                                                                                                                            "&.Mui-disabled": {
                                                                                                                                pointerEvents: "auto",
                                                                                                                                cursor: "not-allowed",
                                                                                                                                color: `${
                                                                                                                                    ViewRoleId !==
                                                                                                                                    null
                                                                                                                                        ? "028C78"
                                                                                                                                        : " rgba(85,85,85,0.7)"
                                                                                                                                }`,
                                                                                                                                opacity: "0.5",
                                                                                                                            },
                                                                                                                        }}
                                                                                                                    />
                                                                                                                }
                                                                                                                label={
                                                                                                                    <span
                                                                                                                        style={{
                                                                                                                            color: `${
                                                                                                                                currentMode === "dark"
                                                                                                                                    ? "white"
                                                                                                                                    : "#555555"
                                                                                                                            }`,
                                                                                                                        }}
                                                                                                                    >
                                                                                                                        Activity log
                                                                                                                    </span>
                                                                                                                }
                                                                                                            />
                                                                                                        </div>
                                                                                                    }
                                                                                                    open={isPaymentTooltipOpen?.Misc}
                                                                                                    onClose={() => handlePaymentTooltipOpen(false)}
                                                                                                >
                                                                                                    <span
                                                                                                        className="cursor-pointer"
                                                                                                        onMouseEnter={handlePaymentTooltipOpen}
                                                                                                        onClick={handlePaymentTooltipOpen}
                                                                                                    >
                                                                                                        More Permissions
                                                                                                    </span>
                                                                                                </LightTooltip>
                                                                                            ) : (
                                                                                                <Checkbox
                                                                                                    checked={elem?.isallowed}
                                                                                                    id={elem?.id}
                                                                                                    title="Checkbox"
                                                                                                    disabled={elem?.isactive || ViewRoleId !== null}
                                                                                                    // className={elem?.id !== 21 && `opacity-[0] invisible`}
                                                                                                    onChange={(event) => {
                                                                                                        handleCheckboxChange(
                                                                                                            {
                                                                                                                target: {
                                                                                                                    name: event.target.name,
                                                                                                                    value: event.target.checked,
                                                                                                                },
                                                                                                            },
                                                                                                            elem?.id,
                                                                                                            ele?.menu_id
                                                                                                        );
                                                                                                    }}
                                                                                                    sx={{
                                                                                                        color: "rgba(85,85,85,0.7)",
                                                                                                        "&.Mui-checked": {
                                                                                                            color: "#028C78",
                                                                                                        },
                                                                                                        "&.Mui-disabled": {
                                                                                                            pointerEvents: "auto",
                                                                                                            cursor: "not-allowed",
                                                                                                            color: `${
                                                                                                                ViewRoleId !== null
                                                                                                                    ? "028C78"
                                                                                                                    : " rgba(85,85,85,0.7)"
                                                                                                            }`,
                                                                                                            opacity: "0.5",
                                                                                                        },
                                                                                                    }}
                                                                                                />
                                                                                            )}
                                                                                        </td>
                                                                                    </React.Fragment>
                                                                                );
                                                                            })}
                                                                        </tr>
                                                                    </React.Fragment>
                                                                );
                                                            })}
                                                        </>
                                                    )}
                                                    {FindRetention && (
                                                        <>
                                                            {RetentionHeader?.map((ele, index) => {
                                                                return (
                                                                    <React.Fragment key={index}>
                                                                        <tr>
                                                                            <td>{ele?.title}</td>

                                                                            {retentionAct?.map((elem, index) => {
                                                                                return (
                                                                                    <React.Fragment key={index}>
                                                                                        <td>
                                                                                            {elem?.label == "Activity" ? (
                                                                                                <LightTooltip
                                                                                                    title={
                                                                                                        <div
                                                                                                            className={`flex items-start px-4 py-3 rounded-[10px] bg-${currentMode}-card `}
                                                                                                        >
                                                                                                            <FormControlLabel
                                                                                                                control={
                                                                                                                    <Checkbox
                                                                                                                        // disabled={elem?.User?.activity===false&&true}

                                                                                                                        // className={
                                                                                                                        //     elem?.isactive &&
                                                                                                                        //     `opacity-[0] invisible`
                                                                                                                        // }
                                                                                                                        id={elem?.id}
                                                                                                                        title="Checkbox"
                                                                                                                        checked={elem?.isallowed}
                                                                                                                        disabled={
                                                                                                                            elem?.isactive ||
                                                                                                                            ViewRoleId !== null
                                                                                                                        }
                                                                                                                        onChange={(event) => {
                                                                                                                            handleCheckboxChange(
                                                                                                                                {
                                                                                                                                    target: {
                                                                                                                                        name: event
                                                                                                                                            .target
                                                                                                                                            .name,
                                                                                                                                        value: event
                                                                                                                                            .target
                                                                                                                                            .checked,
                                                                                                                                    },
                                                                                                                                },
                                                                                                                                elem?.id,
                                                                                                                                ele?.menu_id
                                                                                                                            );
                                                                                                                        }}
                                                                                                                        sx={{
                                                                                                                            color: "rgba(85,85,85,0.7)",
                                                                                                                            "&.Mui-checked": {
                                                                                                                                color: "#028C78",
                                                                                                                            },
                                                                                                                            "&.Mui-disabled": {
                                                                                                                                pointerEvents: "auto",
                                                                                                                                cursor: "not-allowed",
                                                                                                                                color: `${
                                                                                                                                    ViewRoleId !==
                                                                                                                                    null
                                                                                                                                        ? "028C78"
                                                                                                                                        : " rgba(85,85,85,0.7)"
                                                                                                                                }`,
                                                                                                                                opacity: "0.5",
                                                                                                                            },
                                                                                                                        }}
                                                                                                                    />
                                                                                                                }
                                                                                                                label={
                                                                                                                    <span
                                                                                                                        style={{
                                                                                                                            color: `${
                                                                                                                                currentMode === "dark"
                                                                                                                                    ? "white"
                                                                                                                                    : "#555555"
                                                                                                                            }`,
                                                                                                                        }}
                                                                                                                    >
                                                                                                                        Activity log
                                                                                                                    </span>
                                                                                                                }
                                                                                                            />
                                                                                                        </div>
                                                                                                    }
                                                                                                    open={isPaymentTooltipOpen?.Retention}
                                                                                                    onClose={() => handlePaymentTooltipOpen(false)}
                                                                                                >
                                                                                                    <span
                                                                                                        className="cursor-pointer"
                                                                                                        onMouseEnter={handlePaymentTooltipOpen}
                                                                                                        onClick={handlePaymentTooltipOpen}
                                                                                                    >
                                                                                                        More Permissions
                                                                                                    </span>
                                                                                                </LightTooltip>
                                                                                            ) : (
                                                                                                <Checkbox
                                                                                                    checked={elem?.isallowed}
                                                                                                    id={elem?.id}
                                                                                                    title="Checkbox"
                                                                                                    disabled={elem?.isactive || ViewRoleId !== null}
                                                                                                    // className={elem?.id !== 21 && `opacity-[0] invisible`}
                                                                                                    onChange={(event) => {
                                                                                                        handleCheckboxChange(
                                                                                                            {
                                                                                                                target: {
                                                                                                                    name: event.target.name,
                                                                                                                    value: event.target.checked,
                                                                                                                },
                                                                                                            },
                                                                                                            elem?.id,
                                                                                                            ele?.menu_id
                                                                                                        );
                                                                                                    }}
                                                                                                    sx={{
                                                                                                        color: "rgba(85,85,85,0.7)",
                                                                                                        "&.Mui-checked": {
                                                                                                            color: "#028C78",
                                                                                                        },
                                                                                                        "&.Mui-disabled": {
                                                                                                            pointerEvents: "auto",
                                                                                                            cursor: "not-allowed",
                                                                                                            color: `${
                                                                                                                ViewRoleId !== null
                                                                                                                    ? "028C78"
                                                                                                                    : " rgba(85,85,85,0.7)"
                                                                                                            }`,
                                                                                                            opacity: "0.5",
                                                                                                        },
                                                                                                    }}
                                                                                                />
                                                                                            )}
                                                                                        </td>
                                                                                    </React.Fragment>
                                                                                );
                                                                            })}
                                                                        </tr>
                                                                    </React.Fragment>
                                                                );
                                                            })}
                                                        </>
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                    )}
                                    {/* Reports */}
                                    <div className={`overflow-x-auto `}>
                                        <div className={` bg-${currentMode}-1050  flex justify-between items-center px-[30px] py-2`}>
                                            <h4 className="font-interSb">Reports</h4>
                                            {ShowProffesionIcon && (
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            id={33}
                                                            checked={reportsSelectAll}
                                                            disabled={ViewRoleId !== null}
                                                            title="Checkbox"
                                                            onChange={(event) => {
                                                                handleCheckboxChange(
                                                                    {
                                                                        target: {
                                                                            name: event.target.name,
                                                                            value: event.target.checked,
                                                                        },
                                                                    },
                                                                    33
                                                                );
                                                            }}
                                                            sx={{
                                                                color: "rgba(85,85,85,0.7)",
                                                                "& ..MuiFormControlLabel-label": {
                                                                    fontFamily: "Inter-Regular !important",
                                                                },
                                                                "&.Mui-checked": {
                                                                    color: "#028C78",
                                                                },
                                                                "&.Mui-disabled": {
                                                                    pointerEvents: "auto",
                                                                    cursor: "not-allowed",
                                                                    color: `${ViewRoleId !== null ? "028C78" : " rgba(85,85,85,0.7)"}`,
                                                                    opacity: "0.5",
                                                                },
                                                            }}
                                                        />
                                                    }
                                                    label={
                                                        <span
                                                            style={{
                                                                color: `${currentMode === "dark" ? "white" : "#000000"}`,
                                                            }}
                                                        >
                                                            Select All
                                                        </span>
                                                    }
                                                />
                                            )}
                                        </div>

                                        <div className="px-[30px]">
                                            {ShowProffesionIcon && (
                                                <div>
                                                    {accrualreportHeader?.map((ele, index) => {
                                                        return (
                                                            <React.Fragment key={index}>
                                                                <div>
                                                                    {accrualAct?.map((elem, index) => {
                                                                        return (
                                                                            <React.Fragment key={index}>
                                                                                <FormControlLabel
                                                                                    className={`block`}
                                                                                    checked={elem?.isallowed}
                                                                                    disabled={ViewRoleId !== null}
                                                                                    onChange={(event) => {
                                                                                        handleCheckboxChange(
                                                                                            {
                                                                                                target: {
                                                                                                    name: event.target.name,
                                                                                                    value: event.target.checked,
                                                                                                },
                                                                                            },
                                                                                            elem?.id,
                                                                                            ele?.menu_id
                                                                                        );
                                                                                    }}
                                                                                    control={
                                                                                        <Checkbox
                                                                                            title="Checkbox"
                                                                                            id={elem?.id}
                                                                                            sx={{
                                                                                                color: "rgba(85,85,85,0.7)",
                                                                                                "&.Mui-checked": {
                                                                                                    color: "#028C78",
                                                                                                },
                                                                                                "&.Mui-disabled": {
                                                                                                    pointerEvents: "auto",
                                                                                                    cursor: "not-allowed",
                                                                                                    // color: `${ViewRoleId !== null && elem?.isallowed ? '028C78' : 'rgba(85,85,85,0.7)'}`,
                                                                                                    opacity: "0.5",
                                                                                                },
                                                                                            }}
                                                                                        />
                                                                                    }
                                                                                    label={
                                                                                        <span
                                                                                            style={{
                                                                                                color: `${
                                                                                                    currentMode === "dark" ? "white" : "black"
                                                                                                }`,
                                                                                                fontWeight: 500,
                                                                                            }}
                                                                                        >
                                                                                            {ele?.title}
                                                                                        </span>
                                                                                    }
                                                                                />
                                                                            </React.Fragment>
                                                                        );
                                                                    })}
                                                                </div>
                                                            </React.Fragment>
                                                        );
                                                    })}
                                                </div>
                                            )}
                                            {InvoicereportHeader?.map((ele, index) => {
                                                return (
                                                    <React.Fragment key={index}>
                                                        <div>
                                                            {InvReportAct?.map((elem, index) => {
                                                                return (
                                                                    <React.Fragment key={index}>
                                                                        <FormControlLabel
                                                                            className="block"
                                                                            checked={elem?.isallowed}
                                                                            disabled={ViewRoleId !== null}
                                                                            onChange={(event) => {
                                                                                handleCheckboxChange(
                                                                                    {
                                                                                        target: {
                                                                                            name: event.target.name,
                                                                                            value: event.target.checked,
                                                                                        },
                                                                                    },
                                                                                    elem?.id,
                                                                                    ele?.menu_id
                                                                                );
                                                                            }}
                                                                            control={
                                                                                <Checkbox
                                                                                    title="Checkbox"
                                                                                    id={elem?.id}
                                                                                    sx={{
                                                                                        color: "rgba(85,85,85,0.7)",
                                                                                        "&.Mui-checked": {
                                                                                            color: "#028C78",
                                                                                        },
                                                                                        "&.Mui-disabled": {
                                                                                            pointerEvents: "auto",
                                                                                            cursor: "not-allowed",
                                                                                            color: `${
                                                                                                ViewRoleId !== null ? "028C78" : " rgba(85,85,85,0.7)"
                                                                                            }`,
                                                                                            opacity: "0.5",
                                                                                        },
                                                                                    }}
                                                                                />
                                                                            }
                                                                            label={
                                                                                <span
                                                                                    style={{
                                                                                        color: `${currentMode === "dark" ? "white" : "black"}`,
                                                                                        fontWeight: 500,
                                                                                    }}
                                                                                >
                                                                                    {ele?.title}
                                                                                </span>
                                                                            }
                                                                        />
                                                                    </React.Fragment>
                                                                );
                                                            })}
                                                        </div>
                                                    </React.Fragment>
                                                );
                                            })}
                                            {reportAgeHeader?.map((ele, index) => {
                                                return (
                                                    <React.Fragment key={index}>
                                                        <div>
                                                            {reportAgeAct?.map((elem, index) => {
                                                                return (
                                                                    <React.Fragment key={index}>
                                                                        <FormControlLabel
                                                                            className="block"
                                                                            checked={elem?.isallowed}
                                                                            disabled={ViewRoleId !== null}
                                                                            onChange={(event) => {
                                                                                handleCheckboxChange(
                                                                                    {
                                                                                        target: {
                                                                                            name: event.target.name,
                                                                                            value: event.target.checked,
                                                                                        },
                                                                                    },
                                                                                    elem?.id,
                                                                                    ele?.menu_id
                                                                                );
                                                                            }}
                                                                            control={
                                                                                <Checkbox
                                                                                    title="Checkbox"
                                                                                    id={elem?.id}
                                                                                    sx={{
                                                                                        color: "rgba(85,85,85,0.7)",
                                                                                        "&.Mui-checked": {
                                                                                            color: "#028C78",
                                                                                        },
                                                                                        "&.Mui-disabled": {
                                                                                            pointerEvents: "auto",
                                                                                            cursor: "not-allowed",
                                                                                            color: `${
                                                                                                ViewRoleId !== null ? "028C78" : " rgba(85,85,85,0.7)"
                                                                                            }`,
                                                                                            opacity: "0.5",
                                                                                        },
                                                                                    }}
                                                                                />
                                                                            }
                                                                            label={
                                                                                <span
                                                                                    style={{
                                                                                        color: `${currentMode === "dark" ? "white" : "black"}`,
                                                                                        fontWeight: 500,
                                                                                    }}
                                                                                >
                                                                                    {ele?.title}
                                                                                </span>
                                                                            }
                                                                        />
                                                                    </React.Fragment>
                                                                );
                                                            })}
                                                        </div>
                                                    </React.Fragment>
                                                );
                                            })}
                                            {ShowProffesionIcon && (
                                                <div>
                                                    {taskReportHeader?.map((ele, index) => {
                                                        return (
                                                            <React.Fragment key={index}>
                                                                <div>
                                                                    {taskReportAct?.map((elem, index) => {
                                                                        return (
                                                                            <React.Fragment key={index}>
                                                                                <FormControlLabel
                                                                                    className="block"
                                                                                    checked={elem?.isallowed}
                                                                                    disabled={ViewRoleId !== null}
                                                                                    onChange={(event) => {
                                                                                        handleCheckboxChange(
                                                                                            {
                                                                                                target: {
                                                                                                    name: event.target.name,
                                                                                                    value: event.target.checked,
                                                                                                },
                                                                                            },
                                                                                            elem?.id,
                                                                                            ele?.menu_id
                                                                                        );
                                                                                    }}
                                                                                    control={
                                                                                        <Checkbox
                                                                                            title="Checkbox"
                                                                                            id={elem?.id}
                                                                                            sx={{
                                                                                                color: "rgba(85,85,85,0.7)",
                                                                                                "&.Mui-checked": {
                                                                                                    color: "#028C78",
                                                                                                },
                                                                                                "&.Mui-disabled": {
                                                                                                    pointerEvents: "auto",
                                                                                                    cursor: "not-allowed",
                                                                                                    color: `${
                                                                                                        ViewRoleId !== null
                                                                                                            ? "028C78"
                                                                                                            : " rgba(85,85,85,0.7)"
                                                                                                    }`,
                                                                                                    opacity: "0.5",
                                                                                                },
                                                                                            }}
                                                                                        />
                                                                                    }
                                                                                    label={
                                                                                        <span
                                                                                            style={{
                                                                                                color: `${
                                                                                                    currentMode === "dark" ? "white" : "black"
                                                                                                }`,
                                                                                                fontWeight: 500,
                                                                                            }}
                                                                                        >
                                                                                            {ele?.title}
                                                                                        </span>
                                                                                    }
                                                                                />
                                                                            </React.Fragment>
                                                                        );
                                                                    })}
                                                                </div>
                                                            </React.Fragment>
                                                        );
                                                    })}
                                                </div>
                                            )}
                                            {AdvanceReportID && (
                                                <div>
                                                    {AdvReportHeader?.map((ele, index) => {
                                                        return (
                                                            <React.Fragment key={index}>
                                                                <div>
                                                                    {advReportAct?.map((elem, index) => {
                                                                        return (
                                                                            <React.Fragment key={index}>
                                                                                <FormControlLabel
                                                                                    className="block"
                                                                                    checked={elem?.isallowed}
                                                                                    disabled={ViewRoleId !== null}
                                                                                    onChange={(event) => {
                                                                                        handleCheckboxChange(
                                                                                            {
                                                                                                target: {
                                                                                                    name: event.target.name,
                                                                                                    value: event.target.checked,
                                                                                                },
                                                                                            },
                                                                                            elem?.id,
                                                                                            ele?.menu_id
                                                                                        );
                                                                                    }}
                                                                                    control={
                                                                                        <Checkbox
                                                                                            title="Checkbox"
                                                                                            id={elem?.id}
                                                                                            sx={{
                                                                                                color: "rgba(85,85,85,0.7)",
                                                                                                "&.Mui-checked": {
                                                                                                    color: "#028C78",
                                                                                                },
                                                                                                "&.Mui-disabled": {
                                                                                                    pointerEvents: "auto",
                                                                                                    cursor: "not-allowed",
                                                                                                    color: `${
                                                                                                        ViewRoleId !== null
                                                                                                            ? "028C78"
                                                                                                            : " rgba(85,85,85,0.7)"
                                                                                                    }`,
                                                                                                    opacity: "0.5",
                                                                                                },
                                                                                            }}
                                                                                        />
                                                                                    }
                                                                                    label={
                                                                                        <span
                                                                                            style={{
                                                                                                color: `${
                                                                                                    currentMode === "dark" ? "white" : "black"
                                                                                                }`,
                                                                                                fontWeight: 500,
                                                                                            }}
                                                                                        >
                                                                                            {ele?.title}
                                                                                        </span>
                                                                                    }
                                                                                />
                                                                            </React.Fragment>
                                                                        );
                                                                    })}
                                                                </div>
                                                            </React.Fragment>
                                                        );
                                                    })}
                                                </div>
                                            )}
                                            {MiscReportID && (
                                                <div>
                                                    {MiscReportHeader?.map((ele, index) => {
                                                        return (
                                                            <React.Fragment key={index}>
                                                                <div>
                                                                    {miscReportAct?.map((elem, index) => {
                                                                        return (
                                                                            <React.Fragment key={index}>
                                                                                <FormControlLabel
                                                                                    className="block"
                                                                                    checked={elem?.isallowed}
                                                                                    disabled={ViewRoleId !== null}
                                                                                    onChange={(event) => {
                                                                                        handleCheckboxChange(
                                                                                            {
                                                                                                target: {
                                                                                                    name: event.target.name,
                                                                                                    value: event.target.checked,
                                                                                                },
                                                                                            },
                                                                                            elem?.id,
                                                                                            ele?.menu_id
                                                                                        );
                                                                                    }}
                                                                                    control={
                                                                                        <Checkbox
                                                                                            title="Checkbox"
                                                                                            id={elem?.id}
                                                                                            sx={{
                                                                                                color: "rgba(85,85,85,0.7)",
                                                                                                "&.Mui-checked": {
                                                                                                    color: "#028C78",
                                                                                                },
                                                                                                "&.Mui-disabled": {
                                                                                                    pointerEvents: "auto",
                                                                                                    cursor: "not-allowed",
                                                                                                    color: `${
                                                                                                        ViewRoleId !== null
                                                                                                            ? "028C78"
                                                                                                            : " rgba(85,85,85,0.7)"
                                                                                                    }`,
                                                                                                    opacity: "0.5",
                                                                                                },
                                                                                            }}
                                                                                        />
                                                                                    }
                                                                                    label={
                                                                                        <span
                                                                                            style={{
                                                                                                color: `${
                                                                                                    currentMode === "dark" ? "white" : "black"
                                                                                                }`,
                                                                                                fontWeight: 500,
                                                                                            }}
                                                                                        >
                                                                                            {ele?.title}
                                                                                        </span>
                                                                                    }
                                                                                />
                                                                            </React.Fragment>
                                                                        );
                                                                    })}
                                                                </div>
                                                            </React.Fragment>
                                                        );
                                                    })}
                                                </div>
                                            )}
                                            {RetentionReportID && (
                                                <div>
                                                    {retenReportHeader?.map((ele, index) => {
                                                        return (
                                                            <React.Fragment key={index}>
                                                                <div>
                                                                    {retentionReportAct?.map((elem, index) => {
                                                                        return (
                                                                            <React.Fragment key={index}>
                                                                                <FormControlLabel
                                                                                    className="block"
                                                                                    checked={elem?.isallowed}
                                                                                    disabled={ViewRoleId !== null}
                                                                                    onChange={(event) => {
                                                                                        handleCheckboxChange(
                                                                                            {
                                                                                                target: {
                                                                                                    name: event.target.name,
                                                                                                    value: event.target.checked,
                                                                                                },
                                                                                            },
                                                                                            elem?.id,
                                                                                            ele?.menu_id
                                                                                        );
                                                                                    }}
                                                                                    control={
                                                                                        <Checkbox
                                                                                            title="Checkbox"
                                                                                            id={elem?.id}
                                                                                            sx={{
                                                                                                color: "rgba(85,85,85,0.7)",
                                                                                                "&.Mui-checked": {
                                                                                                    color: "#028C78",
                                                                                                },
                                                                                                "&.Mui-disabled": {
                                                                                                    pointerEvents: "auto",
                                                                                                    cursor: "not-allowed",
                                                                                                    color: `${
                                                                                                        ViewRoleId !== null
                                                                                                            ? "028C78"
                                                                                                            : " rgba(85,85,85,0.7)"
                                                                                                    }`,
                                                                                                    opacity: "0.5",
                                                                                                },
                                                                                            }}
                                                                                        />
                                                                                    }
                                                                                    label={
                                                                                        <span
                                                                                            style={{
                                                                                                color: `${
                                                                                                    currentMode === "dark" ? "white" : "black"
                                                                                                }`,
                                                                                                fontWeight: 500,
                                                                                            }}
                                                                                        >
                                                                                            {ele?.title}
                                                                                        </span>
                                                                                    }
                                                                                />
                                                                            </React.Fragment>
                                                                        );
                                                                    })}
                                                                </div>
                                                            </React.Fragment>
                                                        );
                                                    })}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className={` bg-${currentMode}-1050 agreed shadow-[0px_4px_20px_rgba(85,85,85,0.2)] px-[30px] py-2`}>
                                        {/* {ManualHeader?.map((ele, index) => {
                                            return (
                                                <React.Fragment key={index}>
                                                    <div>
                                                        {manualAct?.map(
                                                            (elem, index) => {
                                                                return (
                                                                    <React.Fragment
                                                                        key={
                                                                            index
                                                                        }
                                                                    >
                                                                        <FormControlLabel
                                                                            className="block"
                                                                            checked={
                                                                                elem?.isallowed
                                                                            }
                                                                            disabled={
                                                                                ViewRoleId !==
                                                                                null
                                                                            }
                                                                            onChange={(
                                                                                event
                                                                            ) => {
                                                                                handleCheckboxChange(
                                                                                    {
                                                                                        target: {
                                                                                            name: event
                                                                                                .target
                                                                                                .name,
                                                                                            value: event
                                                                                                .target
                                                                                                .checked,
                                                                                        },
                                                                                    },
                                                                                    elem?.id,
                                                                                    ele?.menu_id
                                                                                );
                                                                            }}
                                                                            control={
                                                                                <Checkbox
                                                                                    title="Checkbox"
                                                                                    id={
                                                                                        elem?.id
                                                                                    }
                                                                                    sx={{
                                                                                        color: "rgba(85,85,85,0.7)",
                                                                                        "&.Mui-checked":
                                                                                        {
                                                                                            color: "#028C78",
                                                                                        },
                                                                                        "&.Mui-disabled":
                                                                                        {
                                                                                            pointerEvents:
                                                                                                "auto",
                                                                                            cursor: "not-allowed",
                                                                                            color: `${ViewRoleId !==
                                                                                                null
                                                                                                ? "028C78"
                                                                                                : " rgba(85,85,85,0.7)"
                                                                                                }`,
                                                                                            opacity:
                                                                                                "0.5",
                                                                                        },
                                                                                    }}
                                                                                />
                                                                            }
                                                                            label={
                                                                                <span
                                                                                    style={{
                                                                                        color: `${currentMode ===
                                                                                            "dark"
                                                                                            ? "white"
                                                                                            : "#555555"
                                                                                            }`,
                                                                                    }}
                                                                                >
                                                                                    {
                                                                                        ele?.title
                                                                                    }
                                                                                </span>
                                                                            }
                                                                        />
                                                                    </React.Fragment>
                                                                );
                                                            }
                                                        )}
                                                    </div>
                                                </React.Fragment>
                                            );
                                        })} */}
                                        {dashboardHeader?.map((ele, index) => {
                                            return (
                                                <React.Fragment key={index}>
                                                    <div>
                                                        {dashboardAct?.map((elem, index) => {
                                                            return (
                                                                <React.Fragment key={index}>
                                                                    <FormControlLabel
                                                                        className="block"
                                                                        checked={elem?.isallowed}
                                                                        disabled={ViewRoleId !== null}
                                                                        onChange={(event) => {
                                                                            handleCheckboxChange(
                                                                                {
                                                                                    target: {
                                                                                        name: event.target.name,
                                                                                        value: event.target.checked,
                                                                                    },
                                                                                },
                                                                                elem?.id,
                                                                                ele?.menu_id
                                                                            );
                                                                        }}
                                                                        control={
                                                                            <Checkbox
                                                                                title="Checkbox"
                                                                                id={elem?.id}
                                                                                sx={{
                                                                                    color: "rgba(85,85,85,0.7)",
                                                                                    "&.Mui-checked": {
                                                                                        color: "#028C78",
                                                                                    },
                                                                                    "&.Mui-disabled": {
                                                                                        pointerEvents: "auto",
                                                                                        cursor: "not-allowed",
                                                                                        color: `${
                                                                                            ViewRoleId !== null ? "028C78" : " rgba(85,85,85,0.7)"
                                                                                        }`,
                                                                                        opacity: "0.5",
                                                                                    },
                                                                                }}
                                                                            />
                                                                        }
                                                                        label={
                                                                            <span
                                                                                style={{
                                                                                    color: `${currentMode === "dark" ? "white" : "black"}`,
                                                                                }}
                                                                            >
                                                                                {ele?.title}
                                                                            </span>
                                                                        }
                                                                    />
                                                                </React.Fragment>
                                                            );
                                                        })}
                                                    </div>
                                                </React.Fragment>
                                            );
                                        })}
                                        {ShowProffesionIcon && (
                                            <div>
                                                {accrualHeader?.map((ele, index) => {
                                                    return (
                                                        <React.Fragment key={index}>
                                                            <div>
                                                                {accrualPostAct?.map((elem, index) => {
                                                                    return (
                                                                        <React.Fragment key={index}>
                                                                            <FormControlLabel
                                                                                className="block"
                                                                                checked={elem?.isallowed}
                                                                                disabled={ViewRoleId !== null}
                                                                                onChange={(event) => {
                                                                                    handleCheckboxChange(
                                                                                        {
                                                                                            target: {
                                                                                                name: event.target.name,
                                                                                                value: event.target.checked,
                                                                                            },
                                                                                        },
                                                                                        elem?.id,
                                                                                        ele?.menu_id
                                                                                    );
                                                                                }}
                                                                                control={
                                                                                    <Checkbox
                                                                                        title="Checkbox"
                                                                                        id={elem?.id}
                                                                                        sx={{
                                                                                            color: "rgba(85,85,85,0.7)",
                                                                                            "&.Mui-checked": {
                                                                                                color: "#028C78",
                                                                                            },
                                                                                            "&.Mui-disabled": {
                                                                                                pointerEvents: "auto",
                                                                                                cursor: "not-allowed",
                                                                                                color: `${
                                                                                                    ViewRoleId !== null
                                                                                                        ? "028C78"
                                                                                                        : " rgba(85,85,85,0.7)"
                                                                                                }`,
                                                                                                opacity: "0.5",
                                                                                            },
                                                                                        }}
                                                                                    />
                                                                                }
                                                                                label={
                                                                                    <span
                                                                                        style={{
                                                                                            color: `${currentMode === "dark" ? "white" : "black"}`,
                                                                                        }}
                                                                                    >
                                                                                        {ele?.title}
                                                                                    </span>
                                                                                }
                                                                            />
                                                                        </React.Fragment>
                                                                    );
                                                                })}
                                                            </div>
                                                        </React.Fragment>
                                                    );
                                                })}
                                            </div>
                                        )}
                                        {ShowProffesionIcon && (
                                            <div>
                                                {MonthEndHeader?.map((ele, index) => {
                                                    return (
                                                        <React.Fragment key={index}>
                                                            <div>
                                                                {MonthEndAct?.map((elem, index) => {
                                                                    return (
                                                                        <React.Fragment key={index}>
                                                                            <FormControlLabel
                                                                                className="block"
                                                                                checked={elem?.isallowed}
                                                                                disabled={ViewRoleId !== null}
                                                                                onChange={(event) => {
                                                                                    handleCheckboxChange(
                                                                                        {
                                                                                            target: {
                                                                                                name: event.target.name,
                                                                                                value: event.target.checked,
                                                                                            },
                                                                                        },
                                                                                        elem?.id,
                                                                                        ele?.menu_id
                                                                                    );
                                                                                }}
                                                                                control={
                                                                                    <Checkbox
                                                                                        title="Checkbox"
                                                                                        id={elem?.id}
                                                                                        sx={{
                                                                                            color: "rgba(85,85,85,0.7)",
                                                                                            "&.Mui-checked": {
                                                                                                color: "#028C78",
                                                                                            },
                                                                                            "&.Mui-disabled": {
                                                                                                pointerEvents: "auto",
                                                                                                cursor: "not-allowed",
                                                                                                color: `${
                                                                                                    ViewRoleId !== null
                                                                                                        ? "028C78"
                                                                                                        : " rgba(85,85,85,0.7)"
                                                                                                }`,
                                                                                                opacity: "0.5",
                                                                                            },
                                                                                        }}
                                                                                    />
                                                                                }
                                                                                label={
                                                                                    <span
                                                                                        style={{
                                                                                            color: `${currentMode === "dark" ? "white" : "black"}`,
                                                                                        }}
                                                                                    >
                                                                                        {ele?.title}
                                                                                    </span>
                                                                                }
                                                                            />
                                                                        </React.Fragment>
                                                                    );
                                                                })}
                                                            </div>
                                                        </React.Fragment>
                                                    );
                                                })}
                                            </div>
                                        )}
                                        {ShowProffesionIcon && (
                                            <div>
                                                {ExchangeRateHeader?.map((ele, index) => {
                                                    return (
                                                        <React.Fragment key={index}>
                                                            <div>
                                                                {exchangeRateAct?.map((elem, index) => {
                                                                    return (
                                                                        <React.Fragment key={index}>
                                                                            <FormControlLabel
                                                                                className="block"
                                                                                checked={elem?.isallowed}
                                                                                disabled={ViewRoleId !== null}
                                                                                onChange={(event) => {
                                                                                    handleCheckboxChange(
                                                                                        {
                                                                                            target: {
                                                                                                name: event.target.name,
                                                                                                value: event.target.checked,
                                                                                            },
                                                                                        },
                                                                                        elem?.id,
                                                                                        ele?.menu_id
                                                                                    );
                                                                                }}
                                                                                control={
                                                                                    <Checkbox
                                                                                        title="Checkbox"
                                                                                        id={elem?.id}
                                                                                        sx={{
                                                                                            color: "rgba(85,85,85,0.7)",
                                                                                            "&.Mui-checked": {
                                                                                                color: "#028C78",
                                                                                            },
                                                                                            "&.Mui-disabled": {
                                                                                                pointerEvents: "auto",
                                                                                                cursor: "not-allowed",
                                                                                                color: `${
                                                                                                    ViewRoleId !== null
                                                                                                        ? "028C78"
                                                                                                        : " rgba(85,85,85,0.7)"
                                                                                                }`,
                                                                                                opacity: "0.5",
                                                                                            },
                                                                                        }}
                                                                                    />
                                                                                }
                                                                                label={
                                                                                    <span
                                                                                        style={{
                                                                                            color: `${currentMode === "dark" ? "white" : "black"}`,
                                                                                        }}
                                                                                    >
                                                                                        {ele?.title}
                                                                                    </span>
                                                                                }
                                                                            />
                                                                        </React.Fragment>
                                                                    );
                                                                })}
                                                            </div>
                                                        </React.Fragment>
                                                    );
                                                })}
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div
                                    className={`bg-${currentMode}-card text-${currentMode}-copy  border-${currentMode}-700 py-[20px] space-x-2 px-[30px] sticky left-0 right-0 bottom-0 shadow-[0px_4px_20px_rgba(85,85,85,0.2)]  border-t-[1px] rounded-b-[8px]`}
                                >
                                    {ViewRole == null && (
                                        <Button
                                            className="bg-light-20 text-white text-base rounded-[8px] py-2 px-7"
                                            onClick={submit}
                                            disabled={fetching}
                                            title={RoleIDINt ? "Update" : "Create"}
                                        >
                                            {RoleIDINt ? "Update" : "Create"}
                                        </Button>
                                    )}
                                    <Link to="/role-master">
                                        <Button
                                            title="Cancel"
                                            className={`bg-light-240 text-black text-base rounded-[8px] py-2 px-7`}
                                            onClick={Closepage}
                                        >
                                            Cancel
                                        </Button>
                                    </Link>
                                </div>
                            </>
                        )}
                    </Card>
                </Main>

                <Footer />
            </Layout>
        </>
    );
};

export default NewRole;
