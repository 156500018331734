import { useContext, useState, useEffect } from "react";
import Modal from "../../../components/UI/Modal/Modal";
import { ThemeContext } from "../../../Hooks/Themes/Themeprovider";
import Button from "../../../components/Button";
import _ from "lodash";
import { Tooltip } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../components/Loader/index";
import Swal from "sweetalert2";
import { ERP_VALIDATIONBYMATCHING_RESET } from "../../../redux/types/types.js";
import { Radio } from "@mui/material";
import { formatNumber } from "../../../utils/helpers.js";
import { NumericFormat } from "react-number-format";
export const ThreeWayMatchingModal = (props) => {
    //var
    const dispatch = useDispatch();
    const invoiceList = useSelector((state) => state?.getInvoiceItemsState);
    const invListData = invoiceList?.data?.datalist[0]?.invoice_items;

    const { initialSelectedData, data: listData, fetching, error, requestComplete } = useSelector((state) => state?.threeWayMatchingByErpState);
    const { currentMode, toggleMode } = useContext(ThemeContext);
    const [lineItemData, setLineItemData] = useState();
    const matchingListData = listData?.overallData;
    const [updatedData, setUpdatedData] = useState([]);
    const objectsWithoutKeys = props?.tblListData.map((item) => {
        return Object.values(item); // Assuming there's only one key per object
    });
    const matching = parseInt(process.env?.REACT_APP_MATCHING_PERCENTAGE);
    const mismatching = parseInt(process.env?.REACT_APP_MISMATCHING_PERCENTAGE);
    const [showLineItemData, setShowLineItemData] = useState(matchingListData);
    const numFormat = localStorage.getItem("userNumberFormat");
    const [itemsFilter, setItemsFilter] = useState(false);
    // const taxPercIndInv =
    function checkNumber(value) {
        const goog = formatNumber(value);
        return isNaN(goog) ? 0 : goog;
    }
    // Extract the relevant tax percentage values
    const taxPercentages =
        [
            checkNumber(lineItemData?.item_tax_percentage),
            checkNumber(lineItemData?.item_ugst_tax_percentage),
            checkNumber(lineItemData?.item_sgst_tax_percentage),
            checkNumber(lineItemData?.item_igst_tax_percentage),
            checkNumber(lineItemData?.item_cgst_tax_percentage),
            checkNumber(lineItemData?.item_cess_tax_percentage),
        ] || [];
    // Extract the relevant tax percentage values
    const taxAmt =
        [
            checkNumber(lineItemData?.item_tax_amount),
            checkNumber(lineItemData?.item_ugst_tax_amount),
            checkNumber(lineItemData?.item_sgst_tax_amount),
            checkNumber(lineItemData?.item_igst_tax_amount),
            checkNumber(lineItemData?.item_cgst_tax_amount),
            checkNumber(lineItemData?.item_cess_tax_amount),
        ] || [];
    // Filter out empty strings
    const filteredTaxPercentages = _.filter(taxPercentages, (percentage) => percentage !== "");
    // Filter out empty strings taxAmt
    const filteredTaxAmount = _.filter(taxAmt, (amt) => amt !== "");
    // Sum the tax percentages
    const sumOfTaxPercentages = _.sum(filteredTaxPercentages.map((percentage) => parseFloat(percentage)));
    // Sum the tax amt
    const sumOfTaxAmount = _.sum(filteredTaxAmount.map((amt) => parseFloat(amt)));
    const radioValues = [
        {
            id: 1,
            name: "Matched",
        },
        {
            id: 2,
            name: "All",
        },
    ];
    //update the po item data on every call
    useEffect(() => {
        if (!fetching && requestComplete && initialSelectedData?.length !== 0) {
            setUpdatedData(initialSelectedData);
        }
    }, [fetching]);
    // get the array of data for specific line item
    useEffect(() => {
        if (props?.getIndexOfMatching !== null) {
            const listData = objectsWithoutKeys?.find((item, index) => index == props?.getIndexOfMatching);
            const lineItem = invoiceDataFetch(listData, props?.getIndexOfMatching);
            setLineItemData(lineItem);
        }
    }, [props?.getIndexOfMatching]);
    useEffect(() => {
        if (matchingListData !== undefined && props?.getIndexOfMatching !== null) {
            const { matching, overall } = radioFilter();
            setShowLineItemData(matching ? [matching] : overall);
            setItemsFilter(matching ? "Matched" : "All");
        }
    }, [matchingListData, props?.getIndexOfMatching]);
    // close button function
    const handleClose = () => {
        props?.handleClose();
        props?.setIndexOfMatching(null);
    };
    const nextIndex =
        matchingListData?.find((item, index) => {
            return item?.lineItemNo > props?.getIndexOfMatching + 1 && item?.hasOwnProperty("poItemCode");
        })?.lineItemNo - 1;
    const prevIndex =
        matchingListData?.find((item, index) => {
            return item?.lineItemNo < props?.getIndexOfMatching + 1 && item?.hasOwnProperty("poItemCode");
        })?.lineItemNo - 1;
    // const findNextNonFright = (currentIndex) => {
    //     const nextValue = matchingListData?.find((item, index) => {
    //         if (index > props?.getIndexOfMatching && item?.poItemCode) {
    //             return index;
    //         }
    //     });
    //     return nextValue;
    // };
    const sortByPropertyDescending = (data, property) => {
        return _.orderBy(data, [property], ["desc"]);
    };
    if (
        (props?.isOpened && error?.response?.status == 500) ||
        (props?.isOpened && error?.response?.status == 404) ||
        (props?.isOpened && error?.response?.status == 400)
    ) {
        Swal.fire({
            text: error?.response?.data?.message,
            icon: "warning",
            closeOnClickOutside: false,
            showCloseButton: true,
            buttons: {
                confirm: true,
            },
        }).then((confirm) => {
            if (confirm) {
                props?.handleClose();
                // dispatch({ type: ERP_VALIDATIONBYMATCHING_RESET });
            }
        });
    }
    //po item select button function
    const handleSelectPoItem = (index) => {
        const getListData = showLineItemData?.find((el, ind) => ind == index);
        const filterList = showLineItemData?.filter((el, ind) => ind !== index);
        showLineItemData?.forEach((el, i) => {
            if (i == index) {
                el.isLineItem = true;
            } else {
                el.isLineItem = false;
            }
        });
        const sortedData = sortByPropertyDescending(filterList, "overall");
        sortedData.unshift(getListData);
        const checkArr = sortedData?.filter((item) => item !== undefined);
        setShowLineItemData(checkArr);
        showLineItemData?.map((el) => {
            if (el?.isLineItem) {
                const check = updatedData?.filter((item) => {
                    return !item.hasOwnProperty(props?.getIndexOfMatching + 1);
                });
                check?.push({
                    [el.lineItemNo]: el?.poItemCode,
                });
                check.sort((a, b) => {
                    const keyA = parseInt(Object.keys(a)[0]);
                    const keyB = parseInt(Object.keys(b)[0]);
                    return keyA - keyB;
                });
                setUpdatedData(check);
            }
        });
    };
    // check the array is a initial data or not
    useEffect(() => {
        if (updatedData?.length !== 0) {
            const areArraysEqual = _.isEqual(initialSelectedData, updatedData);
            props?.setAreArraysEqual(areArraysEqual);
        }
    }, [updatedData]);
    //next button function
    const handleNextBtnClick = () => {
        const currentIndex = props?.getIndexOfMatching;
        // Check if the next index is in the freightCheckArr
        if (props?.freightCheckArr?.includes(nextIndex)) {
            // Find the next available index that is not in freightCheckArr
            let newNextIndex = nextIndex + 1;
            while (props?.freightCheckArr?.includes(newNextIndex)) {
                newNextIndex++;
            }
            // Handle the condition when the next index is in freightCheckArr
            const listData = objectsWithoutKeys?.find((item, index) => index === newNextIndex);
            props?.setIndexOfMatching(newNextIndex);
            const lineItem = invoiceDataFetch(listData, newNextIndex);
            setLineItemData(lineItem);
        } else {
            // Move to the next index
            const listData = objectsWithoutKeys?.find((item, index) => index === nextIndex);
            props?.setIndexOfMatching(nextIndex);
            const lineItem = invoiceDataFetch(listData, nextIndex);
            setLineItemData(lineItem);
        }
    };
    const handlePrevBtnClick = () => {
        const currentIndex = props?.getIndexOfMatching;
        // Check if the previous index is in the freightCheckArr
        if (props?.freightCheckArr?.includes(prevIndex)) {
            // Find the previous available index that is not in freightCheckArr
            let newPrevIndex = prevIndex - 1;
            while (props?.freightCheckArr?.includes(newPrevIndex)) {
                newPrevIndex--;
            }
            // Handle the condition when the previous index is in freightCheckArr
            const listData = objectsWithoutKeys?.find((item, index) => index === newPrevIndex);
            props?.setIndexOfMatching(newPrevIndex);
            const lineItem = invoiceDataFetch(listData, newPrevIndex);
            setLineItemData(lineItem);
        } else {
            // Move to the previous index
            const listData = objectsWithoutKeys?.find((item, index) => index === prevIndex);
            props?.setIndexOfMatching(prevIndex);
            const lineItem = invoiceDataFetch(listData, prevIndex);
            setLineItemData(lineItem);
        }
    };
    function invoiceDataFetch(invoiceData, index) {
        const lineItem = _.groupBy(invoiceData, "item_no");
        const reducedLineItems = _.mapValues(lineItem, (itemGroup) => {
            return _.reduce(
                itemGroup,
                (result, item) => {
                    result[item.label_name] = item.label_value;
                    return result;
                },
                {}
            );
        });
        return reducedLineItems[index + 1];
    }
    function radioFilter() {
        const getLineNoArrauy = matchingListData?.filter((el, ind) => el?.lineItemNo == props?.getIndexOfMatching + 1);
        const getListData = getLineNoArrauy?.find((el, ind) => el?.isLineItem == true);
        const filterList = getLineNoArrauy?.filter((el, ind) => el?.isLineItem == false);
        filterList.unshift(getListData);

        return { overall: filterList?.filter((item) => item !== undefined), matching: getListData };
    }

    function changeFilterType(ele) {
        setItemsFilter(ele);
        const { overall, matching } = radioFilter();
        setShowLineItemData(ele == "All" ? overall : !matching ? [] : [matching]);
    }
    // get the selected object inside the array
    const findLineitemTrue = showLineItemData?.find((ele) => ele?.isLineItem == true);
    return (
        <Modal upperLay={true} cartClose={props?.handleClose} center="left" className={`${props?.className}`}>
            <div
                className={`${
                    error == "" && fetching && matchingListData == undefined
                        ? "max-w-[100px]"
                        : itemsFilter == "All" && showLineItemData?.length > 1
                        ? "w-max"
                        : "w-[563px]"
                }  mx-auto px-0 pr-[20px] py-0 relative bg-${currentMode}-card  transition border-[rgba(236,236,236,0.88)] ${
                    currentMode === "dark" ? "" : "shadow-[0px_4px_20px_rgba(238,238,238,0.5)]"
                } rounded-[10px] duration-150 ease-out p-[30px]`}
            >
                {!fetching && matchingListData !== undefined ? (
                    <>
                        {/* {radioValues?.map((item) => {
                            <Radio
                                tabIndex="3"
                                id={item?.id}
                                sx={{
                                    color: "rgba(85,85,85,0.7)",

                                    "&.Mui-checked": {
                                        color: "#00B399",
                                    },
                                }}
                                onClick={(event) => {
                                    console.log("Clicked");
                                }}
                                name="radio-buttons"
                                inputProps={{
                                    "aria-label": "A",
                                }}
                            />;
                        })} */}
                        <div className={` px-0`}>
                            <div
                                className={`pt-[10px] pb-[5px] ml-[25px] mr-[20px] flex   ${
                                    itemsFilter == "All" && showLineItemData?.length > 1 ? "items-center justify-between" : "flex-col"
                                }`}
                            >
                                <span className={`text-lg`}>{"2 and 3 Way Matching Items"}</span>
                                <div className={`flex ${itemsFilter == "All" && showLineItemData?.length > 1 ? "basis-1/2 mr-[150px] " : ""} `}>
                                    <span className={`py-2`}> {"Show Only: "}</span>
                                    {radioValues.map((item) => (
                                        <div key={item?.id} className={`font-interS`}>
                                            <Radio
                                                key={item?.id} // Don't forget to add a key prop when using map
                                                tabIndex="3"
                                                id={item?.id}
                                                value={item?.name}
                                                sx={{
                                                    color: "rgba(85,85,85,0.7)",

                                                    "&.Mui-checked": {
                                                        color: "#00B399",
                                                    },
                                                }}
                                                checked={itemsFilter == item?.name}
                                                onClick={(event) => {
                                                    changeFilterType(event?.target?.value);
                                                }}
                                                disabled={item?.id == 2 ? false : !findLineitemTrue ? true : false}
                                                name="radio-buttons"
                                                inputProps={{
                                                    "aria-label": "A",
                                                }}
                                            />
                                            <span>{item?.id == 1 ? "Matching Items" : "All Items"}</span>
                                        </div>
                                    ))}
                                    {/* <img
                                        title="Close"
                                        src="/images/invoice/cls-blk.svg"
                                        alt="cls-blk"
                                        className="convertWhite max-w-[18px] max-h-[18px]"
                                    /> */}
                                </div>
                            </div>
                            <div
                                className={`${
                                    itemsFilter == "All" && showLineItemData?.length > 1
                                        ? "max-w-[1231px] overflow-x-auto"
                                        : "max-w-[553px] overflow-x-auto"
                                }`}
                            >
                                <table
                                    className={`${""}  mb-0 border-x-0 rolemasterTable ${
                                        findLineitemTrue !== undefined ? "threewayTbl" : "threewayTblnotHover"
                                    }    rounded-[8px] active-check rounded-b-none border text-left`}
                                >
                                    <thead className="bg-black text-white">
                                        <tr>
                                            <th className="min-w-[49px] bg-white text-black">#</th>
                                            <th className="min-w-[116px] bg-white text-black">Invoice Item</th>

                                            <th className="min-w-[155px] bg-white text-black">
                                                <span className=""> Invoice Value </span>
                                                <p className="">
                                                    (Line Item:
                                                    {props?.getIndexOfMatching + 1})
                                                </p>
                                            </th>

                                            {showLineItemData?.map((item, index) => {
                                                return (
                                                    <>
                                                        <th
                                                            className={`text-center mappedHover space-y-[8px] ${
                                                                item?.isLineItem == true ? "bg-[#028C78] " : "bg-white text-black"
                                                            }  min-w-[230px]`}
                                                        >
                                                            <span>{"PO Item" + " " + item?.poItemCode}</span>
                                                            <div className="flex">
                                                                <span
                                                                    className={` ${
                                                                        item?.isLineItem == true ? "bg-white text-black" : "bg-[#028C78] text-white"
                                                                    } max-w-[140px] blk-btn mx-auto justify-center px-[12px] items-center flex border-${currentMode}-[1px] font-interR rounded-[3px]  text-xs`}
                                                                >
                                                                    <b className="text-lg font-interSb">{item?.overall?.toFixed(2)}</b>{" "}
                                                                    <sub className="mr-[5px] text-xs">%</sub> Matching
                                                                </span>
                                                                <Tooltip
                                                                    title={item?.errorMessages}
                                                                    disablePortal
                                                                    placement="bottom"
                                                                    disableInteractive
                                                                    PopperProps={{
                                                                        sx: {
                                                                            "& .MuiTooltip-tooltip": {
                                                                                fontSize: "0.9em",
                                                                                maxHeight: "120px",
                                                                                maxWidth: "250px",
                                                                                background: "#DAF9F4",
                                                                                color: "#555555",
                                                                                borderRadius: "5px",
                                                                                marginTop: "0 !important",
                                                                            },

                                                                            "& .MuiTooltip-arrow": {
                                                                                color: "#DAF9F4",
                                                                            },
                                                                        },
                                                                    }}
                                                                    arrow
                                                                >
                                                                    <img
                                                                        className="ml-[-10px]"
                                                                        src={
                                                                            item?.isLineItem
                                                                                ? "/images/invoice/eye_tool_white.svg"
                                                                                : "/images/invoice/too_tip_black.svg"
                                                                        }
                                                                        alt="noun-information"
                                                                    />
                                                                </Tooltip>
                                                            </div>
                                                            <Button
                                                                onClick={() => handleSelectPoItem(index)}
                                                                className={`flex min-w-[130px] mx-auto items-center justify-center text-base mb-1 px-5 border-[1px] font-interR mt-1 rounded-[5px] ${
                                                                    item?.isLineItem == true ? "text-white " : "text-black  border-[black]"
                                                                }`}
                                                            >
                                                                Select
                                                            </Button>
                                                        </th>
                                                    </>
                                                );
                                            })}
                                        </tr>
                                    </thead>
                                    <tbody className="min-w-[500px] overflow-x-auto">
                                        {/* <tr
                                            className={`border-[1px] ${`ifhover`}`}
                                        >
                                            <td>1</td>
                                            <td>Code</td>
                                            <td>{lineItemData?.item_code}</td>

                                            {showLineItemData?.map((item) => {
                                                return (
                                                    <>
                                                        <td
                                                            className={`max-w-[10px] truncate `}
                                                        >
                                                            {item?.code}
                                                        </td>
                                                    </>
                                                );
                                            })}
                                        </tr> */}
                                        <tr className="ifhover">
                                            <td>1</td>
                                            <td className="max-w-[10px] truncate ">Description</td>{" "}
                                            <td className="max-w-[10px] truncate cursor-pointer" title={lineItemData?.item_name}>
                                                {lineItemData?.item_name}
                                            </td>{" "}
                                            {showLineItemData?.map((item) => {
                                                return (
                                                    <>
                                                        <td title={item?.item_name} className={`max-w-[10px] truncate   cursor-pointer`}>
                                                            <span
                                                                className={`truncate inline-block w-[180px] ${
                                                                    item?.item_name_perc <= mismatching
                                                                        ? currentMode == "dark"
                                                                            ? "bg-[#DD6F82] px-2 py-1 rounded-[8px] text-black"
                                                                            : "bg-[#FFD8DE] px-2 py-1 rounded-[8px] text-black"
                                                                        : item?.item_name_perc >= mismatching && item?.item_name_perc < matching
                                                                        ? currentMode == "dark"
                                                                            ? "bg-[#EEBF99] px-2 py-1 rounded-[8px] text-black"
                                                                            : "bg-[#EBE1FF] px-2 py-1 rounded-[8px] text-black"
                                                                        : ""
                                                                }`}
                                                            >
                                                                {item?.item_name}
                                                            </span>
                                                        </td>
                                                    </>
                                                );
                                            })}
                                        </tr>
                                        <tr className="ifhover">
                                            <td>2</td>
                                            <td>Quantity</td> <td> {lineItemData?.quantity}</td>{" "}
                                            {showLineItemData?.map((item) => {
                                                return (
                                                    <>
                                                        <td className={`max-w-[10px] truncate   `}>
                                                            <span
                                                                className={`truncate ${
                                                                    item?.quantity_perc <= mismatching
                                                                        ? currentMode == "dark"
                                                                            ? "bg-[#DD6F82] px-2 py-1 rounded-[8px] text-black"
                                                                            : "bg-[#FFD8DE] px-2 py-1 rounded-[8px] text-black"
                                                                        : item?.quantity_perc >= mismatching && item?.quantity_perc < matching
                                                                        ? currentMode == "dark"
                                                                            ? "bg-[#EEBF99] px-2 py-1 rounded-[8px] text-black"
                                                                            : "bg-[#EBE1FF] px-2 py-1 rounded-[8px] text-black"
                                                                        : ""
                                                                }`}
                                                            >
                                                                {item?.quantity}
                                                            </span>
                                                        </td>
                                                    </>
                                                );
                                            })}
                                        </tr>
                                        <tr className="ifhover">
                                            <td>3</td>
                                            <td>Unit</td> <td>{lineItemData?.unit}</td>{" "}
                                            {showLineItemData?.map((item) => {
                                                return (
                                                    <>
                                                        <td className="max-w-[10px] truncate ">{item?.unit}</td>
                                                    </>
                                                );
                                            })}
                                        </tr>
                                        <tr className="ifhover">
                                            <td>4</td>
                                            <td>Unit Price</td> <td>{lineItemData?.item_price}</td>{" "}
                                            {showLineItemData?.map((item) => {
                                                return (
                                                    <>
                                                        <td className={`max-w-[10px] truncate   `}>
                                                            <span
                                                                className={`truncate ${
                                                                    item?.item_price_perc <= mismatching
                                                                        ? currentMode == "dark"
                                                                            ? "bg-[#DD6F82] px-2 py-1 rounded-[8px] text-black"
                                                                            : "bg-[#FFD8DE] px-2 py-1 rounded-[8px] text-black"
                                                                        : item?.item_price_perc >= mismatching && item?.item_price_perc < matching
                                                                        ? currentMode == "dark"
                                                                            ? "bg-[#EEBF99] px-2 py-1 rounded-[8px] text-black"
                                                                            : "bg-[#EBE1FF] px-2 py-1 rounded-[8px] text-black"
                                                                        : ""
                                                                }`}
                                                            >
                                                                {item?.item_price}
                                                            </span>
                                                        </td>
                                                    </>
                                                );
                                            })}
                                        </tr>
                                        <tr className="ifhover">
                                            <td>5</td>
                                            <td>Tax</td>{" "}
                                            <td>
                                                <NumericFormat
                                                    allowNegative={false}
                                                    decimalScale={2}
                                                    displayType="text"
                                                    thousandSeparator={numFormat == "1" ? "," : numFormat == "2" ? "." : " "}
                                                    decimalSeparator={numFormat == "1" ? "." : numFormat == "2" ? "," : "."}
                                                    value={sumOfTaxAmount}
                                                    className={`max-w-[10px] bg-${currentMode}-810`}
                                                />
                                            </td>{" "}
                                            {showLineItemData?.map((item) => {
                                                return (
                                                    <>
                                                        <td className={`max-w-[10px] truncate  `}>
                                                            <span
                                                                className={` truncate ${
                                                                    item?.item_tax_amount_perc <= mismatching
                                                                        ? currentMode == "dark"
                                                                            ? "bg-[#DD6F82] px-2 py-1 rounded-[8px] text-black"
                                                                            : "bg-[#FFD8DE] px-2 py-1 rounded-[8px] text-black"
                                                                        : item?.item_tax_amount_perc >= mismatching &&
                                                                          item?.item_tax_amount_perc < matching
                                                                        ? currentMode == "dark"
                                                                            ? "bg-[#EEBF99] px-2 py-1 rounded-[8px] text-black"
                                                                            : "bg-[#EBE1FF] px-2 py-1 rounded-[8px] text-black"
                                                                        : ""
                                                                }`}
                                                            >
                                                                {item?.item_tax_amount}
                                                            </span>
                                                        </td>
                                                    </>
                                                );
                                            })}
                                        </tr>{" "}
                                        <tr className="ifhover">
                                            <td>6</td>
                                            <td>Tax %</td>{" "}
                                            <td>
                                                {
                                                    sumOfTaxPercentages !== "0" ? sumOfTaxPercentages?.toFixed(2)?.toString() : ""
                                                    // lineItemData?.item_tax_percentage
                                                }
                                            </td>{" "}
                                            {showLineItemData?.map((item) => {
                                                return (
                                                    <>
                                                        <td className={`max-w-[10px] truncate  `}>
                                                            <span
                                                                className={`truncate ${
                                                                    item?.item_tax_percentage_perc <= mismatching
                                                                        ? currentMode == "dark"
                                                                            ? "bg-[#DD6F82] px-2 py-1 rounded-[8px] text-black"
                                                                            : "bg-[#FFD8DE] px-2 py-1 rounded-[8px] text-black"
                                                                        : item?.item_tax_percentage_perc >= mismatching &&
                                                                          item?.item_tax_percentage_perc < matching
                                                                        ? currentMode == "dark"
                                                                            ? "bg-[#EEBF99] px-2 py-1 rounded-[8px] text-black"
                                                                            : "bg-[#EBE1FF] px-2 py-1 rounded-[8px] text-black"
                                                                        : ""
                                                                }`}
                                                            >
                                                                {" "}
                                                                {item?.item_tax_percentage}
                                                            </span>
                                                        </td>
                                                    </>
                                                );
                                            })}
                                        </tr>{" "}
                                        <tr className="ifhover">
                                            <td>7</td>
                                            <td>Discount</td>
                                            <td>
                                                <NumericFormat
                                                    allowNegative={false}
                                                    decimalScale={2}
                                                    displayType="text"
                                                    thousandSeparator={numFormat == "1" ? "," : numFormat == "2" ? "." : " "}
                                                    decimalSeparator={numFormat == "1" ? "." : numFormat == "2" ? "," : "."}
                                                    value={lineItemData?.item_discount_amount}
                                                    className={`max-w-[10px] bg-${currentMode}-810`}
                                                />
                                            </td>{" "}
                                            {showLineItemData?.map((item) => {
                                                return (
                                                    <>
                                                        <td className={`max-w-[10px] truncate  `}>
                                                            <span
                                                                className={`truncate ${
                                                                    item?.item_discount_amount_perc <= mismatching
                                                                        ? currentMode == "dark"
                                                                            ? "bg-[#DD6F82] px-2 py-1  rounded-[8px] text-black"
                                                                            : "bg-[#FFD8DE] px-2 py-1  rounded-[8px] text-black"
                                                                        : item?.item_discount_amount_perc >= mismatching &&
                                                                          item?.item_discount_amount_perc < matching
                                                                        ? currentMode == "dark"
                                                                            ? "bg-[#EEBF99] px-2 py-1 rounded-[8px] text-black"
                                                                            : "bg-[#EBE1FF] px-2 py-1 rounded-[8px] text-black"
                                                                        : ""
                                                                }`}
                                                            >
                                                                {item?.item_discount_amount}
                                                            </span>
                                                        </td>
                                                    </>
                                                );
                                            })}
                                        </tr>{" "}
                                        <tr className="ifhover">
                                            <td>8</td>
                                            <td>Discount %</td> <td> {lineItemData?.item_discount_percentage}</td>{" "}
                                            {showLineItemData?.map((item) => {
                                                return (
                                                    <>
                                                        <td className={`max-w-[10px] truncate   `}>
                                                            <span
                                                                className={`truncate ${
                                                                    item?.item_discount_percentage_perc <= mismatching
                                                                        ? currentMode == "dark"
                                                                            ? "bg-[#DD6F82] px-2 py-1 rounded-[8px] text-black"
                                                                            : "bg-[#FFD8DE] px-2 py-1 rounded-[8px] text-black"
                                                                        : item?.item_discount_percentage_perc >= mismatching &&
                                                                          item?.item_discount_percentage_perc < matching
                                                                        ? currentMode == "dark"
                                                                            ? "bg-[#EEBF99] text-black"
                                                                            : "bg-[#EBE1FF] text-black"
                                                                        : ""
                                                                }`}
                                                            ></span>{" "}
                                                            {item?.item_discount_percentage}
                                                        </td>
                                                    </>
                                                );
                                            })}
                                        </tr>{" "}
                                        <tr className="ifhover">
                                            <td>9</td>
                                            <td>Total</td> <td> {lineItemData?.item_total}</td>{" "}
                                            {showLineItemData?.map((item) => {
                                                return (
                                                    <>
                                                        <td className={`max-w-[10px] truncate  `}>
                                                            <span
                                                                className={`truncate ${
                                                                    item?.item_total_perc <= mismatching
                                                                        ? currentMode == "dark"
                                                                            ? "bg-[#DD6F82] px-2 py-1 rounded-[8px] text-black"
                                                                            : "bg-[#FFD8DE] px-2 py-1 rounded-[8px] text-black"
                                                                        : item?.item_total_perc >= mismatching && item?.item_total_perc < matching
                                                                        ? currentMode == "dark"
                                                                            ? "bg-[#EEBF99] px-2 py-1 rounded-[8px] text-black"
                                                                            : "bg-[#EBE1FF] px-2 py-1 rounded-[8px] text-black"
                                                                        : ""
                                                                }`}
                                                            >
                                                                {item?.item_total}
                                                            </span>
                                                        </td>
                                                    </>
                                                );
                                            })}
                                        </tr>{" "}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div>
                            <div
                                className={`  mt-[20px] pb-[20px] ml-[30px] mr-[20px]  flex  ${
                                    itemsFilter == "All" && showLineItemData?.length > 1
                                        ? "space-x-2 items-center justify-between"
                                        : "space-y-2 flex-col justify-center"
                                } `}
                            >
                                <div className="flex  basis-1/2 space-x-[10px]">
                                    <span className={"flex items-center space-x-2"}>
                                        <div>
                                            <img
                                                src={`${
                                                    currentMode == "dark"
                                                        ? "/images/common/Elslipse-matching-dark.svg"
                                                        : "/images/invoice/Ellipse-matching.svg"
                                                }`}
                                            />
                                        </div>
                                        <span className="">Matching</span>
                                    </span>
                                    <span className={"flex items-center space-x-2"}>
                                        <div>
                                            <img
                                                className=""
                                                src={`${
                                                    currentMode == "dark"
                                                        ? "/images/common/Ellipse-partially-dark.svg"
                                                        : "/images/invoice/partially-matching.svg"
                                                }`}
                                            />
                                        </div>
                                        <span className="">Partially Matching</span>
                                    </span>
                                    <span className={"flex items-center space-x-2"}>
                                        <div>
                                            <img
                                                src={`${
                                                    currentMode == "dark"
                                                        ? "/images/common/Ellipse-mismatching-dark.svg"
                                                        : "/images/invoice/Ellipse-mismatching.svg"
                                                }`}
                                            />
                                        </div>
                                        <span className="">Mismatching</span>
                                    </span>
                                </div>
                                <div
                                    className={`flex  ${
                                        itemsFilter == "All" && showLineItemData?.length > 1 ? "justify-between basis-1/2" : "items-center space-x-2"
                                    }`}
                                >
                                    <div className="flex pr-2">
                                        <Button
                                            type="submit"
                                            title="To Save"
                                            onClick={""}
                                            className={`bg-light-20 ${"font-interR py-[3px] px-[10px]"}  rounded-[5px] text-white min-h-[30px] min-w-[160px]`}
                                        >
                                            PO No.:{props?.poNumber?.label_value}
                                        </Button>
                                    </div>
                                    <div className="flex space-x-[10px]">
                                        <Button
                                            type="cancel"
                                            onClick={handleClose}
                                            title="To Cancel"
                                            className={`border-light-20 border flex font-interR py-[3px] px-[32px] rounded-[5px] ${
                                                currentMode == "dark" ? "text-light-20" : "text-light-40"
                                            } closeModal`}
                                        >
                                            Close
                                        </Button>
                                        <Button
                                            type="cancel"
                                            onClick={handlePrevBtnClick}
                                            disabled={props?.getIndexOfMatching + 1 == 1 || isNaN(prevIndex)}
                                            title="To Back"
                                            className={`border-light-20 border flex font-interR py-[3px] px-[32px] rounded-[5px] ${
                                                currentMode == "dark" ? "text - light - 20" : "text - light - 40"
                                            }closeModal`}
                                        >
                                            Back
                                        </Button>
                                        <Button
                                            type="submit"
                                            title="To Next"
                                            disabled={props?.tblListData?.length == props?.getIndexOfMatching + 1 || isNaN(nextIndex)}
                                            onClick={handleNextBtnClick}
                                            className={`bg-light-20 flex font-interR py-[3px] px-[32px] rounded-[5px] text-white`}
                                        >
                                            Next
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                ) : (
                    error == "" && <Loader className="h-[calc(100vh_-_15rem)]" />
                )}
            </div>
        </Modal>
    );
};
