import Backdrop from "./Backdrop";
import ModalOverlay from "./ModalOverlay";

const Modal = (props) => {
    return (
        <>
            <Backdrop
                onClick={props?.outerClick}
                overlap={props?.overlap}
                className={`${props.className}`}
                highOverlay={props?.highOverlay}
            />

            <ModalOverlay
                className={`${props.className}`}
                overlap={props?.overlap}
                autowidth={props?.autowidth}
                center={props?.center}
                bottom={props?.bottom}
                highOverlay={props?.highOverlay}
                upperLay={props?.upperLay}
            >
                {props.children}
            </ModalOverlay>
        </>
    );
};

export default Modal;
