import Modal from "../../../components/UI/Modal/Modal";
import Button from "../../../components/Button";
import { Autocomplete, Checkbox, Chip, Paper, TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useState, useRef } from "react";
import { decryptId } from "../../../utils/helpers";
const SelectUserModal = (props) => {
    const { className, currentMode, setSelectUserAnchor, isOpened, id, getEmail, setUserEmail, userList, changeStatus, statusReqObj } = props;
    const [userEmail, setUserEmailModal] = useState([...getEmail]);

    //* Functions for the update:
    const _handleSelectUser = (index, value, reason) => {
        if (reason == "selectOption") {
            setUserEmailModal([value?.[value?.length - 1]]);
        } else setUserEmailModal([]);
    };
    const handleClose = () => {
        setSelectUserAnchor(false);
    };
    return (
        <>
            <Modal cartClose={handleClose} center="center" className={`${className}`}>
                <div
                    className={`flex flex-col relative max-w-[531px] xl:ml-[450px] md:mx-auto bg-${currentMode}-card text-${currentMode}-copy border-${currentMode}-700 px-[30px] pb-[30px] rounded-[10px] ${
                        currentMode === "dark" ? "" : "shadow-[0px_4px_20px_rgba(238,238,238,0.5)]"
                    }`}
                >
                    <Button tabIndex={4} className="cursor-pointer right-[0] px-[20px] py-[20px]  absolute" onClick={handleClose}>
                        <img src="/images/common/closeBlk.svg" alt="closeBlk" className="convertWhite" />
                    </Button>
                    <div className={`flex items-center py-[16px] mt-[30px] mx-[-30px] justify-between px-[30px] `}>
                        <span className="block">
                            <span className="text-red-500">*</span> Select the configured user for your next level of approvals:
                        </span>
                    </div>
                    <div className="flex max-w-[400px]  justify-center filter items-center">
                        <Autocomplete
                            // autoComplete="on"
                            // onClose={() => setIsOpen(false)}
                            sx={{
                                "& .MuiChip-label": {
                                    color: `${currentMode == "dark" ? "white" : "black"}`,
                                },
                            }}
                            multiple
                            disablePortal
                            className="noBorderAutocomplete"
                            PaperComponent={({ children }) => (
                                <Paper>
                                    <div className=" max-h-[280px] overflow-y-auto">{children}</div>
                                </Paper>
                            )}
                            popupIcon={<img className="m-0 opacity-50 pr-1 pointer-events-none" src="/images/header/chevronDown.svg" />}
                            value={userEmail?.length == 0 ? [] : userEmail}
                            disableClearable
                            id="multiple-limit-tags"
                            options={userList == undefined ? [] : userList}
                            // single
                            // disableCloseOnSelect
                            limitTags={1}
                            onChange={(e, value, event) => {
                                _handleSelectUser(1, value, event);
                            }}
                            getOptionLabel={(option) => (typeof option === "string" ? option : option?.username)}
                            renderOption={(props, option, { selected }) => {
                                return (
                                    <li {...props}>
                                        <img
                                            className="w-[15px] h-[15px]  object-cover rounded-full"
                                            alt="Natacha"
                                            src={`/images/common/profileImg.jpg`}
                                        />
                                        <span
                                            className={`pl-2 max-w-[160px] truncate ${currentMode == "dark" ? "text-white" : "text-black"} truncate`}
                                            title={option?.username}
                                        >
                                            {option?.username}
                                        </span>
                                    </li>
                                );
                            }}
                            style={{ width: 500 }}
                            renderTags={(values, getTagProps) => (
                                <div>
                                    {values?.map((value, index) => (
                                        <Chip
                                            key={index}
                                            label={
                                                <div style={{ display: "flex", alignItems: "center", width: "100px" }}>
                                                    <img
                                                        className="w-[15px] h-[15px] mr-2 object-cover rounded-full"
                                                        alt="Natacha"
                                                        src={`/images/common/profileImg.jpg`}
                                                    />
                                                    <span title={value?.username} className="block truncate">
                                                        {value?.username}
                                                    </span>
                                                </div>
                                            }
                                            {...getTagProps({ index })}
                                        />
                                    ))}
                                </div>
                            )}
                            renderInput={({ inputProps, ...params }) => (
                                <TextField
                                    sx={{
                                        "& .MuiInputBase-input": {
                                            color: `${currentMode !== "dark" ? `#555555` : `#ECECEC`}`,
                                        },
                                    }}
                                    {...params}
                                    placeholder={userEmail?.length == 0 ? "Select Users" : ""}
                                    inputProps={{
                                        ...inputProps,
                                        readOnly: userEmail?.length > 0 ? true : false,
                                        endAdornment: null,
                                    }}
                                    // autoFocus={true}
                                />
                            )}
                        />
                    </div>
                    <div className=" flex mt-10">
                        <Button
                            className="bg-light-20 text-white text-base rounded-[8px] py-2 px-4"
                            onClick={() => {
                                setUserEmail(userEmail);
                                if (userEmail?.length > 0) {
                                    changeStatus({ ...statusReqObj, user_id: userEmail?.[0]?.id });
                                }
                                handleClose();
                            }}
                            title={"Save"}
                        >
                            {"Save"}
                        </Button>
                        <Button
                            // onClick={}
                            title="Cancel"
                            onClick={handleClose}
                        >
                            {" "}
                            <Button className="bg-light-240 text-light-40 text-base rounded-[8px]  ml-3 py-2 px-5">Cancel</Button>
                        </Button>
                    </div>
                </div>
            </Modal>
        </>
    );
};
export default SelectUserModal;
