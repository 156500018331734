import Button from "../../../components/Button";
import Input from "../../../components/Input";
import Modal from "../../../components/UI/Modal/Modal";
import { useSelector } from "react-redux";
import { ThemeContext } from "../../../Hooks/Themes/Themeprovider";
import { useContext } from "react";

const ViewAlias = (props) => {
    const OrgEditData = useSelector((state) => state?.organisationViewState);
    const { viewData } = OrgEditData;
    const ListData = viewData?.org_alias_list;
    const { currentMode } = useContext(ThemeContext);
    return (
        <>
            <Modal cartClose={props?.handleClose} center="center" overlap={true} className={`${props?.className}`}>
                <div
                    className={` ${!ListData ? "h-[324px]" : "max-h-[600px]"
                        }  overflow-y-auto relative mx-auto  bg-${currentMode}-card border-${currentMode}-700 text-${currentMode}-copy px-[90px] text-center py-[48px] rounded-[10px] shadow-[0px_4px_20px_rgba(238,238,238,0.5)]`}
                >
                    <span onClick={props?.handleClose} className="cursor-pointer absolute  right-[20px] top-[20px]">
                        <img src="/images/common/closeBlk.svg" alt="closeBlk" className="convertWhite" />
                    </span>
                    <table className="w-full mb-8 rolemasterTable overflow-x-auto accrual ">
                        <thead className={`bg-${currentMode}-580`}>
                            <tr>
                                <th>Sl.No.</th>
                                <th>Organization Alias Name</th>
                            </tr>
                        </thead>
                        <tbody>
                            {ListData && ListData?.length > 0 ? (
                                ListData?.map((listelem, index) => {
                                    return (
                                        <tr>
                                            <td className="text-left">{index + 1}</td>
                                            <td className="text-left cursor-default" title={listelem?.alias_name}>
                                                <span className=" block truncate w-[300px]">{listelem?.alias_name}</span>
                                            </td>
                                        </tr>
                                    );
                                })
                            ) : (
                                <tr>
                                    <td>
                                        {" "}
                                        <div className="absolute left-[50%]  translate-x-[-50%]">
                                            <img src="/images/common/delivery.png" alt="delivery" />
                                            <h4 className="font-interSb text-center mt-3">No data found</h4>
                                        </div>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </Modal>
        </>
    );
};
export default ViewAlias;
