// DraggableItem.js
import useDraggable from "../../Hooks/useDraggable";

const DraggableItem = ({ value, key, children, ifDragged }) => {
    const { isDragging, drag } = useDraggable("DRAGGABLE_ITEM", { value });
    return (
        <div
            ref={drag}
            key={key}
            style={{
                opacity: isDragging ? 0.5 : 1,
                cursor: "grab",
            }}>
            {children}
        </div>
    );
};

export default DraggableItem;
