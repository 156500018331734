import {
	GET_GENERAL_LEDGER_SYNC_LIST_REQUEST,
	GET_GENERAL_LEDGER_SYNC_LIST_SUCCESS,
	GET_GENERAL_LEDGER_SYNC_LIST_RESET,
	GET_GENERAL_LEDGER_SYNC_LIST_FAILURE,
	GET_GL_ACCOUNT_SYNC_REQUEST,
	GET_GL_ACCOUNT_SYNC_SUCCESS,
	GET_GL_ACCOUNT_SYNC_RESET,
	GET_GL_ACCOUNT_SYNC_FAILURE,
	GET_COST_CENTER_ACCOUNT_SYNC_REQUEST,
	GET_COST_CENTER_ACCOUNT_SYNC_SUCCESS,
	GET_COST_CENTER_ACCOUNT_SYNC_RESET,
	GET_COST_CENTER_ACCOUNT_SYNC_FAILURE,
	GET_INTERNAL_ORDER_ACCOUNT_SYNC_REQUEST,
	GET_INTERNAL_ORDER_ACCOUNT_SYNC_SUCCESS,
	GET_INTERNAL_ORDER_ACCOUNT_SYNC_RESET,
	GET_INTERNAL_ORDER_ACCOUNT_SYNC_FAILURE,
	GET_TAX_MASTER_SYNC_REQUEST,
	GET_TAX_MASTER_SYNC_SUCCESS,
	GET_TAX_MASTER_SYNC_RESET,
	GET_TAX_MASTER_SYNC_FAILURE,
} from "../types/types.js";

//GetGeneralLedgerSyncList
const GetGeneralLedgerSyncState = {
	syncData: [],
	fetching: false,
	requestComplete: false,
	error: "",
};
export function GetGeneralLedgerSync(state = GetGeneralLedgerSyncState, action) {
	switch (action.type) {
		case GET_GENERAL_LEDGER_SYNC_LIST_REQUEST:
			return {
				...state,
				fetching: true,
				requestComplete: false,
				error: "",
			};
		case GET_GENERAL_LEDGER_SYNC_LIST_SUCCESS:
			return {
				syncData: action.payload,
				fetching: false,
				requestComplete: true,
				error: "",
			};
		case GET_GENERAL_LEDGER_SYNC_LIST_RESET:
			return {
				syncData: [],
				fetching: false,
				requestComplete: false,
				error: "",
			};
		case GET_GENERAL_LEDGER_SYNC_LIST_FAILURE:
			return {
				fetching: false,
				requestComplete: false,
				error: action.payload,
			};
		default:
			return state;
	}
}

//SYNC GL ACCOUNT

//GetGeneralLedgerSyncList
const GetGLAccountSyncState = {
	glData: {},
	fetching: false,
	requestComplete: false,
	error: "",
};
export function GetGLAccountSync(state = GetGLAccountSyncState, action) {
	switch (action.type) {
		case GET_GL_ACCOUNT_SYNC_REQUEST:
			return {
				...state,
				fetching: true,
				requestComplete: false,
				error: "",
			};
		case GET_GL_ACCOUNT_SYNC_SUCCESS:
			return {
				glData: action.payload,
				fetching: false,
				requestComplete: true,
				error: "",
			};
		case GET_GL_ACCOUNT_SYNC_RESET:
			return {
				glData: {},
				fetching: false,
				requestComplete: false,
				error: "",
			};
		case GET_GL_ACCOUNT_SYNC_FAILURE:
			return {
				fetching: false,
				requestComplete: true,
				error: action.payload,
			};
		default:
			return state;
	}
}

//GetCostCenterSyncList
const GetCostCenterAccountSyncState = {
	ccData: [],
	fetching: false,
	requestComplete: false,
	error: "",
};
export function GetCostCenterAccountSync(state = GetCostCenterAccountSyncState, action) {
	switch (action.type) {
		case GET_COST_CENTER_ACCOUNT_SYNC_REQUEST:
			return {
				...state,
				fetching: true,
				requestComplete: false,
				error: "",
			};
		case GET_COST_CENTER_ACCOUNT_SYNC_SUCCESS:
			return {
				ccData: action.payload,
				fetching: false,
				requestComplete: true,
				error: "",
			};
		case GET_COST_CENTER_ACCOUNT_SYNC_RESET:
			return {
				ccData: [],
				fetching: false,
				requestComplete: false,
				error: "",
			};
		case GET_COST_CENTER_ACCOUNT_SYNC_FAILURE:
			return {
				fetching: false,
				requestComplete: true,
				error: action.payload,
			};
		default:
			return state;
	}
}

//GetGeneralLedgerSyncList
const GetIOAccountSyncState = {
	Data: {},
	fetching: false,
	requestComplete: false,
	error: "",
};
export function GetIOAccountSync(state = GetIOAccountSyncState, action) {
	switch (action.type) {
		case GET_INTERNAL_ORDER_ACCOUNT_SYNC_REQUEST:
			return {
				...state,
				fetching: true,
				requestComplete: false,
				error: "",
			};
		case GET_INTERNAL_ORDER_ACCOUNT_SYNC_SUCCESS:
			return {
				Data: action.payload,
				fetching: false,
				requestComplete: true,
				error: "",
			};
		case GET_INTERNAL_ORDER_ACCOUNT_SYNC_RESET:
			return {
				Data: {},
				fetching: false,
				requestComplete: false,
				error: "",
			};
		case GET_INTERNAL_ORDER_ACCOUNT_SYNC_FAILURE:
			return {
				fetching: false,
				requestComplete: true,
				error: action.payload,
			};
		default:
			return state;
	}
}

//GetTaxCodeSyncList
const GetTaxMasterSyncState = {
	syncData: {},
	fetching: false,
	requestComplete: false,
	error: "",
};
export function GetTaxMasterSync(state = GetTaxMasterSyncState, action) {
	switch (action.type) {
		case GET_TAX_MASTER_SYNC_REQUEST:
			return {
				...state,
				fetching: true,
				requestComplete: false,
				error: "",
			};
		case GET_TAX_MASTER_SYNC_SUCCESS:
			return {
				syncData: action.payload,
				fetching: false,
				requestComplete: true,
				error: "",
			};
		case GET_TAX_MASTER_SYNC_RESET:
			return {
				syncData: {},
				fetching: false,
				requestComplete: false,
				error: "",
			};
		case GET_TAX_MASTER_SYNC_FAILURE:
			return {
				fetching: false,
				requestComplete: true,
				error: action.payload,
			};
		default:
			return state;
	}
}
