import { Link } from "react-router-dom";
import Modal from "../../../components/UI/Modal/Modal";
import UserActivityModal from "./UserActivity";
import { useState } from "react";
import { useContext } from "react";
import { ThemeContext } from "../../../Hooks/Themes/Themeprovider";
const UserActivity = (props) => {
    const { currentMode } = useContext(ThemeContext);
    const userid = props?.userid;
    const [searchInput, setSearchInput] = useState("");
    const cartClose = () => {
        props?.handleClose();
        setSearchInput("");
    };
    const currentColor = currentMode === "dark" ? "black" : ``;

    return (
        <>
            <Modal cartClose={cartClose} className={`${props?.className}`}>
                <span onClick={cartClose} className="p-[6.5px] cursor-pointer absolute right-[500px] bg-light-10" title="Close">
                    <img src="/images/common/whiteClose.svg" alt="whiteClose" />
                </span>
                <div
                    className={` w-[500px] bg-${currentMode}-card xl:min-w-[498px] ml-auto transition duration-150 ease-out flex-col flex h-screen max-h-[-webkit-fill-available]`}
                >
                    <UserActivityModal cartClose={cartClose} userid={userid} searchInput={searchInput} setSearchInput={setSearchInput} />
                </div>
            </Modal>
        </>
    );
};

export default UserActivity;
