import { useState, useEffect, useContext } from 'react';
import { ThemeContext } from '../Themes/Themeprovider';

function CountingAnimation({ startValue, endValue, duration }) {
    const [count, setCount] = useState(startValue);
    const { currentMode } = useContext(ThemeContext);

    useEffect(() => {
        const interval = setInterval(() => {
            if (count < endValue) {
                setCount(count + 1);
            } else {
                clearInterval(interval);
            }
        }, duration / (endValue - startValue));

        return () => clearInterval(interval);
    }, [count, duration, endValue, startValue]);
    useEffect(() => {
        if (count !== startValue) {
            setCount(endValue);
        }
    }, [endValue])
    useEffect(() => {
        if (count === startValue) {
            setCount(endValue);
        }
    }, []);

    return (
        <div className={`counting-animation font-interSb text-${currentMode}-20`}>
            {count}
        </div>
    );
}

export default CountingAnimation;