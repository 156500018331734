import {
  GET_FREE_TRAIL_REQUEST,
  GET_FREE_TRAIL_FAILURE,
  GET_FREE_TRAIL_SUCCESS,
  GET_FREE_TRAIL_RESET,
} from "../types/types";
const getFreeTrial = {
  data: [],
  fetching: false,
  requestComplete: false,
  error: "",
};
export function GetFreeTrailReducer(state = getFreeTrial, action) {
  switch (action.type) {
    case GET_FREE_TRAIL_REQUEST:
      return {
        ...state,
        fetching: true,
        requestComplete: false,
        error: "",
      };
    case GET_FREE_TRAIL_SUCCESS:
      return {
        data: action.payload,
        fetching: false,
        requestComplete: true,
        error: "",
      };
    case GET_FREE_TRAIL_RESET:
      return {
        data: [],
        fetching: false,
        requestComplete: false,
        error: "",
      };
    case GET_FREE_TRAIL_FAILURE:
      return {
        fetching: false,
        requestComplete: false,
        error: action.payload,
      };
    default:
      return state;
  }
}
