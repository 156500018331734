import * as React from "react";
import { useState, useEffect } from "react";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import { Alert, Slide } from "@mui/material";
import { useContext } from "react";
import { ThemeContext } from "../../../Hooks/Themes/Themeprovider";

const SuccessMessageBox = (props) => {
    const { success } = props;
    const { currentMode } = useContext(ThemeContext);
    const [open, setOpen] = useState(false);
    useEffect(() => {
        if (success) setOpen(true);
    }, [success]);

    const TransitionLeft = (props) => {
        return <Slide {...props} direction="left" />;
    };

    const action = (
        <React.Fragment>
            <Alert severity="success">{success}</Alert>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={() => setOpen(false)}
            >
                <img
                    src="/images/common/close.png"
                    alt="close"
                    className="convertWhite"
                />
            </IconButton>
        </React.Fragment>
    );

    return (
        <div>
            <Snackbar
                TransitionComponent={TransitionLeft}
                open={open}
                autoHideDuration={null}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                sx={{
                    "& .MuiPaper-root": {
                        border: "0 !important",
                        boxShadow: "none !important",
                    },
                    "& .MuiSnackbarContent-root": {
                        color: "#EA3D2F",
                        backgroundColor: "rgb(237, 247, 237)",
                        paddingLeft: "0",
                        border: "0 !important",
                        boxShadow: "none !important",
                    },
                    "& .MuiSnackbarContent-action": { padding: "0" },
                }}
                onClose={() => setOpen(false)}
                action={action}
            />
        </div>
    );
};

export default SuccessMessageBox;
