import Container from "../../components/UI/Container";

export const CookieDisable = () => {
    return (
        <section className="w-screen absolute z-[11] text-center h-screen grid place-items-center bg-[#FAFBFC]">
            <Container className="grid place-items-center place-content-center">
                <img
                    className="w-[86px] mb-4 h-[80px]"
                    src="/images/logo/MyiqFinal.svg"
                    alt="MyiqFinal"
                />
                <h5 className="text-base">
                    Cookies are disabled for your browser. Please enable cookies
                    to continue.
                </h5>
            </Container>
        </section>
    );
};
