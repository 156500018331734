import axios from "axios";
import {
    GET_PO_RETENTION_HISTORY_REQUEST,
    GET_PO_RETENTION_HISTORY_FAILURE,
    GET_PO_RETENTION_HISTORY_SUCCESS,
    GET_PO_RETENTION_HISTORY_RESET,
    GET_RETENTION_RELEASE_VIEW_REQUEST,
    GET_RETENTION_RELEASE_VIEW_SUCCESS,
    GET_RETENTION_RELEASE_VIEW_RESET,
    GET_RETENTION_RELEASE_VIEW_FAILURE,
    POST_RETENTION_RELEASE_REQUEST,
    POST_RETENTION_RELEASE_SUCCESS,
    POST_RETENTION_RELEASE_RESET,
    POST_RETENTION_RELEASE_FAILURE,
    GET_RETENTION_LIST_FAILURE,
    GET_RETENTION_LIST_REQUEST,
    GET_RETENTION_LIST_SUCCESS,
    GET_RETENTION_LIST_RESET,
    EXPORT_RETENTION_REQUEST_LIST_SUCCESS,
} from "../types/types";
import authHeader from "./auth-header";
import { checkApiStatus } from "../../utils/helpers";
const API_URL = process.env?.REACT_APP_API_URL;
const API = process.env?.REACT_APP_NODE_API_URL;

export const getPoRetentionReleaseHistory = (poNumber, orgId) => async (dispatch) => {
    try {
        dispatch({ type: GET_PO_RETENTION_HISTORY_REQUEST });

        axios
            .get(`${API}api/RetentionRelease/GetPoRetentionHistory?org_id=${orgId}&poNumber=${poNumber}`, {
                headers: authHeader(),
            })
            .then((response) => {
                dispatch({
                    type: GET_PO_RETENTION_HISTORY_SUCCESS,
                    payload: response.data,
                });
            })
            .catch(function (error) {
                if (error?.response?.status == 401) {
                    checkApiStatus();
                }
                dispatch({
                    payload: { error: error.message },
                    type: GET_PO_RETENTION_HISTORY_FAILURE,
                });
            });
    } catch (error) {
        dispatch({
            type: GET_PO_RETENTION_HISTORY_FAILURE,
        });
    }
};

export const getRetentionReleaseView = (id, orgId) => async (dispatch) => {
    try {
        dispatch({ type: GET_RETENTION_RELEASE_VIEW_REQUEST });

        axios
            .get(`${API}api/RetentionRelease/GetRetentionReleaseView?doc_id=${id}&org_id=${orgId}`, {
                headers: authHeader(),
            })
            .then((response) => {
                dispatch({
                    type: GET_RETENTION_RELEASE_VIEW_SUCCESS,
                    payload: response.data,
                });
            })
            .catch(function (error) {
                if (error?.response?.status == 401) {
                    checkApiStatus();
                }
                dispatch({
                    payload: { error: error.message },
                    type: GET_RETENTION_RELEASE_VIEW_FAILURE,
                });
            });
    } catch (error) {
        dispatch({
            type: GET_RETENTION_RELEASE_VIEW_FAILURE,
        });
    }
};

export const postRetentionReleaseView = (data) => async (dispatch) => {
    try {
        dispatch({ type: POST_RETENTION_RELEASE_REQUEST });

        axios
            .post(`${API}api/RetentionRelease/AddUpdateRetentionRelease`, data, {
                headers: authHeader(),
            })
            .then((response) => {
                dispatch({
                    type: POST_RETENTION_RELEASE_SUCCESS,
                    payload: response?.data,
                });
            })
            .catch(function (error) {
                if (error?.response?.status == 401) {
                    checkApiStatus();
                }
                dispatch({
                    payload: { error: error?.response?.data },
                    type: POST_RETENTION_RELEASE_FAILURE,
                });
            });
    } catch (error) {
        dispatch({
            type: POST_RETENTION_RELEASE_FAILURE,
        });
    }
};

export const getRetentionList = (data, download) => async (dispatch) => {
    try {
        if (download !== "download") {
            dispatch({ type: GET_RETENTION_LIST_REQUEST });
        }
        axios
            .post(`${API_URL}api/Recovery/GetDocumentListcount`, data, {
                headers: authHeader(),
            })
            .then((response) => {
                if (download === "download") {
                    dispatch({
                        type: EXPORT_RETENTION_REQUEST_LIST_SUCCESS, //export
                        payload: response?.data,
                    });
                } else {
                    dispatch({
                        type: GET_RETENTION_LIST_SUCCESS,
                        payload: response?.data,
                    });
                }
            })
            .catch((ex) => {
                dispatch({
                    type: GET_RETENTION_LIST_FAILURE,
                    payload: ex,
                });
            });
    } catch (error) {
        if (error?.response?.status == 401) {
            checkApiStatus();
        }
        dispatch({
            type: GET_RETENTION_LIST_FAILURE,
        });
    }
};
