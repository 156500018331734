import { useSelector, useDispatch } from "react-redux";

import Button from "../../../components/Button";
import Modal from "../../../components/UI/Modal/Modal";
import React, { useState, useEffect, useContext } from "react";
import { Select, MenuItem } from "@mui/material";
import { ThemeContext } from "../../../Hooks/Themes/Themeprovider";
import { TransferInvAct } from "../../../redux/actions/invoice";
import { transferInvoiceReject } from "../../../redux/actions/invoice";
import { TRANSFER_INVOICE_RESET } from "../../../redux/types/types";
import Swal from "sweetalert2";
import { useLocation, useNavigate } from "react-router-dom";
import { decryptId } from "../../../utils/helpers";
let urlObj;
const TransferInvoice = (props) => {
    const OrgId = localStorage.getItem("orgId");
    const dispatch = useDispatch();
    const location = useLocation();
    const urlData = location?.search?.slice(1)?.replace(/\n\s+/g, "\n")?.trim();
    const regex = /^type=tour&tourId=[1-9]|10$/;
    useEffect(() => {
        if (urlData && !regex.test(urlData)) {
            urlObj = JSON?.parse(decryptId(urlData));
        }
    }, [urlData]);

    const navigate = useNavigate();
    const { currentMode, toggleMode } = useContext(ThemeContext);
    const orgData = useSelector((state) => state?.ListorganisationDropDownState);
    const invoiceList = useSelector((state) => state?.getInvoiceItemsState);
    const { data: InvData } = invoiceList;
    const InvCountryId = InvData?.datalist?.[0]?.country;
    const { data, fetching, requestComplete, error } = useSelector((state) => state?.TransferInvState);
    const [errormsgs, seterrormsg] = useState({
        message: "",
    });
    const dataList = orgData.viewData;
    const dataList1 = dataList?.filter((el) =>
        InvCountryId == 105 ? el?.id !== +OrgId && el?.country == 105 : el?.id !== +OrgId && el?.country !== 105
    );
    const [orgName, setOrgName] = useState("");
    const onChangeOrganisation = (e) => {
        setOrgName(e);
    };
    const dropdownColor = currentMode === "dark" ? "#333333" : `white`;
    const dropdownText = currentMode === "dark" ? "#ECECEC" : `#555555`;
    const submit = () => {
        if (orgName == "") {
            seterrormsg({
                message: "Please Select any one Organization",
            });
            return false;
        } else {
            seterrormsg({
                message: "",
            });
            dispatch(TransferInvAct(props?.InvId, orgName));
        }
    };
    const cartClose = () => {
        setOrgName("");
        props?.handleClose();
        seterrormsg({
            message: "",
        });
    };
    if (data?.status) {
        // if (data?.datalist?.[0]?.issap) {
        //     dispatch(
        //         transferInvoiceReject(
        //             data?.datalist?.[0]?.invoice_id,
        //             data?.datalist?.[0]?.org_id
        //         )
        //     );
        //     dispatch({ type: TRANSFER_INVOICE_RESET });
        // }

        Swal.fire({
            text: `Invoice is transfered successfully!`,
            closeOnClickOutside: false,
            showCloseButton: true,
            icon: "success",
            buttons: {
                confirm: true,
            },
        }).then((confirm) => {
            setTimeout(() => {
                if (confirm && urlObj?.type == 2) {
                    navigate("/exception");
                } else if (confirm && urlObj?.type == 1) {
                    navigate("/invoice");
                } else if (confirm && urlObj?.type == 3) {
                    navigate("/approve");
                } else if (confirm && (location?.pathname == "/invoice" || location?.pathname == "/exception" || location?.pathname == "/approve")) {
                    window.location.reload();
                }
                dispatch({ type: TRANSFER_INVOICE_RESET });
            }, 1000);
        });
    } else if (!data?.status && requestComplete) {
        Swal.fire({
            text: data?.message,
            closeOnClickOutside: false,
            icon: "warning",
            showCloseButton: true,
            buttons: {
                confirm: true,
            },
        });
    }
    return (
        <>
            <Modal cartClose={cartClose} center="center" className={`${props?.className}`}>
                <div
                    className={`relative max-w-[600px] ml-auto mx-auto bg-${currentMode}-card text-${currentMode}-copy border-${currentMode}-700 px-[90px] py-[48px] rounded-[10px] ${
                        currentMode === "dark" ? "" : "shadow-[0px_4px_20px_rgba(238,238,238,0.5)]"
                    }`}
                >
                    <Button onClick={cartClose} tabIndex="4" className="cursor-pointer absolute right-[25px] top-[20px]">
                        <img src="/images/common/closeBlk.svg" alt="closeBlk" className="convertWhite" />
                    </Button>
                    <span className="font-interSb text-center text-lg block mb-4">Transfer the Invoice</span>
                    <p className="mb-4 text-center">Select any Organization to Transfer the Invoice</p>
                    <Select
                        value={orgName || "0"}
                        name="orgName"
                        onChange={(e) => onChangeOrganisation(e.target.value)}
                        inputProps={{ "aria-label": "Without label" }}
                        fullWidth
                        IconComponent={() => (
                            <img
                                className="absolute opacity-50 right-[15px] pointer-events-none"
                                src={currentMode !== "dark" ? `/images/header/chevronDown.svg` : `/images/header/chevronDown-white.svg`}
                            />
                        )}
                        MenuProps={{
                            sx: {
                                "& .MuiMenu-paper": {
                                    backgroundColor: dropdownColor,
                                    color: dropdownText,
                                    maxWidth: "30rem !important",
                                },
                                "& .MuiMenuItem-root:hover": {
                                    backgroundColor: "rgba(218, 249, 244, 0.3)",
                                },
                                "& .Mui-selected": {
                                    backgroundColor: "rgba(218, 249, 244, 0.3) !important",
                                },
                            },
                        }}
                        SelectDisplayProps={{
                            style: {
                                paddingTop: "9px",
                                paddingBottom: "9px",
                                color: `${currentMode !== "dark" ? `#555555` : `#ECECEC`}`,
                                paddingRight: "30px",
                                paddingLeft: "15px",
                                margin: "0 auto",
                                border: "1px solid rgba(85, 85, 85, 0.4)",
                                borderRadius: "8px",
                            },
                        }}
                        // IconComponent={() => <img className="absolute right-[10px] pointer-events-none" src={currentMode !== 'dark' ? `/images/header/chevronDown.svg` : `/images/header/chevronDown-white.svg`} alt="chevronDown" />}
                    >
                        <MenuItem key={0} name="orgName" value={0}>
                            {"Select"}
                        </MenuItem>

                        {dataList1 &&
                            dataList1?.map((elem, i) => {
                                return (
                                    <MenuItem key={i + 1} name="orgName" value={elem?.id}>
                                        {elem?.organisation_name}
                                    </MenuItem>
                                );
                            })}
                    </Select>
                    {errormsgs?.message && <p className="text-light-50 text-xs mb-1 mt-3">{errormsgs?.message}</p>}
                    <div className="flex justify-center items-center mt-7 space-x-2">
                        <Button className="bg-light-20 px-[30px] py-[8px] text-base text-white rounded-[8px] " onClick={submit} disabled={fetching}>
                            Save
                        </Button>
                        <Button className="bg-light-240 px-[28px] text-light-40 text-base py-[8px] rounded-[8px]" onClick={cartClose}>
                            Cancel
                        </Button>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default TransferInvoice;
