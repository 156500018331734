import axios from "axios";
import authHeader from "./auth-header";
import { checkApiStatus } from "../../utils/helpers";
import {
	ADD_UPDATE_MISC_FAILURE,
	ADD_UPDATE_MISC_REQUEST,
	ADD_UPDATE_MISC_SUCCESS,
	GET_MISC_LIST_FAILURE,
	GET_MISC_LIST_REQUEST,
	GET_MISC_LIST_SUCCESS,
	GET_MISC_PO_LIST_FAILURE,
	GET_MISC_PO_LIST_REQUEST,
	GET_MISC_PO_LIST_SUCCESS,
	GET_MISC_VENDOR_LIST_FAILURE,
	GET_MISC_VENDOR_LIST_REQUEST,
	GET_MISC_VENDOR_LIST_SUCCESS,
	GET_MISC_WAV_SYNC_FAILURE,
	GET_MISC_WAV_SYNC_REQUEST,
	GET_MISC_WAV_SYNC_SUCCESS,
	GET_MISC_WBS_LIST_FAILURE,
	GET_MISC_WBS_LIST_REQUEST,
	GET_MISC_WBS_LIST_SUCCESS,
	TDS_LIST_FAILURE,
	TDS_LIST_REQUEST,
	TDS_LIST_SUCCESS,
	VIEW_MISC_FAILURE,
	VIEW_MISC_REQUEST,
	VIEW_MISC_SUCCESS,
} from "../types/types";
const API_URL = process.env?.REACT_APP_NODE_API_URL;

export const AddUpdateMiscApi = (data) => async (dispatch) => {
	try {
		dispatch({ type: ADD_UPDATE_MISC_REQUEST });

		axios
			.post(`${API_URL}api/Miscellaneous/AddUpdateMiscellaneousRequest`, data, {
				headers: authHeader(),
			})
			.then((response) => {
				dispatch({
					type: ADD_UPDATE_MISC_SUCCESS,
					payload: response?.data,
				});
			})
			.catch(function (error) {
				if (error?.response?.status == 401) {
					checkApiStatus();
				}
				dispatch({
					payload: { error: error.message },
					type: ADD_UPDATE_MISC_FAILURE,
				});
			});
	} catch (error) {
		dispatch({
			type: ADD_UPDATE_MISC_FAILURE,
		});
	}
};

export const ViewMiscApi = (req_id, org_id) => async (dispatch) => {
	try {
		dispatch({ type: VIEW_MISC_REQUEST });

		axios
			.get(`${API_URL}api/Miscellaneous/GetMiscellaneousView?req_id=${req_id}&org_id=${org_id}`, {
				headers: authHeader(),
			})
			.then((response) => {
				dispatch({
					type: VIEW_MISC_SUCCESS,
					payload: response?.data,
				});
			})
			.catch(function (error) {
				if (error?.response?.status == 401) {
					checkApiStatus();
				}
				dispatch({
					payload: { error: error.message },
					type: VIEW_MISC_FAILURE,
				});
			});
	} catch (error) {
		dispatch({
			type: VIEW_MISC_FAILURE,
		});
	}
};
export const MiscListApi = (data) => async (dispatch) => {
	try {
		dispatch({ type: GET_MISC_LIST_REQUEST });

		axios
			.get(`${API_URL}api/ExchangeRate/GetCurrencyByorg`, {
				headers: authHeader(),
			})
			.then((response) => {
				dispatch({
					type: GET_MISC_LIST_SUCCESS,
					payload: response?.data?.datalist,
				});
			})
			.catch(function (error) {
				if (error?.response?.status == 401) {
					checkApiStatus();
				}
				dispatch({
					payload: { error: error.message },
					type: GET_MISC_LIST_FAILURE,
				});
			});
	} catch (error) {
		dispatch({
			type: GET_MISC_LIST_FAILURE,
		});
	}
};

export const GetPoMiscAction = (PoNo, OrgId) => async (dispatch) => {
	try {
		dispatch({ type: GET_MISC_PO_LIST_REQUEST });

		axios
			.get(`${API_URL}api/Miscellaneous/GetPoMiscellaneous?po_number=${PoNo}&org_id=${OrgId}`, {
				headers: authHeader(),
			})
			.then((response) => {
				dispatch({
					type: GET_MISC_PO_LIST_SUCCESS,
					payload: response?.data,
				});
			})
			.catch(function (error) {
				if (error?.response?.status == 401) {
					checkApiStatus();
				}
				dispatch({
					payload: { error: error.message },
					type: GET_MISC_PO_LIST_FAILURE,
				});
			});
	} catch (error) {
		dispatch({
			type: GET_MISC_PO_LIST_FAILURE,
		});
	}
};
export const GetMiscVendorListAction = (orgid) => async (dispatch) => {
	try {
		dispatch({ type: GET_MISC_VENDOR_LIST_REQUEST });

		axios
			.get(`${API_URL}api/Miscellaneous/GetVendorList?org_id=${orgid}`, {
				headers: authHeader(),
			})
			.then((response) => {
				dispatch({
					type: GET_MISC_VENDOR_LIST_SUCCESS,
					payload: response?.data?.data,
				});
			})
			.catch(function (error) {
				if (error?.response?.status == 401) {
					checkApiStatus();
				}
				dispatch({
					payload: { error: error.message },
					type: GET_MISC_VENDOR_LIST_FAILURE,
				});
			});
	} catch (error) {
		dispatch({
			type: GET_MISC_VENDOR_LIST_FAILURE,
		});
	}
};

export const GetWbsListAction = (org_id) => async (dispatch) => {
	try {
		dispatch({ type: GET_MISC_WBS_LIST_REQUEST });

		axios
			.get(`${API_URL}api/Miscellaneous/GetWbsList?org_id=${org_id}`, {
				headers: authHeader(),
			})
			.then((response) => {
				dispatch({
					type: GET_MISC_WBS_LIST_SUCCESS,
					payload: response?.data?.data,
				});
			})
			.catch(function (error) {
				if (error?.response?.status == 401) {
					checkApiStatus();
				}
				dispatch({
					payload: { error: error.message },
					type: GET_MISC_WBS_LIST_FAILURE,
				});
			});
	} catch (error) {
		dispatch({
			type: GET_MISC_WBS_LIST_FAILURE,
		});
	}
};

export const WbsVendorAssestSyncAction = (org_id, type) => async (dispatch) => {
	try {
		dispatch({ type: GET_MISC_WAV_SYNC_REQUEST });

		axios
			.get(`${API_URL}api/MasterSync/SyncAssetWbsVendorSet?org_id=${org_id}&type=${type}`, {
				headers: authHeader(),
			})
			.then((response) => {
				dispatch({
					type: GET_MISC_WAV_SYNC_SUCCESS,
					payload: response?.data,
				});
			})
			.catch(function (error) {
				if (error?.response?.status == 401) {
					checkApiStatus();
				}
				dispatch({
					payload: { error: error.message },
					type: GET_MISC_WAV_SYNC_FAILURE,
				});
			});
	} catch (error) {
		dispatch({
			type: GET_MISC_WAV_SYNC_FAILURE,
		});
	}
};
export const TdsApiAction = (data) => async (dispatch) => {
	try {
		dispatch({ type: TDS_LIST_REQUEST });
		axios
			.post(`${API_URL}api/Recovery/GetTdsDropdown`, data, {
				headers: authHeader(),
			})
			.then((response) => {
				dispatch({
					type: TDS_LIST_SUCCESS,
					payload: response?.data?.data,
				});
			})
			.catch(function (error) {
				if (error?.response?.status == 401) {
					checkApiStatus();
				}
				dispatch({
					payload: { error: error.message },
					type: TDS_LIST_FAILURE,
				});
			});
	} catch (error) {
		dispatch({
			type: TDS_LIST_FAILURE,
		});
	}
};
