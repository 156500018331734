import {
    Autocomplete,
    Checkbox,
    ClickAwayListener,
    Fade,
    MenuItem,
    Paper,
    Popper,
    TextField,
} from "@mui/material";
import {
    DatePicker,
    DesktopDatePicker,
    LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { json, Link } from "react-router-dom";
import Swal from "sweetalert2";
import Button from "../../components/Button";
import Input from "../../components/Input";
import Card from "../../components/UI/Card";
import Main from "../../components/UI/Main";
import Layout from "../../Hoc/Layout";
import Footer from "../../organisms/Footer";
import { invoiceVendorList } from "../../redux/actions/invoice";
import { getReportsAccrualList } from "../../redux/actions/report";
import AccrualTbl from "./AccrualTbl";
import { GetAccrualPosting } from "../../redux/actions/accrual-accounting";
import { ThemeContext } from "../../Hooks/Themes/Themeprovider";

const AccrualReport = () => {
    const { currentMode } = useContext(ThemeContext);
    let optionValue = null;
    let optionStatus = null;

    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const [placement, setPlacement] = useState();
    const [invNumber, setInvNumber] = useState("");
    const [isSupplier, setSupplierValue] = useState(null);
    const [isStatus, setStatusValue] = useState([]);
    const [val, setVal] = useState([]);
    const [valStatus, setValStatus] = useState([]);
    const [isadult, setIsAdult] = useState([]);
    const [isAdultStatus, setIsAdultStatus] = useState([]);
    const [open, setOpen] = useState(false);
    const lastDateOfYear = `12/31/${new Date().getFullYear()}`;
    const [selected, setSelected] = useState({ status: [], supplier: [] });

    const dispatch = useDispatch();
    const handleClick = (newPlacement) => (event) => {
        setAnchorEl(event.currentTarget);
        setOpen((prev) => placement !== newPlacement || !prev);
        setPlacement(newPlacement);
    };

    const handleClickAway = () => {
        setOpen(false);
    };

    const handleValue = (newValue) => {
        if (newValue) {
            setFromDate(newValue);
        }
    };
    const handleToDate = (toDate) => {
        if (toDate) {
            setToDate(toDate);
        }
    };
    const AccessIcon = () => (
        <img
            src="/images/invoice/calendar.svg"
            tabIndex={0}
            alt="calendar"
            className="convertWhite"
        />
    );
    const supplierSelected = val.map((el) => el?.vendor_name);

    const handleCheckboxChange = (event, orgId) => {
        setIsAdult({
            ...isadult,
            [event.target.name]: event.target.value,
        });
        if (!event.target.value) {
            if (val.length > 0) {
                const isExistID = val.find((item) => item.id === orgId);
                if (isExistID) {
                    const removeIdList = val.filter(
                        (item) => item.id !== orgId
                    );
                    setVal([...removeIdList]);
                }
            }
        }
    };
    const statusSelected = valStatus?.map((item) => item?.value);
    const handleCheckboxStatusChange = (event, value) => {
        setIsAdultStatus({
            ...isAdultStatus,
            [event.target.name]: event.target.value,
        });
        if (!event.target.value) {
            if (valStatus.length > 0) {
                const isExistID = valStatus.find(
                    (item) => item.value === value
                );
                // alert(isExistID);
                if (isExistID) {
                    const removeIdList = valStatus.filter(
                        (item) => item.value !== value
                    );
                    setValStatus([...removeIdList]);
                }
            }
        }
    };
    // const orgData = useSelector((state) => state?.ListorganisationDropDownState);
    const { data: orgData, requestComplete } = useSelector(
        (state) => state?.GetAccrualPostingState
    );
    const status = orgData;

    const orgId = localStorage.getItem("orgId");
    const { vebdorlist } = useSelector(
        (state) => state?.InvoiceVendorListState
    );
    const supplier = vebdorlist?.map((item) => item?.vendor_name);
    optionValue = supplier?.map((el, i) => (
        <MenuItem key={i} value={el}>
            {el}
        </MenuItem>
    ));
    const onKeyDown = (e) => {
        if (!/^[\d-]*$/.test(e.key) && e.key !== 'Backspace' && e.key !== 'ArrowRight' && e.key !== "ArrowLeft") {
            e.preventDefault();
        }
    };

    optionStatus = status?.map((el, i) => (
        <MenuItem key={i} value={el?.org_id}>
            {el?.org_name}
        </MenuItem>
    ));
    const fromDateValue = moment(new Date(fromDate)).format("YYYY-MM-DD")
    const toDateValue = moment(new Date(toDate)).format("YYYY-MM-DD")
    const reqStr = {
        org_id: isStatus,
        from_date: fromDate
            ? moment(new Date(fromDate)).format("YYYY-MM-DD")
            : null,
        to_date: toDate ? moment(new Date(toDate)).format("YYYY-MM-DD") : null,
    };
    const handleSearchFilter = () => {
        if (toDate == null && fromDate == null) {
            Swal.fire({
                text: "At least one of the periods must be chosen!",
                icon: "warning",
                showCloseButton: true,
                closeOnClickOutside: false,
                button: "Ok",
            });
        } else if (Date.parse(fromDate) > Date.parse(toDate)) {
            Swal.fire({
                title: "Invalid Date Range!",
                // text: "Start Date cannot be after End Date!",
                icon: "warning",
                showCloseButton: true,
                closeOnClickOutside: false,
                button: "Ok",
            });
        }
        else if (fromDateValue == "Invalid date" || toDateValue == "Invalid date") {
            Swal.fire({
                title: "Invalid Date Range!",
                // text: "Start Date cannot be after End Date!",
                icon: "warning",
                closeOnClickOutside: false,
                showCloseButton: true,
                button: "Ok",
            });
        }
        else {
            dispatch(getReportsAccrualList(reqStr));
        }
    };
    useEffect(() => {
        // const reqObj = { orgid: +orgId, type: "Mar 2" };
        //  dispatch(getReportsAccrualList(reqStr));
        dispatch(GetAccrualPosting());
    }, [dispatch]);

    const dropdownColor = currentMode === "dark" ? "#333333" : `white`;
    const dropdownText = currentMode === "dark" ? "#ECECEC" : `#555555`;
    const dropdownShadow =
        currentMode !== "dark" ? "0px 4px 20px rgba(238, 238, 238, 0.5)" : "";
    const dropdownBorder = currentMode !== "dark" ? "223, 225, 226, 0.56" : "";
    return (
        <>
            <Layout>
                <Main>
                    <div
                        className={`flex justify-between items-center mb-6 text-${currentMode}-copy`}
                    >
                        <div>
                            <h4 className={`text-2xl font-interSb`}>
                                Accrual Reports
                            </h4>
                        </div>
                        <Link className="flex" to="/reports" tabIndex={0}>
                            <img
                                className="mr-2 convertWhite"
                                src="/images/main/noun-back.svg"
                                alt="noun-back"
                            />{" "}
                            Back
                        </Link>
                    </div>
                    <Card className="p-[20px] mb-6">
                        <div className="grid grid-cols-2 xl:grid-cols-4 gap-4">
                            <div className="filter">
                                <span className="block mb-[10px]">
                                    Organization
                                </span>
                                <Autocomplete
                                    multiple
                                    //   value={selected}
                                    tabIndex={0}
                                    clearOnBlur={false}
                                    id="checkboxes-tags-demo"
                                    options={status}
                                    onChange={(event, value) =>
                                        setStatusValue(
                                            value?.map((el) => el?.org_id)
                                        )
                                    }
                                    disableCloseOnSelect
                                    PaperComponent={({ children }) => (
                                        <Paper
                                            style={{
                                                backgroundColor: dropdownColor,
                                                color: dropdownText,
                                                boxShadow: dropdownShadow,
                                                border: dropdownBorder,
                                            }}
                                        >
                                            {children}
                                        </Paper>
                                    )}
                                    popupIcon={
                                        <img
                                            className="m-0 pointer-events-none"
                                            src="/images/header/chevronDown.svg"
                                        />
                                    }
                                    sx={{
                                        "& .MuiAutocomplete-popper": {
                                            backgroundColor: dropdownColor,
                                            color: dropdownText,
                                            boxShadow:
                                                "0px 4px 20px rgba(238, 238, 238, 0.5)",
                                            border: "1px solid rgba(223, 225, 226, 0.56)",
                                            borderRadius: "8px",
                                        },
                                        "& .MuiMenuItem-root:hover": {
                                            backgroundColor:
                                                "rgba(218, 249, 244, 0.3)",
                                        },
                                        "& .Mui-selected": {
                                            backgroundColor:
                                                "rgba(218, 249, 244, 0.3) !important",
                                        },

                                        "& .MuiAutocomplete-popupIndicator": {
                                            padding: 0,
                                            marginTop: "5px",
                                            marginRight: "10px",
                                        },
                                        "& .MuiAutocomplete-popupIndicatorOpen":
                                        {
                                            padding: 0,
                                            marginRight: "10px",
                                        },
                                        "& .MuiChip-label": {
                                            color: `${currentMode == "dark"
                                                ? "white"
                                                : "black"
                                                }`,
                                        },
                                    }}
                                    getOptionLabel={(status) => status.org_name}
                                    isOptionEqualToValue={(option, value) =>
                                        option?.org_id === value?.org_id
                                    }
                                    limitTags={1}
                                    renderOption={(
                                        props,
                                        option,
                                        { selected }
                                    ) => (
                                        <li {...props} tabIndex={1}>
                                            <Checkbox
                                                size="small"
                                                sx={{
                                                    color: "rgba(85,85,85,0.7)",
                                                    borderRadius:
                                                        "3px !important",
                                                    "&.Mui-checked": {
                                                        color: "#028C78",
                                                    },
                                                }}
                                                value={option?.org_id}
                                                checked={selected}
                                            />
                                            {option?.org_name}
                                        </li>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            fullWidth
                                            placeholder="Organization"
                                            InputProps={{
                                                ...params.InputProps,
                                                endAdornment: null,
                                            }}
                                        />
                                    )}
                                />
                            </div>
                            <div className="datePic">
                                <span className="block mb-[10px]">
                                    Invoice From
                                </span>
                                <div title="Calendar">
                                    <LocalizationProvider
                                        dateAdapter={AdapterDayjs}
                                    >
                                        <DesktopDatePicker
                                            selectsStart
                                            inputFormat="MM-YYYY"
                                            disableFuture={true}
                                            // className="font-interR  border-[1px] px-[15px] truncate  text-[rgba(85,85,85,0.4)] text-left border620 py-[10px] w-full rounded-[8px]"
                                            views={["month", "year"]}
                                            value={fromDate}
                                            components={{
                                                OpenPickerIcon: AccessIcon,
                                            }}
                                            // tabIndex={1}
                                            minDate={new Date(1999, 12)}
                                            maxDate={new Date(lastDateOfYear)}
                                            name="invoice_date_from"
                                            onChange={handleValue}
                                            keyboardIcon={() => (
                                                <img
                                                    tabIndex={0}
                                                    src="/images/common/date-ico.svg"
                                                    alt="date-ico"
                                                />
                                            )}
                                            renderInput={(params) => {
                                                return (
                                                    <TextField
                                                        // inputProps={{ tabIndex: 1 }}

                                                        sx={{
                                                            "& .MuiInputBase-input":
                                                            {
                                                                color: `${currentMode ==
                                                                    "dark"
                                                                    ? "white"
                                                                    : "black"
                                                                    } !important`,
                                                            },
                                                        }}
                                                        fullWidth
                                                        onKeyDown={onKeyDown}
                                                        placeholder={params.inputProps?.placeholder?.replace(
                                                            "From"
                                                        )}
                                                        {...params}
                                                    />
                                                );
                                            }}
                                        />
                                    </LocalizationProvider>
                                </div>
                            </div>
                            <div className="datePic">
                                <span className="block mb-[10px]">
                                    Invoice To
                                </span>
                                <div title="Calendar">
                                    <LocalizationProvider
                                        dateAdapter={AdapterDayjs}
                                    >
                                        <DesktopDatePicker
                                            selectsEnd
                                            inputFormat="MM-YYYY"
                                            name="invoice_date_to"
                                            keyboardIcon={() => (
                                                <img
                                                    tabIndex={0}
                                                    src="/images/common/date-ico.svg"
                                                    alt="date-ico"
                                                />
                                            )}
                                            value={toDate}
                                            disableFuture={true}
                                            views={["month", "year"]}
                                            onChange={handleToDate}
                                            minDate={fromDate}
                                            maxDate={new Date(lastDateOfYear)}
                                            components={{
                                                OpenPickerIcon: AccessIcon,
                                            }}
                                            renderInput={(params) => {
                                                return (
                                                    <TextField
                                                        sx={{
                                                            "& .MuiInputBase-input":
                                                            {
                                                                color: `${currentMode ==
                                                                    "dark"
                                                                    ? "white"
                                                                    : "black"
                                                                    } !important`,
                                                            },
                                                        }}
                                                        className="border-[1px]"
                                                        fullWidth
                                                        // inputProps={{ tabIndex: 0 }}
                                                        onKeyDown={onKeyDown}
                                                        placeholder={params.inputProps?.placeholder?.replace(
                                                            "To"
                                                        )}
                                                        {...params}
                                                    />
                                                );
                                            }}
                                        />
                                    </LocalizationProvider>
                                </div>
                            </div>
                            <div className="flex items-end">
                                <Button
                                    disabled={
                                        isStatus?.length !== 0 ? false : true
                                    }
                                    tabIndex={0}
                                    onClick={handleSearchFilter}
                                    className="bg-light-20 btn-primary rounded-[8px] text-white "
                                >
                                    Search
                                </Button>
                            </div>
                        </div>
                    </Card>
                    <AccrualTbl />
                </Main>
                <Footer />
            </Layout>
        </>
    );
};

export default AccrualReport;
