import Button from "../../components/Button";
import Input from "../../components/Input";
import Card from "../../components/UI/Card";
import IOSSwitch from "../../components/UI/Switch";
import Main from "../../components/UI/Main";
import Layout from "../../Hoc/Layout";
import { useState, useEffect } from "react";
import { ADD_SERVER_CONFIG_RESET } from "../../redux/types/types";
import { useFormik } from 'formik';
import { serverValidationSchema } from '../../Hooks/validateschema';
import { useSelector, useDispatch } from "react-redux";
import { serverconfigAdd, serverconfigView } from "../../redux/actions/serverconfig";
import MessageBox from "../../components/UI/Alert";
import SuccessMessageBox from "../../components/UI/Alert/sucess";
import { Link } from "react-router-dom";

const successMsg = "Server configuration has been Updated successfully."

const IntegrationServer = () => {
    const [isRevealPwd, setIsRevealPwd] = useState(false);
    const [configValues, setConfigValues] = useState();
    const [isResetform, setResetForm] = useState(false);
    const dispatch = useDispatch();
    let serverResponse = useSelector((state) => state?.serverViewConfigState);
    if (configValues?.serverurl == undefined) {
        let obj = Object.assign({}, serverResponse.data);
        if (obj[0]?.serverurl !== undefined) {
            setConfigValues(obj[0]);
        }
    }
    let { requestComplete, profile } = useSelector(
        (state) => state?.serverAddConfigState
    );
    // if (responseMail?.fetching) {
    //   swal({
    //     text: "Server configuration has been Updated and validate token has sent to your Email.",
    //     icon: "success",
    //     button: "Ok",
    //   }).then((confirm) => {
    //     if (confirm) {
    //       responseMail.fetching = false;
    //       window.location.reload();
    //     }
    //   })
    // }

    const formik = useFormik({
        initialValues: {
            serverurl: configValues?.serverurl,
            password: configValues?.password,
            username: configValues?.username,
            isactive: configValues?.isactive
        },
        enableReinitialize: true,
        validationSchema: serverValidationSchema,
        onSubmit: values => {
            dispatch(
                serverconfigAdd({
                    id: configValues?.id,
                    serverurl: values?.serverurl,
                    username: values?.username,
                    password: values?.password,
                    isactive: values?.isactive
                })
            );

        },
    });
    const handleResetForm = () => {

        setConfigValues(null);
        setResetForm(true);
        formik.handleReset();
        dispatch({ type: ADD_SERVER_CONFIG_RESET });
        dispatch(serverconfigView());
    }
    //view
    useEffect(() => {
        dispatch(serverconfigView());
    }, [dispatch, configValues]);

    return (
        <>
            {requestComplete && profile?.status &&
                <SuccessMessageBox success={successMsg} />}
            {requestComplete && !profile?.status && <MessageBox error={profile?.message} />}
            <Layout>
                <Main>
                    <div className="flex justify-between items-center mb-4">
                        <h4 className="text-2xl font-interSb">
                            Integration Server Configuration
                        </h4>
                    </div>
                    <Card className="h-[670px] border-[1px] shadow-[0px_4px_20px_rgba(238,238,238,0.5)] border-[rgba(223,225,226,0.56)]">
                        <form onSubmit={formik.handleSubmit} >
                            <div className="flex justify-center items-center">
                                <div className="basis-2/5 mt-[3rem] max-w-[500px] mx-auto">
                                    <form action="">
                                        <div className="from-group mb-5">
                                            <label htmlFor="" className="mb-1 block">
                                                Url
                                            </label>
                                            <Input
                                                tabIndex="1"
                                                onChange={formik.handleChange}
                                                value={formik.values.serverurl}
                                                name="serverurl"
                                                placeholder="https://www.example.com"
                                                className="w-full py-3 rounded-[8px] px-5  border-[1px] border620"
                                            />
                                            {formik.errors.serverurl && formik.touched.serverurl
                                                ? <p className="text-light-50 text-xs">{formik.errors?.serverurl}</p>
                                                : null}
                                        </div>
                                        <div className="from-group mb-5">
                                            <label htmlFor="" className="mb-1 block">
                                                Username
                                            </label>
                                            <Input
                                                tabIndex="2"
                                                autocomplete="off"
                                                onChange={formik.handleChange}
                                                value={formik.values.username}
                                                name="username"
                                                placeholder="Enter the Username"
                                                className="w-full py-3 rounded-[8px] px-5  border-[1px] border620"
                                            />
                                            {formik.errors.username && formik.touched.username
                                                ? <p className="text-light-50 text-xs">{formik.errors.username}</p>
                                                : null}
                                            {/* {errors && (
                      <p className="text-light-50 text-xs">{errors?.username}</p>
                    )} */}

                                        </div>
                                        <div className="from-group mb-5">
                                            <label htmlFor="" className="mb-1 block">
                                                Password
                                            </label>
                                            <div className="relative">
                                                <Input
                                                    tabIndex="3"
                                                    autocomplete="off"
                                                    onChange={formik.handleChange}
                                                    value={formik.values.password}
                                                    type={isRevealPwd ? "text" : "password"}
                                                    name="password"
                                                    placeholder="&bull; &bull; &bull; &bull; &bull; &bull;"
                                                    className="w-full py-3 rounded-[8px] px-5  border-[1px] border620"
                                                />
                                                {formik.errors.password && formik.touched.password
                                                    ? <p className="text-light-50 text-xs">{formik.errors?.password}</p>
                                                    : null}
                                                <Link onClick={() => setIsRevealPwd((prevState) => !prevState)} tabIndex="3" className="absolute cursor-pointer top-[57%] translate-y-[-57%] right-[26px]">
                                                    <img
                                                        title={isRevealPwd ? "Hide password" : "Show password"}
                                                        src={
                                                            isRevealPwd
                                                                ? `/images/common/eyeOpen.svg`
                                                                : `/images/common/eyeClose.svg`
                                                        }
                                                    />
                                                </Link>
                                            </div>
                                        </div>
                                        <div className="from-group mb-3 flex justify-end ">
                                            <label htmlFor="" className="mb-1 block" title={"Active/Inactive"}>
                                                Integration: <IOSSwitch sx={{ m: 1 }} tabIndex="4" onChange={formik.handleChange} checked={formik.values.isactive} name="isactive" />
                                            </label>
                                        </div>
                                    </form>
                                    <div className="flex justify-end space-x-5">
                                        <Button title="Reset" type="button" tabIndex="5" onClick={(e) => handleResetForm(e)} className="bg-light-240 flex text-base font-interR py-[.5rem] px-[2.24rem] rounded-[8px] text-light-40">
                                            Reset
                                        </Button>
                                        <Button title="Update" type="submit" tabIndex="6" className="border-20 bg-light-20 px-5 py-2 rounded-[8px] font-interM text-sm flex items-center border-[1px] text-white">
                                            {"Update"}
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </Card>
                </Main>
            </Layout>
        </>


    );

};

export default IntegrationServer;