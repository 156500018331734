import {
    ACTIVATE_DEACTIVATE_FOLDER_CONFIG_FAILURE,
    ACTIVATE_DEACTIVATE_FOLDER_CONFIG_REQUEST,
    ACTIVATE_DEACTIVATE_FOLDER_CONFIG_RESET,
    ACTIVATE_DEACTIVATE_FOLDER_CONFIG_SUCCESS,
    
    ADD_FOLDER_CONFIG_FAILURE,
    ADD_FOLDER_CONFIG_REQUEST,
    ADD_FOLDER_CONFIG_RESET,
    ADD_FOLDER_CONFIG_SUCCESS,

    GET_FOLDER_CONFIG_FAILURE,
    GET_FOLDER_CONFIG_REQUEST,
    GET_FOLDER_CONFIG_RESET,
    GET_FOLDER_CONFIG_SUCCESS,
    UPDATE_FOLDER_CONFIG_FAILURE,
    UPDATE_FOLDER_CONFIG_REQUEST,
    UPDATE_FOLDER_CONFIG_RESET,
    UPDATE_FOLDER_CONFIG_SUCCESS
} from "../types/types";

//folder list

const ListFolderState = {
    configData: [],
    fetching: false,
    requestComplete: false,
    error: "",
};

export function folderConfigList(state = ListFolderState, action) {
    switch (action.type) {
        case GET_FOLDER_CONFIG_REQUEST:
            return {
                ...state,
                fetching: true,
                requestComplete: false,
                error: "",
            };
        case GET_FOLDER_CONFIG_SUCCESS:
            return {
                configData: action.payload,
                fetching: false,
                requestComplete: true,
                error: "",
            };
        case GET_FOLDER_CONFIG_RESET:
            return {
                configData: [],
                fetching: false,
                requestComplete: false,
                error: "",
            };
        case GET_FOLDER_CONFIG_FAILURE:
            return {
                fetching: false,
                requestComplete: false,
                error: action.payload,
            };
        default:
            return state;
    }
}
//folderAdd
const folderConfigAddState = {
    profile: [],
    fetching: false,
    requestComplete: false,
    error: "",
};
export function folderConfigAdd(state = folderConfigAddState, action) {
    switch (action.type) {
        case ADD_FOLDER_CONFIG_REQUEST:
            return {
                ...state,
                fetching: true,
                requestComplete: false,
                error: "",
            };
        case ADD_FOLDER_CONFIG_SUCCESS:
            return {
                profile: action.payload,
                fetching: false,
                requestComplete: true,
                error: "",
            };
        case ADD_FOLDER_CONFIG_RESET
            :
            return {
                profile: {},
                fetching: false,
                requestComplete: false,
                error: "",
            };
        case ADD_FOLDER_CONFIG_FAILURE:
            return {
                fetching: false,
                requestComplete: false,
                error: action.payload,
            };
        default:
            return state;
    }
}

//folder active-inactive
const folderConfigStatusState = {
    data: [],
     fetching: false,
     requestComplete: false,
     error: "",
 };
 export function folderConfigStatus(state = folderConfigStatusState, action) {
     switch (action.type) {
         case   ACTIVATE_DEACTIVATE_FOLDER_CONFIG_REQUEST:
             return {
                 ...state,
                 fetching: true,
                 requestComplete: false,
                 error: "",
             };
         case ACTIVATE_DEACTIVATE_FOLDER_CONFIG_SUCCESS:
             return {
                 data: action.payload,
                 fetching: false,
                 requestComplete: true,
                 error: "",
             };
         case ACTIVATE_DEACTIVATE_FOLDER_CONFIG_RESET
         :
             return {
                 data: {},
                 fetching: false,
                 requestComplete: false,
                 error: "",
             };
         case ACTIVATE_DEACTIVATE_FOLDER_CONFIG_FAILURE:
             return {
                 fetching: false,
                 requestComplete: false,
                 error: action.payload,
             };
         default:
             return state;
     }
 }

 //folderAdd
const folderConfigUpdateState = {
    data: [],
    fetching: false,
    requestComplete: false,
    error: "",
};
export function folderConfigUpdate(state = folderConfigUpdateState, action) {
    switch (action.type) {
        case UPDATE_FOLDER_CONFIG_REQUEST:
            return {
                ...state,
                fetching: true,
                requestComplete: false,
                error: "",
            };
        case UPDATE_FOLDER_CONFIG_SUCCESS:
            return {
                data: action.payload,
                fetching: false,
                requestComplete: true,
                error: "",
            };
        case UPDATE_FOLDER_CONFIG_RESET
            :
            return {
                data: {},
                fetching: false,
                requestComplete: false,
                error: "",
            };
        case UPDATE_FOLDER_CONFIG_FAILURE:
            return {
                fetching: false,
                requestComplete: false,
                error: action.payload,
            };
        default:
            return state;
    }
}