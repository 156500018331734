import { decryptId } from "../../../utils/helpers";
import React from "react";
import { useContext } from "react";
import { ThemeContext } from "../../../Hooks/Themes/Themeprovider";
import { getViewTaskAttachment } from "../../../redux/actions/my-task";

const EmailContent = React.memo(
    (props) => {
        const { currentMode, toggleMode } = useContext(ThemeContext);

        const htmlContent = props?.item?.email_message; // Your HTML content here
        // Replace color attributes with white
        const hashHtml = htmlContent?.replace(/\\/g, "");
        const whiteHtml = hashHtml?.replace(/black/g, "white");
        const ReplaceHtml = currentMode === "dark" ? whiteHtml : htmlContent;
        const iframeStyles = currentMode == "dark" ? "dark-mode" : "";
        function breakWordsAtSemicolon(text) {
            const words = text?.split(";");
            return words?.map((word, index) => (
                <React.Fragment key={index}>
                    {word}
                    {index % 2 != 0 ? <br /> : words?.length - 1 == index ? "" : ";"}
                </React.Fragment>
            ));
        }
        return (
            <>
                <div className={`border-${currentMode}-700 py-7  px-[30px]  mb-5  mx-[-30px]  border-b-[1px]`}>
                    <div className={`flex`}>
                        <div className="flex flex-1 basis-[50%]">
                            <div className="ml-2 flex-1  ">
                                <div className="mb-1 flex items-center">
                                    <span className={`font-interM `}>{decryptId(props?.item?.sender)}</span>
                                </div>
                                <p className={`text-xs mb-1 ${currentMode == "dark" ? "text-white" : `text-${currentMode}-900`}`}>
                                    <span className="font-interSb pr-1">To:</span>
                                    {breakWordsAtSemicolon(decryptId(props?.item?.receiver))}
                                    {/* {decryptId(props?.item?.receiver)} */}
                                </p>

                                {decryptId(props?.item?.email_cc)?.trim() != "" && props?.item?.email_cc != null && (
                                    <p className={`text-xs mb-1 ${currentMode == "dark" ? "text-white" : `text-${currentMode}-900`} `}>
                                        <span className="font-interSb pr-1">Cc:</span>
                                        {breakWordsAtSemicolon(decryptId(props?.item?.email_cc))}
                                        {/* {decryptId(props?.item?.email_cc)} */}
                                    </p>
                                )}
                                {decryptId(props?.item?.email_bcc)?.trim() != "" && props?.item?.email_bcc != null && (
                                    <p className={`text-xs mb-1 ${currentMode == "dark" ? "text-white" : `text-${currentMode}-900`}  `}>
                                        <span className="font-interSb pr-1">Bcc:</span>
                                        {breakWordsAtSemicolon(decryptId(props?.item?.email_bcc))}
                                        {/* {decryptId(props?.item?.email_bcc)} */}
                                    </p>
                                )}
                                <p
                                    title={props?.item?.email_subject}
                                    className={`text-xs ${currentMode == "dark" ? "text-white" : `text-${currentMode}-900`} max-w-[450px] truncate`}
                                >
                                    <span className="font-interSb ">Subject:</span> {props?.item?.email_subject}
                                </p>
                            </div>
                        </div>
                        <div className={`flex-initial text-${currentMode}-900`}>
                            <span>{props?.item?.email_createdon}</span>
                        </div>
                    </div>

                    <div className="mt-4 mb-2">
                        <iframe
                            title="Rendered HTML"
                            style={{ width: "100%" }}
                            srcDoc={`<html><head><style>.dark-mode { background-color: #1a1a1a; color: #ffffff; }</style></head><body class="${iframeStyles}">${props?.item?.email_message}</body></html>`}
                        ></iframe>
                    </div>
                    <div className="grid grid-cols-3 gap-2">
                        {props?.item?.email_attachments != null &&
                            props?.item?.email_attachments?.map((item, i) => {
                                return (
                                    <div
                                        key={i}
                                        className="rounded-[5px] flex items-center px-2 py-1 border-[1px] max-w-[150px] cursor-pointer  border-[rgba(223,225,226,0.56)]"
                                        onClick={() => props?.handleFileClick(item?.filecontent, item?.filename, item?.contentType)}
                                    >
                                        <img src={props?.dynamicImage(item?.contentType)} alt="attachment" />
                                        <p className={`ml-2 text-${currentMode}-900 truncate`} title={item?.filename}>
                                            {item?.filename}
                                        </p>
                                    </div>
                                );
                            })}
                    </div>
                </div>
            </>
        );
    },
    (prevProps, nextProps) => {
        return prevProps?.item?.message_id === nextProps?.item?.message_id;
    }
);
export default EmailContent;
