import * as Yup from "yup";

export const serverValidationSchema = (authenticateType) =>
    Yup.object().shape({
        serverurl: Yup.string()
            .required("Url is required")
            .matches(/^(ftp|http|https|www|ww):\/\/[^ "]+$/, "Url is invalid"),
        username:
            authenticateType == 2
                ? Yup.string()
                      .required("Username is required")
                      .min(3, "Username should be minimum 3 maximum 50")
                      .max(50, "Username should be minimum 3 maximum 50")
                : authenticateType == 4 && Yup.string().required("Key is required"),
        password:
            authenticateType == 2
                ? Yup.string().required("Password is required")
                : authenticateType == 3
                ? Yup.string().required("Token is required")
                : authenticateType == 4 && Yup.string().required("Value is required"),
        queryParameters: authenticateType == 4 && Yup.number().required("Add to is required").moreThan(0, "Add to is required"),
        erp_type: Yup.number().required("ERP is required").moreThan(0, "ERP is required"),
    });

export const LoginValidateSchema = Yup.object().shape({
    login_email: Yup.string().required("Business Email is required").email("Business Email is invalid"),
    login_password: Yup.string().required("Password is required"),
});

export const SignupValidateSchema = Yup.object().shape({
    signup_firstname: Yup.string()
        .required("First Name is required")
        .min(3, "First name should be minimum 3 maximum 50")
        .max(50, "First name should be minimum 3 maximum 50")
        .matches(/^([A-Za-zá-źÁ-Ź\-'\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi, "First name is invalid.")
        .matches(/^\s*[0-9a-zA-Zá-źÁ-Ź][0-9a-zA-Zá-źÁ-Ź '-]*$/, "First name is invalid"),
    signup_lastname: Yup.string()
        .required("Last Name is required")
        .min(1, "Last name should be minimum 1 to maximum 50")
        .max(50, "Last name should be minimum 1 to maximum 50")
        .matches(/^([A-Za-zá-źÁ-Ź\-'\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi, "Last name is invalid")
        .matches(/^\s*[0-9a-zA-Zá-źÁ-Ź][0-9a-zA-Zá-źÁ-Ź '-]*$/, "Last name is invalid"),
    signup_acceptTerms: Yup.bool().oneOf([true], "Please accept Terms and Privacy"),
    signup_emailaddress: Yup.string()
        .required("Business Email is required")
        .matches(/^(?!.+@(gmail|google|yahoo|outlook|hotmail|msn)\..+)(.+@.+\..+)[^@.0-9]$/, "Business Email is invalid")
        .max(250, "Business emailaddress should be minimum 6 to maximum 250")
        .email("Business Email is invalid"),
    signup_country: Yup.number().moreThan(0, "Country is required"),
});

export const SetpasswordValidateSchema = Yup.object().shape({
    password: Yup.string().required("Password is required"),
    confirm_password: Yup.string()
        .required("Confirm Password is required")
        .oneOf([Yup.ref("password"), null], "Passwords does not match"),
    setPassword_acceptTerms: Yup.bool().oneOf([true], "Please accept Terms and Privacy"),
});

export const validationSceduleddelivery = Yup.object({
    delivery: Yup.object({
        host: Yup.string().required("Host is required"),
        port: Yup.string().required("Port is required"),
        user: Yup.string().required("User name is required"),
        password: Yup.string().required("Password is required"),
    }),
});

export const validateFilter = Yup.object().shape({
    val1: Yup.string().matches(/^[a-zA-Z0-9\s]+$/, "Special characters are not allowed"),
});

export const validateApiForm = (data) => {
    const errors = {};
    // Validate URL
    if (!data?.delivery?.url) {
        errors.url = "Url is required!";
    } else if (!/^(ftp|http|https|www|ww):\/\/[^ "]+$/.test(data?.delivery?.url)) {
        errors.url = "Url is invalid!";
    }
    if (data?.delivery?.authentic == 2) {
        // Validate Username
        if (!data.delivery.username) {
            errors.username = "Username is required!";
        } else if (data?.delivery?.username.length < 3 || data?.delivery?.username?.length > 50) {
            errors.username = "Username should be minimum 3 maximum 50 characters!";
        }
        // Validate Password
        if (!data?.delivery?.apipasword) {
            errors.password = "Password is required!";
        }
    }
    // Validate Key
    if (data?.delivery?.authentic == 3) {
        if (!data.delivery.key) {
            errors.key = "Key is required!";
        }
        if (!data?.delivery?.value) {
            errors.value = "Value is required!";
        }
        if (!data?.delivery?.add_to || data?.delivery?.add_to <= 0) {
            errors.add_to = "Add to is required!";
        }
    }

    return errors;
};

export const validateEmailForm = (data, toInput) => {
    const errors = {};
    // Validate URL
    // console.log(toInput == "", data?.delivery?.to, "uiuiu");
    if (toInput == "" && (!data?.delivery?.to || data?.delivery?.to?.length == 0)) {
        errors.to = "To is required!";
    }
    if (!data?.delivery?.subject) {
        errors.subject = "Subject is required!";
    }
    if (!data?.delivery?.message) {
        errors.message = "Message is required!";
    }
    return errors;
};
export const validateFtpForm = (data) => {
    const errors = {};
    // Validate URL
    if (!data?.delivery?.host) {
        errors.to = "Host is required!";
    }
    if (!data?.delivery?.port) {
        errors.subject = "Port is required!";
    }
    if (!data?.delivery?.user) {
        errors.user = "Username is required!";
    } else if (data?.delivery?.user.length < 3 || data?.delivery?.user?.length > 50) {
        errors.user = "Username should be minimum 3 maximum 50 characters!";
    }
    if (!data?.delivery?.password) {
        errors.password = "Password is required!";
    }
    return errors;
};

export const DocumentValidateSchema = () =>
    Yup.object().shape({
        document_name: Yup.string().required("The document name is mandatory!").max(120, "The document name should consist of 120 characters"),
        document_code: Yup.string()
            .required("The document code is mandatory!")
            .matches(/^[a-zA-Z0-9_ ]*$/, "Only alphanumeric characters are allowed!")
            .max(50, "The document code should be 50 characters long!"),
        document_specialgl: Yup.array().of(
            Yup.object().shape({
                value: Yup.string()
                    .required("A special G/L indicator is required.")
                    .matches(/^[A-Z0-9]$/, "Only capital letters and digits are allowed!")
                    .max(1, "Value must be one character long."),
            })
        ),
    });
