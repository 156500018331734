import Card from "../../components/UI/Card";
import { useContext, useEffect, useState } from "react";
import { ThemeContext } from "../../Hooks/Themes/Themeprovider";
import Input from "../../components/Input";
import { DatePicker, DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Autocomplete, Checkbox, Select, ClickAwayListener, Fade, MenuItem, Paper, Popper, TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import ReactDOM from "react-dom";
import { NumericFormat } from "react-number-format";
import { AddItemsModal } from "../Invoice/View/AddItemsModal";
import { GlTableItems } from "./MiscellaneousPaymentTable/glTable";
import Loader from "../../components/Loader";
import { GetWbsListAction } from "../../redux/actions/miscellaneous-request";
import { getCostCentreList, getGeneralLedgerList } from "../../redux/actions/invoice";
import Swal from "sweetalert2";
import { LedgerModal } from "../Invoice/View/LedgerModal";
import { formatNumber, truncateValue } from "../../utils/helpers";

const MiscPayment = (props) => {
    const { currentMode, toggleMode } = useContext(ThemeContext);
    const dropdownColor = currentMode === "dark" ? "#333333" : `white`;
    const dropdownText = currentMode === "dark" ? "#ECECEC" : `#555555`;
    const AccessIcon = () => <img src="/images/invoice/calendar.svg" tabIndex={0} alt="calendar" className="convertWhite" />;
    const findScreenWidth = window.innerWidth;
    const userDateFormat = localStorage.getItem("userDateFormat");
    const State = props?.Inputstate;
    const handleChange = props?.InputhandleChange;
    const numFormat = localStorage.getItem("userNumberFormat");
    const dispatch = useDispatch();
    const orgId = localStorage.getItem("orgId");
    const [openGL, setOpenGl] = useState(false);
    const [generalLed, setGeneralLed] = useState([
        {
            gl_account_code: "",
            ledger_type: 0,
            cost_center: "",
            amount: "",
        },
    ]);
    // useEffect(() => {

    //     dispatch(getTaxCodeList(9889))
    // }, [])

    // const MiscAmount = State?.MiscAmount?.replaceAll(",", "");
    // const TaxAmount = State?.TaxAmount?.replaceAll(",", "");
    // const TaxDedAmount = State?.TaxDAmount?.replaceAll(",", "");
    let PayableAmount = props?.PayableAmount;

    const titlePayableAmount = String(PayableAmount)?.replaceAll(/[^\d.]/g, "");
    // PayableAmount = MiscAmount + TaxAmount - TaxDedAmount;
    const [Wbs, setWbs] = useState(false);
    const [checkClick, setCheckClick] = useState(false);
    const [tablePopperOpen, setTablePopperOpen] = useState(false);
    const [TableanchorEls, setTableAnchorEls] = useState(null);
    const [checkTableChange, setcheckTableChange] = useState(false);
    const [checkChange, setcheckChange] = useState(false);
    const [popperOpen, setpopperOpen] = useState(false);
    // const [totalLedgerVal, setTotalLedgerVal] = useState(0);
    const [AddItems, setAddItems] = useState([]);
    const [costListData, setCostListData] = useState([]);
    const [addItem, setAddItem] = useState(false);
    const [costArr, setCostArr] = useState([]);
    const [oldData, setOldData] = useState("");
    const [checkRowIndex, setCheckRowIndex] = useState(0);
    const [openLedger, setOpenLedger] = useState(false);
    const [taxCodeOpen, setTaxcodeOpen] = useState(false);
    const [taxAddCodeOpen, setTaxAddCodeOpen] = useState(false);
    const [anchorEls, setAnchorEls] = useState(null);
    const [costCenterList, setCostCenterList] = useState("");
    var emptyarr = [];
    var addCostList = [];
    const errorMsg = props?.errorMsg;
    const [costCenter, setCostCenter] = useState();
    const [checkIndex, setCheckIndex] = useState(0);
    let nonPoCostListData = costCenterList?.cost_center;
    const data = useSelector((state) => state?.generalLedgerListState);

    const nonPOItemList = costArr;
    const tableListData = AddItems;
    const handleOpenLedger = (index) => {
        setCheckRowIndex(index);
        setOpenLedger(true);
        if (data?.data?.length == 0) {
            dispatch(getGeneralLedgerList(orgId));
            dispatch(getCostCentreList(orgId));
        }
    };
    // const checkInvBase = invoiceList?.data?.datalist[0];
    const getTotal = tableListData?.map((el) => el);
    const totaList = getTotal?.map((el) => el);
    const totalListData = totaList?.map((el) => el?.total);
    let validateTotalAmount = "";
    const findTotalValObj = totalListData?.find((el) => el?.item_no === costCenterList?.line_no);
    // const handleCostCenter = (newrow, ListData) => {
    //     if (numFormat == "3") {
    //         validateTotalAmount = findTotalValObj?.label_value?.replaceAll(" ", "");
    //     } else if (numFormat == "1") {
    //         validateTotalAmount = findTotalValObj?.label_value.replaceAll(",", "");
    //     } else if (numFormat == "2") {
    //         validateTotalAmount = findTotalValObj?.label_value?.replaceAll(".", "");
    //     }
    //     //sum a total amount on gl popper
    //     let checkTotal = [];

    //     if (numFormat == "3") {
    //         checkTotal = nonPoCostListData?.map((el) => parseFloat(el?.amount?.replaceAll(" ", "")));
    //     } else if (numFormat == "1") {
    //         checkTotal = nonPoCostListData?.map((el) => parseFloat(el?.amount?.replaceAll(",", "")));
    //     } else if (numFormat == "2") {
    //         checkTotal = nonPoCostListData?.map((el) => parseFloat(el?.amount?.replaceAll(".", "")?.replaceAll(",", ".")));
    //     }

    //     nonPoCostListData = nonPoCostListData?.filter((el) => el?.cost_center !== "");
    //     const totalVal = checkTotal?.reduce((partialSum, a) => partialSum + a, 0);
    //     setOpenLedger(false);
    //     // to find the total mismatched on gl total value and line item total net amount
    //     // if (numFormat == "1" || numFormat == "3") {
    //     //     if (parseFloat(totalVal?.toFixed(3)) !== +(+validateTotalAmount)?.toFixed(3)) {
    //     //         Swal.fire({
    //     //             text: `Row Total Amount is mismatch with line total(${(+validateTotalAmount)?.toFixed(3)})`,
    //     //             icon: "warning",
    //     //             closeOnClickOutside: false,
    //     //             showCloseButton: true,
    //     //             buttons: {
    //     //                 confirm: true,
    //     //             },
    //     //         }).then((confirm) => {
    //     //             if (confirm) {
    //     //                 return false;
    //     //                 setAddItem(false);
    //     //             }
    //     //         });
    //     //     } else {
    //     //         setCostCenter(newrow);
    //     //         setAddItem(false);
    //     //     }
    //     // } else if (numFormat == "2") {
    //     //     const validateTotAmunt = validateTotalAmount?.replaceAll(",", ".");
    //     //     if (totalVal !== +(+validateTotAmunt)?.toFixed(3)) {
    //     //         Swal.fire({
    //     //             text: `Row Total Amount is mismatch with line total(${(+validateTotalAmount)?.toFixed(3)})`,
    //     //             icon: "warning",
    //     //             closeOnClickOutside: false,
    //     //             showCloseButton: true,
    //     //             buttons: {
    //     //                 confirm: true,
    //     //             },
    //     //         }).then((confirm) => {
    //     //             if (confirm) {
    //     //                 return false;
    //     //                 setAddItem(false);
    //     //             }
    //     //         });
    //     //     } else {
    //     //         setCostCenter(newrow);
    //     //         setAddItem(false);
    //     //     }
    //     // }

    //     // if (ListData?.length !== 0) {
    //     //     const filterVal = ListData?.filter((el) => el?.cost_center == "");
    //     //     const checkGl = ListData?.filter((el) => el?.gl_account_code == "");
    //     //     //find the unfilled row when click the save button
    //     //     if (filterVal?.length !== 0 || checkGl?.length !== 0) {
    //     //         setAddItem(true);
    //     //         Swal.fire({
    //     //             text: `Remove the empty row !`,
    //     //             icon: "warning",
    //     //             closeOnClickOutside: false,
    //     //             showCloseButton: true,
    //     //             buttons: {
    //     //                 confirm: true,
    //     //             },
    //     //         }).then((confirm) => {
    //     //             if (confirm) {
    //     //                 return false;
    //     //                 setAddItem(false);
    //     //             }
    //     //         });
    //     //     }
    //     // }
    //     const findDuplicate = ListData?.map((el) => el?.cost_center);
    //     const validateArr = [...new Set(findDuplicate)];
    //     //validate not to select the same cost center on gl
    //     // if (validateArr?.length !== findDuplicate?.length) {
    //     //     setAddItem(true);
    //     //     Swal.fire({
    //     //         text: `Cost center name cannot be selected more than one row !`,
    //     //         icon: "warning",
    //     //         closeOnClickOutside: false,
    //     //         showCloseButton: true,
    //     //         buttons: {
    //     //             confirm: true,
    //     //         },
    //     //     }).then((confirm) => {
    //     //         if (confirm) {
    //     //             return false;
    //     //             setAddItem(false);
    //     //         }
    //     //     });
    //     // }
    // };
    const handleCostCenter = (newrow, index) => {
        if (newrow) {
            setGlRow(newrow);
        }

        setOldData("");
        setAddItem(false);
    };
    useEffect(() => {
        // props?.setNewLineItem(AddItems);
        if (AddItems?.length == 0) {
            AddItems.push(emptyarr);
            setAddItems([...AddItems]);
            costArr.push(addCostList);
        }
        if (costArr?.length == 0) {
            costArr.push(addCostList);
            setCostArr([...costArr]);
        }
    }, []);

    const handleCloseCostCenter = (data) => {
        if (data) {
            setGlRow(JSON?.parse(data));
        }
        setAddItem(false);
    };
    const { data: listData } = useSelector((state) => state?.costCentreListState);
    const GlFunc = () => {
        setOpenGl(true);
    };
    const handleOpenCostCenter = (index) => {
        setCheckIndex(index);
        const costList = nonPOItemList?.find((elem) => elem.line_no === index + 1);
        const value = JSON.stringify(glRow);
        setOldData(value);
        setCostCenterList(costList);
        setAddItem(true);
        if (listData?.length == 0) {
            dispatch(getCostCentreList(orgId));
        }
    };
    const HandleClickWBs = () => {
        setWbs(true);
    };
    const WBsChange = (e, plant, project, wbs, id) => {
        setcheckTableChange(true);
        setTableAnchorEls(e.currentTarget);
        props?.setInputState((prevState) => {
            const updatedState = {
                ...prevState,
                ["WbsElem"]: wbs,
                ["Plant"]: plant,
                ["Project"]: project,
                ["WbsId"]: id,
            };
            return updatedState;
        });
    };
    const WbsData = useSelector((state) => state?.getMiscWbsListState);
    const Wbsarr = WbsData?.data;
    // const Wbsarr = [{ code: 123232, ProjectName: "Real Estate", PlantName: "RMZ", WBSELement: "C.4201.Com.MG" },
    // { code: 123900, ProjectName: "Invoice Process", PlantName: "MYIq", WBSELement: "C.4201.Com.MG" },
    // { code: 12367945, ProjectName: "SAP Builder", PlantName: "RMZ", WBSELement: "C.4201.Com.MG" }];
    useEffect(() => {
        if (Wbsarr?.length == 0 || checkClick == "click") {
            setpopperOpen(false);
            setTablePopperOpen(false);
        }
        if (checkChange && Wbsarr?.length !== 0 && checkClick !== "click") {
            setpopperOpen(true);
        }
        if (checkTableChange && Wbsarr?.length !== 0 && checkClick !== "click") {
            setTablePopperOpen(true);
        }
    }, [Wbsarr, checkClick, checkTableChange]);
    // to select the gl_acc_code

    const { glRow, setGlRow } = props;
    const handleSelect = (account_code, index) => {
        glRow?.forEach((element, inde) => {
            if (inde == checkRowIndex) {
                element.gl_account_code = account_code;
            }
        });
        setOpenLedger(false);
    };

    const FindGlrow = glRow?.filter((el) => el?.amount == "" && el?.cost_center == "" && el?.gl_account_code == "" && el?.ledger_type == 0)?.length > 0
    const { data: taxListData, requestComplete, fetching } = useSelector((state) => state?.taxCodeWithPercentageListState);
    const { data: TdsData, requestComplete: Tdsreq, fetching: tdsfetching } = useSelector((state) => state?.TdsActionState);
    const { data: dataList, requestComplete: GlIndReqComp } = useSelector((state) => state?.getGLIndicatorListState);
    const glIndicData = dataList?.[0]?.special_gl_indicator;
    const handleClickAway = () => {
        setWbs(false);
    };
    const orgData = useSelector((state) => state?.ListorganisationDropDownState);
    const [getCurrencyByOrg, setCurrencyByOrg] = useState("");
    useEffect(() => {
        if (orgData && orgId) {
            setCurrencyByOrg(orgData.viewData?.find((el) => el?.id === +orgId)?.currency_symbol);
        }
    }, [orgId, orgData]);
    return (
        <>
            <div className="xl:flex xl:space-x-3  ">
                <Card
                    className={`${State?.vendor_name == "Select" || !State?.vendor_name ? "opacity-[.5] pointer-events-none" : ""} ${props?.DisableInput ? "pointer-events-none" : ""
                        } border-[1px] border-[rgba(223,225,226,0.56)] xl:min-h-[556px] xl:w-[976px] 2xl:w-full ${currentMode === "dark" ? "" : "shadow-[0px_4px_20px_rgba(238,238,238,0.5)]"
                        }`}
                >
                    <form onSubmit={""} className={`xl:flex w-full h-full`}>
                        <div className="m-[30px] xl:basis-3/4">
                            <div className="flex mb-5 space-x-7 viewStyles">
                                <div className="basis-1/2 datePic">
                                    <label className="block text-sm pb-3">
                                        <span className="text-[red] mr-1">*</span>Misc. Amount:
                                    </label>
                                    <NumericFormat
                                        name="MiscAmount"
                                        placeholder="Enter Amount"
                                        onChange={(e) => handleChange("MiscAmount", e?.target?.value)}
                                        value={State?.MiscAmount}
                                        maxLength={11}
                                        decimalScale={2}
                                        thousandSeparator={numFormat == "1" ? "," : numFormat == "2" ? "." : " "}
                                        decimalSeparator={numFormat == "1" ? "." : numFormat == "2" ? "," : "."}
                                        id="input2"
                                        // placeholder="Enter the last name"
                                        className={`border-[1px] border-${currentMode}-840 rounded-[8px] px-[20px] py-2 w-full bg-${currentMode}-810`}
                                    />
                                    {errorMsg?.MiscAmount && <p className="text-light-50 text-xs">{errorMsg?.MiscAmount}</p>}
                                </div>
                                <div className="basis-1/2">
                                    <label className="block text-sm pb-3">
                                        {" "}
                                        <span className="text-[red] mr-1">*</span>WBS Element:
                                    </label>
                                    <div className=" relative flex justify-between ">
                                        <input
                                            name="WbsElement"
                                            placeholder="Select WBS element"
                                            // onChange={(e) => handleChange("GeneralLedger", e?.target?.value)}
                                            onClick={HandleClickWBs}
                                            value={State?.WbsElem}
                                            onBlur={() => {
                                                setTimeout(() => {
                                                    setWbs(false);
                                                }, 100);
                                            }}
                                            maxLength={50}
                                            decimalScale={2}
                                            thousandSeparator={numFormat == "1" ? "," : numFormat == "2" ? "." : " "}
                                            decimalSeparator={numFormat == "1" ? "." : numFormat == "2" ? "," : "."}
                                            id="input2"
                                            // placeholder="Enter the last name"
                                            className={`border-[1px] border-${currentMode}-840 rounded-[8px] px-[20px] py-2 w-full bg-${currentMode}-810`}
                                        />

                                        {Wbs ? (
                                            <Popper
                                                open={Wbs}
                                                anchorEl={TableanchorEls}
                                                // disablePortal
                                                className="popperTbl z-[22] top-[40%] left-[30%]"
                                            // onClickAway={() => handleClickAway()}
                                            >
                                                <div
                                                    className={`max-h-[340px] overflow-y-auto ml-[26px] bg-${currentMode}-card border rounded-[8px]`}
                                                >
                                                    <table
                                                        className={`w-[514px] max-h-[230px] overflow-y-auto bg-${currentMode}-card rolemasterTable  rounded-[8px] text-left`}
                                                    >
                                                        <thead className="sticky top-0">
                                                            <tr className="">
                                                                <th className="">Project Code</th>
                                                                <th className="">Project Name</th>
                                                                <th>Plant Name</th>
                                                                <th>WBS Element</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody className="">
                                                            {Wbs && Wbsarr !== undefined && Wbsarr?.length !== 0 ? (
                                                                Wbsarr?.map((elem) => {
                                                                    return (
                                                                        <tr
                                                                            className="cursor-pointer"
                                                                            onClick={(e) =>
                                                                                WBsChange(
                                                                                    e,
                                                                                    elem?.plant,
                                                                                    elem?.description,
                                                                                    elem?.wbs_element,
                                                                                    elem?.id
                                                                                )
                                                                            }
                                                                        >
                                                                            <td className="pl-1 pr-1 text-center">{elem?.project}</td>
                                                                            <td title={elem?.description} className="max-w-[190px] pl-1 truncate">
                                                                                {elem?.description}
                                                                            </td>
                                                                            <td className="text-center">{elem?.plant}</td>
                                                                            <td className="text-center">{elem?.wbs_element}</td>
                                                                        </tr>
                                                                    );
                                                                })
                                                            ) : (
                                                                <tr className="cursor-pointer display flex justify-center">
                                                                    <div className="">No Data found</div>
                                                                </tr>
                                                            )}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </Popper>
                                        ) : (
                                            // ) : fetching && !requestComplete && tablePopperOpen ? (
                                            //     <Loader className="h-[calc(100vh_-_17rem)]" />
                                            // )
                                            ""
                                        )}
                                    </div>
                                    {errorMsg?.WbsElem && <p className="text-light-50 text-xs">{errorMsg?.WbsElem}</p>}
                                </div>
                            </div>
                            <div className="flex mb-5 space-x-7 mt-[20px] viewStyles">
                                <div className="basis-1/2 datePic">
                                    <label className="block text-sm pb-3">
                                        <span className="text-[red] mr-1">*</span>Plant:
                                    </label>
                                    <Input
                                        name="Plant"
                                        placeholder=" Select Plant"
                                        // onChange={(e) => handleChange("Plant", e?.target?.value)}
                                        value={State?.Plant}
                                        maxLength={50}
                                        id="input2"
                                        // placeholder="Enter the last name"
                                        className={`border-[1px] border-${currentMode}-840 rounded-[8px] px-[20px] py-2 w-full bg-${currentMode}-810`}
                                    />
                                    {errorMsg?.Plant && <p className="text-light-50 text-xs">{errorMsg?.Plant}</p>}
                                </div>
                                <div className="basis-1/2">
                                    <label className="block text-sm pb-3">
                                        <span className="text-[red] mr-1">*</span>Project:
                                    </label>
                                    <Input
                                        name="Plant"
                                        placeholder=" Select Project"
                                        // onChange={(e) => handleChange("Project", e?.target?.value)}
                                        value={State?.Project}
                                        maxLength={50}
                                        id="input2"
                                        // placeholder="Enter the last name"
                                        className={`border-[1px] border-${currentMode}-840 rounded-[8px] px-[20px] py-2 w-full bg-${currentMode}-810`}
                                    />
                                    {errorMsg?.Project && <p className="text-light-50 text-xs">{errorMsg?.Project}</p>}
                                </div>
                            </div>

                            <div className="flex mb-5 space-x-7 mt-[20px] viewStyles">
                                <div className="basis-1/2 datePic">
                                    <label className="block text-sm pb-3">Tax Addition Code:</label>
                                    <div className="relative">
                                        <input
                                            placeholder="Select Tax Code"
                                            name="tax_id"
                                            value={State?.TaxCode}
                                            disabled={!State?.MiscAmount}
                                            onFocus={(e) => {
                                                setTaxAddCodeOpen(true);
                                                setAnchorEls(e.currentTarget);
                                            }}
                                            onBlur={() => {
                                                setTimeout(() => {
                                                    setTaxAddCodeOpen(false);
                                                }, 100);
                                            }}
                                            readOnly
                                            className={`border-[1px] border-${currentMode}-840 rounded-[8px] px-[20px] py-2 w-full bg-${currentMode}-810`}
                                        />
                                        {/* {errorMsg?.TaxAddCode && <p className="text-light-50 text-xs">{errorMsg?.TaxAddCode}</p>} */}

                                        <img
                                            className="absolute right-3 mt-[-22px]"
                                            src={currentMode === "light" ? `/images/header/chevronDown.svg` : `/images/header/chevronDown-white.svg`}
                                            alt="chevronDown"
                                        />
                                    </div>
                                    {taxAddCodeOpen ? (
                                        <Popper
                                            open={Boolean(taxAddCodeOpen)}
                                            anchorEl={anchorEls}
                                            disablePortal
                                            disabled={props?.DisableInput}
                                            className="popperTbl z-[22]"
                                        // onClickAway={() => handleClickAway()}
                                        >
                                            <div
                                                className={`max-h-[340px] overflow-y-auto ${currentMode === "dark" ? "" : "shadow-[0px_4px_20px_rgba(238,238,238,0.5)]"
                                                    } ml-[26px] bg-${currentMode}-card border rounded-[8px]`}
                                            >
                                                <table
                                                    className={`w-[550px] max-h-[230px] overflow-y-auto bg-${currentMode}-card rolemasterTable rounded-[8px] text-left`}
                                                >
                                                    <thead className="sticky top-0">
                                                        <tr className="">
                                                            <th className="">Tax Code</th>
                                                            <th className="">Tax Description</th>
                                                            <th>Tax Percentage</th>
                                                        </tr>
                                                    </thead>{" "}
                                                    {fetching && !requestComplete ? (
                                                        <Loader className="flex h-[calc(100vh_-_17rem)]" />
                                                    ) : (
                                                        <tbody className="max-h-[100px]">
                                                            {taxListData && taxListData !== undefined && taxListData?.length !== 0 ? (
                                                                taxListData?.map((item) => {
                                                                    return (
                                                                        <tr
                                                                            className={`cursor-pointer hover:bg-${currentMode}-960  `}
                                                                            onClick={(e) => handleChange("TaxCode", item?.tax_code, item)}
                                                                        >
                                                                            <td className="pl-1 pr-1 text-center">{item?.tax_code}</td>
                                                                            <td title={item?.tax_description} className="max-w-[390px] pl-1 truncate">
                                                                                {item?.tax_description}
                                                                            </td>
                                                                            <td className="flex text-center">{item?.tax_percentage}</td>
                                                                        </tr>
                                                                    );
                                                                })
                                                            ) : (
                                                                <tr className="cursor-pointer">
                                                                    <div className="text-center">No Data found</div>
                                                                </tr>
                                                            )}
                                                        </tbody>
                                                    )}
                                                </table>
                                            </div>
                                        </Popper>
                                    ) : (
                                        // <popperDropDownModal open={Boolean(taxCodeOpen)} anchorEl={anchorEl}/>
                                        ""
                                    )}
                                </div>
                                <div className="basis-1/2">
                                    <label className="block text-sm pb-3">Tax Addition Amount:</label>
                                    <NumericFormat
                                        name="TaxAmount"
                                        placeholder="Enter Amount"
                                        onChange={(e) => handleChange("TaxAmount", e?.target?.value)}
                                        disabled={true}
                                        value={State?.TaxAmount}
                                        maxLength={50}
                                        decimalScale={2}
                                        thousandSeparator={numFormat == "1" ? "," : numFormat == "2" ? "." : " "}
                                        decimalSeparator={numFormat == "1" ? "." : numFormat == "2" ? "," : "."}
                                        id="input2"
                                        // placeholder="Enter the last name"
                                        className={`border-[1px] border-${currentMode}-840 rounded-[8px] px-[20px] py-2 w-full bg-${currentMode}-810`}
                                    />
                                    {/* {errorMsg?.TaxAddAmount && <p className="text-light-50 text-xs">{errorMsg?.TaxAddAmount}</p>} */}
                                </div>
                            </div>
                            <div className="flex mb-5 space-x-7 mt-[20px] viewStyles">
                                <div className="basis-1/2 datePic">
                                    <label className="block text-sm pb-3">Tax Deduction Code:</label>
                                    <div className="relative">
                                        <input
                                            placeholder="Select Tax Code"
                                            name="tax_id"
                                            value={State?.TaxDedCode}
                                            disabled={!State?.MiscAmount}
                                            onFocus={(e) => {
                                                setTaxcodeOpen(true);
                                                setAnchorEls(e.currentTarget);
                                            }}
                                            onBlur={() => {
                                                setTimeout(() => {
                                                    setTaxcodeOpen(false);
                                                }, 100);
                                            }}
                                            readOnly
                                            className={`border-[1px] border-${currentMode}-840 rounded-[8px] px-[20px] py-2 w-full bg-${currentMode}-810`}
                                        />

                                        <img
                                            className="absolute right-3 mt-[-22px]"
                                            src={currentMode === "light" ? `/images/header/chevronDown.svg` : `/images/header/chevronDown-white.svg`}
                                            alt="chevronDown"
                                        />
                                    </div>
                                    {taxCodeOpen ? (
                                        <Popper
                                            open={Boolean(taxCodeOpen)}
                                            anchorEl={anchorEls}
                                            disablePortal
                                            disabled={props?.DisableInput}
                                            className="popperTbl z-[22]"
                                        // onClickAway={() => handleClickAway()}
                                        >
                                            <div
                                                className={`max-h-[340px] overflow-y-auto ${currentMode === "dark" ? "" : "shadow-[0px_4px_20px_rgba(238,238,238,0.5)]"
                                                    } ml-[26px] bg-${currentMode}-card border rounded-[8px]`}
                                            >
                                                <table
                                                    className={`w-[550px] max-h-[230px] overflow-y-auto bg-${currentMode}-card rolemasterTable rounded-[8px] text-left`}
                                                >
                                                    <thead className="sticky top-0">
                                                        <tr className="">
                                                            <th className="">Section Code</th>
                                                            <th className="">Tax Code</th>
                                                            <th>Business Place</th>
                                                            <th>Condition Type</th>
                                                            <th>Tax Percentage(%)</th>
                                                            <th>Exemption Percentage(%)</th>
                                                            <th>Threshold limit</th>
                                                        </tr>
                                                    </thead>{" "}
                                                    {tdsfetching && !Tdsreq ? (
                                                        <Loader className="flex h-[calc(100vh_-_17rem)]" />
                                                    ) : (
                                                        <tbody className="max-h-[100px]">
                                                            {props?.DtaxListData && props?.DtaxListData !== undefined && props?.DtaxListData?.length !== 0 ? (
                                                                props?.DtaxListData?.map((item) => {
                                                                    return (
                                                                        <tr
                                                                            className={`cursor-pointer hover:bg-${currentMode}-960  text-center`}
                                                                            onClick={(e) => handleChange("TaxDedCode", item?.tax_code, item)}
                                                                        >
                                                                            <td>{item?.section_code}</td>
                                                                            <td className="">{item?.tax_code}</td>
                                                                            <td className="">{item?.business_place}</td>
                                                                            <td className="">{item?.tax_type}</td>
                                                                            <td className="">{item?.tax_percentage}</td>
                                                                            <td className="">{item?.exemption_percentage}</td>
                                                                            <td className="">{item?.threshold_limit}</td>
                                                                        </tr>
                                                                    );
                                                                })
                                                            ) : (
                                                                <tr className="cursor-pointer items-center">
                                                                    <div className="items-center">No Data found</div>
                                                                </tr>
                                                            )}
                                                        </tbody>
                                                    )}
                                                </table>
                                            </div>
                                        </Popper>
                                    ) : (
                                        // <popperDropDownModal open={Boolean(taxCodeOpen)} anchorEl={anchorEl}/>
                                        ""
                                    )}
                                </div>
                                <div className="basis-1/2">
                                    <label className="block text-sm pb-3">Tax Deduction Amount:</label>
                                    <NumericFormat
                                        name="TaxDAmount"
                                        placeholder="Enter Deduction Amount"
                                        onChange={(e) => handleChange("TaxDAmount", e?.target?.value)}
                                        value={State?.TaxDAmount}
                                        maxLength={50}
                                        disabled={true}
                                        decimalScale={2}
                                        thousandSeparator={numFormat == "1" ? "," : numFormat == "2" ? "." : " "}
                                        decimalSeparator={numFormat == "1" ? "." : numFormat == "2" ? "," : "."}
                                        id="input2"
                                        // placeholder="Enter the last name"
                                        className={`border-[1px] border-${currentMode}-840 rounded-[8px] px-[20px] py-2 w-full bg-${currentMode}-810`}
                                    />
                                </div>
                            </div>
                            <div className="flex mb-5 space-x-7 mt-[20px] viewStyles">
                                <div className="basis-1/2">
                                    <label className="block text-sm pb-3">
                                        {" "}
                                        <span className="text-[red] mr-1">*</span>General Ledger (GL):
                                    </label>
                                    <div className=" relative  ">
                                        <Input
                                            name="GeneralLedger"
                                            placeholder="General Ledger (GL)"
                                            // onChange={(e) => handleChange("GeneralLedger", e?.target?.value)}
                                            onClick={handleOpenCostCenter}
                                            value={FindGlrow ? '' : 'General Ledger is Configured'}
                                            maxLength={50}
                                            decimalScale={2}
                                            thousandSeparator={numFormat == "1" ? "," : numFormat == "2" ? "." : " "}
                                            decimalSeparator={numFormat == "1" ? "." : numFormat == "2" ? "," : "."}
                                            id="input2"
                                            // placeholder="Enter the last name"
                                            className={`absolute border-[1px] border-${currentMode}-840 rounded-[8px] px-[20px] py-2 w-full bg-${currentMode}-810`}
                                        />
                                        <img src={FindGlrow ? "/images/common/search-ico.svg" : '/images/mobilization/GLTick.svg'} className="absolute right-2 top-3 "></img>
                                    </div>
                                    {/* {errorMsg?.Glmsg && <p className="text-light-50 text-xs">{errorMsg?.Glmsg}</p>} */}
                                </div>
                                <div className="basis-1/2 datePic">
                                    <label className="block text-sm pb-3">G/L Indicator:</label>
                                    {glIndicData && glIndicData?.length > 1 ? (
                                        <Select
                                            onChange={(e) => handleChange("GLInd", e?.target?.value)}
                                            value={State?.GLInd || "0"} // Set the default value to "0" if value?.gl_indicator is falsy
                                            name="gl_indicator"
                                            id="input4"
                                            className={`w-[305px] bg-${currentMode}-810 text-${currentMode}-copy`}
                                            MenuProps={{
                                                sx: {
                                                    "& .MuiMenu-paper": {
                                                        backgroundColor: dropdownColor,
                                                        color: dropdownText,
                                                    },
                                                    "& .MuiMenuItem-root:hover": {
                                                        backgroundColor: "rgba(218, 249, 244, 0.3)",
                                                    },
                                                    "& .Mui-selected": {
                                                        backgroundColor: "rgba(218, 249, 244, 0.3) !important",
                                                    },
                                                },
                                            }}
                                            SelectDisplayProps={{
                                                style: {
                                                    color: `${currentMode !== "dark" ? `#555555` : `#ECECEC`}`,
                                                },
                                            }}
                                            IconComponent={() => (
                                                <img
                                                    className="absolute right-[15px] opacity-[.5] pointer-events-none"
                                                    src={
                                                        currentMode !== "dark"
                                                            ? `/images/header/chevronDown.svg`
                                                            : `/images/header/chevronDown-white.svg`
                                                    }
                                                    alt="chevronDown"
                                                />
                                            )}
                                        >
                                            <MenuItem value={"0"} disabled>
                                                {"Select"}
                                            </MenuItem>
                                            {glIndicData?.map((el, index) => {
                                                return (
                                                    <MenuItem key={index} value={el?.value}>
                                                        {el?.value}
                                                    </MenuItem>
                                                );
                                            })}
                                        </Select>
                                    ) : (
                                        <Input
                                            name="G/L indicator"
                                            value={State?.GLInd}
                                            maxLength={50}
                                            readOnly
                                            placeholder="Enter G/L indicator"
                                            className={`border-[1px] border-${currentMode}-840 rounded-[8px] px-[20px] py-2 w-full bg-${currentMode}-810`}
                                        />
                                    )}

                                    {/* <Input
                                        name="G/L indicator"
                                        value={"INR"}
                                        maxLength={50}
                                        readOnly
                                        placeholder="Enter G/L indicator"
                                        className={`border-[1px] border-${currentMode}-840 rounded-[8px] px-[20px] py-2 w-full bg-${currentMode}-810`}
                                    /> */}
                                </div>
                            </div>

                            <div className="flex mb-5 space-x-7 mt-[20px] viewStyles">
                                <div className="basis-1/2 ">
                                    <label className="block text-sm pb-3">Remarks:</label>
                                    <textarea
                                        name="Remarks"
                                        onChange={(e) => handleChange("Remarks", e?.target?.value)}
                                        value={State?.Remarks}
                                        placeholder="Max. 250 characters"
                                        maxLength={250}
                                        id="input2"
                                        // placeholder="Enter the last name"
                                        className={`border-[1px] border-${currentMode}-840 rounded-[8px] px-[20px] py-2 w-[620px] h-[100px] bg-${currentMode}-810`}
                                    />
                                </div>
                            </div>
                            {findScreenWidth < 1181 && (
                                <div className=" text-black mr-[20px] my-[30px] w-full h-[150px] bg-[#FCE7F3] rounded-lg text-center ">
                                    <div className="pt-[24px]">
                                        <p className="text-[16px] mb-[13px] font-medium">Payment Amount</p>
                                        <p title={PayableAmount} className="text-[29px] mb-[10px] font-interM  w-[200px]">
                                            <NumericFormat
                                                value={PayableAmount}
                                                // allowNegative={false}
                                                displayType="text"
                                                decimalScale={2}
                                                // disabled={props?.DisableInput}
                                                prefix={getCurrencyByOrg}
                                                fixedDecimalScale={true}
                                                thousandSeparator={numFormat == "1" ? "," : numFormat == "2" ? "." : " "}
                                                decimalSeparator={numFormat == "1" ? "." : numFormat == "2" ? "," : "."}
                                            />
                                        </p>
                                        <p className="text-[12px]">
                                            {" "}
                                            (Payable Amount = <p>Misc Amount + Tax Addition Amount - Tax Deduction Amount)</p>
                                        </p>
                                    </div>
                                </div>
                            )}
                        </div>
                        {findScreenWidth > 1180 && (
                            <div
                                className={`xl:basis-1/3 text-black mr-[20px] my-[30px] w-[266px] h-[348px] bg-[#FCE7F3] rounded-lg text-center ${taxCodeOpen ? "opacity-[.5] pointer-events-none" : ""
                                    }`}
                            >
                                <p className="text-[16px] mt-[121px] mb-[13px] font-medium">Payment Amount</p>
                                <p
                                    // className="text-[29px] mb-[10px] font-interM truncate w-[250px] text-center pointer-events"
                                    className="text-[29px] mb-[10px] font-interM truncate h-[30px] w-auto text-center pointer-events"
                                    title={titlePayableAmount}
                                >
                                    {getCurrencyByOrg}
                                    {""}
                                    {/* {formatNumber(PayableAmount)} */}
                                    <NumericFormat
                                        value={PayableAmount}
                                        // allowNegative={false}
                                        displayType="text"
                                        decimalScale={2}
                                        // disabled={props?.DisableInput}
                                        // fixedDecimalScale={true}
                                        thousandSeparator={numFormat == "1" ? "," : numFormat == "2" ? "." : " "}
                                        decimalSeparator={numFormat == "1" ? "." : numFormat == "2" ? "," : "."}
                                    />
                                </p>
                                <p className="text-[12px]">
                                    (Payable Amount = <p>Misc Amount + Tax Addition Amount - Tax Deduction Amount)</p>
                                </p>
                            </div>
                        )}
                    </form>
                </Card>
            </div>
            {ReactDOM.createPortal(
                <LedgerModal
                    handleSelect={handleSelect}
                    isOpened={openLedger}
                    checkIndex={checkIndex}
                    nonPOItemList={nonPOItemList}
                    handleClose={() => setOpenLedger(false)}
                    className={`${openLedger && "Show"}`}
                />,
                document.getElementById("user-modal-root")
            )}
            {ReactDOM.createPortal(
                <GlTableItems
                    generalLedger={costCenterList}
                    istotalGl={props?.istotalGl}
                    handleOpenLedger={handleOpenLedger}
                    costListData={costListData}
                    setCostListData={setCostListData}
                    setTotalLedgerVal={props?.setTotalLedgerVal}
                    isOpened={addItem}
                    listData={listData}
                    newrow={glRow}
                    setNewRow={setGlRow}
                    tableListData={tableListData}
                    nonPOItemList={nonPOItemList}
                    nonPoCostListData={nonPoCostListData}
                    handleCostCenter={handleCostCenter}
                    oldData={oldData}
                    handleCloseCostCenter={handleCloseCostCenter}
                    handleClose={() => setAddItem(false)}
                    className={`${addItem && "Show"}`}
                    type={1}
                />,
                document.getElementById("user-modal-root")
            )}
        </>
    );
};

export default MiscPayment;
