import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Input from "../../components/Input";

import { MenuItem } from "@mui/material";
export default function SearchNumber(props) {

    const handleData = (event) => {
        setDate(event.target.value);
    };

    const [date, setDate] = useState("");

    const [filteredResults, setFilteredResults] = useState([]);
    const [searchInput, setSearchInput] = useState('');

    const searchItems = (searchValue) => {
        setSearchInput(searchValue)
        if (searchInput !== '') {
            const filteredData = props?.data.filter((item) => {
                return Object.values(item).join('').toLowerCase().includes(searchInput.toLowerCase())
            })
            setFilteredResults(filteredData)
        }
        else {
            setFilteredResults(props?.data)
        }
    }

    return (
        <div style={{ padding: 20 }}>
            <Input className="bg-white mx-3 mb-4 border-[rgba(223, 225, 226, 0.56)] shadow-[0px_4px_20px_rgba(238,238,238,0.501967)] border-[1px] flex justify-between py-[8px] px-[16px] rounded-[8px]" type="text" onChange={(e) => searchItems(e.target.value)} placeholder="search">
                <img
                    src="/images/profile/searchsmall.svg"
                    className="mr-3"
                    alt="search"
                />
            </Input>
            <div itemsPerRow={3} style={{ marginTop: 20 }}>
                {searchInput.length > 1 ? (
                    filteredResults.map((item, index) => {
                        return (
                            <>
                                <MenuItem
                                    key={index}
                                    value={item.mobilenumber}
                                >
                                    {item.mobilenumber}
                                    <span className="text-light-90 ml-1"></span>
                                </MenuItem>
                            </>
                        )
                    })
                ) : (
                    props?.data?.map((item, index) => {
                        return (
                            <MenuItem
                                key={index}
                                value={item.mobilenumber}
                            >
                                {item.mobilenumber}
                            </MenuItem>
                        )
                    })
                )}
            </div>
        </div>
    )
}
