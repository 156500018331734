import FournotFour from "../404";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import RetentionTable from "./RetentionTable";
import Card from "../../components/UI/Card";
import Loader from "../../components/Loader/index.jsx";
import { ClickAwayListener, Fade, FormControl, MenuItem, Popper, Select, TextField, Tooltip, Autocomplete, Paper } from "@mui/material";
import { ThemeContext } from "../../Hooks/Themes/Themeprovider";
import { ErrorBoundary } from "react-error-boundary";
import Layout from "../../Hoc/Layout";
import Button from "../../components/Button";
import { Link } from "react-router-dom";
import Main from "../../components/UI/Main";
import Footer from "../../organisms/Footer";
import ReactDOM from "react-dom";
import { useDispatch, useSelector } from "react-redux";
import { getPoNumberList } from "../../redux/actions/invoice";
import TogglePopup from "../organisations/Toggle";
import CommentCard from "../../organisms/CommentsCard/index.jsx";
import AdvanceFileUpload from "../../organisms/AdvanceModals/fileUpload.jsx";
import StatusButton from "../../organisms/AdvanceModals/advanceStatusCard.jsx";
import { GET_RETENTION_RELEASE_VIEW_RESET, GET_PO_RETENTION_HISTORY_RESET } from "../../redux/types/types.js";
// import Card from "../../components/UI/Card";
// import { ThemeContext } from "../../Hooks/Themes/Themeprovider";
// import Button from "../../components/Button";
// import ReactDOM from "react-dom";
// import { useNavigate, useLocation } from "react-router-dom";
// import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
// import Input from "../../components/Input";
import Swal from "sweetalert2";
// import TogglePopup from "../../pages/organisations/Toggle";
import { useState, useEffect, useRef, useContext } from "react";
import { getDocumentFromS3ForAdvance } from "../../redux/actions/advance-request.js";
import { ActivityHistory } from "../../redux/actions/auth.js";
import Activity from "../../organisms/ActivityModal/index.jsx";

// import POHeader from "";
// import PaymentDetails from "./PaymentDetails";
import Input from "../../components/Input";
// import TogglePopup from "../../organisations/Toggle";
// import { useState, useEffect, useRef, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { POST_RETENTION_RELEASE_RESET } from "../../redux/types/types.js";
import { getPoRetentionReleaseHistory, getRetentionReleaseView, postRetentionReleaseView } from "../../redux/actions/retention-release.js";
import { formatNumber } from "../../utils/helpers.js";
import ToReassign from "../Invoice/Reassign/index.jsx";
import * as XLSX from "xlsx";
import { postChangeWorkflowStatus } from "../../redux/actions/workflow-automation.js";
import { NumericFormat } from "react-number-format";
import MessageBox from "../../components/UI/Alert/index.jsx";
import SuccessMessageBox from "../../components/UI/Alert/sucess.jsx";
import { ADD_UPDATE_COMMENTS_RESET } from "../../redux/types/types.js";
// import Swal from "sweetalert2";
let findCreateNewRequest = false;

// import UserActivityMain from "./ModalActivity/index";
const RetentionRelease = (props) => {
    const params = new URLSearchParams(window.location.search);
    const userDateFormat = localStorage?.getItem("userDateFormat")?.toUpperCase();

    const navigate = useNavigate();
    const viewId = params.get("id");
    const AccessIcon = () => <img src="/images/invoice/calendar.svg" tabIndex={0} alt="calendar" className="convertWhite" />;
    const { currentMode, toggleMode } = useContext(ThemeContext);
    const ErrorFallback = ({ error }) => {
        return (
            <>
                <FournotFour />
            </>
        );
    };
    const childCompRef = useRef();
    const orgId = localStorage.getItem("orgId");
    const { data: data, fetching, requestComplete } = useSelector((state) => state?.getPoNumberListState);
    const { data: viewData, fetching: viewFetching, requestComplete: viewReqComp } = useSelector((state) => state?.getRetentionReleaseViewState);
    const { data: postData, fetching: postFetching, requestComplete: postReqComp } = useSelector((state) => state?.postRetentionReleaseState);
    const {
        data: commentData,
        fetching: addCmtFetch,
        requestComplete: addCmtReqComp,
        error: addCmtErr,
    } = useSelector((state) => state?.addUpdateCommentsState);
    const {
        data: poReleaseHistory,
        fetching: poReleseFetch,
        requestComplete: poReleseReqComp,
    } = useSelector((state) => state?.getPoRetentionReleaseState);
    const [checkClick, setCheckClick] = useState(false);
    const [popperOpen, setpopperOpen] = useState(false);
    const [filteredPoNoList, setFilteredPoNoList] = useState([]);
    const [searchShow, setSearchShow] = useState(false);
    const [insertPoVal, setInsertPoVal] = useState("");
    const [vendorName, setVendorName] = useState("");
    const [poReleseTable, setPoReleaseTable] = useState([]);
    const [anchorEls, setAnchorEls] = useState(null);
    const [checkChange, setcheckChange] = useState(false);
    const [reqDate, setReqDate] = useState(new Date());
    const [releaseAmount, setReleaseAmount] = useState("");
    const [addHoldAmount, setAddHoldAmount] = useState("");
    const [flag, setFlag] = useState({
        ret: true,
        add: true,
    });
    const [isPopUpNeed, setIsPopUpNeed] = useState({
        retention: false,
        addHold: false,
    });
    const [date, setDate] = useState(new Date());
    const [selectedUserId, setSelectedUserId] = useState(null);
    const selectedFields = [
        "id",
        "invoice_id",
        "invoice_no",
        "invoice_date",
        "retention_amount",
        "document_no",
        "addhold_amount",
        "addhold_repayment_amount",
        "retention_repayment_amount",
    ];
    const [remarks, setRemarks] = useState("");
    const referRetentionSum = poReleseTable?.reduce((init, value) => {
        init += value?.refer_ret_amt || 0;
        return init;
    }, 0);
    const referAddHoldSum = poReleseTable?.reduce((init, value) => {
        init += value?.refer_add_hold || 0;
        return init;
    }, 0);
    const currentStatusInfo = viewData?.data?.[0]?.curr_status;
    const nextStatusInfo = viewData?.data?.[0]?.next_status_info;
    const prevStatusInfo = viewData?.data?.[0]?.prev_status;
    const nextStatusUsers = viewData?.data?.[0]?.next_status_users;
    const [isreassign, setReassign] = useState(false);
    const poNumberList = data;
    useEffect(() => {
        if (insertPoVal !== "" || poNumberList) {
            const filteredFav = poNumberList?.filter((fav) => {
                return fav.PoNumber.includes(insertPoVal);
            });
            setFilteredPoNoList(filteredFav);
        }
    }, [poNumberList, insertPoVal]);
    const dispatch = useDispatch();
    const userNumFormat = localStorage.getItem("userNumberFormat");
    let thousandSeperator = userNumFormat == "1" ? "," : userNumFormat == 2 ? "." : " ";
    let decSeperator = userNumFormat == "1" ? "." : userNumFormat == "2" ? "," : ".";
    useEffect(() => {
        if (orgId && !viewId) {
            dispatch({ type: GET_PO_RETENTION_HISTORY_RESET });
            dispatch({ type: GET_RETENTION_RELEASE_VIEW_RESET });
            dispatch(getPoNumberList(orgId, 2));
        } else if (viewId) {
            dispatch({ type: GET_PO_RETENTION_HISTORY_RESET });
            dispatch({ type: GET_RETENTION_RELEASE_VIEW_RESET });
            dispatch(getRetentionReleaseView(viewId, orgId));
        }
    }, []);
    useEffect(() => {
        if (filteredPoNoList?.length === 0 || checkClick === "click") {
            setpopperOpen(false);
        }
        if (checkChange && filteredPoNoList?.length !== 0 && checkClick !== "click") {
            setpopperOpen(true);
        }
    }, [filteredPoNoList, checkClick]);
    const DisplayRole = useSelector((state) => state?.DisplayRolePermissionStateAction)?.data?.role_info?.menu?.find((item) => item?.menu_id == 23);
    const editDisable =
        (!DisplayRole?.actions?.find((ele) => ele?.id == 2)?.isallowed && viewId) ||
        currentStatusInfo?.[0]?.master_refid == 3 ||
        currentStatusInfo?.[0]?.master_refid == 4 ||
        currentStatusInfo?.[0]?.master_refid == 5;
    const demo = [
        {
            invoice_id: "2803916",
            invoice_no: "104001801",
            invoice_date: "19/07/2022",
            document_no: null,
            retention_amount: 5000,
            addhold_amount: 5000,
            retention_repayment_amount: null,
            addhold_repayment_amount: null,
        },
        {
            invoice_id: "2803916",
            invoice_no: "104001801",
            invoice_date: "19/07/2022",
            document_no: null,
            retention_amount: 5000,
            addhold_amount: 5000,
            retention_repayment_amount: null,
            addhold_repayment_amount: null,
        },
        {
            invoice_id: "2803916",
            invoice_no: "104001801",
            invoice_date: "19/07/2022",
            document_no: null,
            retention_amount: 5000,
            addhold_amount: 5000,
            retention_repayment_amount: null,
            addhold_repayment_amount: null,
        },
    ];
    useEffect(() => {
        if (poReleaseHistory?.data?.length > 0) {
            if (poReleaseHistory?.data?.[0]?.datalist) {
                setPoReleaseTable(
                    poReleaseHistory?.data?.[0]?.datalist?.map((item) => {
                        return {
                            ...item,
                            total_amount: (item?.retention_amount || 0) + (item?.addhold_amount || 0),
                            refer_ret_amt: item?.retention_amount || 0,
                            refer_add_hold: item?.addhold_amount || 0,
                            retention_repayment_char: "",
                            addhold_repayment_char: "",
                            show_ret: true,
                            show_add: true,
                        };
                    })
                );
                setIdInfo(poReleaseHistory?.data?.[0]?.id_info?.[0]);
            } else {
                setPoReleaseTable([]);
                setIdInfo(null);
            }
        } else if (viewData?.data) {
            setPoReleaseTable(
                viewData?.data?.[0]?.json_retention_items?.map((item) => {
                    return {
                        ...item,
                        total_amount: (item?.retention_amount || 0) + (item?.addhold_amount || 0),
                        refer_ret_amt: item?.retention_amount || 0,
                        refer_add_hold: item?.addhold_amount || 0,
                        retention_repayment_char: "",
                        addhold_repayment_char: "",
                        show_ret: true,
                        show_add: true,
                    };
                })
            );
            setInsertPoVal(viewData?.data?.[0]?.po_no);
            setVendorName(viewData?.data?.[0]?.vendor_name);
            setReqDate(viewData?.data?.[0]?.request_date);
            setReleaseAmount(+viewData?.data?.[0]?.release_amount || "");
            setAddHoldAmount(+viewData?.data?.[0]?.addhold_amount || "");
            setRemarks(viewData?.data?.[0]?.remark);
            //   setFileName(viewData?.data?.[0]?.file_name);
            setUploadFile(viewData?.data?.[0]?.file_name);
            setFileSize(viewData?.data?.[0]?.file_size);
            setFileUrl(viewData?.data?.[0]?.file_url);
            setIdInfo({ po_id: viewData?.data?.[0]?.po_id, vendor_id: viewData?.data?.[0]?.vendor_id });
        }
        // setPoReleaseTable()
        // setPoReleaseTable(
        //     demo?.map((item) => {
        //         return {
        //             ...item,
        //             total_amount: (item?.retention_amount || 0) + (item?.addhold_amount || 0),
        //             refer_ret_amt: item?.retention_amount || 0,
        //             refer_add_hold: item?.addhold_amount || 0,
        //             retention_repayment_char: "",
        //             addhold_repayment_char: "",
        //         };
        //     })
        // );
    }, [poReleaseHistory, viewData]);
    // const handleChange = (e) => {
    //     setInsertPoVal(e.target.value);
    //     setcheckChange(true);
    //     setAnchorEls(e.currentTarget);
    // };
    const handleChange = (e, isClick, poNumb, VendorName) => {
        if (isClick && poNumb) {
            setInsertPoVal(poNumb);
            setVendorName(VendorName);
            setCheckClick("click");
            dispatch(getPoRetentionReleaseHistory(poNumb, orgId));
        } else {
            setInsertPoVal(e.target.value);
            setCheckClick("");
        }
        setcheckChange(true);
        setAnchorEls(e.currentTarget);
        setpopperOpen(true);
    };
    const resetPoNumb = () => {
        setInsertPoVal("");
        setVendorName("");
    };
    const filterMenuList = [
        viewData?.data ? { text: "Download", src: "/images/common/download-grn.svg", value: 0 } : "",
        { text: "Reset", src: "/images/mobilization/rotate-resetsvg.svg", value: 1 },
        DisplayRole?.actions?.find((ele) => ele?.id == 13)?.isallowed && {
            text: "Activity",
            src: "/images/mobilization/activity_icon.svg",
            value: 2,
        },
        prevStatusInfo && currentStatusInfo?.[0]?.master_refid != 5
            ? {
                  text: "Move to prev status",
                  src: "/images/invoice/move-to-pre-state.svg",
                  value: 3,
              }
            : "",
    ].filter((item) => item !== null);

    const prepareDataForDownload = () => {
        const date = new Date(reqObj?.requestDate);
        const month = String(date.getUTCMonth() + 1).padStart(2, "0");
        const day = String(date.getUTCDate()).padStart(2, "0");
        const year = date.getUTCFullYear();
        let date1 = userDateFormat?.replace("DD", day)?.replace("MM", month)?.replace("YYYY", year);

        return [
            {
                "PO Number": insertPoVal,
                "Vendor Name": vendorName,
                "Request Date": date1,
                "Release Amount": reqObj?.releaseAmount,
                "Additional Hold Amount": reqObj?.addholdAmount,
                Remark: reqObj?.remarks,
            },
        ];
    };
    const handleDownload = () => {
        const data = prepareDataForDownload();
        const ws = XLSX.utils.json_to_sheet(data);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
        XLSX.writeFile(wb, "Retention-Release-request.xlsx");
    };

    const onFilterItems = (value, id) => {
        switch (value) {
            case 0:
                handleDownload();
                break;
            case 1:
                window.location.reload();
                break;
            case 2:
                setIsUserActivity(true);
                const userReqObj = {
                    transaction_id: location?.pathname == "/retention-release" ? 20 : 19,
                    transaction_key: viewId,
                    action: 0,
                };
                dispatch(ActivityHistory(userReqObj));
                break;
            case 3:
                setReassign(true);
                break;
            default:
                break;
        }
    };

    function updateAdvAmount(parseValue, id) {
        const referBalanceSum = id == 1 ? referRetentionSum : referAddHoldSum;
        let value = parseValue >= referBalanceSum ? referBalanceSum : parseValue;
        if (id == 1) {
            setReleaseAmount(value === 0 ? "" : value);
        } else {
            setAddHoldAmount(value === 0 ? "" : value);
        }
        setPoReleaseTable((prev) => {
            let remainingBalance = value;

            return prev.map((item) => {
                let refer_bal = id == 1 ? item?.refer_ret_amt : item?.refer_add_hold;
                const repaymentAmount = Math.min(remainingBalance, refer_bal);
                //   const balanceAmount = item.refer_bal - repaymentAmount;
                remainingBalance -= repaymentAmount;
                return {
                    ...item,
                    [id == 1 ? "retention_repayment_amount" : "addhold_repayment_amount"]: repaymentAmount,
                    [id == 1 ? "retention_repayment_char" : "addhold_repayment_char"]: repaymentAmount?.toString(),
                    //   balance_amount: balanceAmount,
                };
            });
        });
        setFlag({
            ret: true,
            add: true,
        });
    }
    const handleAmountChange = (value, id) => {
        let ref_val = formatNumber(value || "0");
        const referBalanceSum = id == 1 ? referRetentionSum : referAddHoldSum;
        if (isPopUpNeed?.[id == 1 ? "retention" : "addHold"]) {
            Swal.fire({
                text: `This change will autocalculate the ${
                    id == 1 ? "retention" : "additional hold"
                } amount accordingly, may lose already entered values`,
                icon: "success",
                closeOnClickOutside: false,
                showCloseButton: true,
                button: "Ok",
            }).then((result) => {
                if (result.isConfirmed) {
                    setIsPopUpNeed({
                        ...isPopUpNeed,
                        [id == 1 ? "retention" : "addHold"]: false,
                    });
                    if (ref_val > referBalanceSum) {
                        showPopup(ref_val, id, referBalanceSum);
                        setFlag({
                            ret: !id == 1,
                            add: !id == 2,
                        });
                    } else updateAdvAmount(ref_val, id);
                } else {
                    return;
                }
            });
        } else {
            if (ref_val > referBalanceSum) {
                setFlag({
                    ret: !id == 1,
                    add: !id == 2,
                });
                showPopup(ref_val, id, referBalanceSum);
            } else updateAdvAmount(ref_val, id);
        }
    };
    const showPopup = (ref_val, id, referBalanceSum) => {
        Swal.fire({
            text: `The entered ${id == 1 ? "Retention" : "Add Hold"} Repayment Amount should be lesser than or equal to ${referBalanceSum}  `,
            icon: "success",
            closeOnClickOutside: false,
            showCloseButton: true,
            button: "Ok",
        }).then((result) => {
            setDate(new Date());
            updateAdvAmount(ref_val, id);
        });
    };
    const [isUserActivity, setIsUserActivity] = useState(false);
    const [idInfo, setIdInfo] = useState(null);
    const findScreeSize = window.innerWidth;

    const [fileName, setFileName] = useState("Please select a file.");

    const handleFileChange = (event) => {
        if (event.target.files.length > 0) {
            setFileName(event.target.files[0].name);
        }
    };

    //* Function to call the change the status of the invoice
    function changeStatus(data) {
        if (data?.type == 1) {
            setSelectedUserId(data?.user_id);
        } else {
            setSelectedUserId();
        }
        dispatch(postChangeWorkflowStatus({ upd_status: [data] }));
    }
    // const dispatch = useDispatch();
    //  const findCreateNewRequest = props?.findCreateNewRequest;
    const filterMenuUpload = [
        { text: "Download", src: "/images/common/download-grn.svg", value: 0 },
        { text: "Delete", src: "/images/mobilization/delete.svg", value: 1 },
    ];
    const [uploadFile, setUploadFile] = useState("");
    const [fileSize, setFileSize] = useState("");
    const [fileContent, setFileContent] = useState("");
    const [fileUrl, setFileUrl] = useState("");
    let findCreateNewRequest = false;
    const fileTypes = [
        "image/png",
        "image/jpg",
        "image/jpeg",
        "application/pdf",
        "application/zip",
        "application/vnd.ms-excel",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "application/msword",
    ];
    const location = useLocation();
    // const { currentMode, toggleMode } = useContext(ThemeContext);
    const downloadDocument = () => {
        dispatch(getDocumentFromS3ForAdvance());
    };
    const onChange = (e) => {
        const file = e.target.files[0];
        const fileExt = fileTypes.find((item) => item === file.type);
        if (fileExt) {
            setUploadFile(file?.name);
            if (file) {
                const fileSizeInBytes = file.size;
                const fileSizeInKB = fileSizeInBytes / 1024;

                if (fileSizeInKB > 5120) {
                    // 5120 KB = 5 MB
                    // File size exceeds 5MB, display alert message
                    Swal.fire({
                        text: "File size exceeds 5MB. Please select a smaller file.",
                        icon: "warning",
                        closeOnClickOutside: false,
                        showCloseButton: true,
                        buttons: {
                            confirm: true,
                        },
                    }).then((confirm) => {
                        if (confirm) {
                            // Optionally, you can reset the input to clear the selected file
                            setUploadFile("");
                            setFileSize("");
                        }
                    });
                } else {
                    let fileSizeString;
                    if (fileSizeInKB < 1024) {
                        // Display in KB if below 1 MB
                        fileSizeString = `${fileSizeInKB.toFixed(2)} KB`;
                    } else {
                        // Convert to MB if 1 MB or above
                        const fileSizeInMB = fileSizeInKB / 1024;
                        fileSizeString = `${fileSizeInMB.toFixed(2)} MB`;
                    }
                    setFileSize(fileSizeString);
                    uploadWithJSON(file, file.name);
                }
            }
        } else {
            Swal.fire({
                text: "UnSupported File format!",
                icon: "warning",
                closeOnClickOutside: false,
                showCloseButton: true,
                buttons: {
                    confirm: true,
                },
            }).then((confirm) => {
                if (confirm) {
                    // Optionally, you can reset the input to clear the selected file
                    setUploadFile("");
                    setFileSize("");
                }
            });
        }
    };
    async function uploadWithJSON(file, filename) {
        const reader = new FileReader();
        reader.onload = await _handleReaderLoaded(filename).bind(this);
        reader.readAsBinaryString(file);
    }
    const _handleReaderLoaded = (filename) => async (e) => {
        let binaryString = e.target.result;
        let imgObject = {
            filecontent: btoa(binaryString),
            filename: filename,
        };
        props?.setFileContent(imgObject);
    };
    const onFilterItemsUpload = (value, id) => {
        switch (value) {
            case 0:
                downloadDocument();
                break;
            case 1:
                Swal.fire({
                    text: `Are you sure,you want to delete this file.("${uploadFile}")`,
                    icon: "warning",
                    closeOnClickOutside: true,
                    showCloseButton: true,
                    showCancelButton: true,
                    buttons: {
                        cancel: "Cancel",
                        confirm: "Confirm",
                    },
                }).then((confirm) => {
                    if (confirm?.isConfirmed) {
                        // Optionally, you can reset the input to clear the selected file
                        setUploadFile("");
                        setFileSize("");
                    } else {
                        return false;
                    }
                });
                break;
            default:
                break;
        }
    };
    const handleSubmit = () => {
        // dispatch({ type: GET_RETENTION_RELEASE_VIEW_RESET });
        dispatch(postRetentionReleaseView(reqObj));
    };
    if (postReqComp) {
        let status = postData?.status;
        Swal.fire({
            text: postData?.status ? (viewId ? "Updated Successfully!" : "Saved Successfully") : "Cant save the data",
            icon: postData?.status ? "success" : "error",
            closeOnClickOutside: false,
            showCloseButton: true,
            button: "Ok",
        }).then((confirm) => {
            if (confirm) {
                if (postData?.status && viewId) {
                    window.location.reload();
                } else {
                    navigate("/retention-release-list");
                }
                dispatch({ type: POST_RETENTION_RELEASE_RESET });
            }
        });
    }
    useEffect(() => {
        if (commentData && addCmtReqComp) {
            setTimeout(() => {
                dispatch({ type: ADD_UPDATE_COMMENTS_RESET });
            }, 2000);
        }
    }, [addCmtReqComp]);
    const reqObj = {
        id: viewId || 0,
        orgId: orgId,
        po_id: idInfo?.po_id,
        poNumber: insertPoVal,
        vendor_id: idInfo?.vendor_id,
        vendorName: vendorName,
        requestDate: reqDate,
        releaseAmount: releaseAmount,
        addholdAmount: addHoldAmount,
        remarks: remarks,
        fileName: uploadFile || "",
        fileUrl: fileUrl || "",
        fileSize: fileSize || "",
        fileContent: fileContent?.filecontent || "",
        retentionList:
            poReleseTable?.length > 0
                ? poReleseTable.map((obj) => {
                      const filteredObj = {};
                      selectedFields.forEach((field) => {
                          if (obj?.hasOwnProperty(field)) {
                              filteredObj[field] =
                                  field == "addhold_repayment_amount" || field == "retention_repayment_amount"
                                      ? !obj[field]
                                          ? 0
                                          : obj[field]
                                      : obj[field];
                          } else if (field == "id") {
                              filteredObj[field] = 0;
                          }
                      });
                      return filteredObj;
                  })
                : [],
    };
    return (
        <>
            {addCmtReqComp && commentData?.status && <SuccessMessageBox success={"Comment Added Successfully"} />}
            {addCmtReqComp && !commentData?.status && addCmtErr && <MessageBox error={commentData?.message} />}
            <Layout>
                <ErrorBoundary FallbackComponent={ErrorFallback}>
                    <Main>
                        <div className="mb-[20px] mt-[3px] flex justify-between">
                            <div className="my-auto">
                                <h4 className={`text-[24px] font-interSb text-${currentMode}-copy`}>Retention Release</h4>
                            </div>
                            <div className=" flex text-right">
                                {/* {!findCreateNewRequest && (
                                    <Button className="bg-[#FFE9E9] text-[#FA5A5A] border border-solid border-[#FFD8D8] rounded-[8px] py-2 px-5">
                                        6 field(s) failed to extract!
                                    </Button>
                                )} */}
                                <Link
                                    tabIndex={0}
                                    state={{ from: location.pathname }}
                                    // disabled={}
                                    to={"/retention-release-list"}
                                    title="Cancel"
                                >
                                    {" "}
                                    <Button className="bg-light-240 ml-2 text-light-40 text-base rounded-[8px] py-2 px-5">Cancel</Button>
                                </Link>
                                <Button
                                    tabIndex={0}
                                    onClick={handleSubmit}
                                    className="bg-light-20 text-white text-base rounded-[8px] ml-2 py-2 px-7"
                                    title={"Save"}
                                    disabled={
                                        (!addHoldAmount && !releaseAmount) || postReqComp || [3, 4, 5]?.includes(currentStatusInfo?.[0]?.master_refid)
                                    }
                                >
                                    {"Save"}
                                </Button>
                            </div>
                        </div>
                        {viewFetching ? (
                            <Loader />
                        ) : (
                            <div className="overflow-y-auto xl:mb-[15px]">
                                <div className="xl:flex xl:space-x-2">
                                    <div className="xl:basis-[80%] space-y-2">
                                        {/* <div className=" overflow-y-auto">
                                <div className="xl:flex xl:space-x-2">
                                    <div className="basis-[75%] space-y-2"> */}

                                        <Card
                                            className={` xl:p-[30px] mb-4 xl:mr-4 p-4 border-[1px] border-[rgba(223,225,226,0.56)] h-[520px] xl:w-full ${
                                                currentMode === "dark" ? "" : "shadow-[0px_4px_20px_rgba(238,238,238,0.5)]"
                                            }`}
                                            style={{
                                                backgroundColour: "#FCFCFC",
                                            }}
                                        >
                                            <div className="flex">
                                                <div>
                                                    <h3 className="text-[14px] font-interM">PO Number:</h3>
                                                    <div className="relative">
                                                        <input
                                                            readOnly={viewId}
                                                            autoFocus
                                                            type="number"
                                                            maxLength={31}
                                                            placeholder="Enter PO number"
                                                            onChange={(e) => handleChange(e)}
                                                            value={insertPoVal}
                                                            class="border-[rgba(85,85,85,0.5)] w-[293px] mt-2 h-[40px] bg-transparent px-[10px] py-1 border-[1px] rounded-[5px] "
                                                        />

                                                        {insertPoVal?.length !== 0 && !viewId ? (
                                                            <Button onClick={resetPoNumb}>
                                                                <img
                                                                    className="absolute ml-[-25px] mt-[-10px]"
                                                                    src="/images/common/closeBlk.svg"
                                                                    alt="close"
                                                                />
                                                            </Button>
                                                        ) : (
                                                            ""
                                                        )}
                                                    </div>
                                                    {popperOpen ? (
                                                        <ClickAwayListener onClickAway={() => setpopperOpen(false)}>
                                                            <Popper
                                                                open={Boolean(popperOpen)}
                                                                anchorEl={anchorEls}
                                                                disablePortal
                                                                className="popperTbl z-[1]"
                                                                //  onClickAway={setpopperOpen(false)}
                                                            >
                                                                <div
                                                                    className={`max-h-[340px] overflow-y-auto ${
                                                                        currentMode === "dark" ? "" : "shadow-[0px_4px_20px_rgba(238,238,238,0.5)]"
                                                                    } ml-[26px] bg-${currentMode}-card border rounded-[8px]`}
                                                                >
                                                                    <table
                                                                        className={`w-[514px] max-h-[230px] overflow-y-auto bg-${currentMode}-card rolemasterTable  rounded-[8px] text-left`}
                                                                    >
                                                                        <thead className="sticky top-0">
                                                                            <tr className="">
                                                                                <th className="">PO.No.</th>
                                                                                <th className="">Vendor</th>
                                                                                <th>Vendor type</th>
                                                                            </tr>
                                                                        </thead>{" "}
                                                                        {fetching && !requestComplete ? (
                                                                            <Loader className="flex h-[calc(100vh_-_17rem)]" />
                                                                        ) : (
                                                                            <tbody className="">
                                                                                {insertPoVal !== "" ? (
                                                                                    filteredPoNoList !== undefined &&
                                                                                    filteredPoNoList?.length !== 0 ? (
                                                                                        filteredPoNoList?.map((elem) => {
                                                                                            return (
                                                                                                <tr
                                                                                                    className="cursor-pointer"
                                                                                                    onClick={(e) =>
                                                                                                        handleChange(
                                                                                                            e,
                                                                                                            "click",
                                                                                                            elem?.PoNumber,
                                                                                                            elem?.VendorName
                                                                                                        )
                                                                                                    }
                                                                                                >
                                                                                                    <td className="pl-1 pr-1">{elem?.PoNumber}</td>
                                                                                                    <td className="max-w-[390px] pl-1 truncate">
                                                                                                        {elem?.VendorName}
                                                                                                    </td>
                                                                                                    <td className="flex">{elem?.VendorType}</td>
                                                                                                </tr>
                                                                                            );
                                                                                        })
                                                                                    ) : (
                                                                                        <tr>
                                                                                            <td
                                                                                                colSpan={12}
                                                                                                style={{
                                                                                                    textAlign: "center",
                                                                                                }}
                                                                                            >
                                                                                                No Datas Found
                                                                                            </td>
                                                                                        </tr>
                                                                                    )
                                                                                ) : (
                                                                                    poNumberList?.map((item) => {
                                                                                        return (
                                                                                            <tr
                                                                                                className="cursor-pointer"
                                                                                                onClick={(e) =>
                                                                                                    handleChange(
                                                                                                        e,
                                                                                                        "click",
                                                                                                        item?.PoNumber,
                                                                                                        item?.VendorName
                                                                                                    )
                                                                                                }
                                                                                            >
                                                                                                <td className="pl-1 pr-1">{item?.PoNumber}</td>
                                                                                                <td
                                                                                                    title={item?.VendorName}
                                                                                                    className="max-w-[390px] pl-1 truncate"
                                                                                                >
                                                                                                    {item?.VendorName}
                                                                                                </td>
                                                                                                <td className="flex">{item?.VendorType}</td>
                                                                                            </tr>
                                                                                        );
                                                                                    })
                                                                                )}
                                                                            </tbody>
                                                                        )}
                                                                    </table>
                                                                </div>
                                                            </Popper>
                                                        </ClickAwayListener>
                                                    ) : (
                                                        ""
                                                    )}
                                                </div>
                                                <div className="flex flex-col ml-[20px]">
                                                    <span>
                                                        <h3 className="text-[14px] pb-[10px] font-interM">Vendor:</h3>
                                                    </span>
                                                    <input
                                                        autoFocus
                                                        type="text"
                                                        placeholder="Enter vendor name"
                                                        value={vendorName}
                                                        readOnly={true}
                                                        class="border-[rgba(85,85,85,0.5)] w-[293px] h-[40px] bg-transparent px-[10px] py-1 border-[1px] rounded-[8px] "
                                                    />
                                                </div>
                                            </div>
                                            <div className="flex mt-5 gap-5">
                                                <div className="flex space-x-7 viewStyles">
                                                    <div className="basis-1/2 datePic">
                                                        <label className="block font-interM pb-[10px] ">Request Date:</label>
                                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                            <DesktopDatePicker
                                                                selectsEnd
                                                                className={`border-[1px] border-${currentMode}-840 rounded-[8px] w-[293px] h-[40px] bg-${currentMode}-810`}
                                                                disableFuture={true}
                                                                inputFormat={userDateFormat?.toUpperCase()}
                                                                // name="invoice_date_to"
                                                                components={{
                                                                    OpenPickerIcon: AccessIcon,
                                                                }}
                                                                value={reqDate}
                                                                disabled={editDisable}
                                                                onChange={(event) => setReqDate(event)}
                                                                // minDate={fromDate}
                                                                // maxDate={new Date(lastDateOfYear)}
                                                                renderInput={(params) => (
                                                                    <TextField
                                                                        value={""}
                                                                        fullWidth
                                                                        sx={{
                                                                            "& .MuiInputBase-input": {
                                                                                color: `${currentMode == "dark" ? "white" : "black"} !important`,
                                                                            },
                                                                        }}
                                                                        placeholder={params?.inputProps?.placeholder?.replace("To")}
                                                                        {...params}
                                                                        inputProps={{
                                                                            ...params.inputProps,
                                                                            readOnly: true,
                                                                        }}
                                                                    />
                                                                )}
                                                            />
                                                        </LocalizationProvider>
                                                    </div>
                                                </div>

                                                <div className="flex flex-col">
                                                    <span>
                                                        <h3 className="text-[14px] pb-[10px] font-interM">Release Amount:</h3>
                                                    </span>

                                                    <NumericFormat
                                                        key={`ret-${date}-value`}
                                                        id={1}
                                                        autoComplete="off"
                                                        allowNegative={false}
                                                        readOnly={editDisable}
                                                        decimalScale={2}
                                                        maxLength={23}
                                                        displayType="input"
                                                        thousandSeparator={thousandSeperator}
                                                        decimalSeparator={decSeperator}
                                                        // disabled={status == "7" || status == "4" || InputDisable}
                                                        value={releaseAmount}
                                                        onChange={(event) => handleAmountChange(event.target.value, event.target.id)}
                                                        placeholder="Enter release amount"
                                                        className="border-[rgba(85,85,85,0.5)] w-[293px] h-[40px] bg-transparent px-[10px] py-1 border-[1px] rounded-[8px] "
                                                    />

                                                    {/* <input
                                                        id={1}
                                                        readOnly={editDisable}
                                                        type="number"
                                                        value={releaseAmount}
                                                        onChange={(event) => handleAmountChange(+event.target.value, event.target.id)}
                                                        placeholder="Enter release amount"
                                                        class="border-[rgba(85,85,85,0.5)] w-[293px] h-[40px] bg-transparent px-[10px] py-1 border-[1px] rounded-[8px] "
                                                    /> */}
                                                </div>
                                            </div>
                                            <div className="flex flex-col mt-4">
                                                <span>
                                                    <h3 className="text-[14px] pb-[10px] font-interM">Additional Hold:</h3>
                                                </span>

                                                <NumericFormat
                                                    key={`add-${date}-value`}
                                                    id={2}
                                                    autoComplete="off"
                                                    allowNegative={false}
                                                    readOnly={editDisable}
                                                    decimalScale={2}
                                                    maxLength={23}
                                                    displayType="input"
                                                    thousandSeparator={thousandSeperator}
                                                    decimalSeparator={decSeperator}
                                                    // disabled={status == "7" || status == "4" || InputDisable}
                                                    value={addHoldAmount}
                                                    onChange={(event) => handleAmountChange(event.target.value, event.target.id)}
                                                    placeholder="Enter additional hold amount"
                                                    className="border-[rgba(85,85,85,0.5)] w-[293px] h-[40px] bg-transparent px-[10px] py-1 border-[1px] rounded-[8px] "
                                                />

                                                {/* <input
                                                    id={2}
                                                    type="number"
                                                    readOnly={editDisable}
                                                    value={addHoldAmount}
                                                    onChange={(event) => handleAmountChange(event.target.value, event.target.id)}
                                                    placeholder="Enter additional hold amount"
                                                    class="border-[rgba(85,85,85,0.5)] w-[293px] h-[40px] bg-transparent px-[10px] py-1 border-[1px] rounded-[8px] "
                                                /> */}
                                            </div>
                                            <div className="flex flex-col mt-4">
                                                <span>
                                                    <h3 className="text-[14px] pb-[10px] font-interM">Remarks:</h3>
                                                </span>
                                                <textarea
                                                    readOnly={editDisable}
                                                    id="myTextarea1"
                                                    className="border-[1px] focus-within:outline-0 px-4 w-full h-[150px] py-[4px] border620 bg-transparent rounded-[8px] shadow-[0px_4px_20px_rgba(238,238,238,0.5)] resize-none"
                                                    name="remarks"
                                                    placeholder="Max. 250 characters"
                                                    maxLength={250}
                                                    value={remarks}
                                                    onChange={(e) => setRemarks(e.target.value)}
                                                />
                                            </div>
                                        </Card>
                                    </div>
                                    <div className="xl:basis-[20%] space-y-5 ">
                                        <Card
                                            className={`${
                                                viewId ? "" : "opacity-[.5] pointer-events-none"
                                            } xl:mb-0 xl:mt-0 border-[1px] xl:w-[280px] xl:h-[62px] md:h-[84px] border-[rgba(223,225,226,0.56)] 2xl:w-full ${
                                                currentMode === "dark" ? "" : "shadow-[0px_4px_20px_rgba(238,238,238,0.5)]"
                                            }`}
                                        >
                                            <ul className="flex justify-between xl:py-5 xl:px-5 md:px-[62px] md:py-[30px]">
                                                <StatusButton
                                                    reqId={viewId}
                                                    currentStatusInfo={currentStatusInfo}
                                                    nextStatusInfo={nextStatusInfo}
                                                    createDataObject={viewData?.data?.[0]}
                                                    setIsUserActivity={setIsUserActivity}
                                                    findScreeSize={findScreeSize}
                                                    childCompRef={childCompRef}
                                                    filterMenuList={filterMenuList}
                                                    onFilterItems={onFilterItems}
                                                    isRetention={true}
                                                    DisplayRole={DisplayRole}
                                                    nextStatusUsers={nextStatusUsers}
                                                />
                                            </ul>
                                        </Card>
                                        <div
                                            className={`bg-[#FFF7F0] shadow-md text-${currentMode}-copy border-[1px] border-${currentMode}-700  rounded-[10px] mt-[20px] mb-[3rem]  xl:mt-0 border-[1px] h-[256px] border-[rgba(223,225,226,0.56)] xl:w-[280px] 2xl:w-full
                                            ${currentMode === "dark" ? "" : "shadow-[0px_4px_20px_rgba(238,238,238,0.5)]"}`}
                                        >
                                            <AdvanceFileUpload
                                                uploadFile={uploadFile}
                                                setUploadFile={setUploadFile}
                                                setFileSize={setFileSize}
                                                fileSize={fileSize}
                                                findCreateNewRequest={findCreateNewRequest}
                                                setFileContent={setFileContent}
                                                fileContent={fileContent}
                                                url={fileUrl}
                                                setFileUrl={setFileUrl}
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        {viewId && (
                                            <Card
                                                className={` border-[1px]  flex flex-col justify-between h-[345px] border-[rgba(223,225,226,0.56)] xl:w-[300px] 2xl:w-full ${
                                                    currentMode === "dark" ? "" : "shadow-[0px_4px_20px_rgba(238,238,238,0.5)] "
                                                } ${findScreeSize < 1180 ? "my-5" : ""}`}
                                            >
                                                <CommentCard type={3} createDataObject={viewData?.data?.[0]} reqId={viewId} />
                                            </Card>
                                        )}
                                    </div>
                                </div>

                                {/* {ReactDOM.createPortal(
                                <UserActivityMain
                                    isOpened={isUserActivity}
                                    handleClose={() => setIsUserActivity(false)}
                                    className={isUserActivity && "Show"}
                                />,
                                document.getElementById("user-modal-root")
                            )} */}
                            </div>
                        )}

                        {poReleseFetch && !poReleseReqComp ? (
                            <Loader className="flex h-[calc(100vh_-_17rem)]" />
                        ) : (
                            <RetentionTable
                                poReleseTable={poReleseTable}
                                setPoReleaseTable={setPoReleaseTable}
                                setReleaseAmount={setReleaseAmount}
                                setAddHoldAmount={setAddHoldAmount}
                                isPopUpNeed={isPopUpNeed}
                                setIsPopUpNeed={setIsPopUpNeed}
                                viewFetching={viewFetching}
                                editDisable={editDisable}
                                thousandSeperator={thousandSeperator}
                                decSeperator={decSeperator}
                                viewId={viewId}
                            />
                        )}
                        {ReactDOM.createPortal(
                            <Activity isOpened={isUserActivity} handleClose={() => setIsUserActivity(false)} className={isUserActivity && "Show"} />,
                            document.getElementById("user-modal-root")
                        )}
                        {ReactDOM.createPortal(
                            <ToReassign
                                isOpened={isreassign}
                                apiProps={{
                                    inv_id: viewId,
                                    org_id: +orgId,
                                    type: 2,
                                    status_id: prevStatusInfo?.[0]?.prev_status ? prevStatusInfo?.[0]?.prev_status : null,
                                    user_id: null,
                                    masterref_id: null,
                                    groupType: 4,
                                }}
                                changeStatus={changeStatus}
                                statusName={prevStatusInfo?.[0]?.status_name ? prevStatusInfo?.[0]?.status_name : null}
                                handleClose={() => setReassign(false)}
                                className={`${isreassign && "Show"}`}
                            />,
                            document.getElementById("user-modal-root")
                        )}
                    </Main>
                    <Footer />
                </ErrorBoundary>
            </Layout>
        </>
    );
};

export default RetentionRelease;
