import { useContext, useRef, useState } from "react";
import Button from "../../../components/Button";
import Input from "../../../components/Input";
import Modal from "../../../components/UI/Modal/Modal";
import { useEffect } from "react";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from "react-redux";
import { ORG_VIEW_RESET } from "../../../redux/types/types";
import { ThemeContext } from "../../../Hooks/Themes/Themeprovider";

const AddAlias = (props) => {
    const { currentMode } = useContext(ThemeContext);
    const dispatch = useDispatch();
    const OrgEditData = useSelector((state) => state?.organisationViewState);
    const { viewData } = OrgEditData;
    const orgname = props?.orgname;

    let ListData = viewData?.org_alias_list;

    const [addrow, setAddrow] = useState([ListData]);
    const AddNew = ListData == undefined || ListData == null;
    const Row = ListData !== undefined && ListData !== null;
    const newrow = props?.newrow;
    const setnewRow = props?.setnewRow;
    let msg = props?.setaliasInfo(addrow);
    let msgs = props?.setnewaliasInfo(newrow);
    const aliasnameValid = addrow?.filter((el) => el?.alias_name?.trim() == "");
    const aliasNewnameValid = newrow?.filter((el) => el?.alias_name?.trim() == "");
    const DuplicateName = addrow?.map((el) => el?.alias_name?.replace(/\s+/g, " ")?.trim());
    const DuplicatenewName = newrow?.map((el) => el?.alias_name?.replace(/\s+/g, " ")?.trim());
    const aliasOrgnameDuplication = newrow?.filter((el) => el?.alias_name?.replace(/\s+/g, " ")?.trim() == orgname?.replace(/\s+/g, " ")?.trim());
    const aliasOrgDuplication = addrow?.filter((el) => el?.alias_name?.replace(/\s+/g, " ")?.trim() == orgname?.replace(/\s+/g, " ")?.trim());
    const [value, setValue] = useState("");
    const data = {
        alias_name: "",
        id: 0,
    };
    const scrollRef = useRef(null);

    const [close, setClose] = useState(false);
    const handleClose = () => {
        setClose(true);
        setAddrow(ListData?.filter((el) => el?.id != 0));
        setnewRow([
            {
                alias_name: "",
                id: 0,
            },
        ]);

        props?.handleClose();
    };
    useEffect(() => {
        if (close) {
            setAddrow([]);
        }
        setAddrow(viewData?.org_alias_list);
        if (AddNew) {
            setnewRow([
                {
                    alias_name: "",
                    id: 0,
                },
            ]);
        }
    }, [props?.aliastrue, viewData?.org_alias_list]);

    const addRow = () => {
        const data = {
            alias_name: "",
            id: 0,
        };
        addrow.push(data);
        setAddrow([...addrow]);
        setTimeout(() => {
            var element = document.getElementById("myView2");
            element.scrollTop = element.scrollHeight;
        }, 100);
    };
    const bottomRef = useRef(null);
    const addnewRow = () => {
        const data = {
            alias_name: "",
            id: 0,
        };
        newrow.push(data);
        setnewRow([...newrow]);
        setTimeout(() => {
            var element = document.getElementById("myView");
            element.scrollTop = element.scrollHeight;
        }, 100);
    };

    const handleValueChange = (event, inde) => {
        addrow.forEach((element, index) => {
            if (inde == index) {
                element.alias_name = event.target.value;
            }
        });
        setValue(event.target.value);
        setAddrow([...addrow]);
    };
    const handleAddValueChange = (event, inde) => {
        newrow.forEach((element, index) => {
            if (inde == index) {
                element.alias_name = event.target.value;
            }
        });
        setValue(event.target.value);
        setnewRow([...newrow]);
    };
    const tableRowRemove = (id, inde) => {
        Swal.fire({
            text: "Are you sure you want to delete?",
            icon: "warning",
            closeOnClickOutside: false,
            showCloseButton: true,
            showCancelButton: true,
            buttons: {
                cancel: "Cancel",
                confirm: "Confirm",
            },
        }).then((confirm) => {
            if (confirm?.isConfirmed) {
                addrow.splice(id, 1);

                setAddrow([...addrow]);
            }
        });
    };
    const tableRemove = (id, inde) => {
        Swal.fire({
            text: "Are you sure you want to delete?",
            icon: "warning",
            closeOnClickOutside: false,
            showCloseButton: true,
            showCancelButton: true,
            buttons: {
                cancel: "Cancel",
                confirm: "Confirm",
            },
        }).then((confirm) => {
            if (confirm?.isConfirmed) {
                newrow.splice(id, 1);

                setnewRow([...newrow]);
            }
        });
    };
    let resultSubmit = false;
    const DuplicateNameAlias = AddNew ? DuplicatenewName : DuplicateName;
    resultSubmit = DuplicateNameAlias?.some((element, index) => {
        return DuplicateNameAlias?.indexOf(element) !== index;
    });
    const matchOrgName = AddNew ? aliasOrgnameDuplication : aliasOrgDuplication;
    if (resultSubmit) {
        props?.setDuplicateAlias(true);
    } else {
        props?.setDuplicateAlias(false);
    }
    if (matchOrgName?.length > 0) {
        props?.setmatchOrgAlias(true);
    } else {
        props?.setmatchOrgAlias(false);
    }
    if (AddNew ? aliasNewnameValid?.length > 0 : aliasnameValid?.length > 0) {
        props?.setEmptyAlias(true);
    } else {
        props?.setEmptyAlias(false);
    }

    const NewSubmit = () => {
        let resultNew = false;
        resultNew = DuplicatenewName.some((element, index) => {
            return DuplicatenewName.indexOf(element) !== index;
        });

        if (aliasNewnameValid?.length > 0) {
            Swal.fire({
                text: "Alias name should not be empty!",
                icon: "warning",
                closeOnClickOutside: false,
                showCloseButton: true,
                buttons: {
                    confirm: true,
                },
            });
        } else if (aliasOrgnameDuplication?.length > 0) {
            Swal.fire({
                text: "Alias name should not match with organization Name!",
                icon: "warning",
                closeOnClickOutside: false,
                showCloseButton: true,
                buttons: {
                    confirm: true,
                },
            });
        } else if (resultNew) {
            Swal.fire({
                text: "Alias name should not have duplicate names!",
                icon: "warning",
                closeOnClickOutside: false,
                showCloseButton: true,
                buttons: {
                    confirm: true,
                },
            });
        } else {
            props?.handleClose();
        }
    };
    let isreassign = props?.isOpened;

    const Submit = () => {
        let resultTo = false;
        resultTo = DuplicateName.some((element, index) => {
            return DuplicateName.indexOf(element) !== index;
        });

        if (aliasnameValid?.length > 0) {
            Swal.fire({
                text: "Alias name should not be empty!",
                icon: "warning",
                closeOnClickOutside: false,
                showCloseButton: true,
                buttons: {
                    confirm: true,
                },
            });
        } else if (aliasOrgDuplication?.length > 0) {
            Swal.fire({
                text: "Alias name should not match with organization Name!",
                icon: "warning",
                closeOnClickOutside: false,
                showCloseButton: true,
                buttons: {
                    confirm: true,
                },
            });
        } else if (resultTo) {
            Swal.fire({
                text: "Alias name should not have duplicate names!",
                icon: "warning",
                closeOnClickOutside: false,
                showCloseButton: true,
                buttons: {
                    confirm: true,
                },
            });
        } else {
            props?.handleClose();
        }
    };
    if (props?.isOpened && !newrow?.some((el) => el?.alias_name !== "")) {
        var textarea = document?.getElementById("aliasnameId");
        textarea?.focus();
    }
    const SetCloseModal = () => {
        setnewRow([
            {
                alias_name: "",
                id: 0,
            },
        ]);
        props?.handleClose();
    };
    const RowCloseModal = () => {
        props?.handleClose();

        Row
            ? setAddrow(addrow?.filter((el) => el?.alias_name?.trim() !== ""))
            : AddNew && newrow?.length == 1 && newrow?.every((el) => el?.alias_name?.trim() == "")
            ? setnewRow([
                  {
                      alias_name: "",
                      id: 0,
                  },
              ])
            : setnewRow(newrow?.filter((el) => el?.alias_name !== ""));
    };
    const ValidateClose =
        (AddNew && newrow?.every((el) => el?.alias_name?.trim() == "") && newrow?.length > 1) ||
        (Row && addrow?.every((el) => el?.alias_name?.trim() == "") && addrow?.length > 1);
    const ModalClose = ValidateClose ? SetCloseModal : Row || AddNew ? RowCloseModal : props?.handleClose;
    return (
        <>
            <Modal cartClose={handleClose} center="center" overlap={true} className={`${props?.className}`}>
                <div
                    className={`max-w-[500px] mx-auto relative bg-${currentMode}-card  boder-${currentMode}-700 text-${currentMode}-copy px-[90px] mx-auto  py-[48px] rounded-[10px] shadow-[0px_4px_20px_rgba(238,238,238,0.5)]`}
                >
                    <span onClick={handleClose} title="Close" className="cursor-pointer absolute right-[20px] top-[20px]">
                        <img src="/images/common/closeBlk.svg" alt="closeBlk" />
                    </span>
                    <span className="font-interSb block mb-4">Organization Alias Name</span>
                    <div className="">
                        <div id="myView" className="overflow-y-auto max-h-[20rem] pr-[20px]">
                            {AddNew &&
                                newrow?.map((listelem, index) => {
                                    return (
                                        <div className="flex items-center space-x-3 mb-[1rem]">
                                            <Input
                                                tabIndex="0"
                                                name="alias_name"
                                                id="aliasnameId"
                                                value={listelem?.alias_name}
                                                maxLength={250}
                                                onChange={(event) => {
                                                    handleAddValueChange(
                                                        {
                                                            target: {
                                                                name: event.target.name,
                                                                value: event.target.value,
                                                            },
                                                        },
                                                        index
                                                    );
                                                }}
                                                placeholder="Enter Organization alias name"
                                                className={`border-[1px] borderLeft border-${currentMode}-840 rounded-[8px] pl-[20px] py-2 w-full bg-${currentMode}-810`}
                                            />
                                            <Button
                                                disabled={newrow?.length == 1}
                                                title={newrow?.length == 1 ? "" : "To Delete"}
                                                onClick={() => tableRemove(index)}
                                                className="cursor-pointer pr-2"
                                            >
                                                <img
                                                    src="/images/main/divide-circle.svg"
                                                    alt="divide-circle"
                                                    className="mx-auto cursor-pointer convertWhite"
                                                />
                                            </Button>
                                        </div>
                                    );
                                })}
                        </div>
                        <div id="myView2" className="overflow-y-auto max-h-[20rem] pr-[20px]">
                            {addrow?.map((listelem, index) => {
                                return (
                                    <div className="flex items-center space-x-3 mb-[1rem] last:mb-0">
                                        <Input
                                            tabIndex="0"
                                            name="alias_name"
                                            value={listelem?.alias_name}
                                            maxLength={250}
                                            autoFocus
                                            onChange={(event) => {
                                                handleValueChange(
                                                    {
                                                        target: {
                                                            name: event.target.name,
                                                            value: event.target.value,
                                                        },
                                                    },
                                                    index
                                                );
                                            }}
                                            placeholder="Enter Organization alias name"
                                            className={`border-[1px] borderLeft rounded-[8px] pl-[20px] py-2 w-full bg-${currentMode}-840`}
                                        />
                                        <Button
                                            disabled={ListData?.length == 1}
                                            title="To Delete"
                                            onClick={() => tableRowRemove(index)}
                                            className="cursor-pointer pr-2"
                                        >
                                            <img src="/images/main/divide-circle.svg" alt="divide-circle" className="mx-auto cursor-pointer" />
                                        </Button>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                    {AddNew && (
                        <Button onClick={addnewRow} title="Add New" className="text-light-20">
                            <div className="mt-[30px] mr-[220px]">+ Add New</div>
                        </Button>
                    )}
                    {Row && (
                        <Button onClick={() => addRow()} title="Add New" className="text-light-20">
                            <div className="mt-[30px] mr-[220px]">+ Add New</div>
                        </Button>
                    )}
                    <div className="flex items-center mt-7 space-x-3">
                        {AddNew && (
                            <Button
                                className="bg-light-20 px-[30px] py-[9px] text-white rounded-[8px]"
                                type="submit"
                                title="Save"
                                onClick={NewSubmit}
                                //   disabled={fetching}
                            >
                                Save
                            </Button>
                        )}
                        {Row && (
                            <Button
                                className="bg-light-20 px-[30px] py-[9px] text-white rounded-[8px]"
                                type="submit"
                                title="Save"
                                onClick={Submit}
                                //   disabled={fetching}
                            >
                                Save
                            </Button>
                        )}

                        <Button className="bg-light-240 px-[28px] py-[9px] rounded-[8px] text-light-40" onClick={handleClose} title="Cancel">
                            Cancel
                        </Button>
                    </div>
                </div>
            </Modal>
        </>
    );
};
export default AddAlias;
