
import { PieChart, Pie, Tooltip, ResponsiveContainer, Cell } from "recharts";
import { generateRandomColor, extractTextFromHTML } from "../../../../utils/helpers";


const CustomPiechart = (props) => {
    const data = props?.data;
    const backgroundColor = Array.from({ length: data?.length }, () => generateRandomColor());
    const dataKeys = data && data[0] && Object.keys(data[0]);
    const CustomTooltip = ({ active, payload }) => {
        if (active && payload && payload?.length) {
            const entry = payload[0]?.payload;

            return (
                <div className={` py-3 px-5 border recharts-default-tooltip rounded-[8px]`}>
                    <p className="label">{`${extractTextFromHTML(entry?.[dataKeys?.[1]])} : ${entry?.[dataKeys?.[0]]}`}</p>
                </div>
            );
        }
    };


    return (
        <ResponsiveContainer width="100%" height={400}>
            <PieChart>
                <Pie dataKey={dataKeys?.[0]} nameKey={dataKeys?.[1]} data={data} cx="50%" cy="50%" outerRadius={150} label>
                    {data?.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={backgroundColor[index % backgroundColor?.length]} />
                    ))}
                </Pie>
                <Tooltip content={<CustomTooltip />} />
            </PieChart>
        </ResponsiveContainer>
    );
};

export default CustomPiechart;
