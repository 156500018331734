import PropTypes from "prop-types";
import { useState, useEffect, useContext } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "../../components/Button";
import Card from "../../components/UI/Card";
import Main from "../../components/UI/Main";
import Layout from "../../Hoc/Layout";
import { useDispatch, useSelector } from "react-redux";
import { ProfileDelete, ProfileviewList } from "../../redux/actions/profile";
import ProfileEdit from "./Edit";
import Uploadphoto from "../../uploadphoto";
import ChangePassword from "../ChangePassword";
import Settings from "../Settings";
import { Link, useLocation, NavLink } from "react-router-dom";
import axios from "axios";

import configMyiQ from "../../configuration";
import authHeader from "../../redux/actions/auth-header";
import { getCurrentUser } from "../../redux/actions/auth";
import Swal from "sweetalert2";
import Loader from "../../components/Loader";
import { decryptId } from "../../utils/helpers";
import { ThemeContext } from "../../Hooks/Themes/Themeprovider";

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            className="h-full"
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3, height: "100%" }}>{children}</Box>}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        "aria-controls": `vertical-tabpanel-${index}`,
    };
}

const ProfileView = () => {
    // const Token = localStorage.getItem("token");
    const [fileName, onFileUpload] = useState("");
    const [imageFile, onFileHandle] = useState([]);

    const [file, setFile] = useState();
    const [isLoading, setLoading] = useState(false);
    const [imgData, setImgData] = useState(null);
    const fileTypes = ["image/png", "image/jpg", "image/jpeg"];
    const handleFile = (event) => {
        let file = event.target.files[0];

        const fixExist = fileTypes?.find((item) => item == file?.type);
        if (!fixExist) {
            Swal.fire({
                text: "File type is not supported!",
                icon: "warning",
                closeOnClickOutside: false,
                showCloseButton: true,
                buttons: {
                    confirm: true,
                },
            });
        } else if (event.target.files[0]) {
            setLoading(true);
            const reader = new FileReader();
            reader.addEventListener("load", () => {
                setImgData(reader.result);
            });
            reader.readAsDataURL(event.target.files[0]);

            const currentUser = getCurrentUser();
            const formData = new FormData();
            formData.append("Id", currentUser?.id);
            formData.append("Image", event.target.files[0]);
            formData.append("isdefault_img", false);

            axios({
                url: `${process.env?.REACT_APP_API_URL}User/UploadPhoto`,
                headers: authHeader(),
                method: "POST",
                data: formData,
            }).then(
                (res) => {
                    setLoading(false);
                    if (res?.data?.status) {
                        Swal.fire({
                            text: "Profile photo is uploaded successfully!",
                            icon: "success",
                            closeOnClickOutside: false,
                            showCloseButton: true,
                            button: "Ok",
                        }).then((confirm) => {
                            dispatch(
                                ProfileviewList({
                                    id: parseInt(currentUser.id),
                                })
                            );
                        });
                    } else {
                        setImgData("");
                        Swal.fire({
                            text: res.data.message,
                            closeOnClickOutside: false,
                            showCloseButton: true,
                            icon: "warning",
                            button: "Ok",
                        });
                    }
                },
                (err) => {}
            );
        }
    };
    const _handleReaderLoaded = (e) => {
        let binaryString = e.target.result;
        onFileUpload(btoa(binaryString));
        handleUpload();
    };

    const handleUpload = () => {
        let formdata = new FormData();

        formdata.append("Id", viewData?.id);

        formdata.append("Image", viewData?.fileName);

        axios({
            url: `${process.env?.REACT_APP_API_URL}User/UploadPhoto`,
            method: "POST",
            headers: authHeader(),
            data: formdata,
        }).then(
            (res) => {},
            (err) => {}
        );
    };

    const dispatch = useDispatch();
    const [value, setValue] = useState(0);
    const Id = authHeader()?.id;
    const CurrentUser = getCurrentUser();
    const profileData = useSelector((state) => state?.profileState);
    const { profile: viewData, requestComplete, fetching } = profileData;
    const [edit, setEdit] = useState(false);
    const location = useLocation();
    localStorage.setItem("UserImage", viewData?.user_image);
    const { currentMode } = useContext(ThemeContext);

    useEffect(() => {
        // let bodys = document.querySelector("body").classList;
        // location.pathname === "/profile" &&
        //     bodys.remove(`bg-${currentMode}-base`);
        if (location.pathname !== "/profile") {
            const Id = authHeader()?.id;
            dispatch(ProfileviewList({ id: +Id }));
        }
    }, [dispatch, location.pathname]);

    const _editHandle = (e) => {
        e.preventDefault();
        setEdit(true);
    };
    const EditContent = !edit ? (
        <>
            {fetching && !requestComplete ? (
                <Loader />
            ) : (
                <div>
                    <div className="flex justify-between items-center mb-7">
                        <h3 className="text-2xl font-interM text-light-20">Profile</h3>
                        <Button
                            onClick={_editHandle}
                            className="bg-light-20 flex items-center text-sm font-interR py-[.625rem] px-[.9rem] rounded-[8px] text-white"
                        >
                            <img className="mr-2" src="/images/common/pencil-medium.svg" title="To edit your Profile details" alt="pencil-medium" />{" "}
                            Edit Profile
                        </Button>
                    </div>
                    <div className="flex mb-5 viewStyles">
                        <div className="basis-1/2">
                            <span className="block font-interR text-sm">First Name</span>
                            <b className="text-base">
                                <p title={viewData?.firstname} className="w-[300px] truncate">
                                    {viewData?.firstname}
                                </p>
                            </b>
                        </div>
                        <div className="basis-1/2">
                            <span className="block text-sm font-interR">Last Name</span>
                            <b className="text-base">
                                {" "}
                                <p title={viewData?.lastname} className="w-[300px] truncate">
                                    {viewData?.lastname}
                                </p>
                            </b>
                        </div>
                    </div>
                    <div className="flex mb-5 viewStyles">
                        <div className="basis-1/2">
                            <span className="block text-sm font-interR">Business Email</span>
                            <b title={decryptId(viewData?.user_emailaddress)} className="text-base">
                                {viewData?.user_emailaddress ? decryptId(viewData?.user_emailaddress) : ""}
                            </b>
                        </div>
                        <div className="basis-1/2">
                            <span className="block text-sm font-interR">Mobile Number</span>
                            <b title={viewData?.mobilenumber} className="text-base">
                                {viewData?.mobilenumber}
                            </b>
                        </div>
                    </div>
                    <div className="flex mb-5 viewStyles">
                        {/* <div className="basis-1/2">
        <span className="block text-sm font-interR">Role</span>
        <b className="text-base block">{viewData?.role_id?.role}</b>
      </div> */}
                        <div className="basis-1/2">
                            <span className="block text-sm font-interR">Country</span>
                            <b className="text-base block">{viewData?.country?.country_name}</b>
                        </div>
                    </div>
                </div>
            )}
        </>
    ) : (
        <ProfileEdit datas={viewData} Remove={() => setEdit(false)} />
    );
    const removeFile = (index, fileName) => {
        Swal.fire({
            text: `Are you sure you want to remove your profile photo?`,
            icon: "warning",
            closeOnClickOutside: false,
            showCloseButton: true,
            showCancelButton: true,
            buttons: {
                cancel: "Cancel",
                confirm: "Confirm",
            },
        }).then((confirm) => {
            if (confirm?.isConfirmed) {
                dispatch(ProfileDelete(CurrentUser?.id, true));
            }
            setTimeout(() => {
                dispatch(
                    ProfileviewList({
                        id: parseInt(CurrentUser.id),
                    })
                );
            }, 1000);
        });
        setImgData(null);
    };
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <Layout>
            <Main>
                <div className="lg:flex space-y-5 mb-5 lg:space-y-0 lg:space-x-6 justify-center">
                    <Card className="px-[3.125rem] basis-56 py-[1.875rem]">
                        {isLoading ? (
                            <div className={`h-[130px] w-[130px] flex justify-center flex-col items-center `}>
                                <img className="w-[7rem]" src="/images/gif/Loading-Gif.gif" alt="Loading-Gif" />
                            </div>
                        ) : (
                            <div className="profileHover mb-2">
                                <>
                                    <div>
                                        <img
                                            className="rounded-full object-cover w-[130px] h-[130px]"
                                            src={
                                                imgData
                                                    ? `${imgData}`
                                                    : viewData?.user_image
                                                    ? `data:image;base64,${viewData?.user_image}`
                                                    : `/images/common/profileImg.jpg`
                                            }
                                            alt="Profile"
                                        />
                                        {!viewData?.isdefault_img ? (
                                            <Button onClick={removeFile}>
                                                <img className="mb-1" src="/images/common/removeIcon.svg" alt="removeIcon" />
                                                Remove
                                            </Button>
                                        ) : (
                                            ""
                                        )}
                                        {/* <Uploadphoto /> */}
                                    </div>
                                </>
                            </div>
                        )}
                        {/* <div className="profileHover">
                            <img src="/images/common/Profile.png" alt="Profile" />
                            <Button>
                                <img
                                    className="mb-1"
                                    src="/images/common/removeIcon.svg"
                                    alt="removeIcon"
                                />
                                Remove
                            </Button>
                       </div> */}

                        <div className="upload-btn-wrapper cursor-pointer">
                            <span to="#!" className="flex cursor-pointer items-center mb-2 justify-center">
                                <img className="mr-1" src="/images/common/camera.svg" alt="camera" title="To change the Profile Photo" /> Upload
                            </span>
                            <input className="cursor-pointer" type="file" name="myfile" accept=".jpg, .jpeg, .png" onChange={(e) => handleFile(e)} />
                        </div>
                        <div className="text-center mb-5 items-center  justify-center">
                            <h4 className="text-light-20 font-interSb text-base break-all">
                                {viewData?.firstname}
                                {viewData?.lastname?.length <= 3 ? "   " + viewData?.lastname : ""}{" "}
                            </h4>
                            {viewData?.lastname?.length > 3 && (
                                <h4 className="text-light-20 font-interSb text-base break-all">{viewData?.lastname}</h4>
                            )}
                            <span className="text-xs">{viewData?.role_id?.role}</span>
                        </div>
                        <div className="my-4 lg:hidden">
                            <hr className={`border-${currentMode}-690`} />
                        </div>
                        <Tabs
                            className={` profileTab`}
                            orientation="vertical"
                            variant="scrollable"
                            value={value}
                            onChange={handleChange}
                            aria-label="Vertical tabs"
                            sx={{ borderRight: 1, borderColor: "divider" }}
                        >
                            <Tab
                                icon={<img src="/images/profile/user-ico.svg" alt="user-ico" className="convertWhite" />}
                                iconPosition="start"
                                label="Profile"
                                className={`${currentMode == "dark" ? `text-${currentMode}-copy` : ""}`}
                                {...a11yProps(0)}
                            />
                            <Tab
                                icon={<img src="/images/profile/gear-ico.svg" alt="gear-ico" className="convertWhite" />}
                                iconPosition="start"
                                label="Settings"
                                className={`${currentMode == "dark" ? `text-${currentMode}-copy` : ""}`}
                                {...a11yProps(1)}
                            />
                            <Tab
                                icon={<img src="/images/profile/key-ico.svg" alt="key-ico" className="convertWhite" />}
                                iconPosition="start"
                                className={`${currentMode == "dark" ? `text-${currentMode}-copy` : ""}`}
                                label="Password"
                                {...a11yProps(2)}
                            />
                        </Tabs>
                    </Card>
                    <Card
                        className={`${
                            currentMode !== "dark" ? "shadow-[0px_4px_20px_rgba(238,238,238,0.5)]" : ""
                        } px-[2rem] basis-[56%] min-h-[530px]  py-[1.8rem]`}
                    >
                        <TabPanel value={value} index={0}>
                            {EditContent}
                        </TabPanel>
                        <TabPanel value={value} index={1}>
                            <Settings />
                        </TabPanel>
                        <TabPanel value={value} index={2}>
                            <ChangePassword index={value} />
                        </TabPanel>
                    </Card>
                </div>
            </Main>
        </Layout>
    );
};

export default ProfileView;
