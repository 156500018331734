import Button from "../../components/Button";
import Modal from "../../components/UI/Modal/Modal";
import { BackupDataApi, RemoveData } from "../../redux/actions/setting";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import MessageBox from "../../components/UI/Alert";
import { BACKUP_DATA_RESET } from "../../redux/types/types";
import { getCurrentUser } from "../../redux/actions/auth";
import { useState, useEffect } from "react";
import { decryptId } from "../../utils/helpers";
import { useContext } from "react";
import { ThemeContext } from "../../Hooks/Themes/Themeprovider";

const BackupData = (props) => {
    const { currentMode, toggleMode } = useContext(ThemeContext);
    const { requestComplete, fetching, error, data } = useSelector((state) => state?.BackupDataState);
    const errormsg = "Something went wrong please contact admin";
    const dispatch = useDispatch();
    const CurrentUser = getCurrentUser();
    const emailaddress = decryptId(CurrentUser?.emailaddress);
    const navigate = useNavigate;
    const [success, setSuccess] = useState(false);
    function submit() {
        dispatch(BackupDataApi());
    }
    useEffect(() => {
        if (data?.status && requestComplete) {
            setSuccess(true);
        }
    }, [success, data?.status, requestComplete]);
    const SuccessClose = () => {
        props?.handleClose();
        dispatch({ type: BACKUP_DATA_RESET });
        setSuccess(false);
    };

    return (
        <>
            {/* {requestComplete && !data?.status && <MessageBox error={errormsg} />} */}
            {error && !data?.status && <MessageBox error={errormsg} />}
            <Modal cartClose={props?.handleClose} center="center" className={`${props?.className}`}>
                {!success && (
                    <div
                        className={`w-[500px] mx-auto relative  px-[30px] text-left py-[31px] rounded-[10px] ${
                            currentMode === "dark" ? "" : "shadow-[0px_4px_20px_rgba(238,238,238,0.5)]"
                        } bg-${currentMode}-card text-${currentMode}-copy border-${currentMode}-700`}
                    >
                        <Button onClick={props?.handleClose} tabIndex="4" className="cursor-pointer absolute right-[20px] top-[20px] convertWhite">
                            <img src="/images/common/closeBlk.svg" alt="closeBlk" />
                        </Button>
                        <div className="flex space-x-4">
                            <img className="bg-message rounded-[50%] w-[50px] h-[50px] mb-8" src="/images/common/bg-databackup.svg" alt="alert" />
                            <span className="font-interSb  text-base block mb-4 mt-4">Data Backup</span>
                        </div>

                        <p className="mb-4">
                            Click the 'Confirm' option to Export your invoice data from Last Backup date to till date on to a CSV file.
                        </p>
                        <p className="mb-6">You will receive an email with a file to download your data within a few minutes</p>
                        <div className="flex  mt-[40px] space-x-2">
                            <Button
                                title="Confirm"
                                className="bg-light-20 px-[25px] text-base py-2 text-white rounded-[8px]"
                                type="submit"
                                tabIndex="3"
                                onClick={submit}
                                disabled={fetching}
                            >
                                Confirm
                            </Button>
                            <Button
                                title="Cancel"
                                className="bg-light-240  text-light-40 px-[28px] text-base py-2 rounded-[8px]"
                                onClick={props?.handleClose}
                                tabIndex="3"
                            >
                                Cancel
                            </Button>
                        </div>
                    </div>
                )}
                {success && (
                    <div
                        className={`max-w-[600px] mx-auto relative bg-${currentMode}-card text-${currentMode}-copy border-${currentMode}-700 px-[90px] text-center py-[48px] rounded-[10px] shadow-[0px_4px_20px_rgba(238,238,238,0.5)]`}
                    >
                        <Button onClick={props?.handleClose} tabIndex="4" className="cursor-pointer absolute right-[20px] top-[20px]">
                            <img src="/images/common/closeBlk.svg" alt="closeBlk" />
                        </Button>
                        <div className="flex justify-center ">
                            <img className="bg-message rounded-[50%] w-[50px] h-[50px] mb-8" src="/images/common/progress-tick.svg" alt="alert" />
                        </div>

                        <p className="font-interSb block mb-4">Your back up data will be sent to '{emailaddress}'</p>
                        <div className="flex justify-center items-center mt-7 space-x-3">
                            <Button
                                className="bg-light-20  text-light-40 px-[30px] py-[9px] text-white rounded-[8px]"
                                type="submit"
                                tabIndex="3"
                                onClick={SuccessClose}
                            >
                                Ok
                            </Button>
                        </div>
                    </div>
                )}
            </Modal>
        </>
    );
};

export default BackupData;
