import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Modal from "../../components/UI/Modal/Modal";
import { cartToggleAction } from "../../redux/actions/filter";
import Button from "../../components/Button";
import { roleid } from "../../redux/actions/role";
import UseForm from "../../Hooks/useForm";


const UserFilterModal = (props) => {
    const { handleChange, handleSubmit, values, errors, InitialState, setValues } = UseForm();
    //const dispatch = useDispatch();

    // const cartClose = (e) => {
    //   e.preventDefault();
    //   dispatch(cartToggleAction(false));
    //   dispatch(roleid());
    // };
    const { role } = useSelector((state) => state?.roleState);
    return (
        <Modal cartClose={props?.cartClose} className={props?.className}>
            <div className="min-w-[320px] overflow-y-auto transition duration-150 ease-out flex-col flex justify-between h-screen max-h-[-webkit-fill-available]">
                <div>
                    <div className="flex justify-between px-[30px] py-[20px] items-center">
                        <h3 className="text-lg font-interSb">More Filters</h3>
                        <Link to="#!" onClick={props?.cartClose}>
                            <img src="/images/common/closeBlk.svg" alt="closeBlk" />
                        </Link>
                    </div>
                    <hr />
                    <div className="flex flex-col justify-between px-[30px] py-[23px]">
                        <span className="block text-light-60">Role</span>
                        <select
                            onChange={handleChange}
                            name="role_id"
                            className="customDropdown font-interR"
                        >
                            <option selected value={values?.role_id?.id || ""}>
                                {values?.role_id?.role || "Select"}
                            </option>
                            {role?.map((elem) => {
                                return (
                                    <option key={elem?.id} value={elem?.id}>
                                        {elem?.role}
                                    </option>
                                );
                            })}
                        </select>
                    </div>
                    <hr />
                    <div className="flex flex-col justify-between px-[30px] py-[23px]">
                        <span className="block text-light-60">Status</span>
                        <select name="" className="customDropdown font-interR" id="">
                            <option value="" selected>
                                Active
                            </option>
                            <option value="">InActive</option>
                        </select>
                    </div>
                </div>
                <div className="flex border-t-[1px] border-[rgba(223,225,226,0.56)] justify-between items-center px-[30px] py-[23px]">
                    <Button className="text-white rounded-[5px] bg-light-20 text-xs px-5 py-1">Done</Button>
                    <span className="text-xs">Clear All</span>
                </div>
            </div>
        </Modal>
    );
};

export default UserFilterModal;
