import { CART_ACTION, ORG_TOGGLE_ACTION, USER_TOGGLE_ACTION } from "../types/types";

export const cartToggleAction = (status) => (dispatch) => dispatch({ type: CART_ACTION, payload: status });

export const addUserToggleAction = (status) => (dispatch) => dispatch({ type: USER_TOGGLE_ACTION, payload: status });

export const addOrgToggleAction = (status) => (dispatch) => dispatch({ type: ORG_TOGGLE_ACTION, payload: status });

export const setDroppedValues = (value, isChecked, type) => (dispatch) => {
    switch (type) {
        case "columns":
            dispatch({
                type: "TOGGLE_CHECKBOX_COLUMN",
                payload: { value, isChecked, type },
            });
            break;
        case "rows":
            dispatch({
                type: "TOGGLE_CHECKBOX_MATRIX_ROWS",
                payload: { value, isChecked, type },
            });
            break;
        case "matrixcolumns":
            dispatch({
                type: "TOGGLE_CHECKBOX_MATRIX_COLUMN",
                payload: { value, isChecked, type },
            });
            break;
        case "field":
            dispatch({
                type: "TOGGLE_CHECKBOX_FIELD",
                payload: { value, isChecked, type },
            });
            break;
        case "label":
            dispatch({
                type: "TOGGLE_CHECKBOX_LABEL",
                payload: { value, isChecked, type },
            });
            break;
        case "subtotal":
            dispatch({
                type: "TOGGLE_CHECKBOX_SUBTOTAL",
                payload: { value, isChecked, type },
            });
            break;
        case "sorting":
            dispatch({
                type: "TOGGLE_CHECKBOX_SORTING",
                payload: { value, isChecked, type },
            });
            break;
        case "morefilter":
            dispatch({
                type: "TOGGLE_CHECKBOX_MORE_FILTER",
                payload: { value, isChecked, type },
            });
            break;
        case "json":
            dispatch({
                type: "TOGGLE_CHECKBOX_JSON",
                payload: { value, isChecked, type },
            });
            break;
        case "json":
            dispatch({
                type: "TOGGLE_CHECKBOX_XML",
                payload: { value, isChecked, type },
            });
            break;
        default:
            break;
    }
};

export const storeReportDetail = (data) => (dispatch) => dispatch({ type: "STORE_REPORT_DATA", payload: data });
