import Input from "../../../components/Input";
import { useRef, useState, useEffect } from "react";
import Button from "../../../components/Button";
import { Tooltip } from "@mui/material";
const InputField = (props) => {
    const { fieldType, item, currentMode, ind, EditBox, updateUser, closeUpdate, handleEdit, dynamicClass } = props;
    const [inputValue, setInputValue] = useState(item?.field_display_name);
    const inputRef = useRef(null);
    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    }, [inputValue]);
    return (
        <>
            <Input
                ref={inputRef}
                readOnly={fieldType === item?.field_name ? false : true}
                className={dynamicClass}
                value={inputValue}
                onChange={(e) => {
                    setInputValue(e.target.value);
                    //props?.handleChangeLayoutName(e, ind, item?.id, item?.isitem);
                }}
                name={item?.field_name}
            />
            {((item?.field_name == "purchase_order_date" && fieldType !== "purchase_order_date") ||
                (item?.field_name == "purchase_order_no" && fieldType !== "purchase_order_no") ||
                (item?.field_name == "sale_order_no" && fieldType !== "sale_order_no") ||
                (item?.field_name == "sale_order_date" && fieldType !== "sale_order_date") ||
                (item?.field_name == "tax_code" && fieldType !== "tax_code") ||
                (item?.field_name == "item_tax_code" && fieldType !== "item_tax_code")) && (
                <Tooltip
                    title={`This field is available for the ${
                        item?.field_name == "tax_code" || item?.field_name == "item_tax_code" ? "Non-PO" : "PO"
                    } Invoice only`}
                    disablePortal
                    placement="right"
                    disableInteractive
                    PopperProps={{
                        sx: {
                            "& .MuiTooltip-tooltip": {
                                fontSize: "1.0em",
                                maxHeight: "120px",
                                maxWidth: "220px",
                                background: "#DAF9F4",
                                color: "#555555",
                                borderRadius: "5px",
                                marginTop: "0 !important",
                                padding: "10px",
                            },

                            "& .MuiTooltip-arrow": {
                                color: "#DAF9F4",
                            },
                        },
                    }}
                    arrow
                >
                    <img className="mt-1 h-[12px] cursor-default" src="/images/main/noun-information.svg" alt="noun-information" />
                </Tooltip>
            )}
            {fieldType === item?.field_name && (
                <EditBox
                    className="Edit ml-9"
                    handleClick={() => updateUser(item?.field_name, inputValue, item?.id, item?.isitem, ind)}
                    id={item?.id}
                    closeClick={() => closeUpdate(item?.field_name, item?.field_display_name, item?.id, item?.isitem)}
                />
            )}
            {fieldType !== item?.field_name && (
                <Button
                    className="Edit ml-9"
                    id={item?.id}
                    disabled={fieldType !== ""}
                    onClick={() => handleEdit(item?.id, item?.field_name, item?.field_display_name)}
                >
                    <img src="/images/common/pencilEdit.svg" alt="pencilEdit" />
                </Button>
            )}
        </>
    );
};
export default InputField;
