import { ClickAwayListener, Box } from "@mui/material";
import { Popover, Popper } from "@mui/material";
import Button from "../../components/Button";
import Modal from "../../components/UI/Modal/Modal";
import { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import _ from "lodash";
import Swal from "sweetalert2";
import Loader from "../../components/Loader/index.jsx";
const CreateEditStatusName = (props) => {
    //props value from parent component
    const {
        setEditAnchorEl,
        className,
        permissionInitialdata,
        currentMode,
        StatusList,
        createStatusType,
        createDisplayOrder,
        setStatusList,
        handleDivClick,
        workflowData,
        editAnchorEl,
    } = props;

    // new obj for permission need to push when create new status on tree
    const getDefaultPermission = {
        matching_flag: workflowData?.org_3way,
        parking_flag: workflowData?.org_parking,
        field_list: permissionInitialdata?.field_list,
    };
    const UserinitialObj = {
        id: 0,
        round_robin: false,
        role_flag: false,
        role_id: 0,
        rule_flag: false,
        rule_id: 0,
        user_flag: false,
        rule_user: [],
        user_list: [],
        seq: 1,
    };
    //initial object while create new status
    const initialObj = {
        disp_order: createDisplayOrder,
        id: 0,
        is_default: false,
        master_refid: 1,
        outside_id: uuidv4(),
        inside_id: uuidv4(),
        status_color: "#F5A622",
        notification: {
            email_bcc: [],
            email_body: "",
            email_cc: [],
            email_flag: false,
            email_subject: "",
            push_flag: false,
            push_message: "",
            push_title: "",
        },
        permission: getDefaultPermission,
        status_name: "",
        status_type: createStatusType,
        user_set: createStatusType == 2 ? [UserinitialObj] : [],
    };

    const [ifErrorOccurred, setIfErrorOccurred] = useState(false);
    // sort the  objects
    useEffect(() => {
        if (getDefaultPermission?.field_list !== undefined) {
            // Insert the new object at the specified index
            StatusList.splice(createDisplayOrder, 0, initialObj);
            // Update the disp_order values
            StatusList.forEach((item, index) => {
                item.disp_order = index + 1;
            });
            // Sort the array based on disp_order
            const sortedArray = _.sortBy(StatusList, "disp_order");
            setStatusList(sortedArray);
        }
    }, [permissionInitialdata]);
    const [inputValue, setInputValue] = useState("");
    //close the popper by icon close button
    const handleClose = () => {
        setEditAnchorEl(false);
        const removeObj = StatusList?.filter((ele) => ele?.disp_order !== createDisplayOrder + 1);
        // Update the disp_order values
        removeObj.forEach((item, index) => {
            item.disp_order = index + 1;
        });
        setStatusList(removeObj);
    };
    // for input field to create
    const handleChange = (e) => {
        setInputValue(e.target.value);
    };
    const [errormsg, setErrormsg] = useState("");
    const isDuplicate = StatusList.some((ele) => ele?.status_name?.toLowerCase() === inputValue?.toLowerCase());
    const isEmpty = isDuplicate && inputValue == "";
    const findLength = inputValue?.length > 30;
    //validation checker whilw try to create the new status
    const handleAddStatusName = () => {
        setErrormsg(
            isEmpty
                ? "Status Name cannot be Empty"
                : findLength
                ? "Status Name should be minimum 3 to maximum 30 characters"
                : isDuplicate
                ? "Status Name cannot be same"
                : "Cannot Create the New Status,Please Close and try again"
        );
        const newIndex = _.findIndex(StatusList, (ele) => {
            return ele?.id === 0 && createDisplayOrder + 1 === ele?.disp_order;
        });
        if (!isDuplicate && !isEmpty && !findLength && StatusList[newIndex]?.permission?.field_list !== undefined) {
            if (newIndex !== -1) {
                StatusList[newIndex].status_name = inputValue;
                setEditAnchorEl(false);
                // Now 'newIndex' contains the index of the newly created object
                handleDivClick(newIndex);
            }
        } else {
            setIfErrorOccurred(true);
        }
    };
    return (
        <Modal cartClose={handleClose} center="center" className={`${className}`}>
            <div
                className={`flex flex-col relative  min-w-[460px] xl:ml-[450px] md:mx-auto bg-${currentMode}-card text-${currentMode}-copy border-${currentMode}-700 px-[30px] pb-[30px] rounded-[10px] ${
                    currentMode === "dark" ? "" : "shadow-[0px_4px_20px_rgba(238,238,238,0.5)]"
                }`}
            >
                {permissionInitialdata?.field_list?.length > 0 ? (
                    <>
                        <Button tabIndex="4" className="cursor-pointer right-[0] px-[20px] py-[20px]  absolute" onClick={handleClose}>
                            <img src="/images/common/closeBlk.svg" alt="closeBlk" className="convertWhite" />
                        </Button>
                        <div className={`flex items-center py-[16px] mt-[30px] mx-[-30px] justify-between px-[30px] `}>
                            <span className="block">
                                <span className="text-red-500">*</span>{` Enter your ${createStatusType==2 ? "Conditional" :""} Status Name`}
                            </span>
                        </div>
                        <input
                            autoFocus
                            type="text"
                            maxLength={31}
                            onChange={(e) => handleChange(e)}
                            value={inputValue}
                            class="border-[rgba(85,85,85,0.5)] max-w-[350px] bg-transparent px-[20px] py-1 border-[1px] rounded-[5px] "
                        />
                        {ifErrorOccurred && <p className="text-light-50 block text-xs">{errormsg}</p>}
                        <Button
                            tabIndex={0}
                            className="bg-light-20 text-white text-base rounded-[8px] mt-5 mb-3 py-2 px-7 max-w-[350px]"
                            onClick={handleAddStatusName}
                            title={"Save"}
                            disabled={false}
                        >
                            {"Create"}
                        </Button>
                    </>
                ) : (
                    <Loader className="h-[calc(100vh_-5rem)]" />
                )}
            </div>
        </Modal>
    );
};
export default CreateEditStatusName;
