import { combineReducers } from "redux";
import {
    changepassword,
    ExpiryLinkState,
    Forgoutpasswordreducer,
    login,
    logout,
    RecentActivity,
    registration,
    ResendEmailLink,
    setPassword,
    tokenAuth,
    TransferOwnerReducer,
    tempMailValidate,
    AccountDeleteRedux,
} from "./auth";
import { signUpCountryList, country } from "./country";
import {
    organisationAdd,
    organisationCount,
    organisationEdit,
    organisationList,
    organisationView,
    organisationStatusUpdate,
    organisationDomainDetails,
    organisationListDropDown,
    sapFinancialList,
} from "./organisation";
import {
    userAdd,
    userCount,
    userEdit,
    userList,
    userStatusUpdate,
    userView,
    userRoleDelete,
    userActivityState,
    userListDropDown,
    userListDropDownAllocation,
} from "./user";
import {
    addOrgToggleReducer,
    addUserToggleReducer,
    cartToggleReducer,
    checkFilterReducer,
    setDroppedReducer,
    storeInvCalReducer,
    storeTablerReportReducer,
} from "./filter";
import { profileView, Profileedit } from "./profile";
import { BackupDataRedux, RemoveDemoDataState, settingReducer, settingUpdateReducer, settingValueReducer } from "./setting";
import {
    AddRolePermissionState,
    addUpdateuserRolebyUser,
    DisplayRolePermissionState,
    ListOrgRoleByUser,
    roleidReducer,
    RoleListAllocationDisplay,
    RolePermissionState,
    userRolebyOrg,
    ViewRolePermissionState,
    ViewuserRolebyOrg,
} from "./role";
import { emailConfigAdd, emailConfigList, emailConfigStatus, emailConfigUpdate } from "./emailconfig";
import { folderConfigAdd, folderConfigList, folderConfigStatus, folderConfigUpdate } from "./folderconfig";
import {
    invoiceCount,
    InvoiceListItem,
    GetInvoiceViewItems,
    UpdateInvoice,
    InvoiceApprovedList,
    InvoiceRejectedList,
    InvoiceFlag,
    InvoiceCropedImg,
    InvoiceComparelist,
    invoiceVendorList,
    invoicelabelList,
    invoicelabelAdd,
    GetInvoiceIdConfig,
    InvoiceIdAddConfig,
    addInvoiceAllocation,
    getInvoiceAllocationList,
    PostInvApproveState,
    InvoiceReassignReducer,
    generalLedgerList,
    getDocumentTypeList,
    costCentreList,
    poNumberList,
    sapVendorList,
    TransferInvoiceRedux,
    TransferInvoiceRejectState,
    InvoiceDocumentReducer,
    threeWayMatchingByErp,
    addServiceLineItems,
    taxCodeWithPercentageList,
    getPoTypeReducer,
    getDocumemtTypeReducer,
    postDocumemtTypeReducer,
    getInvCalcViewReducer,
    getInvCalcDetailViewReducer,
} from "./invoice";
// import { serverconfigAdd, } from "../actions/serverconfig";
import { serverConfigAdd, serverConfigTest, serverConfigView } from "./serverconfig";
import {
    DashboardState,
    DashboardVendorStatus,
    DashboardDateStatus,
    DashboardVendorList,
    DashboardTaskList,
    OnTimeProcessReducer,
    ValidTimeReducer,
    getTimeForOnTimeProcessing,
} from "./dashboard";

import { uploadInvoice } from "./uploadinvoice";
import { GlobalSearchRedu } from "./search";
import {
    AddAccrualAcc,
    addAccrualPosting,
    GetAccrualAcc,
    GetAccrualPosting,
    GetAccrualPostingDate,
    GetMonthEndClosingReducer,
    GetValidatePostReducer,
    PostMonthEndClosingReducer,
} from "./accrual-accounting";
import {
    GetSummaryAgingItem,
    reportsAgeingCount,
    reportsCount,
    ReportsFavAdd,
    ReportsFavGet,
    ReportsListAccrualItem,
    ReportsListAgeingItem,
    ReportsListAgeingSummaryItem,
    ReportsListItem,
    ReportsTaskList,
    reportsTaskCount,
    ReportsTaskUserList,
    getLicenseSummary,
    getLicenseUsage,
    ReportsLicenseList,
    reportsLicenseCount,
    sendBillingEmail,
} from "./report";
import {
    ActivateDeactivateRuleBased,
    AddRulebased,
    GetViewRuleList,
    FieldRuleBesed,
    GetRulebasedList,
    OperatorRuleBased,
    RuleView,
} from "../reducers/rule-based";

import {
    priorityConfigList,
    taskNumberConfigList,
    addTaskIdConfig,
    addPriorityConfig,
    myTaskList,
    myTaskUserList,
    addMyTaskCreateToDo,
    getTask,
    addCommentsTask,
    postCompleteTask,
    getInvoiceTaskList,
    getViewTaskAttachment,
} from "./my-task";

import { GetNotificationListReducer, GetPushNotificationReducer, UpdateNotificationReducer } from "./notification";

import {
    emailConfigAddUpdate,
    emailTemplateList,
    emailTemplatePlaceholderList,
    emailTemplateStatusUpdate,
    viewEmailTemplate,
    getEmailTemplateList,
} from "./emailtask";
import { GetGeneralLedgerSync, GetGLAccountSync, GetCostCenterAccountSync, GetIOAccountSync, GetTaxMasterSync } from "./account-sync.js";
import { inboxSettingsDelete, inboxSettingsList, inboxSettingStatus } from "./inbox-setting";
import {
    getInboxEmailBodyViewReducer,
    getInboxEmailChildPrevReducer,
    getInboxEmailMarkasReducer,
    getInboxEmailPrevReducer,
    getInboxFolderReducer,
    sendMsMail,
    deleteMsMail,
    getAttachmentPreview,
    downloadAttachment,
    SocketConnection,
} from "./email-inbox";
import { GetMicrosoftContactListRedu } from "../reducers/contact";
import { GetFreeTrailReducer } from "./free-trail";
import { reducerChatbot } from "./chatbot";
import { layoutReducer, layoutCountryList } from "./layout";
import {
    CustomreportsConfigDeleteReducer,
    CustomReportsConfigListReducer,
    CustomReportsListReducer,
    CustomReportsPublishUnpublishReducer,
    getAddResponseReducer,
    getColumnReducer,
    getFilterDropdownReducer,
    getLabelInfoReducer,
    getPreviewDataReducer,
    getReportDropdownReducer,
    getTablerViewReducer,
} from "./output-management";
import {
    getWorkFlowData,
    getWorkFlowPermissionByOrg,
    postWorkFlowByOrg,
    workFlowStatusDelete,
    getWorkflowByInvoiceId,
    getWorkflowFilter,
    postWorkFlowStatusChange,
} from "./workflow-automation";
import {
    GetAdvanceDetailsforCreate,
    GetAdvanceHistory,
    documentFromS3ForAdvance,
    addupdateComments,
    getAdvanceStatusList,
    getAdvanceRequestView,
    advanceRequestListReduc,
    addUpdateAdvanceRequest,
    getAdvanceRequestPoAndVendorList,
    getUserForCommentsList,
    getExchangeRateByDate,
    getGLIndicatorList,
    updateStatusForAdvance,
    proformaUpdateInvoiceView, GetListAdvanceReqForPoData
} from "./advance-request";
import {
    AddUpdateExchangeRateReducer,
    CurrencyExchangeRateReducer,
    DeleteExchangeRateReducer,
    HistoryExchangeRateReducer,
    ExchangeRateListReducer,
    CurrencyExchangeRateByOrgReducer,
    UploadExchangeRateReducer,
} from "./exchange-rate";
import { getPoRetentionRelease, getRetentionReleaseView, postRetentionRelease } from "./retention-release.js";
import {
    AddUpdateMiscReducer,
    MiscListReducer,
    MiscPoListReducer,
    MiscVendorListReducer,
    MiscWBSListReducer,
    TdsReducer,
    ViewMiscReducer,
    WbsSyncReducer,
} from "./miscellaneous-request";
import { getAdvanceReport } from "./report.js";
// COMBINED REDUCERS
const reducers = {
    registerState: registration,
    loginState: login,
    changepasswordState: changepassword,
    countryState: country,
    setPasswordState: setPassword,
    profileState: profileView,
    profileEditState: Profileedit,
    cartToggleState: cartToggleReducer,
    addToggleState: addUserToggleReducer,
    addorgToggleState: addOrgToggleReducer,
    userListState: userList,
    userAddState: userAdd,
    userViewState: userView,
    userEditState: userEdit,
    userCountState: userCount,
    organisationAddState: organisationAdd,
    organisationListState: organisationList,
    organisationViewState: organisationView,
    organisationEditState: organisationEdit,
    organisationCountState: organisationCount,
    settingState: settingReducer,
    settingUpdateState: settingUpdateReducer,
    settingValueState: settingValueReducer,
    InvoiceListState: InvoiceListItem,
    InvoiceCountState: invoiceCount,
    roleState: roleidReducer,
    emailConfigState: emailConfigList,
    emailAddConfigState: emailConfigAdd,
    emailConfigStatusState: emailConfigStatus,
    folderConfigState: folderConfigList,
    folderAddConfigState: folderConfigAdd,
    folderConfigStatusState: folderConfigStatus,
    getInvoiceItemsState: GetInvoiceViewItems,
    updateInvoiceState: UpdateInvoice,
    InvoiceApprovedState: InvoiceApprovedList,
    InvoiceRejectedState: InvoiceRejectedList,
    LogoutRequestState: logout,
    serverAddConfigState: serverConfigAdd,
    serverViewConfigState: serverConfigView,
    forgoutpasstate: Forgoutpasswordreducer,
    DasboardStatus: DashboardState,
    DashboardvendorState: DashboardVendorStatus,
    DashboarChartState: DashboardDateStatus,
    DashboardVendorListState: DashboardVendorList,
    InvoiceFlagState: InvoiceFlag,
    UploadInvoiceState: uploadInvoice,
    searchState: GlobalSearchRedu,
    expirylinkstate: ExpiryLinkState,
    InvoiceCropedImgState: InvoiceCropedImg,
    OrgStatusUpdateState: organisationStatusUpdate,
    OrgDetailsOnDomainState: organisationDomainDetails,
    ResendEmailLinkState: ResendEmailLink,
    ActivityHistoryState: RecentActivity,
    InvoiceCompareState: InvoiceComparelist,
    RoleByOrgState: userRolebyOrg,
    ViewUserByRoleOrgState: ViewuserRolebyOrg,
    ListOrgRoleByUserState: ListOrgRoleByUser,
    UsersUpdateStatusState: userStatusUpdate,
    UserRoleDeleteState: userRoleDelete,
    AddUpdateUserRoleByUserState: addUpdateuserRolebyUser,
    ListorganisationDropDownState: organisationListDropDown,
    UserActivityListState: userActivityState,
    InvoiceVendorListState: invoiceVendorList,
    RolePermissionStateAction: RolePermissionState,
    AddRolePermissionStateAction: AddRolePermissionState,
    ViewRolePermissionStateAction: ViewRolePermissionState,
    InvoiceLabelLayoutListState: invoicelabelList,
    InvoiceLabelLayoutAddState: invoicelabelAdd,
    DisplayRolePermissionStateAction: DisplayRolePermissionState,
    userListDropDownState: userListDropDown,
    ChangeOwnerShipState: TransferOwnerReducer,
    RemoveDemoDatasState: RemoveDemoDataState,
    GetInvIdConfigState: GetInvoiceIdConfig,
    AddInvIdConfigState: InvoiceIdAddConfig,
    AddAccrualState: AddAccrualAcc,
    GetAccrualState: GetAccrualAcc,
    CheckTokenAuthState: tokenAuth,
    GetReportListState: ReportsListItem,
    GetreportCountState: reportsCount,
    GetreportAgeingListState: ReportsListAgeingItem,
    GetreportAgeingSummaryState: ReportsListAgeingSummaryItem,
    GetReportAccrualListState: ReportsListAccrualItem,
    GetReportFavListState: ReportsFavGet,
    AddReportFavState: ReportsFavAdd,
    GetRuleListState: GetRulebasedList,
    AddUpdateRuleState: AddRulebased,
    addInvoiceAllocationState: addInvoiceAllocation,
    GetViewRuleListState: GetViewRuleList,
    getReportAgeingCountState: reportsAgeingCount,
    GetAccrualPostingState: GetAccrualPosting,
    addAccrualPostingState: addAccrualPosting,
    FieldRuleState: FieldRuleBesed,
    OperatorsRuleState: OperatorRuleBased,
    RuleViewState: RuleView,
    ActivateDeactivateState: ActivateDeactivateRuleBased,
    getInvoiceAllocationListState: getInvoiceAllocationList,
    GetAccrualPostingDateState: GetAccrualPostingDate,
    GetSummaryAgeingState: GetSummaryAgingItem,
    serverConfigTestState: serverConfigTest,
    emailConfigUpdateState: emailConfigUpdate,
    folderConfigUpdateState: folderConfigUpdate,
    userAllocationDropDownState: userListDropDownAllocation,
    RoleListAllocationState: RoleListAllocationDisplay,
    SendToApproverState: PostInvApproveState,
    InvoiceReassignState: InvoiceReassignReducer,
    generalLedgerListState: generalLedgerList,
    getDocumentTypeListState: getDocumentTypeList,
    costCentreListState: costCentreList,
    getTaskIdState: taskNumberConfigList,
    getPriorityConfigState: priorityConfigList,
    postTaskIdConfigState: addTaskIdConfig,
    postPriorityConfigState: addPriorityConfig,
    getpushNotificationState: GetPushNotificationReducer,
    getNotificationListState: GetNotificationListReducer,
    updateNotificationState: UpdateNotificationReducer,
    emailTemplateListState: emailTemplateList,
    viewEmailTemplateState: viewEmailTemplate,
    emailTemplatePlaceholderListState: emailTemplatePlaceholderList,
    emailConfigAddUpdateState: emailConfigAddUpdate,
    emailTemplateStatusUpdateState: emailTemplateStatusUpdate,
    myTaskUserListState: myTaskUserList,
    addMyTaskCreateToDoState: addMyTaskCreateToDo,
    myTaskListState: myTaskList,
    getTaskState: getTask,
    postTaskCommentState: addCommentsTask,
    postCompleteTaskState: postCompleteTask,
    GetGeneralLedgerSyncState: GetGeneralLedgerSync,
    GetGLAccountSyncState: GetGLAccountSync,
    GetCostCenterAccountSyncState: GetCostCenterAccountSync,
    getInvoiceTaskListState: getInvoiceTaskList,
    getEmailTemplateListState: getEmailTemplateList,
    inboxSettingListState: inboxSettingsList,
    inboxSettingsStatusState: inboxSettingStatus,
    inboxSettingsDeleteState: inboxSettingsDelete,
    inboxFolderState: getInboxFolderReducer,
    inboxPrevState: getInboxEmailPrevReducer,
    inboxchildPrevState: getInboxEmailChildPrevReducer,
    inboxbodyViewState: getInboxEmailBodyViewReducer,
    GetMicrosoftContactListReduState: GetMicrosoftContactListRedu,
    emailMarksState: getInboxEmailMarkasReducer,
    sendMsMailState: sendMsMail,
    deleteMsMailState: deleteMsMail,
    getAttachmentPreviewState: getAttachmentPreview,
    downloadAttachmentState: downloadAttachment,
    getPoNumberListState: poNumberList,
    getSapVendorListState: sapVendorList,
    getSocketConnectState: SocketConnection,
    tempMailValidateState: tempMailValidate,
    BackupDataState: BackupDataRedux,
    DashboardTaskListState: DashboardTaskList,
    TransferInvState: TransferInvoiceRedux,
    AccounDeleteState: AccountDeleteRedux,
    sapFinancialListState: sapFinancialList,
    getFreeTrialReducerState: GetFreeTrailReducer,
    ReportsTaskListState: ReportsTaskList,
    reportsTaskCountState: reportsTaskCount,
    ReportsTaskUserListState: ReportsTaskUserList,
    getLicenseSummaryState: getLicenseSummary,
    getLicenseUsageState: getLicenseUsage,
    ReportsLicenseListState: ReportsLicenseList,
    reportsLicenseCountState: reportsLicenseCount,
    signUpCountryListArrState: signUpCountryList,
    getViewTaskAttachmentState: getViewTaskAttachment,
    getRejectInvoiceListState: TransferInvoiceRejectState,
    getInvoiceDocumentState: InvoiceDocumentReducer,
    sendBillingEmailState: sendBillingEmail,
    getMonthEndClosingState: GetMonthEndClosingReducer,
    postMonthEndClosingState: PostMonthEndClosingReducer,
    threeWayMatchingByErpState: threeWayMatchingByErp,
    chatbotState: reducerChatbot,
    layoutSate: layoutReducer,
    GetAccrualValidateState: GetValidatePostReducer,
    layoutCountryListState: layoutCountryList,
    OnTimeProcessState: OnTimeProcessReducer,
    ValidTimeProcessState: ValidTimeReducer,
    GetIOAccountSyncState: GetIOAccountSync,
    getColumnState: getColumnReducer,
    droppedState: setDroppedReducer,
    addServiceLineItemsState: addServiceLineItems,
    GetCustomLabelInfoState: getLabelInfoReducer,
    customReportsListState: CustomReportsListReducer,
    customReportsConfigListState: CustomReportsConfigListReducer,
    customReportsDeleteState: CustomreportsConfigDeleteReducer,
    customReportsPublishUnpublishState: CustomReportsPublishUnpublishReducer,
    getPreviewState: getPreviewDataReducer,
    getFilterDropdownSTate: getFilterDropdownReducer,
    getRespondAddTableState: getAddResponseReducer,
    storeReportState: storeTablerReportReducer,
    getTablerViewState: getTablerViewReducer,
    checkFilterState: checkFilterReducer,
    GetTaxMasterSyncState: GetTaxMasterSync,
    taxCodeWithPercentageListState: taxCodeWithPercentageList,
    getPoTypeState: getPoTypeReducer,
    getDropdownReportState: getReportDropdownReducer,
    getTimeForOnTimeProcessingState: getTimeForOnTimeProcessing,
    getWorkFlowDataState: getWorkFlowData,
    getWorkFlowPermissionByOrgState: getWorkFlowPermissionByOrg,
    postWorkFlowByOrgState: postWorkFlowByOrg,
    workFlowStatusDeleteState: workFlowStatusDelete,
    getWorkflowByInvoiceIdState: getWorkflowByInvoiceId,
    getWorkflowFilterState: getWorkflowFilter,
    postWorkFlowStatusChangeState: postWorkFlowStatusChange,
    getDocumentTypeState: getDocumemtTypeReducer,
    postdocumentTypeState: postDocumemtTypeReducer,
    GetAdvanceDetailsforCreateState: GetAdvanceDetailsforCreate,
    GetAdvanceHistoryState: GetAdvanceHistory,
    documentFromS3ForAdvanceState: documentFromS3ForAdvance,
    addUpdateCommentsState: addupdateComments,
    addUpdateExchangeRateState: AddUpdateExchangeRateReducer,
    currencyListState: CurrencyExchangeRateReducer,
    deleteExchangeRateState: DeleteExchangeRateReducer,
    historyExchangeRateState: HistoryExchangeRateReducer,
    ExchangeRateListState: ExchangeRateListReducer,
    getAdvanceStatusListState: getAdvanceStatusList,
    getAdvanceRequestViewState: getAdvanceRequestView,
    advanceRequestListState: advanceRequestListReduc,
    getPoRetentionReleaseState: getPoRetentionRelease,
    getInvCaclviewState: getInvCalcViewReducer,
    getInvCalcDetailState: getInvCalcDetailViewReducer,
    getRetentionReleaseViewState: getRetentionReleaseView,
    postRetentionReleaseState: postRetentionRelease,
    addUpdateAdvanceRequestState: addUpdateAdvanceRequest,
    currencydropdownByorgState: CurrencyExchangeRateByOrgReducer,
    AddupdateMiscState: AddUpdateMiscReducer,
    ViewMiscState: ViewMiscReducer,
    MiscListState: MiscListReducer,
    UploadExchangeRateState: UploadExchangeRateReducer,
    getAdvanceRequestPoAndVendorListState: getAdvanceRequestPoAndVendorList,
    getInvCalcValState: storeInvCalReducer,
    getUserForCommentsListState: getUserForCommentsList,
    getExchangeRateByDateState: getExchangeRateByDate,
    getGLIndicatorListState: getGLIndicatorList,
    updateStatusForAdvanceState: updateStatusForAdvance,
    proformaUpdateInvoiceViewState: proformaUpdateInvoiceView,
    getAdvanceReportListState: getAdvanceReport,
    getMiscPoListState: MiscPoListReducer,
    getMiscVendorListState: MiscVendorListReducer,
    getMiscWbsListState: MiscWBSListReducer,
    getWavSyncState: WbsSyncReducer,
    TdsActionState: TdsReducer,
    GetListAdvanceReqForPoDataState: GetListAdvanceReqForPoData
};

// COMBINED REDUCERS

export default combineReducers(reducers);
