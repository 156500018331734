import { useContext, useRef } from "react";
import { ThemeContext } from "../../../../Hooks/Themes/Themeprovider";
import Button from "../../../../components/Button";
import Card from "../../../../components/UI/Card";
import CustomPiechart from "./CustomPieChart";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import CustomBarchartPreview from "./CustomBarChart";
import CustomLinechart from "./CustomLineChart";
import { useSelector } from "react-redux";
import { async } from "@firebase/util";
import Loader from "../../../../components/Loader";

const CustomChartsView = (props) => {
    const ChartType = props?.ChartType;
    const { currentMode } = useContext(ThemeContext);
    const { data, fetching } = useSelector((state) => state?.customReportsListState);
    const datalist = data?.[0]?.data_info;

    const chartContainerRef = useRef(null);
    const generatePDF = async (divId, fileName) => {
        try {
            // Use html2canvas to capture the HTML content as an image
            const canvas = await html2canvas(document.getElementById(divId));

            // Create a new jsPDF instance
            const pdf = new jsPDF();
            // Add the captured image to the PDF
            pdf.addImage(canvas.toDataURL("image/png"), "PNG", 0, 0, 250, 190);

            // Save the PDF
            pdf.save(`${fileName}.pdf`);

            // Return a success message or any data you may need
            return { success: true, message: "PDF downloaded successfully." };
        } catch (error) {
            // Handle any errors that occurred during PDF generation
            console.error("Error generating PDF:", error);

            // Return an error message or status
            return { success: false, message: "Error generating PDF." };
        }
    };
    const downloadPDF = async () => {
        await generatePDF("ChartView", "piechart");
    };
    const tableRef = useRef(null);

    const downloadPdf = () => {
        if (!tableRef.current) return;

        // Get the table element
        const table = tableRef.current;

        // Convert the table to an image using html2canvas
        html2canvas(table).then((canvas) => {
            const imgData = canvas.toDataURL("image/png");
            const pdf = new jsPDF();
            const imgWidth = 210;
            const imgHeight = (canvas.height * imgWidth) / canvas.width;

            // Add the image to the PDF
            pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight);
            pdf.save(`${props?.reportName}.pdf`);
        });
    };

    const chartData = [
        {
            "Net Amount": 100,
            "Org. Name": "Abcd",
        },
        {
            "Net Amount": 200,
            "Org. Name": "Abc",
        },
    ];
    const pieData = [
        {
            "Org. Name": "Abc",
            "Net Amount": 200,
        },
        {
            "Org. Name": "Abcd",
            "Net Amount": 100,
        },
    ];
    function convertValuesToInt(arr, keyName) {
        for (let i = 0; i < arr?.length; i++) {
            if (arr[i][keyName]) {
                arr[i][keyName] = parseInt(arr[i][keyName]);
            }
        }
    }

    convertValuesToInt(datalist, "total_net");

    return (
        <>
            <Card className="mb-[3rem] h-[calc(100vh_-_16rem)]">
                {fetching ? (
                    <Loader className="h-[calc(100vh_-_26rem)] absolute left-[50%] translate-x-[-50%]" />
                ) : (
                    <>
                        {datalist?.length !== 0 && datalist !== undefined && datalist !== null ? (
                            <div>
                                <div className="flex justify-end px-[30px] py-[25px]">
                                    <div className="flex items-center space-x-2">
                                        <Button
                                            className={`border-[1px] flex items-center border-${currentMode}-840 py-[8px] px-[15px] rounded-[8px]`}
                                            onClick={() => downloadPdf()}>
                                            <img className="mr-2 w-[16px] h-[16px] convertWhite" src="/images/common/download.svg" alt="download" />
                                            Download
                                        </Button>
                                    </div>
                                </div>
                                <div ref={tableRef}>
                                    <br />
                                    <div id="ChartView" className="mt-7">
                                        {ChartType == 1 && <CustomPiechart data={datalist} />}
                                        {ChartType == 2 && <CustomLinechart data={datalist} />}
                                        {ChartType == 3 && <CustomBarchartPreview data={datalist} />}
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div className="flex h-full justify-center items-center my-10">
                                <div>
                                    <img src="/images/common/delivery.png" alt="delivery" />
                                    <h4 className="font-interSb text-center mt-3">No data found</h4>
                                </div>
                            </div>
                        )}
                    </>
                )}
            </Card>
        </>
    );
};

export default CustomChartsView;
