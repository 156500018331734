import Joyride, { EVENTS, ACTIONS, STATUS } from "react-joyride";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useContext, useEffect, useState } from "react";
import { ThemeContext } from "../../Hooks/Themes/Themeprovider";
let ProdTour = () => {};
const UseTour = (tourConfig) => {
    const [isTourOpen, setIsTourOpen] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const [currentId, setCurrentId] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const { currentMode, toggleMode } = useContext(ThemeContext);
    const params = new URLSearchParams(window.location.search);
    let tourId = params.get("tourId");
    useEffect(() => {
        // Perform API calls
        const fetchData = async () => {
            try {
                // Start loading
                setIsLoading(true);
            } finally {
                setTimeout(() => {
                    // End loading
                    setIsLoading(false);
                }, 1800);
            }
        };
        fetchData();
    }, [location.pathname]);
    const openTour = () => {
        setIsTourOpen(true);
    };
    // Read the currentId from the URL on component mount
    const urlSearchParams = new URLSearchParams(window.location.search);
    const type = urlSearchParams.get("type");
    const [spotWidth, setSpotWidth] = useState();
    useEffect(() => {
        const idFromUrl = parseInt(urlSearchParams.get("tourId"), 10);
        if (!isNaN(idFromUrl)) {
            setCurrentId(idFromUrl);
        }
    }, []);
    const handleCallback = (data) => {
        const { action, index, lifecycle, type } = data;
        if (action == "close") {
            // Need to set our running state to false, so we can restart if we click start again.
            setCurrentId(null);
            navigate(location.pathname);
            setIsTourOpen(false);
        } else if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
            // Update state to advance the tour
            const getId = index + (action === ACTIONS.PREV ? -1 : 1);
            setCurrentId(getId);
            // Update the URL with the new currentId
            const obj = tourConfig[getId];
            if (obj && obj.to) {
                navigate(`${obj.to}?type=tour&tourId=${getId}`, { replace: true });
            } else {
                // Update the URL with the new currentId
                const urlSearchParams = new URLSearchParams(window.location.search);
                urlSearchParams.set("tourId", getId.toString());
                window.history.replaceState({}, "", `${window.location.pathname}?${urlSearchParams}`);
            }
        } else if (action == "start" && index == 10 && lifecycle == "init" && type == "tour:start") {
            // Need to set our running state to false, so we can restart if we click start again.
            setCurrentId(null);
            navigate(location.pathname);
            setIsTourOpen(false);
        } else if (action == "update") {
            setSpotWidth(document?.querySelector(".react-joyride__spotlight")?.style?.width);
        }
    };
    if (isLoading) {
        ProdTour = () => {};
    } else if (!isLoading) {
        ProdTour = () => (
            <Joyride
                steps={tourConfig}
                continuous={true}
                disableCloseOnEsc={true}
                disableOverlayClose={true}
                disableScrolling={true}
                run={isTourOpen}
                showSpotlight={true}
                autoStart={true}
                stepIndex={+tourId || 0}
                styles={{
                    options: {
                        spotlightShadow: "#55555533",
                        position: "absolute",
                        zIndex: 1000,
                        top: "5px",
                        right: "5px",
                        backgroundColor: currentMode == "dark" ? "rgb(31 31 31)" : "#ffffff",
                        primaryColor: "#00B399",
                        overlayColor: currentMode == "dark" ? "#121212" : "#D9D9D9",
                        opacity: "0.4",
                        height: "230px",
                        width: "360px",
                        arrowColor: currentMode == "dark" ? "rgb(31 31 31)" : "#ffffff",
                    },
                    tooltip: {
                        color: currentMode == "dark" ? "white" : "black",
                        className: "custom-tour-tooltip",
                    },
                    beacon: {
                        visibility: type ? "visible" : "",
                        left: currentId == 4 && "-125px",
                        top: currentId == 4 && "20px",
                        className: "custom-beacon-tour",
                    },
                    spotlight: {
                        // Add conditional logic for top
                        marginTop: currentId > 5 && "10px",
                        left: "114px !important",
                        marginLeft: "10px",
                        maxWidth: `calc(${spotWidth} - 20px)`,
                        maxHeight: currentId > 5 ? "90px" : "133px",
                        padding: "30px",
                        boxShadow: currentMode !== "dark" ? "0 0 10px 2px rgba(243, 232, 255, 1)" : "", // Adjust the shadow color and other properties
                    },
                    buttonBack: {
                        duration: "500ms",
                        border: "1px solid #8C9097",
                        padding: "3px 15px",
                        borderRadius: "4px",
                        color: currentMode == "dark" ? "white" : "#000",
                        width: "70px",
                        height: "30px",
                        position: "absolute",
                        left: "20px",
                        bottom: "30px",
                    },
                    buttonNext: {
                        backgroundColor: "rgba(0, 179, 153, 1)", // Assuming 'bg-light-20' is a light background color with 20% opacity
                        padding: "3px 15px",
                        color: "white",
                        borderRadius: "4px",
                        border: "1px solid transparent",
                        width: "70px",
                        height: "30px",
                        position: "absolute",
                        left: "100px",
                        bottom: "30px",
                    },
                    buttonClose: {
                        height: "12px",
                        width: "12px",
                        color: currentMode !== "dark" ? "black" : "white", // Set the text color of the close button
                    },
                }}
                locale={{
                    back: "Back",
                    close: "Close",
                    last: "Finish",
                    next: "Next",
                }}
                callback={handleCallback}
            />
        );
    }

    return {
        ProdTour,
        openTour,
        currentId,
        spotWidth,
    };
};

export default UseTour;
