import axios from "axios";
import configMyiQ from "../../configuration";
import {
    GLOBAL_SEARCH_FAILURE,
    GLOBAL_SEARCH_REQUEST,
    GLOBAL_SEARCH_SUCCESS,
} from "../types/types";
import authHeader from "./auth-header";
import { checkApiStatus } from "../../utils/helpers";
const API_URL = process.env?.REACT_APP_API_URL;

export const GlobalSearchAction = (data) => async (dispatch) => {
    try {
        dispatch({ type: GLOBAL_SEARCH_REQUEST });

        axios
            .post(`${API_URL}GlobalSearch/GlobalSearch`, data, {
                headers: authHeader(),
            })
            .then((response) => {
                dispatch({
                    type: GLOBAL_SEARCH_SUCCESS,
                    payload: response?.data?.datalist,
                });
            })
            .catch((ex) => {
                if (ex?.response?.status == 401) {
                    checkApiStatus();
                }
                dispatch({
                    type: GLOBAL_SEARCH_FAILURE,
                    payload: ex,
                });
            });
    } catch (error) {
        dispatch({
            type: GLOBAL_SEARCH_FAILURE,
        });
    }
};
