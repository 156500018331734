import Cookies from "js-cookie";
export default function authHeader() {
    const userToken = Cookies.get("jwt");
    const user = JSON.parse(localStorage.getItem("user"));
    if (user && userToken) {
        return {
            authorization: "Bearer " + userToken,
            access_token: userToken,
        };
    } else {
        return {};
    }
}
