import { useContext, useState } from "react";
import { useEffect } from "react";
import Input from "../../../components/Input";
import Modal from "../../../components/UI/Modal/Modal";
import { ThemeContext } from "../../../Hooks/Themes/Themeprovider";
import Button from "../../../components/Button";
import { useSelector, useDispatch } from "react-redux";
import { serverConfigTest } from "../../../redux/actions/serverconfig";
import {
    ADD_SERVER_CONFIG_RESET,
    TEST_SERVER_CONFIG_RESET,
    VIEW_SERVER_CONFIG_RESET,
} from "../../../redux/types/types";
let temp;
let array = [];
export const ApiTestingModal = (props) => {
    //var
    const dispatch = useDispatch();
    const { data, requestComplete, fetching, error } = useSelector(
        (state) => state?.serverConfigTestState
    );
    const [index, setIndex] = useState(0);
    const [sync, setSync] = useState(true);
    const [showMethodName, setShowMethodName] = useState("");
    const serverResponse = useSelector((state) => state?.serverViewConfigState);
    const advanceSettingData =
        serverResponse?.data?.[0]?.advanced_settings?.filter(
            (ele) => ele?.api_type == "GET"
        );
    const { currentMode, toggleMode } = useContext(ThemeContext);
    const methodName = advanceSettingData
        ?.filter((ele) => ele?.api_type == "GET")
        ?.map((item) => item?.url);
    const reqObj = props.reqObj;
    const parseUrl = reqObj?.reqUrl;
    reqObj.model = "get";
    //render on every requestcomplete
    useEffect(() => {
        if (
            requestComplete == false &&
            methodName?.length > index &&
            props?.isOpened
        ) {
            reqObj.model = `get`;
            // const endpoint = methodName?.pop();
            reqObj.reqUrl = parseUrl + methodName[index];
            dispatch(serverConfigTest(reqObj));
            temp = index + 1;
            setIndex(temp);
        } else if (requestComplete) {
            array?.push(
                // id: index,
                data?.status == true ? "Success!" : "Failed!"
            );
            dispatch({ type: TEST_SERVER_CONFIG_RESET });
        }
    }, [requestComplete, sync, props?.isOpened]);
    //close button function
    const handleClose = () => {
        props?.setTestedArray(array?.includes("Failed!"));
        props?.handleClose();
        dispatch({ type: TEST_SERVER_CONFIG_RESET });
        setIndex(0);
        array.length = 0;
    };

    return (
        <>
            <Modal
                upperLay={true}
                cartClose={props?.handleClose}
                center="center"
                className={`${props?.className}`}
            >
                <div
                    className={` xl:min-w-[521px] relative transition bg-${currentMode}-card border-[rgba(236,236,236,0.88)] ${
                        currentMode === "dark"
                            ? ""
                            : "shadow-[0px_4px_20px_rgba(238,238,238,0.5)]"
                    } rounded-[10px] duration-150 ease-out p-[30px]`}
                >
                    <div
                        className={`max-h-[658px] bg-${currentMode}-card mx-[-30px] z-[9] py-[20px] pl-[50px]`}
                    >
                        <span
                            className="cursor-pointer absolute right-[2rem] convertWhite top-[1.3rem]"
                            onClick={handleClose}
                        >
                            <img
                                src="/images/common/close-one.svg"
                                alt="close-one"
                            />
                        </span>
                        <div className="max-h-[511px]  overflow-y-auto">
                            {advanceSettingData?.map((item, inde) => {
                                return (
                                    <div className="pb-3">
                                        <div className="flex items-center pb-0.5">
                                            <p>
                                                {fetching &&
                                                inde == index &&
                                                array[inde] == undefined
                                                    ? "Starting test: "
                                                    : array[inde] !== undefined
                                                    ? "Completed: "
                                                    : "In Queue: "}
                                            </p>
                                            {"     "}
                                            <b
                                                className={`flex items-start ${
                                                    array[inde] !== undefined
                                                        ? currentMode == "dark"
                                                            ? `text-dark-40 pl-1  font-bold`
                                                            : `text-light-40 pl-1  font-bold`
                                                        : `text-light-20 pl-1 font-bold`
                                                }
                                               `}
                                            >
                                                {item?.description}{" "}
                                                {inde == index &&
                                                array[inde] == undefined ? (
                                                    <div className="dotAnimation small">
                                                        <span></span>
                                                        <span></span>
                                                        <span></span>
                                                    </div>
                                                ) : (
                                                    ""
                                                )}
                                            </b>
                                        </div>

                                        <b>
                                            <span
                                                className={
                                                    array[inde] == undefined
                                                        ? ""
                                                        : array[inde] ==
                                                          "Success!"
                                                        ? `text-light-440 font-bold`
                                                        : `text-light-1150 font-bold`
                                                }
                                            >
                                                {array[inde] == undefined
                                                    ? ""
                                                    : array[inde] == "Success!"
                                                    ? "Success!"
                                                    : "Failed!"}
                                            </span>
                                        </b>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    );
};
