import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Button from "../../components/Button";
import Input from "../../components/Input";
import Container from "../../components/UI/Container";
import UseForm from "../../Hooks/useForm";
import { validateResetPassword } from "../../Hooks/validate";
import { setPasswordRequest } from "../../redux/actions/auth";
import { SET_PASSWORD_RESET } from "../../redux/types/types";
import { useSelector } from "react-redux";
import MessageBox from "../../components/UI/Alert";
import SuccessMessageBox from "../../components/UI/Alert/sucess";
import { encryptId } from "../../utils/helpers";
import { expirylink } from "../../redux/actions/auth";
import { ErrorMessage } from "formik";
import Loader from "../../components/Loader";
import { Checkbox, ClickAwayListener, Popover, Popper } from "@mui/material";
import { logDOM } from "@testing-library/react";
const successMsg = "Your password has been created successfully.";
const errormsg = "Something went wrong please contact admin";
const Card = (props) => (
    <div className="w-[550px] h-[500px] bg-white px-[6.25rem] py-[5.125rem] shadow-[0px_4px_20px_rgba(238,238,238,0.501967)] rounded-[10px] border-[1px] border-[#dfe1e28f]">
        {props?.children}
    </div>
);

const ResetPassword = () => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const [isRevealPwd, setIsRevealPwd] = useState(false);
    const [isRevealPasswd, setIsRevealPasswd] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    //   const [emid, getQueryStringVal] = useState("");

    const [type, getQueryStringType] = useState("");

    const { handleSubmit, handleChange, values, errors, checkChange } = UseForm(
        submit,
        validateResetPassword
    );
    const [lengthCheck, setLengthCheck] = useState(false);
    const [lowercase, checkLowerCase] = useState(false);
    const [upperCase, checkUpperCase] = useState(false);
    const [splCase, checkSplCase] = useState(false);
    const [numCase, checkNumCase] = useState(false);

    function submit() {
        dispatch(
            setPasswordRequest({
                emid: emid,
                password: encryptId(values?.newpassword),
                type: type,
            })
        );
        setIsLoading(true);
    }
    useEffect(() => {
        checkPass();
    }, [values?.newpassword]);

    const checkPass = () => {
        if (values?.newpassword?.length >= 8) {
            setLengthCheck(true);
        } else {
            setLengthCheck(false);
        }
        if (/[a-z]/.test(values?.newpassword)) {
            checkLowerCase(true);
        } else {
            checkLowerCase(false);
        }
        if (/[A-Z]/.test(values?.newpassword)) {
            checkUpperCase(true);
        } else {
            checkUpperCase(false);
        }
        if (/[@$!%*?&#,-.?<>/|'";:_+={}`~^()]/.test(values?.newpassword)) {
            checkSplCase(true);
        } else {
            checkSplCase(false);
        }
        if (/[0-9]/.test(values?.newpassword)) {
            checkNumCase(true);
        } else {
            checkNumCase(false);
        }
    };

    const dispatch = useDispatch();
    const EmailId = localStorage.getItem("emailid");
    const { requestCompleted, expData } = useSelector(
        (state) => state?.expirylinkstate
    );
    const search = useLocation().search;
    const emid = new URLSearchParams(search).get("emid");

    useEffect(() => {
        const reqObj = {
            emid: emid,
        };
        const params = new URLSearchParams(window.location.search);
        dispatch(expirylink(reqObj));
        let Type = params.get("type");
        if (Type) {
            getQueryStringType(Type);
        }
    }, [dispatch, navigate]);
    if (requestCompleted) {
        let Validation = expData?.data?.validation;
        let EmailAddress = expData?.data?.emailaddress
        if (Validation === false) {
            navigate(`/expirylink?emid=${EmailAddress}`);
        }
    }
    const response = useSelector((state) => state?.setPasswordState);
    const { data, fetching, error, requestComplete } = useSelector(
        (state) => state?.setPasswordState
    );
    const params = new URLSearchParams(window.location.search);
    let Type = params.get("type");
    if (requestComplete) {
        const params = new URLSearchParams(window.location.search);
        let Type = params.get("type");
        if (data?.status && Type == 1) {
            setTimeout(function () {
                navigate("/thankyou");
            }, 2000);
        } else if (data.status) {
            if (data.status) {
                setTimeout(function () {
                    navigate("/");
                    window.location.reload();
                }, 2000);
            }
        }
    }
    if (requestComplete || (error && !data?.status)) {
        setTimeout(() => {
            dispatch({ type: SET_PASSWORD_RESET });
        }, 3000);
    }

    const location = useLocation();
    // const TermsCond = location.search.includes('terms=1')

    // if (requestComplete) {
    //   if (data?.status &&  TermsCond) {
    //     getQueryStringTerm(true)
    //   } else{
    //     getQueryStringTerm(false)
    //   }
    // }

    // const { data, fetching, error, requestComplete } = useSelector(
    //   (state) => state?.expirylinkstate
    // );
    // if ( expiryresponse?.requestComplete) {
    //   const params = new URLSearchParams(window.location.search);

    //   let Validation = params.get("validation");
    //   if (data?.status && Validation=== false) {
    //     setTimeout(function () {
    //       navigate("/expirylink");
    //     }, 2000);
    //   }
    // }

    return (
        <>
            {requestComplete && data?.status && (
                <SuccessMessageBox success={successMsg} />
            )}
            {requestComplete && !data?.status && (
                <MessageBox error={data?.message} />
            )}
            {error && !data?.status && <MessageBox error={errormsg} />}
            <div className="WrapCenter">
                <Container className="mt-10">
                    <Link
                        to={"//www.myiq.ai/"}
                        target="_blank"
                        className="mb-10 inline-block"
                    >
                        <img
                            className="w-[121px] h-[95px]"
                            src="/images/logo/MyiqFinal.svg"
                            alt="logoiq"
                        />
                    </Link>

                    <div className="flex justify-center">
                        <form onSubmit={handleSubmit} noValidate>
                            <div className="">
                                <Card className="w-[550px] h-[500px]">
                                    <h4 className="font-interSb text-center text-2xl mb-[2.813rem]">
                                        {Type == 1
                                            ? "Set your Password"
                                            : Type == 2
                                                ? "Reset your Password"
                                                : Type == 3
                                                    ? "Set your Password"
                                                    : ""}
                                    </h4>

                                    <div className="form-control">
                                        <label
                                            htmlFor=""
                                            className="text-light-40 font-interR text-sm mb-2"
                                        >
                                            <span className="text-[red] mr-1">
                                                *
                                            </span>
                                            New Password
                                        </label>
                                        <div className="relative">
                                            {
                                                <Popper
                                                    id={id}
                                                    open={open}
                                                    className="bg-white rounded-[10px] p-[20px] shadow-[0px_4px_20px_rgba(238,238,238,0.5)] border-[1px] border-[rgba(223,225,226,0.56)]"
                                                    anchorEl={anchorEl}
                                                    modifiers={[
                                                        {
                                                            name: "offset",
                                                            options: {
                                                                offset: [
                                                                    430, -50,
                                                                ],
                                                            },
                                                        },
                                                    ]}
                                                >
                                                    <ul className="font-interM">

                                                        <li className="flex space-x-2 mb-1 items-center">
                                                            <img
                                                                src={`${lowercase
                                                                    ? `/images/common/password-tick-green.svg`
                                                                    : `/images/common/password-tick.svg`
                                                                    }`}
                                                                alt="password-tick"
                                                            />
                                                            <span>
                                                                One lowercase
                                                                character
                                                            </span>
                                                        </li>
                                                        <li className="flex space-x-2 mb-1 items-center">
                                                            <img
                                                                src={`${upperCase
                                                                    ? `/images/common/password-tick-green.svg`
                                                                    : `/images/common/password-tick.svg`
                                                                    }`}
                                                                alt="password-tick"
                                                            />
                                                            <span>
                                                                One uppercase
                                                                character
                                                            </span>
                                                        </li>
                                                        <li className="flex space-x-2 mb-1 items-center">
                                                            <img
                                                                src={`${splCase
                                                                    ? `/images/common/password-tick-green.svg`
                                                                    : `/images/common/password-tick.svg`
                                                                    }`}
                                                                alt="password-tick"
                                                            />
                                                            <span>
                                                                One special
                                                                character
                                                            </span>
                                                        </li>
                                                        <li className="flex space-x-2 mb-1 items-center">
                                                            <img
                                                                src={`${numCase
                                                                    ? `/images/common/password-tick-green.svg`
                                                                    : `/images/common/password-tick.svg`
                                                                    }`}
                                                                alt="password-tick"
                                                            />
                                                            <span>
                                                                One number
                                                            </span>
                                                        </li>
                                                        <li className="flex space-x-2 mb-1 items-center">
                                                            <img
                                                                src={`${lengthCheck
                                                                    ? `/images/common/password-tick-green.svg`
                                                                    : `/images/common/password-tick.svg`
                                                                    }`}
                                                                alt="password-tick"
                                                            />
                                                            <span>
                                                                Minimum 8
                                                                characters
                                                            </span>
                                                        </li>
                                                    </ul>
                                                </Popper>
                                            }

                                            <Input
                                                className="border-[1px] bg-transparent border-light-840 rounded-[8px] pl-[20px] pr-[50px] py-2 w-full"
                                                type={
                                                    isRevealPasswd
                                                        ? "text"
                                                        : "password"
                                                }
                                                name="newpassword"
                                                onChange={handleChange}
                                                value={values?.newpassword}
                                                onFocus={handleClick}
                                                tabIndex="1"
                                                placeholder="&bull; &bull; &bull; &bull; &bull; &bull;"
                                                autoFocus
                                            />
                                            <Button
                                                type="button"
                                                onClick={() =>
                                                    setIsRevealPasswd(
                                                        (prevState) =>
                                                            !prevState
                                                    )
                                                }
                                                className="absolute cursor-pointer top-[57%] translate-y-[-57%] right-[26px]"
                                            >
                                                <img
                                                    title={
                                                        isRevealPasswd
                                                            ? "Hide password"
                                                            : "Show password"
                                                    }
                                                    src={
                                                        isRevealPasswd
                                                            ? `/images/common/eyeOpen.svg`
                                                            : `/images/common/eyeClose.svg`
                                                    }
                                                />
                                            </Button>
                                        </div>
                                        {errors?.newpassword && (
                                            <p className="text-light-50 text-xs">
                                                {errors?.newpassword}
                                            </p>
                                        )}
                                    </div>
                                    <div className="form-control">
                                        <label
                                            htmlFor=""
                                            className="text-light-40 text-sm font-interR mb-2"
                                        >
                                            <span className="text-[red] mr-1">
                                                *
                                            </span>
                                            Retype your New Password:
                                        </label>
                                        <div className="relative">
                                            <Input
                                                className="border-[1px] bg-transparent border-light-840 rounded-[8px] pl-[20px] pr-[50px] py-2 w-full"
                                                type={
                                                    isRevealPwd
                                                        ? "text"
                                                        : "password"
                                                }
                                                onChange={handleChange}
                                                tabIndex="1"
                                                value={values?.confirmpassword}
                                                placeholder="&bull; &bull; &bull; &bull; &bull; &bull;"
                                                name="confirmpassword"
                                            />
                                            <Button
                                                type="button"
                                                onClick={() =>
                                                    setIsRevealPwd(
                                                        (prevState) =>
                                                            !prevState
                                                    )
                                                }
                                                className="absolute cursor-pointer top-[57%] translate-y-[-57%] right-[26px]"
                                            >
                                                <img
                                                    title={
                                                        isRevealPwd
                                                            ? "Hide password"
                                                            : "Show password"
                                                    }
                                                    src={
                                                        isRevealPwd
                                                            ? `/images/common/eyeOpen.svg`
                                                            : `/images/common/eyeClose.svg`
                                                    }
                                                />
                                            </Button>
                                        </div>
                                        {errors?.confirmpassword && (
                                            <p className="text-light-50 text-xs">
                                                {errors?.confirmpassword}
                                            </p>
                                        )}
                                    </div>
                                    {type == 3 && (
                                        <div>
                                            <div className="flex form-group form-check mb-2">
                                                <Checkbox
                                                    name="isChecked"
                                                    size="small"
                                                    className="mr-2"
                                                    onChange={checkChange}
                                                    sx={{
                                                        padding: 0,
                                                        color: "rgba(85,85,85,0.7)",
                                                        "&.Mui-checked": {
                                                            color: "#00B399",
                                                        },
                                                    }}
                                                />{" "}
                                                I agree with{" "}
                                                <a
                                                    href={
                                                        "https://main.dnjz5voyw4q1y.amplifyapp.com/terms-condition"
                                                    }
                                                    relative="path"
                                                    target="_blank"
                                                    className="inline-block"
                                                >
                                                    <div title="Terms">
                                                        <span className="text-light-20 mx-1">
                                                            Terms{" "}
                                                        </span>
                                                    </div>
                                                </a>
                                                and
                                                <a
                                                    href={
                                                        "https://main.dnjz5voyw4q1y.amplifyapp.com/terms-condition"
                                                    }
                                                    relative="path"
                                                    target="_blank"
                                                    className="inline-block"
                                                >
                                                    <div title="Privacy">
                                                        <span className="text-light-20 ml-1">
                                                            {" "}
                                                            Privacy
                                                        </span>
                                                    </div>
                                                </a>
                                            </div>
                                            {errors?.isChecked && (
                                                <p className="text-light-50 text-xs mt-[-.7rem] mb-4">
                                                    {errors?.isChecked}
                                                </p>
                                            )}
                                        </div>
                                    )}
                                    <Button
                                        type="submit"
                                        tabIndex="1"
                                        title="Save Password"
                                        disabled={
                                            fetching ||
                                            (requestComplete && data?.status)
                                        }
                                        className="w-full rounded-[8px] text-white font-interSb text-lg bg-light-20 py-[8px]"
                                    >
                                        {fetching
                                            ? `Loading...`
                                            : "Save Password"}
                                    </Button>
                                </Card>
                            </div>
                        </form>
                    </div>
                </Container>
            </div>
        </>
    );
};

export default ResetPassword;
