import CryptoJS from "crypto-js";

import axios from "axios";

import moment from "moment";

import Cookies from "js-cookie";
import * as XLSX from "xlsx";
import localforage from "localforage";
import { useSelector } from "react-redux";
import { getCurrentUser } from "../redux/actions/auth";

import _ from "lodash";

import swal from "sweetalert";

import Swal from "sweetalert2";

import ExportExcel from "../pages/ExportFiles/Excel";
import { GET_INVOICE_CALC_VIEW_UPDATE } from "../redux/types/types";

const key = CryptoJS.enc.Utf8.parse("OtoA81sslqdpdGZ6");

const iv = CryptoJS.enc.Utf8.parse("OtoA81sslqdpdGZ6");

export const encryptId = (str) => {
    const encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(str), key, {
        keySize: 128 / 8,

        iv: iv,

        mode: CryptoJS.mode.CBC,

        padding: CryptoJS.pad.Pkcs7,
    });

    return encrypted.toString();
};

export const decryptId = (str) => {
    try {
        const decrypted = CryptoJS.AES.decrypt(str, key, {
            keySize: 128 / 8,

            iv: iv,

            mode: CryptoJS.mode.CBC,

            padding: CryptoJS.pad.Pkcs7,
        });

        return decrypted?.toString(CryptoJS.enc.Utf8);
    } catch (ex) {
        return str;
    }
};

export function storageAvailable(type) {
    var storage;

    try {
        storage = window[type];

        var x = "__storage_test__";

        storage.setItem(x, x);

        storage.removeItem(x);

        return true;
    } catch (e) {
        return (
            e instanceof DOMException &&
            // everything except Firefox

            (e.code === 22 ||
                // Firefox

                e.code === 1014 ||
                // test name field too, because code might not be present

                // everything except Firefox

                e.name === "QuotaExceededError" ||
                // Firefox

                e.name === "NS_ERROR_DOM_QUOTA_REACHED") &&
            // acknowledge QuotaExceededError only if there's something already stored

            storage &&
            storage.length !== 0
        );
    }
}

export const areCookiesEnabled = () => {
    const cookieName = "testCookie";

    try {
        // Set a temporary cookie

        document.cookie = `${cookieName}=1`;

        // Check if the cookie can be retrieved

        const cookiesEnabled = document.cookie.indexOf(cookieName) !== -1;

        // Delete the temporary cookie

        document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;

        return cookiesEnabled;
    } catch (error) {
        return false;
    }
};

export const checkApiStatus = () => {
    localStorage.clear();

    document.querySelector("body")?.classList?.remove("dark");

    Cookies.remove("jwt");

    window.location.href = "/";

    localforage.removeItem("MenuItems");
};

// For file size

export const fileSizeMB = 1;

export const fileGroup = 5;

export const fnBrowserDetect = () => {
    let userAgent = navigator.userAgent;

    let browserName;

    if (userAgent.match(/chrome|chromium|crios/i)) {
        browserName = "chrome";
    } else if (userAgent.match(/firefox|fxios/i)) {
        browserName = "firefox";
    } else if (userAgent.match(/safari/i)) {
        browserName = "safari";
    } else if (userAgent.match(/opr\//i)) {
        browserName = "opera";
    } else if (userAgent.match(/edg/i)) {
        browserName = "edge";
    } else {
        browserName = "No browser detection";
    }

    return browserName;
};

export const getOS = () => {
    let userAgent = window.navigator.userAgent,
        platform = window.navigator.platform,
        macosPlatforms = ["Macintosh", "MacIntel", "MacPPC", "Mac68K"],
        windowsPlatforms = ["Win32", "Win64", "Windows", "WinCE"],
        iosPlatforms = ["iPhone", "iPad", "iPod"],
        os = null;

    if (macosPlatforms.indexOf(platform) !== -1) {
        os = "Mac OS";
    } else if (iosPlatforms.indexOf(platform) !== -1) {
        os = "iOS";
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
        os = "Windows";
    } else if (/Android/.test(userAgent)) {
        os = "Android";
    } else if (!os && /Linux/.test(platform)) {
        os = "Linux";
    }

    return os;
};

export const getSystemInfo = async () => {
    const result = await axios.get(`https://pro.ip-api.com/json?key=BgVoP0GQ0ogIaPz`).then((response) => {
        if (response.status == 200) {
            return response?.data;
        } else {
            return "";
        }
    });

    return result;
};

export const DateFormat = () => {
    const CurrentUser = getCurrentUser();

    const UserDateFormat = CurrentUser?.user_date;

    return UserDateFormat?.toUpperCase();
};

// globally dates assigned here to use everywhere!!

const liveDate = moment();

export const startOfWeek = liveDate.clone().startOf("week");

export const endOfWeek = liveDate.clone().endOf("week");

export const startOfMonth = liveDate.clone().startOf("month");

export const endOfMonth = liveDate.clone().endOf("month");

export const startOfYear = liveDate.clone().startOf("year");

export const endOfYear = liveDate.clone().endOf("year");

export const ThisYear = {
    from: moment(startOfYear).format("YYYY-MM-DD"),

    to: moment(new Date()).format("YYYY-MM-DD"),
};

export const ThisMonth = {
    from: moment(startOfMonth).format("YYYY-MM-DD"),

    to: moment(new Date()).format("YYYY-MM-DD"),
};

export const ThisWeek = {
    from: moment(startOfWeek).format("YYYY-MM-DD"),

    to: moment(new Date()).format("YYYY-MM-DD"),
};

// localforage store emails

export const storeEmailsInLocalStorage = async (emails) => {
    try {
        // await window.localforage.setItem("emails", emails);
    } catch (error) {}
};

// get Name avatar letters

export const getInitials = (fullName) => {
    const sanitizedFullName = fullName?.replace(/\s*\(Jira\)/i, ""); // Remove "(Jira)" part

    const names = sanitizedFullName?.split(" ");

    // Extract the initials

    const initials = names?.map((name) => {
        const sanitized = name?.replace(/[^a-zA-Z]/g, ""); // Remove special characters

        return sanitized?.charAt(0)?.toUpperCase();
    });

    return initials?.slice(0, 2)?.join("");
};

const generateRandomDarkColor = () => {
    const randomBetween = (min, max) => Math.floor(Math.random() * (max - min + 1) + min);

    const generateColorComponent = () => {
        const min = 30; // Minimum value for each RGB component

        const max = 160; // Maximum value for each RGB component

        return randomBetween(min, max);
    };

    const isInvalidColor = (color) => {
        const [r, g, b] = color;

        const isBlack = r === 0 && g === 0 && b === 0;

        const isRed = r === 255 && g === 0 && b === 0;

        return isBlack || isRed;
    };

    let color;

    do {
        color = [generateColorComponent(), generateColorComponent(), generateColorComponent()];
    } while (isInvalidColor(color));

    return `rgb(${color.join(",")})`;
};

const usernameColors = {};

export const getUsernameColor = (username) => {
    if (!usernameColors.hasOwnProperty(username)) {
        let color;

        do {
            color = generateRandomDarkColor();
        } while (Object.values(usernameColors).includes(color));

        usernameColors[username] = color;
    }

    return usernameColors[username];
};

export const convertUtcDate = (utcDateString) => {
    const utcDate = new Date(utcDateString);

    const localDateString = utcDate.toLocaleString().replace(",", "");

    const getDate = localDateString.split(" ")?.[0];

    const getTime = localDateString.split(" ")?.[1];

    //convert to AM/PM

    const date = new Date();

    const [hours, minutes] = getTime.split(":");

    date.setHours(parseInt(hours, 10));

    date.setMinutes(parseInt(minutes, 10));

    const time12 = date.toLocaleString("en-US", {
        hour: "numeric",

        minute: "numeric",

        hour12: true,
    });

    // Check for yesterday

    const today = moment(new Date()).format("DD/MM/YYYY");

    const targetDate = moment(new Date(utcDateString)).format("DD/MM/YYYY");

    if (moment(new Date()).format("DD/MM/YYYY") == moment(new Date(utcDateString)).format("DD/MM/YYYY")) {
        return time12;
    } else {
        return getDate;
    }
};

export const fetchEmails = async (id) => {
    const accessArray = JSON.parse(localStorage.getItem("FolderAccess"));

    if (accessArray == null && id != null) {
        const parseArray = [
            {
                key: id,

                time: new Date(),
            },
        ];

        localStorage.setItem("FolderAccess", JSON.stringify(parseArray));
    } else if (id != null) {
        const findIndex = accessArray.findIndex((item) => item?.key == id);

        if (findIndex != -1) {
            accessArray[findIndex].time = new Date();
        } else {
            accessArray.push({
                key: id,

                time: new Date(),
            });
        }

        localStorage.setItem("FolderAccess", JSON.stringify(accessArray));
    }

    try {
        const cachedEmails = await localforage.getItem(id);

        // const cachednextpage = await localforage.getItem(id);

        if (cachedEmails) {
            return cachedEmails;
        } else {
            return [];
        }
    } catch (error) {
        console.error("Error retrieving emails from cache:", error);
    }
};

export const fetchMenuArr = async () => {
    try {
        const MenuItems = await localforage.getItem("MenuItems");

        if (MenuItems) {
            return MenuItems;
        } else {
            return [];
        }
    } catch (error) {
        console.error("Error retrieving emails from cache:", error);
    }

    // return accessArray;
};

export const storeDataInLocalForage = (id, uniqueArray, folder) => {
    //Sorting the data based on date and time

    let sortedData = null;

    if (folder === "folder") {
        sortedData = uniqueArray;
    } else {
        sortedData = [...uniqueArray].sort((a, b) => {
            const timeA = new Date(a?.bodypreview?.receivedDateTime).getTime();

            const timeB = new Date(b?.bodypreview?.receivedDateTime).getTime();

            return timeB - timeA;
        });
    }

    try {
        localforage

            .setItem(id, sortedData)

            .then(() => {})

            .catch((error) => {});
    } catch {}
};

export const getFilesSizeInMB = (arr) => {
    let size = 0;

    arr?.map((file) => {
        const fileSize = (file.size / (1024 * 1024)).toFixed(2);

        size = size + parseFloat(fileSize);
    });

    return size;
};

export const getFirstDayOfWeek = (date) => {
    const currentDate = new Date(date);

    const dayOfWeek = currentDate.getDay();

    const firstDayOfWeek = new Date(currentDate);

    firstDayOfWeek?.setDate(currentDate?.getDate() - dayOfWeek);

    return firstDayOfWeek?.toLocaleString("en-US", {
        month: "short",

        day: "2-digit",

        year: "numeric",

        hour: "2-digit",

        minute: "2-digit",

        hour12: true,
    });
};

export const formatDate = (inputDateStr) => {
    const inputDate = new Date(inputDateStr);

    // Get the year, month, and day components

    const year = inputDate.getFullYear();

    const month = String(inputDate.getMonth() + 1).padStart(2, "0"); // Month is zero-based

    const day = String(inputDate.getDate()).padStart(2, "0");

    // Combine the components into the desired format

    const formattedDate = `${year}-${month}-${day}`;

    return formattedDate;
};

export const getFirstDayOfMonth = (date) => {
    const currentDate = new Date(date);

    currentDate.setDate(1);

    return currentDate.toLocaleString("en-US", {
        year: "numeric",

        month: "short",

        day: "2-digit",

        hour: "2-digit",

        minute: "2-digit",

        hour12: true,
    });
};

export const getFirstDayOfYear = (date) => {
    const currentDate = new Date(date);

    currentDate.setMonth(0, 1);

    return currentDate.toLocaleString("en-US", {
        year: "numeric",

        month: "short",

        day: "2-digit",

        hour: "2-digit",

        minute: "2-digit",

        hour12: true,
    });
};

// Function to remove the "vendor_code" object using lodash

export function removeVendorCode(data) {
    return _.pickBy(
        _.mapValues(data, (item) => {
            // Check if the object has a "vendor_code" property and omit it

            return _.omit(item, "vendor_code");
        }),

        _.size
    ); // Filter out empty objects
}

export function invoiceDataFetch(invoiceData) {
    const reducedLineItems = _.mapValues(invoiceData, (itemGroup) => {
        return _.reduce(
            itemGroup,

            (result, item) => {
                // result[item.label_name] = item

                result[item.label_name] = {
                    label_value: item?.label_value,

                    isrequired: item?.isrequired,

                    ismandatory: item?.ismandatory,

                    status: item?.status,

                    data_type: item?.data_type,

                    id: item?.id,

                    maxlength: item?.maxlength,

                    iseditable: item?.iseditable,

                    item_no: item?.item_no,

                    label_name: item.label_name,

                    label_displayname: item?.label_displayname,

                    is_item: item?.is_item,
                };

                return removeVendorCode(result);
            },

            {}
        );
    });

    return {
        reducedLineItems: reducedLineItems,
    };
}

// !------ Auto Calculation Func() -------! Start

const userNumFormat = localStorage.getItem("userNumberFormat");

export const formatNumber = (value) => {
    const val =
        userNumFormat == "1"
            ? value?.toString()?.replaceAll(",", "")
            : userNumFormat == "2"
            ? value?.toString()?.replaceAll(".", "")?.replaceAll(",", ".")
            : value?.toString()?.replaceAll(" ", "")?.replaceAll(",", "");

    const formatedVal = (+val)?.toFixed(2);

    return val !== "" ? (+val == null ? "" : +formatedVal) : "";
};

//to parse the value for sum

export const showNumber = (value) => {
    const val = userNumFormat == "2" ? value.replaceAll(".", ",") : value;
    return val;
};
export const truncateValue = (val) => {
    // Assuming truncation to 2 decimal places
    const truncated = Math.floor(val * 100) / 100;
    return truncated.toFixed(2);
};
// //to autocalculation on onchange
export const handleDecimal = (val) => {
    const trimDecimal = +val;
    return val !== "" ? trimDecimal?.toFixed(2) : "";
};
export const autoCalculate = (update, key, label, Data) => {
    // Initialize the variables
    let temp = update?.[key];
    let qty = formatNumber(temp?.quantity?.label_value);
    let price = formatNumber(temp?.item_price?.label_value);
    let unitvalue = qty * price;
    let taxperc = formatNumber(temp?.item_tax_percentage?.label_value);
    let taxamt = formatNumber(temp?.item_tax_amount?.label_value);
    let discperc = formatNumber(temp?.item_discount_percentage?.label_value);
    let discamt = formatNumber(temp?.item_discount_amount?.label_value);
    let cgstperc = formatNumber(temp?.item_cgst_tax_percentage?.label_value);
    let cgstamt = formatNumber(temp?.item_cgst_tax_amount?.label_value);
    let igstperc = formatNumber(temp?.item_igst_tax_percentage?.label_value);
    let igstamt = formatNumber(temp?.item_igst_tax_amount?.label_value);
    let sgstperc = formatNumber(temp?.item_sgst_tax_percentage?.label_value);
    let sgstamt = formatNumber(temp?.item_sgst_tax_amount?.label_value);
    let cessperc = formatNumber(temp?.item_cess_tax_percentage?.label_value);
    let cessamt = formatNumber(temp?.item_cess_tax_amount?.label_value);
    let ugstperc = formatNumber(temp?.item_ugst_tax_percentage?.label_value);
    let ugstamt = formatNumber(temp?.item_ugst_tax_amount?.label_value);
    let lineamt = unitvalue - discamt + +taxamt + +cgstamt + +igstamt + +sgstamt + +ugstamt;
    let netval = 0;
    if (temp?.quantity?.label_value?.length > 0 && temp?.item_price?.label_value?.length > 0) {
        cessperc =
            label === "item_cess_tax_amount" && (cessamt == 0 || !cessamt)
                ? ""
                : cessamt > 0 && label !== "item_cess_tax_percentage" && lineamt > 0
                ? (cessamt * 100) / (lineamt - discamt)
                : cessperc;

        discperc =
            label === "item_discount_amount" && (discamt == 0 || !discamt)
                ? ""
                : discamt > 0 && label == "item_discount_amount" && unitvalue > 0
                ? (discamt * 100) / unitvalue
                : discperc;

        cgstperc =
            label === "item_cgst_tax_amount" && (cgstamt == 0 || !cgstamt)
                ? ""
                : cgstamt > 0 && label !== "item_cgst_tax_percentage" && unitvalue > 0
                ? (cgstamt * 100) / (unitvalue - discamt)
                : cgstperc;

        igstperc =
            label === "item_igst_tax_amount" && (igstamt == 0 || !igstamt)
                ? ""
                : igstamt > 0 && label !== "item_igst_tax_percentage" && unitvalue > 0
                ? (igstamt * 100) / (unitvalue - discamt)
                : igstperc;

        sgstperc =
            label === "item_sgst_tax_amount" && (sgstamt == 0 || !sgstamt)
                ? ""
                : sgstamt > 0 && label !== "item_sgst_tax_percentage" && unitvalue > 0
                ? (sgstamt * 100) / (unitvalue - discamt)
                : sgstperc;

        ugstperc =
            label === "item_ugst_tax_amount" && (ugstamt == 0 || !ugstamt)
                ? ""
                : ugstamt > 0 && label !== "item_ugst_tax_percentage" && unitvalue > 0
                ? (ugstamt * 100) / (unitvalue - discamt)
                : ugstperc;
        // Format to 2 decimal
        taxperc =
            label === "item_tax_amount" && (taxamt == 0 || !taxamt)
                ? ""
                : taxamt > 0 && label == "item_tax_amount" && unitvalue > 0
                ? (taxamt * 100) / (unitvalue - discamt)
                : taxperc;

        cessamt = cessperc > 0 && label !== "item_cess_tax_amount" ? (lineamt * cessperc) / 100 : lineamt > 0 && cessperc > 0 ? cessamt : "";
        // Calculate discount amount
        discamt = discperc > 0 && label !== "item_discount_amount" ? (unitvalue * discperc) / 100 : unitvalue > 0 && discperc > 0 ? discamt : "";
        // Format to 2 decimal
        // Calculate tax amount
        cgstamt =
            cgstperc > 0 && label !== "item_cgst_tax_amount"
                ? ((unitvalue - discamt) * cgstperc) / 100
                : unitvalue > 0 && cgstperc > 0
                ? cgstamt
                : "";

        igstamt =
            igstperc > 0 && label !== "item_igst_tax_amount"
                ? ((unitvalue - discamt) * igstperc) / 100
                : unitvalue > 0 && igstperc > 0
                ? igstamt
                : "";

        sgstamt =
            sgstperc > 0 && label !== "item_sgst_tax_amount"
                ? ((unitvalue - discamt) * sgstperc) / 100
                : unitvalue > 0 && sgstperc > 0
                ? sgstamt
                : "";

        ugstamt =
            ugstperc > 0 && label !== "item_ugst_tax_amount"
                ? ((unitvalue - discamt) * ugstperc) / 100
                : unitvalue > 0 && ugstperc > 0
                ? ugstamt
                : "";

        taxamt = taxperc > 0 && label !== "item_tax_amount" ? ((unitvalue - discamt) * taxperc) / 100 : unitvalue > 0 && taxperc > 0 ? taxamt : "";
        // Format to 2 decimal
        // Calculate gross value = net amount - Discount + tax;
        netval = unitvalue - discamt + +taxamt + +cgstamt + +igstamt + +sgstamt + +ugstamt + +cessamt;

        // Update the row object properties using lodash

        _.set(temp, "item_cess_tax_amount.label_value", showNumber(handleDecimal(cessamt)?.toString()));
        _.set(temp, "item_tax_amount.label_value", showNumber(handleDecimal(taxamt)?.toString()));
        _.set(temp, "item_discount_amount.label_value", showNumber(handleDecimal(discamt)?.toString()));
        _.set(temp, "item_cgst_tax_amount.label_value", showNumber(handleDecimal(cgstamt)?.toString()));
        _.set(temp, "item_igst_tax_amount.label_value", showNumber(handleDecimal(igstamt)?.toString()));
        _.set(temp, "item_sgst_tax_amount.label_value", showNumber(handleDecimal(sgstamt)?.toString()));
        _.set(temp, "item_ugst_tax_amount.label_value", showNumber(handleDecimal(ugstamt)?.toString()));
        _.set(temp, "item_tax_percentage.label_value", showNumber(handleDecimal(taxperc)?.toString()));
        _.set(temp, "item_discount_percentage.label_value", showNumber(handleDecimal(discperc)?.toString()));
        _.set(temp, "item_cgst_tax_percentage.label_value", showNumber(handleDecimal(cgstperc)?.toString()));
        _.set(temp, "item_igst_tax_percentage.label_value", showNumber(handleDecimal(igstperc)?.toString()));
        _.set(temp, "item_sgst_tax_percentage.label_value", showNumber(handleDecimal(sgstperc)?.toString()));
        _.set(temp, "item_ugst_tax_percentage.label_value", showNumber(handleDecimal(ugstperc)?.toString()));
        _.set(temp, "item_cess_tax_percentage.label_value", showNumber(handleDecimal(cessperc)?.toString()));
        _.set(temp, "item_total.label_value", showNumber(handleDecimal(netval)?.toString()));
        _.set(temp, "item_price.label_value", showNumber(handleDecimal(price)?.toString()));
        _.set(temp, "quantity.label_value", showNumber(handleDecimal(qty)?.toString()));
    } else if (temp?.quantity?.label_value?.length < 1 || temp?.item_price?.label_value?.length < 1) {
        _.set(temp, "item_cess_tax_amount.label_value", "");
        _.set(temp, "item_tax_amount.label_value", "");
        _.set(temp, "item_discount_amount.label_value", "");
        _.set(temp, "item_cgst_tax_amount.label_value", "");
        _.set(temp, "item_igst_tax_amount.label_value", "");
        _.set(temp, "item_sgst_tax_amount.label_value", "");
        _.set(temp, "item_ugst_tax_amount.label_value", "");
        // Handle the case when quantity or price is not provided
        _.set(temp, "item_total.label_value", "");
    } else {
        // Handle the case when quantity or price is not provided
        _.set(temp, "item_total.label_value", "");
    }
    let updatedData = _.cloneDeep(Data);
    updatedData[key] = temp;
    return updatedData;
};

export let thousandSeperator = userNumFormat == "1" ? "," : userNumFormat == 2 ? "." : " ";

export let decSeperator = userNumFormat == "1" ? "." : userNumFormat == "2" ? "," : ".";

// Helper function to show a warning swal

export function showSwal(text) {
    Swal?.fire({
        text,

        icon: "warning",

        closeOnClickOutside: false,

        showCloseButton: true,

        button: "Ok",
    });
}

export const showValidationError = (errors, title) => {
    let errorMessage = "";
    errors.forEach((error, index) => {
        errorMessage += `<p class="mb-2">${index + 1}. ${error}\n</p>`;
    });
    Swal.fire({
        title: title,
        html: errorMessage, // Display errors in a preformatted text
        icon: "warning",
        customClass: {
            title: "mb-3", // Apply custom styling to the content
        },
    });
};

// Helper function to show a confirmation swal

export function showSwalWithConfirmation(innerHTML, updateDocList, saveInvoice) {
    Swal.fire({
        html: innerHTML,

        icon: "warning",

        closeOnClickOutside: false,

        dangerMode: true,

        showCancelButton: true,

        cancelButtonText: "No, cancel it!",

        confirmButtonText: "Yes, I am sure!",
    }).then((result) => {
        if (result?.isConfirmed) {
            // Call the saveInvoice function to save the data

            saveInvoice(updateDocList);
        } else {
            return false;
        }
    });

    // setTimeout(function () {

    //     document.querySelector(".swal-text").innerHTML += `<br/>${innerHTML}`;

    // }, 500);
}

// invoice sidebar month static set array in common use //

export const monthMap = {
    January: 0,

    February: 1,

    March: 2,

    April: 3,

    May: 4,

    June: 5,

    July: 6,

    August: 7,

    September: 8,

    October: 9,

    November: 10,

    December: 11,
};

// helper func for download excel invoice view page //

// Import lodash library

const newValueItem = [];

export const downloadeExcel = (downloadInvoiceList, onClickDownload) => {
    const invoiceExcelData = [];

    const newValueItem = [];
    let invHeaders1;

    let invHeaders;

    let Title;

    newValueItem.forEach((item) => {
        invHeaders1 = {
            "Invoice Code": item?.code?.label_value,

            Description: item?.description?.label_value,

            Quantity: item?.quantity?.label_value,

            Unit: item?.unit?.label_value,

            "Unit Price": item?.price?.label_value,

            Tax: item?.tax?.label_value,

            "Tax %": item?.taxPercentage?.label_value,

            Discount: item?.discount?.label_value,

            "Discount %": item?.discountPercentage?.label_value,

            "Delivery No": item?.deliveryno?.label_value,

            Total: item?.total?.label_value,
        };

        invoiceExcelData.push(invHeaders1);
    }); // Assuming this is defined elsewhere
    const AccDetailData = downloadInvoiceList?.datalist;

    if (AccDetailData) {
        AccDetailData.forEach((ele) => {
            const invHeader = ["InvoiceItems:"];

            invoiceExcelData.push(invHeader);

            let invItem0 = ele?.invoice_items?.filter((ele) => ele?.is_item == "0");

            let invoiceFields = _.map(invItem0, (object) => {
                return _.pick(object, ["label_displayname", "label_value"]);
            });

            invoiceFields.forEach((item) => {
                invHeaders = [item?.label_displayname, item?.label_value];

                invoiceExcelData.push(invHeaders);
            });

            const invItem1 = ele?.invoice_items?.filter((ele) => ele?.is_item == "1");

            if (invItem1.length > 0) {
                invoiceExcelData.push([]);
                const invHeader1 = ["LineItems:"];

                invoiceExcelData.push(invHeader1);
                // Create an object to store unique headers by label_displayname
                const headersMap = {};

                // Extract label_displaynames and values from invItem1
                invItem1.forEach((item) => {
                    const { label_displayname, label_value } = item;
                    if (!headersMap[label_displayname]) {
                        headersMap[label_displayname] = [label_value]; // Initialize array with first label_value
                    } else {
                        headersMap[label_displayname].push(label_value); // Add label_value to existing array
                    }
                });

                // Construct header row using unique label_displaynames
                const headerRow = Object.keys(headersMap);

                // Add the header row to invoiceExcelData
                invoiceExcelData.push(headerRow);

                // Calculate the maximum number of values for any header
                const maxValues = Math.max(...Object.values(headersMap).map((arr) => arr.length));

                // Construct data rows
                for (let i = 0; i < maxValues; i++) {
                    const dataRow = [];
                    Object.values(headersMap).forEach((values) => {
                        dataRow.push(values[i] || ""); // Add label_value to data row, or empty string if no value
                    });
                    // Add the data row to invoiceExcelData
                    invoiceExcelData.push(dataRow);
                }

                // Add an empty row as a separator between each set of data
                invoiceExcelData.push([]);
            }

            const TaxItem = ele?.invoice_items?.filter((ele) => ele?.is_item == "2");

            if (TaxItem?.length > 0) {
                invoiceExcelData.push([]);
                const taxHeader = ["Tax Items:"];

                invoiceExcelData.push(taxHeader);
                // Create an object to store unique headers by label_displayname
                const headersMap = {};

                // Extract label_displaynames and values from invItem1
                TaxItem?.forEach((item) => {
                    const { label_displayname, label_value } = item;
                    if (!headersMap[label_displayname]) {
                        headersMap[label_displayname] = [label_value]; // Initialize array with first label_value
                    } else {
                        headersMap[label_displayname].push(label_value); // Add label_value to existing array
                    }
                });

                // Construct header row using unique label_displaynames
                const headerRow = Object.keys(headersMap);

                // Add the header row to invoiceExcelData
                invoiceExcelData.push(headerRow);

                // Calculate the maximum number of values for any header
                const maxValues = Math.max(...Object.values(headersMap).map((arr) => arr.length));

                // Construct data rows
                for (let i = 0; i < maxValues; i++) {
                    const dataRow = [];
                    Object.values(headersMap).forEach((values) => {
                        dataRow.push(values[i] || ""); // Add label_value to data row, or empty string if no value
                    });
                    // Add the data row to invoiceExcelData
                    invoiceExcelData.push(dataRow);
                }

                // Add an empty row as a separator between each set of data
                invoiceExcelData.push([]);
            }
        });

        ExportExcel({ excelData: invoiceExcelData, fileName: "MyiQ.Ai" });

        onClickDownload(false);
    }
};

export const generateFormattedText = (array, message) => {
    let formattedText = message; // Starting with a title or label

    array.forEach((item, index) => {
        formattedText += `${index + 1}. ${item}<br/>`;
    });

    // formattedText += message;

    return formattedText;
};

export const SelectDropdwnImg = (currentMode) => (
    <img
        className="absolute right-[15px] opacity-50 pointer-events-none"
        src={currentMode !== "dark" ? `/images/header/chevronDown.svg` : `/images/header/chevronDown-white.svg`}
        alt="chevronDown"
    />
);

export const ClockIcon = () => <img src="/images/reports/clock.svg" alt="clock" />;

export const AccessIcon = () => <img src="/images/invoice/calendar.svg" alt="calendar" />;

export const OrgId = localStorage.getItem("orgId");

export const User = localStorage.getItem("user");

export const userDateFormat = localStorage?.getItem("userDateFormat")?.toUpperCase();

export const UserId = JSON.parse(User)?.id;

export const isEmailregex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const updatedAccordionItems = (accordionItems, fieldValues, check) =>
    accordionItems?.map((parentObj) => {
        const updatedContent = parentObj?.data?.map((contentObj) => {
            const matchingDroppedValue = fieldValues?.find((droppedObj) => droppedObj?.column_name === contentObj?.column_name);

            if (matchingDroppedValue) {
                // Update the checked property in the matching content object

                return { ...contentObj, checked: check };
            }

            return contentObj;
        });

        return { ...parentObj, data: updatedContent };
    });

export function generateRandomColor() {
    let r, g, b;

    const colorChoice = Math.floor(Math.random() * 4); // Randomly choose one of the color options

    switch (colorChoice) {
        case 0: // Green
            r = Math.floor(Math.random() * 64); // Low red component

            g = Math.floor(Math.random() * 128) + 128; // Medium to high green component

            b = Math.floor(Math.random() * 64); // Low blue component

            break;

        case 1: // Red
            r = Math.floor(Math.random() * 128) + 128; // Medium to high red component

            g = Math.floor(Math.random() * 64); // Low green component

            b = Math.floor(Math.random() * 64); // Low blue component

            break;

        case 2: // Pink
            r = Math.floor(Math.random() * 128) + 128; // Medium to high red component

            g = Math.floor(Math.random() * 64); // Low green component

            b = Math.floor(Math.random() * 128) + 128; // Medium to high blue component

            break;

        case 3: // Orange (replace black)
            r = Math.floor(Math.random() * 128) + 128; // Medium to high red component

            g = Math.floor(Math.random() * 64) + 64; // Medium to high green component

            b = Math.floor(Math.random() * 32); // Low to medium blue component

            break;

        default:
            // Default to black if colorChoice is out of range (should never happen)

            r = 0;

            g = 0;

            b = 0;

            break;
    }

    // Convert RGB to hex format

    const hex = `#${r.toString(16).padStart(2, "0")}${g.toString(16).padStart(2, "0")}${b.toString(16).padStart(2, "0")}`;

    return hex;
}

export function extractTextFromHTML(html) {
    const tempElement = document.createElement("div");

    tempElement.innerHTML = html;

    return tempElement.textContent || tempElement.innerText || "";
}

export const helperVideoMenu = [
    {
        menu_id: 0,
        description: "Adding Users",
        src: "/images/tour-helper/user-management-template.png",
        url: "OAmoCQwujio",
    },
    {
        menu_id: 0,
        description: "Adding Roles",
        src: "/images/tour-helper/user-roles-template.png",
        url: "uA1HRaAqip0",
    },
    {
        menu_id: 1,
        description: "Invoice Upload and Extractions",
        src: "/images/tour-helper/upload-template.png",
        url: "VpHbRT4kQLk",
    },

    {
        menu_id: 2,
        description: "Accrual Automation",
        src: "/images/tour-helper/accrual-acc-template.png",
        url: "aUyV1Wfpc1g",
    },
];

export const dateTimeConvertStringComponent = ({ dateString }) => {
    // Parse the date string using moment
    const dateObj = moment(dateString);
    // Format the date as DD/MM/YYYY
    const formattedDate = dateObj.format("DD/MM/YYYY");
    // Format the time with AM/PM
    const formattedTime = dateObj.format("hh:mm A");
    return { date: formattedDate, time: formattedTime };
};
export const removeDuplicateById = (array) => {
    // Group array items by their id
    const groupedById = _.groupBy(array, "id");

    // Map over the groups and select the latest item for each id
    const filteredArray = _.map(groupedById, (group) => {
        // Sort items by some criteria (e.g., timestamp) to get the latest one
        const latestItem = _.maxBy(group, "timestamp"); // Assuming there's a timestamp field

        return latestItem;
    });

    return filteredArray;
};
export const RoleDisplay = () => {
    const DisplayRole = useSelector((state) => state?.DisplayRolePermissionStateAction);
    const { data: DataList } = DisplayRole;
    const RoleList = DataList?.role_info;
    const RoleMenu = RoleList?.menu;

    return RoleMenu;
};

export const RoundNum = (number) => {
    let roundedNumber = Math.round(number * 1000) / 1000; // Round to three decimal places
    let result = roundedNumber.toFixed(2); // Convert to string with two decimal places
    return result;
};

export const amountUpdate = (convertingArray, dispatch, Amounts) => {
    let { RetenTtl, GrossAmt, NetAmt, TaxAmt, retentionIndicator, retentionOnGross, retentionPercentage } = Amounts;
    const updatedArray = [...convertingArray];
    if (updatedArray?.length > 0) {
        const ponetIndex = updatedArray?.findIndex((item) => item.Name === "Poc Net");
        const TtlTaxIndex = updatedArray?.findIndex((item) => item.Name === "Tax Additions");
        const grossIndex = updatedArray?.findIndex((item) => item.Name === "Gross Value");
        const netIndex = updatedArray?.findIndex((item) => item.Name === "Net Value");
        const TaxIndex = updatedArray?.findIndex((item) => item.Name === "Tax Deductions");
        const netPayIn = updatedArray?.findIndex((item) => item.Name === "Net Payable");
        const poRetenIn = updatedArray?.findIndex((item) => item.Name === "Po Retention");
        const getAdRec = _.find(updatedArray, { Name: "Advance Recovery" });
        const getTdsAdRec = _.find(updatedArray, { Name: "TDS Advance Recovery" });
        const poReten = _.find(updatedArray, { Name: "Po Retention" });
        const addHold = _.find(updatedArray, { Name: "Additional Hold" });
        const otherdetuct = _.find(updatedArray, { Name: "Other Deduction" });
        const miscDb = _.find(updatedArray, { Name: "Misc Debit" });
        const creditNote = _.find(updatedArray, { Name: "Direct Credit Note" });
        const tcsonPurchase = _.find(updatedArray, { Name: "TCS On Purchase" });
        if (formatNumber(NetAmt?.textContent) != formatNumber(updatedArray[ponetIndex]["billAmount"])) {
            updatedArray[ponetIndex]["billAmount"] = NetAmt?.textContent;
            updatedArray[ponetIndex]["CumulativeAmount"] =
                formatNumber(NetAmt?.textContent) + formatNumber(updatedArray[ponetIndex]["PreviousBillAmount"]);
            if (retentionIndicator == "H" && !retentionOnGross) {
                RetenTtl = (formatNumber(NetAmt?.textContent) * formatNumber(retentionPercentage)) / 100;
                updatedArray[poRetenIn]["billAmount"] = !retentionOnGross ? RetenTtl : updatedArray[poRetenIn]?.billAmount;
                updatedArray[netPayIn]["billAmount"] = RoundNum(formatNumber(updatedArray[netIndex]?.billAmount) - formatNumber(RetenTtl));
                updatedArray[netPayIn]["CumulativeAmount"] = RoundNum(
                    formatNumber(updatedArray[netPayIn]["billAmount"]) + formatNumber(updatedArray[netPayIn]["PreviousBillAmount"])
                );
            }
            dispatch({ type: GET_INVOICE_CALC_VIEW_UPDATE, payload: updatedArray });
        }
        if (formatNumber(TaxAmt?.textContent) != updatedArray[TtlTaxIndex]["billAmount"]) {
            updatedArray[TtlTaxIndex]["billAmount"] = TaxAmt?.textContent;
            updatedArray[TtlTaxIndex]["CumulativeAmount"] =
                formatNumber(TaxAmt?.textContent) + formatNumber(updatedArray[TtlTaxIndex]["PreviousBillAmount"]);
            dispatch({ type: GET_INVOICE_CALC_VIEW_UPDATE, payload: updatedArray });
        }
        if (formatNumber(GrossAmt?.textContent) != formatNumber(updatedArray[grossIndex]["billAmount"])) {
            const amt = formatNumber(GrossAmt?.textContent) - formatNumber(updatedArray[TaxIndex]["billAmount"]);
            updatedArray[grossIndex]["billAmount"] = GrossAmt?.textContent;
            updatedArray[grossIndex]["CumulativeAmount"] =
                formatNumber(GrossAmt?.textContent) + formatNumber(updatedArray[grossIndex]["PreviousBillAmount"]);
            updatedArray[netIndex]["billAmount"] = amt;
            updatedArray[netIndex]["CumulativeAmount"] =
                formatNumber(GrossAmt?.textContent) + formatNumber(updatedArray[grossIndex]["PreviousBillAmount"]);
            if (retentionIndicator == "H" && retentionOnGross) {
                RetenTtl = (formatNumber(GrossAmt?.textContent) * formatNumber(retentionPercentage)) / 100;
                updatedArray[poRetenIn]["billAmount"] = retentionOnGross ? RetenTtl : updatedArray[poRetenIn]?.billAmount;
                updatedArray[netPayIn]["billAmount"] = RoundNum(formatNumber(updatedArray[netIndex]?.billAmount) - formatNumber(RetenTtl));
                updatedArray[netPayIn]["CumulativeAmount"] = RoundNum(
                    formatNumber(updatedArray[netPayIn]["billAmount"]) + formatNumber(updatedArray[netPayIn]["PreviousBillAmount"])
                );
            }

            const netPayable =
                formatNumber(amt) -
                formatNumber(getAdRec?.billAmount) +
                formatNumber(getTdsAdRec?.billAmount) -
                formatNumber(poReten?.billAmount) -
                formatNumber(addHold?.billAmount) -
                formatNumber(otherdetuct?.billAmount) +
                formatNumber(tcsonPurchase?.billAmount) -
                formatNumber(miscDb?.billAmount) -
                formatNumber(creditNote?.billAmount);
            updatedArray[netPayIn]["billAmount"] = netPayable;
            updatedArray[netPayIn]["CumulativeAmount"] = netPayable;
            dispatch({ type: GET_INVOICE_CALC_VIEW_UPDATE, payload: updatedArray });
        }
    }
};
export const autoCalc = (updatedArray, GrossAmt, dispatch) => {
    const grossIndex = updatedArray?.findIndex((item) => item.Name === "Gross Value");
    const netIndex = updatedArray?.findIndex((item) => item.Name === "Net Value");
    const TaxIndex = updatedArray?.findIndex((item) => item.Name === "Tax Deductions");
    const netPayIn = updatedArray?.findIndex((item) => item.Name === "Net Payable");
    const getAdRec = _.find(updatedArray, { Name: "Advance Recovery" });
    const getTdsAdRec = _.find(updatedArray, { Name: "TDS Advance Recovery" });
    const poReten = _.find(updatedArray, { Name: "Po Retention" });
    const addHold = _.find(updatedArray, { Name: "Additional Hold" });
    const otherdetuct = _.find(updatedArray, { Name: "Other Deduction" });
    const miscDb = _.find(updatedArray, { Name: "Misc Debit" });
    const creditNote = _.find(updatedArray, { Name: "Direct Credit Note" });
    const tcsonPurchase = _.find(updatedArray, { Name: "TCS On Purchase" });

    const amt = formatNumber(GrossAmt?.textContent) - formatNumber(updatedArray[TaxIndex]["billAmount"]);
    updatedArray[grossIndex]["billAmount"] = GrossAmt?.textContent;
    updatedArray[grossIndex]["CumulativeAmount"] = formatNumber(GrossAmt?.textContent) + formatNumber(updatedArray[grossIndex]["PreviousBillAmount"]);
    updatedArray[netIndex]["billAmount"] = amt;
    const netPayable =
        formatNumber(amt) -
        formatNumber(getAdRec?.billAmount) +
        formatNumber(getTdsAdRec?.billAmount) -
        formatNumber(poReten?.billAmount) -
        formatNumber(addHold?.billAmount) -
        formatNumber(otherdetuct?.billAmount) +
        formatNumber(tcsonPurchase?.billAmount) -
        formatNumber(miscDb?.billAmount) -
        formatNumber(creditNote?.billAmount);
    updatedArray[netPayIn]["billAmount"] = RoundNum(+netPayable);
    updatedArray[netPayIn]["CumulativeAmount"] = RoundNum(formatNumber(netPayable) + formatNumber(updatedArray[netPayIn]["PreviousBillAmount"]));

    dispatch({ type: GET_INVOICE_CALC_VIEW_UPDATE, payload: updatedArray });
};
