import { useState, useEffect } from "react";

const UseForm = (callback, validate, data) => {
    const InitialState = {
        firstname: "",
        lastname: "",
        email: "",
        emailaddress: "",
        mobilenumber: "",
        oldpassword: "",
        newpassword: "",
        password2: "",
        phone_number: "",
        password: "",
        confirmpassword: "",
        user_emailaddress: "",
        organisation_country: "",
        user_name: "",
        address_line1: "",
        zipcode: "",
        registration_no: "",
        url: "",
        country: "",
        country_id: "",
        isChecked: "",
        search: "",
        optioncount: "",
        entrycount: "",
        role_id: "",
        emid: "",
        allfields: "",
        invoicenumber: "",
        vendor_name: "",
        locationurl: "",
        serverurl: "",
        username: "",
        image: "",
        id: "",
        code_name: "",
        postalcode: "",
        status_id: "",
        invoice_no: "",
        org_number_format: "",
        org_date_format: "",
        org_country_name: "",
        org_country_id: "",
        logo: "",
        role_name: "",
        role_description: "",
        transfer_owner_emailaddress: "",
        transfer_owner_emailaddressId: "",
        prefix: "",
        suffix: "",
        next_no: "",
        padding: "",
        fy_end: "0",
        fy_start: "0",
        organisation_name: "",
        organisation_code: "",
        tolerance: "",
        currency_id: "",
        is_parking: true,
        three_way_matching: true,
        match_netamount: true,
        retention_recovery: true,
        advance_recovery: false,
    };

    const [values, setValues] = useState(InitialState);
    const [isSubmit, setSubmit] = useState(false);
    const [errors, setErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setSubmit(false);
        setValues({
            ...values,
            [name]: value,
        });
    };
    const checkChange = (e) => {
        const { name, checked } = e.target;
        setValues({
            ...values,
            [name]: checked,
        });
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        setErrors(validate(values));
        setIsSubmitting(true);
    };
    useEffect(() => {
        if (Object.keys(errors).length === 0 && isSubmitting) {
            callback();
        }
    }, [isSubmitting, errors]);
    useEffect(() => {
        if (data) {
            setValues({
                ...values,
                ...data,
            });
        }
    }, [data]);

    return {
        setIsSubmitting,
        handleChange,
        checkChange,
        handleSubmit,
        values,
        setValues,
        errors,
        setErrors,
        InitialState,
        isSubmit,
        setSubmit,
    };
};

export default UseForm;
