import { Link } from "react-router-dom";
import Button from "../../../components/Button";
import Input from "../../../components/Input";
import Card from "../../../components/UI/Card";
import Main from "../../../components/UI/Main";
import { styled } from "@mui/material/styles";
import Layout from "../../../Hoc/Layout";
import Footer from "../../../organisms/Footer";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useContext, useState, useRef } from "react";
import ReactDOM from "react-dom";
import TogglePopup from "../../organisations/Toggle";
import { Checkbox, ClickAwayListener, Fade, FormControl, FormControlLabel, MenuItem, Paper, Popper, Select, TextField } from "@mui/material";
import Loader from "../../../components/Loader";
import {
    GET_CUSTOM_REPORTS_DELETE_RESET,
    GET_CUSTOM_REPORTS_PUBLISH_UNPUBLISH_RESET,
    GET_PREVIEW_DATA_RESET,
    POST_TABLER_DATA_RESET,
    USER_LIST_DROP_RESET,
} from "../../../redux/types/types";
import { ThemeContext } from "../../../Hooks/Themes/Themeprovider";
import CreateReport from "./CreateReports";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import {
    CustomReportsList,
    CustomreportsConfigDelete,
    CustomreportsConfigList,
    CustomreportsPublishUnpublish,
} from "../../../redux/actions/output-management";
import Swal from "sweetalert2";
import { UserDropDown } from "../../../redux/actions/user";
let options = null;
let isChecked;
let ReqObj;
let filterObject;
const ReportSummaryList = (props) => {
    let isToggle = true;
    const { currentMode, toggleMode } = useContext(ThemeContext);
    const textColor = currentMode !== "dark" ? `#ECECEC` : `#000`;
    const dispatch = useDispatch();
    const { requestComplete, data, fetching, error } = useSelector((state) => state?.customReportsDeleteState);
    const publishData = useSelector((state) => state?.customReportsPublishUnpublishState);
    const [selected, setSelected] = useState("0");
    const [selectedUserIds, setSelectedUserIds] = useState([]);
    // const [currentPageNo, changePageNo] = useState(1);
    const [currentPageItem, changePageItem] = useState(10);
    const { data: tblelist, requestComplete: ReqCom } = useSelector((state) => state?.customReportsConfigListState);
    const requestObject = { orgid: localStorage.getItem("orgId"), pgno: 1, reccnt: 100, statustype: 0, reporttype: 0, outputtype: 0 };
    const { configId, requestComplete: reqComp, fetching: fetch } = useSelector((state) => state?.customReportsConfigListState);
    const [id, setCustomReportId] = useState("");
    const [onDelete, setonDelete] = useState(false);
    const [confirmUserState, setUserInfoState] = useState(false);
    const dataList = tblelist?.datalist?.[0]?.data_info;
    const record_count = tblelist?.datalist?.[0]?.record_count;
    const [reportValue, setReportValue] = useState("");
    const [openCreateReport, setOpenCreateReport] = useState(false);
    const [filterStatus, setFilterStatus] = useState("");
    const [isPublish, setIsPublish] = useState(1);
    const dropdownColor = currentMode === "dark" ? "#333333" : `white`;
    const dropdownText = currentMode === "dark" ? "#ECECEC" : `#555555`;
    const dropdownShadow = currentMode !== "dark" ? "0px 4px 20px rgba(238, 238, 238, 0.5)" : "";
    const dropdownBorder = currentMode !== "dark" ? "223, 225, 226, 0.56" : "";
    const [selectedValue, setSelectedValue] = useState("0");
    const [orgId, setOrgId] = useState(localStorage.getItem("orgId"));
    const page = localStorage.getItem("currentPageNo");
    const [currentPageNo, changePageNo] = useState(page !== null ? +page : 1);
    const [totalPageCount, totalPageCOunt] = useState(0);
    const [isIndeterminate, setIndeterminate] = useState(false);
    const changeSelectOptionHandler = (event) => {
        if (event?.target?.value == 0) {
            setSelectedValue("0");
        }
        setSelected(event?.target?.value);
    };
    const [checkSelected, setCheckSelected] = useState(false);
    const pageNo = props?.currentPageNo;
    const entries = props?.currentPageItem;
    const pageCount = props?.listCount && props?.listCount ? props?.listCount?.func_get_reports_taskcount : 0;
    let start = (pageNo - 1) * entries + 1;

    const nextPageCount = Math.ceil(pageCount / props?.currentPageItem);

    const onPageNumberSelect = (event) => {
        let pageNumber = event.target.value;
        props?.changePageNo(parseInt(pageNumber));
    };
    useEffect(() => {
        dispatch({ type: "TOGGLE_CHECKBOX_RESET" });
        dispatch({ type: "STORE_REPORT_DATA_RESET" });
        dispatch({ type: GET_PREVIEW_DATA_RESET });
        dispatch({ type: POST_TABLER_DATA_RESET });
        dispatch({ type: "VIEW_DATA_RESET" });
        dispatch({ type: "CHECK_FILTER_ADDED_RESET" });
    }, []); //Reset when user after return report list page..

    useEffect(() => {
        setOrgId(localStorage.getItem("orgId"));
    }, [localStorage.getItem("orgId")]);
    useEffect(() => {
        const reqObj = {
            orgid: orgId,
            pgno: 1,
            reccnt: 100,
            statustype: selected === "status" ? selectedValue : 0,
            reporttype: selected === "reports" ? selectedValue : 0,
            outputtype: selected === "type" ? selectedValue : 0,
        };
        //  dispatch(getReportsAccrualList(reqStr));
        dispatch(CustomreportsConfigList(reqObj));
    }, [dispatch, selectedValue, orgId]);

    const handleSelectedValue = (event) => {
        setCheckSelected(false);
        setSelectedValue(event?.target?.value);
        changePageNo(1);
    };
    const [isAllSelected, setAllSelected] = useState(false);
    const userListData = useSelector((state) => state?.userListDropDownState);
    const { data: userList } = userListData;
    const statuArr = [
        { name: "Published", value: 1 },
        { name: "Unpublished", value: 2 },
    ];
    const reportArr = [
        { name: "Custom Reports", value: 1 },
        { name: "Scheduled Reports", value: 2 },
    ];
    const reportType = [
        { name: "Tabular Reports", value: 1 },
        { name: "Matrix Reports", value: 2 },
        { name: "Charts", value: 3 },
        { name: "JSON", value: 4 },
    ];

    let type = null;
    if (selected == "status") {
        type = statuArr;
    } else if (selected == "reports") {
        type = reportArr;
    } else if (selected == "type") {
        type = reportType;
    }

    options = type?.map((el, i) => (
        <MenuItem key={i} value={el?.value} className="truncate trunc-1 block">
            {el?.name}
        </MenuItem>
    ));
    if (type == statuArr) {
        options = type?.map((el, i) => (
            <MenuItem key={i} value={el?.value} className="truncate trunc-1 block">
                {el?.name}
            </MenuItem>
        ));
    }
    if (type == reportArr) {
        options = type?.map((el, i) => (
            <MenuItem key={i} value={el?.value} className="truncate trunc-1 block">
                {el?.name}
            </MenuItem>
        ));
    }
    if (type == reportType) {
        options = type?.map((el, i) => (
            <MenuItem key={i} value={el?.value} className="truncate trunc-1 block">
                {el?.name}
            </MenuItem>
        ));
    }

    const SubmitPublish = (e, ID, index, type) => {
        setIsPublish(e);
        if (e == true && type === 1) {
            childCompRef.current.handleClick("bottom-end", index, e);
        }
        if (e == false) {
            setIsPublish(1);
        }
        if (e == true && type === 2) {
            setIsPublish(2);
        }
        ReqObj = {
            publish_info: e == true ? [{ type: type, value: [] }] : null,
            statusId: e,
            id: +ID,
        };
        dispatch(CustomreportsPublishUnpublish(ReqObj));
    };

    const childCompRef = useRef();

    const [popstate, setPopState] = useState({
        value: null,
        anchorEl: null,
        id: null,
    });
    const [placement, setPlacement] = useState();

    const handleClickAway = () => {
        setPopState({ id: false, anchorEl: false, value: "" });
        setUserInfoState(true);
    };
    const OpenReport = (value) => {
        setOpenCreateReport(true);
        handleClickAway();
        if (value == "edit") {
            setReportValue(value);
        }
    };
    const handleCheckboxChange = (userId) => {
        // Check if the user ID is already in the array
        const isSelected = selectedUserIds?.includes(userId);

        // If the user is selected, remove them from the array; otherwise, add them
        if (isSelected) {
            setSelectedUserIds((prevIds) => prevIds?.filter((id) => id !== userId));
        } else {
            setSelectedUserIds((prevIds) => [...prevIds, userId]);
        }
    };

    const CountCalc = pageCount / props?.currentPageItem;
    const Rounded = Math.ceil(CountCalc);

    let EmptyArr = [];
    for (let i = 0; i < Rounded; i++) {
        EmptyArr.push(i);
    }
    const previousBtnClick = () => {
        if (isAllSelected === true) {
            setAllSelected(false);
        }
        if (isIndeterminate == true) {
            setIndeterminate(false);
        }
        props?.changePageNo(props?.currentPageNo - 1);
    };
    const nextBtnClick = () => {
        if (isAllSelected === true) {
            setAllSelected(false);
        }
        if (isIndeterminate == true) {
            setIndeterminate(false);
        }
        props?.changePageNo(props?.currentPageNo + 1);
    };
    const deleteHandleClick = (id) => {
        Swal.fire({
            text: "Are you sure you want to delete this custom report from this organization?",
            icon: "warning",
            // closeOnClickOutside: false,
            showCloseButton: true,
            showCancelButton: true,
            buttons: {
                cancel: "Cancel",
                confirm: "Confirm",
            },
        }).then((confirm) => {
            if (confirm?.isConfirmed) {
                const deleteObj = {
                    id: parseInt(id),
                };
                dispatch(CustomreportsConfigDelete(deleteObj));
                setCustomReportId(id);
                // dispatch({ type: USER_ROLEBYORG_VIEW_RESET });
            }
        });
    };
    if (requestComplete && data?.status) {
        Swal.fire({
            text: "Deleted Successfully!",
            icon: "success",
            closeOnClickOutside: false,
            showCloseButton: true,
            button: "Ok",
        }).then((confirm) => {
            if (confirm) {
                dispatch(CustomreportsConfigList(requestObject));
                dispatch({ type: GET_CUSTOM_REPORTS_DELETE_RESET });
            }
        });
    }
    if (!requestComplete && error?.status === false) {
        Swal.fire({
            text: error?.message,
            icon: "error",
            closeOnClickOutside: false,
            showCloseButton: true,
            buttons: {
                confirm: true,
            },
        });
    }

    if (publishData?.requestComplete && publishData?.data?.status)
        Swal.fire({
            text: ` ${isPublish == 2 ? "Published Successfully!" : "UnPublished Successfully!"}`,
            icon: "success",
            closeOnClickOutside: false,
            showCloseButton: true,
            button: "Ok",
        }).then((confirm) => {
            if (confirm) {
                dispatch(CustomreportsConfigList(requestObject));
                dispatch({ type: GET_CUSTOM_REPORTS_PUBLISH_UNPUBLISH_RESET });
            }
        });
    if (!publishData?.requestComplete && publishData?.error?.status === false) {
        Swal.fire({
            text: publishData?.error?.message,
            icon: "error",
            closeOnClickOutside: false,
            showCloseButton: true,
            buttons: {
                confirm: true,
            },
        });
    }
    if (configId?.length == 0 && onDelete) {
        setTimeout(() => {
            props?.handleClose();
            // window.location.reload();
            dispatch({ type: GET_CUSTOM_REPORTS_DELETE_RESET });
        }, 1000);
    }
    const UserListReqBody = {
        org_id: +orgId,
        status: 2,
    };
    const handleClick = (newPlacement, value, event) => {
        setPopState({ value, anchorEl: event.currentTarget });
        setPlacement(newPlacement);
        dispatch(UserDropDown(UserListReqBody));
    };
    const [FilterValue, setFilterValue] = useState(0);
    const onFilterItems = (value, id, e, ID, index) => {
        setIsPublish(2);
        setFilterValue(value);
        if (value !== 3 && value !== 0) {
            ReqObj = {
                publish_info: [{ type: value, value: [] }],
                statusId: true,
                id: Number(ID),
            };
            dispatch(CustomreportsPublishUnpublish(ReqObj));
        }
        if (value == 3) {
            setUserInfoState(false);
            handleClick("bottom-end", index, e);
        }
    };
    const filterMenuList = [
        {
            text: "Select",
            value: 0,
            tabIndex: "0",
        },
        {
            text: "Private",
            value: 1,
            tabIndex: "0",
        },
        {
            text: "Everyone",
            value: 2,
            tabIndex: "0",
        },
        {
            text: "Select User",
            value: 3,
            tabIndex: "0",
        },
    ];
    // const AllocListData = useSelector((state) => state?.getInvoiceAllocationListState);
    // var invAllocList = AllocListData?.dataList;
    // let allocationList = [];
    // const [filterUserList, setFilterUserList] = useState([]);
    const filterUserList = [];
    if (popstate?.value == 2) {
        isChecked = true;
    } else {
        isChecked = false;
    }
    const LightTooltip = styled(({ className, ...props }) => (
        <Tooltip
            enterTouchDelay={0}
            {...props}
            PopperProps={{
                sx: {
                    "& .MuiTooltip-tooltip": {
                        padding: "0 5px",
                    },
                },
                modifiers: [
                    {
                        name: "offset",
                        options: {
                            offset: [0, 0],
                        },
                    },
                ],
            }}
            classes={{ popper: className }}
        />
    ))(({}) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: dropdownColor,
            color: dropdownText,
            borderRadius: "10px",
            boxShadow: "0px 4px 20px rgba(85,85,85,0.2)",
        },
        [`& .${tooltipClasses.arrow}`]: {
            width: "30px",
            height: "17px",
            marginTop: "-18px !important",
            "&::before": {
                backgroundColor: "white",
                border: "1px solid #DCDCDC",
            },
        },
    }));
    const SaveUsers = (ID) => {
        setIsPublish(2);
        filterObject = { type: FilterValue, value: selectedUserIds };
        if (FilterValue == 3) {
            ReqObj = {
                publish_info: [{ type: FilterValue, value: selectedUserIds }],
                statusId: true,
                id: Number(ID),
            };
            dispatch(CustomreportsPublishUnpublish(ReqObj));
        }
    };
    const [elId, setId] = useState();
    const publishType = (type, user) => {
        switch (type) {
            case 1:
                return "(Private)";
            case 2:
                return "(Everyone)";
            case 3:
                return `(${user?.length} User${user?.length > 1 ? `s` : ``})`;
            default:
                break;
        }
    };
    const [filteredReports, setFilteredReports] = useState([]);
    const [search, setSearch] = useState("");
    // Handle search filter func for tables...
    const handleSearch = (text) => {
        setSearch(text);
        const filtered = dataList?.filter(
            (report) =>
                report?.report_name?.toLowerCase()?.includes(text.toLowerCase()) ||
                report?.report_description?.toLowerCase()?.includes(text.toLowerCase())
        );
        setFilteredReports(filtered);
    };
    const getChartType = (data) => {
        switch (data) {
            case 1:
                return "Tabular reports";
            case 2:
                return "Matrix reports";
            case 3:
                return "Charts";
            case 4:
                return "JSON";
            case 5:
                return "XML";
            default:
                break;
        }
    };

    return (
        <>
            <Layout>
                <Main>
                    <div className="xl:flex justify-between items-center mb-6">
                        <div className="mb-3 xl:mb-0">
                            <h4 className={`text-2xl font-interSb text-${currentMode}-copy`}>Reports Summary</h4>
                        </div>
                        <div className="grid xl:grid-cols-4 lg:grid-cols-3 grid-cols-2 gap-2 items-center">
                            <div
                                className={`border-${currentMode}-840  border search-step flex items-center justify-between py-[8px] px-[15px] min-w-[250px] max-w-[250px] xl:max-w-[auto] rounded-[8px]`}>
                                <div className="flex-auto">
                                    <Input
                                        placeholder="Search..."
                                        name="search"
                                        onChange={(e) => handleSearch(e?.target?.value)}
                                        className={`border-0 text-${currentMode}-copy bg-transparent max-w-[200px]`}
                                        disableUnderline={true}
                                        value={search}
                                        autoComplete="off"
                                    />
                                </div>
                                <div className="w-[16px]">
                                    {search && search.length !== 0 ? (
                                        <img
                                            onClick={() => {
                                                setSearch("");
                                                setFilteredReports([]);
                                            }}
                                            className="cursor-pointer"
                                            src="/images/common/closeBlk.svg"
                                            alt="close"
                                        />
                                    ) : (
                                        <img src="/images/header/search.svg" alt="search" />
                                    )}
                                </div>
                            </div>
                            <div className="flex space-x-2">
                                <div
                                    className={`flex items-center border-[1px] border-${currentMode}-840 pl-[15px] pr-[10px] py-[8.5px] rounded-[8px] bg-${currentMode}-card text-${currentMode}-copy`}>
                                    <img className="mr-2 convertWhite" src="/images/invoice/filter-grp.svg" alt="filter-grp" />
                                    <span>Filter by:</span>
                                    <FormControl
                                        sx={{
                                            minWidth: 138,
                                        }}>
                                        <Select
                                            name="filter"
                                            onChange={changeSelectOptionHandler}
                                            className="insideFilter Org"
                                            value={selected || "0"}
                                            MenuProps={{
                                                sx: {
                                                    "& .MuiMenu-paper": {
                                                        marginTop: "10px",
                                                    },
                                                    "& .MuiMenuItem-root:hover": {
                                                        backgroundColor: "rgba(218, 249, 244, 0.3)",
                                                    },
                                                    "& .Mui-selected": {
                                                        backgroundColor: "rgba(218, 249, 244, 0.3) !important",
                                                    },
                                                },
                                            }}
                                            IconComponent={() => (
                                                <img
                                                    className="absolute right-[5px] opacity-[.5] pointer-events-none"
                                                    src={
                                                        currentMode !== "dark"
                                                            ? `/images/header/chevronDown.svg`
                                                            : `/images/header/chevronDown-white.svg`
                                                    }
                                                    alt="chevronDown"
                                                />
                                            )}
                                            SelectDisplayProps={{
                                                style: {
                                                    display: "flex",
                                                    border: 0,
                                                    color: currentMode === "dark" ? `#ECECEC` : `#555555`,
                                                    paddingRight: "30px",
                                                },
                                            }}
                                            displayEmpty>
                                            <MenuItem value="0">Select</MenuItem>

                                            <MenuItem value="status">
                                                <img className="mr-2 convertWhite" src="/images/invoice/status.svg" alt="status" /> Status
                                            </MenuItem>

                                            <MenuItem value="reports">
                                                <img
                                                    className="mr-2 convertWhite"
                                                    src="/images/Output-manage/reports-common.svg"
                                                    alt="user-plusblk"
                                                />
                                                Reports
                                            </MenuItem>
                                            <MenuItem value="type">
                                                <img className="mr-2 convertWhite" src="/images/Output-manage/reports-type.svg" alt="calendar" />
                                                Report Type
                                            </MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>
                            <div
                                className={`flex items-center min-w-[250px] max-w-[250px] xl:max-w-[auto] border-[1px] border-${currentMode}-840 pl-[15px] pr-[10px] py-[10px] rounded-[8px] bg-${currentMode}-card`}>
                                <FormControl fullWidth>
                                    <Select
                                        name="filter"
                                        className="insideFilter Org"
                                        disabled={selected == "" || selected == undefined}
                                        onChange={handleSelectedValue}
                                        value={selectedValue || "0"}
                                        fullWidth
                                        displayEmpty
                                        MenuProps={{
                                            sx: {
                                                "& .MuiMenu-paper": {
                                                    marginTop: "10px",
                                                    borderRadius: "8px",
                                                    maxWidth: "30rem",
                                                    top: "304rem",
                                                },
                                                "& .MuiMenuItem-root:hover": {
                                                    backgroundColor: "rgba(218, 249, 244, 0.3)",
                                                },
                                                "& .Mui-selected": {
                                                    backgroundColor: "rgba(218, 249, 244, 0.3)",
                                                },
                                            },
                                        }}
                                        IconComponent={() => (
                                            <img
                                                className="absolute right-[5px] opacity-[.5] pointer-events-none"
                                                src={
                                                    currentMode !== "dark" ? `/images/header/chevronDown.svg` : `/images/header/chevronDown-white.svg`
                                                }
                                                alt="chevronDown"
                                            />
                                        )}
                                        SelectDisplayProps={{
                                            style: {
                                                paddingTop: 1,
                                                paddingBottom: 1,
                                                width: "11rem",
                                                color: currentMode === "dark" ? `#ECECEC` : `#555555`,
                                                paddingLeft: "0",
                                                border: 0,

                                                paddingRight: "10px",
                                            },
                                        }}>
                                        <MenuItem value={0}>Select</MenuItem>
                                        {options}
                                    </Select>
                                </FormControl>
                            </div>

                            <Button
                                title="Create Reports"
                                onClick={OpenReport}
                                className={`border-20 min-w-[250px] max-w-[250px] xl:max-w-[auto] justify-center border-${currentMode}-800 bg-light-20 px-5 py-[9px] rounded-[8px] font-interM text-sm flex items-center border-[1px] text-white`}>
                                Create Reports
                            </Button>
                        </div>
                    </div>
                    <Card
                        className={`h-[calc(100vh_-_20rem)] relative xl:h-[calc(100vh_-_13rem)] ${
                            currentMode === "dark" ? "" : "shadow-[0px_4px_20px_rgba(238,238,238,0.5)]"
                        }  overflow-y-auto`}>
                        <table className="rolemasterTable lastBorder text-left">
                            <thead>
                                <tr>
                                    <th>Report Name</th>
                                    <th>Description</th>
                                    <th>Report Type</th>
                                    <th>Status</th>
                                    <th>State</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {tblelist?.datalist?.length === 0 ? (
                                    <div className="absolute top-[50%] left-[50%] translate-y-[-50%] translate-x-[-50%]">
                                        <img src="/images/common/delivery.png" alt="delivery" />
                                        <h4 className="font-interSb text-center mt-3">No data found</h4>
                                    </div>
                                ) : ReqCom ? (
                                    filteredReports?.length == 0 && search == "" ? (
                                        dataList?.map((elem, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td title={elem?.report_name} className="truncate max-w-[200px]">
                                                        {elem?.report_name}
                                                    </td>
                                                    <td title={elem?.report_description} className="truncate max-w-[200px]">
                                                        {elem?.report_description}
                                                    </td>
                                                    <td>
                                                        {elem?.report_type === 1 ? (
                                                            <div>
                                                                <p>Custom Report</p>
                                                                <p className="text-xs mt-2">{`(${getChartType(elem?.output_type)})`}</p>
                                                            </div>
                                                        ) : (
                                                            <div>
                                                                <p>Scheduled Report</p>
                                                                <p className="text-xs mt-2">{`(${getChartType(elem?.output_type)})`}</p>
                                                            </div>
                                                        )}
                                                    </td>
                                                    <td>
                                                        {elem?.status === true ? <p>Published</p> : <p>UnPublished</p>}
                                                        {elem?.publish_to !== null && (
                                                            <span className="block text-xs">
                                                                {publishType(elem?.publish_to?.[0]?.type, elem?.publish_to?.[0]?.value)}
                                                            </span>
                                                        )}
                                                        <LightTooltip
                                                            title={
                                                                <div className="flex items-start p-3 rounded-[10px]">
                                                                    <div className="relative">
                                                                        <img className="w-[24px] h-[24px]" src="/images/common/orgLogo.png" />
                                                                    </div>
                                                                </div>
                                                            }>
                                                            <div onTouchStart={(e) => e.preventDefault()}></div>
                                                        </LightTooltip>
                                                    </td>
                                                    <td>
                                                        <Popper
                                                            className={`bg-${currentMode}-card text-${currentMode}-copy  border-[1px] z-[13] border-${currentMode}-700 shadow-[0px_4px_20px_rgba(238,238,238,0.5)] w-full max-w-[350px] rounded-[10px] reportsPopper `}
                                                            open={index === popstate.value}
                                                            anchorEl={popstate.anchorEl}
                                                            id={index}
                                                            placement={placement}
                                                            disablePortal={true}>
                                                            <div
                                                                className={`py-[10px] flex justify-between px-4 border-b border-${currentMode}-1240`}>
                                                                <span className="font-interM">Add Users</span>
                                                                <img
                                                                    onClick={() => {
                                                                        setUserInfoState(true);
                                                                        dispatch({ type: USER_LIST_DROP_RESET });
                                                                        setSelectedUserIds([]);
                                                                        handleClickAway();
                                                                    }}
                                                                    className="cursor-pointer"
                                                                    src="/images/common/closeBlk.svg"
                                                                    alt="closeBlk"></img>
                                                            </div>
                                                            <ul className="px-4 py-3 rprt-users">
                                                                {userList?.length !== 0 ? (
                                                                    userList?.map((el, i) => (
                                                                        <li
                                                                            key={el?.id}
                                                                            title={el?.user_name}
                                                                            className={`transition duration-300 ease-in-out mx-[-17px] py-1 px-[20px] hover:bg-${currentMode}-960`}>
                                                                            <FormControlLabel
                                                                                className=""
                                                                                control={
                                                                                    <>
                                                                                        <Checkbox
                                                                                            size="small"
                                                                                            sx={{
                                                                                                padding: 0,
                                                                                                color: "#008785",
                                                                                                margin: "0 12px",
                                                                                                "&.Mui-checked": {
                                                                                                    color: "#008785",
                                                                                                },
                                                                                            }}
                                                                                            checked={selectedUserIds?.includes(el?.id)}
                                                                                            onChange={() => handleCheckboxChange(el?.id)}
                                                                                        />
                                                                                        <img
                                                                                            src={`data:image;base64,${el?.user_image}`}
                                                                                            alt="userImg"
                                                                                            className="w-[22px] h-[22px] mr-3"
                                                                                        />
                                                                                    </>
                                                                                }
                                                                                label={el?.user_name}
                                                                            />
                                                                        </li>
                                                                    ))
                                                                ) : userListData?.fetching ? (
                                                                    <div className="w-[100px] mx-auto">
                                                                        <Loader />
                                                                    </div>
                                                                ) : (
                                                                    <p>No Users Found</p>
                                                                )}
                                                                <div className="space-x-2 mt-4">
                                                                    <Button
                                                                        className={`text-white bg-light-20 px-[25px] py-[8px] rounded-[8px]`}
                                                                        disabled={selectedUserIds?.length == 0}
                                                                        onClick={() => {
                                                                            SaveUsers(elId);
                                                                            setUserInfoState(true);
                                                                            setSelectedUserIds([]);
                                                                            dispatch({ type: USER_LIST_DROP_RESET });
                                                                            handleClickAway();
                                                                        }}>
                                                                        {"Save"}
                                                                    </Button>
                                                                    <Button
                                                                        className={`bg-light-240 text-light-40 px-[25px] py-[8px] rounded-[8px]`}
                                                                        onClick={() => {
                                                                            setUserInfoState(true);
                                                                            setSelectedUserIds([]);
                                                                            dispatch({ type: USER_LIST_DROP_RESET });
                                                                            handleClickAway();
                                                                        }}>
                                                                        {"Cancel"}
                                                                    </Button>
                                                                </div>
                                                            </ul>
                                                        </Popper>

                                                        {elem?.status === true && (
                                                            <Button
                                                                title="UnPublish"
                                                                className={`border duration-500 px-[15px] py-[5px] rounded-[8px] ${
                                                                    elem?.status ? "text-black" : "text-white"
                                                                } ${elem?.status ? "bg-[#F0F0F0]" : "bg-light-20"}`}
                                                                disabled={publishData?.fetching}
                                                                onClick={() =>
                                                                    SubmitPublish(false, elem?.id, index, elem?.report_type === 2 ? 2 : 1)
                                                                }>
                                                                {"UnPublish"}
                                                            </Button>
                                                        )}

                                                        {elem?.status === false && elem?.report_type === 2 && (
                                                            <Button
                                                                title="Publish"
                                                                className={`border duration-500 px-[15px] py-[5px] rounded-[8px] ${
                                                                    elem?.status ? "text-black" : "text-white"
                                                                } ${elem?.status ? "bg-[#F0F0F0]" : "bg-light-20"}`}
                                                                onClick={() => SubmitPublish(true, elem?.id, index, 2)}>
                                                                {"Publish"}
                                                            </Button>
                                                        )}
                                                        {elem?.status == false && elem?.report_type === 1 && (
                                                            <>
                                                                <Button
                                                                    title="Publish"
                                                                    className={`border duration-500 px-[15px] py-[5px] rounded-[8px] ${
                                                                        elem?.status ? "text-black" : "text-white"
                                                                    } ${elem?.status ? "bg-[#F0F0F0]" : "bg-light-20"}`}
                                                                    onClick={(e) => {
                                                                        childCompRef.current.handleClick("bottom-end", 1, e);
                                                                        setId(elem?.id);
                                                                    }}>
                                                                    {"Publish"}
                                                                </Button>

                                                                <TogglePopup
                                                                    ref={childCompRef}
                                                                    menuList={filterMenuList}
                                                                    isToggle={isToggle}
                                                                    isChecked={confirmUserState}
                                                                    isOpen={openCreateReport}
                                                                    selectedItemChange={(value, id, e) => onFilterItems(value, id, e, elId, index)}
                                                                    className="w-[150px]"
                                                                />
                                                            </>
                                                        )}
                                                    </td>
                                                    <td>
                                                        <div className="flex space-x-4">
                                                            <Link
                                                                title="Edit"
                                                                to={`/reports/scheduled?id=${elem?.id}&dataset=${elem?.dataset_id}&type=${elem?.report_type}&prev=${elem?.output_type}&name=${elem?.report_name}`}>
                                                                <img src="/images/common/pencil-green.svg" alt="pencil-green" title="Edit" />
                                                            </Link>
                                                            <Button title="Delete" onClick={() => deleteHandleClick(elem?.id)}>
                                                                <img src="/images/Output-manage/delete-icon.svg" alt="delete" />
                                                            </Button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            );
                                        })
                                    ) : filteredReports?.length !== 0 ? (
                                        filteredReports?.map((elem, index) => (
                                            <tr key={index}>
                                                <td title={elem?.report_name} className="truncate max-w-[200px]">
                                                    {elem?.report_name}
                                                </td>
                                                <td title={elem?.report_description} className="truncate max-w-[200px]">
                                                    {elem?.report_description}
                                                </td>
                                                <td>
                                                    {elem?.report_type === 1 ? (
                                                        <div>
                                                            <p>Custom Report</p>
                                                            {elem?.output_type === 1 ? (
                                                                <p className="text-xs mt-2">(Tabular reports)</p>
                                                            ) : elem?.output_type === 2 ? (
                                                                <p className="text-xs mt-2">(Matrix reports)</p>
                                                            ) : elem?.output_type === 3 ? (
                                                                <p className="text-xs mt-2">(Charts)</p>
                                                            ) : null}
                                                        </div>
                                                    ) : (
                                                        <div>
                                                            <p>Scheduled Report</p>
                                                            {elem?.output_type === 1 ? (
                                                                <p className="text-xs mt-2">(Tabular reports)</p>
                                                            ) : elem?.output_type === 2 ? (
                                                                <p className="text-xs mt-2">(Matrix reports)</p>
                                                            ) : elem?.output_type === 3 ? (
                                                                <p className="text-xs mt-2">(Charts)</p>
                                                            ) : null}
                                                        </div>
                                                    )}
                                                </td>
                                                <td>
                                                    {elem?.status === true ? <p>Published</p> : <p>UnPublished</p>}
                                                    {elem?.publish_to !== null && (
                                                        <span className="block text-xs">
                                                            {publishType(elem?.publish_to?.[0]?.type, elem?.publish_to?.[0]?.value)}
                                                        </span>
                                                    )}
                                                    <LightTooltip
                                                        title={
                                                            <div className="flex items-start p-3 rounded-[10px]">
                                                                <div className="relative">
                                                                    <img className="w-[24px] h-[24px]" src="/images/common/orgLogo.png" />
                                                                </div>
                                                            </div>
                                                        }>
                                                        <div onTouchStart={(e) => e.preventDefault()}></div>
                                                    </LightTooltip>
                                                </td>
                                                <td>
                                                    <Popper
                                                        className={`bg-${currentMode}-card text-${currentMode}-copy  border-[1px] z-[13] border-${currentMode}-700 shadow-[0px_4px_20px_rgba(238,238,238,0.5)] w-full max-w-[350px] rounded-[10px] reportsPopper `}
                                                        open={index === popstate.value}
                                                        anchorEl={popstate.anchorEl}
                                                        id={index}
                                                        placement={placement}
                                                        disablePortal={true}>
                                                        <div className={`py-[10px] flex justify-between px-4 border-b border-${currentMode}-1240`}>
                                                            <span className="font-interM">Add Users</span>
                                                            <img
                                                                onClick={() => {
                                                                    setUserInfoState(true);
                                                                    dispatch({ type: USER_LIST_DROP_RESET });
                                                                    setSelectedUserIds([]);
                                                                    handleClickAway();
                                                                }}
                                                                className="cursor-pointer"
                                                                src="/images/common/closeBlk.svg"
                                                                alt="closeBlk"></img>
                                                        </div>
                                                        <ul className="px-4 py-3">
                                                            {userList?.length !== 0 ? (
                                                                userList?.map((el, i) => (
                                                                    <li
                                                                        key={el?.id}
                                                                        className={`transition duration-300 ease-in-out mx-[-17px] py-1 px-[20px] hover:bg-${currentMode}-960`}>
                                                                        <FormControlLabel
                                                                            control={
                                                                                <>
                                                                                    <Checkbox
                                                                                        size="small"
                                                                                        sx={{
                                                                                            padding: 0,
                                                                                            color: "#008785",
                                                                                            margin: "0 12px",
                                                                                            "&.Mui-checked": {
                                                                                                color: "#008785",
                                                                                            },
                                                                                        }}
                                                                                        checked={selectedUserIds?.includes(el?.id)}
                                                                                        onChange={() => handleCheckboxChange(el?.id)}
                                                                                    />
                                                                                    <img
                                                                                        src="/images/common/userImg.svg"
                                                                                        alt="userImg"
                                                                                        className="w-[22px] h-[22px] mr-3"
                                                                                    />
                                                                                </>
                                                                            }
                                                                            label={el?.user_name}
                                                                        />
                                                                    </li>
                                                                ))
                                                            ) : userListData?.fetching ? (
                                                                <div className="w-[100px] mx-auto">
                                                                    <Loader />
                                                                </div>
                                                            ) : (
                                                                <p>No Users Found</p>
                                                            )}
                                                            <div className="space-x-2 mt-4">
                                                                <Button
                                                                    className={`text-white bg-light-20 px-[25px] py-[8px] rounded-[8px]`}
                                                                    disabled={selectedUserIds?.length == 0}
                                                                    title="Save"
                                                                    onClick={() => {
                                                                        SaveUsers(elId);
                                                                        setUserInfoState(true);
                                                                        setSelectedUserIds([]);
                                                                        dispatch({ type: USER_LIST_DROP_RESET });
                                                                        handleClickAway();
                                                                    }}>
                                                                    {"Save"}
                                                                </Button>
                                                                <Button
                                                                    title="Cancel"
                                                                    className={`text-${currentMode}-40 bg-${currentMode}-240 px-[25px] py-[8px] rounded-[8px]`}
                                                                    onClick={() => {
                                                                        setUserInfoState(true);
                                                                        setSelectedUserIds([]);
                                                                        dispatch({ type: USER_LIST_DROP_RESET });
                                                                        handleClickAway();
                                                                    }}>
                                                                    {"Cancel"}
                                                                </Button>
                                                            </div>
                                                        </ul>
                                                    </Popper>

                                                    {elem?.status === true && (
                                                        <Button
                                                            title="UnPublish"
                                                            className={`border duration-500 px-[15px] py-[5px] rounded-[8px] ${
                                                                elem?.status ? "text-black" : "text-white"
                                                            } ${elem?.status ? "bg-[#F0F0F0]" : "bg-light-20"}`}
                                                            disabled={publishData?.fetching}
                                                            onClick={() => SubmitPublish(false, elem?.id, index, elem?.report_type === 2 ? 2 : 1)}>
                                                            {"UnPublish"}
                                                        </Button>
                                                    )}

                                                    {elem?.status === false && elem?.report_type === 2 && (
                                                        <Button
                                                            title="Publish"
                                                            className={`border duration-500 px-[15px] py-[5px] rounded-[8px] ${
                                                                elem?.status ? "text-black" : "text-white"
                                                            } ${elem?.status ? "bg-[#F0F0F0]" : "bg-light-20"}`}
                                                            onClick={() => SubmitPublish(true, elem?.id, index, 2)}>
                                                            {"Publish"}
                                                        </Button>
                                                    )}
                                                    {elem?.status == false && elem?.report_type === 1 && (
                                                        <>
                                                            <Button
                                                                title="Publish"
                                                                className={`border duration-500 px-[15px] py-[5px] rounded-[8px] ${
                                                                    elem?.status ? "text-black" : "text-white"
                                                                } ${elem?.status ? "bg-[#F0F0F0]" : "bg-light-20"}`}
                                                                onClick={(e) => {
                                                                    childCompRef.current.handleClick("bottom-end", 1, e);
                                                                    setId(elem?.id);
                                                                    setUserInfoState(true);
                                                                }}>
                                                                {"Publish"}
                                                            </Button>
                                                            <TogglePopup
                                                                ref={childCompRef}
                                                                menuList={filterMenuList}
                                                                isToggle={isToggle}
                                                                isOpen={openCreateReport}
                                                                isChecked={confirmUserState}
                                                                selectedItemChange={(value, id, e) => onFilterItems(value, id, e, elId, index)}
                                                                className="w-[150px]"
                                                            />
                                                        </>
                                                    )}
                                                </td>
                                                <td>
                                                    <div className="flex space-x-4">
                                                        <Link
                                                            to={`/reports/scheduled?id=${elem?.id}&dataset=${elem?.dataset_id}&type=${elem?.report_type}&prev=${elem?.output_type}&name=${elem?.report_name}`}>
                                                            <img src="/images/common/pencil-green.svg" alt="pencil-green" title="Edit" />
                                                        </Link>
                                                        <Button onClick={() => deleteHandleClick(elem?.id)}>
                                                            <img src="/images/Output-manage/delete-icon.svg" title="delete" alt="delete" />
                                                        </Button>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))
                                    ) : (
                                        <div className="absolute top-[50%] left-[50%] translate-y-[-50%] translate-x-[-50%]">
                                            <img src="/images/common/delivery.png" alt="delivery" />
                                            <h4 className="font-interSb text-center mt-3">No data found</h4>
                                        </div>
                                    )
                                ) : (
                                    <Loader className="absolute left-[50%] translate-x-[-50%] top-[50%] translate-y-[-50%]" />
                                )}
                            </tbody>
                        </table>
                    </Card>
                </Main>
                <Footer />
            </Layout>
            {ReactDOM.createPortal(
                <CreateReport
                    isOpened={openCreateReport}
                    reportValue={reportValue}
                    setReportValue={setReportValue}
                    handleClose={() => setOpenCreateReport(false)}
                    className={openCreateReport && "Show"}
                    handleOpen={() => setOpenCreateReport(true)}
                />,
                document.getElementById("user-modal-root")
            )}
        </>
    );
};

export default ReportSummaryList;
