import Card from "../../components/UI/Card";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Bar } from "recharts";
import {
    FormControl,
    MenuItem,
    Paper,
    Select,
    TextField,
    Autocomplete,
    Chip,
    CircularProgress,
    ClickAwayListener,
    Popper,
    Fade,
} from "@mui/material";
import { DateRangePicker } from "@mui/x-date-pickers";
import { useContext, useState, useEffect } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import moment from "moment";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Button from "../../components/Button";
import { Barchart } from "../../organisms/BarChart";
import { ThemeContext } from "../../Hooks/Themes/Themeprovider";
import { useDispatch } from "react-redux";
import { getLicenseUsageData } from "../../redux/actions/report";
import { useSelector } from "react-redux";
import Loader from "../../components/Loader";

export const BillingChart = () => {
    const [selected, setSelected] = useState(2);
    const currentDate = new Date();
    const [fromDate, setFromDate] = useState(currentDate);
    const [toDate, setToDate] = useState(currentDate);
    function getMonthName(number) {
        const date = new Date();
        date.setMonth(number - 1); // Months are zero-based (0 - 11)

        const monthName = date.toLocaleString("default", { month: "short" });
        return monthName;
    }
    let getFrom = moment(currentDate).format("MM-YYYY")?.split("-");
    let getTo = moment(currentDate).format("MM-YYYY")?.split("-");

    const [showFrom, setShowFrom] = useState(getMonthName(getFrom[0]) + "-" + getFrom[1]);
    const [showTo, setShowTo] = useState(getMonthName(getTo[0]) + "-" + getTo[1]);
    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = useState(null);
    const [open, setOpen] = useState(false);
    const [placement, setPlacement] = useState();
    const lastDateOfYear = `12/31/${new Date().getFullYear()}`;
    const { dataList, fetching, requestComplete } = useSelector((state) => state?.getLicenseUsageState);
    const modifiedData = dataList?.map((item) => ({
        invcount: item?.invcount,

        Invoices: item?.invlist,
    }));

    useEffect(() => {
        const getEnd = moment(new Date(toDate)).format("MM-YYYY")?.split("-");
        const endDate = new Date(getEnd[1], getEnd[0], 0);
        const lastDayOfMonth = endDate.getDate();
        const fromParse = moment(new Date(fromDate)).format("YYYY-MM").toString() + "-01";

        const toParse = moment(new Date(toDate)).format("YYYY-MM").toString() + "-" + lastDayOfMonth;
        dispatch(getLicenseUsageData(fromParse, toParse));
    }, [fromDate, toDate]);

    const filterChange = (event) => {
        setSelected(event.target.value);
        if (event.target.value == 1) {
            setFromDate(null);
            setToDate(null);
        }
    };
    const handleValue = (newValue) => {
        if (newValue) {
            setFromDate(newValue);
        }
        getFrom = moment(new Date(newValue)).format("MM-YYYY")?.split("-");
        setShowFrom(getMonthName(getFrom[0]) + "-" + getFrom[1]);
    };
    const handleToDate = (toDate) => {
        if (toDate) {
            setToDate(toDate);
        }
        getTo = moment(new Date(toDate)).format("MM-YYYY")?.split("-");
        setShowTo(getMonthName(getTo[0]) + "-" + getTo[1]);
    };

    const handleClickAway = () => {
        setOpen(false);
    };

    const handleClick = (newPlacement) => (event) => {
        setAnchorEl(event.currentTarget);
        setOpen((prev) => placement !== newPlacement || !prev);
        setPlacement(newPlacement);
    };

    const AccessIcon = () => <img src="/images/common/date-ico.svg" alt="date-ico" />;
    const { currentMode } = useContext(ThemeContext);
    const textColor = currentMode !== "dark" ? `#ECECEC` : `#000`;
    const data = [
        {
            name: "Page A",
            uv: 4000,
            pv: 2400,
            amt: 2400,
        },
        {
            name: "Page B",
            uv: 3000,
            pv: 1398,
            amt: 2210,
        },
        {
            name: "Page C",
            uv: 2000,
            pv: 9800,
            amt: 2290,
        },
        {
            name: "Page D",
            uv: 2780,
            pv: 3908,
            amt: 2000,
        },
        {
            name: "Page E",
            uv: 1890,
            pv: 4800,
            amt: 2181,
        },
        {
            name: "Page F",
            uv: 2390,
            pv: 3800,
            amt: 2500,
        },
        {
            name: "Page G",
            uv: 3490,
            pv: 4300,
            amt: 2100,
        },
    ];

    return (
        <Card className="mb-[20px]">
            <div className={`flex justify-between items-center p-8 border-b-[1px] py-6 border-${currentMode}-700`}>
                <h4 className="font-interM text-lg">Document Usage</h4>
                <div className="flex ">
                    {selected == 2 && (
                        <div className={`border-[1px] border-${currentMode}-840 mr-5 pl-[15px] pr-[10px] py-[8.5px] rounded-[8px]`}>
                            <Button className="flex font-interR mr-auto" onClick={handleClick("bottom-start")}>
                                <img className="mr-2 convertWhite" src="/images/invoice/calendar.svg" alt="calendar" />{" "}
                                {fromDate && toDate ? showFrom + " to " + showTo : "Select data range"}
                            </Button>
                            <ClickAwayListener mouseEvent="onMouseDown" touchEvent="onTouchStart" onClickAway={handleClickAway}>
                                <Popper open={open} anchorEl={anchorEl} placement={placement} className="z-[111] filterPop" transition>
                                    {({ TransitionProps }) => (
                                        <Fade {...TransitionProps} timeout={350}>
                                            <Paper>
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <ul className="space-y-4 px-[15px] py-4">
                                                        <li className="flex justify-between">
                                                            <DesktopDatePicker
                                                                selectsStart
                                                                inputFormat="MM-YYYY"
                                                                className="border-0"
                                                                components={{
                                                                    OpenPickerIcon: AccessIcon,
                                                                }}
                                                                views={["month", "year"]}
                                                                value={fromDate}
                                                                label="From"
                                                                minDate={new Date(1999, 12)}
                                                                maxDate={toDate == null ? new Date(lastDateOfYear) : toDate}
                                                                name="invoice_date_from"
                                                                onChange={handleValue}
                                                                renderInput={(params) => (
                                                                    <TextField
                                                                        sx={{
                                                                            "& .MuiInputBase-input": {
                                                                                color: `${currentMode !== "dark" ? `#000` : `#ECECEC`}`,
                                                                            },
                                                                        }}
                                                                        placeholder={params.inputProps?.placeholder?.replace("From")}
                                                                        {...params}
                                                                        inputProps={{
                                                                            ...params.inputProps,
                                                                            readOnly: true,
                                                                        }}
                                                                    />
                                                                )}
                                                            />
                                                        </li>
                                                        <li className="flex justify-between">
                                                            <DesktopDatePicker
                                                                selectsEnd
                                                                className="border-0"
                                                                inputFormat="MM-YYYY"
                                                                name="invoice_date_to"
                                                                label="To"
                                                                components={{
                                                                    OpenPickerIcon: AccessIcon,
                                                                }}
                                                                views={["month", "year"]}
                                                                value={toDate}
                                                                onChange={handleToDate}
                                                                minDate={fromDate}
                                                                maxDate={new Date(lastDateOfYear)}
                                                                renderInput={(params) => (
                                                                    <TextField
                                                                        sx={{
                                                                            "& .MuiInputBase-input": {
                                                                                color: `${currentMode !== "dark" ? `#000` : `#ECECEC`}`,
                                                                            },
                                                                        }}
                                                                        placeholder={params.inputProps?.placeholder?.replace("To")}
                                                                        {...params}
                                                                        inputProps={{
                                                                            ...params.inputProps,
                                                                            readOnly: true,
                                                                        }}
                                                                    />
                                                                )}
                                                            />
                                                            {/* <img
                            src="/images/common/date-ico.svg"
                            alt="date-ico"
                          /> */}
                                                        </li>
                                                    </ul>
                                                </LocalizationProvider>
                                            </Paper>
                                        </Fade>
                                    )}
                                </Popper>
                            </ClickAwayListener>
                        </div>
                    )}
                    {/* <div className="flex items-center max-w-[120px] border-[1px] border-[(rgba(85,85,85,0.51)] pl-[15px] pr-[10px] py-[8.5px] rounded-[8px] ">
                        <FormControl
                            sx={{
                                minWidth: 100,
                            }}
                        >
                            <Select
                                onChange={filterChange}
                                name="filter"
                                className="Org"
                                defaultValue=""
                                value={selected}
                                MenuProps={{
                                    sx: {
                                        "& .MuiMenu-paper": {
                                            backgroundColor:
                                                "white  !important",
                                            boxShadow:
                                                "0px 4px 20px rgba(238, 238, 238, 0.5)",
                                            border: "1px solid rgba(223, 225, 226, 0.56)",
                                            borderRadius: "8px",
                                        },
                                        "& .MuiMenuItem-root:hover": {
                                            backgroundColor:
                                                "rgba(218, 249, 244, 0.3)",
                                        },
                                        "& .Mui-selected": {
                                            backgroundColor:
                                                "rgba(218, 249, 244, 0.3) !important",
                                        },
                                    },
                                }}
                                IconComponent={() => (
                                    <img
                                        className="relative right-[10px] pointer-events-none"
                                        src="/images/header/chevronDown.svg"
                                    />
                                )}
                                SelectDisplayProps={{
                                    style: {
                                        paddingTop: 1,
                                        display: "flex",
                                        paddingBottom: 1,
                                        paddingLeft: "5px",
                                        border: 0,
                                        paddingRight: "30px",
                                    },
                                }}
                                displayEmpty
                            >
                                <MenuItem value={1}>Monthly</MenuItem>
                                <MenuItem value={2}>Weekly</MenuItem>
                            </Select>
                        </FormControl>
                    </div> */}
                </div>
            </div>
            {/* <div className="flex px-auto mb-[2rem]">
                <img
                    className="mr-8"
                    src="/images/dashboard/usage.svg"
                    alt=""
                />
                <img src="/images/dashboard/remaining.svg" alt="" />
            </div> */}
            {/* <div className="flex"> */}
            {/* <span>Document Usage</span> */}

            <ResponsiveContainer width="100%">
                {/* <LineChart
                    width="100%"
                    height={350}
                    data={data}
                    margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Line
                        dataKey="pv"
                        stroke="#EF40A3"
                        activeDot={{ r: 8 }}
                        dot={{
                            stroke: "#EF40A3",
                            strokeWidth: 1,
                            r: 6,
                            strokeDasharray: "",
                        }}
                    />
                    <Line
                        activeDot={{ r: 8 }}
                        dataKey="uv"
                        stroke="#D1D1D1"
                        dot={{
                            stroke: "#D1D1D1",
                            strokeWidth: 1,
                            r: 6,
                            strokeDasharray: "",
                        }}
                    />
                </LineChart> */}
                <div className="px-[20px] py-5">
                    {fetching ? (
                        <Loader className="h-[290px] flex justify-center items-center" />
                    ) : (
                        <Barchart
                            margin={{
                                top: 0,
                                bottom: 0,
                            }}
                            data={modifiedData}
                            width={700}
                            height={350}
                            color={"#00B399"}
                            xDataKey={"invcount"}
                            yDataKey={"Invoices"}
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis
                                xDataKey={"invcount"}
                                tick={{ fill: textColor, fontSize: "13px" }}
                                stroke={currentMode === "dark" ? "#dfe1e28f" : "#E3E3E3"}
                            />
                            <YAxis
                                yDataKey={"Invoices"}
                                tick={{ fill: textColor, fontSize: "13px" }}
                                stroke={currentMode === "dark" ? "#dfe1e28f" : "#E3E3E3"}
                            />
                            <Tooltip cursor={false} />
                            <Legend />
                            <Bar dataKey="Invoices" barSize={20} stroke label={{ fill: textColor }} fill="#00B399" />
                        </Barchart>
                    )}
                </div>
            </ResponsiveContainer>

            {/* </div> */}
        </Card>
    );
};
