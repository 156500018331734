import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Content = () => {
    const settings = {
        dots: true,
        infinite: true,
        arrows: false,
        autoplay: true,
        speed: 2000,
        slidesToShow: 1,
        slidesToScroll: 1,
    };
    return (
        <div>
            <div className="relative mb-10 ">
                <h4 className="text-[50px] leading-[70px] mb-[35px] font-interSb">
                    Save <span className="text-[#4285F4]">60%</span> on your
                    operational costs on
                    <br />
                    <span className="text-[#4285F4]">
                        accounts payable or we pay the difference.
                    </span>
                </h4>
                <p className="font-interM text-lg">
                    No Credit card. No Strings attached
                </p>
                <hr className="border-[#00B399] my-4" />
                <ul className="space-y-3 list-disc pl-4 mb-[3.12rem]">
                    <li>Fill out the form. Verify the link. Get started</li>
                    <li>
                        Digitize and Manage Invoices, Approval workflows and
                        Exceptions
                    </li>
                    <li>
                        Unleash Productivity with Superior 24/7 Customer support
                    </li>
                </ul>
                {/* <div className="w-[670px] h-[368px]">
                    <img className="mb-5 object-contain" src="/images/signin/Final-Dashboard.png" alt="Final-Dashboard" />
                </div> */}
            </div>
            {/* <h4 className="text-2xl mb-4">
                With our specialised software for your enterprise, you can:
            </h4>
            <ul className="space-y-3 sigupList mb-[3.12rem]">
                <li>
                    <img src="/images/common/tick.svg" alt="tick" />
                    Route Invoices to the Right Approvers
                </li>
                <li>
                    <img src="/images/common/tick.svg" alt="tick" />
                    Take advantage of the early payment revenue you receive from your
                    suppliers.
                </li>
                <li>
                    <img src="/images/common/tick.svg" alt="tick" />
                    Improve cash flow.
                </li>
                <li>
                    <img src="/images/common/tick.svg" alt="tick" />
                    Track expenses.
                </li>
            </ul> */}
            {/* <span className="font-interSb block mb-2">MyiQ.Ai Business Suite is an integration platform with 95% of accuracy to automate and connect with existing business processes with API connectors to your existing ERP and unified environment :</span>
            <ul className="list-disc pl-3 space-y-2">
                <li className="marker:text-light-20">Jump-start on your Invoice Automation with 0% Downtime.</li>
                <li className="marker:text-light-20">Let's not just Automate your Invoice Processing; Let's Supercharge your AP Processing with
                    8X Productivity.</li>
            </ul> */}
        </div>
    );
};

export default Content;
