import Button from "../../components/Button";
import Modal from "../../components/UI/Modal/Modal";
import { BackupDataApi, RemoveData } from "../../redux/actions/setting";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import MessageBox from "../../components/UI/Alert";
import { BACKUP_DATA_RESET, SEND_BILLING_MAIL_RESET } from "../../redux/types/types";
import { getCurrentUser } from "../../redux/actions/auth";
import { useState, useEffect } from "react";
import { decryptId, encryptId } from "../../utils/helpers";
import { useContext } from "react";
import { ThemeContext } from "../../Hooks/Themes/Themeprovider";
import Swal from "sweetalert2";
import { sendBillingEmail } from "../../redux/actions/report";

const UpgradeModal = (props) => {
    const { currentMode, toggleMode } = useContext(ThemeContext);
    const dispatch = useDispatch();
    const emailStatus = useSelector((state) => state?.sendBillingEmailState);

    const handleEMailSend = () => {
        const user = JSON.parse(localStorage.getItem("user"));
        const reqObj = {
            customerName: user?.username,
            customerEmail: user?.emailaddress,
            phone: user?.mobilenumber,
            toEmail: encryptId(process.env?.REACT_APP_SALES_EMAIL),
        };
        dispatch(sendBillingEmail(reqObj));
        Swal.fire({
            title: "Thank you for using our free trial version!",
            text: "MyiQ.Ai has received your request for an upgrade. Our team will reach to you shortly.",
            closeOnClickOutside: false,
            showCloseButton: true,
            icon: "success",
            button: "Ok",
        });
    };
    if (emailStatus?.requestComplete) {
        dispatch({ type: SEND_BILLING_MAIL_RESET });
    }

    return (
        <>
            <Modal cartClose={props?.handleClose} center="center" className={`${props?.className}`}>
                <div
                    className={`max-w-[600px] mx-auto relative bg-${currentMode}-card text-${currentMode}-copy border-${currentMode}-700 px-[90px] text-center py-[48px] rounded-[10px] shadow-[0px_4px_20px_rgba(238,238,238,0.5)]`}
                >
                    <Button onClick={props?.handleClose} tabIndex="4" className="cursor-pointer absolute right-[20px] top-[20px]">
                        <img src="/images/common/closeBlk.svg" alt="closeBlk" />
                    </Button>
                    <div className="flex justify-center">
                        <img className="bg-message rounded-[50%] w-[60px] h-[60px] mb-3" src="/images/settings/noun-upgrade.svg" alt="alert" />
                    </div>
                    <p className="font-interSb block mb-4">Upgrade your subscription to get the access</p>
                    <div className="flex justify-center items-center mt-7 space-x-3">
                        <Button
                            className="bg-light-20  text-light-40 px-[30px] py-[9px] text-white rounded-[8px]"
                            type="submit"
                            tabIndex="3"
                            onClick={handleEMailSend}
                            disabled={emailStatus?.requestComplete}
                        >
                            Upgrade
                        </Button>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default UpgradeModal;
