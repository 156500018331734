import {
	GET_ACCRUAL_ACCOUNTING_SUCCESS,
	GET_ACCRUAL_ACCOUNTING_FAILURE,
	GET_ACCRUAL_ACCOUNTING_RESET,
	GET_ACCRUAL_ACCOUNTING_REQUEST,
	ADD_ACCRUAL_ACCOUNTING_SUCCESS,
	ADD_ACCRUAL_ACCOUNTING_FAILURE,
	ADD_ACCRUAL_ACCOUNTING_RESET,
	ADD_ACCRUAL_ACCOUNTING_REQUEST,
	GET_ACCRUAL_POSTING_REQUEST,
	GET_ACCRUAL_POSTING_SUCCESS,
	GET_ACCRUAL_POSTING_RESET,
	GET_ACCRUAL_POSTING_FAILURE,
	ADD_ACCRUAL_POSTING_REQUEST,
	ADD_ACCRUAL_POSTING_SUCCESS,
	ADD_ACCRUAL_POSTING_RESET,
	ADD_ACCRUAL_POSTING_FAILURE,
	GET_ACCRUAL_POSTING_DATES_REQUEST,
	GET_ACCRUAL_POSTING_DATES_SUCCESS,
	GET_ACCRUAL_POSTING_DATES_RESET,
	GET_ACCRUAL_POSTING_DATES_FAILURE,
	GET_MONTH_CLOSING_REQUEST,
	GET_MONTH_CLOSING_SUCCESS,
	GET_MONTH_CLOSING_RESET,
	GET_MONTH_CLOSING_FAILURE,
	POST_MONTH_CLOSING_REQUEST,
	POST_MONTH_CLOSING_SUCCESS,
	POST_MONTH_CLOSING_RESET,
	POST_MONTH_CLOSING_FAILURE,
	GET_ACCRUAL_VALIDATE_FAILURE,
	GET_ACCRUAL_VALIDATE_REQUEST,
	GET_ACCRUAL_VALIDATE_SUCCESS,
	GET_ACCRUAL_VALIDATE_RESET,
} from "../types/types.js";

const intGetAccrualState = {
	data: [],
	fetching: false,
	requestComplete: false,
	error: "",
};

export function GetAccrualPosting(state = GetAccrualPostingState, action) {
	switch (action.type) {
		case GET_ACCRUAL_POSTING_REQUEST:
			return {
				...state,
				fetching: true,
				requestComplete: false,
				error: "",
			};
		case GET_ACCRUAL_POSTING_SUCCESS:
			return {
				data: action.payload,
				fetching: false,
				requestComplete: true,
				error: "",
			};
		case GET_ACCRUAL_POSTING_RESET:
			return {
				data: [],
				fetching: false,
				requestComplete: false,
				error: "",
			};
		case GET_ACCRUAL_POSTING_FAILURE:
			return {
				fetching: false,
				requestComplete: false,
				error: action.payload,
			};
		default:
			return state;
	}
}

const intAddAccrualState = {
	data: {},
	fetching: false,
	requestComplete: false,
	error: "",
};

export function AddAccrualAcc(state = intAddAccrualState, action) {
	switch (action.type) {
		case ADD_ACCRUAL_ACCOUNTING_REQUEST:
			return {
				...state,
				fetching: true,
				requestComplete: false,
				error: "",
			};
		case ADD_ACCRUAL_ACCOUNTING_SUCCESS:
			return {
				data: action.payload,
				fetching: false,
				requestComplete: true,
				error: "",
			};
		case ADD_ACCRUAL_ACCOUNTING_RESET:
			return {
				data: {},
				fetching: false,
				requestComplete: false,
				error: "",
			};
		case ADD_ACCRUAL_ACCOUNTING_FAILURE:
			return {
				fetching: false,
				requestComplete: false,
				error: action.payload,
			};
		default:
			return state;
	}
}

const GetAccrualPostingState = {
	data: [],
	fetching: false,
	requestComplete: false,
	error: "",
};

export function GetAccrualAcc(state = intGetAccrualState, action) {
	switch (action.type) {
		case GET_ACCRUAL_ACCOUNTING_REQUEST:
			return {
				...state,
				fetching: true,
				requestComplete: false,
				error: "",
			};
		case GET_ACCRUAL_ACCOUNTING_SUCCESS:
			return {
				data: action.payload,
				fetching: false,
				requestComplete: true,
				error: "",
			};
		case GET_ACCRUAL_ACCOUNTING_RESET:
			return {
				data: [],
				fetching: false,
				requestComplete: false,
				error: "",
			};
		case GET_ACCRUAL_ACCOUNTING_FAILURE:
			return {
				fetching: false,
				requestComplete: false,
				error: action.payload,
			};
		default:
			return state;
	}
}

const addAccrualPostingState = {
	data: {},
	fetching: false,
	requestComplete: false,
	error: "",
};

export function addAccrualPosting(state = addAccrualPostingState, action) {
	switch (action.type) {
		case ADD_ACCRUAL_POSTING_REQUEST:
			return {
				...state,
				fetching: true,
				requestComplete: false,
				error: "",
			};
		case ADD_ACCRUAL_POSTING_SUCCESS:
			return {
				data: action.payload,
				fetching: false,
				requestComplete: true,
				error: "",
			};
		case ADD_ACCRUAL_POSTING_RESET:
			return {
				data: {},
				fetching: false,
				requestComplete: false,
				error: "",
			};
		case ADD_ACCRUAL_POSTING_FAILURE:
			return {
				fetching: false,
				requestComplete: false,
				error: action.payload,
			};
		default:
			return state;
	}
}

const GetAccrualPostingDatesState = {
	data: [],
	fetching: false,
	requestComplete: false,
	error: "",
};

export function GetAccrualPostingDate(
	state = GetAccrualPostingDatesState,
	action
) {
	switch (action.type) {
		case GET_ACCRUAL_POSTING_DATES_REQUEST:
			return {
				...state,
				fetching: true,
				requestComplete: false,
				error: "",
			};
		case GET_ACCRUAL_POSTING_DATES_SUCCESS:
			return {
				data: action.payload,
				fetching: false,
				requestComplete: true,
				error: "",
			};
		case GET_ACCRUAL_POSTING_DATES_RESET:
			return {
				data: [],
				fetching: false,
				requestComplete: false,
				error: "",
			};
		case GET_ACCRUAL_POSTING_DATES_FAILURE:
			return {
				fetching: false,
				requestComplete: false,
				error: action.payload,
			};
		default:
			return state;
	}
}

const GetMonthEndClosingState = {
	data: [],
	fetching: false,
	requestComplete: false,
	error: "",
};

export function GetMonthEndClosingReducer(
	state = GetMonthEndClosingState,
	action
) {
	switch (action.type) {
		case GET_MONTH_CLOSING_REQUEST:
			return {
				...state,
				fetching: true,
				requestComplete: false,
				error: "",
			};
		case GET_MONTH_CLOSING_SUCCESS:
			return {
				data: action.payload,
				fetching: false,
				requestComplete: true,
				error: "",
			};
		case GET_MONTH_CLOSING_RESET:
			return {
				data: [],
				fetching: false,
				requestComplete: false,
				error: "",
			};
		case GET_MONTH_CLOSING_FAILURE:
			return {
				fetching: false,
				requestComplete: false,
				error: action.payload,
			};
		default:
			return state;
	}
}

const PostMonthEndClosingState = {
	data: [],
	fetching: false,
	requestComplete: false,
	error: "",
};

export function PostMonthEndClosingReducer(
	state = PostMonthEndClosingState,
	action
) {
	switch (action.type) {
		case POST_MONTH_CLOSING_REQUEST:
			return {
				...state,
				fetching: true,
				requestComplete: false,
				error: "",
			};
		case POST_MONTH_CLOSING_SUCCESS:
			return {
				data: action.payload,
				fetching: false,
				requestComplete: true,
				error: "",
			};
		case POST_MONTH_CLOSING_RESET:
			return {
				data: [],
				fetching: false,
				requestComplete: false,
				error: "",
			};
		case POST_MONTH_CLOSING_FAILURE:
			return {
				fetching: false,
				requestComplete: false,
				error: action.payload,
			};
		default:
			return state;
	}
}

const GetValidatePostState = {
	data: [],
	fetching: false,
	requestComplete: false,
	error: "",
};

export function GetValidatePostReducer(state = GetValidatePostState, action) {
	switch (action.type) {
		case GET_ACCRUAL_VALIDATE_REQUEST:
			return {
				...state,
				fetching: true,
				requestComplete: false,
				error: "",
			};
		case GET_ACCRUAL_VALIDATE_SUCCESS:
			return {
				data: action.payload,
				fetching: false,
				requestComplete: true,
				error: "",
			};
		case GET_ACCRUAL_VALIDATE_RESET:
			return {
				data: [],
				fetching: false,
				requestComplete: false,
				error: "",
			};
		case GET_ACCRUAL_VALIDATE_FAILURE:
			return {
				fetching: false,
				requestComplete: false,
				error: action.payload,
			};
		default:
			return state;
	}
}
