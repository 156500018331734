import { useContext } from "react";
import { Link } from "react-router-dom";
import Modal from "../../../components/UI/Modal/Modal";
import { ThemeContext } from "../../../Hooks/Themes/Themeprovider";
import EmailConfigModal from "./EmailConfig";

const EmailConfigs = (props) => {
    const handleClose = () => {
        const btnClick = document.querySelector(".closeEmailModel");
        btnClick.click();
    };
    const { currentMode } = useContext(ThemeContext);
    return (
        <>
            <Modal cartClose={props?.handleClose} className={`${props?.className}`}>
                <span onClick={() => handleClose()} className="p-[6.5px] absolute cursor-pointer right-[565px] bg-light-10">
                    <img src="/images/common/whiteClose.svg" alt="whiteClose" />
                </span>
                <div
                    className={`max-w-[565px] ml-auto bg-${currentMode}-card border-${currentMode}-700 transition duration-150 ease-out flex-col flex h-screen max-h-[-webkit-fill-available] overflow-y-auto`}
                >
                    <div className={`flex-col px-[30px] py-[24px] border-b-[1px] border-${currentMode}-590 mb-[1.8rem] relative`}>
                        <h4 className="font-interSb text-lg">Email Configuration</h4>
                    </div>
                    <EmailConfigModal orgId={props?.orgId} cartClose={props?.handleClose} updateOrgList={props?.updateOrgList} />
                </div>
            </Modal>
        </>
    );
};

export default EmailConfigs;
