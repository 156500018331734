import { useDispatch } from "react-redux";
import Button from "../../../../components/Button";
import { getInvoiceCalcDetail } from "../../../../redux/actions/invoice";
import { useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { RoundNum, formatNumber, showSwal } from "../../../../utils/helpers";
import Input from "../../../../components/Input";
import Loader from "../../../../components/Loader";
import _ from "lodash";
import Swal from "sweetalert2";
import { NumericFormat } from "react-number-format";

export const AdvanceRc = ({ currentMode, handleClose, invid, type, advData, setAdvData, netValue, isEdit }) => {
    const dispatch = useDispatch();
    const { advdata, fetching } = useSelector((state) => state?.getInvCalcDetailState);
    const [initialInvData, setInitialInvData] = useState(null);
    useEffect(() => {
        if (type) {
            dispatch(getInvoiceCalcDetail(invid, type));
        }
    }, [type]);
    const DummyData = {
        header: ["Seq No.", "Document No.", "Document Date", "Advance Amount", "Balance Amount", "Adjustment Amount"],
    };

    useMemo(() => {
        const initialData = _.cloneDeep(advdata);
        setInitialInvData(initialData);
        if (advData?.length === 0) {
            setAdvData(advdata);
        }
    }, [advdata]);

    const totalAdjustmentAmount =
        advData &&
        advData?.reduce((total, item) => {
            // Parse adjustment_amount as a number (remove commas and convert to float)
            const adjustmentAmount = parseFloat(item?.adjustment_amount?.replace(/,/g, ""));
            return total + adjustmentAmount || total || 0;
        }, 0);
    const [dummyKey, setDummyKey] = useState(Date.now());

    // Function to handle change of adjustment_amount
    const handleAdjustmentChange = (newValue, index, balance, prev) => {
        // Create a copy of the adjustments array
        const updatedAdjustments = _.cloneDeep(advData);
        updatedAdjustments[index].adjustment_amount = newValue;
        // Update the state with the modified array
        if (formatNumber(balance) < formatNumber(newValue)) {
            setDummyKey(Date.now());
            updatedAdjustments[index].adjustment_amount = updatedAdjustments[index].balance_amount;
            setAdvData(updatedAdjustments);
            showSwal(`Adjustment amount should not be greater than ${updatedAdjustments[index].balance_amount}!`);
        } else {
            setAdvData(updatedAdjustments);
        }
    };
    const Cancel = () => {
        const ifEdit = _.isEqual(advData, initialInvData);
        if (!ifEdit) {
            Swal.fire({
                text: `All changes that you made will be lost?`,
                icon: "warning",
                showCloseButton: true,
                showCancelButton: true,
                buttons: {
                    cancel: "Cancel",
                    confirm: "Confirm",
                },
            }).then((confirm) => {
                if (confirm?.isConfirmed) {
                    setAdvData([...initialInvData]);
                    dispatch({ type: "STORE_ADV_RECOVERY_RESET" });
                    handleClose();
                }
            });
        } else {
            handleClose();
        }
    };
    const onSave = () => {
        if (totalAdjustmentAmount > formatNumber(netValue?.billAmount)) {
            showSwal(`Total Adjust amount should not exceed "Poc Net" amount`);
            return;
        }
        dispatch({ type: "STORE_ADV_RECOVERY", payload: totalAdjustmentAmount });
        handleClose();
    };
    const userNumFormat = localStorage.getItem("userNumberFormat");
    let thousandSeperator = userNumFormat == "1" ? "," : userNumFormat == 2 ? "." : " ";
    let decSeperator = userNumFormat == "1" ? "." : userNumFormat == "2" ? "," : ".";

    return (
        <div className={`${currentMode == "dark" ? "" : "shadow-[0px_4px_20px_0px_rgba(238, 238, 238, 0.50)]"}`}>
            <div className={`flex justify-between items-center px-[30px] border-b border-${currentMode}-1380 py-[14px]`}>
                <span className="text-[18px] font-interM">Advance Recovery</span>
                <span onClick={handleClose} className="cursor-pointer convertWhite">
                    <img src="/images/common/closeBlk.svg" alt="closeBlk" />
                </span>
            </div>
            {fetching ? (
                <Loader />
            ) : (
                <>
                    <table className={`w-full  rolemasterTable lastBorder !rounded-[0] text-left mb-[30px]`}>
                        <thead>
                            <tr>
                                <th className="rounded-[0]">{"#"}</th>
                                {DummyData?.header?.map((el, i) => (
                                    <>
                                        <th className="rounded-[0]">{el}</th>
                                    </>
                                ))}
                            </tr>
                        </thead>
                        <tbody className={`relative`}>
                            {advData &&
                                advData?.map((el, i) => (
                                    <tr key={i}>
                                        <td className="rounded-[0]">{i + 1}</td>
                                        <td>{el?.sequence_no}</td>
                                        <td>{el?.document_no}</td>
                                        <td>{el?.document_date}</td>
                                        <td>{el?.advance_amount}</td>
                                        <td>{el?.balance_amount}</td>
                                        <td>
                                            {
                                                <NumericFormat
                                                    key={`adv-${i}-${dummyKey}`}
                                                    id={`adv-${i}`}
                                                    autoComplete="off"
                                                    readOnly={isEdit}
                                                    allowNegative={false}
                                                    onClick={(e) => e.target.select()}
                                                    value={formatNumber(el?.adjustment_amount)} // Check for undefined and provide a default value
                                                    onChange={(e) =>
                                                        handleAdjustmentChange(e.target.value, i, el?.balance_amount, el?.adjustment_amount)
                                                    }
                                                    className={`border font-interR max-w-[200px] rounded-[8px] my-[-10px] mx-[-15px] border-${currentMode}-840 bg-${currentMode}-card py-[3px] px-[10px]`}
                                                    decimalScale={2}
                                                    maxLength={20}
                                                    displayType="input"
                                                    thousandSeparator={thousandSeperator}
                                                    decimalSeparator={decSeperator}
                                                />
                                            }
                                        </td>
                                    </tr>
                                ))}
                            <tr>
                                <td colSpan={DummyData?.header?.length - 1} className="border-0"></td>
                                <td className="font-interM !border-b text-center">Total</td>
                                <td className={`font-interM !border-b bg-${currentMode}-1400 text-center`}>
                                    {"" + RoundNum(+totalAdjustmentAmount)}
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <div className="space-x-2 flex justify-end pb-[30px] pr-[30px]">
                        <Button onClick={onSave} title={"Save"} className="bg-light-20 text-base text-white rounded-[8px] py-2 px-7">
                            {"Save"}
                        </Button>
                        <Button title="Cancel" onClick={Cancel} className={`bg-light-240 text-light-40 text-base rounded-[8px] py-2 px-7`}>
                            Cancel
                        </Button>
                    </div>
                </>
            )}
        </div>
    );
};
