import axios from "axios";
import configMyiQ from "../../configuration";
import {
	GETALLUSER_BYORG_FAILURE,
	GETALLUSER_BYORG__REQUEST,
	GETALLUSER_BYORG__SUCCESS,
	GET_USERCOUNT_FAILURE,
	GET_USERCOUNT_REQUEST,
	GET_USERCOUNT_SUCCESS,
	USER_ADD_FAILURE,
	USER_ADD_REQUEST,
	USER_ADD_SUCCESS,
	USER_EDIT_FAILURE,
	USER_EDIT_REQUEST,
	USER_EDIT_SUCCESS,
	USER_STATUS_UPDATE_FAILURE,
	USER_STATUS_UPDATE_REQUEST,
	USER_STATUS_UPDATE_SUCCESS,
	USER_VIEW_FAILURE,
	USER_VIEW_REQUEST,
	USER_VIEW_SUCCESS,
	USER_ROLE_DELETE_REQUEST,
	USER_ROLE_DELETE_SUCCESS,
	USER_ROLE_DELETE_FAILURE,
	USER_LIST_DROP_REQUEST,
	USER_LIST_DROP_SUCCESS,
	USER_LIST_DROP_FAILURE,
	USER_ROLE_DELETE_RESET,
	USER_ACTIVITY_FAILURE,
	USER_ACTIVITY_REQUEST,
	USER_ACTIVITY_SUCCESS,
	GET_USER_DROP_DOWN_ALLOCATION_REQUEST,
	GET_USER_DROP_DOWN_ALLOCATION_SUCCESS,
	GET_USER_DROP_DOWN_ALLOCATION_FAILURE,
} from "../types/types";
import authHeader from "./auth-header";
import { checkApiStatus } from "../../utils/helpers";
//userlist
const API_URL = process.env?.REACT_APP_API_URL;
const API = process.env?.REACT_APP_NODE_API_URL;

export const userByOrg = (data) => async (dispatch) => {
	try {
		dispatch({ type: GETALLUSER_BYORG__REQUEST });

		axios
			.post(`${API_URL}User/GetAllUsersByOrg`, data, {
				headers: authHeader(),
			})
			.then((response) => {
				dispatch({
					type: GETALLUSER_BYORG__SUCCESS,
					payload: response?.data?.datalist,
				});
			})
			.catch((error) => {
				if (error?.response?.status == 401) {
					checkApiStatus();
				}
				dispatch({
					type: GETALLUSER_BYORG_FAILURE,
					payload: { error: error.message },
				});
			});
	} catch (error) {
		dispatch({
			type: GETALLUSER_BYORG_FAILURE,
		});
	}
};
//user page-count
export const userCount = (data) => async (dispatch) => {
	try {
		dispatch({ type: GET_USERCOUNT_REQUEST });

		axios
			.post(`${API_URL}User/GetUserCount`, data, {
				headers: authHeader(),
			})
			.then((response) => {
				dispatch({
					type: GET_USERCOUNT_SUCCESS,
					payload: response,
				});
			});
	} catch (error) {
		if (error?.response?.status == 401) {
			checkApiStatus();
		}
		dispatch({
			type: GET_USERCOUNT_FAILURE,
		});
	}
};
//adduser
export const userAdd = (data) => async (dispatch) => {
	try {
		dispatch({ type: USER_ADD_REQUEST });

		axios
			.post(`${API_URL}User/AddUserByOrgAdmin`, data, {
				headers: authHeader(),
			})
			.then((response) => {
				dispatch({
					type: USER_ADD_SUCCESS,
					payload: response.data,
				});
			})
			.catch(function (error) {
				if (error?.response?.status == 401) {
					checkApiStatus();
				}
				dispatch({
					payload: { error: error.message },
					type: USER_ADD_FAILURE,
				});
			});
	} catch (error) {
		dispatch({
			type: USER_ADD_FAILURE,
		});
	}
};

//viewuser
export const userView = (userId) => async (dispatch) => {
	dispatch({ type: USER_VIEW_REQUEST });

	axios
		.get(`${API_URL}User/GetUserById?id=${userId}`, {
			headers: authHeader(),
		})
		.then((response) => {
			dispatch({
				type: USER_VIEW_SUCCESS,
				payload: response.data?.data,
			});
		})
		.catch((error) => {
			if (error?.response?.status == 401) {
				checkApiStatus();
			}
			dispatch({
				type: USER_VIEW_FAILURE,
			});
		});
};
//edituser
export const userEdit = (data) => async (dispatch) => {
	try {
		dispatch({ type: USER_EDIT_REQUEST });

		axios
			.post(`${API_URL}User/UpdateUser`, data, {
				headers: authHeader(),
			})
			.then((response) => {
				dispatch({
					type: USER_EDIT_SUCCESS,
					payload: response.data,
				});
			})
			.catch(function (error) {
				dispatch({
					payload: { error: error.message },
					type: USER_EDIT_FAILURE,
				});
			});
	} catch (error) {
		if (error?.response?.status == 401) {
			checkApiStatus();
		}
		dispatch({
			type: USER_EDIT_FAILURE,
		});
	}
};

export const ActivateDeactivateUsers = (data) => async (dispatch) => {
	try {
		dispatch({ type: USER_STATUS_UPDATE_REQUEST });

		axios
			.get(`${API_URL}User/ActivateDeactivateUsers?id=${data.id}&isactive=${data.status}`, {
				headers: authHeader(),
			})
			.then((response) => {
				dispatch({
					type: USER_STATUS_UPDATE_SUCCESS,
					payload: response?.data,
				});
			});
	} catch (error) {
		if (error?.response?.status == 401) {
			checkApiStatus();
		}
		dispatch({
			type: USER_STATUS_UPDATE_FAILURE,
		});
	}
};

export const UserRoleDelete = (data) => async (dispatch) => {
	try {
		dispatch({ type: USER_ROLE_DELETE_REQUEST });
		axios
			.post(`${API_URL}Role/DeleteRoles`, data, {
				headers: authHeader(),
			})
			.then((response) => {
				dispatch({
					type: USER_ROLE_DELETE_SUCCESS,
					payload: response?.data,
				});
			});
	} catch (error) {
		if (error?.response?.status == 401) {
			checkApiStatus();
		}
		dispatch({
			type: USER_ROLE_DELETE_FAILURE,
		});
	}
};

export const GetUserActivity = (data) => async (dispatch) => {
	try {
		dispatch({ type: USER_ACTIVITY_REQUEST });

		axios
			.post(`${API_URL}Activity/GetUserActivityHistory`, data, {
				headers: authHeader(),
			})
			.then((response) => {
				dispatch({
					type: USER_ACTIVITY_SUCCESS,
					payload: response.data.datalist,
				});
			})
			.catch(function (error) {
				if (error?.response?.status == 401) {
					checkApiStatus();
				}
				dispatch({
					payload: { error: error.message },
					type: USER_ACTIVITY_FAILURE,
				});
			});
	} catch (error) {
		dispatch({
			type: USER_ACTIVITY_FAILURE,
		});
	}
};

export const UserDropDown = (data) => async (dispatch) => {
	try {
		dispatch({ type: USER_LIST_DROP_REQUEST });

		axios
			.post(`${API_URL}Role/GetUserDropDown`, data, {
				headers: authHeader(),
			})
			.then((response) => {
				dispatch({
					type: USER_LIST_DROP_SUCCESS,
					payload: response.data.datalist,
				});
			})
			.catch(function (error) {
				if (error?.response?.status == 401) {
					checkApiStatus();
				}
				dispatch({
					payload: { error: error.message },
					type: USER_LIST_DROP_FAILURE,
				});
			});
	} catch (error) {
		dispatch({
			type: USER_LIST_DROP_FAILURE,
		});
	}
};

export const UserAllocationDropDown = (id, status) => async (dispatch) => {
	try {
		dispatch({ type: GET_USER_DROP_DOWN_ALLOCATION_REQUEST });

		axios
			.get(`${API}api/getuserlistforalloc?org_id=${id}&status=${status}`, {
				headers: authHeader(),
			})
			.then((response) => {
				dispatch({
					type: GET_USER_DROP_DOWN_ALLOCATION_SUCCESS,
					payload: response.data.datalist,
				});
			})
			.catch(function (error) {
				if (error?.response?.status == 401) {
					checkApiStatus();
				}
				dispatch({
					payload: { error: error.message },
					type: GET_USER_DROP_DOWN_ALLOCATION_FAILURE,
				});
			});
	} catch (error) {
		dispatch({
			type: GET_USER_DROP_DOWN_ALLOCATION_FAILURE,
		});
	}
};
