import Button from "../../components/Button";
import Modal from "../../components/UI/Modal/Modal";
import { Link, useNavigate } from "react-router-dom";
import { ThemeContext } from "../../Hooks/Themes/Themeprovider";
import { useContext, useEffect } from "react";
const ProductTourMain = (props) => {
    const { currentMode } = useContext(ThemeContext);
    const handleStartTour = () => {
        props?.handleClose();
    };
    return (
        <>
            <Modal cartClose={props?.handleClose} center="center" className={`${props?.className}`}>
                <div
                    className={`w-[500px] h-[350px] mx-auto relative bg-${currentMode}-card border-[rgba(223,225,226,0.56)] px-[50px]  py-[31px] rounded-[10px] ${
                        currentMode === "dark" ? "" : "shadow-[0px_4px_20px_rgba(238,238,238,0.5)]"
                    }]`}
                >
                    <span onClick={props?.handleClose} className="cursor-pointer absolute right-[20px] top-[20px] convertWhite">
                        <img src="/images/common/closeBlk.svg" alt="closeBlk" />
                    </span>
                    <img className="mt-2 h-10 w-10 mx-auto" src="/images/main/Myiq.aiVertical-tour.svg" alt="tour-logo" />
                    <div className="space-x-3 mt-4 text-center">
                        <h4 className="text-xl font-interB block">Welcome aboard MyiQ.Ai!</h4>
                    </div>
                    <div className={`mt-6 text-${currentMode}-40 relative border-[2px] border-[#028C7870] px-3 py-3 text-center rounded-lg`}>
                        <div
                            className={`absolute bg-${currentMode}-card left-[-1.6%] top-1/2 transform -translate-y-1/2 inline-block w-3 h-3 border-2 border-[#028C7880] rounded-full`}
                        ></div>
                        <p className={`inline-block ${currentMode === "dark" ? "text-white" : "text-black"} text-center`}>
                            Ready to dive into efficiency?
                            <br /> Let’s kick off our quick product tour, and you’ll be
                            <br /> seamlessly processing invoices in no time.
                        </p>
                        <div
                            className={`absolute bg-${currentMode}-card right-[-1.6%] top-1/2 transform -translate-y-1/2 inline-block w-3 h-3 border-2 border-[#028C7880] rounded-full`}
                        ></div>
                    </div>
                    <div className="flex space-x-3 justify-center mt-8">
                        <Button
                            className={`duration-500 border-[1px] border-[#8C9097] px-[20px] py-[7px] rounded-[8px] ${
                                currentMode === "dark" ? "text-white" : "text-black"
                            } `}
                            type="Skip the tour"
                            onClick={props?.handleClose}
                        >
                            Skip The Tour
                        </Button>

                        <div>
                            <Link
                                to="/admin-setting?type=tour"
                                onClick={handleStartTour}
                                className="start_step bg-light-20 px-[20px] py-[7px] text-white rounded-[8px]"
                            >
                                Show me around!
                            </Link>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default ProductTourMain;
