import { ACTIVATE_DEACTIVATE_EMAIL_TEMPLATE_FAILURE, ACTIVATE_DEACTIVATE_EMAIL_TEMPLATE_REQUEST, ACTIVATE_DEACTIVATE_EMAIL_TEMPLATE_RESET, ACTIVATE_DEACTIVATE_EMAIL_TEMPLATE_SUCCESS, ADD_UPDATE_EMAIL_TEMPLATE_FAILURE, ADD_UPDATE_EMAIL_TEMPLATE_REQUEST, ADD_UPDATE_EMAIL_TEMPLATE_RESET, ADD_UPDATE_EMAIL_TEMPLATE_SUCCESS, GET_EMAIL_TEMPLATE_LIST_FAILURE, GET_EMAIL_TEMPLATE_LIST_REQUEST, GET_EMAIL_TEMPLATE_LIST_RESET, GET_EMAIL_TEMPLATE_LIST_SUCCESS, LIST_EMAIL_TEMPLATE_PLACEHOLDER_FAILURE, LIST_EMAIL_TEMPLATE_PLACEHOLDER_REQUEST, LIST_EMAIL_TEMPLATE_PLACEHOLDER_RESET, LIST_EMAIL_TEMPLATE_PLACEHOLDER_SUCCESS, VIEW_EMAIL_TEMPLATE_FAILURE, VIEW_EMAIL_TEMPLATE_REQUEST, VIEW_EMAIL_TEMPLATE_RESET, VIEW_EMAIL_TEMPLATE_SUCCESS,GET_TEMPLATE_LIST_FAILURE,GET_TEMPLATE_LIST_RESET,GET_TEMPLATE_LIST_SUCCESS,GET_TEMPLATE_LIST_REQUEST } from "../types/types";

//LIST EMAIL TEMPLATE
const listEmailTemplateState = {
    data: [],
    fetching: false,
    requestComplete: false,
    error: "",
};

export function emailTemplateList(state = listEmailTemplateState, action) {
    switch (action.type) {
        case GET_EMAIL_TEMPLATE_LIST_REQUEST:
            return {
                ...state,
                fetching: true,
                requestComplete: false,
                error: "",
            };
        case GET_EMAIL_TEMPLATE_LIST_SUCCESS:
            return {
                data: action.payload,
                fetching: false,
                requestComplete: true,
                error: "",
            };
        case GET_EMAIL_TEMPLATE_LIST_RESET:
            return {
                data: [],
                fetching: false,
                requestComplete: false,
                error: "",
            };
        case GET_EMAIL_TEMPLATE_LIST_FAILURE:
            return {
                fetching: false,
                requestComplete: false,
                error: action.payload,
            };
        default:
            return state;
    }
}

//VIEW EMAIL TEMPLATE
const viewEmailTemplateState = {
    data: [],
    fetching: false,
    requestComplete: false,
    error: "",
};

export function viewEmailTemplate(state = viewEmailTemplateState, action) {
    switch (action.type) {
        case VIEW_EMAIL_TEMPLATE_REQUEST:
            return {
                ...state,
                fetching: true,
                requestComplete: false,
                error: "",
            };
        case VIEW_EMAIL_TEMPLATE_SUCCESS:
            return {
                data: action.payload,
                fetching: false,
                requestComplete: true,
                error: "",
            };
        case VIEW_EMAIL_TEMPLATE_RESET:
            return {
                data: [],
                fetching: false,
                requestComplete: false,
                error: "",
            };
        case VIEW_EMAIL_TEMPLATE_FAILURE:
            return {
                fetching: false,
                requestComplete: false,
                error: action.payload,
            };
        default:
            return state;
    }
}
// email template placeholder list
const EmailTemplatePlaceholderState = {
    data: [],
    fetching: false,
    requestComplete: false,
    error: "",
};

export function emailTemplatePlaceholderList(state = EmailTemplatePlaceholderState, action) {
    switch (action.type) {
        case LIST_EMAIL_TEMPLATE_PLACEHOLDER_REQUEST:
            return {
                ...state,
                fetching: true,
                requestComplete: false,
                error: "",
            };
        case LIST_EMAIL_TEMPLATE_PLACEHOLDER_SUCCESS:
            return {
                data: action.payload,
                fetching: false,
                requestComplete: true,
                error: "",
            };
        case LIST_EMAIL_TEMPLATE_PLACEHOLDER_RESET:
            return {
                data: [],
                fetching: false,
                requestComplete: false,
                error: "",
            };
        case LIST_EMAIL_TEMPLATE_PLACEHOLDER_FAILURE:
            return {
                fetching: false,
                requestComplete: false,
                error: action.payload,
            };
        default:
            return state;
    }
}
//ADD UPDATE EMAIL TEMPLATE
const emailTemplateAddUpdateState = {
    data: [],
    fetching: false,
    requestComplete: false,
    error: "",
};
export function emailConfigAddUpdate(state = emailTemplateAddUpdateState, action) {
	switch (action.type) {
		case   ADD_UPDATE_EMAIL_TEMPLATE_REQUEST:
			return {
				...state,
				fetching: true,
				requestComplete: false,
				error: "",
			};
		case ADD_UPDATE_EMAIL_TEMPLATE_SUCCESS:
			return {
				data: action.payload,
				fetching: false,
				requestComplete: true,
				error: "",
			};
		case ADD_UPDATE_EMAIL_TEMPLATE_RESET:
			return {
				data: {},
				fetching: false,
				requestComplete: false,
				error: "",
			};
		case ADD_UPDATE_EMAIL_TEMPLATE_FAILURE:
			return {
				fetching: false,
				requestComplete: false,
				error: action.payload,
			};
		default:
			return state;
	}
}

//email template active-inactive
const emailTemplateStatusState = {
    status: [],
     fetching: false,
     requestComplete: false,
     error: "",
 };
 export function emailTemplateStatusUpdate(state = emailTemplateStatusState, action) {
     switch (action.type) {
         case   ACTIVATE_DEACTIVATE_EMAIL_TEMPLATE_REQUEST:
             return {
                 ...state,
                 fetching: true,
                 requestComplete: false,
                 error: "",
             };
         case ACTIVATE_DEACTIVATE_EMAIL_TEMPLATE_SUCCESS:
             return {
                status: action.payload,
                 fetching: false,
                 requestComplete: true,
                 error: "",
             };
         case ACTIVATE_DEACTIVATE_EMAIL_TEMPLATE_RESET:
             return {
                 status: {},
                 fetching: false,
                 requestComplete: false,
                 error: "",
             };
         case ACTIVATE_DEACTIVATE_EMAIL_TEMPLATE_FAILURE:
             return {
                 fetching: false,
                 requestComplete: false,
                 error: action.payload,
             };
         default:
             return state;
     }
 }

 //LIST EMAIL TEMPLATE
const getEmailTemplateListState = {
    data: [],
    fetching: false,
    requestComplete: false,
    error: "",
};

export function getEmailTemplateList(state = getEmailTemplateListState, action) {
    switch (action.type) {
        case GET_TEMPLATE_LIST_REQUEST:
            return {
                ...state,
                fetching: true,
                requestComplete: false,
                error: "",
            };
        case GET_TEMPLATE_LIST_SUCCESS:
            return {
                data: action.payload,
                fetching: false,
                requestComplete: true,
                error: "",
            };
        case GET_TEMPLATE_LIST_RESET:
            return {
                data: [],
                fetching: false,
                requestComplete: false,
                error: "",
            };
        case GET_TEMPLATE_LIST_FAILURE:
            return {
                fetching: false,
                requestComplete: false,
                error: action.payload,
            };
        default:
            return state;
    }
}