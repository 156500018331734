import Button from "../../../components/Button";
import Modal from "../../../components/UI/Modal/Modal";
import { ThemeContext } from "../../../Hooks/Themes/Themeprovider";
import { useContext } from "react";
import { useState } from "react";
import SceheduledOutputModal from "./SceheduledTemplates";
import ReactDOM from "react-dom";
import Input from "../../../components/Input";
import Swal from "sweetalert2";
import { useDispatch } from "react-redux";
import { storeReportDetail } from "../../../redux/actions/filter";

const CreateReport = (props) => {
    const { currentMode, toggleMode } = useContext(ThemeContext);
    const [isHovered, setIsHovered] = useState(false);
    const [isScheduledHover, setScheduledHover] = useState(false);
    const [openReport, setOpenReport] = useState(false);
    const [reportFormat, setreportFormat] = useState(null);
    const [customActive, setcustomActive] = useState(false);
    const [scheduleActive, setscheduleActive] = useState(false);
    const [reportName, setReportName] = useState("");
    const [reportDescription, setReportDescription] = useState("");
    const [errormsgs, seterrormsg] = useState({ message: "", description: "" });
    const [descriptionError, setDescriptionError] = useState({
        message: "",
    });
    const dispatch = useDispatch();
    const OpenSchedule = () => {
        if (reportName == "") {
            seterrormsg({
                ...errormsgs,
                message: "Report Name is required",
            });
            return false;
        }
        if (reportName?.length > 100) {
            seterrormsg({
                ...errormsgs,
                message: "Maximum length should not exceed 100",
            });
            return false;
        }
        if (!/^(?=.*[a-zA-Z])[A-Za-z0-9_ ]+$/.test(reportName)) {
            seterrormsg({
                ...errormsgs,
                message: "Report Name is Invalid",
            });
            return false;
        }
        seterrormsg(false);
        if (reportDescription?.length > 250) {
            seterrormsg({
                ...errormsgs,
                description: "Maximum length should not exceed 250",
            });
            return false;
        } else {
            seterrormsg({
                ...errormsgs,
                description: "",
            });
        }
        if (reportFormat == null) {
            Swal.fire({
                text: "Report type is required",
                closeOnClickOutside: false,
                showCloseButton: true,
                icon: "warning",
                button: "Ok",
            });
            return false;
        }
        setOpenReport(true);
        const ReqObj = { report_name: reportName, report_description: reportDescription, report_type: reportFormat };
        dispatch(storeReportDetail(ReqObj));
        setReportName("");
        setReportDescription("");
        setscheduleActive(false);
        setcustomActive(false);
        setreportFormat(null);
        props?.handleClose();
    };
    const ClickOutput = (e) => {
        setreportFormat(e);
        if (e == 1) {
            setcustomActive(true);
        } else {
            setcustomActive(false);
        }
        if (e == 2) {
            setscheduleActive(true);
        } else {
            setscheduleActive(false);
        }
    };
    const CartClose = () => {
        setscheduleActive(false);
        setcustomActive(false);
        setreportFormat(null);
        setReportName("");
        setReportDescription("");
        seterrormsg({ message: "", description: "" });
        props?.handleClose();
        props?.setReportValue("");
    };
    return (
        <>
            {/* {requestComplete && !data?.status && <MessageBox error={errormsg} />} */}
            {/* {error && !data?.status && <MessageBox error={errormsgs} />} */}
            <Modal cartClose={CartClose} center="center" className={`${props?.className}`}>
                <div
                    className={`w-[640px] mx-auto relative  px-[30px]  py-[30px] rounded-[10px] ${currentMode === "dark" ? "" : "shadow-[0px_4px_20px_rgba(238,238,238,0.5)]"
                        } bg-${currentMode}-card text-${currentMode}-copy border-${currentMode}-700`}>
                    <Button onClick={CartClose} tabIndex="4" className="cursor-pointer absolute right-[20px] top-[20px] convertWhite">
                        <img src="/images/common/closeBlk.svg" alt="closeBlk" />
                    </Button>

                    <span className="font-medium block text-base mb-4 text-center">
                        {props?.reportValue == "edit" ? "Update Reports" : " Create Reports"}
                    </span>
                    <div>
                        <div className="basis-1/2 space-x-5 mb-[20px] flex items-center">
                            <span className={`block text-sm pb-3 flex-auto`}>
                                Report Name<span className="text-[red] mr-1">*</span>
                            </span>
                            <div className="flex-1">
                                <Input
                                    name="report_name"
                                    autoFocus
                                    value={reportName}
                                    onChange={(e) => setReportName(e.target.value)}
                                    tabIndex={0}
                                    maxLength={100}
                                    className={`w-[454px] px-[10px] h-[40px] border-[1px] bg-${currentMode}-card text-${currentMode}-copy border-${currentMode}-840 rounded-[8px]`}
                                />
                                {errormsgs?.message && <p className="text-light-50 text-xs">{errormsgs?.message}</p>}
                            </div>
                        </div>

                        <div className="basis-1/2 flex space-x-5">
                            <span className={`block flex-auto text-sm pb-3`}>Description</span>
                            <div className="flex-1">
                                <textarea
                                    name="description"
                                    autoFocus
                                    value={reportDescription}
                                    onChange={(e) => setReportDescription(e.target.value)}
                                    tabIndex={0}
                                    maxLength={251}
                                    className={`w-[454px] px-[10px] block h-[80px] border-[1px] bg-${currentMode}-card text-${currentMode}-copy border-${currentMode}-840 rounded-[8px]`}
                                />
                                {errormsgs?.description && <p className="text-light-50 text-xs">{errormsgs?.description}</p>}
                            </div>
                        </div>
                    </div>
                    <div className="flex justify-center items-center text-center space-x-4 mt-[40px]">
                        <div
                            className={`  border-[1px] rounded-[10px] basis-1/2  border-${currentMode}-700 py-[20px] px-[18px] hover:bg-[#00B399] hover:text-white ${customActive ? `bg-[#00B399] text-white` : `bg-${currentMode}-card`
                                } cursor-pointer`}
                            onMouseEnter={() => setIsHovered(true)}
                            onMouseLeave={() => setIsHovered(false)}
                            onClick={() => ClickOutput(1)}>
                            <img
                                className="mb-4 mx-auto"
                                src={`${isHovered || customActive
                                    ? "/images/Output-manage/custom-report-white.svg"
                                    : "/images/Output-manage/custom-reports.svg"
                                    }`}
                                alt="gmail-icon"
                            />
                            <span className="font-interSb mb-3 block">Custom Report</span>
                            <p className="text-center font-inter text-xs font-normal leading-4">
                                Custom reports are commonly used in business intelligence, analytics, research, and decision-making processes.
                            </p>
                        </div>
                        <div
                            className={`${scheduleActive ? `bg-[#00B399] text-white` : `bg-${currentMode}-card`
                                } basis-1/2 py-[20px] px-[20px] border-[1px] rounded-[10px] border-${currentMode}-700 hover:bg-[#00B399] hover:text-white cursor-pointer`}
                            onMouseEnter={() => setScheduledHover(true)}
                            onMouseLeave={() => setScheduledHover(false)}
                            onClick={() => ClickOutput(2)}>
                            <img
                                className="mb-4 mx-auto"
                                src={
                                    isScheduledHover || scheduleActive
                                        ? "/images/Output-manage/scheduled-report-white.svg"
                                        : "/images/Output-manage/scheduled-report.svg"
                                }
                                alt="outlook-icon"
                            />
                            <span className="font-interSb mb-3 block">Scheduled Output</span>
                            <p className=" text-center font-inter text-xs font-normal leading-4">
                                Scheduled reports are generated automatically without requiring manual initiation each time.
                            </p>
                        </div>
                    </div>
                    <div className="mx-[-30px] mt-[50px]">
                        <hr className={`border-${currentMode}-1240`} />
                    </div>

                    <div className={` text-${currentMode}-copy pt-[30px] space-x-2 px-[30px] text-right`}>
                        <Button
                            title="Cancel"
                            className="bg-light-240 text-light-40 text-base px-[25px] py-[8px] rounded-[8px]"
                            onClick={CartClose}
                            tabIndex="3">
                            Cancel
                        </Button>
                        <Button
                            className="bg-light-20 px-[33px] py-[8px] text-base text-white rounded-[8px]"
                            type="submit"
                            title="Next"
                            tabIndex="3"
                            onClick={OpenSchedule}>
                            Next
                        </Button>
                    </div>
                </div>
            </Modal>
            {ReactDOM.createPortal(
                <SceheduledOutputModal
                    isOpened={openReport}
                    reportType={reportFormat}
                    handleClose={() => setOpenReport(false)}
                    className={openReport && "Show"}
                    handleOpen={() => setOpenReport(true)}
                />,
                document.getElementById("user-modal-root")
            )}
        </>
    );
};

export default CreateReport;
