import React, { useContext, useEffect, useState } from "react";
import Modal from "../../components/UI/Modal/Modal";
import Input from "../../components/Input";
import Button from "../../components/Button";
import Card from "../../components/UI/Card";
import { ThemeContext } from "../../Hooks/Themes/Themeprovider";
import Loader from "../../components/Loader";
import styles from "./index.module.scss";
import { getCurrentUser } from "../../redux/actions/auth";
import ReactDOM from "react-dom";
import * as XLSX from "xlsx";
import { useSelector } from "react-redux";
import ExportExcel from "../ExportFiles/Excel";
import { async } from "@firebase/util";
import { useDispatch } from "react-redux";
import { AddUpdateExchangeRate, ExchangeRateListAction, GetCurrencyListExchangeRate, UploadExchangeRate } from "../../redux/actions/exchange-rate";
import Swal from "sweetalert2";
import { UPLOAD_EXCHANGE_RATE_RESET } from "../../redux/types/types";
import moment from "moment";
import ErrorTableModal from "./ErrorTable";
let uploadedFiles = [];
let fileStatusList = [];

const SampleCsv = "http://localhost:3000/sample_exchangerate.csv";

const UploadModal = ({ uploadModalOpen, uploadModalClose, className, setJsonData, setIsSubmit, currency, currencyList }) => {
    const { currentMode } = useContext(ThemeContext);
    const currentUser = getCurrentUser();
    const [fetching, setFetching] = useState(false);
    const [fileList, setFileStatusList] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);

    const dispatch = useDispatch();
    // const [fileStatusList, setFileStatusList] = useState([]);
    const [isButton, setIsButton] = useState(false);
    // const [jsonData, setJsonData] = useState(null);
    const [uploadedData, setUploadedData] = useState(null);
    const fileTypes = ["application/vnd.ms-excel", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", "text/csv"];
    const UploadFile = useSelector((state) => state?.UploadExchangeRateState);

    const handleSubmit = async (e) => {
        // e.preventDefault();
        // uploadModalClose();
        const file = e.target.files[0];
        const reader = new FileReader();

        reader.onload = (event) => {
            const binaryString = event.target.result;
            const workbook = XLSX.read(binaryString, { type: "binary" });

            const sheetName = workbook.SheetNames[0];
            const sheet = workbook.Sheets[sheetName];

            const data = XLSX.utils.sheet_to_json(sheet, { header: 1 });

            // Assuming the first row contains headers, create JSON
            const headers = data[0];
            const jsonDatas = data?.slice(1).map((row) => {
                return headers.reduce((obj, key, index) => {
                    obj[key] = row[index];
                    return obj;
                }, {});
            });
            setJsonData(jsonDatas);
            setUploadedData(jsonDatas);
        };

        reader.readAsBinaryString(file);
        let files = e.target.files;
        if (files) {
            for (let i = 1; i <= files.length; i++) {
                const fileExt = fileTypes.find((item) => item == files[i - 1].type);
                // const fileCount = fileStatusList.find(item=>item.fileName == files[i-1].name);
                if (files[i - 1]) {
                    const fileSize = (files[i - 1].size / (1024 * 1024)).toFixed(2); //mb conversion
                    if (fileSize > 10) {
                        fileStatusList.push({
                            fileName: files[i - 1].name,
                            error: "File size should be less than 10MB",
                            status: false,
                        });
                    } else if (!fileExt) {
                        fileStatusList.push({
                            fileName: files[i - 1].name,
                            error: "File type is not supported",
                            status: false,
                        });
                    } else {
                        fileStatusList.push({
                            fileName: files[i - 1].name,
                            error: "",
                            status: true,
                        });
                        await uploadWithJSON(files[i - 1], files[i - 1].name);
                    }
                }
            }
            setFileStatusList([...fileStatusList]);
            e.target.value = "";
        }
    };
    const _handleReaderLoaded = (fileName) => async (e) => {
        let binaryString = e.target.result;
        let imgObject = { image: btoa(binaryString), fileName: fileName };
        uploadedFiles.push(imgObject);
    };
    async function uploadWithJSON(file, fileName) {
        const reader = new FileReader();
        reader.onload = await _handleReaderLoaded(fileName).bind(this);
        reader.readAsBinaryString(file);
    }
    const removeFile = (index, fileName) => {
        const reMovedFileList = fileList;
        reMovedFileList.splice(index, 1);
        setFileStatusList([...reMovedFileList]);
        uploadedFiles = uploadedFiles.filter((item) => item.fileName !== fileName);
        fileStatusList = reMovedFileList;
    };

    const downloadSampleFile = async (url) => {
        // const link = document.createElement("a");
        // link.href = url;
        // link.setAttribute("download", "");
        // document.body.appendChild(link);
        // link.click();
        // document.body.removeChild(link);
        const dataDisplay = [];
        const dataList = [
            {
                "To Currency": "AFN",
                "From Currency": "JPY",
                Valid_From: "2024-04-05",
                Exchange_Rate: "89",
            },
            {
                "To Currency": "EUR",
                "From Currency": "USD",
                Valid_From: "2023-09-12",
                Exchange_Rate: "134.4",
            },
            {
                "To Currency": "JPY",
                "From Currency": "AED",
                Valid_From: "2023-09-18",
                Exchange_Rate: "90",
            },
            {
                "To Currency": "JPY",
                "From Currency": "AED",
                Valid_From: "2023-09-18",
                Exchange_Rate: "9s0",
            },

        ];

        dataList?.forEach((item) => {
            let invHeaders = {
                "From Currency": item["From Currency"],
                "To Currency": item["To Currency"],
                "Valid From": item["Valid_From"],
                "Exchange Rate": item?.Exchange_Rate,
            };

            dataDisplay?.push(invHeaders);
        });

        ExportExcel({ excelData: dataDisplay, fileName: "Sample Exchange Rate" });
    };
    const disableInput = fileList?.length > 0;
    const ModalClose = () => {
        uploadModalClose();
        setFileStatusList([]);
        removeFile();
        dispatch({ type: UPLOAD_EXCHANGE_RATE_RESET });
    };
    const CurrencyList = [
        { id: 1, currency: "USD" },
        { id: 2, currency: "EURO" },
        { id: 3, currency: "JPY" },
        { id: 4, currency: "INR" },
    ];

    useEffect(() => {
        dispatch(GetCurrencyListExchangeRate());
    }, []);
    const Currency = useSelector((state) => state?.currencyListState);
    const currencies = Currency?.data;
    const currencyMap = {};
    currencies?.forEach((el) => {
        currencyMap[el.currency_code] = el.currency_id;
    });

    // Default ID for cases where currency code is not found
    const defaultId = null;

    // Update myArray with From_Id and To_Id
    const updatedArray = uploadedData?.map((item) => ({
        ...item,
        From_Id: currencyMap[item["From Currency"]] || defaultId,
        To_Id: currencyMap[item["To Currency"]] || defaultId,
    }));
    const unmatchedCurrencies = updatedArray?.filter((item) => !currencyList?.some((currency) => currency?.currency_code === item["From Currency"]));
    const UnmatchCurrencyFrom = [...new Set(unmatchedCurrencies?.map((item) => item["From Currency"]))]?.filter((el) => el !== undefined);
    const FindUndefined = UnmatchCurrencyFrom?.some((el) => el == undefined);

    // const colmaps = {};
    // jsonData?.forEach((obj) => {
    //     if (!colmaps[obj.column_name]) {
    //         colmaps[obj.column_name] = [];
    //     }
    //     colmaps[obj.column_name].push(obj);
    // });
    const ValidCurrencyCheck = updatedArray?.filter((el) => el?.To_Id == null);
    const FindDuplicatesFrom = updatedArray?.filter((el) => el?.From_Id == null);
    const FindDuplicatesTo = updatedArray?.filter((el) => el?.To_Id == null);
    const fromCurrencyArray = FindDuplicatesFrom?.map((item) => item["From Currency"]);
    const toCurrencyArray = FindDuplicatesTo?.map((item) => item["To Currency"]);
    const FindSameData = updatedArray?.filter((item) => item["From Currency"] == item["To Currency"]);
    const FindSameDatevalue = FindSameData?.map((item) => item["From Currency"]);
    const invalidExchangeRates = updatedArray?.filter((item) => isNaN(parseFloat(+item["Exchange Rate"])));

    const checkForEmptyValues = () => {
        // Function to check if any value in the objects is empty
        for (let obj of updatedArray) {
            for (let key in obj) {
                if (obj[key] === "") {
                    return true; // Found an empty value, return true
                }
            }
        }
        return false; // No empty values found
    };

    const isEmpty = updatedArray ? checkForEmptyValues() : "";

    const Submit = () => {
        if (fileList?.length == 0) {
            Swal.fire({
                text: `Please select any files to upload!`,
                icon: "warning",
                closeOnClickOutside: false,
                showCloseButton: true,
                buttons: {
                    confirm: "Confirm",
                },
            });
        } else if (isEmpty) {
            Swal.fire({
                text: `Empty Values are not allowed!`,
                icon: "warning",
                closeOnClickOutside: false,
                showCloseButton: true,
                buttons: {
                    confirm: "Confirm",
                },
            });
        } else if ((unmatchedCurrencies?.length > 0 && UnmatchCurrencyFrom?.length > 0) || (ValidCurrencyCheck?.length > 0) || (FindSameData?.length > 0) || (invalidExchangeRates?.length > 0)) {
            // Swal.fire({
            //     // text: `The Currency is not matching with application's currency list`,
            //     icon: "warning",
            //     html: `The Following currencies does not match with currency dropdown:${UnmatchCurrencyFrom}`,
            //     closeOnClickOutside: false,
            //     showCloseButton: true,
            //     buttons: {
            //         confirm: "Confirm",
            //     },
            // });
            setModalOpen(true)
        } else if (unmatchedCurrencies?.length > 0 && UnmatchCurrencyFrom?.length == 0) {
            Swal.fire({
                // text: `The Currency is not matching with application's currency list`,
                icon: "warning",
                html: `There no currency list matched with currency dropdown`,
                closeOnClickOutside: false,
                showCloseButton: true,
                buttons: {
                    confirm: "Confirm",
                },
            });
            // setModalOpen(true)
        }
        // else if (ValidCurrencyCheck?.length > 0) {
        //     // Swal.fire({
        //     //     // text: `The Currency is not matching with application's currency list`,
        //     //     icon: "warning",
        //     //     html: `The Following currencies does not match with application's currency list:${fromCurrencyArray} ${toCurrencyArray}`,
        //     //     closeOnClickOutside: false,
        //     //     showCloseButton: true,
        //     //     buttons: {
        //     //         confirm: "Confirm",
        //     //     },
        //     // });
        //     setModalOpen(true)
        // }
        //  else if (FindSameData?.length > 0) {
        //     Swal.fire({
        //         // text: `The Currency is not matching with application's currency list`,
        //         icon: "warning",
        //         html: `The From Currency should not be as same as the To Currency - ${FindSameDatevalue}`,
        //         closeOnClickOutside: false,
        //         showCloseButton: true,
        //         buttons: {
        //             confirm: "Confirm",
        //         },
        //     });
        // } 
        // else if (invalidExchangeRates?.length > 0) {
        //     Swal.fire({
        //         // text: `The Currency is not matching with application's currency list`,
        //         icon: "warning",
        //         html: `The Following Exchange Rates are Invalid ${invalidExchangeRates?.map((el) => el["Exchange Rate"])}`,
        //         closeOnClickOutside: false,
        //         showCloseButton: true,
        //         buttons: {
        //             confirm: "Confirm",
        //         },
        //     });
        // } 
        else {
            setIsSubmit(true);
            // ModalClose();
            const ReqArray = [];
            updatedArray?.forEach((item) => {
                let Headers = {
                    validfrom_date: moment(new Date(item["Valid From"]))?.format("YYYY-MM-DD") || "",
                    from_currency: item["From_Id"],
                    from_currency_code: item["From Currency"],
                    to_currency: item["To_Id"],
                    to_currency_code: item["To Currency"],
                    exchange_rate: item["Exchange Rate"],
                };

                ReqArray?.push(Headers);
            });
            const reqBody = {
                json: ReqArray,
            };
            dispatch(UploadExchangeRate(reqBody));
        }
    };
    const handleSuccess = () => {
        Swal.fire({
            text: "Exchange Rate Uploaded Successfully!",
            icon: "success",
            closeOnClickOutside: false,
            showCloseButton: true,
            button: "Ok",
        }).then((confirm) => {
            if (confirm?.isConfirmed) {
                ModalClose();
                dispatch({ type: UPLOAD_EXCHANGE_RATE_RESET });
                dispatch(ExchangeRateListAction(currency));
            }
        });
    };
    const handleError = () => {
        Swal.fire({
            text: UploadFile?.error?.error,
            icon: "error",
            closeOnClickOutside: false,
            showCloseButton: true,
            button: "Ok",
        }).then((confirm) => {
            if (confirm?.isConfirmed) {
                dispatch({ type: UPLOAD_EXCHANGE_RATE_RESET });
            }
        });
    };
    if (UploadFile?.requestComplete) {
        handleSuccess();
    } else if (UploadFile?.error) {
        handleError();
    }

    const handleModalClose = (type) => {
        setModalOpen(false);
    };

    return (
        <>
            <Modal editModalOpen={uploadModalOpen} editModalClose={ModalClose} center="center" className={`${className}`}>
                <Card
                    className={`h-[441px] w-[880px] overflow-y-auto px-20 py-10 border-[1px] relative ${currentMode === "dark" ? "" : "shadow-[0px_4px_20px_rgba(238,238,238,0.5)]"
                        } border-[rgba(223,225,226,0.56)] flex items-center flex-col justify-center`}>

                    <Button onClick={ModalClose} className="absolute top-0 right-0 mt-2 mr-2 text-gray-500 hover:text-gray-700 ">
                        <img src="/images/common/closeBlk.svg" alt="closeBlk" className="convertWhite pt-4 pr-4" />
                    </Button>

                    <form className={styles?.fileUpload}>
                        <Input
                            type="file"
                            className="file cursor-pointer w-[740px] h-[200px]"
                            disabled={disableInput}
                            name="file"
                            onChange={handleSubmit}
                            accept="application/vnd.ms-excel,
                        application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                        />
                        {fetching ? (
                            <Loader className="h-full" />
                        ) : (
                            <>
                                <img className="mb-4" src="/images/common/fileUpload.svg" alt="fileUpload" />
                                <span className={`text-xl mb-1 font-interM`}>Upload your file directly here</span>
                            </>
                        )}
                        <span className={`text-xs mb-1 font-interS flex flex-wrap`}>
                            Download the{" "}
                            <div
                                className="relative z-[2] cursor-pointer underline mx-1"
                                onClick={(e) => {
                                    // console.log("clicked")
                                    downloadSampleFile(SampleCsv);
                                }}>
                                sample
                            </div>{" "}
                            template, update it with the required data, and upload the file.
                        </span>
                    </form>

                    {fileStatusList.length > 0 && fileList.length > 0 && (
                        <div className="bg-[rgba(222,248,243,0.5)] mt-5 p-[20px] rounded-[8px] overflowscroll w-full max-w-[740px]">
                            <ul className="space-y-2">
                                {fileList.map((item, index) => {
                                    return (
                                        <li
                                            key={index}
                                            className="bg-light-10 flex px-3 py-2 space-x-2 rounded-[5px] flex items-center justify-between text-white w-full">
                                            <div className="flex min-w-[21rem] flex-1 items-center space-x-5">
                                                <Button
                                                    className={`${!item.status ? `bg-transparent` : `bg-white`
                                                        } w-[15px] grid place-items-center h-[15px] rounded-full`}>
                                                    <img
                                                        src={!item.status ? `/images/common/warning-ico.svg` : `/images/common/tick-small.svg`}
                                                        alt="tick-small"
                                                    />
                                                </Button>{" "}
                                                <span title={item.fileName} className="truncate flex-1">
                                                    {" "}
                                                    {item.fileName}
                                                </span>
                                            </div>
                                            {!item.status && (
                                                <span title={item.error} className="truncate flex-1 text-light-100">
                                                    Error: {item.error}
                                                </span>
                                            )}
                                            <Button disabled={UploadFile?.fetching} onClick={() => removeFile(index, item.fileName)}>
                                                <img src="/images/common/close-small.svg" alt="close-small" title="To remove this document" />
                                            </Button>
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                    )}
                    <Button
                        type="submit"
                        disabled={UploadFile?.fetching}
                        title="To manually upload the Invoices"
                        onClick={Submit}
                        className="mt-6 border-transparent approve-step border-440 w-[103px] h-[40px] p-5 bg-light-20 rounded-[8px] text-base flex justify-center items-center border-[1px] text-white">
                        Submit
                    </Button>

                </Card>

            </Modal>
            {ReactDOM.createPortal(
                <ErrorTableModal
                    uploadModalOpen={modalOpen}
                    FindSameData={FindSameData}
                    unmatchedCurrencies={unmatchedCurrencies}
                    ValidCurrencyCheck={ValidCurrencyCheck}
                    invalidExchangeRates={invalidExchangeRates}
                    className={modalOpen && "Show"}
                    uploadModalClose={() => handleModalClose()}

                />,
                document.getElementById("user-modal-root")
            )}
        </>
    );
};

export default UploadModal;
