import axios from "axios";
import authHeader from "./auth-header";
import { checkApiStatus } from "../../utils/helpers";
import {
    ADD_UPDATE_EXCHANGE_RATE_FAILURE,
    ADD_UPDATE_EXCHANGE_RATE_REQUEST,
    ADD_UPDATE_EXCHANGE_RATE_SUCCESS,
    CURRENCY_DROPDOWN_BYORG_EXCHANGE_RATE_FAILURE,
    CURRENCY_DROPDOWN_BYORG_EXCHANGE_RATE_REQUEST,
    CURRENCY_DROPDOWN_BYORG_EXCHANGE_RATE_SUCCESS,
    CURRENCY_DROPDOWN_EXCHANGE_RATE_FAILURE,
    CURRENCY_DROPDOWN_EXCHANGE_RATE_REQUEST,
    CURRENCY_DROPDOWN_EXCHANGE_RATE_SUCCESS,
    DELETE_EXCHANGE_RATE_FAILURE,
    DELETE_EXCHANGE_RATE_REQUEST,
    DELETE_EXCHANGE_RATE_SUCCESS,
    HISTORY_EXCHANGE_RATE_FAILURE,
    HISTORY_EXCHANGE_RATE_REQUEST,
    HISTORY_EXCHANGE_RATE_SUCCESS,
    LIST_EXCHANGE_RATE_FAILURE,
    LIST_EXCHANGE_RATE_REQUEST,
    LIST_EXCHANGE_RATE_SUCCESS,
    UPLOAD_EXCHANGE_RATE_FAILURE,
    UPLOAD_EXCHANGE_RATE_REQUEST,
    UPLOAD_EXCHANGE_RATE_SUCCESS,
} from "../types/types";
const API_URL = process.env?.REACT_APP_NODE_API_URL;

export const AddUpdateExchangeRate = (data) => async (dispatch) => {
    try {
        dispatch({ type: ADD_UPDATE_EXCHANGE_RATE_REQUEST });

        axios
            .post(`${API_URL}api/ExchangeRate/AddUpdateExchangeRate`, data, {
                headers: authHeader(),
            })
            .then((response) => {
                dispatch({
                    type: ADD_UPDATE_EXCHANGE_RATE_SUCCESS,
                    payload: response?.data?.datalist,
                });
            })
            .catch(function (error) {
                if (error?.response?.status == 401) {
                    checkApiStatus();
                }
                dispatch({
                    payload: { error: error?.response?.data?.message },
                    type: ADD_UPDATE_EXCHANGE_RATE_FAILURE,
                });
            });
    } catch (error) {
        dispatch({
            type: ADD_UPDATE_EXCHANGE_RATE_FAILURE,
        });
    }
};
export const UploadExchangeRate = (data) => async (dispatch) => {
    try {
        dispatch({ type: UPLOAD_EXCHANGE_RATE_REQUEST });

        axios
            .post(`${API_URL}api/ExchangeRate/UploadExchangeRate`, data, {
                headers: authHeader(),
            })
            .then((response) => {
                dispatch({
                    type: UPLOAD_EXCHANGE_RATE_SUCCESS,
                    payload: response?.data?.datalist,
                });
            })
            .catch(function (error) {
                if (error?.response?.status == 401) {
                    checkApiStatus();
                }
                dispatch({
                    payload: { error: error?.response?.data?.message },
                    type: UPLOAD_EXCHANGE_RATE_FAILURE,
                });
            });
    } catch (error) {
        dispatch({
            type: UPLOAD_EXCHANGE_RATE_FAILURE,
        });
    }
};

export const GetCurrencyListExchangeRate = (data) => async (dispatch) => {
    try {
        dispatch({ type: CURRENCY_DROPDOWN_EXCHANGE_RATE_REQUEST });

        axios
            .get(`${API_URL}api/ExchangeRate/GetCurrency`, {
                headers: authHeader(),
            })
            .then((response) => {
                dispatch({
                    type: CURRENCY_DROPDOWN_EXCHANGE_RATE_SUCCESS,
                    payload: response?.data?.datalist,
                });
            })
            .catch(function (error) {
                if (error?.response?.status == 401) {
                    checkApiStatus();
                }
                dispatch({
                    payload: { error: error.message },
                    type: CURRENCY_DROPDOWN_EXCHANGE_RATE_FAILURE,
                });
            });
    } catch (error) {
        dispatch({
            type: CURRENCY_DROPDOWN_EXCHANGE_RATE_FAILURE,
        });
    }
};
export const GetCurrencyListExchangeRateByOrg = (data) => async (dispatch) => {
    try {
        dispatch({ type: CURRENCY_DROPDOWN_BYORG_EXCHANGE_RATE_REQUEST });

        axios
            .get(`${API_URL}api/ExchangeRate/GetCurrencyByorg`, {
                headers: authHeader(),
            })
            .then((response) => {
                dispatch({
                    type: CURRENCY_DROPDOWN_BYORG_EXCHANGE_RATE_SUCCESS,
                    payload: response?.data?.datalist,
                });
            })
            .catch(function (error) {
                if (error?.response?.status == 401) {
                    checkApiStatus();
                }
                dispatch({
                    payload: { error: error.message },
                    type: CURRENCY_DROPDOWN_BYORG_EXCHANGE_RATE_FAILURE,
                });
            });
    } catch (error) {
        dispatch({
            type: CURRENCY_DROPDOWN_BYORG_EXCHANGE_RATE_FAILURE,
        });
    }
};
// Below Action (API) call to get the get view tabler reports
export const DeleteExchangeRateAction = (data) => async (dispatch) => {
    try {
        dispatch({ type: DELETE_EXCHANGE_RATE_REQUEST });

        axios
            .post(`${API_URL}api/ExchangeRate/DeleteExchangeRate`, data, {
                headers: authHeader(),
            })
            .then((response) => {
                dispatch({
                    type: DELETE_EXCHANGE_RATE_SUCCESS,
                    payload: response?.data?.datalist,
                });
            })
            .catch(function (error) {
                if (error?.response?.status == 401) {
                    checkApiStatus();
                }
                dispatch({
                    payload: { error: error.message },
                    type: DELETE_EXCHANGE_RATE_FAILURE,
                });
            });
    } catch (error) {
        dispatch({
            type: DELETE_EXCHANGE_RATE_FAILURE,
        });
    }
};
// Below Action (API) call to get the Columns list
export const HistoryExchangeRateAction = (from, to) => async (dispatch) => {
    try {
        dispatch({ type: HISTORY_EXCHANGE_RATE_REQUEST });

        axios
            .get(`${API_URL}api/ExchangeRate/GetExchageRateHistory?fromcurrency=${from}&tocurrency=${to}`, {
                headers: authHeader(),
            })
            .then((response) => {
                dispatch({
                    type: HISTORY_EXCHANGE_RATE_SUCCESS,
                    payload: response?.data?.datalist,
                });
            })
            .catch(function (error) {
                if (error?.response?.status == 401) {
                    checkApiStatus();
                }
                dispatch({
                    payload: { error: error.message },
                    type: HISTORY_EXCHANGE_RATE_FAILURE,
                });
            });
    } catch (error) {
        dispatch({
            type: HISTORY_EXCHANGE_RATE_FAILURE,
        });
    }
};

// Below Action (API) call to get the Master filter dropdown data
export const ExchangeRateListAction = (id) => async (dispatch) => {
    try {
        dispatch({ type: LIST_EXCHANGE_RATE_REQUEST });

        axios
            .get(`${API_URL}api/ExchangeRate/GetExchageRate?fromcurrency=${id}`, {
                headers: authHeader(),
            })
            .then((response) => {
                dispatch({
                    type: LIST_EXCHANGE_RATE_SUCCESS,
                    payload: response?.data?.datalist,
                });
            })
            .catch(function (error) {
                if (error?.response?.status == 401) {
                    checkApiStatus();
                }
                dispatch({
                    payload: { error: error.message },
                    type: LIST_EXCHANGE_RATE_FAILURE,
                });
            });
    } catch (error) {
        dispatch({
            type: LIST_EXCHANGE_RATE_FAILURE,
        });
    }
};
