import { useContext, useEffect, useState } from "react";
import Modal from "../../components/UI/Modal/Modal";
import Input from "../../components/Input";
import Button from "../../components/Button";
import Card from "../../components/UI/Card";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { ThemeContext } from "../../Hooks/Themes/Themeprovider";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TextField } from "@mui/material";
import ExchangeRate from "./ExchangeRate";
import { useDispatch } from "react-redux";
import { AddUpdateExchangeRate, ExchangeRateListAction } from "../../redux/actions/exchange-rate";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import { ADD_UPDATE_EXCHANGE_RATE_RESET } from "../../redux/types/types";
import moment from "moment";

const AddCurrency = ({ addModalOpen, addModalClose, className, handleAddCurrency, userDateFormat, currency }) => {
    const { currentMode } = useContext(ThemeContext);
    const [currencySymbol, setCurrencySymbol] = useState("");
    const [currencyDescription, setCurrencyDescription] = useState("");
    const [currencyCode, setCurrencyCode] = useState("");
    const [exchangeRate, setExchangeRate] = useState("");
    const dropdownColor = currentMode === "dark" ? "#333333" : `white`;
    const dropdownText = currentMode === "dark" ? "#ECECEC" : `#555555`;
    const dropdownShadow = currentMode !== "dark" ? "0px 4px 20px rgba(238, 238, 238, 0.5)" : "";
    const dropdownBorder = currentMode !== "dark" ? "223, 225, 226, 0.56" : "";
    const [validFrom, setValidFrom] = useState(null);
    const lastDateOfYear = `12/31/${new Date().getFullYear()}`;
    const [errormsg, setErrormsg] = useState({
        CurrencyCode: "",
    });
    const [ValidError, setValidErrormsg] = useState({
        message: "",
    });
    const [exchangeError, setExchangerror] = useState({
        message: "",
    });
    const AddCurrency = useSelector((state) => state?.AddUpdateExchangeRateReducer);
    const Currency = useSelector((state) => state?.currencyListState);
    const CreateData = useSelector((state) => state?.addUpdateExchangeRateState);
    const currencies = Currency?.data
    const handleValidFromChange = (newValidFrom) => {
        setValidFrom(newValidFrom?.$d);
    };
    const handleChange = (e) => {
        const selectedId = parseInt(e.target.value, 10);
        const selectedCurrencyData = currencies.find((currency) => currency.currency_id === selectedId);
        if (selectedCurrencyData) {
            setCurrencyCode(selectedCurrencyData.currency_id);
            setCurrencySymbol(selectedCurrencyData.currency_symbol);
            setCurrencyDescription(selectedCurrencyData.currency_name);
        }
    };
    const AccessIcon = () => <img src="/images/invoice/calendar.svg" alt="calendar" className="convertWhite" />;
    const dispatch = useDispatch();

    const handleClose = (e) => {
        addModalClose();
        e?.preventDefault();
        setCurrencyCode("");
        setCurrencySymbol("");
        setCurrencyDescription("");
        setValidFrom(null);
        setExchangeRate("");
        setErrormsg({
            CurrencyCode: "",
        });
        setValidErrormsg({
            message: "",
        });
        setExchangerror({
            message: "",
        });
        dispatch({ type: ADD_UPDATE_EXCHANGE_RATE_RESET })

    };
    const handleSuccess = () => {
        Swal.fire({
            text: "Exchange rate is added successfully!",
            icon: "success",
            closeOnClickOutside: false,
            showCloseButton: true,
            button: "Ok",
        }).then((confirm) => {
            if (confirm) {
                handleClose();

                dispatch({ type: ADD_UPDATE_EXCHANGE_RATE_RESET });
                dispatch(ExchangeRateListAction(currency));
            }
        });
    };
    const handleError = () => {
        Swal.fire({
            text: CreateData?.error?.error,
            icon: "error",
            closeOnClickOutside: false,
            showCloseButton: true,
            button: "Ok",
        }).then((confirm) => {
            if (confirm) {
                dispatch({ type: ADD_UPDATE_EXCHANGE_RATE_RESET });
            }
        });
    };
    // useEffect(() => {

    // }, [])
    const handleSubmit = (e) => {
        e?.preventDefault();
        if (!currencyCode || !validFrom || !exchangeRate) {
            if (!currencyCode) {
                setErrormsg({
                    CurrencyCode: "Currency Code is Required!",
                });
            } else {
                setErrormsg({
                    CurrencyCode: "",
                });
            }

            if (!validFrom) {
                setValidErrormsg({
                    message: "Valid From is Required!",
                });
            } else {
                setValidErrormsg({
                    message: "",
                });
            }
            if (!exchangeRate) {
                setExchangerror({
                    message: "Exchange Rate is Required or Invalid!"
                });
            }

            else {
                setExchangerror({
                    message: "",
                });
            }
        } else {
            setErrormsg({
                CurrencyCode: "",
            });
            setValidErrormsg({
                message: "",
            });
            setExchangerror({
                message: "",
            });
            const newCurrency = {
                validfrom: moment(new Date(validFrom))?.format("YYYY-MM-DD") || "",
                fromcurrency: currency,
                tocurrency: currencyCode,
                exchangerate: exchangeRate,
                exchangerateid: 0,
            };
            handleAddCurrency(newCurrency);
            // setCurrencyCode("");
            // setCurrencySymbol("");
            // setCurrencyDescription("");
            // addModalClose();
            dispatch(AddUpdateExchangeRate(newCurrency));
        }
    };
    if (CreateData?.requestComplete) {
        handleSuccess();
    } else if (CreateData?.error) {
        handleError();
    }

    const CurrencyData = currencies?.filter((el) => el?.currency_id !== currency);
    return (
        <Modal addmodalOpen={addModalOpen} addmodalClose={addModalClose} center="center" className={`${className}`}>
            <Card>
                <div className="fixed z-10 inset-0 overflow-y-auto flex items-center justify-center min-h-screen px-4">
                    <div
                        className={`flex flex-col relative  min-w-[460px] xl:ml-[450px] min-h-[411px] md:mx-auto bg-${currentMode}-card text-${currentMode}-copy border-${currentMode}-700 px-[10px] pb-[30px] rounded-[10px] ${currentMode === "dark" ? "" : "shadow-[0px_4px_20px_rgba(238,238,238,0.5)]"
                            }`}
                    >
                        <div className={`flex justify-between items-center border border-${currentMode}-700 py-2 absolute inset-x-0`}>
                            <p className="text-base font-bold ml-8 mt-2 pb-1">Add Currency</p>
                            <Button className="text-gray-500 hover:text-gray-700 mt-2 mr-2 " onClick={handleClose}>
                                <img src="/images/common/closeBlk.svg" alt="closeBlk" className="convertWhite w-3.5 h-3.5 mr-8" />
                            </Button>
                        </div>
                        <div className="px-3 mt-[50px]">
                            <div className="mt-[20px]">
                                <span className={`block font-semibold text-sm pb-3 flex-auto`}>
                                    <span className="text-[red] ml-3 mr-2">*</span>
                                    Currency Code
                                </span>
                                <FormControl
                                    sx={{
                                        width: "400px",
                                    }}
                                >
                                    <Select
                                        name="currencyCode"
                                        className={` w-[400px] h-[40px] bg-${currentMode}-card mx-3 text-xs rounded-[5px] `}
                                        value={currencyCode || "default"}
                                        onChange={(e) => handleChange(e)}
                                        IconComponent={() => (
                                            <img
                                                className="absolute opacity-50 right-[16px] pointer-events-none"
                                                src={
                                                    currentMode !== "dark" ? `/images/header/chevronDown.svg` : `/images/header/chevronDown-white.svg`
                                                }
                                                alt=""
                                            />
                                        )}
                                        MenuProps={{
                                            sx: {
                                                "& .MuiMenu-paper": {
                                                    maxHeight: 200,
                                                    backgroundColor: dropdownColor,
                                                    color: dropdownText,
                                                    boxShadow: dropdownShadow,
                                                    border: dropdownBorder,
                                                    borderRadius: "8px",
                                                },
                                            },
                                        }}
                                        SelectDisplayProps={{
                                            style: {
                                                display: "flex",
                                                border: 0,
                                                color: currentMode === "dark" ? `#D3FAF4` : `#555555`,
                                                paddingRight: "20px",

                                            },
                                        }}
                                    >
                                        <MenuItem value="default" className="hidden">
                                            Select
                                        </MenuItem>

                                        {CurrencyData?.map((currency) => (
                                            <MenuItem key={currency.currency_id} value={currency.currency_id}>
                                                {currency.currency_code} - {currency.currency_name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    {errormsg?.CurrencyCode && (
                                        <p className="text-light-50 text-xs ml-[15px]">
                                            {errormsg?.CurrencyCode}
                                        </p>
                                    )}
                                </FormControl>
                            </div>
                            <div className="px-3 mt-[20px]">
                                <span className={`block text-sm font-semibold pb-3 flex-auto`}>
                                    <span className="text-[red] mr-2">*</span>
                                    Currency Symbol
                                </span>
                                <Input
                                    name="currencySymbol"
                                    type="text"
                                    placeholder="Currency Symbol"
                                    value={currencySymbol}
                                    readOnly
                                    className={`appearance-none bg-transparent border border-${currentMode}-1390 rounded-lg w-[400px] h-[40px] py-3 px-3  leading-tight focus:outline-none focus:shadow-outline text-${currentMode}-copy !font-interR`}
                                    onChange={(e) => setCurrencySymbol(e.target.value)}
                                />
                            </div>
                            <div className="px-3 mt-[20px]">
                                <span className={`block text-sm font-semibold pb-3 flex-auto`}>
                                    <span className="text-[red] mr-2">*</span>
                                    Description
                                </span>
                                <Input
                                    name="currencyDescription"
                                    type="text"
                                    placeholder="Description"
                                    readOnly
                                    value={currencyDescription}
                                    className={`appearance-none bg-transparent border border-${currentMode}-1390 rounded-lg w-[400px] h-[40px] py-3 px-3 leading-tight focus:outline-none focus:shadow-outline !font-interR`}
                                    onChange={(e) => setCurrencyDescription(e.target.value)}
                                />
                            </div>
                            <div className="px-3 mt-[20px]">
                                <span className={`block text-sm font-semibold pb-3 flex-auto`}>
                                    <span className="text-[red] ml-1 mr-2">*</span>
                                    Valid From
                                </span>
                                <div className={`datePic`} title="Calendar">
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DesktopDatePicker
                                            inputFormat={userDateFormat?.toUpperCase()}
                                            className={` h-[45px]`}
                                            components={{
                                                OpenPickerIcon: AccessIcon,
                                            }}
                                            disableFuture={true}
                                            value={validFrom}
                                            name="validFrom"
                                            minDate={new Date(1999, 12)}
                                            maxDate={new Date(lastDateOfYear)}
                                            onChange={handleValidFromChange}
                                            renderInput={(params) => (
                                                <TextField
                                                    value={validFrom}
                                                    sx={{
                                                        "& .MuiInputBase-input": {
                                                            color: `${currentMode === "dark" ? "white" : "black"} !important`,
                                                        },
                                                    }}
                                                    fullWidth
                                                    placeholder={params.inputProps?.placeholder?.replace("From")}
                                                    {...params}
                                                    inputProps={{
                                                        ...params.inputProps,
                                                        readOnly: true,
                                                    }}
                                                />
                                            )}
                                        />
                                    </LocalizationProvider>
                                </div>
                                {ValidError?.message && <p className="text-light-50 text-xs">{ValidError?.message}</p>}
                            </div>
                            <div className="px-3 mt-[20px]">
                                <span className={`block text-sm font-semibold pb-3 flex-auto`}>
                                    <span className="text-[red] mr-2">*</span>
                                    Exchange Rate
                                </span>
                                <input
                                    name="Exchange Rate"
                                    placeholder="Enter exchange rate"
                                    type="number"
                                    value={exchangeRate}
                                    onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                                    className={`appearance-none bg-transparent border border-${currentMode}-1390 rounded-lg w-[400px] h-[40px] py-3 px-3 leading-tight focus:outline-none focus:shadow-outline !font-interR`}
                                    onChange={(e) => setExchangeRate(e.target.value)}
                                />
                                {exchangeError?.message && <p className="text-light-50 text-xs">{exchangeError?.message}</p>}
                            </div>
                        </div>
                        <div className="flex justify-left pt-5 pl-6 space-x-3 mt-[10px]">
                            <Button
                                className="border-transparent approve-step border-440 w-[103px] h-[40px] p-5 bg-light-20 rounded-[8px] text-base flex justify-center items-center border-[1px] text-white"
                                onClick={handleSubmit}
                                disabled={CreateData?.fetching}
                            >
                                Save
                            </Button>
                            <Button
                                className=" border-transparent approve-step border-440 !font-interR w-[103px] h-[40px] p-5 bg-gray-200 rounded-[8px] text-base flex justify-center items-center border-[1px] text-black"
                                onClick={handleClose}
                            >
                                Cancel
                            </Button>
                        </div>
                    </div>
                </div>
            </Card>
        </Modal>
    );
};

export default AddCurrency;
