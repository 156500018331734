import { useContext, useEffect, useRef, useState } from "react";
import Card from "../../../components/UI/Card";
import { ThemeContext } from "../../../Hooks/Themes/Themeprovider";
import Input from "../../../components/Input";
import { MapModal } from "./MapModal";
import { ClickAwayListener, Popper } from "@mui/material";
import { JsonTarget } from "../../../organisms/DropTarget/JsonTarget";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { showSwal } from "../../../utils/helpers";
import { useDispatch } from "react-redux";
import _ from "lodash";
import { setDroppedValues } from "../../../redux/actions/filter";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import { GetColumnsList } from "../../../redux/actions/output-management";

export const JsonTble = ({ type, isView, prevType }) => {
    const dispatch = useDispatch();
    const { currentMode } = useContext(ThemeContext);
    const [placement, setPlacement] = useState();
    const [popstate, setPopState] = useState({
        value: null,
        anchorEl: null,
        id: null,
    });
    useEffect(() => {
        if (isView) {
            const OrgId = localStorage.getItem("orgId");
            dispatch(GetColumnsList({ type: +type, orgid: +OrgId, outputType: prevType }));
        }
    }, [dispatch]);
    const handleClickAway = () => {
        setPopState({ id: false, anchorEl: false, value: "" });
        setIsClick([]);
    };
    const handleClick = (newPlacement, value, event, id = null) => {
        setPopState({ value, anchorEl: event.currentTarget, id }, (prev) => placement !== newPlacement || !prev);
        setPlacement(newPlacement);
    };
    const handleDrop = (itemValue, areaName, index, id, IsNormal) => {
        const GetParent = jsonData?.filter((el) => el?.id == id)?.map((el) => el?.isArray);
        if (itemValue?.multiple_objects && !GetParent?.[0]) {
            dispatch({ type: "UPDATE_ISARRAY", payload: { id, isArray: true } });
        }
        if (areaName == "json") {
            // Update the content of the dropped row based on the index or ID
            dispatch(setDroppedValues(itemValue, true, areaName));
            dispatch({ type: "UPDATE_ROW_CONTENT", payload: { itemValue, index, id } });
        }
    };
    const { jsonData } = useSelector((state) => state?.droppedState);

    const handleFocus = () => {
        // Add new dummy data when the user focuses on the last input field
        dispatch({ type: "ADD_ROW" });
    };

    const handleChange = (index, field, value, parentid, checkIsarray) => {
        if (value != "" && !/^\S(?:.*\S)?\s?$/.test(value)) {
            showSwal(`Leading or multiple trailing spaces are not allowed!`);
            return false;
        }
        const isConflict = jsonData?.some(
            (item) => item?.label !== "" && item?.label?.toLowerCase() === value?.toLowerCase() && item?.parent_id === (parentid !== "" && parentid)
        );
        if (isConflict) {
            showSwal(`${value} is already exist!`);
            return false;
        }
        // Dispatch an action to update the label in Redux state
        dispatch({ type: "UPDATE_JSON_ROWS", payload: { index, field, value: value, parentid, checkIsarray } });
    };

    const convertedData = {};
    jsonData?.forEach((item) => {
        if (item?.label?.trim() !== "") {
            // Only add items with non-empty labels
            convertedData[item.label] = typeof item.content == "object" ? item?.content?.field_display_name : item?.content;
        }
    });

    const [isClick, setIsClick] = useState([]);
    const [isIndex, setIsIndex] = useState();
    const unique = [...new Set(isClick)];
    // console.log(jsonData, "json");
    function createDynamicJSON(data) {
        const result = {};

        function buildJSON(parent, items) {
            items.forEach((item) => {
                const key = item?.label;
                const isChild = item?.isChild;
                const isArray = item?.isArray;
                const refId = item?.id;
                const value = item?.content;

                if (!parent[key]) {
                    if (isChild || key == "") {
                        parent[key] = isArray == false ? (isChild == true ? {} : "") : [{}];
                        buildJSON(
                            isArray == false ? parent[key] : parent[key][0],
                            data?.filter((d) => d?.parent_id === refId)
                        );
                    } else {
                        parent[key] = isArray ? {} : typeof value == "object" ? value?.field_display_name : value;
                        if (isArray) {
                            buildJSON(
                                parent[key],
                                data?.filter((d) => d?.parent_id === refId)
                            );
                        }
                    }
                }
            });
        }

        buildJSON(
            result,
            data?.filter((d) => d?.parent_id === "")
        );

        return result;
    }
    const checkRemove = (idToRemove, name) => {
        Swal.fire({
            text: `Are you sure you want to delete ${!name ? `this` : name} row?`,
            icon: "warning",
            showCloseButton: true,
            showCancelButton: true,
            buttons: {
                cancel: "Cancel",
                confirm: "Confirm",
            },
        }).then((confirm) => {
            if (confirm?.isConfirmed) {
                dispatch({
                    type: "DELETE_JSON_ROW",
                    payload: idToRemove,
                });
            }
        });
    };

    const handleEditClick = (index) => {
        const Input = document?.querySelector(`.INP-${index}`);
        if (Input) {
            Input?.focus();
        }
    }; // Input focus manually on Pencil(Edit) icon on click...!
    return (
        <div className="xl:flex space-y-[20px] xl:space-y-0 px-[30px] xl:space-x-[22px]">
            <div className="basis-[70%]">
                <Card className={`border min-h-[702px] max-h-[702px] overflow-y-auto border-${currentMode}-560 rounded-[8px]`}>
                    <div className={`bg-${currentMode}-20 sticky top-0 z-[1] rounded-t-[8px] py-[12px] px-[20px] flex xl:justify-end  text-white`}>
                        <div className="flex items-center cursor-pointer font-interSb" onClick={(e) => handleClick("bottom-end", 1, e)}>
                            <span>Map Fields</span>
                            <div>
                                <img className="ml-2" src="/images/Output-manage/plus.svg" alt="plus" />
                            </div>
                        </div>
                    </div>
                    <div className={`flex sticky z-[1] top-[46px] bg-${currentMode}-card`}>
                        <div className="basis-1/2 px-[20px] py-[9px] border-r border-b">{`{ }`}</div>
                        <div className="basis-1/2 px-[20px] py-[9px] border-b">Content</div>
                    </div>
                    {jsonData?.map((el, i) => {
                        return (
                            <div className="flex items-center">
                                <div
                                    className={`basis-1/2 flex justify-between actionHover ${
                                        unique?.includes(i) ? `clicked` : ``
                                    } px-[20px] py-[9px] border-r border-b`}
                                >
                                    <div className="flex flex-auto max-w-[190px] xl:max-w-full">
                                        <img
                                            style={{ marginLeft: el?.indent !== 1 ? `${el?.indent}rem` : `` }}
                                            className={`mr-[10px] `}
                                            src={
                                                el?.isChild == true
                                                    ? `/images/Output-manage/parent-ico.svg`
                                                    : el?.parent_id == "" && el?.isChild == false
                                                    ? `/images/Output-manage/file-ico.svg`
                                                    : `/images/Output-manage/child-ico.svg`
                                            }
                                            alt="parent-co"
                                        />
                                        <Input
                                            name={`label-${i}`}
                                            className={`w-full INP-${i}`}
                                            value={el?.label}
                                            onFocus={i === jsonData?.length - 1 ? handleFocus : null}
                                            placeholder={`Type here`}
                                            onChange={(e) => handleChange(i, "label", e?.target?.value, el?.parent_id)}
                                        />
                                    </div>
                                    <div className="flex space-x-[10px] flex-1 mr-[-5px] justify-end actions items-center">
                                        {el?.label !== "" && (
                                            <span className="cursor-pointer" onClick={() => handleEditClick(i)}>
                                                <img src="/images/Output-manage/edit-blk.svg" alt="edit-blk" />
                                            </span>
                                        )}
                                        <span
                                            className="cursor-pointer"
                                            onClick={(e) => {
                                                setIsIndex(el?.id);
                                                handleClick("bottom-end", 2, e);
                                                setIsClick([...isClick, i]);
                                            }}
                                        >
                                            <img src="/images/Output-manage/plus-blk.svg" alt="plus-blk" />
                                        </span>
                                        <button disabled={jsonData?.length == 1} onClick={() => checkRemove(el?.id, el?.label)}>
                                            <img src="/images/Output-manage/delete-blk.svg" alt="delete-blk" />
                                        </button>
                                    </div>
                                    {isIndex == el?.id ? (
                                        <ClickAwayListener mouseEvent="onMouseDown" touchEvent="onTouchStart" onClickAway={handleClickAway}>
                                            <Popper
                                                className={`bg-${currentMode}-card  text-${currentMode}-copy min-w-[180px] border-[1px] z-[12] border-${currentMode}-700 shadow-[0px_4px_20px_0px] shadow-${currentMode}-1230 rounded-[10px] reportsPopper `}
                                                open={2 === popstate.value}
                                                anchorEl={popstate.anchorEl}
                                                id={2}
                                                placement={placement}
                                            >
                                                <ul className="space-y-[4px] py-[15px]">
                                                    {el?.isChild == true ? (
                                                        <>
                                                            <li
                                                                className={`cursor-pointer py-1 px-[20px] hover:bg-${currentMode}-960`}
                                                                onClick={() =>
                                                                    dispatch({ type: "INSERT_ROW", payload: { isIndex: el?.id, int: el?.indent } })
                                                                }
                                                            >
                                                                Add New Child
                                                            </li>

                                                            <li
                                                                className={`cursor-pointer py-1 px-[20px] hover:bg-${currentMode}-960`}
                                                                onClick={() =>
                                                                    dispatch({
                                                                        type: "ADD_NEW_PARENT_JSON",
                                                                        payload: { isIndex: el?.id, int: el?.indent, parent: el?.parent_id },
                                                                    })
                                                                }
                                                            >
                                                                Add New Parent
                                                            </li>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <li
                                                                className={`cursor-pointer py-1 px-[20px] hover:bg-${currentMode}-960`}
                                                                onClick={() => dispatch({ type: "MAKE_PARENT_JSON", payload: isIndex })}
                                                            >
                                                                Make a Parent
                                                            </li>
                                                        </>
                                                    )}
                                                </ul>
                                            </Popper>
                                        </ClickAwayListener>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                <div className="basis-1/2 border-b">
                                    <DndProvider backend={HTML5Backend}>
                                        <JsonTarget
                                            name={`content-${i}`}
                                            mainData={el}
                                            data={typeof el?.content == "object" ? `@${el?.content?.field_display_name}` : el?.content}
                                            onDrop={handleDrop}
                                            handleChange={handleChange}
                                            index={i}
                                            areaName="json"
                                            currentMode={currentMode}
                                        />
                                    </DndProvider>
                                </div>
                            </div>
                        );
                    })}

                    <ClickAwayListener mouseEvent="onMouseDown" touchEvent="onTouchStart" onClickAway={handleClickAway}>
                        <Popper
                            className={`bg-${currentMode}-card  text-${currentMode}-copy max-w-[270px] border-[1px] z-[12] border-${currentMode}-700 shadow-[0px_4px_20px_0px] shadow-${currentMode}-1230 rounded-[10px] reportsPopper `}
                            open={1 === popstate.value}
                            anchorEl={popstate.anchorEl}
                            id={1}
                            disablePortal
                            placement={placement}
                        >
                            <MapModal currentMode={currentMode} />
                        </Popper>
                    </ClickAwayListener>
                </Card>
            </div>
            <div className="basis-[30%]">
                <div className={`bg-${currentMode}-1320 border overflow-y-auto min-h-[702px] max-h-[702px] border-${currentMode}-560 rounded-[8px]`}>
                    <div className={`bg-${currentMode}-20 rounded-t-[8px] py-[12px] px-[20px] text-white`}>
                        <span className="flex font-interSb">Results</span>
                    </div>
                    <div className="p-[20px] max-w-[400px]">
                        <pre>{JSON.stringify(createDynamicJSON(jsonData), null, 2)}</pre>
                    </div>
                </div>
            </div>
        </div>
    );
};
