import { Checkbox, ClickAwayListener, FormControlLabel, MenuItem, Popper, Select } from "@mui/material";
import _, { concat, isEqual, remove } from "lodash";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../components/Button";
import Loader from "../../components/Loader";
import Card from "../../components/UI/Card";
import { getReportsList, reportsCountTotal } from "../../redux/actions/report";
import ExportExcel from "../ExportFiles/Excel";
import TogglePopup from "../organisations/Toggle";
import { GET_REPORTS_LIST_RESET, GET_REPORTS_COUNT_RESET } from "../../redux/types/types";
import { ThemeContext } from "../../Hooks/Themes/Themeprovider";
import { encryptId } from "../../utils/helpers";
import { Link, NavLink, useNavigate, useLocation, json } from "react-router-dom";

const Table = (props) => {
    const { currentMode } = useContext(ThemeContext);
    let optionValue = ["Invoice Id", "Invoice No", "Vendor Name", "Invoice Date", "Net Amount", "Status"];
    const [values, setValues] = useState(optionValue);
    const [isdownloadClick, onClickDownload] = useState(false);
    const [isMount, setIsMount] = useState(false);
    const location = useLocation();
    const [popstate, setPopState] = useState({
        value: null,
        anchorEl: null,
        id: null,
    });
    const [placement, setPlacement] = useState();

    const handleClickAway = () => {
        setPopState({ id: false, anchorEl: false, value: "" });
    };
    const handleClick = (newPlacement, value, event, id = null) => {
        setPopState({ value, anchorEl: event.currentTarget, id }, (prev) => placement !== newPlacement || !prev);
        setPlacement(newPlacement);
    };

    const checkChange = (e) => {
        const elem = e.target.value;
        if (!e.target.checked) {
            const Remove = values?.filter((el) => el !== elem);
            setValues(Remove);
            localStorage.setItem("customOptions", JSON.stringify(values));
        } else {
            setValues([...values, elem]);
            localStorage.setItem("customOptions", JSON.stringify(values));
        }
    };
    useEffect(() => {
        let Check = JSON.parse(localStorage.getItem("customOptions"));
        if (Check !== undefined && Check !== null && Check !== "") {
            setValues(JSON.parse(localStorage.getItem("customOptions")));
        } else {
            localStorage.setItem("customOptions", JSON.stringify(optionValue));
            setValues(optionValue);
        }
    }, []);

    useEffect(() => {
        if (!localStorage.getItem("customOptions")) {
            localStorage.setItem("customOptions", JSON.stringify(values));
        }
        localStorage.setItem("customOptions", JSON.stringify(values));
    }, [values]);

    const [refreshValue, handleRefreshValue] = useState({
        isStatus: [2],
        invNumber: "",
        isSupplier: "",
    });

    // const {
    //   fromDate: fromDate,
    //   toDate: toDate,
    // } = props;

    const [isAllSelected, setAllSelected] = useState(false);
    const [isIndeterminate, setIndeterminate] = useState(false);
    const [addedInvoice, setAddedInvoice] = useState([]);
    const [isSorting, setSorting] = useState(0);

    const pageNo = props?.currentPageNo;
    const entries = props?.currentPageItem;
    const pageData = useSelector((state) => state?.GetreportCountState);
    const pageCount = pageData && pageData.data && pageData.data?.data ? pageData.data.data.data.rec_cnt : 0;
    let start = (pageNo - 1) * entries + 1;
    const nextPageCount = Math.ceil(pageCount / props?.currentPageItem);
    const [sortColumn, columnSorting] = useState("");
    const [approveList, onApproveInvoice] = useState([]);
    const getApproveInvList = (invList) => {
        onApproveInvoice(invList);
    };
    const onSelectAll = (event) => {
        setAllSelected(event.target.checked);
        setIndeterminate(false);
        dataList?.forEach((invoice) => {
            invoice.checked = event.target.checked;
        });
        setAddedInvoice(dataList);
        getApproveInvList(addedInvoice);
    };
    const orgId = localStorage.getItem("orgId");
    const supplier = props?.isSupplier;
    const invNo = props?.invNumber;
    const status = props?.isStatus;
    const fromDate = props?.fromDate;
    const toDate = props?.toDate;

    const reqObj = {
        status_type: 1,
        status_id: status?.length === 0 ? [0] : status,
        organisation: +orgId,
        vendor_id: supplier?.length === 0 ? null : supplier,
        invoice_date_from: fromDate,
        invoice_date_to: toDate,
        // search_text: "",
        invoice_no: invNo,
        order_column: sortColumn,
        order_by: isSorting,
        pg_no: props?.currentPageNo,
        rec_cnt: props?.currentPageItem,
    };
    const reqObjCount = {
        status_type: 1,
        status_id: status?.length === 0 ? [0] : status,
        invoice_date_from: fromDate,
        invoice_date_to: toDate,
        // search_text: "",
        organisation: +orgId,
        vendor_id: supplier?.length === 0 ? null : supplier,
        invoice_no: invNo,
        order_column: sortColumn,
        order_by: isSorting,
        pg_no: props?.currentPageNo,
        rec_cnt: props?.currentPageItem,
    };
    const CountCalc = pageCount / props?.currentPageItem;

    const Rounded = Math.ceil(CountCalc);

    const onPageNumberSelect = (event) => {
        let pageNumber = event.target.value;
        props?.changePageNo(parseInt(pageNumber));
    };
    const onPageItemSelect = (event) => {
        setAllSelected(false);
        let pageItemVal = event.target.value;
        props?.changePageItem(parseInt(pageItemVal));
        props?.changePageNo(1);
    };
    let EmptyArr = [];
    for (let i = 0; i < Rounded; i++) {
        EmptyArr.push(i);
    }
    const previousBtnClick = () => {
        if (isAllSelected === true) {
            setAllSelected(false);
        }
        if (isIndeterminate == true) {
            setIndeterminate(false);
        }
        props?.changePageNo(props?.currentPageNo - 1);
    };
    const nextBtnClick = () => {
        if (isAllSelected === true) {
            setAllSelected(false);
        }
        if (isIndeterminate == true) {
            setIndeterminate(false);
        }
        props?.changePageNo(props?.currentPageNo + 1);
    };
    const dispatch = useDispatch();

    const onSorting = (columnName) => {
        const sortBy = isSorting === 1 ? 2 : 1;
        if (sortBy === 1) {
            document.getElementById(columnName).classList.add("Up");
        } else {
            document.getElementById(columnName).classList.remove("Up");
        }

        setSorting(sortBy);
        columnSorting(columnName);
    };
    const onSortingIconChange = () => {
        if (requestComplete) {
            if (isSorting === 1) {
                document.getElementById(sortColumn).classList.add("Up");
            } else {
                document.getElementById(sortColumn).classList.remove("Up");
            }
        }
    };
    useEffect(() => {
        if (isMount) {
            async function fetchEmployees() {
                await dispatch(reportsCountTotal(reqObjCount));
                await dispatch(getReportsList(reqObj));
                onSortingIconChange();
            }
            fetchEmployees();
        } else {
            setIsMount(true);
            dispatch({ type: GET_REPORTS_LIST_RESET });
            dispatch({ type: GET_REPORTS_COUNT_RESET });
        }
    }, [dispatch, props?.currentPageNo, props?.currentPageItem, sortColumn, isSorting]);
    const { dataList, requestComplete, fetching, exportData } = useSelector((state) => state?.GetReportListState);
    let totalPageCount = dataList ? dataList.length * (props?.currentPageNo ? props?.currentPageNo : 1) : 0;
    const totalPageExceed = props?.currentPageNo * props?.currentPageItem;
    if (totalPageExceed >= pageCount) {
        totalPageCount = pageCount;
    }
    const childCompRef = useRef();
    const exportRef = useRef();
    const onExportItems = (value, id) => {};
    const ExportList = [
        {
            text: "Excel",
            title: "To allow Organisation Access",
            src: "/images/common/excel.svg",
            value: 0,
        },
        {
            text: "CSV",
            title: "To allow Organisation Access",
            src: "/images/common/excel.svg",
            value: 1,
        },
        {
            text: "PDF",
            title: "To View the Organisation Access",
            src: "/images/common/excel.svg",
            value: 2,
        },
        {
            text: "JPG",
            title: "To View the Organisation Access",
            src: "/images/common/excel.svg",
            value: 4,
        },
    ];

    const filterMenuList = [
        "Invoice Id",
        "Invoice No",
        "Vendor Name",
        "Invoice Date",
        "PO No",
        "PO Date",
        "Net Amount",
        "Gross Amount",
        "Currency",
        "Status",
        "Created Date",
        "Modified Date",
    ];

    const CheckLineItems = JSON.parse(localStorage.getItem("customOptions"));
    const downloadeExcel = (downloadInvoiceList) => {
        if (downloadInvoiceList) {
            const invoiceExcelData = [];
            downloadInvoiceList?.forEach((item) => {
                let invHeaders = {
                    [CheckLineItems?.includes("Invoice Id") && "Invoice Id"]: CheckLineItems?.includes("Invoice Id") && item?.inv_seqno,
                    [CheckLineItems?.includes("Invoice No") && "Invoice No"]: CheckLineItems?.includes("Invoice No") && item?.invoice_no,
                    [CheckLineItems?.includes("Vendor Name") && "Vendor Name"]: CheckLineItems?.includes("Vendor Name") && item?.vendor,
                    [CheckLineItems?.includes("Created Date") && "Created Date"]: CheckLineItems?.includes("Created Date") && item?.createddate,
                    [CheckLineItems?.includes("Gross Amount") && "Gross Amount"]: CheckLineItems?.includes("Gross Amount") && item?.gross_amount,
                    [CheckLineItems?.includes("Currency") && "Currency"]: CheckLineItems?.includes("Currency") && item?.currency,
                    [CheckLineItems?.includes("Net Amount") && "Net Amount"]: CheckLineItems?.includes("Net Amount") && item?.net_amount,
                    [CheckLineItems?.includes("PO No") && "PO No"]: CheckLineItems?.includes("PO No") && item?.pono,
                    [CheckLineItems?.includes("PO Date") && "PO Date"]: CheckLineItems?.includes("PO Date") && item?.po_date,
                    [CheckLineItems?.includes("Modified Date") && "Modified Date"]: CheckLineItems?.includes("Modified Date") && item?.modifieddate,
                    [CheckLineItems?.includes("Status") && "Status"]: CheckLineItems?.includes("Status") && item?.ext_status,
                    [CheckLineItems?.includes("Invoice Date") && "Invoice Date"]: CheckLineItems?.includes("Invoice Date") && item?.invoice_date,
                };
                invoiceExcelData.push(_.pickBy(invHeaders));
            });
            ExportExcel({
                excelData: invoiceExcelData,
                fileName: "MyiQ.Ai",
            });
            onClickDownload(false);
            setExportFile(false);
        }
    };
    const [exportFile, setExportFile] = useState(false);
    const onExportExcel = () => {
        setExportFile(true);
        const reqObj = {
            status_type: 1,
            status_id: status?.length === 0 ? [0] : status,
            organisation: +orgId,
            vendor_id: supplier?.length === 0 ? null : supplier,
            invoice_date_from: fromDate,
            invoice_date_to: toDate,
            // search_text: "",
            invoice_no: invNo,
            order_column: sortColumn,
            order_by: isSorting,
            pg_no: 1,
            rec_cnt: pageCount,
        };
        // if (dataList !== null) {
        //     dispatch(getReportsList(reqObj, "download"));
        // }
        // if (exportData?.length > 0 && exportFile) {
        //     onClickDownload(true);
        // }
        downloadeExcel(dataList);
    };
    // useEffect(() => {
    //     if (exportData?.length > 0 && exportFile) {
    //         onClickDownload(true);
    //     }
    // }, [exportData?.length > 0]);
    // if (isdownloadClick) {
    //     let downloadInvoiceList = exportData;
    //     downloadeExcel(downloadInvoiceList);
    // }
    const dropdownColor = currentMode === "dark" ? "#333333" : `white`;
    const dropdownText = currentMode === "dark" ? "#ECECEC" : `#555555`;
    const dropdownShadow = currentMode !== "dark" ? "0px 4px 20px rgba(238, 238, 238, 0.5)" : "";
    const dropdownBorder = currentMode !== "dark" ? "223, 225, 226, 0.56" : "";
    //   const IsInside = ;

    const DisplayRole = useSelector((state) => state?.DisplayRolePermissionStateAction);
    const { data: DataList } = DisplayRole;
    const RoleList = DataList?.role_info;
    const RoleMenu = RoleList?.menu;
    const RoleProcess = RoleMenu?.find((elem) => elem?.menu_id === 8);
    const RoleProcessAction = RoleProcess?.actions;
    const RoleActProcessView = RoleProcessAction?.find((element) => element.id === 3);
    const ViewOpen = RoleActProcessView?.isallowed;

    return (
        <>
            <Card className={`mb-[4rem] bg-${currentMode}-card`}>
                <div className="flex justify-between">
                    {dataList?.length !== 0 && (
                        <div className="flex my-auto px-[30px] py-[20px]">
                            <div className="">
                                <span>Show</span>
                                <Select
                                    name="entrycount"
                                    className={` mx-3 insideTble text-xs entries bg-${currentMode}-card`}
                                    value={props?.currentPageItem}
                                    onChange={(e) => onPageItemSelect(e)}
                                    IconComponent={() => (
                                        <img
                                            className="absolute opacity-50 right-[8px] pointer-events-none"
                                            src={currentMode !== "dark" ? `/images/header/chevronDown.svg` : `/images/header/chevronDown-white.svg`}
                                        />
                                    )}
                                    MenuProps={{
                                        sx: {
                                            "& .MuiMenu-paper": {
                                                backgroundColor: dropdownColor,
                                                color: dropdownText,
                                                boxShadow: dropdownShadow,
                                                border: dropdownBorder,
                                                borderRadius: "8px",
                                            },
                                        },
                                    }}
                                    SelectDisplayProps={{
                                        style: {
                                            paddingTop: "5px",
                                            display: "flex",
                                            paddingBottom: "5px",
                                            paddingLeft: "10px",
                                            border: 0,
                                            color: currentMode === "dark" ? `#ECECEC` : `#555555`,
                                            paddingRight: "25px",
                                        },
                                    }}
                                >
                                    <MenuItem value={10}>{10}</MenuItem>
                                    <MenuItem value={15}>{15}</MenuItem>
                                    <MenuItem value={20}>{20}</MenuItem>
                                    <MenuItem value={25}>{25}</MenuItem>
                                    <MenuItem value={50}>{50}</MenuItem>
                                    <MenuItem value={75}>{75}</MenuItem>
                                    <MenuItem value={100}>{100}</MenuItem>
                                </Select>
                                <span>entries</span>
                            </div>
                        </div>
                    )}
                    {dataList?.length !== 0 && (
                        <div className="flex items-center space-x-2 px-[30px] py-[20px]">
                            <div>
                                <Button
                                    title="To Export Invoice to Excel"
                                    // onClick={handleClick("top-start")}
                                    onClick={() => onExportExcel()}
                                    className={`border-20 export-step px-5 py-2 rounded-[8px] font-interM text-sm flex justify-center items-center border-[1px] text-${currentMode}-copy border-light-20`}
                                >
                                    <img className="mr-[.4rem]" src="/images/common/download-excel.svg" alt="upload" /> Download
                                </Button>
                            </div>
                        </div>
                    )}
                </div>
                <div className={`heightFix invoices relative report max-h-[initial] ${dataList?.length == 0 ? `rounded-t-[8px]` : ``}`}>
                    <table className="table-auto TblNormal invoice reports w-full">
                        <thead className="sticky top-0 z-[1] rounded-md">
                            <tr className={`bg-${currentMode}-450`}>
                                <>
                                    {values?.includes("Invoice Id") ? <th>Invoice Id </th> : ""}

                                    {values?.includes("Invoice No") ? <th>Invoice Number </th> : ""}
                                    {values?.includes("Vendor Name") ? <th>Vendor Name </th> : ""}
                                    {values?.includes("PO No") ? <th>PO No </th> : ""}
                                    {values?.includes("PO Date") ? <th>PO Date </th> : ""}
                                    {values?.includes("Invoice Date") ? <th>Invoice Date </th> : ""}
                                    {values?.includes("Net Amount") ? <th className={dataList?.length > 0 ? "text-end" : ""}>Net Amount </th> : ""}
                                    {values?.includes("Tax Amount") ? <th className="text-end">Tax Amount </th> : ""}
                                    {values?.includes("Gross Amount") ? <th className="text-end">Gross Amount </th> : ""}
                                    {values?.includes("Currency") ? <th>Currency </th> : ""}
                                    {values?.includes("Created Date") ? <th>Created Date </th> : ""}
                                    {values?.includes("Modified Date") ? <th>Modified Date </th> : ""}
                                    {values?.includes("Status") ? <th>Status </th> : ""}
                                </>
                                <th>
                                    <img
                                        className="cursor-pointer max-w-[inherit] convertWhite"
                                        onClick={(e) => handleClick("bottom-end", 1, e)}
                                        src="/images/main/columns.svg"
                                        alt="columns"
                                    />
                                </th>
                            </tr>
                        </thead>
                        {fetching ? (
                            <Loader className="h-[calc(100vh_-_26rem)] absolute left-[50%] translate-x-[-50%]" />
                        ) : (
                            <>
                                <tbody>
                                    {dataList !== null && dataList?.length !== 0 ? (
                                        dataList?.map((elem, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td className={`${!ViewOpen ? "pointer-events-none" : ""}`}>
                                                        {" "}
                                                        <Link
                                                            state={{ from: location.pathname }}
                                                            to={`${"/invoice-view?"}${encryptId(
                                                                `{"inv":${+elem?.id},"type":${9},"seq":"${+elem?.inv_seqno}","status":${
                                                                    elem?.ext_status_id
                                                                }}`
                                                            )}
                                                           `}
                                                            style={{
                                                                textDecoration: "underline",
                                                            }}
                                                            target="_blank"
                                                        >
                                                            {elem?.inv_seqno}{" "}
                                                        </Link>
                                                    </td>
                                                    <td className={`${!ViewOpen ? "pointer-events-none" : ""}`}>
                                                        {" "}
                                                        <Link
                                                            state={{ from: location.pathname }}
                                                            to={`${"/invoice-view?"}${encryptId(
                                                                `{"inv":${+elem?.id},"type":${7},"seq":"${+elem?.inv_seqno}","status":${
                                                                    elem?.ext_status_id
                                                                }}`
                                                            )}
                                                           `}
                                                            style={{
                                                                textDecoration: "underline",
                                                            }}
                                                            target="_blank"
                                                        >
                                                            {elem?.invoice_no}{" "}
                                                        </Link>
                                                    </td>
                                                    <td className="max-w-[50px] truncate">{elem?.vendor}</td>
                                                    {values?.includes("PO No") ? <td>{elem?.pono}</td> : ""}
                                                    {values?.includes("PO Date") ? <td>{elem?.po_date}</td> : ""}
                                                    <td>{elem?.invoice_date}</td>
                                                    <td className="text-end">{elem?.net_amount}</td>
                                                    {values?.includes("Tax Amount") ? <td className="text-end">{elem?.tax_amount}</td> : ""}
                                                    {values?.includes("Gross Amount") ? <td className="text-end">{elem?.gross_amount}</td> : ""}
                                                    {values?.includes("Currency") ? <td>{elem?.currency}</td> : ""}
                                                    {values?.includes("Created Date") ? <td>{elem?.createddate}</td> : ""}
                                                    {values?.includes("Modified Date") ? <td>{elem?.modifieddate}</td> : ``}

                                                    <td>
                                                        <Button
                                                            className={`cursor-default ${
                                                                elem?.ext_status === "Processed"
                                                                    ? `bg-light-480`
                                                                    : elem?.ext_status === "Approved"
                                                                    ? `bg-light-490`
                                                                    : elem?.ext_status === "Rejected"
                                                                    ? `bg-light-180`
                                                                    : elem?.ext_status === "Draft"
                                                                    ? `bg-light-500`
                                                                    : elem?.ext_status === "Submitted"
                                                                    ? `bg-light-510`
                                                                    : elem?.ext_status === "Posted"
                                                                    ? `bg-light-510`
                                                                    : elem?.ext_status === "ForApproval"
                                                                    ? `bg-light-520`
                                                                    : ``
                                                            } w-full max-w-[100px] font-interR rounded-[5px] py-[2px] px-3 text-black`}
                                                        >
                                                            {elem?.ext_status}
                                                        </Button>
                                                    </td>
                                                    <td></td>
                                                </tr>
                                            );
                                        })
                                    ) : (
                                        <div className="absolute top-[50%] left-[50%] translate-y-[-50%] translate-x-[-50%]">
                                            <img src="/images/common/delivery.png" alt="delivery" />
                                            <h4 className="font-interSb text-center mt-3">No data found</h4>
                                        </div>
                                    )}
                                </tbody>
                            </>
                        )}
                    </table>
                </div>

                <ClickAwayListener mouseEvent="onMouseDown" touchEvent="onTouchStart" onClickAway={handleClickAway}>
                    <Popper
                        className={`bg-${currentMode}-card text-${currentMode}-copy  p-4 border-[1px] z-[12] border-${currentMode}-700  shadow-[0px_4px_20px_rgba(238,238,238,0.5)] rounded-[10px] reportsPopper `}
                        open={1 === popstate.value}
                        anchorEl={popstate.anchorEl}
                        id={1}
                        placement={placement}
                    >
                        <ul>
                            {filterMenuList?.map((el) => {
                                return (
                                    <li className={`transition duration-300 ease-in-out mx-[-17px]  py-1 px-[20px] hover:bg-${currentMode}-960`}>
                                        <Button className={`flex ${1} items-center justify-between font-interR`}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        size="small"
                                                        value={el}
                                                        onChange={checkChange}
                                                        disabled={!Boolean(optionValue?.indexOf(el) === -1)}
                                                        defaultChecked={!Boolean(values?.indexOf(el) === -1)}
                                                        sx={{
                                                            padding: 0,
                                                            color: "#008785",

                                                            margin: "0 12px",
                                                            "&.Mui-checked": {
                                                                color: "#008785",
                                                            },
                                                        }}
                                                    />
                                                }
                                                label={el}
                                            />
                                        </Button>
                                    </li>
                                );
                            })}
                        </ul>
                    </Popper>
                </ClickAwayListener>
                <TogglePopup ref={exportRef} menuList={ExportList} selectedItemChange={onExportItems} />
                <div className={`bg-${currentMode}-460 flex justify-between items-center py-[10px] px-[30px]`}>
                    {dataList?.length !== 0 && dataList !== null && (
                        <div>
                            <div>
                                Showing <span>{`${start} to ${totalPageCount}`}</span> entries of <span>{pageCount}</span>
                            </div>
                        </div>
                    )}
                    {dataList?.length !== 0 && dataList !== null && (
                        <div className="flex items-center">
                            <span>The page you’re on</span>
                            <Select
                                name=""
                                className={`bg-${currentMode}-card insideTble mx-3 text-xs entries`}
                                id=""
                                value={props?.currentPageNo}
                                onChange={(e) => onPageNumberSelect(e)}
                                IconComponent={() => (
                                    <img
                                        className="absolute opacity-50 right-[8px] pointer-events-none"
                                        src={currentMode !== "dark" ? `/images/header/chevronDown.svg` : `/images/header/chevronDown-white.svg`}
                                    />
                                )}
                                SelectDisplayProps={{
                                    style: {
                                        paddingTop: "5px",
                                        display: "flex",
                                        paddingBottom: "5px",
                                        paddingLeft: "10px",
                                        border: 0,
                                        color: currentMode === "dark" ? `#ECECEC` : `#555555`,
                                        paddingRight: "25px",
                                    },
                                }}
                                MenuProps={{
                                    sx: {
                                        "& .MuiMenu-paper": {
                                            backgroundColor: dropdownColor,
                                            color: dropdownText,
                                            boxShadow: dropdownShadow,
                                            border: dropdownBorder,
                                            borderRadius: "8px",
                                        },
                                    },
                                }}
                            >
                                {EmptyArr?.map((elem) => (
                                    <MenuItem value={elem + 1}>{elem + 1}</MenuItem>
                                ))}
                            </Select>
                            <div className={`flex space-x-2 border-l-[1px] pl-3 border-${currentMode}-830`}>
                                <Button
                                    title="Previous page"
                                    disabled={props?.currentPageNo > 1 ? false : true}
                                    onClick={() => previousBtnClick()}
                                    className={`border-${currentMode}-840 border-[1px] rounded-[5px] bg-${currentMode}-card px-2 py-3`}
                                >
                                    <img className="rotate-180 convertWhite " src="/images/common/arrowRight.svg" alt="arrowRight" />
                                </Button>

                                <Button
                                    title="Next page"
                                    className={`border-${currentMode}-840 border-[1px] rounded-[5px] bg-${currentMode}-card px-2 py-3`}
                                    disabled={props?.currentPageNo >= nextPageCount ? true : false}
                                    onClick={() => nextBtnClick()}
                                >
                                    <img className="convertWhite" src="/images/common/arrowRight.svg" alt="arrowRight" />
                                </Button>
                            </div>
                        </div>
                    )}
                </div>
                {/* )} */}
            </Card>
        </>
    );
};

export default Table;
