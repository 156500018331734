import { Link } from "react-router-dom";
import Button from "../../components/Button";
import Card from "../../components/UI/Card";
import Main from "../../components/UI/Main";
import Footer from "../../organisms/Footer";
import Header from "../../organisms/Header";
import Sidebar from "../../organisms/Sidebar";
import { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import FreeTrail from "../../organisms/Header/TrialPage";
import { getCurrentUser } from "../../redux/actions/auth";
import { useSelector } from "react-redux";

const UserPage = () => {
    const currentUser = getCurrentUser();
    const [trialOpen, setTrialOpen] = useState(false);
    const date = new Date();
    const d = date.toLocaleDateString("en-GB");
    let hours = date.getHours();
    const Data = useSelector((state) => state?.getFreeTrialReducerState);
    hours = ((hours + 11) % 12 + 1).toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const seconds = date.getSeconds().toString().padStart(2, "0");
    const targetDateStr = currentUser?.expiring_date;
    const targetDate = new Date(targetDateStr);
    const currentDate = new Date();
    const matchdate = targetDate < currentDate
    const formattedTime = `${hours}:${minutes}`;
    useEffect(() => {
        if (matchdate && currentUser?.license_type == 2 && formattedTime == currentUser?.login_time) {
            setTrialOpen(true)
        }
    }, [])

    return (
        <>
            <Header />
            <Sidebar />
            <Main>
                <Card className="h-[calc(100vh_-_20vh)] grid place-items-center place-content-center">
                    <div className="flex items-center  mx-auto">
                        <div className="" >
                            <img className="mb-5" src="/images/common/delivery.png" alt="404" />
                            <h4>Yet to assign Organization with defined Roles </h4>
                        </div>
                    </div>
                </Card>
            </Main>
            <Footer />
            {ReactDOM.createPortal(
                <FreeTrail
                    isOpened={trialOpen}
                    handleClose={() => setTrialOpen(false)}
                    className={trialOpen && "Show"}
                />,
                document.getElementById("user-modal-root")
            )}
        </>
    );
};

export default UserPage;
