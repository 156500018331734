import { RoundNum, formatNumber } from "../../utils/helpers";
import {
    INVOICE_LIST_FAILURE,
    INVOICE_LIST_REQUEST,
    INVOICE_LIST_RESET,
    INVOICE_LIST_SUCCESS,
    GET_INVCOUNT_REQUEST,
    GET_INVCOUNT_FAILURE,
    GET_INVCOUNT_RESET,
    GET_INVCOUNT_SUCCESS,
    INVOICE_GET_REQUEST,
    INVOICE_GET_SUCCESS,
    INVOICE_GET_FAILURE,
    INVOICE_GET_RESET,
    INVOICE_UPDATE_REQUEST,
    INVOICE_UPDATE_FAILURE,
    INVOICE_UPDATE_SUCCESS,
    INVOICE_UPDATE_RESET,
    INVOICE_APPROVED_LIST_REQUEST,
    INVOICE_APPROVED_LIST_SUCCESS,
    INVOICE_APPROVED_LIST_RESET,
    INVOICE_APPROVED_LIST_FAILURE,
    INVOICE_REJECTED_LIST_REQUEST,
    INVOICE_REJECTED_LIST_SUCCESS,
    INVOICE_REJECTED_LIST_FAILURE,
    INVOICE_REJECTED_LIST_RESET,
    INVOICE_FLAG_REQUEST,
    INVOICE_FLAG_SUCCESS,
    INVOICE_FLAG_FAILURE,
    INVOICE_FLAG_RESET,
    INVOICE_CROPED_IMG_SUCCESS,
    INVOICE_CROPED_IMG_REQUEST,
    INVOICE_CROPED_IMG_FAILURE,
    INVOICE_COMPARE_REQUEST,
    INVOICE_COMPARE_SUCCESS,
    INVOICE_COMPARE_FAILURE,
    INVOICE_VENDOR_LIST_REQUEST,
    INVOICE_VENDOR_LIST_SUCCESS,
    INVOICE_VENDOR_LIST_RESET,
    INVOICE_VENDOR_LIST_FAILURE,
    INVOICE_LABEL_LAYOUT_LIST_REQUEST,
    INVOICE_LABEL_LAYOUT_LIST_SUCCESS,
    INVOICE_LABEL_LAYOUT_LIST_FAILURE,
    INVOICE_LABEL_LAYOUT_LIST_RESET,
    INVOICE_LABEL_LAYOUT_ADD_REQUEST,
    INVOICE_LABEL_LAYOUT_ADD_SUCCESS,
    INVOICE_LABEL_LAYOUT_ADD_FAILURE,
    INVOICE_LABEL_LAYOUT_ADD_RESET,
    INVOICE_ID_CONFIG_REQUEST,
    INVOICE_ID_CONFIG_SUCCESS,
    INVOICE_ID_CONFIG_FAILURE,
    INVOICE_ID_CONFIG_RESET,
    GET_INVOICE_ID_CONFIG_REQUEST,
    GET_INVOICE_ID_CONFIG_SUCCESS,
    GET_INVOICE_ID_CONFIG_FAILURE,
    GET_INVOICE_ID_CONFIG_RESET,
    ADD_INVOICE_ALLOCATION_REQUEST,
    ADD_INVOICE_ALLOCATION_SUCCESS,
    ADD_INVOICE_ALLOCATION_RESET,
    ADD_INVOICE_ALLOCATION_FAILURE,
    GET_INVOICE_ALLOCATION_REQUEST,
    GET_INVOICE_ALLOCATION_SUCCESS,
    GET_INVOICE_ALLOCATION_RESET,
    GET_INVOICE_ALLOCATION_FAILURE,
    POST_INVOICE_APPROVER_REQUEST,
    POST_INVOICE_APPROVER_SUCCESS,
    POST_INVOICE_APPROVER_FAILURE,
    POST_INVOICE_APPROVER_RESET,
    POST_INVOICE_REASSIGN_REQUEST,
    POST_INVOICE_REASSIGN_SUCCESS,
    POST_INVOICE_REASSGIN_FAILURE,
    POST_INVOICE_REASSIGN_RESET,
    GET_GENERAL_LEDGER_LIST_REQUEST,
    GET_GENERAL_LEDGER_LIST_SUCCESS,
    GET_GENERAL_LEDGER_LIST_RESET,
    GET_GENERAL_LEDGER_LIST_FAILURE,
    GET_DOCUMENT_TYPE_LIST_REQUEST,
    GET_DOCUMENT_TYPE_LIST_SUCCESS,
    GET_DOCUMENT_TYPE_LIST_RESET,
    GET_DOCUMENT_TYPE_LIST_FAILURE,
    GET_LIST_COST_CENTRE_REQUEST,
    GET_LIST_COST_CENTRE_SUCCESS,
    GET_LIST_COST_CENTRE_RESET,
    GET_LIST_COST_CENTRE_FAILURE,
    GET_PO_NUMBER_LIST_REQUEST,
    GET_PO_NUMBER_LIST_SUCCESS,
    GET_PO_NUMBER_LIST_RESET,
    GET_PO_NUMBER_LIST_FAILURE,
    GET_SAP_VENDOR_LIST_REQUEST,
    GET_SAP_VENDOR_LIST_SUCCESS,
    GET_SAP_VENDOR_LIST_RESET,
    GET_SAP_VENDOR_LIST_FAILURE,
    TRANSFER_INVOICE_REQUEST,
    TRANSFER_INVOICE_SUCCESS,
    TRANSFER_INVOICE_RESET,
    TRANSFER_INVOICE_FAILURE,
    GET_REJECT_INVOICE_FAILURE,
    GET_REJECT_INVOICE_REQUEST,
    GET_REJECT_INVOICE_SUCCESS,
    GET_REJECT_INVOICE_RESET,
    INVOICE_GET_DOCUMENT_REQUEST,
    INVOICE_GET_DOCUMENT_SUCCESS,
    INVOICE_GET_DOCUMENT_RESET,
    INVOICE_GET_DOCUMENT_FAILURE,
    EXPORT_INVOICE_LIST_REQUEST,
    EXPORT_INVOICE_LIST_FAILURE,
    EXPORT_INVOICE_LIST_RESET,
    EXPORT_INVOICE_LIST_SUCCESS,
    ERP_VALIDATIONBYMATCHING_REQUEST,
    ERP_VALIDATIONBYMATCHING_SUCCESS,
    ERP_VALIDATIONBYMATCHING_RESET,
    ERP_VALIDATIONBYMATCHING_FAILURE,
    ERP_VALIDATIONBYMATCHING_UPDATE,
    POST_SERVICE_LINE_ITEMS_REQUEST,
    POST_SERVICE_LINE_ITEMS_SUCCESS,
    POST_SERVICE_LINE_ITEMS_RESET,
    POST_SERVICE_LINE_ITEMS_FAILURE,
    POST_SERVICE_LINE_ITEMS_UPDATE,
    GET_PO_TYPE_REQUEST,
    GET_PO_TYPE_FAILURE,
    GET_PO_TYPE_RESET,
    GET_PO_TYPE_SUCCESS,
    GET_TAX_CODE_LIST_REQUEST,
    GET_TAX_CODE_LIST_SUCCESS,
    GET_TAX_CODE_LIST_RESET,
    GET_TAX_CODE_LIST_FAILURE,
    GET_DOCUMENT_TYPE_REQUEST,
    GET_DOCUMENT_TYPE_SUCCESS,
    GET_DOCUMENT_TYPE_RESET,
    GET_DOCUMENT_TYPE_FAILURE,
    POST_DOCUMENT_TYPE_RESET,
    POST_DOCUMENT_TYPE_FAILURE,
    POST_DOCUMENT_TYPE_SUCCESS,
    POST_DOCUMENT_TYPE_REQUEST,
    GET_INVOICE_CALC_VIEW_REQUEST,
    GET_INVOICE_CALC_VIEW_SUCCESS,
    GET_INVOICE_CALC_VIEW_RESET,
    GET_INVOICE_CALC_VIEW_FAILURE,
    GET_INVOICE_CALC_DETAIL_VIEW_REQUEST,
    GET_INVOICE_CALC_DETAIL_VIEW_SUCCESS,
    GET_INVOICE_CALC_DETAIL_VIEW_RESET,
    GET_INVOICE_CALC_DETAIL_VIEW_FAILURE,
    GET_INVOICE_CALC_VIEW_UPDATE,
} from "../types/types";
import _, { over, cloneDeep } from "lodash";

const invoiceProcessState = {
    dataList: [],
    exportData: [],
    fetching: false,
    requestComplete: false,
    error: "",
};

export function InvoiceListItem(state = invoiceProcessState, action) {
    switch (action.type) {
        case INVOICE_LIST_REQUEST:
            return {
                ...state,
                fetching: true,
                requestComplete: false,
                error: "",
            };

        case INVOICE_LIST_SUCCESS:
            return {
                dataList: action.payload,
                fetching: false,
                requestComplete: true,
                error: "",
            };
        case EXPORT_INVOICE_LIST_SUCCESS:
            return {
                ...state,
                exportData: action.payload,
                fetching: false,
                requestComplete: true,
                error: "",
            };

        case INVOICE_LIST_RESET:
            return {
                dataList: [],
                fetching: false,
                requestComplete: false,
                error: "",
            };

        case INVOICE_LIST_FAILURE:
            return {
                fetching: false,
                requestComplete: false,
                error: action.payload,
            };

        default:
            return state;
    }
}

const InvoiceCountState = {
    data: [],
    fetching: false,
    cancelData: [],
    requestComplete: false,
    error: "",
};
export function invoiceCount(state = InvoiceCountState, action) {
    switch (action.type) {
        case GET_INVCOUNT_REQUEST:
            return {
                ...state,
                fetching: true,
                requestComplete: false,
                error: "",
            };
        case GET_INVCOUNT_SUCCESS:
            return {
                data: action.payload,
                fetching: false,
                requestComplete: true,
                error: "",
            };
        case GET_INVCOUNT_RESET:
            return {
                data: [],
                fetching: false,
                requestComplete: false,
                error: "",
            };
        case GET_INVCOUNT_FAILURE:
            return {
                fetching: false,
                requestComplete: false,
                error: action.payload,
            };
        default:
            return state;
    }
}
const invoiceApproveState = {
    data: [],
    fetching: false,
    requestComplete: false,
    error: "",
};

export function InvoiceApprovedList(state = invoiceApproveState, action) {
    switch (action.type) {
        case INVOICE_APPROVED_LIST_REQUEST:
            return {
                ...state,
                fetching: true,
                requestComplete: false,
                error: "",
            };
        case INVOICE_APPROVED_LIST_SUCCESS:
            return {
                data: action.payload,
                fetching: false,
                requestComplete: true,
                error: "",
            };
        case INVOICE_APPROVED_LIST_RESET:
            return {
                data: [],
                fetching: false,
                requestComplete: false,
                error: "",
            };
        case INVOICE_APPROVED_LIST_FAILURE:
            return {
                data: action.payload,
                fetching: false,
                requestComplete: true,
                error: action?.payload,
            };
        default:
            return state;
    }
}
const InvoiceUpadte = {
    data: [],
    fetching: false,
    requestComplete: false,
    error: "",
};

export function UpdateInvoice(state = InvoiceUpadte, action) {
    switch (action.type) {
        case INVOICE_UPDATE_REQUEST:
            return {
                ...state,
                fetching: true,
                requestComplete: false,
                error: "",
            };
        case INVOICE_UPDATE_SUCCESS:
            return {
                data: action.payload,
                fetching: false,
                requestComplete: true,
                error: "",
            };
        case INVOICE_UPDATE_FAILURE:
            return {
                data: [],
                fetching: false,
                requestComplete: false,
                error: "Validated,Found Exceptions",
            };
        case INVOICE_UPDATE_RESET:
            return {
                fetching: false,
                requestComplete: false,
                error: action.payload,
            };
        default:
            return state;
    }
}

export function InvoiceRejectedList(state = invoiceProcessState, action) {
    switch (action.type) {
        case INVOICE_REJECTED_LIST_REQUEST:
            return {
                ...state,

                fetching: true,

                requestComplete: false,

                error: "",
            };

        case INVOICE_REJECTED_LIST_SUCCESS:
            return {
                dataList: action.payload,

                fetching: false,

                requestComplete: true,

                error: "",
            };

        case INVOICE_REJECTED_LIST_RESET:
            return {
                dataList: [],

                fetching: false,

                requestComplete: false,

                error: "",
            };

        case INVOICE_REJECTED_LIST_FAILURE:
            return {
                fetching: false,

                requestComplete: false,

                error: action.payload,
            };

        default:
            return state;
    }
}

export function GetInvoiceViewItems(state = InvoiceCountState, action) {
    let getData = action?.payload;
    if (action?.type == "INVOICE_GET_SUCCESS" || action?.type == "INVOICE_GET__SUCCESS") {
        getData.datalist = action.payload.datalist.map((item) => {
            return {
                ...item,
                cancelData: item?.matching_info,
            };
        });
    }
    switch (action.type) {
        case INVOICE_GET_REQUEST:
            return {
                ...state,
                fetching: true,
                requestComplete: false,
                error: "",
            };
        case INVOICE_GET_SUCCESS:
            return {
                data: getData,
                fetching: false,
                requestComplete: true,
                error: "",
            };
        case INVOICE_GET_RESET:
            return {
                data: [],
                fetching: false,
                requestComplete: false,
                error: "",
            };
        case INVOICE_GET_FAILURE:
            return {
                fetching: false,
                requestComplete: false,
                error: action.payload,
            };
        default:
            return state;
    }
}

export function InvoiceFlag(state = InvoiceCountState, action) {
    switch (action.type) {
        case INVOICE_FLAG_REQUEST:
            return {
                ...state,
                fetching: true,
                requestComplete: false,
                error: "",
            };
        case INVOICE_FLAG_SUCCESS:
            return {
                data: action.payload,
                fetching: false,
                requestComplete: true,
                error: "",
            };
        case INVOICE_FLAG_RESET:
            return {
                data: {},
                fetching: false,
                requestComplete: false,
                error: "",
            };
        case INVOICE_FLAG_FAILURE:
            return {
                fetching: false,
                requestComplete: false,
                error: action.payload,
            };
        default:
            return state;
    }
}

const InvoiceImgCropedState = {
    data: [],
    fetching: false,
    requestComplete: false,
    error: "",
};
export function InvoiceCropedImg(state = InvoiceImgCropedState, action) {
    switch (action.type) {
        case INVOICE_CROPED_IMG_REQUEST:
            return {
                ...state,
                fetching: true,
                requestComplete: false,
                error: "",
            };
        case INVOICE_CROPED_IMG_SUCCESS:
            return {
                data: action.payload,
                fetching: false,
                requestComplete: true,
                error: "",
            };

        case INVOICE_CROPED_IMG_FAILURE:
            return {
                fetching: false,
                requestComplete: false,
                error: action.payload,
            };
        default:
            return state;
    }
}

const InvoiceCompare = {
    data: [],
    fetching: false,
    requestComplete: false,
    error: "",
};

export function InvoiceComparelist(state = InvoiceCompare, action) {
    switch (action.type) {
        case INVOICE_COMPARE_REQUEST:
            return {
                ...state,
                fetching: true,
                requestComplete: false,
                error: "",
            };
        case INVOICE_COMPARE_SUCCESS:
            return {
                data: action.payload,
                fetching: false,
                requestComplete: true,
                error: "",
            };

        case INVOICE_COMPARE_FAILURE:
            return {
                fetching: false,
                requestComplete: false,
                error: action.payload,
            };
        default:
            return state;
    }
}

const intAuthState = {
    vebdorlist: [],
    fetching: false,
    requestComplete: false,
    error: "",
};

export function invoiceVendorList(state = intAuthState, action) {
    switch (action.type) {
        case INVOICE_VENDOR_LIST_REQUEST:
            return {
                ...state,
                fetching: true,
                requestComplete: false,
                error: "",
            };
        case INVOICE_VENDOR_LIST_SUCCESS:
            return {
                vebdorlist: action.payload,
                fetching: false,
                requestComplete: true,
                error: "",
            };
        case INVOICE_VENDOR_LIST_RESET:
            return {
                vebdorlist: [],
                fetching: false,
                requestComplete: false,
                error: "",
            };
        case INVOICE_VENDOR_LIST_FAILURE:
            return {
                fetching: false,
                requestComplete: false,
                error: action.payload,
            };
        default:
            return state;
    }
}

const intlabelState = {
    data: [],
    fetching: false,
    requestComplete: false,
    error: "",
};

export function invoicelabelList(state = intlabelState, action) {
    let replaced = [];
    if (action?.checkType == 2 && action?.type == "INVOICE_LABEL_LAYOUT_LIST_SUCCESS") {
        replaced = state?.data?.map((item) => {
            const replace = action?.payload?.find((ele) => ele?.field_name == item?.field_name);
            if (replace) {
                delete replace?.id;
                return {
                    ...replace,
                    id: item?.id,
                };
            } else {
                return item;
            }
        });
    }
    switch (action.type) {
        case INVOICE_LABEL_LAYOUT_LIST_REQUEST:
            return {
                ...state,
                fetching: true,
                requestComplete: false,
                error: "",
            };
        case INVOICE_LABEL_LAYOUT_LIST_SUCCESS:
            return {
                data: action?.checkType == 1 ? action.payload : replaced,
                fetching: false,
                requestComplete: true,
                error: "",
            };
        case INVOICE_LABEL_LAYOUT_LIST_RESET:
            return {
                data: [],
                fetching: false,
                requestComplete: false,
                error: "",
            };
        case INVOICE_LABEL_LAYOUT_LIST_FAILURE:
            return {
                fetching: false,
                requestComplete: false,
                error: action.payload,
            };
        default:
            return state;
    }
}

const intlabelAddState = {
    data: [],
    fetching: false,
    requestComplete: false,
    error: "",
};

export function invoicelabelAdd(state = intlabelAddState, action) {
    switch (action.type) {
        case INVOICE_LABEL_LAYOUT_ADD_REQUEST:
            return {
                ...state,
                fetching: true,
                requestComplete: false,
                error: "",
            };
        case INVOICE_LABEL_LAYOUT_ADD_SUCCESS:
            return {
                data: action.payload,
                fetching: false,
                requestComplete: true,
                error: "",
            };
        case INVOICE_LABEL_LAYOUT_ADD_RESET:
            return {
                data: [],
                fetching: false,
                requestComplete: false,
                error: "",
            };
        case INVOICE_LABEL_LAYOUT_ADD_FAILURE:
            return {
                fetching: false,
                requestComplete: false,
                error: action.payload,
            };
        default:
            return state;
    }
}

const GetInvIdconfig = {
    data: {},
    fetching: false,
    requestComplete: false,
    error: "",
};

export function GetInvoiceIdConfig(state = GetInvIdconfig, action) {
    switch (action.type) {
        case GET_INVOICE_ID_CONFIG_REQUEST:
            return {
                ...state,
                fetching: true,
                requestComplete: false,
                error: "",
            };
        case GET_INVOICE_ID_CONFIG_SUCCESS:
            return {
                data: action.payload,
                fetching: false,
                requestComplete: true,
                error: "",
            };
        case GET_INVOICE_ID_CONFIG_RESET:
            return {
                data: {},
                fetching: false,
                requestComplete: false,
                error: "",
            };
        case GET_INVOICE_ID_CONFIG_FAILURE:
            return {
                fetching: false,
                requestComplete: false,
                error: action.payload,
            };
        default:
            return state;
    }
}

const InvIdconfig = {
    data: [],
    fetching: false,
    requestComplete: false,
    error: "",
};

export function InvoiceIdAddConfig(state = InvIdconfig, action) {
    switch (action.type) {
        case INVOICE_ID_CONFIG_REQUEST:
            return {
                ...state,
                fetching: true,
                requestComplete: false,
                error: "",
            };
        case INVOICE_ID_CONFIG_SUCCESS:
            return {
                data: action.payload,
                fetching: false,
                requestComplete: true,
                error: "",
            };
        case INVOICE_ID_CONFIG_RESET:
            return {
                data: [],
                fetching: false,
                requestComplete: false,
                error: "",
            };
        case INVOICE_ID_CONFIG_FAILURE:
            return {
                fetching: false,
                requestComplete: false,
                error: action.payload,
            };
        default:
            return state;
    }
}

const addInvoiceAllocationState = {
    data: [],
    fetching: false,
    requestComplete: false,
    error: "",
};

export function addInvoiceAllocation(state = addInvoiceAllocationState, action) {
    switch (action.type) {
        case ADD_INVOICE_ALLOCATION_REQUEST:
            return {
                ...state,
                fetching: true,
                requestComplete: false,
                error: "",
            };
        case ADD_INVOICE_ALLOCATION_SUCCESS:
            return {
                data: action.payload,
                fetching: false,
                requestComplete: true,
                error: "",
            };
        case ADD_INVOICE_ALLOCATION_RESET:
            return {
                data: [],
                fetching: false,
                requestComplete: false,
                error: "",
            };
        case ADD_INVOICE_ALLOCATION_FAILURE:
            return {
                fetching: false,
                requestComplete: false,
                error: action.payload,
            };
        default:
            return state;
    }
}

const invoiceAllocationListState = {
    dataList: [],
    fetching: false,
    requestComplete: false,
    error: "",
};

export function getInvoiceAllocationList(state = invoiceAllocationListState, action) {
    switch (action.type) {
        case GET_INVOICE_ALLOCATION_REQUEST:
            return {
                ...state,
                fetching: true,
                requestComplete: false,
                error: "",
            };
        case GET_INVOICE_ALLOCATION_SUCCESS:
            return {
                dataList: action.payload,
                fetching: false,
                requestComplete: true,
                error: "",
            };
        case GET_INVOICE_ALLOCATION_RESET:
            return {
                dataList: [],
                fetching: false,
                requestComplete: false,
                error: "",
            };
        case GET_INVOICE_ALLOCATION_FAILURE:
            return {
                fetching: false,
                requestComplete: false,
                error: action.payload,
            };
        default:
            return state;
    }
}
const getInvApprove = {
    data: [],
    fetching: false,
    requestComplete: false,
    error: "",
};

export function PostInvApproveState(state = getInvApprove, action) {
    switch (action.type) {
        case POST_INVOICE_APPROVER_REQUEST:
            return {
                ...state,
                fetching: true,
                requestComplete: false,
                error: "",
            };
        case POST_INVOICE_APPROVER_SUCCESS:
            return {
                data: action.payload,
                fetching: false,
                requestComplete: true,
                error: "",
            };
        case POST_INVOICE_APPROVER_RESET:
            return {
                data: [],
                fetching: false,
                requestComplete: false,
                error: "",
            };
        case POST_INVOICE_APPROVER_FAILURE:
            return {
                fetching: false,
                requestComplete: true,
                error: action.payload,
            };
        default:
            return state;
    }
}

const InvReassign = {
    data: [],
    fetching: false,
    requestComplete: false,
    error: "",
};

export function InvoiceReassignReducer(state = InvReassign, action) {
    switch (action.type) {
        case POST_INVOICE_REASSIGN_REQUEST:
            return {
                ...state,
                fetching: true,
                requestComplete: false,
                error: "",
            };
        case POST_INVOICE_REASSIGN_SUCCESS:
            return {
                data: action.payload,
                fetching: false,
                requestComplete: true,
                error: "",
            };
        case POST_INVOICE_REASSIGN_RESET:
            return {
                data: [],
                fetching: false,
                requestComplete: false,
                error: "",
            };
        case POST_INVOICE_REASSGIN_FAILURE:
            return {
                fetching: false,
                requestComplete: false,
                error: action.payload,
            };
        default:
            return state;
    }
}
//GENERAL LEDGER LIST
const GeneralLedgerListState = {
    data: [],
    fetching: false,
    requestComplete: false,
    error: "",
};
export function generalLedgerList(state = GeneralLedgerListState, action) {
    switch (action.type) {
        case GET_GENERAL_LEDGER_LIST_REQUEST:
            return {
                ...state,
                fetching: true,
                requestComplete: false,
                error: "",
            };
        case GET_GENERAL_LEDGER_LIST_SUCCESS:
            return {
                data: action.payload,
                fetching: false,
                requestComplete: true,
                error: "",
            };
        case GET_GENERAL_LEDGER_LIST_RESET:
            return {
                data: [],
                fetching: false,
                requestComplete: false,
                error: "",
            };
        case GET_GENERAL_LEDGER_LIST_FAILURE:
            return {
                fetching: false,
                requestComplete: false,
                error: action.payload,
            };
        default:
            return state;
    }
}

//GET DOCUMENT_TYPE_LIST

const getDocumentTypeListState = {
    data: [],
    fetching: false,
    requestComplete: false,
    error: "",
};
export function getDocumentTypeList(state = getDocumentTypeListState, action) {
    switch (action.type) {
        case GET_DOCUMENT_TYPE_LIST_REQUEST:
            return {
                ...state,
                fetching: true,
                requestComplete: false,
                error: "",
            };
        case GET_DOCUMENT_TYPE_LIST_SUCCESS:
            return {
                data: action.payload,
                fetching: false,
                requestComplete: true,
                error: "",
            };
        case GET_DOCUMENT_TYPE_LIST_RESET:
            return {
                data: [],
                fetching: false,
                requestComplete: false,
                error: "",
            };
        case GET_DOCUMENT_TYPE_LIST_FAILURE:
            return {
                fetching: false,
                requestComplete: false,
                error: action.payload,
            };
        default:
            return state;
    }
}

//GET_LIST_COST_CENTRE

const costCentreListState = {
    data: [],
    fetching: false,
    requestComplete: false,
    error: "",
};
export function costCentreList(state = costCentreListState, action) {
    switch (action.type) {
        case GET_LIST_COST_CENTRE_REQUEST:
            return {
                ...state,
                fetching: true,
                requestComplete: false,
                error: "",
            };
        case GET_LIST_COST_CENTRE_SUCCESS:
            return {
                data: action.payload,
                fetching: false,
                requestComplete: true,
                error: "",
            };
        case GET_LIST_COST_CENTRE_RESET:
            return {
                data: [],
                fetching: false,
                requestComplete: false,
                error: "",
            };
        case GET_LIST_COST_CENTRE_FAILURE:
            return {
                fetching: false,
                requestComplete: false,
                error: action.payload,
            };
        default:
            return state;
    }
}

//get PO NUMBER LIST

const poNumberListState = {
    data: [],
    fetching: false,
    requestComplete: false,
    error: "",
};
export function poNumberList(state = poNumberListState, action) {
    switch (action.type) {
        case GET_PO_NUMBER_LIST_REQUEST:
            return {
                ...state,
                fetching: true,
                requestComplete: false,
                error: "",
            };
        case GET_PO_NUMBER_LIST_SUCCESS:
            return {
                data: action.payload,
                fetching: false,
                requestComplete: true,
                error: "",
            };
        case GET_PO_NUMBER_LIST_RESET:
            return {
                data: [],
                fetching: false,
                requestComplete: false,
                error: "",
            };
        case GET_PO_NUMBER_LIST_FAILURE:
            return {
                fetching: false,
                requestComplete: false,
                error: action.payload,
            };
        default:
            return state;
    }
}

//GET SAP VENDOR LIST

const sapVendorListState = {
    data: [],
    fetching: false,
    requestComplete: false,
    error: "",
};
export function sapVendorList(state = sapVendorListState, action) {
    switch (action.type) {
        case GET_SAP_VENDOR_LIST_REQUEST:
            return {
                ...state,
                fetching: true,
                requestComplete: false,
                error: "",
            };
        case GET_SAP_VENDOR_LIST_SUCCESS:
            return {
                data: action.payload,
                fetching: false,
                requestComplete: true,
                error: "",
            };
        case GET_SAP_VENDOR_LIST_RESET:
            return {
                data: [],
                fetching: false,
                requestComplete: false,
                error: "",
            };
        case GET_SAP_VENDOR_LIST_FAILURE:
            return {
                fetching: false,
                requestComplete: false,
                error: action.payload,
            };
        default:
            return state;
    }
}

const TransferInv = {
    data: [],
    fetching: false,
    requestComplete: false,
    error: "",
};
export function TransferInvoiceRedux(state = TransferInv, action) {
    switch (action.type) {
        case TRANSFER_INVOICE_REQUEST:
            return {
                ...state,
                fetching: true,
                requestComplete: false,
                error: "",
            };
        case TRANSFER_INVOICE_SUCCESS:
            return {
                data: action.payload,
                fetching: false,
                requestComplete: true,
                error: "",
            };
        case TRANSFER_INVOICE_RESET:
            return {
                data: [],
                fetching: false,
                requestComplete: false,
                error: "",
            };
        case TRANSFER_INVOICE_FAILURE:
            return {
                fetching: false,
                requestComplete: false,
                error: action.payload,
            };
        default:
            return state;
    }
}

const TransferInvReject = {
    data: [],
    fetching: false,
    requestComplete: false,
    error: "",
};
export function TransferInvoiceRejectState(state = TransferInvReject, action) {
    switch (action.type) {
        case GET_REJECT_INVOICE_REQUEST:
            return {
                ...state,
                fetching: true,
                requestComplete: false,
                error: "",
            };
        case GET_REJECT_INVOICE_SUCCESS:
            return {
                data: action.payload,
                fetching: false,
                requestComplete: true,
                error: "",
            };
        case GET_REJECT_INVOICE_RESET:
            return {
                data: [],
                fetching: false,
                requestComplete: false,
                error: "",
            };
        case GET_REJECT_INVOICE_FAILURE:
            return {
                fetching: false,
                requestComplete: false,
                error: action.payload,
            };
        default:
            return state;
    }
}

const invoiceDocState = {
    dataList: [],
    fetching: false,
    requestComplete: false,
    error: "",
    imageId: "",
};

export function InvoiceDocumentReducer(state = invoiceDocState, action) {
    switch (action.type) {
        case INVOICE_GET_DOCUMENT_REQUEST:
            return {
                ...state,
                fetching: true,
                requestComplete: false,
                error: "",
            };
        case INVOICE_GET_DOCUMENT_SUCCESS:
            return {
                dataList: action.payload,
                fetching: false,
                requestComplete: true,
                error: "",
                imageId: action.id,
            };
        case INVOICE_GET_DOCUMENT_RESET:
            return {
                dataList: [],
                fetching: false,
                requestComplete: false,
                error: "",
                imageId: action.id,
            };
        case INVOICE_GET_DOCUMENT_FAILURE:
            return {
                fetching: false,
                requestComplete: false,
                error: action.payload,
                imageId: "",
            };
        default:
            return state;
    }
}

//three way matchin by erp validation

const threeWayMatchingState = {
    data: {},
    initialSelectedData: {},
    cancelData: {},
    fetching: false,
    requestComplete: false,
    error: "",
};

export function threeWayMatchingByErp(state = threeWayMatchingState, action) {
    let erpValidateArr = action?.erpValidateArr ? action?.erpValidateArr : {};
    let cancelData = action?.cancelData ? action?.cancelData : {};
    if (action?.type == "ERP_VALIDATIONBYMATCHING_UPDATE") {
        if (action?.opp == 1) {
            const lineItemNo = action?.lineItemNo;
            let overallData = erpValidateArr?.overallData;
            let onlySelectedLineItem = erpValidateArr?.onlySelectedLineItem;
            overallData = _.reject(overallData, { lineItemNo: lineItemNo });
            onlySelectedLineItem = _.reject(onlySelectedLineItem, { lineItemNo: lineItemNo });
            overallData = _.map(overallData, (item) => {
                if (item.lineItemNo >= lineItemNo) {
                    item.lineItemNo = item.lineItemNo - 1;
                }
                return item;
            });
            onlySelectedLineItem = _.map(onlySelectedLineItem, (item) => {
                if (item.lineItemNo >= lineItemNo) {
                    item.lineItemNo = item.lineItemNo - 1;
                }
                return item;
            });
            erpValidateArr = {
                ...erpValidateArr,
                overallData: overallData,
                onlySelectedLineItem: onlySelectedLineItem,
            };
        } else if (action?.opp == 2) {
            erpValidateArr = cancelData;
        } else if (action?.opp == 3) {
            cancelData = erpValidateArr;
        }
    }
    switch (action.type) {
        case ERP_VALIDATIONBYMATCHING_REQUEST:
            return {
                ...state,
                fetching: true,
                requestComplete: false,
                error: "",
            };
        case ERP_VALIDATIONBYMATCHING_SUCCESS:
            return {
                data: action.payload,
                initialSelectedData: action.initialData,
                cancelData: cloneDeep(action?.payload),
                fetching: false,
                requestComplete: true,
                error: "",
            };
        case ERP_VALIDATIONBYMATCHING_RESET:
            return {
                data: {},
                cancelData: {},
                fetching: false,
                requestComplete: false,
                error: "",
            };
        case ERP_VALIDATIONBYMATCHING_FAILURE:
            return {
                fetching: false,
                requestComplete: false,
                error: action.payload,
            };
        case ERP_VALIDATIONBYMATCHING_UPDATE:
            return {
                ...state,
                data: erpValidateArr,
                cancelData: cancelData,
            };
        default:
            return state;
    }
}
const addInvoiceServiceLineItems = {
    data: [],
    cancelData: [],
    fetching: false,
    requestComplete: false,
    error: "",
};

export function addServiceLineItems(state = addInvoiceServiceLineItems, action) {
    let changeData = action?.parseData ? action?.parseData : [];
    if (action?.type == "POST_SERVICE_LINE_ITEMS_UPDATE") {
        if (action?.opp == 1) {
            const lineItemNo = action?.lineItemNo;
            let parseData = [...changeData?.parseData];
            let poItemCodeData = [...changeData?.poItemCodeData];

            //* Function to change the parseData
            function removeAndUpdateItemNo(arr) {
                return _.map(arr, (innerArray) => {
                    return _.map(innerArray, (item) => {
                        if (item.item_no > lineItemNo) {
                            return { ...item, item_no: item.item_no - 1 };
                        }
                        return item;
                    });
                });
            }
            parseData = _.filter(parseData, (innerArray) => {
                if (innerArray?.[0]?.item_no != lineItemNo) {
                    return removeAndUpdateItemNo(innerArray);
                }
            });

            poItemCodeData = _.reject(poItemCodeData, { lineItemNo: lineItemNo });
            poItemCodeData = _.map(poItemCodeData, (item) => {
                if (item.lineItemNo >= lineItemNo) {
                    item.lineItemNo = item.lineItemNo - 1;
                }
                return item;
            });
            changeData = {
                parseData,
                poItemCodeData,
            };
        } else if (action?.opp == 2) {
            changeData = cloneDeep(state?.cancelData);
        } else if (action?.opp == 3) {
            state.cancelData = cloneDeep(changeData);
        }
    }
    switch (action.type) {
        case POST_SERVICE_LINE_ITEMS_REQUEST:
            return {
                ...state,
                fetching: true,
                requestComplete: false,
                error: "",
            };
        case POST_SERVICE_LINE_ITEMS_SUCCESS:
            const newData = action?.payload;
            return {
                data: cloneDeep(newData),
                cancelData: cloneDeep(newData),
                fetching: false,
                requestComplete: true,
                error: "",
            };
        case POST_SERVICE_LINE_ITEMS_RESET:
            return {
                data: [],
                cancelData: [],
                fetching: false,
                requestComplete: false,
                error: "",
            };
        case POST_SERVICE_LINE_ITEMS_FAILURE:
            return {
                fetching: false,
                requestComplete: false,
                error: action.payload,
            };
        case POST_SERVICE_LINE_ITEMS_UPDATE:
            return {
                ...state,
                data: changeData,
            };
        default:
            return state;
    }
}

//get TAX CODE LIST

const taxCodeListState = {
    data: [],
    fetching: false,
    requestComplete: false,
    error: "",
};
export function taxCodeWithPercentageList(state = taxCodeListState, action) {
    switch (action.type) {
        case GET_TAX_CODE_LIST_REQUEST:
            return {
                ...state,
                fetching: true,
                requestComplete: false,
                error: "",
            };
        case GET_TAX_CODE_LIST_SUCCESS:
            return {
                data: action.payload,
                fetching: false,
                requestComplete: true,
                error: "",
            };
        case GET_TAX_CODE_LIST_RESET:
            return {
                data: [],
                fetching: false,
                requestComplete: false,
                error: "",
            };
        case GET_TAX_CODE_LIST_FAILURE:
            return {
                fetching: false,
                requestComplete: false,
                error: action.payload,
            };
        default:
            return state;
    }
}

//* Reducer for get Po type
const getPoTypeState = {
    data: [],
    fetching: false,
    requestComplete: false,
    error: "",
};
export function getPoTypeReducer(state = getPoTypeState, action) {
    switch (action.type) {
        case GET_PO_TYPE_REQUEST:
            return {
                ...state,
                fetching: true,
                requestComplete: false,
                error: "",
            };
        case GET_PO_TYPE_SUCCESS:
            return {
                data: action.payload?.data,
                fetching: false,
                requestComplete: true,
                error: "",
            };
        case GET_PO_TYPE_RESET:
            return {
                data: [],
                fetching: false,
                requestComplete: false,
                error: "",
            };
        case GET_PO_TYPE_FAILURE:
            return {
                fetching: false,
                requestComplete: false,
                error: action.payload,
            };
        default:
            return state;
    }
}

//* Reducer for get document type
const getDocumentTypeState = {
    getDocument: [],
    fetching: false,
    requestComplete: false,
    error: "",
};
export function getDocumemtTypeReducer(state = getDocumentTypeState, action) {
    switch (action.type) {
        case GET_DOCUMENT_TYPE_REQUEST:
            return {
                ...state,
                fetching: true,
                requestComplete: false,
                error: "",
            };
        case GET_DOCUMENT_TYPE_SUCCESS:
            return {
                getDocument: action.payload,
                fetching: false,
                requestComplete: true,
                error: "",
            };
        case GET_DOCUMENT_TYPE_RESET:
            return {
                getDocument: [],
                fetching: false,
                requestComplete: false,
                error: "",
            };
        case GET_DOCUMENT_TYPE_FAILURE:
            return {
                fetching: false,
                requestComplete: false,
                error: action.payload,
            };
        default:
            return state;
    }
}

//* Reducer for Post document type
const postDocumentTypeState = {
    updateDocument: [],
    fetching: false,
    requestComplete: false,
    error: "",
};
export function postDocumemtTypeReducer(state = postDocumentTypeState, action) {
    switch (action.type) {
        case POST_DOCUMENT_TYPE_REQUEST:
            return {
                ...state,
                fetching: true,
                requestComplete: false,
                error: "",
            };
        case POST_DOCUMENT_TYPE_SUCCESS:
            return {
                updateDocument: action.payload,
                fetching: false,
                requestComplete: true,
                error: "",
            };
        case POST_DOCUMENT_TYPE_RESET:
            return {
                updateDocument: [],
                fetching: false,
                requestComplete: false,
                error: "",
            };
        case POST_DOCUMENT_TYPE_FAILURE:
            return {
                fetching: false,
                requestComplete: false,
                error: action.payload,
            };
        default:
            return state;
    }
}

//* Reducer for get invoice calc view
const getInvCalcState = {
    invCalc: [],
    initial: [],
    fetching: false,
    requestComplete: false,
    error: "",
};
const Format = (invCalc, Amounts) =>
    invCalc?.flatMap((item) => {
        const billAmount = item?.bill_amount;
        const previousBillAmount = item?.previous_bill_amount;
        const dynamicLabels = item?.dynamic_label;
        const getNetVal = billAmount[`net_value`];
        const getAdRec = billAmount[`advance_recovery`];
        const getTdsAdRec = billAmount[`tds_advance_recovery`];
        const poReten = billAmount[`po_retention`];
        const addHold = billAmount[`additional_hold`];
        const otherdetuct = billAmount[`other_deduction`];
        const miscDb = billAmount[`misc_debit`];
        const creditNote = billAmount[`direct_credit_note`];
        const tcsonPurchase = billAmount[`tcs_on_purchase`];

        item.bill_amount.po_retention = poReten != "0" && poReten != undefined ? poReten : RoundNum(Amounts?.RetenTtl || 0);

        const netPayable =
            formatNumber(getNetVal) -
            formatNumber(getAdRec) +
            formatNumber(getTdsAdRec) -
            formatNumber(poReten) -
            formatNumber(addHold) -
            formatNumber(otherdetuct) +
            formatNumber(tcsonPurchase) -
            formatNumber(miscDb) -
            formatNumber(creditNote);
        item.bill_amount.net_payable = RoundNum(netPayable);
        item.bill_amount.poc_net = RoundNum(formatNumber(Amounts?.NetAmt?.textContent || billAmount[`poc_net`]));
        item.bill_amount.gross_value = RoundNum(formatNumber(Amounts?.GrossAmt?.textContent || billAmount[`gross_value`]));
        item.bill_amount.tax_additions = RoundNum(formatNumber(Amounts?.TaxAmt?.textContent || billAmount[`tax_additions`]));

        return dynamicLabels?.map((label, i) => {
            let modifiedLabel = label;
            if (label === "tcs_on_purchase") {
                modifiedLabel = "TCS On Purchase";
            } else if (label === "tds_advance_recovery") {
                modifiedLabel = "TDS Advance Recovery";
            }

            const formattedLabel = modifiedLabel?.replace(/_/g, " ").replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase());
            const isEditable = ["Po Retention", "Additional Hold", "Other Deduction", "TCS On Purchase"]?.includes(formattedLabel);
            const isChild = ["Poc Net", "Tax Deductions", "Advance Recovery", "Misc Debit", "Direct Credit Note"]?.includes(formattedLabel);
            const isPlus = ["Tax Additions", "TDS Advance Recovery", "TCS On Purchase"]?.includes(formattedLabel);
            const isMinus = [
                "Tax Deductions",
                "Advance Recovery",
                "Po Retention",
                "Additional Hold",
                "Other Deduction",
                "Tcs On Purchase",
                "Misc Debit",
                "Direct Credit Note",
            ]?.includes(formattedLabel);
            return {
                Name: formattedLabel,
                billAmount: billAmount[label],
                PreviousBillAmount: previousBillAmount[label],
                CumulativeAmount: RoundNum(formatNumber(billAmount[label]) + formatNumber(previousBillAmount[label])),
                isEditable: isEditable ? true : false,
                isChild: isChild ? true : false,
                isPlus: isPlus ? true : isMinus ? false : null,
            };
        });
    }); //formating the response data to our as per the UI Table formatting

export function getInvCalcViewReducer(state = getInvCalcState, action) {
    switch (action.type) {
        case GET_INVOICE_CALC_VIEW_REQUEST:
            return {
                ...state,
                fetching: true,
                requestComplete: false,
                error: "",
            };
        case GET_INVOICE_CALC_VIEW_SUCCESS:
            return {
                invCalc: Format(action.payload.data, action.payload.Amounts),
                initial: Format(action.payload.data, action.payload.Amounts),
                fetching: false,
                requestComplete: true,
                error: "",
            };
        case GET_INVOICE_CALC_VIEW_UPDATE:
            return {
                ...state,
                invCalc: action.payload,
                fetching: false,
                requestComplete: true,
                error: "",
            };
        case GET_INVOICE_CALC_VIEW_RESET:
            return {
                invCalc: [],
                initial: [],
                fetching: false,
                requestComplete: false,
                error: "",
            };
        case GET_INVOICE_CALC_VIEW_FAILURE:
            return {
                fetching: false,
                requestComplete: false,
                error: action.payload,
            };
        default:
            return state;
    }
}

//* Reducer for invoice calc detail view
const getInvCalcViewState = {
    invCalcview: [],
    micdata: [],
    advdata: [],
    creditnte: [],
    poData: {},
    fetching: false,
    requestComplete: false,
    error: "",
};
export function getInvCalcDetailViewReducer(state = getInvCalcViewState, action) {
    switch (action.type) {
        case GET_INVOICE_CALC_DETAIL_VIEW_REQUEST:
            return {
                ...state,
                fetching: true,
                requestComplete: false,
                error: "",
            };
        case GET_INVOICE_CALC_DETAIL_VIEW_SUCCESS:
            let arr = [];
            return {
                invCalcview: action?.CalcType == 2 ? action?.payload?.message : [], //TDS
                creditnte: action?.CalcType == 4 ? action?.payload?.message : [],
                micdata: action?.CalcType == 3 ? action?.payload?.message : [],
                advdata: action?.CalcType == 1 ? action?.payload?.message : [],
                poData: action?.payload?.po_Data,
                fetching: false,
                requestComplete: true,
                error: "",
            };
        case GET_INVOICE_CALC_DETAIL_VIEW_RESET:
            return {
                invCalcview: [],
                micdata: [],
                advdata: [],
                creditnte: [],
                poData: {},
                fetching: false,
                requestComplete: false,
                error: "",
            };
        case GET_INVOICE_CALC_DETAIL_VIEW_FAILURE:
            return {
                fetching: false,
                requestComplete: false,
                error: action?.payload,
            };
        default:
            return state;
    }
}
