import { useContext, useEffect, useState } from "react";
import Modal from "../../../components/UI/Modal/Modal";
import { ThemeContext } from "../../../Hooks/Themes/Themeprovider";
import Input from "../../../components/Input";
import Button from "../../../components/Button";
import { useSelector } from "react-redux";
import _ from "lodash";
import Swal from "sweetalert2";
import { formatNumber } from "../../../utils/helpers";
import { useMemo } from "react";
import { NumericFormat } from "react-number-format";
import { useLocation } from "react-router-dom";

let message = "";
const ShowMoreModal = (props) => {
    const numFormat = props?.numFormat;
    const { headerItem, totalRetentionAmount, overallErrors } = props;
    const totalObject = _.filter(headerItem, { label_name: "total" });
    const [totalLabelValue] = totalObject.map((obj) => obj.label_value);
    let totalVal = formatNumber(totalLabelValue);
    const totalNetObject = _.filter(headerItem, { label_name: "total_net" });
    const [totalNetLabelValue] = totalNetObject.map((obj) => obj.label_value);
    let totalNetVal = formatNumber(totalNetLabelValue);
    const { currentMode } = useContext(ThemeContext);
    const invoiceList = useSelector((state) => state?.getInvoiceItemsState);
    const DisplayRole = useSelector((state) => state?.DisplayRolePermissionStateAction);
    const roleActionRetentionEdit = DisplayRole?.data?.role_info?.menu?.find((ite) => ite?.menu_id == 8)?.actions?.find((ele) => ele?.id == 26);
    const roleActionAdvanceAmountEdit = DisplayRole?.data?.role_info?.menu?.find((ite) => ite?.menu_id == 8)?.actions?.find((ele) => ele?.id == 27);
    const recoveryInfo = invoiceList?.data?.datalist[0]?.recovery_info;
    const mainData = invoiceList?.data?.datalist[0];
    const InputDisabled = mainData?.ext_status_id == 3 || mainData?.ext_status_id == 4 || mainData?.ext_status_id == 5;
    let Tags;
    const location = useLocation();
    message = props?.isShowmore ? props?.sapMessage?.replace(/[*]/g, "<br/>") : props?.retentionMessage;
    useEffect(() => {
        // const Breaks = document.querySelector(`.texts`);
        // if (Breaks) {
        //     Tags = Breaks.innerHTML = message?.replace(/[*]/g, "<br/>");
        // }
    }, [props?.isShowmore, props?.isRententionShow]);
    const { data } = useSelector((state) => state?.threeWayMatchingByErpState); //get from erp retention datas
    const [downData, setDownData] = useState([]);
    const [oldData, setOldData] = useState({});
    const [reduceTotal, setReduceTotal] = useState("");
    const [inputValues, setInputValues] = useState({}); // input onchange store state
    const [inputTotRetentionValues, setInputTotRetentionValues] = useState(totalRetentionAmount); // input onchange store state
    // input onchange store state
    const handleChange = (e) => {
        props?.setIsEditRetention(true);
        const { name, value } = e.target;
        const formatedVal = formatNumber(value);
        if (formatedVal > totalVal) {
            Swal.fire({
                text: `Retention amount cannot be greater than Gross amount!`,
                icon: "warning",
                closeOnClickOutside: false,
                showCloseButton: true,
                buttons: {
                    confirm: true,
                },
            });
            setInputTotRetentionValues(totalLabelValue);
        } else {
            setInputTotRetentionValues(value);
            data.recovery_info.totalRetentionAmount = formatNumber(inputTotRetentionValues);
        }
    };
    useEffect(() => {
        setInputTotRetentionValues(totalRetentionAmount);
    }, [props?.isOpened]);
    const changeReduceValue = (i) => {
        let reduceValue = 0;
        let index = 0;
        _.map(downData, (item, key) => {
            if (index !== i) {
                reduceValue += !item?.adjustmentAmount ? 0 : parseFloat(item?.adjustmentAmount);
            }
            index++;
        });
        // downData[i].adjustmentAmount = inputValues?.[`field-${i}`];
        const reduceTot = totalVal - reduceValue;
        setReduceTotal(reduceTot);
        downData[i].recoveryAmount = parseInt(oldData[i]) - reduceTot;
    };
    const initial = useSelector((state) => state?.threeWayMatchingByErpState)?.data?.recovery_info;
    const checkRetention = roleActionRetentionEdit?.isallowed
        ? !initial
            ? recoveryInfo?.hasOwnProperty("retentionIndicator")
                ? ["h", "i"]?.includes(recoveryInfo?.retentionIndicator?.toLowerCase())
                : false
            : ["h", "i"]?.includes(initial?.retentionIndicator?.toLowerCase())
        : false;

    useMemo(() => {
        let rec = {};
        if (recoveryInfo?.downpaymentInfo?.length > 0 && downData?.length == 0) {
            recoveryInfo?.downpaymentInfo?.map((item, index) => {
                rec[index] = item?.recoveryAmount;
            });
            setOldData(rec);
            setReduceTotal(totalVal);
            setDownData(recoveryInfo?.downpaymentInfo);
        } else if (data?.recovery_info?.downpaymentInfo?.length > 0 && downData?.length == 0) {
            data?.recovery_info?.downpaymentInfo?.map((item, index) => {
                rec[index] = item?.recoveryAmount;
            });
            setOldData(rec);
            setReduceTotal(totalVal);
            setDownData(data?.recovery_info?.downpaymentInfo);
        }
    }, [data, recoveryInfo]);
    // handle change for adjustment input
    const handleInputChange = (e, i) => {
        const minV = Math.min(+reduceTotal, +oldData[i]); //get minimum value gross and
        changeReduceValue(i);
        const { name, value } = e?.target;
        const numericValue = formatNumber(value);
        // Check if the value exceeds the maximum
        if (numericValue > minV) {
            downData.forEach((el, ind) => {
                if (ind == i) {
                    el.adjustmentAmount = formatNumber(minV)?.toString();
                }
            });
            // If it exceeds the maximum, set the input value to the maximum
            setInputValues((prevInputValues) => ({
                ...prevInputValues,
                [name]: minV.toString(),
            }));
            downData[i].recoveryAmount = (+oldData[i] - minV)?.toString();
            Swal.fire({
                text: `Adjustment amount should below ${minV}!`,
                icon: "warning",
                showCloseButton: true,
                closeOnClickOutside: false,
                buttons: {
                    confirm: true,
                },
            });
        } else {
            if (downData[i]) {
                let updateAmt = +oldData[i] - numericValue;
                if (isNaN(numericValue)) {
                    downData[i].recoveryAmount = +oldData[i]?.toString();
                } else {
                    downData[i].recoveryAmount = updateAmt?.toString();
                }
            }
            downData.forEach((el, ind) => {
                if (ind == i) {
                    el.adjustmentAmount = formatNumber(value)?.toString();
                }
            });
            setInputValues((prevInputValues) => ({
                ...prevInputValues,
                [name]: value,
            }));
        }
    };
    const totalAdjustmentAmount = downData.reduce((total, adjustment) => {
        // Convert adjustmentAmount from string to number using parseFloat
        const adjustmentAmount = formatNumber(adjustment.adjustmentAmount);

        // Check if adjustmentAmount is a valid number before adding
        if (!isNaN(adjustmentAmount)) {
            return total + adjustmentAmount;
        } else {
            return total; // Skip invalid values
        }
    }, 0);
    let sum = 0;
    let payanleAmt = Object?.values(inputValues).forEach((str) => {
        const number = formatNumber(str);
        if (!isNaN(number)) {
            sum += number;
        }
    });
    const handleSubmit = () => {
        props?.handleClose();
        props?.setRetentionArr(downData);
        props?.setTotRetentionAmount(inputTotRetentionValues ? inputTotRetentionValues : data?.recovery_info?.totalRetentionAmount);
    };
    sum = sum == 0 ? totalAdjustmentAmount : sum;
    const payAmtVal = totalVal - formatNumber(inputTotRetentionValues) - sum;
    return (
        <>
            <Modal cartClose={props?.handleClose} center="center" autowidth={`autowidth`} className={`${props?.className}`}>
                {!props?.isShowmore && props?.isRententionShow ? (
                    <div
                        className={`xl:min-w-[980px] p-[20px] rounded-[8px] transition bg-${currentMode}-card border-[rgba(236,236,236,0.88)] shadow-[0px_4px_20px_rgba(238,238,238,0.5)]  duration-150 `}
                    >
                        <h5 className="text-[#028C78] font-interSb pb-[20px]"> Recovery Details</h5>

                        <div className={`flex justify-between `}>
                            <div>
                                <ul className="font-interM space-y-[15px]">
                                    <div className="flex">
                                        <li>Net amount </li>
                                        <span className="pl-[134px] pr-[10px] text-right">:</span>
                                        {totalNetLabelValue}
                                    </div>
                                    <div className="flex">
                                        <li>Gross amount </li>
                                        <span className="pl-[118px] pr-[10px] text-right">:</span>
                                        {totalLabelValue}
                                    </div>

                                    {/* <div className="flex">
                                        <li>
                                            Down payment <span className="pl-[108px] pr-[10px] text-right">:</span>
                                            {!data?.recovery_info?.advanceTotal ? "0" : data?.recovery_info?.advanceTotal}
                                        </li>
                                    </div> */}
                                    <div className="flex">
                                        <li className="mb-1 flex space-x-2">
                                            Retention amount <span className={`pl-[93px] pr-[7px] text-right`}>:</span>
                                            {checkRetention ? (
                                                <div>
                                                    <NumericFormat
                                                        allowNegative={false}
                                                        decimalScale={2}
                                                        displayType="input"
                                                        maxlength={20}
                                                        disabled={
                                                            props?.status == 7 ||
                                                            props?.status == "7" ||
                                                            props?.status == "4" ||
                                                            props?.status == 4 ||
                                                            InputDisabled
                                                        }
                                                        thousandSeparator={numFormat == "1" ? "," : numFormat == "2" ? "." : " "}
                                                        decimalSeparator={numFormat == "1" ? "." : numFormat == "2" ? "," : "."}
                                                        value={inputTotRetentionValues}
                                                        onChange={handleChange}
                                                        name="totalRetentionAmount"
                                                        className={`flex w-full border-[1px] py-1 px-5 rounded-[8px] min-w-[150px] text-${currentMode}-copy bg-transparent`}
                                                        placeholder="Retention amount.."
                                                    />
                                                </div>
                                            ) : (
                                                <div>{inputTotRetentionValues}</div>
                                            )}
                                        </li>
                                    </div>
                                </ul>
                            </div>
                            <span className="cursor-pointer convertWhite absolute right-[20px] top-[20px]" onClick={props?.handleClose}>
                                <img src="/images/common/close-one.svg" alt="close-one" />
                            </span>
                        </div>
                        {downData?.length == 0 ? (
                            <div className="mx-[-20px] mt-[40px] border-[#ECECEC]">
                                <hr />
                            </div>
                        ) : (
                            <h4 className="mb-[10px] font-interM mt-5">Downpayment Details:</h4>
                        )}

                        {downData && downData?.length !== 0 && (
                            <div className="max-h-[408px] mx-[-20px] overflow-y-auto">
                                <table className="w-full border-0 rolemasterTable rounded-[0] lastBorder text-left">
                                    <thead>
                                        <tr>
                                            <th className="px-[10px] rounded-l-[0]">#</th>
                                            <th className="px-[10px]">Document Number</th>
                                            <th className="">Document Year</th>
                                            <th>Down payment</th>
                                            <th>Balance amount</th>
                                            <th className="rounded-r-[0]">Adjustment amount</th>
                                        </tr>
                                    </thead>
                                    <tbody className={downData?.length !== 0 ? `relative` : ``}>
                                        {downData?.length !== 0 ? (
                                            downData?.map((el, i) => {
                                                return (
                                                    <tr key={i}>
                                                        <td className="px-[10px]">{i + 1}</td>
                                                        <td className="">{el?.documentNo}</td>
                                                        <td className="">{el?.documentDate}</td>
                                                        <td className="">{el?.downpaymentAmount}</td>
                                                        <td className="">
                                                            {" "}
                                                            <NumericFormat
                                                                allowNegative={false}
                                                                decimalScale={2}
                                                                value={el?.recoveryAmount}
                                                                displayType="text"
                                                            />
                                                        </td>

                                                        {roleActionAdvanceAmountEdit?.isallowed == true ? (
                                                            <td className="flex">
                                                                <NumericFormat
                                                                    allowNegative={false}
                                                                    decimalScale={2}
                                                                    value={el?.adjustmentAmount}
                                                                    displayType="input"
                                                                    thousandSeparator={numFormat == "1" ? "," : numFormat == "2" ? "." : " "}
                                                                    decimalSeparator={numFormat == "1" ? "." : numFormat == "2" ? "," : "."}
                                                                    disabled={
                                                                        initial?.downpaymentInfo?.find(
                                                                            (item) =>
                                                                                item?.documentNo == el?.documentNo && item?.downpaymentAmount == 0
                                                                        ) ||
                                                                        props?.status == 7 ||
                                                                        props?.status == 4 ||
                                                                        props?.status == "4" ||
                                                                        props?.status == "7" ||
                                                                        InputDisabled
                                                                    }
                                                                    onChange={(e) => handleInputChange(e, i)}
                                                                    name={`adjustmentAmount-${i}`}
                                                                    className={`w-full border-[1px] py-1 px-5 rounded-[8px] min-w-[150px] text-${currentMode}-copy bg-transparent`}
                                                                    placeholder="Adjust the amount.."
                                                                />
                                                            </td>
                                                        ) : (
                                                            <td className="">{el?.adjustmentAmount}</td>
                                                        )}
                                                    </tr>
                                                );
                                            })
                                        ) : (
                                            <div className="absolute mb-8 left-0 w-full text-center border-b">No Data Found</div>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        )}
                        <div className="mt-6">
                            <div className="flex space-x-2 items-center">
                                <span className="font-interM"> Payable amount</span>
                                <span className="pl-[100px] pr-[10px] text-right">:</span>
                                <p className="font-interM">{payAmtVal?.toFixed(2)}</p>
                            </div>
                            <span className="text-xs block">(Gross - Retention - Down payment)</span>
                        </div>
                        <div className="space-x-2 pb-[10px] justify-end flex">
                            <Button
                                type="submit"
                                title="To Save"
                                disabled={props?.status == 7 || props?.status == "7" || props?.status == "4" || props?.status == 4 || InputDisabled}
                                onClick={handleSubmit}
                                className={`bg-light-20 flex text-base font-interR py-[.5rem] px-[2.24rem] rounded-[8px] text-white`}
                            >
                                Save
                            </Button>
                            <Button
                                type="cancel"
                                onClick={props?.handleClose}
                                title="To Cancel"
                                className="bg-light-240 flex text-base font-interR py-[.5rem] px-[1.9rem] rounded-[8px] text-light-40 closeModal"
                            >
                                Cancel
                            </Button>
                        </div>
                    </div>
                ) : (
                    props?.isShowmore && (
                        <div
                            className={`xl:max-w-[500px] min-w-[500px] max-h-[300px]  transition bg-${currentMode}-card border-[rgba(236,236,236,0.88)] shadow-[0px_4px_20px_rgba(238,238,238,0.5)] rounded-[10px] duration-150 ease-out p-[30px]`}
                        >
                            <div
                                className={`flex justify-between items-center border-${currentMode}-700 sticky pt-0 top-0 bg-${currentMode}-card  z-[9] border-b-[1px] mx-[-30px] py-5 px-[30px]`}
                            >
                                <span className="flex">
                                    <img src="images/common/alert-triangle.svg" alt="warning" />
                                    <span className="ml-2">{`${
                                        location.pathname === "/proforma-invoice-view"
                                            ? "Resolve the failed proforma invoice data mismatches:"
                                            : "Resolve the failed ERP data or invoice data mismatches:"
                                    }`}</span>
                                </span>
                                <span className="cursor-pointer convertWhite" onClick={props?.handleClose}>
                                    <img src="/images/common/close-one.svg" alt="close-one" />
                                </span>
                            </div>
                            {/* <p className="texts">{Tags}</p> */}
                            <div className="mt-7 max-h-[150px] overflow-y-auto mx-auto">
                                {overallErrors?.map((item, index) => {
                                    return (
                                        <ul key={index + 1}>
                                            {index + 1}. {item}
                                        </ul>
                                    );
                                })}
                            </div>
                        </div>
                    )
                )}
            </Modal>
        </>
    );
};

export default ShowMoreModal;
