import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Button from "../../components/Button";
import Container from "../../components/UI/Container";
import { resendEmailLink } from "../../redux/actions/auth";
import { encryptId } from "../../utils/helpers";
import SuccessMessageBox from "../../components/UI/Alert/sucess";
import MessageBox from "../../components/UI/Alert";
import { RESEND_EMAIL_LINK_REQUEST, RESEND_EMAIL_LINK_RESET } from "../../redux/types/types";
const ExpiryLink = () => {
    const successMsg = "Email Link sent Successfully";
    const errorMsg = "Something went wrong please contact admin";
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const backToSignUp = () => {
        navigate("/signup");
    };
    const params = new URLSearchParams(window.location.search);
    // let emailIDPlus;
    let emailID = params?.get("emid");
    if (emailID) {
        emailID = emailID.replace(" ", "+");
    }
    const handleResendLink = () => {
        const reqObj = {
            email: emailID,
            type: 1,
        };
        dispatch(resendEmailLink(reqObj));
        dispatch({ type: RESEND_EMAIL_LINK_REQUEST });
    };
    const resendDetail = useSelector((state) => state?.ResendEmailLinkState);
    const { requestComplete, error, data, fetching } = resendDetail;
    if (requestComplete) {
        setTimeout(() => {
            dispatch({ type: RESEND_EMAIL_LINK_RESET });
        }, 3000);
    }
    return (
        <>
            {requestComplete && data?.status && (
                <SuccessMessageBox success={successMsg} />
            )}
            {requestComplete && !data?.status && <MessageBox error={data?.message} />}
            {error && !data?.status && <MessageBox error={errorMsg} />}
            <div className="WrapCenter">
                <Container className="mt-10">
                    <Link to={'//www.myiq.ai/'} target="_blank" className="mb-10 inline-block" >
                        <img
                            className="w-[121px] h-[95px]"
                            src="/images/logo/MyiqFinal.svg"
                            alt="logoiq"
                        />
                    </Link>
                    <div className="flex justify-center items-center h-[calc(80vh_-_120px)] mx-auto">
                        <div className="basis-1/2 2xl:basis-full text-center flex justify-center items-center flex-col">
                            <div className="bg-message rounded-[50%] w-[100px] h-[100px] grid place-items-center mb-8">
                                <img
                                    className=""
                                    src="images/common/sand-clock.svg"
                                    alt="logoiq"
                                />
                            </div>
                            <h3 className="text-[36px] font-interSb leading-[43px] text-light-20 mb-4">
                                Oops! Your email link has expired
                            </h3>
                            {/* <p className="mb-[2rem] text-[14px] text-inter">
                                Click the below button to resend confirmation mail.
                            </p> */}
                            <p className="mt-4 text-center">
                                <span className="text-[red]">*</span>
                                <span> Please Click on  Resend Invitation to send you a new link to your registered email ID</span>
                                <Button type="submit"
                                    onClick={() => handleResendLink()}
                                    disabled={fetching} className="text-light-10 inline-block">
                                    Resend Invitation
                                </Button>

                            </p>
                            {/* <p className="mt-4 text-center">
                                <span className="text-[red]">*</span> If you {`haven’t`}{" "}
                                received click here to{" "}
                                <Button
                                    onClick={handleResendLink}
                                    className="text-light-10 inline-block"
                                >
                                    resend confirmation mail
                                </Button>
                            </p> */}
                        </div>
                    </div>
                </Container>
            </div>
        </>
    );
};

export default ExpiryLink;
