export const HeadeLinks = [
    {
        id: 1,
        icon: "/images/MyiQ-icons/dashboard.svg",
        tour: "Dashboard_step",
        name: "Dashboard",
        href: "/dashboard",
        isShow: true,
    },
    {
        id: 2,
        icon: "/images/MyiQ-icons/invoice.svg",
        name: "Invoice",
        ihref: "/invoice",
        exep: "/exception",
        approve: "/approve",
        upload: "/file-upload",
        isShow: true,
    },
    // {
    //  id: 6,
    //  icon: "/images/sidebar/fileupload.svg",
    //  name: "Upload",
    //  href: "/file-upload",
    //  isShow: true,
    // },
    // {
    //     id: 3,
    //     icon:"/images/sidebar/exception.svg",
    //     name: "Exception",
    //     href: "/exception"
    // },
    // {
    //     id: 5,
    //     icon: "/images/sidebar/company.svg",
    //     name: "Organisation",
    //     href: "/organisations",
    //     isShow: true,
    // },
    // {
    //     id: 4,
    //     icon: "/images/sidebar/users.svg",
    //     name: "Users",
    //     href: "/users",
    //     isShow: true,
    // },
    {
        id: 5,
        icon: "/images/sidebar/my-task-new.svg",
        name: "Task",
        tour: "task_management_step",
        href: "/my-task/list",
        isShow: true,
    },
    {
        id: 4,
        icon: "/images/MyiQ-icons/inbox.svg",
        name: "Inbox",
        tour: "inbox_step",
        href: "/inbox-config",
        isShow: true,
    },

    // {
    //     id: 7,
    //     icon:"/images/sidebar/config.svg",
    //     name: "Config",
    //     href: "#!",
    // },
    {
        id: 8,
        darkMode: "",
        icon: "/images/MyiQ-icons/month-end.svg",
        name: "Month End",
        aref: "/accrual-secondary",
        ihref: "/month-closing",
        isShow: true,
    },
    {
        id: 9,
        darkMode: "",
        icon: "/images/MyiQ-icons/reports.svg",
        name: "Reports",
        tour: "reports_step",
        href: "/reports",
    },
    // {
    //     id: 10,
    //     icon: "/images/sidebar/ticket-icon.svg",
    //     name: "Support",
    //     href: "#!",
    //     isShow: true,
    // },
];

export const DummyOrg = [
    {
        company: "iQuantM Technologies",
        url: " www.iquantm.com",
        country: "United Kingdom",
    },
    {
        company: "IQ",
        url: " www.iquantm.com",
        country: "United Kingdom",
    },
    {
        company: "IQAI",
        url: " www.iquantm.com",
        country: "United Kingdom",
    },
];

export const InboxLinks = [
    {
        img: "/images/inbox/inbox.svg",
        text: "Inbox",
    },
    {
        img: "/images/inbox/star-inbox.svg",
        text: "Starred",
    },
    {
        img: "/images/inbox/send.svg",
        text: "Sent",
    },
    {
        img: "/images/inbox/file.svg",
        text: "Drafts",
    },
    {
        img: "/images/inbox/alert-octagon.svg",
        text: "Spam",
    },
    {
        img: "/images/inbox/trash-2.svg",
        text: "Trash",
    },
];

export const EMailShareLinks = [
    {
        img: "/images/main/link-list.svg",
        hovertext: "Copy email link",
    },
    {
        img: "/images/main/approve-list.svg",
        hovertext: "Complete Task",
    },
    // {
    //   img: "/images/main/add-list.svg",
    //   hovertext: "Add Task",
    // },
    {
        img: "/images/main/file-list.svg",
        hovertext: "View Invoice",
    },
    // {
    //   img: "/images/main/addfile-list.svg",
    //   hovertext: "Attachment",
    // },
    {
        img: "/images/main/download-list.svg",
        hovertext: "Download",
    },
];

export const columns = [
    {
        id: 1,
        title: "Invoice",
        parent: false,
        content: [
            { id: 1, label: "Invoice No.", checked: false },
            { id: 2, label: "Invoice Date", checked: false },
            { id: 3, label: "PO.No.", checked: false },
            { id: 4, label: "PO.Date", checked: false },
        ],
    },
    {
        id: 2,
        title: "Organization",
        parent: false,
        content: [
            { id: 5, label: "Email", checked: false },
            { id: 6, label: "Folder", checked: false },
            { id: 7, label: "Target", checked: false },
        ],
    },
    {
        id: 3,
        title: "Vendor",
        parent: false,
        content: [
            { id: 8, label: "Vendor Name", checked: false },
            { id: 9, label: "Vendor No", checked: false },
        ],
    },
    {
        id: 4,
        title: "Vendor Bank",
        parent: false,
        content: [
            { id: 10, label: "Account No", checked: false },
            { id: 11, label: "Amount", checked: false },
            { id: 12, label: "Bank Name", checked: false },
        ],
    },
    {
        id: 5,
        title: "Line Items",
        parent: false,
        content: [
            { id: 13, label: "Tax %", checked: false },
            { id: 14, label: "Tax Amount", checked: false },
            { id: 15, label: "Quantity", checked: false },
        ],
    },
];

export const DateFilterConfig = [
    {
        label: "Today",
        id: 1,
    },
    {
        label: "Last 7th day",
        id: 2,
    },
    {
        label: "Last 15th day",
        id: 3,
    },
    {
        label: "Last 30th day",
        id: 4,
    },
    {
        label: "Last 45th day",
        id: 5,
    },
    {
        label: "Last 60th day",
        id: 6,
    },
    {
        label: "Last 75th day",
        id: 7,
    },
    {
        label: "Last 90th day",
        id: 8,
    },
    {
        label: "This Month First",
        id: 9,
    },
    {
        label: "This Month End",
        id: 10,
    },
    {
        label: "FY Start Date",
        id: 11,
    },
    {
        label: "FY End Date",
        id: 12,
    },
];

export const DaysRange = [
    {
        imgNormal: "/images/reports/sun-normal.svg",
        imgActive: "/images/reports/sun-active.svg",
        value: 1,
        text: "Sunday",
    },
    {
        imgNormal: "/images/reports/mon-normal.svg",
        imgActive: "/images/reports/mon-active.svg",
        value: 2,
        text: "Monday",
    },
    {
        imgNormal: "/images/reports/tue-normal.svg",
        imgActive: "/images/reports/tue-active.svg",
        value: 3,
        text: "Tuesday",
    },
    {
        imgNormal: "/images/reports/wed-normal.svg",
        imgActive: "/images/reports/wed-active.svg",
        value: 4,
        text: "Wednesday",
    },
    {
        imgNormal: "/images/reports/tue-normal.svg",
        imgActive: "/images/reports/tue-active.svg",
        value: 5,
        text: "Thursday",
    },
    {
        imgNormal: "/images/reports/fri-normal.svg",
        imgActive: "/images/reports/fri-active.svg",
        value: 6,
        text: "Friday",
    },
    {
        imgNormal: "/images/reports/sun-normal.svg",
        imgActive: "/images/reports/sun-active.svg",
        value: 7,
        text: "Saturday",
    },
];

export const Operators = ["=", ">=", "<", "<=", ">", "Between"];
export const NumOperators = ["=", ">=", "<", "<=", ">"];
export const Matcher = ["Starts with", "Contains", "=", "Ends with"];
export const months = [
    { monthName: "January", val: 1 },
    { monthName: "February", val: 2 },
    { monthName: "March", val: 3 },
    { monthName: "April", val: 4 },
    { monthName: "May", val: 5 },
    { monthName: "June", val: 6 },
    { monthName: "July", val: 7 },
    { monthName: "August", val: 8 },
    { monthName: "September", val: 9 },
    { monthName: "October", val: 10 },
    { monthName: "November", val: 11 },
    { monthName: "December", val: 12 },
];

export const Days = [
    { dayName: "Sunday", val: 1 },
    { dayName: "Monday", val: 2 },
    { dayName: "Tuesday", val: 3 },
    { dayName: "Wednesday", val: 4 },
    { dayName: "Thursday", val: 5 },
    { dayName: "Friday", val: 6 },
    { dayName: "Saturday", val: 7 },
];
export const starting = [
    { start: "First", val: 1 },
    { start: "Second", val: 2 },
    { start: "Third", val: 3 },
    { start: "Fourth", val: 4 },
    { start: "Last", val: 5 },
];
