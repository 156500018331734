import {
	PROFILE_EDIT_FAILURE,
	PROFILE_EDIT_REQUEST,
	PROFILE_EDIT_RESET,
	PROFILE_EDIT_SUCCESS,
	PROFILE_VIEW_FAILURE,
	PROFILE_VIEW_REQUEST,
	PROFILE_VIEW_RESET,
	PROFILE_VIEW_SUCCESS,
	PROFILE_DELETE_FAILURE,
	PROFILE_DELETE_REQUEST,
	PROFILE_DELETE_SUCCESS,
	PROFILE_DELETE_RESET,
} from "../types/types";

const intAuthState = {
	profile: {},
	fetching: false,
	requestComplete: false,
	error: "",
};

export function profileView(state = intAuthState, action) {
	switch (action.type) {
		case PROFILE_VIEW_REQUEST:
			return {
				...state,
				fetching: true,
				requestComplete: false,
				error: "",
			};
		case PROFILE_VIEW_SUCCESS:
			return {
				profile: action.payload,
				fetching: false,
				requestComplete: true,
				error: "",
			};
		case PROFILE_VIEW_RESET:
			return {
				profile: {},
				fetching: false,
				requestComplete: false,
				error: "",
			};
		case PROFILE_VIEW_FAILURE:
			return {
				fetching: false,
				requestComplete: false,
				error: action.payload,
			};
		default:
			return state;
	}
}
export function Profileedit(state = intAuthState, action) {
	switch (action.type) {
		case PROFILE_EDIT_REQUEST:
			return {
				...state,
				fetching: true,
				requestComplete: false,
				error: "",
			};
		case PROFILE_EDIT_SUCCESS:
			return {
				profile: action.payload,
				fetching: false,
				requestComplete: true,
				error: "",
			};
		case PROFILE_EDIT_RESET:
			return {
				profile: {},
				fetching: false,
				requestComplete: false,
				error: "",
			};
		case PROFILE_EDIT_FAILURE:
			return {
				fetching: false,
				requestComplete: false,
				error: action.payload,
			};
		default:
			return state;
	}
}

export function ProfileDeleteRedux(state = intAuthState, action) {
	switch (action.type) {
		case PROFILE_DELETE_REQUEST:
			return {
				...state,
				fetching: true,
				requestComplete: false,
				error: "",
			};
		case PROFILE_DELETE_SUCCESS:
			return {
				profile: action.payload,
				fetching: false,
				requestComplete: true,
				error: "",
			};
		case PROFILE_DELETE_RESET:
			return {
				profile: {},
				fetching: false,
				requestComplete: false,
				error: "",
			};
		case PROFILE_DELETE_FAILURE:
			return {
				fetching: false,
				requestComplete: false,
				error: action.payload,
			};
		default:
			return state;
	}
}
