import React, { useEffect, useState, useRef, useContext } from "react";
import ReactDOM from "react-dom";
import Card from "../../../../components/UI/Card";
import { ThemeContext } from "../../../../Hooks/Themes/Themeprovider";
import { ViewTask } from "../../../MyTask/MyTaskList/ViewTask";
import { InvoiceViewTask } from "./ViewTask";

const Kanban = (props) => {
    const [viewTask, setViewTask] = useState(false);
    const { currentMode } = useContext(ThemeContext);
    const [getId, setId] = useState("");
    const KanbanCard = ({ elem }) => {
        return (
            <Card className={`flex-row p-[20px]  rounded-[10px] mb-2 bg-${currentMode}-card text-${currentMode}-copy border-${currentMode}-700 ${currentMode === "dark" ? '' : 'shadow-[0px_4px_20px_rgba(238,238,238,0.5)]'}  items-center hover:bg-${currentMode}-960`}>
                <span
                    className="cursor-pointer"
                    title="Click to view task"
                    onClick={() => {
                        setViewTask(true);
                        setId(elem?.task_id);
                    }}
                >
                    <div className="flex justify-between min-w-[300px]">
                        <div className="flex items-center space-x-2">
                            <button
                                className={`border-0 whitespace-nowrap rounded-[2px] font-thin text-[12px]  text-${currentMode}-980`}
                            >
                                {elem?.task_no}
                            </button>
                            <button
                                className={`border-[0] font-interM  rounded-[2px] text-[12px] px-2 text-white ${elem?.task_priority === "High"
                                    ? "bg-[rgba(255,114,94,1)]"
                                    : elem?.task_priority === "Low"
                                        ? "bg-[rgba(0,177,162,1)]"
                                        : elem?.task_priority === "Medium"
                                            ? "bg-[rgba(251,189,3,1)]"
                                            : elem?.task_priority === "Urgent"
                                                ? "bg-[rgba(215,13,13,1)]"
                                                : ""
                                    }`}
                            >
                                {elem?.task_priority}
                            </button>
                            <div>
                                <button
                                    className={`border-0 font-interM whitespace-nowrap px-2 py-[.5px] bg-[rgba(158,231,227,1)] rounded-[2px] text-black text-[12px]`}
                                >
                                    {elem?.task_status == 1
                                        ? "In Progress"
                                        : elem?.task_status == 3
                                            ? "Done"
                                            : elem?.task_status == 4
                                                ? "Assign"
                                                : "Completed"}
                                </button>
                                <button
                                    className={`border-[0] ml-[6px] whitespace-nowrap px-2 rounded-[2px]  mx-auto text-[12px]  text-white ${elem?.task_type === 1
                                        ? "bg-[rgba(94,101,174,1)]"
                                        : "bg-[rgba(1,148,186,1)]"
                                        }`}
                                >
                                    {elem?.task_type === 1
                                        ? "Internal"
                                        : "External"}
                                </button>
                            </div>
                        </div>
                        {/* <img
                            className="cursor-pointer"
                            src="/images/main/vertical-hamburger.svg"
                            alt="task-icon"
                        /> */}
                    </div>
                    <div className="flex mt-3 items-center">
                        <img
                            src={
                                elem?.task_status == 2
                                    ? "/images/main/task-complete.svg"
                                    : "/images/main/circle.svg"
                            }
                            className="convertWhite"
                        />
                        <span className="ml-2 flex-initial cursor-pointer">
                            Invoice Number: {elem?.invoice_no} - {elem?.task_name}
                        </span>
                    </div>
                    <div className="flex justify-between items-center mt-3">
                        <div className="flex basis-[80%]">
                            {/* <img
              className="flex-initial"
              src="/images/main/userNew.svg"
              alt="userNew"
            /> */}
                            <img
                                className="ml-3 convertWhite"
                                src="/images/invoice/calendar.svg"
                                alt="calendar"
                            />
                            <span className="flex my-auto ml-1">
                                {elem?.due_date.slice(0, -11)}
                            </span>
                        </div>
                        {elem?.total_count > 0 && (
                            <div className="flex item-center items-center mt-2 justify-between">
                                <span className="">{elem?.total_count}</span>
                                <img
                                    className="mx-1 cursor-pointer convertWhite"
                                    src="/images/main/subtask.svg"
                                    alt="subtask-icon"
                                    onClick={() => {
                                        setViewTask(true);
                                        setId(elem?.task_id);
                                    }}
                                />
                            </div>
                        )}
                    </div>
                </span>
            </Card>
        );
    };
    return (
        <>
            {props?.elem?.pendingWithMe?.length === 0 &&
                props?.elem?.pendingWithTeam?.length === 0 &&
                props?.elem?.awaitingResponse?.length === 0 &&
                props?.elem?.completedTask?.length === 0 ? (
                <div className=" text-center">
                    <div className="mx-auto my-5">
                        <img
                            className="mt-[100px] mx-auto"
                            src="/images/common/delivery.png"
                            alt="delivery"
                        />
                    </div>
                    <h4 className="font-interSb">No Data found</h4>
                </div>
            ) : (
                <div className="flex space-x-[30px] mx-[30px] mb-[3rem]">
                    {props?.elem?.pendingWithMe.length > 0 && (
                        <div className={`py-[13px] rounded-[8px] px-[10px] bg-${currentMode}-990`}>
                            <span className="block mb-2 font-interM">
                                Pending with me
                            </span>
                            {props?.elem?.pendingWithMe?.length !== 0 ? (
                                props?.elem?.pendingWithMe.map((item) => (
                                    <KanbanCard elem={item} />
                                ))
                            ) : (
                                <div className=" text-center">
                                    <div className="mx-auto my-5">
                                        <img
                                            className="mt-[100px] mx-auto"
                                            src="/images/common/delivery.png"
                                            alt="delivery"
                                        />
                                    </div>
                                    <h4 className="font-interSb">
                                        No Data found
                                    </h4>
                                </div>
                            )}
                        </div>
                    )}

                    {props?.elem?.pendingWithTeam.length > 0 && (
                        <div className={`py-[13px] rounded-[8px] px-[10px] bg-${currentMode}-990`}>
                            <span className="block mb-2 font-interM">
                                Pending with team
                            </span>
                            {props?.elem?.pendingWithTeam?.length !== 0 ? (
                                props?.elem?.pendingWithTeam?.map((item) => (
                                    <KanbanCard elem={item} />
                                ))
                            ) : (
                                <div className=" text-center">
                                    <div className="mx-auto my-5">
                                        <img
                                            className="mt-[100px] mx-auto"
                                            src="/images/common/delivery.png"
                                            alt="delivery"
                                        />
                                    </div>
                                    <h4 className="font-interSb">
                                        No Data found
                                    </h4>
                                </div>
                            )}
                        </div>
                    )}

                    {props?.elem?.awaitingResponse.length > 0 && (
                        <div className={`py-[13px] rounded-[8px] px-[10px] bg-${currentMode}-990`}>
                            <span className="block mb-2 font-interM">
                                Awaiting response
                            </span>
                            {props?.elem?.awaitingResponse?.length !== 0 ? (
                                props?.elem?.awaitingResponse?.map((item) => (
                                    <KanbanCard elem={item} />
                                ))
                            ) : (
                                <div className=" text-center">
                                    <div className="mx-auto my-5">
                                        <img
                                            className="mt-[100px] mx-auto"
                                            src="/images/common/delivery.png"
                                            alt="delivery"
                                        />
                                    </div>
                                    <h4 className="font-interSb">
                                        No Data found
                                    </h4>
                                </div>
                            )}
                        </div>
                    )}
                    {props?.elem?.completedTask.length > 0 && (
                        <div className="py-[13px] rounded-[8px] px-[10px] bg-[rgba(244,245,247,0.3)]">
                            <span className="block mb-2 font-interM">
                                Completed Task
                            </span>
                            {props?.elem?.completedTask?.length !== 0 ? (
                                props?.elem?.completedTask?.map((item) => (
                                    <KanbanCard elem={item} />
                                ))
                            ) : (
                                <div className=" text-center">
                                    <div className="mx-auto my-5">
                                        <img
                                            className="mt-[100px] mx-auto"
                                            src="/images/common/delivery.png"
                                            alt="delivery"
                                        />
                                    </div>
                                    <h4 className="font-interSb">
                                        No Data found
                                    </h4>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            )}
            {ReactDOM.createPortal(
                <InvoiceViewTask
                    isOpened={viewTask}
                    handleClose={() => setViewTask(false)}
                    idParse={getId}
                    className={viewTask && "Show"}
                />,
                document.getElementById("Modal-root")
            )}
        </>
    );
};
export default Kanban;
