import {
    ADD_REPORTS_FAV_FAILURE,
    GET_TASK_ID_FAILURE,
    GET_TASK_ID_RESET,
    GET_TASK_ID_SUCCESS,
    GET_TASK_ID_REQUEST,
    GET_PRIORITY_CONFIG_FAILURE,
    GET_PRIORITY_CONFIG_RESET,
    GET_PRIORITY_CONFIG_SUCCESS,
    GET_PRIORITY_CONFIG_REQUEST,
    POST_TASK_ID_FAILURE,
    POST_TASK_ID_RESET,
    POST_TASK_ID_SUCCESS,
    POST_TASK_ID_REQUEST,
    POST_PRIORITY_CONFIG_FAILURE,
    POST_PRIORITY_CONFIG_RESET,
    POST_PRIORITY_CONFIG_SUCCESS,
    POST_PRIORITY_CONFIG_REQUEST,
    GET_MY_TASK_LIST_REQUEST,
    GET_MY_TASK_LIST_SUCCESS,
    GET_MY_TASK_LIST_RESET,
    GET_MY_TASK_LIST_FAILURE,
    GET_INVOICE_TASK_USERDROPDOWN_REQUEST,
    GET_INVOICE_TASK_USERDROPDOWN_SUCCESS,
    GET_INVOICE_TASK_USERDROPDOWN_RESET,
    GET_INVOICE_TASK_USERDROPDOWN_FAILURE,
    Add_INVOICE_TODO_CREATE_TASK_REQUEST,
    Add_INVOICE_TODO_CREATE_TASK_SUCCESS,
    Add_INVOICE_TODO_CREATE_TASK_RESET,
    Add_INVOICE_TODO_CREATE_TASK_FAILURE,
    GET_TASK_FAILURE,
    GET_TASK_REQUEST,
    GET_TASK_SUCCESS,
    GET_TASK_RESET,
    POST_COMMENT_FAILURE,
    POST_COMMENT_REQUEST,
    POST_COMMENT_RESET,
    POST_COMMENT_SUCCESS,
    POST_COMPLETE_TASK_REQUEST,
    POST_COMPLETE_TASK_SUCCESS,
    POST_COMPLETE_TASK_RESET,
    GET_INVOICE_TASK_LIST_REQUEST,
    GET_INVOICE_TASK_LIST_RESET,
    GET_INVOICE_TASK_LIST_SUCCESS,
    GET_INVOICE_TASK_LIST_FAILURE,
    GET_VIEW_TASK_ATTACHMENT_REQUEST,
    GET_VIEW_TASK_ATTACHMENT_FAILURE,
    GET_VIEW_TASK_ATTACHMENT_SUCCESS,
    GET_VIEW_TASK_ATTACHMENT_RESET,
} from "../types/types";

const getTaskIdState = {
    dataList: [],
    fetching: false,
    requestComplete: false,
    error: "",
};

export function taskNumberConfigList(state = getTaskIdState, action) {
    switch (action.type) {
        case GET_TASK_ID_REQUEST:
            return {
                ...state,
                fetching: true,
                requestComplete: false,
                error: "",
            };
        case GET_TASK_ID_SUCCESS:
            return {
                dataList: action.payload,
                fetching: false,
                requestComplete: true,
                error: "",
            };
        case GET_TASK_ID_RESET:
            return {
                dataList: [],
                fetching: false,
                requestComplete: false,
                error: "",
            };
        case GET_TASK_ID_FAILURE:
            return {
                fetching: false,
                requestComplete: false,
                error: action.payload,
            };
        default:
            return state;
    }
}

const getPriorityConfigState = {
    dataList: [],
    fetching: false,
    requestComplete: false,
    error: "",
};

export function priorityConfigList(state = getPriorityConfigState, action) {
    switch (action.type) {
        case GET_PRIORITY_CONFIG_REQUEST:
            return {
                ...state,
                fetching: true,
                requestComplete: false,
                error: "",
            };
        case GET_PRIORITY_CONFIG_SUCCESS:
            return {
                dataList: action.payload,
                fetching: false,
                requestComplete: true,
                error: "",
            };
        case GET_PRIORITY_CONFIG_RESET:
            return {
                dataList: [],
                fetching: false,
                requestComplete: false,
                error: "",
            };
        case GET_PRIORITY_CONFIG_FAILURE:
            return {
                fetching: false,
                requestComplete: false,
                error: action.payload,
            };
        default:
            return state;
    }
}

const addTaskConfigState = {
    data: null,
    fetching: false,
    requestComplete: false,
    error: "",
};

export function addTaskIdConfig(state = addTaskConfigState, action) {
    switch (action.type) {
        case POST_TASK_ID_REQUEST:
            return {
                ...state,
                fetching: true,
                requestComplete: false,
                error: "",
            };
        case POST_TASK_ID_SUCCESS:
            return {
                data: action.payload,
                fetching: false,
                requestComplete: true,
                error: "",
            };
        case POST_TASK_ID_RESET:
            return {
                data: null,
                fetching: false,
                requestComplete: false,
                error: "",
            };
        case POST_TASK_ID_FAILURE:
            return {
                fetching: false,
                requestComplete: false,
                error: action.payload,
            };
        default:
            return state;
    }
}

const addPriorityConfigState = {
    data: null,
    fetching: false,
    requestComplete: false,
    error: "",
};

export function addPriorityConfig(state = addPriorityConfigState, action) {
    switch (action.type) {
        case POST_PRIORITY_CONFIG_REQUEST:
            return {
                ...state,
                fetching: true,
                requestComplete: false,
                error: "",
            };
        case POST_PRIORITY_CONFIG_SUCCESS:
            return {
                data: action.payload,
                fetching: false,
                requestComplete: true,
                error: "",
            };
        case POST_PRIORITY_CONFIG_RESET:
            return {
                data: null,
                fetching: false,
                requestComplete: false,
                error: "",
            };
        case POST_PRIORITY_CONFIG_FAILURE:
            return {
                fetching: false,
                requestComplete: false,
                error: action.payload,
            };
        default:
            return state;
    }
}

const getMyTaskListState = {
    dataList: [],
    fetching: false,
    requestComplete: false,
    error: "",
};

export function myTaskList(state = getMyTaskListState, action) {
    switch (action.type) {
        case GET_MY_TASK_LIST_REQUEST:
            return {
                ...state,
                fetching: true,
                requestComplete: false,
                error: "",
            };
        case GET_MY_TASK_LIST_SUCCESS:
            return {
                dataList: action.payload,
                fetching: false,
                requestComplete: true,
                error: "",
            };
        case GET_MY_TASK_LIST_RESET:
            return {
                dataList: [],
                fetching: false,
                requestComplete: false,
                error: "",
            };
        case GET_MY_TASK_LIST_FAILURE:
            return {
                fetching: false,
                requestComplete: false,
                error: action.payload,
            };
        default:
            return state;
    }
}

const getTaskState = {
    dataList: [],
    fetching: false,
    requestComplete: false,
    error: "",
};

export function getTask(state = getTaskState, action) {
    switch (action.type) {
        case GET_TASK_REQUEST:
            return {
                ...state,
                fetching: true,
                requestComplete: false,
                error: "",
            };
        case GET_TASK_SUCCESS:
            return {
                dataList: action.payload?.datalist,
                dataimg: action.payload?.dataimg,
                fetching: false,
                requestComplete: true,
                error: "",
            };
        case GET_TASK_RESET:
            return {
                dataList: [],
                fetching: false,
                requestComplete: false,
                error: "",
            };
        case GET_TASK_FAILURE:
            return {
                fetching: false,
                requestComplete: false,
                error: action.payload,
            };
        default:
            return state;
    }
}

const addCommentsState = {
    data: null,
    fetching: false,
    requestComplete: false,
    error: "",
};

export function addCommentsTask(state = addCommentsState, action) {
    switch (action.type) {
        case POST_COMMENT_REQUEST:
            return {
                ...state,
                fetching: true,
                requestComplete: false,
                error: "",
            };
        case POST_COMMENT_SUCCESS:
            return {
                data: action.payload,
                fetching: false,
                requestComplete: true,
                error: "",
            };
        case POST_COMMENT_RESET:
            return {
                data: null,
                fetching: false,
                requestComplete: false,
                error: "",
            };
        case POST_COMMENT_FAILURE:
            return {
                fetching: false,
                requestComplete: false,
                error: action.payload,
            };
        default:
            return state;
    }
}
//POST COMPLETE TASK

const addComplateTaskState = {
    data: null,
    fetching: false,
    requestComplete: false,
    error: "",
};

export function postCompleteTask(state = addComplateTaskState, action) {
    switch (action.type) {
        case POST_COMPLETE_TASK_REQUEST:
            return {
                ...state,
                fetching: true,
                requestComplete: false,
                error: "",
            };
        case POST_COMPLETE_TASK_SUCCESS:
            return {
                data: action.payload,
                fetching: false,
                requestComplete: true,
                error: "",
            };
        case POST_COMPLETE_TASK_RESET:
            return {
                data: null,
                fetching: false,
                requestComplete: false,
                error: "",
            };
        case POST_COMPLETE_TASK_SUCCESS:
            return {
                fetching: false,
                requestComplete: false,
                error: action.payload,
            };
        default:
            return state;
    }
}

const getToDoTaskUserListState = {
    dataList: null,
    fetching: false,
    requestComplete: false,
    error: "",
};
export function myTaskUserList(state = getToDoTaskUserListState, action) {
    switch (action.type) {
        case GET_INVOICE_TASK_USERDROPDOWN_REQUEST:
            return {
                ...state,
                fetching: true,
                requestComplete: false,
                error: "",
            };
        case GET_INVOICE_TASK_USERDROPDOWN_SUCCESS:
            return {
                dataList: action.payload,
                fetching: false,
                requestComplete: true,
                error: "",
            };
        case GET_INVOICE_TASK_USERDROPDOWN_RESET:
            return {
                dataList: [],
                fetching: false,
                requestComplete: false,
                error: "",
            };
        case GET_INVOICE_TASK_USERDROPDOWN_FAILURE:
            return {
                fetching: false,
                requestComplete: false,
                error: action.payload,
            };
        default:
            return state;
    }
}

//Add_INVOICE_TODO_CREATE_TASK

const addMyTaskCreateToDoState = {
    data: null,
    fetching: false,
    requestComplete: false,
    error: "",
};

export function addMyTaskCreateToDo(state = addMyTaskCreateToDoState, action) {
    switch (action.type) {
        case Add_INVOICE_TODO_CREATE_TASK_REQUEST:
            return {
                ...state,
                fetching: true,
                requestComplete: false,
                error: "",
            };
        case Add_INVOICE_TODO_CREATE_TASK_SUCCESS:
            return {
                data: action.payload,
                fetching: false,
                requestComplete: true,
                error: "",
            };
        case Add_INVOICE_TODO_CREATE_TASK_RESET:
            return {
                data: null,
                fetching: false,
                requestComplete: false,
                error: "",
            };
        case Add_INVOICE_TODO_CREATE_TASK_FAILURE:
            return {
                fetching: false,
                requestComplete: false,
                error: action.payload,
            };
        default:
            return state;
    }
}

//GET INVOICE TASK LIST BY INVOICE
const getInvoiceTaskListState = {
    dataList: null,
    fetching: false,
    requestComplete: false,
    error: "",
};
export function getInvoiceTaskList(state = getInvoiceTaskListState, action) {
    switch (action.type) {
        case GET_INVOICE_TASK_LIST_REQUEST:
            return {
                ...state,
                fetching: true,
                requestComplete: false,
                error: "",
            };
        case GET_INVOICE_TASK_LIST_SUCCESS:
            return {
                dataList: action.payload,
                fetching: false,
                requestComplete: true,
                error: "",
            };
        case GET_INVOICE_TASK_LIST_RESET:
            return {
                dataList: [],
                fetching: false,
                requestComplete: false,
                error: "",
            };
        case GET_INVOICE_TASK_LIST_FAILURE:
            return {
                fetching: false,
                requestComplete: false,
                error: action.payload,
            };
        default:
            return state;
    }
}

//GET VIEW TASK ATTACHMENTS
const getViewTaskAttachmentState = {
    dataList: null,
    fetching: false,
    requestComplete: false,
    error: "",
};
export function getViewTaskAttachment(
    state = getViewTaskAttachmentState,
    action
) {
    switch (action.type) {
        case GET_VIEW_TASK_ATTACHMENT_REQUEST:
            return {
                ...state,
                fetching: true,
                requestComplete: false,
                error: "",
            };
        case GET_VIEW_TASK_ATTACHMENT_SUCCESS:
            return {
                dataList: action.payload,
                fetching: false,
                requestComplete: true,
                error: "",
            };
        case GET_VIEW_TASK_ATTACHMENT_RESET:
            return {
                dataList: null,
                fetching: false,
                requestComplete: false,
                error: "",
            };
        case GET_VIEW_TASK_ATTACHMENT_FAILURE:
            return {
                fetching: false,
                requestComplete: false,
                error: action.payload,
            };
        default:
            return state;
    }
}
