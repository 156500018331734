import { useContext, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import Container from "../../components/UI/Container";
import { ThemeContext } from "../../Hooks/Themes/Themeprovider";
import Content from "../../organisms/SignUp/Content";
import Form from "../../organisms/SignUp/Form";

const SignUp = () => {
    const location = useLocation();
    const { currentMode } = useContext(ThemeContext);
    // useEffect(() => {
    //     let bodys = document.querySelector("body").classList;
    //     location.pathname === "/signup" &&
    //         bodys.remove(`bg-${currentMode}-190`);
    // }, [location.pathname]);
    return (
        <div className="WrapCenter">
            <Container>
                <div className="mt-4 mb-7">
                    <Link
                        to={"//www.myiq.ai/"}
                        className="inline-block"
                        target="_blank"
                    >
                        <img
                            src="/images/logo/MyiqFinal.svg"
                            alt="logoiq"
                            className="w-[100px] h-[75px]"
                        />
                    </Link>
                </div>
                <div className="flex flex-col-reverse items-center lg:flex-row">
                    <div className="lg:w-[52%] pb-7">
                        <Content />
                    </div>
                    <div className="lg:w-[45%] w-full pb-7 xl:mt-[-6rem] xl:pl-[5rem] border-[#55555526] xl:border-l-[1px] lg:ml-auto">
                        <Form />
                    </div>
                </div>
            </Container>
        </div>
    );
};

export default SignUp;
