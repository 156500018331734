import { Checkbox } from "@mui/material";
import Input from "../../../components/Input";
import { Link } from "react-router-dom";
import Button from "../../../components/Button";
import Card from "../../../components/UI/Card";
import Main from "../../../components/UI/Main";
import Layout from "../../../Hoc/Layout";
import Footer from "../../../organisms/Footer";
import styles from "./index";
import ReactDOM from "react-dom";
import { useDispatch, useSelector } from "react-redux";
import { FormControl, MenuItem, Select, Popper, ClickAwayListener, Tooltip } from "@mui/material";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { InvoiceLabelLayoutAdd, InvoiceLabelLayoutList } from "../../../redux/actions/invoice";
import { getLayoutCountryList } from "../../../redux/actions/layout.js";
import { useState, useEffect, useContext } from "react";
import SuccessMessageBox from "../../../components/UI/Alert/sucess";
import MessageBox from "../../../components/UI/Alert";
import { INVOICE_LABEL_LAYOUT_ADD_RESET } from "../../../redux/types/types";
import Loader from "../../../components/Loader";
import { styled } from "@mui/material/styles";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Swal from "sweetalert2";
import { ThemeContext } from "../../../Hooks/Themes/Themeprovider";
import EditBox from "../../../components/UserCard/EditBox";
import { findDuplicate, generateFormattedText } from "../../../utils/helpers.js";
import AlertLayout from "./LayoutConfirm";
import swal from "sweetalert";
import InputField from "./InputField.js";
const _ = require("lodash"); // Import lodash library
let defaultVal = "";

const InvoiceLayout = () => {
    const { currentMode, toggleMode } = useContext(ThemeContext);
    const [expandedTax, setExpandedTax] = useState("3");
    const [InvoiceItems, setInvoiceItems] = useState([]);
    const [alertOpen, setalertOpen] = useState(false);
    const [LineItems, setLineItems] = useState([]);
    const layoutCountryId = localStorage.getItem("layoutCountryId");
    const [countryVal, setCountryVal] = useState(0);
    const [popstate, setPopState] = useState({ id: null, anchorEl: false });
    const [updatedArr, setUpdatedArr] = useState([]);
    const [invoiceData, setInvoiceData] = useState([]);
    const [ErrodataOpen, setErrorDataOpen] = useState(false);
    const LayoutList = useSelector((state) => state?.InvoiceLabelLayoutListState);
    const { data: dataList, requestComplete: labelReqComp } = LayoutList;
    const [layoutItems, setLayoutItems] = useState(dataList);
    const [displayorder, setdisplayorder] = useState("");
    const [maxlength, setmaxlength] = useState("");
    const [fieldType, setFieldType] = useState("");
    const [isEdit, setIsEdit] = useState(false);
    const [ids, setIds] = useState({
        displayOrder: "",
        mxLength: "",
        invMax: "",
        displayName: "",
    });
    const [placement, setPlacement] = useState();
    const [displayName, setDisplayName] = useState("");
    const [expanded, setExpanded] = useState("1");
    const Accordion = styled((props) => <MuiAccordion disableGutters elevation={0} square {...props} />)(({ theme }) => ({
        border: `0`,
        "&:not(:last-child)": {
            borderBottom: 0,
        },
        "&:before": {
            display: "none",
        },
    }));
    const handleClickAway = (e) => {
        setPopState({ id: null, anchorEl: false });
    };
    const AccordionSummary = styled((props) => (
        <MuiAccordionSummary
            expandIcon={
                <img
                    src={`${
                        props?.unique
                            ? `${currentMode == "dark" ? "/images/header/chevronDown-white.svg" : "/images/invoice/chevron-down.svg"}`
                            : `/images/common/accordion-active.svg`
                    }`}
                    alt="accordion-active"
                />
            }
            {...props}
        />
    ))((data) => ({
        padding: "11px 30px",
        margin: 0,
        flexDirection: "row-reverse",
        "& .MuiAccordionSummary-expandIconWrapper": {
            marginRight: 10,
            transform: data?.unique ? "rotate(180deg)" : "rotate(270deg)",
            filter: `${currentMode === "dark" ? "filter: brightness(0) invert(1)" : "brightness(0) invert(0)"}`,
        },
        "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
            transform: "rotate(360deg)",
            filter: "none",
        },
        "& .MuiAccordionSummary-content": {
            margin: 0,
        },
    }));
    const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
        padding: 0,
        border: 0,
    }));
    const dispatch = useDispatch();
    const { datalist: countryList } = useSelector((state) => state?.layoutCountryListState);
    const [copiedCountry, setCopiedCountry] = useState("");
    const succesMsg = "Updated successfully";
    const findCopiedCountry = countryList?.find((el) => el?.country_id === +copiedCountry)?.country_name;
    const copiedMsg = `Copied Successfully from ${findCopiedCountry} country`;
    const errormsg = "Something went wrong please contact admin";
    const layoutUpdatedCountry = countryList?.filter((el) => el?.isconfirmed == true && el?.country_id !== 105 && el?.country_id !== +countryVal);
    const handleGetLayoutFromCountry = async (id) => {
        setCopiedCountry(id);
        dispatch(InvoiceLabelLayoutList(+id, 2, LayoutList));
    };
    const { fetching, requestComplete, error, data } = useSelector((state) => state?.InvoiceLabelLayoutAddState);

    const LineItemsData = dataList?.filter((elem) => elem?.isitem == 2);
    const InvoiceListData = dataList?.filter((elem) => elem?.isitem == 1);
    const taxItems = dataList?.filter((elem) => elem?.isitem == 3);
    const RemoveEmptyDisplayOrder = InvoiceItems?.filter((item) => item?.displayorder !== null && item?.displayorder !== 0);
    const DisplayOrder = RemoveEmptyDisplayOrder?.map((elem) => elem?.displayorder);
    const iseditableMap = new Map();
    const iseditableLineMap = new Map();
    const duplicateDisName = new Map();
    const duplicateLineDisName = new Map();

    InvoiceItems?.forEach((item) => {
        if (!iseditableMap.has(item?.displayorder)) {
            iseditableMap.set(item?.displayorder, [item?.field_display_name]);
        } else if (item?.displayorder) {
            iseditableMap.get(item?.displayorder).push(item?.field_display_name);
        }
    });
    LineItems?.forEach((item) => {
        if (!iseditableLineMap.has(item?.displayorder)) {
            iseditableLineMap.set(item?.displayorder, [item?.field_display_name]);
        } else if (item?.displayorder) {
            iseditableLineMap.get(item?.displayorder).push(item?.field_display_name);
        }
    });
    InvoiceItems?.forEach((item) => {
        const displayName = item?.field_display_name?.toLowerCase();
        if (!duplicateDisName.has(displayName)) {
            duplicateDisName.set(displayName, [displayName]);
        } else if (item?.field_display_name) {
            duplicateDisName.get(displayName).push(displayName);
        }
    });

    LineItems?.forEach((item) => {
        const displayName = item?.field_display_name?.toLowerCase();

        if (!duplicateLineDisName.has(displayName)) {
            duplicateLineDisName.set(displayName, [item?.field_display_name]);
        } else if (item?.field_display_name) {
            duplicateLineDisName.get(displayName).push(item?.field_display_name);
        }
    });
    // Find duplicates
    const duplicateNames = [];
    const DisName = [];
    const lineDisName = [];
    iseditableMap.forEach((names) => {
        if (names.length > 1) {
            duplicateNames.push(names);
        }
    });
    iseditableLineMap.forEach((names) => {
        if (names.length > 1) {
            duplicateNames.push(names);
        }
    });
    duplicateDisName.forEach((names) => {
        if (names.length > 1) {
            DisName.push(names);
        }
    });
    duplicateLineDisName.forEach((names) => {
        if (names.length > 1) {
            lineDisName.push(names);
        }
    });
    const LineItemDisplayOrder = LineItems?.filter((elem) => elem?.displayorder !== "" && elem?.displayorder !== null)?.map(
        (ite) => ite?.displayorder
    );
    const emptyDisplayorder = InvoiceItems?.filter((elem) => (elem?.displayorder == 0 || elem?.displayorder == null) && elem?.isrequired == true);
    const maxlengthcheckabove250 = dataList?.filter((elem) => elem?.maxlength > 250);
    const Mandotarycheck = dataList?.filter(
        (elem) => elem?.ismandatory && (elem?.maxlength == 0 || elem?.maxlength == null) && elem?.data_type !== "d" && elem?.field_name !== "tax"
    );
    const findEmptyDisplayName = dataList?.filter((elem) => elem?.field_display_name == "");
    //updated array render
    const IncludeCheckboxChange = (event, id, err) => {
        dataList?.forEach((element) => {
            if (id == element?.id) {
                element.isrequired = event.target.value;
            }
            if (id == element?.id && element?.isrequired == false && element?.field_name == "tax") {
                dataList?.filter((ele) => {
                    if (ele?.isitem == 3) {
                        ele.iseditable = false;
                        ele.ismandatory = false;
                        ele.isrequired = false;
                    }
                });
            }
            if (id == element?.id && element?.isrequired == false) {
                element.iseditable = false;
            }
            if (id == element?.id && element?.isrequired == false) {
                element.ismandatory = false;
            }
        });
        setLayoutItems([...layoutItems]);
    };

    const EditableCheckboxChange = (event, id) => {
        dataList?.forEach((element) => {
            if (id == element?.id) {
                element.iseditable = event.target.value;
            }
            if (id == element?.id && element?.iseditable == false) {
                element.ismandatory = false;
            }
        });
        setLayoutItems([...layoutItems]);
    };
    const dropdownColor = currentMode === "dark" ? "#333333" : `white`;
    const dropdownText = currentMode === "dark" ? "#ECECEC" : `#555555`;
    const MandatoryCheckboxChange = (event, id) => {
        dataList?.forEach((element) => {
            if (id == element?.id) {
                element.ismandatory = event.target.value;
            }
        });
        setLayoutItems([...layoutItems]);
    };
    const DisplayOrderChange = (event, id) => {
        const onlyDigits = event?.target?.value?.replace(/\D/g, "");
        setdisplayorder(+onlyDigits);
        dataList?.forEach((element) => {
            if (id == element?.id) {
                setIds({
                    displayOrder: id,
                    mxLength: "",
                    invMax: "",
                    displayName: "",
                });
                element.displayorder = +onlyDigits;
            }
        });
        // setLayoutItems([...layoutItems]);
    };
    useEffect(() => {
        if (labelReqComp && copiedCountry !== "") {
            setTimeout(() => {
                // dispatch({ type: INVOICE_LABEL_LAYOUT_LIST_RENDER_RESET });
                setCopiedCountry("");
            }, 2000);
        }
    }, [labelReqComp]);
    const MaximumLengthChange = (event, id, lineItems) => {
        const onlyDigits = event?.target?.value?.replace(/\D/g, "");
        setmaxlength(onlyDigits);
        dataList?.forEach((element) => {
            if (id == element?.id) {
                if (lineItems === "lineItems") {
                    setIds({
                        displayOrder: "",
                        mxLength: "",
                        invMax: id,
                        displayName: "",
                    });
                } else {
                    setIds({
                        displayOrder: "",
                        mxLength: id,
                        invMax: "",
                        displayName: "",
                    });
                }
                element.maxlength = onlyDigits;
            }
            if (element?.maxlength > 250) {
                Swal.fire({
                    text: ` Field Max. Length should be between 1-250!`,
                    icon: "warning",
                    closeOnClickOutside: false,
                    showCloseButton: true,
                    buttons: {
                        confirm: true,
                    },
                });
                return false;
            }
        });
        // setLayoutItems([...layoutItems]);
    };

    useEffect(() => {
        dispatch(InvoiceLabelLayoutList(countryVal == "0" || countryVal == 0 ? layoutCountryId : countryVal, 1));
        dispatch(getLayoutCountryList());
    }, [dispatch, countryVal]);
    if (countryList?.length !== 0 && (countryVal == 0 || countryVal == undefined) && (layoutCountryId == null || layoutCountryId == undefined)) {
        setCountryVal(countryList[0]?.country_id);

        localStorage.setItem("layoutCountryId", countryList[0]?.country_id);
    } else if (countryVal == 0) {
        setCountryVal(layoutCountryId);
    }
    const namesArray = Mandotarycheck?.map((item) => item?.field_display_name);
    const DisplayEmptynames = emptyDisplayorder?.map((item) => item?.field_display_name);
    const MaxLengthabove250Names = maxlengthcheckabove250?.map((item) => item?.field_display_name);
    const DupliacteSeqArry = duplicateNames?.[0] == undefined ? duplicateNames : duplicateNames?.[0];
    const DisplaySeqMulti = DisName?.[0] == undefined ? DisName : DisName?.[0];
    const LineDisplaySeqMulti = lineDisName?.[0] == undefined ? lineDisName : lineDisName?.[0];
    const uniqueArrayDisplaySeq = [...new Set(DisplaySeqMulti)];
    const uniqueArrayDisplaySeqLine = [...new Set(LineDisplaySeqMulti)];
    const MandotaryList = generateFormattedText(
        namesArray,
        "The following Max. Characters should not be empty or zero  after selecting the  mandatory fields:<br/> <br/>"
    );
    const DuplicateList = generateFormattedText(DupliacteSeqArry, "The following  duplicate values are found in the  display sequence:<br/> <br/>");
    const DisplayOrderEmptyList = generateFormattedText(DisplayEmptynames, "The following display sequence should not be empty:<br/> <br/>");
    const MaxLenghtList = generateFormattedText(
        MaxLengthabove250Names,
        "The following Max. Characters values should not be greater than 250 <br/> <br/>"
    );
    const SameDisplaySeqList = generateFormattedText(uniqueArrayDisplaySeq, " The display name cannot be same! <br/> <br/>");
    const LineDisplaySeqList = generateFormattedText(uniqueArrayDisplaySeqLine, "The display name cannot be same! <br/> <br/>");
    const OnChangeDispalyList = generateFormattedText(uniqueArrayDisplaySeq, " The display name cannot be same! <br/> <br/>");
    const handleClick = (newPlacement, id) => (event) => {
        // setAnchorEl(event.currentTarget);
        setPopState({ id, anchorEl: event.currentTarget });
        setPlacement(newPlacement);
    };
    const reqobj = {
        results: dataList,

        country: countryVal,
    };

    function submit() {
        let resultToReturn = false;
        resultToReturn = DisplayOrder?.some((element, index) => {
            return DisplayOrder?.indexOf(element) !== index;
        });
        let resultTo = false;
        resultTo = LineItemDisplayOrder.some((element, index) => {
            return LineItemDisplayOrder.indexOf(element) !== index;
        });
        if (findEmptyDisplayName?.length > 0) {
            Swal.fire({
                text: `${"Display Name cannot be empty!"}`,
                icon: "warning",
                closeOnClickOutside: false,
                showCloseButton: true,
                buttons: {
                    confirm: true,
                },
            });
            return false;
        }
        if (DisName[0]?.length > 1) {
            Swal.fire({
                // text: `${"Display Name cannot be Same"}`,
                html: SameDisplaySeqList,
                icon: "warning",
                showCloseButton: true,
                closeOnClickOutside: false,
                buttons: {
                    confirm: true,
                },
            });
            // setTimeout(function () {
            //     document.querySelector(".swal-text").innerHTML += `<br/>`;
            //     DisName?.map((itemValue, index) => {
            //         document.querySelector(
            //             ".swal-text"
            //         ).innerHTML += `<b>Invoice:</b><strong style="margin-top: 5px; display: block;">${++index}. ${itemValue}</strong>`;
            //     });
            // }, 200);

            return false;
        }
        if (lineDisName[0]?.length > 1) {
            Swal.fire({
                // text: `${"Display Name cannot be same"}`,
                html: LineDisplaySeqList,
                icon: "warning",
                showCloseButton: true,
                closeOnClickOutside: false,
                buttons: {
                    confirm: true,
                },
            });
            return false;
        }
        if (Mandotarycheck?.length > 0) {
            Swal?.fire({
                // text: ` ${MandotaryList}  Maxlength should not be empty or 0 when Mandatory field is checked`,
                icon: "warning",
                html: MandotaryList,
                closeOnClickOutside: false,
                showCloseButton: true,
                buttons: {
                    confirm: "Confirm",
                },
            });

            return false;
        }
        if (resultToReturn || resultTo) {
            Swal?.fire({
                // text: `${DuplicateList} has Duplicate Values in Display sequence`,
                html: DuplicateList,
                icon: "warning",

                closeOnClickOutside: false,

                showCloseButton: true,
                buttons: {
                    confirm: "Confirm",
                },
            });

            return false;
        }

        // if (resultTo) {

        //     Swal.fire({

        //         text: `Display Order should not have Duplicate Values in Line Items`,

        //         icon: "warning",

        //         closeOnClickOutside: false,

        //         buttons: {

        //             cancel: true,

        //             confirm: true,

        //         },

        //     });

        //     return false;

        // }

        if (!/^[0-9]*$/.test(maxlength)) {
            Swal.fire({
                text: `Invalid Maximum length`,

                icon: "warning",

                closeOnClickOutside: false,

                showCloseButton: true,
                buttons: {
                    confirm: "Confirm",
                },
            });

            return false;
        }

        if (!/^[0-9]*$/.test(displayorder)) {
            Swal.fire({
                text: `Invalid Display sequence`,

                icon: "warning",

                closeOnClickOutside: false,

                showCloseButton: true,
                buttons: {
                    confirm: "Confirm",
                },
            });

            return false;
        }

        if (emptyDisplayorder?.length > 0) {
            Swal?.fire({
                // text: ` ${DisplayOrderEmptyList} Display sequence should not be empty`,
                html: DisplayOrderEmptyList,
                icon: "warning",

                closeOnClickOutside: false,
                showCloseButton: true,
                buttons: {
                    confirm: "Confirm",
                },
            });

            return false;
        }

        if (maxlengthcheckabove250?.length > 0) {
            Swal?.fire({
                // text: ` ${MaxLenghtList} Maxmium Length should not be greater than 250`,
                html: MaxLenghtList,
                icon: "warning",

                closeOnClickOutside: false,
                showCloseButton: true,
                buttons: {
                    confirm: "Confirm",
                },
            });

            return false;
        }

        const reqStr = {
            json: JSON.stringify(reqobj),
        };
        setalertOpen(true);
        // Swal.fire({
        //     text: `Impacts of Invoice Layout modification:

        //      Is Editable, Is Mandatory, Display sequence modification will impact existing Invoice as well.

        //      Field Max Length modification will impact the Invoices modified from now on.

        //     Are you sure you want to Continue?`,
        //     icon: "warning",
        //     closeOnClickOutside: false,
        //     showCloseButton: true,
        //     showCancelButton: true,
        //     buttons: {
        //         cancel: "Cancel",
        //         confirm: "Confirm",
        //     },
        // }).then((confirm) => {
        //     if (confirm?.isConfirmed) {
        //         dispatch(InvoiceLabelLayoutAdd(reqobj));
        //     }
        // });
    }

    const EmptyMsg = (val, getValue) => {
        const value = generateFormattedText(getValue, " The display name cannot be same! <br/> <br/>");
        Swal.fire({
            html: `${val ? "Display name cannot be empty!" : value}`,
            icon: "warning",
            closeOnClickOutside: false,
            showCloseButton: true,
            buttons: {
                confirm: true,
            },
        });
        // if (val !== true) {
        //     if (disName) {
        //         setTimeout(function () {
        //             document.querySelector(".swal-text").innerHTML += `<br/>`;
        //             DisName?.map((itemValue, index) => {
        //                 document.querySelector(
        //                     ".swal-text"
        //                 ).innerHTML += `<b>Invoice:</b><strong style="margin-top: 5px; display: block;">${++index}. ${itemValue}</strong>`;
        //             });
        //         }, 200);
        //     } else if (lineDispName) {
        //         setTimeout(function () {
        //             document.querySelector(".swal-text").innerHTML += `<br/>`;
        //             lineDisName?.map((itemValue, index) => {
        //                 document.querySelector(
        //                     ".swal-text"
        //                 ).innerHTML += `<b>Line items:</b><strong style="margin-top: 5px; display: block;">${++index}. ${itemValue}</strong>`;
        //             });
        //         }, 200);
        //     }
        // }
    };

    const newUpdateUser = (label, value, id, isitem, ind) => {
        let filterArr = [];
        const itemsToSearch = isitem === 1 ? InvoiceItems : isitem === 3 ? taxItems : LineItems;
        const checkOther = isitem === 1 ? duplicateLineDisName : duplicateDisName;
        _.forEach(itemsToSearch, (item, i) => {
            if (ind === i && item.field_name === label && item.id === id) {
                item.field_display_name = value;
                setIds({
                    displayOrder: "",
                    mxLength: "",
                    invMax: "",
                    displayName: id,
                });
                filterArr = [...new Set(updatedArr), item];
                setUpdatedArr([...new Set(updatedArr), item]);
            }
        });
        setInvoiceData([...InvoiceItems]);
        const getValue = dataList?.filter((item) => item?.id != id && value?.toLowerCase() == item?.field_display_name?.toLowerCase());
        const isNamePresent = itemsToSearch?.find((item, index) => {
            if (index != ind && value?.toLowerCase() == item?.field_display_name?.toLowerCase()) {
                return item;
            }
        });
        const isLineItemNamePresent = checkOther.has(value?.toLowerCase());
        if (value == "" || getValue?.length > 0) {
            EmptyMsg(
                value == "",
                getValue?.map((item) => item?.field_display_name)
            );
            setFieldType(label);
        } else {
            const nameUpdateObj = {
                results: filterArr,
                country: countryVal,
            };
            setIsEdit(true);
            dispatch(InvoiceLabelLayoutAdd(nameUpdateObj));
            setFieldType("");
        }
    };
    const updateUser = (label, value, id) => {
        const filterArr = [...new Set(updatedArr)];
        if (value == "" || DisName[0]?.length > 1 || lineDisName[0]?.length > 1) {
            EmptyMsg(value == "", DisName[0]?.length > 1, lineDisName[0]?.length > 1);
            setFieldType(label);
        } else {
            const nameUpdateObj = {
                results: filterArr,
                country: countryVal,
            };
            setIsEdit(true);
            dispatch(InvoiceLabelLayoutAdd(nameUpdateObj));
            setFieldType("");
        }
    };

    const handleChangeLayoutName = (e, ind, id, isitem) => {
        const { name, value } = e.target;
        setDisplayName(value);
        const itemsToSearch = isitem === 1 ? InvoiceItems : isitem === 3 ? taxItems : LineItems;
        _.forEach(itemsToSearch, (item, i) => {
            if (e && ind === i && item.field_name === name && item.id === id) {
                item.field_display_name = value;
                setIds({
                    displayOrder: "",
                    mxLength: "",
                    invMax: "",
                    displayName: id,
                });
                setUpdatedArr([...new Set(updatedArr), item]);
            }
        });
        setInvoiceData([...InvoiceItems]);
    };

    const handleEdit = (id, name, initialVal) => {
        defaultVal = initialVal;
        setFieldType(name);
    };

    const closeUpdate = (label, value, id, isitem) => {
        const itemsToSearch = isitem === 1 ? InvoiceItems : isitem === 3 ? taxItems : LineItems;
        _.forEach(itemsToSearch, (item, i) => {
            if (item.field_name === label && item.id === id) {
                item.field_display_name = defaultVal;
                setIds({
                    displayOrder: "",
                    mxLength: "",
                    invMax: "",
                    displayName: id,
                });
                setUpdatedArr([...new Set(updatedArr), item]);
            }
        });
        setInvoiceData([...InvoiceItems]);
        setFieldType("");
    };

    if ((requestComplete && isEdit) || (requestComplete && !data.status && isEdit) || (error && !data?.status && isEdit)) {
        setTimeout(function () {
            setIsEdit(false);
            dispatch({ type: INVOICE_LABEL_LAYOUT_ADD_RESET });
        }, 2000);
    } else if (requestComplete || (requestComplete && !data.status) || (error && !data?.status)) {
        setTimeout(function () {
            dispatch(InvoiceLabelLayoutList(countryVal, 1));
            dispatch({ type: INVOICE_LABEL_LAYOUT_ADD_RESET });
        }, 2000);
    }

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    const handleChangeTax = (panel) => (event, newExpanded) => {
        setExpandedTax(newExpanded ? panel : null);
    };

    const ExpandLineItem = () => {
        const divElement = document?.getElementById("ListView");
        if (divElement) {
            divElement?.scrollIntoView({ behavior: "smooth" });
        }
    };

    useEffect(() => {
        if (InvoiceListData !== undefined) {
            setInvoiceItems(InvoiceListData);
            setLineItems(LineItemsData);
        }
    }, [dataList]);

    const handleCountryChange = (e) => {
        if (e !== 0) {
            setCountryVal(e);
            localStorage.setItem("layoutCountryId", e);
        }
    };

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        result.forEach((el, inde) => {
            el.displayorder = inde + 1;
        });
        return result;
    };

    const onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }
        const content = reorder(InvoiceItems, result.source.index, result.destination.index);
        setInvoiceItems(content);
    };

    const onDragEndLine = (result) => {
        if (!result.destination) {
            return;
        }
        const content = reorder(LineItems, result.source.index, result.destination.index);
        setLineItems(content);
    };
    useEffect(() => {
        // Focus the input element after each render
        document?.querySelector(`.displayorder-${ids?.displayOrder}`)?.focus();
        document?.querySelector(`.field-${ids?.mxLength}`)?.focus();
        document?.querySelector(`.lineMax-${ids?.invMax}`)?.focus();
        //document?.querySelector(`.displayName-${ids?.displayName}`)?.focus();
    }, [displayorder, maxlength, ids, displayName]);
    return (
        <>
            {requestComplete && <SuccessMessageBox success={succesMsg} />}
            {labelReqComp && copiedCountry !== "" && <SuccessMessageBox success={copiedMsg} />}
            {requestComplete && !data.status && <MessageBox error={data.message} />}
            {error && !data?.status && <MessageBox error={errormsg} />}
            <Layout>
                <Main>
                    <div className={`flex items-center mb-6 text-${currentMode}-copy`}>
                        <div className="mr-[40px]">
                            <h4 className="text-2xl font-interSb">Invoice Layout</h4>
                        </div>{" "}
                        <div>
                            <FormControl
                                sx={{
                                    minWidth: "300px",
                                }}
                            >
                                <Select
                                    value={countryVal !== 0 ? countryVal : 0}
                                    name="countryList"
                                    onChange={(e) => handleCountryChange(e.target.value)}
                                    inputProps={{
                                        "aria-label": "Without label",
                                    }}
                                    IconComponent={() => (
                                        <img
                                            className="absolute opacity-50 right-[15px] pointer-events-none"
                                            src={currentMode !== "dark" ? `/images/header/chevronDown.svg` : `/images/header/chevronDown-white.svg`}
                                        />
                                    )}
                                    MenuProps={{
                                        sx: {
                                            "& .MuiMenu-paper": {
                                                backgroundColor: dropdownColor,
                                                color: dropdownText,
                                            },
                                            "& .MuiMenuItem-root:hover": {
                                                backgroundColor: "rgba(218, 249, 244, 0.3)",
                                            },
                                            "& .Mui-selected": {
                                                backgroundColor: "rgba(218, 249, 244, 0.3) !important",
                                            },
                                        },
                                    }}
                                    SelectDisplayProps={{
                                        style: {
                                            paddingTop: "7px",
                                            paddingBottom: "7px",
                                            color: `${currentMode !== "dark" ? `#555555` : `#ECECEC`}`,
                                            paddingRight: "30px",
                                            border: "1px solid rgba(85, 85, 85, 0.40)",
                                            borderRadius: "8px",
                                        },
                                    }}
                                >
                                    <MenuItem key={0} name="countryList" value={0}>
                                        {"Select"}
                                    </MenuItem>

                                    {countryList &&
                                        countryList?.map((listelem, i) => {
                                            return (
                                                <MenuItem key={i + 1} name="countryList" value={listelem?.country_id}>
                                                    {listelem?.country_name}
                                                </MenuItem>
                                            );
                                        })}
                                </Select>
                            </FormControl>
                        </div>
                        <Button className="bg-light-20 ml-3 text-white text-base rounded-[8px] py-2 px-2" onClick={handleClick("right-start", 2)}>
                            <img src="/images/settings/noun-clone.svg" />
                        </Button>
                        <ClickAwayListener mouseEvent="onMouseDown" touchEvent="onTouchStart" onClickAway={handleClickAway}>
                            <Popper
                                className={`${styles?.Popper} bg-${currentMode}-card mt-[33px] z-[1] text-${currentMode}-copy border-${currentMode}-730 Poppershadow`}
                                open={2 === popstate.id}
                                anchorEl={popstate.anchorEl}
                                id="2"
                                placement={placement}
                            >
                                <div
                                    className={`max-h-[235px] overflow-y-auto xl:min-w-[300px] pt-0 relative bg-${currentMode}-card text-${currentMode}-copy border-${currentMode}-700 text-center py-[13px] rounded-[10px] ${
                                        currentMode === "dark" ? "" : "shadow-[0px_4px_20px_rgba(238,238,238,0.5)]"
                                    }`}
                                >
                                    <div className="flex sticky py-3 border-b px-[38px] bg-white z-[2] top-0">
                                        <b className="">Copy from other country</b>

                                        <Button
                                            onClick={() =>
                                                setPopState({
                                                    id: null,

                                                    anchorEl: false,
                                                })
                                            }
                                            tabIndex={0}
                                            className="cursor-pointer absolute right-[20px] top-[18px] convertWhite"
                                        >
                                            <img src="/images/common/closeBlk.svg" alt="closeBlk" />
                                        </Button>
                                    </div>
                                    <ul>
                                        {layoutUpdatedCountry !== undefined && layoutUpdatedCountry?.length !== 0 ? (
                                            layoutUpdatedCountry?.map((el) => {
                                                return (
                                                    <li
                                                        onClick={() => {
                                                            setPopState({
                                                                id: null,

                                                                anchorEl: false,
                                                            });

                                                            handleGetLayoutFromCountry(el?.country_id);
                                                        }}
                                                        className="cursor-pointer text-left pl-3 hover:bg-[#F3FFFE] pt-2 pb-2"
                                                    >
                                                        {el?.country_name}
                                                    </li>
                                                );
                                            })
                                        ) : (
                                            <div className="grid place-items-center text-center place-content-center h-[calc(70vh_-_60vh)]">
                                                <h5 className="font-interSb mb-1">No Countries found</h5>
                                            </div>
                                        )}
                                    </ul>
                                </div>
                            </Popper>
                        </ClickAwayListener>
                    </div>
                    <Card
                        className={`h-[calc(100vh_-_22rem)] xl:h-[calc(100vh_-_17.5rem)] p-0 relative border-b-0 rounded-t-[10px] rounded-b-[0] overflow-y-auto ${
                            currentMode === "dark" ? "" : "shadow-[0px_4px_20px_rgba(238,238,238,0.5)]"
                        } max-h-[-webkit-fill-available] relative bg-${currentMode}-card text-${currentMode}-copy border-${currentMode}-700`}
                    >
                        {!LayoutList?.requestComplete ? (
                            <Loader className="h-[calc(100vh_-_17rem)]" />
                        ) : (
                            <>
                                <div
                                    className={`bg-${currentMode}-450 w-[1400px] xl:w-full overflow-x-auto font-interM flex sticky left-0 right-0 z-[1] top-0 accord_head `}
                                >
                                    <div className="basis-[50%] pr-[3.4rem]">Fields</div>

                                    <div className="basis-3/12 text-center">Display</div>

                                    <div className="basis-3/12  text-center">Edit</div>

                                    <div className="basis-3/12  text-center">Mandatory</div>

                                    <div className="basis-3/12  text-center">Display Sequence</div>

                                    <div className="basis-3/12  text-center">Field Max Length</div>
                                </div>

                                <div className="w-[1400px] paperBorder xl:w-full overflow-x-auto" id="ListView">
                                    <Accordion expanded={expanded === "1"} onChange={handleChange("1")}>
                                        <AccordionSummary
                                            aria-controls="panel1d-content"
                                            id="panel1d-header"
                                            className={`bg-${currentMode}-card text-${currentMode}-copy `}
                                        >
                                            <span className="font-interM">Invoice</span>
                                        </AccordionSummary>

                                        <AccordionDetails>
                                            <DragDropContext onDragEnd={onDragEnd}>
                                                <Droppable droppableId="column1">
                                                    {(provided, snap) => (
                                                        <div ref={provided.innerRef} {...provided.droppableProps}>
                                                            {InvoiceItems &&
                                                                InvoiceItems?.map((elem, index) => {
                                                                    return (
                                                                        <Draggable key={elem.id} draggableId={elem.id} index={index}>
                                                                            {(provided, snap) => (
                                                                                <div
                                                                                    ref={provided.innerRef}
                                                                                    {...provided.draggableProps}
                                                                                    {...provided.dragHandleProps}
                                                                                    className="list-item"
                                                                                    style={{
                                                                                        backgroundColor: snap.isDragging ? "#4fe" : "#eee",

                                                                                        ...provided.draggableProps.style,
                                                                                    }}
                                                                                >
                                                                                    <>
                                                                                        {elem?.field_name == "tax" ? (
                                                                                            <div
                                                                                                id="ListView"
                                                                                                className={`w-[1400px] insideAccord paperBorder xl:w-full overflow-x-auto`}
                                                                                            >
                                                                                                <Accordion
                                                                                                    expanded={expandedTax === "3"}
                                                                                                    onChange={handleChangeTax("3")}
                                                                                                >
                                                                                                    <div
                                                                                                        className={` bg-${currentMode}-card flex accord_body `}
                                                                                                    >
                                                                                                        <AccordionSummary
                                                                                                            aria-controls="panel1d-content"
                                                                                                            id="panel3d-header"
                                                                                                            unique={true}
                                                                                                            className={`flex basis-[50%] !justify-start  Editable pl-[3.4rem] px-[30px] py-0 flex-row`}
                                                                                                        >
                                                                                                            <div className="mr-5 border-0 pl-0 flex">
                                                                                                                <span
                                                                                                                    className={`${
                                                                                                                        currentMode == "dark"
                                                                                                                            ? "text-white  bg-transparent"
                                                                                                                            : "font-interM"
                                                                                                                    }`}
                                                                                                                >
                                                                                                                    {" "}
                                                                                                                    {elem?.field_display_name}
                                                                                                                </span>
                                                                                                            </div>
                                                                                                        </AccordionSummary>
                                                                                                        <div className="basis-3/12 px-[30px] text-center">
                                                                                                            <Checkbox
                                                                                                                checked={elem?.isrequired}
                                                                                                                disabled={
                                                                                                                    elem?.field_name ==
                                                                                                                        "invoice_no" ||
                                                                                                                    elem?.field_name ==
                                                                                                                        "purchase_order_no" ||
                                                                                                                    elem?.field_name == "total_net" ||
                                                                                                                    elem?.field_name == "total" ||
                                                                                                                    elem?.field_name == "currency"
                                                                                                                }
                                                                                                                onChange={(event) => {
                                                                                                                    IncludeCheckboxChange(
                                                                                                                        {
                                                                                                                            target: {
                                                                                                                                name: event.target
                                                                                                                                    .name,

                                                                                                                                value: event.target
                                                                                                                                    .checked,
                                                                                                                            },
                                                                                                                        },

                                                                                                                        elem?.id
                                                                                                                    );
                                                                                                                }}
                                                                                                                sx={{
                                                                                                                    padding: 0,
                                                                                                                    marginRight: 1,
                                                                                                                    color: "rgba(85,85,85,0.7)",
                                                                                                                    "&.Mui-checked": {
                                                                                                                        color: "#028C78",
                                                                                                                    },
                                                                                                                }}
                                                                                                            />
                                                                                                        </div>
                                                                                                        <div className="basis-3/12  px-[30px] text-center">
                                                                                                            <Checkbox
                                                                                                                checked={elem?.iseditable}
                                                                                                                unselectable={
                                                                                                                    elem?.isrequired == false
                                                                                                                }
                                                                                                                disabled={elem?.isrequired == false}
                                                                                                                onChange={(event) => {
                                                                                                                    EditableCheckboxChange(
                                                                                                                        {
                                                                                                                            target: {
                                                                                                                                name: event.target
                                                                                                                                    .name,

                                                                                                                                value: event.target
                                                                                                                                    .checked,
                                                                                                                            },
                                                                                                                        },

                                                                                                                        elem?.id
                                                                                                                    );
                                                                                                                }}
                                                                                                                sx={{
                                                                                                                    color: "rgba(85,85,85,0.7)",
                                                                                                                    padding: 0,
                                                                                                                    "&.Mui-checked": {
                                                                                                                        color: "#028C78",
                                                                                                                    },
                                                                                                                }}
                                                                                                            />
                                                                                                        </div>
                                                                                                        <div className="basis-3/12  px-[30px] text-center">
                                                                                                            <Checkbox
                                                                                                                checked={elem?.ismandatory}
                                                                                                                disabled={
                                                                                                                    elem?.iseditable == false ||
                                                                                                                    elem?.isrequired == false
                                                                                                                }
                                                                                                                onChange={(event) => {
                                                                                                                    MandatoryCheckboxChange(
                                                                                                                        {
                                                                                                                            target: {
                                                                                                                                name: event.target
                                                                                                                                    .name,

                                                                                                                                value: event.target
                                                                                                                                    .checked,
                                                                                                                            },
                                                                                                                        },

                                                                                                                        elem?.id
                                                                                                                    );
                                                                                                                }}
                                                                                                                sx={{
                                                                                                                    padding: 0,

                                                                                                                    color: "rgba(85,85,85,0.7)",

                                                                                                                    "&.Mui-checked": {
                                                                                                                        color: "#028C78",
                                                                                                                    },
                                                                                                                }}
                                                                                                            />
                                                                                                        </div>
                                                                                                        <div className="basis-3/12 px-[30px] text-center">
                                                                                                            <Input
                                                                                                                className={`border-[rgba(85,85,85,0.7)] displayorder-${elem?.id} border-[1px] rounded-md w-[50px] h-[24px] text-center color-[ #555555B2] bg-${currentMode}-810 text-${currentMode}-copy`}
                                                                                                                name="displayorder"
                                                                                                                disabled={elem?.isrequired == false}
                                                                                                                maxLength="3"
                                                                                                                value={
                                                                                                                    elem?.displayorder == 0
                                                                                                                        ? ""
                                                                                                                        : elem?.displayorder &&
                                                                                                                          elem?.isrequired == false
                                                                                                                        ? (elem.displayorder = null)
                                                                                                                        : elem?.displayorder
                                                                                                                }
                                                                                                                type="tel"
                                                                                                                onChange={(event) => {
                                                                                                                    DisplayOrderChange(
                                                                                                                        {
                                                                                                                            target: {
                                                                                                                                name: event.target
                                                                                                                                    .name,

                                                                                                                                value: event.target
                                                                                                                                    .value,
                                                                                                                            },
                                                                                                                        },
                                                                                                                        elem?.id
                                                                                                                    );
                                                                                                                }}
                                                                                                            />
                                                                                                        </div>
                                                                                                        <div className="basis-3/12  px-[30px] text-center"></div>
                                                                                                    </div>
                                                                                                    <AccordionDetails>
                                                                                                        {taxItems?.length !== 0 &&
                                                                                                            taxItems?.map((item, ind) => {
                                                                                                                return (
                                                                                                                    <>
                                                                                                                        <div
                                                                                                                            className={`bg-${currentMode}-1200 flex accord_body space-x-1.375`}
                                                                                                                        >
                                                                                                                            <div className="basis-[50%] flex Editable pl-[3.4rem] border-t-0">
                                                                                                                                {/* {console.log(
                                                                                                                                    "Rendering",
                                                                                                                                    item?.field_display_name
                                                                                                                                )} */}
                                                                                                                                {/* <Input
                                                                                                                                    readOnly={
                                                                                                                                        fieldType ===
                                                                                                                                        item?.field_name
                                                                                                                                            ? false
                                                                                                                                            : true
                                                                                                                                    }
                                                                                                                                    className={`${
                                                                                                                                        currentMode ==
                                                                                                                                        "dark"
                                                                                                                                            ? "text-white  bg-transparent"
                                                                                                                                            : "font-interM"
                                                                                                                                    } displayName-${
                                                                                                                                        item?.id
                                                                                                                                    } bg-[#F3FFFE]`}
                                                                                                                                    value={
                                                                                                                                        item?.field_display_name
                                                                                                                                    }
                                                                                                                                    onChange={(e) => {
                                                                                                                                        handleChangeLayoutName(
                                                                                                                                            e,
                                                                                                                                            ind,
                                                                                                                                            item?.id,
                                                                                                                                            item?.isitem
                                                                                                                                        );
                                                                                                                                    }}
                                                                                                                                    name={
                                                                                                                                        item?.field_name
                                                                                                                                    }
                                                                                                                                /> */}
                                                                                                                                <InputField
                                                                                                                                    item={item}
                                                                                                                                    dynamicClass={`${
                                                                                                                                        currentMode ==
                                                                                                                                        "dark"
                                                                                                                                            ? "text-white  bg-transparent"
                                                                                                                                            : "font-interM"
                                                                                                                                    } displayName-${
                                                                                                                                        item?.id
                                                                                                                                    } bg-[#F3FFFE]`}
                                                                                                                                    fieldType={
                                                                                                                                        fieldType
                                                                                                                                    }
                                                                                                                                    ind={ind}
                                                                                                                                    currentMode={
                                                                                                                                        currentMode
                                                                                                                                    }
                                                                                                                                    EditBox={EditBox}
                                                                                                                                    updateUser={
                                                                                                                                        newUpdateUser
                                                                                                                                    }
                                                                                                                                    closeUpdate={
                                                                                                                                        closeUpdate
                                                                                                                                    }
                                                                                                                                    handleEdit={
                                                                                                                                        handleEdit
                                                                                                                                    }
                                                                                                                                />
                                                                                                                                {/* {fieldType ===
                                                                                                                                    item?.field_name && (
                                                                                                                                    <EditBox
                                                                                                                                        className="Edit ml-9"
                                                                                                                                        handleClick={() =>
                                                                                                                                            updateUser(
                                                                                                                                                item?.field_name,
                                                                                                                                                item?.field_display_name,
                                                                                                                                                item?.id
                                                                                                                                            )
                                                                                                                                        }
                                                                                                                                        id={item?.id}
                                                                                                                                        closeClick={() =>
                                                                                                                                            closeUpdate(
                                                                                                                                                item?.field_name,
                                                                                                                                                item?.field_display_name,
                                                                                                                                                item?.id,
                                                                                                                                                item?.isitem
                                                                                                                                            )
                                                                                                                                        }
                                                                                                                                    />
                                                                                                                                )}
                                                                                                                                {fieldType !==
                                                                                                                                    item?.field_name && (
                                                                                                                                    <Button
                                                                                                                                        className="Edit ml-9"
                                                                                                                                        id={item?.id}
                                                                                                                                        disabled={
                                                                                                                                            fieldType !==
                                                                                                                                            ""
                                                                                                                                        }
                                                                                                                                        onClick={() =>
                                                                                                                                            handleEdit(
                                                                                                                                                item?.id,
                                                                                                                                                item?.field_name,
                                                                                                                                                item?.field_display_name
                                                                                                                                            )
                                                                                                                                        }
                                                                                                                                    >
                                                                                                                                        <img
                                                                                                                                            src="/images/common/pencilEdit.svg"
                                                                                                                                            alt="pencilEdit"
                                                                                                                                        />
                                                                                                                                    </Button>
                                                                                                                                )} */}
                                                                                                                            </div>
                                                                                                                            <div className="basis-3/12 py-[11px] px-[30px] border-t-0 text-center">
                                                                                                                                <Checkbox
                                                                                                                                    checked={
                                                                                                                                        item?.isrequired
                                                                                                                                    }
                                                                                                                                    disabled={
                                                                                                                                        item?.field_name ==
                                                                                                                                            "invoice_no" ||
                                                                                                                                        item?.field_name ==
                                                                                                                                            "purchase_order_no" ||
                                                                                                                                        item?.field_name ==
                                                                                                                                            "total_net" ||
                                                                                                                                        item?.field_name ==
                                                                                                                                            "total" ||
                                                                                                                                        item?.field_name ==
                                                                                                                                            "currency" ||
                                                                                                                                        (elem?.field_name ==
                                                                                                                                            "tax" &&
                                                                                                                                            elem?.isrequired ==
                                                                                                                                                false)
                                                                                                                                    }
                                                                                                                                    onChange={(
                                                                                                                                        event
                                                                                                                                    ) => {
                                                                                                                                        IncludeCheckboxChange(
                                                                                                                                            {
                                                                                                                                                target: {
                                                                                                                                                    name: event
                                                                                                                                                        .target
                                                                                                                                                        .name,

                                                                                                                                                    value: event
                                                                                                                                                        .target
                                                                                                                                                        .checked,
                                                                                                                                                },
                                                                                                                                            },
                                                                                                                                            item?.id
                                                                                                                                        );
                                                                                                                                    }}
                                                                                                                                    sx={{
                                                                                                                                        padding: 0,
                                                                                                                                        marginRight: 1,
                                                                                                                                        color: "rgba(85,85,85,0.7)",
                                                                                                                                        "&.Mui-checked":
                                                                                                                                            {
                                                                                                                                                color: "#028C78",
                                                                                                                                            },
                                                                                                                                    }}
                                                                                                                                />
                                                                                                                            </div>
                                                                                                                            <div className="basis-3/12 py-[11px] px-[30px] border-t-0 text-center">
                                                                                                                                <Checkbox
                                                                                                                                    checked={
                                                                                                                                        item?.iseditable
                                                                                                                                    }
                                                                                                                                    unselectable={
                                                                                                                                        item?.isrequired ==
                                                                                                                                        false
                                                                                                                                    }
                                                                                                                                    disabled={
                                                                                                                                        item?.isrequired ==
                                                                                                                                        false
                                                                                                                                    }
                                                                                                                                    onChange={(
                                                                                                                                        event
                                                                                                                                    ) => {
                                                                                                                                        EditableCheckboxChange(
                                                                                                                                            {
                                                                                                                                                target: {
                                                                                                                                                    name: event
                                                                                                                                                        .target
                                                                                                                                                        .name,

                                                                                                                                                    value: event
                                                                                                                                                        .target
                                                                                                                                                        .checked,
                                                                                                                                                },
                                                                                                                                            },
                                                                                                                                            item?.id
                                                                                                                                        );
                                                                                                                                    }}
                                                                                                                                    sx={{
                                                                                                                                        color: "rgba(85,85,85,0.7)",
                                                                                                                                        padding: 0,
                                                                                                                                        "&.Mui-checked":
                                                                                                                                            {
                                                                                                                                                color: "#028C78",
                                                                                                                                            },
                                                                                                                                    }}
                                                                                                                                />
                                                                                                                            </div>
                                                                                                                            <div className="basis-3/12 py-[11px] px-[30px] border-t-0 text-center">
                                                                                                                                <Checkbox
                                                                                                                                    checked={
                                                                                                                                        item?.ismandatory
                                                                                                                                    }
                                                                                                                                    disabled={
                                                                                                                                        item?.iseditable ==
                                                                                                                                            false ||
                                                                                                                                        item?.isrequired ==
                                                                                                                                            false
                                                                                                                                    }
                                                                                                                                    onChange={(
                                                                                                                                        event
                                                                                                                                    ) => {
                                                                                                                                        MandatoryCheckboxChange(
                                                                                                                                            {
                                                                                                                                                target: {
                                                                                                                                                    name: event
                                                                                                                                                        .target
                                                                                                                                                        .name,

                                                                                                                                                    value: event
                                                                                                                                                        .target
                                                                                                                                                        .checked,
                                                                                                                                                },
                                                                                                                                            },
                                                                                                                                            item?.id
                                                                                                                                        );
                                                                                                                                    }}
                                                                                                                                    sx={{
                                                                                                                                        padding: 0,
                                                                                                                                        color: "rgba(85,85,85,0.7)",
                                                                                                                                        "&.Mui-checked":
                                                                                                                                            {
                                                                                                                                                color: "#028C78",
                                                                                                                                            },
                                                                                                                                    }}
                                                                                                                                />
                                                                                                                            </div>
                                                                                                                            <div className="basis-3/12 py-[11px] px-[30px] border-t-0 text-center"></div>
                                                                                                                            <div className="basis-3/12 py-[11px] px-[30px] border-t-0 text-center">
                                                                                                                                <Input
                                                                                                                                    className={`border-[rgba(85,85,85,0.7)] border-[1px] field-${
                                                                                                                                        item?.id
                                                                                                                                    } rounded-md w-[50px] h-[24px] text-center color-[ #555555B2] ${
                                                                                                                                        item?.data_type ==
                                                                                                                                            "d" &&
                                                                                                                                        "opacity-[0] invisible"
                                                                                                                                    } bg-${currentMode}-810 text-${currentMode}-copy`}
                                                                                                                                    value={
                                                                                                                                        item?.isrequired ==
                                                                                                                                            false ||
                                                                                                                                        item?.maxlength ==
                                                                                                                                            0
                                                                                                                                            ? (item.maxlength =
                                                                                                                                                  null)
                                                                                                                                            : item?.maxlength
                                                                                                                                    }
                                                                                                                                    disabled={
                                                                                                                                        item?.iseditable ==
                                                                                                                                        false
                                                                                                                                    }
                                                                                                                                    maxLength={3}
                                                                                                                                    type="tel"
                                                                                                                                    onChange={(
                                                                                                                                        event
                                                                                                                                    ) => {
                                                                                                                                        MaximumLengthChange(
                                                                                                                                            {
                                                                                                                                                target: {
                                                                                                                                                    name: event
                                                                                                                                                        .target
                                                                                                                                                        .name,

                                                                                                                                                    value: event
                                                                                                                                                        .target
                                                                                                                                                        .value,
                                                                                                                                                },
                                                                                                                                            },

                                                                                                                                            item?.id
                                                                                                                                        );
                                                                                                                                    }}
                                                                                                                                />
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </>
                                                                                                                );
                                                                                                            })}
                                                                                                    </AccordionDetails>
                                                                                                </Accordion>
                                                                                            </div>
                                                                                        ) : (
                                                                                            <div
                                                                                                className={` bg-${currentMode}-card flex accord_body `}
                                                                                            >
                                                                                                <div className="basis-[50%] flex Editable pl-[3.4rem]">
                                                                                                    {/* <Input
                                                                                                        className={`${
                                                                                                            currentMode == "dark"
                                                                                                                ? "text-white  bg-transparent"
                                                                                                                : "font-interM"
                                                                                                        } displayName-${elem?.id}`}
                                                                                                        value={elem?.field_display_name}
                                                                                                        readOnly={
                                                                                                            fieldType === elem?.field_name
                                                                                                                ? false
                                                                                                                : true
                                                                                                        }
                                                                                                        onChange={(e) => {
                                                                                                            handleChangeLayoutName(
                                                                                                                e,
                                                                                                                index,
                                                                                                                elem?.id,
                                                                                                                elem?.isitem
                                                                                                            );
                                                                                                        }}
                                                                                                        name={elem?.field_name}
                                                                                                    />
                                                                                                    {((elem?.field_name == "purchase_order_date" &&
                                                                                                        fieldType !== "purchase_order_date") ||
                                                                                                        (elem?.field_name == "purchase_order_no" &&
                                                                                                            fieldType !== "purchase_order_no") ||
                                                                                                        (elem?.field_name == "sale_order_no" &&
                                                                                                            fieldType !== "sale_order_no") ||
                                                                                                        (elem?.field_name == "sale_order_date" &&
                                                                                                            fieldType !== "sale_order_date")) && (
                                                                                                        <Tooltip
                                                                                                            title={
                                                                                                                "This field is available for the PO invoice only"
                                                                                                            }
                                                                                                            disablePortal
                                                                                                            placement="right"
                                                                                                            disableInteractive
                                                                                                            PopperProps={{
                                                                                                                sx: {
                                                                                                                    "& .MuiTooltip-tooltip": {
                                                                                                                        fontSize: "1.0em",
                                                                                                                        maxHeight: "120px",
                                                                                                                        maxWidth: "220px",
                                                                                                                        background: "#DAF9F4",
                                                                                                                        color: "#555555",
                                                                                                                        borderRadius: "5px",
                                                                                                                        marginTop: "0 !important",
                                                                                                                        padding: "10px",
                                                                                                                    },

                                                                                                                    "& .MuiTooltip-arrow": {
                                                                                                                        color: "#DAF9F4",
                                                                                                                    },
                                                                                                                },
                                                                                                            }}
                                                                                                            arrow
                                                                                                        >
                                                                                                            <img
                                                                                                                className="mt-1 h-[12px] cursor-default"
                                                                                                                src="/images/main/noun-information.svg"
                                                                                                                alt="noun-information"
                                                                                                            />
                                                                                                        </Tooltip>
                                                                                                    )}

                                                                                                    {fieldType === elem?.field_name && (
                                                                                                        <EditBox
                                                                                                            className="Edit ml-9"
                                                                                                            handleClick={() =>
                                                                                                                updateUser(
                                                                                                                    elem?.field_name,
                                                                                                                    elem?.field_display_name,
                                                                                                                    elem?.id
                                                                                                                )
                                                                                                            }
                                                                                                            id={elem?.id}
                                                                                                            closeClick={() =>
                                                                                                                closeUpdate(
                                                                                                                    elem?.field_name,
                                                                                                                    elem?.field_display_name,
                                                                                                                    elem?.id,
                                                                                                                    elem?.isitem
                                                                                                                )
                                                                                                            }
                                                                                                        />
                                                                                                    )}
                                                                                                    {fieldType !== elem?.field_name && (
                                                                                                        <Button
                                                                                                            className="Edit ml-9"
                                                                                                            id={elem?.id}
                                                                                                            disabled={fieldType !== ""}
                                                                                                            onClick={() =>
                                                                                                                handleEdit(
                                                                                                                    elem?.id,
                                                                                                                    elem?.field_name,
                                                                                                                    elem?.field_display_name
                                                                                                                )
                                                                                                            }
                                                                                                        >
                                                                                                            <img
                                                                                                                src="/images/common/pencilEdit.svg"
                                                                                                                alt="pencilEdit"
                                                                                                            />
                                                                                                        </Button>
                                                                                                    )} */}
                                                                                                    <InputField
                                                                                                        item={elem}
                                                                                                        dynamicClass={`${
                                                                                                            currentMode == "dark"
                                                                                                                ? "text-white  bg-transparent"
                                                                                                                : "font-interM"
                                                                                                        } displayName-${elem?.id}`}
                                                                                                        fieldType={fieldType}
                                                                                                        ind={index}
                                                                                                        currentMode={currentMode}
                                                                                                        EditBox={EditBox}
                                                                                                        updateUser={newUpdateUser}
                                                                                                        closeUpdate={closeUpdate}
                                                                                                        handleEdit={handleEdit}
                                                                                                    />
                                                                                                </div>
                                                                                                <div className="basis-3/12 text-center">
                                                                                                    <Checkbox
                                                                                                        checked={elem?.isrequired}
                                                                                                        disabled={
                                                                                                            elem?.field_name == "invoice_no" ||
                                                                                                            elem?.field_name == "purchase_order_no" ||
                                                                                                            elem?.field_name == "total_net" ||
                                                                                                            elem?.field_name == "total" ||
                                                                                                            elem?.field_name == "currency"
                                                                                                        }
                                                                                                        onChange={(event) => {
                                                                                                            IncludeCheckboxChange(
                                                                                                                {
                                                                                                                    target: {
                                                                                                                        name: event.target.name,
                                                                                                                        value: event.target.checked,
                                                                                                                    },
                                                                                                                },
                                                                                                                elem?.id
                                                                                                            );
                                                                                                        }}
                                                                                                        sx={{
                                                                                                            padding: 0,
                                                                                                            marginRight: 1,
                                                                                                            color: "rgba(85,85,85,0.7)",
                                                                                                            "&.Mui-checked": {
                                                                                                                color: "#028C78",
                                                                                                            },
                                                                                                        }}
                                                                                                    />
                                                                                                </div>
                                                                                                <div className="basis-3/12 text-center">
                                                                                                    <Checkbox
                                                                                                        checked={elem?.iseditable}
                                                                                                        unselectable={elem?.isrequired == false}
                                                                                                        disabled={elem?.isrequired == false}
                                                                                                        onChange={(event) => {
                                                                                                            EditableCheckboxChange(
                                                                                                                {
                                                                                                                    target: {
                                                                                                                        name: event.target.name,
                                                                                                                        value: event.target.checked,
                                                                                                                    },
                                                                                                                },
                                                                                                                elem?.id
                                                                                                            );
                                                                                                        }}
                                                                                                        sx={{
                                                                                                            color: "rgba(85,85,85,0.7)",
                                                                                                            padding: 0,
                                                                                                            "&.Mui-checked": {
                                                                                                                color: "#028C78",
                                                                                                            },
                                                                                                        }}
                                                                                                    />
                                                                                                </div>
                                                                                                <div className="basis-3/12 text-center">
                                                                                                    <Checkbox
                                                                                                        checked={elem?.ismandatory}
                                                                                                        disabled={
                                                                                                            elem?.iseditable == false ||
                                                                                                            elem?.isrequired == false
                                                                                                        }
                                                                                                        onChange={(event) => {
                                                                                                            MandatoryCheckboxChange(
                                                                                                                {
                                                                                                                    target: {
                                                                                                                        name: event.target.name,
                                                                                                                        value: event.target.checked,
                                                                                                                    },
                                                                                                                },
                                                                                                                elem?.id
                                                                                                            );
                                                                                                        }}
                                                                                                        sx={{
                                                                                                            padding: 0,
                                                                                                            color: "rgba(85,85,85,0.7)",
                                                                                                            "&.Mui-checked": {
                                                                                                                color: "#028C78",
                                                                                                            },
                                                                                                        }}
                                                                                                    />
                                                                                                </div>
                                                                                                <div className="basis-3/12 text-center">
                                                                                                    <Input
                                                                                                        className={`border-[rgba(85,85,85,0.7)] displayorder-${elem?.id} border-[1px] rounded-md w-[50px] h-[24px] text-center color-[ #555555B2] bg-${currentMode}-810 text-${currentMode}-copy`}
                                                                                                        name="displayorder"
                                                                                                        disabled={elem?.isrequired == false}
                                                                                                        maxLength="3"
                                                                                                        value={
                                                                                                            elem?.displayorder == 0
                                                                                                                ? ""
                                                                                                                : elem?.displayorder &&
                                                                                                                  elem?.isrequired == false
                                                                                                                ? (elem.displayorder = null)
                                                                                                                : elem?.displayorder
                                                                                                        }
                                                                                                        type="tel"
                                                                                                        onChange={(event) => {
                                                                                                            DisplayOrderChange(
                                                                                                                {
                                                                                                                    target: {
                                                                                                                        name: event.target.name,
                                                                                                                        value: event.target.value,
                                                                                                                    },
                                                                                                                },
                                                                                                                elem?.id
                                                                                                            );
                                                                                                        }}
                                                                                                    />
                                                                                                </div>
                                                                                                <div className="basis-3/12 text-center">
                                                                                                    <Input
                                                                                                        className={`border-[rgba(85,85,85,0.7)] border-[1px] field-${
                                                                                                            elem?.id
                                                                                                        } rounded-md w-[50px] h-[24px] text-center color-[ #555555B2] ${
                                                                                                            elem?.data_type == "d" &&
                                                                                                            "opacity-[0] invisible"
                                                                                                        } bg-${currentMode}-810 text-${currentMode}-copy`}
                                                                                                        value={
                                                                                                            elem?.isrequired == false ||
                                                                                                            elem?.maxlength == 0
                                                                                                                ? (elem.maxlength = null)
                                                                                                                : elem?.maxlength
                                                                                                        }
                                                                                                        disabled={elem?.iseditable == false}
                                                                                                        maxLength={3}
                                                                                                        type="tel"
                                                                                                        onChange={(event) => {
                                                                                                            MaximumLengthChange(
                                                                                                                {
                                                                                                                    target: {
                                                                                                                        name: event.target.name,

                                                                                                                        value: event.target.value,
                                                                                                                    },
                                                                                                                },
                                                                                                                elem?.id
                                                                                                            );
                                                                                                        }}
                                                                                                    />
                                                                                                </div>
                                                                                            </div>
                                                                                        )}
                                                                                    </>
                                                                                </div>
                                                                            )}
                                                                        </Draggable>
                                                                    );
                                                                })}
                                                        </div>
                                                    )}
                                                </Droppable>
                                            </DragDropContext>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion expanded={expanded === "2"} onChange={handleChange("2")}>
                                        <AccordionSummary
                                            aria-controls="panel1d-content"
                                            id="panel2d-header"
                                            onClick={() => ExpandLineItem()}
                                            className={`bg-${currentMode}-card text-${currentMode}-copy`}
                                        >
                                            <span className="font-interM">Line Items</span>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <DragDropContext onDragEnd={onDragEndLine}>
                                                <Droppable droppableId="column1">
                                                    {(provided, snap) => (
                                                        <div ref={provided.innerRef} {...provided.droppableProps}>
                                                            {LineItems &&
                                                                LineItems?.map((elem, inde) => {
                                                                    return (
                                                                        <Draggable key={elem.id} draggableId={elem.id} index={inde}>
                                                                            {(provided, snap) => (
                                                                                <div
                                                                                    ref={provided.innerRef}
                                                                                    {...provided.draggableProps}
                                                                                    {...provided.dragHandleProps}
                                                                                    className="list-item"
                                                                                    style={{
                                                                                        backgroundColor: snap.isDragging ? "#4fe" : "#eee",

                                                                                        ...provided.draggableProps.style,
                                                                                    }}
                                                                                >
                                                                                    <div className={` bg-${currentMode}-card flex accord_body `}>
                                                                                        <div className="basis-[50%] flex Editable pl-[3.4rem]">
                                                                                            {/* <Input
                                                                                                readOnly={
                                                                                                    fieldType === elem?.field_name ? false : true
                                                                                                }
                                                                                                className={`${
                                                                                                    currentMode == "dark"
                                                                                                        ? "text-white bg-transparent"
                                                                                                        : "font-interM"
                                                                                                } displayName-${elem?.id} w-full `}
                                                                                                value={elem?.field_display_name}
                                                                                                onChange={(e) => {
                                                                                                    handleChangeLayoutName(
                                                                                                        e,
                                                                                                        inde,
                                                                                                        elem?.id,
                                                                                                        elem?.isitem
                                                                                                    );
                                                                                                }}
                                                                                                name={elem?.field_name}
                                                                                            />

                                                                                            {fieldType === elem?.field_name && (
                                                                                                <EditBox
                                                                                                    className="Edit ml-9"
                                                                                                    handleClick={() =>
                                                                                                        updateUser(
                                                                                                            elem?.field_name,
                                                                                                            elem?.field_display_name,
                                                                                                            elem?.id
                                                                                                        )
                                                                                                    }
                                                                                                    id={elem?.id}
                                                                                                    closeClick={() =>
                                                                                                        closeUpdate(
                                                                                                            elem?.field_name,
                                                                                                            elem?.field_display_name,
                                                                                                            elem?.id,
                                                                                                            elem?.isitem
                                                                                                        )
                                                                                                    }
                                                                                                />
                                                                                            )}

                                                                                            {fieldType !== elem?.field_name && (
                                                                                                <Button
                                                                                                    className="Edit ml-9"
                                                                                                    id={elem?.id}
                                                                                                    disabled={fieldType !== ""}
                                                                                                    onClick={() =>
                                                                                                        handleEdit(
                                                                                                            elem?.id,
                                                                                                            elem?.field_name,
                                                                                                            elem?.field_display_name
                                                                                                        )
                                                                                                    }
                                                                                                >
                                                                                                    <img
                                                                                                        src="/images/common/pencilEdit.svg"
                                                                                                        alt="pencilEdit"
                                                                                                    />
                                                                                                </Button>
                                                                                            )} */}
                                                                                            <InputField
                                                                                                item={elem}
                                                                                                dynamicClass={`${
                                                                                                    currentMode == "dark"
                                                                                                        ? "text-white bg-transparent"
                                                                                                        : "font-interM"
                                                                                                } displayName-${elem?.id} w-full `}
                                                                                                fieldType={fieldType}
                                                                                                ind={inde}
                                                                                                currentMode={currentMode}
                                                                                                EditBox={EditBox}
                                                                                                updateUser={newUpdateUser}
                                                                                                closeUpdate={closeUpdate}
                                                                                                handleEdit={handleEdit}
                                                                                            />
                                                                                        </div>

                                                                                        <div className={`basis-3/12 text-center`}>
                                                                                            <Checkbox
                                                                                                checked={elem?.isrequired}
                                                                                                disabled={
                                                                                                    elem?.field_name == "quantity" ||
                                                                                                    elem?.field_name == "item_price" ||
                                                                                                    elem?.field_name == "item_total"
                                                                                                }
                                                                                                onChange={(event) => {
                                                                                                    IncludeCheckboxChange(
                                                                                                        {
                                                                                                            target: {
                                                                                                                name: event.target.name,
                                                                                                                value: event.target.checked,
                                                                                                            },
                                                                                                        },
                                                                                                        elem?.id
                                                                                                    );
                                                                                                }}
                                                                                                sx={{
                                                                                                    padding: 0,
                                                                                                    marginRight: 1,
                                                                                                    color: "rgba(85,85,85,0.7)",
                                                                                                    "&.Mui-checked": {
                                                                                                        color: "#028C78",
                                                                                                    },
                                                                                                }}
                                                                                            />
                                                                                        </div>
                                                                                        <div className="basis-3/12 text-center">
                                                                                            <Checkbox
                                                                                                checked={elem?.iseditable}
                                                                                                unselectable={elem?.isrequired == false}
                                                                                                disabled={elem?.isrequired == false}
                                                                                                onChange={(event) => {
                                                                                                    EditableCheckboxChange(
                                                                                                        {
                                                                                                            target: {
                                                                                                                name: event.target.name,
                                                                                                                value: event.target.checked,
                                                                                                            },
                                                                                                        },
                                                                                                        elem?.id
                                                                                                    );
                                                                                                }}
                                                                                                sx={{
                                                                                                    color: "rgba(85,85,85,0.7)",
                                                                                                    padding: 0,
                                                                                                    "&.Mui-checked": {
                                                                                                        color: "#028C78",
                                                                                                    },
                                                                                                }}
                                                                                            />
                                                                                        </div>
                                                                                        <div className="basis-3/12 text-center">
                                                                                            <Checkbox
                                                                                                checked={elem?.ismandatory}
                                                                                                disabled={
                                                                                                    elem?.iseditable == false ||
                                                                                                    elem?.isrequired == false
                                                                                                }
                                                                                                onChange={(event) => {
                                                                                                    MandatoryCheckboxChange(
                                                                                                        {
                                                                                                            target: {
                                                                                                                name: event.target.name,
                                                                                                                value: event.target.checked,
                                                                                                            },
                                                                                                        },
                                                                                                        elem?.id
                                                                                                    );
                                                                                                }}
                                                                                                sx={{
                                                                                                    padding: 0,
                                                                                                    color: "rgba(85,85,85,0.7)",
                                                                                                    "&.Mui-checked": {
                                                                                                        color: "#028C78",
                                                                                                    },
                                                                                                }}
                                                                                            />
                                                                                        </div>
                                                                                        <div className="basis-3/12 text-center">
                                                                                            <Input
                                                                                                className={`border-[rgba(85,85,85,0.7)] displayorder-${elem?.id} border-[1px] rounded-md w-[50px] h-[24px] text-center color-[ #555555B2] bg-${currentMode}-810 text-${currentMode}-copy`}
                                                                                                name="displayorder"
                                                                                                disabled={elem?.isrequired == false}
                                                                                                value={
                                                                                                    elem?.displayorder == 0
                                                                                                        ? ""
                                                                                                        : elem?.displayorder &&
                                                                                                          elem?.isrequired == false
                                                                                                        ? (elem.displayorder = null)
                                                                                                        : elem?.displayorder
                                                                                                }
                                                                                                maxLength={3}
                                                                                                type="number"
                                                                                                onChange={(event) => {
                                                                                                    DisplayOrderChange(
                                                                                                        {
                                                                                                            target: {
                                                                                                                name: event.target.name,
                                                                                                                value: event.target.value,
                                                                                                            },
                                                                                                        },
                                                                                                        elem?.id
                                                                                                    );
                                                                                                }}
                                                                                            />
                                                                                        </div>
                                                                                        <div className="basis-3/12 text-center">
                                                                                            <Input
                                                                                                className={`border-[rgba(85,85,85,0.7)] border-[1px] rounded-md w-[50px] h-[24px] text-center color-[ #555555B2] ${
                                                                                                    elem?.data_type == "d" && "opacity-[0] invisible"
                                                                                                } bg-${currentMode}-810 lineMax-${
                                                                                                    elem?.id
                                                                                                } text-${currentMode}-copy`}
                                                                                                value={
                                                                                                    elem?.isrequired == false || elem?.maxlength == 0
                                                                                                        ? null
                                                                                                        : elem?.maxlength
                                                                                                }
                                                                                                disabled={elem?.iseditable == false}
                                                                                                maxLength={3}
                                                                                                type="tel"
                                                                                                onChange={(event) => {
                                                                                                    MaximumLengthChange(
                                                                                                        {
                                                                                                            target: {
                                                                                                                name: event.target.name,
                                                                                                                value: event.target.value,
                                                                                                            },
                                                                                                        },
                                                                                                        elem?.id,
                                                                                                        "lineItems"
                                                                                                    );
                                                                                                }}
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            )}
                                                                        </Draggable>
                                                                    );
                                                                })}
                                                        </div>
                                                    )}
                                                </Droppable>
                                            </DragDropContext>
                                        </AccordionDetails>
                                    </Accordion>
                                </div>
                            </>
                        )}
                    </Card>
                    <div
                        className={`bg-${currentMode}-card text-${currentMode}-copy border-${currentMode}-700 py-[20px] space-x-2 px-[30px] sticky left-0 right-0 shadow-[0px_4px_20px_rgba(85,85,85,0.2)] border-[1px] border-[#dfe1e28f] rounded-b-[10px]`}
                    >
                        <Button
                            title="Save"
                            disabled={fieldType !== ""}
                            className="bg-light-20 text-white text-base rounded-[8px] py-2 px-7"
                            onClick={submit}
                        >
                            Save
                        </Button>

                        <Link to="/admin-setting">
                            <Button title="Cancel" className="bg-light-240 text-base text-light-40 rounded-[8px] py-2 px-7">
                                Cancel
                            </Button>
                        </Link>
                    </div>
                </Main>
                <Footer />
            </Layout>
            {ReactDOM.createPortal(
                <AlertLayout isOpened={alertOpen} handleClose={() => setalertOpen(false)} reqobj={reqobj} className={alertOpen && "Show"} />,
                document.getElementById("user-modal-root")
            )}
        </>
    );
};

export default InvoiceLayout;
