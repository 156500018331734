import { useContext } from "react";
import Modal from "../../../components/UI/Modal/Modal";
import { ThemeContext } from "../../../Hooks/Themes/Themeprovider";
import ServerConfigModal from "./ServerConfig";

const ServerConfigs = (props) => {
    const { currentMode, toggleMode } = useContext(ThemeContext);

    const HandleClose = () => {
        document.querySelector("[data-id='8']").classList.remove("isActive");
        props?.handleClose(true);
    };

    return (
        <>
            <Modal cartClose={HandleClose} className={`${props?.className}`}>
                <ServerConfigModal {...props} orgList={props?.orgList} isOpened={props?.isOpened} orgId={props?.orgId} handleClose={HandleClose} />
            </Modal>
        </>
    );
};

export default ServerConfigs;
