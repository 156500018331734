export const validateLogin = (values) => {
    let errors = {};
    if (!values?.emailaddress) errors.emailaddress = "Business Email is required";
    else if (!/^(?!.+@(gmail|google|yahoo|outlook|hotmail|msn)\..+)(.+@.+\..+)$/.test(values.emailaddress))
        errors.emailaddress = "Email address is invalid";
    else if (!/^[A-Za-z0-9._-{!~@#$%^&*()}{:"></?,.+=_-`}}]+@[A-Za-z0-9._-]+\.[A-Za-z]{2,}$/.test(values?.emailaddress))
        errors.emailaddress = "Email address is invalid";
    else if (/^[!~@#$%^&*()}{:"></?,.+=_-`}]+@[A-Za-z0-9._-]+\.[A-Za-z]{2,}$/.test(values?.emailaddress))
        errors.emailaddress = "Email address is invalid";
    else if (values?.emailaddress?.length < 6 || values?.emailaddress?.length > 250)
        errors.emailaddress = "Business Email address should be minimum 6 to maximum 250";
    return errors;
};

export const validateRegister = (values) => {
    let errors = {};
    if (!values?.firstname) errors.firstname = "First Name is required";
    if (!values?.lastname) errors.lastname = "Last Name is required";
    if (!values?.isChecked) errors.isChecked = "Please accept Terms and Privacy";
    if (!values?.emailaddress) errors.emailaddress = "Business Email is required";
    else if (!/^(?!.+@(gmail|google|yahoo|outlook|hotmail|msn)\..+)(.+@.+\..+)$/.test(values.emailaddress))
        errors.emailaddress = "Business Email is invalid";
    return errors;
};

export const validateProfileEdit = (values) => {
    let errors = {};
    if (values?.country?.country_id === 0 || values?.country === 0) errors.country = "Country is required";
    if (!values?.firstname) errors.firstname = "First Name is required";
    else if (!/[^0-9]$/.test(values?.firstname)) errors.firstname = "First name is invalid";
    else if (!/[^!~@#$%^&*(){}":,.<>";?/\|_]$/.test(values?.firstname)) errors.firstname = "First name is invalid";
    else if (!/^\s*[á-źÁ-Źa-zA-Z][á-źÁ-Źa-zA-Z '-]*$/.test(values?.firstname)) errors.firstname = "First name is invalid";
    else if (values?.firstname?.length < 3) errors.firstname = "First name should be minimum 3 maximum 50";
    else if (values?.firstname?.length > 50) errors.firstname = "First name should be minimum 3 maximum 50";
    if (!values?.lastname) errors.lastname = "Last Name is required";
    else if (!/[^0-9]$/.test(values?.lastname)) errors.lastname = "Last name is invalid";
    else if (!/[^!~@#$%^&*(){}":,.<>";?/\|_]$/.test(values?.lastname)) errors.lastname = "Last name is invalid";
    else if (!/[^0-9\(\)\/\+ \-]+$/.test(values?.lastname)) errors.lastname = "Last name is invalid";
    else if (values?.lastname?.length > 50) errors.lastname = "Last name should be minimum 1 maximum 50";

    return errors;
};
export const validateChangepassword = (values) => {
    let errors = {};
    if (!values?.oldpassword) errors.oldpassword = " Current Password is required";
    if (!values?.newpassword) errors.newpassword = "New Password is required";
    else if (values?.oldpassword?.trim() == values?.newpassword?.trim()) errors.newpassword = " New password cannot be the same as your old password";
    else if (/\s+/g.test(values.newpassword)) errors.newpassword = "Space is not allowed";
    if (!values?.confirmpassword) errors.confirmpassword = "Confirm Password is required";
    //  else if (values.confirmpassword !== values?.newpassword) errors.confirmpassword="Password does not match";
    else if (values.confirmpassword !== values?.newpassword) errors.confirmpassword = "Password doesn't match";

    return errors;
};
export const validateUserAdd = (values) => {
    let errors = {};
    if (!values?.firstname) errors.firstname = "First Name is required";
    else if (!/[^0-9]$/.test(values?.firstname)) errors.firstname = "First name is invalid";
    else if (!/[^!~@#$%^&*(){}":,.<>";?/\|_]$/.test(values?.firstname)) errors.firstname = "First name is invalid";
    else if (!/^\s*[á-źÁ-Źa-zA-Z][á-źÁ-Źa-zA-Z '-]*$/.test(values?.firstname)) errors.firstname = "First name is invalid";
    else if (values?.firstname?.length < 3) errors.firstname = "First name should be minimum 3 to maximum 50";
    else if (values?.firstname?.length > 50) errors.firstname = "First name should be minimum 3 to maximum 50";
    if (!values?.lastname) errors.lastname = "Last Name is required";
    else if (!/[^0-9]$/.test(values?.lastname)) errors.lastname = "Last name is invalid";
    else if (!/[^!~@#$%^&*(){}":,.<>";?/\|_]$/.test(values?.lastname)) errors.lastname = "Last name is invalid";
    else if (!/^\s*[á-źÁ-Źa-zA-Z][á-źÁ-Źa-zA-Z '-]*$/.test(values?.lastname)) errors.lastname = "Last name is invalid";
    else if (values?.lastname?.length > 50) errors.lastname = "Last name should be minimum 1 to maximum 50";
    if (!values?.emailaddress) errors.emailaddress = "Business Email is required";
    else if (!/^(?!.+@(gmail|google|yahoo|outlook|hotmail|msn)\..+)(.+@.+\..+)$/.test(values.emailaddress))
        errors.emailaddress = "Email address is invalid";
    else if (!/^[A-Za-z0-9._-{!~@#$%^&*()}{:"></?,.+=_-`}}]+@[A-Za-z0-9._-]+\.[A-Za-z]{2,}$/.test(values?.emailaddress))
        errors.emailaddress = "Email address is invalid";
    else if (/^[!~@#$%^&*()}{:"></?,.+=_-`}]+@[A-Za-z0-9._-]+\.[A-Za-z]{2,}$/.test(values?.emailaddress))
        errors.emailaddress = "Email address is invalid";
    else if (values?.emailaddress?.length < 6 || values?.emailaddress?.length > 250)
        errors.emailaddress = "Business Email address should be minimum 6 to maximum 250";
    //   if (!values?.role_id) errors.role_id = "Role is required";
    if (!values?.country || values?.country == "Select") errors.country = "Country is required";
    return errors;
};
export const validateUserEdit = (values) => {
    let errors = {};
    if (!values?.firstname) errors.firstname = "First Name is required";
    else if (!/[^0-9]$/.test(values?.firstname)) errors.firstname = "First name is invalid";
    else if (!/[^!~@#$%^&*(){}":,.<>";?/\|_]$/.test(values?.firstname)) errors.firstname = "First name is invalid";
    else if (!/^\s*[á-źÁ-Źa-zA-Z][á-źÁ-Źa-zA-Z '-]*$/.test(values?.firstname)) errors.firstname = "First name is invalid";
    else if (values?.firstname?.length < 3) errors.firstname = "First name should be minimum 3 to maximum 50";
    else if (values?.firstname?.length > 50) errors.firstname = "First name should be minimum 3 to maximum 50";
    if (!values?.lastname) errors.lastname = "Last Name is required";
    else if (!/[^0-9]$/.test(values?.lastname)) errors.lastname = "Last name is invalid";
    else if (!/[^!~@#$%^&*(){}":,.<>";?/\|_]$/.test(values?.lastname)) errors.lastname = "Last name is invalid";
    else if (!/^\s*[á-źÁ-Źa-zA-Z][á-źÁ-Źa-zA-Z '-]*$/.test(values?.lastname)) errors.lastname = "Last name is invalid";
    else if (values?.lastname?.length > 50) errors.lastname = "Last name should be minimum 1 to maximum 50";
    if (!values?.user_emailaddress) errors.user_emailaddress = "Business Email is required";
    else if (!/^[A-Za-z0-9._-{!~@#$%^&*()}{:"></?,.+=_-`}}]+@[A-Za-z0-9._-]+\.[A-Za-z]{2,}$/.test(values?.user_emailaddress))
        errors.user_emailaddress = "Email address is invalid";
    else if (!/^(?!.+@(gmail|google|yahoo|outlook|hotmail|msn)\..+)(.+@.+\..+)$/.test(values.user_emailaddress))
        errors.user_emailaddress = "Email address is invalid";
    else if (/^[!~@#$%^&*()}{:"></?,.+=_-`}]+@[A-Za-z0-9._-]+\.[A-Za-z]{2,}$/.test(values?.user_emailaddress))
        errors.user_emailaddress = "Email address is invalid";
    else if (values?.user_emailaddress?.length < 6 || values?.user_emailaddress?.length > 250)
        errors.user_emailaddress = "Business Email address should be minimum 6 to maximum 250";
    // if (values?.country?.country_id===0) errors.country = "Country is required";
    if (values?.country == 0 || values?.country?.country_id === 0) errors.country = "Country is required";
    return errors;
};
export const validateOrganisationAdd = (values) => {
    let errors = {};
    const actionType = localStorage.getItem("OrgactionType");
    if (!values?.url) errors.url = "Organization Domain Name is required";
    else if (values?.url?.length < 5 || values?.url?.length > 100) {
        errors.url = "Organization Domain Name should be minimum 5 to maximum 100";
    } else if (
        !/^(https?:\/\/)?(www\.)?[a-zA-Z0-9]([a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(\.[a-zA-Z]{2,})+$/i.test(
            values?.url
        )
    )
        errors.url = "Organization Domain Name is invalid ";
    if (!values?.organisation_name) errors.organisation = "Organization Name is required";
    else if (values?.organisation_name?.length < 3 || values?.organisation_name?.length > 250)
        errors.organisation = "Organization Name should be minimum 3 to maximum 250 characters";
    else if (/^\s*$/.test(values?.organisation_name)) errors.organisation_name = "Organization Name is Invalid";
    if (!values?.organisation_code) errors.organisation_code = "Organization Code is required";
    else if (values?.organisation_code?.length < 3 || values?.organisation_code?.length > 25)
        errors.organisation_code = "Organization Code Name should be minimum 3 to maximum 25 characters";
    else if (/^\s*$/.test(values?.organisation_code)) errors.organisation_code = "Organization Code Name is Invalid";
    // else if (/^[0-9 ]\w*$/.test(values?.organisation)) errors.organisation= "Organization Name is invalid";
    //  else if (/^[!@#$%^&*()_{+=?/.,};:']+$/.test(values?.organisation)) errors.organisation="Organization Name is invalid";
    // if (values?.is_parking == null) errors.is_parking = "Parking is Required";
    // if (values?.three_way_matching == null)
    // errors.three_way_matching = "3 Way Matching is Required";
    if (!values?.registration_no) errors.registration_no = "Register Number is required";
    else if (!/^(?=.*[0-9])[A-Za-z0-9-]+$/.test(values?.registration_no)) errors.registration_no = "Register Number is invalid";
    else if (values?.registration_no?.length < 5 || values?.registration_no?.length > 25)
        errors.registration_no = "Register number should be minimum 5 to maximum 25";
    if (!values?.address_line1) errors.address_line1 = "Address is required";
    else if (values?.address_line1?.length < 10 || values?.address_line1?.length > 250)
        errors.address_line1 = "Address should be minimum 10 to maximum 250 characters";
    if (values?.country_id == 0 && actionType == 0) errors.country_id = "Country is required";
    if (!values?.currency_id) errors.currency_id = "Currency is required";
    // if(!values?.logo) errors.logo="Please upload your Organization Logo";
    // if (!values?.zipcode) errors.zipcode = "Zip  Code / Postal Code is required";
    // else if (!/[^-'!~@#$%^&*(){}":,.<>";?/\|_]$/.test(values?.zipcode))errors.zipcode="Zip code / Postal Code is invalid";
    if (!/^[^-e]*$/.test(values?.tolerance)) errors.tolerance = "Tolerance is invalid ";
    return errors;
};
export const validateOrganisationEdit = (values) => {
    let errors = {};
    if (!values?.url) errors.url = "Organization Domain is required";
    else if (values?.url?.length < 5 || values?.url?.length > 100) errors.url = "Organization Domain should be minimum 5 and maximum 100";
    else if (
        !/^(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})*$/.test(
            values?.url
        )
    )
        errors.url = "Organization Domain is invalid ";
    if (!values?.organisation) errors.organisation = "Company Name is required";
    else if (values?.organisation?.length < 3 || values?.organisation?.length > 250)
        errors.organisation = "Company name should be minimum 3 maximum 250 characters";
    else if (/^[0-9 ]\w*$/.test(values?.organisation)) errors.organisation = "Company name is invalid";
    else if (/^[!@#$%^&*()_{+=?/.,};:']+$/.test(values?.organisation)) errors.organisation = "Company name is invalid";
    if (!values?.registration_no) errors.registration_no = "Register Number is required";
    else if (!/^(?=.*[0-9])[A-Za-z0-9-]+$/.test(values?.registration_no)) errors.registration_no = "Register Number is invalid";
    else if (values?.registration_no?.length < 5 || values?.registration_no?.length > 25)
        errors.registration_no = "Register number should be minimum 5 and maximum 25";
    if (!values?.address_line1) errors.address_line1 = "Address is required";
    else if (values?.address_line1?.length < 10 || values?.address_line1?.length > 250)
        errors.address_line1 = "Address should be minimum 10 or maximum 250 characters";
    if (values?.org_country_id == "") errors.org_country_id = "Country is required";
    if (!values?.logo) errors.logo = "Please upload your Organization Logo";
    return errors;
};
export const validateResetPassword = (values) => {
    const params = new URLSearchParams(window.location.search);
    let Type = params.get("type");
    let errors = {};
    if (!values?.newpassword) errors.newpassword = "Password is required";
    else if (
        !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#,-.?<>/|'";:_+={}`~^()])[A-Za-z\d@$|!%*?&#,-.?/'";:_<>+={}`~^()]{8,20}$/.test(
            values.newpassword
        )
    )
        errors.newpassword =
            "Password must be Minimum 8 characters and maximum 20 characters, at least one uppercase letter, one lowercase letter, one number and one special character";
    if (/\s+/g.test(values.newpassword)) errors.newpassword = "Space is not allowed";

    if (!values?.confirmpassword) errors.confirmpassword = "Confirm Password is required";
    //  else if (values.confirmpassword !== values?.newpassword) errors.confirmpassword="Password does not match";
    else if (values.confirmpassword !== values?.newpassword) errors.confirmpassword = "Password doesn't match";
    if (!values?.isChecked && Type == 3) errors.isChecked = "Please accept Terms and Privacy";
    return errors;
};

export const validateEmailConfig = (values) => {
    let errors = {};
    if (!values?.emailaddress) errors.emailaddress = "Email Address is required";
    else if (!/^(?!.+@(gmail|google|yahoo|outlook|hotmail|msn)\..+)(.+@.+\..+)$/.test(values.emailaddress))
        errors.emailaddress = "Email Address is invalid";

    return errors;
};
export const validateFolderConfig = (values) => {
    let errors = {};
    if (!values?.emailaddress) errors.emailaddress = "Email Address is required";
    else if (!/^(?!.+@(gmail|google|yahoo|outlook|hotmail|msn)\..+)(.+@.+\..+)$/.test(values.emailaddress))
        errors.emailaddress = "Email Address is invalid";
    if (!values?.locationurl) errors.locationurl = "Folder name is required";
    return errors;
};

export const validateServerConfig = (values) => {
    let errors = {};
    if (!values?.serverurl) errors.serverurl = "Url is required";
    else if (
        !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            values.emailaddress
        )
    )
        errors.emailaddress = "Email Address is invalid";
    if (!values?.username) errors.username = "Email Address is required";
    if (!values?.password) errors.password = "Password is required";
    else if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(values.password))
        errors.password =
            "Password might be Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character";
    return errors;
};
