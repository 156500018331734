import { useState } from "react";
import Card from "../../../components/UI/Card";
import Input from "../../../components/Input";
import { MapModal } from "./MapModal";
import { ClickAwayListener, Popper } from "@mui/material";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { XmlTarget } from "../../../organisms/DropTarget/xmlTarget";
import _ from "lodash";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setDroppedValues } from "../../../redux/actions/filter";
import Swal from "sweetalert2";

export const XmlTable = ({ currentMode }) => {
    const dispatch = useDispatch();
    const { xmlData } = useSelector((state) => state?.droppedState);
    const [placement, setPlacement] = useState();
    const [popstate, setPopState] = useState({
        value: null,
        anchorEl: null,
        id: null,
    });
    const handleClickAway = () => {
        setPopState({ id: false, anchorEl: false, value: "" });
        setIsClick([]);
    };
    const handleClick = (newPlacement, value, event, id = null) => {
        setPopState({ value, anchorEl: event.currentTarget, id }, (prev) => placement !== newPlacement || !prev);
        setPlacement(newPlacement);
    };
    const handleDrop = (itemValue, areaName, index, id, check) => {
        if (areaName == "xml" && check == false) {
            // Update the content of the dropped row based on the index or ID
            dispatch(setDroppedValues(itemValue, true, areaName));
            dispatch({ type: "UPDATE_XML_ROW_CONTENT", payload: { itemValue, index } });
        }
    };

    const handleChange = (index, field, value) => {
        // Dispatch an action to update the label in Redux state
        dispatch({ type: "UPDATE_XML_ROWS", payload: { index, field, value } });
    };

    function createDynamicJSON(data) {
        const result = {};

        function buildJSON(parent, items) {
            items.forEach((item) => {
                const key = item?.isAttribute ? `-${item?.label}` : item?.label; // Add "-" prefix if isAttribute is true
                const isChild = item?.isChild;
                const refId = item?.id;
                const value = item?.content;
                const isText = item?.isText;

                if (!parent[key]) {
                    if (isChild) {
                        parent[key] = isChild ? {} : ""; // Changed to {} instead of [{}]
                        buildJSON(
                            isChild == false ? parent[key] : parent[key],
                            data?.filter((d) => d?.parent_id === refId)
                        );
                    } else {
                        if (isChild == false && isText) {
                            // Check if isText is true
                            parent["#text"] = value;
                        } else {
                            parent[key] = isChild ? {} : typeof value == "object" ? value?.field_display_name : value;
                            if (isChild) {
                                buildJSON(
                                    parent[key],
                                    data?.filter((d) => d?.parent_id === refId)
                                );
                            }
                        }
                    }
                }
            });
        }

        buildJSON(
            result,
            data?.filter((d) => d?.parent_id === "")
        );

        return result;
    }
    const checkRemove = (idToRemove, name) => {
        Swal.fire({
            text: `Are you sure you want to delete ${!name ? `this` : name} row?`,
            icon: "warning",
            showCloseButton: true,
            showCancelButton: true,
            buttons: {
                cancel: "Cancel",
                confirm: "Confirm",
            },
        }).then((confirm) => {
            if (confirm?.isConfirmed) {
                dispatch({
                    type: "DELETE_XML_ROW",
                    payload: idToRemove,
                });
            }
        });
    };

    const convertedData = {};
    xmlData?.forEach((item) => {
        if (item?.label?.trim() !== "") {
            // Only add items with non-empty labels
            convertedData[item.label] = typeof item.content == "object" ? item?.content?.field_display_name : item?.content;
        }
    });
    const [isClick, setIsClick] = useState([]);
    const [isIndex, setIsIndex] = useState();
    const unique = [...new Set(isClick)];

    function jsonToXml(json, indent = 1) {
        let xml = "";

        // Get keys in order
        const keys = Object.keys(json);

        for (let key of keys) {
            if (json.hasOwnProperty(key)) {
                // Add indentation based on the level of nesting
                const indentation = " ".repeat(indent * 2);

                if (typeof json[key] === "object" && key !== "#text") {
                    let attributes = "";
                    let textContent = "";
                    for (let attrKey in json[key]) {
                        // if (attrKey === "#text") {
                        //     textContent = json[key][attrKey];
                        // } else
                        if (attrKey.startsWith("-")) {
                            attributes += ` ${attrKey.substring(1)}="${json[key][attrKey]}"`;
                        }
                    }
                    if (!key.startsWith("-")) {
                        xml += `${indentation}<${key}${attributes}>\n`;
                        if (textContent) {
                            xml += `${indentation}  ${textContent}\n`;
                        }
                        xml += jsonToXml(json[key], indent + 1);
                        xml += `${indentation}</${key}>\n`;
                    }
                } else if (key === "#text") {
                    xml += `${indentation}${json[key]}\n`;
                } else {
                    if (!key.startsWith("-")) {
                        xml += `${indentation}<${key}>${json[key]}</${key}>\n`;
                    }
                }
            }
        }
        // Wrapping the xml with parent tag
        if (indent === 1) {
            xml = `<?xml version="1.0" encoding="UTF-8"?>\n${xml}</?xml>\n`;
        }

        return xml;
    }

    // console.table(xmlData);
    return (
        <div className="xl:flex space-y-[20px] xl:space-y-0 px-[30px] xl:space-x-[22px]">
            <div className="basis-[70%]">
                <Card className={`border min-h-[702px] max-h-[702px] overflow-y-auto border-${currentMode}-560 rounded-[8px]`}>
                    <div className={`bg-${currentMode}-20 sticky z-[1] top-0 rounded-t-[8px] py-[12px] px-[20px] flex justify-end  text-white`}>
                        <span className="flex cursor-pointer font-interSb" onClick={(e) => handleClick("bottom-end", 1, e)}>
                            Map Fields <img className="ml-2" src="/images/Output-manage/plus.svg" alt="plus" />
                        </span>
                    </div>
                    <div className={`flex sticky z-[1] top-[46px] bg-${currentMode}-card`}>
                        <div className="basis-1/2 px-[20px] py-[9px] border-r border-b">XML Prolog</div>
                        <div className="basis-1/2 px-[20px] py-[9px] border-b">
                            <p>
                                {`<?xml`} {`version=`} <Input className="w-[40px]" />
                                {`encoding=`} <Input className="w-[50px]" />
                                {`?>`}
                            </p>
                        </div>
                    </div>
                    {xmlData?.map((el, i) => {
                        return (
                            <div className="flex items-center">
                                <div
                                    className={`basis-1/2 flex justify-between actionHover ${
                                        unique?.includes(i) ? `clicked` : ``
                                    } px-[20px] py-[9px] border-r border-b`}>
                                    <div className="flex flex-auto">
                                        {el?.isChild == false && el?.isText ? (
                                            ``
                                        ) : (
                                            <img
                                                style={{ marginLeft: el?.indent !== 1 ? `${el?.indent}rem` : `` }}
                                                className={`mr-[10px] `}
                                                src={
                                                    el?.isChild == false && el?.isText
                                                        ? `/images/Output-manage/XML-text.svg`
                                                        : el?.isAttribute == false
                                                        ? `/images/Output-manage/element-ico.svg`
                                                        : `/images/Output-manage/Attribute-ico.svg`
                                                }
                                                alt="parent-co"
                                            />
                                        )}

                                        <Input
                                            name={`label-${i}`}
                                            className={`w-full`}
                                            value={el?.label}
                                            disabled={el?.isChild == false && el?.isText}
                                            placeholder={el?.isChild == false && el?.isText ? `` : `Type here`}
                                            onChange={(e) => handleChange(i, "label", e?.target?.value)}
                                        />
                                    </div>
                                    <div className="flex space-x-[10px] flex-1 mr-[-5px] justify-end actions items-center">
                                        <button className="cursor-pointer" disabled={el?.isChild == false && el?.isText}>
                                            <img src="/images/Output-manage/edit-blk.svg" alt="edit-blk" />
                                        </button>
                                        {el?.isAttribute == false && (
                                            <button
                                                className="cursor-pointer"
                                                onClick={(e) => {
                                                    setIsIndex(el?.id);
                                                    handleClick("bottom-end", 2, e);
                                                    setIsClick([...isClick, i]);
                                                }}>
                                                <img src="/images/Output-manage/plus-blk.svg" alt="plus-blk" />
                                            </button>
                                        )}

                                        <button
                                            className="cursor-pointer"
                                            disabled={el?.parent_id == ""}
                                            onClick={() => checkRemove(el?.id, el?.label)}>
                                            <img src="/images/Output-manage/delete-blk.svg" alt="delete-blk" />
                                        </button>
                                    </div>
                                    {isIndex == el?.id ? (
                                        <ClickAwayListener mouseEvent="onMouseDown" touchEvent="onTouchStart" onClickAway={handleClickAway}>
                                            <Popper
                                                className={`bg-${currentMode}-card  text-${currentMode}-copy min-w-[180px] border-[1px] z-[12] border-${currentMode}-700 shadow-[0px_4px_20px_0px] shadow-${currentMode}-1230 rounded-[10px] reportsPopper `}
                                                open={2 === popstate.value}
                                                anchorEl={popstate.anchorEl}
                                                id={2}
                                                placement={placement}>
                                                <ul className="space-y-[4px] py-[15px]">
                                                    <>
                                                        {el?.isText == false && (
                                                            <li
                                                                className={`cursor-pointer py-1 px-[20px] hover:bg-${currentMode}-960`}
                                                                onClick={() =>
                                                                    dispatch({
                                                                        type: "ADD_NEW_ELEMENT_XML",
                                                                        payload: { isIndex: el?.id, int: el?.indent, parent: el?.parent_id },
                                                                    })
                                                                }>
                                                                Add New Element
                                                            </li>
                                                        )}

                                                        <li
                                                            className={`cursor-pointer py-1 px-[20px] hover:bg-${currentMode}-960`}
                                                            onClick={() =>
                                                                dispatch({
                                                                    type: "ADD_NEW_ATTRIBUTE_XML",
                                                                    payload: { isIndex: el?.id, int: el?.indent, parent: el?.parent_id },
                                                                })
                                                            }>
                                                            Add New Attribute
                                                        </li>
                                                        {el?.id == 1 ||
                                                            (el?.isText == false && el?.isElement == false && (
                                                                <li
                                                                    className={`cursor-pointer py-1 px-[20px] hover:bg-${currentMode}-960`}
                                                                    onClick={() =>
                                                                        dispatch({
                                                                            type: "ADD_NEW_TEXT_XML",
                                                                            payload: { isIndex: el?.id, int: el?.indent, parent: el?.parent_id },
                                                                        })
                                                                    }>
                                                                    Add Inner Text
                                                                </li>
                                                            ))}
                                                    </>
                                                </ul>
                                            </Popper>
                                        </ClickAwayListener>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                <div className="basis-1/2 border-b">
                                    <DndProvider backend={HTML5Backend}>
                                        <XmlTarget
                                            name={`content-${i}`}
                                            mainData={el}
                                            data={typeof el?.content == "object" ? `@${el?.content?.field_display_name}` : el?.content}
                                            onDrop={handleDrop}
                                            handleChange={handleChange}
                                            index={i}
                                            areaName="xml"
                                            currentMode={currentMode}
                                        />
                                    </DndProvider>
                                </div>
                            </div>
                        );
                    })}

                    <ClickAwayListener mouseEvent="onMouseDown" touchEvent="onTouchStart" onClickAway={handleClickAway}>
                        <Popper
                            className={`bg-${currentMode}-card  text-${currentMode}-copy max-w-[270px] border-[1px] z-[12] border-${currentMode}-700 shadow-[0px_4px_20px_0px] shadow-${currentMode}-1230 rounded-[10px] reportsPopper `}
                            open={1 === popstate.value}
                            anchorEl={popstate.anchorEl}
                            id={1}
                            placement={placement}>
                            <MapModal currentMode={currentMode} />
                        </Popper>
                    </ClickAwayListener>
                </Card>
            </div>
            <div className="basis-[30%]">
                <div className={`!bg-[#F3FFFE] border overflow-y-auto min-h-[702px] max-h-[702px] border-${currentMode}-560 rounded-[8px]`}>
                    <div className={`bg-${currentMode}-20 rounded-t-[8px] py-[12px] px-[20px] text-white`}>
                        <span className="flex font-interSb">Results</span>
                    </div>
                    <div className="p-[20px] max-w-[400px]">
                        <pre className="whitespace-pre">{jsonToXml(createDynamicJSON(xmlData))}</pre>
                    </div>
                </div>
            </div>
        </div>
    );
};
