import axios from "axios";
import configMyiQ from "../../configuration";
import {
    UPLOAD_INVOICE_FAILURE,
    UPLOAD_INVOICE_REQUEST,
    UPLOAD_INVOICE_SUCCESS,
} from "../types/types";
import authHeader from "./auth-header";
import { checkApiStatus } from "../../utils/helpers";
const API_URL = process.env?.REACT_APP_API_URL;

//adduser

export const invoiceUpload = (data) => async (dispatch) => {
    try {
        dispatch({ type: UPLOAD_INVOICE_REQUEST });
        axios
            .post(`${API_URL}Invoice/ManualUploadInvoice`, data, {
                headers: authHeader(),
            })
            .then((response) => {
                dispatch({
                    type: UPLOAD_INVOICE_SUCCESS,
                    payload: response.data,
                });
            })
            .catch(function (error) {
                if (error?.response?.status == 401) {
                    checkApiStatus();
                }
                dispatch({
                    payload: { error: error.message },
                    type: UPLOAD_INVOICE_FAILURE,
                });
            });
    } catch (error) {
        dispatch({
            type: UPLOAD_INVOICE_FAILURE,
        });
    }
};
