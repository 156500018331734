import styles from "./index.module.scss";
const VerticalStepper = (props) => {
    return (
        <>
            <div className={`${styles.step}`}>
                <div className={styles[`v-stepper`]}>
                    <div className={`${styles.circle} ${styles[props.dynamic]} VerticalCircle`}><img src={props?.img} /></div>
                    <div className={`${styles.line} VerticalLine`}></div>
                </div>
                <div className={styles.content}>
                    {props?.card}
                </div>
            </div>
        </>
    );
};

export default VerticalStepper;