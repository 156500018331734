import { Tooltip } from "@mui/material";

export const LightTooltip = ({ children, title }) => {
    return (
        <Tooltip
            title={title}
            disablePortal
            placement="bottom"
            disableInteractive
            PopperProps={{
                sx: {
                    "& .MuiTooltip-tooltip": {
                        background: "#DAF9F4",
                        color: "#555555",
                        borderRadius: "5px",
                        marginTop: "0 !important",
                    },
                },
            }}>
            {children}
        </Tooltip>
    );
};
