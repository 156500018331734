import { Autocomplete, Checkbox, Chip, TextField, ClickAwayListener } from "@mui/material";
import ReactQuill from "react-quill";
import { decryptId, encryptId } from "../../../utils/helpers";
import { useState, useEffect, useContext, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Input from "../../../components/Input";
import Button from "../../../components/Button";
import Card from "../../../components/UI/Card";
import { getEmailTemplatePlaceHolderList } from "../../../redux/actions/emailtask";
const NotificationAllocation = (props) => {
	const { selectedId, setSelectedId, check, setCheck, currentMode } = props;
	const [notificationVal, setNotificationVal] = useState();
	useEffect(() => {
		if (selectedId !== null) {
			check.find((ele, i) => {
				if (i == selectedId) {
					setNotificationVal(ele?.notification);
					setViewEmailBcc(ele?.notification?.email_bcc?.length > 0 ? true : false);
				}
			});
		}
	}, [selectedId]);
	const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	const dispatch = useDispatch();
	const [title, setTitle] = useState(notificationVal?.notification_title);
	const [notificationenable, setnotificationEnable] = useState(notificationVal?.push_flag);
	const [error1, setError] = useState(false);
	const [message, setMessage] = useState(notificationVal?.push_message);
	const [Emailenable, setemailEnable] = useState(notificationVal?.email_flag);
	const [subject, setSubject] = useState("");
	const [emailMessage, setEmailmessage] = useState(notificationVal?.email_body);
	const CC = notificationVal?.email_cc;
	const BCC = notificationVal?.email_bcc;
	const emailcc = CC?.map((i) => decryptId(i));
	const emailbcc = BCC?.map((i) => decryptId(i));
	const [offset, setOffset] = useState(null);
	//  BCC?.map((i) => decryptId(i));
	const [emailCC, setEmailCC] = useState(emailcc);
	const [emailBcc, setEmailbcc] = useState(emailbcc);
	const [inputValue, setInputValue] = useState("");
	const [inputValue2, setInputValue2] = useState("");
	useEffect(() => {
		if (selectedId !== null) {
			check.find((ele, i) => {
				if (i == selectedId) {
					setNotificationVal(ele?.notification);
				}
			});
		}
	}, [selectedId]);
	useEffect(() => {
		setEmailCC(notificationVal?.email_cc);
		setEmailbcc(notificationVal?.email_bcc);
		setTitle(notificationVal?.notification_title == undefined ? "" : notificationVal?.notification_title);
		setnotificationEnable(notificationVal?.push_flag);
		setMessage(notificationVal?.push_message == null ? "" : notificationVal?.push_message);
		setemailEnable(notificationVal?.email_flag);
		setSubject(notificationVal?.email_subject == null ? "" : notificationVal?.email_subject);
		setEmailmessage(notificationVal?.email_body);
		if (placeHolData?.length == 0 || !placeHolData) {
			dispatch(getEmailTemplatePlaceHolderList());
		}
	}, [notificationVal, notificationVal?.email_cc, notificationVal?.email_bcc, notificationVal?.push_message, dispatch]);
	const handlenotificationcheckChange = (event, id) => {
		// Assuming 'id' corresponds to either 'push_flag' or 'email_flag'
		const updatedObject = { ...notificationVal }; // Copy the main object
		if (event.target.name === "push_flag") {
			updatedObject.push_flag = event.target.value;
			updatedObject.push_title = event.target.value == false ? "" : updatedObject?.push_title;
			updatedObject.push_message = event.target.value == false ? "" : updatedObject?.push_message;
			setnotificationEnable(event.target.value);
		} else if (event.target.name === "email_flag") {
			updatedObject.email_flag = event.target.value;
			updatedObject.email_cc = event.target.value == false ? [] : updatedObject.email_cc;
			updatedObject.email_bcc = event.target.value == false ? [] : updatedObject.email_bcc;
			updatedObject.email_subject = event.target.value == false ? "" : updatedObject.email_subject;
			updatedObject.email_body = event.target.value == false ? "" : updatedObject.email_body;
			setemailEnable(event.target.value);
		}
		// You can add additional conditions for other properties if needed
		// Update the main object with the changes
		setNotificationVal(updatedObject);
		setCheck((prevCheck) => {
			// Assuming selectedId is the index where you want to update the user_set property
			const updatedCheck = prevCheck.map((ele, i) => {
				if (i === selectedId) {
					return {
						...ele,
						notification: {
							...ele?.notification,
							push_flag: event.target.name === "push_flag" ? event.target.value : ele?.notification?.push_flag,
							push_title: event.target.name === "push_flag" && event.target.value == false ? "" : ele?.notification?.push_title,
							push_message: event.target.name === "push_flag" && event.target.value == false ? "" : ele?.notification?.push_message,
							email_flag: event.target.name === "email_flag" ? event.target.value : ele?.notification?.email_flag,
							email_subject: event.target.name === "email_flag" && event.target.value == false ? "" : ele?.notification?.email_subject,
							email_body: event.target.name === "email_flag" && event.target.value == false ? "" : ele?.notification?.email_body,
							email_bcc: event.target.name === "email_flag" && event.target.value == false ? [] : ele?.notification?.email_bcc,
							email_cc: event.target.name === "email_flag" && event.target.value == false ? [] : ele?.notification?.email_cc,
						},
					};
				}
				return ele;
			});
			return updatedCheck;
		});
	};
	const handleNotificationTitle = (e) => {
		setNotificationVal((prevVal) => ({
			...prevVal,
			push_title: e.target.value,
		}));
		setCheck((prevCheck) => {
			// Assuming selectedId is the index where you want to update the user_set property
			const updatedCheck = prevCheck.map((ele, i) => {
				if (i === selectedId) {
					return {
						...ele,
						notification: {
							...ele?.notification,
							push_title: e.target.value,
						},
					};
				}
				return ele;
			});
			return updatedCheck;
		});
	};
	const orgName = props?.orgName;
	const notification_enabled = props?.notification_enabled;
	const email_enabled = props?.email_enabled;
	const [viewEmailBcc, setViewEmailBcc] = useState(false);
	const messageRef = useRef(null);
	const ReactQuillRef = useRef(null);
	const modules = {
		toolbar: [["bold", "italic", "underline", "blockquote"], [{ list: "ordered" }, { list: "bullet" }], ["clean"]],
	};
	const formats = [
		// "header",
		"bold",
		"italic",
		"underline",
		// "strike",
		"blockquote",
		"list",
		"bullet",
		// "indent",
		"link",
		"image",
	];
	const { data: placeHolData } = useSelector((state) => state?.emailTemplatePlaceholderListState);
	const invoicePlaceHolderList =
		props?.documentGroup == 1
			? placeHolData?.filter((el) => el?.document_group == 1)
			: props?.documentGroup == 2
			? placeHolData?.filter((el) => el?.document_group == 2)
			: props?.documentGroup == 3
			? placeHolData?.filter((el) => el?.document_group == 3)
			: props?.documentGroup == 4
			? placeHolData?.filter((el) => el?.document_group == 4)
			: [];
	const [showing, setShowing] = useState(null);
	const [showing2, setShowing2] = useState(null);
	const [focused, setFocused] = useState("");

	const handleClick = (event) => {
		if (orgName !== 0 && notificationenable == true) {
			setShowing(true);
		}
	};
	const ReacthandleClick = (event) => {
		if (Emailenable) {
			setShowing2(true);
		}
	};
	const handleClickAway = (e) => {
		setShowing(false);
		setShowing2(false);
	};

	const handleInvoiceClick = (name) => {
		const percent = "%" + name + "%";
		const newContent = message + percent;
		setMessage(newContent);
		setNotificationVal((prevVal) => ({
			...prevVal,
			push_message: newContent,
		}));
		setCheck((prevCheck) => {
			// Assuming selectedId is the index where you want to update the user_set property
			const updatedCheck = prevCheck.map((ele, i) => {
				if (i === selectedId) {
					return {
						...ele,
						notification: {
							...ele?.notification,
							push_message: newContent,
						},
					};
				}
				return ele;
			});
			return updatedCheck;
		});
		//  messageRef?.current?.editor?.setData(newContent);
	};
	const handleNotificationMessage = (e) => {
		setMessage(e.target.value);
		setNotificationVal((prevVal) => ({
			...prevVal,
			push_message: e.target.value,
		}));
		setCheck((prevCheck) => {
			// Assuming selectedId is the index where you want to update the user_set property
			const updatedCheck = prevCheck.map((ele, i) => {
				if (i === selectedId) {
					return {
						...ele,
						notification: {
							...ele?.notification,
							push_message: e.target.value,
						},
					};
				}
				return ele;
			});
			return updatedCheck;
		});
	};
	const handleEmailSubject = (e) => {
		setSubject(e.target.value);
		setNotificationVal((prevVal) => ({
			...prevVal,
			email_subject: e.target.value,
		}));
		setCheck((prevCheck) => {
			// Assuming selectedId is the index where you want to update the user_set property
			const updatedCheck = prevCheck.map((ele, i) => {
				if (i === selectedId) {
					return {
						...ele,
						notification: {
							...ele?.notification,
							email_subject: e.target.value,
						},
					};
				}
				return ele;
			});
			return updatedCheck;
		});
	};
	const handleMessageClick = (name) => {
		const percent = "%" + name + "%";
		const replacemessage = emailMessage?.replace(null, "");
		const NewMessage = (replacemessage == undefined ? "" : replacemessage) + percent;
		setEmailmessage(NewMessage);
		// ReactQuillRef?.current?.editor?.setData(NewMessage);
	};
	//////////////////////////////////////////////
	const onFocus = (event) => {
		setFocused("message");
	};
	const ReactFocus = () => {
		setFocused("React");
	};
	// Example of a drop event handler
	const handleDrop = (event) => {
		event.preventDefault();
		const data = "%" + event.dataTransfer.getData("text/plain") + "%";
		const inputElement = event.target;
		const currentValue = inputElement.value;
		// Append the dropped data to the existing value, separated by a space
		const newValue = currentValue ? `${currentValue} ${data}` : data;
		// Update the state with the new value
		setMessage(newValue);
		setNotificationVal((prevVal) => ({
			...prevVal,
			push_message: newValue,
		}));
		setCheck((prevCheck) => {
			// Assuming selectedId is the index where you want to update the user_set property
			const updatedCheck = prevCheck.map((ele, i) => {
				if (i === selectedId) {
					return {
						...ele,
						notification: {
							...ele?.notification,
							push_message: newValue,
						},
					};
				}
				return ele;
			});
			return updatedCheck;
		});
	};
	const handleEmailCC = (newVal) => {
		setEmailCC(newVal);
		setNotificationVal((prevVal) => ({
			...prevVal,
			email_cc: newVal,
		}));
		setCheck((prevCheck) => {
			// Assuming selectedId is the index where you want to update the user_set property
			const updatedCheck = prevCheck.map((ele, i) => {
				if (i === selectedId) {
					return {
						...ele,
						notification: {
							...ele?.notification,
							email_cc: newVal,
						},
					};
				}
				return ele;
			});
			return updatedCheck;
		});
	};
	const handleEmailBCC = (newVal) => {
		setEmailbcc(newVal);
		setNotificationVal((prevVal) => ({
			...prevVal,
			email_bcc: newVal,
		}));
		setCheck((prevCheck) => {
			// Assuming selectedId is the index where you want to update the user_set property
			const updatedCheck = prevCheck.map((ele, i) => {
				if (i === selectedId) {
					return {
						...ele,
						notification: {
							...ele?.notification,
							email_bcc: newVal,
						},
					};
				}
				return ele;
			});
			return updatedCheck;
		});
	};
	const handleOpenBcc = () => {
		setViewEmailBcc(true);
	};
	const handleEmailBody = (e) => {
		setEmailmessage(e);
		setNotificationVal((prevVal) => ({
			...prevVal,
			email_body: e,
		}));
		setCheck((prevCheck) => {
			// Assuming selectedId is the index where you want to update the user_set property
			const updatedCheck = prevCheck.map((ele, i) => {
				if (i === selectedId) {
					return {
						...ele,
						notification: {
							...ele?.notification,
							email_body: e,
						},
					};
				}
				return ele;
			});
			return updatedCheck;
		});
	};

	const handleBlur = () => {
		const editor = ReactQuillRef.current.getEditor();

		const selection = editor.getSelection();
		const cursorPosition = selection ? selection.index : -1; // -1 if no selection
		// Get the container text
		const containerText = editor.getText(cursorPosition);
		setOffset(cursorPosition);
	};

	const handleEmailDrop = (e) => {
		e.preventDefault();
		const data = "%" + e.dataTransfer.getData("text/plain") + "%";
		// Access the Quill instance using the quillRef
		const quill = ReactQuillRef.current.getEditor();
		const currentContent = quill.getText();
		const selection = quill.getSelection();
		const insertIndex = selection ? selection.index : currentContent.length;
		// Append the dropped data to the existing content
		const updatedContent = currentContent.slice(0, insertIndex) + data + currentContent.slice(insertIndex);
		quill.setText(updatedContent); // Update the content in Quill
		// Set the editorHtml state with the updated content
		setEmailmessage(updatedContent);
		setNotificationVal((prevVal) => ({
			...prevVal,
			email_body: updatedContent,
		}));
		setCheck((prevCheck) => {
			// Assuming selectedId is the index where you want to update the user_set property
			const updatedCheck = prevCheck.map((ele, i) => {
				if (i === selectedId) {
					return {
						...ele,
						notification: {
							...ele?.notification,
							email_body: updatedContent,
						},
					};
				}
				return ele;
			});
			return updatedCheck;
		});
	};
	return (
		<>
			<Card
				tabIndex={0}
				className={`${
					orgName !== 0 ? `` : `opacity-75`
				} basis-[35%] p-4 xl:p-[20px] mt-3 border-[1px] border-t-[0] border-b-[0] border-${currentMode}-600 h-[calc(100vh_-_40rem)] xl:h-[calc(100vh_-_17rem)] overflow-y-auto rounded-t-[8px] rounded-b-[0] ${
					currentMode === "dark" ? "" : "shadow-[0px_4px_20px_rgba(238,238,238,0.5)]"
				}`}
			>
				<>
					<div>
						<div className={`border-b-[1px] flex items-start justify-between border-${currentMode}-700`}>
							<div className="flex items-start flex-auto">
								<Checkbox
									tabIndex={0}
									name="push_flag"
									size="small"
									// disabled={orgName !== 0}
									checked={notificationVal?.push_flag ? true : false}
									// value={notificationenable}
									className="mr-2"
									onChange={(event) => {
										handlenotificationcheckChange({
											target: {
												name: event.target.name,
												value: event.target.checked,
											},
										});
									}}
									sx={{
										padding: 0,
										color: "rgba(85,85,85,0.7)",
										"&.Mui-checked": {
											color: "#028C78",
										},
									}}
								/>
								<div>
									<span className="mb-1 inline-block">Push Notification</span>
									<p className={`text-${currentMode}-1280 text-xs mb-[15px]`}>
										Push notifications are messages or alerts that are displayed in the user screen.
									</p>
								</div>
							</div>
							<div className="flex-initial">
								<img
									title="Notification"
									src="/images/main/notification-icon.svg"
									alt="notification-icon"
								/>
							</div>
						</div>
						<div className={`${notificationenable ? "" : "opacity-30"}`}>
							<div className={`border-b-[1px] pb-[0] pt-[20px] border-${currentMode}-700`}>
								<span className="block">Title</span>
								<Input
									tabIndex={0}
									type="text"
									name="push_title"
									placeholder="Enter your notification title"
									className={`w-full pt-[20px] border-${currentMode}-830 bg-transparent`}
									disabled={orgName !== 0 && notificationenable ? false : true}
									value={notificationVal?.push_title}
									onChange={(e) => handleNotificationTitle(e)}
								/>
							</div>
							<div className={`border-b-[1px] mx-[-20px] px-[20px] py-[20px] border-${currentMode}-700  `}>
								<div className="flex relative">
									<span className="block">Message</span>
									<div className="ml-[45px]">
										<span
											onClick={handleClick}
											// aria-describedby={id}
											className={`flex absolute  right-[5px] top-[1px] block ${
												notificationenable == true && orgName !== 0 ? "cursor-pointer" : "cursor-not-allowed"
											} `}
										>
											Insert Placeholders{" "}
											<img
												className="ml-2 convertWhite"
												src="/images/common/chevron-grey.svg"
												alt="chevronDown"
											/>
										</span>
										{showing && (
											<ClickAwayListener
												mouseEvent="onMouseDown"
												touchEvent="onTouchStart"
												onClickAway={(e) => handleClickAway(e)}
											>
												<div
													className={`bg-${currentMode}-card absolute top-[40px] right-0 border-${currentMode}-1020 border-[1px] z-[2] mt-[32px]  ${
														currentMode !== "dark" ? `shadow-[0px_4px_20px_rgba(85,85,85,0.2)]` : ``
													}  rounded-[10px]`}
												>
													<div className="grid grid-cols-1 gap-4">
														<div className={`py-[20px]`}>
															<span
																draggable="false"
																className="font-interSb mb-2 block pointer-events-none px-[30px]"
															>
																Invoice
															</span>

															<ul className="cursor-pointer">
																{invoicePlaceHolderList?.length !== 0 ? (
																	invoicePlaceHolderList?.map((el, i) => {
																		return (
																			<li
																				onDragStart={(event) => {
																					event.dataTransfer.setData(
																						"text/plain",
																						el.placeholder_display_name
																					);
																				}}
																				onDragEnd={(event) => {
																					event.dataTransfer.clearData();
																				}}
																				className={`hover:bg-${currentMode}-960 px-[30px] userDrag py-2 w-full`}
																				key={i}
																				draggable="true"
																				onClick={() => handleInvoiceClick(el.placeholder_display_name)}
																			>
																				{el.placeholder_display_name}
																			</li>
																		);
																	})
																) : (
																	<div className="px-[20px]">No data found</div>
																)}
															</ul>
														</div>
													</div>
												</div>
											</ClickAwayListener>
										)}
									</div>
								</div>

								<div>
									<Input
										placeholder="Enter your message"
										onDragOver={(event) => event.preventDefault()}
										onDrop={handleDrop}
										value={message}
										tabIndex={0}
										autoFocus
										id={"targetInput"}
										onFocus={onFocus}
										ref={messageRef}
										name="push_message"
										onChange={(e) => handleNotificationMessage(e)}
										disabled={orgName !== 0 && notificationenable ? false : true}
										type="text"
										className={`border-[1px] rounded-[8px] py-2 px-[12px] w-full border-${currentMode}-840 bg-${currentMode}-810 mt-3.5`}
									/>
								</div>
							</div>
						</div>

						<div className={`border-b-[1px] flex items-start py-[20px] justify-between border-${currentMode}-700`}>
							<div className="flex items-start">
								<Checkbox
									name="email_flag"
									size="small"
									tabIndex={0}
									onChange={(event) => {
										handlenotificationcheckChange({
											target: {
												name: event.target.name,
												value: event.target.checked,
											},
										});
									}}
									checked={notificationVal?.email_flag ? true : false}
									disabled={orgName == 0 || undefined}
									className="mr-2"
									//   onChange={formik.handleChange}
									sx={{
										padding: 0,
										color: "rgba(85,85,85,0.7)",
										"&.Mui-checked": {
											color: "#028C78",
										},
									}}
								/>
								<div>
									<span className="mb-1 inline-block">Email</span>
									<p className={`text-${currentMode}-1280 text-xs`}>
										Enable the email checkbox for configuring the email notifications.
									</p>
								</div>
							</div>
							<div>
								<img
									title="Email"
									src="/images/main/mail-icon.svg"
									alt="email-icon"
								/>
							</div>
						</div>
						<div className={`${orgName !== 0 && Emailenable ? "" : "opacity-30"}`}>
							<div className={`flex w-full border-b-[1px] items-center border-${currentMode}-700 pt-[22px] justify-between`}>
								<span className="mr-3 block">Cc:</span>
								<Autocomplete
									tabIndex={0}
									multiple
									sx={{
										"&.MuiAutocomplete-root .MuiInputBase-root": {
											border: 0,
											marginLeft: "0",
											overflow: "hidden",
										},
										".MuiInputBase-input": {
											color: `${currentMode == "dark" ? "white" : "black"} !important`,
										},
										"&.MuiFormHelperText-root .MuiFormHelperText-root": {
											marginLeft: "35px",
										},
										"& .MuiChip-label": {
											color: `${currentMode == "dark" ? "white" : "black"}`,
										},
									}}
									id="tags-filled"
									options={[]}
									disabled={!Emailenable}
									value={emailCC == undefined ? [] : emailCC}
									inputValue={inputValue}
									// disableCloseOnSelect
									//disableClearable
									// autoHighlight={true}
									onInputChange={(e, newValue) => {
										setInputValue(newValue);
									}}
									onChange={(e, newval, reason) => {
										const errorEmail = newval.find((email) => !regex.test(email));
										if (errorEmail) {
											// set value displayed in the textbox
											setInputValue(errorEmail);
											setError(true);
										} else {
											setError(false);
										}
										handleEmailCC(newval?.filter((email) => regex.test(email)));

										//  (newval);
									}}
									disableCloseOnSelect
									autoHighlight={true}
									freeSolo
									style={{ width: "100%", border: 0 }}
									limitTags={1}
									renderTags={(value, getTagProps) =>
										value &&
										value?.map((option, index) => {
											// let ValueOption = encryptId(option);
											// setEmailCC(ValueOption)
											return (
												<Chip
													key={index}
													variant="outlined"
													label={option}
													{...getTagProps({ index })}
													onDelete={() => {
														handleEmailCC(emailCC?.filter((e) => e !== option));
													}}
												/>
											);
										})
									}
									renderInput={(params) => (
										<TextField
											className={`overflow-y-hidden`}
											fullWidth
											{...params}
											placeholder="Enter the Cc email address."
										/>
									)}
								/>
								{!viewEmailBcc ? (
									<Button
										className="p-0"
										disabled={!Emailenable}
										onClick={handleOpenBcc}
									>
										<span className="block">Bcc</span>
									</Button>
								) : (
									""
								)}
							</div>
							{viewEmailBcc ? (
								<div className={`flex w-full items-center border-b-[1px] border-${currentMode}-910 pt-[22px] justify-between`}>
									<span className="mr-3 block">Bcc:</span>
									<Autocomplete
										multiple
										disablePortal
										sx={{
											"&.MuiAutocomplete-root .MuiInputBase-root": {
												border: 0,
												marginLeft: "0",
												overflow: "hidden",
											},
											".MuiInputBase-input": {
												color: `${currentMode == "dark" ? "white" : "black"} !important`,
											},
											"&.MuiFormHelperText-root .MuiFormHelperText-root": {
												marginLeft: "35px",
											},
											"& .MuiChip-label": {
												color: `${currentMode == "dark" ? "white" : "black"}`,
											},
										}}
										id="tags-filled"
										tabIndex={0}
										options={[]}
										value={emailBcc == undefined ? [] : emailBcc}
										inputValue={inputValue2}
										// disableCloseOnSelect
										//disableClearable
										// autoHighlight={true}
										onInputChange={(e, newValue) => {
											setInputValue2(newValue);
										}}
										onChange={(e, newval, reason) => {
											const errorEmail = newval.find((email) => !regex.test(email));
											if (errorEmail) {
												// set value displayed in the textbox
												setInputValue2(errorEmail);
												setError(true);
											} else {
												setError(false);
											}
											handleEmailBCC(newval?.filter((email) => regex.test(email)));

											//  (newval);
										}}
										disableCloseOnSelect
										disabled={!Emailenable}
										freeSolo
										style={{ width: "100%", border: 0 }}
										limitTags={1}
										renderTags={(value, getTagProps) =>
											value &&
											value?.map((option, index) => {
												// let ValueOptions = encryptId(option);
												// bccreceivers.push(ValueOptions);
												return (
													<Chip
														key={index}
														variant="outlined"
														label={option}
														{...getTagProps({
															index,
														})}
														onDelete={() => {
															handleEmailBCC(emailBcc?.filter((e) => e !== option));
														}}
													/>
												);
											})
										}
										renderInput={(params) => (
											<TextField
												fullWidth
												{...params}
												placeholder="Enter the Bcc email address."
											/>
										)}
									/>
								</div>
							) : (
								""
							)}

							<div className="py-[20px]">
								<span className="block mb-2">Subject</span>
								<Input
									tabIndex={0}
									value={subject}
									onChange={(e) => handleEmailSubject(e)}
									className={`border-[1px] rounded-[8px] py-2 px-[12px] w-full border-${currentMode}-840 bg-${currentMode}-810`}
									placeholder="Enter your subject"
									disabled={!Emailenable}
								/>
							</div>
						</div>

						<div className={` allocate-textarea relative ${orgName !== 0 && Emailenable ? "" : "opacity-30"}`}>
							<span className="block">Message</span>
							<div className="ml-[45px]">
								<span
									onClick={ReacthandleClick}
									// aria-describedby={id}
									className={`flex absolute right-[5px] top-[1px] block  ${Emailenable ? "cursor-pointer" : "cursor-not-allowed"} `}
								>
									Insert Placeholders{" "}
									<img
										className="ml-2 convertWhite"
										src="/images/common/chevron-grey.svg"
										alt="chevronDown"
									/>
								</span>
								{showing2 && (
									<ClickAwayListener
										mouseEvent="onMouseDown"
										touchEvent="onTouchStart"
										onClickAway={(e) => handleClickAway(e)}
									>
										<div
											className={`bg-${currentMode}-card absolute top-[-410px] right-0 border-${currentMode}-1020 border-[1px] z-[8] mt-[32px]  ${
												currentMode !== "dark" ? `shadow-[0px_4px_20px_rgba(85,85,85,0.2)]` : ``
											}  rounded-[10px]`}
										>
											<div className="grid grid-cols-1 gap-4">
												<div className={`py-[20px]`}>
													<span
														draggable="false"
														className="font-interSb mb-2 block pointer-events-none px-[30px]"
													>
														Invoice
													</span>
													<ul className="cursor-pointer">
														{invoicePlaceHolderList?.length !== 0 ? (
															invoicePlaceHolderList?.map((el, i) => {
																return (
																	<li
																		onDragStart={(event) => {
																			event.dataTransfer.setData("text/plain", el.placeholder_display_name);
																		}}
																		onDragEnd={(event) => {
																			event.dataTransfer.clearData();
																		}}
																		className={`hover:bg-${currentMode}-960 px-[30px] userDrag py-2 w-full`}
																		key={i}
																		draggable="true"
																		onClick={() => handleMessageClick(el.placeholder_display_name)}
																	>
																		{el.placeholder_display_name}
																	</li>
																);
															})
														) : (
															<div className="px-[20px]">No data found</div>
														)}
													</ul>
												</div>
											</div>
										</div>
									</ClickAwayListener>
								)}
							</div>

							<div onDrop={handleEmailDrop}>
								<ReactQuill
									tabIndex={0}
									onDragOver={(event) => event.preventDefault()}
									theme="snow"
									onFocus={ReactFocus}
									readOnly={!Emailenable}
									ref={ReactQuillRef}
									style={{ borderRadius: "8px", marginTop: "18px" }}
									value={emailMessage}
									formats={formats}
									onBlur={(event) => {
										handleBlur();
									}}
									modules={modules}
									disabled={!Emailenable}
									onChange={(e) => handleEmailBody(e)}
									onSelectionChange={(selection) => {
										const cursorPosition = selection ? selection.index : -1;
									}}
									placeholder="Enter your message..."
								/>
							</div>
						</div>
					</div>
				</>
			</Card>
		</>
	);
};

export default NotificationAllocation;
