import {
    Checkbox,
    ClickAwayListener,
    Fade,
    FormControl,
    FormControlLabel,
    Input,
    MenuItem,
    Paper,
    Popper,
    Select,
    TextField,
    Tooltip,
} from "@mui/material";
import ReactDOM from "react-dom";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../../components/Button";
import Card from "../../../components/UI/Card";
import { decryptId, encryptId } from "../../../utils/helpers";
import { useState, useEffect, useContext, useMemo, useTransition } from "react";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import { cartToggleAction } from "../../../redux/actions/filter";
import { InvoiceList, InvoiceStatusRejected, InvoiceStatusApproved, invoiceVendorList } from "../../../redux/actions/invoice";
import UseForm from "../../../Hooks/useForm";
import { invoiceCountTotal } from "../../../redux/actions/invoice";
import { getCurrentUser } from "../../../redux/actions/auth";
import React from "react";
import Loader from "../../../components/Loader";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import moment from "moment";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { ThemeContext } from "../../../Hooks/Themes/Themeprovider";
import TransferInvoice from "./TransferInvoice";
import StatusInfoComponent from "./StatusInfoComponent";
import { getWorkFlowFilter } from "../../../redux/actions/workflow-automation";
import UseTour from "../../../Hooks/ProductTour";
import { Trans, useTranslation } from "react-i18next";
// import { tourConfig } from "../../../utils/helpers.js";
let options = null;
let Tags;
const Table = (props) => {
    let location = useLocation();
    const { t } = useTranslation(["invoiceList"]);
    const orgData = useSelector((state) => state?.ListorganisationDropDownState);
    const OrgListData = orgData?.viewData?.length == 1;
    const refresh = props?.refresh;
    const [selected, setSelected] = useState("default");
    const [SatusValue, setStatusValue] = useState([]);
    const StatusCount = SatusValue?.filter((el) => el?.isCheck == true);
    const ChangeOrg = localStorage.getItem("ChangeOrg");
    // useEffect(() => {
    //     props?.setstatusOptionArray(location.pathname === "/approve" ? [7] : [3847]);
    // }, [location.pathname]);
    const date = new Date();
    const d = date.toLocaleDateString("en-GB");
    const { handleSubmit } = UseForm(submit);
    const label = { inputProps: { "aria-label": "Checkbox demo" } };
    const dispatch = useDispatch();
    const [popstate, setPopState] = useState({ id: null, anchorEl: null });
    const [open, setOpen] = useState(false);
    const [placement, setPlacement] = useState();
    const [currentPage, setCurrentPage] = useState();
    const [openTransfer, setOpenTransfer] = useState(false);
    const [currentPageItem, changePageItem] = useState(10);
    const [approvedList, approvedListItem] = useState();
    const [rejectList, rejectListItem] = useState();
    const [selectedValue, setSelectedValue] = useState("");
    const [isIndeterminate, setIndeterminate] = useState(false);
    const [isSorting, setSorting] = useState(0);
    const [sortColumn, columnSorting] = useState("");
    const [valuecheck, handleValues] = useState([]);
    const [anchorElCalendar, setAnchorElCalendar] = useState(null);
    const [placementCalendar, setPlacementCalendar] = useState();
    const [statusInfoAnchor, setStatusInfoAnchor] = useState(false);
    const filterObj = props?.filterObj;
    const currentUser = getCurrentUser();
    // let bodys = document.querySelector("body").classList;
    const orgId = localStorage.getItem("orgId") || 0;
    const { currentMode } = useContext(ThemeContext);
    const page = localStorage.getItem("currentPageNo");
    const [currentPageNo, changePageNo] = useState(page !== null ? +page : 1);
    // location.pathname === "/profile" && bodys.remove(`bg-${currentMode}-base`);
    const ProcessInv = useSelector((state) => state?.InvoiceListState);
    //* For workflow filter
    const statusReset = (filterList) => {
        return filterList?.map((item) => {
            return {
                ...item,
                isActive: false,
            };
        });
    };
    const { data: workflowFilter } = useSelector((state) => state?.getWorkflowFilterState);
    const parseId = workflowFilter?.datalist
        ?.filter((item) => {
            if (location.pathname === "/approve" && ![2, 3, 4, 5]?.includes(item?.master_refid)) {
                return item?.id;
            } else if (![3, 4, 5]?.includes(item?.master_refid)) {
                return item?.id;
            }
        })
        ?.map((item) => item?.id);
    //console.log("ParseId:", parseId1);
    // const parseId = +workflowFilter?.datalist?.find((item) => item?.master_refid == (location.pathname === "/approve" ? 7 : 2))?.id;
    const filterValues =
        location.pathname === "/approve" ? workflowFilter?.datalist?.filter((item) => item?.master_refid != 2) : workflowFilter?.datalist;
    const [status, setStatusCheck] = useState(workflowFilter?.datalist ? statusReset(filterValues) : []);
    const [isFlag, setisFlag] = useState(false);
    const [CheckFilterValue, setCheckFilterValue] = useState([]);
    const [FilterValue, setFilterValue] = useState([]);
    const Unique = [...new Set(FilterValue)];
    const UniqueValue = [...new Set(CheckFilterValue)];
    const TransferValue = UniqueValue?.filter((el) => el !== "Transferred");
    const TransferValueAlone = UniqueValue?.filter((el) => el == "Transferred");
    const RemoveTransfer = UniqueValue?.length > 1 && UniqueValue?.includes("Transferred") ? TransferValue : UniqueValue;
    const uniqLength = RemoveTransfer?.length - 1;
    const uniqLength2 = uniqLength == "0" ? "" : uniqLength;
    const plusShow = RemoveTransfer?.length > 1 ? "+" : "";
    const StatusValue = RemoveTransfer?.[0] + plusShow + uniqLength2;
    const { dataList } = ProcessInv;
    const [userId, setUserId] = useState(0);
    const letuserId = currentUser?.role_id == 1 ? currentUser?.id : props?.userId;
    const Fromdate =
        moment(new Date(props?.fromDate)).format("YYYY-MM-DD") == "Invalid date" ? "" : moment(new Date(props?.fromDate)).format("YYYY-MM-DD");
    const Todate = moment(new Date(props?.toDate)).format("YYYY-MM-DD") == "Invalid date" ? "" : moment(new Date(props?.toDate)).format("YYYY-MM-DD");
    const invType = [
        { name: "PO Invoice", value: 1 },
        { name: "Non-PO Invoice", value: 2 },
    ];
    const { vebdorlist } = useSelector((state) => state?.InvoiceVendorListState);
    const supplier = vebdorlist?.map((item) => item?.vendor_name);
    let type = null;
    if (selected === "status") {
        type = status;
    } else if (selected === "supplier") {
        type = supplier;
    } else if (selected === "invType") {
        type = invType;
    }
    //   else if (selected === "period") {
    //     type = period;
    //   }
    const checkFilterType = selected == "supplier" || selected == "invType" || selected == "period";

    let reqObject = {
        status_type:
            location.pathname === "/invoice"
                ? 4
                : location.pathname === "/exception"
                ? 1
                : location.pathname === "/processed"
                ? 2
                : location.pathname === "/approve"
                ? 3
                : "",
        status_id: filterObj?.status_id ? filterObj?.status_id : checkFilterType ? [0] : props?.statusOptionArray,
        org_group: currentUser?.organisation,
        isflagged: isFlag,
        vendor_id: filterObj?.vendor_id ? filterObj?.vendor_id : props?.filterSupplierValue,
        invoice_date_from: Fromdate,
        invoice_date_to: Todate,
        search_text: props?.searchValue || "",
        order_column: sortColumn,
        order_by: isSorting,
        // invoice_type: invoiceType,
        pg_no: currentPageNo,
        rec_cnt: currentPageItem,
        organisation: +orgId,
        invoice_no: !filterObj?.invoice_no ? "" : filterObj?.invoice_no,
        document_type: props?.invoiceType?.value,
        alloc_user: letuserId,
    };
    if (props?.searchValue) {
        localStorage.setItem("invSearch", props?.searchValue);
    }
    const handleSelectedValue = (event) => {
        setCheckSelected(false);
        setSelectedValue(event.target.value);
        changePageNo(1);
    };
    const checkChange = (e) => {
        const { checked } = e.target;
        const { value, id } = e.target;

        handleValues({
            // ...valuecheck,
            name: e.target.value,
            isCheck: checked,
        });
        const parseValue = {
            name: e.target.value,
            isCheck: checked,
            id,
        };
        checkStatus(parseValue);
        status.map((item) => {
            // if (item?.name == "Transferred" && item?.isCheck == true) {
            //     item.isCheck = false;
            // }
            if (item.name == value) {
                item.isCheck = checked;

                return item;
            } else {
                return item;
            }
        });
    };
    const handleValue = (newValue) => {
        if (newValue) {
            props?.setFromDate(newValue);
        }
    };
    const handleToDate = (toDate) => {
        if (toDate) {
            props?.setToDate(toDate);
        }
    };

    let reqObjectCount = {
        status_type:
            location.pathname === "/invoice"
                ? 4
                : location.pathname === "/exception"
                ? 1
                : location.pathname === "/processed"
                ? 2
                : location.pathname === "/approve"
                ? 3
                : "",
        status_id: filterObj?.status_id ? filterObj?.status_id : checkFilterType ? [0] : props?.statusOptionArray,
        vendor_id: 1,
        isflagged: isFlag,
        invoice_date_from: Fromdate,
        invoice_date_to: Todate,
        search_text: props?.searchValue || "",
        organisation: +orgId,
        // invoice_type: invoiceType,
        vendor_id: filterObj?.vendor_id ? filterObj?.vendor_id : props?.filterSupplierValue,
        invoice_no: !filterObj?.invoice_no ? "" : filterObj?.invoice_no,
        document_type: props?.invoiceType?.value,
        alloc_user: letuserId,
    };
    let reqObj = reqObject;
    // if(props?.filterObj){
    //   reqObj = props.filterObj;
    //   reqObjectCount = props.filterObj;
    // }
    const reqObjCount = reqObjectCount;

    const PeriodTitle =
        (props?.fromDate == "" ? "" : props?.fromDate?.$d?.toLocaleDateString()) +
        (props?.fromDate == "" || props?.toDate == "" ? " " : " to ") +
        (props?.toDate == "" ? "" : props?.toDate?.$d?.toLocaleDateString());
    const [checkSelected, setCheckSelected] = useState(false);
    const changeSelectOptionHandler = (event) => {
        // changePageNo(1);
        setCheckSelected(true);
        if (selected == "period" || selected == "supplier" || selected == "status" || selected == "invType") {
            props?.setFilterSupplierValue("");
            props?.setInvoiceType({ name: "", value: 0 });
            props?.setFromDate("");
            props?.setToDate("");
            setSelected("default");
            setisFlag(false);
            setSelectedValue("");
            setCheckFilterValue([]);
            setStatusCheck(statusReset(filterValues));
        }

        if (location.pathname == "/invoice") {
            props?.setstatusOptionArray(parseId || []);
        }
        setSelected(event.target.value);
        if (event.target.value == "status") {
            props?.setstatusOptionArray([]);
        } else if (event.target.value !== "status") {
            props.setstatusOptionArray(parseId || []);
        }
        setStatusValue([]);
    };

    useEffect(() => {
        // changePageItem(10);
        props?.setAllSelected(false);
        // props?.handleSearchChange("");
        // setSelected("default");
        // setSelectedValue("");
        localStorage.removeItem("invSearch", "");
        // dispatch(invoiceCountTotal(reqObjCount));
        // dispatch(InvoiceList(reqObj));
        props?.setFromDate("");
        props?.setToDate("");
        // if (ChangeOrg == "1") {
        //     changePageNo(1);
        //     onRefreshPage();
        //     changePageItem(10);
        //     setisFlag(false);
        //     setStatusCheck(statusReset(workflowFilter?.datalist));
        // }
        if (props?.searchValue !== "") {
            changePageNo(1);
        }
        if (filterObj !== null) {
            changePageNo(1);
            changePageItem(10);
            props?.setFilterSupplierValue("");
            props?.setInvoiceType({ name: "", value: 0 });
            props?.setFromDate("");
            props?.setToDate("");
            setSelected("default");
            setisFlag(false);
            setSelectedValue("");
            setCheckFilterValue([]);
            setStatusCheck(statusReset(filterValues));
        }
    }, [setStatusCheck, filterObj, props?.searchValue, isFlag]);

    const setApprove = props?.statusOptionArray?.length == 0 && location.pathname === "/approve";
    useEffect(() => {
        if (selected === "supplier" && vebdorlist?.length == 0) {
            dispatch(invoiceVendorList(orgId));
        }
        // if (setApprove) {
        //     props?.setstatusOptionArray([7]);
        // }
        if (selected === "status") {
            setStatusCheck(statusReset(filterValues));
        } else if (selected === "status" && location.pathname === "/approve") {
            //* To remove submitted and transferred in approval page
            setStatusCheck(statusReset(filterValues));
            setisFlag(false);
        }
        if (!workflowFilter?.status || orgId != workflowFilter?.orgId) {
            dispatch(getWorkFlowFilter(orgId, 1));
        }

        if (selected != "default" && selected) {
            props?.setOnrefresh(true);
        }
    }, [dispatch, selected, setApprove, orgId]);
    if (type == status) {
        options = status?.map((el, i) => (
            <MenuItem key={i}>
                <FormControlLabel
                    control={
                        <Checkbox
                            id={el?.id}
                            size="small"
                            onChange={checkChange}
                            value={el?.name}
                            checked={UniqueValue?.includes(el?.name)}
                            // checked={el?.isCheck}
                            sx={{
                                padding: 0,
                                color: "#008785",
                                margin: "0 12px",
                                "&.Mui-checked": {
                                    color: "#008785",
                                },
                            }}
                        />
                    }
                    label={el?.name}
                />
            </MenuItem>
        ));
    }

    if (type == invType) {
        options = type?.map((el, i) => (
            <MenuItem key={i} value={el} className="truncate trunc-1 block">
                {el?.name}
            </MenuItem>
        ));
    }
    if (type == supplier) {
        options = type?.map((el, i) => (
            <MenuItem key={i} value={el} className="truncate trunc-1 block">
                {el}
            </MenuItem>
        ));
    }
    function submit(reqPara) {
        props?.setStatus(true);
        dispatch(cartToggleAction(true));
        dispatch(InvoiceList(reqObj));
        props?.OrderBy(reqObject);
    }

    const onPageNumberSelect = (event) => {
        let pageNumber = event.target.value;
        changePageNo(parseInt(pageNumber));
    };
    const onPageItemSelect = (event) => {
        props?.setAllSelected(false);
        let pageItemVal = event.target.value;
        changePageItem(parseInt(pageItemVal));
        changePageNo(1);
    };
    const previousBtnClick = () => {
        if (props?.isAllSelected === true) {
            props?.setAllSelected(false);
        }
        if (isIndeterminate == true) {
            setIndeterminate(false);
        }
        changePageNo(currentPageNo - 1);
    };
    const nextBtnClick = () => {
        if (props?.isAllSelected === true) {
            props?.setAllSelected(false);
        }
        if (isIndeterminate == true) {
            setIndeterminate(false);
        }
        changePageNo(currentPageNo + 1);
    };
    const resetInputField = () => {
        props?.handleSearchChange("");
        localStorage.removeItem("invSearch", "");
    };
    const handleClick = (newPlacement, id) => (event) => {
        setPopState({ id, anchorEl: event.currentTarget }, (prev) => placement !== newPlacement || !prev);
        setPlacement(newPlacement);
    };

    const isOpened = useSelector((state) => state?.cartToggleState);
    const [addedInvoice, setAddedInvoice] = useState([]);
    const navigate = useNavigate();
    if (ProcessInv.error) {
        navigate("/404");
    }
    if (!ProcessInv.fetching && ProcessInv.requestComplete) {
        dataList?.forEach((item) => {
            return (item.checked = false);
        });
        setAddedInvoice(dataList);
        ProcessInv.fetching = true;
    }
    const cartOpen = (e) => {
        e.preventDefault();
        dispatch(cartToggleAction(true));
        if (vebdorlist?.length == 0) {
            dispatch(invoiceVendorList(orgId));
        }
    };

    const handleClickAway = () => {
        setPopState({ id: false, anchorEl: false });
    };

    const handleClickAwayCalendar = () => {
        setOpen(false);
        setAnchorElCalendar(null);
        setPlacementCalendar();
    };

    const lastDateOfYear = `12/31/${new Date().getFullYear()}`;

    const onRefreshPage = () => {
        if (location?.pathname === "/exception" || location?.pathname === "/processed") {
            navigate("/invoice");
        } else {
            const reqObjCount = {
                status_type:
                    location.pathname === "/invoice"
                        ? 4
                        : location.pathname === "/exception"
                        ? 1
                        : location.pathname === "/processed"
                        ? 2
                        : location.pathname === "/approve"
                        ? 3
                        : "",
                status_id: parseId || [],
                vendor_id: "",
                // invoice_date_from: null,
                // invoice_date_to:null,
                search_text: "",
                organisation: +orgId,
                vendor_id: "",
                invoice_no: "",
                document_type: 0,
                alloc_user: letuserId,
                isflagged: isFlag,
            };
            const reqObj = {
                status_type:
                    location.pathname === "/invoice"
                        ? 4
                        : location.pathname === "/exception"
                        ? 1
                        : location.pathname === "/processed"
                        ? 2
                        : location.pathname === "/approve"
                        ? 3
                        : "",
                status_id: parseId || [],
                org_group: currentUser?.organisation,
                vendor_id: "",
                // invoice_date_from: null,
                // invoice_date_to: null,
                search_text: "",
                order_column: "",
                order_by: 0,
                pg_no: 1,
                rec_cnt: 10,
                organisation: +orgId,
                invoice_no: "",
                document_type: 0,
                alloc_user: letuserId,
                isflagged: isFlag,
            };
            changePageItem(10);
            props?.setAllSelected(false);
            props?.handleSearchChange("");
            setCheckFilterValue([]);
            setFilterValue([]);
            setIndeterminate(false);
            setSelected("default");
            setSelectedValue("");
            localStorage.removeItem("currentPageNo");
            changePageNo(1);
            localStorage.removeItem("invSearch", "");
            if (workflowFilter && parseId) {
                dispatch(invoiceCountTotal(reqObjCount));
                dispatch(InvoiceList(reqObj));
                props?.setstatusOptionArray(parseId || []);
            }
            props?.setFromDate("");
            props?.setToDate("");
            props?.setFilterSupplierValue("");
            props?.setOnrefresh(true);
            setisFlag(false);
            setStatusCheck(statusReset(filterValues));
        }
    };
    useMemo(() => {
        // changePageNo(1);
        if (userId != letuserId) {
            setUserId(letuserId);
            changePageNo(1);
        } else {
            onRefreshPage();
        }

        // changePageItem(10);
        //setisFlag(false);
        // setStatusCheck(statusReset(workflowFilter?.datalist));
    }, [workflowFilter, location.pathname, letuserId]);
    const checkStatus = (parseValue) => {
        SatusValue.push(parseValue);
        const { name, isCheck, id } = parseValue;
        const parse = props?.statusOptionArray ? props?.statusOptionArray : [];
        if (isCheck) {
            CheckFilterValue.push(name);
            parse?.push(+id);
            props?.setstatusOptionArray(parse);
            FilterValue.push(`${name}+ `);
        } else {
            props?.setstatusOptionArray(props?.statusOptionArray?.filter((el) => el != +id));
            setFilterValue(FilterValue?.filter((el) => el != `${name}+ `));
            setCheckFilterValue(CheckFilterValue?.filter((el) => el != name));
        }
        props?.setFilterStatusValue(ReAssign);
    };
    const ReAssign = [...new Set(props?.statusOptionArray)];
    useEffect(() => {
        if (type == supplier) {
            props?.setFilterSupplierValue(selectedValue);
        }
        // if (type == status) {
        //     setCheckFilterValue(CheckFilterValue)
        //     checkStatus(statusValue)
        // }
        if (type == invType) {
            props?.setInvoiceType(selectedValue);
        }
    }, [selectedValue]);
    const filteredApproval = localStorage.getItem("filteredApproval");
    // Get tomorrow's date
    // const tomorrow = moment().add(1, "days");
    // Format the date as "YYYY-MM-DD"
    // const formattedTomorrow = tomorrow.format("YYYY-MM-DD");
    useEffect(() => {
        // if (refresh || props?.resultstatus) {
        //     onRefreshPage();
        // }
        async function fetchEmployees() {
            if (currentPage !== location.pathname) {
                props?.handleSearchChange("");
                setisFlag(false);
                setStatusCheck(statusReset(filterValues));
                setCheckFilterValue([]);
                setFilterValue([]);
                setSelected("default");
                setSelectedValue("");
                changePageNo(page !== null ? +page : 1);
                changePageItem(10);
                props?.setFromDate("");
                props?.setToDate("");
                const reqObjCount = {
                    status_type:
                        location.pathname === "/invoice"
                            ? 4
                            : location.pathname === "/exception"
                            ? 1
                            : location.pathname === "/processed"
                            ? 2
                            : location.pathname === "/approve"
                            ? 3
                            : "",
                    status_id: location?.pathname === "/approve" ? (filteredApproval == "true" ? [3, 4] : [7]) : [2],
                    vendor_id: "",
                    invoice_date_from: null,
                    invoice_date_to: null,
                    search_text: "",
                    organisation: +orgId,
                    vendor_id: "",
                    invoice_no: "",
                    document_type: 0,
                    alloc_user: letuserId,
                    isflagged: isFlag,
                };
                const reqObj = {
                    status_type:
                        location.pathname === "/invoice"
                            ? 4
                            : location.pathname === "/exception"
                            ? 1
                            : location.pathname === "/processed"
                            ? 2
                            : location.pathname === "/approve"
                            ? 3
                            : "",
                    status_id: location?.pathname === "/approve" ? (filteredApproval == "true" ? [3, 4] : [7]) : [2],
                    org_group: currentUser?.organisation,
                    vendor_id: "",
                    invoice_date_from: null,
                    invoice_date_to: null,
                    search_text: "",
                    order_column: "",
                    order_by: 0,
                    pg_no: page ? page : 1,
                    rec_cnt: 10,
                    organisation: +orgId,
                    invoice_no: "",
                    document_type: 0,
                    alloc_user: letuserId,
                    isflagged: isFlag,
                };
                // dispatch(invoiceCountTotal(reqObjCount));
                // dispatch(InvoiceList(reqObj));
                localStorage.setItem("filteredApproval", false);
            } else {
                if (!checkSelected && !props?.exportFile && !props?.Onrefresh && !filterObj) {
                    await dispatch(invoiceCountTotal(reqObjCount));
                    await dispatch(InvoiceList(reqObj));
                }
            }

            setCheckSelected(false);
            setCurrentPage(location.pathname);
            onSortingIconChange();
        }
        fetchEmployees();
        localStorage.removeItem("currentPageNo");
    }, [
        location.pathname,
        dispatch,
        props?.searchValue,
        currentPageNo,
        currentPageItem,
        sortColumn,
        isFlag,
        isSorting,
        props?.statusid,
        props?.searchValue,
        props?.filterSupplierValue,
        props?.filterStatusValue,
        props?.invoiceType,
        props?.fromDate,
        props?.toDate,
        userId,
    ]);
    const pageNo = currentPageNo;
    const entries = currentPageItem;
    const pageData = useSelector((state) => state?.InvoiceCountState);
    const pageCount = pageData && pageData.data && pageData.data?.data ? pageData.data.data.data.rec_cnt : 0;
    const PropsCount = props.TotalInv(pageCount);
    const CountCalc = pageCount / currentPageItem;
    const Rounded = Math.ceil(CountCalc);
    const demoStatus = [
        {
            id: 1,
            status_name: "Submitted",
            status_color: "#FF007E",
        },
        {
            id: 2,
            status_name: "Approved",
            status_color: "#FF007E",
        },
        {
            id: 3,
            status_name: "Manager",
            status_color: "#B100FB",
        },
        {
            id: 4,
            status_name: "Status Manager",
            status_color: "#007BFF",
        },
        {
            id: 5,
            status_name: "Fianance Manager",
            status_color: "#F5A622",
        },
        {
            id: 6,
            status_name: "Approved",
            status_color: "#00BFD8",
        },
        {
            id: 7,
            status_name: "Posted",
            status_color: "#F5A622",
        },
        {
            id: 8,
            status_name: "Manager",
            status_color: "#F5A622",
        },
        {
            id: 9,
            status_name: "Check the status",
            status_color: "#F5A622",
        },
        {
            id: 10,
            status_name: "Posted",
            status_color: "#F5A622",
        },
    ];
    let EmptyArr = [];
    for (let i = 0; i < Rounded; i++) {
        EmptyArr.push(i);
    }
    const handleClickCalendar = (newPlacement) => (event) => {
        setAnchorElCalendar(event.currentTarget);
        setOpen((prev) => placementCalendar !== newPlacement || !prev);
        setPlacementCalendar(newPlacement);
    };
    const AccessIcon = () => <img src="/images/common/date-ico.svg" alt="date-ico" />;

    let pagesCount = (entries - 1) / entries + 1;
    let start = (pageNo - 1) * entries + 1;
    let end = Math.min(start + entries - 1, pageCount);

    const nextPageCount = Math.ceil(pageCount / currentPageItem);

    const ApproveBtnClick = () => {
        dispatch(InvoiceStatusApproved({ id: [1] }));
        approvedList(approvedListItem);
    };

    const ApprovedInv = useSelector((state) => state?.InvoiceApprovedState);
    const { approveList } = ApprovedInv;

    //Rejected

    const RejectBtnClick = () => {
        dispatch(InvoiceStatusRejected({ id: [1] }));
        rejectList(rejectListItem);
    };

    const RejectInv = useSelector((state) => state?.InvoiceRejectedState);
    const { RejectList } = RejectInv;

    const onSelectAll = (event) => {
        props?.setAllSelected(event.target.checked);
        setIndeterminate(false);
        dataList
            ?.filter((item) => item?.ext_status_id !== 5)
            ?.forEach((invoice) => {
                invoice.checked = event.target.checked;
            });

        setAddedInvoice(dataList);
        props.onApproveList(addedInvoice);
        // if(props?.isAllSelected==false){
        //   setAddedInvoice(dataList);
        //   props.onApproveList(addedInvoice);
        // }
    };

    const onSelect = (event) => {
        const index = dataList.findIndex((item) => item.id === parseInt(event.target.name));
        // shallow clone
        const updatedArray = addedInvoice;
        let getListObjIndex = updatedArray[index];
        getListObjIndex.checked = event.target.checked;

        let newArr = dataList.map(function (a, _index) {
            return _index === index ? getListObjIndex : a;
        });
        // const newArr = _.map(addedInvoice=(a)=>{
        //   return a.id === 1 ? getListObjIndex.checked = event.target.checked : a;
        // });

        setAddedInvoice(newArr);

        // change all select checkbox
        if (dataList.every((invoice) => invoice.checked)) {
            props?.setAllSelected(true);
            setIndeterminate(false);
        } else if (dataList.every((invoice) => !invoice.checked)) {
            props?.setAllSelected(false);
            setIndeterminate(false);
        } else {
            setIndeterminate(true);
        }
        props.onApproveList(addedInvoice);
    };

    const onSorting = (columnName) => {
        const sortBy = isSorting === 1 ? 2 : 1;
        // if (sortBy === 1) {
        //   document.getElementById(columnName).classList.add("Up");
        // } else {
        //   document.getElementById(columnName).classList.remove("Up");
        // }

        setSorting(sortBy);
        columnSorting(columnName);
    };
    const onSortingIconChange = () => {
        // if (ProcessInv?.requestComplete) {
        //   if (isSorting === 1) {
        //     document.getElementById(sortColumn).classList.add("Up");
        //   } else {
        //     document.getElementById(sortColumn).classList.remove("Up");
        //   }
        // }
    };

    //   const shortcutsItems = [
    //     {
    //       label: "This Week",
    //       getValue: () => {
    //         const today = dayjs();
    //         return [today.startOf("week"), today.endOf("week")];
    //       },
    //     },
    //     {
    //       label: "Last Week",
    //       getValue: () => {
    //         const today = dayjs();
    //         const prevWeek = today.subtract(7, "day");
    //         return [prevWeek.startOf("week"), prevWeek.endOf("week")];
    //       },
    //     },
    //     {
    //       label: "Last 7 Days",
    //       getValue: () => {
    //         const today = dayjs();
    //         return [today.subtract(7, "day"), today];
    //       },
    //     },
    //     {
    //       label: "Current Month",
    //       getValue: () => {
    //         const today = dayjs();
    //         return [today.startOf("month"), today.endOf("month")];
    //       },
    //     },
    //     {
    //       label: "Next Month",
    //       getValue: () => {
    //         const today = dayjs();
    //         const startOfNextMonth = today.endOf("month").add(1, "day");
    //         return [startOfNextMonth, startOfNextMonth.endOf("month")];
    //       },
    //     },
    //     { label: "Reset", getValue: () => [null, null] },
    //   ];

    const pageType =
        location.pathname === "/invoice"
            ? 1
            : location.pathname === "/exception"
            ? 2
            : location.pathname === "/approve"
            ? 3
            : location.pathname === "/processed"
            ? 6
            : "";
    let totalPageCount = dataList ? dataList.length * (currentPageNo ? currentPageNo : 1) : 0;
    const totalPageExceed = currentPageNo * currentPageItem;
    if (totalPageExceed >= pageCount) {
        totalPageCount = pageCount;
    }
    const [InvId, setInvId] = useState();
    const OpenTransferInv = (Id) => {
        setOpenTransfer(true);
        setInvId(Id);
    };
    const dropdownColor = currentMode === "dark" ? "#333333" : `white`;
    const dropdownText = currentMode === "dark" ? "#ECECEC" : `#555555`;
    const dropdownShadow = currentMode !== "dark" ? "0px 4px 20px rgba(238, 238, 238, 0.5)" : "";
    const dropdownBorder = currentMode !== "dark" ? "223, 225, 226, 0.56" : "";
    const targetDateStr = currentUser?.expiring_date;
    const targetDate = new Date(targetDateStr);
    const currentDate = new Date();
    const matchdate = targetDate < currentDate;
    const showTransfer =
        (!matchdate && currentUser?.license_type == 2) ||
        currentUser?.license_type == 1 ||
        currentUser?.license_type == 2 ||
        currentUser?.license_type == 3 ||
        currentUser?.license_type == 4;
    const SetPage = () => {
        changePageItem(10);
        props?.setAllSelected(false);
        props?.handleSearchChange("");
        setCheckFilterValue([]);
        setFilterValue([]);
        setIndeterminate(false);
        setisFlag(false);
        setSelected("default");
        setSelectedValue("");
        localStorage.removeItem("currentPageNo");
        changePageNo(1);
        localStorage.removeItem("invSearch", "");
        props?.setFromDate("");
        props?.setToDate("");
        props?.setFilterSupplierValue("");
        props?.setOnrefresh(true);
        setStatusCheck(statusReset(workflowFilter?.datalist));
        addedInvoice.forEach((element) => {
            element.checked = false;
        });
    };
    useEffect(() => {
        if (refresh || props?.resultstatus) {
            onRefreshPage();
        } else if (props?.exportFile) {
            SetPage();
        }
    }, [props?.resultstatus]);

    const dynamicClass = (status) => {
        switch (status) {
            case "Processed":
                return `bg-light-480`;
            case "Approved":
                return `bg-light-490`;
            case "Rejected":
                return `bg-light-180`;
            case "Draft":
                return `bg-light-500`;
            case "Submitted":
                return `bg-light-510`;
            case "Posted":
                return `bg-light-510`;
            case "Waiting For Approval":
                return `bg-light-520`;
            case "ForApproval":
                return `bg-light-520`;
            default:
                break;
        }
    };
    const filterStatus = props?.filterStatus;
    const LightTooltip = (props) => (
        <Tooltip
            title={props?.title}
            disablePortal
            placement="bottom"
            disableInteractive
            PopperProps={{
                sx: {
                    "& .MuiTooltip-tooltip": {
                        background: "#DAF9F4",
                        color: "#555555",
                        borderRadius: "5px",
                        marginTop: "0 !important",
                    },
                },
            }}>
            {props?.img}
        </Tooltip>
    );
    function segregateAndNumber(inputString) {
        // Split the input string by asterisk and filter out empty strings
        const parts = inputString?.split("*")?.filter((part) => part?.trim() !== "");
        // Create a numbered list
        const numberedList = parts?.map((part, index) => `${index + 1}. ${part?.trim()}`);
        // Join the numbered list with newline characters
        const result = numberedList?.join("\n");
        return result;
    }
    return (
        <>
            <Card className="mb-6 xl:h-[calc(100vh_-_13rem)] h-[calc(100vh_-_18rem)]">
                <form onSubmit={handleSubmit}>
                    <div className="xl:flex justify-between px-[15px] py-[25px] space-y-4 xl:space-y-0">
                        <div className="flex justify-between xl:justify-start">
                            <div className="flex items-center">
                                <span>{t("Show")}</span>
                                <Select
                                    name="entrycount"
                                    className={` insideTble bg-${currentMode}-card mx-3 text-xs rounded-[5px] entries`}
                                    value={currentPageItem}
                                    onChange={(e) => onPageItemSelect(e)}
                                    IconComponent={() => (
                                        <img
                                            className="absolute opacity-50 right-[8px] pointer-events-none"
                                            src={currentMode !== "dark" ? `/images/header/chevronDown.svg` : `/images/header/chevronDown-white.svg`}
                                        />
                                    )}
                                    MenuProps={{
                                        sx: {
                                            "& .MuiMenu-paper": {
                                                backgroundColor: dropdownColor,
                                                color: dropdownText,
                                                boxShadow: dropdownShadow,
                                                border: dropdownBorder,
                                                borderRadius: "8px",
                                            },
                                        },
                                    }}
                                    SelectDisplayProps={{
                                        style: {
                                            display: "flex",
                                            border: 0,
                                            color: currentMode === "dark" ? `#ECECEC` : `#555555`,
                                        },
                                    }}>
                                    {/* {EntryArr?.map(ent => <option value={ent}>{ent}</option>)} */}
                                    <MenuItem value={10}>{10}</MenuItem>
                                    <MenuItem value={15}>{15}</MenuItem>
                                    <MenuItem value={20}>{20}</MenuItem>
                                    <MenuItem value={25}>{25}</MenuItem>
                                    <MenuItem value={50}>{50}</MenuItem>
                                    <MenuItem value={75}>{75}</MenuItem>
                                    <MenuItem value={100}>{100}</MenuItem>
                                </Select>
                                <span>{t("Entries")}</span>
                                <div className="flex items-center">
                                    <div>
                                        <Button title="To Refresh the Invoice List" onClick={() => onRefreshPage()}>
                                            <img className="mx-3 w-[18]" src="/images/common/refresh.svg" alt="upload" />{" "}
                                        </Button>
                                    </div>
                                </div>
                            </div>

                            <div
                                className={`bg-${currentMode}-230 xl:hidden border-${currentMode}-840 border search-step flex items-center justify-between py-[5.5px] px-[16px] min-w-[250px] max-w-[250px] xl:max-w-[auto] rounded-[8px]`}>
                                <Input
                                    placeholder={t("Search")}
                                    name="search"
                                    className={`border-0 text-${currentMode}-copy`}
                                    disableUnderline={true}
                                    onChange={(e) => props?.handleSearchChange(e.target.value)}
                                    value={props?.searchValue}
                                />
                                <div>
                                    {props?.searchValue && props?.searchValue.length !== 0 ? (
                                        <img onClick={resetInputField} src="/images/common/closeBlk.svg" alt="close" className="convertWhite" />
                                    ) : (
                                        <img src="/images/header/search.svg" alt="search" />
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="xl:flex space-y-5 xl:space-y-0 items-center xl:space-x-2">
                            <div
                                className={`bg-${currentMode}-230 hidetab border-${currentMode}-840 border search-step flex items-center justify-between py-[4.5px] px-[16px] min-w-[250px] max-w-[250px] xl:max-w-[auto] rounded-[8px]`}>
                                <Input
                                    placeholder="Search..."
                                    name="search"
                                    className={`border-0 text-${currentMode}-copy`}
                                    disableUnderline={true}
                                    onChange={(e) => props?.handleSearchChange(e.target.value)}
                                    value={props?.searchValue}
                                />
                                <div>
                                    {props?.searchValue && props?.searchValue.length !== 0 ? (
                                        <img onClick={resetInputField} src="/images/common/closeBlk.svg" alt="close" className="convertWhite" />
                                    ) : (
                                        <img src="/images/header/search.svg" alt="search" />
                                    )}
                                </div>
                            </div>
                            <div className=" flex space-x-2">
                                <div
                                    className={`flex invoice_filter_step items-center border-[1px] border-${currentMode}-840 pl-[15px] pr-[10px] py-[8.5px] rounded-[8px] bg-${currentMode}-card `}>
                                    <img className="mr-2 convertWhite" src="/images/invoice/filter-grp.svg" alt="filter-grp" />
                                    Filter by:
                                    <FormControl
                                        sx={{
                                            minWidth: 138,
                                        }}>
                                        <Select
                                            onChange={changeSelectOptionHandler}
                                            name="filter"
                                            defaultValue="default"
                                            className={"insideFilter Org"}
                                            value={selected == undefined ? "default" : selected}
                                            MenuProps={{
                                                sx: {
                                                    "& .MuiMenu-paper": {
                                                        marginTop: "10px",
                                                    },
                                                    "& .MuiMenuItem-root:hover": {
                                                        backgroundColor: "rgba(218, 249, 244, 0.3)",
                                                    },
                                                    "& .Mui-selected": {
                                                        backgroundColor: "rgba(218, 249, 244, 0.3) !important",
                                                    },
                                                },
                                            }}
                                            IconComponent={() => (
                                                <img
                                                    className="absolute right-[5px] opacity-[.5] pointer-events-none"
                                                    src={
                                                        currentMode !== "dark"
                                                            ? `/images/header/chevronDown.svg`
                                                            : `/images/header/chevronDown-white.svg`
                                                    }
                                                    alt="chevronDown"
                                                />
                                            )}
                                            SelectDisplayProps={{
                                                style: {
                                                    display: "flex",
                                                    border: 0,
                                                    color: currentMode === "dark" ? `#ECECEC` : `#555555`,
                                                    paddingRight: "30px",
                                                },
                                            }}
                                            displayEmpty>
                                            <MenuItem value="default" sx={{ display: "none" }}>
                                                Select
                                            </MenuItem>

                                            <MenuItem value="status">
                                                <img className="mr-2 convertWhite" src="/images/invoice/status.svg" alt="status" /> Status
                                            </MenuItem>

                                            <MenuItem value="supplier">
                                                <img className="mr-2 convertWhite" src="/images/invoice/user-plusblk.svg" alt="user-plusblk" /> Vendor
                                            </MenuItem>
                                            <MenuItem value="period">
                                                <img className="mr-2 convertWhite" src="/images/invoice/calendar.svg" alt="calendar" /> Period
                                            </MenuItem>
                                            <MenuItem value="invType">
                                                <img className="mr-2 convertWhite" src="/images/common/invoice-type.svg" alt="calendar" /> Invoice
                                                Type
                                            </MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                                <div
                                    className={`flex items-center  border-[1px] border-${currentMode}-840 pl-[15px] pr-[10px] py-[8px] rounded-[8px] bg-${currentMode}-card`}>
                                    {/* <img
                    className="mr-2"
                    src="/images/invoice/3-layers.svg"
                    alt="filter-grp"
                  /> */}

                                    <FormControl
                                        fullWidth
                                        sx={
                                            {
                                                // minWidth: 170,
                                                // minHeight: 80
                                            }
                                        }>
                                        <Select
                                            name="filter"
                                            title={
                                                selected == "supplier"
                                                    ? selectedValue
                                                    : selected == "period"
                                                    ? PeriodTitle
                                                    : selected == "status"
                                                    ? UniqueValue
                                                    : ""
                                            }
                                            onChange={handleSelectedValue}
                                            className="insideFilter Org"
                                            defaultValue="default"
                                            disabled={!selected || selected == "default"}
                                            fullWidth
                                            multiple={selected == "status" ? true : false}
                                            value={
                                                props?.fromDate || props?.toDate
                                                    ? PeriodTitle
                                                    : selected == "supplier" || selected == "invType"
                                                    ? selected == "invType"
                                                        ? selectedValue?.name
                                                        : selectedValue
                                                    : []
                                            }
                                            displayEmpty
                                            renderValue={(value) =>
                                                value?.length
                                                    ? Array.isArray(value)
                                                        ? value.join(", ")
                                                        : value
                                                    : StatusCount?.length == 0
                                                    ? "Select"
                                                    : valuecheck
                                                    ? CheckFilterValue?.length == 0
                                                        ? "Select"
                                                        : StatusValue
                                                    : selected
                                            }
                                            MenuProps={{
                                                sx: {
                                                    "& .MuiMenu-paper": {
                                                        backgroundColor: dropdownColor,
                                                        color: dropdownText,
                                                        boxShadow: dropdownShadow,
                                                        border: dropdownBorder,
                                                        borderRadius: "8px",
                                                        maxWidth: "30rem",
                                                        // height: selected == "invType" ? "5rem" : selected == "status" ? "11rem" : selected == "period" ? "3rem" : "",
                                                        // minHeight: selected == "supplier" && "10rem !important",
                                                        top: "304rem",
                                                        marginTop: "10px",
                                                    },
                                                    "& .MuiMenuItem-root:hover": {
                                                        backgroundColor: "rgba(218, 249, 244, 0.3)",
                                                    },
                                                    "& .Mui-selected": {
                                                        backgroundColor: "rgba(218, 249, 244, 0.3)",
                                                    },
                                                },
                                            }}
                                            IconComponent={() => (
                                                <img
                                                    className="absolute right-[5px] opacity-[.5] pointer-events-none"
                                                    src={
                                                        currentMode !== "dark"
                                                            ? `/images/header/chevronDown.svg`
                                                            : `/images/header/chevronDown-white.svg`
                                                    }
                                                    alt="chevronDown"
                                                />
                                            )}
                                            SelectDisplayProps={{
                                                style: {
                                                    paddingTop: 1,
                                                    paddingBottom: 1,
                                                    width: "11rem",
                                                    color: currentMode === "dark" ? `#ECECEC` : `#555555`,
                                                    paddingLeft: "0",
                                                    border: 0,

                                                    paddingRight: "10px",
                                                },
                                            }}>
                                            <MenuItem
                                                value={
                                                    props?.fromDate && props?.toDate
                                                        ? moment(new Date(props?.fromDate)).format("DD-MM-YYYY") +
                                                          " to " +
                                                          moment(new Date(props?.toDate)).format("DD-MM-YYYY")
                                                        : "default"
                                                }
                                                sx={{ display: "none" }}>
                                                Select
                                            </MenuItem>
                                            {selected == "period" ? (
                                                <div className="basis-[32%] xl:basis-[20%]">
                                                    <Button
                                                        className={`font-interR px-[15px] truncate  text-${currentMode}-copy text-left py-[8px] w-full`}
                                                        onClick={handleClickCalendar("bottom-start")}>
                                                        {props?.fromDate && props?.toDate
                                                            ? moment(new Date(props?.fromDate)).format("DD-MM-YYYY") +
                                                              " to " +
                                                              moment(new Date(props?.toDate)).format("DD-MM-YYYY")
                                                            : "Select data range"}
                                                    </Button>
                                                    <ClickAwayListener
                                                        mouseEvent="onMouseDown"
                                                        touchEvent="onTouchStart"
                                                        onClickAway={handleClickAwayCalendar}>
                                                        <Popper
                                                            open={open}
                                                            anchorEl={anchorElCalendar || ""}
                                                            placement={placementCalendar || ""}
                                                            className="z-[1300] filterPop">
                                                            <Paper>
                                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                    <ul className={`space-y-4 px-[15px] py-4 bg-${currentMode}-card`}>
                                                                        <li className="flex justify-between">
                                                                            <DesktopDatePicker
                                                                                selectsStart
                                                                                inputFormat="DD/MM/YYYY"
                                                                                className={`border-0 text-${currentMode}-copy`}
                                                                                components={{
                                                                                    OpenPickerIcon: AccessIcon,
                                                                                }}
                                                                                value={props?.fromDate}
                                                                                label="From"
                                                                                minDate={new Date(1999, 12)}
                                                                                maxDate={new Date()}
                                                                                name="invoice_date_from"
                                                                                onChange={handleValue}
                                                                                renderInput={(params) => (
                                                                                    <TextField
                                                                                        sx={{
                                                                                            "& .MuiInputBase-input": {
                                                                                                color: `${
                                                                                                    currentMode !== "dark" ? `#555555` : `#ECECEC`
                                                                                                }`,
                                                                                            },
                                                                                        }}
                                                                                        placeholder={params.inputProps?.placeholder?.replace("From")}
                                                                                        {...params}
                                                                                        inputProps={{
                                                                                            ...params.inputProps,
                                                                                            readOnly: true,
                                                                                        }}
                                                                                    />
                                                                                )}
                                                                            />
                                                                        </li>
                                                                        <li className="flex justify-between">
                                                                            <DesktopDatePicker
                                                                                selectsEnd
                                                                                className="border-0"
                                                                                inputFormat="DD/MM/YYYY"
                                                                                name="invoice_date_to"
                                                                                label="To"
                                                                                components={{
                                                                                    OpenPickerIcon: AccessIcon,
                                                                                }}
                                                                                value={props?.toDate}
                                                                                onChange={handleToDate}
                                                                                minDate={props?.fromDate}
                                                                                maxDate={new Date()}
                                                                                renderInput={(params) => (
                                                                                    <TextField
                                                                                        sx={{
                                                                                            "& .MuiInputBase-input": {
                                                                                                color: `${
                                                                                                    currentMode !== "dark" ? `#555555` : `#ECECEC`
                                                                                                }`,
                                                                                            },
                                                                                        }}
                                                                                        placeholder={params.inputProps?.placeholder?.replace("To")}
                                                                                        {...params}
                                                                                        inputProps={{
                                                                                            ...params.inputProps,
                                                                                            readOnly: true,
                                                                                        }}
                                                                                    />
                                                                                )}
                                                                            />
                                                                        </li>
                                                                    </ul>
                                                                </LocalizationProvider>
                                                            </Paper>
                                                        </Popper>
                                                    </ClickAwayListener>
                                                </div>
                                            ) : (
                                                options
                                            )}
                                        </Select>
                                    </FormControl>
                                </div>
                                <Button
                                    onClick={cartOpen}
                                    className={`filter-step bg-${currentMode}-20 border-${currentMode}-700 px-3 py-2 rounded-[8px] font-interM text-sm flex items-center text-white`}>
                                    <img src="/images/common/filterIcon.svg" title="For Advanced Filter" alt="filterIcon" />
                                </Button>
                            </div>
                        </div>
                    </div>
                    <div className="heightFix invoices">
                        {!ProcessInv?.requestComplete || props?.fetching ? (
                            <Loader className="h-[calc(100vh_-_26rem)]" />
                        ) : (
                            <table className="TblNormal invoice w-max xl:w-full">
                                <thead className="sticky top-0 z-[1]">
                                    <tr className={`bg-${currentMode}-450 `}>
                                        <th className={`w-[5%]`}>
                                            {addedInvoice !== null ? (
                                                <Checkbox
                                                    title="Checkbox"
                                                    checked={props?.isAllSelected}
                                                    inputProps={{
                                                        "aria-label": "select all desserts",
                                                    }}
                                                    onChange={onSelectAll}
                                                    indeterminate={isIndeterminate}
                                                    sx={{
                                                        padding: 0,
                                                        color: "#008785",
                                                        "&.Mui-checked": {
                                                            color: "#008785",
                                                        },
                                                        "&.MuiCheckbox-indeterminate": {
                                                            color: "#008785",
                                                        },
                                                    }}
                                                />
                                            ) : (
                                                ``
                                            )}{" "}
                                        </th>

                                        <th className="w-[2%]"></th>

                                        <th>
                                            <Link className="flex tableHead" onClick={() => onSorting("id")}>
                                                Invoice Id
                                                <img
                                                    id="id"
                                                    src="/images/common/chevronDown.svg"
                                                    alt="chevronDown"
                                                    className={`${isSorting === 1 && sortColumn === "id" ? "rotate-180" : ""}`}
                                                />
                                            </Link>
                                        </th>
                                        <th>
                                            <Link className="flex tableHead" onClick={() => onSorting("invoice_no")}>
                                                Invoice Number
                                                <img
                                                    id="invoice_no"
                                                    src="/images/common/chevronDown.svg"
                                                    alt="chevronDown"
                                                    className={`${isSorting === 1 && sortColumn === "invoice_no" ? "rotate-180" : ""}`}
                                                />
                                            </Link>
                                        </th>
                                        <th>
                                            <Link
                                                className="flex tableHead"
                                                // onClick={() => onSorting("invoice_no")}
                                            >
                                                Invoice Type
                                                {/* <img
                          id="invoice_no"
                          src="/images/common/chevronDown.svg"
                          alt="chevronDown"
                          className={`${
                            isSorting === 1 && sortColumn === "invoice_no"
                              ? "rotate-180"
                              : ""
                          }`}
                        /> */}
                                            </Link>
                                        </th>
                                        <th>
                                            <Link className="flex tableHead" onClick={() => onSorting("vendor_name")}>
                                                Vendor Name
                                                <img
                                                    id="vendor_name"
                                                    src="/images/common/chevronDown.svg"
                                                    alt="chevronDown"
                                                    className={`${isSorting === 1 && sortColumn === "vendor_name" ? "rotate-180" : ""}`}
                                                />
                                            </Link>
                                        </th>

                                        {/* <th>
 
                        <Link to="#!" className="flex tableHead" onClick={()=>onSorting("createddate")}>
 
                          Created Date{" "}
 
                          <img id="createddate"
 
                            src="/images/common/chevronDown.svg"
 
                            alt="chevronDown"
 
                          />
 
                        </Link>
 
                      </th> */}

                                        <th>
                                            <Link className="flex tableHead" onClick={() => onSorting("invoice_date")}>
                                                Invoice Date
                                                <img
                                                    src="/images/common/chevronDown.svg"
                                                    id="invoice_date"
                                                    alt="chevronDown"
                                                    className={`${isSorting === 1 && sortColumn === "invoice_date" ? "rotate-180" : ""}`}
                                                />
                                            </Link>
                                        </th>

                                        {/* <th className="text-end">
                      <Link to="#!" className="flex justify-end tableHead">
                        Gross Amount{" "}
                        <img
 
                          src="/images/common/chevronDown.svg"
                           id="gross_amount"
                          alt="chevronDown"
                        />
                      </Link>
                    </th>
 
                    <th>
                      <Link to="#!" className="flex justify-end tableHead">
                        Tax{" "}
                      
                        <img
 
                          src="/images/common/chevronDown.svg"
                           id="tax_amount"
                          alt="chevronDown"
                        />
                      </Link>
                    </th> */}
                                        <th>
                                            <Link className="flex justify-end text-end tableHead">
                                                Net Amount
                                                {/* <img
 
                          src="/images/common/chevronDown.svg"
                          id="net_amount"
                          alt="chevronDown"
                        /> */}
                                            </Link>
                                        </th>
                                        <th>Document Number</th>
                                        <th>
                                            <Link className="flex tableHead">
                                                {t("Status")}{" "}
                                                {/* <img
 
                            src="/images/common/chevronDown.svg"
 
                            alt="chevronDown"
 
                          /> */}
                                            </Link>
                                        </th>

                                        <th>{t("Message")} </th>
                                        <th>{t("Currency")}</th>
                                        <th>
                                            <Link className="flex tableHead" onClick={() => onSorting("created_date")}>
                                                Created Date
                                                <img
                                                    id="created_date"
                                                    src="/images/common/chevronDown.svg"
                                                    alt="chevronDown"
                                                    className={`${isSorting === 1 && sortColumn === "created_date" ? "rotate-180" : ""}`}
                                                />
                                            </Link>
                                        </th>
                                        <th>
                                            <Link className="flex tableHead" onClick={() => onSorting("modified_date")}>
                                                Modified Date
                                                <img
                                                    id="modified_date"
                                                    src="/images/common/chevronDown.svg"
                                                    alt="chevronDown"
                                                    className={`${isSorting === 1 && sortColumn === "modified_date" ? "rotate-180" : ""}`}
                                                />
                                            </Link>
                                        </th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {dataList !== null ? (
                                        addedInvoice?.map((elem, index) => {
                                            const OuterGreenIcon =
                                                elem?.message_type == "s" && elem?.createddate_formated == elem?.modifieddate_formated;
                                            const FullGreenIcon =
                                                elem?.message_type == "s" && elem?.createddate_formated !== elem?.modifieddate_formated;
                                            Tags = elem?.exc_message;
                                            const segregatedLists = segregateAndNumber(elem?.exc_message);
                                            // const segregatedList = segregatedLists
                                            //     ?.split('\n')
                                            //     ?.filter(line => line?.trim() !== '')
                                            //     ?.map((line, index) => (
                                            //         <div key={index}>{line?.trim()}</div>
                                            //     ))
                                            // const segregatedListFinal = segregatedList?.slice(0, 10)
                                            // console.log(segregatedList, 'segregatedList');
                                            const lines = segregatedLists?.split("\n").filter((line) => line.trim() !== "");

                                            const maxLines = 5;
                                            const visibleLines = lines.slice(0, maxLines);
                                            const remainingLines = lines.slice(maxLines);

                                            let segregatedList = visibleLines.map((line, index) => <div key={index}>{line.trim()}</div>);

                                            if (remainingLines.length > 0) {
                                                segregatedList.push(
                                                    <div key="remaining">
                                                        + {remainingLines?.length} {remainingLines?.length === 1 ? "exception" : "exceptions"}
                                                    </div>
                                                );
                                            }
                                            return (
                                                <tr key={index} className={`border-b border-${currentMode}-700 last:border-b-0 `}>
                                                    <td className="w-[3%]">
                                                        <Checkbox
                                                            disabled={elem?.ext_status_id == 5}
                                                            title="Checkbox"
                                                            checked={elem?.checked}
                                                            onChange={(event) => onSelect(event)}
                                                            name={elem.id}
                                                            sx={{
                                                                padding: 0,
                                                                color: "#008785",
                                                                "&.Mui-checked": {
                                                                    color: "#008785",
                                                                },
                                                            }}
                                                        />{" "}
                                                    </td>
                                                    <td className="p-0">
                                                        <div className="w-[15px] mx-auto">
                                                            {elem?.isflagged ? (
                                                                <img
                                                                    className=" h-[17px]"
                                                                    src="/images/invoice/flagorange.svg"
                                                                    alt="flagorange"
                                                                    title="Flagged"
                                                                />
                                                            ) : (
                                                                ""
                                                            )}
                                                            {elem?.validatestatus === 1 && elem?.targetvalue !== "IQAI" ? (
                                                                <img
                                                                    className="flex-1"
                                                                    src="/images/common/validate-process.svg"
                                                                    alt="validate-process"
                                                                    title="Validation Inprogress"
                                                                />
                                                            ) : (
                                                                ``
                                                            )}
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <Link
                                                            title={elem?.inv_seqno}
                                                            disabled={elem?.istransfer == false}
                                                            state={{ from: location.pathname }}
                                                            to={`${"/invoice-view?"}${encryptId(
                                                                `{"inv":${+elem?.id},"type":${+pageType},"seq":"${
                                                                    elem?.inv_seqno
                                                                }","status":${+elem?.ext_status_id}}`
                                                            )}
                                                           `}
                                                            className={`${"tableHead truncate w-[110px]"}`}
                                                            style={{
                                                                pointerEvents: elem?.istransfer ? "none" : "",
                                                                textDecoration: "underline",
                                                            }}
                                                            onClick={() => {
                                                                localStorage.setItem("currentPageNo", currentPageNo);
                                                            }}>
                                                            {" "}
                                                            {elem?.inv_seqno}
                                                        </Link>

                                                        {/* {elem?.erp_ref !==
                                                            "" ? (
                                                            <small
                                                                title={
                                                                    elem?.erp_ref
                                                                }
                                                                className="trunc-1 w-[100px] inline-block truncate"
                                                            >
                                                                {elem?.erp_ref}
                                                            </small>
                                                        ) : (
                                                            ``
                                                        )} */}
                                                    </td>
                                                    <td>
                                                        {/* <a
 
                                href={`${"/invoice-view?inv="}${elem?.id}&type=${pageType}`}
 
                                className={`${"flex tableHead"}`}
 
                                style={{ textDecoration: "underline" }}
 
                            >
 
                                {" "} */}

                                                        <Link
                                                            state={{ from: location.pathname }}
                                                            to={`${"/invoice-view?"}${encryptId(
                                                                `{"inv":${+elem?.id},"type":${+pageType},"seq":"${
                                                                    elem?.inv_seqno
                                                                }","status":${+elem?.ext_status_id}}`
                                                            )}
                                                       `}
                                                            style={{
                                                                pointerEvents: elem?.istransfer ? "none" : "",
                                                                textDecoration: "underline",
                                                            }}
                                                            onClick={() => {
                                                                localStorage.setItem("currentPageNo", currentPageNo);
                                                            }}>
                                                            <u>{elem?.invoice_no}</u>
                                                        </Link>
                                                    </td>
                                                    <td>
                                                        <p title={elem?.document_type} className="truncate w-[100px]">
                                                            {elem?.document_type}
                                                        </p>
                                                    </td>
                                                    <td>
                                                        <p title={elem?.vendor} className="truncate w-[100px]">
                                                            {elem?.vendor}
                                                        </p>
                                                    </td>

                                                    {/* <td>{elem?.createddate}</td> */}

                                                    <td>{elem?.invoice_date}</td>

                                                    {/* <td className="text-end">{elem?.gross_amount}</td>
 
                          <td className="text-end">{elem?.tax_amount}</td> */}

                                                    <td className="text-end">{elem?.net_amount}</td>

                                                    <td>{elem?.document_no}</td>

                                                    <td>
                                                        {elem?.inv_status?.length > 0 && (
                                                            <div className="flex items-center">
                                                                <Tooltip
                                                                    title={elem?.inv_status[0]?.status_name}
                                                                    disablePortal
                                                                    placement="bottom"
                                                                    disableInteractive
                                                                    PopperProps={{
                                                                        sx: {
                                                                            "& .MuiTooltip-tooltip": {
                                                                                fontSize: "0.9em",
                                                                                maxHeight: "120px",
                                                                                maxWidth: "250px",
                                                                                background: "#DAF9F4",
                                                                                color: "#555555",
                                                                                borderRadius: "5px",
                                                                                marginTop: "10px !important",
                                                                            },

                                                                            "& .MuiTooltip-arrow": {
                                                                                color: "#DAF9F4",
                                                                            },
                                                                        },
                                                                    }}
                                                                    arrow>
                                                                    <div
                                                                        className={`flex items-center justify-center px-2 py-3 min-w-[90px] min-h-[17px] max-w-[90px] max-h-[17px] rounded-[5px] cursor-pointer text-white`}
                                                                        style={{
                                                                            background: `${elem?.inv_status[0]?.color_code}`,
                                                                        }}>
                                                                        <span className="block truncate  "> {elem?.inv_status[0]?.status_name}</span>
                                                                    </div>
                                                                </Tooltip>
                                                                <img
                                                                    src={
                                                                        currentMode == "dark" && !elem?.message_category
                                                                            ? "/images/workflow-automation/api_call_dark.svg"
                                                                            : !elem?.message_category
                                                                            ? `/images/workflow-automation/statusApi.svg`
                                                                            : `/images/workflow-automation/StatusRed.svg`
                                                                    }
                                                                    alt="get-status"
                                                                    onClick={() => {
                                                                        setStatusInfoAnchor({
                                                                            anchor: true,
                                                                            invoiceId: elem?.id,
                                                                            seqNo: elem?.inv_seqno,
                                                                        });
                                                                    }}
                                                                    className="ml-2 max-h-[18px] cursor-pointer"
                                                                />
                                                                {/* <DynamicImage currentMode={currentMode} /> */}
                                                            </div>
                                                        )}
                                                    </td>

                                                    <td>
                                                        <Tooltip
                                                            title={Tags == null || Tags == "" ? elem?.message_category : segregatedList}
                                                            disablePortal
                                                            placement="bottom"
                                                            disableInteractive
                                                            PopperProps={{
                                                                sx: {
                                                                    "& .MuiTooltip-tooltip": {
                                                                        background: "#DAF9F4",
                                                                        color: "#555555",
                                                                        borderRadius: "5px",
                                                                        marginTop: "0 !important",
                                                                        // width: '250px'
                                                                    },
                                                                },
                                                            }}>
                                                            <Link
                                                                className={` items-end  ${
                                                                    elem?.message_category !== "" ? "border-[#D5745B] border-10 border-b-[1px]" : ""
                                                                }  text-[#D5745B] w-[100px]`}
                                                                state={{ from: location.pathname }}
                                                                to={`${"/invoice-view?"}${encryptId(
                                                                    `{"inv":${+elem?.id},"type":${+pageType},"seq":"${
                                                                        elem?.inv_seqno
                                                                    }","status":${+elem?.ext_status_id}}`
                                                                )}
                                               `}>
                                                                {elem?.message_category}
                                                            </Link>
                                                        </Tooltip>
                                                    </td>

                                                    <td>{elem?.currency}</td>
                                                    <td className="w-[10%]">{elem?.createddate_formated}</td>

                                                    <td className="text-left w-[10%]">{elem?.modifieddate_formated}</td>
                                                    <td>
                                                        <Button
                                                            title="Actions"
                                                            className="px-[10px] w-max"
                                                            onClick={handleClick("bottom-end", elem?.id)}>
                                                            <img src="/images/common/threeDots.svg" alt="threeDots" />
                                                        </Button>

                                                        <ClickAwayListener
                                                            mouseEvent="onMouseDown"
                                                            touchEvent="onTouchStart"
                                                            onClickAway={handleClickAway}>
                                                            <Popper
                                                                className={`bg-${currentMode}-card text-${currentMode}-copy px-3 py-3 border-[1px] rounded-[10px] border-${currentMode}-600`}
                                                                open={elem?.id === popstate?.id}
                                                                anchorEl={popstate?.anchorEl}
                                                                id={elem?.id}
                                                                placement={placement}
                                                                transition>
                                                                {({ TransitionProps }) => (
                                                                    <Fade {...TransitionProps} timeout={350}>
                                                                        <div>
                                                                            <ul className="">
                                                                                {elem?.istransfer == false && (
                                                                                    <li>
                                                                                        <Link
                                                                                            state={{ from: location.pathname }}
                                                                                            className={`flex mx-[-12px] px-[11px] py-[6px] transition duration-300 ease-in-out hover:bg-${currentMode}-960`}
                                                                                            to={`${"/invoice-view?"}${encryptId(
                                                                                                `{"inv":${+elem?.id},"type":${+pageType},"seq":"${
                                                                                                    elem?.inv_seqno
                                                                                                }","status":${+elem?.ext_status_id}}`
                                                                                            )}
                                                                                       `}
                                                                                            onClick={() => {
                                                                                                localStorage.setItem("currentPageNo", currentPageNo);
                                                                                            }}>
                                                                                            <img
                                                                                                className="flex-none pr-2"
                                                                                                src="/images/common/eye.svg"
                                                                                                alt="user"
                                                                                            />
                                                                                            View
                                                                                        </Link>
                                                                                    </li>
                                                                                )}

                                                                                {/* <li >
                                    <Link className="flex" to="#!" value={approvedList}
                                    onClick={()=>ApproveBtnClick()}>
                                        <img
                                        className="flex-none pr-2"
                                        src="/images/common/pencil.svg"
                                        alt="help"
                                        />
                            Approved
                                    </Link>
                                    </li> */}
                                                                                {/* <li>
                                    <Link className="flex" to="#!"value={rejectList}
                                    onClick={RejectBtnClick}>
                                    <img className="mr-2" src="/images/common/whiteClose.svg" alt="whiteClose"/>
                            Rejected
                                    </Link>
                                    </li> */}
                                                                                <li>
                                                                                    <Link
                                                                                        className={`flex mx-[-12px] px-[11px] py-[6px] transition duration-300 ease-in-out hover:bg-${currentMode}-960`}
                                                                                        onClick={() => props.downloadInvoice(elem?.id)}>
                                                                                        <img
                                                                                            className="flex-none pr-2"
                                                                                            src="/images/common/log-in.svg"
                                                                                            alt="log-in"
                                                                                        />
                                                                                        Download
                                                                                    </Link>
                                                                                </li>
                                                                                {location.pathname !== "/approve" &&
                                                                                    showTransfer &&
                                                                                    elem?.ext_status_id == 2 &&
                                                                                    elem?.istransfer == false &&
                                                                                    !OrgListData && (
                                                                                        <li>
                                                                                            <Link
                                                                                                className={`flex mx-[-12px] py-[6px] px-[11px] transition duration-300 ease-in-out hover:bg-${currentMode}-960`}
                                                                                                onClick={() => OpenTransferInv(elem?.id)}>
                                                                                                <img
                                                                                                    className="flex-none pr-2"
                                                                                                    src="/images/invoice/transfer.svg"
                                                                                                    alt="Transfer"
                                                                                                />
                                                                                                Transfer
                                                                                            </Link>
                                                                                        </li>
                                                                                    )}
                                                                            </ul>
                                                                        </div>
                                                                    </Fade>
                                                                )}
                                                            </Popper>
                                                        </ClickAwayListener>
                                                    </td>
                                                </tr>
                                            );
                                        })
                                    ) : (
                                        <div className="bg-white">
                                            <div className="text-center absolute left-[50%] translate-x-[-50%]">
                                                <div className="mx-auto my-5">
                                                    <img className="mx-auto" src="/images/common/delivery.png" alt="delivery" />
                                                </div>
                                                <h4 className="font-interSb">No Invoices found</h4>
                                            </div>
                                        </div>
                                    )}
                                </tbody>
                            </table>
                        )}
                    </div>

                    {addedInvoice?.length !== 0 && ProcessInv?.dataList !== null && (
                        <div className={`bg-${currentMode}-460 flex justify-between rounded-b-[8px] items-center py-[2.5px] xl:py-[10px] px-[15px]`}>
                            {addedInvoice?.length !== 0 && (
                                <div>
                                    <div>
                                        Showing <span>{`${start} to ${totalPageCount}`}</span> entries of <span>{pageCount}</span>
                                        {/* <Trans
                                            // i18nKey={"description.line1"}
                                            i18nKey={t("Showing")}
                                            values={{
                                                start: start,
                                                pageTotal: totalPageCount,
                                                total: pageCount,
                                            }}
                                            components={{ 1: <b /> }}
                                        ></Trans> */}
                                    </div>
                                </div>
                            )}
                            {addedInvoice?.length > 0 && (
                                <div className="xl:flex grid grid-cols-2 gap-1 xl:space-x-3 w-max">
                                    <div className="flex spacex-2 items-center">
                                        <span className="mr-2">
                                            <img
                                                src={
                                                    currentMode == "dark"
                                                        ? "/images/workflow-automation/api_call_dark.svg"
                                                        : `/images/workflow-automation/statusApi.svg`
                                                }
                                                className="min-h-[20px]"></img>
                                        </span>
                                        {t("Processed")}
                                    </div>
                                    <div className="flex spacex-2 items-center">
                                        <span className="mr-2">
                                            <img src="/images/workflow-automation/StatusRed.svg"></img>
                                        </span>{" "}
                                        {t("Exception")}
                                    </div>
                                </div>
                            )}
                            <div className="flex items-center">
                                <span>The page you're on</span>
                                <Select
                                    name=""
                                    className={`bg-${currentMode}-card insideTble mx-3 text-xs rounded-[5px] entries`}
                                    id=""
                                    value={currentPageNo}
                                    onChange={(e) => onPageNumberSelect(e)}
                                    IconComponent={() => (
                                        <img
                                            className="absolute opacity-50 right-[8px] pointer-events-none"
                                            src={currentMode !== "dark" ? `/images/header/chevronDown.svg` : `/images/header/chevronDown-white.svg`}
                                        />
                                    )}
                                    SelectDisplayProps={{
                                        style: {
                                            paddingTop: "5px !important",
                                            display: "flex",
                                            paddingBottom: "5px !important",
                                            paddingLeft: "10px",
                                            border: 0,
                                            color: currentMode === "dark" ? `#ECECEC` : `#555555`,
                                            paddingRight: "25px",
                                        },
                                    }}
                                    MenuProps={{
                                        sx: {
                                            "& .MuiMenu-paper": {
                                                backgroundColor: dropdownColor,
                                                color: dropdownText,
                                                boxShadow: dropdownShadow,
                                                border: dropdownBorder,
                                                borderRadius: "8px",
                                            },
                                        },
                                    }}>
                                    {EmptyArr?.map((elem, i) => (
                                        <MenuItem key={i} value={elem + 1}>
                                            {elem + 1}
                                        </MenuItem>
                                    ))}
                                </Select>
                                <div className={`flex space-x-2 border-l-[1px] border-${currentMode}-830  pl-3`}>
                                    <Button
                                        title="Previous page"
                                        disabled={currentPageNo > 1 ? false : true}
                                        onClick={() => previousBtnClick()}
                                        className={`border-${currentMode}-840 border-[1px] rounded-[5px] bg-${currentMode}-card px-2 py-3`}>
                                        <img className="rotate-180 convertWhite" src="/images/common/arrowRight.svg" alt="arrowRight" />
                                    </Button>

                                    <Button
                                        title="Next page"
                                        className={`border-${currentMode}-840 border-[1px] rounded-[5px] bg-${currentMode}-card px-2 py-3`}
                                        disabled={currentPageNo >= nextPageCount ? true : false}
                                        onClick={() => nextBtnClick()}>
                                        <img className="convertWhite" src="/images/common/arrowRight.svg" alt="arrowRight" />
                                    </Button>
                                </div>
                            </div>
                        </div>
                    )}
                </form>
                {/* <InvoiceProcessFilterModal props?.searchValue={props?.searchValue} /> */}
            </Card>
            {ReactDOM.createPortal(
                <TransferInvoice
                    isOpened={openTransfer}
                    InvId={InvId}
                    handleClose={() => setOpenTransfer(false)}
                    className={openTransfer && "Show"}
                />,
                document.getElementById("user-modal-root")
            )}
            {statusInfoAnchor?.anchor && (
                <>
                    {ReactDOM.createPortal(
                        <StatusInfoComponent
                            isOpened={statusInfoAnchor?.anchor}
                            setStatusInfoAnchor={setStatusInfoAnchor}
                            currentMode={currentMode}
                            className={statusInfoAnchor?.anchor && "Show"}
                            orgId={orgId}
                            seqNo={statusInfoAnchor?.seqNo}
                            invoiceId={statusInfoAnchor?.invoiceId}
                        />,
                        document.getElementById("Modal-root")
                    )}
                </>
            )}
        </>
    );
};

export default Table;
