import { useContext } from "react";
import { ThemeContext } from "../../Hooks/Themes/Themeprovider";
import styles from "./index.module.scss";
const Stepper = (props) => {
    const { currentMode, toggleMode } = useContext(ThemeContext);
    return <div className={styles?.steppers}>
        <div
            className={`${styles[`steppers-left`]
                } ${currentMode === "dark" ? "bg-[transaparent]" : "bg-white"} ${currentMode !== "dark" ? "shadow-[0px_4px_20px_rgba(238,238,238,0.5)]" : ""} border-[1px] border-[rgba(223,225,226,0.56)]`}
        >
            {props?.no}
        </div>
        {props?.children}
    </div>
}

export default Stepper;
