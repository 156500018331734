import {
    ACTIVATE_DEACTIVATE_EMAIL_CONFIG_FAILURE,
    ACTIVATE_DEACTIVATE_EMAIL_CONFIG_REQUEST,
    ACTIVATE_DEACTIVATE_EMAIL_CONFIG_RESET,
    ACTIVATE_DEACTIVATE_EMAIL_CONFIG_SUCCESS,

    ADD_EMAIL_CONFIG_FAILURE,
    ADD_EMAIL_CONFIG_REQUEST,
    ADD_EMAIL_CONFIG_RESET,
    ADD_EMAIL_CONFIG_SUCCESS,

    GET_EMAIL_CONFIG_FAILURE,
    GET_EMAIL_CONFIG_REQUEST,
    GET_EMAIL_CONFIG_RESET,
    GET_EMAIL_CONFIG_SUCCESS,
	UPDATE_EMAIL_CONFIG_FAILURE,
	UPDATE_EMAIL_CONFIG_REQUEST,
	UPDATE_EMAIL_CONFIG_RESET,
	UPDATE_EMAIL_CONFIG_SUCCESS
} from "../types/types";

//email list

const ListEmailState = {
    configData: [],
    fetching: false,
    requestComplete: false,
    error: "",
};

export function emailConfigList(state = ListEmailState, action) {
    switch (action.type) {
        case GET_EMAIL_CONFIG_REQUEST:
            return {
                ...state,
                fetching: true,
                requestComplete: false,
                error: "",
            };
        case GET_EMAIL_CONFIG_SUCCESS:
            return {
                configData: action.payload,
                fetching: false,
                requestComplete: true,
                error: "",
            };
        case GET_EMAIL_CONFIG_RESET:
            return {
                configData: [],
                fetching: false,
                requestComplete: false,
                error: "",
            };
        case GET_EMAIL_CONFIG_FAILURE:
            return {
                fetching: false,
                requestComplete: false,
                error: action.payload,
            };
        default:
            return state;
    }
}

//emailAdd
const emailConfigAddState = {
    profile: [],
    fetching: false,
    requestComplete: false,
    error: "",
};
export function emailConfigAdd(state = emailConfigAddState, action) {
	switch (action.type) {
		case   ADD_EMAIL_CONFIG_REQUEST:
			return {
				...state,
				fetching: true,
				requestComplete: false,
				error: "",
			};
		case ADD_EMAIL_CONFIG_SUCCESS:
			return {
				profile: action.payload,
				fetching: false,
				requestComplete: true,
				error: "",
			};
		case ADD_EMAIL_CONFIG_RESET:
			return {
				profile: {},
				fetching: false,
				requestComplete: false,
				error: "",
			};
		case ADD_EMAIL_CONFIG_FAILURE:
			return {
				fetching: false,
				requestComplete: false,
				error: action.payload,
			};
		default:
			return state;
	}
}

//email active-inactive
const emailConfigStatusState = {
   data: [],
    fetching: false,
    requestComplete: false,
    error: "",
};
export function emailConfigStatus(state = emailConfigStatusState, action) {
	switch (action.type) {
		case   ACTIVATE_DEACTIVATE_EMAIL_CONFIG_REQUEST:
			return {
				...state,
				fetching: true,
				requestComplete: false,
				error: "",
			};
		case ACTIVATE_DEACTIVATE_EMAIL_CONFIG_SUCCESS:
			return {
				data: action.payload,
				fetching: false,
				requestComplete: true,
				error: "",
			};
		case ACTIVATE_DEACTIVATE_EMAIL_CONFIG_RESET:
			return {
				data: {},
				fetching: false,
				requestComplete: false,
				error: "",
			};
		case ACTIVATE_DEACTIVATE_EMAIL_CONFIG_FAILURE:
			return {
				fetching: false,
				requestComplete: false,
				error: action.payload,
			};
		default:
			return state;
	}
}
//EMAIL CONFIG UPDATE

const emailConfigUpdateState = {
    data: [],
    fetching: false,
    requestComplete: false,
    error: "",
};
export function emailConfigUpdate(state = emailConfigUpdateState, action) {
	switch (action.type) {
		case   UPDATE_EMAIL_CONFIG_REQUEST:
			return {
				...state,
				fetching: true,
				requestComplete: false,
				error: "",
			};
		case UPDATE_EMAIL_CONFIG_SUCCESS:
			return {
				data: action.payload,
				fetching: false,
				requestComplete: true,
				error: "",
			};
		case UPDATE_EMAIL_CONFIG_RESET:
			return {
				data: {},
				fetching: false,
				requestComplete: false,
				error: "",
			};
		case UPDATE_EMAIL_CONFIG_FAILURE:
			return {
				fetching: false,
				requestComplete: false,
				error: action.payload,
			};
		default:
			return state;
	}
}