import { useContext, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import Container from "../../components/UI/Container";
import { ThemeContext } from "../../Hooks/Themes/Themeprovider";
import Content from "../../organisms/SignIn/Content";
import Form from "../../organisms/SignIn/Form";

const SignIn = () => {
    const location = useLocation();
    const { currentMode } = useContext(ThemeContext);

    // useEffect(() => {
    //     let bodys = document.querySelector("body").classList;
    //     location.pathname === "/"
    //         ? bodys.add(`bg-${currentMode}-base`)
    //         : bodys.remove(`bg-${currentMode}-base`);
    // }, [location.pathname]);
    return (
        <Container>
            <div className="my-2">
                <Link
                    tabIndex="1"
                    to={"//www.myiq.ai/"}
                    target="_blank"
                    className="inline-block"
                >
                    <img
                        src="/images/logo/MyiqFinal.svg"
                        className="w-[100px] h-[75px]"
                        alt="logoiq"
                    />
                </Link>
            </div>
            <div className="flex flex-col-reverse lg:flex-row">
                <div className="lg:w-1/2 pb-7">
                    <Content />
                </div>
                <div className="lg:w-[45%] py-7 lg:pl-[3rem] border-[#55555526] lg:border-l-[1px] lg:ml-auto">
                    <Form />
                </div>
            </div>
        </Container>
    );
};

export default SignIn;
