import Button from "../../components/Button";
import { Checkbox, ClickAwayListener, FormControlLabel, Popper } from "@mui/material";
import _, { concat, isEqual, remove } from "lodash";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Loader from "../../components/Loader";
import Card from "../../components/UI/Card";
import { getReportsList, reportsCountTotal } from "../../redux/actions/report";
import ExportExcel from "../ExportFiles/Excel";
import TogglePopup from "../organisations/Toggle";
import { GET_REPORTS_ACCRUAL_RESET } from "../../redux/types/types";
import { ThemeContext } from "../../Hooks/Themes/Themeprovider";

const AccrualTbl = (props) => {
    const { currentMode } = useContext(ThemeContext);
    const [currentPageNo, changePageNo] = useState(1);
    const [isAllSelected, setAllSelected] = useState(false);
    const [isdownloadClick, onClickDownload] = useState(false);
    const [isIndeterminate, setIndeterminate] = useState(false);
    const [isSorting, setSorting] = useState(0);
    const [currentPageItem, changePageItem] = useState(10);
    const pageNo = currentPageNo;
    const entries = currentPageItem;
    const [isMount, setIsMount] = useState(false);

    const pageData = useSelector((state) => state?.GetreportCountState);
    const pageCount = pageData && pageData.data && pageData.data?.data ? pageData.data.data.data.rec_cnt : 0;
    let start = (pageNo - 1) * entries + 1;

    const nextPageCount = Math.ceil(pageCount / currentPageItem);
    const [sortColumn, columnSorting] = useState("");
    const [approveList, onApproveInvoice] = useState([]);
    const getApproveInvList = (invList) => {
        onApproveInvoice(invList);
    };
    const orgId = localStorage.getItem("orgId");
    const supplier = props?.isSupplier;
    const invNo = props?.invNumber;
    const status = props?.isStatus;
    const reqObj = {
        status_type: 0,
        status_id: status,
        organisation: +orgId,
        vendor_id: supplier,
        //invoice_date_from: "2023-03-08T06:30:42.501Z",
        //invoice_date_to: "2023-03-08T06:30:42.501Z",
        search_text: "",
        invoice_no: invNo,
        order_column: sortColumn,
        order_by: isSorting,
        pg_no: currentPageNo,
        rec_cnt: currentPageItem,
    };
    const reqObjCount = {
        status_type: 0,
        status_id: status,
        // invoice_date_from: null,
        // invoice_date_to:null,
        search_text: "",
        organisation: +orgId,
        vendor_id: supplier,
        invoice_no: invNo,
    };
    const CountCalc = pageCount / currentPageItem;

    const Rounded = Math.ceil(CountCalc);

    const onPageNumberSelect = (event) => {
        let pageNumber = event.target.value;
        changePageNo(parseInt(pageNumber));
    };
    const onPageItemSelect = (event) => {
        setAllSelected(false);
        let pageItemVal = event.target.value;
        changePageItem(parseInt(pageItemVal));
        changePageNo(1);
    };
    let EmptyArr = [];
    for (let i = 0; i < Rounded; i++) {
        EmptyArr.push(i);
    }
    const previousBtnClick = () => {
        if (isAllSelected === true) {
            setAllSelected(false);
        }
        if (isIndeterminate == true) {
            setIndeterminate(false);
        }
        changePageNo(currentPageNo - 1);
    };
    const nextBtnClick = () => {
        if (isAllSelected === true) {
            setAllSelected(false);
        }
        if (isIndeterminate == true) {
            setIndeterminate(false);
        }
        changePageNo(currentPageNo + 1);
    };
    const dispatch = useDispatch();

    const onSorting = (columnName) => {
        const sortBy = isSorting === 1 ? 2 : 1;
        if (sortBy === 1) {
            document.getElementById(columnName).classList.add("Up");
        } else {
            document.getElementById(columnName).classList.remove("Up");
        }

        setSorting(sortBy);
        columnSorting(columnName);
    };
    const onSortingIconChange = () => {
        if (requestComplete) {
            if (isSorting === 1) {
                document.getElementById(sortColumn).classList.add("Up");
            } else {
                document.getElementById(sortColumn).classList.remove("Up");
            }
        }
    };
    useEffect(() => {
        if (isMount) {
            async function fetchEmployees() {
                await dispatch(reportsCountTotal(reqObjCount));
                await dispatch(getReportsList(reqObj));
                onSortingIconChange();
            }
            fetchEmployees();
        } else {
            setIsMount(true);
            dispatch({ type: GET_REPORTS_ACCRUAL_RESET });
        }
    }, [dispatch, currentPageNo, currentPageItem, sortColumn, isSorting]);
    const { dataList, requestComplete, fetching } = useSelector((state) => state?.GetReportAccrualListState);
    let totalPageCount = dataList ? dataList.length * (currentPageNo ? currentPageNo : 1) : 0;
    const totalPageExceed = currentPageNo * currentPageItem;
    if (totalPageExceed >= pageCount) {
        totalPageCount = pageCount;
    }

    const downloadeExcel = () => {
        const AccDetailData = dataList?.map((ele, index) => ele);
        const [Excel] = dataList?.map((el) => el);
        const invoiceExcelData = [];
        const [desc] = Excel?.acc_details?.map((el) => el?.acc_description);
        const code = Excel?.acc_details?.map((el) => el?.account_code);
        const [amnt] = Excel?.acc_details?.map((el) => el?.amount);
        const [credit] = Excel?.acc_details?.map((el) => el?.debit_credit);
        if (AccDetailData) {
            AccDetailData?.forEach((ele) => {
                let invoiceFields = _.map(ele?.acc_details, (object) => {
                    return _.pick(object, ["account_code", "debit_credit", "amount", "acc_description", "cost_centre"]);
                });

                invoiceFields.forEach((item) => {
                    let invHeaders = {
                        Organisation: ele?.organisation,
                        "Month + Year": ele?.month_year,
                        "Posting Reference": ele?.erp_ref == null || ele?.erp_ref == "" ? "Not Posted" : ele?.erp_ref,
                        "Posting Date": ele?.posting_date.split("T")[0],
                        "Account Description": item?.acc_description,
                        "Account code": item?.account_code,

                        Amount: item?.amount,
                        "Debit Or Credit": item?.debit_credit == 1 ? "Cr" : "Dr",
                        "Cost Center": item?.cost_centre,
                    };
                    invoiceExcelData.push(invHeaders);
                });
            });

            ExportExcel({ excelData: invoiceExcelData, fileName: "MyiQ.Ai" });
            onClickDownload(false);
        }
    };
    const onExportExcel = () => {
        if (dataList.length > 0) {
            onClickDownload(true);
        }
    };
    if (requestComplete && isdownloadClick) {
        let downloadInvoiceList = dataList;
        downloadeExcel(downloadInvoiceList);
    }

    const ChildTbl = ({ acc }) =>
        acc?.map((el, i) => (
            <div className="accrual-parent">
                <div key={i} className="childTbl flex">
                    <span className="basis-[25%]">
                        <p className="trunc-2 w-[118px]">{el?.debit_credit === 1 ? `Cr` : `Dr`}</p>
                    </span>
                    <span className="basis-1/2">
                        <p className="trunc-2 w-[118px]">{el?.account_code}</p>
                    </span>
                    <span className="basis-1/2 trunc-2">
                        <p className="trunc-2 w-[118px]">{el?.cost_centre}</p>
                    </span>
                    <span className="basis-1/2 trunc-2 h-[52px] cursor-default" title={el?.acc_description}>
                        <p className="trunc-2 w-[118px]">{el?.acc_description}</p>
                    </span>
                    <span className="basis-1/2 trunc-2 border-b-0">
                        <p className="trunc-2 w-[118px]">{el?.amount}</p>
                    </span>
                </div>
            </div>
        ));
    return (
        <Card className="mb-[4rem]">
            <div className="flex justify-end">
                {/* <div className="flex items-center">
          <div className="flex items-center">
            <span>Show</span>
            <select
              name="entrycount"
              className="border-[1px] mx-3 text-xs customDropdown entries border-[#dfe1e28f]"
              value={currentPageItem}
              onChange={(e) => onPageItemSelect(e)}
            >
              <option value={10}>{10}</option>
              <option value={15}>{15}</option>
              <option value={20}>{20}</option>
              <option value={25}>{25}</option>
            </select>
            <span>entries</span>
          </div>
        </div> */}
                {dataList?.length !== 0 && (
                    <div className="flex items-center space-x-2 px-[30px] py-[20px]">
                        <div>
                            <Button
                                title="To Export Invoice to Excel"
                                // onClick={handleClick("top-start")}
                                onClick={() => onExportExcel()}
                                className={`border-20 export-step px-[15px] py-2 rounded-[8px] font-interM text-sm flex justify-center items-center border-[1px] text-${currentMode}-copy border-light-20`}
                            >
                                <img className="mr-[.4rem]" src="/images/common/download-excel.svg" alt="upload" /> Download
                            </Button>
                        </div>
                    </div>
                )}
            </div>
            <div className={`heightFix invoices relative report ${dataList?.length == 0 ? `rounded-t-[8px]` : ``}`}>
                <table className={`table-auto tabedTble TblNormal invoice reports accrual w-full`}>
                    <thead className="sticky top-0 z-[1]">
                        <tr>
                            <th className="border-0">Organization </th>
                            <th className="border-0">Month + Year </th>
                            <th className=" border-0">Posting Reference</th>
                            <th className="border-0">Posting Date</th>
                            <th className="!p-0 border-0 accrual-th">
                                <div className="accrual-parent">
                                    <div className="childTbl flex">
                                        <span className="basis-1/2 trunc-2 border-0">
                                            <p className="trunc-2 w-[118px]">Dr/Cr</p>
                                        </span>
                                        <span className="basis-1/2 trunc-2 border-0">
                                            <p className="trunc-2 w-[118px]">Account Code</p>
                                        </span>
                                        <span className="basis-1/2 trunc-2 border-0">
                                            <p className="trunc-2 w-[118px]">Cost Center</p>
                                        </span>
                                        <span className="basis-1/2 trunc-2 border-0">
                                            <p className="trunc-2 w-[118px]">Description</p>
                                        </span>
                                        <span className="basis-1/2 border-0 trunc-2">
                                            <p className="trunc-2 w-[118px]">Amount</p>
                                        </span>
                                    </div>
                                </div>
                            </th>
                        </tr>
                    </thead>
                    {fetching ? (
                        <Loader className="h-[calc(100vh_-_26rem)] absolute left-[50%] translate-x-[-50%]" />
                    ) : (
                        <tbody>
                            {dataList?.length !== 0 ? (
                                dataList?.map((elem, index) => {
                                    const data = elem?.erp_ref;

                                    // Replace commas with line breaks
                                    const formattedData = data.replace(/,/g, "\n");
                                    const tdStyle = {
                                        whiteSpace: "pre-line", // Preserve line breaks
                                    };
                                    return (
                                        <tr key={index}>
                                            <td>
                                                <p className="truncate w-[150px]">{elem?.organisation}</p>
                                            </td>
                                            <td>{elem?.month_year}</td>
                                            <td className="w-[10rem]" style={tdStyle}>
                                                <span className="block">
                                                    {elem?.erp_ref == null || elem?.erp_ref == "" ? "Not Posted" : formattedData}
                                                </span>
                                            </td>
                                            <td>{elem?.posting_date != null ? elem?.posting_date?.split("T")?.[0] : ""}</td>
                                            <td className="p-[0] border-b-0">
                                                <ChildTbl acc={elem?.acc_details} />
                                            </td>
                                        </tr>
                                    );
                                })
                            ) : (
                                <div className="absolute top-[50%] left-[50%] translate-y-[-50%] translate-x-[-50%]">
                                    <img src="/images/common/delivery.png" alt="delivery" />
                                    <h4 className="font-interSb text-center mt-3">No data found</h4>
                                </div>
                            )}
                        </tbody>
                    )}
                </table>
            </div>
        </Card>
    );
};

export default AccrualTbl;
