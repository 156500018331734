import Modal from "../../components/UI/Modal/Modal";
import { ThemeContext } from "../../Hooks/Themes/Themeprovider";
import { useContext } from "react";
import Input from "../../components/Input";
import Button from "../../components/Button";
import { MenuItem, Select, } from "@mui/material";

const CreateReminderModal = (props) => {
    const { currentMode, toggleMode } = useContext(ThemeContext);
    const dropdownColor = currentMode === "dark" ? "#333333" : `white`;
    const dropdownText = currentMode === "dark" ? "#ECECEC" : `#555555`;
    return (
        <>
            <Modal cartClose={props?.handleClose} center="center" overlap={true} className={`${props?.className}`}>
                <div
                    className={`max-w-[500px] mx-auto relative bg-${currentMode}-card  boder-${currentMode}-700 text-${currentMode}-copy px-[90px] mx-auto  py-[48px] rounded-[10px] shadow-[0px_4px_20px_rgba(238,238,238,0.5)]`}
                >
                    <span onClick={props?.handleClose} title="Close" className="cursor-pointer absolute right-[20px] top-[20px]">
                        <img src="/images/common/closeBlk.svg" alt="closeBlk" />
                    </span>

                    <span className={`block text-sm pb-3`}>
                        <span className="text-[red] mr-1">*</span>
                        Escalation and Reminder Name
                    </span>


                    <div id="myView" className="overflow-y-auto max-h-[20rem] pr-[20px]">

                        <div className="flex items-center space-x-3 mb-[1rem]">
                            <Input
                                tabIndex="0"
                                name="Reminder"
                                maxLength={250}
                                placeholder="Enter your Reminder"
                                className={`border-[1px] borderLeft border-${currentMode}-840 rounded-[8px] pl-[20px] py-2 w-[350px] bg-${currentMode}-810`}
                            />

                        </div>

                    </div>







                    <div className="flex items-center mt-7 space-x-3">


                        <Button
                            className="bg-light-20 px-[30px] py-[9px] text-white rounded-[8px] w-[350px]"
                            type="submit"
                            title="Save"
                        //   disabled={fetching}
                        >
                            Create Reminder
                        </Button>



                    </div>
                </div>
            </Modal>
        </>
    );
};
export default CreateReminderModal;
