import React, { useState } from "react";
import { useDispatch } from "react-redux";
import Card from "../../../components/UI/Card";
import UserCard from "../../../components/UserCard";
import { GETALLUSER_BYORG__RESET } from "../../../redux/types/types";

const ListContent = (props) => {
    const dispatch = useDispatch();
    const EditOpen = props?.EditOpen;
    const searchObj = props?.searchObj;
    const userData = props?.Data;
    return (
        <>
            <div className="grid xl:grid-cols-2 gap-5 mb-[4rem]">
                {searchObj && searchObj && !props?.filterpage ? (
                    <React.Fragment>
                        <UserCard EditOpen={EditOpen} ListData={searchObj} />
                    </React.Fragment>
                ) : props?.searchInput.length > 1 ? (
                    props?.filteredData?.map((el, i) => {
                        return (
                            <React.Fragment key={i}>
                                <UserCard EditOpen={EditOpen} ListData={el} userData={userData} />
                            </React.Fragment>
                        );
                    })
                ) : props?.Data?.length !== 0 ? (
                    props?.Data?.map((el, i) => {
                        return (
                            <React.Fragment key={i}>
                                <UserCard EditOpen={EditOpen} ListData={el} />
                            </React.Fragment>
                        );
                    })
                ) : (
                    <div className="h-full grid place-items-center place-content-center">
                        <img className="mb-3" src="/images/common/searching.png" alt="searching" />
                        <h4 className="font-interSb">No data found</h4>
                        <p>Click add user button to add users details</p>
                    </div>
                )}
            </div>
            {(props?.searchInput?.length > 1 && props?.filterpage && userData?.length === undefined && props?.filteredData?.length === 0) ||
            userData?.length === undefined ||
            (props?.searchInput?.length > 1 && !props?.filterpage && props?.filteredData?.length === 0) ||
            (props?.filteredData?.length == 0 && props?.searchInput?.length > 1) ? (
                <div className={`h-[calc(100vh_-_18rem)] grid place-items-center place-content-center bg-transparent`}>
                    <img className="mb-3" src="/images/common/delivery.png" alt="searching" />
                    <h4 className="font-interSb">No Users found</h4>
                </div>
            ) : (
                ""
            )}
        </>
    );
};

export default ListContent;
