import { useEffect, useState } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import Button from "../../../components/Button";
import Modal from "../../../components/UI/Modal/Modal";
import { countryList } from "../../../redux/actions/country";
import EditUserModal from "./EditUser";
import configMyiQ from "../../../configuration";
import authHeader from "../../../redux/actions/auth-header";
import Swal from "sweetalert2";
import UseForm from "../../../Hooks/useForm";
import { useContext } from "react";
import { ThemeContext } from "../../../Hooks/Themes/Themeprovider";

const EditUser = (props) => {
    const { currentMode } = useContext(ThemeContext);
    const dispatch = useDispatch();
    const { handleChange, handleSubmit, values, errors, setErrors, setValues, InitialState } = UseForm();
    const UserEditData = useSelector((state) => state?.userViewState);
    const { viewData } = UserEditData;
    const [fileName, onFileUpload] = useState("");
    const [imageFile, onFileHandle] = useState([]);
    const [saveFile, onSaveFile] = useState("");
    useEffect(() => {
        //dispatch(countryList());
    }, [saveFile, fileName]);

    const fileTypes = ["image/png", "image/jpg", "image/jpeg"];
    const onChange = (e) => {
        let file = e.target.files[0];
        const fixExist = fileTypes?.find((item) => item == file?.type);

        const fileSize = (file.size / (1 * 1024)).toFixed(2); //mb conversion
        if (!fixExist) {
            Swal.fire({
                text: "File type is not supported!",
                icon: "warning",
                showCloseButton: true,
                closeOnClickOutside: false,
                buttons: {
                    confirm: true,
                },
            });
        } else if (fileSize > 50) {
            Swal.fire({
                text: "File size should be less than 50kb!",
                icon: "warning",
                closeOnClickOutside: false,
                showCloseButton: true,
                buttons: {
                    confirm: true,
                },
            });
        } else {
            onSaveFile(file);
            const reader = new FileReader();
            reader.onload = _handleReaderLoaded.bind(this);
            reader.readAsBinaryString(file);
            // handleUpload();
        }
    };

    const _handleReaderLoaded = (e) => {
        let binaryString = e.target.result;
        onFileUpload(btoa(binaryString));
    };
    const handleFile = (e) => {
        let Image = e.target.files[0];
        onFileHandle([Image]);
    };
    const cartReset = () => {
        props?.handleClose();
        setErrors({});
        setValues({
            InitialState,
        });
    };
    return (
        <Modal cartClose={props?.handleClose} className={`${props?.className}`}>
            <Link onClick={cartReset} className="p-[6.5px] absolute right-[712px] bg-light-10" title="Close">
                <img src="/images/common/whiteClose.svg" title="Close" alt="whiteClose" />
            </Link>
            <div
                className={`max-w-[712px] min-w-[550px] overflow-y-auto ml-auto transition duration-150 ease-out flex-col flex h-screen max-h-[-webkit-fill-available] bg-${currentMode}-card text-${currentMode}-copy border-${currentMode}-700`}
            >
                <div className={`bg-${currentMode}-580  min-h-[150px] mb-[6.25rem] relative`}>
                    <div className="absolute bottom-[-50%] left-[50%] translate-x-[-50%]">
                        <div
                            className={`mb-2 bg-${currentMode}-card ${currentMode !== "dark"
                                ? "shadow-[0px_4px_37px_#EEEEEE]"
                                : ""
                                }  p-2 rounded-full`}
                        >
                            <div>
                                <img
                                    className="rounded-[50%] object-cover w-[120px] h-[120px]"
                                    src={
                                        saveFile !== ""
                                            ? `data:image;base64,${fileName}`
                                            : viewData?.user_image
                                                ? `data:image;base64,${viewData?.user_image}`
                                                : `/images/common/profileImg.jpg`
                                    }
                                    alt="Profile"
                                />
                                {/* <Button>
                  <img
                    className="mb-1"
                    src="/images/common/removeIcon.svg"
                    alt="removeIcon"
                  />
                  Remove
                </Button> */}
                            </div>
                        </div>
                        <div className="upload-btn-wrapper" title="To update Profile Photo">
                            <NavLink to="#!" className="flex cursor-pointer items-center mb-2 justify-center">
                                <img className="mr-1" src="/images/common/camera.svg" alt="camera" /> Upload
                            </NavLink>
                            <input className="cursor-pointer" type="file" name="myfile" accept=".jpg, .jpeg, .png" onChange={(e) => onChange(e)} />
                        </div>
                    </div>
                </div>
                <EditUserModal cartClose={props?.handleClose} fileName={saveFile} imageView={viewData?.user_image} />
            </div>
        </Modal>
    );
};

export default EditUser;
