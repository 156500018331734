import { FormControl, MenuItem, Select, Tooltip } from "@mui/material";
import Button from "../../../components/Button";
import { SelectDropdwnImg, extractTextFromHTML, showSwal } from "../../../utils/helpers";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { GetPreviewData } from "../../../redux/actions/output-management";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import _ from "lodash";
import { setDroppedValues } from "../../../redux/actions/filter";
import { MatrixColTarget } from "../../../organisms/DropTarget/MatrixColumns";
import { RowsTarget } from "../../../organisms/DropTarget/Rows";
import { MoreFilterTarget } from "../../../organisms/DropTarget/MoreFilter";
import { SubTtlTarget } from "../../../organisms/DropTarget/SubTtl";
import { SortingTarget } from "../../../organisms/DropTarget/Sorting";
import Loader from "../../../components/Loader";

export const MatrixTble = ({
    addItems,
    setAddItems,
    currentMode,
    accordionItems,
    setAccordionItems,
    filteredAccordionItems,
    type,
    setFilteredAccordionItems,
    prevtype,
}) => {
    const dispatch = useDispatch();
    const OrgId = localStorage.getItem("orgId");
    const { preview, fetching } = useSelector((state) => state?.getPreviewState);
    const { droppedValues, subTtalValues, sortingValues, morefilterValues, matrixColValues, rowsValues } = useSelector(
        (state) => state?.droppedState
    );
    let SNo = 0;
    const _handlePreview = () => {
        const hasNumericType = rowsValues?.some((item) => item?.data_type === "n" || item?.data_type === "m" || item?.data_type === "p");
        const numericTypes = rowsValues?.filter((item) => item?.data_type === "n" || item.data_type === "m" || item?.data_type === "p");
        const filteredData = numericTypes?.filter((item) => item?.Agg !== null);
        if (matrixColValues?.length >= 1 && rowsValues?.length >= 1) {
            setAddItems({ ...addItems, preview: true });
            const ReqObj = {
                dynamicquery: [...droppedValues, ...subTtalValues, ...sortingValues, ...morefilterValues, ...matrixColValues, ...rowsValues],
                orgid: +OrgId,
                dataset_id: +type,
                output_type: +prevtype,
            };
            if (rowsValues?.length < 2) {
                showSwal(`Select at least two rows to proceed!`);
                return false;
            } else if (!hasNumericType) {
                showSwal(`Select at least one number type rows to proceed!`);
                return false;
            } else if (filteredData?.length == 0) {
                showSwal(`Select the aggregate function for the ${numericTypes?.[0]?.field_display_name}!`);
                return false;
            } else if (numericTypes?.length > 1) {
                showSwal(`Only one number type rows has to be selected!`);
                return false;
            }
            dispatch(GetPreviewData(ReqObj));
        }
    };
    const handleDrop = (itemValue, areaName) => {
        const conditions = {
            matrixcolumns: true,
            rows: true,
            subtotal: itemValue?.checked,
            subtotal: itemValue?.checked && (itemValue?.data_type === "n" || itemValue?.data_type === "m"),
            sorting: itemValue?.checked,
            morefilter: !itemValue?.checked,
        };
        if (conditions[areaName]) {
            if (matrixColValues?.length < 1 && areaName == "matrixcolumns") {
                dispatch(setDroppedValues(itemValue, true, areaName));
            } else if (areaName == "rows") {
                dispatch(setDroppedValues(itemValue, true, areaName));
            } else {
                if (areaName != "rows" && areaName != "matrixcolumns") {
                    dispatch(setDroppedValues(itemValue, true, areaName));
                } else {
                    showSwal(`Field should be allow one`);
                    return false;
                }
            }
        } else {
            if (areaName === "subtotal" && itemValue?.checked && (itemValue?.data_type !== "n" || itemValue?.data_type !== "m")) {
                showSwal(`Subtotal should be number type`);
            } else {
                showSwal(
                    `${_.startCase(areaName == "morefilter" ? `More filter` : areaName)} is allowed for the ${areaName == "morefilter" ? `un` : ``
                    }selected fields/columns`
                );
            }
        }
    };
    const getUniqueValues = (columnName) => {
        return {
            label: preview?.header?.map((el, i) => ({
                name: el?.field_display_name,
                column: el?.column_name,
            })),
            headers: [...new Set(preview?.data?.map((item) => "" + item[columnName]))],
        };
    };
    let Headers = getUniqueValues(preview?.matrix?.[0]?.column_name);

    const numericKeyArray = preview?.data?.map((item) => {
        const numericKey = Object.keys(item).find((key) => typeof item[key] === "number");
        return numericKey || 0; // null if no numeric key is found
    });
    const nonZeroValue = numericKeyArray?.find((value) => value !== 0);
    const ToolTip = ({ text }) => (
        <Tooltip
            title={text}
            disablePortal
            placement="top"
            disableInteractive
            PopperProps={{
                sx: {
                    "& .MuiTooltip-tooltip": {
                        fontSize: "0.9em",
                        maxHeight: "120px",
                        maxWidth: "300px",
                        background: "#DAF9F4",
                        color: "#555555",
                        borderRadius: "5px",
                        paddingBottom: "10px",
                        paddingTop: "10px",
                        paddingLeft: "15px",
                    },

                    "& .MuiTooltip-arrow": {
                        color: "#DAF9F4",
                    },
                },
            }}
            arrow>
            <img className="ml-2 cursor-pointer" src="/images/main/noun-information.svg" alt="noun-information" />
        </Tooltip>
    );

    return (
        <>
            {" "}
            <div className="p-[20px] flex justify-end">
                <div className=" space-x-[10px]">
                    <Button
                        disabled={addItems?.sorting}
                        title="Add Sorting"
                        onClick={() => setAddItems({ ...addItems, sorting: true })}
                        className={`border-${currentMode}-840 border rounded-[8px] px-[15px] py-[3.5px]`}>
                        Add Sorting
                    </Button>
                    <Button
                        title="More Filters"
                        disabled={addItems?.filters}
                        onClick={() => setAddItems({ ...addItems, filters: true })}
                        className={`border-${currentMode}-840 border rounded-[8px] px-[15px] py-[3.5px]`}>
                        More Filters
                    </Button>
                    <Button
                        title="Add Subtotal"
                        disabled={addItems?.subtotal}
                        onClick={() => setAddItems({ ...addItems, subtotal: true })}
                        className={`border-${currentMode}-840 border rounded-[8px] px-[15px] py-[3.5px]`}>
                        Add Subtotal
                    </Button>
                    <Button
                        title="Preview Data"
                        disabled={fetching}
                        onClick={_handlePreview}
                        className={`border-${currentMode}-20 bg-${currentMode}-20 text-white border rounded-[8px] px-[15px] py-[3.5px]`}>
                        Preview Data
                    </Button>
                </div>
            </div>
            <div className="flex flex-wrap">
                <div className={`basis-[100%] border-y border-${currentMode}-1240`}>
                    <div className={`py-[12px] flex px-[20px] w-full bg-${currentMode}-card border-b border-${currentMode}-680 inline-block `}>
                        <span className={`font-interM block`}>Columns</span>
                        <ToolTip text="Drag and drop or select at least one Column data field to specify which data attributes should be included in generating the matrix reports." />
                    </div>
                    <div className="overflow-y-auto max-h-[15rem]">
                        <DndProvider backend={HTML5Backend}>
                            <MatrixColTarget
                                onDrop={handleDrop}
                                setAccordionItems={setAccordionItems}
                                accordionItems={accordionItems}
                                setFilteredAccordionItems={setFilteredAccordionItems}
                                filteredAccordionItems={filteredAccordionItems}
                                areaName="matrixcolumns"
                                type={type}
                                currentMode={currentMode}
                            />
                        </DndProvider>
                    </div>
                </div>
                <div className={`basis-[100%]  border-b border-${currentMode}-680`}>
                    <div className={`py-[12px] px-[20px] flex w-full bg-${currentMode}-card border-b border-${currentMode}-680 inline-block `}>
                        <span className={`font-interM block`}>Rows</span>
                        <ToolTip text="Drag and drop or select at least one Row data field to specify which data attributes should be included in generating the matrix reports." />
                    </div>
                    <div className="overflow-y-auto max-h-[15rem]">
                        <DndProvider backend={HTML5Backend}>
                            <RowsTarget
                                onDrop={handleDrop}
                                setAccordionItems={setAccordionItems}
                                accordionItems={accordionItems}
                                setFilteredAccordionItems={setFilteredAccordionItems}
                                filteredAccordionItems={filteredAccordionItems}
                                areaName="rows"
                                type={type}
                                currentMode={currentMode}
                            />
                        </DndProvider>
                    </div>
                </div>
            </div>
            <div className="flex">
                {addItems?.subtotal && (
                    <div className={`${addItems?.filters ? `basis-1/2` : `basis-[100%]`} border-b border-r border-${currentMode}-680`}>
                        <div className={`py-[12px] px-[20px] flex w-full bg-${currentMode}-card border-b border-${currentMode}-680 inline-block `}>
                            <span className="font-interM">Subtotal</span>
                            <ToolTip text="Dynamically calculate and display subtotal values for specific groups of data within your reports. Subtotal provides aggregated summary information." />
                        </div>
                        <div className="overflow-y-auto max-h-[15rem]">
                            <DndProvider backend={HTML5Backend}>
                                <SubTtlTarget
                                    onDrop={handleDrop}
                                    setAccordionItems={setAccordionItems}
                                    accordionItems={accordionItems}
                                    areaName="subtotal"
                                    type={type}
                                    currentMode={currentMode}
                                />
                            </DndProvider>
                        </div>
                    </div>
                )}
                {addItems?.filters && (
                    <div className={`${addItems?.subtotal ? `basis-1/2` : `basis-[100%]`} border-b border-${currentMode}-680`}>
                        <div className={`py-[12px] px-[20px] flex w-full bg-${currentMode}-card border-b border-${currentMode}-680 inline-block `}>
                            <span className="font-interM">More Filters</span>
                            <ToolTip text="Apply more filters to select and display only the relevant data rows in your tabular reports." />
                        </div>
                        <div className="overflow-y-auto max-h-[15rem]">
                            <DndProvider backend={HTML5Backend}>
                                <MoreFilterTarget
                                    onDrop={handleDrop}
                                    areaName="morefilter"
                                    currentMode={currentMode}
                                    filterCheck={[...(matrixColValues || []), ...(rowsValues || [])]}
                                    setAccordionItems={setAccordionItems}
                                    accordionItems={accordionItems}
                                    type={type}
                                    setFilteredAccordionItems={setFilteredAccordionItems}
                                    filteredAccordionItems={filteredAccordionItems}
                                />
                            </DndProvider>
                        </div>
                    </div>
                )}
            </div>
            {addItems?.sorting && (
                <div className={`basis-full border-b border-${currentMode}-680`}>
                    <div className={`py-[12px] px-[20px] flex w-full bg-${currentMode}-card border-b border-${currentMode}-680 inline-block `}>
                        <span className="font-interM">Sorting</span>
                        <ToolTip text="Organize your data rows in a logical sequence for easier interpretation and analysis by using the sorting feature." />
                    </div>
                    <div className="overflow-y-auto max-h-[15rem]">
                        <DndProvider backend={HTML5Backend}>
                            <SortingTarget onDrop={handleDrop} type={type} areaName="sorting" currentMode={currentMode} />
                        </DndProvider>
                    </div>
                </div>
            )}
            {addItems?.preview && (
                <div className="max-h-[380px] mt-7 overflow-auto">
                    {fetching ? (
                        <Loader className="w-full flex items-center justify-center" />
                    ) : Object?.keys(preview || {})?.length !== 0 ? (
                        <table className="w-full relative overflow-auto rolemasterTable rounded-[0] lastBorder onTimeTbl text-left">
                            <thead className="sticky top-0 z-[1]">
                                <tr>
                                    {Headers?.label?.map((el, i) => (
                                        <th key={i} className={`!rounded-[0] whitespace-nowrap`}></th>
                                    ))}
                                    <th
                                        className={`!rounded-[0] text-center whitespace-nowrap`}
                                        colSpan={Headers?.label?.length + Headers?.headers?.length}>
                                        {preview?.matrix?.[0]?.field_display_name}
                                    </th>
                                </tr>
                                <tr>
                                    {Headers?.label?.map((el, i) => (
                                        <th key={i} className={`!rounded-[0] whitespace-nowrap`}>
                                            {el?.name}
                                        </th>
                                    ))}
                                    {Headers?.headers?.map((head, index) => (
                                        <>
                                            <th className={`!rounded-[0] whitespace-nowrap`} key={index}>
                                                {head}
                                            </th>
                                        </>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {preview?.data?.map((item, index) => {
                                    SNo = SNo + (item?.issubtotal > 0 ? 0 : 1);
                                    return (
                                        <tr key={index} className={` ${item?.issubtotal > 0 ? 'bg-[#EEFCFA] font-interSb' : `bg-${currentMode}-card`}`}>
                                            {Headers?.label?.map((header, subIndex) => (
                                                <td
                                                    title={extractTextFromHTML(item[header?.column])}
                                                    className={`whitespace-nowrap truncate max-w-[180px] ${subIndex == 0 ? `font-interM` : ``}`}
                                                    key={subIndex}>
                                                    <div
                                                        dangerouslySetInnerHTML={{
                                                            __html: item[header?.column],
                                                        }}
                                                    />
                                                </td>
                                            ))}

                                            {Headers?.headers?.map((header, subIndex) => (
                                                <td
                                                    key={subIndex}
                                                    title={
                                                        "" + item[preview?.matrix?.[0]?.column_name] === "" + header &&
                                                        (item[nonZeroValue] !== null ? item[nonZeroValue] : "")
                                                    }>
                                                    {"" + item[preview?.matrix?.[0]?.column_name] === "" + header &&
                                                        (item[nonZeroValue] !== null ? item[nonZeroValue] : "")}
                                                </td>
                                            ))}
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    ) : (
                        <b className="text-center block">Preview data not found</b>
                    )}
                </div>
            )}
        </>
    );
};
