import { Link } from "react-router-dom";
import Button from "../../components/Button";
import Container from "../../components/UI/Container";
import { useNavigate } from "react-router-dom";
const Thankyou = () => {
    const navigate = useNavigate();

    const backToLogin = () => {
        navigate("/");
    };
    return (
        <div className="WrapCenter">
            <Container className="mt-10">
                <Link to={'//www.myiq.ai/'} target="_blank">
                    <img className="w-[121px] h-[95px]" src="/images/logo/MyiqFinal.svg" alt="logoiq" />
                </Link>
                <div className="flex justify-center items-center h-[calc(80vh_-_120px)]">
                    <div className="basis-1/2 2xl:basis-full text-center">
                        <h3 className="text-[36px] font-interSb leading-[43px] text-light-20 mb-2">
                            Thank you for choosing MyiQ.Ai
                        </h3>
                        <p className="mb-10 text-sm">
                            You have experienced MyiQ.Ai, the most trusted invoice automation platform for business. We deliver accurate invoicing and support every time.
                        </p>
                        <p className="mb-5 text-base">Your MyiQ.Ai account is ready!</p>
                        <Button
                            type="submit"
                            onClick={() => backToLogin()}
                            className="w-full max-w-[250px] text-lg shadow-[0px_12.1167px_24.2334px_rgba(0,179,153,0.05)] rounded-[8px] text-white bg-light-20 py-[14px]"
                        >
                            Get Started
                        </Button>
                    </div>
                </div>
            </Container>
        </div>
    );
};

export default Thankyou;
