import Button from "../../../components/Button";
import Layout from "../../../Hoc/Layout";
import Main from "../../../components/UI/Main";
import Footer from "../../../organisms/Footer";
import { useDispatch, useSelector } from "react-redux";
import ReactDOM from "react-dom";
import { useEffect, useRef, useState } from "react";
import { ClickAwayListener, Fade, Popper } from "@mui/material";
import { Link } from "react-router-dom";
import Loader from "../../../components/Loader";
import AddUser from "../../../organisms/UserModal/AddUser";
import EditUser from "../../../organisms/UserModal/EditUser";
import UserFilterModal from "../../../organisms/FilterModal/UserFilterModal";
import { countryList } from "../../../redux/actions/country";
import { roleid } from "../../../redux/actions/role";
import ListContent from "./Content";
import Input from "../../../components/Input";
import TogglePopup from "../../organisations/Toggle";
import { userByOrg } from "../../../redux/actions/user";
import { getCurrentUser } from "../../../redux/actions/auth";
import Fuse from "fuse.js";
import UseTour from "../../../Hooks/ProductTour";
import { useLocation } from "react-router-dom";
import { decryptId } from "../../../utils/helpers";
import { async } from "@firebase/util";
import styles from "../../../organisms/Sidebar/index";
import { useContext } from "react";
import { ThemeContext } from "../../../Hooks/Themes/Themeprovider";
import Card from "../../../components/UI/Card";

const UsersListing = () => {
    const { currentMode } = useContext(ThemeContext);
    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = useState(null);
    const [open, setOpen] = useState(false);
    const [isAdd, setIsAdd] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [placement, setPlacement] = useState();
    const [searchValue, handleSearchChange] = useState("");
    const [filteredResults, setFilteredResults] = useState([]);
    const [filteredResults2, setFilteredResults2] = useState([]);
    const [userId, getQueryStringVal] = useState("");
    const [filterPage, setfilterPage] = useState(false);
    const resetInputField = () => {
        searchItems("");
        setFilteredResults(listData);
    };
    const handleClick = (newPlacement) => (event) => {
        setAnchorEl(event.currentTarget);
        setOpen((prev) => placement !== newPlacement || !prev);
        setPlacement(newPlacement);
    };

    const params = new URLSearchParams(window.location.search);
    let userID = params.get("user");
    let searchUserId = params.get("userid");

    const reqObj = {
        role_id: 0,
        status: 2,
        order_column: "",
        search_text: userID || "",
        order_by: 1,
        pg_no: 1,
        rec_cnt: 10,
    };

    // getQueryStringVal(searchUserId);
    useEffect(() => {
        const currentUser = getCurrentUser();
        const Roleid = currentUser?.role_id;
        const Orgid = currentUser?.organisation;
        let emailID = params.get("email");

        getUserDetails(reqObj);
    }, [dispatch]);
    const getUserDetails = (reqObjList) => {
        dispatch(userByOrg(reqObjList));
    };
    const profileData = useSelector((state) => state?.userListState);
    const { userlist: listData } = profileData?.data;
    const location = useLocation();
    const locationpath = location.pathname;
    const paramss = new URLSearchParams(window.location.search);
    let pageType = paramss.get("userid");

    const filteredDataPendings = listData?.filter(
        (item) => item.user_isactive === false && item.isactivated === false
    );
    const searchItems = (searchInput) => {
        handleSearchChange(searchInput);
        if (searchInput !== "") {
            const filteredDatas = listData.filter((item) => {
                // return Object.values(item)
                // .join("")
                //.toLowerCase()
                //.includes(searchInput.toLowerCase());
                const getEmail = decryptId(item?.user_emailaddress);
                if (
                    item?.firstname
                        .toLowerCase()
                        .includes(searchInput.toLowerCase())
                ) {
                    return item;
                } else if (
                    getEmail.toLowerCase().includes(searchInput.toLowerCase())
                ) {
                    return item;
                } else if (
                    item?.lastname
                        .toLowerCase()
                        .includes(searchInput.toLowerCase())
                ) {
                    return item;
                } else if (
                    item?.mobilenumber
                        .toLowerCase()
                        .includes(searchInput.toLowerCase())
                ) {
                    return item;
                } else if (
                    item?.country?.country_name
                        .toLowerCase()
                        .includes(searchInput.toLowerCase())
                ) {
                    return item;
                } else if (
                    item?.user_name
                        .toLowerCase()
                        .includes(searchInput.toLowerCase())
                ) {
                    return item;
                }
            });
            // const fuse = new Fuse(listData, searhKey);
            // const filteredDatas = fuse.search(searchValue);

            setFilteredResults(filteredDatas);
        } else {
            setFilteredResults(listData);
        }
    };

    const onFilterItems = (value) => {
        if (searchValue == "") {
            reqObj.status = +value;
            getUserDetails(reqObj);
        }
        const filteredData = listData?.filter(
            (item) => item.user_isactive === true
        );
        const filteredSearchData = listData?.filter(
            (item) =>
                item.user_isactive === true &&
                item?.user_name.includes(searchValue)
        );
        const filteredDataInactive = listData?.filter(
            (item) => item.user_isactive === false && item.isactivated === true
        );
        const filteredSearchDataInactive = listData?.filter(
            (item) =>
                item.user_isactive === false &&
                item.isactivated === true &&
                item?.user_name.includes(searchValue)
        );
        const filteredDataPending = listData?.filter(
            (item) => item.user_isactive === false && item.isactivated === false
        );
        const filteredSearchDataPending = listData?.filter(
            (item) =>
                item.user_isactive === false &&
                item.isactivated === false &&
                item?.user_name.includes(searchValue)
        );
        setfilterPage(true);
        switch (value) {
            case 1:
                if (searchValue !== "") {
                    setFilteredResults([...filteredSearchData]);
                } else {
                    setFilteredResults(filteredData);
                }
                break;
            case 2:
                if (pageType) {
                    window.location.href = "/users";
                } else if (searchValue !== "") {
                    const filteredDatas = listData.filter((item) => {
                        // return Object.values(item)
                        // .join("")
                        //.toLowerCase()
                        //.includes(searchInput.toLowerCase());
                        const getEmail = decryptId(item?.user_emailaddress);
                        if (
                            item?.firstname
                                .toLowerCase()
                                .includes(searchValue.toLowerCase())
                        ) {
                            return item;
                        } else if (
                            getEmail
                                .toLowerCase()
                                .includes(searchValue.toLowerCase())
                        ) {
                            return item;
                        } else if (
                            item?.lastname
                                .toLowerCase()
                                .includes(searchValue.toLowerCase())
                        ) {
                            return item;
                        } else if (
                            item?.mobilenumber
                                .toLowerCase()
                                .includes(searchValue.toLowerCase())
                        ) {
                            return item;
                        } else if (
                            item?.country?.country_name
                                .toLowerCase()
                                .includes(searchValue.toLowerCase())
                        ) {
                            return item;
                        } else if (
                            item?.user_name
                                .toLowerCase()
                                .includes(searchValue.toLowerCase())
                        ) {
                            return item;
                        }
                    });
                    // const fuse = new Fuse(listData, searhKey);
                    // const filteredDatas = fuse.search(searchValue);

                    setFilteredResults(filteredDatas);
                } else setFilteredResults(listData);
                break;
            case 0:
                if (searchValue !== "") {
                    setFilteredResults([...filteredSearchDataInactive]);
                } else {
                    setFilteredResults(filteredDataInactive);
                }

                break;
            case 3:
                if (searchValue !== "") {
                    setFilteredResults([...filteredSearchDataPending]);
                } else {
                    setFilteredResults(filteredDataPending);
                }

                break;
            default:
                break;
        }
    };
    const searchObj = listData?.find((item) => {
        return item?.id === +searchUserId;
    });

    const handleClickAway = () => {
        setOpen(false);
    };
    const { country } = useSelector((state) => state?.countryState);

    const Addsubmit = () => {
        setIsAdd(true);
        if (country?.length === 0) {
            dispatch(countryList());
        }
        // dispatch(roleid());
    };

    const IsFilter = useSelector((state) => state?.cartToggleState);

    const data = useSelector((state) => state?.UsersUpdateStatusState);

    const filterMenuList = [
        { text: "All", src: "/images/common/all-ico.svg", value: 2, id: 2 },
        {
            text: "Active",
            src: "/images/common/active-ico.svg",
            value: 1,
            id: 1,
        },
        {
            text: "Deactive",
            src: "/images/common/inactive-ico.svg",
            value: 0,
            id: 0,
        },
        {
            text: "Pending",
            src: "/images/common/pending-ico.svg",
            value: 3,
            id: 3,
        },
    ];

    const childCompRef = useRef();
    const DisplayRole = useSelector(
        (state) => state?.DisplayRolePermissionStateAction
    );
    const { data: DataList } = DisplayRole;
    const RoleList = DataList?.role_info;
    const RoleMenu = RoleList?.menu;
    const RoleUser = RoleMenu?.find((elem) => elem?.menu_id === 6);
    const RoleUserAction = RoleUser?.actions;
    const RoleActUserAdd = RoleUserAction?.find((element) => element.id === 1);

    //    var  SearchGlobalValue = document.getElementById("globalSearchId")?.value
    //     if ( SearchGlobalValue){
    //    searchValue=SearchGlobalValue
    //     }

    const searhKey = {
        includeScore: true,
        keys: ["firstname", "lastname", "mobilenumber"],
    };

    const tourConfig = [
        {
            selector: ".adduser-step",
            content: "Click on this Add User button to add a user",
        },
        {
            selector: ".userfilter-step",
            content: "Click on this Filter button to view more filter options",
        },
        {
            selector: ".usersearch-step",
            content: "Click on this Search to search the users",
        },
        {
            selector: ".userMore-step",
            content: "Click on this Kebab menu to view more actions",
        },
    ];
    const { openTour, ProdTour } = UseTour(tourConfig);
    const Userslength =
        listData?.length == 1
            ? listData?.length + " user added"
            : listData?.length + " user(s) added";
    return (
        <>
            <ProdTour />
            <Layout>
                <Main>
                    <div className="xl:flex justify-between items-center mb-6">
                        <div
                            className={`flex space-x-2 mb-4 xl:mb-0 text-${currentMode}-copy`}
                        >
                            <div>
                                <h4 className="text-2xl font-interSb">
                                    User Management
                                </h4>
                                {listData &&
                                    listData?.length > 0 &&
                                    !pageType ? (
                                    <p className="text-xs">{Userslength}</p>
                                ) : (
                                    !pageType && (
                                        <p className="text-xs">
                                            No user(s) added
                                        </p>
                                    )
                                )}
                            </div>
                            {/* <div className="mt-1">
                                <img
                                    title="User Management Tour"
                                    className="cursor-pointer"
                                    onClick={openTour}
                                    src="/images/common/tour.svg"
                                    alt="tour"
                                />
                            </div> */}
                        </div>
                        <div className="flex space-x-2">
                            <div className="flex space-x-2">
                                <div
                                    className={`border-${currentMode}-560 bg-${currentMode}-360 text-${currentMode}-copy usersearch-step border-20 border-[1px] flex items-center justify-between py-[4px] px-[16px] min-w-[250px] rounded-[8px]`}
                                >
                                    <Input
                                        placeholder="Search..."
                                        name="search"
                                        className={`border-0 bg-transparent `}
                                        maxLength={250}
                                        autoComplete="off"
                                        disableUnderline={true}
                                        onChange={(e) =>
                                            searchItems(e.target.value)
                                        }
                                        value={searchValue}
                                    />
                                    {searchValue && searchValue.length !== 0 ? (
                                        <img
                                            onClick={resetInputField}
                                            src="/images/common/closeBlk.svg"
                                            alt="close"
                                        />
                                    ) : (
                                        <img src="/images/header/search.svg" />
                                    )}
                                </div>

                                <TogglePopup
                                    ref={childCompRef}
                                    menuList={filterMenuList}
                                    selectedItemChange={onFilterItems}
                                />
                                <Button
                                    onClick={(e) =>
                                        childCompRef.current.handleClick(
                                            "bottom-end",
                                            1,
                                            e
                                        )
                                    }
                                    className={`border-${currentMode}-560 bg-${currentMode}-360 userfilter-step px-3 py-2 rounded-[8px] font-interM text-sm flex items-center border-[1px] text-white`}
                                >
                                    <img
                                        title="For Advanced Filter"
                                        src="/images/common/filter-blue.svg"
                                        alt="filterIcon"
                                    />
                                </Button>
                            </div>
                            <ClickAwayListener
                                mouseEvent="onMouseDown"
                                touchEvent="onTouchStart"
                                onClickAway={handleClickAway}
                            >
                                <Popper
                                    className={`bg-white px-3 py-3 border-[1px] border-[rgba(223,225,226,0.56)] shadow-[0px_4px_20px_rgba(238,238,238,0.5)] rounded-[10px] `}
                                    open={open}
                                    sx={{ minWidth: 120 }}
                                    anchorEl={anchorEl}
                                    placement={placement}
                                    transition
                                >
                                    {({ TransitionProps }) => (
                                        <Fade
                                            {...TransitionProps}
                                            timeout={350}
                                        >
                                            <div>
                                                <ul className="space-y-3">
                                                    <li>
                                                        <Link
                                                            className="flex"
                                                            to="#!"
                                                        >
                                                            <img
                                                                className="flex-none pr-2"
                                                                src="/images/common/excel.svg"
                                                                alt="user"
                                                            />
                                                            Excel
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link
                                                            className="flex"
                                                            to="#!"
                                                        >
                                                            <img
                                                                className="flex-none pr-2"
                                                                src="/images/common/excel.svg"
                                                                alt="help"
                                                            />
                                                            CSV
                                                        </Link>
                                                    </li>

                                                    <li>
                                                        <Link
                                                            className="flex"
                                                            to="#!"
                                                        >
                                                            <img
                                                                className="flex-none pr-2"
                                                                src="/images/common/pdf.svg"
                                                                alt="log-in"
                                                            />
                                                            PDF
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link
                                                            className="flex"
                                                            to="#!"
                                                        >
                                                            <img
                                                                className="flex-none pr-2"
                                                                src="/images/common/jpg.svg"
                                                                alt="log-in"
                                                            />
                                                            JPG
                                                        </Link>
                                                    </li>
                                                </ul>
                                            </div>
                                        </Fade>
                                    )}
                                </Popper>
                            </ClickAwayListener>
                            <Button
                                title="To add a new user"
                                onClick={Addsubmit}
                                className={`${RoleActUserAdd?.isallowed == false &&
                                    "hidden"
                                    } border-${currentMode}-800 adduser-step users-page-step border-20 bg-light-20 px-5 py-2 rounded-[8px] font-interM text-sm flex items-center border-[1px] text-white`}
                            >
                                <img
                                    className="mr-1"
                                    src="/images/common/plus.svg"
                                    alt="plus"
                                />{" "}
                                Add User
                            </Button>
                        </div>
                    </div>
                    {/* <Card className="h-[calc(100vh_-_18rem)] xl:h-[calc(100vh_-_14rem)] p-[30px] overflow-y-auto"> */}
                    {!profileData?.requestComplete ? (
                        <div className="h-[calc(100vh_-_15rem)]">
                            <Loader />
                        </div>
                    ) : (
                        <ListContent
                            searchInput={searchValue}
                            filterpage={filterPage}
                            EditOpen={() => setIsEdit(true)}
                            searchObj={searchObj}
                            filteredData={filteredResults}
                            Data={listData}
                        />
                    )}
                    {/* </Card> */}

                    {ReactDOM.createPortal(
                        <AddUser
                            isOpened={isAdd}
                            handleClose={() => setIsAdd(false)}
                            className={isAdd && "Show"}
                        />,
                        document.getElementById("user-modal-root")
                    )}
                    {ReactDOM.createPortal(
                        <EditUser
                            isOpened={isEdit}
                            handleClose={() => setIsEdit(false)}
                            className={isEdit && "Show"}
                        />,
                        document.getElementById("user-modal-root")
                    )}
                    {ReactDOM.createPortal(
                        <UserFilterModal
                            isOpened={IsFilter}
                            className={IsFilter && "Show"}
                        />,
                        document.getElementById("Modal-root")
                    )}
                </Main>
                <Footer />
            </Layout>
        </>
    );
};

export default UsersListing;
