import { useState } from "react";
import { PieChart, Pie, Legend, Tooltip, ResponsiveContainer, Cell, Sector } from "recharts";
import { extractTextFromHTML } from "../../../../utils/helpers";

const PiechartPreview = ({ preview, bgColor, currentMode }) => {
    const dataKeys = preview?.data && Object.keys(preview?.data?.[0]);
    const hasNoData = preview?.data?.every((item) => item[dataKeys?.[0]] === null || item[dataKeys?.[0]] === 0);
    const renderActiveShape = (props) => {
        const RADIAN = Math.PI / 180;
        const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value } = props;
        const sin = Math.sin(-RADIAN * midAngle);
        const cos = Math.cos(-RADIAN * midAngle);
        const sx = cx + (outerRadius + 10) * cos;
        const sy = cy + (outerRadius + 10) * sin;
        const mx = cx + (outerRadius + 30) * cos;
        const my = cy + (outerRadius + 30) * sin;
        const ex = mx + (cos >= 0 ? 1 : -1) * 22;
        const ey = my;
        const textAnchor = cos >= 0 ? "start" : "end";

        return (
            <g>
                {/* <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
                    {payload?.[dataKeys?.[0]]}
                </text> */}
                <Sector cx={cx} cy={cy} innerRadius={innerRadius} outerRadius={outerRadius} startAngle={startAngle} endAngle={endAngle} fill={fill} />
                <Sector
                    cx={cx}
                    cy={cy}
                    startAngle={startAngle}
                    endAngle={endAngle}
                    innerRadius={outerRadius + 6}
                    outerRadius={outerRadius + 10}
                    fill={fill}
                />
                <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
                <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
                <text
                    x={ex + (cos >= 0 ? 1 : -1) * 12}
                    y={ey}
                    textAnchor={textAnchor}
                    fill={currentMode == "dark" ? "#fff" : "#333"}>{`${value}`}</text>
                {/* <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999">
                    {`(Rate ${(percent * 100).toFixed(2)}%)`}
                </text> */}
            </g>
        );
    };

    const [activeIndex, setActiveIndex] = useState(0);

    const onPieEnter = (_, index) => {
        setActiveIndex(index);
    };
    const CustomTooltip = ({ active, payload }) => {
        if (active && payload && payload?.length) {
            const entry = payload[0]?.payload;

            return (
                <div className={` py-3 px-5 border recharts-default-tooltip rounded-[8px]`}>
                    <p className="label">{`${extractTextFromHTML(entry?.[dataKeys?.[1]])} : ${entry?.[dataKeys?.[0]]}`}</p>
                </div>
            );
        }
    };

    return !hasNoData ? (
        <ResponsiveContainer width="100%" height={400}>
            <PieChart>
                <Pie
                    dataKey={dataKeys?.[0]}
                    nameKey={dataKeys?.[1]}
                    data={preview?.data}
                    cx="50%"
                    activeIndex={activeIndex}
                    activeShape={renderActiveShape}
                    cy="50%"
                    labelLine={false}
                    onMouseEnter={onPieEnter}>
                    {preview?.data?.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={bgColor[index % bgColor?.length]} />
                    ))}
                </Pie>
                <Tooltip content={<CustomTooltip />} />
            </PieChart>
        </ResponsiveContainer>
    ) : (
        <b className="text-center block">Preview data not found</b>
    );
};

export default PiechartPreview;
