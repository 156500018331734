import { ClickAwayListener, Box } from "@mui/material";
import { Popover, Popper } from "@mui/material";
import Button from "../../components/Button";
import { Link } from "react-router-dom";
const StatusPopper = (props) => {
    const { id, anchorEl, placement, open, handleClickAway, currentMode, buttons, openEdit, type, deleteRow, itemData } = props;
    const transformOrigin = type === 2 ? { vertical: "top", horizontal: "right" } : undefined;
    return (
        <>
            <Popover
                className={` ${
                    type == 1 ? "min-h-[121px] min-w-[200px] mt-[10px]" : "min-h-[115px] min-w-[120px]"
                } border-[1px] workflowPopOver rounded-[10px]`}
                open={open}
                anchorEl={anchorEl}
                id={id}
                placement={placement}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: type == 2 ? "right" : "left",
                }}
                transformOrigin={transformOrigin}
                onClose={handleClickAway}
                transition
            >
                <div className="flex flex-col ">
                    {type == 1 && (
                        <div className="flex justify-between px-[5px] border-b-[1px] py-[8px] items-center font-interM">
                            <span title="Add new" className="px-[5px]">
                                + Add New
                            </span>
                            <img src="/images/invoice/cls-sm.svg" className="px-[5px] cursor-pointer" onClick={handleClickAway} />
                        </div>
                    )}
                    <div className="flex flex-col mt-[5px] ">
                        {buttons?.map((item) => {
                            return (
                                <Link to={item?.to}>
                                    <li
                                        className={`flex cursor-pointer ${
                                            type == 1 ? "py-[8px] px-[8px]" : "py-[5px] px-[5px]"
                                        }  hover:bg-${currentMode}-960 hover:rounded-b-[10px] `}
                                        onClick={item?.to !== "/workflow-automation" ? () => openEdit(itemData, id, item?.id) : () => deleteRow(id)()}
                                        tabIndex="1"
                                    >
                                        <img src={item?.src} className="px-[2px] convertWhite" alt="setting" />
                                        <span className="px-[5px]">{item?.name}</span>
                                    </li>
                                </Link>
                            );
                        })}
                    </div>
                </div>
            </Popover>
        </>
    );
};
export default StatusPopper;
