import React, { createContext, useState, useEffect } from "react";

export const ThemeContext = createContext();

export const ThemeProvider = ({ children }) => {
    const [currentMode, setCurrentMode] = useState("light");

    useEffect(() => {
        const storedMode = localStorage.getItem("themeMode");
        if (storedMode) {
            setCurrentMode(storedMode);
        }
    }, []);

    const toggleMode = (mode) => {
        setCurrentMode(mode);
        localStorage.setItem("themeMode", mode);
    };

    return (
        <ThemeContext.Provider value={{ currentMode, toggleMode }}>
            {children}
        </ThemeContext.Provider>
    );
};
