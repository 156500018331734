import axios from "axios";
import configMyiQ from "../../configuration";
import authHeader from "./auth-header";
import { checkApiStatus } from "../../utils/helpers";
import {
    ACTIVATE_DEACTIVATE_FOLDER_CONFIG_FAILURE,
    ACTIVATE_DEACTIVATE_FOLDER_CONFIG_REQUEST,
    ACTIVATE_DEACTIVATE_FOLDER_CONFIG_SUCCESS,
    ADD_FOLDER_CONFIG_FAILURE,
    ADD_FOLDER_CONFIG_REQUEST,
    ADD_FOLDER_CONFIG_SUCCESS,
    GET_FOLDER_CONFIG_FAILURE,
    GET_FOLDER_CONFIG_REQUEST,
    GET_FOLDER_CONFIG_SUCCESS,
    UPDATE_FOLDER_CONFIG_FAILURE,
    UPDATE_FOLDER_CONFIG_REQUEST,
    UPDATE_FOLDER_CONFIG_SUCCESS,
} from "../types/types";

const API_URL = process.env?.REACT_APP_API_URL;
//folderlist
export const getFolderConfig = (organisation) => async (dispatch) => {
    try {
        dispatch({ type: GET_FOLDER_CONFIG_REQUEST });

        axios
            .get(
                `${API_URL}Folderconfig/GetFolderConfigByOrg?organisation=${organisation?.id}`,
                {
                    headers: authHeader(),
                }
            )
            .then((response) => {
                dispatch({
                    type: GET_FOLDER_CONFIG_SUCCESS,
                    payload: response?.data?.datalist,
                });
            });
    } catch (error) {
        if (error?.response?.status == 401) {
            checkApiStatus();
        }
        dispatch({
            type: GET_FOLDER_CONFIG_FAILURE,
        });
    }
};
//add folder
export const folderconfigAdd = (data) => async (dispatch) => {
    try {
        dispatch({ type: ADD_FOLDER_CONFIG_REQUEST });

        axios
            .post(`${API_URL}Folderconfig/AddUpdateFolderConfig`, data, {
                headers: authHeader(),
            })
            .then((response) => {
                dispatch({
                    type: ADD_FOLDER_CONFIG_SUCCESS,
                    payload: response.data,
                });
            });
    } catch (error) {
        if (error?.response?.status == 401) {
            checkApiStatus();
        }
        dispatch({
            type: ADD_FOLDER_CONFIG_FAILURE,
        });
    }
};
//active-inactive folder config
export const folderConfigStatusMode = (data) => async (dispatch) => {
    try {
        dispatch({ type: ACTIVATE_DEACTIVATE_FOLDER_CONFIG_REQUEST });

        axios
            .get(
                `${API_URL}Folderconfig/ActiveDeactivateFolderconfig?id=${data?.id}&isactive=${data?.isactive}`,
                {
                    headers: authHeader(),
                }
            )
            .then((response) => {
                dispatch({
                    type: ACTIVATE_DEACTIVATE_FOLDER_CONFIG_SUCCESS,
                    payload: response.data,
                });
            });
    } catch (error) {
        if (error?.response?.status == 401) {
            checkApiStatus();
        }
        dispatch({
            type: ACTIVATE_DEACTIVATE_FOLDER_CONFIG_FAILURE,
        });
    }
};

//update folder
export const folderconfigUpdate = (data) => async (dispatch) => {
    try {
        dispatch({ type: UPDATE_FOLDER_CONFIG_REQUEST });

        axios
            .post(`${API_URL}Folderconfig/UpdateFolderconfig`, data, {
                headers: authHeader(),
            })
            .then((response) => {
                dispatch({
                    type: UPDATE_FOLDER_CONFIG_SUCCESS,
                    payload: response.data,
                });
            });
    } catch (error) {
        if (error?.response?.status == 401) {
            checkApiStatus();
        }
        dispatch({
            type: UPDATE_FOLDER_CONFIG_FAILURE,
        });
    }
};
