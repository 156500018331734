import { Autocomplete, Checkbox, Chip, Paper, TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Modal from "../../../../components/UI/Modal/Modal";
import { decryptId } from "../../../../utils/helpers";
import { useDispatch, useSelector } from "react-redux";
import { useContext, useEffect, useState, useRef } from "react";
import { ThemeContext } from "../../../../Hooks/Themes/Themeprovider";
import Button from "../../../../components/Button";
import { Popover, Popper } from "@mui/material";

const AddUsersModal = (props) => {
    const { currentMode, toggleMode } = useContext(ThemeContext);
    const { anchorEl, open, placement, id } = props;
    const listData = props?.listData;
    const defaultId = props?.defaultId;
    const userListdata = listData;
    const handleSelectUser = props?.handleSelectUser;
    const GetFilter = userListdata?.filter((o) => defaultId?.includes(+o.user_id));
    const currentUser = useRef();
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [isOpen, setIsOpen] = useState(open);
    const [selectstate, setselect] = useState(true);

    const cartClose = () => {
        props?.handleClose(id, currentUser?.current);
        setIsOpen(false);
    };
    const submitClose = () => {
        props?.submitClose();
        setIsOpen(false);
    };
    const dropdownColor = currentMode === "dark" ? "#333333" : `white`;
    const dropdownText = currentMode === "dark" ? "#ECECEC" : `#555555`;
    const dropdownShadow = currentMode !== "dark" ? "0px 4px 20px rgba(238, 238, 238, 0.5)" : "";
    const dropdownBorder = currentMode !== "dark" ? "223, 225, 226, 0.56" : "";
    const dynamicHeight =
        // 200 + userListdata?.length * 50 <= 430 ? 200 + userListdata?.length * 50 + "px" :
        "456px";

    useEffect(() => {
        const updatedSelectedOptions = userListdata?.filter((o) => defaultId?.includes(+o.user_id));
        setSelectedOptions(updatedSelectedOptions);
    }, [defaultId]);

    useEffect(() => {
        if (anchorEl) {
            currentUser.current = GetFilter?.map((item) => +item?.user_id);
        }
    }, [anchorEl]);

    return (
        <>
            <Popover
                // className={`bg-white px-3 py-3 border-[1px] border-[rgba(223,225,226,0.56)] rounded-[10px] `}
                className={` border-[1px] workflowPopOver rounded-[10px]`}
                open={open}
                anchorEl={anchorEl}
                id={id}
                placement={placement}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                // onClose={handleClickAway}
                transition
            >
                <div
                    className={`max-w-[350px] overflow-y-hidden max-h-[456px]
                    ${isOpen ? `min-h-[456px]` : ``}
                     flex justify-between flex-col mx-auto relative bg-${currentMode}-card text-${currentMode}-copy border-${currentMode}-700 border-t-[1px] rounded-[10px] ${
                        currentMode === "dark" ? "" : "shadow-[0px_4px_4px_0px_rgba(0,0,0,0.10),0px_4px_4px_0px_rgba(0,0,0,0.06)]"
                    }`}
                >
                    <div>
                        <div className={`flex justify-between items-center px-[20px] py-[10px] border-b-[1px] border-${currentMode}-590`}>
                            <h4 className="font-interSb font-lg">Add Users</h4>
                            <span onClick={cartClose} className="cursor-pointer" tabIndex={0}>
                                <img src="/images/common/closeBlk.svg" alt="closeBlk" className="convertWhite" />
                            </span>
                        </div>
                        <div className="flex justify-center filter items-center py-5 px-5">
                            <Autocomplete
                                open={isOpen}
                                // autoComplete="on"
                                onOpen={() => setIsOpen(true)}
                                // onClose={() => setIsOpen(false)}
                                sx={{
                                    "& .MuiChip-label": {
                                        color: `${currentMode == "dark" ? "white" : "black"}`,
                                    },
                                }}
                                disablePortal
                                className="noBorderAutocomplete"
                                PaperComponent={({ children }) => (
                                    <Paper>
                                        <div className=" max-h-[280px] overflow-y-auto">{children}</div>
                                    </Paper>
                                )}
                                popupIcon={<img className="m-0 opacity-50 pr-1 pointer-events-none" src="/images/header/chevronDown.svg" />}
                                multiple
                                value={GetFilter?.length !== 0 ? GetFilter : []}
                                id="checkboxes-tags-demo"
                                options={userListdata == undefined ? [] : userListdata}
                                disableCloseOnSelect
                                limitTags={2}
                                getOptionLabel={(option) => option?.username}
                                onChange={handleSelectUser}
                                renderTags={(values, getTagProps) => (
                                    <div>
                                        {values?.map((value, index) => (
                                            <Chip
                                                key={index}
                                                label={
                                                    <div style={{ display: "flex", alignItems: "center", width: "100px" }}>
                                                        {/* <img
                                                            className="w-[15px] h-[15px] mr-2 object-cover rounded-full"
                                                            alt="Natacha"
                                                            src={`/images/common/profileImg.jpg`}
                                                        /> */}
                                                        <span title={value?.username} className="block truncate">
                                                            {value?.username}
                                                        </span>
                                                    </div>
                                                }
                                                {...getTagProps({ index })}
                                            />
                                        ))}
                                    </div>
                                )}
                                renderOption={(props, option, { selected }) => {
                                    return (
                                        <li {...props}>
                                            <Checkbox
                                                style={{ marginRight: 8 }}
                                                sx={{
                                                    color: "rgba(85,85,85,0.7)",
                                                    borderRadius: "3px !important",
                                                    "&.Mui-checked": {
                                                        color: "#028C78",
                                                    },
                                                }}
                                                //   onChange={handleCheckboxChange(option)}
                                                checked={selected}
                                            />
                                            {/* <img
                                                className="w-[15px] h-[15px]  object-cover rounded-full"
                                                alt="Natacha"
                                                src={`/images/common/profileImg.jpg`}
                                            /> */}
                                            <span className={`${currentMode !== "dark" ? `text-black` : 'text-white'} max-w-[160px] truncate`} title={option?.username}>
                                                {option?.username}
                                            </span>
                                        </li>
                                    );
                                }}
                                style={{ width: 500 }}
                                renderInput={(params) => (
                                    <TextField
                                        sx={{
                                            "& .MuiInputBase-input": {
                                                color: `${currentMode !== "dark" ? `text-white` : `#ECECEC`}`,
                                            },
                                        }}
                                        {...params}
                                        placeholder={GetFilter?.length == 0 ? "Select Users" : ""}
                                        autoComplete="off"
                                        // autoFocus={true}
                                    />
                                )}
                            />
                        </div>
                    </div>
                    <div className=" flex text-right px-5 pb-3">
                        <Button
                            tabIndex={0}
                            className="bg-light-20 text-white text-base rounded-[8px] px-7 py-[8px] w-[103px] h-[40px]"
                            onClick={() => submitClose()}
                            title={"Ok"}
                            disabled={false}
                        >
                            {"OK"}
                        </Button>
                        <Button
                            tabIndex={0}
                            // to="/admin-setting"
                            title="Cancel"
                            className=" bg-light-240 text-light-40 text-base rounded-[8px] ml-[10px] py-[8px] px-[25px] w-[103px] h-[40px]"
                            onClick={() => cartClose()}
                        >
                            {"Cancel"}
                        </Button>
                    </div>
                </div>
            </Popover>
        </>
    );
};

export default AddUsersModal;
