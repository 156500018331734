import {
    WORKFLOW_STATUS_DELETE_REQUEST,
    WORKFLOW_STATUS_DELETE_SUCCESS,
    WORKFLOW_STATUS_DELETE_RESET,
    WORKFLOW_STATUS_DELETE_FAILURE,
    GET_WORKFLOW_DATA_REQUEST,
    GET_WORKFLOW_DATA_SUCCESS,
    GET_WORKFLOW_DATA_RESET,
    GET_WORKFLOW_DATA_FAILURE,
    GET_WORKFLOW_PERMISSIONLAYOUT_REQUEST,
    GET_WORKFLOW_PERMISSIONLAYOUT_SUCCESS,
    GET_WORKFLOW_PERMISSIONLAYOUT_RESET,
    GET_WORKFLOW_PERMISSIONLAYOUT_FAILURE,
    POST_WORKFLOW_AUTOMATION_BYORG_REQUEST,
    POST_WORKFLOW_AUTOMATION_BYORG_SUCCESS,
    POST_WORKFLOW_AUTOMATION_BYORG_RESET,
    POST_WORKFLOW_AUTOMATION_BYORG_FAILURE,
    GET_WORKFLOW_DATA_BY_INVOICE_ID_REQUEST,
    GET_WORKFLOW_DATA_BY_INVOICE_ID_SUCCESS,
    GET_WORKFLOW_DATA_BY_INVOICE_ID_RESET,
    GET_WORKFLOW_DATA_BY_INVOICE_ID_FAILURE,
    GET_WORKFLOW_FILTER_REQUEST,
    GET_WORKFLOW_FILTER_SUCCESS,
    GET_WORKFLOW_FILTER_RESET,
    GET_WORKFLOW_FILTER_FAILURE,
    POST_CHANGE_WORKFLOW_STATUS_REQUEST,
    POST_CHANGE_WORKFLOW_STATUS_RESET,
    POST_CHANGE_WORKFLOW_STATUS_SUCCESS,
    POST_CHANGE_WORKFLOW_STATUS_FAILURE,
} from "../types/types";
import { decryptId } from "../../utils/helpers";
//delete status on workflow automation
const workFlowStatusDeleteState = {
    data: {},
    fetching: false,
    requestComplete: false,
    error: "",
};

export function workFlowStatusDelete(state = workFlowStatusDeleteState, action) {
    switch (action.type) {
        case WORKFLOW_STATUS_DELETE_REQUEST:
            return {
                ...state,
                fetching: true,
                requestComplete: false,
                error: "",
            };
        case WORKFLOW_STATUS_DELETE_SUCCESS:
            return {
                data: action.payload,
                fetching: false,
                requestComplete: true,
                error: "",
            };
        case WORKFLOW_STATUS_DELETE_RESET:
            return {
                data: {},
                fetching: false,
                requestComplete: false,
                error: "",
            };
        case WORKFLOW_STATUS_DELETE_FAILURE:
            return {
                fetching: false,
                requestComplete: false,
                error: action.payload,
            };
        default:
            return state;
    }
}

//get workflow automation data by org
const getWorkFlowDataState = {
    data: {},
    fetching: false,
    requestComplete: false,
    error: "",
};
export function getWorkFlowData(state = getWorkFlowDataState, action) {
    if (action?.type == "GET_WORKFLOW_DATA_SUCCESS") {
        action?.payload?.work_flow?.forEach((mainObj, index) => {
            if (mainObj?.notification?.email_bcc && mainObj?.notification?.email_cc) {
                mainObj.notification.email_bcc = mainObj.notification.email_bcc?.map((i) => decryptId(i));
                mainObj.notification.email_cc = mainObj.notification.email_cc?.map((i) => decryptId(i));
            }
        });
    }
    switch (action.type) {
        case GET_WORKFLOW_DATA_REQUEST:
            return {
                ...state,
                fetching: true,
                requestComplete: false,
                error: "",
            };
        case GET_WORKFLOW_DATA_SUCCESS:
            return {
                data: action.payload,
                fetching: false,
                requestComplete: true,
                error: "",
            };
        case GET_WORKFLOW_DATA_RESET:
            return {
                data: {},
                fetching: false,
                requestComplete: false,
                error: "",
            };
        case GET_WORKFLOW_DATA_FAILURE:
            return {
                fetching: false,
                requestComplete: false,
                error: action.payload,
            };
        default:
            return state;
    }
}
//get workflow permission layout by org

const getWorkFlowPermissonState = {
    data: [],
    fetching: false,
    requestComplete: false,
    error: "",
};
export function getWorkFlowPermissionByOrg(state = getWorkFlowPermissonState, action) {
    switch (action.type) {
        case GET_WORKFLOW_PERMISSIONLAYOUT_REQUEST:
            return {
                ...state,
                fetching: true,
                requestComplete: false,
                error: "",
            };
        case GET_WORKFLOW_PERMISSIONLAYOUT_SUCCESS:
            return {
                data: action.payload,
                fetching: false,
                requestComplete: true,
                error: "",
            };
        case GET_WORKFLOW_PERMISSIONLAYOUT_RESET:
            return {
                data: [],
                fetching: false,
                requestComplete: false,
                error: "",
            };
        case GET_WORKFLOW_PERMISSIONLAYOUT_FAILURE:
            return {
                fetching: false,
                requestComplete: false,
                error: action.payload,
            };
        default:
            return state;
    }
}

//ADDUPDATE WORKFLOW AUTOMATION BY ORG
const addUpdateWorkFlowByOrg = {
    data: {},
    fetching: false,
    requestComplete: false,
    error: "",
};
export function postWorkFlowByOrg(state = addUpdateWorkFlowByOrg, action) {
    switch (action.type) {
        case POST_WORKFLOW_AUTOMATION_BYORG_REQUEST:
            return {
                ...state,
                fetching: true,
                requestComplete: false,
                error: "",
            };
        case POST_WORKFLOW_AUTOMATION_BYORG_SUCCESS:
            return {
                data: action.payload,
                fetching: false,
                requestComplete: true,
                error: "",
            };
        case POST_WORKFLOW_AUTOMATION_BYORG_RESET:
            return {
                data: {},
                fetching: false,
                requestComplete: false,
                error: "",
            };
        case POST_WORKFLOW_AUTOMATION_BYORG_FAILURE:
            return {
                profile: action.payload,
                fetching: false,
                requestComplete: true,
                error: action.payload,
            };
        default:
            return state;
    }
}

//* Reducer for get workflow status of a invoice id
const getWorkflowById = {
    data: {},
    fetching: false,
    requestComplete: false,
    error: "",
};
export function getWorkflowByInvoiceId(state = getWorkflowById, action) {
    switch (action.type) {
        case GET_WORKFLOW_DATA_BY_INVOICE_ID_REQUEST:
            return {
                ...state,
                fetching: true,
                requestComplete: false,
                error: "",
            };
        case GET_WORKFLOW_DATA_BY_INVOICE_ID_SUCCESS:
            return {
                data: action.payload,
                fetching: false,
                requestComplete: true,
                error: "",
            };
        case GET_WORKFLOW_DATA_BY_INVOICE_ID_RESET:
            return {
                data: {},
                fetching: false,
                requestComplete: false,
                error: "",
            };
        case GET_WORKFLOW_DATA_BY_INVOICE_ID_FAILURE:
            return {
                profile: action.payload,
                fetching: false,
                requestComplete: true,
                error: action.payload,
            };
        default:
            return state;
    }
}

//* Reducer for get workflow status of a invoice id
const getWorkflowFilterState = {
    data: {},
    fetching: false,
    requestComplete: false,
    error: "",
};
export function getWorkflowFilter(state = getWorkflowFilterState, action) {
    if (action.type == "GET_WORKFLOW_FILTER_SUCCESS") {
        action.payload.datalist = action.payload.datalist
            .map((item) => {
                const { status_name, ...rest } = item;
                return {
                    name: status_name,
                    ...rest,
                };
            })
            ?.sort((a, b) => a?.display_order - b?.display_order);
    }
    switch (action.type) {
        case GET_WORKFLOW_FILTER_REQUEST:
            return {
                ...state,
                fetching: true,
                requestComplete: false,
                error: "",
            };
        case GET_WORKFLOW_FILTER_SUCCESS:
            return {
                data: { ...action.payload, orgId: action.orgId },
                fetching: false,
                requestComplete: true,
                error: "",
            };
        case GET_WORKFLOW_FILTER_RESET:
            return {
                data: {},
                fetching: false,
                requestComplete: false,
                error: "",
            };
        case GET_WORKFLOW_FILTER_FAILURE:
            return {
                data: {},
                fetching: false,
                requestComplete: true,
                error: action.payload,
            };
        default:
            return state;
    }
}

//* Reducer for the status change api:
const postWorkFlowStatusChangeState = {
    data: {},
    fetching: false,
    requestComplete: false,
    error: "",
};
export function postWorkFlowStatusChange(state = postWorkFlowStatusChangeState, action) {
    switch (action.type) {
        case POST_CHANGE_WORKFLOW_STATUS_REQUEST:
            return {
                ...state,
                fetching: true,
                requestComplete: false,
                error: "",
            };
        case POST_CHANGE_WORKFLOW_STATUS_SUCCESS:
            return {
                data: action.payload,
                fetching: false,
                requestComplete: true,
                error: "",
            };
        case POST_CHANGE_WORKFLOW_STATUS_RESET:
            return {
                data: {},
                fetching: false,
                requestComplete: false,
                error: "",
            };
        case POST_CHANGE_WORKFLOW_STATUS_FAILURE:
            return {
                data: {},
                fetching: false,
                requestComplete: true,
                error: action.payload,
            };
        default:
            return state;
    }
}
