import {
	LOGIN_FAILURE,
	LOGIN_REQUEST,
	LOGIN_SUCCESS,
	LOGIN_RESET,
	SIGNUP_FAILURE,
	SIGNUP_REQUEST,
	SIGNUP_SUCCESS,
	SIGNUP_RESET,
	CHANGE_PASSWORD_REQUEST,
	CHANGE_PASSWORD_SUCCESS,
	CHANGE_PASSWORD_RESET,
	CHANGE_PASSWORD_FAILURE,
	SET_PASSWORD_RESET,
	SET_PASSWORD_REQUEST,
	SET_PASSWORD_SUCCESS,
	SET_PASSWORD_FAILURE,
	RESET_PASSWORD_FAILURE,
	RESET_PASSWORD_RESET,
	RESET_PASSWORD_SUCCESS,
	RESET_PASSWORD_REQUEST,
	LOGOUT_REQUEST,
	LOGOUT_SUCCESS,
	LOGOUT_RESET,
	LOGOUT_FAILURE,
	FORGOT_PASS_FAILURE,
	FORGOT_PASS_REQUEST,
	FORGOT_PASS_SUCCESS,
	FORGOT_PASS_RESET,
	EXPIRY_LINK_FAILURE,
	EXPIRY_LINK_REQUEST,
	EXPIRY_LINK_SUCCESS,
	EXPIRY_LINK_RESET,
	RESEND_EMAIL_LINK_REQUEST,
	RESEND_EMAIL_LINK_SUCCESS,
	RESEND_EMAIL_LINK_RESET,
	RESEND_EMAIL_LINK_FAILURE,
	ACTIVITY_HISTORY_REQUEST,
	ACTIVITY_HISTORY_SUCCESS,
	ACTIVITY_HISTORY_FAILURE,
	TRANSFER_OWNER_FAILURE,
	TRANSFER_OWNER_REQUEST,
	TRANSFER_OWNER_SUCCESS,
	TRANSFER_OWNER_RESET,
	TOKEN_AUTHORIZATION_REQUEST,
	TOKEN_AUTHORIZATION_SUCCESS,
	TOKEN_AUTHORIZATION_FAILURE,
	TOKEN_AUTHORIZATION_RESET,
	GET_TEMP_MAIL_VALIDATE_REQUEST,
	GET_TEMP_MAIL_VALIDATE_SUCCESS,
	GET_TEMP_MAIL_VALIDATE_RESET,
	GET_TEMP_MAIL_VALIDATE_FAILURE,
	ACCOUNT_DELETE_FAILURE,
	ACCOUNT_DELETE_REQUEST,
	ACCOUNT_DELETE_SUCCESS,
	ACCOUNT_DELETE_RESET,
} from "../types/types";

const intAuthState = {
	data: {},
	fetching: false,
	requestComplete: false,
	error: "",
};

export function registration(state = intAuthState, action) {
	switch (action.type) {
		case SIGNUP_REQUEST:
			return {
				...state,
				fetching: true,
				requestComplete: false,
				error: "",
			};
		case SIGNUP_SUCCESS:
			return {
				data: action.payload,
				fetching: false,
				requestComplete: true,
				error: "",
			};
		case SIGNUP_RESET:
			return {
				data: {},
				fetching: false,
				requestComplete: false,
				error: "",
			};
		case SIGNUP_FAILURE:
			return {
				fetching: false,
				requestComplete: false,
				error: action.payload,
			};
		default:
			return state;
	}
}
const LoginAuthState = {
	data: {},
	fetching: false,
	requestComplete: false,
	error: "",
};

export function login(state = LoginAuthState, action) {
	switch (action.type) {
		case LOGIN_REQUEST:
			return {
				...state,
				fetching: true,
				requestComplete: false,
				error: "",
			};
		case LOGIN_SUCCESS:
			return {
				data: action.payload,
				fetching: false,
				requestComplete: true,
				error: "",
			};
		case LOGIN_RESET:
			return {
				data: {},
				fetching: false,
				requestComplete: false,
				error: "",
			};
		case LOGIN_FAILURE:
			return {
				data: {},
				fetching: false,
				requestComplete: false,
				error: action?.payload?.response?.data,
			};
		default:
			return state;
	}
}
const SetPasswordAuthState = {
	data: {},
	fetching: false,
	requestComplete: false,
	error: "",
};

export function setPassword(state = SetPasswordAuthState, action) {
	switch (action.type) {
		case SET_PASSWORD_REQUEST:
			return {
				...state,
				fetching: true,
				requestComplete: false,
				error: "",
			};
		case SET_PASSWORD_SUCCESS:
			return {
				data: action.payload,
				fetching: false,
				requestComplete: true,
				error: "",
			};
		case SET_PASSWORD_RESET:
			return {
				data: {},
				fetching: false,
				requestComplete: false,
				error: "",
			};
		case SET_PASSWORD_FAILURE:
			return {
				fetching: false,
				requestComplete: false,
				error: action.payload,
			};
		default:
			return state;
	}
}
const ResetPasswordAuthState = {
	data: {},
	fetching: false,
	requestComplete: false,
	error: "",
};
export function resetPassword(state = ResetPasswordAuthState, action) {
	switch (action.type) {
		case RESET_PASSWORD_REQUEST:
			return {
				...state,
				fetching: true,
				requestComplete: false,
				error: "",
			};
		case RESET_PASSWORD_SUCCESS:
			return {
				data: action.payload,
				fetching: false,
				requestComplete: true,
				error: "",
			};
		case RESET_PASSWORD_RESET:
			return {
				data: {},
				fetching: false,
				requestComplete: false,
				error: "",
			};
		case RESET_PASSWORD_FAILURE:
			return {
				fetching: false,
				requestComplete: false,
				error: action.payload,
			};
		default:
			return state;
	}
}
const ChangePasswordAuthState = {
	data: {},
	fetching: false,
	requestComplete: false,
	error: "",
};
export function changepassword(state = ChangePasswordAuthState, action) {
	switch (action.type) {
		case CHANGE_PASSWORD_REQUEST:
			return {
				...state,
				fetching: true,
				requestComplete: false,
				error: "",
			};
		case CHANGE_PASSWORD_SUCCESS:
			return {
				data: action.payload,
				fetching: false,
				requestComplete: true,
				error: "",
			};
		case CHANGE_PASSWORD_RESET:
			return {
				data: {},
				fetching: false,
				requestComplete: false,
				error: "",
			};
		case CHANGE_PASSWORD_FAILURE:
			return {
				fetching: false,
				requestComplete: false,
				error: action.payload,
			};
		default:
			return state;
	}
}

const LogoutAuthState = {
	data: {},
	fetching: false,
	requestComplete: false,
	error: "",
};

export function logout(state = LogoutAuthState, action) {
	switch (action.type) {
		case LOGOUT_REQUEST:
			return {
				...state,
				fetching: true,
				requestComplete: false,
				error: "",
			};
		case LOGOUT_SUCCESS:
			return {
				data: action.payload,
				fetching: false,
				requestComplete: true,
				error: "",
			};
		case LOGOUT_RESET:
			return {
				data: {},
				fetching: false,
				requestComplete: false,
				error: "",
			};
		case LOGOUT_FAILURE:
			return {
				data: {},
				fetching: false,
				requestComplete: false,
				error: action?.payload?.response?.data,
			};
		default:
			return state;
	}
}

const ForgotpassAuthState = {
	data: {},
	fetching: false,
	requestComplete: false,
	error: "",
};

export function Forgoutpasswordreducer(state = ForgotpassAuthState, action) {
	switch (action.type) {
		case FORGOT_PASS_REQUEST:
			return {
				...state,
				fetching: true,
				requestComplete: false,
				error: "",
			};
		case FORGOT_PASS_SUCCESS:
			return {
				data: action.payload,
				fetching: false,
				requestComplete: true,
				error: "",
			};
		case FORGOT_PASS_RESET:
			return {
				data: {},
				fetching: false,
				requestComplete: false,
				error: "",
			};
		case FORGOT_PASS_FAILURE:
			return {
				data: {},
				fetching: false,
				requestComplete: false,
				error: action?.payload,
			};
		default:
			return state;
	}
}

const expirylinkState = {
	expData: {},
	fetching: false,
	requestCompleted: false,
	error: "",
};

export function ExpiryLinkState(state = expirylinkState, action) {
	switch (action.type) {
		case EXPIRY_LINK_REQUEST:
			return {
				...state,
				fetching: true,
				requestCompleted: false,
				error: "",
			};
		case EXPIRY_LINK_SUCCESS:
			return {
				expData: action.payload,
				fetching: false,
				requestCompleted: true,
				error: "",
			};
		case EXPIRY_LINK_RESET:
			return {
				expData: {},
				fetching: false,
				requestCompleted: false,
				error: "",
			};
		case EXPIRY_LINK_FAILURE:
			return {
				expData: {},
				fetching: false,
				requestCompleted: false,
				error: action?.payload?.response?.data,
			};
		default:
			return state;
	}
}

const ResendEmailAuthState = {
	data: {},
	fetching: false,
	requestComplete: false,
	error: "",
};

export function ResendEmailLink(state = ResendEmailAuthState, action) {
	switch (action.type) {
		case RESEND_EMAIL_LINK_REQUEST:
			return {
				...state,
				fetching: true,
				requestComplete: false,
				error: "",
			};
		case RESEND_EMAIL_LINK_SUCCESS:
			return {
				data: action.payload,
				fetching: false,
				requestComplete: true,
				error: "",
			};
		case RESEND_EMAIL_LINK_RESET:
			return {
				data: {},
				fetching: false,
				requestComplete: false,
				error: "",
			};
		case RESEND_EMAIL_LINK_FAILURE:
			return {
				data: {},
				fetching: false,
				requestComplete: false,
				error: action?.payload,
			};
		default:
			return state;
	}
}

const ActivityHistory = {
	data: [],
	fetching: false,
	requestComplete: false,
	error: "",
};

export function RecentActivity(state = ActivityHistory, action) {
	switch (action.type) {
		case ACTIVITY_HISTORY_REQUEST:
			return {
				...state,
				fetching: true,
				requestComplete: false,
				error: "",
			};
		case ACTIVITY_HISTORY_SUCCESS:
			return {
				data: action.payload,
				fetching: false,
				requestComplete: true,
				error: "",
			};

		case ACTIVITY_HISTORY_FAILURE:
			return {
				fetching: false,
				requestComplete: false,
				error: action.payload,
			};
		default:
			return state;
	}
}

const transferState = {
	data: [],
	fetching: false,
	requestComplete: false,
	error: "",
};

export function TransferOwnerReducer(state = transferState, action) {
	switch (action.type) {
		case TRANSFER_OWNER_REQUEST:
			return {
				...state,
				fetching: true,
				requestComplete: false,
				error: "",
			};
		case TRANSFER_OWNER_SUCCESS:
			return {
				data: action.payload,
				fetching: false,
				requestComplete: true,
				error: "",
			};

		case TRANSFER_OWNER_FAILURE:
			return {
				fetching: false,
				requestComplete: false,
				error: action.payload,
			};
		default:
			return state;
	}
}

const TokenAuthState = {
	data: {},
	fetching: false,
	requestComplete: false,
	error: "",
};

export function tokenAuth(state = TokenAuthState, action) {
	switch (action.type) {
		case TOKEN_AUTHORIZATION_REQUEST:
			return {
				...state,
				fetching: true,
				requestComplete: false,
				error: "",
			};
		case TOKEN_AUTHORIZATION_SUCCESS:
			return {
				data: action.payload,
				fetching: false,
				requestComplete: true,
				error: "",
			};
		case TOKEN_AUTHORIZATION_RESET:
			return {
				data: {},
				fetching: false,
				requestComplete: false,
				error: "",
			};
		case TOKEN_AUTHORIZATION_FAILURE:
			return {
				data: {},
				fetching: false,
				requestComplete: false,
				error: action?.payload?.response?.data,
			};
		default:
			return state;
	}
}

//VALIDATE TEMP EMAIL
const ValidateTempMailState = {
	data: {},
	fetching: false,
	requestComplete: false,
	error: "",
};

export function tempMailValidate(state = ValidateTempMailState, action) {
	switch (action.type) {
		case GET_TEMP_MAIL_VALIDATE_REQUEST:
			return {
				...state,
				fetching: true,
				requestComplete: false,
				error: "",
			};
		case GET_TEMP_MAIL_VALIDATE_SUCCESS:
			return {
				data: action.payload,
				fetching: false,
				requestComplete: true,
				error: "",
			};
		case GET_TEMP_MAIL_VALIDATE_RESET:
			return {
				data: {},
				fetching: false,
				requestComplete: false,
				error: "",
			};
		case GET_TEMP_MAIL_VALIDATE_FAILURE:
			return {
				data: {},
				fetching: false,
				requestComplete: false,
				error: action?.payload,
			};
		default:
			return state;
	}
}
const AccDelete = {
	data: {},
	fetching: false,
	requestComplete: false,
	error: "",
};

export function AccountDeleteRedux(state = AccDelete, action) {
	switch (action.type) {
		case ACCOUNT_DELETE_REQUEST:
			return {
				...state,
				fetching: true,
				requestComplete: false,
				error: "",
			};
		case ACCOUNT_DELETE_SUCCESS:
			return {
				data: action.payload,
				fetching: false,
				requestComplete: true,
				error: "",
			};
		case ACCOUNT_DELETE_RESET:
			return {
				data: {},
				fetching: false,
				requestComplete: false,
				error: "",
			};
		case ACCOUNT_DELETE_FAILURE:
			return {
				data: {},
				fetching: false,
				requestComplete: false,
				error: action?.payload,
			};
		default:
			return state;
	}
}
