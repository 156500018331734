import axios from "axios";
import configMyiQ from "../../configuration";
import authHeader from "./auth-header";
import { checkApiStatus } from "../../utils/helpers";
import {
    INVOICE_LIST_REQUEST,
    INVOICE_LIST_SUCCESS,
    INVOICE_LIST_FAILURE,
    GET_INVCOUNT_REQUEST,
    GET_INVCOUNT_SUCCESS,
    GET_INVCOUNT_FAILURE,
    INVOICE_GET_REQUEST,
    INVOICE_GET_SUCCESS,
    INVOICE_GET_FAILURE,
    INVOICE_UPDATE_REQUEST,
    INVOICE_UPDATE_FAILURE,
    INVOICE_UPDATE_SUCCESS,
    INVOICE_APPROVED_LIST_REQUEST,
    INVOICE_APPROVED_LIST_SUCCESS,
    INVOICE_APPROVED_LIST_FAILURE,
    INVOICE_REJECTED_LIST_REQUEST,
    INVOICE_REJECTED_LIST_SUCCESS,
    INVOICE_REJECTED_LIST_FAILURE,
    INVOICE_FLAG_REQUEST,
    INVOICE_FLAG_SUCCESS,
    INVOICE_FLAG_FAILURE,
    INVOICE_CROPED_IMG_SUCCESS,
    INVOICE_CROPED_IMG_REQUEST,
    INVOICE_CROPED_IMG_FAILURE,
    INVOICE_COMPARE_REQUEST,
    INVOICE_COMPARE_SUCCESS,
    INVOICE_COMPARE_FAILURE,
    INVOICE_VENDOR_LIST_REQUEST,
    INVOICE_VENDOR_LIST_SUCCESS,
    INVOICE_VENDOR_LIST_FAILURE,
    INVOICE_LABEL_LAYOUT_LIST_REQUEST,
    INVOICE_LABEL_LAYOUT_LIST_SUCCESS,
    INVOICE_LABEL_LAYOUT_LIST_FAILURE,
    INVOICE_LABEL_LAYOUT_ADD_REQUEST,
    INVOICE_LABEL_LAYOUT_ADD_SUCCESS,
    INVOICE_LABEL_LAYOUT_ADD_FAILURE,
    INVOICE_ID_CONFIG_REQUEST,
    INVOICE_ID_CONFIG_SUCCESS,
    INVOICE_ID_CONFIG_FAILURE,
    GET_INVOICE_ID_CONFIG_REQUEST,
    GET_INVOICE_ID_CONFIG_SUCCESS,
    GET_INVOICE_ID_CONFIG_FAILURE,
    ADD_INVOICE_ALLOCATION_REQUEST,
    ADD_INVOICE_ALLOCATION_SUCCESS,
    ADD_INVOICE_ALLOCATION_FAILURE,
    GET_INVOICE_ALLOCATION_REQUEST,
    GET_INVOICE_ALLOCATION_SUCCESS,
    GET_INVOICE_ALLOCATION_FAILURE,
    POST_INVOICE_APPROVER_REQUEST,
    POST_INVOICE_APPROVER_SUCCESS,
    POST_INVOICE_APPROVER_FAILURE,
    POST_INVOICE_REASSIGN_REQUEST,
    POST_INVOICE_REASSIGN_SUCCESS,
    POST_INVOICE_REASSGIN_FAILURE,
    GET_GENERAL_LEDGER_LIST_REQUEST,
    GET_GENERAL_LEDGER_LIST_SUCCESS,
    GET_GENERAL_LEDGER_LIST_FAILURE,
    GET_DOCUMENT_TYPE_LIST_REQUEST,
    GET_DOCUMENT_TYPE_LIST_SUCCESS,
    GET_DOCUMENT_TYPE_LIST_FAILURE,
    GET_LIST_COST_CENTRE_REQUEST,
    GET_LIST_COST_CENTRE_SUCCESS,
    GET_LIST_COST_CENTRE_FAILURE,
    GET_PO_NUMBER_LIST_REQUEST,
    GET_PO_NUMBER_LIST_SUCCESS,
    GET_PO_NUMBER_LIST_FAILURE,
    GET_SAP_VENDOR_LIST_REQUEST,
    GET_SAP_VENDOR_LIST_SUCCESS,
    GET_SAP_VENDOR_LIST_FAILURE,
    TRANSFER_INVOICE_REQUEST,
    TRANSFER_INVOICE_SUCCESS,
    TRANSFER_INVOICE_RESET,
    TRANSFER_INVOICE_FAILURE,
    GET_REJECT_INVOICE_FAILURE,
    GET_REJECT_INVOICE_REQUEST,
    GET_REJECT_INVOICE_SUCCESS,
    GET_REJECT_INVOICE_RESET,
    INVOICE_GET_DOCUMENT_REQUEST,
    INVOICE_GET_DOCUMENT_SUCCESS,
    INVOICE_GET_DOCUMENT_FAILURE,
    EXPORT_INVOICE_LIST_SUCCESS,
    ERP_VALIDATIONBYMATCHING_FAILURE,
    ERP_VALIDATIONBYMATCHING_REQUEST,
    ERP_VALIDATIONBYMATCHING_SUCCESS,
    POST_SERVICE_LINE_ITEMS_REQUEST,
    POST_SERVICE_LINE_ITEMS_SUCCESS,
    POST_SERVICE_LINE_ITEMS_FAILURE,
    GET_PO_TYPE_REQUEST,
    GET_PO_TYPE_FAILURE,
    GET_PO_TYPE_RESET,
    GET_PO_TYPE_SUCCESS,
    GET_TAX_CODE_LIST_REQUEST,
    GET_TAX_CODE_LIST_SUCCESS,
    GET_TAX_CODE_LIST_FAILURE,
    GET_DOCUMENT_TYPE_REQUEST,
    GET_DOCUMENT_TYPE_SUCCESS,
    GET_DOCUMENT_TYPE_FAILURE,
    POST_DOCUMENT_TYPE_REQUEST,
    POST_DOCUMENT_TYPE_SUCCESS,
    POST_DOCUMENT_TYPE_FAILURE,
    GET_INVOICE_CALC_VIEW_REQUEST,
    GET_INVOICE_CALC_VIEW_SUCCESS,
    GET_INVOICE_CALC_VIEW_FAILURE,
    GET_INVOICE_CALC_DETAIL_VIEW_REQUEST,
    GET_INVOICE_CALC_DETAIL_VIEW_SUCCESS,
    GET_INVOICE_CALC_DETAIL_VIEW_FAILURE,
} from "../types/types";

const API_URL = process.env?.REACT_APP_API_URL;
const API = process.env?.REACT_APP_NODE_API_URL;

//processinvoice
export const InvoiceList = (data, download) => async (dispatch) => {
    try {
        if (download !== "download") {
            dispatch({ type: INVOICE_LIST_REQUEST });
        }
        axios
            .post(`${API_URL}Invoice/GetInvoiceList`, data, {
                headers: authHeader(),
            })
            .then((response) => {
                if (download === "download") {
                    dispatch({
                        type: EXPORT_INVOICE_LIST_SUCCESS,
                        payload: response?.data?.datalist,
                    });
                } else {
                    dispatch({
                        type: INVOICE_LIST_SUCCESS,
                        payload: response?.data?.datalist,
                    });
                }
            })
            .catch((ex) => {
                dispatch({
                    type: INVOICE_LIST_FAILURE,
                    payload: ex,
                });
            });
    } catch (error) {
        if (error?.response?.status == 401) {
            checkApiStatus();
        }
        dispatch({
            type: INVOICE_LIST_FAILURE,
        });
    }
};

export const invoiceCountTotal = (data) => async (dispatch) => {
    try {
        dispatch({ type: GET_INVCOUNT_REQUEST });

        axios
            .post(`${API_URL}Invoice/GetInvoiceCount`, data, {
                headers: authHeader(),
            })
            .then((response) => {
                dispatch({
                    type: GET_INVCOUNT_SUCCESS,
                    payload: response,
                });
            });
    } catch (error) {
        if (error?.response?.status == 401) {
            checkApiStatus();
        }
        dispatch({
            type: GET_INVCOUNT_FAILURE,
        });
    }
};

//Get invoice details

export const getInvoiceItems = (data) => async (dispatch) => {
    try {
        dispatch({ type: INVOICE_GET_REQUEST });

        axios
            .post(`${API}api/invoiceController/getInvoiceView`, data, {
                headers: authHeader(),
            })
            .then((response) => {
                dispatch({
                    type: INVOICE_GET_SUCCESS,
                    payload: response?.data,
                });
            })
            .catch((ex) => {
                if (ex?.response?.status == 401) {
                    checkApiStatus();
                }
                dispatch({
                    type: INVOICE_GET_FAILURE,
                    payload: ex,
                });
            });
    } catch (error) {
        dispatch({
            type: INVOICE_GET_FAILURE,
        });
    }
};

//processinvoice
export const UpdateInvoice = (data) => async (dispatch) => {
    try {
        dispatch({ type: INVOICE_UPDATE_REQUEST });

        axios
            .post(`${API}api/v1/Invoice/updateInvoice`, data, {
                headers: authHeader(),
            })
            .then((response) => {
                dispatch({
                    type: INVOICE_UPDATE_SUCCESS,
                    payload: response?.data,
                });
            })
            .catch(function (error) {
                if (error?.response?.status == 401) {
                    checkApiStatus();
                }
                dispatch({
                    type: INVOICE_UPDATE_FAILURE,
                });
            });
    } catch (error) {
        dispatch({
            type: INVOICE_UPDATE_FAILURE,
        });
    }
};

export const InvoiceStatusRejected = (data) => async (dispatch) => {
    try {
        dispatch({ type: INVOICE_REJECTED_LIST_REQUEST });

        axios
            .post(`${API}api/invoiceController/updateInvoiceStatusRejected`, data, {
                headers: authHeader(),
            })

            .then((response) => {
                dispatch({
                    type: INVOICE_REJECTED_LIST_SUCCESS,

                    payload: response?.data,
                });
            });
    } catch (error) {
        if (error?.response?.status == 401) {
            checkApiStatus();
        }
        dispatch({
            type: INVOICE_REJECTED_LIST_FAILURE,
        });
    }
};

export const InvoiceStatusApproved = (data) => async (dispatch) => {
    try {
        dispatch({ type: INVOICE_APPROVED_LIST_REQUEST });

        axios
            .post(`${API}api/invoiceController/UpdateInvoiceStatusApproved`, data, {
                headers: authHeader(),
            })

            .then((response) => {
                dispatch({
                    type: INVOICE_APPROVED_LIST_SUCCESS,
                    payload: response?.data,
                });
            })
            .catch(function (error) {
                if (error?.response?.status == 401) {
                    checkApiStatus();
                }
                dispatch({
                    payload: error.response.data,
                    type: INVOICE_APPROVED_LIST_FAILURE,
                });
            });
    } catch (error) {
        dispatch({
            type: INVOICE_APPROVED_LIST_FAILURE,
        });
    }
};

export const InvoiceFlag = (data) => async (dispatch) => {
    try {
        dispatch({ type: INVOICE_FLAG_REQUEST });

        axios
            .post(`${API_URL}Invoice/FlagInvoice`, data, {
                headers: authHeader(),
            })

            .then((response) => {
                dispatch({
                    type: INVOICE_FLAG_SUCCESS,

                    payload: response?.data,
                });
            })
            .catch(function (error) {
                if (error?.response?.status == 401) {
                    checkApiStatus();
                }
                dispatch({
                    payload: { error: error.message },
                    type: INVOICE_FLAG_FAILURE,
                });
            });
    } catch (error) {
        dispatch({
            type: INVOICE_FLAG_FAILURE,
        });
    }
};

export const InvoiceCropedImgUpload = (data) => async (dispatch) => {
    try {
        dispatch({ type: INVOICE_CROPED_IMG_REQUEST });

        axios
            .post(`${process.env?.REACT_APP_NODE_API_URL}invocieFileUpload`, data, {
                headers: authHeader(),
            })

            .then((response) => {
                dispatch({
                    type: INVOICE_CROPED_IMG_SUCCESS,

                    payload: response?.data,
                });
            })
            .catch(function (error) {
                if (error?.response?.status == 401) {
                    checkApiStatus();
                }
                dispatch({
                    payload: { error: error.message },
                    type: INVOICE_CROPED_IMG_FAILURE,
                });
            });
    } catch (error) {
        dispatch({
            type: INVOICE_CROPED_IMG_FAILURE,
        });
    }
};

export const InvoiceCompare = (data) => async (dispatch) => {
    try {
        dispatch({ type: INVOICE_COMPARE_REQUEST });

        axios
            .post(`${API_URL}Activity/InvoiceCompare`, data, {
                headers: authHeader(),
            })

            .then((response) => {
                dispatch({
                    type: INVOICE_COMPARE_SUCCESS,
                    payload: response?.data?.datalist,
                });
            })
            .catch(function (error) {
                if (error?.response?.status == 401) {
                    checkApiStatus();
                }
                dispatch({
                    payload: { error: error.message },
                    type: INVOICE_COMPARE_FAILURE,
                });
            });
    } catch (error) {
        dispatch({
            type: INVOICE_COMPARE_FAILURE,
        });
    }
};

export const invoiceVendorList = (id) => async (dispatch) => {
    try {
        dispatch({ type: INVOICE_VENDOR_LIST_REQUEST });
        axios
            .get(`${API_URL}Invoice/GetVendorList?org_id=${id}`, {
                headers: authHeader(),
            })
            .then((response) => {
                dispatch({
                    type: INVOICE_VENDOR_LIST_SUCCESS,
                    payload: response?.data?.datalist,
                });
            });
    } catch (error) {
        if (error?.response?.status == 401) {
            checkApiStatus();
        }
        dispatch({
            type: INVOICE_VENDOR_LIST_FAILURE,
        });
    }
};

export const InvoiceLabelLayoutList = (countryId, type) => async (dispatch) => {
    try {
        /**
         *  type 1 ---> Fresh country change
         *  type 2 ---> Change in country for copy
         */
        dispatch({ type: INVOICE_LABEL_LAYOUT_LIST_REQUEST });
        axios
            .get(`${API}api/layoutSettings/getInvoiceLayout?country=${countryId}`, {
                headers: authHeader(),
            })
            .then((response) => {
                dispatch({
                    type: INVOICE_LABEL_LAYOUT_LIST_SUCCESS,
                    payload: response?.data?.datalist,
                    checkType: type,
                });
            });
    } catch (error) {
        if (error?.response?.status == 401) {
            checkApiStatus();
        }
        dispatch({
            type: INVOICE_LABEL_LAYOUT_LIST_FAILURE,
        });
    }
};

export const InvoiceLabelLayoutAdd = (data) => async (dispatch) => {
    try {
        dispatch({ type: INVOICE_LABEL_LAYOUT_ADD_REQUEST });

        axios
            .post(`${API}api/layoutSettings/updateInvoicelayout`, data, {
                headers: authHeader(),
            })

            .then((response) => {
                dispatch({
                    type: INVOICE_LABEL_LAYOUT_ADD_SUCCESS,
                    payload: response?.data?.datalist,
                });
            })
            .catch(function (error) {
                if (error?.response?.status == 401) {
                    checkApiStatus();
                }
                dispatch({
                    payload: { error: error.message },
                    type: INVOICE_LABEL_LAYOUT_ADD_FAILURE,
                });
            });
    } catch (error) {
        dispatch({
            type: INVOICE_LABEL_LAYOUT_ADD_FAILURE,
        });
    }
};

export const InvoiceIdConfig = (data) => async (dispatch) => {
    try {
        dispatch({ type: INVOICE_ID_CONFIG_REQUEST });

        axios
            .post(`${API}api/v1/IdConfig/UpdateIDConfig`, data, {
                headers: authHeader(),
            })

            .then((response) => {
                dispatch({
                    type: INVOICE_ID_CONFIG_SUCCESS,
                    payload: response?.data,
                });
            })
            .catch(function (error) {
                if (error?.response?.status == 401) {
                    checkApiStatus();
                }
                dispatch({
                    payload: error?.response?.data,
                    type: INVOICE_ID_CONFIG_FAILURE,
                });
            });
    } catch (error) {
        dispatch({
            payload: error?.response?.data,
            type: INVOICE_ID_CONFIG_FAILURE,
        });
    }
};

export const GetInvoiceIdConfig = (data) => async (dispatch) => {
    try {
        dispatch({ type: GET_INVOICE_ID_CONFIG_REQUEST });

        axios
            .get(`${API}api/v1/IdConfig/ViewIDConfig?org_id=${data}`, {
                headers: authHeader(),
            })

            .then((response) => {
                dispatch({
                    type: GET_INVOICE_ID_CONFIG_SUCCESS,
                    payload: response?.data,
                });
            })
            .catch(function (error) {
                if (error?.response?.status == 401) {
                    checkApiStatus();
                }
                dispatch({
                    payload: { error: error.message },
                    type: GET_INVOICE_ID_CONFIG_FAILURE,
                });
            });
    } catch (error) {
        dispatch({
            type: GET_INVOICE_ID_CONFIG_FAILURE,
        });
    }
};

export const addInvoiceAllocation = (data) => async (dispatch) => {
    try {
        dispatch({ type: ADD_INVOICE_ALLOCATION_REQUEST });

        axios
            .post(`${API}api/addupdateinvoiceallocation`, data, {
                headers: authHeader(),
            })

            .then((response) => {
                dispatch({
                    type: ADD_INVOICE_ALLOCATION_SUCCESS,
                    payload: response?.data,
                });
            })
            .catch(function (error) {
                if (error?.response?.status == 401) {
                    checkApiStatus();
                }
                dispatch({
                    payload: { error: error.message },
                    type: ADD_INVOICE_ALLOCATION_FAILURE,
                });
            });
    } catch (error) {
        dispatch({
            type: ADD_INVOICE_ALLOCATION_FAILURE,
        });
    }
};
//getAllocationList
export const getInvoiceAllocationList = (orgId) => async (dispatch) => {
    try {
        dispatch({ type: GET_INVOICE_ALLOCATION_REQUEST });

        axios
            .get(`${API}api/getallocationlist?orgid=${orgId}`, {
                headers: authHeader(),
            })
            .then((response) => {
                dispatch({
                    type: GET_INVOICE_ALLOCATION_SUCCESS,
                    payload: response?.data?.datalist,
                });
            })
            .catch((ex) => {
                if (ex?.response?.status == 401) {
                    checkApiStatus();
                }
                dispatch({
                    type: GET_INVOICE_ALLOCATION_FAILURE,
                    payload: ex,
                });
            });
    } catch (error) {
        dispatch({
            type: GET_INVOICE_ALLOCATION_FAILURE,
        });
    }
};

export const getInvApprove = (orgId, InvId) => async (dispatch) => {
    try {
        dispatch({ type: POST_INVOICE_APPROVER_REQUEST });

        axios
            .get(`${API}api/sendtoassignapprove?orgid=${orgId}&invid=${InvId}&type=${2}`, {
                headers: authHeader(),
            })
            .then((response) => {
                dispatch({
                    type: POST_INVOICE_APPROVER_SUCCESS,
                    payload: response?.data,
                });
            })
            .catch((ex) => {
                if (ex?.response?.status == 401) {
                    checkApiStatus();
                }
                dispatch({
                    type: POST_INVOICE_APPROVER_FAILURE,
                    payload: ex,
                });
            });
    } catch (error) {
        dispatch({
            type: POST_INVOICE_APPROVER_FAILURE,
        });
    }
};
export const ReassignInvoice = (data) => async (dispatch) => {
    try {
        dispatch({ type: POST_INVOICE_REASSIGN_REQUEST });

        axios
            .post(`${API}api/reassigninvoice`, data, {
                headers: authHeader(),
            })
            .then((response) => {
                dispatch({
                    type: POST_INVOICE_REASSIGN_SUCCESS,
                    payload: response?.data,
                });
            })
            .catch((ex) => {
                if (ex?.response?.status == 401) {
                    checkApiStatus();
                }
                dispatch({
                    type: POST_INVOICE_REASSGIN_FAILURE,
                    payload: ex,
                });
            });
    } catch (error) {
        dispatch({
            type: POST_INVOICE_REASSGIN_FAILURE,
        });
    }
};

//GET LEDGER LIST
export const getGeneralLedgerList = (orgId) => async (dispatch) => {
    try {
        dispatch({ type: GET_GENERAL_LEDGER_LIST_REQUEST });

        axios
            .get(`${API}api/getlistGeneralLedger?org_id=${orgId}`, {
                headers: authHeader(),
            })
            .then((response) => {
                dispatch({
                    type: GET_GENERAL_LEDGER_LIST_SUCCESS,
                    payload: response?.data?.datalist,
                });
            })
            .catch((ex) => {
                if (ex?.response?.status == 401) {
                    checkApiStatus();
                }
                dispatch({
                    type: GET_GENERAL_LEDGER_LIST_FAILURE,
                    payload: ex,
                });
            });
    } catch (error) {
        dispatch({
            type: GET_GENERAL_LEDGER_LIST_FAILURE,
        });
    }
};
//GET DOCUMENT_TYPE LIST
export const getDocumentTypeList = () => async (dispatch) => {
    try {
        dispatch({ type: GET_DOCUMENT_TYPE_LIST_REQUEST });

        axios
            .get(`${API_URL}Invoice/GetDocument_Type`, {
                headers: authHeader(),
            })
            .then((response) => {
                dispatch({
                    type: GET_DOCUMENT_TYPE_LIST_SUCCESS,
                    payload: response?.data?.datalist,
                });
            })
            .catch((ex) => {
                if (ex?.response?.status == 401) {
                    checkApiStatus();
                }
                dispatch({
                    type: GET_DOCUMENT_TYPE_LIST_FAILURE,
                    payload: ex,
                });
            });
    } catch (error) {
        dispatch({
            type: GET_DOCUMENT_TYPE_LIST_FAILURE,
        });
    }
};

//GET_COST_CENTRE_LIST

export const getCostCentreList = (orgId) => async (dispatch) => {
    try {
        dispatch({ type: GET_LIST_COST_CENTRE_REQUEST });

        axios
            .get(`${API}api/getlistCostCentre?org_id=${orgId}`, {
                headers: authHeader(),
            })
            .then((response) => {
                dispatch({
                    type: GET_LIST_COST_CENTRE_SUCCESS,
                    payload: response?.data?.datalist,
                });
            })
            .catch((ex) => {
                if (ex?.response?.status == 401) {
                    checkApiStatus();
                }
                dispatch({
                    type: GET_LIST_COST_CENTRE_FAILURE,
                    payload: ex,
                });
            });
    } catch (error) {
        dispatch({
            type: GET_LIST_COST_CENTRE_FAILURE,
        });
    }
};

//GET PO NUMBER LIST
export const getPoNumberList = (orgId, type) => async (dispatch) => {
    try {
        dispatch({ type: GET_PO_NUMBER_LIST_REQUEST });

        axios
            .get(`${API}api/getPoList?orgid=${orgId}${type ? `&type=${type}` : ""}`, {
                headers: authHeader(),
            })
            .then((response) => {
                dispatch({
                    type: GET_PO_NUMBER_LIST_SUCCESS,
                    payload: response?.data?.datalist,
                });
            })
            .catch((ex) => {
                if (ex?.response?.status == 401) {
                    checkApiStatus();
                }
                dispatch({
                    type: GET_PO_NUMBER_LIST_FAILURE,
                    payload: ex,
                });
            });
    } catch (error) {
        dispatch({
            type: GET_PO_NUMBER_LIST_FAILURE,
        });
    }
};

//GET SAP VENDOR LIST

export const getSapVendorList = (orgId) => async (dispatch) => {
    try {
        dispatch({ type: GET_SAP_VENDOR_LIST_REQUEST });

        axios
            .get(`${API}api/GetSAPVendorList/getVendorList?orgid=${orgId}`, {
                headers: authHeader(),
            })
            .then((response) => {
                dispatch({
                    type: GET_SAP_VENDOR_LIST_SUCCESS,
                    payload: response?.data?.datalist,
                });
            })
            .catch((ex) => {
                if (ex?.response?.status == 401) {
                    checkApiStatus();
                }
                dispatch({
                    type: GET_SAP_VENDOR_LIST_FAILURE,
                    payload: ex,
                });
            });
    } catch (error) {
        dispatch({
            type: GET_SAP_VENDOR_LIST_FAILURE,
        });
    }
};
export const TransferInvAct = (invid, org_id) => async (dispatch) => {
    try {
        dispatch({ type: TRANSFER_INVOICE_REQUEST });

        axios
            .get(
                `${API}api/transfer_invoiceowner?invoice_id=${invid}&org_id=${org_id}`,

                {
                    headers: authHeader(),
                }
            )
            .then((response) => {
                dispatch({
                    type: TRANSFER_INVOICE_SUCCESS,
                    payload: response?.data,
                });
            })
            .catch((ex) => {
                if (ex?.response?.status == 401) {
                    checkApiStatus();
                }
                dispatch({
                    type: TRANSFER_INVOICE_FAILURE,
                    payload: ex,
                });
            });
    } catch (error) {
        dispatch({
            type: TRANSFER_INVOICE_FAILURE,
        });
    }
};

//Transfer invoice reject list api
export const transferInvoiceReject = (invid, org_id) => async (dispatch) => {
    try {
        dispatch({ type: GET_REJECT_INVOICE_REQUEST });

        axios
            .get(
                `${API_URL}Invoice/TransferInvoiceReject?invoice_id=${invid}&orgid=${org_id}`,

                {
                    headers: authHeader(),
                }
            )
            .then((response) => {
                dispatch({
                    type: GET_REJECT_INVOICE_SUCCESS,
                    payload: response?.data,
                });
            })
            .catch((ex) => {
                if (ex?.response?.status == 401) {
                    checkApiStatus();
                }
                dispatch({
                    type: GET_REJECT_INVOICE_FAILURE,
                    payload: ex,
                });
            });
    } catch (error) {
        dispatch({
            type: GET_REJECT_INVOICE_FAILURE,
        });
    }
};

export const InvoiceGetDocumnet = (data) => async (dispatch) => {
    try {
        dispatch({ type: INVOICE_GET_DOCUMENT_REQUEST });
        axios
            .get(`${API_URL}Invoice/GetDocumentFromS3?documentName=${data}`, {
                headers: authHeader(),
            })
            .then((response) => {
                dispatch({
                    type: INVOICE_GET_DOCUMENT_SUCCESS,
                    payload: response?.data,
                    id: data,
                });
            })
            .catch((ex) => {
                dispatch({
                    type: INVOICE_GET_DOCUMENT_FAILURE,
                    payload: ex,
                });
            });
    } catch (error) {
        if (error?.response?.status == 401) {
            checkApiStatus();
        }
        dispatch({
            type: INVOICE_GET_DOCUMENT_FAILURE,
        });
    }
};

// three way matching by erp validation
export const postThreeWayMatching = (data) => async (dispatch) => {
    try {
        dispatch({ type: ERP_VALIDATIONBYMATCHING_REQUEST });

        axios
            .post(`${API}api/validateERP`, data, {
                headers: authHeader(),
            })
            .then((response) => {
                const initialData = [];
                response?.data?.data?.overallData?.map((item) => {
                    if (item?.isLineItem) {
                        initialData.push({
                            [item.lineItemNo]: item?.poItemCode,
                        });
                    }
                });
                dispatch({
                    type: ERP_VALIDATIONBYMATCHING_SUCCESS,
                    payload: response?.data?.data,
                    initialData,
                });
            })
            .catch((ex) => {
                if (ex?.response?.status == 401) {
                    checkApiStatus();
                }
                dispatch({
                    type: ERP_VALIDATIONBYMATCHING_FAILURE,
                    payload: ex,
                });
            });
    } catch (error) {
        dispatch({
            type: ERP_VALIDATIONBYMATCHING_FAILURE,
        });
    }
};
//fill service line items
//processinvoice
export const UpdateServiceLineItems = (data) => async (dispatch) => {
    try {
        dispatch({ type: POST_SERVICE_LINE_ITEMS_REQUEST });

        axios
            .post(`${API}api/fillServiceLineItems`, data, {
                headers: authHeader(),
            })
            .then((response) => {
                dispatch({
                    type: POST_SERVICE_LINE_ITEMS_SUCCESS,
                    payload: response?.data,
                });
            })
            .catch(function (error) {
                if (error?.response?.status == 401) {
                    checkApiStatus();
                }
                dispatch({
                    type: POST_SERVICE_LINE_ITEMS_FAILURE,
                });
            });
    } catch (error) {
        dispatch({
            type: POST_SERVICE_LINE_ITEMS_FAILURE,
        });
    }
};

//GET TAX CODE LIST

export const getTaxCodeList = (orgId) => async (dispatch) => {
    try {
        dispatch({ type: GET_TAX_CODE_LIST_REQUEST });

        axios
            .get(`${API}api/getlistTaxCode?org_id=${orgId}`, {
                headers: authHeader(),
            })
            .then((response) => {
                dispatch({
                    type: GET_TAX_CODE_LIST_SUCCESS,
                    payload: response?.data?.datalist,
                });
            })
            .catch((ex) => {
                if (ex?.response?.status == 401) {
                    checkApiStatus();
                }
                dispatch({
                    type: GET_TAX_CODE_LIST_FAILURE,
                    payload: ex,
                });
            });
    } catch (error) {
        dispatch({
            type: GET_TAX_CODE_LIST_FAILURE,
        });
    }
};
//GET PO TYPE
export const getPoType = (data) => async (dispatch) => {
    try {
        dispatch({ type: GET_PO_TYPE_REQUEST });

        axios
            .get(`${API}api/getPoType?orgid=${data?.orgId}&poNumber=${data?.poNumber}`, {
                headers: authHeader(),
            })
            .then((response) => {
                dispatch({
                    type: GET_PO_TYPE_SUCCESS,
                    payload: response?.data,
                });
            })
            .catch(function (error) {
                if (error?.response?.status == 401) {
                    checkApiStatus();
                }
                dispatch({
                    type: GET_PO_TYPE_FAILURE,
                });
            });
    } catch (error) {
        dispatch({
            type: GET_PO_TYPE_FAILURE,
        });
    }
};

//GET PO TYPE
export const getDocumentType = () => async (dispatch) => {
    try {
        dispatch({ type: GET_DOCUMENT_TYPE_REQUEST });

        axios
            .get(`${API}api/v1/DocumentType/typeList`, {
                headers: authHeader(),
            })
            .then((response) => {
                dispatch({
                    type: GET_DOCUMENT_TYPE_SUCCESS,
                    payload: response?.data?.message,
                });
            })
            .catch(function (error) {
                if (error?.response?.status == 401) {
                    checkApiStatus();
                }
                dispatch({
                    type: GET_DOCUMENT_TYPE_FAILURE,
                });
            });
    } catch (error) {
        dispatch({
            type: GET_DOCUMENT_TYPE_FAILURE,
        });
    }
};

//Post PO TYPE
export const UpdateDocumentType = (data) => async (dispatch) => {
    try {
        dispatch({ type: POST_DOCUMENT_TYPE_REQUEST });

        axios
            .post(`${API}api/v1/DocumentType/updatetype`, data, {
                headers: authHeader(),
            })
            .then((response) => {
                dispatch({
                    type: POST_DOCUMENT_TYPE_SUCCESS,
                    payload: response,
                });
            })
            .catch(function (error) {
                if (error?.response?.status == 401) {
                    checkApiStatus();
                }
                dispatch({
                    type: POST_DOCUMENT_TYPE_FAILURE,
                });
            });
    } catch (error) {
        dispatch({
            type: POST_DOCUMENT_TYPE_FAILURE,
        });
    }
};

//GET INVOICE CALCULATION
export const getInvoiceCalc = (invid, orgid, Amounts) => async (dispatch) => {
    try {
        dispatch({ type: GET_INVOICE_CALC_VIEW_REQUEST });

        axios
            .get(`${API}api/v1/Invoice/invoiceCalculationList?invid=${+invid}&org_id=${+orgid}`, {
                headers: authHeader(),
            })
            .then((response) => {
                dispatch({
                    type: GET_INVOICE_CALC_VIEW_SUCCESS,
                    payload: { data: response?.data?.message, Amounts },
                });
            })
            .catch(function (error) {
                if (error?.response?.status == 401) {
                    checkApiStatus();
                }
                dispatch({
                    type: GET_INVOICE_CALC_VIEW_FAILURE,
                });
            });
    } catch (error) {
        dispatch({
            type: GET_INVOICE_CALC_VIEW_FAILURE,
        });
    }
};

//GET INVOICE CALCULATION
export const getInvoiceCalcDetail = (invid, type, orgId, PoNumber, vendorCode, ref_date) => async (dispatch) => {
    try {
        dispatch({ type: GET_INVOICE_CALC_DETAIL_VIEW_REQUEST });
        axios
            .get(
                `${API}api/v1/Invoice/invoiceCalculationDetailList?invid=${invid}&type=${type}&org_id=${orgId}&poNumber=${PoNumber}&vendorCode=${vendorCode}&request_date=${ref_date}`,
                {
                    headers: authHeader(),
                }
            )
            .then((response) => {
                dispatch({
                    type: GET_INVOICE_CALC_DETAIL_VIEW_SUCCESS,
                    payload: response?.data,
                    CalcType: type,
                });
            })
            .catch(function (error) {
                if (error?.response?.status == 401) {
                    checkApiStatus();
                }
                dispatch({
                    type: GET_INVOICE_CALC_DETAIL_VIEW_FAILURE,
                    payload: error?.response,
                });
            });
    } catch (error) {
        dispatch({
            type: GET_INVOICE_CALC_DETAIL_VIEW_FAILURE,
        });
    }
};