import Modal from "../../../components/UI/Modal/Modal";
import { ThemeContext } from "../../../Hooks/Themes/Themeprovider";
import { useContext } from "react";
import { PoHeaderView } from "./ModalContains/PoHeaderView";
import { TaxDetuction } from "./ModalContains/Tax";
import { AdvanceRc } from "./ModalContains/AdvanceRc";
import { MicsPR } from "./ModalContains/MicsPR";
import { CreditNote } from "./ModalContains/CreditNote";
const CalCulationModal = (props) => {
    const { currentMode } = useContext(ThemeContext);
    const { advData, setAdvData, miscData, setMiscData, listItems, netValue, isEdit, creditData, setCreditData } = props;

    return (
        <>
            <Modal cartClose={props?.handleClose} center="center" className={`${props?.className}`}>
                <div
                    className={`min-w-[986px] border-${currentMode}-1090 text-${currentMode}-main border-b mx-auto relative bg-${currentMode}-card  rounded-[10px] ${
                        currentMode == "dark" ? "" : "shadow-[0px_4px_20px_rgba(238,238,238,0.5)]"
                    }]`}>
                    {props?.type == 7 && (
                        <PoHeaderView
                            listItems={listItems}
                            currentMode={currentMode}
                            type={props?.type}
                            invid={props?.invid}
                            handleClose={props?.handleClose}
                        />
                    )}
                    {props?.type == 2 && (
                        <TaxDetuction
                            isEdit={isEdit}
                            listItems={listItems}
                            type={props?.type}
                            invid={props?.invid}
                            currentMode={currentMode}
                            handleClose={props?.handleClose}
                        />
                    )}
                    {props?.type == 1 && (
                        <AdvanceRc
                            netValue={netValue}
                            advData={advData}
                            setAdvData={setAdvData}
                            type={props?.type}
                            isEdit={isEdit}
                            listItems={listItems}
                            invid={props?.invid}
                            currentMode={currentMode}
                            handleClose={props?.handleClose}
                        />
                    )}
                    {props?.type == 3 && (
                        <MicsPR
                            type={props?.type}
                            miscData={miscData}
                            isEdit={isEdit}
                            listItems={listItems}
                            setMiscData={setMiscData}
                            invid={props?.invid}
                            currentMode={currentMode}
                            handleClose={props?.handleClose}
                        />
                    )}
                    {props?.type == 4 && (
                        <CreditNote
                            listItems={listItems}
                            type={props?.type}
                            isEdit={isEdit}
                            invid={props?.invid}
                            creditData={creditData}
                            setCreditData={setCreditData}
                            currentMode={currentMode}
                            handleClose={props?.handleClose}
                        />
                    )}
                </div>
            </Modal>
        </>
    );
};

export default CalCulationModal;
