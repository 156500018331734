import { useContext, useEffect, useState } from "react";
import Modal from "../../../components/UI/Modal/Modal";
import _ from "lodash";
import { ThemeContext } from "../../../Hooks/Themes/Themeprovider";

const ValidationModal = (props) => {
    const goog = "* Company and Invoice company not matching* Net and gross amount is mismatching";

    const overallErrors = props?.overallErrors;
    const { currentMode, toggleMode } = useContext(ThemeContext);
    return (
        <>
            <Modal cartClose={props?.handleClose} center="center" autowidth={`autowidth`} className={`${props?.className}`}>
                <div
                    className={`xl:max-w-[500px] min-w-[500px] max-h-[300px]  transition bg-${currentMode}-card border-[rgba(236,236,236,0.88)] shadow-[0px_4px_20px_rgba(238,238,238,0.5)] rounded-[10px] duration-150 ease-out p-[30px]`}
                >
                    <div
                        className={`flex justify-between items-center border-${currentMode}-700 sticky pt-0 top-0 bg-${currentMode}-card  z-[9] border-b-[1px] mx-[-30px] py-5 px-[30px]`}
                    >
                        <span className="flex">
                            <img src="images/common/alert-triangle.svg" alt="warning" />
                            <span className="ml-2">Resolve the failed ERP data or invoice data mismatches:</span>
                        </span>
                        <span className="cursor-pointer convertWhite" onClick={props?.handleClose}>
                            <img src="/images/common/close-one.svg" alt="close-one" />
                        </span>
                    </div>
                    {/* <p className="texts">{Tags}</p> */}
                    <div className={`mt-7 max-h-[150px] overflow-y-auto mx-auto`}>
                        {overallErrors && overallErrors?.length !== 0 ? (
                            overallErrors?.map((item, index) => {
                                return (
                                    <ul key={index + 1}>
                                        {index + 1}. {item}
                                    </ul>
                                );
                            })
                        ) : (
                            <div className="bg-white">
                                <h4 className="flex justify-center font-interSb">No Data found</h4>
                            </div>
                        )}
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default ValidationModal;
